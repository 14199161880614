(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerBankRelationshipReportController', CustomerBankRelationshipReportController);

    CustomerBankRelationshipReportController.$inject = ['$scope', '$state', 'workflowSharedService','GlobalValues','CustomerBankRelationshipReport','UserProfile','EntityPermissions','$filter', 'entity','ScreenLog', '$rootScope', 'MessageBox', 'entityConstants','$timeout', 'CustomerTradingBankDetails', 'TacViewForEntitiesUtility', 'TACViewForEntitiesService', 'agreedTACList'];

    function CustomerBankRelationshipReportController($scope, $state,  workflowSharedService,  GlobalValues, CustomerBankRelationshipReport, UserProfile,EntityPermissions,$filter, entity,ScreenLog, $rootScope, MessageBox , entityConstants,$timeout, CustomerTradingBankDetails, TacViewForEntitiesUtility, TACViewForEntitiesService, agreedTACList) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.noOfRecordsPerPage1 = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page1 = 1;
        $scope.noOfRecordsPerPage2 = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page2 = 1;
        vm.screenLog=ScreenLog;
        vm.userBank=vm.globalValues.getCustomerId;
        vm.deleteRelationship=deleteRelationship;
        vm.withdrawRequest=withdrawRequest;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRADING_BANK_LIST
        vm.close=close;
        $scope.refresh=refresh;
        vm.exportToFile=exportToFile;
        vm.openAnnexure2=openAnnexure2;
        var yesMessageEvent = null;
        vm.mimeType= '';  	  
  	  	vm.baseString = 'Trading_Bank_List_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   
  	  	
  	    var tacAnnexurefilename = "FX-Retail Registration with T&C_";

        vm.organisation = vm.globalValues.getOrganisation();
        vm.clearcorpOrgId = 1;	  
        vm.agreedTacList = agreedTACList;
        getClearcorpTACAgreed();
        
        vm.permitCustomerBankRelationshipAddEntityAction = EntityPermissions.hasPermission('TradingBankList.ADD')
        vm.permitCustomerBankRelationshipRemoveEntityAction = EntityPermissions.hasPermission('TradingBankList.REMOVE')
		vm.permitCustomerBankRelationshipWithdrawEntityAction = EntityPermissions.hasPermission('TradingBankList.WITHDRAW')
		
        
        loadAll();

        function getClearcorpTACAgreed(){
            if(vm.agreedTacList!=null && vm.agreedTacList!=undefined && vm.agreedTacList.length>0){
                vm.clearcorpTAC = _.filter(angular.copy(vm.agreedTacList), { 'tacProviderId': vm.clearcorpOrgId });
                if(vm.clearcorpTAC!=null && vm.clearcorpTAC!=undefined && vm.clearcorpTAC.length>0){
                    vm.isClearcorpTACAgreed = vm.clearcorpTAC[0].isAgreed;
                }
            }
        }

        vm.launch=launch;
        function launch() {
            if(vm.isClearcorpTACAgreed!=null && vm.isClearcorpTACAgreed!=undefined && angular.lowercase(vm.isClearcorpTACAgreed)=='yes'){
                $state.go("trading-bank-create");    
            }else{
                MessageBox.openMessageBox("Alert", $filter('translate')('gatewayApp.BankRelationship.agreeClearcorpRevisedTAC'), "Ok", "customerbankrelationship");
            }
        }
        
        logScreenAccess();
        //Get customer trading bank details and customer registration request details for customer id
        
        function loadAll() {
        	CustomerBankRelationshipReport.getCustomerTradingBanks({"customerId":vm.globalValues.getOrgId()},onGetCustomerTradingBanksSuccess,onGetCustomerTradingBanksError);
        	
        	CustomerBankRelationshipReport.getPendingAndRejected({"customerId":vm.globalValues.getOrgId()},onGetCustomerPendingSuccess,onGetCustomerPendingError);
        	
        	UserProfile.get({"userId" : vm.globalValues.getUser().id}, onGetUserProfileSuccess, onGetUserProfileError);
        	
        	

        }
        
        function onGetCustomerTradingBanksSuccess(result)
        {
        	vm.tradingBanks=result;
        }
        
        
        function onGetCustomerTradingBanksError()
        {
        	
        }
        
        function onGetCustomerPendingSuccess(result)
        {
        	vm.pendingAndRejectedBanks=result;
        	
        }
        
        
        function onGetCustomerPendingError()
        {
        	
        }
        
        function onGetUserProfileSuccess(result){
			vm.userprofile = result;
			vm.customerBank=vm.userprofile.userTradingBank;
			

        }
        
        function onGetUserProfileError(){
        	
        }
        
        //delete customer trading bank relationship
        
        function deleteRelationship(id){
        	$scope.deleteRequestId = id;
        	MessageBox.openMessageBox("Alert", "Are you sure to delete this bank relationship?", "YesNo", "deletebankrelationship");
        	//CustomerBankRelationshipReport.delete({"customerTradingBankDetailsId":id,"customerId":vm.globalValues.getOrgId()},onDeleteCustomerTradingBankSuccess,onDeleteCustomerTradingBankError);
        }
        
        yesMessageEvent = $rootScope.$on('gatewayApp.messagebox.yes.deletebankrelationship', function (event, data) {
        	if($scope.deleteRequestId!=undefined){
                	CustomerBankRelationshipReport.delete({"customerTradingBankDetailsId":$scope.deleteRequestId,"customerId":vm.globalValues.getOrgId()},onDeleteCustomerTradingBankSuccess,onDeleteCustomerTradingBankError);

        		
        	}
        });
        
        $scope.$on('$destroy', function() {
  		  if (yesMessageEvent!=null )
  			  	yesMessageEvent();
	        });
        
		
		$rootScope.$on('gatewayApp.messagebox.no.deletebankrelationship', function (event, data) {
			//do nothing
    	});

        
        function onDeleteCustomerTradingBankSuccess(){
        	$scope.deleteRequestId = undefined;
        	$state.go('customer-bank-relationship-report', null, { reload: 'customer-bank-relationship-report' });
        }
        
        function onDeleteCustomerTradingBankError(){
        	$scope.deleteRequestId = undefined;
        }
        
        //withdraw registration request
        
        function withdrawRequest(id){
        	$scope.requestId = id;
        	MessageBox.openMessageBox("Alert", "Do you want to withdraw your bank relationship request?", "YesNo", "withdrawbankrelationrequest");
        	//CustomerBankRelationshipReport.discard({"requestId": id},onWithdrawTradingBankSuccess,onWithdrawTradingBankError);
        }
        
        $rootScope.$on('gatewayApp.messagebox.yes.withdrawbankrelationrequest', function (event, data) {
        	if($scope.requestId!=undefined){
            	CustomerBankRelationshipReport.discard({"requestId": $scope.requestId},onWithdrawTradingBankSuccess,onWithdrawTradingBankError);
        	}
        });
		
		$rootScope.$on('gatewayApp.messagebox.no.withdrawbankrelationrequest', function (event, data) {
			//do nothing
    	});
        
        function onWithdrawTradingBankSuccess(){
        	$scope.requestId = undefined;
        	$state.go('customer-bank-relationship-report', null, { reload: 'customer-bank-relationship-report' });
        	
        }
        
        function onWithdrawTradingBankError(){
        	$scope.requestId = undefined;
        }
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
   
        function close(id){
        	CustomerBankRelationshipReport.close({"id": id},onCloseSuccess,onCloseError);
        }
       
        function onCloseSuccess(){
        	$state.go('customer-bank-relationship-report', null, { reload: 'customer-bank-relationship-report' });
        }
        
        function onCloseError(){
        	
        }
        
        function exportToFile(fileType){

        	vm.baseString = 'Trading_Bank_List_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');
       	  if(( vm.tradingBanks !=null &&  vm.tradingBanks!=undefined) || (vm.pendingAndRejectedBanks !=null &&  vm.pendingAndRejectedBanks!=undefined) ){
       		  if(fileType == 'csv'){
       			  vm.mimeType = 'text/csv'
       			  vm.baseString = vm.baseString+ ".csv";
       			CustomerBankRelationshipReport.csvCustomerBankRelationshipReport({"customerId":vm.globalValues.getOrgId()},onGenerateSuccess);
       		  }else if(fileType == 'excel'){
       			  vm.mimeType = 'application/vnd.ms-excel';
       			  vm.baseString = vm.baseString+ ".xls";
       
       			CustomerBankRelationshipReport.excelCustomerBankRelationshipReport({"customerId":vm.globalValues.getOrgId()},onGenerateSuccess);
       		  }
       	  }
        }
        function onGenerateSuccess(result){
        	
 			  var fileData = result.data;
   			  
   			  var IEfile= new Blob([result.data], {type: vm.mimeType});
 			      if(window.navigator.msSaveOrOpenBlob) {
 			    	 window.navigator.msSaveOrOpenBlob(IEfile, vm.baseString );
 			      }
 			      else
 			    	  {
 	     			  var link = document.createElement("a");   
 	     			  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
 	     			  
 	 			      //var data = btoa(String.fromCharCode.apply(null, bytes)); // get data string from binary array			     
 	     		   	  //link.href = 'data:'+mimeType+';base64,'+ encodeURI(data);
 	     			  
 	 			      var blob = new Blob([bytes], {type: vm.mimeType});
 	 			      link.href =  window.URL.createObjectURL(blob);
 	 			      
 	 			   
 	 			      
 	     		   	  link.download = vm.baseString;
 	     		   	  document.body.appendChild(link);
 	     		   	  link.click();
 	     			  document.body.removeChild(link);
 			    	  }
        	}


        
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
        
        $scope.customFilter = function customFilter(pendingAndRejectedBank){
        	return (pendingAndRejectedBank.customerBankRelationshipStatus.shortName=='PENDINGAPPROVAL');
        }
        
        $scope.customFilter1 = function customFilter1(pendingAndRejectedBank){
        	return (pendingAndRejectedBank.customerBankRelationshipStatus.shortName=='REJECTED');
        }

        function openAnnexure2(customerTradingBankDetailsId){
            CustomerTradingBankDetails.getAnnexure2ForCustomerBankRelationship({customerTradinBankDetailsId: customerTradingBankDetailsId}, onGetAnnexure2Success, onGetAnnexure2Failure);
        }

        function onGetAnnexure2Success(result){
            TacViewForEntitiesUtility.downloadTACForArray(result.data, tacAnnexurefilename + moment().format( 'DD_MMM_YYYY_HH_mm') + '.pdf' );
        }

        function onGetAnnexure2Failure(){
            //do nothing
        }
        
     }
})();