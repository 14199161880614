(function() {
    'use strict';

    angular
        .module('gatewayApp')      
        .controller('ManageUserRestrictionController', ManageUserRestrictionController);

    ManageUserRestrictionController.$inject = ['$scope', '$state', '$rootScope', 'workflowSharedService', 'GlobalValues', 'EntityPermissions', 'ScreenLog', 'entityConstants', 'AlertService', '$filter', 'UserRestriciton', '$uibModalInstance','Workflow'];

    function ManageUserRestrictionController($scope, $state,$rootScope ,workflowSharedService, GlobalValues,EntityPermissions, ScreenLog ,entityConstants ,AlertService,$filter, UserRestriciton, $uibModalInstance,Workflow) {
    	
    	 var vm = this;
         vm.userRestriction={};  
         vm.screenName = entityConstants.ENTITY_SHORT_NAME.USER_RESTRICTION;
         vm.entityConstants = entityConstants;
         vm.screenLog = ScreenLog;
         vm.globalValues = GlobalValues;
         
         $scope.showOkButton = false;
         $scope.showSaveButton = false;
         $scope.showResubmitButton = false;
         $scope.showApproveRejectButton = false;
         $scope.showDiscardButton = false;
         vm.showChangeStatus = true;
         vm.disableEdit = false;
         vm.disableForResubmit= false;         
         $scope.clicked=false;
         
         vm.approveUserRestriction = approveUserRestriction ;
         vm.resubmitUserRestriction =resubmitUserRestriction;
         vm.rejectUserRestriction = rejectUserRestriction;
         vm.discardUserRestriction = discardUserRestriction;
         vm.clear=clear;
         vm.saveUserRestriction = saveUserRestriction;
         
         vm.loggedInUser = vm.globalValues.getUser();
         vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
         vm.mulitCustomerEntityOwnerTypeId = 4;
        
         //vm.possibleRestricitons=null;  
         vm.disableBank=false;
         vm.disableBranch=false;
         vm.disableCustomer=false;     
                 
         //vm.userFilterValue =[];
        // vm.userLookUpName = "";
         vm.userFilterValueCCIL =[];
         vm.userLookUpNameCCIL = "";
         vm.userFilterValueBank =[];
         vm.userLookUpNameBank = "";
         vm.userFilterValueBranch =[];
         vm.userLookUpNameBranch = "";
         vm.userFilterValueCustomer =[];
         vm.userLookUpNameCustomer = "";
         
         
         if($state.current.name=="manage-user-restrictions"){
        	 workflowSharedService.viewFrom="";
         }
         
         logScreenAccess();
         function logScreenAccess(){
            	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
         }         
         // method to enable Radio buttons                  
         function enableRadioButtons(showCCILRadio, showBankRadio, showBranchRadio, showCustomerRadio){
        	
        	vm.showCCILRadio= showCCILRadio;
            vm.showBankRadio = showBankRadio;
            vm.showBranchRadio = showBranchRadio;
            vm.showCustomerRadio  = showCustomerRadio;
        }     
         //method to enable controls
        function showControls(showBankControl, showBranchControl, showCustomerControl, showCcilUser, showBankUser,showBranchUser, showCustomerUser){       	
        	 
            vm.showBankControl = showBankControl;
            vm.showBranchControl = showBranchControl;
            vm.showCustomerControl  = showCustomerControl;
            vm.ccilUser = showCcilUser;
            vm.bankUser= showBankUser;
            vm.branchUser= showBranchUser;
            vm.customerUser = showCustomerUser;
         /*  
            if(vm.showBankControl){
           	 angular.element(document.querySelector("#txtbank")).attr('required','');
           	 angular.element(document.querySelector("#txtbank")).addClass('borderClass');
           	 //border: 1px solid #BB8557;
           }else{
           	 angular.element(document.querySelector("#txtbank")).removeAttr('required');
           }
           if(vm.showBranchControl){
          	 angular.element(document.querySelector("#txtBranch")).attr('required','');
          	 angular.element(document.querySelector("#txtBranch")).addClass('borderClass');
          	 //border: 1px solid #BB8557;
          }else{
          	 angular.element(document.querySelector("#txtBranch")).removeAttr('required');
          }
           if(vm.showCustomerControl){
          	 angular.element(document.querySelector("#txtCustomer")).attr('required','');
          	 angular.element(document.querySelector("#txtCustomer")).addClass('borderClass');
          	 //border: 1px solid #BB8557;
          }else{
          	 angular.element(document.querySelector("#txtCustomer")).removeAttr('required');
          }*/
        }       
        function disableControls(disableBank,disableBranch,disableCustomer){
        	 
        	vm.disableBank=disableBank;
            vm.disableBranch=disableBranch;
            vm.disableCustomer=disableCustomer;
         
        }   	 
        
        // Enable Radio buttons based on logged in user entity
        function activateAllowedEntities(){
        	 vm.possibleRestricitons=null;  
        	 var selectedEntity = "";
        	 if (vm.loggedinEntityOwnerType == 'Clearcorp'){
        		 enableRadioButtons(true,true,true,true);
        		 selectedEntity="Clearcorp";
        	 }
        	 else if (vm.loggedinEntityOwnerType == 'Bank'){
        		 enableRadioButtons(false,true,true,true);
        		 vm.userRestriction.selectedBank = vm.globalValues.getBank();
        		 //vm.disableBank = true;
        		 selectedEntity="Bank";
        	 }
        	 else if (vm.loggedinEntityOwnerType == 'Branch'){
        		 enableRadioButtons(false,false,true,true);
        		 vm.userRestriction.selectedBank = vm.globalValues.getBank();
        		 vm.userRestriction.selectedBranch = vm.globalValues.getBranch();
        		 //vm.disableBranch= true;
        		 selectedEntity="Branch";
        	 }
        	 else if (vm.loggedinEntityOwnerType == 'MultiUserCustomer'){
        		 enableRadioButtons(false,false,false,true);
        		 vm.userRestriction.selectedCustomer = vm.globalValues.getOrganisation();
        		// vm.disableCustomer = true;;
        		 selectedEntity="MultiUserCustomer";
        	 }
        	 if(vm.userRestriction.selectedEntity==null || vm.userRestriction.selectedEntity==undefined || vm.userRestriction.selectedEntity==""){
        		 vm.userRestriction.selectedEntity = selectedEntity;
        	 }
        	 onRadioValueChange(vm.userRestriction.selectedEntity);
        	 
         }
        //check workflow and set the flags accordingly
        if(workflowSharedService.viewFrom == 'userRestriction_checker') {
        	 
        	 	vm.userRestriction  =  angular.fromJson($rootScope.payload);         	 	
         		$scope.showSaveButton = false;
         		$scope.showResubmitButton = false;
         		$scope.showApproveRejectButton=true;
         		$scope.showDiscardButton = false;
         		vm.disableEdit=true;
         		//vm.userFilterValue ='';
               //vm.userLookUpName = '';
         		 vm.userFilterValueCCIL ='';
                 vm.userLookUpNameCCIL = '';
                 vm.userFilterValueBank ='';
                 vm.userLookUpNameBank = '';
                 vm.userFilterValueBranch ='';
                 vm.userLookUpNameBranch = '';
                 vm.userFilterValueCustomer ='';
                 vm.userLookUpNameCustomer = '';
               
         }
         else if(workflowSharedService.viewFrom == 'userRestriction_maker') {
        	 	vm.userRestriction=  angular.fromJson($rootScope.payload); 
        	 	
        	 	$scope.showSaveButton = false;
        	 	$scope.showResubmitButton = false;
         	 	$scope.showApproveRejectButton=false;
         	 	$scope.showDiscardButton = true;
         	 	vm.disableEdit=true;
         	 	 vm.userFilterValueCCIL ='';
                 vm.userLookUpNameCCIL = '';
                 vm.userFilterValueBank ='';
                 vm.userLookUpNameBank = '';
                 vm.userFilterValueBranch ='';
                 vm.userLookUpNameBranch = '';
                 vm.userFilterValueCustomer ='';
                 vm.userLookUpNameCustomer = '';
                
         }
         else if(workflowSharedService.viewFrom == 'userRestriction_resubmit') {
         	
        	 	vm.userRestriction= angular.fromJson($rootScope.payload); 
        	 	vm.screenLog.screenLog(vm.screenName, vm.userRestriction.user.id==null?0:vm.userRestriction.user.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	 	$scope.showSaveButton=false;
        	 	$scope.showResubmitButton = true;
        	 	$scope.showApproveRejectButton=false;
        	 	$scope.showDiscardButton = false;
        	 	vm.disableEdit=false;
        	 	vm.disableForResubmit = true;
         }else if(workflowSharedService.viewFrom == 'userRestriction_auditLog'){
     	 	
        	 	vm.userRestriction=angular.fromJson($rootScope.payload);        		
        	 	$scope.showSaveButton = false;
        	 	$scope.showApproveRejectButton = false;
        	 	$scope.showDiscardButton = false;
        	 	vm.disableEdit=true;
        	 	$scope.showResubmitButton = false;
        	 	$scope.showOkButton = true;
         }else{
        	 	vm.userRestriction={};        		
        	 	$scope.showSaveButton = true;
        	 	$scope.showApproveRejectButton = false;
        	 	$scope.showDiscardButton = false;
        	 	vm.disableEdit=false;
        	 	$scope.showResubmitButton = false;
         } 
        // activate Allowed Entities on pageload
        activateAllowedEntities();
        function onRadioValueChange(selectedEntity){
        	
        	//on radio button change 
        	 if( !(workflowSharedService.viewFrom == 'userRestriction_resubmit' || workflowSharedService.viewFrom == 'userRestriction_maker' 
        		 || workflowSharedService.viewFrom == 'userRestriction_checker'|| workflowSharedService.viewFrom == 'userRestriction_auditLog')){
        		 clearInput();
        	 }else{
        		 UserRestriciton.get({userId : vm.userRestriction.user.id} ,onGetPossibleRestricitonSuccess, onGetPossibleRestricitonFailure );
        	 }
        	 if( vm.loggedinEntityOwnerType == 'Clearcorp' && selectedEntity == 'Clearcorp') {
        		
        		 showControls(false,false,false,true,false,false,false);
        		 disableControls(true,true,true);
        		 vm.userFilterValueCCIL ='';
                 vm.userLookUpNameCCIL = "usersByOrganisationForRestriction";
                 
        	 }else if(vm.loggedinEntityOwnerType == 'Clearcorp' && selectedEntity == 'Bank'){        		 
        		
        		 showControls(true,false,false,false,true,false,false); 
        		 disableControls(false,true,true);
        		 vm.userFilterValueBank=null;
                 vm.userLookUpNameBank = null;
        	 }else if( vm.loggedinEntityOwnerType == 'Clearcorp'  && selectedEntity == 'Branch'){        		 
        		 
        		 showControls(true,true,false,false,false,true,false);  
        		 disableControls(false,false,true);
        		 vm.userFilterValueBranch=null;
                 vm.userLookUpNameBranch = null;
        	 }else if( vm.loggedinEntityOwnerType == 'Clearcorp'  && selectedEntity == 'MultiUserCustomer'){
        		 
        		 showControls(true,true,true,false,false,false,true);
        		 disableControls(false,false,false);
        		 //vm.userFilterValue =['MultiUserCustomer', '', '' ,vm.userRestriction.selectedCustomer.id ];
        		 vm.userFilterValueCustomer=null;
                 vm.userLookUpNameCustomer = null;
        	 }
        	 else if(  vm.loggedinEntityOwnerType == 'Bank'  && selectedEntity == 'Branch'){ 
        	 
        		 showControls(true,true,false,false,false,true,false);  
        		 disableControls(true,false,true);
        		 vm.userFilterValueBranch=null;
                 vm.userLookUpNameBranch = null;
        	 }else if(  vm.loggedinEntityOwnerType == 'Bank' && selectedEntity == 'MultiUserCustomer'){
        		 
        		 showControls(true,true,true,false,false,false,true);
        		 disableControls(true,false,false);
        		 //vm.userFilterValue =['MultiUserCustomer', '', '' ,vm.userRestriction.selectedCustomer.id ];
        		 vm.userFilterValueCustomer=null;
                 vm.userLookUpNameCustomer = null;
        	 }
        	 else if(vm.loggedinEntityOwnerType == 'Bank' && selectedEntity == 'Bank'){
        		 
        		 showControls(true,false,false,false,true,false,false);
        		 disableControls(true,true,true);
        		 vm.userFilterValueBank ='';
                 vm.userLookUpNameBank = "usersByOrganisationForRestriction";
        	 }else if(vm.loggedinEntityOwnerType == 'Branch' && selectedEntity == 'Branch'){
        		 
        		 showControls(false,true,false,false,false,true,false);
        		 disableControls(true,true,true);
        		 vm.userFilterValueBranch ='';
                 vm.userLookUpNameBranch = "usersByOrganisationForRestriction";
        	 }else if(vm.loggedinEntityOwnerType == 'Branch' && selectedEntity == 'MultiUserCustomer'){
        		 
        		 showControls(false,true,true,false,false,false,true);
        		 disableControls(true,true,false);
        		 //vm.userFilterValue =['MultiUserCustomer', '', '' ,vm.userRestriction.selectedCustomer.id ];
        		 vm.userFilterValueCustomer=null;
                 vm.userLookUpNameCustomer = null;
        	 }else if(vm.loggedinEntityOwnerType == 'MultiUserCustomer' && selectedEntity == 'MultiUserCustomer'){
        		 
        		 showControls(false,false,true,false,false,false,true);
        		 disableControls(true,true,true);
        		 vm.userFilterValueCustomer ='';
                 vm.userLookUpNameCustomer = "usersByOrganisationForRestriction";
        	 }
        	
        	 //setUserFileterValue();
         }
         function onUserSelection(selectedUSer){
        	 
        	 if(selectedUSer!=null && selectedUSer !=undefined &&  selectedUSer!=""){
        		 if( !(workflowSharedService.viewFrom == 'userRestriction_resubmit' || workflowSharedService.viewFrom == 'userRestriction_maker' 
        			 || workflowSharedService.viewFrom == 'userRestriction_checker' || workflowSharedService.viewFrom =='userRestriction_auditLog')){
        		
        			 
        			 UserRestriciton.get({userId : selectedUSer.id} ,onGetPossibleRestricitonSuccess, onGetPossibleRestricitonFailure );
        		 }
        		 
        	 }
        	
         }
         function onGetPossibleRestricitonSuccess(result){
        	 vm.userRestriction.existingRestrictionStatus = result.currentStatus;
        	 vm.possibleRestricitons = result.availableStatus;
        	 if(vm.userRestriction.existingRestrictionStatus.shortName == 'TERMINATED'  || vm.userRestriction.existingRestrictionStatus.shortName == 'DELINKED'){
    			 vm.showChangeStatus = false;
    			 //vm.userRestriction.newRestrictionApplied = null;
    		 }else{
    			 vm.showChangeStatus = true;
    		 }
         }
         function onGetPossibleRestricitonFailure(error){
        	//errro handling
         }
         function saveUserRestriction(){
        	 var orgId = GlobalValues.getOrgId();
        	 vm.userRestriction.user.organisation=null;
        	 UserRestriciton.updateUserStatus( { "orgId": orgId },vm.userRestriction, onSaveSuccess, onSaveError ) ;
         }
         function onSaveSuccess (result) {
        	 //$uibModalInstance.close(result); 
        	 clearInput();
        	 vm.userRestriction.selectedEntity='';
        	 activateAllowedEntities();
        
        	
        	 
         }

         function onSaveError () {
        	 vm.userRestriction.selectedEntity='';
        	 activateAllowedEntities();
        	 clicked=false;
         }
         $scope.$watch('vm.userRestriction.selectedEntity', function(value){
        	 onRadioValueChange(value);
        	 
         });       
         $scope.$watch('vm.userRestriction.user', function(value){
        	 onUserSelection(value);
        	 
         });
         $scope.$watch('vm.userRestriction.selectedBank', function(value){
        	 
        	 setUserFileterValue();
         });
         $scope.$watch('vm.userRestriction.selectedBranch', function(value){
        	
        	 setUserFileterValue();
         });
         $scope.$watch('vm.userRestriction.selectedCustomer', function(value){
        	 
        	 setUserFileterValue();
         });
       
         function setUserFileterValue(){
        	 if( !(workflowSharedService.viewFrom == 'userRestriction_resubmit' || workflowSharedService.viewFrom == 'userRestriction_maker' 
        		 || workflowSharedService.viewFrom == 'userRestriction_checker' || workflowSharedService.viewFrom == 'userRestriction_auditLog')){
        		 vm.userRestriction.user = null;
        	 }
        	 if(vm.loggedinEntityOwnerType == 'Clearcorp' && vm.userRestriction.selectedEntity == 'Bank'
     		 	&& !(vm.userRestriction.selectedBank ==null || vm.userRestriction.selectedBank==undefined || vm.userRestriction.selectedBank=="" )){
        		 vm.userFilterValueBank ='Bank'+'~~'+ vm.userRestriction.selectedBank.id;
        		 vm.userLookUpNameBank = "userForRestriciton";
        	 }else if( ( vm.loggedinEntityOwnerType == 'Clearcorp' || vm.loggedinEntityOwnerType == 'Bank') && vm.userRestriction.selectedEntity == 'Branch'
     		 	&& !(vm.userRestriction.selectedBank ==null || vm.userRestriction.selectedBranch==undefined || vm.userRestriction.selectedBranch=="" )){
        		 
        		 vm.userFilterValueBranch ='Branch'+'~~'+ ''+ '~~'+ vm.userRestriction.selectedBranch.id;
        		 vm.userLookUpNameBranch = "userForRestriciton";
        	 }else if( (vm.loggedinEntityOwnerType == 'Clearcorp' || vm.loggedinEntityOwnerType == 'Bank' || vm.loggedinEntityOwnerType == 'Branch' ) && vm.userRestriction.selectedEntity == 'MultiUserCustomer' 
        		 &&  !(vm.userRestriction.selectedCustomer ==null || vm.userRestriction.selectedCustomer==undefined || vm.userRestriction.selectedCustomer=="" )){
         	 	//vm.userFilterValue ='MultiUserCustomer'+'~~'+ ''+ '~~'+ ''+ '~~'+vm.userRestriction.selectedCustomer.id ;
        		vm.userFilterValueCustomer =''+'~~'+ ''+ '~~'+ ''+ '~~'+vm.userRestriction.selectedCustomer.id ;
         	 	vm.userLookUpNameCustomer = "userForRestriciton";
          	}
         }
         function clearInput(){
        	 vm.userRestriction.existingRestrictionStatus = null;
        	 vm.userRestriction.newRestrictionApplied = null;
        	 vm.userRestriction.modifiedBy = null;
        	 vm.userRestriction.user = null;
        	 vm.userRestriction.id = null;
        	 vm.possibleRestricitons=null;
        	 if( vm.loggedinEntityOwnerType == 'Clearcorp' ) {
        		 vm.userRestriction.selectedBank = null;
        		 vm.userRestriction.selectedBranch = null;
        		 vm.userRestriction.selectedCustomer = null;
        	 }else if(vm.loggedinEntityOwnerType =='Bank'){
        		 
        		 vm.userRestriction.selectedBranch = null;
        		 vm.userRestriction.selectedCustomer = null;
        	 }else if(vm.loggedinEntityOwnerType =='Branch'){
        		
        		 vm.userRestriction.selectedCustomer = null;
        	 }
        	 //vm.userFilterValue =null;
             //vm.userLookUpName = "";            
        	 vm.userFilterValueCCIL = null;
             vm.userLookUpNameCCIL = '';
             vm.userFilterValueBank = null;
             vm.userLookUpNameBank = '';
             vm.userFilterValueBranch = null;
             vm.userLookUpNameBranch = '';
             vm.userFilterValueCustomer = null;
             vm.userLookUpNameCustomer = '';
         }
         
         function clear() {
        	 workflowSharedService.viewFrom="";
             $uibModalInstance.dismiss('cancel');
         }
         function resubmitUserRestriction() {
        	 vm.userRestriction.user.organisation=null;
        	UserRestriciton.resubmit({"taskId" : $rootScope.taskID}, vm.userRestriction, onResubmitSuccess, onResubmitError);
         	$uibModalInstance.dismiss('cancel');
         }
         
         function onResubmitSuccess(result) {
         	$rootScope.removeRow($rootScope.taskID);
         }        
         function onResubmitError(error) {
        	 clicked=false;
         }
         
         function rejectUserRestriction() { Workflow.rejectTask($rootScope.taskID); }
         
         function discardUserRestriction() {
        	UserRestriciton.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
         	$uibModalInstance.dismiss('cancel');	
         }
         
         function onDiscardSuccess(result) {
         	
         	$rootScope.removeRow($rootScope.taskID);        	
         }        
         function onDiscardError(error) {
         	
         }

         function approveUserRestriction() {  
        	UserRestriciton.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
         	$uibModalInstance.dismiss('cancel');     	
         }
         
         function onApproveSuccess(result) {
         	$rootScope.removeRow($rootScope.taskID);        	
         }        
         function onApproveError(error) {
         	
         }
         
         
    }
   
   
})();
  