(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'changeemail'
	})

	.controller('ChangeEmailController', ChangeEmailController);

	ChangeEmailController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'ChangeEmail', 'GlobalValues',
			'EntityPermissions', 'ScreenLog', '$stateParams',
			'$uibModalInstance', '$rootScope', 'MessageBox', 'CustomerRegistration', 
			'globalValue', 'AlertService'];

	function ChangeEmailController($scope, $state, workflowSharedService,
			ChangeEmail, GlobalValues, EntityPermissions, ScreenLog,
			$stateParams, $uibModalInstance, $rootScope, MessageBox, CustomerRegistration, 
			globalValue, AlertService) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.userprofile = {};
		vm.user = {};
		vm.currentEmail = $stateParams.email;
		vm.userId = $stateParams.userId;
		vm.customerId = $stateParams.customerId;
		vm.mode = $stateParams.mode;
		vm.sendActivationMail = sendActivationMail;
		vm.updateNewEmail = updateNewEmail;
		vm.checkPassword = checkPassword;
		vm.updateEmail = updateEmail;
		vm.showChangeEmail = false;
		vm.clicked = false;
		vm.password=null;
		vm.allowedPasswordCharacters=/^[!@#^&*()\-_.:;a-zA-Z0-9]/;
		vm.clear = clear;

		vm.otpLength = globalValue.defaultOTPLength;
		vm.visibleOTPEmail = false;
		vm.isEmailVerified = false;
	    vm.skipOTPEmail = false;
   		vm.showGenerateOTPEmailButton = false;
        vm.disableGenerateOTPEmail = true;
        vm.checkEmailOTP = false;
        vm.invalidEmailOtp = false;
		vm.emailOtp = null;
		vm.generateOTPEmail = generateOTPEmail;
		vm.wrongOTPAttemptCount = 0;
		vm.resendOTPAttemptCount = 0;
		vm.otpVerifiedId='';
		if(vm.mode!='user' && (globalValue.skipOTPVerification == 'No' || globalValue.skipOTPVerification == 'no'|| globalValue.skipOTPVerification == 'NO')){
			vm.skipOTPEmail = false;
    	}else{
    		vm.skipOTPEmail = false;
    	}

		console.log(vm.mode);
		console.log(vm.customerId);
		function updateEmail() {
			
				$rootScope.email = vm.userProfile.confirmEmail;
				$rootScope.otpVerifiedId = vm.otpVerifiedId;
				$scope.$emit('changeEmailRefresh');
				$uibModalInstance.close();
			
		}

		function sendActivationMail() {
			
			if(vm.mode == 'customerinfo'){
				ChangeEmail.sendActivationMailForCustomer({
					"customerId" : vm.customerId,
					"newEmail" : vm.userProfile.confirmEmail,
					"email" : vm.currentEmail
				}, onEmailChangeSuccess, onEmailChangeError)
			}else{
				ChangeEmail.sendActivationMail({
					"userId" : vm.userId,
					"newEmail" : vm.userProfile.confirmEmail,
					"email" : vm.currentEmail
				}, onEmailChangeSuccess, onEmailChangeError)
			}
				
				
			
		}

		function checkPassword() {
			if(vm.mode == 'customerinfo'){
				var userCredentialsForCustomer = {};
				userCredentialsForCustomer.userId = vm.userId;
				userCredentialsForCustomer.password = vm.password;
				userCredentialsForCustomer.customerId = vm.customerId;
				ChangeEmail.checkPasswordForCustomer(userCredentialsForCustomer, onSaveSuccess, onSaveError);	


			}else {
				var userCredentials = {};
				userCredentials.userId = vm.userId;
				userCredentials.password = vm.password;
				ChangeEmail.checkPassword(userCredentials, onSaveSuccess, onSaveError);
				}
			
		}

		function onSaveSuccess(result) {
			// $uibModalInstance.close(result);
			 if (result.hasOwnProperty('IsPendingEmailChange') && result.IsPendingEmailChange != null && result.IsPendingEmailChange == true) {
				 MessageBox.openMessageBox("Alert", "Change of email request is already pending for verification. If you proceed with change of email, then the pending email change request will be discarded automatically. Do you still want to proceed?", "ProceedCancel", "changeemailrequest");
			 }else{
				 vm.showChangeEmail = true;
			 }
			//vm.showChangeEmail = true;
			
		}

		 $scope.$watch('vm.userProfile.newEmail', function(value){
	        	if(value!= undefined && value!=null && value!=''){
	    			vm.visibleOTPEmail = false;
	        		vm.emailOtp = null;
	        		vm.isEmailVerified = false;
	        		if(value.length>=1){
	                	if(!vm.skipOTPEmail){
	                		vm.showGenerateOTPEmailButton = true;
	                	}
	                	if((value.indexOf('@')>-1) && (value.indexOf('.')>-1)){
	                        vm.disableGenerateOTPEmail = false;
	                	}else{
	                		vm.disableGenerateOTPEmail = true;
	                	}
	                	
	        		}else{
	        			vm.disableGenerateOTPEmail = true;
	                	vm.showGenerateOTPEmailButton = false;
	        		}
	        	}else{
	        		vm.showGenerateOTPEmailButton = false;
	        		vm.emailOtp = null;
	         	}
	      })
	      
	    $scope.otpEmailInput = {
				size : globalValue.defaultOTPLength,
				type : "password",
				onDone : function(value) {
					vm.emailOtp = value;
				},
				onChange : function(value) {
					if(value!= undefined && value!=null){
		        		vm.checkEmailOTP = false;
		        		vm.isEmailVerified = false;
		        		if(value.length==vm.otpLength){
		        			vm.checkEmailOTP = true;
		        			vm.invalidEmailOtp = false;
		            	/* 	CustomerRegistration.verifyOTPEmail({email: vm.userProfile.confirmEmail, otp: vm.emailOtp}, function(result){
		            			vm.otpVerification = result.isVerified;
		            			if(vm.otpVerification){
		            				vm.isEmailVerified = true;
		            				vm.invalidEmailOtp = false;
		            			}else{
		            				vm.wrongOTPAttemptCount = vm.wrongOTPAttemptCount + 1;
		            				if(vm.wrongOTPAttemptCount>=globalValue.maximumOTPWrongAttemptCount){
				        				MessageBox.openMessageBox('Alert', 'Maximum wrong OTP attempt count exceeds! Session expired. You will be redirected to login page...!', 'Ok', 'invalidotpchangeemail');
				        			}
		            				vm.invalidEmailOtp = true;
		            				vm.isEmailVerified = false;
		            			}
		            		}); */
							CustomerRegistration.verifyOTPEmail({email: vm.userProfile.confirmEmail, otp: vm.emailOtp},onemailChangeSuccess,onemailChangefailed);

		        		}else{
		        			vm.checkEmailOTP = false;
		        		}
		        	}
				}
		};
        function onemailChangeSuccess(result){
		if(result.isVerified=='VERIFIED'){
			vm.isEmailVerified = true;
		    vm.invalidEmailOtp = false;
			vm.otpVerifiedId=result.otpVerifiedId;
		}
	}
		function onemailChangefailed(){
			vm.wrongOTPAttemptCount = vm.wrongOTPAttemptCount + 1;
			if(vm.wrongOTPAttemptCount>=globalValue.maximumOTPWrongAttemptCount){
				MessageBox.openMessageBox('Alert', 'Maximum wrong OTP attempt count exceeds! Session expired. You will be redirected to login page...!', 'Ok', 'invalidotpchangeemail');
			}
			vm.invalidEmailOtp = true;
			vm.isEmailVerified = false;
		}
		 $rootScope.$on('gatewayApp.messagebox.ok.invalidotpchangeemail', function (event, data) {
			 $scope.$emit('gatewayApp.messagebox.ok.sessionexpired');        	      
		 });
	        
		 $rootScope.$on('gatewayApp.messagebox.clear.invalidotpchangeemail', function (event, data) {
			 $scope.$emit('gatewayApp.messagebox.ok.sessionexpired');      	      
		 });

	    function generateOTPEmail(){
        	vm.disableGenerateOTPEmail = true;
        	vm.showGenerateOTPEmailButton = false;
        	ChangeEmail.sendOTPVerificationEmail({email: vm.currentEmail, newEmail: vm.userProfile.confirmEmail,userId:vm.userId}, onOtpEmailSendSuccess, onOtpEmailSendError);
        }

		 function onOtpEmailSendSuccess(result){
        	AlertService.info("OTP email has been sent to your email");
        	vm.visibleOTPEmail = true;
    		vm.emailOtp = null;
    		vm.isEmailVerified = false;
    		vm.wrongOTPAttemptCount = 0;
    		vm.resendOTPAttemptCount = 0;
        }
        
        function onOtpEmailSendError(){
        	AlertService.error("Error occurred while generating OTP email");
        	vm.disableGenerateOTPEmail = false;
        	vm.showGenerateOTPEmailButton = true;
        }
		
        vm.resendOTPEmail = function resendOTPEmail(){
        	if(vm.resendOTPAttemptCount>=globalValue.maximumOTPResendAttemptCount){
        		//AlertService.error("Maximum resend OTP count exceeds!<br>Please try again later.")
        		MessageBox.openMessageBox('Alert', 'Maximum resend OTP count exceeds! Please try again.', 'Ok', 'resendotpchangeemail');
        	}else{
            	ChangeEmail.sendOTPVerificationEmail({email: vm.currentEmail, newEmail: vm.userProfile.confirmEmail}, onResendOtpEmailSuccess, onResendOtpEmailError);
        	}
        }

        $rootScope.$on('gatewayApp.messagebox.ok.resendotpchangeemail', function (event, data) {
        	clear();      	      
		});
	        
		$rootScope.$on('gatewayApp.messagebox.clear.resendotpchangeemail', function (event, data) {
			clear();      	      
		});

        function onResendOtpEmailSuccess(result){
        	vm.wrongOTPAttemptCount = 0;
        	vm.resendOTPAttemptCount = vm.resendOTPAttemptCount + 1; 
        	AlertService.info("OTP has been resent to your email");
        }
        
        function onResendOtpEmailError(){
        	
        }
        
		$rootScope.$on('gatewayApp.messagebox.proceed.changeemailrequest', function (event, data) {
			vm.showChangeEmail = true;
			if(vm.mode == 'customerinfo'){
				ChangeEmail.activatePendingEmailChangeRequestForCustomer({"customerId" : vm.customerId}, onCancelSuccess, onCancelError);
			}else {
				ChangeEmail.activatePendingEmailChangeRequest({"userId" : vm.userId}, onCancelSuccess, onCancelError);	
			}
			
    	});
		
		$rootScope.$on('gatewayApp.messagebox.cancel.changeemailrequest', function (event, data) {
			vm.showChangeEmail = false;
			$uibModalInstance.close();
    	});
		
		function onSaveError() {

			vm.clicked = false;
		}
		
		function onCancelSuccess() {

			//vm.clicked = false;
		}
		
		function onCancelError() {

			//vm.clicked = false;
		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function onEmailChangeSuccess(result) {

			$uibModalInstance.close(result);

		}

		function onEmailChangeError() {

			vm.clicked = false;
		}
		
		function updateNewEmail(){
			if(vm.mode == 'customerinfo'){
				ChangeEmail.updateCustomerEmail({
					"customerId" : vm.customerId,
					"newEmail" : vm.userProfile.confirmEmail,
					"email" : vm.currentEmail,
					"otpVerifiedId":vm.otpVerifiedId

				}, onEmailChangeSuccess, onEmailChangeError)
			}else{
				ChangeEmail.updateUserEmail({
					"userId" : vm.userId,
					"newEmail" : vm.userProfile.confirmEmail,
					"email" : vm.currentEmail,
					"otpVerifiedId":vm.otpVerifiedId
				}, onEmailChangeSuccess, onEmailChangeError)
			}
		}
		
		 /*
         * allow only 30 characters in password and only some special characters
         */
		 $scope.$watch('vm.password', function(newVal, oldVal) {
			 if(newVal!=null||newVal!=undefined)
	        	{
			       	  if(newVal.length > 30) {       
			       		  vm.password = oldVal;
			       	  }
			       	  if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
			   		  {
			       		  vm.password = oldVal;
			   		  }
	        	}
	       	});

	}
})();
