(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("CSPLPagination", CSPLPagination);
       
    CSPLPagination.$inject = ['$rootScope'];

    function CSPLPagination ($rootScope) {
    	
		var pagableValuesCollection = {}
		var itemsPerPage = 10;
		var pagedValue = {};
		
    	var service = {
    			initialisePagination: initialisePagination,
    			getPagedValues: getPagedValues,
    			getItemsPerPage: getItemsPerPage,
    			setPage:setPage,
    			getTotalRecordCount: getTotalRecordCount
    	};
    	return service;
    	
    	function getItemsPerPage(paginationName){
    		return itemsPerPage;
    	}
    	
    	function getTotalRecordCount(paginationName){
    		return pagableValuesCollection[paginationName].length;
    	}
    	
    	function getPagedValues(paginationName){
    		return pagedValue[paginationName].values;
    	}
    	
    	function initialisePagination(paginationName, pagableValues){
    		pagableValuesCollection[paginationName] = pagableValues;
    	}
    	
    	function setPage(paginationName, currentPage){
      	  var begin = (currentPage - 1) * itemsPerPage;
    	  var end = begin + itemsPerPage;

	       pagedValue[paginationName] = {
	    	    values: pagableValuesCollection[paginationName].slice(begin, end)
	    	  }
	    };
    }
})();