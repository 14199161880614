(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'orderStatusReport'
        })
        
        .controller('SettlementReportController', SettlementReportController);

    SettlementReportController.$inject = ['$scope', '$state', 'SettlementReport', 'GlobalValues', 'ScreenLog','entityConstants','$timeout','DateUtils', 'DefaultMarket','filterBy', '$filter','$window','systemSetting','AlertService', 'StaticLookUp'];

    function SettlementReportController($scope, $state, SettlementReport , GlobalValues, ScreenLog,entityConstants,$timeout,DateUtils,DefaultMarket, filterBy, $filter, $window, systemSetting,AlertService, StaticLookUp) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRADESTOBESETTLEDREPORT;
        $scope.noOfRecordsPerPage = 3;
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        DefaultMarket.marketInstruments(function(result){
        	vm.instrumentTypes = result;
        });
		
		StaticLookUp.getEventTypeList(function(result){
			vm.eventTypes = result;
		});
		vm.clicked=false;
        vm.ExpandOrCollapse = true;
        vm.expandBuyTrades = true;
        vm.expandSellTrades = true;
        vm.expandPropBuyTrades = true;
        vm.expandPropSellTrades = true;
        vm.todayDate = new Date();
        vm.businessDate = DateUtils.convertLocalDateFromServer(vm.globalValues.getSystemSettings().businessDate);;
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.settlementReportSummaryDTOList = [];
        var  index = 0;

        vm.queryfromDate =  null;
        vm.querytoDate = null;

        if(systemSetting != null && systemSetting != undefined){
        	vm.currentDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);
        	vm.fromSetDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);
        	vm.toSetDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);
        	
        }
        	

        if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
        	 vm.customer = vm.globalValues.getCustomer();
        }
        if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
        	vm.bank = vm.globalValues.getBank();
        }
        if(vm.entityOwnerType=='Branch'){
        	vm.branch = vm.globalValues.getBranch();
        }
        vm.filterBy = filterBy;
        
        angular.forEach(vm.filterBy, function(value){
        	if(value.shortName == 'AllTrade'){
        		vm.selectedFilterBy = value;
        	}
        })
        vm.getSettlementReport = getSettlementReport;
        vm.exportToFile = exportToFile;

        logScreenAccess();
        vm.viewFor = 'CUSTOMER';

        function getSettlementReport() {
        	var bankId = 0;
        	var branchId = 0;
        	var customerId = 0;
        	var instrumentId = 0;
			var tradeTypeId = 0;
        	var customFilter = 'ALL';
        	var viewFor = null;

        	vm.settlementReportSummaryDTOList = [];

        	if(moment(vm.fromSetDate).isAfter(vm.toSetDate,'day')){
        		AlertService.error($filter('translate')('gatewayApp.settlementReport.greaterFromDate'));
        		vm.valid=false;
        	}
        	else{
				vm.clicked=true;
        		if(vm.bank!=null){
        			bankId = vm.bank.id;
        		}
        		if(vm.branch!=null){
        			branchId = vm.branch.id;
        		}
        		if(vm.customer!=null){
        			customerId = vm.customer.id;
        		}
        		if(vm.instrument!=null){
        			instrumentId = vm.instrument.id;
        		}
				if(vm.tradeType!=null){
        			tradeTypeId = vm.tradeType.id;
        		}
        		if(vm.selectedFilterBy != null && vm.selectedFilterBy != undefined){
        			customFilter = vm.selectedFilterBy.shortName;
        		}

        		if(vm.entityOwnerType == 'Clearcorp'){
        			viewFor = vm.viewFor;
        		}
        		else if(vm.entityOwnerType == 'SingleUserCustomer' || vm.entityOwnerType == 'MultiUserCustomer'){
        			viewFor = 'Customer';
        		}

        		else{
        			viewFor = vm.entityOwnerType;
        		}

        		SettlementReport.getSettlementReport({bankId: bankId, branchId: branchId, customerId: customerId, instrumentId: instrumentId, tradeTypeId: tradeTypeId,
        			entityOwnerType: vm.entityOwnerType, customFilter : customFilter, viewFor : viewFor, fromDate: DateUtils.convertLocalDateToServer(vm.fromSetDate),toDate : DateUtils.convertLocalDateToServer(vm.toSetDate)}, onGetSetttlementReportSuccess, onGetSetttlementReportFailure)

        	}
        }
           
       
       function  onGetSetttlementReportSuccess(result){
    	   
    	   vm.queryfromDate = DateUtils.convertLocalDateToServer(vm.fromSetDate);
    	   vm.querytoDate = DateUtils.convertLocalDateToServer(vm.toSetDate);
    	   
    	   index  = 1;
    	   
    	   if(result.length > 0 && result[0].settlementReportDetailsDTOList.length > 0 && result[0].settlementReportDetailsDTOList.length > 0 &&
    			   result[0].settlementReportDetailsDTOList[0].tradeDTOList.length > 0){
    		   
    		    $filter('orderBy')(result[0].settlementReportDetailsDTOList[0].tradeDTOList, 'tradeSequenceNumber');
    		    
    		   angular.forEach(result[0].settlementReportDetailsDTOList[0].tradeDTOList, function(value){
    			  
    			   value.index = index++;
    		   })
    	   }
    	   
    	   if(result.length > 0 && result[0].settlementReportDetailsDTOList.length > 0 && result[0].settlementReportDetailsDTOList.length > 1 &&
    			   result[0].settlementReportDetailsDTOList[1].tradeDTOList.length > 0){
    		   
    		    $filter('orderBy')(result[0].settlementReportDetailsDTOList[1].tradeDTOList, 'tradeSequenceNumber');
    		   
    		   angular.forEach(result[0].settlementReportDetailsDTOList[1].tradeDTOList, function(value){
    			   value.index = index++;
    		   })
    	   }
    	   
    	   index = 1;
    	   
    	   if(result.length > 1 && result[1].settlementReportDetailsDTOList.length > 0 && result[1].settlementReportDetailsDTOList.length > 0 
    			   && result[1].settlementReportDetailsDTOList[0].tradeDTOList.length > 0){
    		   
    		    $filter('orderBy')(result[1].settlementReportDetailsDTOList[0].tradeDTOList, 'tradeSequenceNumber');
    		    
    		   angular.forEach(result[1].settlementReportDetailsDTOList[0].tradeDTOList, function(value){
    			   value.index = index++;
    		   })
    	   }
    	   
 if(result.length > 1 && result[1].settlementReportDetailsDTOList.length > 0 && result[1].settlementReportDetailsDTOList.length > 1 
		 && result[1].settlementReportDetailsDTOList[1].tradeDTOList.length > 0){
    		   
	  $filter('orderBy')(result[1].settlementReportDetailsDTOList[1].tradeDTOList, 'tradeSequenceNumber');
	  
    		   angular.forEach(result[1].settlementReportDetailsDTOList[1].tradeDTOList, function(value){
    			   value.index = index++;
    		   })
    	   }
    	   
  		 
    	   vm.settlementReportSummaryDTOList = result;
		   vm.clicked=false;
    	
					
       }
       
       function onGetSetttlementReportFailure(){
		vm.clicked=false;
       }
       
       function logScreenAccess(){
    	   ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       vm.checkForNoRecords = checkForNoRecords
       function checkForNoRecords(){
    	   
    	   
    	   if((vm.settlementReportSummaryDTOList.length > 0 && vm.settlementReportSummaryDTOList[0] != null && vm.settlementReportSummaryDTOList != undefined
    			   && vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList.length > 0
    			   && vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[0].tradeDTOList.length > 0)
    			   
    	   ||
    	   (vm.settlementReportSummaryDTOList.length > 0 && vm.settlementReportSummaryDTOList[0] != null && vm.settlementReportSummaryDTOList != undefined
    			   && vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList.length > 1
    			   && vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[1].tradeDTOList.length> 0)
    			   
    	   ||
    	   (vm.settlementReportSummaryDTOList.length > 0 && vm.settlementReportSummaryDTOList[1] != null && vm.settlementReportSummaryDTOList != undefined
    			   && vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList.length > 0
    			   && vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[0].tradeDTOList.length > 0)
    	   ||
    	   (vm.settlementReportSummaryDTOList.length > 1 && vm.settlementReportSummaryDTOList[0] != null && vm.settlementReportSummaryDTOList != undefined
    			   && vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList.length > 1
    			   && vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[1].tradeDTOList.length > 0)){
    		   
    		   return false;
    		   
    	   }
    	   else{
    		   return true;
    	   }
    	 
    	 /*  if(vm.settlementReportSummaryDTOList.length > 0){
    		   
    		   if(  (vm.settlementReportSummaryDTOList[0] == null || vm.settlementReportSummaryDTOList[0] == undefined ) 
        			   ||( (vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[0] == null 
        					   || vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[0] == undefined 
        					   ||  vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[0].tradeDTOList.length==0)
        					  && (
        							  vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[1] == null 
        	    					   || vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[1] == undefined 
        	    					   ||  vm.settlementReportSummaryDTOList[0].settlementReportDetailsDTOList[1].tradeDTOList.length==0)
        			   ) &&
        			   (vm.settlementReportSummaryDTOList[1] == null || vm.settlementReportSummaryDTOList[1] == undefined ) 
        			   ||( (vm.settlementReportSummaryDTOList[1] != null && vm.settlementReportSummaryDTOList[1] != undefined && vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[0] == null 
        					   || vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[0] == undefined 
        					   ||  vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[0].tradeDTOList.length==0)
        					  && (
        							  vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[1] == null 
        	    					   || vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[1] == undefined 
        	    					   ||  vm.settlementReportSummaryDTOList[1].settlementReportDetailsDTOList[1].tradeDTOList.length==0)
        			   )
          			   
          	   ){
        		   return true;
        	   }else {return false;}
    		   
    	   }*/
    	 
       }
       
       
       $scope.$watch('vm.viewFor', function(value){
    	   if(vm.entityOwnerType=='Clearcorp'){
          	 vm.customer = null;
          	 vm.bank = null;
          	 vm.branch = null;
          }
    	   
       })
       
       
       function exportToFile(fileType){
	      	
	      	 vm.fileType = fileType;
	      	  
	      	 
	      	  var viewFor = '';
	      	 vm.valid=true;
	      	
	 		   var bankName = 'ALL BANKS';
	 		   var branchName= 'ALL BRANCHES'
	 		  
	 		  
	 		   var customerName = 'ALL CUSTOMERS';
	 		   var customFilter = 'ALL';
	 		   var instrument = 'ALL INSTRUMENTS';
			   var tradeType = 'ALL TRADE TYPES';
	 		 
	 		   var branchCode = null;
	 		   var customerCode = null;
	 		   
	 		   if(vm.bank != undefined && vm.bank != null & vm.bank != ''){
	 			  
	 			  bankName = vm.bank.bankName;
	 		   }
	 		  if(vm.branch != undefined && vm.branch != null && vm.branch != ''){
	 			 branchName = vm.branch.branchName;
	 			 branchCode = vm.branch.ifscCode;
	 			
	 		   }
	 		 if(vm.customer != undefined && vm.customer != null && vm.customer != ''){
	 			customerCode = vm.customer.customerCode;
	 			
	 			customerName = vm.customer.customerName;
			   }
	 		 if(vm.instrument != null && vm.instrument != undefined){
	 			instrument = vm.instrument.instrumentCode;
	 			
	 		 }
	 		 if(vm.tradeType != null && vm.tradeType != undefined){
				tradeType = vm.tradeType.name;
			 }
			 
	 		 if(vm.selectedFilterBy != null && vm.selectedFilterBy != undefined){
	 			 customFilter = vm.selectedFilterBy.shortName;
	 		 }
	 		 
	 		 if(vm.entityOwnerType == 'Clearcorp' && vm.viewFor != null && vm.viewFor != undefined && vm.viewFor != ''){
	 			 viewFor = vm.viewFor;
	 		 }
	      	  if( vm.settlementReportSummaryDTOList !=null &&  vm.settlementReportSummaryDTOList!=undefined && vm.settlementReportSummaryDTOList.length > 0){
	      		  if(vm.fileType == 'csv'){
	      			  
	      			  
	      			SettlementReport.exportDataCSV({bankName : bankName, branchCode : branchCode, customerCode : customerCode,
	 					customerName : customerName, branchName : branchName, customFilter : customFilter, instrument : instrument, tradeType: tradeType,
						viewFor : viewFor, fromDate: vm.queryfromDate,toDate : vm.querytoDate}, vm.settlementReportSummaryDTOList, onExportSuccess);
	      			
	      		  }else if(vm.fileType == 'excel'){
	      			  
	      			
	      			SettlementReport.exportData({bankName : bankName, branchCode : branchCode, customerCode : customerCode,
	 					customerName : customerName, branchName : branchName, customFilter : customFilter, instrument : instrument, tradeType: tradeType,
						viewFor : viewFor, fromDate: vm.queryfromDate,toDate : vm.querytoDate}, vm.settlementReportSummaryDTOList, onExportSuccess);
	      		  }
	      		
	 			 
	 			 }
	      		  
	      	  }
       
       function onExportSuccess(result){
    	   var mimeType= '';
    	   
    	   var baseString =   'Trades_To_Be_Settled_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   
    	   
    	   if(vm.fileType == 'csv'){
   			  
   			  mimeType = 'text/csv'
   			  baseString = baseString+ ".csv";  
    	   }
    	   else{
    		   mimeType = 'application/vnd.ms-excel';
     			baseString = baseString+ ".xls";  
    	   }
    	   
    	   var fileData = null;
    	   
    	   fileData = result.data;
			  
 			var IEFile = new Blob([result.data], {type: mimeType});
     		
             
             if(window.navigator.msSaveOrOpenBlob) {
                   window.navigator.msSaveOrOpenBlob(IEFile, baseString );
               }
             else{
           	  
           	  var link = document.createElement("a");   
     			  var bytes = new Uint8Array(fileData );   
     			      			  
 			      var blob = new Blob([bytes], {type: mimeType});
 			      link.href =  window.URL.createObjectURL(blob);
 			      
     		   	  link.download = baseString;
     		   	  document.body.appendChild(link);
     		   	  link.click();
     			  document.body.removeChild(link);
     			  
             }
       }
       
       
       $scope.$watch('vm.selectedFilterBy', function(value){
       	if(value != null && value != undefined && (vm.entityOwnerType == 'Bank' || vm.entityOwnerType == 'Branch' || vm.entityOwnerType == 'Clearcorp')){
       		vm.customer = null;
       	}
       })
    }
    
    
})();
