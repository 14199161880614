(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'exposureSubType'
        })
        
        .controller('ExposureSubTypeController', ExposureSubTypeController);

    ExposureSubTypeController.$inject = ['$scope', '$state', '$rootScope','ExposureSubType', 'GlobalValues', 'workflowSharedService', 
    'EntityPermissions', 'ScreenLog', 'entityConstants', '$timeout', 'IntradayMarket', 'MessageBox', 'validateSODAndSession','Auth'];

    function ExposureSubTypeController($scope, $state, $rootScope, ExposureSubType, GlobalValues, workflowSharedService, 
      EntityPermissions, ScreenLog, entityConstants, $timeout, IntradayMarket, MessageBox, validateSODAndSession,Auth) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.EXPOSURE_SUB_TYPE;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "exposureSubType";
        $scope.refresh=refresh;
        vm.validateSODAndSession = validateSODAndSession;
        
        vm.launch=launch;
        vm.editExposureSubType=editExposureSubType;
        function launch() {
          if(vm.marketSessionSettings.sessionStatus=='OPEN'){
            MessageBox.openMessageBox("Alert", "Addition/Modification is permitted only after the IB.SPOT market hours...!", "Ok", "exposuresubtype");
          }else if(!vm.validateSODAndSession.allowToAddOrEdit){
            MessageBox.openMessageBox("Alert", "SOD is completed for the business day, addition/modifications is not permitted.", "Ok", "exposuretype");
          }else{
            $state.go("exposure-sub-type-new");  
          }
        }

        function editExposureSubType(exposureSubTypeId){
          if(vm.marketSessionSettings.sessionStatus=='OPEN'){
            MessageBox.openMessageBox("Alert", "Addition/Modification is permitted only after the IB.SPOT market hours...!", "Ok", "exposuresubtype");
          }else if(!vm.validateSODAndSession.allowToAddOrEdit){
            MessageBox.openMessageBox("Alert", "SOD is completed for the business day, addition/modifications is not permitted.", "Ok", "exposuretype");
          }else{
            vm.screenLog.screenLog(vm.screenName, exposureSubTypeId, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
            $state.go("exposure-sub-type-edit", {id:exposureSubTypeId});
          }
        }
        
        vm.permitExposureSubTypeAddEntityAction = EntityPermissions.hasPermission('ExposureSubType.CREATE');
        vm.permitExposureSubTypeEditEntityAction = EntityPermissions.hasPermission('ExposureSubType.EDIT');
        vm.permitExposureSubTypeViewEntityAction = EntityPermissions.hasPermission('ExposureSubType.VIEW');
       /* if(vm.permitExposureSubTypeViewEntityAction ==false){
          //message box 
          MessageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'permitExposureSubTypeViewEntityAction');
        }
    
        $rootScope.$on('gatewayApp.messagebox.ok.permitExposureSubTypeViewEntityAction', function (event) {
          Auth.sessionlogout();
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.permitExposureSubTypeViewEntityAction', function (result){
          Auth.sessionlogout();
        })*/
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   
    	   ExposureSubType.getAllExposureSubTypes(function(result) {
               vm.exposureSubTypes = result;                
           });
    	 
        }      
       
        //get market session settings.
        IntradayMarket.getIntradayMarketSessionSettingsForInstrument({"instrument":'InterbankSPOT'}, onGetMarketSessionSuccess, onGetMarketSessionFailure);

        function onGetMarketSessionSuccess(result){
          vm.marketSessionSettings = result;
        }

        function onGetMarketSessionFailure(){

        }

       function logScreenAccess(){
    	   ScreenLog.screenLog(vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
