(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('TransactionChargesSummaryView', TransactionChargesSummaryView);

	TransactionChargesSummaryView.$inject = ['$resource'];

	function TransactionChargesSummaryView ($resource) {
		var resourceUrl =  'api/transaction-charges-summary/:id';

		return $resource(resourceUrl, {}, {

			'getTransactionChargesSummaryReport':{          	 
				method: 'GET',
				params: {bankId : '@bankId',from : '@from', to: '@to',printorGenerateAfreshOption : '@printorGenerateAfreshOption'},  				
				url: 'api/transaction-charges-summary/get-transaction-charges-summary-details'
			},
			'exportTransactionChargesSummaryExcel':{
				method: 'POST',
				url: 'api/transaction-charges-summary/excel-download',
				responseType: 'arraybuffer',
				params: {freshOrPrintOption : '@freshOrPrintOption', bank : '@bank'},
				transformRequest: function (data) {
	                   	var copy = angular.copy(data);
	                   	
	                       return angular.toJson(copy);
	            },
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				}      
			},
			'downloadEInvoiceExcel':{
				method: 'POST',
				url: 'api/transaction-charges-summary/eInvoice-download',
				responseType: 'arraybuffer',
				params: {from : '@from', to: '@to',financialYearId : '@financialYearId'},
				transformRequest: function (data) {
	                   	var copy = angular.copy(data);
	                   	
	                       return angular.toJson(copy);
	            },
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				}      
			},
			'exportTransactionChargesSummaryCsv':{
				method: 'POST',
				url: 'api/transaction-charges-summary/csv-download',
				responseType: 'arraybuffer',
				params: {freshOrPrintOption : '@freshOrPrintOption', bank : '@bank'},
				transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
            },
			transformResponse: function(data, headers) {
				return {
					data: data,
					headers: headers
				};   
			}         
			},
			'exportTransactionChargesSummaryPdf':{
				method: 'POST',
				url: 'api/transaction-charges-summary/pdf-download',
				responseType: 'arraybuffer',
				params: {freshOrPrintOption : '@freshOrPrintOption', bank : '@bank'},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				}      
			},
			'getPreviousBusinessDate':{
				method: 'GET',  
                url: 'api/get-previous-business-date',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}
			
		
		});
	}
})();
