(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'configurationsettings'
	})

	.controller('DealingAdminApplicationWideSettingsController', DealingAdminApplicationWideSettingsController);

	DealingAdminApplicationWideSettingsController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'DealingAdminApplicationWideSettings', 'GlobalValues', 'MessageBox',
			'EntityPermissions', 'ScreenLog', 'DateUtils','$uibModalInstance','$stateParams','$rootScope','entityConstants', 'IntradayMarket' ];

	function DealingAdminApplicationWideSettingsController($scope, $state, workflowSharedService,
			DealingAdminApplicationWideSettings, GlobalValues, MessageBox, EntityPermissions, ScreenLog, DateUtils,$uibModalInstance,$stateParams,$rootScope,entityConstants,IntradayMarket) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.applicationWideSettings = {};
		vm.save = save;
		vm.userId = $stateParams.userId;
		vm.restoreDefault = restoreDefault;
		vm.launch = launch;
		vm.clear = clear;
		workflowSharedService.viewFrom = 'changeConfig';
		vm.isShowRegistrationSettings = true;
		function launch() {
			$state.go("dealing-admin-application-wide-settings");
		}
		logScreenAccess();
		loadAll();
		vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
		vm.decimalsForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
		vm.decimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();

		function loadAll() {
			DealingAdminApplicationWideSettings.get({
				"userId" : vm.userId
			},onGetConfigurationSettingsSuccess, onGetConfigurationSettingsError);
		}

		function onGetConfigurationSettingsSuccess(result) {
			console.log(vm.applicationWideSettings);
			vm.applicationWideSettings = result;
		}

		function onGetConfigurationSettingsError() {

		}
		
		function restoreDefault() {
			loadAll();
		}
		
       	//get market session settings. to display status. market session settings will be available for SPOT, cash TOM
       	IntradayMarket.getIntradayMarketSessionSettingsForInstrument({"instrument":'InterbankSPOT'}, onGetMarketSessionSuccess, onGetMarketSessionFailure);
		
    	function onGetMarketSessionSuccess(result){
        	vm.marketSessionSettings = result;
        }
		
		function onGetMarketSessionFailure(){
			
		}
		
		vm.validateAndChangeConfig = function validateAndChangeConfig(headerName, labelName, configName, configValue, controlType, screenName){
			if(configName=='OrderLotSize' || configName=='MinimumOrderValue' || configName=='MaximumOrderValueHardCheck' || configName=='MaximumOrderValueSoftCheck' || configName=='AllowedOrderPriceDeviationFromMarketPriceInPercentage'){
				if(vm.marketSessionSettings.sessionStatus=='OPEN'){
					MessageBox.openMessageBox("Alert", "Modification is permitted only after the IB.SPOT market hours...!", "Ok", "applicationdealingadminsettings");
				}else{
					$state.go("dealing-admin-change-config", {headerLabel: headerName,labelName: labelName , configName: configName ,configValue: configValue, controlType : controlType, screenName : screenName});
				}
			}else{
				$state.go("dealing-admin-change-config", {headerLabel: headerName,labelName: labelName , configName: configName ,configValue: configValue, controlType : controlType, screenName : screenName});
			}
		}
	
		function save() {
			var fd = new FormData();
			
			vm.user.dob = DateUtils.convertLocalDateToServer(vm.user.dob);
			fd.append('user', JSON.stringify(vm.user));
            
			console.log(fd);
			if(vm.panCardUpload != undefined){
				fd.append('file', vm.panCardUpload);
				UserProfile.updateWithImage( fd, onSaveSuccess, onSaveError);	
			}else{
				
				UserProfile.update( fd, onSaveSuccess, onSaveError);
			}
				
			
		}
		
		$rootScope.$on('Refresh', function(event, result) {
			loadAll();
		});
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function onSaveSuccess(result) {
			$uibModalInstance.close(result);
		}

		function onSaveError() {

			$scope.clicked = false;
		}
		function logScreenAccess(){
	       	ScreenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.DELAING_ADMIN_APPLICATION_WIDE_SEETINGS ,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	    }

	}
})();
