(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('CancelledOrdersReportService', CancelledOrdersReportService);

        CancelledOrdersReportService.$inject = ['$resource'];

    function CancelledOrdersReportService ($resource) {
        var resourceUrl =  '';
        
        return $resource(resourceUrl, {}, {
            'getCancelledOrdersReport':{
           	 
                isArray:true,
                 method: 'GET',
                 params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'},
                 url: 'api/cancel-order-report/bank/:bankId/branch/:branchId/customer/:customerId/instrument/:instrumentId'
                }

        	
            });
        }
    })();
    