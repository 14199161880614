(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'uploadDownloadParticipantCertificate'
        })
        
        .controller('ParticipantCertificateController', ParticipantCertificateController);

    ParticipantCertificateController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    				'MessageBox','$rootScope','UploadDownloadParticipantCertificates','AlertService','$filter', '$window'];

    function ParticipantCertificateController($scope, $state , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    				MessageBox,$rootScope,UploadDownloadParticipantCertificates, AlertService, $filter, $window) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.UPLOAD_DOWNLOAD_PARTICIPANT_CERTIFICATE;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.clicked = false;

        
        vm.organisation = vm.globalValues.getOrganisation().organisationType.shortName;
       
        vm.selectedFilter='upload';
        vm.uploadCertificate = uploadCertificate;
        vm.downloadCertificate = downloadCertificate;
        
        if(vm.organisation == 'CUSTOMER'){
        	vm.participantCode = vm.globalValues.getCustomer().customerCode;
        }
        else if(vm.organisation == 'BANK'){
        	vm.participantCode = vm.globalValues.getBank().ccpMembershipId;
        }
        else{
        	vm.participantCode = 'CLEARCORP';
        }
       
        vm.participantName = vm.globalValues.getOrganisation().shortName;
        
        vm.todayDate = new Date();
       
      
        
  
   
        workflowSharedService.viewFrom = "uploadDownloadParticipantCertificate";
       
      
        logScreenAccess();
       // loadCurrentBusinessDate();
        
      
       function uploadCertificate() {
    	  
    	   if(vm.participantCode == 'CLEARCORP'){
    		   vm.code = 'APS';
    	   }
    	   else{
    		   vm.code = vm.participantCode;
    	   }
    	   var fd1 = new FormData();
    	   fd1.append('code', vm.code);
    	   fd1.append('certificate', vm.certificate);
    	   UploadDownloadParticipantCertificates.uploadCertificate(fd1, onUploadSuccess, onUploadFailure);
    	  
       }
       
       function onUploadSuccess(){
    	   angular.element("input[type='file']").val(null);
			 vm.certificate = null;
			 vm.inputFile = null;
			 vm.clicked = false;
       }
       
       function onUploadFailure(){
    	   angular.element("input[type='file']").val(null);
			 vm.certificate = null;
			 vm.inputFile = null;
			 vm.clicked = false;
       }
       
       function downloadCertificate() {
    	   var mimeType= '';
    	   var baseString =   'Server_Certificate_'+  moment().format( 'DD_MMM_YYYY_HH_mm') + '.cer';   
    	   UploadDownloadParticipantCertificates.downloadCertificate(function(result){
    		   
    		   if(result.data != null && result.data != undefined && result.data.byteLength != 0){
    			   
    			   vm.certificateFile = result.data;
				  
    	   			 
    				      var IEFile = new Blob([result.data], {type: mimeType});
    				      
    				     if(window.navigator.msSaveOrOpenBlob) {
    		                   window.navigator.msSaveOrOpenBlob(IEFile, baseString );
    		               }
    				      
    				      else{
    				    	  
    				    	  var link = document.createElement("a");   
    	    	   			  var bytes = new Uint8Array( vm.certificateFile );   
    	    	   			      			  
    	    				      var blob = new Blob([bytes], {type: mimeType});
    	    				      link.href =  window.URL.createObjectURL(blob);
    	    				      
    	    				      link.download = baseString;
    	        	   		   	  document.body.appendChild(link);
    	        	   		   	  link.click();
    	        	   			  document.body.removeChild(link);
    	    				      
    				      }
						  vm.clicked=false;
    				    
    		   }
    		   else{
    			   AlertService.error($filter('translate')('gatewayApp.uploadDownloadCertificates.fileNotExists'));
				   vm.clicked=false;
    		   }
    		  
   			  
   			 
    	   })
       }
        
       $scope.$watch('vm.certificate', function(value){
    	   if(vm.certificate != null && vm.certificate != undefined){
    		   if(vm.certificate.name != null && vm.certificate.name != undefined){
    			   
    			   vm.inputFile = vm.certificate.name;
    	    		 vm.fileExtension =  vm.inputFile.substring(vm.certificate.name.lastIndexOf(".") + 1);
    	    		 if(vm.fileExtension  != 'cer'){
    	    			 AlertService.error($filter('translate')('gatewayApp.uploadDownloadCertificates.invalidFile'));
    	    			 angular.element("input[type='file']").val(null);
    	    			 vm.certificate = null;
    	    			 vm.inputFile = null;
    	    		 }
    	    		 
    		   }
    		  
    	   }
       })
     
        	
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
     
    }
})();
