(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('settlement-report', {
            parent: 'entity',
            params:{screen: 'TradesToBeSettledReport'},
            url: '/trades-to-be-settled-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.settlementReport.main" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/settlement-report/settlement-report.html',
                    controller: 'SettlementReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settlementReport');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderActivity');
                    return $translate.refresh();
                }],
                filterBy: ['StaticLookUp', function(StaticLookUp) {
                    return StaticLookUp.getTradesToBeSettledReportFilter().$promise;
                }],
                systemSetting: ['IntradayMarket', function(IntradayMarket) {
                    return IntradayMarket.getCurrentBusinessDate().$promise;
                }]
               
            },
          
        })

        .state('trade-activity', {
            parent: 'settlement-report',
            url: '/order-activity-status/{orderNumber}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                            	 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.orderNumber}).$promise;
                                 }],
                                 businessDate: null
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })

        
        
    };
})();
