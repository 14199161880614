(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'amcSettings'
        })
        
        .controller('AMCSettingsController', AMCSettingsController);

    AMCSettingsController.$inject = ['$scope', '$state', 'AMCSettings', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function AMCSettingsController($scope, $state, AMCSettings , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.AMC_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "amcSettings";
        vm.bank = GlobalValues.getBank();
        $scope.refresh=refresh;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        
        vm.launch=launch;
        function launch() {
        	$state.go("amc-settings-new");
        }
        
        
        vm.permitAMCSettingsAddEntityAction = EntityPermissions.hasPermission('AMCSettings.CREATE');
        vm.permitAMCSettingsImportEntityAction = EntityPermissions.hasPermission('AMCSettings.IMPORT');
        vm.permitAMCSettingsEditEntityAction = EntityPermissions.hasPermission('AMCSettings.EDIT');
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   
    	   AMCSettings.query(function(result) {
               vm.amcSettings = result;                
           });
    	 
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
