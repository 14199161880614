(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('start-eod-process', {
            parent: 'entity',
            params:{screen: 'EODProcess'},
            url: '/start-eod-process',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.EODProcess.eodProcess" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/eod-process/start-eod-process.html',
                    controller: 'EodProcessNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
              
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('eodProcess');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
