(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UsersViewController', UsersViewController);

    UsersViewController.$inject = ['$scope', '$state','$rootScope','workflowSharedService','$uibModalInstance','User', 'Branch' ,'entity', 'Workflow', 'GlobalValues', 'ScreenLog', 'DateUtils'];

    function UsersViewController($scope, $state, $rootScope, workflowSharedService, $uibModalInstance,User, Branch ,entity, Workflow, GlobalValues, ScreenLog, DateUtils) {
        var vm = this; 
        vm.clear=clear;
        vm.approveUsers = approveUsers;
        vm.rejectUsers = rejectUsers;
        vm.discardUsers = discardUsers;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.theme = "dark";
        vm.globalValues = GlobalValues;
        vm.userType = vm.globalValues.getOrganisation().organisationType.shortName;
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.bank=vm.globalValues.getBank();
        $scope.clicked=false;
        
        
        if(workflowSharedService.viewFrom == 'userRole'  || workflowSharedService.viewFrom == 'bankregistration' || workflowSharedService.viewFrom == 'branch'){
        	vm.users = entity;        
        	vm.mobileCountryCode = vm.users.mobileCountryCode;
        	vm.country=vm.users.country;
        	vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
        	vm.gender = vm.users.gender; 
        	if(vm.users.entityOwnerType.shortName=='Branch'){
        		vm.branch = vm.users.branch;
        	}
            //ScreenLog.screenLog({'entityName': 'UserManagement', 'entityRecordId': vm.users.id, 'action': 'VIEW'});
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'usermanagementtodolist_checker') {
        	vm.users =  angular.fromJson($rootScope.payload); 
        	vm.mobileCountryCode = vm.users.mobileCountryCode;
        	vm.country=vm.users.country;
        	vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
        	vm.gender = vm.users.gender; 
        	if(vm.userEntityOwnerType=='Branch' || vm.users.entityOwnerTypeShortName=='Branch'){
        		vm.branch = vm.users.branch;
        		
        	}
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        	
        	Branch.get({"id":vm.users.branchId},onGetBranchSuccess,onGetBranchError);
        	
        	
        }
        else if(workflowSharedService.viewFrom == 'usermanagementtodolist_maker') {
        	vm.users =  angular.fromJson($rootScope.payload); 
        	vm.mobileCountryCode = vm.users.mobileCountryCode;
        	vm.country=vm.users.country;
        	vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
        	vm.gender = vm.users.gender; 
        	console.log(vm.users);
        	if(vm.userEntityOwnerType=='Branch' || vm.users.entityOwnerTypeShortName=='Branch'){
        		vm.branch = vm.users.branch;
        	}
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        if(workflowSharedService.viewFrom == 'userRole_auditLog'){
        	vm.users =  angular.fromJson($rootScope.payload); 
        	vm.mobileCountryCode = vm.users.mobileCountryCode;
        	vm.country=vm.users.country;
        	vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
        	vm.gender = vm.users.gender; 
        	if(vm.userEntityOwnerType=='Branch' || vm.users.entityOwnerTypeShortName=='Branch'){
        		vm.branch = vm.users.branch;
        	}
            //ScreenLog.screenLog({'entityName': 'UserManagement', 'entityRecordId': vm.users.id, 'action': 'VIEW'});
       	 	$scope.showButton=false;
       	 	
	       	 Branch.get({"id":vm.users.branchId},onGetBranchSuccess,onGetBranchError);
	     	
        }
        
        function approveUsers() { 
        	User.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	$scope.clicked=false;
        }
       
        function rejectUsers() { Workflow.rejectTask($rootScope.taskID); }

        function discardUsers() {
        	User.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

    	var onGetBranchSuccess =  function onGetBranchSuccess(result){
    		vm.branch=result.branchName;
    	}
    	
    	var onGetBranchError = function onGetBranchError(){
    		
    	}
       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }   
})();
