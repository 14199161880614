(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SwapQuotesHomePageController', SwapQuotesHomePageController);

    SwapQuotesHomePageController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', 
    '$stateParams', 'GlobalValues', 'EntityPermissions', 'workflowSharedService'];

    function SwapQuotesHomePageController($scope, $rootScope, $state, $uibModalInstance, 
        $stateParams, GlobalValues, EntityPermissions, workflowSharedService) {
        var vm = this;
        
        vm.clear=clear;
        vm.globalValues = GlobalValues;

        vm.showPriceIcon=true;
        vm.bankQuotesPaginationNoOfRecordsPerPage = 10;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.hasPermission = EntityPermissions.hasPermission;
        vm.permitSwapQuotesEditEntityAction = vm.hasPermission("SwapQuotes.EDIT");
        vm.editSwapQuotes = editSwapQuotes;
        vm.onChangingBankInSwapQuotes = onChangingBankInSwapQuotes;
        vm.onChangingBankInMarkup = onChangingBankInMarkup;

        vm.account=$stateParams.account;
        vm.defaultTradingBank = $stateParams.defaultTradingBank;

        if(vm.account.organisation.organisationType.shortName!='BANK'){
            vm.defaultTab = 'comparitive_tab1';
        }else{
            vm.defaultTab = 'comparitive_tab3';
        }
        vm.showExpandIcon = false;

        $scope.$watch('vm.defaultTab', function(value){
            $rootScope.defaultTab = value;
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        $scope.$watch('vm.showPriceIcon', function(value){
            if(value==true){
                if(document.getElementById('comparitive_tab1')!=null){
                    setTimeout(function(){document.getElementById('comparitive_tab1').click()},100);    
                }
            }
        })

        function editSwapQuotes(){
            workflowSharedService.viewFrom = "swapQuotes";
            $state.go('swap-quotes-change-exp', {businessDate: vm.globalValues.getSystemSettings().businessDate});
        }

        function onChangingBankInSwapQuotes(bank){
            $rootScope.bankFromSwapQuote = bank;
            $scope.$emit('onChangingBankInSwapQuotes');
            $uibModalInstance.close();
        }

        function onChangingBankInMarkup(bank){
            $rootScope.bankFromMarkup = bank;
            $scope.$emit('onChangingBankInMarkup');
            $uibModalInstance.close();
        }

    }   
})();
