(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('CustomerTradingBankDetails', CustomerTradingBankDetails);

    CustomerTradingBankDetails.$inject = ['$resource'];

    function CustomerTradingBankDetails ($resource) {
        var resourceUrl =  'api/customer-trading-bank-details/:id';

        return $resource(resourceUrl, {}, {
            
            
            'getCustomerDetailsForReport':{
            	 
            	isArray:true,
 				method: 'GET',
 				params: {bankId:'@bankId',branchId:'@branchId'},
 				url: 'api/customer-details/get-customer-details-for-report'
             },
             'exportAllCustomerDetails':{
            	 
  				method: 'GET',
  				responseType: 'arraybuffer',
  				url: 'api/customer-details/export-all-customer-details',
  				transformResponse: function(data,headers) {
  					return {
                        data: data,
                        headers: headers
                    };
  				 }     
              },
              'getCustomerDetailsForBankRelationship':{
   				method: 'GET',
   				params: {customerTradinBankDetailsId:'@customerTradinBankDetailsId'},
   				url: 'api/customer-details/get-customer-report/:customerTradinBankDetailsId'
               },
               'getBankAndDefaultUserDetailsForCustomer':{
      				method: 'GET',
      				params: {customerId:'@customerId', bankId:'@bankId'},
      				url: 'api/customer-details/get-bank-and-default-user/:customerId/:bankId'
               },
               'getCustomerRegistrationRequestFromPayload':{
     				method: 'POST',
     				url: 'api/customer-details/get-customer-registration-from-report',
     				transformResponse: function(data,headers) {
     	  					return {
     	                        data: data,
     	                        headers: headers
     	                    };
     	  			},
     	  			transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
              } ,
              'getValidTradingBankDetails':{
	   				method: 'GET',
	   				url: 'api/valid-status-trading-bank-for-customer',
	   				isArray:true
   	  			},
   	  		       
            'getAnnexure2ForCustomerBankRelationship':{
                method:'GET',
                params: {customerTradinBankDetailsId:'@customerTradinBankDetailsId'},
                url: 'api/annexure-2-for-customer-relationship/:customerTradinBankDetailsId',
                responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'exportExcelData':{
			    method: 'GET',
                url: 'api/customer-details/export-customer-details/excel',
                responseType: 'arraybuffer',
                params : {
                    bankId : '@bankId',
					branchId : '@branchId'
				},
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
			},
			'exportCSVData':{
			    method: 'GET',
                url: 'api/customer-details/export-customer-details/csv',
                responseType: 'arraybuffer',
                params : {
					bankId : '@bankId',
					branchId : '@branchId'
				},
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
			}

               
             });
    }
})();
