(function() {
	'use strict';
	angular.module('gatewayApp').factory('ChangeEmail', ChangeEmail);

	ChangeEmail.$inject = [ '$resource' ];

	function ChangeEmail($resource) {
		var resourceUrl = 'api/change-email/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'sendActivationMail' : {
				method : 'POST',
				url : 'api/user-profile/send-email-change'
			},
			'sendActivationMailForCustomer' : {
				method : 'POST',
				url : 'api/user-profile-customer'
			},
			'activatePendingEmailChangeRequest' : {
				method : 'POST',
				params : {
					userId : '@userId'
				},
				url : 'api/user-profile/activate-email-change/userId/:userId'
			},
			'activatePendingEmailChangeRequestForCustomer' : {
				method : 'POST',
				params : {
					customerId : '@customerId'
				},
				url : 'api/user-profile/activate-email-change-customer/customerId/:customerId'
			},
			'update' : {
				method : 'POST',
				params : {
					key : '@key'
				},
				url : 'api/user-profile/update-email/key/:key'
			},
			'updateForCustomer' : {
				method : 'POST',
				params : {
					key : '@key'
				},
				url : 'api/user-profile/update-email-customer/key/:key'
			},
			'checkPassword' : {
				method : 'POST',
				/*params : {
					userId : '@userId',
					password : '@password'
				},*/
				url : 'api/user-profile/check-password'
			},
			
			'sendOTPVerificationEmail' : {
				method : 'POST',
				url : 'api/send-otp-verification-email',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'updateCustomerEmail' : {
				method : 'POST',
				url : 'api/update-customer-email'
			},
			'updateUserEmail' : {
				method : 'POST',
				url : 'api/update-user-email'
			},
			'checkPasswordForCustomer' : {
				method : 'POST',
				/*params : {
					userId : '@userId',
					password : '@password',
					customerId : '@customerId'
				},*/
				url : 'api/user-profile/check-password-customer'
			}
		});
	}
})();
