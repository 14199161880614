(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'emailSettings'
        })
        
        .controller('EmailSettingsController', EmailSettingsController);

    EmailSettingsController.$inject = ['$scope', '$state', 'EmailSettings', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function EmailSettingsController($scope, $state, EmailSettings , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.EMAIL_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        $scope.refresh=refresh;
        workflowSharedService.viewFrom = "emailSettings";
        
        vm.launch=launch;
        function launch() {
        	$state.go("email-new");
        }
        
        
        
        vm.permitEmailSettingsEditEntityAction = EntityPermissions.hasPermission('EmailSettings.EDIT');
        vm.permitEmailSettingsAddEntityAction = EntityPermissions.hasPermission('EmailSettings.CREATE');
        
        logScreenAccess();
        loadAll();
       

       function loadAll() {
    	   
    	   EmailSettings.query({"orgId": GlobalValues.getOrgId()},function(result) {
               vm.emailSettings = result;
            });  
    	 
        }      
       
      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
