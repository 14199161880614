(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'orderActivityReport'
        })
        
        .controller('OrderActivityController', OrderActivityController);

    OrderActivityController.$inject = ['$scope', '$state', 'OrderActivity', 'GlobalValues', 'workflowSharedService', '$uibModalInstance','EntityPermissions', 'ScreenLog','entityConstants','$timeout','entity','IntradayMarket','DateUtils', 'businessDate'];

    function OrderActivityController($scope, $state, OrderActivity , GlobalValues, workflowSharedService, $uibModalInstance, EntityPermissions, ScreenLog,entityConstants,$timeout,entity, IntradayMarket,DateUtils,businessDate) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDERACTIVITYREPORT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.clear=clear;
        vm.todayDate = new Date();
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        
        loadCurrentBusinessDate();
        if(businessDate!=null && businessDate != undefined){
        	vm.todayDate = businessDate;
        }
        
        angular.forEach(entity, function(value){
        	
	              	value.revisionTimeStamp=moment(value.revisionTimeStamp,'YYYYMMDDHHmmss.SSSSSSSSS').format();
	              	
	              	if(value.expiryTime==0){
	              		var time = moment().toDate();
		              	time.setHours(0);
		              	time.setMinutes(0);
		              	time.setSeconds(0);
		              	time.setMilliseconds(0);
		              	value.expiryTime=time;
	              	}else{
	              		value.expiryTime=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
	              	}
	              	
	              	
	             // 	value.activityTimeStamp=moment(value.activityTimeStamp,'YYYYMMDDHHmmss.SSSSSSSSS').format();
	              	if(value.activityIndicator=='PLAC'){
	              		value.activityIndicator='Add';
	              	}else if(value.activityIndicator=='MDFY'){
	              		value.activityIndicator='Modify';
	              	}else if(value.activityIndicator=='CNCL'){
	              		value.activityIndicator='Cancel';
	              	}else if(value.activityIndicator == 'TRAD'){
	              		value.activityIndicator='Trade';
	              	}
	              	
	              	if(value.buySellFlag.toUpperCase()=='BUYY'){
	              		value.buySellFlag='Buy';
	              	}
	              	else{
	              		value.buySellFlag='Sell';
	              	}
	              	
	              	
	              	if(value.timeLimitIndicator.toUpperCase()!='GTTM'){
	              		value.expiryTime=null;
	              	}
	              	
	              	if(value.priceConditionName!='Stop Loss' && value.priceConditionName!='Take Profit'){
	              		value.stopExchangeRate=null;
	              	}
	              	
	              	if(value.tradeSequenceNumber==0){
	              		value.tradeSequenceNumber=0;
	              	}
	              	
    	});
        
        vm.orderActivityDetails=entity;
        
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function loadCurrentBusinessDate(){
    	   IntradayMarket.getCurrentBusinessDate(getBusinessDateSuccess, getBusinessDateFailed);
       }
       
       function getBusinessDateSuccess(result){
    	   vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(result.businessDate);
    	   
    	   vm.convertedCurrentBusinessDate=DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
    	  
       }
       
       function getBusinessDateFailed(){
    	   
       }
       
       function clear () {
           $uibModalInstance.dismiss('cancel');
       }

	   vm.getSwapPoints = function getSwapPoints(spotExchangeRate, tradeExchangeRate){
		var swapPts =0;
		if(spotExchangeRate!=0){
		 var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
		 swapPts = (Math.round(parseFloat(swapPts)*100)/100);
		 return swapPts;
		}else{
		 return swapPts;
		}
	   }
    }
})();
