(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'tradesDownlaod'
        })
        
        .controller('TradesDownlaodController', TradesDownlaodController);

    TradesDownlaodController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','TradesDownload',
    	'DateUtils','AlertService','$filter'];

    function TradesDownlaodController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,TradesDownload,DateUtils, AlertService,$filter) {
    	var vm=this;
    	vm.globalValues = GlobalValues;
    	  var systemSettings = vm.globalValues.getSystemSettings();
    	  vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRADES_DOWNLOAD
    	  vm.entityConstants = entityConstants;
          vm.screenLog = ScreenLog;;
          vm.selectTime = false;
          vm.disableTime = true; 
		  vm.clicked=false;
          vm.checkSelection = checkSelection;
          vm.downloadInCSV = cvDownload;
          vm.downloadInFIX  = fixDownload;
          
          
          
          if(systemSettings!=null && systemSettings!=undefined){
        	  vm.currentDate = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
          }
         
         /* vm.fromTime =  moment(angular.copy(systemSettings.businessDate),'YYYY-MM-DD').startOf('day')     ;
      	 // vm.toTime =  angular.copy(vm.fromTime).endOf('day').subtract('seconds',1);
          vm.toTime =   moment(angular.copy(systemSettings.businessDate),'YYYY-MM-DD').set({ 	'hour':23,
				'minute':59,
				'second':59
				
			 });*/
          vm.fromTime =  moment().startOf('day')     ;
       	 // vm.toTime =  angular.copy(vm.fromTime).endOf('day').subtract('seconds',1);
           vm.toTime =   moment().set({ 	'hour':23,
 				'minute':59,
 				'second':59
 				
 			 });
      	  vm.maxDateTime =  angular.copy(vm.toTime);
      	  vm.minDateTime = angular.copy(vm.fromTime);;
          logScreenAccess();
          function logScreenAccess(){
             	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
          }
          function checkSelection(event){
            	
        	  changeTimeSelection(event.target.checked);
                 
          }
         function changeTimeSelection(value){
      	 	if(value){
      	 		vm.disableTime = false;
      	 		
      		}else{
      			vm.disableTime = true;
      			vm.fromTime =   angular.copy(vm.minDateTime);     ;
      	      	 // vm.toTime =  angular.copy(vm.fromTime).endOf('day').subtract('seconds',1);
      	        vm.toTime =  angular.copy(vm.maxDateTime);
      		}
          
          }
         
         function cvDownload(){
        	 var comparision =  vm.fromTime.isAfter(vm.toTime);
        	//console.log(vm.fromTime.format('YYYY-MM-DD HH:mm:ss'));
        	//console.log(vm.toTime.format('YYYY-MM-DD HH:mm:ss'));
       	  	//console.log(vm.currentDate);
       	 	//console.log(comparision);
      		if(!vm.disableTime && comparision ){
      			AlertService.error("From Time cannot be greater than To Time");
      		}
      		else if(vm.disableTime){
      			TradesDownload.getBytesForDownload({'from':null, 'to' :null},getCSVDataSuccess, getCSVDataFailure);
      		}
      		else{
      			TradesDownload.getBytesForDownload({'from':vm.fromTime.format('YYYYMMDDHHmmss.000000000'), 'to' :vm.toTime.format('YYYYMMDDHHmmss.000000000')},getCSVDataSuccess, getCSVDataFailure);
      		}
         }
         
         function getCSVDataSuccess(result){
        	 var fileData = result.data;
			 vm.clicked=false;
        	 var mimeType = 'text/csv'
        	 var baseString =    moment(vm.currentDate).format( 'YYYYMMDD')+'_FXRETAIL_TRADES';  
        	 if(fileData  !=null && fileData !=undefined && fileData.byteLength > 0){
			 /* var link = document.createElement("a");   
			  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer		  
			  
		      var blob = new Blob([bytes], {type: mimeType});
		      link.href =  window.URL.createObjectURL(blob);
		      
		   	  link.download = baseString;
		   	  document.body.appendChild(link);
		   	  link.click();
			  document.body.removeChild(link);*/
        		 var base64data = "base64," + $.base64.encode(fileData);
                 var blob = new Blob([fileData], { type: "application/" + "csv" + ';' + base64data })
                 window.saveAs(blob, baseString + "."  + "csv");
        	 }else{
        		 AlertService.error("No Records Found");
        	 }
         }
         function getCSVDataFailure(error){
        	 AlertService.error("Error in fetching data for Day trade download");
			 vm.clicked=false;
         }
         function fixDownload(){
        	 var comparision =  vm.fromTime.isAfter(vm.toTime);        	
      		if(!vm.disableTime && comparision ){
      			AlertService.error("From Time cannot be greater than To Time");
      		}
      		else if(vm.disableTime){
      			TradesDownload.getFixBytesForDownload({'from':null, 'to' :null},getFIXDataSuccess, getFIXDataFailure);
      		}
      		else{
      			TradesDownload.getFixBytesForDownload({'from':vm.fromTime.format('YYYYMMDDHHmmss.000000000'), 'to' :vm.toTime.format('YYYYMMDDHHmmss.000000000')},getFIXDataSuccess, getFIXDataFailure);
      		}
         }
         function getFIXDataSuccess(result){
        	 var fileData = result.data;
			 vm.clicked=false;
        	 var mimeType = 'text/plain'
        	 var baseString =    moment(vm.currentDate).format( 'YYYYMMDD')+'_FXRETAIL_TRADES';  
        	 if(fileData  !=null && fileData !=undefined && fileData.byteLength > 0){
			 
        		 var base64data = "base64," + $.base64.encode(fileData);
                 var blob = new Blob([fileData], { type: "text/plain" +  ';' + base64data })
                 window.saveAs(blob, baseString + "."  + "txt");
        	 }else{
        		 AlertService.error("No Records Found");
        	 }
         }
         function getFIXDataFailure(error){
			 vm.clicked=false;
        	 AlertService.error("Error in fetching data for Day trade download");
         }
    }
})();
