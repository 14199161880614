(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UsersRoleAssignController', UsersRoleAssignController);

    UsersRoleAssignController.$inject = ['Workflow', '$scope', '$state','$stateParams', '$rootScope','workflowSharedService', 'GlobalValues', '$uibModalInstance', 'User','Role','entity','ScreenLog'];

     function UsersRoleAssignController(Workflow, $scope, $state,$stateParams, $rootScope,workflowSharedService, GlobalValues, $uibModalInstance, User,Role,entity, ScreenLog) {
        var vm = this;
        vm.screenName = 'UserRole';
        vm.screenLog = ScreenLog;
        vm.save = save;
        vm.clear = clear;
        vm.discardUserRole=discardUserRole;
        vm.approveUserRole=approveUserRole;
        vm.rejectUserRole=rejectUserRole;
        vm.resubmit=resubmit;
        vm.roles=  Role.query({"orgId": GlobalValues.getCorpId()});
        vm.userRoleDTO = entity;
        vm.setDisabledValue=setDisabledValue();
        vm.disableSave=true;
        vm.ViewDisable = false;
        vm.selected = {
		    roles: []
		};
        vm.clicked=false;
        if(workflowSharedService.viewFrom == 'userRole') {
        	
         	$scope.showButton=false;
         	$scope.showDiscardButton=false;
        	$scope.showSave=true;
        	$scope.showResubmit=false;
         	$scope.disableElements=false;
         	vm.userId=vm.userRoleDTO.userId;
         	vm.shortName=vm.userRoleDTO.shortName;
        	 angular.forEach(vm.userRoleDTO.organisationRoleList,function(rolees,index){
 				vm.selected.roles.push(rolees);
 				vm.rolee={};
 				
 			});

        	vm.userRoleDTO.userId =$stateParams.id;
			//ScreenLog.screenLog({'entityName': 'UserRole', 'entityRecordId': vm.userRoleDTO.userId, 'action': 'MAP'});
        }
        
        else if(workflowSharedService.viewFrom == 'userroletodolist_checker'){
        	vm.ViewDisable = true;
          	$scope.showButton=true;
         	$scope.showDiscardButton=false;
        	$scope.showSave=false;
        	$scope.showResubmit=false;
        	$scope.disableElements=true;
        	vm.userRoles =  angular.fromJson($rootScope.payload); 
        	
        	vm.userId=vm.userRoles.userId;
        	vm.shortName=vm.userRoles.shortName;
        	angular.forEach(vm.userRoles.organisationRoleList,function(rolees,index){
 				vm.rolee=rolees;
 				vm.selected.roles.push(vm.rolee);
 				vm.rolee={};
 				
 			});
        	
        }
        else if(workflowSharedService.viewFrom == 'userroletodolist_maker'){
        	vm.ViewDisable = true;
         	$scope.showButton=false;
         	$scope.showResubmit=false;
         	$scope.showSave=false;
         	$scope.showDiscardButton=true;
         	$scope.disableElements=true;
        	vm.userRoles =  angular.fromJson($rootScope.payload);
        
        	vm.userId=vm.userRoles.userId;
        	vm.shortName=vm.userRoles.shortName;
        	angular.forEach(vm.userRoles.organisationRoleList,function(rolees,index){
 				vm.rolee=rolees;
 				vm.selected.roles.push(vm.rolee);
 				vm.rolee={};
 				
 			});

        }
        else if(workflowSharedService.viewFrom == 'userroletodolist_resubmit'){
        	$scope.showButton=false;
        	$scope.showResubmit=true;
         	$scope.showDiscardButton=false;
        	$scope.showSave=false;
        	$scope.disableElements=false;
        	vm.userRoleDTO =  angular.fromJson($rootScope.payload);
        	vm.screenLog.screenLog(vm.screenName, vm.userRoleDTO.userId, 'RESUBMIT');
        	vm.userId=vm.userRoleDTO.userId;
        	vm.shortName=vm.userRoleDTO.shortName;
        	angular.forEach(vm.userRoleDTO.organisationRoleList,function(rolees,index){
 				vm.rolee=rolees;
 				vm.selected.roles.push(vm.rolee);
 				vm.rolee1={};
 				
 			});
        }
        else if(workflowSharedService.viewFrom == 'userroletodolist_auditLog'){
        	vm.ViewDisable = true;
          	$scope.showButton=false;
         	$scope.showDiscardButton=false;
        	$scope.showSave=false;
        	$scope.showResubmit=false;
        	$scope.disableElements=true;
        	vm.userRoles =  angular.fromJson($rootScope.payload); 
        	
        	vm.userId=vm.userRoles.userId;
        	vm.shortName=vm.userRoles.shortName;
        	angular.forEach(vm.userRoles.organisationRoleList,function(rolees,index){
 				vm.rolee=rolees;
 				vm.selected.roles.push(vm.rolee);
 				vm.rolee={};
 				
 			});
        	
        }
        
        
		vm.userRoleDTO.organisationRoleList = [];
		
		function setRoleID(){
			vm.userRoleDTO.organisationRoleList = [];
			angular.forEach(vm.selected.roles,function(roles,index){
				vm.userRoleDTO.organisationRoleList.push(roles);
				vm.role={};
				
			});
		}
		function setDisabledValue(){
			vm.disableSave=false;
		}
	
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }
		
		function save () {	
			setRoleID();
            User.mapRole(vm.userRoleDTO, onSaveSuccess, onSaveError);	
        }
		function resubmit () {	
		
			setRoleID();
        	
            User.resubmit({"taskId" : $rootScope.taskID},vm.userRoleDTO, onSaveSuccess, onSaveError);	
        }
     
        function onSaveSuccess (result) {
        	 
            $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.clicked=false;
        }
        function approveUserRole() { 
        	User.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);
        	
        }        
        function onApproveError(error) {
        	vm.clicked=false;
        }
        
        function rejectUserRole() { 
        	Workflow.rejectTask($rootScope.taskID);
        }

        function discardUserRole() {
        	User.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
               
		
     } 
})();