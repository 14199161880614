(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrderActivity', OrderActivity);

    OrderActivity.$inject = ['$resource'];

    function OrderActivity ($resource) {
        var resourceUrl =  'api/order-activity/:orderNumber';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'get': {
                method: 'GET',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
              
        });
    }
})();
