(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customer-view-report', {
            parent: 'entity',
            params:{screen: 'CustomerViewReport'}, 
            url: '/customer-view-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.customer.manageCustomerViewReport" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/customer-view-report/customer-view-report.html',
                    controller: 'CustomerViewReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('customerViewReport');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('customerBankRelationshipReport');
                    return $translate.refresh();
                }],
                entity: function(){
                	
                }
            }
        })
        /*.state('customer-view-report.edit', {
            parent: 'customer-view-report',
            url: '/customer-view-report/edit/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/customer-bank-relationship-report/customer-trading-bank-dialog.html',
                    controller: 'CustomerTradingBankDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: ['CustomerTradingBankDetails', 'GlobalValues', function(CustomerTradingBankDetails, GlobalValues) {
                   		 	
                            return CustomerTradingBankDetails.getCustomerDetailsForBankRelationship({customerTradinBankDetailsId : $stateParams.id}).$promise;
                        }]
              
           }
                }).result.then(function() {
                    $state.go('customer-view-report', {}, { reload: 'customer-view-report' });
                }, function() {
                    $state.go('^');
                });
            }]
        })*/
       
       };
    })();