(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'otrSummaryView'
	})

	.controller('OTRSummaryViewController', OTRSummaryViewController);

	OTRSummaryViewController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
		'DateUtils','AlertService','$filter',   'OTRSummaryView','systemSettings','configSetting','financialYears'];

	function OTRSummaryViewController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
			DateUtils,AlertService,$filter,  OTRSummaryView,systemSettings,configSetting,financialYears) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.OTR_SUMMARY_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;

		$scope.noOfRecordsPerPageSummary = angular.copy($scope.noOfRecordsPerPage);
		$scope.pageSummary = 1;

		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.configSettings = configSetting;
		vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		vm.freshOrPrintOption = 'PrintGeneratedInvoice';
		vm.getOTRSummary = getOTRSummary;
		vm.exportDataToFile = exportDataToFile;
		vm.setDefaultValue = setDefaultValue;
		vm.getOTRSummaryDetails = getOTRSummaryDetails;
		vm.loadFinancialMonthOption = loadFinancialMonthOption;
		vm.clearAllValues = clearAllValues;
		vm.filterData = {};
		vm.otrSummaryfileName = "OTR_Summary_Report_";
		vm.eInvoiceChargesfileName = "e-Invoice_";
		vm.fileName = vm.otrSummaryfileName;		
		vm.extension = "";
		vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
		vm.firstTimeLoad = null;
		vm.financialMonth = {};
		vm.previousFiancialMonthOptions = [];
		vm.otrSummarySelected = [];
		vm.showDownloadEInvoice = false;
		vm.showNoRecordsFound = false;
		vm.selectedBankName = "";
		vm.financialYear = vm.globalValues.getFinancialYear();
		vm.downloadEInvoice = downloadEInvoice;
		vm.financialYearList=financialYears;
		logScreenAccess();
		vm.valid = false;
		vm.issuePresent = false;

		if(vm.configSettings != null && vm.configSettings != undefined && vm.configSettings.noOfMOnthsForHistoricalReportsView != null && vm.configSettings.noOfMOnthsForHistoricalReportsView != undefined && !isNaN(vm.configSettings.noOfMOnthsForHistoricalReportsView)){
			vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		}
		else{
			vm.noOFMonthsToDisplayForReports = 2;
		}

		if(systemSettings != null && systemSettings != undefined){
			vm.currentBusinessDate = systemSettings.businessDate;
			vm.currentBusinessDateMoment = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.currentBusinessDate)));
			//vm.maxDate =  angular.copy(vm.currentBusinessDateMoment).toDate();
			vm.minDate =  angular.copy(vm.currentBusinessDateMoment).subtract(vm.noOFMonthsToDisplayForReports,'months').startOf('month').toDate();		
			loadFinancialMonthOption();
			setDefaultValue(vm.freshOrPrintOption);
		}


		function getOTRSummary(){

			if(vm.freshOrPrintOption == 'PrintGeneratedInvoice'&& (vm.bank==null || vm.bank== undefined|| vm.bank=='')){
				vm.showDownloadEInvoice = true;
			}else{
				vm.showDownloadEInvoice = false;
			}
			vm.eInvoiceDisable = false;
			vm.otrSummaryDetails = [];
			vm.otrSummarySelected = [];
			vm.valid = true;
			vm.bankId = 0;
			if(vm.bank!=null && vm.bank !=undefined){
				vm.bankId = angular.copy(vm.bank.id);
			}

			if(moment(vm.minDate).isAfter(vm.fromDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromDateLesserThanMinDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}else if(moment(vm.maxDate).isBefore(vm.fromDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromDateGreaterMaxDate'));
				vm.valid = false;
			}
			else if(moment(vm.toDate).isAfter(vm.maxDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.toDateGreaterMaxDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(moment(vm.minDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.toDateLesserThanMinDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(moment(vm.fromDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromDateGreaterThanToDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(vm.fromDate.getMonth() != vm.toDate.getMonth()){
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromToNotSameMonth'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else{
				vm.issuePresent = false;
				vm.valid = true;
				vm.selectedPrintorRefresh = angular.copy(vm.freshOrPrintOption);
				if(vm.bank != null && vm.bank != undefined){
					vm.selectedBankName = vm.bank.bankName;
				}else{
					vm.selectedBankName = '--All--';
				}
				clearAllValues();
				OTRSummaryView.getOTRSummaryReport({ bankId : vm.bankId,from : DateUtils.convertLocalDateToServer(vm.fromDate), to: DateUtils.convertLocalDateToServer(vm.toDate),printorGenerateAfreshOption : vm.selectedPrintorRefresh}, 
						onGetOTRSummarySuccess, onGetOTRSummaryFailure);  		
			}

		}
		function onGetOTRSummarySuccess(result){
			vm.otrSummaryDetails = result;
		}
		function onGetOTRSummaryFailure(error){

		}

		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		//this method is used to set default value to both the from and to date or financial month
		function setDefaultValue(value){

			if(value == 'PrintGeneratedInvoice'){
				/*vm.selectedFinancialMonth  = vm.previousFiancialMonthOptions[0];*/
				vm.financialMonth = angular.copy(vm.previousFiancialMonthOptions[0]);
				vm.fromDate = angular.copy( vm.financialMonth.date).toDate();
				vm.toDate =angular.copy( vm.financialMonth.date).endOf('month').toDate();
				vm.maxDate =  angular.copy(vm.currentBusinessDateMoment).endOf('month').toDate();//previous bdate	//temporary
				//vm.minToDate = angular.copy(vm.financialMonth.date).toDate();
				//vm.maxToDate =  angular.copy(vm.toDate);
				
					

			}else if(value == 'GenerateAfresh'){

				vm.fromDate = angular.copy(vm.currentBusinessDateMoment).startOf('month').toDate();
				vm.toDate =  angular.copy(vm.currentBusinessDateMoment).toDate();
				
				//vm.minToDate = vm.fromDate;
				//vm.maxToDate =  angular.copy(vm.currentBusinessDateMoment).toDate();
				

			}
			
		}

		function loadFinancialMonthOption(){
			vm.previousFiancialMonthOptions = [];
			if(vm.configSettings.billingEndDate!=null && vm.configSettings.billingEndDate !=""){
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment)));	//temporary
			}
			var monthCount =1;
			for(monthCount =1; monthCount <= vm.noOFMonthsToDisplayForReports ;monthCount++) {
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment).subtract(monthCount,'month')))
			}

		}

		vm.createMonthOptions = createMonthOptions;
		function createMonthOptions(dateMoment){
			var currentMonthOption = {}
			currentMonthOption.date =  angular.copy(dateMoment).startOf('month');
			currentMonthOption.monthDisplay = dateMoment.format('MMM - YYYY');
			return currentMonthOption;

		}
		vm.onFinacialMonthSelection = onFinacialMonthSelection;
		function onFinacialMonthSelection(value){
			var monthSelected = value.date; 
			vm.fromDate = angular.copy(monthSelected).toDate();
			vm.toDate  = angular.copy(monthSelected).endOf('month').toDate();
			loadFinancialYearDisplay(vm.fromDate);
			//vm.minToDate = angular.copy(vm.financialMonth.date).toDate();
			//vm.maxToDate =  angular.copy(vm.toDate);

		}
		function loadFinancialYearDisplay(value){
			var yearCount = 0;
			if(vm.financialYearList != null && vm.financialYearList != undefined){
				for(yearCount = 0;yearCount < vm.financialYearList.length; yearCount++){
					var fromYear =  moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYearList[yearCount].financialYearStart)));
					var toYear = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYearList[yearCount].financialYearEnd))); 
					var financialMonth= moment(angular.copy(value)); 
					if( fromYear.isBefore(financialMonth)&&toYear.isAfter(financialMonth)){
						vm.financialYear=vm.financialYearList[yearCount];
						
					}else{
						vm.financialYear=vm.globalValues.getFinancialYear();
					}
				
			}
		}
	}
		function downloadEInvoice(){
			vm.mimeType = 'application/vnd.ms-excel';
			vm.extension = ".xls"; 
			vm.fileName = vm.eInvoiceChargesfileName;
			OTRSummaryView.downloadEInvoiceExcel({ from : DateUtils.convertLocalDateToServer(vm.fromDate), to: DateUtils.convertLocalDateToServer(vm.toDate),financialYearId :vm.financialYear.id}, processResponse );


         }

		function exportDataToFile(fileType){

			vm.fileName = vm.otrSummaryfileName;
			if( vm.otrSummaryDetails !=null &&  vm.otrSummaryDetails != undefined && vm.otrSummaryDetails.headerList.length > 0){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv';
					vm.extension =  ".csv";  
					OTRSummaryView.exportOTRSummaryCsv({freshOrPrintOption:vm.selectedPrintorRefresh,bank:vm.selectedBankName },vm.otrSummaryDetails, processResponse );
				}
				else if(fileType == 'xls'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.extension = ".xls";  
					OTRSummaryView.exportOTRSummaryExcel({freshOrPrintOption:vm.selectedPrintorRefresh,bank:vm.selectedBankName },vm.otrSummaryDetails, processResponse );
				}
				else if(fileType == 'pdf'){
					vm.mimeType = 'application/pdf';
					vm.extension = ".pdf";  
					OTRSummaryView.exportOTRSummaryPdf({freshOrPrintOption:vm.selectedPrintorRefresh,bank:vm.selectedBankName },vm.otrSummaryDetails , processResponse );
				}

			}
		}

		function processResponse(result){        	  
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			vm.baseString =   vm.fileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 

			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}

		

		$scope.$watch('vm.fromDate',function(value){
			if(value!= undefined && value!=null && (vm.firstTimeLoad != null && vm.firstTimeLoad != undefined)){
				if(vm.freshOrPrintOption == 'PrintGeneratedInvoice'){
					vm.toDate = moment(angular.copy(value)).endOf('month').toDate();
					//vm.maxToDate =  angular.copy(vm.toDate);
					//vm.minToDate = value;
				}else{
					
					if(!moment(value).isAfter(vm.maxDate) && !moment(value).isBefore(vm.minDate)){
						if(vm.currentBusinessDateMoment.isAfter(moment(angular.copy(value)).endOf('month'))){
							vm.toDate = moment(angular.copy(value)).endOf('month').toDate();
							//vm.maxToDate =  angular.copy(vm.toDate);
							//vm.minToDate = value;
						}else{
							vm.toDate = angular.copy(vm.currentBusinessDateMoment).toDate();
							//vm.maxToDate =  angular.copy(vm.toDate);
							//vm.minToDate = angular.copy(vm.currentBusinessDateMoment).startOf('month').toDate();
						}	
					}
				}

			}
			vm.eInvoiceDisable = true;
			vm.firstTimeLoad = 'No';
		});

		$scope.$watch('vm.freshOrPrintOption',function(value){
			if(value!= undefined && value!=null ){				
				setDefaultValue(value);

			}

		});

		$scope.$watch('vm.financialMonth',function(value){
			if(value!= undefined && value!=null ){
				onFinacialMonthSelection(value);
			}
		
		});
		
		function clearAllValues(){
			vm.otrSummaryDetails = null;
			vm.otrSummarySelected = [];
			$scope.filterText = null;
			$scope.filterTextSummary = null;
		}
		


		function getOTRSummaryDetails(otrSummary){
			vm.otrSummarySelected = vm.otrSummaryDetails.bankWiseCustomerDetails[otrSummary.bankChargesDTO.bankName];
			vm.otrSummaryCharges = vm.otrSummaryDetails.detailsListGrandTotalCharges[otrSummary.bankChargesDTO.bankName];

		}
	}
})();