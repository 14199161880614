(function() {
    'use strict';
	/*  this screen view for all entity  */
    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'clearCorpTradesReport'
        })
        
        .controller('ClearCorpTradesReportController', ClearCorpTradesReportController);

    ClearCorpTradesReportController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'DateUtils','AlertService','$filter',   'ClearCorpTradesReportService' , 'GenerateDealTicket','SODProcess', 'DealingAdminApplicationWideSettings'];

    function ClearCorpTradesReportController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		DateUtils,AlertService,$filter,  ClearCorpTradesReportService, GenerateDealTicket,SODProcess, DealingAdminApplicationWideSettings) {
       
    	var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRADES_FOR_THE_DAY;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
       
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.configSettings = vm.globalValues.getConfigSettings();
		vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
		
        vm.toTime = moment();
       
        vm.defaultDuration = "01:00:00";
	    vm.maxDate = moment().subtract( vm.duration);
	    vm.todayDate = new Date();
		vm.clicked=false;
	    vm.getTrades = getTrades;
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    vm.exportDataToPdfFile = exportDataToPdfFile;
	    vm.filterData = {};
	    vm.printDealTicket = printDealTicket;
	    vm.fileName = "Trades_for_the_day_report_";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
	    vm.printDealTicket = printDealTicket;

		setUsers();
        function setUsers() {
            if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
                vm.loggedInCustomer = vm.globalValues.getCustomerId();
           }
           if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
               vm.bank = vm.globalValues.getBank();
           }
           if(vm.entityOwnerType=='Branch'){
               vm.branch = vm.globalValues.getBranch();
           }
            
        }
	    
	    logScreenAccess();
	    vm.valid = false;
	   
	    function loadDates() {
        	
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
		}
    	function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.todayDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
    	}
    	
    	function loadSystemSettingsError(){
			
		}
        loadDates();
        var subtractDurationFromGivenValue = function(toValue) {
	       	 if(toValue !=null && toValue !=undefined && toValue != ""){
	       		 var duration = vm.duration;
	       		 var hours = duration.hours();
	       		 var minutes = duration.minutes();
	       		 if(duration==null || duration ==undefined ||  duration=="" || ( hours == 0 && minutes == 0)){
	       			 duration = moment.duration( vm.defaultDuration);
	       		 }
	       		 return angular.copy(toValue).subtract(duration);
	       	 }else{
	       		 
	       		 return null;
	       	 }
       }
      
        DealingAdminApplicationWideSettings.getConfigValueForConfigName(
        		{"configName": "TimeDifferenceForTimePickerControl"}, function(result){
        			if(result !=null && result !=undefined && result.value!=null && result.value !=undefined && result.value != ""){
        				vm.duration = moment.duration( parseInt( result.value), 'hours');
        			}else{
        				vm.duration = moment.duration( vm.defaultDuration);
        			}
        			 vm.fromTime = subtractDurationFromGivenValue( vm.toTime);
       });
        
        function setReportPeriod(){
        	vm.toDateTime = moment(angular.copy(vm.todayDate ) , 'YYYY-MM-DD');        	
        	vm.toDateTime.set(
       			 { 	'hour':vm.toTime.get('hour'),
       				'minute':vm.toTime.get('minute'),
       				'second':vm.toTime.get('second')
       				
       			 });
        	vm.fromDateTime = moment(angular.copy(vm.todayDate ) , 'YYYY-MM-DD');        	
        	vm.fromDateTime.set(
       			 { 	'hour':vm.fromTime.get('hour'),
       				'minute':vm.fromTime.get('minute'),
       				'second':vm.fromTime.get('second')
       				
       			 });
       	 
        	vm.fromTimeDateObject = new Date(vm.fromDateTime);
        	vm.toTimeDateObject = new Date(vm.toDateTime);
        }

		function prepareDataForCustomer() {
			if(vm.entityOwnerType == 'SingleUserCustomer' || vm.entityOwnerType == 'MultiUserCustomer')
			vm.filterData.customerId = vm.globalValues.getOrgId();
		}
        
        function copyFilterData(){
				// if  bank user
				if (vm.entityOwnerType == 'Bank') {
					vm.filterData.bankId =  vm.globalValues.getOrgId();
				}
				// if branch user
				if (vm.entityOwnerType == 'Branch') {
					vm.filterData.bankId =  vm.globalValues.getOrgId();
					vm.filterData.branchId = vm.globalValues.getBranchId();
				}
        	
        	 if(vm.bank!=null && vm.bank !=undefined){
        		 
        		 vm.filterData.bankId = angular.copy(vm.bank.id);
        	 }else{
        		
        		 vm.filterData.bankId = 0;
        	 }
        	 if(vm.branch!=null && vm.branch!=undefined){
        		
        		 vm.filterData.branchId = angular.copy(vm.branch.id);
        	}else{
        		 
        		vm.filterData.branchId = 0;
        	}if(vm.customer!=null && vm.customer!=undefined){
        		 
        		vm.filterData.customerId = angular.copy(vm.customer.id);
        	}else{
        		
        		vm.filterData.customerId = 0;
        	}
        	if(vm.instrument!=null && vm.instrument!=undefined){
        		 vm.filterData.instrumentId = angular.copy(vm.instrument.id);
        	}else{
        		vm.filterData.instrumentId = 0;
        	}
        	 vm.filterData.fromTimeDateObject = angular.copy(vm.fromTimeDateObject );
        	 vm.filterData.toTimeDateObject = angular.copy(vm.toTimeDateObject);
        }
        
        function getTrades(){
        	vm.valid = true;
        	if(vm.toTime.isAfter(moment())){
        		
        		AlertService.error("To Time should be less than or equal to current time");
        	}else if(vm.fromTime.isAfter( vm.toTime)){
        		
        		AlertService.error("From Time should be less than or equal to To Time");
        	}else{
				vm.clicked=true;
        		setReportPeriod();
        		copyFilterData();
				prepareDataForCustomer();
        		ClearCorpTradesReportService.getTradesForReport({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, 
    			customerId:vm.filterData.customerId,  instrumentId: vm.filterData.instrumentId, fromTime:vm.fromTimeDateObject , toTime:vm.toTimeDateObject  }, 
    			onGetTradesSuccess, onGetTradesFailure);  		
        	}
        	
        }
        function onGetTradesSuccess(result){
        	 
        	angular.forEach(result,function(value){
        		value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        	});
        	 vm.clearcorptradesReportDetails = result;
			 vm.clicked=false;
        }
        function onGetTradesFailure(error){
        	vm.clicked=false;
        }
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        function exportDataToExcelFile(){
      	 
      	  if(  vm.clearcorptradesReportDetails  !=null &&   vm.clearcorptradesReportDetails !=undefined &&  vm.clearcorptradesReportDetails .length>0){
		
      			vm.mimeType = 'application/vnd.ms-excel';
      			vm.extension = ".xls";  
      			ClearCorpTradesReportService.exportTradeExcel({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId, fromTime: vm.filterData.fromTimeDateObject , toTime: vm.filterData.toTimeDateObject  } 
      			, processResponse );
      		 
      	  }
        }       
        function exportDataToCsvFile(){        	
        		
        	  if( vm.clearcorptradesReportDetails !=null &&  vm.clearcorptradesReportDetails!=undefined && vm.clearcorptradesReportDetails.length>0){
			
        			vm.mimeType = 'text/csv';
        			vm.extension = ".csv";  
        			ClearCorpTradesReportService.exportTradesCsv({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
          			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId, fromTime: vm.filterData.fromTimeDateObject , toTime: vm.filterData.toTimeDateObject  } , processResponse );
        		 
        	  }
        }
	    function exportDataToPdfFile(){	    	
	    	
	      	  if( vm.clearcorptradesReportDetails !=null &&  vm.clearcorptradesReportDetails!=undefined && vm.clearcorptradesReportDetails.length>0){
				
	      			vm.mimeType = 'application/pdf';
	      			vm.extension =  ".pdf";  
	      			ClearCorpTradesReportService.exportTradesPdf({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
	        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId, fromTime: vm.filterData.fromTimeDateObject , toTime: vm.filterData.toTimeDateObject  } , processResponse );
	      		 
	      	  }
	    }
	    function processResponse(result){
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString =     vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension;   	
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}
	    function printDealTicket(trade){
        	vm.tradeResponse = angular.copy(trade);
        	console.log(typeof vm.tradeResponse.tradeTime);
        	vm.tradeResponse.tradeTime = moment(vm.tradeResponse.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
        	GenerateDealTicket.generate(vm.tradeResponse,function(result){
	        	var file = new Blob([result.data], {type: 'application/pdf'});
	      		
                var fileURL = URL.createObjectURL(file);
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "DealTicket.pdf");
                }
                else {
                     window.open(fileURL, "_blank", "fullscreen=yes");    
                }
        	});
        }
		vm.getSwapPoints = function getSwapPoints(spotExchangeRate, tradeExchangeRate){
            var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
            swapPts = (Math.round(parseFloat(swapPts)*100)/100);
            return swapPts;
		}
    }
})();