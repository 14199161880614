(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrderUpload', OrderUpload);

        OrderUpload.$inject = ['$resource'];

    function OrderUpload ($resource) {
        var resourceUrl =  '';
        
        return $resource(resourceUrl, {}, {
        	
              
                 'downloadOrderTemplate':{
     				method: 'GET',
     				url: 'api/order-upload-template-download',
     				 responseType: 'arraybuffer',
     				  transformResponse: function(data, headers) {
     	              	  return {
     	                        data: data,
     	                        headers: headers
     	                    };   
     	                } 
                 }
          
              
        });
    }
})();
