(function () {
	'use strict';
	angular
		.module('gatewayApp')
		.service("workflowSharedService", function () {
			this.viewFrom = 'OrderConfirmation'
		})
		.controller('OrderUploadConfirmationStatusController', OrderUploadConfirmationStatusController);
	OrderUploadConfirmationStatusController.$inject = ['$scope', '$state', '$rootScope', '$uibModalInstance', 'entityConstants', '$stateParams', 'screenInfo', 'Market','ExportFile','GlobalValues','MessageBox','AlertService', '$filter','$timeout','workflowSharedService'];

	function OrderUploadConfirmationStatusController($scope, $state, $rootScope, $uibModalInstance, entityConstants, $stateParams, screenInfo, Market,ExportFile,GlobalValues,MessageBox,AlertService, $filter,$timeout,workflowSharedService) {
		var vm = this;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDER_CONFIRMATION;
		vm.ScreenInfo = screenInfo;
		vm.globalValues = GlobalValues;
		$scope.noOfRecordsPerPage = vm.globalValues.getNoOfRecordsPerPageInPagination();
		vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.clear = clear;
		vm.exportDataToExcelFile = exportDataToExcelFile;
		vm.orderDTOList = $stateParams.orderDTOList;
		vm.tableName = 'orderUploadConfirmationStatus';
		vm.splitOrderConfirmationfileName = "Split_Order_Confirmation_Report";
		vm.splitOrderfileName = "Split_Order_Status_Report";
		vm.bulkOrderfileName = "Bulk_Order_Status_Report";
		vm.bulkOrderConfirmationfileName = "Bulk_Order_Confirmation_Report";
		vm.submitplaceOrder = submitplaceOrder;
        vm.viewFrom=workflowSharedService.viewFrom;

		
		
		 if(vm.viewFrom == 'OrderUpload'){
		 	/*if (vm.ScreenInfo == "OrderConfirmation") {
				$timeout(function() {
					exportDataToExcelFile();
				}, 1000);
				
		 	}*/
		}
		if(vm.ScreenInfo == 'OrderSubmission'){
			placeAllOrder(vm.orderDTOList);
			
		}


	
		
		//click the submit order button in order confirmation screen call split order screen   
		function submitplaceOrder() {
			if (vm.orderDTOList != null && vm.orderDTOList != undefined) {
				$uibModalInstance.dismiss('cancel');
				if(workflowSharedService.viewFrom == 'OrderUpload'){
					
					$state.go('order-upload-status', { orderDTOList: vm.orderDTOList }).catch(function (error) {
						// if error occurs go to create state
						$state.go('order-upload-status', { orderDTOList: vm.orderDTOList });
					})
				}else if(workflowSharedService.viewFrom == 'SplitOrder'){
					
					$state.go('split-order-status', { orderDTOList: vm.orderDTOList }).catch(function (error) {
						// if error occurs go to create state
						$state.go('split-order-status', { orderDTOList: vm.orderDTOList });
					})
				}
				
			}
		}

		function placeAllOrder(orderDTOList) {
			console.time("BulkOrder");
			const promises = orderDTOList.map(function(orderDTO) {
				if (orderDTO.uploadStatus === 'SUCCESS') {
				return makeMarketOrder(orderDTO)
				.then(function(result) {
					onOrderSucess(orderDTO, result);
				})
				.catch(function(error) {
				  onOrderFailure(orderDTO, error);
				});
			} else {
				onOrderFailure(orderDTO, null);
			  }
			});
			return Promise.all(promises)
			  .then(function() {
				console.timeEnd("BulkOrder");
				$timeout(function() {
				  onPlaceOrderSuccess();
				  exportDataToExcelFile();
				  AlertService.success($filter('translate')('gatewayApp.orderUploadConfirmationStatus.orderSubmitted'));
				}, 1000);
			  });
		  }

		function makeMarketOrder(orderDTO) {
			return new Promise( function(resolve, reject) {
				Market.placeOrder(orderDTO, resolve, reject);
			});
		}
		function onOrderSucess(orderDTO, result) {
			angular.forEach(vm.orderDTOList, function (item) {
				if (item.SystemUniqueSNo === orderDTO.SystemUniqueSNo) {
					item.orderSubmission = 'SUCCESS';
					 return;
				}
			});
			//onPlaceOrderSuccess();
		}

		function onOrderFailure(orderDTO, result) {
			angular.forEach(vm.orderDTOList, function (item) {
				if (item.SystemUniqueSNo === orderDTO.SystemUniqueSNo) {
					item.orderSubmission = 'FAILED';
					return;
				}
			});
			//onPlaceOrderSuccess();
		}

		function onPlaceOrderSuccess() {

			$rootScope.orderPlacedSuccessCount = 0;
			$scope.$emit('orderplacedsuccess');
			//$uibModalInstance.close(result);

		}

		function exportDataToExcelFile(){
			var pageNo=1;
			$scope.noOfRecordsPerPage=vm.orderDTOList.length;
			if(workflowSharedService.viewFrom == 'OrderUpload'){
				if (vm.ScreenInfo == "OrderConfirmation") {
					ExportFile.exportFile('excel',vm.tableName,vm.orderDTOList.length,$scope.noOfRecordsPerPage,undefined,pageNo,vm.bulkOrderConfirmationfileName,undefined,undefined,undefined);
				} else {
					ExportFile.exportFile('excel',vm.tableName,vm.orderDTOList.length,$scope.noOfRecordsPerPage,undefined,pageNo,vm.bulkOrderfileName,undefined,undefined,undefined);	
				}
			}else if(workflowSharedService.viewFrom == 'SplitOrder'){
				if (vm.ScreenInfo == "OrderConfirmation") {
				ExportFile.exportFile('excel',vm.tableName,vm.orderDTOList.length,$scope.noOfRecordsPerPage,undefined,pageNo,vm.splitOrderConfirmationfileName,undefined,undefined,undefined);
				}else{
					ExportFile.exportFile('excel',vm.tableName,vm.orderDTOList.length,$scope.noOfRecordsPerPage,undefined,pageNo,vm.splitOrderfileName,undefined,undefined,undefined);
				}
			}
		}

		function clear() {
			if(vm.ScreenInfo== 'OrderConfirmation'){
				onPlaceOrderSuccess();
				MessageBox.openMessageBox("Alert", "Orders will not been submitted..! Are you sure to cancel?", "YesNo", "splitOrderConfirmationClear");
			
			}else{
				
				$uibModalInstance.dismiss('cancel');
			}
		}
		$rootScope.$on('gatewayApp.messagebox.yes.splitOrderConfirmationClear', function (event, data) {
				$uibModalInstance.dismiss('cancel');
			});

			 $rootScope.$on('gatewayApp.messagebox.no.splitOrderConfirmationClear', function (event, data) {
				//do nothing
			});

	}
})();
