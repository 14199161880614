(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('FileUtil', FileUtil);

    FileUtil.$inject = ['$window', '$fileLogger', '$timeout'];

    function FileUtil ($window, $fileLogger, $timeout) {

        var service = {
        		checkImageFileExtension: checkImageFileExtension,
        		checkPanImageFileExtension : checkPanImageFileExtension,
        		checkTermsConditionExtension:checkTermsConditionExtension,
        		checkExcelFileExtension : checkExcelFileExtension,
                checkZipFileExtension : checkZipFileExtension,
        		isFileSizeValid : isFileSizeValid,
        		isFileNameValid : isFileNameValid,
        		base64ToArrayBuffer : base64ToArrayBuffer,
                downloadLogFile : downloadLogFile,
                viewFile : viewFile,
                viewFileFromBase64  : viewFileFromBase64,
                downloadFileFromBase64 : downloadFileFromBase64,
                parseExcelFile : parseExcelFile

        };

        return service;

        function checkImageFileExtension(firstFourBytes) {        	
        	 
        	  var header = "";
        	  for(var i = 0; i < firstFourBytes.length; i++) {
        	     header += firstFourBytes[i].toString(16);
        	  }
        	  console.log(header);
        	  var type = 'unknown';
        	  // Check the file signature against known types
              switch (header) {
                  case '89504e47':
                      type = 'image/png';
                      break;
                  case 'ffd8ffe0':
                  case 'ffd8ffe1':
                  case 'ffd8ffe2':
                      type = 'image/jpeg';
                      break;
                  /*case '25504446':
                      type = 'application/pdf';
                      break;*/
              }
              if(type == 'unknown'){
            		  return false;
        		
              }
              else{ return true };

        }
        function checkExcelFileExtension(firstFourBytes) {        	
       	 
      	  var header = "";
      	  for(var i = 0; i < firstFourBytes.length; i++) {
      	     header += firstFourBytes[i].toString(16);
      	  }
      	  console.log(header);
      	  var type = 'unknown';
      	  // Check the file signature against known types
            switch (header) {
                case 'd0cf11e0':
                    type = 'application/vnd.ms-excel';
                    break;
                case '504b34':
                    type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                
            }
            if(type == 'unknown'){
          		  return false;
      		
            }
            else{ return true };

      }
      function checkZipFileExtension(firstFourBytes) {        	
       	 
        var header = "";
        for(var i = 0; i < firstFourBytes.length; i++) {
           header += firstFourBytes[i].toString(16);
        }
        console.log(header);
        var type = 'unknown';
        // Check the file signature against known types
        switch (header) {
            
            case '504b34':
                type = 'application/x-zip-compressed';
                break;
            
        }
        if(type == 'unknown'){
                return false;
          
        }
        else{ return true };

  }
        
        function checkPanImageFileExtension(firstFourBytes) {        	
       	 
      	  var header = "";
      	  for(var i = 0; i < firstFourBytes.length; i++) {
      	     header += firstFourBytes[i].toString(16);
      	  }
      	  console.log(header);
      	  var type = 'unknown';
      	  // Check the file signature against known types
            switch (header) {
                case '89504e47':
                    type = 'image/png';
                    break;
                
                case 'ffd8ffe0':
                case 'ffd8ffe1':
                case 'ffd8ffe2':
                    type = 'image/jpeg';
                    break;
                case '25504446':
                    type = 'application/pdf';
                    break;
            }
            if(type == 'unknown'){
          		  return false;
      		
            }
            else{ return true };

      }
      
        function checkTermsConditionExtension(firstFourBytes) {        	

        	var header = "";
        	for(var i = 0; i < firstFourBytes.length; i++) {
        		header += firstFourBytes[i].toString(16);
        	}
        	console.log(header);
        	var type = 'unknown';
        	// Check the file signature against known types
        	switch (header) {

        	case '25504446':
        		type = 'application/pdf';
        		break;
        	}
        	if(type == 'unknown'){
        		return false;

        	}
        	else{ return true };

        }

        
      function isFileSizeValid(size, minSize, maxSize) {   
    	  if(size < minSize || size  > maxSize  ){
    		  return false;
    	  }else{
    		  return true;
    	  }
       	 
      }
      function isFileNameValid(fileName, maxlength) {        	
        	 if(fileName.length > maxlength){
        		 return false;
        	 }
        	 return true;
      }
      function base64ToArrayBuffer(base64) {
  	    var binary_string =  window.atob(base64);
  	    var len = binary_string.length;
  	    var bytes = new Uint8Array( len );
  	    for (var i = 0; i < len; i++)        {
  	        bytes[i] = binary_string.charCodeAt(i);
  	    }
  	    return bytes.buffer;
  	}
        
    function downloadLogFile(fileName){
        $fileLogger.getLogfile().then(function(l) {
            downloadFile(l, fileName);

            $timeout(function(){
                $fileLogger.deleteLogfile().then(function() {
                  console.log('Logfile deleted');
                });
            }, 1000);
        });

        
    }
    
    function downloadFile(result, fileName){
        var baseString = fileName + "_" + moment().format( 'DD_MMM_YYYY_HH_mm_ss') + ".log";  
        var blob = new Blob([ result ], { type : 'text/plain' });
        window.saveAs(blob, baseString);
    }

    function viewFile(file, fileName){
        var fileURL = URL.createObjectURL(file);
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, fileName);
        }
        else {
            window.open(fileURL, fileName);    
        }
      }



function viewFileFromBase64(base64string, fileName){
            var content = base64ToArrayBuffer(base64string);
            var bytes = new Uint8Array( content );
            var mimeType = getMimeTypeForFile(bytes.slice(0,4));
            var fileData = content;  
            var bytes = new Uint8Array( fileData );
            var file = new Blob([bytes], {type: mimeType});
      		
            viewFile(file, fileName);
        }



// function downloadFile(file){
//             var link = document.createElement("a");   
//             var objectURL = URL.createObjectURL(file);
//             link.download = file.name;
//             link.href = objectURL;
            
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//         }

        function downloadFileFromBase64(base64string, fileName){
            var content = base64ToArrayBuffer(base64string);
            var bytes = new Uint8Array( content );
            var mimeType = getMimeTypeForFile(bytes.slice(0,4));
            var fileData = content;
            var link = document.createElement("a");   
            var bytes = new Uint8Array( fileData );

            var blob = new Blob([bytes], {
                type: mimeType
            });
            
            saveAs(blob, fileName);
        }

        function parseExcelFile(data){
			  var workbook = XLSX.read(data, { type: 'array', cellDates: true ,date1904: true});
			  var worksheet = workbook.Sheets[workbook.SheetNames[0]];
			  var jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: ''});
        jsonData = convertNullToEmptyString(jsonData);
				var excelData = removeEmptyCells(jsonData);
        formatDates(excelData);
        return excelData;
			
        }
        
        function convertNullToEmptyString(data) {
            return data.map(function(row) {
              return row.map(function(cell) {
                return cell === null ? '' : cell;
              });
            });
          }
      
        function readFileAsArrayBuffer(file, callback) {
            var reader = new FileReader();
            reader.onload = function (e) {
              var data = new Uint8Array(e.target.result);
              callback(data);
            };
            reader.readAsArrayBuffer(file);
          }

        function removeEmptyCells(data) {
			    return data.filter(function(row) {
			  return row.some(function(cell) {
				return cell !== '';
			  });
			});
		  }
           function formatDates(excelData) {
            excelData.forEach(function(row) {
              for (var i = 0; i < row.length; i++) {
                if (row[i] instanceof Date) {
                  var formattedDate = new Date(moment(row[i]).add(1, 'days'));
                  row[i] = formattedDate;
                }
              }
            });
          }
    
}
})();
