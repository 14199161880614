(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('GenericUsers', GenericUsers);

    GenericUsers.$inject = ['$resource'];

    function GenericUsers ($resource) {
        var resourceUrl =  'api/generic-users';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', 
        	    url: 'api/user-import'
        	 },
         	
              
        });
    }
})();
