(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RequestReferenceNumberController', RequestReferenceNumberController);

    RequestReferenceNumberController.$inject = ['$scope', '$rootScope', '$state',  
    '$uibModalInstance', '$stateParams', 'configSettings', 'AlertService', 'CustomerRegistration'];

    function RequestReferenceNumberController($scope, $rootScope, $state, 
        $uibModalInstance, $stateParams, configSettings, AlertService, CustomerRegistration) {
        var vm = this;
        vm.clear=clear;
        vm.fetch=fetch;
        $scope.clicked=false;
        vm.refNo = $stateParams.refNo;
        vm.generateOTP = generateOTP;
        vm.resendOTP = resendOTP;
        vm.theme = 'light';

        vm.isOTPSend = false;
        
        $scope.otpInput = {
            size : configSettings.defaultOTPLength,
            type : "password",
            onDone : function(value) {
                vm.otp = value;
            },
            onChange : function(value) {
                if((value==null || value==undefined || value=='') || (value!=null && value!=undefined && value.length!=parseInt(configSettings.defaultOTPLength))){
                    vm.disableFetch = true;
                }else{
                    vm.disableFetch = false;
                }
                console.log(value);
            }
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        $scope.$watch('vm.refNo', function(value){
            if(value!=null && value!=undefined && value!=''){
                vm.disableGenerateOTP = false;
                vm.isOTPSend = false;
            }
        });

        function generateOTP(){
            CustomerRegistration.validateRejectedRequestAndSendOTP({requestReferenceNo: vm.refNo}, onValidateSuccess, onValidateFailure);
        }

        function onValidateSuccess(result){
            vm.disableGenerateOTP = true;
            vm.wrongOTPAttemptCount = 0;
            AlertService.info("OTP has been sent to your email id and mobile number");
            vm.isOTPSend = true;
            setTimeout(function(){document.getElementById('ref-no').blur()});
        }

        function onValidateFailure(){
            vm.disableGenerateOTP= false;
        }

        function resendOTP(){
            CustomerRegistration.validateRejectedRequestAndSendOTP({requestReferenceNo: vm.refNo}, onResendSuccess, onResendFailure);
        }

        function onResendSuccess(result){
            vm.wrongOTPAttemptCount = 0;
            AlertService.info("OTP has been resent to your email id and mobile number");
        }

        function onResendFailure(){

        }

        function fetch(){
            if(vm.wrongOTPAttemptCount>=configSettings.maximumOTPWrongAttemptCount) // check if the wrong otp was entered for 3 or more times 
            {
                AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.");
            }else{
                CustomerRegistration.verifyOTPAndGetCustomerRegistrationRequest({requestReferenceNo: vm.refNo, otp: vm.otp}, onVerifySuccess, onVerifyFailure);
            }
        }

        function onVerifySuccess(result){
            $rootScope.refNo = vm.refNo;
            $rootScope.requestDetailsToResubmit = result;
            $scope.$emit('fetchRequest');
            $uibModalInstance.close();
        }

        function onVerifyFailure(){
            vm.disableFetch = false;
            vm.wrongOTPAttemptCount=vm.wrongOTPAttemptCount+1;
        }
    }   
})();
