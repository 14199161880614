(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RegularHolidayDialogController', RegularHolidayDialogController);

    RegularHolidayDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','entity', 'StaticLookUp', 'RegularHoliday', 'DateUtils', 'ScreenLog', 'entityConstants'];

     function RegularHolidayDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,entity, StaticLookUp, RegularHoliday, DateUtils, ScreenLog , entityConstants) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.REGULAR_HOLIDAY;
        vm.screenLog = ScreenLog;
        vm.regularHolidaysDetailsSet = [];
        vm.dayList=getDayList();
        vm.weekNumberList=getWeekNumberList();
        vm.clear=clear;
        vm.save=save;
        $scope.clicked=false;
        $scope.showButton = false;
        $scope.validationMessage = '';
        vm.globalValues = GlobalValues;

        vm.enableFields = enableFields;
        vm.valid = true;
        vm.resubmitRegularHoliday = resubmitRegularHoliday;
       	
        
        if(entity!=null){
            	vm.regularHolidaysHeader=entity;
            	//vm.regularHolidaysHeader.effectiveFromDate = DateUtils.convertDateTimeFromServer(vm.regularHolidaysHeader.effectiveFromDate);
            	getRegularHolidayDetailList();
           	 	$scope.showButton=false;
        }
		
        if(workflowSharedService.viewFrom == 'regularholidays'){
        	vm.regularHolidaysHeader=entity;
        	getRegularHolidayDetailList();
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'regularholidays_resubmit') {
        	vm.regularHolidaysHeader = angular.fromJson($rootScope.payload); 
        	vm.screenLog.screenLog(vm.screenName, vm.regularHolidaysHeader.id==null?0:vm.regularHolidaysHeader.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	getRegularHolidayDetailList();
        	vm.disableForm = false;
        	$scope.showButton=true;
        }
        else if(workflowSharedService.viewFrom == 'regularholidays_resubmit_delete') {
        	vm.regularHolidaysHeader = angular.fromJson($rootScope.payload);
        	getRegularHolidayDetailList();
        	vm.disableForm = true;
        	$scope.showButton=true;
        }
        
        function getRegularHolidayDetailList(){
        	 var regularHolidaysDetailsArr = vm.regularHolidaysHeader.regularHolidaysDetailsList;
        	 vm.daySelectionNumberList = {};
        	 vm.weekSelectionNumberList = {};
             angular.forEach(regularHolidaysDetailsArr, function(value, key) 
             {
            	 var dayNumber = [];
            	 
            	 var index = value.dayNumber.id - 1;
            	 dayNumber.push(value.dayNumber);
            	 if(vm.daySelectionNumberList[index]==null){vm.daySelectionNumberList[index] = [];} 
            	 vm.daySelectionNumberList[index].push(value.dayNumber);
            	 if(vm.weekSelectionNumberList[index]==null){vm.weekSelectionNumberList[index] = [];} 
            	 vm.weekSelectionNumberList[index].push(value.weekNumber);
             });
        }

        function getDayList(){
        	return StaticLookUp.getDayNumberList();
        }
        
        function getWeekNumberList(){
        	return StaticLookUp.getWeekNumberList();
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {  
        	prepareRegularHolidaysHeader();
        	saveRegularHolidays();
         }
        
        function prepareRegularHolidaysHeader(){
        	vm.regularHolidaysDetailsSet = [];
        	angular.forEach(vm.daySelectionNumberList, function(value, $index) {
        		if(value.length>0){
            		angular.forEach(vm.weekSelectionNumberList[$index], function(value2, i){
            			vm.regularHolidaysDetailsSet.push({'dayNumber': value[0], 'weekNumber': value2});
            		})
            		vm.regularHolidaysHeader.regularHolidaysDetailsList = angular.copy(vm.regularHolidaysDetailsSet);
        		}
        	});
        }
        
		
		function saveRegularHolidays(){
			//vm.regularHolidaysHeader.effectiveFromDate = new Date();
        	if(vm.regularHolidaysHeader.id==null){
        		RegularHoliday.save({orgId: GlobalValues.getOrgId()}, vm.regularHolidaysHeader, onSaveSuccess, onSaveError);
        	}else if(vm.regularHolidaysHeader.id!=null){
        		RegularHoliday.update({orgId: GlobalValues.getOrgId()}, vm.regularHolidaysHeader, onSaveSuccess, onSaveError);
        	}
		}

        
        function onSaveSuccess (result) {
        	$scope.$emit('regularHolidayRefresh');
        	 $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	$scope.clicked = false;
        }
        
        function resubmitRegularHoliday() {
        	//vm.regularHolidaysHeader.effectiveFromDate = new Date();
        	prepareRegularHolidaysHeader();
       		RegularHoliday.resubmit({"taskId" : $rootScope.taskID}, vm.regularHolidaysHeader, onResubmitSuccess, onResubmitError);
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {}
        function enableFields(elementId){
        	var enableField= false;
        	var field = angular.element(elementId) ;
        	
        	console.log(angular.toJson(field));
        	var value = field.prop( "checked" );
        	console.log( elementId + "-" + angular.toJson(value));
        	if(field!==undefined){
        		enableField = value;
        	}
        	return enableField;
        }
        
       
     }   
})();
