(function() {
	'use strict';
	angular
		.module('gatewayApp')
		.factory('ModifyCustomerDetailsService', ModifyCustomerDetailsService);

	ModifyCustomerDetailsService.$inject = [ '$resource' ];

	function ModifyCustomerDetailsService($resource) {
		var resourceUrl = 'api/trading-branch-edi';

		return $resource(resourceUrl, {}, {
			
			'resubmit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId',
					oldBranchId : '@oldBranchId'
				},
				url : 'api/tasks/resubmit-modify-customer-details',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},			
			'editTradingBranch':{
        		method: 'PUT',
  				params: {orgId:'@orgId', oldBranchId:'@oldBranchId'},
  				url: 'api/trading-branch-edit',
  				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}      					
			},
			'approve': {
               method: 'GET',
               params: {taskId:'@taskId'},
               url: 'api/tasks/:taskId/approve'
			}

		});
	}
})();
