(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('CustomerTACUploadByBankApprovalController', CustomerTACUploadByBankApprovalController);

	CustomerTACUploadByBankApprovalController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','CustomerTACUploadByBank','entity','DateUtils','Workflow','FileUtil','AlertService','$filter','$uibModalInstance','systemSettings','TacViewForEntitiesUtility','globalValue','TACViewForEntitiesService','configurationSetting'];

	function CustomerTACUploadByBankApprovalController($scope, $state, $rootScope, GlobalValues, workflowSharedService,CustomerTACUploadByBank,entity,DateUtils,Workflow,FileUtil,AlertService,$filter,$uibModalInstance,systemSettings,TacViewForEntitiesUtility,globalValue,TACViewForEntitiesService,configurationSetting) {
		var vm = this;
		vm.bankForCustomerTacDTO=entity;   

		vm.clear=clear;
		vm.clicked=false;
		$scope.showButton = false;
		$scope.showApproveRejectButton=false;
		$scope.showDiscardButton = false;
		$scope.showResubmitButton=false;
		vm.globalValues = GlobalValues;
		vm.discardTAC = discardTAC;
		vm.rejectTAC = rejectTAC;
		vm.approveTAC = approveTAC;
		vm.openClearcorpTac = TacViewForEntitiesUtility.onGetCcilTacForBank;
        vm.messageBoxName = "customerTACUploadApproval";
        vm.checkTACFile = false;
		vm.resubmitTAC = resubmitTAC;
		vm.viewMode=false;
		vm.configSettings = globalValue;
		vm.maxFileNameLength = (vm.configSettings.uploadedFileNameLength != null && vm.configSettings.uploadedFileNameLength != undefined && vm.configSettings.uploadedFileNameLength != '') ?vm.configSettings.uploadedFileNameLength : 150 ;
		var minFileSizeinBytes = 0;
		var maxFileSizeinBytes = 500;
		vm.maximumFileSizeinKB = (configurationSetting != null && configurationSetting != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != null && configurationSetting.maximumFileSizeTermsAndConditionsUpload != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != '') ? parseInt(configurationSetting.maximumFileSizeTermsAndConditionsUpload) : 500 ;
		vm.minimumFileSizeInKB = 0;
		if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
			minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
		}
		if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){

			maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
			if(vm.maximumFileSizeinKB == 1024){
				vm.showInKB = false;
				vm.tacFileUploadSize = 1;
			}else{
				vm.showInKB = true;
				vm.tacFileUploadSize = vm.maximumFileSizeinKB;
			}
		}
		
		var Extensions = "pdf";
		vm.minDate = new Date();
		vm.minDate.setDate(new Date().getDate() + 1);

		$scope.showButton = false;
		vm.orgId = vm.globalValues.getOrgId();
		
		if(systemSettings != null && systemSettings != undefined){
			vm.businessDate  = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
			vm.businessDatePlusOne =  moment(vm.businessDate).add(1,'days').toDate();
			vm.minDate = angular.copy(vm.businessDatePlusOne);
		}

		if(workflowSharedService.viewFrom == 'customerTermsConditionUploadByBanktodolist_resubmit') {
			vm.bankForCustomerTacDTO =  angular.copy(angular.fromJson($rootScope.payload));
			vm.bankForCustomerTacDTO.enforcingDate = new Date(vm.bankForCustomerTacDTO.tacMaster.enforcingDate);

			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=true;
			vm.viewMode=false;
		}

		else if(workflowSharedService.viewFrom == 'customerTermsConditionUploadByBank_todolist_checker') {
			vm.bankForCustomerTacDTO =  angular.fromJson($rootScope.payload); 
			vm.bankForCustomerTacDTO.enforcingDate =DateUtils.convertLocalDateFromServer( vm.bankForCustomerTacDTO.tacMaster.enforcingDate);
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;
		}
		else if(workflowSharedService.viewFrom == 'customerTermsConditionUploadByBank_todolist_maker') {
			vm.bankForCustomerTacDTO =  angular.fromJson($rootScope.payload); 
			vm.bankForCustomerTacDTO.enforcingDate =DateUtils.convertLocalDateFromServer( vm.bankForCustomerTacDTO.tacMaster.enforcingDate);
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			$scope.showResubmitButton=false;
		}
		else if(workflowSharedService.viewFrom=='customerTermsConditionUploadByBank_auditlog'){
			vm.bankForCustomerTacDTO =  angular.fromJson($rootScope.payload); 
			vm.bankForCustomerTacDTO.enforcingDate =DateUtils.convertLocalDateFromServer( vm.bankForCustomerTacDTO.tacMaster.enforcingDate);			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;

		}else{
			vm.bankForCustomerTacDTO=entity;
			vm.bankForCustomerTacDTO.enforcingDate = vm.minDate;
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;
			vm.viewMode=false;
		}
		function base64ToArrayBuffer(base64) {
			var binary_string =  window.atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array( len );
			for (var i = 0; i < len; i++)        {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		}

		vm.openTACPreview = openTACPreview;
		function openTACPreview(pdfContent){
			TacViewForEntitiesUtility.previewTAC(pdfContent.fileContent, pdfContent.fileName);
		}

		/*function openClearcorpTac(){
			CustomerTACUploadByBank.getAgreedTacForBank({orgId:vm.orgId},onGetTacSuccess,onGetTacFailure)
		}

		function onGetTacSuccess(result){

			if(result != null || result != undefined){
				TacViewForEntitiesUtility.previewTAC(result.tacMaster.tacFiles.fileContent, result.tacMaster.tacFiles.fileName);
			}else{
				AlertService.error($filter('translate')('gatewayApp.customerTacUploadByBank.bankNotAgreedTac'));
			}
		}*/

		function onGetTacFailure(error){

		}


		function clear() {
			$uibModalInstance.dismiss('cancel');
		}


		function approveTAC() { 
			CustomerTACUploadByBank.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
			     	
		}

		function onApproveSuccess(result) {
			$uibModalInstance.dismiss('cancel');
			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onApproveError(error) {
			vm.clicked = false;
		}

		function rejectTAC() { Workflow.rejectTask($rootScope.taskID); }

		function discardTAC() {
			CustomerTACUploadByBank.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
			$uibModalInstance.dismiss('cancel');	
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onDiscardError(error) {

		}
		
		function resubmitTAC() {
			if(vm.bankForCustomerTacDTO.enforcingDate == null || vm.bankForCustomerTacDTO.enforcingDate == undefined || moment(vm.minDate).isAfter(DateUtils.convertLocalDateToServer(vm.bankForCustomerTacDTO.enforcingDate))){
				vm.clicked = false;
				AlertService.error($filter('translate')('gatewayApp.customerTacUploadByBank.enforcingDateGreater'));
				
			}
			else{
				if(vm.termsCondition != null){
					var fd1 = new FormData();
					//vm.bankForCustomerTacDTO.tacMaster.tacFiles.fileName = vm.termsCondition.name;
					//vm.bankForCustomerTacDTO.tacMaster.enforcingDate = DateUtils.convertLocalDateToServer(vm.bankForCustomerTacDTO.enforcingDate);
					fd1.append('id', JSON.stringify($rootScope.taskID));
					var enforcingDate = DateUtils.convertLocalDateToServer(vm.bankForCustomerTacDTO.enforcingDate);
					fd1.append('enforcingDate', enforcingDate);
					fd1.append('file', vm.termsCondition);
					CustomerTACUploadByBank.resubmit( fd1, onResubmitSuccess, onResubmitError);


				}
			}
			
		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}        
		function onResubmitError(error) {
			vm.clicked = false;
		}

		//validate terms and condition 
		$scope.$watch('vm.termsCondition', function(value){
		
			
			if(value!= undefined && value!=null){
				
				if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
					vm.invalidTermsConditionSize = true;
					if(vm.showInKB == true){
						vm.invalidTermsConditionSixeText = "Maximum of " + vm.tacFileUploadSize +" KB file size is allowed";
					}else{
						vm.invalidTermsConditionSixeText = "Maximum of " + vm.tacFileUploadSize +" MB file size is allowed";
					}
					vm.invalidTermsCondition = false;
					vm.invalidTermsConditionName = false;
					vm.termsCondition=null;
					angular.element("input[type='file']").val(null);

				}else{
					vm.inputTermsCondition=vm.termsCondition.name;
					vm.invalidTermsConditionSize = false;
					vm.invalidTermsCondition = false;
					vm.invalidTermsConditionName = false;
					vm.fileExtension = vm.inputTermsCondition.substring(vm.termsCondition.name.lastIndexOf(".") + 1);
					var fileNameWithoutExtension  =  vm.inputTermsCondition.substring(0,vm.termsCondition.name.lastIndexOf("."));
					//check for extension
					if(!(Extensions == (vm.fileExtension.toLowerCase()))) {
						angular.element("input[type='file']").val(null);
						vm.termsCondition=null;
						vm.inputTermsCondition=null;
						vm.invalidTermsCondition = true;
						vm.invalidTermsConditionSize = false;
						vm.invalidTermsConditionName = false;
						vm.invalidTermsConditionText = "Accepted file format .pdf";
					}
					else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
	    				 angular.element("input[type='file']").val(null);
	    				 vm.termsCondition=null;
				         vm.inputTermsCondition=null;
				         vm.invalidTermsCondition = false;
				         vm.invalidTermsConditionSize = false;
				         vm.invalidTermsConditionName = true;
				         vm.invalidTermsConditionNameText = "Uploaded pdf file name should not exceed " + vm.maxFileNameLength+ " charaters.";
				         
	    			 }

					else{ // check for malicious file with correct extension
						var reader = new FileReader();
						reader.readAsArrayBuffer(value.slice(0,4));
						reader.onloadend = function(e){
							if (e.target.readyState === FileReader.DONE) {
								var arrayBuffer = e.target.result;
								var bytes = new Uint8Array( arrayBuffer );
								if(!FileUtil.checkTermsConditionExtension(bytes)){
									angular.element("input[type='file']").val(null);
									vm.termsCondition=null;
									vm.inputTermsCondition=null;
									vm.invalidTermsCondition = true;
									vm.invalidTermsConditionSize = false;
									vm.invalidTermsConditionName = false;
									vm.invalidTermsConditionText = "Accepted file format .pdf";
								}
								else{
									vm.invalidTermsConditionSize = false;
									vm.invalidTermsCondition =false;
									vm.invalidTermsConditionName = false;


								}
								$scope.$digest();
							}
						}; 
					}
				}
			}
			else{
				angular.element("input[type='file']").val(null);

				vm.inputTermsCondition=null;
			}
		});
		
	}   
})();
