(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'defaultMarketSessionSettings'
        })
        
        .controller('DefaultMarketSessionSettingsController', DefaultMarketSessionSettingsController);

    DefaultMarketSessionSettingsController.$inject = ['$scope', '$state', 'DefaultMarket', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','DateUtils','$filter','$timeout'];

    function DefaultMarketSessionSettingsController($scope, $state, DefaultMarket , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,DateUtils,$filter,$timeout) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.DEFAULT_MARKET_SESSION_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "defaultMarketSessionSettings";
        vm.currentBusinessDate;
        vm.convertedCurrentBusinessDate;
        vm.permitDefaultMarketEditAction = EntityPermissions.hasPermission('DefaultMarketSessionSettings.EDIT');
        $scope.refresh=refresh;
        
        logScreenAccess();
        loadAll();
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
    
       
      function loadAll(){
    	   DefaultMarket.query({"orgId": GlobalValues.getOrgId()},function(result) {
    		   
    		   angular.forEach(result, function(value){
    			   		
		    			  var closeTime = value.inputCloseTime.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [value.inputCloseTime];
		    			   
		    			  closeTime = closeTime.slice (1);  
		    			  closeTime[5] = ' ';
		    			  closeTime[6] = +closeTime[0] < 12 ? 'AM' : 'PM'; 
		    			  closeTime[0] = +closeTime[0] % 12 || 12;
		    			  if(closeTime[0]<=9){
		    				  closeTime[0]=0+""+closeTime[0];
		    			  }
    				    value.inputCloseTime = closeTime.join (''); 
    				    /*if(value.inputCloseTime='12 PM'){
    				    	value.inputCloseTime = '12:00 PM';
    				    }*/
    				    
    				    
    				    var openTime = value.inputOpenTime.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [value.inputOpenTime];
    	    			   
    				    openTime = openTime.slice (1);  
    				    openTime[5]= ' ';
    				    openTime[6] = +openTime[0] < 12 ? 'AM' : 'PM'; 
    				    openTime[0] = +openTime[0] % 12 || 12;
    				    if(openTime[0]<=9){
    				    	openTime[0]=0+""+openTime[0];
    				    }
    				    value.inputOpenTime = openTime.join (''); 
    				  });
    				  
    				  
    				  vm.defaultMarket = result;
    				
    		   });
              
         
    	   
      }
      
      $scope.toggle = true;
      function refresh() {
          $scope.toggle = false;
          $scope.refreshFlag = false;
          loadAll();
          if ($scope.toggle == false) {
              $timeout(function() {
                  $scope.toggle = true;
              }, 2000);
              $scope.refreshFlag = false;
          }
      };
       
    }
})();
