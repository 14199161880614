
(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            ncyBreadcrumb: {
                label: 'Home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderActivity');
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        })
        .state('cspl-control', {
            parent: 'app',
            url: '/cspl-control',
            data: {
           	 authorities: ['ROLE_USER'],
             pageTitle: 'gatewayApp.businessUnit.home.title'
            },
            ncyBreadcrumb: {
                label: 'CSPL Controls',
                parent: 'home'
              },
            views: {
                'content@': {
                    templateUrl: 'app/controllers/csplcontrol/csplcontrol.html',
                    controller: 'CSPLControlController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('swapQuotes');
                    return $translate.refresh();
                }]
            }
        })
       .state('pre-order-confirmation', {
            parent: 'home',
            url: '/pre-order-confirmation',
            params: {closeTime: null, showPreOrderConfirmation: null, sessionTimedOut: null, cancelSelected: null,showOrderConfirmationForSplitOrder:null,noOfSplit:null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.preOrderConfirmation.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal,$rootScope) {
                $uibModal.open({
                    templateUrl: 'app/entities/pre-order-confirmation/pre-order-confirmation.html',
                    controller: 'PreOrderConfirmationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        },
                        systemTime: ['Market',  function(Market ) {
         					return   Market.getCurrentDateTime().$promise;
                         }],
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $rootScope.$emit('orderplacedsuccess');
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('pre-order-confirmation');
                	$translatePartialLoader.addPart('expiry-time');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       .state('add-remarks', {
            parent: 'home',
            url: '/add-remarks',
            params: {remarks: null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.addRemarks.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/add-remarks/add-remarks.html',
                    controller: 'AddRemarksController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('add-remarks');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       .state('expiry-time', {
            parent: 'home',
            url: '/expiry-time',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.expiryTime.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/expiry-time/expiry-time.html',
                    controller: 'ExpiryTimeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('expiry-time');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       .state('market-depth-expand', {
            parent: 'home',
            url: '/market-depth',
            params: {instrument: null, marketDepth: null, ibMarketDepth: null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.marketDepthExpand.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/market-depth/market-depth-expand.html',
                    controller: 'MarketDepthExpandController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('market-depth-expand');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       .state('market-statistics', {
            parent: 'home',
            url: '/market-statistics',
            params: {instrument: null, currentBusinessDate: null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.marketStatistics.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/market-statistics/market-statistics.html',
                    controller: 'MarketStatisticsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: ['MarketMovement', 'GlobalValues', function(MarketMovement, GlobalValues) {
                            return MarketMovement.query({instrument : $stateParams.instrument, businessDate : $stateParams.currentBusinessDate}).$promise;
                        }],
                        prevClose: ['MarketMovement', 'GlobalValues', function(MarketMovement, GlobalValues) {
                            return MarketMovement.getDailyClosingPriceList({"instrument": $stateParams.instrument}).$promise;
                        }]
                        
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('market-statistics');
                    $translatePartialLoader.addPart('marketMovement');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       .state('order-config', {
            parent: 'home',
            url: '/order-config',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.orderConfig.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-config/order-config.html',
                    controller: 'OrderConfigController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:true});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('order-config');
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('home-page-reports', {
            parent: 'home',
            url: '/home-page-reports',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.homePageReports.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/home-page-reports/home-page-reports.html',
                    controller: 'HomePageReportsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('swap-quotes-home-page', {
            parent: 'home',
            url: '/swap-quotes-home-page',
            params: {account: null, defaultTradingBank: null,
                bankComparativeDTO: null,
                bankComparativeDTOInPrice: null,
                markupSettings: null
            },
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.swapQuotesHomePage.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-view/swap-quotes-home-page.html',
                    controller: 'SwapQuotesHomePageController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function(){
                            
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
          .state('fx-support', {
            parent: 'home',
            url: '/fx-support',
           
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.contactUs.title" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contact-us/contact-us.html',
                    controller: 'ContactUsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function(){
                            
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:false});
                }, function() {
                    $state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('login');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('split-order-confirmation', {
            parent: 'home',
            url: '/split-order-confirmation',
            params: {orderDTOList:[],showOrderConfirmationForSplitOrder:null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.orderUploadConfirmationStatus.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal,$rootScope) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-upload-confirmation-status/order-upload-confirmation-status.html',
                    controller: 'OrderUploadConfirmationStatusController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function(){
                        	
                        },
                        systemTime: ['Market',  function(Market ) {
         					return   Market.getCurrentDateTime().$promise;
                         }],
                         screenInfo : function(){
                            return "OrderConfirmation";
                        },
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('orderUploadConfirmationStatus');
                	$translatePartialLoader.addPart('expiry-time');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('split-order-status', {
            parent: 'home',
            url: '/split-order-status',
            params: {orderDTOList:[]},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.splitOrderStatus.main" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal,$rootScope) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-upload-confirmation-status/order-upload-confirmation-status.html',
                    controller: 'OrderUploadConfirmationStatusController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function(){
                        	
                        },
                        systemTime: ['Market',  function(Market ) {
         					return   Market.getCurrentDateTime().$promise;
                         }],
                         screenInfo : function(){
                            return "OrderSubmission";
                        },
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('orderUploadConfirmationStatus');
                	$translatePartialLoader.addPart('expiry-time');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
    }
})();