(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage', 'GlobalValues'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage, GlobalValues) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            if (!config || !config.url || /^http/.test(config.url)) return config;

            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            if(!isInvalid(GlobalValues.getUser())){
                config.headers.guid = constructGuid();
            }
            return config;
        }
            
        function isInvalid(object) {
        	return object == undefined || object == null || !angular.isObject(object);
        }

        //Generate GUID for each request and append with the user id
        function constructGuid(){
            return GlobalValues.getUser().id + '_' + generateGuid();
        }

        //Generate GUID
        function generateGuid(){
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
                var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
                return v.toString(16);  
             }); 
        }
    }
})();
