(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customer-markup-view-report', {
            parent: 'entity',
            params:{screen: 'CustomerMarkupView'}, 
            url: '/customer-markup-view-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.markupReportForClearCorp.main" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/clearcorp-markup-report/clearcorp-markup-report.html',
                    controller: 'ClearCorpMarkupReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clearcorpMarkupReport');                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderActivity');
                    return $translate.refresh();
                }]
               
            }
        })
                 
        
         
         };
    })();
