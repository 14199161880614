(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UploadDialogController', UploadDialogController);

    UploadDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','UploadService','entity', 
    	'DateUtils', 'ScreenLog', 'Workflow','MessageBox','AlertService','$filter','entityConstants'];

     function UploadDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,UploadService,entity,
    		 DateUtils,ScreenLog,Workflow,MessageBox, AlertService, $filter, entityConstants) {
        var vm = this;
        vm.uploadData = [];
        vm.entity = entity;
        vm.screenName = 'MessageUpload';
        vm.screenLog = ScreenLog;
        vm.approve = approve;
        vm.reject = reject;
        vm.discard = discard;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.globalValues = GlobalValues;
        vm.dataColumnSeperator = ",";
        vm.clear = clear;
        vm.alignmentArray = [];
        vm.rightAlignHeaderList = ['Bid Spread','Offer Spread'];

        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        
        if(workflowSharedService.viewFrom == 'messageuploadtodolist_checker') {
        	vm.entity = angular.fromJson($rootScope.payload); 
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'messageuploadtodolist_maker') {
        	vm.entity = angular.fromJson($rootScope.payload); 
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom == 'messageuploadtodolist_auditLog') {
        	vm.entity = angular.fromJson($rootScope.payload); 
        	$scope.showButton=false;
        	$scope.showDiscardButton = false;
        }
        
        vm.businessDate = DateUtils.convertLocalDateFromServer(vm.entity.businessDate);
        if(vm.entity != null && vm.entity.fileContent != null){
            vm.uploadData = vm.entity.fileContent.replace('\r\n', '\n').split('\n');
            
            if(vm.uploadData != null && vm.uploadData.length > 0){
                vm.header = vm.uploadData[0];
                vm.uploadData.shift();
            	vm.messageType = vm.entity.shortName;	
            	var headerList = vm.header.split(',');
            	
            	angular.forEach(headerList, function(value,index) {
            			if(vm.rightAlignHeaderList.includes(value)){
            				vm.alignmentArray[index] = "right";
            			}
            			else{
            				vm.alignmentArray[index] = "left";
            			}
            		});
            }
            
            console.log(vm.header);
        }

        function approve() { 
        	if(   workflowSharedService.importType=='LimitSettings' ||  workflowSharedService.importType=='PendingOrderReport' ){
				if( workflowSharedService.importType == 'PendingOrderReport'){
					vm.screenLog.screenLog('PendingOrderReport', 0, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
						//  MessageBox
						//  .showMessageBox(
						// 		 "Alert",
						// 		 "Cancel Order approval is still in progress... Please wait",
						// 		 "autocloseupload");
						UploadService.orderApprove({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);
					}else{
						UploadService.orderApproveImport({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);
					}
        	} else{
        		if( workflowSharedService.importType == 'HomeBranchIFSCUpload'){
                vm.screenLog.screenLog('HomeBranchIFSCUpload', 0, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
        			 MessageBox
 					.showMessageBox(
 							"Alert",
 							"Home Branch Import approval is still in progress... Please wait",
 							"autocloseupload");
        		}
        		UploadService.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);   
        	}
        	      	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	if(   workflowSharedService.importType == 'HomeBranchIFSCUpload' 
			// ||  workflowSharedService.importType=='PendingOrderReport'
			){        			
   			 	MessageBox.closeMessageBox("autocloseupload");
        	}
        	if(workflowSharedService.importType!='PendingOrderReport' && result!="") {
        		exportException(result, true, 'Import' + '_error');
        	}else if(workflowSharedService.importType!='PendingOrderReport'){
        		//MessageBox.showMessageBox("Alert", " File has been uploaded successfully.  ", "Ok");
        		AlertService.success($filter('translate')('error.upload.success'));
        	}
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        
        function exportException(JSONData, showHeader, fileName) {

			if (JSONData != "") {
				var arrData = typeof (JSONData) != 'object' ? JSON
						.parse(JSONData) : JSONData;
						var out = arrData[arrData.length-1];
						if (out == "failure" || out == 1) {
							MessageBox.showMessageBox("Alert",
									"File upload failed! Please check the error log. ",
									"Ok");
							//generateFile(arrData,fileName);
						} 
						else if(out=="invalid"){
								MessageBox.showMessageBox("Alert",
										"Invalid file",
										"Ok");
							}
								else {
					MessageBox
							.showMessageBox(
									"Alert",
									"Partial success! Please check the error log for the failed records.  ",
									"Ok");
				}
						
				var csv = "";
				
				
				
				console.log(out);
				
				

				for (var i = 0; i <= arrData.length-2; i++) {

					var row = "";

					row += arrData[i];
					
					/*for ( var index in arrData[i]) {
						
					}
*/
					row = row.slice(0, row.length - 1);

					csv += row + '\r\n';

				}
				
				console.log(csv);

				if (csv != '') {
					//csv = 'test, test'
					var uri = 'data:text/csv;charset=UTF-8,' + escape(csv);

					var link = document.createElement("a");
					link.href = uri;

					link.style = 'hidden';
					link.download = fileName + '.csv';

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

				}
			}

		}
        function onApproveError(error) {
        	if(   workflowSharedService.importType == 'HomeBranchIFSCUpload' 
		//	||  workflowSharedService.importType=='PendingOrderReport'
			){        			
   			 	MessageBox.closeMessageBox("autocloseupload");
        	}
        	$scope.clicked=false;
        }
       
        function reject() { Workflow.rejectTask($rootScope.taskID); }

        function discard() {
        	UploadService.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
      
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $rootScope.$on('gatewayApp.messagebox.clear.autocloseupload',function (event, data){
            //do nothing
        });

     }   
})();
