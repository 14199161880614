(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('InstrumentView', InstrumentView);

    InstrumentView.$inject = ['$resource'];

    function InstrumentView ($resource) {
        var resourceUrl =  'api/instrument-view/:id';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId'},
        	    url: 'api/orgId/:orgId/instrument-view'
        	 }
              
        });
    }
})();
