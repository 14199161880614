(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ParticipantCertificateDialogController', ParticipantCertificateDialogController);

    ParticipantCertificateDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance',
    	'UploadDownloadParticipantCertificates','entity','DateUtils','Workflow','$filter'];

     function ParticipantCertificateDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance
    		 ,UploadDownloadParticipantCertificates,entity,DateUtils,Workflow, $filter) {
        var vm = this;
        vm.amcSetting=entity;   
        
        vm.clear=clear;
       
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
       
        vm.globalValues = GlobalValues;
        vm.discardCertificate = discardCertificate;
        vm.rejectCertificate= rejectCertificate;
        vm.approveCertificate= approveCertificate;
        vm.viewMode=false;
        
        vm.organisation = vm.globalValues.getOrganisation().organisationType.shortName;
       
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
       
           
        if(workflowSharedService.viewFrom == 'participantCertificate'){
        	vm.certificate=entity;
        	vm.certificate.expiryDate = $filter('date')(vm.certificate.expiryDate, 'dd-MMM-yyyy');
        	vm.certificate.issueDate = $filter('date')(vm.certificate.issueDate, 'dd-MMM-yyyy');
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'participantCertificate_todolist_checker') {
        	vm.certificate =  angular.fromJson($rootScope.payload); 
        	vm.certificate.expiryDate = $filter('date')(vm.certificate.expiryDate, 'dd-MMM-yyyy');
        	vm.certificate.issueDate = $filter('date')(vm.certificate.issueDate, 'dd-MMM-yyyy');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'participantCertificate_todolist_maker') {
        	vm.certificate =  angular.fromJson($rootScope.payload); 
        	vm.certificate.expiryDate = $filter('date')(vm.certificate.expiryDate, 'dd-MMM-yyyy');
        	vm.certificate.issueDate = $filter('date')(vm.certificate.issueDate, 'dd-MMM-yyyy');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='participantCertificate_auditlog'){
        	vm.certificate =  angular.fromJson($rootScope.payload); 
        	vm.certificate.expiryDate = $filter('date')(vm.certificate.expiryDate, 'dd-MMM-yyyy');
        	vm.certificate.issueDate = $filter('date')(vm.certificate.issueDate, 'dd-MMM-yyyy');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
      
        function approveCertificate() { 
        	UploadDownloadParticipantCertificates.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectCertificate() { Workflow.rejectTask($rootScope.taskID); }

        function discardCertificate() {
        	UploadDownloadParticipantCertificates.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
        
        

       
     }   
})();
