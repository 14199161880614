(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'swapQuotes'
        })
        
        .controller('SwapQuotesController', SwapQuotesController);

    SwapQuotesController.$inject = ['$scope', '$state', 'workflowSharedService', 'SwapQuotes', 'GlobalValues', 'EntityPermissions', 'ScreenLog', 'entityConstants','DateUtils', 'AlertService', '$filter'];
    function SwapQuotesController($scope, $state, workflowSharedService, SwapQuotes, GlobalValues, EntityPermissions, ScreenLog , entityConstants, DateUtils, AlertService,$filter ) {
    	
    	var vm = this;
        vm.globalValues = GlobalValues;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SWAP_QUOTES;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        //vm.currentDate = new Date();
        vm.currentDate = vm.globalValues.getSystemSettings().businessDate;
       // DateUtils.convertDateTimeFromServer
       // DateUtils.convertLocalDateToServer
    //    vm.fromDate = angular.copy(vm.currentDate);
      //  vm.toDate = angular.copy(vm.currentDate);
        vm.loadAll = loadData;
        vm.editSwapQuotes = editSwapQuotes;
        vm.currentBusinessDate = vm.globalValues.getSystemSettings().businessDate;
       vm.fromDate = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
       vm.toDate = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
              
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		workflowSharedService.viewFrom = "swapQuotes";
		
		vm.permitSwapQuotesEditEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
        
		function loadData(){
		
			var fromServerDate = DateUtils.convertLocalDateToServer(vm.fromDate);
			var toServerDate = DateUtils.convertLocalDateToServer(vm.toDate);
			if(moment(vm.fromDate).isAfter(vm.currentDate,'day') || moment(vm.toDate).isAfter(vm.currentDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.forwardDateViewError'));
				//AlertService.error("Swap Quotes cannot be viewed for future dates");
			}else if(moment(vm.fromDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.fromDateError'));
				//AlertService.error("");
			}else{
				SwapQuotes.gatAllSwapQuotes({ orgId : vm.globalValues.getOrganisation().id , fromDate : fromServerDate, toDate : toServerDate} , function(result) {
	                vm.swapQuotes = result;                
	            });
			
			
			}
		}
       
        logScreenAccess();
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        //loadData();
        function editSwapQuotes(swapQuotes,$event){
        	//$event.preventDefault();
        	if(swapQuotes.businessDate != DateUtils.convertLocalDateToServer(vm.currentDate) ){
       			AlertService.error($filter('translate')('gatewayApp.swapQuotes.pastDateEditError'));
       			
       		}else{
       			ScreenLog.screenLog(vm.screenName,swapQuotes.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
       			$state.params = {id:swapQuotes.id}
       			var state = '.edit';
       			$state.go(state,{id:swapQuotes.id});
       		}
        }


    }
})();