(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'organisation'
         })
        .controller('ManageOrganisationController', ManageOrganisationController);

    ManageOrganisationController.$inject = ['$scope', '$state', 'workflowSharedService','Organisation', 'GlobalValues',  'entity', 'EntityPermissions'];

    function ManageOrganisationController($scope, $state, workflowSharedService,Organisation,GlobalValues, entity, EntityPermissions) {
        var vm = this;
        workflowSharedService.viewFrom = "organisation";

        vm.organisation=entity;
        
        vm.permitOrganisationEditEntityAction = EntityPermissions.hasPermission('Organisation.EDIT')
        vm.permitOrganisationViewEntityAction = EntityPermissions.hasPermission('Organisation.VIEW')

        
        	

 
    }
})();
