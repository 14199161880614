(function() {
	'use strict';
	angular.module('gatewayApp').factory('DealingAdminApplicationWideSettings', DealingAdminApplicationWideSettings);

	DealingAdminApplicationWideSettings.$inject = [ '$resource' ];

	function DealingAdminApplicationWideSettings($resource) {
		var resourceUrl = 'api/applicationWideSettings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				url : 'api/configuration-settings',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
            'getConfigValueForConfigName': {
                method: 'GET',                      
 				params : {
 					configName : '@configName'
 				},
                url: 'api/get-configuration-setting-value',
                transformResponse : function(data) {
 					if (data) {
 						data = angular.fromJson(data);
 					}
 					return data;
 				}
             }
		});
	}
})();
