(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('FloatUtils', FloatUtils);

    FloatUtils.$inject = ['GlobalValues', 'StringUtils', '$filter'];

    function FloatUtils(GlobalValues, StringUtils, $filter) {

        var service = {
            getAbsFloat: getAbsFloat,
            getFloat: getFloat,
            getFixedFloat: getFixedFloat,
            getFixedAmount: getFixedAmount,
            getFixedRate: getFixedRate,
            isInvalid: isInvalid,
            getSignedFloat: getSignedFloat,
            multiply: multiply,
            divide: divide,
            add: add,
            subtract: subtract,
            difference: difference,
            getSign: getSign,
            getNegativeFloat: getNegativeFloat,
            isNumber: isNumber,
            parseNumber: parseNumber,
            valueOf: valueOf,
            isNegative: isNegative,
            format: format,
            getFormattedValue : getFormattedValue,
            convertAmountInCrores : convertAmountInCrores
        };

        return service;

        function isInvalid(number) {
            return number == undefined || number == null || isNaN(parseFloat(number)) || !angular.isNumber(parseFloat(number));
        }

        function getAbsFloat(number) {
            return Math.abs(getFloat(number));
        }

        function getFloat(number) {
            var floatNumber = 0;
            if (number != undefined && number != null) {
                if (angular.isString(number)) {
                    number = number.replace(/,/g, '');
                    number = number.replace("( ", "-");
                    number = number.replace(")", "");
                }
                floatNumber = parseFloat(number);
                if (isNaN(floatNumber)) {
                    floatNumber = 0;
                }
            }
            return floatNumber;
        }

        function getFixedFloat(number, numberOfDecimals) {
            var floatNumber = 0;
            floatNumber = getFloat(number);
            floatNumber = floatNumber.toFixed(numberOfDecimals);
            return parseFloat(floatNumber);
        }

        function getFixedAmount(amount) {
            return getFixedFloat(amount, GlobalValues.getNumberOfDecimalsForAmount());
        }

        function getFixedRate(rate) {
            return getFixedFloat(rate, GlobalValues.getNumberOfDecimalsForInputRate());
        }

        function getSignedFloat(referenceFloat, destinationFloat) {
            var returnFloat = getFloat(destinationFloat);
            referenceFloat = getFloat(referenceFloat);
            if(referenceFloat > 0) {
                returnFloat = getAbsFloat(destinationFloat);
            } else if(referenceFloat < 0) {
                returnFloat = getAbsFloat(destinationFloat) * -1;
            }
            return returnFloat;
        }

        function divide(numerator, denominator, numberOfDecimals) {
            var returnValue = 0;
            denominator = getFloat(denominator);
            if (denominator != 0) {
                returnValue = getFixedFloat(getFloat(numerator) / denominator, numberOfDecimals);
            }
            return returnValue;
        }

        function multiply(multiplier, multiplicand){
        	 var returnValue = 0;
        	 return getFloat(multiplier) * getFloat(multiplicand);
        }
        
        function add(augend, addend) {
            return getFloat(augend) + getFloat(addend);
        }

        function subtract(minuend, subtrahend) {
            return getFloat(minuend) - getFloat(subtrahend);
        }

        function difference(minuend, subtrahend) {
            return getAbsFloat(subtract(minuend, subtrahend));
        }

        function getSign(number) {
            var sign = 1;
            if(getFloat(number) < 0) {
                sign = -1;
            }
            return sign;
        }

        function getNegativeFloat(value) {
            return getAbsFloat(value) * -1;
        }

        function isNumber(value) {
            return angular.isNumber(value);
        }

        function parseNumber(floatString) {
            var returnValue = 0;
            if (!StringUtils.isBlank(floatString)) {
                if (floatString[0] == '(' && floatString[floatString.length - 1] == ')') {
                    returnValue = floatString.replace(/[(),]/g, '');
                    returnValue = '-' + returnValue;
                } else if (floatString[0] == '-') {
                    returnValue = floatString.replace(/[(),]/g, '');
                } else {
                    returnValue = floatString;
                }
                returnValue = getFloat(returnValue.replace(/[,]/g, ''));
            }
            return returnValue;
        }

        function valueOf(value) {
            var returnValue = 0;
            if(isNumber(value)) {
                returnValue = getFloat(value);
            } else if(StringUtils.isString(value)) {
                returnValue = parseNumber(value);
            }
            return returnValue;
        }
        
        function isNegative(value){
        	if(value < 0 ){
        		return true;
        	}
        	else{
        		return false;
        	}
        }

        function format(value, noOfDecimals) {
            return $filter('number')(value, noOfDecimals);
        }

        function getFormattedValue(value, numOfDecimals){
            return getFloat(value).toLocaleString
                (GlobalValues.getLocaleString(), 
                    { maximumFractionDigits: numOfDecimals, minimumFractionDigits : numOfDecimals});
            
        }

        function convertAmountInCrores(value, numOfDecimals){
            var convertedValue = divide(value, getFloat(10000000), numOfDecimals);
            return getFormattedValue(convertedValue, numOfDecimals);
        }
    }
})();
