(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manage-users', {
            parent: 'entity',
            url: '/manage-users',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.security.manageusers" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manage-users/manage-users-role.html',
                    controller: 'ManageUserRoleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('ForgotPID');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('manage-users-clearance', {
            parent: 'manage-users',
            url: '/manage-users-clearance/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/manage-users-clearance.html',
                    controller: 'ManageUserClearanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: ['User', function(User) {
                    		return User.getUserClearance({"userId" : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('manage-users', {}, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('manage-users.assignrole', {
            parent: 'manage-users',
            url: '/assign-role/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-role-assign-dialog.html',
                    controller: 'UsersRoleAssignController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: ['User', function(User) {
                            return User.getRolesForUser({"userId" : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('manage-users', null, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
             .state('manage-users-roletodolist-assignrole', {
            parent: 'dashboard',
            url: '/assign-role',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-role-assign-dialog.html',
                    controller: 'UsersRoleAssignController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	
                            	
                             
                            };
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
                .state('manage-users-roletodolist-assignrole.resubmit', {
            parent: 'dashboard',
            url: '/assign-role-resubmit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-role-assign-dialog.html',
                    controller: 'UsersRoleAssignController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                    		
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
          .state('manage-user-todolist', {
            parent: 'dashboard',
            url: '/role-clearance',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/manage-users-clearance.html',
                    controller: 'ManageUserClearanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                    		
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-user-todolist-resubmit', {
            parent: 'dashboard',
            url: '/role-clearance-resubmit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/manage-users-clearance.html',
                    controller: 'ManageUserClearanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	
                            	
                             
                            };
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-users.new', {
            parent: 'manage-users',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-dialog.html',
                    controller: 'UsersDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	 id:null,  
                            	name:null                            	
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('manage-users', null, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-users.view', {
            parent: 'manage-users',
            url: '/users/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['User', 'GlobalValues', function(User, GlobalValues) {
                                     return User.get({userId : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-users.edit', {
            parent: 'manage-users',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-dialog.html',
                    controller: 'UsersDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['User', 'GlobalValues', function(User, GlobalValues) {
                                     return User.get({userId : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-users.delink', {
            parent: 'manage-users',
            url: '/{id}/delink',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-delink-dialog.html',
                    controller: 'UsersDelinkController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	entity: ['User', 'GlobalValues', function(User, GlobalValues) {
                            return User.get({userId : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('usermanagement-todolist', {
            parent: 'dashboard',
            url: '/usersview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-users', null, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('usermanagement-todolist.resubmit', {
            parent: 'dashboard',
            url: '/usersedit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-dialog.html',
                    controller: 'UsersDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-users', null, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('usermanagement-auditLog', {
            parent: 'audit-log',
            url: '/usersview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-users', null, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-user-auditLog', {
            parent: 'audit-log',
            url: '/role-clearance',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/manage-users-clearance.html',
                    controller: 'ManageUserClearanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                    		
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         .state('manage-users-role-auditLog', {
            parent: 'audit-log',
            url: '/assign-role',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-role-assign-dialog.html',
                    controller: 'UsersRoleAssignController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	
                            	
                             
                            };
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         .state('manage-users-role-historyReport', {
            parent: 'history-report',
            url: '/assign-role',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-role-assign-dialog.html',
                    controller: 'UsersRoleAssignController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	
                            	
                             
                            };
                    	}
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('usermanagement-historyReport', {
            parent: 'history-report',
            url: '/usersview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-users', null, { reload: 'manage-users' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         
         .state('forgot-pid', {
            parent: 'manage-users.new',
            url: '/forgot-pid',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/forgot-pid/forgot-pid.html',
                    controller: 'ForgotPIDController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'smd',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-users.new', null, { reload: 'manage-users.new' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
        .state('bank-registration-user-view', {
            parent: 'bank-registration',
            url: '/bank-users/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['User', 'GlobalValues', function(User, GlobalValues) {
                                     return User.get({userId : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('branch-registration-user-view', {
            parent: 'branch',
            url: '/branch-users/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['User', 'GlobalValues', function(User, GlobalValues) {
                                     return User.get({userId : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
   };
})();