(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('csplInfoTooltip', ['$translate','StringUtils',
            function( $translate,  StringUtils) {

            function linkFunction(scope, element, attrs) {
                
				// scope.showTooltip = "No";

            	element.click(function(){

					
					
					if(!StringUtils.isBlank(attrs.csplInfoTooltip)){

						var title = attrs.csplInfoTooltip;
						$(element).tooltip({
							container: 'body',
							title : function(){
								return title; 
							} ,
							placement : attrs.tooltipPosition,
							template: '<div class="popover info-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner info-tooltip-inner"></div></div>'
						});
						$(element).tooltip('show');

					}
					/*else if(attrs.tooltipRequired != undefined && attrs.tooltipRequired != null &&
						attrs.tooltipRequired){
							var title = attrs.csplInfoTooltip;
						
							$(element).tooltip({
								container: 'body',
								title : function(){
									return title; 
								} ,
								placement : "right"
							});
							$(element).tooltip('show');
					}
					else{
						$(element).tooltip('destroy');
					}   */ 
               });

			   element.mouseleave(function(){
					$('body>.tooltip').remove();
					$(element).tooltip('destroy');
			});

            }

            return {
                restrict: 'A',
                link: linkFunction
            };
        }]);
})();

