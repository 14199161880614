(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('SwapQuotes', SwapQuotes);

    SwapQuotes.$inject = ['$resource'];

    function SwapQuotes ($resource) {
        var resourceUrl =  'api/swap-quotes/:id';

        return $resource(resourceUrl, {}, {
            'get':{ method: 'GET', transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }},
            
            
            'gatAllSwapQuotes': {
                method: 'GET',
                params: {orgId:'@orgId', fromDate :'@fromDate' , toDate: '@toDate'},
                url:'api/swap-quotes/orgId/:orgId/fromDate/:fromDate/toDate/:toDate', 
                isArray:true
                     
                
            },           
            'getSwapQuotesForAllBanks': {
                method: 'GET',
                url:'api/swap-quotes-for-all-banks', 
                isArray:true
            },           

            'getSwapQuotesForGivenBank': {
                method: 'GET',
                params: {bankId:'@bankId'},
                url:'api/swap-quotes-for-given-bank/:bankId', 
                isArray:true
            },           

            'getSwapQuotesForGivenCustomer': {
                method: 'GET',
                params: {customerId:'@customerId'},
                url:'api/swap-quotes-for-given-customer/:customerId', 
                isArray:true
            },           

            'update': {
            	method:'PUT',
            	params: {orgId:'@orgId'},
                url: 'api/swap-quotes/:orgId',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
            
            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
             'resubmit': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmit/swap-quotes',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             },
             'getSettlementDateForCashAndTOM' :{
            	 method: 'GET',             
            	 url: 'api/swap-quotes/settlementDate',
            	 isArray:true
             },
             'getSwapQuotesView': {
                 method: 'GET',
                 params: {bankId:'@bankId', fromDate :'@fromDate' , toDate: '@toDate'},
                 url:'api/swap-quotes-view/:bankId/bank/:from/from/:to/to', 
                 isArray:true
             },           
             'exportData':{
   			    method: 'POST',
                   url: 'api/swap-quotes-view/export',
                   responseType: 'arraybuffer',
                   params : { bankName : '@bankName',from :'@from', to : '@to'},
                   transformRequest: function (data) {
                   	var copy = angular.copy(data);
                       return angular.toJson(copy);
                   },
                   transformResponse: function(data, headers) {
                 	  return {
                           data: data,
                           headers: headers
                       };   
                   } 
             },
             'exportDataCSV':{
    			    method: 'POST',
                    url: 'api/swap-quotes-view/export-csv',
                    responseType: 'arraybuffer',
                    params : { bankName : '@bankName',from :'@from', to : '@to'},
                    transformRequest: function (data) {
                    	var copy = angular.copy(data);
                        return angular.toJson(copy);
                    },
                    transformResponse: function(data, headers) {
                  	  return {
                            data: data,
                            headers: headers
                        };   
                    } 
              },
              'exportDataPdf':{
  			    method: 'POST',
                  url: 'api/swap-quotes-view/export-pdf',
                  responseType: 'arraybuffer',
                  params : { bankName : '@bankName',from :'@from', to : '@to'},
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                      return angular.toJson(copy);
                  },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  } 
            }
        });
    }
})();

            
