(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('CustomerApproval', CustomerApproval);

    CustomerApproval.$inject = ['$resource','DateUtils'];

    function CustomerApproval ($resource, DateUtils) {
        var resourceUrl = 'api/tasks/';
        return $resource(resourceUrl, {}, {
            'query': { 
            	method: 'GET', 
            	isArray: true, 
            	url: 'api/customer-approval/orgId/:orgId',
            	transformResponse: function (data) {
	                if (data) {
	                    data = angular.fromJson(data);   
	                    angular.forEach(data,function(value,index){                	
	                    	value.payload = angular.fromJson(value.payload);
	                    	var getDate = value.createdDate;
	                        getDate = getDate.substring(0, getDate.indexOf('T'));
	                        value.createdDate = getDate;
	                    })
	                }
                return data;
            }},
            'get': {
                method: 'GET',               
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);                                              
                    }
                    return data;
                }
            },
            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'approveCustomerRegistration': {
                method: 'POST',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve-customer-registration',
    			transformRequest : function(data) {
    				var copy = angular.copy(data);
    				return angular.toJson(copy);
    			}
            },
            'reject': {
                method: 'POST',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'view':{
            	method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId',
                transformResponse: function (data) {                	
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'discard': {
            	 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/discard'
            },
            'resubmitCustomerInfo' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmitCustomer/customer',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'resubmitUpdatedCustomerInfo' : {
				method : 'POST',
				url : 'api/tasks/resubmitUpdatedCustomer',
				headers: {
                    'Content-Type': undefined
                }
			},
			'getBankDetails' : {
				method : 'GET',
				params : {
					
					customerId : '@customerId',
					bankId : '@bankId'
				},
				url : 'api/trading-details-for-customer-bank/:customerId/customer/:bankId/bank',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}
			
            
        });
    }
})();
