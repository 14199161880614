(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('CustomerTACUploadByBank', CustomerTACUploadByBank);

	CustomerTACUploadByBank.$inject = ['$resource'];

	function CustomerTACUploadByBank($resource) {
		var resourceUrl =  'api/customer-terms-condition-upload-by-bank/:id';

		return $resource(resourceUrl, {}, {
			'getActiveCustomerTACUploadByBank':{
				isArray:true,
				method: 'GET',
				params: {organisationId : '@organisationId'
				},
				url: 'api/customer-terms-condition-upload-by-bank/get-active-tac'

			},
			'getTermsConditionDetails':{
				method: 'GET',
				params: {id : '@id'},
				url: 'api/customer-terms-condition-upload/terms-conditions/:id',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'getAgreedTacForBank':{
				method: 'GET',
				params: {orgId : '@orgId'
				},
				url: 'api/customer-terms-condition-upload-by-bank/agreed-entity',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'getCustomerTacUploadByBankDTO':{
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'resubmit':{
				method: 'POST',
				url: 'api/tasks/resubmit/customer-terms-condition-upload-by-bank-resubmit',
				headers: {
					'Content-Type': undefined
				}


			},			
			'update':{
				method:'POST',
				url: 'api/customer-terms-condition-upload-by-bank',
				headers: {
					'Content-Type': undefined
				}

			},
			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			}

		});
	}
})();
