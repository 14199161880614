(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'specialholidays'
        })
        
        .controller('SpecialHolidaysController', SpecialHolidaysController);

    SpecialHolidaysController.$inject = ['$scope', '$state', 'workflowSharedService', 'SpecialHolidays', 'GlobalValues', 'EntityPermissions', 'ScreenLog', 'entityConstants'];

    function SpecialHolidaysController($scope, $state, workflowSharedService, SpecialHolidays, GlobalValues, EntityPermissions, ScreenLog , entityConstants) {
        
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SPECIAL_HOLIDAY;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        vm.loadAll = loadAll;
        
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;

		workflowSharedService.viewFrom = "specialholidays";
		
        vm.launch=launch;
        function launch() {
        	$state.go("special-holidays.new");
        	//$state.go("special-holidays.view");
        }
        
        vm.permitSpecialHolidaysEditEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
        vm.permitSpecialHolidaysCreateEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.CREATE);
		vm.permitSpecialHolidaysViewEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.VIEW);
		vm.permitSpecialHolidaysDeleteEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.DELETE);
		
        var loadAll = function() {
            SpecialHolidays.query(function(result) {
                vm.specialHolidays = result;                
            });
        }

        loadAll();
        logScreenAccess();
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
    }
})();
