(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerTacUploadApprovalDisclaimerController', CustomerTacUploadApprovalDisclaimerController);

    CustomerTacUploadApprovalDisclaimerController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues', '$rootScope', '$scope', 'TacViewForEntitiesUtility','CustomerTACUploadByBank'];

    function CustomerTacUploadApprovalDisclaimerController($uibModalInstance,$stateParams,GlobalValues,   $rootScope, $scope, TacViewForEntitiesUtility,CustomerTACUploadByBank) {
    	
    	var vm = this;
    	
    	vm.clear = clear;
        vm.approve = approve;
        $scope.clicked = false;
        vm.entityId =  $stateParams.entityId;
        vm.onGetCcilTac = TacViewForEntitiesUtility.onGetCcilTacForBank;
        vm.organisation = GlobalValues.getOrganisation();
        vm.messageBoxName = "customertacuploadApprovalfromtodo";
    	
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function approve(){
        	$scope.rowID = vm.entityId;
        	CustomerTACUploadByBank.approve({"taskId" : $scope.rowID},onApproveSuccess,onApproveError);      	
			             	
     	            
        }
      
        function onApproveSuccess(result) {
           
            $rootScope.removeRow( $scope.rowID );
            $uibModalInstance.dismiss('cancel');
        }

        function onApproveError(error) {
        	//$scope.clicked=false;
        	 $uibModalInstance.dismiss('cancel');        	
        }
        
        
    }
})();