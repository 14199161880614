(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'customerCharges'
        })
        
        .controller('CustomerChargesController', CustomerChargesController);

    CustomerChargesController.$inject = ['$scope', '$state', 'CustomerCharges', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function CustomerChargesController($scope, $state, CustomerCharges , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_CHARGES;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "customerCharges";
        $scope.refresh=refresh;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
     //   vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.decimalsForPercent = 4;
        
        vm.launch=launch;
        function launch() {
        	$state.go("customer-charges-new");
        }
        
        
        vm.permitCustomerChargesAddEntityAction = EntityPermissions.hasPermission('CustomerCharges.CREATE');
        vm.permitCustomerChargesImportEntityAction = EntityPermissions.hasPermission('CustomerCharges.IMPORT');
        vm.permitCustomerChargesEditEntityAction = EntityPermissions.hasPermission('CustomerCharges.EDIT');
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   
    	   CustomerCharges.query(function(result) {
               vm.customerCharges = result;                
           });
    	 
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
