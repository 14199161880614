(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.service("workflowSharedService", function () {
			this.viewFrom = 'eInvoiceUpload'
		})

		.controller('EInvoiceUploadController', EInvoiceUploadController);

	EInvoiceUploadController.$inject = ['$scope', '$state', 'workflowSharedService', 'EInvoiceUpload', 'chargeTypes', 'configSetting', 'EntityPermissions', 'ScreenLog', 'entityConstants', 'ImportFile', 'AlertService', '$filter', 'ImportFileService', 'MessageBox', 'FileUtil', '$rootScope', 'DateUtils', 'systemSettings', 'previousSystemSettings','financialYears'];
	function EInvoiceUploadController($scope, $state, workflowSharedService, EInvoiceUpload, chargeTypes, configSetting, EntityPermissions, ScreenLog, entityConstants, ImportFile, AlertService, $filter, ImportFileService, MessageBox, FileUtil, $rootScope, DateUtils, systemSettings, previousSystemSettings,financialYears) {

		var vm = this;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.EINVOICE_UPLOAD;
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		vm.uploadFiles = uploadFiles;
		vm.chargeTypes = chargeTypes;
		vm.chargesType = angular.copy(vm.chargeTypes[2]);
		vm.financialYears = financialYears;
		
		vm.checkZipFile = false;
		vm.checkMappingFile = false;
		vm.invalidJsonZipSize = false;
		vm.invalidJsonZipSizeText = "Maximum of 3MB file size is allowed";
		vm.invalidJsonZipText = "Accepted file format .zip";
		vm.invalidMappingFileSizeText = "Maximum of 3MB file size is allowed";
		vm.invalidMappingFileText = "Accepted file format .xls, .xlsx";
		vm.loadFinancialMonthOption = loadFinancialMonthOption;
		vm.loadFinancialYearDisplay = loadFinancialYearDisplay;
		
		vm.configSettings = configSetting;
		vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		vm.previousFiancialMonthOptions = [];
		vm.invalidJsonZip = false;
		vm.invalidMappingFile = false;
		vm.invalidMappingFileSize = false;
		vm.uploadedIfscData = null;
		var MappingExtensions = ["xls", "xlsx"];
		var JsonExtension = ["zip"];
		workflowSharedService.viewFrom = "eInvoiceUpload";




		var minJsonFileSizeinBytes = 1;
		var maxJsonFileSizeinBytes = 3 * 1024 * 1024;
		var minMappingFileSizeinBytes = 1;
		var maxMappingFileSizeinBytes = 3 * 1024 * 1024;
		vm.fileExtension = "";
		vm.inputJsonZipFileName = "";
		vm.inputMappingFileName = "";
		vm.fileNameWithoutExtension = "";
		if (vm.configSettings != null && vm.configSettings != undefined && vm.configSettings.noOfMOnthsForHistoricalReportsView != null && vm.configSettings.noOfMOnthsForHistoricalReportsView != undefined && !isNaN(vm.configSettings.noOfMOnthsForHistoricalReportsView)) {
			vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		}
		else {
			vm.noOFMonthsToDisplayForReports = 2;
		}
		if (systemSettings != null && systemSettings != undefined && previousSystemSettings != null && previousSystemSettings != undefined) {
			vm.currentBusinessDate = systemSettings.businessDate;
			vm.previousBusinessDate = previousSystemSettings.businessDate;
			vm.currentBusinessDateMoment = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.currentBusinessDate)));
			vm.previousBusinessDateMoment = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.previousBusinessDate)));
			loadFinancialMonthOption();
		}
		function loadFinancialMonthOption() {
			vm.previousFiancialMonthOptions = [];
			if(vm.configSettings.billingEndDate!=null && vm.configSettings.billingEndDate !=""){
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment)));	//temporary
			}
			var monthCount = 1;
			for (monthCount = 1; monthCount <= vm.noOFMonthsToDisplayForReports; monthCount++) {
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment).subtract(monthCount, 'month')))
			}
			vm.financialMonth = angular.copy(vm.previousFiancialMonthOptions[0]);
			

		}
		loadFinancialYearDisplay();
		function loadFinancialYearDisplay(){
			var yearCount = 0;
			if(vm.financialYears != null && vm.financialYears != undefined){
				for(yearCount = 0;yearCount < vm.financialYears.length; yearCount++){
					var fromYear = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYears[yearCount].financialYearStart))).year();
					var toYear = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYears[yearCount].financialYearEnd))).year();
					var displayYear = fromYear+"-"+ toYear;
					vm.financialYears[yearCount].displayYear = displayYear;
				}
			}
			vm.allFinancialYears = [];
			if(vm.configSettings.billingEndDate!=null && vm.configSettings.billingEndDate !=""){
				vm.allFinancialYears.push(angular.copy(vm.financialYears[0]));	//temporary
			}
			vm.allFinancialYears.push(angular.copy(vm.financialYears[1]));
			vm.financialYear =  angular.copy(vm.financialYears[1]);
			
		}
		vm.createMonthOptions = createMonthOptions;
		function createMonthOptions(dateMoment) {
			var currentMonthOption = {}
			currentMonthOption.date = angular.copy(dateMoment).startOf('month');
			currentMonthOption.monthDisplay = dateMoment.format('MMM - YYYY');
			return currentMonthOption;

		}




		logScreenAccess();
		function logScreenAccess() {
			ScreenLog.screenLog(vm.screenName, 0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}
		function uploadFiles() {
			if (vm.mappingFile != null && vm.jsonZipFile != null) {
				if(vm.chargesType.shortName!='AMC'){
					vm.fromDate = angular.copy( vm.financialMonth.date).toDate();
					vm.toDate =angular.copy( vm.financialMonth.date).endOf('month').toDate();
				}else{
				vm.fromDate = DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYear.financialYearStart));
			    vm.toDate  = DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYear.financialYearEnd));
				}
				
				var fd1 = new FormData();
				fd1.append('chargeTypeId', JSON.stringify(vm.chargesType.id));
				fd1.append('fromDate', DateUtils.convertLocalDateToServer(vm.fromDate));
				fd1.append('toDate', DateUtils.convertLocalDateToServer(vm.toDate));
				fd1.append('jsonFile', vm.jsonZipFile);
				fd1.append('mappingFile', vm.mappingFile);
				EInvoiceUpload.uploadFiles(fd1, onSaveSuccess, onSaveError);
				
				

			}

		}
		function onSaveSuccess(result) {
			resetMappingFileValue();
				resetJsonFileValue();
				vm.clicked = false;
		}


		function onSaveError() {
			resetMappingFileValue();
			resetJsonFileValue();
			vm.clicked = false;
		}
		function resetJsonFileValue() {
			vm.inputJsonZipFileName = null;
			vm.jsonZipFile = null;
			vm.checkZipFile = false;
		}
		function resetMappingFileValue() {
			vm.mappingFile = null;
			vm.inputMappingFileName = null;
			vm.checkMappingFile = false;
		}
		$scope.$watch('vm.jsonZipFile', function (value) {
			if (value != null && value != undefined) {
				vm.inputJsonZipFileName = value.name;

				if (!FileUtil.isFileSizeValid(value.size, minJsonFileSizeinBytes, maxJsonFileSizeinBytes)) {
					vm.invalidJsonZipSize = true;
					vm.invalidJsonZip = false;
					resetJsonFileValue();

				} else {
					vm.fileNameWithoutExtension = "";
					vm.invalidJsonZipSize = false;
					vm.invalidJsonZip = false;
					vm.fileExtension = vm.inputJsonZipFileName.substring(value.name.lastIndexOf(".") + 1);
					if (JsonExtension.indexOf(vm.fileExtension.toLowerCase()) <= -1) {
						resetJsonFileValue();
						//check for extension
						vm.invalidJsonZip = true;
						vm.invalidJsonZipSize = false;
					}
					else {
						var reader = new FileReader();
						reader.readAsArrayBuffer(value);

						reader.onloadend = function (e) {
							if (e.target.readyState === FileReader.DONE) {
								var bytes = new Uint8Array(e.target.result);
								var arr = bytes.subarray(0, 4);
								if (!FileUtil.checkZipFileExtension(arr)) {
									resetJsonFileValue();
									vm.invalidJsonZip = true;
									vm.invalidJsonZipSize = false;
								} else {

									vm.invalidJsonZip = false;
									vm.invalidJsonZipSize = false;

								}
								$scope.$digest();
							}

						};

					}
				}
			}
			else {
				document.getElementById("jsonFile").value = null;
				resetJsonFileValue();
			}
		});
		$scope.$watch('vm.mappingFile', function (value) {
			if (value != null && value != undefined) {
				vm.inputMappingFileName = value.name;

				if (!FileUtil.isFileSizeValid(value.size, minMappingFileSizeinBytes, maxMappingFileSizeinBytes)) {
					resetMappingFileValue();
					vm.invalidMappingFileSize = true;
					vm.invalidMappingFile = false;

				} else {
					vm.fileNameWithoutExtension = "";
					vm.invalidMappingFileSize = false;
					vm.invalidMappingFile = false;
					vm.fileExtension = vm.inputMappingFileName.substring(value.name.lastIndexOf(".") + 1);
					if (MappingExtensions.indexOf(vm.fileExtension.toLowerCase()) <= -1) {
						resetMappingFileValue();
						//check for extension
						vm.invalidMappingFile = true;
						vm.invalidMappingFileSize = false;
					}
					else {
						var reader = new FileReader();
						reader.readAsArrayBuffer(value);

						reader.onloadend = function (e) {
							if (e.target.readyState === FileReader.DONE) {
								var bytes = new Uint8Array(e.target.result);
								var arr = bytes.subarray(0, 4);
								if (!FileUtil.checkExcelFileExtension(arr)) {
									resetMappingFileValue();;
									vm.invalidMappingFile = true;
									vm.invalidMappingFileSize = false;
								} else {

									vm.invalidMappingFile = false;
									vm.invalidMappingFileSize = false;

								}
								$scope.$digest();
							}

						};

					}
				}
			}
			else {
				document.getElementById("mappingFile").value = null;
				resetMappingFileValue();
			}
		});



	}
})();