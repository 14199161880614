(function() {
	'use strict';

	angular.module('gatewayApp')

	.controller('LimitsDialogController', LimitsDialogController);

	LimitsDialogController.$inject = [ '$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','LimitSettings', 
		'DateUtils', 'ScreenLog','entityConstants', 'AlertService', '$filter', 'Workflow','entity','StaticLookUp','MessageBox', '$stateParams', 'Market' ];

	function LimitsDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,LimitSettings,
			DateUtils,ScreenLog,entityConstants,AlertService,$filter, Workflow,entity,StaticLookUp,MessageBox,$stateParams,Market) {
		var vm=this;
		var ALL_LIMIT_DETAIL_COUNT = 1;
		var INDIVIDUAL_LIMIT_DETAIL_COUNT = 4;
		var UPTOSPOTANDFORWARD_LIMIT_DETAIL_COUNT = 2;
		vm.actionType = "";
		 vm.globalValues = GlobalValues;
         vm.entityConstants = entityConstants;
         vm.screenLog = ScreenLog;
         vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
         vm.screenName = entityConstants.ENTITY_SHORT_NAME.LIMITSETTINGS;
         $scope.showOkButton = false;
         vm.limitHeader={};
         vm.minValue = 0;
        
        
         //vm.disableReinstateField = false;         
         //vm.showLimiReinstateType = false;         
         vm.SETTLED_TRADES = 'SettledTrades';
         vm.DAILY = 'Daily';
         vm.YES = 'Yes';
         vm.NO = 'No';         
         //vm.isLimitToBeReinstated = NO;
         
         //vm.currentDate = new Date();
         //vm.effectiveToMinimumDate =  new Date();
         var systemSettings = vm.globalValues.getSystemSettings();
         if(systemSettings!=null && systemSettings!=undefined){
        	 var now = moment();
        	 vm.momentBusinessDate = moment(systemSettings.businessDate, 'YYYY-MM-DD');        	
        	 vm.momentBusinessDate.set(
        			 { 	'hour':now.get('hour'),
        				'minute':now.get('minute'),
        				'second':now.get('second')
        				
        			 });
        	 vm.currentDate = vm.momentBusinessDate.toDate();
        	
        	 vm.effectiveToMinimumDate = systemSettings.businessDate;
        	 vm.tillDate = new Date(vm.currentDate);
        	 vm.tillDate.setDate(vm.currentDate.getDate() + 364);
         }
         
         vm.customerLimit = {};
       
         
         vm.clear = clear;
         vm.resubmitLimitSettings= resubmitLimitSettings;
         vm.approveLimitSettings = approveLimitSettings;
         vm.rejectLimitSettings = rejectLimitSettings;
         vm.discardLimitSettings = discardLimitSettings;
         vm.save=save;
         vm.clicked = false;
         vm.showLimitUtilised = false;
         vm.showLimitUtilisedWithINR = false;
         vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();

         loadInstrumentType();
         loadCurrency();
         loadLimitTypes();
                  
         function loadInstrumentType(){
        	 StaticLookUp.getInstrumentType(istrumentLookUpSuccess, instrumentLookupFailure);
         }
         function istrumentLookUpSuccess(result){
        	 vm.instrumentTypes = result;
        	 angular.forEach( vm.instrumentTypes, function(item){
        		 if(item.shortName =='SPOT'){
        			 vm.spotType = item;
        		 }else if(item.shortName =='CASH'){
        			 vm.cashType = item;
        		 }else if (item.shortName =='TOM'){
        			 vm.tomType = item;
        		 }else if (item.shortName =='UptoSPOT'){
        			 vm.uptoSpotType = item;
        		 }else if (item.shortName =='Forward'){
        			 vm.frwdType = item;
        		 }
        	 });
        	
         }
         function instrumentLookupFailure(error){
        	 
         }        
         function loadCurrency(){
        	 StaticLookUp.getCurrency(currencySuccess,currencyFailure);
         }
         function currencySuccess(result){
        	 vm.currencies = result;
         }
         function currencyFailure(error){
        	 
         }
         function loadLimitTypes(){
        	 StaticLookUp.getLimitType(limitTypeSuccess, limitTypeFailure);
         }
         function limitTypeSuccess(result){
        	 vm.limitTypes = result;
        	 prepareLimitTypeToView();
         }
         function limitTypeFailure(error){
        	 
         }
         
         $scope.$watch('vm.selectedLimitType' , function(value){
        	 if(vm.limitHeader!=null && vm.limitHeader!==undefined){
        		 if(value !=null && value!=undefined)
        			 vm.limitHeader.limitType = value.shortName;
        		// else if(vm.limitHeader.limitType=null || vm.limitHeader.limitType == undefined )
        			// vm.limitHeader.limitType="";
        	 }
         });
         $scope.$watch('vm.isLimitToBeReinstated' , function(value){
        	 //if(vm.limitHeader!=null && vm.limitHeader!==undefined){
        		//alert(value + " " + typeof value);
        		 if(value !=null && value!=undefined && value== vm.NO){
        			 vm.showLimiReinstateType = false;
        			 vm.limitHeader.isLimitToBeReinstated =false;
        			 vm.limitHeader.limitReinstateMethod = null;
        		 }else if(value !=null && value!=undefined && value==vm.YES){
        			 vm.showLimiReinstateType = true;
        			 vm.limitHeader.isLimitToBeReinstated =true;
        			 document.getElementById("reinstateMethodRadio").focus();
        		 }
        	 //}
         });
         $scope.$watch('vm.selectedInstrument', function(value){
        	 if( vm.limitHeader!=null && vm.limitHeader!=undefined && value == 'all'){
        		 if( document.getElementById("txtspot")!=undefined &&  document.getElementById("txtspot")!=null){
        			 document.getElementById("txtspot").value = "";
        		 }
        		 if( document.getElementById("txttom")!=undefined &&  document.getElementById("txttom")!=null){
        			 document.getElementById("txttom").value = "";
        		 }
        		 if( document.getElementById("txtcash")!=undefined &&  document.getElementById("txtcash")!=null){
        			 document.getElementById("txtcash").value = "";
        		 }
        		 if( document.getElementById("txtforward")!=undefined &&  document.getElementById("txtforward")!=null){
        			 document.getElementById("txtforward").value = "";
        		 }
        		 if( document.getElementById("txtuptospot")!=undefined &&  document.getElementById("txtuptospot")!=null){
        			 document.getElementById("txtuptospot").value = "";
        		 }
        		 if( document.getElementById("txtuptospotforward")!=undefined &&  document.getElementById("txtuptospotforward")!=null){
        			 document.getElementById("txtuptospotforward").value = "";
        		 }
        		 vm.spotValue=null;
        		 vm.tomValue=null;
        		 vm.cashValue=null;
        		 vm.forwardValue = null;
        		 vm.uptoSpotValue = null;
        		 vm.forwardUptoSpotValue = null;
        	 }else if( vm.limitHeader!=null && vm.limitHeader!=undefined && value == 'individual'){
        		 if( document.getElementById("txtall")!=undefined &&  document.getElementById("txtall")!=null){
        		 	document.getElementById("txtall").value = "";
        	 	}
        		 if( document.getElementById("txtuptospot")!=undefined &&  document.getElementById("txtuptospot")!=null){
        			 document.getElementById("txtuptospot").value = "";
        		 }
        		 if( document.getElementById("txtuptospotforward")!=undefined &&  document.getElementById("txtuptospotforward")!=null){
        			 document.getElementById("txtuptospotforward").value = "";
        		 }
        		 vm.allValue=null;
        		 vm.uptoSpotValue = null;
        		 vm.forwardUptoSpotValue = null;
        	 }
        	 if( vm.limitHeader!=null && vm.limitHeader!=undefined && value == 'uptoSpot'){
        		 if( document.getElementById("txtall")!=undefined &&  document.getElementById("txtall")!=null){
         		 	document.getElementById("txtall").value = "";
         	 	 }
        		 if( document.getElementById("txtspot")!=undefined &&  document.getElementById("txtspot")!=null){
        			 document.getElementById("txtspot").value = "";
        		 }
        		 if( document.getElementById("txttom")!=undefined &&  document.getElementById("txttom")!=null){
        			 document.getElementById("txttom").value = "";
        		 }
        		 if( document.getElementById("txtcash")!=undefined &&  document.getElementById("txtcash")!=null){
        			 document.getElementById("txtcash").value = "";
        		 }
        		 if( document.getElementById("txtforward")!=undefined &&  document.getElementById("txtforward")!=null){
        			 document.getElementById("txtforward").value = "";
        		 }
        		 vm.allValue=null;
        		 vm.spotValue=null;
        		 vm.tomValue=null;
        		 vm.cashValue=null;
        		 vm.forwardValue = null;
        	 }
        	 prepareLimitDetailsToSaveToDB(value);
         });
         
         $scope.$watch('vm.allValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.allValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.spotValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.spotValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.tomValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.tomValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.cashValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.cashValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.forwardValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.forwardValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.uptoSpotValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.uptoSpotValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.forwardUptoSpotValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.forwardUptoSpotValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
    
         function prepareLimitTypeToView(){
        	
        	 if(vm.limitHeader!=null && vm.limitHeader!=undefined){
        		 angular.forEach(vm.limitTypes, function(item){
            		 if(item.shortName == vm.limitHeader.limitType ){
            			 vm.selectedLimitType = item;
            		 }
            	 })
        	 }
        	
         }
         function checkInstrumentValue(instrumentValue){
        	 
        	 if(instrumentValue == "" || instrumentValue == undefined || instrumentValue == null){
        		 return 0.00;
        	 }else{
        		 return instrumentValue;
        	 }
         }
         function prepareLimitDetailsToSaveToDB(value){
        	 if( vm.limitHeader!=null && vm.limitHeader!=undefined && value == 'all'){
        		 vm.limitHeader.limitSettingsDetailsList=[];
        		 vm.limitHeader.limitSettingsDetailsList[0]={};
        		 vm.limitHeader.limitSettingsDetailsList[0].instrumentType=null;
        		 vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue(vm.allValue);
        		
        		 
        	 }else if( vm.limitHeader!=null && vm.limitHeader!=undefined && value == 'individual'){
        		 
        		
        		 vm.limitHeader.limitSettingsDetailsList=[];
        		 vm.limitHeader.limitSettingsDetailsList[0]={};
        		 vm.limitHeader.limitSettingsDetailsList[0].instrumentType=vm.cashType;
        		 vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue(vm.cashValue);
        		 
        		 vm.limitHeader.limitSettingsDetailsList[1]={};
        		 vm.limitHeader.limitSettingsDetailsList[1].instrumentType=vm.tomType;
        		 vm.limitHeader.limitSettingsDetailsList[1].value=checkInstrumentValue(vm.tomValue);
        		 
        		 vm.limitHeader.limitSettingsDetailsList[2]={};
        		 vm.limitHeader.limitSettingsDetailsList[2].instrumentType=vm.spotType;
        		 vm.limitHeader.limitSettingsDetailsList[2].value=checkInstrumentValue(vm.spotValue);
        		 
        		 vm.limitHeader.limitSettingsDetailsList[3]={};
        		 vm.limitHeader.limitSettingsDetailsList[3].instrumentType=vm.frwdType;
        		 vm.limitHeader.limitSettingsDetailsList[3].value=checkInstrumentValue( vm.forwardValue);
        		 
        	 }else if( vm.limitHeader!=null && vm.limitHeader!=undefined && value == 'uptoSpot'){
        		 
        		 
        		 vm.limitHeader.limitSettingsDetailsList=[];
        		 vm.limitHeader.limitSettingsDetailsList[0]={};
        		 vm.limitHeader.limitSettingsDetailsList[0].instrumentType=vm.uptoSpotType;
        		 vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue(vm.uptoSpotValue);
        		 
        		 vm.limitHeader.limitSettingsDetailsList[1]={};
        		 vm.limitHeader.limitSettingsDetailsList[1].instrumentType=vm.frwdType;
        		 vm.limitHeader.limitSettingsDetailsList[1].value=checkInstrumentValue(vm.forwardUptoSpotValue);
        		 
        		
        	 }
         }
         function prepateLimitDetailsToView(){
        	 
        	 if( vm.limitHeader!=null && vm.limitHeader !=undefined && vm.limitHeader.limitSettingsDetailsList!=null && vm.limitHeader.limitSettingsDetailsList!=undefined && vm.limitHeader.limitSettingsDetailsList.length==ALL_LIMIT_DETAIL_COUNT){
        		 vm.selectedInstrument = "all";
        		 vm.allValue = vm.limitHeader.limitSettingsDetailsList[0].value;
        		 if(vm.prevClose!=null && vm.prevClose!=undefined && vm.allValue !=null && vm.allValue !=undefined){
        			 vm.allValueInINR = (Math.round(parseFloat(vm.allValue)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
        	 else if(vm.limitHeader!=null && vm.limitHeader !=undefined && vm.limitHeader.limitSettingsDetailsList!=null && vm.limitHeader.limitSettingsDetailsList!=undefined && (vm.limitHeader.limitSettingsDetailsList.length==INDIVIDUAL_LIMIT_DETAIL_COUNT
        			 || vm.limitHeader.limitSettingsDetailsList.length==3 )){
        		 vm.selectedInstrument = "individual";
        		 angular.forEach( vm.limitHeader.limitSettingsDetailsList, function(item){
        			 if(item.instrumentType!=null && item.instrumentType!=undefined){
        			 	
        				 if(item.instrumentType.shortName =='CASH'){
         			 		vm.cashValue = item.value;
        	        		 if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
        	        			 vm.cashValueInINR = (Math.round(parseFloat(vm.cashValue)*10000) / vm.prevClose) / 10000;
        	        		 }
         			 	}else if(item.instrumentType.shortName =='TOM'){
        			 		vm.tomValue = item.value;
        			 		if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
    	        			 vm.tomValueInINR = (Math.round(parseFloat(vm.tomValue)*10000) / vm.prevClose) / 10000;
        			 		}
        			 	}else if(item.instrumentType.shortName =='SPOT'){
        			 		vm.spotValue = item.value;
        			 		if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
       	        			 vm.spotValueInINR = (Math.round(parseFloat(vm.spotValue)*10000) / vm.prevClose) / 10000;
        			 		}
        			 	}else if(item.instrumentType.shortName =='Forward'){
        			 		vm.forwardValue = item.value;
        			 		if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
       	        			 vm.forwardValueInINR = (Math.round(parseFloat(vm.forwardValue)*10000) / vm.prevClose) / 10000;
        			 		}
        			 	}
        			 	
        			 }
        			 	  			 
        		 });
        	 }
        	 else if(vm.limitHeader!=null && vm.limitHeader !=undefined && vm.limitHeader.limitSettingsDetailsList!=null && vm.limitHeader.limitSettingsDetailsList!=undefined && vm.limitHeader.limitSettingsDetailsList.length == UPTOSPOTANDFORWARD_LIMIT_DETAIL_COUNT){
        		 vm.selectedInstrument = "uptoSpot";
        		 angular.forEach( vm.limitHeader.limitSettingsDetailsList, function(item){
        			 if(item.instrumentType!=null && item.instrumentType!=undefined){        			 	
        				
        			 	if(item.instrumentType.shortName =='UptoSPOT'){
        			 		vm.uptoSpotValue = item.value;
        			 		if(vm.prevClose!=null && vm.prevClose!=undefined){
       	        			 vm.uptoSpotValueInINR = (Math.round(parseFloat(vm.uptoSpotValue)*10000) / vm.prevClose) / 10000;
        			 		}
        			 	}else if(item.instrumentType.shortName =='Forward'){
        			 		vm.forwardUptoSpotValue = item.value;
        			 		if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 			vm.forwardUptoSpotValueInINR = (Math.round(parseFloat(vm.forwardUptoSpotValue)*10000) / vm.prevClose) / 10000;
        			 		}
        			 	}
        			 	
        			 }
        			 	  			 
        		 });
        	 }
         }
         function loadDefaultEffectiveToDate(){
        	 //LimitSettings.getDefaultEffectiveToDate();
        	 LimitSettings.getDefaultEffectiveToDate(loadEffectiveDateSuccess, loadEffectiveDateFailure);
         }
         function loadEffectiveDateSuccess(result){
        	
        	 vm.efectiveToDate = DateUtils.convertDateTimeFromServer(result.effectiveToDate);
         }
         function loadEffectiveDateFailure(error){
        	 
         }
         function prepareDatesForView(){
        	 if(vm.limitHeader!=null && vm.limitHeader!=undefined){
        		 vm.efectiveFromDate = DateUtils.convertDateTimeFromServer(vm.limitHeader.effectiveFromDateTime);
        		 
        	 	vm.efectiveToDate = DateUtils.convertDateTimeFromServer(vm.limitHeader.effectiveToDateTime);
        	 }
         }	
         function prepareDatesforServer(){
        	// vm.limitHeader.effectiveFromDateTime = DateUtils.convertLocalDateToServer(vm.efectiveFromDate);
        	 //vm.limitHeader.effectiveToDateTime = DateUtils.convertLocalDateToServer(vm.efectiveToDate);
        	 vm.limitHeader.effectiveFromDateTime = vm.efectiveFromDate;        	
        	 vm.limitHeader.effectiveToDateTime = vm.efectiveToDate;
         }
		if (workflowSharedService.viewFrom == 'limitSettings_add') {

			vm.limitHeader = {};
			vm.selectedInstrument = 'individual';
			vm.efectiveFromDate = vm.currentDate;
			loadDefaultEffectiveToDate();
			
	        $scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;		
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			vm.actionType="Add";
			var disableBranch=false;
			if (vm.loggedinEntityOwnerType == "Bank"){
	        	 disableBranch=false;
	        	 vm.limitHeader.bank = vm.globalValues.getBank();
	    			 
	    	 }else if(vm.loggedinEntityOwnerType == "Branch") {
	    		 disableBranch=true;
	    		
	    		 vm.limitHeader.branch = vm.globalValues.getBranch();
	    		 vm.limitHeader.bank = vm.limitHeader.branch.bank;
	    	 }
			enableControls(disableBranch,false,false,false,false);
			vm.disableReinstateField = false;
			
		} else if (workflowSharedService.viewFrom == 'limitSettings_edit') {
			
			vm.limitHeader = entity;
			
			prepateLimitDetailsToView();
			prepareDatesForView();
			vm.efectiveFromDate = vm.currentDate;
			prepareLimitTypeToView();
			/*if(vm.limitHeader !=null  && vm.limitHeader !=undefined){
				vm.limitHeader.effectiveFromDateTime = vm.currentDate;
			}*/
			vm.previousLimit = angular.copy(vm.limitHeader);
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			vm.actionType="Change";
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
		
		} else if (workflowSharedService.viewFrom == 'limitSettings_view') {
			
			vm.limitHeader = entity;
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			/*if(vm.limitHeader !=null  && vm.limitHeader !=undefined){
				vm.limitHeader.effectiveFromDateTime = vm.currentDate;
			}*/
			vm.previousLimit = angular.copy(vm.limitHeader);
			$scope.showOkButton = true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = true;
			vm.actionType="View";
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
		
		}
		else if (workflowSharedService.viewFrom == 'limitSettings_checker') {
			
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);			
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			vm.disableForm = true;
			vm.actionType=getActionType($rootScope.action);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
		} else if (workflowSharedService.viewFrom == 'limitSettings_maker') {
			
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			vm.actionType=getActionType($rootScope.action);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
			
		} else if (workflowSharedService.viewFrom == 'limitSettings_resubmit') {
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.screenLog.screenLog(vm.screenName, vm.limitHeader.id == null ? 0
					: vm.limitHeader.id,
					entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			vm.disableForm = false;
			$scope.showButton = false;
			$scope.showResubmitButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			vm.actionType=getActionType($rootScope.action);
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
		} else if (workflowSharedService.viewFrom == 'limitSettings_auditLog') {
			
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.disableForm = true;
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			vm.actionType= getActionType($rootScope.actionName);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
		}else if(workflowSharedService.viewFrom=='limit_import_auditlog'){
        	
			var copyOfPayload = angular.copy($rootScope.payload); 
			var limitHeaderDTO = angular.fromJson(copyOfPayload);
			
			vm.limitHeader = limitHeaderDTO;
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.disableForm = true;
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			vm.actionType= getActionType($rootScope.actionName);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;

        }
		else if (workflowSharedService.viewFrom == 'customer_limitSettings') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit  = entity;
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : $stateParams.customerId , bankId :$stateParams.bankId }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			prepareDatesForView();
			vm.efectiveFromDate = vm.currentDate;
			prepareLimitTypeToView();
			ScreenLog.screenLog(vm.screenName,vm.limitHeader.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
			vm.limitHeader.effectiveFromDateTime = vm.currentDate;
			vm.previousLimit = angular.copy(vm.limitHeader);
			
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			vm.actionType="CustomerChange";
			enableControls(true,true,true,false,true,false);
			vm.disableReinstateField = true;
		} 
		else if (workflowSharedService.viewFrom == 'customer_limitSettings_checker') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : vm.limitHeader.customer.id , bankId :vm.limitHeader.bank.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			prepareDatesForView();
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			//vm.disableForm = false;
			vm.disableForm = true;
			vm.actionType="CustomerChange";
			//enableControls(true,true,true,false,true,false);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
			
		} else if (workflowSharedService.viewFrom == 'customer_limitSettings_maker') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : vm.limitHeader.customer.id , bankId :vm.limitHeader.bank.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			prepareDatesForView();
			prepareLimitTypeToView();
			
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			vm.actionType="CustomerChange";
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
			
		} else if (workflowSharedService.viewFrom == 'customer_limitSettings_resubmit') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : vm.limitHeader.customer.id , bankId :vm.limitHeader.bank.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.screenLog.screenLog(vm.screenName, vm.limitHeader.id == null ? 0
					: vm.limitHeader.id,
					entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			vm.disableForm = false;
			$scope.showButton = false;
			$scope.showResubmitButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			vm.actionType="CustomerChange";
			enableControls(true,true,true,false,true,false);
			vm.disableReinstateField = true;
		} else if (workflowSharedService.viewFrom == 'customer_limitSettings_auditLog') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.disableForm = true;
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			//vm.actionType= getActionType($rootScope.actionName);
			if(vm.limitHeader.id!=null && vm.limitHeader.id!=undefined && !vm.limitHeader.id==""){
				vm.actionType="CustomerChange";
			}else{
				vm.actionType="CustomerCreate";
			}
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
		}
		
		else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_checker') {
			
			//vm.minValue = 0.01;
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
						
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			prepateLimitDetailsToView();
			prepareDatesForView();
			if(vm.limitHeader.effectiveFromDateTime == null || vm.limitHeader.effectiveFromDateTime == undefined
					|| vm.limitHeader.effectiveFromDateTime == ""){
				
				vm.efectiveFromDate = vm.currentDate;
				
			}
			if(vm.limitHeader.effectiveToDateTime == null || vm.limitHeader.effectiveToDateTime == undefined
					|| vm.limitHeader.effectiveToDateTime == ""){
				
				loadDefaultEffectiveToDate();
			}
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			//vm.disableForm = false;
			vm.disableForm = false;
			vm.actionType="CustomerCreate";
			//enableControls(true,true,true,false,true,false);
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
			
		} 
		else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_checker_view') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
						
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			prepateLimitDetailsToView();
			prepareDatesForView();
			if(vm.limitHeader.effectiveFromDateTime == null || vm.limitHeader.effectiveFromDateTime == undefined
					|| vm.limitHeader.effectiveFromDateTime == ""){
				
				vm.efectiveFromDate = vm.currentDate;
				
			}
			if(vm.limitHeader.effectiveToDateTime == null || vm.limitHeader.effectiveToDateTime == undefined
					|| vm.limitHeader.effectiveToDateTime == ""){
				
				loadDefaultEffectiveToDate();
			}
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			vm.actionType="CustomerCreate";
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
			
		} 
		setLimitReinstateOption();
		
		function setLimitReinstateOption(){
			if( vm.limitHeader!=null && vm.limitHeader!=undefined 
					&& vm.limitHeader.isLimitToBeReinstated!=null && vm.limitHeader.isLimitToBeReinstated!=undefined ){
				if(vm.limitHeader.isLimitToBeReinstated == true){
					vm.isLimitToBeReinstated = vm.YES;
					vm.showLimiReinstateType = true;
				}else{
					vm.isLimitToBeReinstated = vm.NO;
					vm.showLimiReinstateType = false;
				}
			}else{
				vm.isLimitToBeReinstated = vm.NO;
				vm.showLimiReinstateType = false;
			}
		}
		function enableControls(disableBranch , disableCusomer, disableOtherControls,disableEffectiveToControl, disableRadio, disableValues){
			
			vm.disableBranch = disableBranch;
			vm.disableCustomer = disableCusomer;
			vm.disableLimitType= disableOtherControls;
			vm.disableCurrency= disableOtherControls;
			vm.disableEffectiveToDate= disableEffectiveToControl;
			vm.disableRadio = disableRadio;
			vm.disableValues = disableValues;
		}
		  
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function getActionType(action){
        	if(action!=null && action!=undefined){
	        	action = action.toLowerCase();
	        	if(action.indexOf("add") !== -1 || action.indexOf("create") !== -1){
	        		return "Add";
	        	}
	        	else if(action.indexOf("edit") !== -1 || action.indexOf("modify") !== -1 || action.indexOf("modified") !== -1 || action.indexOf("change") !== -1){
	        		return "Change";
	        	}
        	}
        }
        
        function save () {        	    		
        	 prepareLimitDetailsToSaveToDB(vm.selectedInstrument);
        	 prepareDatesforServer();
        	 var comparision = moment(vm.currentDate).isAfter(vm.limitHeader.effectiveToDateTime, 'day');
        	  console.log(vm.limitHeader.effectiveToDateTime);
        	  console.log(vm.currentDate);
       		if(comparision){
       			AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
       			vm.clicked=false;
       		}else if(moment(vm.limitHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		}
       		else if (vm.limitHeader.id !== null && vm.limitHeader.id!==undefined) {
       			if(validateChange()){
       				if(workflowSharedService.viewFrom == 'customer_limitSettings'){
       					LimitSettings.updateLimitSettingsByCustomer(vm.customerLimit, onCustomerSaveSuccess, onCustomerSaveError);
       				}else{
       					LimitSettings.update(vm.limitHeader, onSaveSuccess, onSaveError);
       				}
       				
       				
       			}else{
       				AlertService.error($filter('translate')('gatewayApp.limitSettings.noChangesMade'));
       				vm.clicked = false;
       			}
        		
             }else {
            	 
            	 LimitSettings.save(vm.limitHeader, onSaveSuccess, onSaveError);           
             }       	
        	
         }
     
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	//vm. = DateUtils.convertDateTimeFromServer(vm.swapQuotes.holidayDate);

        	vm.clicked = false;
        }
        
        function onCustomerSaveSuccess(result){
        	//$scope.clicked = true;
        	 $uibModalInstance.close(result); 
        	 MessageBox.openMessageBox('Alert', 'The request has been sent for bank approval. You will be notified by email on the request status...!', 'Ok', 'homepage');
        }
        function onCustomerSaveError(error){
        	vm.clicked = false;
        }
        function resubmitLimitSettings() {
        	 prepareLimitDetailsToSaveToDB(vm.selectedInstrument);
        	 prepareDatesforServer();
        	 var comparision = moment(vm.currentDate).isAfter(vm.limitHeader.effectiveToDateTime, 'day');
	       	 
        	if(comparision){
        			AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
        			vm.clicked=false;
        	 }else if(moment(vm.limitHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		 }else if(workflowSharedService.viewFrom == 'customer_limitSettings_resubmit'){
        		 LimitSettings.resubmitLimitChangeByCustomer({"taskId" : $rootScope.taskID}, vm.customerLimit, onResubmitSuccess, onResubmitError);
        	 }else{
        		 LimitSettings.resubmit({"taskId" : $rootScope.taskID}, vm.limitHeader, onResubmitSuccess, onResubmitError);
        	 }
        	 
        	
        }
        
        function onResubmitSuccess(result) {
        	$uibModalInstance.close(result); 
        	$rootScope.removeRow($rootScope.taskID);
        	
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function rejectLimitSettings() { 
        	$rootScope.action = vm.actionType;
        	Workflow.rejectTask($rootScope.taskID); 
        }

        function discardLimitSettings() {
        	LimitSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

        function approveLimitSettings() {  
    		prepareLimitDetailsToSaveToDB(vm.selectedInstrument);
    		prepareDatesforServer();
        	var comparision = moment(vm.currentDate).isAfter(vm.limitHeader.effectiveToDateTime, 'day');
	       	 
        	if(comparision){
       			AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
       			vm.clicked=false;
       		}else if(moment(vm.limitHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		}       	
        	else if(workflowSharedService.viewFrom == 'create_customer_limitSettings_checker'){
        		LimitSettings.approveWihPayload({"taskId" : $rootScope.taskID},vm.customerLimit,onApproveSuccess,onApproveError);         	
        		 
        	}else{
        		LimitSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError); 
        	}
        	 
        }
        
        function onApproveSuccess(result) {        	
        	
        	$rootScope.removeRow($rootScope.taskID); 
        	$uibModalInstance.close(result);  
        }        
        function onApproveError(error) {
        	vm.clicked = false;
        }
        function validateChange(){
        	var changesMade = false;
        	var field1 = checkvalidityOfValues(vm.previousLimit.limitType , vm.limitHeader.limitType);
        	
        	var field2 = false;
        	if(vm.previousLimit.limitCurrency != null && vm.previousLimit.limitCurrency!=undefined && vm.limitHeader.limitCurrency!=null && vm.limitHeader.limitCurrency !=undefined){
        		field2 = checkvalidityOfValues(vm.previousLimit.limitCurrency.code , vm.limitHeader.limitCurrency.code);
        	}
        	var field3 = checkvalidityOfValues(DateUtils.convertLocalDateToServer(vm.previousLimit.effectiveToDateTime ),DateUtils.convertLocalDateToServer( vm.limitHeader.effectiveToDateTime));
        	var field4 =false;
        	var ChangesMAdeInDetail = false;
        	if(vm.previousLimit.limitSettingsDetailsList.length != vm.limitHeader.limitSettingsDetailsList.length){
        		field4 = true;
        	}else{
        		angular.forEach(vm.previousLimit.limitSettingsDetailsList, function(previousDetail){
        			
        			angular.forEach(vm.limitHeader.limitSettingsDetailsList, function(currenDetail){
        				if(previousDetail.instrumentType == null && currenDetail.instrumentType == null){
        					if(checkvalidityOfValues(previousDetail.value,currenDetail.value)){
        						ChangesMAdeInDetail = true;
        					}
        				}
        				else if( ( previousDetail.instrumentType != null && currenDetail.instrumentType == null ) ||  (previousDetail.instrumentType == null && currenDetail.instrumentType != null)){
        					
        						ChangesMAdeInDetail = true;
        					
        				}
        				else if( previousDetail.instrumentType.id==currenDetail.instrumentType.id ){
        					if(checkvalidityOfValues(previousDetail.value,currenDetail.value)){
        						ChangesMAdeInDetail = true;
        					}
        				}
        			});
        			});
        	}
        	
        	var field5 =false;
        	field5 = checkvalidityOfValues(vm.previousLimit.isLimitToBeReinstated , vm.limitHeader.isLimitToBeReinstated);
        	
        	var field6 =false;
        	field6 = checkvalidityOfValues(vm.previousLimit.limitReinstateMethod , vm.limitHeader.limitReinstateMethod);
        	
        	
        	
        	if(field1 || field2 || field3 || field4 || ChangesMAdeInDetail || field5 || field6 ){
        		changesMade = true;
        	}
        	return changesMade;
        }
        function checkvalidityOfValues(previousValue, currentValue){
        	var isValueChanged = (!(previousValue == null && (currentValue == "" || currentValue== null)) && previousValue!=currentValue);
        	return isValueChanged;
        }
        
       
        
        
	}
})();
