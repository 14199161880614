(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('outstanding-trades-report', {
            parent: 'entity',
            params:{screen: 'OutstandingTradesReport'},
            url: '/outstanding-trades-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.tradesOutstandingReport.main" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/clearcorp-trades-outstanding-report/clearcorp-trades-outstanding-report.html',
                    controller: 'ClearcorpTradesOutstandingReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clearcorpTradesOutstandingReport');                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderActivity');
                    return $translate.refresh();
                }],
                systemSetting: ['IntradayMarket', function(IntradayMarket) {
                    return IntradayMarket.getCurrentBusinessDate().$promise;
                }]

            }
        })
        .state('order-activity-report-from-trades-os', {
            parent: 'outstanding-trades-report',
            url: '/order-activity-report-for-trades-os/{orderNumber}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                            	 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.orderNumber}).$promise;
                                 }],
                                 businessDate: [ 'GlobalValues','DateUtils', function( GlobalValues,DateUtils) {
                                	 var systemSettings = GlobalValues.getSystemSettings();
                                     if(systemSettings!=null && systemSettings!=undefined){
                                     	return DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
                                     	 
                                     }else{
                                    	 return null;
                                     }
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
             
        
         
         };
    })();
