(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('LoggedInUsersForBank', LoggedInUsersForBank);

    LoggedInUsersForBank.$inject = ['$resource','DateUtils'];

    function LoggedInUsersForBank($resource, DateUtils) {
        var resourceUrl = 'api/loggedinusersforbank';
        return $resource(resourceUrl, {}, {
            
            'getHeaderData': {
                method: 'GET',  
                url: 'api/loggedinusersforbank/all-available-users-count',
               
                isArray: true
            },
            'getBankUsersDetails': {
                method: 'GET',  
                url: 'api/loggedinusersforbank/bank-users',
                params : {
                	bankId : '@bankId'
					
				},
				isArray: true
            },
            'getBranchUsersCountForBank': {
                method: 'GET',  
                url: 'api/loggedinusersforbank/branch-users-count-for-bank',
                params : {
                	bankId : '@bankId'
					
				},
                isArray: true
            },'getCustomerCountForBranch': {
                method: 'GET',  
                url: 'api/loggedinusersforbank/customers-count-for-branch',
                params : {
                	bankId : '@bankId'
					
				},
                isArray: true
            },'getCustomersUsersCount': {
                method: 'GET',  
                url: 'api/loggedinusersforbank/customer-users-count',
                params : {
                	bankId : '@bankId',
                	branchId: '@branchId'
					
				},
                isArray: true
            },'getCurrentBusinessDate': {
                method: 'GET',  
                url: 'api/eod-process',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            },
            /*'getExcelData': {
                method: 'POST',  
                url: 'api/loggedinusersforbank/export/excel',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getCSVData': {
                method: 'POST',  
                url: 'api/loggedinusersforbank/export/csv',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },*/
            'getExcelData': {
                method: 'POST',  
                url: 'api/bankloggedinusers/export/excel',
                responseType: 'arraybuffer',   
                params : {                	
                	isAsOfNow: '@isAsOfNow'					
				},
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getCSVData': {
                method: 'POST',  
                url: 'api/bankloggedinusers/export/csv',
                responseType: 'arraybuffer', 
                params : {                	
                	isAsOfNow: '@isAsOfNow'					
				},
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getSummaryData': {
                method: 'GET',  
                url: 'api/bankloggedinusers/bank-summary',
                params : {
                	
                	isAsOfNow: '@isAsOfNow'
					
				},
            	transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
            	}
            },
            
            'getLoggedInUsersAsOFNow': {
                method: 'GET',  
                url: 'api/bankloggedinusers/get-users-loggedin-as-of-now',
                params : {
                	
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getLoggedInUsersForTheDay': {
                method: 'GET',  
                url: 'api/bankloggedinusers/get-users-loggedin-for-the-day',
                params : {
                	
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            }
        });
    }
})();