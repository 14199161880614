(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OrganisationEditController', OrganisationEditController);

    OrganisationEditController.$inject = ['$scope', '$state','$rootScope', 'workflowSharedService','$uibModalInstance','Organisation','entity','GlobalValues'];

    function OrganisationEditController($scope, $state,$rootScope, workflowSharedService,$uibModalInstance, Organisation,entity,GlobalValues) {
        var vm = this;
        vm.clear=clear;
        vm.save=save;
        $scope.showButton=false;
        vm.resubmit=resubmit;
        $scope.clicked=false;
       vm.calculateFYEndMonth = calculateFYEndMonth;
       
       if(workflowSharedService.viewFrom == 'organisationtodolist_maker') {
    	   vm.organisation =  angular.fromJson($rootScope.payload); //load from pay-load when viewing from dash-board
         
        
          	$scope.showButton=true;
          }
       
       else{
    	   vm.organisation=entity;//load from the entity state during normal view
       	
       }
       
       if(vm.organisation.organisationType.shortName=="CORPORATE"){
    	   vm.showCorporate=true;
    	   vm.organisation.fyStartMonth= vm.organisation.fyStartMonth.toString();
           
           vm.organisation.fyEndMonth= vm.organisation.fyEndMonth.toString();
       }
       
      function save(){

          if(vm.organisation.organisationType.shortName=="CORPORATE"){
        	  Organisation.updateCorporate(vm.organisation);
        	  
          }
          else  if(vm.organisation.organisationType.shortName=="CONSULTANT"){
        	  Organisation.updateConsultant(vm.organisation);
          }
          $uibModalInstance.dismiss('cancel');
      }
      function resubmit(){
    	   if(vm.organisation.organisationType.shortName=="CORPORATE"){
         	  Organisation.resubmitCorporate({"taskId" : $rootScope.taskID},vm.organisation,onResubmitSuccess);
         	  
           }
           else  if(vm.organisation.organisationType.shortName=="CONSULTANT"){
         	  Organisation.resubmitConsultant({"taskId" : $rootScope.taskID},vm.organisation,onResubmitSuccess);
           }
    	  
      }
      function onResubmitSuccess(){
       		$rootScope.removeRow($rootScope.taskID);
    	  $uibModalInstance.dismiss('cancel');
      }
      
       function calculateFYEndMonth(){
       	
       		vm.organisation.fyEndMonth = (parseInt(vm.organisation.fyStartMonth) -1).toString();
       		if(vm.organisation.fyEndMonth == "0"){
       			vm.organisation.fyEndMonth = "12";
       		}
       	
       }
        function clear () {
        	
            $uibModalInstance.dismiss('cancel');
        }
       
        
    }
})();
