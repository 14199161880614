(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Organisation', Organisation);

    Organisation.$inject = ['$resource'];

    function Organisation ($resource) {
    	   var resourceUrl =  'api/corporate/:id';

           return $resource(resourceUrl, {}, {
               'query': { method: 'GET', isArray: true},
               'getCorporate': {
                   method: 'GET',
                   isArray:false,
                   params: {id:'@id'},
                   url: 'api/corporate/:id',
                   transformResponse: function (data) {
                       if (data) {
                           data = angular.fromJson(data);
                       }
                       return data;
                   }
               },
            'getConsultant': {
                method: 'GET',
                params: {id:'@id'},
                url:'api/consultant/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'updateCorporate': {
                method: 'PUT',
                url: 'api/corporates',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
            'updateConsultant': {
                method: 'PUT',
                url: 'api/consultants',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
    
            
            'resubmitCorporate': {
                method: 'PUT',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/resubmit/corporates',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                	return angular.toJson(copy);
                }
            },
      

            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
             'resubmitConsultant': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmit/consultants',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             },
  
        });
    }
})();
