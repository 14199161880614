(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('DefaultMarketSessionDialogController', DefaultMarketSessionDialogController);

    DefaultMarketSessionDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','Workflow','$uibModalInstance','DefaultMarket','entity','StaticLookUp','DateUtils'];

     function DefaultMarketSessionDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService,Workflow, $uibModalInstance,DefaultMarket,entity,StaticLookUp,DateUtils) {
        var vm = this;
        vm.defaultMarket=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitDefaultMarket = resubmitDefaultMarket;
        vm.globalValues = GlobalValues;
        vm.discardDefaultMarket = discardDefaultMarket;
        vm.rejectDefaultMarket= rejectDefaultMarket;
        vm.approveDefaultMarket= approveDefaultMarket;
       vm.viewMode=false;
      vm.controlMinDate =new Date();
                
        if(workflowSharedService.viewFrom == 'defaultMarketSessionSettings'){
        	vm.defaultMarket=entity;
        	vm.inputCloseTime = moment(vm.defaultMarket.inputCloseTime, 'HH:mm');
        	vm.inputOpenTime = moment(vm.defaultMarket.inputOpenTime,'HH:mm');
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarketSessionSettings_todolist_resubmit') {
        	vm.defaultMarket =  angular.fromJson($rootScope.payload);
        	vm.inputCloseTime = moment(vm.defaultMarket.inputCloseTime, 'HH:mm');
        	vm.inputOpenTime = moment(vm.defaultMarket.inputOpenTime,'HH:mm');
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarketSessionSettings_todolist_checker') {
        	vm.defaultMarket =  angular.fromJson($rootScope.payload); 
        	vm.inputCloseTime = moment(vm.defaultMarket.inputCloseTime, 'HH:mm');
        	vm.inputOpenTime = moment(vm.defaultMarket.inputOpenTime,'HH:mm');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarketSessionSettings_todolist_maker') {
        	vm.defaultMarket =  angular.fromJson($rootScope.payload); 
        	vm.inputCloseTime = moment(vm.defaultMarket.inputCloseTime, 'HH:mm');
        	vm.inputOpenTime = moment(vm.defaultMarket.inputOpenTime,'HH:mm');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='defaultMarketSessionSettings_auditlog'){
        	vm.defaultMarket =  angular.fromJson($rootScope.payload);   
        	vm.inputCloseTime = moment(vm.defaultMarket.inputCloseTime, 'HH:mm');
        	vm.inputOpenTime = moment(vm.defaultMarket.inputOpenTime,'HH:mm');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	vm.defaultMarket.inputOpenTime = vm.inputOpenTime.format( 'HH:mm');
        	vm.defaultMarket.inputCloseTime = vm.inputCloseTime.format( 'HH:mm');
        	
        	DefaultMarket.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.defaultMarket, onSaveSuccess, onSaveError);
			
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitDefaultMarket() {
        	vm.defaultMarket.inputOpenTime = vm.inputOpenTime.format( 'HH:mm');
        	vm.defaultMarket.inputCloseTime = vm.inputCloseTime.format( 'HH:mm');
        	DefaultMarket.resubmit({"taskId" : $rootScope.taskID}, vm.defaultMarket, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }

        function approveDefaultMarket() { 
        	DefaultMarket.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectDefaultMarket() { Workflow.rejectTask($rootScope.taskID); }

        function discardDefaultMarket() {
        	DefaultMarket.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

     }   
})();
