(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'clearCorpMarkupReport'
        })
        
        .controller('ClearCorpMarkupReportController', ClearCorpMarkupReportController);

    ClearCorpMarkupReportController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'DateUtils','AlertService','$filter',   'ClearCorpMarkupReportService', 'SODProcess'];

    function ClearCorpMarkupReportController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		DateUtils,AlertService,$filter,  ClearCorpMarkupReportService, SODProcess) {
       
    	var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_MARKUP_VIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
       
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.configSettings = vm.globalValues.getConfigSettings();
		
        /*vm.toTime = moment();
        vm.duration = moment.duration(  parseInt(vm.configSettings.timeDifferenceForTimePickerControl), 'hours')
	    vm.maxDate = moment().subtract( vm.duration);*/
	    vm.todayDate = new Date();
	    vm.getMarkup = getMarkup;
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    vm.exportDataToPdfFile = exportDataToPdfFile;
	    vm.filterData = {};	    
	    vm.fileName = "Customer_markup_view_as_on";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
	    vm.ExpandOrCollapseMarkup = true;
	    logScreenAccess();
	    vm.valid = false;
		vm.clicked=false;
	    function loadDates() {
        	
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
		}
    	function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.todayDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
    	}
    	
    	function loadSystemSettingsError(){
			
		}
        loadDates(); 
                   
        function copyFilterData(){
        	
        	 if(vm.bank!=null && vm.bank !=undefined){
        		 
        		 vm.filterData.bankId = angular.copy(vm.bank.id);
        	 }else{
        		
        		 vm.filterData.bankId = 0;
        	 }
        	 if(vm.branch!=null && vm.branch!=undefined){
        		
        		 vm.filterData.branchId = angular.copy(vm.branch.id);
        	}else{
        		 
        		vm.filterData.branchId = 0;
        	}if(vm.customer!=null && vm.customer!=undefined){
        		 
        		vm.filterData.customerId = angular.copy(vm.customer.id);
        	}else{
        		
        		vm.filterData.customerId = 0;
        	}
        	
        }
        
        function getMarkup(){
        	vm.valid = true;
        	vm.clearcorpmarkupReportDetails = [];
        	copyFilterData();
        	ClearCorpMarkupReportService.getMarkupForReport({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, 
    			customerId:vm.filterData.customerId }, 
    			onGetMarkupSuccess, onGetMarkupFailure);  		
        	
        }
        function onGetMarkupSuccess(result){        	 
        
        	 vm.clearcorpmarkupReportDetails = result;
			 vm.clicked=false;
        }
        function onGetMarkupFailure(error){
        	vm.clicked=false;
        }
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        function exportDataToExcelFile(){
      	 
      	  if(  vm.clearcorpmarkupReportDetails  !=null &&   vm.clearcorpmarkupReportDetails !=undefined &&  vm.clearcorpmarkupReportDetails .length>0){
      		 
      			vm.mimeType = 'application/vnd.ms-excel';
      			vm.extension = ".xls";  
      			ClearCorpMarkupReportService.exportMarkupExcel({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
        			customerId:  vm.filterData.customerId  } 
      			, processResponse );
      		 
      	  }
        }       
        function exportDataToCsvFile(){        	
        		
        	  if( vm.clearcorpmarkupReportDetails !=null &&  vm.clearcorpmarkupReportDetails!=undefined && vm.clearcorpmarkupReportDetails.length>0){
        		 
        			vm.mimeType = 'text/csv';
        			vm.extension = ".csv";  
        			ClearCorpMarkupReportService.exportMarkupCsv({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
          			customerId:  vm.filterData.customerId } , processResponse );
        		 
        	  }
        }
	    function exportDataToPdfFile(){	    	
	    	
	      	  if( vm.clearcorpmarkupReportDetails !=null &&  vm.clearcorpmarkupReportDetails!=undefined && vm.clearcorpmarkupReportDetails.length>0){
	      		 
	      			vm.mimeType = 'application/pdf';
	      			vm.extension =  ".pdf";  
	      			ClearCorpMarkupReportService.exportMarkupPdf({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
	        			customerId:  vm.filterData.customerId  } , processResponse );
	      		 
	      	  }
	    }
	    function processResponse(result){
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString =     vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension;   	
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}
	   
    }
})();