(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'clearCorpLimitUtilisationReportController'
        })
        
        .controller('ClearCorpLimitUtilisationReportController', ClearCorpLimitUtilisationReportController);

    ClearCorpLimitUtilisationReportController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'DateUtils','AlertService','$filter', 'ClearCorpLimitUtilisedReportService','SODProcess'];

    function ClearCorpLimitUtilisationReportController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		DateUtils,AlertService,$filter, ClearCorpLimitUtilisedReportService, SODProcess) {
       
    	var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.LIMIT_UTILISATION_REPORT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
       
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.configSettings = vm.globalValues.getConfigSettings();
	
	    vm.todayDate = new Date();
	    vm.getLimitUtilised = getLimitUtilised;
		vm.clicked=false;
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    vm.exportDataToPdfFile = exportDataToPdfFile;
	    vm.filterData = {};
	    vm.fileName = "Limit_utilisation_report_";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
	    	    
	    logScreenAccess();
	    vm.valid = false;
	   
	    function loadDates() {
        	
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
		}
    	function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.todayDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
    	}
    	
    	function loadSystemSettingsError(){
			
		}
        loadDates();      
        function copyFilterData(){
        	
        	 if(vm.bank!=null && vm.bank !=undefined){
        		 
        		 vm.filterData.bankId = angular.copy(vm.bank.id);
        	 }else{
        		
        		 vm.filterData.bankId = 0;
        	 }
        	 if(vm.branch!=null && vm.branch!=undefined){
        		
        		 vm.filterData.branchId = angular.copy(vm.branch.id);
        	}else{
        		 
        		vm.filterData.branchId = 0;
        	}if(vm.customer!=null && vm.customer!=undefined){
        		 
        		vm.filterData.customerId = angular.copy(vm.customer.id);
        	}else{
        		
        		vm.filterData.customerId = 0;
        	}
        	if(vm.instrument!=null && vm.instrument!=undefined){
        		 vm.filterData.instrumentId = angular.copy(vm.instrument.id);
        	}else{
        		vm.filterData.instrumentId = 0;
        	}
       }
        
        function getLimitUtilised(){
        		vm.valid = true;
        		copyFilterData();
        		ClearCorpLimitUtilisedReportService.getLimitsForReport({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, 
    			customerId:vm.filterData.customerId,  instrumentId: vm.filterData.instrumentId }, 
    			onGetLimitSuccess, onGetLimitFailure);
    			 		
        	
        }
        function onGetLimitSuccess(result){
        	 
        	 vm.limitUtilisationDetailsList = result;
			 vm.clicked=false;
        }
        function onGetLimitFailure(error){
        	vm.clicked=false;
        }
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        function exportDataToExcelFile(){
      	 
      	  if(  vm.limitUtilisationDetailsList  !=null &&   vm.limitUtilisationDetailsList !=undefined &&  vm.limitUtilisationDetailsList .length>0){
      		 
      			vm.mimeType = 'application/vnd.ms-excel';
      			vm.extension = ".xls";  
      			ClearCorpLimitUtilisedReportService.exportLimitsExcel({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId } ,vm.limitUtilisationDetailsList	, processResponse );
      		 
      	  }
        }       
        function exportDataToCsvFile(){        	
        		
        	  if( vm.limitUtilisationDetailsList !=null &&  vm.limitUtilisationDetailsList!=undefined && vm.limitUtilisationDetailsList.length>0){
        		 
        			vm.mimeType = 'text/csv';
        			vm.extension = ".csv";  
        			ClearCorpLimitUtilisedReportService.exportLimitsCsv({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
          			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId },vm.limitUtilisationDetailsList , processResponse );
        		 
        	  }
        }
	    function exportDataToPdfFile(){	    	
	    	
	      	  if( vm.limitUtilisationDetailsList !=null &&  vm.limitUtilisationDetailsList!=undefined && vm.limitUtilisationDetailsList.length>0){
	      		 
	      			vm.mimeType = 'application/pdf';
	      			vm.extension =  ".pdf";  
	      			ClearCorpLimitUtilisedReportService.exportLimitsPdf({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
	        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId} ,vm.limitUtilisationDetailsList, processResponse );
	      		 
	      	  }
	    }
	    function processResponse(result){
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString =     vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension;   	
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}
	    
	    vm.getBuyingPower = function getBuyingPower(limit){
        	var buyingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
        	var totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
        	if(limit.limitType=='GROSSSELL'){
        		buyingPower = 0;
        	}else if(limit.limitType=='GROSSBUY'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy);
        	}else if(limit.limitType=='GLOBALGROSS'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue  + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		buyingPower = limit.limitAllocatedInUSD - totalBuy;
        	}
        	if( buyingPower != undefined && buyingPower !=null && buyingPower != 0 && buyingPower>0)
				return $filter('number')(buyingPower, vm.noOfDecimalsForAmount);
			else{
        		return '-';
        	}
        		
		   
        	
        }
        
        vm.getExposure = function getExposure(limit){
        	var exposure = 0;
        	var totalBuy = 0;
        	var totalSell = 0;
        	if(limit.limitType!='NET'){
            	totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
            	totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
            	exposure = (limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue + limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue) * 100/limit.limitAllocatedInUSD;
        	}else{
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));

            	var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		exposure =  Math.max.apply(Math, xVals) * 100/limit.limitAllocatedInUSD;
        	}
        	
        	if( !isNaN(exposure) && exposure != undefined && exposure !=null && exposure != 0 ){
        		return $filter('number')(exposure, vm.noOfDecimalsForAmount) + '%';
        	}else{
        		return '0.00%';
        	}
        }
        
        vm.getSellingPower = function getSellingPower(limit){
        	var sellingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue);
        	var totalSell = limit.totalPendingSellOrderValue + (limit.totalSellTradeValue + limit.previousDaySellTradeValue);
        	if(limit.limitType=='GROSSSELL'){
        		sellingPower = limit.limitAllocatedInUSD - (totalSell);
        	}else if(limit.limitType=='GROSSBUY'){
        		sellingPower = 0;
        	}else if(limit.limitType=='GLOBALGROSS'){
        		sellingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		sellingPower = limit.limitAllocatedInUSD - totalSell;
        	}
        	if( sellingPower != undefined && sellingPower !=null && sellingPower != 0 && sellingPower>0)
				return $filter('number')(sellingPower, vm.noOfDecimalsForAmount);
			else{
        		return '-'
        	}
        }
        
        vm.calculateGrossBuyTotal = function calculateGrossBuyTotal(limitUtilisationDetail){
        	return limitUtilisationDetail.totalBuyTradeValue + limitUtilisationDetail.previousDayBuyTradeValue + limitUtilisationDetail.totalPendingBuyOrderValue;
        }
        vm.calculateGrossSellTotal = function calculateGrossSellTotal(limitUtilisationDetail){
        	return limitUtilisationDetail.totalSellTradeValue + limitUtilisationDetail.previousDaySellTradeValue + limitUtilisationDetail.totalPendingSellOrderValue
        }
        
        vm.calculateNetBuyTotal = function calculateNetBuyTotal(limitUtilisationDetail){
        	return limitUtilisationDetail.totalPendingBuyOrderValue+((limitUtilisationDetail.totalBuyTradeValue + limitUtilisationDetail.previousDayBuyTradeValue) - (limitUtilisationDetail.totalSellTradeValue + limitUtilisationDetail.previousDaySellTradeValue));
        }
        
        vm.calculateNetSellTotal = function calculateNetSellTotal(limitUtilisationDetail){
     	   return limitUtilisationDetail.totalPendingSellOrderValue+((limitUtilisationDetail.totalSellTradeValue + limitUtilisationDetail.previousDaySellTradeValue) - (limitUtilisationDetail.totalBuyTradeValue + limitUtilisationDetail.previousDayBuyTradeValue));
        }
    }
})();