(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerRegistrationControllerNew', CustomerRegistrationControllerNew);

		CustomerRegistrationControllerNew.$inject = ['$scope', '$rootScope', '$state','$timeout', 'Auth', '$window', 'StaticLookUp', 
    'MessageBox', 'CustomerRegistration', '$sessionStorage', 'DateUtils', 'Country', 'GlobalValues', 'globalValue', 'AlertService', 
    '$filter','FileUtil', 'Branch', 'customerTypeWiseInputDetails', 'TACViewForEntitiesService', 'TacViewForEntitiesUtility'];

    function CustomerRegistrationControllerNew ($scope, $rootScope, $state, $timeout, Auth, $window, StaticLookUp, 
        MessageBox, CustomerRegistration, $sessionStorage, DateUtils, Country, GlobalValues, globalValue, AlertService,
        $filter,FileUtil, Branch, customerTypeWiseInputDetails, TACViewForEntitiesService, TacViewForEntitiesUtility) {
        var vm = this;
        vm.customerTypes = StaticLookUp.getCustomerTypes();
		vm.sourceOfIntro = StaticLookUp.getsourceOfIntro();
		vm.theme = 'light';
        //initialize customer type wise input details
        vm.customerTypeWiseInputDetails = customerTypeWiseInputDetails;

        //Indices of input details in customer type wise input details array
        $scope.firstNameIndex = 0;
        $scope.middleNameIndex = 1;
        $scope.lastNameIndex = 2;
        $scope.entityNameIndex = 3;
        $scope.panNoIndex = 4;
        $scope.panImageIndex = 5;
        $scope.legalEntityIdentifierIndex = 6;
        $scope.leiExpiryDateIndex = 7;
        $scope.cinIndex = 8;
        $scope.gstinIndex = 9;
        $scope.stateCodeIndex = 10;
        $scope.dobDoiIndex = 11;
		vm.showSingleUser=false;
		vm.showMultiUser=false;
		vm.validPan=false;
		vm.touchedsourceOfIntro=true;


      
        vm.globalValues = GlobalValues;
		vm.configSettings = globalValue;
        $sessionStorage.isAuth = true;
        vm.checkPanImage = false;
        vm.customerRegistrationRequest = {};
        vm.customerRegistrationRequest.userMobileCountryCode = 91;
        vm.required = false;
        vm.otpLength = globalValue.defaultOTPLength;
        var Extensions = ["pdf","png","jpg"];
        vm.todayDate=new Date();
        vm.leiExpiryRequired = false;
    	vm.disableLeiExpiry = true;
    	vm.leiMinDate = vm.todayDate;
		vm.leiMinDate.setDate(new Date().getDate() + 1);
		//vm.leiMinDate =  vm.leiMinDate ;
        vm.skipOTPUserContact=true;
        vm.skipOTPUserEmailContact=true;
    	vm.showSkipOTP = true;
    	if(globalValue.skipOTPVerification == 'No' || globalValue.skipOTPVerification == 'no'|| globalValue.skipOTPVerification == 'NO'){
            vm.skipOTPUserContact=false;
            vm.skipOTPUserEmailContact=false;
    		vm.showSkipOTP = false;
			vm.customerRegistrationRequest.isSkipOTPUserMobile=false;
		    vm.customerRegistrationRequest.isSkipOTPUserEmailContact=false;
			
    	}else{
            vm.skipOTPUserContact=true;
            vm.skipOTPUserEmailContact=true;
    		vm.showSkipOTP = true;
			vm.customerRegistrationRequest.isSkipOTPUserMobile=true;
		    vm.customerRegistrationRequest.isSkipOTPUserEmailContact=true;
    	}
    	//get rejected request by reference number
    	vm.refNo = "";
        vm.clearcorpOrgId = 1;
        vm.clearcorpForCustomerTACTypeId = 1;
        vm.bankForCustomerTACTypeId = 3;
    	vm.fetchRejectedRequest = fetchRejectedRequest;
        vm.openHelpWindow = openHelpWindow;
        vm.previewClearcorpTAC = previewClearcorpTAC;
        vm.previewBankTAC = previewBankTAC;
		vm.maximumFileSize = "200 kb"
		vm.customerRegistrationRequest.assignTradingBranch=true;
		vm.disableTradingBranch = true;
		vm.customerRegistrationRequest.otpVerifiedEmailId="";
		vm.customerRegistrationRequest.otpVerifiedMobileId="";
		vm.customerRegistrationRequest.isSkipOTPUserMobile=true;
		vm.customerRegistrationRequest.isSkipOTPUserEmailContact=true;

		setEnvironmentName();

        $window.onresize = function() {
            changeTemplate();
        };
        changeTemplate();

        function changeTemplate() {
            var screenWidth = $window.innerWidth;
            if (screenWidth < 768) {
                vm.mobileView = true;
            } else if (screenWidth >= 768) {
                vm.mobileView = false;
            }
        }
        
        $scope.$watch('vm.customerRegistrationRequest.customerType', function(value){
            if(value!=null && value!=undefined){
				vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
					return item.customerType.id == value.id;
				  });
				if(value.customerUserType.shortName=='SingleUserCustomer'){
					vm.showSingleUser=true;
					vm.showMultiUser=false;
						}else if(value.customerUserType.shortName=='MultiUserCustomer'){
							vm.showMultiUser=true;
							vm.showSingleUser=false;
					
						}
            }else{
				vm.showSingleUser=true;
				vm.showMultiUser=false;
                vm.inputDetailsForSelectedCustType = [
                    {customerType: null, fieldName: 'FirstName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'MiddleName', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LastName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'EntityName', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'PANNo', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'PANImage', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'LegalEntityIdentifier', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LEIExpiryDate', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'GSTIN', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'StateCode', display: true, isMandatory: false} 
                ]
            }
			userInfoContentChange();

        });
       
        getCountry();
        getClearcorpTACMaster();
        
        function getCountry(){
        	Country.get({id: 1}, onGetSuccess, onGetFailure);
        }
        
        function onGetSuccess(result){
            vm.customerRegistrationRequest.country=result;
        }

        function onGetFailure(){
        		
        }

        function getClearcorpTACMaster(){
            TACViewForEntitiesService.getActiveTACForOrganisationAndTacType({orgId: vm.clearcorpOrgId, tacTypeId: vm.clearcorpForCustomerTACTypeId}, function(result){
                vm.clearcorpTAC = result;
            })
        }

        vm.invalidPanImageSize = false;
        vm.invalidPanImage = false;
        vm.isUserMobileVerified = false;
        vm.isUserEmailVerified = false;
        vm.checkUserOTP = false;
        vm.checkUserEmailOTP = false;
        vm.invalidUserOtp = false;
        vm.invalidUserEmailOtp = false;
        vm.visibleUserOTP = false;
        vm.visibleUserOTPEmail = false;
        vm.submit = submit;
        vm.showGenerateOTPButton = false;
        vm.showGenerateOTPEmailButton = false;
        vm.disableGenerateOTP = true;
        vm.disableGenerateOTPEmail = true;
        vm.generateUserOTP = generateUserOTP;
        vm.generateUserOTPEmail = generateUserOTPEmail;
        vm.wrongUserOTPCount=0;
    
      
        vm.minimumFileSizeInKB = globalValue.uploadedFileMinimumSize;
        vm.maximumFileSizeinKB = globalValue.uploadedFileMaximumSize;
        vm.invalidPanImageSixeText = "";
        vm.invalidPanImageFileNameText = "";
        vm.invalidPanImageText = "";
        vm.maxFileNameLength = (globalValue.uploadedFileNameLength != null && globalValue.uploadedFileNameLength != undefined && globalValue.uploadedFileNameLength != '') ?globalValue.uploadedFileNameLength : 150 ;
        var minFileSizeinBytes = 0;
        var maxFileSizeinBytes = 100;
        if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
        	minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
        }
        if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){
        	maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
        }
		
		function userInfoContentChange() {
			if(vm.showMultiUser){
				vm.firstNameInfo = 'Enter Authorised Person\'s  First Name';
				vm.middleNameInfo = 'Enter Authorised Person\'s Middle Name';
				vm.lastNameInfo = 'Enter Authorised Person\'s Last Name';
				vm.emailInfo = 'Enter Authorised Person\'s Email ID';
				vm.mobileInfo = 'Enter Authorised Person\'s Mobile No.';
				vm.dobInfo = 'Enter Authorised Person\'s Date of Birth';
				vm.address1Info = 'Enter the Address First line of Entity';	
				vm.address2Info = 'Enter the Address Second line of Entity';
				vm.panImageInfo = 'Upload Image of Entity PAN card in JPG, PNG, PDF (max size 200 Kb)';
			}else{
				vm.firstNameInfo = 'Enter your First Name';
				vm.middleNameInfo = 'Enter your Middle Name';
				vm.lastNameInfo = 'Enter your Last Name';
				vm.emailInfo = 'Enter your Email ID';
				vm.mobileInfo = 'Enter your Mobile No.';
				vm.dobInfo = 'Enter your Date of Birth';
				vm.address1Info = 'Enter your Address First line';
				vm.address2Info = 'Enter your Address Second line';
				vm.panImageInfo = 'Upload Image of your PAN card in JPG, PNG, PDF (max size 200 Kb)';
			}
			
		}

		$scope.userotpInput = {
			size : globalValue.defaultOTPLength,
			type : "password",
			onDone : function(value) {
				vm.userMobileOtp = value;
			},
			onChange : function(value) {
				if(value!= undefined && value!=null){
					vm.checkUserOTP = false;
					vm.isUserMobileVerified = false;
					if(value.length==vm.otpLength){
						vm.checkUserOTP = true;
						vm.invalidUserOtp = false;
						if(vm.customerRegistrationRequest.userMobileCountryCode==null && vm.customerRegistrationRequest.userMobileCountryCode==undefined){
							vm.customerRegistrationRequest.userMobileCountryCode = "";
						}
						var mobileNo = vm.customerRegistrationRequest.userMobileCountryCode.toString() + vm.userMobile;
						CustomerRegistration.verifyOTP({ mobile: mobileNo, otp: vm.userMobileOtp }, onMobileVerifySuccess, onMobileVerifyFailure);
						//vm.userOtpVerification = result.isVerified;
						/* if(vm.userOtpVerification){
							vm.isUserMobileVerified = true;
							vm.invalidUserOtp = false;
						}else{
							vm.invalidUserOtp = true;
							vm.isUserMobileVerified = false;
							vm.wrongUserOTPCount=vm.wrongUserOTPCount+1;
							if(vm.wrongUserOTPCount>=globalValue.maximumOTPWrongAttemptCount) // check if the wrong user otp was entered for 3 or more times 
							{
								AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.");
							}		            			
						} 
					});*/
					} else {
						vm.checkUserOTP = false;
					}
				}
			}
		};
		function onMobileVerifySuccess(result) {
			if (result.isVerified == 'VERIFIED') {
				vm.isUserMobileVerified = true;
				vm.invalidUserOtp = false;
				vm.customerRegistrationRequest.otpVerifiedMobileId=result.otpVerifiedId;
				//save the mobilOTPID
			}

		}
		function onMobileVerifyFailure(result) {
			vm.invalidUserOtp = true;
			vm.isUserMobileVerified = false;
			vm.wrongUserOTPCount = vm.wrongUserOTPCount + 1;
			if (vm.wrongUserOTPCount >= globalValue.maximumOTPWrongAttemptCount) // check if the wrong user otp was entered for 3 or more times 
			{
				AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.");
			}

		}
		$scope.userOtpEmailInput = {
			size : globalValue.defaultOTPLength,
			type : "password",
			onDone : function(value) {
				vm.userEmailOtp = value;
			},
			onChange : function(value) {
				if(value!= undefined && value!=null){
					vm.checkUserEmailOTP = false;
					vm.isUserEmailVerified = false;
					if(value.length==vm.otpLength){
						vm.checkUserEmailOTP = true;
						vm.invalidUserEmailOtp = false;
						/* CustomerRegistration.verifyOTPEmail({email: vm.userEmail, otp: vm.userEmailOtp}, function(result){
							vm.userOtpEmailVerification = result.isVerified;
							if(vm.userOtpEmailVerification){
								vm.isUserEmailVerified = true;
								vm.checkPanImage = true;
								vm.invalidUserEmailOtp = false;
							}else{
								vm.invalidUserEmailOtp = true;
								vm.isUserEmailVerified = false;
							}
						}); */
						CustomerRegistration.verifyOTPEmail({email: vm.userEmail, otp: vm.userEmailOtp},onemailChangeSuccess,onemailChangefailed);
					}else{
						vm.checkUserEmailOTP = false;
					}
				}
			}
		};
		
		function onemailChangeSuccess(result){
			if(result.isVerified=='VERIFIED'){
			vm.isUserEmailVerified = true;
			vm.checkPanImage = true;
			vm.invalidUserEmailOtp = false;
			vm.customerRegistrationRequest.otpVerifiedEmailId=result.otpVerifiedId;
			//save emailOTP ID

		}
	}
		function onemailChangefailed(){
			vm.invalidUserEmailOtp = true;
			vm.isUserEmailVerified = false;
		}

        //validate pan image size
        $scope.$watch('vm.panImage', function(value){
        	
        	if(value!= undefined && value!=null){
        		if(value.name!=null && value.name!=undefined){
            			vm.inputPanImage=value.name;
            			if(vm.panImage.name!=null && vm.panImage.name!=undefined){
                			vm.inputPanImage=vm.panImage.name;
                			vm.customerRegistrationRequest.fileExtension = vm.inputPanImage.substring(vm.panImage.name.lastIndexOf(".") + 1);
            			}else{
            				vm.panImageBytes = vm.panImage;
            			} 
            				 var reader = new FileReader();
            				 reader.readAsArrayBuffer(vm.panImage);
            				reader.onloadend = function(e) {
            					if (e.target.readyState === FileReader.DONE) {
									var binary = '';
            						var bytes = new Uint8Array( e.target.result ); 
									var len = bytes.byteLength;
    	            				       for (var i = 0; i < len; i++) {
    	            				           binary += String.fromCharCode( bytes[ i ] );
    	            				       }
    	            				      var data = window.btoa( binary );
    	      				      		//var data = btoa(String.fromCharCode.apply(null, bytes)); 
    	      				      		vm.panImageBytes =  data;  
    	      				      	}
    	        					 $scope.$digest();
            					
            					  // Check the file signature against known types

            				};
            			 
            			
            		
        		}
        	}
        	else{
        		angular.element("input[type='file']").val(null);
        		vm.panImage=null;
        		vm.inputPanImage=null;
        	}
        })
		$scope.$watch('vm.skipOTPUserContact', function(value){
       	 if(value!=null && value!=undefined){
			vm.customerRegistrationRequest.isSkipOTPUserMobile=value;
       		vm.skipOTPUserMobile = value;
       	 	if(value){
       		
       	 		vm.showGenerateUserOTPButton = false;// if skip otp then don't show the generate button
       	 		vm.disableGenerateUserOTP = true; // 
       	 		vm.userMobileOtp = null;
       	 	}else if(!value && !vm.isUserMobileVerified ){
       	 		 //show generate button when skip is unchecked
       	 		vm.showGenerateUserOTPButton = false;
       	 		vm.disableGenerateUserOTP = true;
       	 		vm.visibleUserOTP = false;
				vm.customerRegistrationRequest.isSkipOTPUserMobile=value;
       	 		if(vm.customerRegistrationRequest.userMobile!=null && vm.customerRegistrationRequest.userMobile!=undefined 
          				 && vm.customerRegistrationRequest.userMobile.length>=1 ){
							vm.disableGenerateOTP = false;
						 }
       	 			
       	 			
       	 		
       	 	}else if(!value && vm.isUserMobileVerified){
       	 		vm.showGenerateUserOTPButton = false;// if skip otp then don't show the generate button
       	 		vm.disableGenerateUserOTP = true; // 
       	 		//vm.visibleUserOTP = true;
       	 	}
            validateUserMobile(vm.customerRegistrationRequest.userMobile);
        }
        });

        function validateUserMobile(value){
            if(value!= undefined && value!=null){
                    vm.visibleUserOTP = false;
                    vm.userMobile = vm.customerRegistrationRequest.userMobile;
                    vm.userMobileOtp = null;
                    vm.isUserMobileVerified = false;
                
     
                if(value.length>=1  ){
					var isSkipOTPForUserContact = vm.skipOTPUserContact;
					vm.skipOTPUserMobile = isSkipOTPForUserContact;
                        
                        if(!vm.skipOTPUserMobile && (vm.customerRegistrationRequest.userMobileCountryCode!=vm.verifiedUserMobileCountryCode || value!=vm.verifiedUserMobile)){
                                
                                vm.showGenerateUserOTPButton = true;
                                vm.disableGenerateUserOTP = false;
                                
                        }else {
							vm.showGenerateUserOTPButton = false;
							vm.disableGenerateUserOTP = true;
                        }
                }else{
                    vm.disableGenerateUserOTP = true;
                    vm.showGenerateUserOTPButton = false;
                }
                
            }else{
                vm.showGenerateUserOTPButton = false;
                vm.userMobileOtp = null;
            }
        }

        $scope.$watch('vm.skipOTPUserEmailContact', function(value){
          	 if(value!=null && value!=undefined){
				vm.customerRegistrationRequest.isSkipOTPUserEmailContact=value;
        		vm.skipOTPUserEmail = value;
        	 	if(value){
        	 		vm.showGenerateUserOTPEmailButton = false;// if skip otp then don't show the generate button
        	 		vm.disableGenerateUserOTPEmail = true; // 
        	 		vm.userEmailOtp = null;
        	 	}else if(!value && !vm.isUserEmailVerified ){
        	 		 //show generate button when skip is unchecked
        	 		vm.showGenerateUserOTPEmailButton = false;
        	 		vm.disableGenerateUserOTPEmail = true;
        	 		vm.visibleUserOTPEmail = false;
        	 		if(vm.customerRegistrationRequest.userEmail!=null && vm.customerRegistrationRequest.userEmail!=undefined 
           				 && vm.customerRegistrationRequest.userEmail.length>=1 && ((vm.customerRegistrationRequest.userEmail.indexOf('@')>-1) && (vm.customerRegistrationRequest.userEmail.indexOf('.')>-1))){
							vm.disableGenerateUserOTPEmail = false;
        	 		}
        	 	}else if(!value && vm.isUserEmailVerified){
        	 		vm.showGenerateUserOTPEmailButton = false;// if skip otp then don't show the generate button
        	 		vm.disableGenerateUserOTPEmail = true; // 
        	 	}

                validateUserEmail(vm.customerRegistrationRequest.userEmail);
             }
         });

        function validateUserEmail(value){
            if(value!= undefined && value!=null){
                vm.visibleUserOTPEmail = false;
                vm.userEmail = vm.customerRegistrationRequest.userEmail;
                vm.userEmailOtp = null;
                vm.isUserEmailVerified = false;
                var isSkipOTPForUserEmailContact = vm.skipOTPUserEmailContact;
                vm.skipOTPUserEmail = isSkipOTPForUserEmailContact;
                if(value.length>=1 && !vm.skipOTPUserEmail){
                        vm.disableGenerateUserOTPEmail = true;
                        vm.showGenerateUserOTPEmailButton = false;
                        if((value!=vm.verifiedUserEmail)){
                                vm.showGenerateUserOTPEmailButton = true;
						}else{
							vm.isUserEmailVerified = true;
                            vm.showGenerateUserOTPEmailButton = false;
						}
                        if((value.indexOf('@')>-1) && (value.indexOf('.')>-1)){
                                    vm.disableGenerateUserOTPEmail = false;
                                }else{
                                    vm.disableGenerateUserOTPEmail = true;
                            }
                              
                                
                        
                }else{
                    vm.disableGenerateUserOTPEmail = true;
                    vm.showGenerateUserOTPEmailButton = false;
                }
                
            }else{
                vm.showGenerateUserOTPEmailButton = false;
                vm.userEmailOtp = null;
            }
        }
        
        //send otp sms to the user contact mobile number
        $scope.$watch('vm.customerRegistrationRequest.userMobile', function(value){
            validateUserMobile(value);
       
        });
        function generateUserOTP(){
        	
        	vm.disableGenerateUserOTP = true;
        	vm.showGenerateUserOTPButton = false;
    		
        	if(vm.customerRegistrationRequest.userMobileCountryCode==null && vm.customerRegistrationRequest.userMobileCountryCode==undefined){
        		vm.customerRegistrationRequest.userMobileCountryCode = "";
        	}
        	var mobileNo = vm.customerRegistrationRequest.userMobile;
        	var mobileCountryCode = vm.customerRegistrationRequest.userMobileCountryCode.toString();
    		CustomerRegistration.sendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode}, onUserOtpSendSuccess, onUserOtpSendError);
        }
        function onUserOtpSendSuccess(result){
        	AlertService.info("OTP has been sent to your mobile");
        	vm.wrongUserOTPCount=0;
        	vm.visibleUserOTP = true;
        	vm.userMobileOtp = null;
        	vm.userMobile = vm.customerRegistrationRequest.userMobile;
    		vm.isUserMobileVerified = false;
    		var email = 0;
        	
        }
        
        function onUserOtpSendError(){
        	AlertService.info("Error occurred while generating OTP");
        	vm.disableGenerateUserOTP = false;
        	vm.showGenerateUserOTPButton = true;
        }
        
        $scope.$watch('vm.customerRegistrationRequest.userEmail', function(value){
            validateUserEmail(value);
       
        });

        function generateUserOTPEmail(){
        	vm.disableGenerateUserOTPEmail = true;
        	vm.showGenerateUserOTPEmailButton = false;
    		CustomerRegistration.sendOTPEmail({email: vm.customerRegistrationRequest.userEmail}, onUserEmailOtpSendSuccess, onUserEmailOtpSendError);
        }
        
        function onUserEmailOtpSendSuccess(result){
        	AlertService.info("OTP has been sent to your email");
        	vm.visibleUserOTPEmail = true;
        	vm.userEmailOtp = null;
        	vm.userEmail = vm.customerRegistrationRequest.userEmail;
    		vm.isUserEmailVerified = false;
    		var email = 0;
        	
        }
        
        function onUserEmailOtpSendError(){
        	AlertService.info("Error occurred while generating OTP");
        	vm.disableGenerateUserOTPEmail = false;
        	vm.showGenerateUserOTPEmailButton = true;
        }

        



        
        
         $scope.$watch('vm.customerRegistrationRequest.homeBranch', function(value){
        	if(value!=null&&value!=undefined){
        		vm.tradingBranchAddress = value.address;
        	}else{
        		vm.tradingBranchAddress = null;
        	}
        });
        
        function submit(){
			
        	if( vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display && vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
          			 && vm.customerRegistrationRequest.leiExpiryDate!= '' && moment(vm.customerRegistrationRequest.leiExpiryDate).isBefore(vm.todayDate,'day')){
           		
           		
           		$scope.$emit('errorMessage', ($filter('translate')('gatewayApp.customerRegistration.expiryDate')));
          		 
           	}else if(moment(vm.customerRegistrationRequest.dob).isAfter(vm.todayDate,'day')){
        		
       		
        		$scope.$emit('errorMessage', ($filter('translate')('gatewayApp.customerRegistration.futuredateOfBirth')));
       	 
        	}else{
				vm.clicked=true;
        		vm.customerRegistrationRequest.branchAddress = vm.tradingBranchAddress;
                //update clearcorp TAC and bank TAC in cust reg.request in order to update tacForCustomerRequested
                if(vm.clearcorpTAC!=null && vm.clearcorpTAC.id!=null){
                    vm.customerRegistrationRequest.clearcorpTAC = vm.clearcorpTAC;
                }
                if(vm.bankTAC!=null && vm.bankTAC.id!=null){
                    vm.customerRegistrationRequest.bankTAC = vm.bankTAC;
                }
				
				if(vm.customerRegistrationRequest.isSkipOTPUserMobile == null || vm.customerRegistrationRequest.isSkipOTPUserMobile == undefined){
					vm.customerRegistrationRequest.isSkipOTPUserMobile=true;
				}
				if(vm.customerRegistrationRequest.isSkipOTPUserEmailContact == null || vm.customerRegistrationRequest.isSkipOTPUserEmailContact == undefined){
					vm.customerRegistrationRequest.isSkipOTPUserEmailContact=true;
				}
        		//vm.customerRegistrationRequest.panImage = vm.panImageBytes;
			
				
        		$rootScope.panFile = vm.panImage;
        		prepareDatesforServer();
        		var fd = new FormData();
        		fd.append('pan', vm.panImage);
        		fd.append('customer', JSON.stringify(vm.customerRegistrationRequest));
        		if(vm.panImage.name!=null && vm.panImage.name!=undefined){
            		CustomerRegistration.validateCustomerOnboarding(fd, onSubmitSuccess, onSubmitFailure);
        		}else{
        			onSubmitSuccess('success');
        		}
			
       	 }
        	
        }
		function prepareDatesforServer(){
			if(vm.inputDetailsForSelectedCustType!=null 
				&& vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
				&& vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
				vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.companyName;
				vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.companyName;
			}
			//vm.customerRegistrationRequest.fileExtension = vm.panImage.name.substring(vm.panImage.name.lastIndexOf(".") + 1);
			// unnecessary columns set to empty or null
			vm.customerRegistrationRequest.dobOrDoi=null;
			vm.customerRegistrationRequest.primaryContactName='';
			vm.customerRegistrationRequest.primaryMobileCountryCode='';
			vm.customerRegistrationRequest.primaryMobile='';
			vm.customerRegistrationRequest.primaryEmail='';
			vm.customerRegistrationRequest.userAddress1='';
			vm.customerRegistrationRequest.userCity='';
			vm.customerRegistrationRequest.userCountry=null;
			vm.customerRegistrationRequest.userState=null;
			vm.customerRegistrationRequest.userPincode='';
			vm.customerRegistrationRequest.tradingBranch = null;
			
		
		}
        
        function onSubmitSuccess(result){
        	$state.go('customerRegistrationPreview', {customerRegistrationRequest : vm.customerRegistrationRequest, theme : 'light', image :vm.panImageBytes });
			vm.clicked=false;
        }
        
        function onSubmitFailure(){
			vm.clicked=false;
        	vm.customerRegistrationRequest.dob  = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dob);
        	
        	if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
				vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.companyName;
				vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.companyName;
				vm.required = false;
			}
            if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display){
                if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                         && vm.customerRegistrationRequest.leiExpiryDate!= ''){
                    vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.leiExpiryDate);
                }                
            }

        }
        
		vm.resendUserOTP = function resendUserOTP(){
        	var email = '0';
    		if(vm.customerRegistrationRequest.userEmail!=null && vm.customerRegistrationRequest.userEmail!=''){
    			email = vm.customerRegistrationRequest.userEmail;
    		}
        	if(vm.customerRegistrationRequest.userMobileCountryCode==null && vm.customerRegistrationRequest.userMobileCountryCode==undefined){
        		vm.customerRegistrationRequest.userMobileCountryCode = "";
        	}
        	var mobileNo = vm.customerRegistrationRequest.userMobile;
        	var mobileCountryCode = vm.customerRegistrationRequest.userMobileCountryCode.toString();
        	CustomerRegistration.sendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode}, onResendUserOtpSuccess, onResendUserOtpError);
        }

        function onResendUserOtpSuccess(result){
        	AlertService.info("OTP has been resent to your mobile");
        	vm.wrongUserOTPCount=0;
        }
        
        function onResendUserOtpError(){
        	
        }
        
    
		vm.resendUserOTPEmail = function resendUserOTPEmail(){
        	CustomerRegistration.sendOTPEmail({email: vm.customerRegistrationRequest.userEmail}, onResendUserOtpEmailSuccess, onResendUserOtpEmailError);
        }

        function onResendUserOtpEmailSuccess(result){
        	AlertService.info("OTP has been resent to your email");
        }
        
        function onResendUserOtpEmailError(){
        	
        }
        
       
        
		$scope.$watch('vm.customerRegistrationRequest.bank', function(value){
			if(value!=null && value!='' && value!=undefined){
				//var bankName = value.bankName;
				//bankName = bankName.substring(0, 5); // issue fix mantis id :20
				vm.filterText = value.ccpMembershipId;
			}
		});
		  $scope.$watch('vm.customerRegistrationRequest.legalEntityIdentifier', function(value){
	        	if(value!= undefined && value!=null){
	        		
	        		if(value.length==20){
	        			vm.leiExpiryRequired = true;
	                	vm.disableLeiExpiry = false;
	                	//vm.customerRegistrationRequest.leiExpiryDate = null;
	                	
	        		}else{
	        			vm.leiExpiryRequired = false;
	                	vm.disableLeiExpiry = true;
	                	vm.customerRegistrationRequest.leiExpiryDate = null;
	        		}        		
	            	
	        	}
	        	else{
	        		vm.leiExpiryRequired = false;
                	vm.disableLeiExpiry = true;
                	vm.customerRegistrationRequest.leiExpiryDate = null;
	        	}
	        })
	        
	      function fetchRejectedRequest(){
			  $state.go('request-reference-number', {refNo: vm.refNo});
		  }

	      var subscribeFetchRequest = $rootScope.$on('fetchRequest', function(event, result) {
	        	if($rootScope.refNo==null || $rootScope.refNo==undefined){
	        		vm.refNo = "";
	        	}else{
	        		vm.refNo = $rootScope.refNo;
                    vm.requestDetailsToResubmit = $rootScope.requestDetailsToResubmit;
                    if(vm.requestDetailsToResubmit.customerType.isRequiredForDisplay==false){
                        vm.requestDetailsToResubmit.customerType = null;
                        vm.touchedcustomertype=true;
						vm.touchedsourceOfIntro=true;
                    }
	        		getCustomerRegistrationRequestToResubmit(vm.refNo);
	        	}
	      });
	        
	      $scope.$on('$destroy', function() {
	        	subscribeFetchRequest();	        	
	      });
	      
	      //get request by reference number to resubmit  
	      function getCustomerRegistrationRequestToResubmit(refNo){
			  if(refNo!=""){
		    	  vm.customerRegistrationRequest = {};
		          vm.customerRegistrationRequest.userMobileCountryCode = 91;
				  vm.panImage = null;
				  vm.panImageBytes = null;
				  vm.tradingBranch = null;
				  vm.inputPanImage = null;
				  //CustomerRegistration.getCustomerRegistrationRequestToResubmit({requestReferenceNo: refNo}, onFetchSuccess, onFetchFailure)
                  onFetchSuccess(vm.requestDetailsToResubmit);
			  }
		  }
	      
	      function onFetchSuccess(result){
			  if(result.id!=null && result.id!=null){
				  vm.customerRegistrationRequest = angular.copy(result);
				  $timeout(function(){
					  vm.customerRegistrationRequest.state = angular.copy(result.state);
				  }, 1000);
				  vm.panImage = vm.customerRegistrationRequest.panImage;
				  vm.panImageBytes = vm.customerRegistrationRequest.panImage;
				  vm.customerRegistrationRequest.dob = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dob);
                  vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
                    return item.customerType.id == vm.customerRegistrationRequest.customerType.id;
                });
				  if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
					  vm.customerRegistrationRequest.companyName = vm.customerRegistrationRequest.customerFirstName;
                      //vm.customerRegistrationRequest.customerLastName = " ";
				  }
                  if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display){
                      vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.leiExpiryDate);
                  }
				  vm.tradingBranch = angular.copy(vm.customerRegistrationRequest.tradingBranch);
				  vm.inputPanImage = "PAN Image uploaded";
				  vm.validPan=true;
				  vm.verifiedUserMobileCountryCode = angular.copy(vm.customerRegistrationRequest.userMobileCountryCode);
                  vm.verifiedUserMobile = angular.copy(vm.customerRegistrationRequest.userMobile);
                  vm.verifiedUserEmail = angular.copy(vm.customerRegistrationRequest.userEmail);
                
			  }else{
				  //AlertService.error("Invalid request reference number.");
			  }
	      }
	      
	      function onFetchFailure(){
	      }
	      
	      function base64ToArrayBuffer(base64) {
    	    var binary_string =  window.atob(base64);
    	    var len = binary_string.length;
    	    var bytes = new Uint8Array( len );
    	    for (var i = 0; i < len; i++)        {
    	        bytes[i] = binary_string.charCodeAt(i);
    	    }
    	    return bytes.buffer;
	      }
	      
	      vm.viewPanImage = function viewPanImage(){
	        	if(vm.customerRegistrationRequest.fileExtension=='pdf'){
	    			//var pdfWindow = window.open("");
	        		//pdfWindow.document.write("<iframe width='100%' height='100%'title='PAN'  src='data:application/pdf;base64, " + encodeURI(vm.customerRegistrationRequest.panImage)+"' ></iframe>")
	        		var contentByteArray = base64ToArrayBuffer(vm.customerRegistrationRequest.panImage);
		    		  
		    		var file = new Blob([contentByteArray], { type: 'application/pdf' });
		            var fileURL = URL.createObjectURL(file);
		              
		            if(window.navigator.msSaveOrOpenBlob) {
		                  window.navigator.msSaveOrOpenBlob(file, "PAN.pdf");
		            }
		            else {
		                   window.open(fileURL, "PAN.pdf");    
		            }
	        	}
	        	else{
	        		$state.go('view-panImage-resubmit-request', {image:vm.customerRegistrationRequest.panImage,theme:'light'});
	        	}
	        }
       
	      
	      function openHelpWindow(){
	    	  
	    	  CustomerRegistration.getHelpContent({type : 'Customer_OnBoarding'}, onGetHelpContentsSuccess, onGetHelpContentsFailure);
	    	  
	    	  
	      }
	      
	      function onGetHelpContentsSuccess(result){
	    	  if(result.contents != null && result.contents != undefined){
	    		  var contentByteArray = base64ToArrayBuffer(result.contents);
	    		  
	    		  var file = new Blob([contentByteArray], { type: 'application/pdf' });
	              var fileURL = URL.createObjectURL(file);
	              
	              if(window.navigator.msSaveOrOpenBlob) {
	                    window.navigator.msSaveOrOpenBlob(file, "CustomerOnBoarding.pdf");
	                }
	                else {
	                     window.open(fileURL, "CustomerOnBoarding.pdf");    
	                }
	              
	              
	    		  

	    		  
	    	  }
	      }
	      
	      function base64ToArrayBuffer(base64) {
	    	    var binary_string =  window.atob(base64);
	    	    var len = binary_string.length;
	    	    var bytes = new Uint8Array( len );
	    	    for (var i = 0; i < len; i++)        {
	    	        bytes[i] = binary_string.charCodeAt(i);
	    	    }
	    	    return bytes.buffer;
	    	}
	      
	      function onGetHelpContentsFailure(){
	    	  
	      }
	      $scope.$watch('vm.customerRegistrationRequest.bank', function(value){
	    	  if(value!=null && value!=undefined){
                  TACViewForEntitiesService.getActiveTACForOrganisationAndTacType({orgId: value.id, tacTypeId: vm.bankForCustomerTACTypeId}, function(result){
                    if(result!=null && result.tacFiles!=null){
                        vm.bankTAC = result;    
                    }else{
                        vm.bankTAC = null;
                    }
                  })
	    	  }else{
                  vm.bankTAC = null;
	    	  }
	      })
	      
	    

          function previewClearcorpTAC(){
            if(vm.clearcorpTAC!=null && vm.clearcorpTAC!=undefined){
                TacViewForEntitiesUtility.previewTAC(vm.clearcorpTAC.tacFiles.fileContent, vm.clearcorpTAC.tacFiles.fileName);    
            }
          }

          function previewBankTAC(){
            if(vm.bankTAC!=null && vm.bankTAC!=undefined){
                TacViewForEntitiesUtility.previewTAC(vm.bankTAC.tacFiles.fileContent, vm.bankTAC.tacFiles.fileName);    
            }
          }

          $scope.$watch('vm.customerRegistrationRequest.companyName', function(newVal, oldVal) {
            if(newVal!=null && newVal!=undefined)
            {
                newVal = newVal.replace(/(\r\n|\n|\r)/gm," ");
                vm.customerRegistrationRequest.companyName = newVal.replace(/(,)/gm,"");
                
            }
          });
          
          vm.openUserInfo = function openUserInfo(){
        	  $timeout(function(){
    			  //set focus to login control
    			  if(vm.customerRegistrationRequest.customerType.enableUserInfo==false 
    					  && document.getElementById("txtLogin") !=null && document.getElementById("txtLogin")!= undefined){
    	        		document.getElementById("txtLogin").focus();
    			  }
        	  }, 100)
          }
		  function setEnvironmentName() {
			if( vm.configSettings.environmentName !='' &&   vm.configSettings.environmentName!=undefined &&  vm.configSettings.environmentName!=null){
				vm.environmentName = vm.configSettings.environmentName.toUpperCase();
			}
		}
    }
})();
