(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HeConnectivitySettingsDialogController', HeConnectivitySettingsDialogController);

    HeConnectivitySettingsDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','Workflow','$uibModalInstance','HeConnectivity','entity','StaticLookUp'];

     function HeConnectivitySettingsDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService,Workflow, $uibModalInstance,HeConnectivity,entity,StaticLookUp) {
        var vm = this;
        vm.connectivitySettings=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitConnectivity = resubmitConnectivity;
        vm.globalValues = GlobalValues;
        vm.discardConnectivity = discardConnectivity;
        vm.rejectConnectivity= rejectConnectivity;
        vm.approveConnectivity= approveConnectivity;
       vm.viewMode=false;
      
                
        if(workflowSharedService.viewFrom == 'heConnectivitySettings'){
        	vm.connectivitySettings=entity;
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'heConnectivitySettings_todolist_resubmit') {
        	vm.connectivitySettings =  angular.fromJson($rootScope.payload);
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'heConnectivitySettings_todolist_checker') {
        	vm.connectivitySettings =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'heConnectivitySetting_todolist_maker') {
        	vm.connectivitySettings =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='heConnectivitySettings_auditlog'){
        	vm.connectivitySettings =  angular.fromJson($rootScope.payload);   
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
			
        	HeConnectivity.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.connectivitySettings, onSaveSuccess, onSaveError);
			
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitConnectivity() {
        	
        	HeConnectivity.resubmit({"taskId" : $rootScope.taskID}, vm.connectivitySettings, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {}

        function approveConnectivity() { 
        	HeConnectivity.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectConnectivity() { Workflow.rejectTask($rootScope.taskID); }

        function discardConnectivity() {
        	HeConnectivity.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

     }   
})();
