(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('MarkupReport', MarkupReport);

    MarkupReport.$inject = ['$resource','DateUtils'];

    function MarkupReport ($resource, DateUtils) {
        var resourceUrl = 'api/trades';
        return $resource(resourceUrl, {}, {
            
            'getMarkupReportHeaderList': {
                method: 'GET',  
                url: 'api/trades/getMarkupReportHeaderList',
                params : {
                	bankId : '@bankId',
					branchId : '@branchId',
					customerId:'@custId'
                },
                isArray: true
            },
            'getHistoricalMarkupReportHeaderList': {
                method: 'GET',  
                url: 'api/trades/getHistoricalMarkupReportHeaderList',
                params : {
                	bankId : '@bankId',
					branchId : '@branchId',
					customerId:'@custId',
					fromDate:'@fromDate',
					toDate :'@toDate'
                },
                isArray: true
            },
            
            'getMarkupReportDetailsList': {
                method: 'GET',  
                url: 'api/trades/getMarkupReportDetailsList',
                params : {
                	bankId : '@bankId',
					branchId : '@branchId',
					customerId:'@custId',
					buyOrSell:'@buyOrSell'
				},
				isArray: true
            },
            'getHistoricalMarkupReportDetailsList': {
                method: 'GET',  
                url: 'api/trades/getHistoricalMarkupReportDetailsList',
                params : {
                	bankId : '@bankId',
					branchId : '@branchId',
					customerId:'@custId',
					buyOrSell:'@buyOrSell',
					businessDate :'@businessDate'
				},
				isArray: true
            }
            ,'getCurrentBusinessDate': {
                method: 'GET',  
                url: 'api/eod-process',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            },
            'exportData':{
			    method: 'POST',
                url: 'api/markup-report/export',
                responseType: 'arraybuffer',
                params : {
					bankId : '@bankId',
					branchId : '@branchId',
					custId :'@custId',
					fromDate :'@fromDate',
					toDate:'@toDate'
				},
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
			},
			 'exportHistoricalData':{
				    method: 'POST',
	                url: 'api/historical-markup-report/export',
	                responseType: 'arraybuffer',
	                params : {
						bankId : '@bankId',
						branchId : '@branchId',
						custId :'@custId',
						fromDate :'@fromDate',
						toDate:'@toDate'
					},
	                transformResponse: function(data, headers) {
	              	  return {
	                        data: data,
	                        headers: headers
	                    };   
	                } 
				},
				
				 'exportHistoricalCSVData': {
	                  method: 'POST',
	                  url: 'api/historical-markup-report/export-csv',
	                  responseType: 'arraybuffer',
	                  params : {
							bankId : '@bankId',
							branchId : '@branchId',
							custId :'@custId',
							fromDate :'@fromDate',
							toDate:'@toDate'
						},
	                  transformResponse: function(data, headers) {
	                	  return {
	                          data: data,
	                          headers: headers
	                      };   
	                  }         
	             },
	             
	             'exportCSVData': {
	                  method: 'POST',
	                  url: 'api/markup-report/export-csv',
	                  responseType: 'arraybuffer',
	                  params : {
							bankId : '@bankId',
							branchId : '@branchId',
							custId :'@custId',
							fromDate :'@fromDate',
							toDate:'@toDate'
						},
	                  transformResponse: function(data, headers) {
	                	  return {
	                          data: data,
	                          headers: headers
	                      };   
	                  }         
	             }
        });
    }
})();