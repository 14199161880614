(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('csplCheckbox', [function() {

            function linkFunction(scope, iElement, iAttrs, ngModelController) {
                var input = iElement.find('input[ng-model]');
                scope.color = "#F2F2F2";
                if (!ngModelController) {
                    return; // do nothing if no ng-model
                }
                
                if(scope.themeType=='light'){
                	scope.color = "#0D0D0D";
                }

                scope.onChange = function() {
                    ngModelController.$setViewValue(scope.value);
                };

                ngModelController.$render = function() {
                    scope.value = ngModelController.$modelValue;
                };
            }

            return {
                templateUrl: 'app/components/cspl-controls/cspl-checkbox/cspl-checkbox.html',
                restrict: 'E',
                require: 'ngModel',
                scope: {
                    ngDisabled: '=',
                    id: '=',
                    themeType: '@',
                    labelValue: '@'
                },
                link: linkFunction
            };
        }]);
})();
