(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FaqController', FaqController);

    FaqController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','FAQService'];

     function FaqController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,FAQService) {
        var vm = this;
        
        vm.question='';
		vm.answer='';
        vm.videofilename='';
        vm.templatefilename='';
        vm.selectedfaqcat = [];
        
        vm.faqdetailsfromJSONFile = $rootScope.faqdetailsfromJSONFile;
        vm.faqcategorylistfromJSONFile = $rootScope.faqcategorylistfromJSONFile;
        
        vm.clear=clear;

        $scope.clicked=false;
        $scope.showButton = false;
        loadfaqconfig();
        
        if(workflowSharedService.viewFrom == 'faq'){
       	 	$scope.showButton=false;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
     
        function onSaveSuccess (result) {
        	 $rootScope.$emit('gatewayApp:reloadFAQPage');
        	 $uibModalInstance.dismiss('close');   
        }

        function onSaveError () {
        	
        }

        function loadfaqconfig() {
			// alert("load");
			FAQService.downloadJSONFile({
				"type" : "faq",
				"fileName" : "faqconfig.txt",
				"fileType" : "txt"
			}, function(result) {
				vm.faqconfig = result;				
				vm.faqlastusedindex =vm.faqconfig[0].faqlastusedindex;
			
			});
		}
        function saveJSON() {
        	        	
        	//alert(vm.videofilename);
        	if (vm.videofilename != null && vm.videofilename != "") { vm.uploadvideo(); }
        	if (vm.templatefilename != null && vm.templatefilename != "") { vm.uploadtemplate(); }
        	
        	var JSONText = angular.toJson(vm.faqdetailsfromJSONFile);
        	FAQService.uploadJSONFile({"type": "faq", "fileName": "faqdetailsjson.txt", "fileType": "txt"},JSONText,onSaveSuccess, onSaveError);
        }
        
        function savefaqconfigJSON() {      	
        	
        	var JSONText = angular.toJson(vm.faqconfig);
        	FAQService.uploadJSONFile({"type": "faq", "fileName": "faqconfig.txt", "fileType": "txt"},JSONText,function(result) {
                              
        	});
        }
        
        vm.uploadvideo= function() {
        	//alert("uploadvideo");
        	var fd = new FormData();
            fd.append("file", vm.videofileforupload);
            FAQService.uploadCommonFiles({"type" : "faq", "subfolder" : "videos"}, fd);
        }
        
        vm.uploadtemplate= function() {
        	//alert("uploadtemp");
        	var fd = new FormData();
            fd.append("file", vm.templatefileforupload);
            FAQService.uploadCommonFiles({"type" : "faq", "subfolder" : "templates"}, fd);
            //alert("uploadtemp end ");
        }
        
        
        vm.save=function(){

        	faqsave();
        }
      
        function faqsave() {
        	
        	vm.faqlastusedindex = Number(vm.faqlastusedindex) +1;
        	vm.faqconfig[0].faqlastusedindex = vm.faqlastusedindex 
        	//alert(vm.faqlastusedindex);
        	
        	vm.faqdetailsfromJSONFile.push({ 'question':vm.question, 'answer':vm.answer, 'videofilename':vm.videofilename, 'templatefilename':vm.templatefilename, 'index':vm.faqlastusedindex, 'categorylist':vm.selectedfaqcat });
            
        	savefaqconfigJSON();
            saveJSON();
            
            }
        
        function ResetFileNames(){
        	
    		vm.videofilename = document.getElementById("field_VideoFileName").files[0].name;
    		vm.templatefilename = document.getElementById("field_TemplateFileName").files[0].name;

        }
        
        $scope.uploadVideoFile = function () {                
            if (document.getElementById("field_VideoFileName").value != "") {
            	vm.videofilename =document.getElementById("field_VideoFileName").files[0].name;
            }
            else
            	{
            	vm.videofilename ="";
            	}

        }

        $scope.uploadTemplateFile = function () {                
           if (document.getElementById("field_TemplateFileName").value != "") {
        	   vm.templatefilename =document.getElementById("field_TemplateFileName").files[0].name;
           }
           else
           	{
           	vm.templatefilename ="";
           	}

       }        
        
     }   
})();
