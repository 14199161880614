(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'marketMovement'
        })
        
        .controller('MarketMovementController', MarketMovementController);

    MarketMovementController.$inject = ['$scope', '$state', 'MarketMovement', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'$timeout','IntradayMarket','DateUtils','$rootScope'];

    function MarketMovementController($scope, $state, MarketMovement , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		$timeout,IntradayMarket,DateUtils,$rootScope) {
    	
    	var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.MARKETMOVEMENT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.instrument = 'SPOT';
        vm.tbq=0;
        vm.toq=0;
        vm.ttq=0;
        vm.ltp=0;
        vm.todayDate = new Date();
        $scope.refresh=refresh;
        vm.exportName = 'RetailSPOT';
        vm.openPrice = 0.00;
          	 
        workflowSharedService.viewFrom = "marketMovement";
        
    	loadCurrentBusinessDate();
    	 logScreenAccess();
    	 
    	 vm.getCurrentDateTime = getCurrentDateTime;
    	 function getCurrentDateTime(){
    		 vm.todayDate = new Date();
    	 }
    	 //loadAll();
    	 
    	/* var tickerPriceChange = $rootScope.$on("tickerPrice", function(event, object){
    		 vm.ltp=object;
    	 });
    	 
    	 $scope.$on('$destroy', function() {
    		 tickerPriceChange();
    	 
    	 });*/
    	 
        

         function loadAll() {
        	 vm.ltp=0;
        	 vm.high=null;
        	 vm.low=null;
        	 vm.changeInPrice=null;
        	 vm.changeInPercent=null;
        	// vm.currentDate=DateUtils.convertLocalDateToServer(vm.todayDate);
      	   	 MarketMovement.query({"instrument": vm.instrument, "businessDate" : vm.convertedCurrentBusinessDate},function(result) {
      		   
      		   
                  vm.marketMovement = result;
                  vm.tbq = vm.marketMovement.reduce(function (a,b) { return a + b.intervalBuyAmount; }, 0);
                  vm.toq = vm.marketMovement.reduce(function (a,b) { return a + b.intervalSellAmount; }, 0);
                  vm.ttq = vm.marketMovement.reduce(function (a,b) { return a + b.intervalTradeAmount; }, 0);
                 
                  angular.forEach(vm.marketMovement, function(value){
                  	value.intervalStartTime=moment(value.intervalStartTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
                  	value.intervalEndTime=moment(value.intervalEndTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
                  });
                  // Fix for issue -417 mantis , Market Open Price not getting updated
                  if( vm.marketMovement!=null &&  vm.marketMovement!=undefined &&  vm.marketMovement.length>0){
                	  	var openRateFilteredMovementArray = vm.marketMovement.filter(function(val){return  val.intervalOpenExchangeRate !== 0.00});
                  		if(openRateFilteredMovementArray!=null && openRateFilteredMovementArray!=undefined && openRateFilteredMovementArray.length>0)
                  			vm.openPrice = openRateFilteredMovementArray[0].intervalOpenExchangeRate;
      	   	 		}
                  // End Fix
                  vm.reverseMarketMovement = angular.copy(vm.marketMovement).reverse();
                  
                  angular.forEach(vm.reverseMarketMovement, function(value){
                    	if(value.intervalCloseExchangeRate!=0 && vm.ltp==0){
                    		vm.ltp = value.intervalCloseExchangeRate;
                    	}
                  });
                  
                  MarketMovement.getDailyClosingPrice({"instrument": vm.instrument},function(result){
            	   		vm.prevClose = result.lastTradeExchangeRate;
      	      	   	
            	   		if(vm.marketMovement.length>0){
            	   			//vm.ltp = vm.marketMovement[vm.marketMovement.length-1].intervalCloseExchangeRate;
             	      	    if(vm.ltp>0){
             	      	    	vm.changeInPrice = vm.ltp - vm.prevClose;
                  	            vm.changeInPrice = Math.round(parseFloat(vm.changeInPrice)*100000) / 100000;
                  	            vm.changeInPercent = (vm.changeInPrice/vm.ltp) * 100;
                  	            vm.changeInPercent = Math.round(parseFloat(vm.changeInPercent)*100000) / 100000;
             	      	    }
              	            
                            vm.high= Math.max.apply(Math,vm.marketMovement.map(function(item){return item.intervalHighExchangeRate;}).filter(function(val){return val !== 0}));
                            vm.low= Math.min.apply(Math,vm.marketMovement.map(function(item){return item.intervalLowExchangeRate;}).filter(function(val){return val !== 0}));
            	   		}
                });   
                  
               });    
      	   
      	      
         }
         
         $scope.$watch('vm.instrument', function(value){
        	 if(vm.instrument=='SPOT'){
        		 vm.exportName = 'RetailSPOT';
        	 }else{
        		 vm.exportName='InterbankSPOT';
        	 }
            	if(value!=null && value!=undefined){
            		
            		if(vm.convertedCurrentBusinessDate!=null && vm.convertedCurrentBusinessDate!=undefined){
            			loadAll();
            		}
            		
            	}
            });
         
         function logScreenAccess(){
         	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
         }
         
        
    	 
    	 function loadCurrentBusinessDate(){
      	   IntradayMarket.getCurrentBusinessDate(getBusinessDateSuccess, getBusinessDateFailed);
         }
         
         function getBusinessDateSuccess(result){
      	   vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(result.businessDate);
      	   
      	   vm.convertedCurrentBusinessDate=DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
      	   
      	   loadAll();
      	   
         }
         
         function getBusinessDateFailed(){
      	   
         }
         
         $scope.toggle = true;
         function refresh() {
             $scope.toggle = false;
             $scope.refreshFlag = false;
             getCurrentDateTime();
             loadAll();
             if ($scope.toggle == false) {
                 $timeout(function() {
                     $scope.toggle = true;
                 }, 2000);
                 $scope.refreshFlag = false;
             }
         };
         
    }    
})();
  