(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'SwapQuotesView'
        })
        
        .controller('SwapQuotesViewController', SwapQuotesViewController);

    SwapQuotesViewController.$inject = ['$scope', '$rootScope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'DateUtils','AlertService','$filter',  'SwapQuotes','IntradayMarket','systemSettings'];

    function SwapQuotesViewController($scope, $rootScope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		DateUtils,AlertService,$filter,  SwapQuotes,IntradayMarket,systemSettings) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SWAP_QUOTES_VIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.getSwapQuotesView = getSwapQuotesView; 
        vm.exportToFile = exportToFile;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.configSettings = vm.globalValues.getConfigSettings();
        vm.ExpandOrCollapseSwapQuotesView = true;
	    vm.todayDate = new Date();
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    vm.exportDataToPdfFile = exportDataToPdfFile;
	    vm.filterData = {};
	    vm.fileName = "Swap_Quotes_View_";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm_ss';
	    vm.valid=false;
		vm.clicked=false;
	    logScreenAccess();
	   
	    /*var systemSettings = vm.globalValues.getSystemSettings();
        if(systemSettings!=null && systemSettings!=undefined){
        	vm.todayDate  = new Date(systemSettings.businessDate);
            vm.fromDate = new Date(systemSettings.businessDate);
            vm.toDate = new Date(systemSettings.businessDate);
        }*/
	   if(systemSettings != null && systemSettings != undefined){
	    	vm.todayDate = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
	    	vm.fromDate = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
	    	vm.toDate = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
	    	vm.minDate = DateUtils.getMinimumLocalDate(vm.fromDate);
	    	
	    }
        
        function logScreenAccess(){
           	ScreenLog.screenLog(vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        
        function getSwapQuotesView(){
        	vm.swapQuotesList = []; //to re-initialize the array
        	var bankId = 0;
        	if(vm.bank!=null && vm.bank!=undefined){
        		bankId = vm.bank.id;
        	}
        	
			var fromServerDate = DateUtils.convertLocalDateToServer(vm.fromDate);
			var toServerDate = DateUtils.convertLocalDateToServer(vm.toDate);
			copyFilterData();
			if(moment(vm.fromDate).isAfter(vm.todayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.swapQuotesView.futureFromDate'));
    			vm.valid=false;
    		}
    		else if(moment(vm.fromDate).isAfter(vm.toDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.swapQuotesView.greaterFromDate'));
    			vm.valid=false;
    		}else if(moment(vm.toDate).isAfter(vm.todayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.swapQuotesView.futureToDate'));
    			vm.valid=false;
    		}
    		else{
    			vm.valid=true;
				vm.clicked=true;
    			SwapQuotes.getSwapQuotesView({bankId: bankId, from: fromServerDate, to: toServerDate}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure)
    		}
        }
        
        function onGetSwapQuotesSuccess(result){
        	vm.swapQuotesList = result;
			vm.clicked=false;
        }
        
        function onGetSwapQuotesFailure(){
        	vm.clicked=false;
        }
        
        function copyFilterData(){
        	if(vm.bank!=null && vm.bank!=""){
        		vm.filterData.bankName = vm.bank.bankName;
        	}else{
        		vm.filterData.bankName = "--All--";
        	}
        	
        	var from=$filter('date')(vm.fromDate, "dd-MM-yyyy");
        	var to=$filter('date')(vm.toDate, "dd-MM-yyyy");

        	vm.filterData.from = from;
    		vm.filterData.to = to;
        }
        
        function exportToFile(fileType){
        	if(fileType=='csv'){
        		exportDataToCsvFile();
        	}else if(fileType=='excel'){
        		exportDataToExcelFile();
        	}else if(fileType=='pdf'){
        		exportDataToPdfFile();
        	}
        	$rootScope.fileType = fileType;
        }

        function exportDataToExcelFile(){
      	  if(vm.swapQuotesList !=null &&  vm.swapQuotesList!=undefined && vm.swapQuotesList.length>0){
      		  vm.mimeType = 'application/vnd.ms-excel';
      		  vm.extension = ".xls";  
      		  SwapQuotes.exportData({bankName:  vm.filterData.bankName, from:  vm.filterData.from, to:  vm.filterData.to}, vm.swapQuotesList, onExportSuccess);
      	  }
        }
        
        function exportDataToCsvFile(){
        	if(vm.swapQuotesList !=null &&  vm.swapQuotesList!=undefined && vm.swapQuotesList.length>0){
        		vm.mimeType = 'text/csv';
        		vm.extension = ".csv";  
        		SwapQuotes.exportDataCSV({bankName:  vm.filterData.bankName, from:  vm.filterData.from, to:  vm.filterData.to}, vm.swapQuotesList, onExportSuccess);
        	}
        }
	    
        function exportDataToPdfFile(){
        	if(vm.swapQuotesList !=null &&  vm.swapQuotesList!=undefined && vm.swapQuotesList.length>0){
        		vm.mimeType = 'application/pdf';
        		vm.extension = ".pdf";  
	      		SwapQuotes.exportDataPdf({bankName:  vm.filterData.bankName, from:  vm.filterData.from, to:  vm.filterData.to}, vm.swapQuotesList, onExportSuccess);
        	}
        }
	    
	    function onExportSuccess(result){        	  
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString = vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension; 
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob){
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString);
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}
    }
})();