(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('CustomerInfoUtil', CustomerInfoUtil);

    CustomerInfoUtil.$inject = [];

    function CustomerInfoUtil() {

        var service = {
        		checkForNameChangeWrokFlow: checkForNameChangeWrokFlow,
        		isCustomerProfileFormValuesChanged: isCustomerProfileFormValuesChanged,
        		checkvalidityOfValues: checkvalidityOfValues
           
        };

        return service;


        function checkForNameChangeWrokFlow(customerPreviousData , customerInfo ){
        	
        	if(customerPreviousData.customerFirstName != customerInfo.customer.customerFirstName || 
        			customerPreviousData.customerMiddleName != customerInfo.customer.customerMiddleName  ||
        			customerPreviousData.customerLastName != customerInfo.customer.customerLastName  ){
        		
        		customerInfo.isSuspendableAction = true;
        		customerInfo.isApprovalRequired = true;
        		
        	/* }else if(customerPreviousData.address1 != customerInfo.customer.address1
        			|| customerPreviousData.address2 != customerInfo.customer.address2
        			|| customerPreviousData.city != customerInfo.customer.city
        			|| customerPreviousData.state.id != customerInfo.customer.state.id
        			|| customerPreviousData.pincode != customerInfo.customer.pincode
        			|| customerPreviousData.stateCode != customerInfo.customer.stateCode
        			|| customerPreviousData.gstin != customerInfo.customer.gstin) {
        		
        		customerInfo.isSuspendableAction = false;
        		customerInfo.isApprovalRequired = true; */
        	}else{
        		
        		customerInfo.isSuspendableAction = false;
        		customerInfo.isApprovalRequired = false;
        	}
        	
        	
        }  
        function isCustomerProfileFormValuesChanged(customerPreviousData,customerInfo,image ) {
        	customerInfo.fieldCheckArray = [];
        	var customerTypeSolePropId = 27;
    		var changesMade = false;
    		if(customerInfo.customer.customerType.customerUserType.shortName=='MultiUserCustomer' || customerInfo.customer.customerType.id == customerTypeSolePropId){
				var isField1 = checkvalidityOfValues(customerPreviousData.customerFirstName , customerInfo.customer.customerFirstName , "entityName" ,customerInfo ); 
			}else{
				var isField1 = checkvalidityOfValues(customerPreviousData.customerFirstName , customerInfo.customer.customerFirstName , "customerFirstName" ,customerInfo ); 
			}
    		
    		var isField2 =  checkvalidityOfValues( customerPreviousData.customerMiddleName , customerInfo.customer.customerMiddleName, "customerMiddleName", customerInfo);
    		var isField3 = checkvalidityOfValues(customerPreviousData.customerLastName , customerInfo.customer.customerLastName, "customerLastName", customerInfo);
    		//var isField4 =  (vm.image!=undefined &&  vm.image!=null && vm.image!='');
    		var isField5 =  checkvalidityOfValues(customerPreviousData.address1 , customerInfo.customer.address1, "address1", customerInfo);			
    		var isField6 =  checkvalidityOfValues(customerPreviousData.address2 , customerInfo.customer.address2 , "address2" , customerInfo);
    		var isField7 =  checkvalidityOfValues(customerPreviousData.city , customerInfo.customer.city, "city", customerInfo);
    		var isField8 =  checkvalidityOfValues(customerPreviousData.state.id , customerInfo.customer.state.id, "state",customerInfo);
    		var isField9 =  checkvalidityOfValues(customerPreviousData.pincode , customerInfo.customer.pincode, "pincode", customerInfo);			
    		var isField10 =  checkvalidityOfValues(customerPreviousData.stateCode , customerInfo.customer.stateCode, "stateCode", customerInfo);				
    		var isField11 = checkvalidityOfValues(customerPreviousData.gstin , customerInfo.customer.gstin, "gstin" , customerInfo);			
    		var isField12 =  checkvalidityOfValues(customerPreviousData.primaryContactName , customerInfo.customer.primaryContactName, "primaryContactName", customerInfo);
    		var isField13 =  checkvalidityOfValues(customerPreviousData.primaryEmail , customerInfo.customer.primaryEmail, "primaryEmail", customerInfo);
    		var isField14 =  checkvalidityOfValues(customerPreviousData.primaryMobileCountryCode , customerInfo.customer.primaryMobileCountryCode, "primaryMobileCountryCode", customerInfo);
    		var isField15 =  checkvalidityOfValues(customerPreviousData.primaryMobile , customerInfo.customer.primaryMobile, "primaryMobile", customerInfo);			
    		var isField16 =  checkvalidityOfValues(customerPreviousData.alternateContactName1 , customerInfo.customer.alternateContactName1, "alternateContactName1", customerInfo);
    		var isField17 =  checkvalidityOfValues(customerPreviousData.alternateEmail1 , customerInfo.customer.alternateEmail1, "alternateEmail1", customerInfo);
    		var isField18 =  checkvalidityOfValues(customerPreviousData.alternateMobileCountryCode1 , customerInfo.customer.alternateMobileCountryCode1, "alternateMobileCountryCode1" , customerInfo);				
    		var isField19 =  checkvalidityOfValues(customerPreviousData.alternateMobile1 , customerInfo.customer.alternateMobile1, "alternateMobile1", customerInfo);			
    		var isField20 =  checkvalidityOfValues(customerPreviousData.alternateContactName2 , customerInfo.customer.alternateContactName2, "alternateContactName2" , customerInfo);				
    		var isField21 =  checkvalidityOfValues(customerPreviousData.alternateMobileCountryCode2 , customerInfo.customer.alternateMobileCountryCode2, "alternateMobileCountryCode2", customerInfo) ;				
    		var isField22 =  checkvalidityOfValues(customerPreviousData.alternateMobile2 , customerInfo.customer.alternateMobile2, "alternateMobile2", customerInfo) ;		
    		var isField23 =  checkvalidityOfValues(customerPreviousData.alternateEmail2 , customerInfo.customer.alternateEmail2, "alternateEmail2"  ,customerInfo);
    		
			if( isField1 || isField2 ||	isField3 ||	(image!=undefined &&  image!=null && image!='') ||	isField5 ||	isField6 ||	isField7 ||
				isField8 ||	isField9 ||	isField10 || isField11 ||	isField12||	isField13 ||	isField14|| isField15 || isField16|| isField17|| isField18 ||isField19 ||				
				isField20||	isField21||	isField22 ||isField23){
				
				changesMade = true;
			 }
			if(image!=undefined &&  image!=null && image!=''){
				customerInfo.fieldCheckArray.push("customerPanImage"); 
        	}
			return changesMade;
        }
        function checkvalidityOfValues(previousValue, currentValue, fieldName , customerInfo){
        	
        	var isValueChanged = (!(previousValue == null && (currentValue == "" || currentValue== null)) && previousValue!=currentValue);
        	if(isValueChanged){
        		customerInfo.fieldCheckArray.push(fieldName);
        	}
        		
        	return isValueChanged;
        }

    }

})();