(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'creditFactorSettingsReport'
        })
        
        .controller('CreditFactorSettingsReportController', CreditFactorSettingsReportController);

    CreditFactorSettingsReportController.$inject = ['$scope', '$state', 'CreditFactorSettings', 'GlobalValues', 'workflowSharedService', 
    'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function CreditFactorSettingsReportController($scope, $state, CreditFactorSettings , GlobalValues, workflowSharedService, 
      EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CREDIT_FACTOR_SETTINGS_VIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "creditFactorSettingsReport";
     
        $scope.refresh=refresh;
       
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForPercent = 4;        
     
        logScreenAccess();
        loadAll();

        function loadAll() {
    	    CreditFactorSettings.getActiveCreditSettings( getCreditFactorSettingsSuccess, getCreditFactorSettingsFailure);
        }      
       
        function getCreditFactorSettingsSuccess(result){
    	  
    	   vm.creditFactorSettingsList = result; 	 
    	    		  
       }
       
       function getCreditFactorSettingsFailure(){}
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };

       
       
    }
})();
