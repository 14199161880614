(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerChargesEditController', CustomerChargesEditController);

    CustomerChargesEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','CustomerCharges','entity','DateUtils','Workflow'];

     function CustomerChargesEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,CustomerCharges,entity,DateUtils,Workflow) {
        var vm = this;
        vm.customerCharge=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitCustomerCharges = resubmitCustomerCharges;
        vm.globalValues = GlobalValues;
        vm.discardCustomerCharges = discardCustomerCharges;
        vm.rejectCustomerCharges= rejectCustomerCharges;
        vm.approveCustomerCharges= approveCustomerCharges;
        vm.viewMode=false;
        //vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.decimalsForPercent = 4;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        var systemSettings = vm.globalValues.getSystemSettings();
      
           
        if(workflowSharedService.viewFrom == 'customerCharges'){
        	vm.customerCharge=entity;
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'customerChargestodolist_resubmit') {
        	vm.customerCharge =  angular.fromJson($rootScope.payload);
        	
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'customerCharges_todolist_checker') {
        	vm.customerCharge =  angular.fromJson($rootScope.payload); 
        
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'customerCharges_todolist_maker') {
        	vm.customerCharge =  angular.fromJson($rootScope.payload); 
        	
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='customerCharges_auditlog'){
        	vm.customerCharge =  angular.fromJson($rootScope.payload); 
        
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        else if(workflowSharedService.viewFrom=='customer_charges_import_auditlog'){
        	
        	vm.customerCharge =  angular.fromJson($rootScope.payload);   
        	vm.customerCharge = vm.customerCharge.customerCharges;
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	vm.customerCharge.effectiveFrom = DateUtils.convertLocalDateToServer(systemSettings.businessDate);
        	
        	
        	if (vm.customerCharge.id != null) {
				CustomerCharges.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.customerCharge, onSaveSuccess, onSaveError);
			} else {
				CustomerCharges.save({
					"orgId" : GlobalValues.getOrgId()
				}, vm.customerCharge, onSaveSuccess, onSaveError);
			}
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitCustomerCharges() {
        	
        	vm.customerCharge.effectiveFrom = DateUtils.convertLocalDateToServer(systemSettings.businessDate);
        	
        	
        	CustomerCharges.resubmit({"taskId" : $rootScope.taskID}, vm.customerCharge, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function approveCustomerCharges() { 
        	CustomerCharges.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectCustomerCharges() { Workflow.rejectTask($rootScope.taskID); }

        function discardCustomerCharges() {
        	CustomerCharges.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
        
        

       
     }   
})();
