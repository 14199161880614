(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ContactUsController', ContactUsController);

    ContactUsController.$inject = ['$scope', '$state', '$rootScope','$uibModalInstance' ];

     function ContactUsController($scope, $state, $rootScope, $uibModalInstance ) {
        var vm = this;
        
        vm.ExpandOrCollapseAuditLog = false;
        vm.valid=true;
        vm.clear=clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
     }   
})();
