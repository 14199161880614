(function () {
	'use strict';
	// This file not in use as of JULY 2021, new file cc-loggedin-users-report-controller.js is used instead 
	angular
		.module('gatewayApp')
		.controller('LoggedInUsersReportController', LoggedInUsersReportController);

	LoggedInUsersReportController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',  'AlertService','LoggedInUsersReport','$filter', 'ScreenLog','entityConstants','DateUtils', '$timeout'];

	function LoggedInUsersReportController( $scope, $state, $rootScope, GlobalValues,AlertService , LoggedInUsersReport , $filter , ScreenLog, entityConstants,DateUtils,$timeout){
		var vm = this;
		vm.globalValues = GlobalValues;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.NO_OF_LOGGEDIN_USER_REPORT;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
    	$scope.refresh = refresh;
        
        vm.headerList =[];
        vm.selectedEntityUserList=[];
        
        var recordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        //recordsPerPage=3;
        $scope.noOfRecordsPerPage = recordsPerPage;
        $scope.page = 1;
        $scope.noOfUserRecordsPerPage = recordsPerPage;
        $scope.userPage = 1;
        $scope.noOfBranchRecordsPerPage = recordsPerPage;
        $scope.branchPage = 1;
        $scope.noOfBranchCustomerRecordsPerPage = recordsPerPage;
        $scope.branchCustomerPage = 1;       
        $scope.noOfCustomerRecordsPerPage = recordsPerPage;
        $scope.customerPage = 1;
        
        vm.getBankUsersDetails = getBankUsersDetails;
        vm.getBranchWiseUsersCount = getBranchWiseUsersCount;
        vm.getBranchWiseCustomerCount = getBranchWiseCustomerCount;
        vm.getBranchUsersDetails = getBranchUsersDetails;
        vm.getBranchCustomerDetails = getBranchCustomerDetails;
        vm.getCustomerUserDetails = getCustomerUserDetails;
        
        vm.selectedBankName = '';
        vm.selectedBankId = '';	
        vm.selectedEntityName = '';
        vm.selectedEntityType = '';
        vm.selectedBranchName = '';
        vm.selectedBranchId = '';
        
		vm.showBranchDrillDown = false;
		vm.showCustomerCountForBranches=false; 
		vm.showSelectedBranchCustomerDetails = false;
		
		vm.showSelectedBankUsersDetails = false;
		vm.showSelectedBranchUsersDetails = false;
		vm.showSelectedCustomerUsersDetails = false;
		
		//initialise list		
		vm.branchWiseUserCountList= [];
		vm.branchWiseCustomerCountList =[];
		vm.customerDetailsList = [];
		vm.selectedEntityUserList = [];		
		
		vm.exportToFile = exportToFile;
		        	    
 	   /*LoggedInUsersReport.getCurrentBusinessDate(getBusinnessDateSuccess, getBusinessDateFailed);
 	   
 	   function getBusinnessDateSuccess(result) {
 		  if (result.date) {
 			 vm.reportDate  = DateUtils.convertLocalDateFromServer(result.date);
          }
 		  else {vm.reportDate =  null;}
 		 		
		}
		function getBusinessDateFailed(error) {
			//Do Nothing
		}*/
		vm.todayDate=new Date();
		vm.reportDate  = DateUtils.convertDateTimeFromServer(vm.todayDate);
 	    
 	   logScreenAccess();
 	   loadHeader();
 	   
 	   function loadHeader(){
 		   	vm.headerList =[];
 		   	vm.totalBankUsersCount = 0;
			vm.totalBranchUsersCount = 0;
			vm.totalCustomerUsersCount = 0;	
			vm.totalBranchUserCountForBank = 0;
			vm.totalBranchCustomerCountForBank = 0;
			vm.totalBranchCustomerUSerCount = 0;
			vm.filterText="";
 		  
 		  LoggedInUsersReport.getHeaderData(  reportHeaderSuccess, reportHeaderFailure );
 	   }
 	   function reportHeaderSuccess(result){
 		  
 		   vm.headerList = result;
 		  calculateHeaderTotal(vm.headerList);
 		  showGridsForHeaderSelection();
 	   }
 	   function reportHeaderFailure(error){
 		   
 	   }
 	  
 	  function getBankUsersDetails(selectedBankId, selectedBankName){
 		  
 		 vm.selectedBankName = selectedBankName;
         vm.selectedBankId = selectedBankId;
         vm.selectedEntityName = selectedBankName;
         vm.selectedEntityType = 'Bank';
         vm.userFilterText="";
         //reset the paggination
 		 $scope.noOfUserRecordsPerPage = recordsPerPage;
 		 $scope.userPage = 1; 	
         vm.showSelectedBankUsersDetails = true;
 		 vm.showSelectedBranchUsersDetails = false;
 		 vm.showSelectedCustomerUsersDetails = false;
 		 vm.showBranchDrillDown = false;
 		 vm.showCustomerCountForBranches=false; 
 		 vm.showSelectedBranchCustomerDetails = false;
         LoggedInUsersReport.getBankUsersDetails({bankId:selectedBankId},bankUserDetailsSuccess, bankUserDetailsFailure );
         
 	  }
 	  function bankUserDetailsSuccess(result){
 		 vm.selectedEntityUserList = result;
 			 
 		 
 	  }
 	  function bankUserDetailsFailure(error){
		   
 	  }
 	  
 	  
 	  function getBranchWiseUsersCount(selectedBankId, selectedBankName){
 		 vm.branchWiseUserCountList = [];
 		 vm.totalBranchUserCountForBank = 0; 			
 		 vm.totalBranchCustomerCountForBank = 0;  
 		 vm.totalBranchCustomerUSerCount = 0; 		  
 		 vm.selectedBankName = selectedBankName;
         vm.selectedBankId = selectedBankId;
         vm.branchWiseFilterText="";
         //reset the paggination
 		 /*$scope.noOfBranchRecordsPerPage = recordsPerPage;
 		 $scope.branchPage = 1;
         vm.showBranchDrillDown = true;
 		 vm.showSelectedBankUsersDetails = false;
 		 vm.showSelectedBranchUsersDetails = false;
 		 vm.showSelectedCustomerUsersDetails = false;
 		 vm.showCustomerCountForBranches=false; 
 		 vm.showSelectedBranchCustomerDetails = false;*/
         showGridsForBranchUserSelection();
         LoggedInUsersReport.getBranchUsersCountForBank({bankId:selectedBankId}, branchWiseUsersCountSuccess, branchWiseUsersCountFailure );
 	  }
 	  function branchWiseUsersCountSuccess(result){
 		
 		 vm.branchWiseUserCountList = result;
 		 calculateBranchUserTotal( vm.branchWiseUserCountList);
 		
 		 
 	  }
 	  function branchWiseUsersCountFailure(error){
		   
 		  
 	  }
 	  
 	  function getBranchWiseCustomerCount(selectedBankId, selectedBankName){
 		 vm.branchWiseCustomerCountList =[];
 		 vm.totalBranchCustomerCountForBank = 0;
 		 vm.totalBranchCustomerUSerCount = 0;
 		 vm.customerDetailsList =[];
 		 vm.branchWiseCustomerFilterText = "";
 		 vm.selectedBankName = selectedBankName;
         vm.selectedBankId = selectedBankId;
         showGridsForBranchCustomerCountSelection();
         //reset the paggination
 		 /*$scope.noOfBranchCustomerRecordsPerPage = recordsPerPage;
 		 $scope.branchCustomerPage = 1;
 		 vm.showSelectedBankUsersDetails = false;
		 vm.showSelectedBranchUsersDetails = false;
		 vm.showSelectedCustomerUsersDetails = false;
		 vm.showBranchDrillDown = false;
		 vm.showCustomerCountForBranches=true; 
		 vm.showSelectedBranchCustomerDetails = false;*/
         showGridsForBranchCustomerCountSelection();
         LoggedInUsersReport.getCustomerCountForBranch({bankId:selectedBankId}, branchWiseCustomerCountSuccess, branchWiseCustomerCountSuccess );
 	  }
 	  function branchWiseCustomerCountSuccess(result){
  		
 		 vm.branchWiseCustomerCountList = result;
 		 calculateBranchCustomerTotal(vm.branchWiseCustomerCountList);
 		 
 	  }
 	  function branchWiseCustomerCountFailure(error){
		   
 		  
 	  }
 	  
 	  function getBranchUsersDetails(branchName , branchId, userList){
 		 vm.selectedEntityName = branchName;
         vm.selectedEntityType = 'Branch';
         vm.userFilterText="";
    	 vm.selectedEntityUserList = userList;
 		 //reset the paggination
 		 $scope.noOfUserRecordsPerPage = recordsPerPage;
 		 $scope.userPage = 1; 
 		 vm.showSelectedBankUsersDetails = false;
		 vm.showSelectedBranchUsersDetails = true;
		 vm.showSelectedCustomerUsersDetails = false;
		 vm.showBranchDrillDown = true;
		 vm.showCustomerCountForBranches=false; 
		 vm.showSelectedBranchCustomerDetails = false;
 	  }
 	  
 	  function logScreenAccess(){
			  vm.screenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	  }
	
 	  
 	  function getBranchCustomerDetails(bankId, branchId, branchName){
 		 vm.totalBranchCustomerUSerCount = 0;
 		 vm.customerDetailsList =[];
 		 vm.selectedBranchName = branchName;
         vm.selectedBranchId = branchId;     
 		 
 		 /*
 		 //reset the paggination
 		 $scope.noOfCustomerRecordsPerPage = recordsPerPage;
 		 $scope.customerPage = 1; 
 		 vm.showSelectedBankUsersDetails = false;
		 vm.showSelectedBranchUsersDetails = false;
		 vm.showSelectedCustomerUsersDetails = false;
		 vm.showBranchDrillDown = false;
		 vm.showCustomerCountForBranches=true; 
		 vm.showSelectedBranchCustomerDetails = true;*/
 		 showGridsForCustomerUserCountSelection();
 		 LoggedInUsersReport.getCustomersUsersCount({bankId:bankId, branchId:branchId },getBranchCustomerDetailsSuccess,getBranchCustomerDetailsFailure);
 		
 	  }
 	  function getBranchCustomerDetailsSuccess(result){
 		 
 		 vm.customerDetailsList = result;
 		 calculateCustomerUserTotal( vm.customerDetailsList);
 		
 	  }
 	  function getBranchCustomerDetailsFailure(error){
 		 
 	  }
 	  
 	  function getCustomerUserDetails(customerName, userList){
 		  
 		 vm.selectedEntityName = customerName;
         vm.selectedEntityType = 'Customer';
         vm.userFilterText="";
    	 vm.selectedEntityUserList = userList;
 		 //reset the paggination
 		 $scope.noOfUserRecordsPerPage = recordsPerPage;
 		 $scope.userPage = 1; 
 		 vm.showSelectedBankUsersDetails = false;
		 vm.showSelectedBranchUsersDetails = false;
		 vm.showSelectedCustomerUsersDetails = true;
		 vm.showBranchDrillDown = false;
		 vm.showCustomerCountForBranches=true; 
		 vm.showSelectedBranchCustomerDetails = true;
 	  }
 	  
 		/********** Refresh**********/
	  $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadHeader();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
	
	/***********END *************/
        function calculateHeaderTotal(list){
    		vm.totalBankUsersCount = 0;
    		vm.totalBranchUsersCount = 0;
    		vm.totalCustomerUsersCount= 0;
    		
    		angular.forEach(list, function(overallCountForbank) {
    			
    			vm.totalBankUsersCount = vm.totalBankUsersCount + overallCountForbank.bankUsersCount;  
    			vm.totalBranchUsersCount = vm.totalBranchUsersCount + overallCountForbank.branchUsersCount; 
    			vm.totalCustomerUsersCount = vm.totalCustomerUsersCount + overallCountForbank.customerUsersCount;
    		});
    		
    	}
    	function calculateBranchUserTotal(list){
    		vm.totalBranchUserCountForBank = 0;
    		angular.forEach(list , function(branch) {		

    			vm.totalBranchUserCountForBank = vm.totalBranchUserCountForBank + branch.branchUsersCount;

    		});
    		
    	}
    	function calculateBranchCustomerTotal(list){
    		vm.totalBranchCustomerCountForBank  = 0;
    		angular.forEach(list, function(branchCustomer) {
    			
    			vm.totalBranchCustomerCountForBank = vm.totalBranchCustomerCountForBank + branchCustomer.customerCount;
    		});
    		
    	}
    	function calculateCustomerUserTotal(list){
    		vm.totalBranchCustomerUSerCount = 0;
    		angular.forEach(list , function(customer) {
    			
    			vm.totalBranchCustomerUSerCount = vm.totalBranchCustomerUSerCount + customer.customerUsersCount;
    		});
    		
    	}
    	$scope.$watch('vm.filterText', function(value){
    		calculateHeaderTotal(vm.filterData);
    		showGridsForHeaderSelection();
        }); 
    	$scope.$watch('vm.branchWiseFilterText', function(value){
    		calculateBranchUserTotal(vm.branchWiseUserFilterData);
    		showGridsForBranchUserSelection();
        }); 
    	$scope.$watch('vm.branchWiseCustomerFilterText', function(value){
    		calculateBranchCustomerTotal(vm.branchWiseCustomerFilterData );
    		showGridsForBranchCustomerCountSelection();
        }); 
    	$scope.$watch('vm.customerFilterText', function(value){
    		calculateCustomerUserTotal(vm.customerFilterData);
    		showGridsForCustomerUserCountSelection();
        });
    	
    	function showGridsForCustomerUserCountSelection(){
    		 //reset the paggination
    		$scope.noOfCustomerRecordsPerPage = recordsPerPage;
    		$scope.customerPage = 1; 
    		vm.showSelectedBankUsersDetails = false;
   		 	vm.showSelectedBranchUsersDetails = false;
   		 	vm.showSelectedCustomerUsersDetails = false;
   		 	vm.showBranchDrillDown = false;
   		 	vm.showCustomerCountForBranches=true; 
   		 	vm.showSelectedBranchCustomerDetails = true;
   		 	vm.userFilterText="";
   		 	
    	}
    	function showGridsForBranchCustomerCountSelection(){
    		 //reset pagination
    		 $scope.noOfBranchCustomerRecordsPerPage = recordsPerPage;
    		 $scope.branchCustomerPage = 1;
    		 vm.showSelectedBankUsersDetails = false;
    		 vm.showSelectedBranchUsersDetails = false;
    		 vm.showSelectedCustomerUsersDetails = false;
    		 vm.showBranchDrillDown = false;
    		 vm.showCustomerCountForBranches=true; 
    		 vm.showSelectedBranchCustomerDetails = false;
    		 vm.userFilterText="";
    		 vm.customerFilterText ="";
    		 
    	}
    	function showGridsForBranchUserSelection(){
    		
    		 $scope.noOfBranchRecordsPerPage = recordsPerPage;
    		 $scope.branchPage = 1;
    		 vm.showBranchDrillDown = true;
     		 vm.showSelectedBankUsersDetails = false;
     		 vm.showSelectedBranchUsersDetails = false;
     		 vm.showSelectedCustomerUsersDetails = false;
     		 vm.showCustomerCountForBranches=false; 
     		 vm.showSelectedBranchCustomerDetails = false;
     		 vm.userFilterText="";
     		 //vm.branchWiseFilterText="";
    	}
    	function showGridsForHeaderSelection(){
    		
    		vm.showBranchDrillDown = false;
    		 vm.showSelectedBankUsersDetails = false;
    		 vm.showSelectedBranchUsersDetails = false;
    		 vm.showSelectedCustomerUsersDetails = false;
    		 vm.showCustomerCountForBranches=false; 
    		 vm.showSelectedBranchCustomerDetails = false;
    		 vm.userFilterText="";
    		 vm.customerFilterText ="";
    		 vm.branchWiseCustomerFilterText = "";
    		 vm.branchWiseFilterText="";
    	}
    	 function exportToFile(fileType){
          	  vm.baseString =   'No_Of_Logged_In_Users_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
          	  if( vm.headerList !=null &&  vm.headerList!=undefined){
          		  if(fileType == 'csv'){
          			 vm.mimeType = 'text/csv'
          			 vm.baseString = vm.baseString+ ".csv";  
          			LoggedInUsersReport.getCSVData(vm.headerList , processResponse )
          		  }else if(fileType == 'excel'){
          			  vm.mimeType = 'application/vnd.ms-excel';
          			  vm.baseString = vm.baseString+ ".xls";  
          			LoggedInUsersReport.getExcelData(vm.headerList , processResponse );
          		  }
          	  }
            }	
            function processResponse(result){
          	  var fileData = null;
      		  fileData = result.data;
      		  var link = document.createElement("a");   
      		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
      		  
      		  
      	      var blob = new Blob([bytes], {type: vm.mimeType});
      	      if(window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
      	      }else{
      	    	  link.href =  window.URL.createObjectURL(blob);      
      	    	  link.download = vm.baseString;
      	    	  document.body.appendChild(link);
      	    	  link.click();
      	    	  document.body.removeChild(link);
      	      }
      	  }
	}
})();