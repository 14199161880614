(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("GlobalValues", GlobalValues);

    GlobalValues.$inject = ['$sessionStorage','OrganisationTypeEnum'];

    function GlobalValues($sessionStorage,OrganisationTypeEnum) {

        var corp = null;
        var entityOwnerType = null;
        var branch = null;
        var bank = null;
        var customer = null;
        var user = null;
        var configSettings = null;
        var systemSettings = null;
        var country=null;
        var errorMessages = null;
        var sessionTimedOut = false;
        var financialYear = null;
        var environmentName = '';

    	var service = {
    		getCorpId: getCorpId,
    		clearPreviousValues: clearPreviousValues,
    		setCorp: setCorp,
    		setEntityOwnerType:setEntityOwnerType,
    		setBranch:setBranch,
    		setBank:setBank,
    		setCustomer:setCustomer,
    		setUser:setUser,
    		setConfigurationSettings:setConfigurationSettings,
    		setSystemSettings:setSystemSettings,
    		setCountry:setCountry,
    		getCorporate: getCorporate,
    		getNumberOfDecimalsForRate:getNumberOfDecimalsForRate,
    		getNumberOfDecimalsForRateInReports:getNumberOfDecimalsForRateInReports,
    		getNumberOfDecimalsForInputRate : getNumberOfDecimalsForInputRate,
    		getNumberOfDecimalsForDays:getNumberOfDecimalsForDays,
    		getNumberOfDecimalsForPercent:getNumberOfDecimalsForPercent,
    		getNumberOfDecimalsForAmount:getNumberOfDecimalsForAmount,
    		getOrgId:getOrgId,
    		getEntityOwnerTypeId:getEntityOwnerTypeId,
    		getBranchId:getBranchId,
    		getBankId:getBankId,
    		getCustomerId:getCustomerId,
    		getUserId:getUserId,
    		getOrganisation:getOrganisation,
    		getEntityOwnerType:getEntityOwnerType,
    		getConfigSettings:getConfigSettings,
    		getSystemSettings:getSystemSettings,
    		getBranch:getBranch,
    		getBank:getBank,
    		getCustomer:getCustomer,
    		getUser:getUser,
    		getCountry:getCountry,
    		checkAndSetSelectedOrganisation:checkAndSetSelectedOrganisation,
    		checkAndSetSelectedEntityOwnerType:checkAndSetSelectedEntityOwnerType,
    		checkAndSetSelectedBranch:checkAndSetSelectedBranch,
    		checkAndSetSelectedBank:checkAndSetSelectedBank,
    		checkAndSetSelectedCustomer:checkAndSetSelectedCustomer,
    		checkAndSetSelectedUser:checkAndSetSelectedUser,
    		checkAndSetSelectedConfigurationSettings:checkAndSetSelectedConfigurationSettings,
    		checkAndSetSelectedSystemSettings:checkAndSetSelectedSystemSettings,
    		setSelectedUser:setSelectedUser,
    		getUserOrganisation:getUserOrganisation,
    		getNoOfRecordsPerPageInPagination:getNoOfRecordsPerPageInPagination,
    		checkAndSetSelectedCountry:checkAndSetSelectedCountry,
    		formatCamelCaseToStringWithSpace:formatCamelCaseToStringWithSpace,
    		checkAndSetErrorMessages : checkAndSetErrorMessages,
    		getErrorMessages:getErrorMessages,
    		setErrorMessages:setErrorMessages,
    		checkAndSetSelectedErrorMessages : checkAndSetSelectedErrorMessages,
    		getSessionTimedOut: getSessionTimedOut,
    		setSessionTimedOut: setSessionTimedOut,
    		getFinancialYear : getFinancialYear,
    		setFinancialYear : setFinancialYear,
    		checkAndSetSelectedFinancialYear : checkAndSetSelectedFinancialYear,
            getLoginUrlParam : getLoginUrlParam,
            getEnvironmentName:getEnvironmentName
    	};
    	return service;
    	
    	function getNumberOfDecimalsForRate(){
    		return 4;
    	}
    	
        function formatCamelCaseToStringWithSpace(camelCaseString){
        	if(camelCaseString.trim().indexOf(' ') > -1){
        		return camelCaseString;
        	}
        	else {
            	var CAMEL_CASE_REGEXP1 = /([A-Z]+)/g;
            	var CAMEL_CASE_REGEXP2 = /([A-Z][a-z])/g;
            	var separator = " ";
                return camelCaseString.replace(CAMEL_CASE_REGEXP1, separator + "$1").replace(CAMEL_CASE_REGEXP2, separator + "$1").trim();
        	}
        }
        //get enviramentName in configration settings
        function getEnvironmentName(){
            if($sessionStorage.configSettings!=null){
                checkAndSetEnvironmentName();
            } 
            return environmentName;
        }
    	function getNumberOfDecimalsForRateInReports(){
    		return 2;
    	}
    	function getNumberOfDecimalsForInputRate(){
    		return 6;
    	}
    	
    	function getNumberOfDecimalsForDays(){
    		return 0;
    	}
    	
    	function getNumberOfDecimalsForPercent(){
    		return 3;
    	}
    	function getNumberOfDecimalsForAmount(){
    		return 2;
    	}
    	
    	function clearPreviousValues(){
            corp = null;
            entityOwnerType = null;
            branch = null;
            bank = null;
            customer = null;
            user = null;
            country=null;
            configSettings = null;
            systemSettings = null;
            errorMessages = null;
            sessionTimedOut = false;
            financialYear = null;
            environmentName = '';
            $sessionStorage.corp = null;
            $sessionStorage.entityOwnerType = null;
            $sessionStorage.branch = null;
            $sessionStorage.bank = null;
            $sessionStorage.customer = null;
            $sessionStorage.user = null;
            $sessionStorage.configSettings = null;
            $sessionStorage.systemSettings = null;
            $sessionStorage.country=null;
            $sessionStorage.errorMessages = null;
            $sessionStorage.sessionTimedOut = false;
            $sessionStorage.financialYear = null;
    	}
    	
    	function setCorp(selectedcorp) {
    		corp = selectedcorp;
    		$sessionStorage.corp = selectedcorp;
        }

   	   	function setEntityOwnerType(selectedEntityOwnerType) {
   	   		entityOwnerType = selectedEntityOwnerType;
    		$sessionStorage.entityOwnerType = selectedEntityOwnerType;
        }

   	   	function setBranch(selectedBranch) {
   	   		branch = selectedBranch;
    		$sessionStorage.branch = selectedBranch;
        }

   	   	function setBank(selectedBank) {
   	   		bank = selectedBank;
    		$sessionStorage.bank = selectedBank;
        }

   	   	function setCustomer(selectedCustomer) {
   	   		customer = selectedCustomer;
    		$sessionStorage.customer = selectedCustomer;
        }

   	   	function setUser(selectedUser) {
   	   		user = selectedUser;
    		$sessionStorage.user = selectedUser;
        }

   	   	function setConfigurationSettings(selectedConfigSettings) {
   	   		configSettings = selectedConfigSettings;
    		$sessionStorage.configSettings = selectedConfigSettings;
        }
   	   	
   	   	function setSystemSettings(selectedSystemSettings) {
   	   		systemSettings = selectedSystemSettings;
    		$sessionStorage.systemSettings = selectedSystemSettings;
        }
   	   	
   	   	function setCountry(selectedCountry) {
   	   		country = selectedCountry;
    		$sessionStorage.country = selectedCountry;
        }
   	   	
	   	 function setFinancialYear(selectedFinancialYear) {
	   		 financialYear = selectedFinancialYear;
	 		$sessionStorage.financialYear = selectedFinancialYear;
	     }

        function getUserOrganisation() {
            return corp;
        }

        function getCorpId() {
            checkAndSetCorporate();
            return corp.id;
        }

        function getOrgId() {
            checkAndSetCorporate();
            return corp.id;
        }

        function getEntityOwnerTypeId() {
            checkAndSetEntityOwnerType();
            return entityOwnerType.id;
        }

        function getBranchId() {
            checkAndSetBranch();
            return branch.id;
        }

        function getBankId() {
            checkAndSetBank();
            return bank.id;
        }

        function getCustomerId() {
            checkAndSetCustomer();
            return customer.id;
        }

        function getUserId() {
            checkAndSetUser();
            return user.id;
        }

        function getNoOfRecordsPerPageInPagination() {
            return 10;
        }

        function checkAndSetSelectedOrganisation(selectedOrganisation) {
            //if ($sessionStorage.corp == null) {
                setCorp(selectedOrganisation);
            //} else {
            //    setCorp($sessionStorage.corp);
            //}
        }

        function checkAndSetSelectedEntityOwnerType(selectedEntityOwnerType) {
//            if ($sessionStorage.entityOwnerType == null) {
                setEntityOwnerType(selectedEntityOwnerType);
//            } else {
//            	setEntityOwnerType($sessionStorage.entityOwnerType);
//            }
        }

        function checkAndSetSelectedBranch(selectedBranch) {
            //if ($sessionStorage.branch == null) {
                setBranch(selectedBranch);
            //} else {
            //	setBranch($sessionStorage.branch);
            //}
        }

        function checkAndSetSelectedBank(selectedBank) {
            //if ($sessionStorage.bank == null) {
                setBank(selectedBank);
            /*} else {
            	setBank($sessionStorage.bank);
            }*/
        }

        function checkAndSetSelectedCustomer(selectedCustomer) {
//            if ($sessionStorage.customer == null) {
                setCustomer(selectedCustomer);
//            } else {
//            	setCustomer($sessionStorage.customer);
//            }
        }

        function checkAndSetSelectedUser(selectedUser) {
//            if ($sessionStorage.user == null) {
                setUser(selectedUser);
//            } else {
//            	setUser($sessionStorage.user);
//            }
        }

        function checkAndSetSelectedConfigurationSettings(selectedConfigSettings) {
//            if ($sessionStorage.configSettings == null) {
                setConfigurationSettings(selectedConfigSettings);
//            } else {
//            	setConfigurationSettings($sessionStorage.configSettings);
//            }
        }
        
        function checkAndSetSelectedSystemSettings(selectedSystemSettings) {
//            if ($sessionStorage.systemSettings == null) {
                setSystemSettings(selectedSystemSettings);
//            } else {
//            	setSystemSettings($sessionStorage.systemSettings);
//            }
        }

        function checkAndSetSelectedCountry(selectedCountry) {
//        	 if ($sessionStorage.country == null) {
                 setCountry(selectedCountry);
//             } else {
//             	setCountry($sessionStorage.country);
//             }
         }
        
        
        function checkAndSetSelectedFinancialYear(selectedFinancialYear) {
//       	 	if ($sessionStorage.financialYear == null) {
                setFinancialYear(selectedFinancialYear);
//            } else {
//            	setFinancialYear($sessionStorage.financialYear);
//            }
        }
        function checkAndSetEnvironmentName(){
            if($sessionStorage.configSettings.environmentName != null && $sessionStorage.configSettings.environmentName!='' 
                    && $sessionStorage.configSettings.environmentName!=undefined){
                  environmentName=$sessionStorage.configSettings.environmentName.toUpperCase();
           }
        }

        function checkAndSetCorporate() {
        	if ($sessionStorage.corp != null) {
                corp = $sessionStorage.corp;
            }
        }

        function checkAndSetEntityOwnerType() {
        	if ($sessionStorage.entityOwnerType != null) {
        		entityOwnerType = $sessionStorage.entityOwnerType;
            }
        }

        function checkAndSetBranch() {
        	if ($sessionStorage.branch != null) {
        		branch = $sessionStorage.branch;
            }
        }

        function checkAndSetBank() {
        	if ($sessionStorage.bank != null) {
        		bank = $sessionStorage.bank;
            }
        }

        function checkAndSetCustomer() {
        	if ($sessionStorage.customer != null) {
        		customer = $sessionStorage.customer;
            }
        }

        function checkAndSetUser() {
        	if ($sessionStorage.user != null) {
        		user = $sessionStorage.user;
            }
        }

        function checkAndSetConfigurationSettings() {
        	if ($sessionStorage.configSettings != null) {
        		configSettings = $sessionStorage.configSettings;
            }
        }
        
        function checkAndSetSystemSettings() {
        	if ($sessionStorage.systemSettings != null) {
        		systemSettings = $sessionStorage.systemSettings;
            }
        }
        
        function checkAndSetCountry() {
        	if ($sessionStorage.country != null) {
        		country = $sessionStorage.country;
            }
        }
        
        function checkAndSetFinancialYear() {
        	if ($sessionStorage.financialYear != null) {
        		financialYear = $sessionStorage.financialYear;
            }
        }

        function getCorporate() {
            checkAndSetCorporate();
            return corp;
        }

        function getOrganisation() {
            checkAndSetCorporate();
            return corp;
        }

        function getEntityOwnerType() {
            checkAndSetEntityOwnerType();
            return entityOwnerType;
        }
        
        function getBranch() {
            checkAndSetBranch();
            return branch;
        }
        
        function getBank() {
            checkAndSetBank();
            return bank;
        }

        function getCustomer() {
            checkAndSetCustomer();
            return customer;
        }

        function getUser() {
            checkAndSetUser();
            return user;
        }
        
        function getConfigSettings() {
            checkAndSetConfigurationSettings();
            return configSettings;
        }
        
        function getSystemSettings() {
            checkAndSetSystemSettings();
            return systemSettings;
        }
        
        function setSelectedUser(selectedUser){
        	setUser(selectedUser);
        }
        
        function getCountry() {
            checkAndSetCountry();
            return country;
        }
        
        function getFinancialYear() {
            checkAndSetFinancialYear();
            return financialYear;
        }
        function getErrorMessages(){
        	checkAndSetErrorMessages();
        	return errorMessages;
        }
        function checkAndSetSelectedErrorMessages(selectedErrorMessages) {
        	 if ($sessionStorage.errorMessages == null || $sessionStorage.errorMessages == undefined) {
                 setErrorMessages(selectedErrorMessages);
             } else {
            	 setErrorMessages($sessionStorage.errorMessages);
             }
        }
        function setErrorMessages(selectedErrorMessages) {
        	errorMessages = selectedErrorMessages;
        	$sessionStorage.errorMessages = selectedErrorMessages;
        }
        function checkAndSetErrorMessages() {
        	if ($sessionStorage.errorMessages != null) {
        		errorMessages = $sessionStorage.errorMessages;
            }
        }
        
        function setSessionTimedOut(status){
        	sessionTimedOut = status;
    		$sessionStorage.sessionTimedOut = status;
        }

        function getSessionTimedOut(){
        	return $sessionStorage.sessionTimedOut;
        }
        function getLoginUrlParam(){
            if(getOrganisation() != undefined && getOrganisation() != null){
                if(getOrganisation().organisationType.shortName == OrganisationTypeEnum.CLEARCORP){
                    return OrganisationTypeEnum.ADMIN;
                }
            }
            return "";
        }
    }
})();
