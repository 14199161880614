(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SMSFailureReportController', SMSFailureReportController);

    SMSFailureReportController.$inject = ['$scope', '$state', 'workflowSharedService','SMSMessages','$rootScope','MessageBox', 'GlobalValues','EntityPermissions','$filter',
    	'ScreenLog', 'DateUtils','entityConstants','AlertService','alertType','$window'];

    function SMSFailureReportController($scope, $state, workflowSharedService, SMSMessages,$rootScope, MessageBox, GlobalValues, EntityPermissions,$filter,
    		ScreenLog, DateUtils, entityConstants,AlertService, alertType, $window) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
		vm.clicked=false;
        vm.getSMSFailureReport=getSMSFailureReport;
        vm.todayDate=new Date();
        vm.clearData=clearData;
        vm.resend=resend;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SMS_FAILURE_REPORT;
        vm.screenLog = ScreenLog;
        vm.status = 'ALL';
        
        //vm.fromDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        //vm.toDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        logScreenAccess();
        vm.alertTypes = alertType;
        vm.exportToFile = exportToFile;
        vm.historicalOrCurrent = 'Current';
        
		vm.reportDate  = DateUtils.convertDateTimeFromServer(vm.todayDate);
		vm.yesterdayDate = angular.copy(vm.reportDate);
		vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
		vm.fromDate = angular.copy(vm.reportDate);
		vm.fromDate.setDate(vm.fromDate.getDate() -1);
	    vm.toDate = angular.copy(vm.reportDate);
	    vm.toDate.setDate(vm.toDate.getDate() -1);
        
        /*
         * Get details for report given from date, to date, successflag
         */
        function getSMSFailureReport()
        {   
        		vm.smsDetails = [];
        		var alertType = 0;
        		if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
        			AlertService.error($filter('translate')('gatewayApp.FailureReport.futureFromDate'));
                }else if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.toDate,'day')){
        			AlertService.error($filter('translate')('gatewayApp.FailureReport.greaterFromDate'));
        		}else if(vm.historicalOrCurrent != 'Current' && moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
        			AlertService.error($filter('translate')('gatewayApp.FailureReport.greaterToDate'));
        		}
        		else{
				vm.clicked=true;
        		var from =  DateUtils.convertLocalDateToServer(vm.fromDate);
        		var to =  DateUtils.convertLocalDateToServer(vm.toDate);
        		
        		if(vm.alertType != null && vm.alertType != undefined){
        			alertType = vm.alertType.id;
        		}
        			
        		
        		if(vm.historicalOrCurrent == 'Current'){
            		from =  DateUtils.convertLocalDateToServer(vm.todayDate);
            		to =  DateUtils.convertLocalDateToServer(vm.todayDate);
            		SMSMessages.getSMSFailureReport({"from":from,"to":to, "alertTypeId": alertType, "status": vm.status},onGetSMSFailureReportSuccess,onGetSMSFailureReportError);
        		}else{
        			SMSMessages.getSMSFailureArchiveReport({"from":from,"to":to, "alertTypeId": alertType, "status": vm.status},onGetSMSFailureReportSuccess,onGetSMSFailureReportError);
        		}
        		
        		}
        	
        }
        function onGetSMSFailureReportSuccess(result)
        {
        	vm.successDetails = [];
        	vm.failureDetails = [];
        	
        	vm.smsDetails=result;
        	vm.clicked=false;
        		
        }
        
        
        function onGetSMSFailureReportError()
        {
        	vm.clicked=false;
        }
        
        /*
         * Clear data
         */
        
        function clearData()
        {
        	MessageBox.openMessageBox("Alert", "Are you sure to clear the data?", "ProceedCancel", "cleardata");
        }
        
        $rootScope.$on('gatewayApp.messagebox.proceed.cleardata', function(event,data) {
        	if(vm.historicalOrCurrent == 'Current'){
            	SMSMessages.delete(vm.smsFailureDetails, onDeleteSuccess, onDeleteFailure);
        	}else{
            	SMSMessages.deleteArchive(vm.smsFailureDetails, onDeleteSuccess, onDeleteFailure);
        	}
        	function onDeleteSuccess() {
        		
                $uibModalInstance.close(true);
               
                
            }
        	 $state.go('sms-failure-report', null, { reload: 'sms-failure-report' });
        	function onDeleteFailure() {
                
            }
		});
        
        $rootScope.$on('gatewayApp.messagebox.cancel.cleardata', function(event,data) {
        	
        });
        
        /*
         * Resend message
         */
        
        function resend(smsId){
        	if(vm.historicalOrCurrent == 'Current'){
            	SMSMessages.resend({"smsId" : smsId, "isHistorical": false}, onResendSuccess, onResendError);
        	}else{
            	SMSMessages.resend({"smsId" : smsId, "isHistorical": true}, onResendSuccess, onResendError);
        	}
        }
        
        function onResendSuccess () 
        {
        	
        	getSMSFailureReport();
       }
        
        function onResendError(){
        	getSMSFailureReport();
        }
        
        
        function exportToFile(fileType){
	      	  var mimeType= '';
	      	  var fileData = null;
	      	  var baseString =   'SMS_Report'+ moment().format( 'DD_MMM_YYYY_HH_mm');   
	      	  var viewFor = '';
	 		 
	      	  if( vm.successDetails.length > 0 || vm.failureDetails.length > 0){
	      		  if(fileType == 'csv'){
	      			  mimeType = 'text/csv'
	      			  baseString = baseString+ ".csv";  
	      		  }else if(fileType == 'excel'){
	      			  mimeType = 'application/vnd.ms-excel';
	      			  baseString = baseString+ ".xls";  
	      		  }
	      		  vm.convertedFromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
	      		  vm.convertedToDate = DateUtils.convertLocalDateToServer(vm.toDate);
	 			
	 				SMSMessages.exportData({alertType : vm.alertType.alertTypeName, status : vm.status, from : vm.convertedFromDate, to : vm.convertedToDate}, vm.smsDetails, 
	 					function(result){
	 				
		      			 
		      			  fileData = result.data;
		      			  var link = document.createElement("a");   
		      			  var bytes = new Uint8Array( fileData );   
		      			      			  
		  			      var blob = new Blob([bytes], {type: mimeType});
		  			      link.href =  window.URL.createObjectURL(blob);
		  			      
		      		   	  link.download = baseString;
		      		   	  document.body.appendChild(link);
		      		   	  link.click();
		      			  document.body.removeChild(link);
		      		  });
	 			 
	 			 }
	      		
	      		  
	      		  
	      	  }
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        
     }
})();
