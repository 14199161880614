(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RegularHolidayViewController', RegularHolidayViewController);

    RegularHolidayViewController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','entity', 'StaticLookUp', 'RegularHoliday', 'DateUtils', 'Workflow'];

     function RegularHolidayViewController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,entity, StaticLookUp, RegularHoliday, DateUtils, Workflow) {
        var vm = this;
        vm.dayList=getDayList();
        vm.weekNumberList=getWeekNumberList();
        vm.approveRegularHoliday = approveRegularHoliday;
        vm.rejectRegularHoliday = rejectRegularHoliday;
        vm.discardRegularHoliday = discardRegularHoliday;
        vm.clear=clear;
        $scope.clicked=false;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        vm.globalValues = GlobalValues;
        vm.valid = true;
       	
        if(entity!=null){
            	vm.regularHolidaysHeader=entity;
            	//vm.regularHolidaysHeader.effectiveFrom = DateUtils.convertDateTimeFromServer(vm.regularHolidaysHeader.effectiveFrom);
            	getRegularHolidayDetailList();
           	 	$scope.showButton=false;
        }
        
        if(workflowSharedService.viewFrom == 'regularholidays'){
        	vm.regularHolidaysHeader = entity;
        	if(vm.regularHolidaysHeader!=null){
            	//vm.regularHolidaysHeader.effectiveFrom = DateUtils.convertDateTimeFromServer(vm.regularHolidaysHeader.effectiveFrom);
            	getRegularHolidayDetailList();
        	}
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'regularholidaystodolist_checker') {
        	vm.regularHolidaysHeader =  angular.fromJson($rootScope.payload); 
        	if(vm.regularHolidaysHeader!=null){
            	//vm.regularHolidaysHeader.effectiveFrom = DateUtils.convertDateTimeFromServer(vm.regularHolidaysHeader.effectiveFrom);
            	getRegularHolidayDetailList();
        	}
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'regularholidaystodolist_maker') {
        	vm.regularHolidaysHeader =  angular.fromJson($rootScope.payload); 
        	if(vm.regularHolidaysHeader!=null){
            	//vm.regularHolidaysHeader.effectiveFrom = DateUtils.convertDateTimeFromServer(vm.regularHolidaysHeader.effectiveFrom);
            	getRegularHolidayDetailList();
        	}
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom == 'regularHoliday_auditLog') {
        	vm.regularHolidaysHeader =  angular.fromJson($rootScope.payload); 
        	if(vm.regularHolidaysHeader!=null){
            	//vm.regularHolidaysHeader.effectiveFrom = DateUtils.convertDateTimeFromServer(vm.regularHolidaysHeader.effectiveFrom);
            	getRegularHolidayDetailList();
        	}
        	$scope.showButton=false;
        	$scope.showDiscardButton = false;
        }
        function getRegularHolidayDetailList(){
       	 var regularHolidaysDetailsArr = vm.regularHolidaysHeader.regularHolidaysDetailsList;
       	 vm.daySelectionNumberList = [];
       	 vm.weekSelectionNumberList = {};
            angular.forEach(regularHolidaysDetailsArr, function(value, key) 
            {
           	 var dayNumber = [];
           	 
           	 var index = value.dayNumber.id - 1;
           	 dayNumber.push(value.dayNumber);
           	 
           	if(vm.daySelectionNumberList[index]==null){vm.daySelectionNumberList[index] = [];} 
           	vm.daySelectionNumberList[index].push(value.dayNumber);
           	 if(vm.weekSelectionNumberList[index]==null){vm.weekSelectionNumberList[index] = [];} 
           	 vm.weekSelectionNumberList[index].push(value.weekNumber);
            });
            console.log("day Selection number List "+ angular.toJson(vm.daySelectionNumberList));
            console.log("weeek Selection number List "+ angular.toJson(vm.weekSelectionNumberList));
            
       }

        function getDayList(){
        	return StaticLookUp.getDayNumberList();
        }
        
        function getWeekNumberList(){
        	return StaticLookUp.getWeekNumberList();
        }

        function approveRegularHoliday() { 
        	RegularHoliday.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectRegularHoliday() { Workflow.rejectTask($rootScope.taskID); }

        function discardRegularHoliday() {
        	RegularHoliday.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
       
        

     }   
})();
