(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerRegistrationViewController', CustomerRegistrationViewController);

    CustomerRegistrationViewController.$inject = ['$scope', '$state','$rootScope', '$window','$stateParams','workflowSharedService','mode', 'CustomerTradingBankDetails', 'Branch',
    			'$uibModalInstance','StaticLookUp','CustomerApproval', 'entity', 'Workflow', 'GlobalValues', 'ScreenLog', 'CustomerRegistration', 'MessageBox','DateUtils',
                'CustomerInfoChange','enableField', 'CustomerInfoUtil', 'entityConstants','$filter','ModifyCustomerDetailsService','AlertService', 'TACViewForEntitiesService',
                'TacViewForEntitiesUtility', 'customerTypeWiseInputDetails','ClearcorpTACUpload','markupTypes','roEdApplicabilityMethods'];

    function CustomerRegistrationViewController($scope, $state, $rootScope,$window, $stateParams,workflowSharedService, mode, CustomerTradingBankDetails, Branch,
    			$uibModalInstance,StaticLookUp, CustomerApproval, entity, Workflow, GlobalValues, ScreenLog, CustomerRegistration, MessageBox,DateUtils,
                CustomerInfoChange,enableField, CustomerInfoUtil, entityConstants,$filter,ModifyCustomerDetailsService,AlertService, TACViewForEntitiesService,
                TacViewForEntitiesUtility, customerTypeWiseInputDetails,ClearcorpTACUpload,markupTypes,roEdApplicabilityMethods) {
        var vm = this;
        vm.screenLog = ScreenLog;
        vm.clear=clear;
        vm.customerTypes = StaticLookUp.getAllCustomerTypes();
        vm.approveCustomerRegistration = approveCustomerRegistration;
        vm.rejectCustomerRegistration = rejectCustomerRegistration;
		vm.sourceOfIntro = StaticLookUp.getsourceOfIntro();
        vm.mode = mode;
        vm.showButton = false;    
		vm.disableMarkup = false; 
		vm.showHighLight = false;   
        vm.clicked=false;
        vm.globalValues = GlobalValues;
        if(vm.globalValues!=null && vm.globalValues!=undefined 
        		&& vm.globalValues.getEntityOwnerType()!=null 
        			&& vm.globalValues.getEntityOwnerType()!=undefined){
        	
        	vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        }
		vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.decimalForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
		vm.roEdApplicabilityMethodList = roEdApplicabilityMethods;
		vm.markupTypes = markupTypes;
        vm.disable=true;
        vm.ExpandOrCollapseCustomerInfo=true;
        vm.ExpandOrCollapseBankRelationship=false;
        vm.ExpandOrCollapseUserInfo=false;
        vm.theme = $stateParams.theme;
    	vm.showDiscardButton = false;
        vm.bankInfo=true;
		vm.showMarkup=false;
        vm.defaultUserInfo=true;
        vm.showResubmitButton=false;
        vm.resubmitCustomerRegistration=resubmitCustomerRegistration;
        vm.discardCustomerUpdate =  discardCustomerUpdate;
        /*Added to enable fields for resubmit from cstomer prfile amendment    */
        vm.checkforModification = checkforModification;
        vm.enableFieldsForResubmit = false;
        vm.invalidPanImageSize = false;
        var fd = null;
        var customerPreviousData ={}
        vm.oldTradingBranch = null;
        vm.oldHomeBranchDetails =  null;
   	 	vm.oldBankAccountNumber = null;	
   	 	vm.filterText = null;
        vm.isEdit= false;
        vm.noChange = false;
        vm.noHomeBranchChange = false;
        vm.noAccountChange = false;
        vm.isModifyScreen = false;
        vm.saveModificationOnCustomerDetails = saveModificationOnCustomerDetails;
        vm.cutomerInfo = true;
		vm.authPersonInfo = true;
        vm.showPrint= false;
        vm.discardModifyCustomerDetails = discardModifyCustomerDetails;
        vm.rejectCustomerModification = rejectCustomerModification;
        vm.approveModifyCustomerDetails = approveModifyCustomerDetails;
        vm.resubmitModifyCustomerDetails = resubmitModifyCustomerDetails;
        vm.viewAcceptedTermsAndConditions = viewAcceptedTermsAndConditions;
        vm.downloadAnnexure1 = downloadAnnexure1;
		vm.clicked=false;
        //intialize customer type wise input details
        vm.customerTypeWiseInputDetails = customerTypeWiseInputDetails;
        
        //Indices of input details in customer type wise input details array
        $scope.firstNameIndex = 0;
        $scope.middleNameIndex = 1;
        $scope.lastNameIndex = 2;
        $scope.entityNameIndex = 3;
        $scope.panNoIndex = 4;
        $scope.panImageIndex = 5;
        $scope.legalEntityIdentifierIndex = 6;
        $scope.leiExpiryDateIndex = 7;
        $scope.cinIndex = 8;
        $scope.gstinIndex = 9;
        $scope.stateCodeIndex = 10;
        $scope.dobDoiIndex = 11;
        vm.showSingleUser=false;
		vm.showMultiUser=false;
		vm.isSoleProp = false;
        var tacAnnexurefilename = "FX-Retail Registration with T&C_";

        $scope.customerTypeSolePropId = 27;
        
        vm.onGetCcilTac = TacViewForEntitiesUtility.onGetCcilTacForBank;
        vm.messageBoxName = "customerApproval";

		angular.forEach(vm.markupTypes, function(value){
			if(value.shortName.toUpperCase()=='PAISE'){
				vm.markupType = value; 
			}else{
				vm.markupType = value;
			}
		});
		
		//set no of decimals based on markup types
		$scope.$watch('vm.markupType', function(value){
			if(value!=null && value!=undefined){
				if(vm.markupType.shortName.toUpperCase()=='PERCENTAGE'){
					vm.noOfDecimals=vm.decimalsForPercent;
				 document.getElementById("txtMarkupValue2").value = '';
				 vm.markup.markupValue = null;
				}else{
					vm.noOfDecimals=vm.decimalForPaise;
				 document.getElementById("txtMarkupValue1").value = '';
				 vm.markup.markupValue = null;
				}
			}
		});
		
		$scope.$watch('vm.customerRegistrationRequest.markupSettings.markupValue', function(value){
			if(value===null || value===undefined || value===""){
				vm.disableTax=true;
				document.getElementById("txtTaxOnMarkup").value = "";
				vm.customerRegistrationRequest.markupSettings.taxOnMarkup=null;
				
			}else{
				if(value===0){
					vm.customerRegistrationRequest.markupSettings.markupValue="0";
				}
				vm.disableTax=false;
			}
		});
		
		function setMarkupTypeAndRoedMethod(){
			angular.forEach(vm.markupTypes, function(value){
				if(value.shortName.toUpperCase()==vm.customerRegistrationRequest.markupSettings.markupType.toUpperCase()){
					vm.markupType = value;
				}
			});
			angular.forEach(vm.roEdApplicabilityMethodList, function(value){
				if(value.id==vm.customerRegistrationRequest.markupSettings.roEdApplicabilityMethodId){
					vm.roEdApplicabilityMethod = value; 
				}
			});
		}
		function setUserNameAsCustomerName() {
			if(vm.customerRegistrationRequest.customerType.customerUserType.shortName=='SingleUserCustomer'){
				vm.customerRegistrationRequest.userFirstName = vm.customerDetails.customer.customerFirstName;
				vm.customerRegistrationRequest.userMiddleName = vm.customerDetails.customer.customerMiddleName;
				vm.customerRegistrationRequest.userLastName = vm.customerDetails.customer.customerLastName;
			}
			
			
		}
		function setCustomerNameAsUserName() {
			if(vm.customerRegistrationRequest.customerType.customerUserType.shortName=='SingleUserCustomer'){
				vm.customerDetails.customer.customerFirstName=vm.customerRegistrationRequest.userFirstName ;
				vm.customerDetails.customer.customerMiddleName=vm.customerRegistrationRequest.userMiddleName ;
				 vm.customerDetails.customer.customerLastName=vm.customerRegistrationRequest.userLastName ;
			}
			
			
		}
		function hideNameDisplayDetailsForSoleProp(customerRegistrationRequest){
			if(customerRegistrationRequest.customerType.id == $scope.customerTypeSolePropId){
				vm.isSoleProp = true;
			}
			}
        if(mode=='customerViewReport'){
        	vm.bankInfo=true;
        	vm.defaultUserInfo=true;
        	
        	
        }else if(mode=='customerApprovalToDo'){
        	vm.theme = 'dark';
        }else if(mode=='auditlog' || mode=='HistoryReport'){
        	vm.theme='dark';
        }
       
        vm.customerRegistrationRequest = entity;
        if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
    		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
    			vm.customerRegistrationRequest.customerPanImage = result;
    		})
    	}
        vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
        vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);  
        if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
     			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
		}

        if ((mode == "customeronboardingstatus" || mode == "registrationrequeststatus")  && vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='PENDINGAPPROVAL'){
        	vm.withdraw= true;
        }
        if(workflowSharedService.viewFrom == 'customerregistration'){
        	vm.customerRegistrationRequest = entity;
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
        		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
        			vm.customerRegistrationRequest.customerPanImage = result;
        		})
        	}
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            	vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
            }
       	 	vm.showButton=false;
       	 	if (mode == "customeronboardingstatus" || mode == "registrationrequeststatus"){
	        	vm.withdraw= true;
	        }
       	 	else if (mode == "modifycustomerdetails"){
	       	 	vm.oldTradingBranch = entity.tradingBranch;
	       		//Added for Home branch and account number update CR 27-OCT-2021
	       	 	vm.oldHomeBranchDetails =  angular.copy(entity.homeBranch);
	       	 	vm.oldBankAccountNumber = 	 angular.copy(entity.bankAccountNumber);	
	       	 	vm.filterText = entity.bank.ccpMembershipId;
	         	//alert(vm.tradingBank.customer.id);
	         	vm.isEdit = true;
	         	vm.noChange = false;
	         	vm.noHomeBranchChange = false;
	            vm.noAccountChange = false;
	         	vm.bankInfo=true;
	            vm.defaultUserInfo=false;	                      
	            vm.theme = 'dark';
	            vm.termsAndConditionsApproval = true;
	            vm.showPrint = false;	         	
	         	vm.showButton=false;
	         	vm.showResubmitButton=false;
	         	vm.showDiscardButton = false;
	         	vm.isModifyScreen = true;
	         	vm.cutomerInfo = false;
				vm.authPersonInfo = false;
	         	vm.ExpandOrCollapseCustomerInfo=false;
	            vm.ExpandOrCollapseBankRelationship=true;
	            vm.ExpandOrCollapseUserInfo=false;
       	 	}
       	        	 
       	 	
        }
        else if(workflowSharedService.viewFrom == 'customerregistrationtodolist_checker') {
        	
        	
        	if( mode=='customerInfoChangeStatus'){
             	vm.bankInfo=true;
             	vm.defaultUserInfo=true;
             	vm.customerDetails=angular.copy(angular.fromJson($rootScope.payload));
             	console.log(vm.customerDetails);
             	vm.customerRegistrationRequest=vm.customerDetails.customer;
             	CustomerTradingBankDetails.getBankAndDefaultUserDetailsForCustomer({customerId: vm.customerDetails.customer.id, bankId: vm.globalValues.getOrganisation().id}, function(result){
             		vm.bankAndDefaultUserDetails = result;
             		UpdateBankAndDefaultUserDetails();
					 setUserNameAsCustomerName();					 
             	})
				 hideNameDisplayDetailsForSoleProp(vm.customerRegistrationRequest);
             	vm.theme = 'dark';
             	vm.termsAndConditionsApproval = true;
             	vm.showPrint = true;
             }
        	else{
        		vm.customerRegistrationRequest =  angular.copy(angular.fromJson($rootScope.payload));
        		vm.termsAndConditionsApproval = false;
        		vm.showPrint = true;
        		vm.organisation = vm.globalValues.getOrganisation();
				
				vm.showMarkup = true;
				vm.showHighLight =true;
				//fill markup setting
				if(vm.customerRegistrationRequest.markupSettings==null||vm.customerRegistrationRequest.markupSettings==undefined){
					CustomerRegistration.getMarkupDetails(vm.customerRegistrationRequest,function(result){
						vm.customerRegistrationRequest.markupSettings = result.markupSettings;
						setMarkupTypeAndRoedMethod();
					});
				}else{
					setMarkupTypeAndRoedMethod();
				}
        		//loadTacAgreedDetailsForChecker(vm.organisation.id);
        	}
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
        		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
        			vm.customerRegistrationRequest.customerPanImage = result;
        		})
        	}
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
   			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
   			//vm.printArray.push(vm.customerRegistrationRequest);
            }
            
        	vm.showButton=true;
        	vm.showResubmitButton=false;
        	vm.showDiscardButton = false;
        	
        	
        }
        else if(workflowSharedService.viewFrom == 'customerregistrationtodolist_maker') {
        	if(mode=='customerInfoChangeStatus'){
             	vm.bankInfo=false;
             	vm.defaultUserInfo=false;
				vm.authPersonInfo = false;
             	vm.customerDetails=angular.copy(angular.fromJson($rootScope.payload)); 
             	vm.customerRegistrationRequest=vm.customerDetails.customer;
				 setUserNameAsCustomerName();
				 hideNameDisplayDetailsForSoleProp(vm.customerRegistrationRequest);
             	vm.theme = 'dark';
             	
             }
        	else{
        		vm.customerRegistrationRequest =  angular.copy(angular.fromJson($rootScope.payload));
        		
        	}
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
        		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
        			vm.customerRegistrationRequest.customerPanImage = result;
        		})
        	}
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            	vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
            }
        	vm.showButton=false;
        	vm.showResubmitButton=false;
        	vm.showDiscardButton = true;
        }
        
        else if(workflowSharedService.viewFrom == 'customerregistrationtodolist_resubmit') {
        	if(mode=='customerInfoChangeStatus'){
             	vm.bankInfo=false;
             	vm.defaultUserInfo=false;
				 vm.authPersonInfo = false;
             	vm.customerDetails=angular.copy(angular.fromJson($rootScope.payload)); 
             	vm.customerRegistrationRequest=vm.customerDetails.customer;
				 setUserNameAsCustomerName();
				 hideNameDisplayDetailsForSoleProp(vm.customerRegistrationRequest);
             	vm.theme = 'dark';
             	vm.enableFieldsForResubmit = enableField;
             	if(vm.enableFieldsForResubmit ){
             		
             		$('#panImageFile').val(null);
        			vm.image = null;
        			fd = new FormData();
        			customerPreviousData = angular.copy(vm.customerDetails.customer);
        			
             	}
             	
             	
             }
        	else{
        		vm.customerRegistrationRequest =  angular.copy(angular.fromJson($rootScope.payload));
        		
        	}
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
        		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
        			vm.customerRegistrationRequest.customerPanImage = result;
        		})
        	}
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            	vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
            }
        	vm.showButton=false;
        	vm.showResubmitButton=true;
        	vm.showDiscardButton = false;
        }
        
        	if(workflowSharedService.viewFrom == 'customerregistrationtodolist_auditlog') {
        	
        		if(mode=='customerInfoChangeStatus' || mode=='InfoAuditlog'){
                 	vm.bankInfo=false;
                 	vm.defaultUserInfo=false;
					 vm.authPersonInfo = false;
                 	vm.customerDetails=angular.copy(angular.fromJson($rootScope.payload)); 
                 	vm.customerRegistrationRequest=vm.customerDetails.customer;
					 setUserNameAsCustomerName();
					 hideNameDisplayDetailsForSoleProp(vm.customerRegistrationRequest);
                 	vm.theme='dark';
                 }
            	else{
            		vm.customerRegistrationRequest =  angular.copy(angular.fromJson($rootScope.payload));
					
					vm.disableMarkup = true;
					if(vm.customerRegistrationRequest.markupSettings!=null){
						vm.showMarkup=true; 
						setMarkupTypeAndRoedMethod();
					}   
					
            	}
        	
        		if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
            		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
            			vm.customerRegistrationRequest.customerPanImage = result;
            		})
            	}
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            	vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
            }
        	vm.showButton=false;
        	vm.showResubmitButton=false;
        	
        }
        	if(workflowSharedService.viewFrom == 'customerregistrationtodolist_historyReport') {
            	
        		if(mode=='customerInfoChangeStatus' || mode=='InfoHistoryReport'){
                 	vm.bankInfo=false;
                 	vm.defaultUserInfo=false;
					 vm.authPersonInfo = false;
                 	vm.customerDetails=angular.copy(angular.fromJson($rootScope.payload)); 
                 	vm.customerRegistrationRequest=vm.customerDetails.customer;
					 setUserNameAsCustomerName();
					 hideNameDisplayDetailsForSoleProp(vm.customerRegistrationRequest);
                 	vm.theme='dark';
                 }
            	else{
            		vm.customerRegistrationRequest =  angular.copy(angular.fromJson($rootScope.payload));
            		
					vm.disableMarkup = true;   
					if(vm.customerRegistrationRequest.markupSettings!=null){
						vm.showMarkup=true; 
						setMarkupTypeAndRoedMethod();
					}   
            	}
        	
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
            		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
            			vm.customerRegistrationRequest.customerPanImage = result;
            		})
            }
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            	vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
            }
        	vm.showButton=false;
        	vm.showResubmitButton=false;
        	
        }else if(workflowSharedService.viewFrom == 'modifycustomerdetails_checker') {
             	
        		vm.bankInfo=true;
                vm.defaultUserInfo=true;
                vm.customerReport=angular.copy(angular.fromJson($rootScope.payload));
            	//Added for Home branch and account number update CR 27-OCT-2021
                vm.oldTradingBranch =  angular.copy(vm.customerReport.oldTradingBranch);
                vm.oldHomeBranchDetails =  angular.copy(vm.customerReport.oldHomeBranchDetails);
                vm.oldBankAccountNumber = 	 angular.copy(vm.customerReport.oldBankAccountNumber);			
                CustomerTradingBankDetails.getCustomerRegistrationRequestFromPayload( vm.customerReport, function(result){
                	vm.customerRegistrationRequest = angular.fromJson(result.data);
                	if(vm.customerReport.customer.customerCode!=null && vm.customerReport.customer.customerCode!=undefined){
                 		CustomerRegistration.getPANImage({customerCode: vm.customerReport.customer.customerCode}, function(result){
                 			vm.customerRegistrationRequest.customerPanImage = result;
                 		})
                 	}
                 	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
                    vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
                    if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                  			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
            			//vm.printArray.push(vm.customerRegistrationRequest);
                    }
                 });
                vm.theme = 'dark';
                vm.termsAndConditionsApproval = true;
                vm.showPrint = true;
             	
             	vm.showButton=true;
             	vm.showResubmitButton=false;
             	vm.showDiscardButton = false;
             	vm.isModifyScreen = true;
             	vm.ExpandOrCollapseCustomerInfo=false;
	            vm.ExpandOrCollapseBankRelationship=true;
	            vm.ExpandOrCollapseUserInfo=false;
             	
           } else if(workflowSharedService.viewFrom == 'modifycustomerdetails_maker') {
             	
                  	vm.bankInfo=true;
                  	vm.defaultUserInfo=true;
                  	vm.theme = 'dark';                 	
                  	 vm.customerReport=angular.copy(angular.fromJson($rootScope.payload));
                 	//Added for Home branch and account number update CR 27-OCT-2021
                  	vm.oldTradingBranch =  angular.copy(vm.customerReport.oldTradingBranch);
                     vm.oldHomeBranchDetails =  angular.copy(vm.customerReport.oldHomeBranchDetails);
                     vm.oldBankAccountNumber = 	 angular.copy(vm.customerReport.oldBankAccountNumber);			
                     CustomerTradingBankDetails.getCustomerRegistrationRequestFromPayload( vm.customerReport, function(result){
                     	vm.customerRegistrationRequest = angular.fromJson(result.data);
                     	if(vm.customerReport.customer.customerCode!=null && vm.customerReport.customer.customerCode!=undefined){
                     		CustomerRegistration.getPANImage({customerCode: vm.customerReport.customer.customerCode}, function(result){
                     			vm.customerRegistrationRequest.customerPanImage = result;
                     		})
                     	}
                      	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
                         vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
                         if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                       			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
                 			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
                 			//vm.printArray.push(vm.customerRegistrationRequest);
                         }
                      });
	             	vm.showButton=false;
	             	vm.showResubmitButton=false;
	             	vm.showDiscardButton = true;
	             	vm.isModifyScreen = true;
	             	vm.ExpandOrCollapseCustomerInfo=false;
		            vm.ExpandOrCollapseBankRelationship=true;
		            vm.ExpandOrCollapseUserInfo=false;
             }
             
             else if(workflowSharedService.viewFrom == 'modifycustomerdetails_resubmit') {
             	                 	
             		vm.bankInfo=true;             	
                  	vm.defaultUserInfo=true;
                  	vm.isEdit = true;
                  	vm.theme = 'dark';
                  	vm.enableFieldsForResubmit = false;
                  	vm.customerReport=angular.copy(angular.fromJson($rootScope.payload));
                  	vm.oldTradingBranch = vm.customerReport.oldTradingBranch;
                	//Added for Home branch and account number update CR 27-OCT-2021
                    vm.oldHomeBranchDetails =  angular.copy(vm.customerReport.oldHomeBranchDetails);
                    vm.oldBankAccountNumber = 	 angular.copy(vm.customerReport.oldBankAccountNumber);	
    	       	 	vm.filterText = vm.customerReport.customerTradingBankDetails.bank.ccpMembershipId;
                    CustomerTradingBankDetails.getCustomerRegistrationRequestFromPayload( vm.customerReport, function(result){
                     	vm.customerRegistrationRequest = angular.fromJson(result.data);
                     	if(vm.customerReport.customer.customerCode!=null && vm.customerReport.customer.customerCode!=undefined){
                     		CustomerRegistration.getPANImage({customerCode: vm.customerReport.customer.customerCode}, function(result){
                     			vm.customerRegistrationRequest.customerPanImage = result;
                     		})
                     	}
                      	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
                         vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
                         if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                       			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
                 			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
                 			//vm.printArray.push(vm.customerRegistrationRequest);
                         }
                      });
                  	if(vm.enableFieldsForResubmit ){
                  		
                  		$('#panImageFile').val(null);
             			vm.image = null;
             			fd = new FormData();
             			//customerPreviousData = angular.copy(vm.customerDetails.customer);
             			
                  	}
             		
	             	vm.showButton=false;
	             	vm.showResubmitButton=true;
	             	vm.showDiscardButton = false;
	             	vm.isModifyScreen = true;
	             	vm.termsAndConditionsApproval = false;
	             	vm.ExpandOrCollapseCustomerInfo=false;
		            vm.ExpandOrCollapseBankRelationship=true;
		            vm.ExpandOrCollapseUserInfo=false;
             }
           
             if(workflowSharedService.viewFrom == 'modifycustomerdetails_auditlog') {
             	
            	vm.bankInfo=true;
               	vm.defaultUserInfo=true;
               	vm.isModifyScreen = true;
               	vm.theme = 'dark';
               	vm.customerReport=angular.copy(angular.fromJson($rootScope.payload));
               	//Added for Home branch and account number update CR 27-OCT-2021
               	vm.oldTradingBranch =  angular.copy(vm.customerReport.oldTradingBranch);
                vm.oldHomeBranchDetails =  angular.copy(vm.customerReport.oldHomeBranchDetails);
                vm.oldBankAccountNumber = 	 angular.copy(vm.customerReport.oldBankAccountNumber);			
                CustomerTradingBankDetails.getCustomerRegistrationRequestFromPayload( vm.customerReport, function(result){
                  	vm.customerRegistrationRequest = angular.fromJson(result.data);
                  	if(vm.customerReport.customer.customerCode!=null && vm.customerReport.customer.customerCode!=undefined){
                  		CustomerRegistration.getPANImage({customerCode: vm.customerReport.customer.customerCode}, function(result){
                  			vm.customerRegistrationRequest.customerPanImage = result;
                  		})
                 }
                vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
                      vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
                      if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                    			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
              			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
              			//vm.printArray.push(vm.customerRegistrationRequest);
                      }
                });
             	vm.showButton=false;
             	vm.showResubmitButton=false;
             	
             }
            /* if(workflowSharedService.viewFrom == 'modifycustomerdetails_historyReport') {
                 	
            	 vm.bankInfo=true;
            	 vm.defaultUserInfo=true;
            	 vm.isModifyScreen = true;
            	 vm.theme = 'dark';                 	
            	 vm.customerReport=angular.copy(angular.fromJson($rootScope.payload));
                   
                 CustomerTradingBankDetails.getCustomerRegistrationRequestFromPayload( vm.customerReport, function(result){
                   	vm.customerRegistrationRequest = angular.fromJson(result.data);
                   	if(vm.customerReport.customer.customerCode!=null && vm.customerReport.customer.customerCode!=undefined){
                   		CustomerRegistration.getPANImage({customerCode: vm.customerReport.customer.customerCode}, function(result){
                   			vm.customerRegistrationRequest.customerPanImage = result;
                   		})
                  }
                 vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
                       vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
                       if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                     			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
               			vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
               			//vm.printArray.push(vm.customerRegistrationRequest);
                       }
                 });
             	vm.showButton=false;
             	vm.showResubmitButton=false;
             	
             } */
        if(workflowSharedService.viewFrom == 'customerinfoupdateintimationview'){
        	if(mode=='customerInfoUpdationIntimationView'){
             	vm.bankInfo=false;
             	vm.defaultUserInfo=false;
				 vm.authPersonInfo = false;
             	vm.customerDetails=angular.copy(angular.fromJson($rootScope.payload)); 
             	vm.customerRegistrationRequest=vm.customerDetails.customer;
				 setUserNameAsCustomerName();
             	vm.theme = 'dark';
        	}
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
        		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
        			vm.customerRegistrationRequest.customerPanImage = result;
        		})
        	}
        	vm.customerRegistrationRequest.dobOrDoi = new Date(vm.customerRegistrationRequest.dobOrDoi);
            vm.customerRegistrationRequest.dob = new Date(vm.customerRegistrationRequest.dob);
            if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
         			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
            	vm.customerRegistrationRequest.leiExpiryDate = new Date(vm.customerRegistrationRequest.leiExpiryDate);
   			//vm.printArray.push(vm.customerRegistrationRequest);
            }
        }
		//markup hide when remove action-type
		if(vm.customerRegistrationRequest.actionType == 'REMOVE'){
			vm.showMarkup = false;
		}
       
        
        function approveCustomerRegistration() { 
			
        	if(vm.mode=='customerInfoChangeStatus'){
        		vm.screenLog.screenLog('Customer',vm.customerDetails.customer.id, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
        		CustomerApproval.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        		$uibModalInstance.dismiss('cancel');
        	}else if(vm.customerRegistrationRequest.tradingBranch!=null){
				if(vm.roEdApplicabilityMethod==null || vm.roEdApplicabilityMethod == undefined){
					AlertService.error('Please assign the RO/ED Applicability Method');
				}
				if(vm.markupType==null || vm.markupType == undefined){
					AlertService.error('Please assign the markup value');
				}
				vm.customerRegistrationRequest.markupSettings.markupType = vm.markupType.shortName;
				 vm.customerRegistrationRequest.markupSettings.roEdApplicabilityMethodId=vm.roEdApplicabilityMethod.id;
				 vm.customerRegistrationRequest.dobOrDoi=null;
        		vm.screenLog.screenLog('CustomerRegistration',vm.customerRegistrationRequest.id, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
        		CustomerApproval.approveCustomerRegistration({"taskId" : $rootScope.taskID},vm.customerRegistrationRequest,onApproveSuccess,onApproveError);         	

        		/*CustomerApproval.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     */	
        	}
        }
        function onApproveSuccess(result) {
        	if((result != null && result != undefined) && (result.errorMessage != null && result.errorMessage != undefined && result.errorMessage != "")){
				if(result.errorMessage  == 'tacViewForEntities.bank.cannotApproveCustomer'){
					vm.clicked=false;
					MessageBox.openMessageBox("Alert", $filter('translate')('error.tacViewForEntities.bank.cannotApproveCustomer'), "Ok", "bankApprovalMessage");
				}
			}else{
	        	$rootScope.removeRow($rootScope.taskID);    
	        	$uibModalInstance.dismiss('cancel');
			}

        }        
        function onApproveError(error) {
        	vm.clicked=false;
        	
        }
       
        function rejectCustomerRegistration() { Workflow.rejectCustomerApproval($rootScope.taskID); }

        

        vm.viewPanImage = function viewPanImage(){
        	
        	if(vm.customerRegistrationRequest.customerCode!=null && vm.customerRegistrationRequest.customerCode!=undefined){
        		CustomerRegistration.getPANImage({customerCode: vm.customerRegistrationRequest.customerCode}, function(result){
        			vm.customerRegistrationRequest.customerPanImage = result;
        			openPanImage();
        		})
        	}else{
        		openPanImage();
        	}
        	
        	
        }
        
        function base64ToArrayBuffer(base64) {
    	    var binary_string =  window.atob(base64);
    	    var len = binary_string.length;
    	    var bytes = new Uint8Array( len );
    	    for (var i = 0; i < len; i++)        {
    	        bytes[i] = binary_string.charCodeAt(i);
    	    }
    	    return bytes.buffer;
    	}
        
        function openPanImage(){
        	if(vm.customerRegistrationRequest.fileExtension=='pdf'){
        		var contentByteArray = base64ToArrayBuffer(vm.customerRegistrationRequest.panImage);
	    		  
	    		  var file = new Blob([contentByteArray], { type: 'application/pdf' });
	              var fileURL = URL.createObjectURL(file);
	              
	              if(window.navigator.msSaveOrOpenBlob) {
	                    window.navigator.msSaveOrOpenBlob(file, "PAN.pdf");
	              }
	              else {
	                     window.open(fileURL, "PAN.pdf");    
	              }
    			//var pdfWindow = window.open("");
        		//pdfWindow.document.write("<iframe width='100%' height='100%' title='PAN'  src='data:application/pdf;base64, " + encodeURI(vm.customerRegistrationRequest.panImage)+"' ></iframe>")
        	}
        	else if((vm.customerRegistrationRequest.customerPanImage!=null || vm.customerRegistrationRequest.customerPanImage!=undefined) && vm.customerRegistrationRequest.customerPanImage.fileExtension=='pdf' ){
        		var contentByteArray = base64ToArrayBuffer(vm.customerRegistrationRequest.customerPanImage.image);
	    		  
	    		var file = new Blob([contentByteArray], { type: 'application/pdf' });
	            var fileURL = URL.createObjectURL(file);
	              
	            if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(file, "PAN.pdf");
	            }
	            else {
	                   window.open(fileURL, "PAN.pdf");    
	            }
    			//var pdfWindow = window.open("");
        		//pdfWindow.document.write("<iframe width='100%' height='100%' title='PAN'  src='data:application/pdf;base64, " + encodeURI(vm.customerRegistrationRequest.customerPanImage.image)+"' ></iframe>")
        		
        	}
        	else{
        		
        	if(mode=='trackstatus'){
        		$state.go('view-pan-image-track-status', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});	
        	}else if(mode=='registrationrequeststatus'){
        		$state.go('view-pan-image-registration-status', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});
        	}else if(mode=='customeronboarding'){
        		$state.go('view-panImage-customer-onboarding', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});	
        	}else if(mode=='customeronboardingstatus'){
        		$state.go('view-panImage-customer-onboarding-status', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});
        	}else if(mode=='customerApprovalToDo'){
        		$state.go('view-panImage-customer-approval', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});
        	}
        	else if(mode=='customerViewReport'){
        		$state.go('view-panImage-customer-view-report', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});
        	}else if(mode=='customerInfoChangeStatus' && ( workflowSharedService.viewFrom  == 'customerregistrationtodolist_maker' || workflowSharedService.viewFrom == 'customerregistrationtodolist_resubmit' ) ){
        		$state.go('view-panImage-customer-info-status', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}else if(mode=='customerInfoChangeStatus' && workflowSharedService.viewFrom  == 'customerregistrationtodolist_checker'){
        		$state.go('view-panImage-customer-update', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}else if(mode=='auditlog'){
        		$state.go('view-panImage-audit-log', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});
        	}else if(mode=='HistoryReport'){
        		$state.go('view-panImage-history-report', {image:vm.customerRegistrationRequest.panImage,theme:vm.theme});
        	}else if(mode=='InfoAuditlog'){
        		$state.go('view-panImage-customer-info-audit-log', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}else if(mode=='InfoHistoryReport'){
        		$state.go('view-panImage-customer-info-history-report', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}else if(mode=='customerInfoUpdationIntimationView'){
        		$state.go('view-panImage-customer-info-intimation-status', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}
        	else if(mode=='modifycustomerdetails_todo'){
        		$state.go('view-panImage-modify-customer-todo', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}
        	else if(mode=='modifycustomerDetails_history'){
        		$state.go('view-panImage-modify-customer-details-history-report', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}
        	else if(mode=='modifycustomerDetails_audit'){
        		$state.go('view-panImage-modify-customer-details-audit-log', {image:vm.customerRegistrationRequest.customerPanImage.image,theme:vm.theme});
        	}
        
        }
        }
       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.withdrawRequest = function withdraw(){
        	vm.isSent = false;
        	if(vm.mode=='customeronboardingstatus'){
            	MessageBox.openMessageBox("Alert", "Do you want to withdraw your bank relationship request?", "YesNo", 'customeronboardingstatus');
        	}else if(vm.mode=='registrationrequeststatus'){
            	MessageBox.openMessageBox("Alert", "Do you want to withdraw your bank relationship request?", "YesNo", 'registrationrequeststatus');
        	}
        	//CustomerRegistration.discard({ "requestId": vm.customerRegistrationRequest.id }, onDiscardSuccess, onDiscardError);
        }
        
        $rootScope.$on('gatewayApp.messagebox.yes.customeronboardingstatus', function (event, data) {
        	if(!vm.isSent){
        		vm.isSent = true;
        		vm.openVerifyDialogue = false;
            	if(vm.customerRegistrationRequest.userMobileCountryCode==null || vm.customerRegistrationRequest.userMobileCountryCode==undefined){
            		vm.customerRegistrationRequest.userMobileCountryCode = "";
            	}
            	var mobileNo = vm.customerRegistrationRequest.userMobile;
            	var mobileCountryCode = vm.customerRegistrationRequest.userMobileCountryCode.toString() ;
            	CustomerRegistration.sendOTPToMobileAndEmail({mobile: mobileNo, mobileCountryCode : mobileCountryCode, email: vm.customerRegistrationRequest.userEmail}, onSendSuccess, onSendFailure);
        	}
        });
		
        function onSendSuccess(){
        	if(!vm.openVerifyDialogue){
        		vm.openVerifyDialogue = true;
            	if(vm.customerRegistrationRequest.userMobileCountryCode==null || vm.customerRegistrationRequest.userMobileCountryCode==undefined){
            		vm.customerRegistrationRequest.userMobileCountryCode = "";
            	}
            	var mobileNo = vm.customerRegistrationRequest.userMobile;
            	var mobileCountryCode =  vm.customerRegistrationRequest.userMobileCountryCode.toString();
            	$state.go('otp-confirmation-withdraw-request', {mobile: mobileNo, mobileCountryCode : mobileCountryCode, 
                    requestId: vm.customerRegistrationRequest.requestReferenceNumber, email: vm.customerRegistrationRequest.userEmail, theme: 'light'});
        	}
        }
        
        function onSendFailure(){
        	//do nothing
        }
        
        $rootScope.$on('gatewayApp.messagebox.yes.registrationrequeststatus', function (event, data) {
        	if(!vm.isSent){
        		vm.isSent = true;
        		vm.openVerifyDialogue = false;
            	if(vm.customerRegistrationRequest.userMobileCountryCode==null || vm.customerRegistrationRequest.userMobileCountryCode==undefined){
            		vm.customerRegistrationRequest.userMobileCountryCode = "";
            	}
            	var mobileNo = vm.customerRegistrationRequest.userMobile;
            	var mobileCountryCode =  vm.customerRegistrationRequest.userMobileCountryCode.toString();
            	CustomerRegistration.sendOTPToMobileAndEmail({mobile: mobileNo, mobileCountryCode : mobileCountryCode, email: vm.customerRegistrationRequest.userEmail}, onSendOtpSuccess, onSendOtpFailure);
        	}
        });
		
        function onSendOtpSuccess(){
        	if(!vm.openVerifyDialogue){
        		vm.openVerifyDialogue = true;
            	if(vm.customerRegistrationRequest.userMobileCountryCode==null || vm.customerRegistrationRequest.userMobileCountryCode==undefined){
            		vm.customerRegistrationRequest.userMobileCountryCode = "";
            	}
            	var mobileNo =  vm.customerRegistrationRequest.userMobile;
            	var mobileCountryCode = vm.customerRegistrationRequest.userMobileCountryCode.toString();
            	$state.go('otp-confirmation-withdraw-reg-request', {mobile: mobileNo, mobileCountryCode : mobileCountryCode, 
                    requestId: vm.customerRegistrationRequest.requestReferenceNumber, email: vm.customerRegistrationRequest.userEmail, theme: 'light'});
        	}
        }
        
        function onSendOtpFailure(){
        	//do nothing
        }
        
		$rootScope.$on('gatewayApp.messagebox.no.customeronboardingstatus', function (event, data) {
			//do nothing
			vm.clicked=false;
			
    	});
        
		$rootScope.$on('gatewayApp.messagebox.no.registrationrequeststatus', function (event, data) {
			//do nothing
			vm.clicked=false;
    	});
		
		$rootScope.$on('gatewayApp.messagebox.clear.customeronboardingstatus', function (event, data) {
			//do nothing
			vm.clicked=false;
			
    	});
        
		$rootScope.$on('gatewayApp.messagebox.clear.registrationrequeststatus', function (event, data) {
			//do nothing
			vm.clicked=false;
    	});

		
        
        function onDiscardSuccess(){
        	$uibModalInstance.dismiss('cancel'); 
        }
        
        function onDiscardError(){
        	 $uibModalInstance.dismiss('cancel');
        }
        function perpareDataToBeSaved(){
        	fd = new FormData();
			 if(vm.customerDetails.isSuspendableAction){
				 //doNothing
			 }else if(vm.customerDetails.isApprovalRequired){
				 CustomerInfoUtil.checkForNameChangeWrokFlow(customerPreviousData ,  vm.customerDetails);
				 if(!vm.customerDetails.isSuspendableAction){
					 vm.customerDetails.isApprovalRequired = true;
				 }
			 }
			
			 
			 if( vm.image === undefined || vm.image == ""){
				 vm.image = null;
			 }
			 fd.append('pan', vm.image);
			 fd.append('customer', JSON.stringify( vm.customerDetails));
			 fd.append('taskId' , $rootScope.taskID);
		 }
		
        function resubmitCustomerRegistration() {
        	//vm.isupdated = false;
			setCustomerNameAsUserName();
        	ScreenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.CUSTOMER, vm.customerDetails.customer.id==null?0:vm.customerDetails.customer.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	vm.customerDetails.customer.dobOrDoi = DateUtils.convertLocalDateToServer(vm.customerDetails.customer.dobOrDoi);
        	if( vm.customerDetails.customer.leiExpiryDate !=null && vm.customerDetails.customer.leiExpiryDate!=undefined 
         			 && vm.customerDetails.customer.leiExpiryDate!= ''){
        		vm.customerDetails.customer.leiExpiryDate = DateUtils.convertLocalDateToServer(vm.customerDetails.customer.leiExpiryDate);
        	}
        	if(vm.enableFieldsForResubmit){
        		var previousFieldArray = [];
        		previousFieldArray = angular.copy(vm.customerDetails.fieldCheckArray);
        		CustomerInfoUtil.isCustomerProfileFormValuesChanged(customerPreviousData,vm.customerDetails,vm.image );
        		angular.forEach(previousFieldArray, function(field) {
                	if(vm.customerDetails.fieldCheckArray.indexOf(field)==-1){
                		vm.customerDetails.fieldCheckArray.push(field);
                	}
                });
        		perpareDataToBeSaved();
        		if(vm.customerDetails.isSuspendableAction==true){
					vm.isupdated = false;
					confirmFromUserForAction();
					
				 }else{
					 CustomerApproval.resubmitUpdatedCustomerInfo(fd, onResubmitSuccess, onResubmitError);
					 $uibModalInstance.dismiss('cancel');
				 }
        		
        	}
        	else{
        		CustomerApproval.resubmitCustomerInfo({"taskId" : $rootScope.taskID}, vm.customerDetails, onResubmitSuccess, onResubmitError);
        		$uibModalInstance.dismiss('cancel');
        	}
        
        	 
        	 
        	
        }    

        
        function confirmFromUserForAction(){
        	
        	MessageBox.openMessageBox("Alert", $filter('translate')('gatewayApp.customerInfo.suspendAlert'), "YesNo", "customerprofileresubmit");
        	$rootScope.$on('gatewayApp.messagebox.yes.customerprofileresubmit', function (event, data) {
    	 		if(fd!=null && !vm.isupdated){
    	 			vm.isupdated = true;
    	 			CustomerApproval.resubmitUpdatedCustomerInfo(fd, onResubmitSuccess, onResubmitError);
    	 			$uibModalInstance.dismiss('cancel');
    	 		}	 		

            });
    		
    		$rootScope.$on('gatewayApp.messagebox.no.customerprofileresubmit', function (event, data) {
    				
    				vm.isupdated = true;
    		});
        }
	 	
        
        function onResubmitSuccess(result) {
        	fd=null;
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked=false;
        }

        function discardCustomerUpdate() {
        	
        	vm.discardProcessed =false;
        	//vm.customerDetails.customer.dobOrDoi = DateUtils.convertLocalDateToServer(vm.customerDetails.customer.dobOrDoi);
        	ScreenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.CUSTOMER, vm.customerDetails.customer.id==null?0:vm.customerDetails.customer.id, entityConstants.ENTITY_ACTION_TYPE.DISCARD);
        	MessageBox.openMessageBox("Alert", "Do you want to withdraw your customer info change?", "ProceedCancel", "withdrawcustomerchange_regview");        		 
        	 
        	
        }
        $rootScope.$on('gatewayApp.messagebox.proceed.withdrawcustomerchange_regview', function (event, data) {
        	
        	if(!vm.discardProcessed){
        		
        		vm.discardProcessed = true;
        		CustomerInfoChange.discard({"taskId" : $rootScope.taskID},  onDiscardSuccess, onDiscardError);
        		
        		
        	}

        });
		
		$rootScope.$on('gatewayApp.messagebox.cancel.withdrawcustomerchange_regview', function (event, data) {			
				vm.discardProcessed = true;
			
    	});
        
		var subscribeWithdrawOTPConfirm = $rootScope.$on('withdrawOTPConfirm', function(event, result) {
        	vm.requestId= $rootScope.requestId;
        	CustomerRegistration.discard({ "requestId": vm.customerRegistrationRequest.id }, onWithdrawnSuccess, onWithdrawnError);
        });
		
        $scope.$on('$destroy', function() {
            subscribeWithdrawOTPConfirm();                
        });
        
		function onWithdrawnSuccess(){
			MessageBox.openMessageBox("Alert", "The request ref #" + vm.requestId + " has been withdrawn", "Ok", "withdrawnrequest");
		}
		
		function onWithdrawnError(){
			
		}
		
		$rootScope.$on('gatewayApp.messagebox.ok.withdrawnrequest', function (event, data) {
			$uibModalInstance.dismiss('cancel');
    	});

		function checkforModification(fieldName){
			if( (mode=='customerInfoChangeStatus' || mode=='customerInfoUpdationIntimationView')  && vm.customerDetails!=undefined && vm.customerDetails!=null) {
				var fieldCheckArray = vm.customerDetails.fieldCheckArray;
				
				if(fieldCheckArray!=undefined && fieldCheckArray!=null  && fieldCheckArray.indexOf(fieldName)>=0){
					return true;
				}else{
					return false;
				}
				 
			}
			else{
				return false;
			}
				
		}
		$scope.$watch('vm.image', function(value){
        	if(value!= undefined && value!=null){
        		if(value.size > 200000){
        			vm.invalidPanImageSize = true;
        			angular.element("input[type='file']").val(null);
        			vm.image = null;
        		}else{
        			vm.inputPanImage=vm.image.name;
        			vm.invalidPanImageSize = false;
        		}
        	}
        });
		
		// resubmit action customer email and primary mobile change
		vm.openChangeEmailForCustomer = function openChangeEmail(){
			console.log(vm.globalValues.getOrganisation().id);
			$state.go('change-email-customer-info-resubmit', {userId: vm.globalValues.getUserId(),email: vm.customerDetails.customer.primaryEmail, mode: 'customerinfo',customerId: vm.globalValues.getOrganisation().id});
		}
		
		vm.openChangeMobileForCustomer = function openChangeMobile(){
			$state.go('change-mobile-customer-info-resubmit', {userId: vm.globalValues.getUserId(),mobile: vm.customerDetails.customer.primaryMobile,countryCode: vm.customerDetails.customer.primaryMobileCountryCode ,  mode: 'customerinfo',customerId: vm.globalValues.getOrganisation().id});
		}
		$rootScope.$on('changeEmailRefresh', function(event, result) {
			if(vm.enableFieldsForResubmit)
				vm.customerDetails.customer.primaryEmail = $rootScope.email;
		});

		$rootScope.$on('changeMobileRefresh', function(event, result) {
			if(vm.enableFieldsForResubmit)
				vm.customerDetails.customer.primaryMobile = $rootScope.mobile;
		});
		
		vm.print = print;
		/*function print(){
			 var printContents = document.getElementById("printPage").innerHTML;
			 var popupWin = window.open('', '_blank', 'fullscreen=yes');
       	  	popupWin.document.open();
       	  	var value = '<html><head><link rel="stylesheet" type="text/css" href="style.css" /><style type="text/css" media="print"> @page { size: landscape;}</style></head><body onload="window.print()"  >' + printContents + '</body></html>';
       	  	console.log(value);
       	  	popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /><style type="text/css" media="print"> @page { size: landscape;}</style></head><body onload="window.print()"  >' + printContents + '</body></html>');
       	  	popupWin.document.close();
		}*/
		function print(){
			var customerRegDetails = angular.copy(vm.customerRegistrationRequest);
			customerRegDetails.dob = DateUtils.convertLocalDateToServer(customerRegDetails.dob);
			customerRegDetails.dobOrDoi = DateUtils.convertLocalDateToServer(customerRegDetails.dobOrDoi);
			if( vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display 
                            && customerRegDetails.leiExpiryDate !=null && customerRegDetails.leiExpiryDate!=undefined 
	       			 && customerRegDetails.leiExpiryDate!= ''){
				customerRegDetails.leiExpiryDate = DateUtils.convertLocalDateToServer(customerRegDetails.leiExpiryDate);
			}
			
			if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display && customerRegDetails.companyName!=null && customerRegDetails.companyName!=''){
				customerRegDetails.customerFirstName=customerRegDetails.companyName;
				customerRegDetails.customerLastName=customerRegDetails.companyName;
			}
       	  	
       	 	CustomerRegistration.getCustomerRegistrationPrintForBankApproval(customerRegDetails,function(result){
	        	var file = new Blob([result.data], {type: 'application/pdf'});
	      		var fileURL = URL.createObjectURL(file);
                //window.open(fileURL, "_blank", "fullscreen=yes");
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "CustomerRegistrationPreview.pdf");
               }
               else{
                    window.open(fileURL, "CustomerRegistrationPreview.pdf");    
               }
       	 	});
		}

		function UpdateBankAndDefaultUserDetails(){
	    	var bankAndDefaultUserDetailsObj = angular.copy(vm.bankAndDefaultUserDetails);
	    	vm.customerRegistrationRequest.bank = bankAndDefaultUserDetailsObj.bank;
	    	vm.customerRegistrationRequest.homeBranch = bankAndDefaultUserDetailsObj.homeBranch;
		    vm.customerRegistrationRequest.tradingBranch = bankAndDefaultUserDetailsObj.tradingBranch;
	    	vm.customerRegistrationRequest.bankAccountNumber = bankAndDefaultUserDetailsObj.bankAccountNumber;
	    	vm.customerRegistrationRequest.branchAddress = bankAndDefaultUserDetailsObj.branchAddress;
	    	vm.customerRegistrationRequest.userFirstName = bankAndDefaultUserDetailsObj.userFirstName;
	    	vm.customerRegistrationRequest.userMiddleName = bankAndDefaultUserDetailsObj.userMiddleName;
	    	vm.customerRegistrationRequest.userLastName = bankAndDefaultUserDetailsObj.userLastName;
	    	vm.customerRegistrationRequest.aadharNumber = bankAndDefaultUserDetailsObj.aadharNumber;
	    	vm.customerRegistrationRequest.gender = bankAndDefaultUserDetailsObj.gender;
	    	vm.customerRegistrationRequest.dob = new Date(bankAndDefaultUserDetailsObj.dob);
	    	vm.customerRegistrationRequest.userAddress1 = bankAndDefaultUserDetailsObj.userAddress1;
	    	vm.customerRegistrationRequest.userAddress2 = bankAndDefaultUserDetailsObj.userAddress2;
	    	vm.customerRegistrationRequest.userCity = bankAndDefaultUserDetailsObj.userCity;
	    	vm.customerRegistrationRequest.userState = bankAndDefaultUserDetailsObj.userState;
	    	vm.customerRegistrationRequest.userCountry = bankAndDefaultUserDetailsObj.userCountry;
	    	vm.customerRegistrationRequest.userPincode = bankAndDefaultUserDetailsObj.userPincode;
	    	vm.customerRegistrationRequest.login = bankAndDefaultUserDetailsObj.login;
	    	vm.customerRegistrationRequest.userEmail = bankAndDefaultUserDetailsObj.userEmail;
	    	vm.customerRegistrationRequest.userMobileCountryCode = bankAndDefaultUserDetailsObj.userMobileCountryCode;
	    	vm.customerRegistrationRequest.userMobile = bankAndDefaultUserDetailsObj.userMobile;
	    	vm.customerRegistrationRequest.active = bankAndDefaultUserDetailsObj.active;
	    	vm.customerRegistrationRequest.panImage = bankAndDefaultUserDetailsObj.panImage;
	    }
		  $scope.$watch('vm.customerRegistrationRequest.tradingBranch', function(value){
		        if(value!=null && value!=undefined && vm.isEdit==true ){
		            if(vm.oldTradingBranch != undefined && vm.oldTradingBranch != null && vm.customerRegistrationRequest.tradingBranch != null
		            		&&  vm.customerRegistrationRequest.tradingBranch !=undefined  && vm.oldTradingBranch.id == vm.customerRegistrationRequest.tradingBranch.id){
		                vm.noChange = true;		               
		            }else{
		                vm.noChange = false;
		            }
		        }
		    });
		  $scope.$watch('vm.customerRegistrationRequest.homeBranch', function(value){
		        if(value!=null && value!=undefined && vm.isEdit==true ){
		            if( vm.oldHomeBranchDetails != null && vm.oldHomeBranchDetails != undefined 
		            		&& vm.customerRegistrationRequest.homeBranch != null && vm.customerRegistrationRequest.homeBranch != undefined 
		            		&& vm.oldHomeBranchDetails.id == vm.customerRegistrationRequest.homeBranch.id){
		                vm.noHomeBranchChange = true;		               
		            }else{
		                vm.noHomeBranchChange = false;
		            }
		        }
		    });
		  $scope.$watch('vm.customerRegistrationRequest.bankAccountNumber', function(value){
		        if(value!=null && value!=undefined && vm.isEdit==true ){
		            if( vm.oldBankAccountNumber !=null && vm.oldBankAccountNumber!=undefined && vm.oldBankAccountNumber == vm.customerRegistrationRequest.bankAccountNumber){
		                vm.noAccountChange = true;		               
		            }else{
		                vm.noAccountChange = false;
		            }
		        }
		    });
		    
		    function saveModificationOnCustomerDetails(){   	
		    	
		    	if( vm.noChange &&  vm.noHomeBranchChange && vm.noAccountChange ){
		    		//show error when trading branch selected is same as the current branch
		    		 AlertService.error("No changes are made");
		    		 vm.clicked = false;
		    	}else{
		    		ModifyCustomerDetailsService.editTradingBranch({orgId: vm.globalValues.getOrganisation().id, oldBranchId: vm.oldTradingBranch.id } 
		    		,vm.customerRegistrationRequest, editTradingBranchSuccess,editTradingBranchFailed);
		    	}
		    }
			function editTradingBranchSuccess(result){
				
				 $uibModalInstance.close(result);  
			}
			function editTradingBranchFailed(){
				vm.clicked = false;
			}
			function discardModifyCustomerDetails(){
				ModifyCustomerDetailsService.discard({"taskId" : $rootScope.taskID},  onDiscardSuccess, onDiscardError);
			}
			function rejectCustomerModification() { Workflow.rejectTask($rootScope.taskID); }
			function approveModifyCustomerDetails() { 
				vm.screenLog.screenLog('ModifyCustomerDetails',0, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
				ModifyCustomerDetailsService.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
            	$uibModalInstance.dismiss('cancel'); 
        	
			}
			function resubmitModifyCustomerDetails(){
				if( vm.noChange &&  vm.noHomeBranchChange && vm.noAccountChange ){
		    		//show error when trading branch selected is same as the current branch
		    		 AlertService.error("No changes are made");
		    		 vm.clicked = false;
		    	}else{
		    		ModifyCustomerDetailsService.resubmit({"taskId" : $rootScope.taskID, "oldBranchId": vm.oldTradingBranch.id},vm.customerRegistrationRequest, onResubmitSuccess, onResubmitError);
		    		$uibModalInstance.dismiss('cancel');
		    	}
			}

		$scope.$watch('vm.customerRegistrationRequest.assignTradingBranch', function(value){
	    	  if(value!=null && value!=undefined){
	    		  if(value){
	    			  if(vm.customerRegistrationRequest.tradingBranch!=null && vm.mode!='customeronboarding'){
	    				  vm.customerRegistrationRequest.tradingBranch = null;
	    			  }
	    			  vm.disableTradingBranch = true;
	    		  }else{
	    			  vm.disableTradingBranch = false;
	    		  }
	    	  }
	    })

        function viewAcceptedTermsAndConditions(){
            TACViewForEntitiesService.getAnnexure2(vm.customerRegistrationRequest, onGetAnnexure2Success, onGetAnnexure2Failure);
        }

        function onGetAnnexure2Success(result){
            TacViewForEntitiesUtility.downloadTACForArray(result.data,  tacAnnexurefilename+ moment().format( 'DD_MMM_YYYY_HH_mm')+'.pdf' );
            // var file = new Blob([result.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // //window.open(fileURL, "_blank", "fullscreen=yes");
            // if(window.navigator.msSaveOrOpenBlob) {
            //     window.navigator.msSaveOrOpenBlob(file, "Annexure2.pdf");
            // }
            // else{
            //     //window.open(fileURL, "Annexure2.pdf");    
            //     var link = document.createElement("a");   
            //     link.href =  fileURL;      
            //     link.download ="Annexure2.pdf";
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);
            // }
        }

        function onGetAnnexure2Failure(){
            //do nothing
        }

        function downloadAnnexure1(){
            TACViewForEntitiesService.getAnnexure1(vm.customerRegistrationRequest, onGetAnnexure1Success, onGetAnnexure1Failure);
        }

        function onGetAnnexure1Success(result){
            TacViewForEntitiesUtility.downloadTACForArray(result.data, tacAnnexurefilename + moment().format( 'DD_MMM_YYYY_HH_mm')+'.pdf'  );
			vm.clicked=false;
            // var file = new Blob([result.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // //window.open(fileURL, "_blank", "fullscreen=yes");
            // if(window.navigator.msSaveOrOpenBlob) {
            //     window.navigator.msSaveOrOpenBlob(file, "Annexure1.pdf");
            // }
            // else{
            //     //window.open(fileURL, "Annexure2.pdf");    
            //     var link = document.createElement("a");   
            //     link.href =  fileURL;      
            //     link.download ="Annexure1.pdf";
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);
            // }
        }

        function onGetAnnexure1Failure(){
			vm.clicked=false;
            //do nothing
        }

        $scope.$watch('vm.customerRegistrationRequest.customerType', function(value){
            if(value!=null && value!=undefined){
                vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
                    return item.customerType.id == value.id;
                });
				if(value.customerUserType.shortName=='SingleUserCustomer'){
					vm.showSingleUser=true;
					vm.showMultiUser=false;
						}else if(value.customerUserType.shortName=='MultiUserCustomer'){
							vm.showMultiUser=true;
							vm.showSingleUser=false;
					
						}
                if(workflowSharedService.viewFrom == 'customerregistrationtodolist_auditlog' 
                    || workflowSharedService.viewFrom == 'customerregistrationtodolist_historyReport'
                    || workflowSharedService.viewFrom == 'modifycustomerdetails_auditlog'){
                    if(vm.customerRegistrationRequest.customerType.id == $scope.customerTypeSolePropId && vm.customerRegistrationRequest.customerLastName!=null && vm.customerRegistrationRequest.customerLastName!=""){
                        vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display = false;
                        vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].isMandatory = false;
                        vm.inputDetailsForSelectedCustType[$scope.firstNameIndex].display = true;
                        vm.inputDetailsForSelectedCustType[$scope.firstNameIndex].isMandatory = true;
                        vm.inputDetailsForSelectedCustType[$scope.lastNameIndex].display = true;
                        vm.inputDetailsForSelectedCustType[$scope.lastNameIndex].isMandatory = true;
                        vm.inputDetailsForSelectedCustType[$scope.middleNameIndex].display = true;
                    }
                }
            }else{
				vm.showSingleUser=true;
				vm.showMultiUser=false;
                vm.inputDetailsForSelectedCustType = [
                    {customerType: null, fieldName: 'FirstName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'MiddleName', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LastName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'EntityName', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'PANNo', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'PANImage', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'LegalEntityIdentifier', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LEIExpiryDate', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'CIN', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'GSTIN', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'StateCode', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'DOBDOI', display: true, isMandatory: true}
                ]
            }
        });
        
        $scope.$watchGroup(['vm.customerRegistrationRequest.customerFirstName', 'vm.enableFieldsForResubmit'], function(value){
            if(vm.enableFieldsForResubmit){
                var newVal = vm.customerRegistrationRequest.customerFirstName;
                if(newVal!=null && newVal!=undefined)
                {
                    newVal = newVal.replace(/(\r\n|\n|\r)/gm," ");
                    vm.customerRegistrationRequest.customerFirstName = newVal.replace(/(,)/gm,"");
                    
                }
            }
        });
        
        vm.checkforTradingBranchModification =  function checkforTradingBranchModification(){
			if(  vm.mode=='customerApprovalToDo'   && vm.userEntityOwnerType=='Bank') {
				return true;
			}else if( vm.mode=='modifycustomerdetails_todo'  && vm.userEntityOwnerType=='Bank'){
				//if old and new home branch id is different then show as modified
				if(vm.oldTradingBranch!=null && vm.oldTradingBranch != undefined 
						&& vm.customerRegistrationRequest.tradingBranch != undefined && vm.customerRegistrationRequest.tradingBranch != null 
						&& vm.customerRegistrationRequest.tradingBranch.id != vm.oldTradingBranch.id)
				 return true;
			}
			return false;
		};
        vm.checkforHomeBranchModification =  function checkforHomeBranchModification(){
			if(  vm.mode=='modifycustomerdetails_todo' && vm.userEntityOwnerType=='Bank') {
				//if old and new home branch id is different then show as modified
				if(vm.oldHomeBranchDetails!=null && vm.oldHomeBranchDetails!=undefined
						&& vm.customerRegistrationRequest.homeBranch!=null && vm.customerRegistrationRequest.homeBranch!=undefined
						 && vm.customerRegistrationRequest.homeBranch.id != vm.oldHomeBranchDetails.id)
				 return true;
			}
			return false;
		};
        vm.checkforAccountModification =  function checkforAccountModification(){
			if(  vm.mode=='modifycustomerdetails_todo' && vm.userEntityOwnerType=='Bank') {
				//if old and new home branch id is different then show as modified
				if(vm.oldBankAccountNumber!=null && vm.oldBankAccountNumber!=undefined 
						&& vm.customerRegistrationRequest.bankAccountNumber != null && vm.customerRegistrationRequest.bankAccountNumber != undefined
						&& vm.customerRegistrationRequest.bankAccountNumber != vm.oldBankAccountNumber	)
				 return true;
			}
			return false;
		};
        

    }
	
})();
