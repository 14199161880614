(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('HistoryReportController', HistoryReportController);

    HistoryReportController.$inject = ['$scope', '$state', 'workflowSharedService','$rootScope','MessageBox','HistoryReport', 'GlobalValues','EntityPermissions','$filter', 'entity','DateUtils','ScreenLog','entityConstants','AlertService'];

    function HistoryReportController($scope, $state, workflowSharedService, $rootScope, MessageBox, HistoryReport, GlobalValues, EntityPermissions,$filter, entity,DateUtils,ScreenLog,entityConstants,AlertService) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.getHistoryReport=getHistoryReport;
        vm.todayDate=new Date();
        vm.Values=Values;
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.userListValue={};
        vm.ExpandOrCollapseHistoryReport = true;
        vm.screenLog=ScreenLog;
        logScreenAccess();
        vm.valid=false;
		vm.clicked=false;
        vm.historicalOrCurrent = 'Current';

		vm.reportDate  = DateUtils.convertDateTimeFromServer(vm.todayDate);
		vm.yesterdayDate = angular.copy(vm.reportDate);
		vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
		vm.fromDate = angular.copy(vm.reportDate);
		vm.fromDate.setDate(vm.fromDate.getDate() -1);
	    vm.toDate = angular.copy(vm.reportDate);
	    vm.toDate.setDate(vm.toDate.getDate() -1);
	    vm.orgId = null;
  	    /*
         * Get details for history report given login name, screen, from and to date
         */
        function getHistoryReport()
        {   
        	vm.historyReportDetails = [];
        	setOrganisationIdForSlectedEntity();
        	if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.HistoryReport.futureFromDate'));
    			vm.valid=false;
    		}
    		else if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.toDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.HistoryReport.greaterFromDate'));
    			vm.valid=false;
    		}
    		else if (vm.historicalOrCurrent != 'Current' && moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.HistoryReport.greaterToDate'));
    			vm.valid=false;
    		}
        	
    		else{
				vm.clicked=true;
    			vm.valid=true;
    			var from=$filter('date')(vm.fromDate, "yyyy-MM-dd");
        		var to=$filter('date')(vm.toDate, "yyyy-MM-dd");
        		var entityId = 0;
        		if(vm.screenName!=undefined && vm.screenName!=null && vm.screenName!=""){
        			entityId = vm.screenName.id; 
        		}
        		if(vm.historicalOrCurrent == 'Current'){
        			from=$filter('date')(vm.todayDate, "yyyy-MM-dd");
        			to=$filter('date')(vm.todayDate, "yyyy-MM-dd");
            		HistoryReport.getHistoryReportDetails({"entityId":entityId,"login":vm.userListValue,"from":from,"to":to,"orgId":vm.orgId},onGetHistoryReportDetailsSuccess,onGetHistoryReportDetailsError);
        		}else{
            		HistoryReport.getHistoryReportDetailsFromArchive({"entityId":entityId,"login":vm.userListValue,"from":from,"to":to,"orgId":vm.orgId},onGetHistoryReportDetailsSuccess,onGetHistoryReportDetailsError);
        		}
    		}
        		
            		

        }
        
        function setOrganisationIdForSlectedEntity(){
        	if(vm.userEntityOwnerType == vm.selectedEntityOwnerType){
        		vm.orgId = vm.globalValues.getOrgId(); 
        		console.log("logged in user org id "  + vm.orgId);
        	}else{
	        	if(vm.userEntityOwnerType == 'Clearcorp'){
	        	
		        	 if( vm.selectedEntityOwnerType =='Bank' || vm.selectedEntityOwnerType == 'Branch'){
		        		vm.orgId = vm.bankValue.id; 
		        		console.log("selected bank or branch org id "  + vm.orgId);
		        	}else if( vm.selectedEntityOwnerType =='SingleUserCustomer' || vm.selectedEntityOwnerType == 'MultiUserCustomer'){
		        		vm.orgId = vm.customerValue.id; 
		        		console.log("selected customer id "  + vm.orgId);
		        	}
	        	}else if(vm.userEntityOwnerType == 'Bank'){
	        		
	        		if(vm.selectedEntityOwnerType == 'Branch'){
		        		vm.orgId = vm.globalValues.getOrgId(); 
		        		console.log("logged in user org id "  + vm.orgId);
	        		}else if( vm.selectedEntityOwnerType =='SingleUserCustomer' || vm.selectedEntityOwnerType == 'MultiUserCustomer'){
		        		vm.orgId = vm.customerValue.id; 
		        		console.log("selected customer id "  + vm.orgId);
		        	}
	        	}else if(vm.userEntityOwnerType == 'Branch'){
	        		
	        		if( vm.selectedEntityOwnerType =='SingleUserCustomer' || vm.selectedEntityOwnerType == 'MultiUserCustomer'){
		        		vm.orgId = vm.customerValue.id; 
		        		console.log("selected customer id "  + vm.orgId);
		        	}
	        	}
	        	
        	}
        }
        //vm.fromDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        //vm.toDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        function onGetHistoryReportDetailsSuccess(result)
        {
        	
        	vm.historyReportDetails=result;
			vm.clicked=false;
        	
        }
        
        
        function onGetHistoryReportDetailsError()
        {
        	vm.clicked=false;
        }
        
        /*
         * Load the screen by passing entity and payload
         */
        
        function Values(entity,payload,actionName){
            $rootScope.payload = payload;
            $rootScope.actionName = actionName;
            workflowSharedService.viewFrom = getViewFrom(entity,actionName);
            var state = getState(entity,actionName);
            $state.go(state);
            
            
        	
        }
        
        
        
        function getViewFrom(entity,actionName){
        	if (entity == 'SecurityPolicy'){
        		return 'securityPolicy_auditLog';
        	}
        	else if (entity == 'GlobalSecurityPolicy'){
        		return 'globalSecurityPolicy_auditLog';
        	}        	
        	else if (entity == 'EntityStateApprovalProcess'){
        		return 'entityActionApprovalPolicy_auditLog';
        	}
        	
        	
        	else if (entity == 'ApprovalProcess'){
        		return 'approvalPolicy_auditLog';
        	}
        	
        	
        	else if (entity == 'OrganisationRole'){
        		return 'role_auditLog';
        	}
        	
        	
        	else if (entity == 'UserRole' ){
        		return 'userroletodolist_auditLog';
        	}
        	

        	else if (entity == 'UserManagement') {
        		return 'userRole_auditLog';
        	}
        	
          	else if (entity == 'BankRegistration'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else{
        			return 'bankregistration_auditlog';
        		}
        	}
        	
        	else if (entity == 'BranchRegistration'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else{
        			return 'branch_auditLog';
        		}
        	}
        	
        	else if (entity == 'AlertConfiguration'){
        		return 'alertConfiguration_auditLog';
        	}

        	
        	
        	else if (entity == 'SMSSettings'){
        		return 'smssettings_auditlog';
        	}
        	else if(entity=='CustomerRegistration'){
        		return 'customerregistrationtodolist_historyReport';
        	}
        	else if(entity=='Customer'){
        		return 'customerregistrationtodolist_historyReport'
        	}
        	else if (entity == 'UserAdminApplicationWideSettings'){
        		return 'userAdminApplicationWideSettings_auditlog';
        	}
        	
          	else if (entity == 'DealingAdminApplicationWideSettings'){
        		return 'dealingAdminApplicationWideSettings_auditlog';
        	}
          	else if (entity == 'DefaultMarkupSettings'){
        		return 'defaultMarkup_auditlog';
        	}
        	
          	else if (entity == 'MarkupSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'markup_import_auditlog';
        		}else{
        			return 'markup_auditLog';
        		}
          	}
          	else if (entity == 'HEConnectivitySettings'){
        		return 'heConnectivitySettings_auditlog';
        	}
          	else if (entity=='IntradayMarketSessionSettings'){
        		return 'intradayMarketSessionSettings_auditlog' ;
        	}
          	else if (entity=='DefaultMarketSessionSettings'){
          		return 'defaultMarketSessionSettings_auditlog';
          	}
          	else if (entity=='EmailSettings'){
          		return 'emailSettings_auditlog' ;
          	}
          	else if (entity=='UserRestriction'){
        		return 'userRestriction_auditLog';
        	}
          	else if (entity=='SwapQuotes'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}   
        		return 'swapQuotes_auditLog';
        	}
          	else if (entity=='SpecialHoliday'){
        		return 'specialHoliday_auditLog';
        	}
          	else if (entity=='RegularHolidays'){
        		return 'regularHoliday_auditLog';
        	}
          	else if (entity=='LimitSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'  || actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'limit_import_auditlog';
        		}else{
        			return 'limitSettings_auditLog';
        		}
        		
        	}
          	else if (entity=='CustomerLimitChangeRequest'){
          		
          		if(actionName.indexOf('CREATE') >= 0 && (vm.userEntityOwnerType == 'SingleUserCustomer' || vm.userEntityOwnerType =='MultiUserCustomer') )
          			return 'create_customer_limitSettings_auditLog';
          		else
          			return 'customer_limitSettings_auditLog';
        	}
        	else if (entity=='EntityRestriction'){
        		return 'entityRestriction_auditLog';
        	}
        	else if (entity == 'AMCSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'amc_import_auditlog';
        		}else{
        			return 'amcSettings_auditlog';
        		}
          	}
        	else if (entity == 'CustomerCharges'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'customer_charges_import_auditlog';
        		}else{
        			return 'customerCharges_auditlog';
        		}
          	}
        	else if (entity == 'GSTSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'gst_settings_import_auditlog';
        		}else{
        			return 'gstSettings_auditlog';
        		}
          	}
        	else if (entity=='TransactionChargesSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else{
        			return 'transactionChargesSettings_auditlog';
        		}
        		
        	}
            else if (entity=='CreditFactorSettings'){
                return 'creditFactorSettings_auditlog';
            }
        	else if (entity=='HomeBranchIFSCUpload'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}
        		
        	}
        	else if (entity=='ModifyCustomerDetails'){
        		//modifycustomerdetails_auditlog
        		return 'modifycustomerdetails_auditlog';
        	}
        	else if (entity=='TermsAndConditionsUpload'){

        		return 'termsConditionUpload_auditlog';
        	}
		else if (entity=='TermsAndConditionsView'){
        		
        		return 'entited_tac_view_auditLog';
        	}
		else if (entity=='CustomerTermsAndConditionsUpload'){
        		return 'customerTermsConditionUploadByBank_auditlog';
        	}

		else if (entity=='BankFeesChargesUpload'){
	    		return 'bankFeesAndChargesUpload_auditlog';
	    	}
			else if (entity == 'PendingOrderReport'){
        		return 'messageuploadtodolist_auditLog';
        	}
        }
        
        function getState(entity,actionName){
        	if (entity == 'SecurityPolicy'){
        		return 'security-policy-historyReport';
        	}
        	else if (entity == 'GlobalSecurityPolicy'){
        		return 'global-security-policy-historyReport';
        	}
        	
        	
        	else if (entity == 'EntityStateApprovalProcess'){
        		return 'entity-action-approval-policy-historyReport';
        	}
        	
        	
        	else if (entity == 'ApprovalProcess' ){
        		return 'approval-policy-historyReport';
        	}
        	
        	
        	else if (entity == 'OrganisationRole'){
        		return 'manage-role-historyReport';
        	}
        	
        	
        	else if (entity == 'UserRole' ){
        		return 'manage-users-role-historyReport';
        	}
        	
        	
        	else if (entity == 'UserManagement'){
        		return 'usermanagement-historyReport';
        	}
        	
         	else if (entity == 'BankRegistration' ){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
        			return 'bank-registration-historyReport';
        		}
        	}
        	
        	else if (entity == 'BranchRegistration' ){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
        			return 'branch-historyReport';
        		}
        	}
        	
        	else if (entity == 'AlertConfiguration'){
        		return 'alert-configuration-historyReport';
        	}
        	else if (entity == 'SMSSettings' ){
        		return 'sms-settings-historyReport';
        	}
        	else if(entity=='CustomerRegistration'){
				var customerRegistration =  angular.fromJson($rootScope.payload);
				if( customerRegistration.primaryContactName != ""){
					return 'old-customer-registration-historyReport';
                    }else{
						return 'customer-registration-historyReport';
					}
        	
        	}
        	else if(entity=='Customer'){
        		return 'customer-info-status-view-historyreport';
        	}
        	else if (entity == 'DealingAdminApplicationWideSettings' ){
        		return 'dealing-admin-change-config-historyreport';
        	}
        	
         	else if (entity == 'UserAdminApplicationWideSettings' ){
        		return 'change-config-historyreport';
        	}
         	else if(entity=='CustomerSpecificSettings')
         		{
         			return 'customer-specific-settings-historyreport'
         		}
        	
         	else if (entity == 'DefaultMarkupSettings'){
         		return 'default-markup-historyReport';
         	}
         	else if (entity=='MarkupSettings'){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
        			return 'markup-historyReport';
        		}
         		return 'markup-historyReport';
         	}
         	else if(entity=='HEConnectivitySettings'){
        		return 'he-connectiviy-settings-historyReport';
        	}
         	else if(entity=='IntradayMarketSessionSettings'){
         		return 'intraday-market-settings-historyReport';
         	}
         	else if (entity=='DefaultMarketSessionSettings'){
         		return 'default-market-settings-historyReport';
         	}
         	else if (entity=='EmailSettings'){
         		return 'email-historyReport';
         	}
         	else if (entity=='UserRestriction'){
        		return 'userRestriction-historyReport';
        	}
         	else if (entity=='SwapQuotes'){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}
         		var swapQuotesForTenorDatesDTO =  angular.fromJson($rootScope.payload);
        		if(swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList == undefined ){
        			return 'old-swapQuotes-historyReport';
        		}
        		return 'swapQuotes-historyReport';
        	}
          	else if (entity=='SpecialHoliday'){
        		return 'specialHoliday-historyReport';
        	}
          	else if (entity=='RegularHolidays'){
        		return 'regularHoliday-historyReport';
        	}
          	else if (entity=='LimitSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
                    var limitSettingsHeaderDTO =  angular.fromJson($rootScope.payload);
                    if(limitSettingsHeaderDTO.limitInstrumentGroup == undefined ){
                        return 'old-limit-settings-auditLog';
                    }
        			return 'limit-settings-historyReport';
        		}
        		
        	}
          	else if (entity=='CustomerLimitChangeRequest'){         	
          			
          		if(actionName.indexOf('CREATE') >= 0 && (vm.userEntityOwnerType == 'SingleUserCustomer' || vm.userEntityOwnerType =='MultiUserCustomer') )
          			return 'create-customer-limit-settings-historyReport';
          		else
                    var customerLimitSettingsDTO =  angular.fromJson($rootScope.payload);
                    if(customerLimitSettingsDTO.limitSettingsHeader.limitInstrumentGroup == undefined ){
                        return 'old-customer-limit-settings-auditLog';
                    }
          			return 'customer-limit-settings-historyReport';
        	}
          	else if (entity=='EntityRestriction'){
        		return 'manage-Entity-Restrictions-historyReport';
        	}
          	else if (entity=='AMCSettings'){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
        			return 'amc-historyReport';
        		}
         		return 'amc-historyReport';
         	}
          	else if (entity=='CustomerCharges'){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
        			return 'customer-charges-historyReport';
        		}
         		return 'customer-charges-historyReport';
         	}
          	else if (entity=='GSTSettings'){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-historyReport';
        		}else{
        			return 'gst-settings-historyReport';
        		}
         		return 'gst-settings-historyReport';
         	}
          	else if (entity=='TransactionChargesSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName == 'REJECT - IMPORT'){
          			return 'upload-todolist-historyReport';
          		}else{
          			return 'transaction-charges-settings-historyReport';
          		}
    		
          	}
            else if (entity=='CreditFactorSettings'){
                return 'credit-factor-settings-historyReport';
            }
          	else if (entity=='HomeBranchIFSCUpload'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT'){
          			return 'upload-todolist-historyReport';
          		}
          	}
          	else if (entity=='ModifyCustomerDetails'){
        		//modifycustomerdetails_auditlog
        		return 'modify-customer-details-historyreport';
        	}
          	else if (entity=='TermsAndConditionsUpload'){

        		return 'terms-condition-upload-historyReport';
        	}
		else if (entity=='TermsAndConditionsView'){
        		
        		return 'entities-tac-view-historyReport';
        	}
		else if (entity=='CustomerTermsAndConditionsUpload'){

        		return 'customer-tac-upload-by-bank-historyReport';
        	}
		else if (entity=='BankFeesChargesUpload'){

		    		return 'bank-fees-charges-upload-historyReport';
		   	}
		else if (entity == 'PendingOrderReport'){
        		return 'upload-todolist-historyReport';
        	}
			   
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( entityConstants.ENTITY_SHORT_NAME.HISTORY_REPORT,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }

     }
})();
