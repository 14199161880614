(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'securityPolicy'
        })
        .controller('SecurityPolicyController', SecurityPolicyController);

    SecurityPolicyController.$inject = ['$scope', '$rootScope', '$state', 'workflowSharedService', 'SecurityPolicy', 'GlobalValues', 'EntityPermissions','ScreenLog', 'entityConstants','$timeout'];

    function SecurityPolicyController ($scope, $rootScope, $state, workflowSharedService, SecurityPolicy, GlobalValues, EntityPermissions,ScreenLog, entityConstants,$timeout) {
        var vm = this;
        vm.screenLog=ScreenLog;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SECURITY_POLICY;
        vm.securityPolicies = [];
        
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.refresh=refresh;
        
        vm.permitSecurityPolicyMapEntityAction = EntityPermissions.hasPermission('SecurityPolicy.MAP');
                
        workflowSharedService.viewFrom = 'securityPolicy';
        
        $rootScope.$on('gatewayApp:securityPolicyRefresh', function(event, result) {
        	loadAll();
        });
        
        logScreenAccess();
        loadAll();

        function loadAll() {
        	SecurityPolicy.query({"orgId":GlobalValues.getOrgId()},function(result) {
        		vm.securityPolicies = result;
        	});
        	
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
    }
})();
