(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manage-user-restrictions', {          
            
        	parent: 'home',
            params:{screen: 'UserRestriction'},
        	url: 'manage-user-restrictions',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'global.title'
        	},
        	ncyBreadcrumb: {
        		label: '{{ "global.menu.entityRestriction.manageUserRestriction" | translate }}',
        		parent: 'home'
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/manage-user-restrictions/manage-user-restrictions.html',
        			controller: 'ManageUserRestrictionController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'md',
        			resolve: {
        				entity: function(){
                    	
        				}
        			}
        		}).result.then(function() {
        			$state.go('home', null, {reload:true});
        		}, function() {
        			$state.go('home', null, {reload:false});
        		});
        	}],
        	resolve: {
        		translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            	
        			$translatePartialLoader.addPart('userRestriction');
        			$translatePartialLoader.addPart('error');
        			$translatePartialLoader.addPart('global');
        			return $translate.refresh();
        			}]
        		}           
        	})
        	 .state('user-restrictions-todolist', {
            parent: 'dashboard',
            url: '/userRestrictionsView',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-user-restrictions/manage-user-restrictions.html',
                    controller: 'ManageUserRestrictionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('home', null, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('userRestriction');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
            
        })
        
         .state('user-restrictions-todolist.resubmit', {
            parent: 'dashboard',
            url: '/userRestrictionsResubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-user-restrictions/manage-user-restrictions.html',
                    controller: 'ManageUserRestrictionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('home', null, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('userRestriction');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('userRestriction-auditLog', {
            parent: 'audit-log',
            url: '/userRestrictionsauditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-user-restrictions/manage-user-restrictions.html',
                    controller: 'ManageUserRestrictionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('audit-log', null, { reload: 'audit-log' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('userRestriction');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('userRestriction-historyReport', {
            parent: 'history-report',
            url: '/userRestrictionsHistoryReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-user-restrictions/manage-user-restrictions.html',
                    controller: 'ManageUserRestrictionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('home', null, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('userRestriction');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
       }    
 }) ();