 (function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('change-mobile', {
            parent: 'user-profile',
            url: '/change-mobile/{userId}/{mobile}/{countryCode}/{mode}/{customerId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-mobile/change-mobile.html',
                    controller: 'ChangeMobileController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                    	entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
            	
                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
         .state('change-mobile-user', {
            parent: 'manage-users',
            url: '/change-mobile/{userId}/{mobile}/{countryCode}/{mode}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-mobile/change-mobile.html',
                    controller: 'ChangeMobileController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        
                    	entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
            	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('change-mobile-customer-info-resubmit', {
            parent: 'customer-info-status-view',
            url: '/change-mobile/{userId}/{mobile}/{countryCode}/{mode}/{customerId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-mobile/change-mobile.html',
                    controller: 'ChangeMobileController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                    	entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
            	
                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
         };
    })();
