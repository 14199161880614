(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('AMCSummaryView', AMCSummaryView);

	AMCSummaryView.$inject = ['$resource'];

	function AMCSummaryView ($resource) {
		var resourceUrl =  'api/amc-summary/:id';

		return $resource(resourceUrl, {}, {

			'getAMCSummaryReport':{          	 
				method: 'GET',
				params: {bankId : '@bankId',from : '@from', to: '@to',printorGenerateAfreshOption : '@printorGenerateAfreshOption'},  				
				url: 'api/amc-summary/get-amc-summary-details'
			},
			'exportAMCSummaryExcel':{
				method: 'POST',
				url: 'api/amc-summary/excel-download',
				responseType: 'arraybuffer',
				params: {freshOrPrintOption : '@freshOrPrintOption', bank : '@bank'},
				transformRequest: function (data) {
	                   	var copy = angular.copy(data);
	                   	
	                       return angular.toJson(copy);
	            },
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				}      
			},
			'downloadEInvoiceExcel':{
				method: 'POST',
				url: 'api/amc-summary/eInvoice-download',
				responseType: 'arraybuffer',
				params: {from : '@from', to: '@to',financialYearId : '@financialYearId'},
				transformRequest: function (data) {
	                   	var copy = angular.copy(data);
	                   	
	                       return angular.toJson(copy);
	            },
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				}      
			},
			'exportAMCSummaryCsv':{
				method: 'POST',
				url: 'api/amc-summary/csv-download',
				responseType: 'arraybuffer',
				params: {freshOrPrintOption : '@freshOrPrintOption', bank : '@bank'},
				transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
            },
			transformResponse: function(data, headers) {
				return {
					data: data,
					headers: headers
				};   
			}         
			},
			'exportAMCSummaryPdf':{
				method: 'POST',
				url: 'api/amc-summary/pdf-download',
				responseType: 'arraybuffer',
				params: {freshOrPrintOption : '@freshOrPrintOption'},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				}      
			}
		
		});
	}
})();
