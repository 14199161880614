(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('MemberBanksFeeAndChargesViewService', MemberBanksFeeAndChargesViewService);

	MemberBanksFeeAndChargesViewService.$inject = ['$resource'];

	function MemberBanksFeeAndChargesViewService($resource) {
		var resourceUrl =  '/api';

		return $resource(resourceUrl, {}, {
			'getMemberBankViewDto':{
				method: 'GET',
				isArray: true,
				url: '/api/active-bank-fees-charges/get-all-bank-ccil-view',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}

			},
			'getMemberBankHistoryViewDto':{
				method: 'GET',
				isArray: true,
				params:{fromDate:'@fromDate',toDate:'@toDate'},
				url: '/api/history-bank-fees-charges-for-Period/get-all-bank-ccil-view',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}

			}
		
		

		});
	}
})();
