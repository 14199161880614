(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('MarketStatisticsController', MarketStatisticsController);

    MarketStatisticsController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', '$stateParams','entity','prevClose','GlobalValues','$filter'];

    function MarketStatisticsController($scope, $rootScope, $state, $uibModalInstance, $stateParams,entity,prevClose,GlobalValues,$filter) {
        var vm = this;
        
        vm.clear=clear;
        vm.ltp=0;
        vm.ltpForIb=0;
        vm.instrument=$rootScope.instrumentForStats;
        vm.openPrice;
        vm.openPriceForIb;
        if(vm.instrument!='BOTH'){
            vm.marketStatistics=entity;
            vm.prevCloseObj = prevClose[0];
            vm.openPrice = getIntervalOpenExchangeRate(vm.marketStatistics);
        }else{
            vm.marketStatistics=$filter('filter')(entity, {tokenSequenceNumber: '10000002'});
            vm.prevCloseObj = prevClose[0];
            vm.marketStatisticsForIb=$filter('filter')(entity, {tokenSequenceNumber: '10000001'});;
            vm.prevCloseObjForIb = prevClose[1];
            vm.openPrice = getIntervalOpenExchangeRate(vm.marketStatistics);
            vm.openPriceForIb = getIntervalOpenExchangeRate(vm.marketStatisticsForIb);
        }
        vm.globalValues=GlobalValues;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();

        vm.tbq = vm.marketStatistics.reduce(function (a,b) { return a + b.intervalBuyAmount; }, 0);
        vm.toq = vm.marketStatistics.reduce(function (a,b) { return a + b.intervalSellAmount; }, 0);
        vm.ttq = vm.marketStatistics.reduce(function (a,b) { return a + b.intervalTradeAmount; }, 0);
        vm.totalVal = vm.tbq + vm.toq;
        vm.prevClose=vm.prevCloseObj.lastTradeExchangeRate;
        
        vm.reverseMarketStatistics = angular.copy(vm.marketStatistics).reverse();
        
        angular.forEach(vm.reverseMarketStatistics, function(value){
          	if(value.intervalCloseExchangeRate!=0 && vm.ltp==0){
          		vm.ltp = value.intervalCloseExchangeRate;
          	}
        });
        
        if(vm.marketStatistics.length>0){
            //vm.ltp = vm.marketStatistics[vm.marketStatistics.length-1].intervalCloseExchangeRate;
            
            if(vm.ltp>0){
            	vm.changeInPrice = vm.ltp - vm.prevClose;
                vm.changeInPrice = Math.round(parseFloat(vm.changeInPrice)*100000) / 100000;
                vm.changeInPercent = (vm.changeInPrice/vm.ltp) * 100;
                vm.changeInPercent = Math.round(parseFloat(vm.changeInPercent)*100000) / 100000;
            }
            
            vm.high= Math.max.apply(Math,vm.marketStatistics.map(function(item){return item.intervalHighExchangeRate;}).filter(function(val){return val !== 0}));
            vm.low= Math.min.apply(Math,vm.marketStatistics.map(function(item){return item.intervalLowExchangeRate;}).filter(function(val){return val !== 0}));
        }
        
        if(vm.marketStatisticsForIb!=null && vm.marketStatisticsForIb!=undefined){
        	vm.tbqForIb = vm.marketStatisticsForIb.reduce(function (a,b) { return a + b.intervalBuyAmount; }, 0);
            vm.toqForIb = vm.marketStatisticsForIb.reduce(function (a,b) { return a + b.intervalSellAmount; }, 0);
            vm.ttqForIb = vm.marketStatisticsForIb.reduce(function (a,b) { return a + b.intervalTradeAmount; }, 0);
            vm.totalValForIb = vm.tbqForIb + vm.toqForIb;
            vm.prevCloseForIb=vm.prevCloseObjForIb.lastTradeExchangeRate;
            
            vm.reverseMarketStatisticsForIb = angular.copy(vm.marketStatisticsForIb).reverse();
            
            angular.forEach(vm.reverseMarketStatisticsForIb, function(value){
              	if(value.intervalCloseExchangeRate!=0 && vm.ltpForIb==0){
              		vm.ltpForIb = value.intervalCloseExchangeRate;
              	}
            });
            
            if(vm.marketStatisticsForIb.length>0){
                //vm.ltp = vm.marketStatistics[vm.marketStatistics.length-1].intervalCloseExchangeRate;
                
                if(vm.ltpForIb>0){
                	vm.changeInPriceForIb = vm.ltpForIb - vm.prevCloseForIb;
                    vm.changeInPriceForIb = Math.round(parseFloat(vm.changeInPriceForIb)*100000) / 100000;
                    vm.changeInPercentForIb = (vm.changeInPriceForIb/vm.ltpForIb) * 100;
                    vm.changeInPercentForIb = Math.round(parseFloat(vm.changeInPercentForIb)*100000) / 100000;
                }
                
                vm.highForIb= Math.max.apply(Math,vm.marketStatisticsForIb.map(function(item){return item.intervalHighExchangeRate;}).filter(function(val){return val !== 0}));
                vm.lowForIb= Math.min.apply(Math,vm.marketStatisticsForIb.map(function(item){return item.intervalLowExchangeRate;}).filter(function(val){return val !== 0}));
            }
        }
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function getIntervalOpenExchangeRate(marketStatisticsLlist){
        	 // Fix for issue -417 mantis , Market Open Price not getting updated
        	if ( marketStatisticsLlist!=null && marketStatisticsLlist!=undefined && marketStatisticsLlist.length>0 ){
        		var openRateFilteredMovementArray = marketStatisticsLlist.filter(function(val){return  val.intervalOpenExchangeRate !== 0.00});
            	if(openRateFilteredMovementArray!=null && openRateFilteredMovementArray!=undefined && openRateFilteredMovementArray.length>0)
            		return  openRateFilteredMovementArray[0].intervalOpenExchangeRate;
            	else return 0.00;
        	}
            // End Fix
        }
        
    }   
})();
