(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('SwapQuotesForTenorDates', SwapQuotesForTenorDates);

	SwapQuotesForTenorDates.$inject = ['$resource'];

	function SwapQuotesForTenorDates ($resource) {
		var resourceUrl =  'api/swap-quotes-tenor-date/:orgId/:businessDate';

		return $resource(resourceUrl, {}, {  
			
			'getSystemSettings':{
				method: 'GET',			
				url:'api/swap-quotes-for-tenor-date/system-settings',
				transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
			},

			'get':{ 
				method: 'GET',
				params: {orgId:'@orgId', businessDate :'@businessDate' , toDate: '@toDate'},
				url:'api/swap-quotes-tenor-date/:orgId/:businessDate', 
				isArray:true,			

			},
			'update': {
            	method:'PUT',
            	params: {orgId:'@orgId'},
                url: 'api/swap-quotes-tenor-date/:orgId',
                isArray:true,
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
			'getAllSwapQuotes': {
				method: 'GET',
				params: {orgId:'@orgId', fromDate :'@fromDate' , toDate: '@toDate'},
				url:'api/swap-quotes-tenor-date/:orgId/:fromDate/:toDate', 
				isArray:true


			},
			'getSettlementDateForCashTomFrwd':{
				method: 'GET',
				url:'api/swap-quotes-for-tenor-date/csInstrumentList', 
				isArray:true
			},
			'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
			'exportData':{
				method: 'POST',
				url: 'api/swap-quotes-view/export',
				responseType: 'arraybuffer',
				params : { orgId : '@orgId',from :'@from', to : '@to'},
				transformRequest: function (data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'generateTemplateCSV':{
				method: 'POST',
				url: 'api/swap-quotes-tenor-date/generate-template/:orgId/:businessDate',
				responseType: 'arraybuffer',
				params : { orgId : '@orgId', businessDate :'@businessDate'},
				transformRequest: function (data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'exportDataPdf':{
				method: 'POST',
				url: 'api/swap-quotes-tenor-dates-view/export-pdf',
				responseType: 'arraybuffer',
				params : {orgId : '@orgId',from :'@from', to : '@to'},
				transformRequest: function (data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
            'getSwapQuotesForAllBanks': {
                method: 'GET',
                url:'api/swap-quotes-tenor-for-all-banks', 
            },           

            'getSwapQuotesForGivenBank': {
                method: 'GET',
                params: {bankId:'@bankId'},
                url:'api/swap-quotes-tenor-for-given-bank/:bankId', 
            },           

            'getSwapQuotesForGivenCustomer': {
                method: 'GET',
                params: {customerId:'@customerId'},
                url:'api/swap-quotes-tenor-for-given-customer/:customerId', 
            }
		});
	}
})();


