(function() {
    'use strict';

    angular
        .module('gatewayApp')
        
        .controller('GenericUsersController', GenericUsersController);

    GenericUsersController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','GenericUsers','AlertService'];

    function GenericUsersController($scope, $state , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,GenericUsers, AlertService) {
        var vm = this;
        vm.importUsers = importUsers;
        
       function importUsers() {
        	GenericUsers.query(onGetSuccess, onGetFailure);
    	        	
        }      
        
        function onGetSuccess(){
        	AlertService.success("File uploaded successfully...!");
        }
        
        function onGetFailure(){
        	
        }
    }
})();
