(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('GlobalSecurityPolicy', GlobalSecurityPolicy);

    GlobalSecurityPolicy.$inject = ['$resource'];

    function GlobalSecurityPolicy ($resource) {
        var resourceUrl =  'api/orgId/:orgId/global-security-policy/:themeID';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId'},
        	    url: 'api/orgId/:orgId/global-security-policy'
        	 },
        	 'getEntityPermissions':{
        		 params: {orgId:'@orgId'},
        		 isArray: true,
         	     url: 'api/orgId/:orgId/global-security-policy/entity-permissions'
        	 },
        	 'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
        	 'getGlobalSecurityPolicies': {
                 method: 'GET',
                 isArray: true,
                 url: 'api/global-security-policy/orgTypeId/:orgTypeId/customerUserTypeId/:customerUserTypeId',
                 params: {orgTypeId:'@orgTypeId',customerUserTypeId:'@customerUserTypeId'},
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
        	 'save': {
                 method: 'POST',
                 params: {orgId:'@orgId'},
                 url: 'api/orgId/:orgId/global-security-policy',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 return angular.toJson(copy);
                 }                
             },
             'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/global-security-policy',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              }
              
        });
    }
})();
