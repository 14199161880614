(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('customerRegistration', {
            parent: 'account',
            url: '/customer-registration',
            data: {
                authorities: []
            },
            views: {
                'logincontent@': {
                    templateUrl: 'app/entities/customer-registration/customer-registration-new.html',
                    controller: 'CustomerRegistrationControllerNew',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	globalValue: ['StaticLookUp', function(StaticLookUp) {
                    return StaticLookUp.getConfigSettingsPreLogin().$promise;
                }],
            	
                customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                    return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                }],
                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    return $translate.refresh();
                }]
            }
        })
        .state('request-reference-number', {
            parent: 'customerRegistration',
            url: '/request-reference-number',
            params: {refNo: null},
            data: {
                authorities: [],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.requestReferenceNumber.main" | translate }}',
                parent: 'customerRegistration'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/request-reference-number/request-reference-number.html',
                    controller: 'RequestReferenceNumberController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xs',
                    resolve: {
                        configSettings: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettingsPreLogin().$promise;
                        }],
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('customerRegistration', null, {reload:false});
                }, function() {
                	$state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('request-reference-number');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer-onboarding-view', {
            parent: 'customer-onboarding-exception-report',
            params: {id: null},
            url: '/customer-registration-view/{theme}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerRegistration', 'GlobalValues', function(CustomerRegistration, GlobalValues) {
                                     return CustomerRegistration.get($stateParams.id).$promise;
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getMarkupTypes().$promise;
                                }],
                                roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                                }],
                                 mode: function(){
                 					return "customeronboarding";
                                 },
                                 enableField: function(){
                                	 return false;
                                 }
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-customer-onboarding', {
            parent: 'customer-onboarding-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                    //$state.go('^', null, { reload: true });
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('view-panImage-resubmit-request', {
            parent: 'customerRegistration',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                    //$state.go('^', null, { reload: true });
                	$state.go('^', null, { reload: 'false' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customer-registration-status-view', {
            parent: 'registrationRequestStatus',
            params: {id: null},
            url: '/customer-details/{theme}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerRegistration', 'GlobalValues', function(CustomerRegistration, GlobalValues) {
                                     return CustomerRegistration.get($stateParams.id).$promise;
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: {},
                                 roEdApplicabilityMethods: {},
                					mode: function(){
                					return "registrationrequeststatus";
                					},
                					enableField: function(){
                                   	 return false;
                                    }
                       
                    }
                }).result.then(function() {
                	$state.go('registrationRequestStatus', null, { reload: true });
                }, function() {
                	$state.go('registrationRequestStatus', null, { reload: true });
                });
            }]
            
        })
        
        .state('customer-track-status-view', {
            parent: 'trackStatus',
            params: {id: null},
            url: '/customer-onboarding-details/{theme}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerRegistration', 'GlobalValues', function(CustomerRegistration, GlobalValues) {
                                     return CustomerRegistration.get($stateParams.id).$promise;
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: {},
                                 roEdApplicabilityMethods: {},
                					mode: function(){
                					return "trackstatus";
                					},
                					enableField: function(){
                                   	 return false;
                                    }
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
        .state('customer-onboarding-status-view', {
            parent: 'customerOnboardingStatus',
            params: {id: null},
            url: '/customer-onboarding-status/{theme}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerRegistration', 'GlobalValues', function(CustomerRegistration, GlobalValues) {
                                     return CustomerRegistration.get($stateParams.id).$promise;
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: {},
                                 roEdApplicabilityMethods: {},
                					mode: function(){
                					return "customeronboardingstatus";
                					},
                					enableField: function(){
                                   	 return false;
                                    }
                       
                    }
                }).result.then(function() {
                    $state.go('customerOnboardingStatus', null, { reload: true });
                }, function() {
                	$state.go('customerOnboardingStatus', null, { reload: true });
                });
            }]
            
        })
        .state('view-pan-image-track-status', {
            parent: 'customer-track-status-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('view-pan-image-registration-status', {
            parent: 'customer-registration-status-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('view-panImage-customer-onboarding-status', {
            parent: 'customer-onboarding-status-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customer-approval-todo-view', {
            parent: 'customer-todo',
            url: '/customer-registration-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {     
                    		
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                    	mode: function(){
                    		
         				return  'customerApprovalToDo';
                    	},
                    	enableField: function(){
                       	 return false;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('customer-update-approval-todo-view', {
            parent: 'customer-todo',
            url: '/customer-update-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {     
                    		
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                    	mode: function(){
                    		
         				return 'customerInfoChangeStatus';
                    		},
                    		enableField: function(){
                           	 return false;
                            }
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('modify-customer-details-todoList', {
            parent: 'dashboard',
            url: '/modify-customer-details-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {     
                    		
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                    	mode: function(){
                    		
         				return  'modifycustomerdetails_todo';
                    	},
                    	enableField: function(){
                       	 return false;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
       /* .state('customer-update-approval-todo-view', {
            parent: 'customer-todo',
            url: '/customer-update-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {     
                    		
                            return {                            		
                            };
                        },
                    	mode: function(){
                    		
         				return 'customerInfoChangeStatus';
                    		},
                    		enableField: function(){
                           	 return false;
                            }
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })*/
        .state('view-panImage-customer-approval', {
            parent: 'customer-approval-todo-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('view-panImage-customer-update', {
            parent: 'customer-update-approval-todo-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customer-view-report-view', {
            parent: 'customer-view-report',
            params: {id: null},
            url: '/customer-registration-view/{theme}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerTradingBankDetails', 'GlobalValues', function(CustomerTradingBankDetails, GlobalValues) {
                                     //return UserProfile.getCustomer({customerId : $stateParams.id}).$promise;
                            		 return CustomerTradingBankDetails.getCustomerDetailsForBankRelationship({customerTradinBankDetailsId: $stateParams.id}).$promise;
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getMarkupTypes().$promise;
                                }],
                                roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                                }],
                                 mode: function(){
                 					return "customerViewReport";
                                 },
                                 enableField: function(){
                                	 return false;
                                 }
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('modify-customer-details', {
            parent: 'customer-view-report',
            params: {id: null},
            url: '/modify-customer-details/{theme}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerTradingBankDetails', 'GlobalValues', function(CustomerTradingBankDetails, GlobalValues) {
                                     //return UserProfile.getCustomer({customerId : $stateParams.id}).$promise;
                            		 return CustomerTradingBankDetails.getCustomerDetailsForBankRelationship({customerTradinBankDetailsId: $stateParams.id}).$promise;
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getMarkupTypes().$promise;
                                }],
                                roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                                }],
                                 mode: function(){
                 					return "modifycustomerdetails";
                                 },
                                 enableField: function(){
                                	 return false;
                                 }
                       
                    }
                }).result.then(function() {
                    $state.go('customer-view-report', {}, { reload: 'customer-view-report' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-customer-view-report', {
            parent: 'customer-view-report-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('view-panImage-customer-info-update', {
            parent: 'user-profile',
            url: '/pan',
            params: {image: null},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
	.state('customer-info-status-view', {
            parent: 'customer-info-change-status',
            url: '/customer-registration-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['CustomerRegistration', 'GlobalValues', function(CustomerRegistration, GlobalValues) {
                                     //return CustomerRegistration.get({requestId : $stateParams.id}).$promise;
                            		 return {};
                                 }],
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 markupTypes: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getMarkupTypes().$promise;
                                }],
                                roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                                    return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                                }],
                                 mode: function(){
                 					return "customerInfoChangeStatus";
                                 },
                                 enableField: function(){
                                	 return $state.params.enableFields;
                                 }
                				
                       
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-customer-info-status', {
            parent: 'customer-info-status-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customer-registration-auditLog', {
            parent: 'audit-log',
            url: '/customerRegistrationAuditLog',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                        mode: function(){
         					return "auditlog";
                        	},
                        	enableField: function(){
                           	 return false;
                            }
                    }
                }).result.then(function() {
                	$state.go('customerRegistration', null, { reload: 'customerRegistration' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('customer-registration-historyReport', {
            parent: 'history-report',
            url: '/customerRegistrationHistoryReport',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                		mode: function(){
 					return "HistoryReport";
                		},
                		enableField: function(){
                       	 return false;
                        }
                
                    }
                }).result.then(function() {
                	$state.go('customerRegistration', null, { reload: 'customerRegistration' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('old-customer-registration-auditLog', {
            parent: 'audit-log',
            url: '/old-customerRegistrationAuditLog',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                        mode: function(){
         					return "auditlog";
                        	},
                        	enableField: function(){
                           	 return false;
                            }
                    }
                }).result.then(function() {
                	$state.go('customerRegistration', null, { reload: 'customerRegistration' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('old-customer-registration-historyReport', {
            parent: 'history-report',
            url: '/old-customerRegistrationHistoryReport',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                		mode: function(){
 					return "HistoryReport";
                		},
                		enableField: function(){
                       	 return false;
                        }
                
                    }
                }).result.then(function() {
                	$state.go('customerRegistration', null, { reload: 'customerRegistration' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-audit-log', {
            parent: 'customer-registration-auditLog',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('view-panImage-history-report', {
            parent: 'customer-registration-historyReport',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customer-info-status-view-auditLog', {
            parent: 'audit-log',
            url: '/customer-info-audit-log',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                        mode: function(){
         					return "InfoAuditlog";
                        	},
                        	enableField: function(){
                           	 return false;
                            }
                    }
                }).result.then(function() {
                	$state.go('customerRegistration', null, { reload: 'customerRegistration' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-customer-info-audit-log', {
            parent: 'customer-info-status-view-auditLog',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('customer-info-status-view-historyreport', {
            parent: 'history-report',
            url: '/customer-info-history-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                        mode: function(){
         					return "InfoHistoryReport";
                        	},
                        	enableField: function(){
                           	 return false;
                            }
                    }
                }).result.then(function() {
                	$state.go('customerRegistration', null, { reload: 'customerRegistration' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-customer-info-history-report', {
            parent: 'customer-info-status-view-historyreport',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('terms-and-conditions', {
            parent: 'customerRegistration',
            url: '/termsAndConditions/{theme} ',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/terms-and-conditions.html',
                    controller: 'TermsAndConditionsController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerOnBoarding";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customer-info-update-intimation-view', {
            parent: 'entity',
            url: '/customer-info-update-intimation-view',
            data: {
                authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                    	mode: function(){
         					return "customerInfoUpdationIntimationView";
                        },
                        enableField: function(){
                       	 return false;
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, { reload: 'home' });
                }, function() {
                    $state.go('home', null, { reload: 'home' });
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('view-panImage-customer-info-intimation-status', {
            parent: 'customer-info-update-intimation-view',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('terms-and-conditions-bank-registration', {
            parent: 'trading-bank-create',
            url: '/termsAndConditions /{theme}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/terms-and-conditions.html',
                    controller: 'TermsAndConditionsController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "tradingBankRelationship";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('customerRegistrationPreview', {
            parent: 'customerRegistration',
            url: '/customer-registration-preview/{theme}',
            params: { customerRegistrationRequest: null, image: null},
            data: {
            	authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-preview.html',
                    controller: 'CustomerRegistrationPreviewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xlg',
                    resolve: {	
                                 mode: function(){
                 					return "customeronboarding";
                                 },
                                 customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                                 }],
                                 enableField: function(){
                                	 return false;
                                 }
                       
                    }
                }).result.then(function(result) {
                   $state.go('customerOnboardingStatus', {key: result.activationKey}, { reload: true });
                }, function() {
                   $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-customer-registration-preview', {
            parent: 'customerRegistrationPreview',
            url: '/pan',
            params: { image: null ,
            	theme : null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                    //$state.go('^', null, { reload: true });
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('termsAndConditionsApproval', {
            parent: 'customer-todo',
            url: '/termsAndConditionsApproval',
            params : {entityId : null, customerId : null, tradingBank : null},
            data: {
            	authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions-approval/terms-and-conditions-approval.html',
                    controller: 'TermsAndConditionsApprovalController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('terms-and-conditions-checker-approval', {
            parent: 'termsAndConditionsApproval',
            url: '/termsAndConditions /{theme}',
            data: {
            	authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/terms-and-conditions-member.html',
                    controller: 'TermsAndConditionsController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerToDo";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('terms-and-conditions-view-approval', {
            parent: 'customer-approval-todo-view',
            url: '/termsAndConditions /{theme}',
            data: {
            	authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/terms-and-conditions-member.html',
                    controller: 'TermsAndConditionsController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerViewApproval";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('password-policy-bank-registration', {
            parent: 'terms-and-conditions-bank-registration',
            url: '/passwordPolicy /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/password-policy.html',
                    controller: 'PasswordPolicyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "tradingBankRelationship";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('password-policy-customer-approval', {
            parent: 'terms-and-conditions-checker-approval',
            url: '/passwordPolicy /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/password-policy.html',
                    controller: 'PasswordPolicyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerToDo";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('password-policy-customer-view-approval', {
            parent: 'terms-and-conditions-view-approval',
            url: '/passwordPolicy /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/password-policy.html',
                    controller: 'PasswordPolicyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerViewApproval";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('password-policy-customer-onBoarding', {
            parent: 'terms-and-conditions',
            url: '/passwordPolicy /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/password-policy.html',
                    controller: 'PasswordPolicyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerOnBoarding";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('schedule-of-fees-bank-registration', {
            parent: 'terms-and-conditions-bank-registration',
            url: '/scheduleOfFees /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "tradingBankRelationship";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('schedule-of-fees-customer-approval', {
            parent: 'terms-and-conditions-checker-approval',
            url: '/scheduleOfFees /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerToDo";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('schedule-of-fees-customer-view-approval', {
            parent: 'terms-and-conditions-view-approval',
            url: '/scheduleOfFees /{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerViewApproval";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('schedule-of-fees-customer-onBoarding', {
            parent: 'terms-and-conditions',
            url: '/scheduleOfFees/{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "customerOnBoarding";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
         .state('schedule-of-fees-transaction-charges-report', {
            parent: 'transaction-charges',
            url: '/Schedule-of-fees-Transaction-charges/{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	mode: function(){
         					return "transactionCharges";
                        }
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
          .state('schedule-of-fees', {
            parent: 'home',
            url: '/Schedule-of-fees/{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ClearCorpScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
      /*  .state('schedule-of-fees', {
            parent: 'entity',
            url: '/Schedule-of-fees/{theme}',
            data: {
            	authorities: [],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/terms-and-conditions/schedule-of-fees.html',
                    controller: 'ScheduleOfFeesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })*/
        .state('view-panImage-modify-customer-todo', {
            parent: 'modify-customer-details-todoList',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('modify-customer-details-historyreport', {
            parent: 'history-report',
            url: '/modify-customer-details-history-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                        mode: function(){
         					return "modifycustomerDetails_history";
                        	},
                        	enableField: function(){
                           	 return false;
                            }
                    }
                }).result.then(function() {
                	$state.go('history-report', null, { reload: 'history-report' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-modify-customer-details-history-report', {
            parent: 'modify-customer-details-historyreport',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('modify-customer-details-auditLog', {
            parent: 'audit-log',
            url: '/modify-customer-details-audit-log',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-new-view-dialog.html',
                    controller: 'CustomerRegistrationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        customerTypeWiseInputDetails: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getCustomerTypeWiseInputDetails().$promise;
                        }],
                        markupTypes: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }],
                        mode: function(){
         					return "modifycustomerDetails_audit";
                        	},
                        	enableField: function(){
                           	 return false;
                            }
                    }
                }).result.then(function() {
                	$state.go('audit-log', null, { reload: 'audit-log' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('view-panImage-modify-customer-details-audit-log', {
            parent: 'modify-customer-details-auditLog',
            url: '/pan/{theme}',
            params: {image: null},
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-view-panImage.html',
                    controller: 'CustomerViewPanImageController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: 'true' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('customer-registration-apporval-disclaimer', {
            parent: 'customer-todo',
            url: '/customer-registration-approval-disclaimer',
            params : {entityId : null,customerId : null, tradingBank : null},
            data: {
            	authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-registration/customer-registration-approval-disclaimer-dialog.html',
                    controller: 'CustomerRegistrationApprovalDisclaimerController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	systemSettings: ['IntradayMarket', function(IntradayMarket) {
                            return IntradayMarket.getCurrentBusinessDate().$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }]
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
       
        
    }
})();
