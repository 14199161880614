(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GlobalSecurityPolicyDialogController', GlobalSecurityPolicyDialogController);

    GlobalSecurityPolicyDialogController.$inject = ['Workflow', '$scope', '$state', '$stateParams', '$rootScope','workflowSharedService', 'GlobalValues', '$uibModalInstance', 'GlobalOrganisationTypeRole', 'GlobalSecurityPolicy','entity','ScreenLog','entityConstants'];

     function GlobalSecurityPolicyDialogController(Workflow, $scope, $state, $stateParams, $rootScope, workflowSharedService, GlobalValues, $uibModalInstance, GlobalOrganisationTypeRole, GlobalSecurityPolicy, entity,ScreenLog,entityConstants) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.GLOBAL_SECURITY_POLICY;
        vm.screenLog = ScreenLog;
        vm.save = save;
        vm.clear = clear;
        vm.clicked = false;
        vm.securityPolicyDTO ={};
       
        vm.approveSecurityPolicy = approveSecurityPolicy;
        vm.rejectSecurityPolicy = rejectSecurityPolicy;
        vm.discardSecurityPolicy = discardSecurityPolicy;
        vm.resubmitSecurityPolicy = resubmitSecurityPolicy;
        
      //  vm.roles = GlobalOrganisationTypeRole.rolesAllForOrganisationType({orgTypeId:GlobalValues.getOrganisation().organisationType.id,custUserTypeId:0});
        console.log(vm.roles);
        vm.selected = {orgRoleID: []};
        
        if(workflowSharedService.viewFrom == 'globalSecurityPolicy'){
	        showButtonsViewScreen();
	        vm.securityPolicyDTO = entity;
	        console.log($stateParams.custUserTypeId);
	        vm.roles = GlobalOrganisationTypeRole.queryAll({orgTypeId:$stateParams.orgTypeId,customerUserTypeId:$stateParams.custUserTypeId});
	        vm.selected = {orgRoleID: []};
	        vm.orgTypeId = $stateParams.orgTypeId;
	        vm.custUserTypeId = $stateParams.custUserTypeId;
	        vm.screenLog.screenLog(vm.screenName, vm.securityPolicyDTO.themeID, entityConstants.ENTITY_ACTION_TYPE.MAP);
	    	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
        }
        else if(workflowSharedService.viewFrom == 'globalSecurityPolicytodolist_checker') {
        	vm.ViewDisable = true;
        	
        	vm.securityPolicyDTO =  angular.fromJson($rootScope.payload); 
        	 vm.roles = GlobalOrganisationTypeRole.queryAll({orgTypeId:vm.securityPolicyDTO.orgTypeId,customerUserTypeId:vm.securityPolicyDTO.custUserTypeId});
  	        vm.selected = {orgRoleID: []};
        	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
        	vm.orgTypeId = vm.securityPolicyDTO.orgTypeId;
	        vm.custUserTypeId = vm.securityPolicyDTO.custUserTypeId;
        	showButtonsViewCheckerScreen();
        }
        else if(workflowSharedService.viewFrom == 'globalSecurityPolicytodolist_maker') {
        	vm.ViewDisable = true;
        	
        	vm.securityPolicyDTO =  angular.fromJson($rootScope.payload); 
        	vm.roles = GlobalOrganisationTypeRole.queryAll({orgTypeId:vm.securityPolicyDTO.orgTypeId,customerUserTypeId:vm.securityPolicyDTO.custUserTypeId});
  	        vm.selected = {orgRoleID: []};
        	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
        	vm.orgTypeId = vm.securityPolicyDTO.orgTypeId;
	        vm.custUserTypeId = vm.securityPolicyDTO.custUserTypeId;
        	showButtonsViewMakerScreen();
        }
        
        else if(workflowSharedService.viewFrom == 'globalSecurityPolicy_resubmit') {
        	vm.securityPolicyDTO = angular.fromJson($rootScope.payload); 
        	vm.roles = GlobalOrganisationTypeRole.queryAll({orgTypeId:vm.securityPolicyDTO.orgTypeId,customerUserTypeId:vm.securityPolicyDTO.custUserTypeId});
 	        vm.selected = {orgRoleID: []};
	        vm.screenLog.screenLog(vm.screenName, vm.securityPolicyDTO.themeID, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
        	vm.orgTypeId = vm.securityPolicyDTO.orgTypeId;
	        vm.custUserTypeId = vm.securityPolicyDTO.custUserTypeId;
        	showButtonsResubmitScreen();
        }
        else if(workflowSharedService.viewFrom == 'globalSecurityPolicy_auditLog') {
        	
        	vm.ViewDisable = true;        	
        	vm.securityPolicyDTO =  angular.fromJson($rootScope.payload); 
        	 vm.roles = GlobalOrganisationTypeRole.queryAll({orgTypeId:vm.securityPolicyDTO.orgTypeId,customerUserTypeId:vm.securityPolicyDTO.custUserTypeId});
   	        vm.selected = {orgRoleID: []};
         	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
         	vm.orgTypeId = vm.securityPolicyDTO.orgTypeId;
 	        vm.custUserTypeId = vm.securityPolicyDTO.custUserTypeId;
        	vm.btnClose=true;
        	vm.btnApproveReject=false;
        	
        }
        function showButtonsViewScreen(){
        	vm.ViewDisable = false;
            vm.btnClose = false;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = true;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = false;
        }
        function showButtonsViewCheckerScreen(){
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        	vm.btnClose = true;
        	$scope.showDiscardButton = false;
        	vm.btnApproveReject = true;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        function showButtonsResubmitScreen(){
        	vm.btnClose = false;
        	$scope.showButton=true;
        	vm.btnApproveReject = false;
        	$scope.showDiscardButton = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = true;
        }
        function showButtonsViewMakerScreen(){
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        	vm.btnClose = true;
        	vm.btnApproveReject = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }
		
		function save () {	
			
			vm.securityPolicyDTO.orgRoleIDs = vm.selected.orgRoleID;
			vm.securityPolicyDTO.orgTypeId = vm.orgTypeId;
			if(vm.custUserTypeId!=null && vm.custUserTypeId!=undefined){
				vm.securityPolicyDTO.custUserTypeId = vm.custUserTypeId;
			}else{
				vm.securityPolicyDTO.custUserTypeId=0;
			}
			
			
			
        	GlobalSecurityPolicy.save({"orgId":GlobalValues.getOrgId()},vm.securityPolicyDTO, onSaveSuccess, onSaveError);	
        }
     
        function onSaveSuccess (result) {
        	$scope.$emit('globalSecurityPolicyRefresh');        	
            $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.clicked = false;
        }
        function approveSecurityPolicy() { 
        	GlobalSecurityPolicy.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	vm.clicked = false;
        }
       
        function rejectSecurityPolicy() { Workflow.rejectTask($rootScope.taskID); }

        function onRejectSuccess(result) {
        
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onRejectError(error) {}
        
        function discardSecurityPolicy() {
        	GlobalSecurityPolicy.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {

        }
        function resubmitSecurityPolicy() {
        	vm.securityPolicyDTO.organisationRoleIDs = vm.selected.orgRoleID;
        	vm.securityPolicyDTO.orgTypeId = vm.orgTypeId;
			if(vm.custUserTypeId!=null && vm.custUserTypeId!=undefined){
				vm.securityPolicyDTO.custUserTypeId = vm.custUserTypeId;
			}else{
				vm.securityPolicyDTO.custUserTypeId=0;
			}
         	GlobalSecurityPolicy.resubmit({"taskId" : $rootScope.taskID}, vm.securityPolicyDTO, onResubmitSuccess, onResubmitError);
          	$uibModalInstance.dismiss('cancel');
          }
          
        function onResubmitSuccess(result) {
         
          $rootScope.removeRow($rootScope.taskID);
         }        
         function onResubmitError(error) {
        	 vm.clicked = false;
         }
         
         
     } 
})();