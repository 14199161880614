(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-view', {
            parent: 'entity',
            params:{screen: 'InvoiceView'},
            url: '/invoice-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.invoiceView.title" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-view/invoice-view.html',
                    controller: 'InvoiceViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('invoiceView');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                financialYears: ['StaticLookUp', function(StaticLookUp){
               	 return StaticLookUp.getPastAndPresentFinancialYears().$promise;
                }],
                chargeTypes: ['StaticLookUp', function(StaticLookUp){
                  	 return StaticLookUp.getChargesType().$promise;
                   }]
               
            }
        })
       
         
         };
    })();
