(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SpecialHolidaysViewController', SpecialHolidaysViewController);

    SpecialHolidaysViewController.$inject = ['$scope', '$state','$rootScope','workflowSharedService','$uibModalInstance','SpecialHolidays','entity', 'Workflow', 'GlobalValues'];

    function SpecialHolidaysViewController($scope, $state, $rootScope, workflowSharedService, $uibModalInstance,SpecialHolidays,entity, Workflow, GlobalValues) {
        var vm = this;
        vm.clear=clear;
        vm.approveSpecialHolidays = approveSpecialHolidays;
        vm.rejectSpecialHolidays = rejectSpecialHolidays;
        vm.discardSpecialHolidays = discardSpecialHolidays;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.globalValues = GlobalValues;
        
        
        
        if(workflowSharedService.viewFrom == 'specialholidays'){
        	vm.specialHolidays = entity;        	
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'specialholidaystodolist_checker') {
        	vm.specialHolidays =  angular.fromJson($rootScope.payload); 
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'specialholidaystodolist_maker') {
        	vm.specialHolidays =  angular.fromJson($rootScope.payload); 
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom == 'specialHoliday_auditLog') {
        	vm.specialHolidays =  angular.fromJson($rootScope.payload); 
        	$scope.showButton=false;
        	$scope.showDiscardButton = false;
        }
        
        vm.specialHolidays.holidayDate = new Date(vm.specialHolidays.holidayDate);

        function approveSpecialHolidays() { 
        	SpecialHolidays.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectSpecialHolidays() { Workflow.rejectTask($rootScope.taskID); }

        function discardSpecialHolidays() {
        	SpecialHolidays.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }   
})();
