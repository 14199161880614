(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$window', 'errorConstants', 'GlobalValues', 'Account', 
    	'MessageBox','$scope','globalValue', 'User','DealingAdminApplicationWideSettings', 'EntityPermissions', '$sessionStorage','ApplicationWideSettings', '$filter', 'StaticLookUp','LoginService',
        'OrganisationTypeEnum','StringUtils','$stateParams'];

    function LoginController ($rootScope, $state, $timeout, Auth, $window, errorConstants, GlobalValues, Account, 
    		MessageBox,$scope,globalValue, User, DealingAdminApplicationWideSettings, EntityPermissions, $sessionStorage,ApplicationWideSettings, $filter, StaticLookUp,LoginService,
            OrganisationTypeEnum,StringUtils,$stateParams) {
        var vm = this;
        vm.cCILWebsite="";
        vm.rbiCmsWebsite="";
        vm.grievancePortal="";
        vm.rbiCircularWebsite="";
        getCCILWebsite();
        getRbICMSWebsite();
        getGrievancePortal();
        getRBICircularWebsite();
        vm.showAlert = false;
        $scope.StringUtils = StringUtils;
        vm.embedUrlPattern = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)/i;
        vm.video = document.getElementById("popup-video");
        vm.playButton = document.getElementById("playButton");
        getVersionNo();
        getAllVirtualDemoDetails();
        function getVersionNo(){
            StaticLookUp.getVersionNo(function(result){
                vm.versionNo = result.version;
            })
        }
        function getAllVirtualDemoDetails(){
            StaticLookUp.getAllVirtualDemoDetails(function(result){
                vm.virtualDemoDetails = result;
            })
        }
        if(!StringUtils.isBlank($stateParams.orgType) && $stateParams.orgType == OrganisationTypeEnum.ADMIN){
            vm.orgType = OrganisationTypeEnum.CLEARCORP;
            vm.orgTypeParam = OrganisationTypeEnum.ADMIN;
        }else{
            vm.orgType = OrganisationTypeEnum.OTHERS;
            vm.orgTypeParam = "";
        }
        function getCCILWebsite()
        {
        	var configName = 'CCILWebsite';
        	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": configName}, getCCILWebsiteUrlSuccess, getCCILWebsiteUrlFailure);

        }
        
        function getCCILWebsiteUrlSuccess(result){
        	
        	vm.cCILWebsite = result.value;

        }
        function getCCILWebsiteUrlFailure(error){

        }
        function getRbICMSWebsite()
        {
        	var configName = 'RBICMS';
        	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": configName}, getRBICMSUrlSuccess, getRBICMSUrlFailure);

        }
        
        function getRBICMSUrlSuccess(result){
        	
        	vm.rbiCmsWebsite = result.value;

        }
        function getRBICMSUrlFailure(error){

        }
        function getGrievancePortal(){
            var configName = 'GrievancePortal';
        	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": configName}, getGrievancePortalSuccess, getGrievancePortalFailure);
        }
        function getGrievancePortalSuccess(result){
        	
        	vm.grievancePortal = result.value;

        }
        function getGrievancePortalFailure(error){

        }
        function getRBICircularWebsite()
        {
        	var configName = 'RBICircular';
        	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": configName}, getRBICircularUrlSuccess, getRBICircularUrlFailure);

        }
        
        function getRBICircularUrlSuccess(result){
        	
        	vm.rbiCircularWebsite = result.value;

        }
        function getRBICircularUrlFailure(error){

        }
        $scope.showDropDownContent = function(){
            document.getElementById("demoContent").classList.toggle("showContent");

        }
        //popup video
        $scope.popup = function(result){
            if(result.match(vm.embedUrlPattern)){
                document.getElementById("popup-video").src='';
                document.getElementById("popup-iframe").src=result;
                $('.video-popup').fadeOut('fast');
                $('.video-popup-iframe').fadeIn('fast');
            }
            else{
                document.getElementById("popup-iframe").src='';
                document.getElementById("popup-video").src=result;
                $('.video-popup-iframe').fadeOut('fast');
                $('.video-popup').fadeIn('fast');
                vm.playButton.style.opacity = 1;
            }
          };
        
          //popup video close button
        $('.close-btn').on('click', function(){
            $('.video-popup').fadeOut('fast');
            document.getElementById("popup-video").src='';
            $('.video-popup-iframe').fadeOut('fast');
             document.getElementById("popup-iframe").src='';
             return false;
       });

       //mobile popup video
        $scope.mobile_popup = function(result){
            if(result.match(vm.embedUrlPattern)){
            document.getElementById("mobile-popup-video").src='';
            $('.mobile-video-popup').fadeOut('fast');
            document.getElementById("mobile-popup-iframe").src=result;
            $('.mobile-video-popup-iframe').fadeIn('fast');
            }else{
                document.getElementById("mobile-popup-iframe").src='';
                $('.mobile-video-popup-iframe').fadeOut('fast');
                document.getElementById("mobile-popup-video").src=result;
                $('.mobile-video-popup').fadeIn('fast');
            }
          };

          // mobile popup video close button 
           $('.mobile-close-btn').on('click', function(){
             $('.mobile-video-popup').fadeOut('fast');
             $('.mobile-video-popup-iframe').fadeOut('fast');
             document.getElementById("mobile-popup-iframe").src='';
             document.getElementById("mobile-popup-video").src='';
              return false;
        });

        //pause and play virtual demo video
        // $scope.playOrPauseVideo = function() {
        //     var video = document.getElementById("popup-video");
        //     var playButton = document.getElementById("playButton");
        //     if (video.paused) {
        //         video.play();
        //       vm.videoPlaying = true;
        //       playButton.style.display = "none";
              
        //     } else {
        //         video.pause();
        //         vm.videoPlaying = false;
        //         playButton.style.display = "block"; 
        //     }
        //   };


        //pause and play virtual demo video
          
          vm.video.addEventListener("click", togglePlay);   
          vm.playButton.addEventListener("click", togglePlay);
          vm.video.addEventListener("playing", function () {  
            vm.playButton.style.opacity = 0;
         });
          vm.video.addEventListener("pause", function () {
            vm.playButton.style.opacity = 1; 
        });
        // toggle play/pause video
        function togglePlay() {
            if (vm.video.paused || vm.video.ended) {
                vm.video.play();
            } else {
                vm.video.pause();
            }
        }

    	//$state.reload();
        /*if( window.localStorage )
        {
            if( !localStorage.getItem('firstLoad') )
            {
              localStorage['firstLoad'] = true;
              window.location.reload();
            }  
            else
              localStorage.removeItem('firstLoad');
         }*/
        
        
        $scope.openurl = function(url){
            window.open(url, '_blank');  // open ccil website in new window
        } 
       
        
        vm.mobileView = false;

        $window.onresize = function() {
            changeTemplate();
        };
        changeTemplate();

        logoutExisting();
        
        function logoutExisting(){
            GlobalValues.clearPreviousValues();
            EntityPermissions.clearEntityPermission();
            $sessionStorage.isAuth = false;
            Auth.logout();
            //$localStorage.authenticationToken = null;
            $sessionStorage.authenticationToken = null;
        }
        
        function changeTemplate() {
            var screenWidth = $window.innerWidth;
            if (screenWidth < 768) {
            	vm.mobileView = true;
            } else if (screenWidth >= 768) {
            	vm.mobileView = false;
            }
        }
        getSkipOTPConfig();
        function getSkipOTPConfig(){
        	var configName = 'SkipOTPVerification';
        	DealingAdminApplicationWideSettings.getConfigValueForConfigName( {"configName": configName}, getConfigSuccess, getConfigFailure);
        }
        function getConfigSuccess(result){
        	
        	vm.skipOtpVerification = result.value;
        	if(vm.skipOtpVerification == 'No' || vm.skipOtpVerification == 'no'|| vm.skipOtpVerification == 'NO'){
        		vm.skipOTP = false;
        		vm.showSkipOTP = false;
        	}else{
        		vm.skipOTP = true;
        		vm.showSkipOTP = true;
        	}
        }
        function getConfigFailure(error){
        	vm.showSkipOTP = true;
        	vm.skipOTP = true;
        }
        //vm.showSkipOTP = true;
        //vm.skipOTP = true;
        vm.authenticationError = false;
        vm.LockError = false;
        vm.dormantError = false;
        vm.otpLength =0;
        vm.passwordExpiryError = false;
        vm.tradingNotActivatedError = false;
        vm.userSuspendedError = false;
        vm.userBlocked = false;
        vm.userDelinkedError = false;
        vm.userEntitySuspendedError = false;
        vm.noActiveBankRelationshipError = false;
        vm.allowedPasswordCharacters=/^[!@#^&*()\-_.:;a-zA-Z0-9]/;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.loginWithOTP = loginWithOTP;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = false;
        vm.requestResetPassword = requestResetPassword;
        vm.requestUnlockUser = requestUnlockUser;
        vm.username = null;
        vm.mobileView = false;
        vm.confirmOTP = false;
        vm.otpFailed = false;
        vm.clicked = false;
        //vm.skipOTP = true;
        vm.sendOTPSuccess = false;
        vm.resendOTPSuccess = false;
        vm.controlType = "password";
        vm.wrongOTPCount=0;
        vm.wrongOTPLimitExceeded=false;
        vm.globalValues = GlobalValues;
        vm.configSettings = globalValue;
        vm.userBlockedError = "";
    	vm.resendOTPCount=0;
        setEnvironmentName();
        $timeout(function (){angular.element('#username').focus();});

        $scope.otpInput = {
     			size :globalValue.defaultOTPLength, 
     			type : "password",
     			onDone : function(value) {
     				vm.otp = value;
     			},
     			onChange : function(value) {
     				console.log(value);
     			}
     		};
        
        
			
        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            vm.LockError = false;
            vm.dormantError = false;
            vm.tradingNotActivatedError = false;
            vm.noActiveBankRelationship = false;
            vm.passwordExpiryError = false;
            vm.userSuspendedError = false;
            vm.userBlocked = false;
            vm.userEntitySuspendedError = false;
            vm.userDelinkedError = false;
            vm.userBlockedError = "";
        }

        function login (event) {
        	vm.authenticationError = false;
            vm.LockError = false;
            vm.dormantError = false;
            vm.tradingNotActivatedError = false;
            vm.noActiveBankRelationship = false;
            vm.passwordExpiryError = false;
            vm.userDelinkedError = false;
            vm.userSuspendedError = false;
            vm.userBlocked = false;
            vm.userEntitySuspendedError = false;
            vm.otpFailed = false;
            vm.userBlockedError = "";
            event.preventDefault();
            if(vm.skipOTP){
            	loginToSystem();
            }else{
            	preAuthorize();
            }
        }
        
        function preAuthorize(){
            var organisationType = "";

            //check organisation type others then, pass others in param
            if(OrganisationTypeEnum.OTHERS == vm.orgType){
                organisationType = 'others';
            }
            //check organisation type CLEARCORP then, pass admin  in param
            else if(OrganisationTypeEnum.CLEARCORP == vm.orgType){
                organisationType = 'admin';
            }
        	Account.preAuthorize({"orgType":organisationType},{
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                skipOTP: vm.skipOTP,
                orgType : vm.orgType
            }, onPreAuthorizeSuccess, onPreAuthorizeFailure);
        }

        function onPreAuthorizeSuccess(result){
        	vm.confirmOTP = true;
            Account.sendOTP({login: vm.username});
            vm.sendOTPSuccess = true;
            vm.clicked = false;
        }
        
        function onPreAuthorizeFailure(response){
            if(response.status!=403){
            if(angular.fromJson(response.data).type === errorConstants.USER_LOCKED_TYPE){
            	vm.LockError = true;
            }else if(angular.fromJson(response.data).type === errorConstants.USER_DORMANT_TYPE){
            	vm.dormantError = true;
            }else if(angular.fromJson(response.data).type === errorConstants.TRADING_NOT_ACTIVATED_TYPE){
            	vm.tradingNotActivatedError = true;
            }else if(angular.fromJson(response.data).type === errorConstants.PASSWORD_EXPIRED_TYPE){
                MessageBox.openMessageBox('Alert', 'Password is expired. Please proceed to set a new Password...!', 'Ok', 'login');
            }else if(angular.fromJson(response.data).type === errorConstants.PASSWORD_SYSTEM_GENERATED_TYPE){
                Auth.storePreviousState('change-password-expiry', {login: vm.username, orgType : vm.orgTypeParam} );
                MessageBox.openMessageBox('Alert', 'This is a Temporary Password. Please proceed to set a new Password...!', 'Ok', 'login');
            }else if(angular.fromJson(response.data).type === errorConstants.USER_SUSPENDED_TYPE){
            	vm.userSuspendedError = true;
            }else if(angular.fromJson(response.data).type === errorConstants.USER_ENTITY_SUSPENDED_TYPE){
            	vm.userEntitySuspendedError = true;
            }else if(angular.fromJson(response.data).type === errorConstants.NO_ACTIVE_BANK_RELATIONSHIP){
            	vm.noActiveBankRelationship = true;
            }else if(angular.fromJson(response.data).type === errorConstants.USER_DELINKED_TYPE){
            	vm.userDelinkedError = true;
            }else if(angular.fromJson(response.data).type === errorConstants.INVALID_OTP_TYPE){
            	vm.otpFailed = true;
        	}else if(angular.fromJson(response.data).type === errorConstants.USER_BLOCKED_TYPE){
        		vm.userBlocked = true;
        		vm.userBlockedError = angular.fromJson(response.data).title;
        	}else{
        		vm.authenticationError = true;
        	}
        }else{
            vm.authenticationError = true;
        }
            vm.clicked = false;
        }
        
        $rootScope.$on('gatewayApp.messagebox.ok.login', function (event, data) {
        	if($state.current.name === 'login'){
        		$state.go('change-password-expiry', {login: vm.username, orgType : vm.orgTypeParam, action: 'Expiry'});	
        	}
        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.ok.resendotp', function (event, data) {
        	if($state.current.name === 'login'){
        		window.location.reload();	
        	}
        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.resendotp', function (event, data) {
        	if($state.current.name === 'login'){
        		window.location.reload();	
        	}
        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.ok.invalidotp', function (event, data) {
        	if($state.current.name === 'login'){
        		window.location.reload();	
        	}
        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.invalidotp', function (event, data) {
        	if($state.current.name === 'login'){
        		window.location.reload();	
        	}
        	      
        });
        
        function loginWithOTP(){
        	vm.otpFailed = false;
        	vm.resendOTPCount=0;
        	vm.wrongOTPCount=vm.wrongOTPCount+1;
			if(vm.wrongOTPCount>=globalValue.maximumOTPWrongAttemptCount) // check if the wrong otp was entered for 3 or more times 
			{
				Account.verifyOTP({login: vm.username, otp: vm.otp, exceed: true}, onOTPSuccess, onOTPFailure);	
			}else{
				Account.verifyOTP({login: vm.username, otp: vm.otp, exceed: false}, onOTPSuccess, onOTPFailure);
			}
        	
        }
        
        function onOTPSuccess(result){

			// vm.otpVerification = result.isVerified;
			// if(vm.otpVerification){
                if(result.isVerified=='VERIFIED'){
				//check password expiry warning and sign in
				loginToSystem();
			}
		
        }	

        function checkPasswordExpiryWarning(){
        	var userCredential = {};
        	userCredential.login = vm.username;
        	userCredential.password = vm.password;
        	Account.checkPasswordExpiry(userCredential, onPasswordExpriryValidationSuccess, onPasswordExpriryValidationError);
        }
        
        function loginToSystem(){

            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                skipOTP: vm.skipOTP,
                orgType : vm.orgType
            }).then(function () {
            	GlobalValues.clearPreviousValues();
                vm.authenticationError = false;
                vm.LockError = false;
                vm.dormantError = false;
                vm.tradingNotActivatedError = false;
                vm.noActiveBankRelationship = false;
                vm.passwordExpiryError = false;
                vm.userSuspendedError = false;
                vm.userDelinkedError = false;
                vm.userEntitySuspendedError = false;
                vm.userBlocked = false;
                checkPasswordExpiryWarning();
                /*if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset' || 
                    $state.current.name === 'change-password-expiry' || $state.current.name === 'change-password') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    if (previousState.name === 'register' || previousState.name === 'activate' ||
                            previousState.name === 'finishReset' || previousState.name === 'requestReset' || 
                            previousState.name === 'change-password-expiry' || previousState.name === 'change-password') {
                    	$state.go('home');
                    }else{
                    	$state.go(previousState.name, previousState.params);
                    }
                    
                }
                else {
                	$state.go('home');
                }*/
            }).catch(function (response) {
                if(response.status!=403){
            	vm.authenticationError = true;
                vm.clicked = false;
            	if(angular.fromJson(response.data).type === errorConstants.USER_LOCKED_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.dormantError = false;
            		vm.LockError = true;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.USER_DORMANT_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.LockError = false;
            		vm.dormantError = true;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.TRADING_NOT_ACTIVATED_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.tradingNotActivatedError = true;
            		vm.userDelinkedError = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.PASSWORD_EXPIRED_TYPE){
            		vm.authenticationError = false;
            		//vm.passwordExpiryError = true;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = false;
            		MessageBox.openMessageBox('Alert', 'Password is expired. Please proceed to set a new Password...!', 'Ok', 'login');
            		//$state.go('change-password-expiry', {login: vm.username});
            	}else if(angular.fromJson(response.data).type === errorConstants.PASSWORD_SYSTEM_GENERATED_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = false;
            		Auth.storePreviousState('change-password-expiry', {login: vm.username,orgType : vm.orgTypeParam} );
            		MessageBox.openMessageBox('Alert', 'This is a Temporary Password. Please proceed to set a new Password...!', 'Ok', 'login');
            	}else if(angular.fromJson(response.data).type === errorConstants.USER_SUSPENDED_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.userSuspendedError = true;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.USER_ENTITY_SUSPENDED_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = true;
            		vm.userDelinkedError = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.NO_ACTIVE_BANK_RELATIONSHIP){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = true;
            		vm.userSuspendedError = false;
            		vm.userDelinkedError = false;
            		vm.userEntitySuspendedError = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.USER_DELINKED_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = true;
            	}else if(angular.fromJson(response.data).type === errorConstants.INVALID_OTP_TYPE){
            		vm.authenticationError = false;
            		vm.passwordExpiryError = false;
            		vm.LockError = false;
            		vm.dormantError = false;
            		vm.tradingNotActivatedError = false;
            		vm.noActiveBankRelationship = false;
            		vm.userSuspendedError = false;
            		vm.userEntitySuspendedError = false;
            		vm.userDelinkedError = false;
            		vm.otpFailed = true;
    				vm.clicked = false;
            	}else if(angular.fromJson(response.data).type === errorConstants.USER_BLOCKED_TYPE){
            		vm.authenticationError = false;
            		vm.userBlocked = true;
            		vm.userBlockedError = angular.fromJson(response.data).title;
            	}
            }else{
                vm.authenticationError = true;
                vm.clicked = false;
            }
            });

        }
        
        function signIn(){
//			if ($state.current.name === 'register' || $state.current.name === 'activate' ||
//                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset' || 
//                    $state.current.name === 'change-password-expiry' || $state.current.name === 'change-password') {
//                    $state.go('home');
//                }else{
//                	$state.go('home');
//                }
        	    $state.go('home');
                $rootScope.$broadcast('authenticationSuccess');
                $rootScope.sessionExpired = false;
                User.insertSessionLog();

                /*if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    if (previousState.name === 'register' || previousState.name === 'activate' ||
                            previousState.name === 'finishReset' || previousState.name === 'requestReset' || 
                            previousState.name === 'change-password-expiry' || previousState.name === 'change-password') {
                    	$state.go('home');
                    }else{
                    	$state.go(previousState.name, previousState.params);
                    }
                    
                }
                else {
                	$state.go('home');
                }*/
        }
        
        function onPasswordExpriryValidationSuccess(result){
        	if(result.passwordExpiryDays!=-1){
        		MessageBox.openMessageBox('Alert', "Your password will be expired in " + result.passwordExpiryDays + " day(s). Do you want to reset your password?", 'YesNo', "passwordexpirywarning");
        	}else{
        		signIn();
        	}
        }
        
        $rootScope.$on('gatewayApp.messagebox.yes.passwordexpirywarning', function (event, data) {
        	if($state.current.name === 'login'){
            	vm.confirmOTP = false;
        		$state.go('change-password-expiry', {login: vm.username,orgType : vm.orgTypeParam, action: 'Expiry'});	
        	}
        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.no.passwordexpirywarning', function (event, data) {
        	if($state.current.name === 'login'){
        		signIn();
        	}
        	      
        });
        
        $rootScope.$on('gatewayApp.messagebox.clear.passwordexpirywarning', function (event, data) {
        	if($state.current.name === 'login'){
        		signIn();
        	}
        	      
        });
        
        /*
         * allow only 30 characters in password and only some special characters
         */
        $scope.$watch('vm.password', function(newVal, oldVal) {
        	 if(newVal!=null||newVal!=undefined)
	        	{
	        		 if(newVal.length > 30) {       
	        			 vm.password = oldVal;
	        		 }
	        		 if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
	        		 {
	        			 vm.password = oldVal;
	        		 }
	        	}
        	});

        function onPasswordExpriryValidationError(){
        	
        }
        
        function onOTPFailure(result){
            
				//vm.wrongOTPCount=vm.wrongOTPCount+1;
				if(vm.wrongOTPCount>=globalValue.maximumOTPWrongAttemptCount) // check if the wrong otp was entered for 3 or more times 
				{
					vm.otpFailed=false;
					vm.wrongOTPLimitExceeded=true;		//display message
	        		MessageBox.openMessageBox('Alert', 'Maximum wrong OTP attempt count is exceeded! Login blocked. Please try after ' + vm.configSettings.timePeriodToBlockUserLoginInMinutes + ' minutes.', 'Ok', 'invalidotp');
	        		//MessageBox.openMessageBox('Alert', $filter('translate')('gatewayApp.error.UserBlockedForMaximumWrongOTPCount'), 'Ok', 'invalidotp');
				}
				else{
					vm.otpFailed = true;
					vm.clicked = false;
				}

			
        	console.log(result);
        }
        
        function register () {
            $state.go('register');
        }

        function requestResetPassword () {
            $state.go('requestReset', {action: 'RESET', orgType : vm.orgTypeParam});
        }
        
        function requestUnlockUser () {
            $state.go('requestUnlock', {action: 'UNLOCK', orgType : vm.orgTypeParam});
        }
            
        
        vm.showPwd = function showPwd(controlType){
        	//vm.showPassword=!vm.showPassword;
        	if(controlType=="password"){
        		vm.controlType = "text";		
        	}
        	if(controlType=="text"){
        		vm.controlType = "password";		
        	}
        }
        
        vm.resendOTP = function resendOTP(){
        	if(vm.resendOTPCount>=globalValue.maximumOTPResendAttemptCount) 
			{
        		MessageBox.openMessageBox('Alert', 'Maximum request for resend OTP is exceeded! Please try again.', 'Ok', 'resendotp');
			}else{
	        	Account.sendOTP({login: vm.username}, onOtpResendSuccess, onOtpResendError);
			}
        }

        function onOtpResendSuccess(result){
        	vm.resendOTPCount=vm.resendOTPCount+1;
        	vm.wrongOTPCount=0;
        	vm.wrongOTPLimitExceeded=false;
        	vm.sendOTPSuccess = false;
        	vm.resendOTPSuccess = true;
        }
        
        function onOtpResendError(){
            MessageBox.openMessageBox('Alert', 'Too many requests - try again later.', 'Ok', 'resendotp')
		
        }
        
        vm.openCustomerOnboarding = function openCustomerOnboarding(){
        	$state.go('customerRegistration');
        }
        
        vm.resubmitApplication = function resubmitApplication(){
        	$state.go('request-reference-number', {refNo: vm.refNo});
        }

       function setEnvironmentName() {
        if( vm.configSettings.environmentName !='' &&   vm.configSettings.environmentName!=undefined &&  vm.configSettings.environmentName!=null){
            vm.environmentName = vm.configSettings.environmentName.toUpperCase();
        }
        
       }
        
    }

})();
