(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('session-log', {
            parent: 'entity',
            url: '/session-log',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.AuditLog.sessionLog" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/session-log-report/session-log-report.html',
                    controller: 'SessionLogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('sessionLog');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }],
                entity: function(){
                	
                }
            }
        })
        .state('session-manage-users-view', {
            parent: 'session-log',
            url: '/users/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-users/users-detail.html',
                    controller: 'UsersViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['User', 'GlobalValues', function(User, GlobalValues) {
                                     return User.get({userId : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
       
       };
       
    })();