(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("OrganisationManipulation", OrganisationManipulation);
       
    OrganisationManipulation.$inject = [];

    function OrganisationManipulation() {
    	
    	var service = {
    		checkAndGetGivenObjectListWithOrganisation: checkAndGetGivenObjectListWithOrganisation,
    		checkAndGetGivenObjectListWithOrganisationDTO: checkAndGetGivenObjectListWithOrganisationDTO,
    		checkAndGetOrganisation: checkAndGetOrganisation,
    		checkAndGetOrganisationDTO: checkAndGetOrganisationDTO
    	};
    	return service;
    	
        function checkAndGetGivenObjectListWithOrganisation(inputObjectList){
          	var objectListWithOrganisation = [];
          	
          	inputObjectList.forEach(function(item) {
          		item.organisation = checkAndGetOrganisation(item.organisation);
          		objectListWithOrganisation.push(item);
          	});
          	
          	return objectListWithOrganisation;
          }
          
          function checkAndGetGivenObjectListWithOrganisationDTO(inputObjectList){
          	var objectListWithOrganisationDTO = [];
          	
          	inputObjectList.forEach(function(item) {
          		item.organisation = checkAndGetOrganisationDTO(item.organisation);
          		objectListWithOrganisationDTO.push(item);
          	});
          	
          	return objectListWithOrganisationDTO;
          }
          
          function checkAndGetOrganisation(organisationDTO){
        	if (organisationDTO == null){
        		return null;
        	}
        	else if (organisationDTO.corporate != null){
          		return organisationDTO.corporate;
          	} 
        	else if (organisationDTO.customer != null){
          		return organisationDTO.customer;
          	} 
        	else if (organisationDTO.bank != null){
          		return organisationDTO.bank;
          	} 
        	else if (organisationDTO.clearcorp != null){
          		return organisationDTO.clearcorp;
          	} 
          	else{
          		return organisationDTO.consultant;
          	}
          }
          
          function checkAndGetOrganisationDTO(organisation){
          	var organisationDTO = {}
          	
          	if (organisation.organisationType.shortName == "CLEARCORP"){
          		organisationDTO.clearcorp = organisation;
          	} 
          	else if (organisation.organisationType.shortName == "BANK"){
          		organisationDTO.bank = organisation;
          	}
          	else if (organisation.organisationType.shortName == "CUSTOMER"){
          		organisationDTO.customer = organisation;
          	}
          	else{
          		organisationDTO.consultant = organisation;
          	}
          	
          	return organisationDTO;
          }
    }
})();