(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'tacUpload'
	})

	.controller('ClearcorpTACViewController', ClearcorpTACViewController);

	ClearcorpTACViewController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
		'AlertService','$rootScope','ClearcorpTACUpload','$timeout','TacViewForEntitiesUtility'];

	function ClearcorpTACViewController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
			AlertService,$rootScope,ClearcorpTACUpload,$timeout,TacViewForEntitiesUtility) {

		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.TERMS_AND_CONDITIONS_VIEW;
		vm.uploadScreenName = entityConstants.ENTITY_SHORT_NAME.CLEARCORP_TAC_UPLOAD;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.filterData = {};	    
		vm.extension = "";
		workflowSharedService.viewFrom = "tacUpload";
		logScreenAccess();
		loadAll();
		vm.valid = false;
		//vm.clearcorpTACDetails = entity
		vm.openTACPreview = openTACPreview;
		vm.uploadTAC = uploadTAC;
		$scope.refresh=refresh;
		 //removing the permission to avoid the conflict
		//vm.permitTACImportEntityAction = EntityPermissions.hasPermission('TermsAndConditionsUpload.IMPORT');
		//vm.permitTACViewEntityAction = EntityPermissions.hasPermission('TermsAndConditionsView.VIEW');


		function base64ToArrayBuffer(base64) {
			var binary_string =  window.atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array( len );
			for (var i = 0; i < len; i++)        {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		}

		function openTACPreview(tacFile){
			/*var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);

			var contentByteArray = base64ToArrayBuffer(pdfContent);
			var file = new Blob([contentByteArray], {type: 'application/pdf'});
			$scope.fileURL = URL.createObjectURL(file);
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(file, "TermsAndConditions.pdf");
			}
			else {
				var win =  window.open($scope.fileURL, "_blank", "width=700,height=700,"+"top="+y+", left="+x);  

			}*/
			TacViewForEntitiesUtility.previewTAC(tacFile.fileContent,tacFile.fileName);	
		}

		function uploadTAC(tacDetails){
			ScreenLog.screenLog(vm.uploadScreenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
			$state.go('terms-condition-upload',{id:tacDetails.id});

		}

		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		vm.loadAll = loadAll;
		function loadAll() {
			vm.clearcorpTACDetails = [];
			ClearcorpTACUpload.getTermsConditionDetails({organisationId:GlobalValues.getOrgId()},function(result) {
				vm.clearcorpTACDetails = result;

			});
		}


		$scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };

	}
})();