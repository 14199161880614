(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('EmailSettingsDialogController', EmailSettingsDialogController);

    EmailSettingsDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','Workflow','$uibModalInstance',
		'EmailSettings','entity','EmailCategory','DateUtils','AlertService','$filter'];

     function EmailSettingsDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService,Workflow, $uibModalInstance,
	 			EmailSettings,entity,EmailCategory,DateUtils,AlertService,$filter) {
        var vm = this;
        vm.emailSettings=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitEmailSettings = resubmitEmailSettings;
        vm.globalValues = GlobalValues;
        vm.discardEmailSettings = discardEmailSettings;
        vm.rejectEmailSettings= rejectEmailSettings;
        vm.approveEmailSettings= approveEmailSettings;
       vm.viewMode=false;
       vm.emailCategory = getEmailCategories();
       vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
       vm.Valid=false;
       vm.invalidEmails = [];
      
       vm.entity=vm.entityOwnerType;
       
       
       function getEmailCategories(){
    	   vm.entityOwnerTypeId = vm.globalValues.getEntityOwnerType().id;
    	   EmailCategory.getEmailCategoryForEntity({entityOwnerTypeId: vm.entityOwnerTypeId},function(result) {
               vm.emailCategory = result;
            }); 
       }
     
       function validateEmail(email) {
    	    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    	    return re.test(email);
    	}

       function testEmailValidation(email){
    	

    	var emails = email.split(';');

    	vm.invalidEmails=[];

    	for (var i = 0; i < emails.length; i++) { 
    	    if(!validateEmail(emails[i].trim())) {
    	      vm.invalidEmails.push(emails[i].trim())
    	    }
    	}

    	if(vm.invalidEmails.length>0){
    		
    		vm.Valid=false;
    	}else{
    		vm.Valid=true;
    	}

       }
    	
       $scope.$watch('vm.emailSettings.emailIds', function(value){
            if(value!=null && value!=undefined && value != ''){
                vm.emailSettings.emailIds = vm.emailSettings.emailIds.toLowerCase();
            }
       });
                
        if(workflowSharedService.viewFrom == 'emailSettings'){
        	vm.emailSettings=entity;
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'emailSettings_todolist_resubmit') {
        	vm.emailSettings =  angular.fromJson($rootScope.payload);
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'emailSettings_todolist_checker') {
        	vm.emailSettings =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'emailSettings_todolist_maker') {
        	vm.emailSettings =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='emailSettings_auditlog'){
        	vm.emailSettings =  angular.fromJson($rootScope.payload);   
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
		
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	vm.emailSettings.organisation=null;
        	if(vm.entityOwnerType=='Branch'){
        		vm.emailSettings.branch = vm.globalValues.getBranch();
        	}
        	
        	if(vm.emailSettings.emailIds!=null && vm.emailSettings.emailIds!=""){
        	testEmailValidation(vm.emailSettings.emailIds);
        	if(vm.Valid==true){
        		if (vm.emailSettings.id != null) {
    				EmailSettings.update({
    					"orgId" : GlobalValues.getOrgId()
    				}, vm.emailSettings, onSaveSuccess, onSaveError);
    			} else {
    				EmailSettings.save({
    					"orgId" : GlobalValues.getOrgId()
    				}, vm.emailSettings, onSaveSuccess, onSaveError);
    				
    			}
        	}else{
        		var error = $filter('translate')('gatewayApp.emailSettings.invalidEmail');
        		AlertService.error(error + vm.invalidEmails);
        		//AlertService.error($filter('translate')('gatewayApp.emailSettings.invalidEmail'),invalidEmails);
        	}
        	}else{
        		if (vm.emailSettings.id != null) {
    				EmailSettings.update({
    					"orgId" : GlobalValues.getOrgId()
    				}, vm.emailSettings, onSaveSuccess, onSaveError);
    			} else {
    				EmailSettings.save({
    					"orgId" : GlobalValues.getOrgId()
    				}, vm.emailSettings, onSaveSuccess, onSaveError);
    				
    			}
        	}
        	
        	
        	
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitEmailSettings() {
        	
        	vm.emailSettings.organisation=null;
        	if(vm.entityOwnerType=='Branch'){
        		vm.emailSettings.branch = vm.globalValues.getBranch();
        	}
        	if(vm.emailSettings.emailIds!=null && vm.emailSettings.emailIds!=""){
        	testEmailValidation(vm.emailSettings.emailIds);
        	if(vm.Valid==true){
        		EmailSettings.resubmit({"taskId" : $rootScope.taskID}, vm.emailSettings, onResubmitSuccess, onResubmitError);
            	
           		
            	$uibModalInstance.dismiss('cancel');
        	}else{
        		var error = $filter('translate')('gatewayApp.emailSettings.invalidEmail');
        		AlertService.error(error + vm.invalidEmails);
        		//AlertService.error($filter('translate')('gatewayApp.emailSettings.invalidEmail'),invalidEmails);
        	}
        	}else{
        		EmailSettings.resubmit({"taskId" : $rootScope.taskID}, vm.emailSettings, onResubmitSuccess, onResubmitError);
            	
           		
            	$uibModalInstance.dismiss('cancel');
        	}
        	
        	
        			
        		
        	}
        	
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked= false;
        }

        function approveEmailSettings() { 
        	EmailSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectEmailSettings() { Workflow.rejectTask($rootScope.taskID); }

        function discardEmailSettings() {
        	EmailSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

     }   
})();
