(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'orderServerManualLogin'
        })
        
        .controller('OrderServerManualLoginController', OrderServerManualLoginController);

    OrderServerManualLoginController.$inject = ['$scope', '$state',  'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout',
    		'OrderServerManualLogin','MessageBox','$rootScope','AlertService', '$filter'];

    function OrderServerManualLoginController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,
    		OrderServerManualLogin,MessageBox,$rootScope,AlertService,$filter) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDER_SERVER_MANUAL_LOGIN;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        workflowSharedService.viewFrom = "orderServerManualLogin";
        vm.screenLog = ScreenLog;
        var yesMessageEvent = null;
        var startRecoveryEvent=null;
        logScreenAccess();
        
        vm.orderServerManualLogin = orderServerManualLogin;
        
        vm.todayDate = new Date();
      
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       
       function orderServerManualLogin(){
    	   
    	   MessageBox.openMessageBox("Alert", "Do you want to manually login to order server?", "YesNo", "orderServerManualLogin");
       }
       
       $scope.$on('$destroy', function() {
 		  if (yesMessageEvent!=null )
 			  	yesMessageEvent();
	        });
   
 		
    	 yesMessageEvent = $rootScope.$on('gatewayApp.messagebox.yes.orderServerManualLogin', function (event, data) {
    		 	vm.orderServerManualLoginStatus = "PROGRESS";
 				OrderServerManualLogin.manualLogin(orderServerManualLoginSuccess,orderServerManualLoginFailure);
 				
 			
 		});
 	
 
		$rootScope.$on('gatewayApp.messagebox.no.orderServerManualLogin', function (event, data) {
			//do nothing
		});
		
		function orderServerManualLoginSuccess(error){
			
		}
		
		function orderServerManualLoginFailure(error){
			//alert(error.message);
			vm.orderServerManualLoginStatus = "";
			if(error.status==503){
				
				AlertService.error($filter('translate')( "error.order.serviceUnavailable"));
			}else if(error.status==403){
				//since session expiry  popup is diplayed do not show any error for 403 status
				//AlertService.error($filter('translate')( "Service Unavailable"));
			}
			else{
				AlertService.error($filter('translate')( error.data));
			}
		}
		
		 startRecoveryEvent = $rootScope.$on('orderServerManualLogin', function (event, data) {
	 			if(data!=null){
	 				
	 				if(data.message=="success"){
	 					//vm.orderServerManualLoginStatus = "SUCCESS";
	 					vm.orderServerManualLoginStatus = "";
	 					console.log("Order server manual login successful " + data.message);
	 					AlertService.success($filter('translate')('global.menu.orderServerManualLogin.success'));
	 				}else{
	 					//vm.orderServerManualLoginStatus = "FAILURE";
	 					vm.orderServerManualLoginStatus = "";
	 					console.log("Order server manual login failed " + data.message);
	 					AlertService.error(data.message);
	 				}
	 			}
	 			
	 		});
	 	
		 $scope.$on('$destroy', function() {
	 		  if (startRecoveryEvent!=null )
	 			 startRecoveryEvent();
		        });
     
      
    }
})();
