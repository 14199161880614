(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ApprovalPolicyDialogController', ApprovalPolicyDialogController);

    ApprovalPolicyDialogController.$inject = ['Workflow', '$scope', '$state', 'entity', 'mode', '$rootScope', '$uibModalInstance', 'workflowSharedService', 'ApprovalPolicy', 'GlobalValues', 'OrganisationManipulation', 'ScreenLog','entityConstants','AlertService'];

    function ApprovalPolicyDialogController(Workflow, $scope, $state, entity, mode, $rootScope,  $uibModalInstance, workflowSharedService, ApprovalPolicy, GlobalValues, OrganisationManipulation, ScreenLog,entityConstants,AlertService) {
        var vm = this;
        vm.save = save;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.APPROVAL_PROCESS;
        vm.screenLog = ScreenLog;
        vm.approvalPolicyDTO = {};
        vm.ownerOrganisation={};
        vm.approvalPolicyRolesList = [];
        vm.approvalPolicyRoleIds = [];
        
        vm.selectedPolicyOrganisationRole = null;
        vm.error = null;
        vm.validateNoOfApprovals = validateNoOfApprovals;
                
        vm.clear = clear;
        vm.approveApprovalPolicy = approveApprovalPolicy;
        vm.rejectApprovalPolicy = rejectApprovalPolicy;
        vm.discardApprovalPolicy = discardApprovalPolicy;
        vm.resubmitApprovalPolicy = resubmitApprovalPolicy;
        vm.addProcessRole = addProcessRole;
        vm.removePolicy = removePolicy;
        vm.globalValues = GlobalValues;
        vm.ownerOrganisation=vm.globalValues.getOrganisation();
        vm.ownerOrganisationId=vm.globalValues.getOrganisation().id;
        //vm.noOfApprovals=1;
        vm.clicked=false;
        
        if(workflowSharedService.viewFrom == 'approvalPolicy'){
	        vm.approvalPolicyDTO = entity;
	        
	        if(vm.approvalPolicyDTO.id==null || vm.approvalPolicyDTO.id==undefined){
		        vm.approvalPolicyDTO.isSendEmail = true;
	        }

	        if (mode == "View"){
	        	//ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': vm.approvalPolicyDTO.id, 'action': 'VIEW'});
	        	showButtonsViewScreen();
	        	vm.ViewDisable = true;
	        }
	        else if (mode == "Edit"){
                //ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': vm.approvalPolicyDTO.id, 'action': 'EDIT'});
	        	showButtonsManipulateScreen();
	        	vm.EditDisable = true;
	        }
	        else{
				//ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': 0, 'action': 'ADD'});
		        showButtonsManipulateScreen();
	        }
	        
        }
        else if (workflowSharedService.viewFrom == "entityActionApprovalPolicytodolist_checker"){
        	 vm.ViewDisable = true;
	   		 vm.approvalPolicyDTO = entity;
			 showButtonsViewScreen();
        }
        else if(workflowSharedService.viewFrom == 'approvalPolicytodolist_checker') {
        	vm.ViewDisable = true;

    		vm.approvalPolicyDTO =  angular.fromJson($rootScope.payload); 
    		showButtonsViewCheckerScreen();
        }
        else if(workflowSharedService.viewFrom == 'approvalPolicytodolist_maker') {
        	vm.ViewDisable = true;
        	vm.approvalPolicyDTO =  angular.fromJson($rootScope.payload); 
        	showButtonsViewMakerScreen();
        }
        
        else if(workflowSharedService.viewFrom == 'approvalPolicy_resubmit') {
        	vm.EditDisable = true;
        	vm.approvalPolicyDTO = angular.fromJson($rootScope.payload); 
            var entityRecordId = 0;
            if(vm.approvalPolicyDTO.id!=null){
  	      		entityRecordId = vm.approvalPolicyDTO.id;
  	      	}          
            vm.screenLog.screenLog(vm.screenName, entityRecordId, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	showButtonsResubmitScreen();
        }
        else if(workflowSharedService.viewFrom == 'approvalPolicy_auditLog') {
        	vm.ViewDisable = true;

    		vm.approvalPolicyDTO =  angular.fromJson($rootScope.payload); 
    		showButtonsViewScreen();
        }
        if(vm.approvalPolicyDTO.organisationDTO!=null){
            vm.ownerOrganisation = OrganisationManipulation.checkAndGetOrganisation(vm.approvalPolicyDTO.organisationDTO);
            
        }
        
        if (vm.approvalPolicyDTO.approvalPolicyRoles != null){
            vm.approvalPolicyRolesList = OrganisationManipulation.checkAndGetGivenObjectListWithOrganisation(vm.approvalPolicyDTO.approvalPolicyRoles);
        	fetchExistingRoleIDs();
        }
        
        function fetchExistingRoleIDs(){
        	vm.approvalPolicyDTO.approvalPolicyRoles.forEach(function(item) {
        		  vm.approvalPolicyRoleIds.push(item.organisationRole.id);
        	});
        }
        
        function addProcessRole(){
        	var approvalPolicyRoleDTO = {}
        	
        	if (vm.selectedPolicyOrganisationRole!=null && validateDuplicationOfRole() && vm.validateNoOfApprovals()){
        		console.log(vm.selectedPolicyOrganisationRole);
	        	vm.approvalPolicyRoleIds.push(vm.selectedPolicyOrganisationRole.id);
	        	approvalPolicyRoleDTO.organisation =  vm.ownerOrganisation;
	        	approvalPolicyRoleDTO.organisationRole = vm.selectedPolicyOrganisationRole;
	        	approvalPolicyRoleDTO.noOfApprovals=vm.noOfApprovals;
	        	vm.approvalPolicyRolesList.push(approvalPolicyRoleDTO);
	            
	            vm.selectedPolicyOrganisationRole = null;
	            vm.noOfApprovals="";
        	}
        }
        
        function validateDuplicationOfRole(){
        	vm.error = "";
        	
        	if (vm.approvalPolicyRoleIds.indexOf(vm.selectedPolicyOrganisationRole.id) > -1){
        		AlertService.error("Role already exists in the process.");
        		//vm.error = "Role already exists in the process."
        		return false;
        	}
        	return true;
        }
        function validateNoOfApprovals(){
        	vm.error = "";
        	
        	if (vm.noOfApprovals =="" || vm.noOfApprovals ==null|| vm.noOfApprovals >5 || vm.noOfApprovals < 1){
        		//vm.error = "No of Approvals should be 1 to 5."
        		AlertService.error("No of Approvals should be 1 to 5.");
        		return false;
        	}
        	return true;
        }
        
        function validateExistanceOfLevels(){
        	vm.error = "";
        	
        	if (vm.approvalPolicyRoleIds.length == 0){
        		AlertService.error("Workflow levels should not be empty.");
        		//vm.error = "Workflow levels should not be empty."
        		return false;
        	}
        	return true;
        }
        
        function removePolicy(rowId){
        	vm.approvalPolicyRoleIds.splice(rowId,1);
        	vm.approvalPolicyRolesList.splice(rowId,1);
        	$scope.approvalPolicyForm.$setDirty();
        }
        
        function showButtonsViewScreen(){
            vm.btnClose = true;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        
        function showButtonsManipulateScreen(){
        	vm.ViewDisable = false;
            vm.btnClose = false;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = true;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = false;
        }
        function showButtonsViewCheckerScreen(){
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        	vm.btnClose = true;
        	$scope.showDiscardButton = false;
        	vm.btnApproveReject = true;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        function showButtonsResubmitScreen(){
        	vm.btnClose = false;
        	$scope.showButton=true;
        	vm.btnApproveReject = false;
        	$scope.showDiscardButton = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = true;
        }
        function showButtonsViewMakerScreen(){
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        	vm.btnClose = true;
        	vm.btnApproveReject = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }
		
		function save () {	
			if (validateExistanceOfLevels()){
				vm.approvalPolicyDTO.approvalPolicyRoles = vm.approvalPolicyRolesList;
				if (mode == "Edit"){
					ApprovalPolicy.update({"orgId":GlobalValues.getOrgId()}, getActualApprovalPolicyDTO(), onSaveSuccess, onSaveError);
				}
				else {
					ApprovalPolicy.save({"orgId":GlobalValues.getOrgId()}, getActualApprovalPolicyDTO(), onSaveSuccess, onSaveError);
				}
			}else{
				vm.clicked=false;
			}
        }
     
        function onSaveSuccess (result) {
            $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.clicked=false;

        }
        function approveApprovalPolicy() { 
        	ApprovalPolicy.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	vm.clicked=false;
        }
       
        function rejectApprovalPolicy() { Workflow.rejectTask($rootScope.taskID);}

        function discardApprovalPolicy() {
        	ApprovalPolicy.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {

        }
        function resubmitApprovalPolicy() {
			if (validateExistanceOfLevels()){
	        	ApprovalPolicy.resubmit({"taskId" : $rootScope.taskID}, getActualApprovalPolicyDTO(), onResubmitSuccess, onResubmitError);
	          	$uibModalInstance.dismiss('cancel');
			}
          }
          
          function onResubmitSuccess(result) {
          	$rootScope.removeRow($rootScope.taskID);
          }        
          function onResubmitError(error) {
        	  vm.clicked=false;
          }
          
          function getActualApprovalPolicyDTO(){
        	  vm.approvalPolicyDTO.organisationDTO = OrganisationManipulation.checkAndGetOrganisationDTO(vm.ownerOrganisation);
        	  vm.approvalPolicyDTO.approvalPolicyRoles = OrganisationManipulation.checkAndGetGivenObjectListWithOrganisationDTO(vm.approvalPolicyRolesList);
        	  
        	  return vm.approvalPolicyDTO;
          }
        
    }
})();
