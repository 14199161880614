(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'pendingLimitRequestsView'
        })
        
        .controller('PendingLimitRequestsViewController', PendingLimitRequestsViewController);

    PendingLimitRequestsViewController.$inject = ['$scope', 'GlobalValues', 'ScreenLog','entityConstants', 
    	'DateUtils', 'PendingLimitRequestsViewService',  'systemSetting'];

    function PendingLimitRequestsViewController($scope, GlobalValues, ScreenLog,entityConstants, 
    		DateUtils, PendingLimitRequestsViewService, systemSetting) {
       
    	var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.PENDING_LIMIT_REQUESTS_VIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
		$scope.noOfRecordsPerPageDetail = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.pageDetail = 1;

        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
       
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
       
	    vm.getPendingLimitRequestsView = getPendingLimitRequestsView;
		vm.getPendingLimitRequestDetails = getPendingLimitRequestDetails;
		vm.getTotalPendingLimitRequestDetails = getTotalPendingLimitRequestDetails;
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    
	    vm.filterData = {};
	    vm.fileName = "Pending_Limit_Requests_View_";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
		vm.totalCustomerLimitRequests = 0;
    	vm.totalBankLimitRequests = 0;
		vm.pendingLimitRequestsViewList = [];
		vm.pendingRequestDetailsList = [];
		vm.requestType="";
	    
	    logScreenAccess();
	    vm.valid = false;
		vm.clicked=false;
	    if(systemSetting != null && systemSetting != undefined){
        	vm.currentDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);	//not working
        }
	    vm.todayDate = new Date();
		vm.reportDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        
        function getPendingLimitRequestsView(){
			var bankId = 0;
        	var branchId = 0;
        	var customerId = 0;

        	vm.pendingLimitRequestsViewList = [];
			vm.pendingRequestDetailsList = [];

			vm.valid = true;
			if(vm.bank!=null){
				bankId = vm.bank.id;
			}
			if(vm.branch!=null){
				branchId = vm.branch.id;
			}
			if(vm.customer!=null){
				customerId = vm.customer.id;
			}

			copyFilterParams(bankId, branchId, customerId);

			PendingLimitRequestsViewService.getPendingLimitRequestsView({"bankId": bankId, "branchId": branchId, "customerId": customerId},
				onGetPendingLimitRequestsSuccess, onGetPendingLimitRequestsFailure);
        	
        }

        function onGetPendingLimitRequestsSuccess(result){
			vm.totalCustomerLimitRequests = 0;
    		vm.totalBankLimitRequests = 0;
			vm.pendingLimitRequestsViewList = result;
			calculateHeaderTotal(result);
			vm.clicked=false;
        }

		function onGetPendingLimitRequestsFailure(){
        	vm.clicked=false;
        }

		function calculateHeaderTotal(list){
    		vm.totalCustomerLimitRequests = 0;
    		vm.totalBankLimitRequests = 0;
    		
    		angular.forEach(list, function(value) {
    			vm.totalCustomerLimitRequests = vm.totalCustomerLimitRequests + value.customerLimitRequestsCount;  
    			vm.totalBankLimitRequests = vm.totalBankLimitRequests + value.bankLimitRequestsCount; 
    		});
    		
    	}

		function copyFilterParams(bankId, branchId, customerId){
			vm.filterData = {};
			
			vm.filterData.bankId = bankId;
			vm.filterData.branchId = branchId;
			vm.filterData.customerId = customerId;

		}

		function getPendingLimitRequestDetails(limitRequestSummaryDTO, requestType){
			vm.pendingRequestDetailsList = [];
			vm.requestType = requestType;
			if(requestType=='BANK'){
				vm.pendingRequestDetailsList = limitRequestSummaryDTO.pendingBankRequestedLimitList;
			}else{
				vm.pendingRequestDetailsList = limitRequestSummaryDTO.pendingCustomerRequestedLimitList;
			}
		}

		function getTotalPendingLimitRequestDetails(requestType){
			vm.pendingRequestDetailsList = [];
			vm.requestType = requestType;
			angular.forEach(vm.pendingLimitRequestsViewList, function(value){
				if(requestType=='BANK'){
					angular.forEach(value.pendingBankRequestedLimitList, function(value1){
						vm.pendingRequestDetailsList.push(value1);
					});
				}else{
					angular.forEach(value.pendingCustomerRequestedLimitList, function(value2){
						vm.pendingRequestDetailsList.push(value2);
					});
				}
			})

		}
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }

		function exportDataToExcelFile(){
      	 
      	  if(vm.pendingLimitRequestsViewList  !=null &&   vm.pendingLimitRequestsViewList !=undefined &&  vm.pendingLimitRequestsViewList .length>0){
      		 
      			vm.mimeType = 'application/vnd.ms-excel';
      			vm.extension = ".xls";  
				var selectedRequests = angular.copy(vm.pendingLimitRequestsViewList);
				
				PendingLimitRequestsViewService.exportPendingLimitRequestsExcel({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, 
					customerId: vm.filterData.customerId}, selectedRequests, processResponse);
      		 
      	  }
        }       
        function exportDataToCsvFile(){        	
        		
        	  if( vm.pendingLimitRequestsViewList !=null &&  vm.pendingLimitRequestsViewList!=undefined && vm.pendingLimitRequestsViewList.length>0){
        		 
        			vm.mimeType = 'text/csv';
        			vm.extension = ".csv";  
					var selectedRequests = angular.copy(vm.pendingLimitRequestsViewList);
					
					PendingLimitRequestsViewService.exportPendingLimitRequestsCsv({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, 
						customerId: vm.filterData.customerId}, selectedRequests, processResponse);
					 
        	  }
        }
	    
	    function processResponse(result){
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString =     vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension;   	
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}

		//get length for rowspan
		vm.getLength = function getLength(limitRequestHeaderDTO){
			var len =0;
			angular.forEach(limitRequestHeaderDTO.limitRequestDetailsDTOList, function(value){
				len = len + 1;
			})
			return len;
		}

    }
})();