(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.controller('CCLoggedInUsersReportController', CCLoggedInUsersReportController);

	CCLoggedInUsersReportController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',  'AlertService','LoggedInUsersReport','$filter', 'ScreenLog','entityConstants','DateUtils', '$timeout'];

	function CCLoggedInUsersReportController( $scope, $state, $rootScope, GlobalValues,AlertService , LoggedInUsersReport , $filter , ScreenLog, entityConstants,DateUtils,$timeout){
		var vm = this;
		vm.globalValues = GlobalValues;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.NO_OF_LOGGEDIN_USER_REPORT;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
    	$scope.refresh = refresh;
    	vm.currentDateTime =moment().format( 'DD_MMM_YYYY_HH_mm');
        
        vm.overallSummary =[];
        vm.bankwiseSummary =[];
        vm.userDetailsList =[];
        vm.BANK_TYPE= "Bank";
        vm.BRANCH_TYPE= "Branch";
        vm.CUSTOMER_TYPE= "Customer";
        vm.ORDER_TYPE= "Order";
        vm.TRADE_TYPE= "Trade";
        vm.CLEARCORP = "Clearcorp";
        
        var recordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        //recordsPerPage=3;
        $scope.noOfRecordsPerPage = recordsPerPage;
        $scope.page = 1;      
        $scope.overallPage = 1;
        $scope.userPage = 1;
        $scope.noOfRecordsPerUSerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        
        vm.getUsersLoggedInForTheMoment = getUsersLoggedInForTheMoment;
        vm.getUsersLoggedInForTheDay = getUsersLoggedInForTheDay;
        vm.getUsersNotLoggedInForTheDay = getUsersNotLoggedInForTheDay;
        vm.getUsersFailedToLoginForTheDay = getUsersFailedToLoginForTheDay;
        vm.getUsersOrderPlacedForTheDay = getUsersOrderPlacedForTheDay;
        vm.getUsersTradedForTheDay = getUsersTradedForTheDay;
        vm.getCustomerUsersLoggedInForTheDay = getCustomerUsersLoggedInForTheDay;
        
        vm.selectedBankName = '';
        vm.selectedBankId = 0;	
        vm.selectedEntityType = '';
        
        vm.showUserDetailsDrillDown = false;
		vm.showDetailsForOrderorTradedUsers = false;
		vm.showCustomerDetails=false; 
		vm.showBankDetails = false;
		vm.showBranchDetails = false;
		
		//vm.exportToFile = exportToFile;
		        	    
 	   /*LoggedInUsersReport.getCurrentBusinessDate(getBusinnessDateSuccess, getBusinessDateFailed);
 	   
 	   function getBusinnessDateSuccess(result) {
 		  if (result.date) {
 			 vm.reportDate  = DateUtils.convertLocalDateFromServer(result.date);
          }
 		  else {vm.reportDate =  null;}
 		 		
		}
		function getBusinessDateFailed(error) {
			//Do Nothing
		}*/
		vm.todayDate=new Date();
		vm.reportDate  = DateUtils.convertDateTimeFromServer(vm.todayDate);
 	    
 	   logScreenAccess();
 	   loadHeader();
 	   
 	   function loadHeader(){
 		   
 		   	vm.overallSummary =[];
 		   	vm.totalAsOfNowCount = 0;
			vm.totalForTheDayCount = 0;
			vm.totalOrderUsersCount = 0;	
			vm.totalTradedUsersCount = 0;
			vm.totalNotLoggedInUsersCount = 0;
			vm.totalFailedLoginUsersCount = 0;
			vm.filterText="";
			vm.bankwiseSummaryfilterText="";
			vm.userSummaryFilterText ="";
			vm.bankwiseSummary =[];
			vm.userDetailsList = [];
			resetGridsForHeaderSelection();
 		  
 		   LoggedInUsersReport.getOverAllSummaryData(  reportHeaderSuccess, reportHeaderFailure );
 		   LoggedInUsersReport.getBankwiseSummaryData(  bankwiseSummarySuccess, bankwiseSummaryFailure );
 	   }
 	   function reportHeaderSuccess(result){
 		  
 		  vm.overallSummary = result;
 		  calculateHeaderTotal(vm.overallSummary);
 		  
 	   }
 	   function reportHeaderFailure(error){
 		   
 	   }
 	  function bankwiseSummarySuccess(result){
 		  
		  vm.bankwiseSummary  = result;
 	  }
	  function bankwiseSummaryFailure(error){
		   
	  }
 	  
 	  function logScreenAccess(){
			  vm.screenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	  }
	
 	 
 	  
 		/********** Refresh**********/
	  $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadHeader();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
	
	/***********END *************/
        function calculateHeaderTotal(list){
    		
        	vm.totalAsOfNowCount = 0;
    		vm.totalForTheDayCount = 0;
    		vm.totalOrderUsersCount = 0;
    		vm.totalTradedUsersCount = 0;
    		vm.totalNotLoggedInUsersCount = 0;
    		vm.totalFailedLoginUsersCount = 0;
    		
    		angular.forEach(list, function(overallCountForbank) {
    			
    			vm.totalAsOfNowCount = vm.totalAsOfNowCount + overallCountForbank.asOfNow;
        		vm.totalForTheDayCount = vm.totalForTheDayCount + overallCountForbank.forTheDay;
        		vm.totalOrderUsersCount = vm.totalOrderUsersCount + overallCountForbank.orderPlaced;
        		vm.totalTradedUsersCount = vm.totalTradedUsersCount + overallCountForbank.traded;
        		vm.totalNotLoggedInUsersCount = vm.totalNotLoggedInUsersCount + overallCountForbank.notLoggedIn;
        		vm.totalFailedLoginUsersCount = vm.totalFailedLoginUsersCount + overallCountForbank.failedLoginUsers;
    		});
    		
    	}
    	
    	$scope.$watch('vm.filterText', function(value){
    		calculateHeaderTotal(vm.filterData);
    		resetGridsForHeaderSelection();
        }); 
    	
    	$scope.$watch('vm.bankwiseSummaryfilterText', function(value){
    		
    		resetGridsForHeaderSelection();
        }); 
    	
    	function resetGridsForHeaderSelection(){
    		 vm.selectedBankName = '';
    	     vm.selectedBankId = 0;	
    	     vm.selectedEntityType = '';
    	     vm.userSummaryFilterText = '';
    	    // vm.userDetailsHeader = '';
    	        
    	     vm.showUserDetailsDrillDown = false;
    		 vm.showDetailsForOrderorTradedUsers = false;
    		 vm.showCustomerDetails=false; 
    		 vm.showBankDetails = false;
    		 vm.showBranchDetails = false;
    		
    	}
    	function userDetailsSuccess(result){
    		vm.userDetailsList = result;
    		angular.element("#userSearch").focus();
    	}
    	function userDetailsFailure(error){
    		
    	}
    	
    	function getUsersLoggedInForTheMoment(bankId, bankName, entityType, drillDownGridType){
    		
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getLoggedInUsersAsOFNow({'orgID' :bankId , 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getUsersLoggedInForTheDay(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getLoggedInUsersForTheDay({'orgID' :bankId , 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getUsersNotLoggedInForTheDay(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getNotLoggedInUsers({'orgID' :bankId , 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getUsersFailedToLoginForTheDay(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getFailedToLoginUsers({'orgID' :bankId , 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getUsersOrderPlacedForTheDay(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getUsersPlacedOrderForTheDay({'orgID' :bankId , 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getUsersTradedForTheDay(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getUsersPlacedTradeForTheDay({'orgID' :bankId , 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getCustomerUsersLoggedInForTheDay(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		LoggedInUsersReport.getCustomerLoggedInUsersForTheDay({'bankId' :bankId } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	
    	function setSelectionDetails(bankId, bankName, entityType,drillDownGridType){
    		 
    		vm.selectedBankName = bankName;
    	    vm.selectedBankId = bankId;	
    	    vm.selectedEntityType = entityType;
    	    vm.userSummaryFilterText = '';
    	    setColumDisplayForUserDetails(bankId, bankName, drillDownGridType);
    	}
    	function setColumDisplayForUserDetails(bankId, bankName, drillDownGridType){
    		vm.showUserDetailsDrillDown = true;
    		vm.showDetailsForOrderorTradedUsers = false;
    		vm.showCustomerDetails=false; 
    		vm.showBankDetails = false;
    		vm.showBranchDetails = false;
    		
    		if(drillDownGridType ==  vm.BANK_TYPE ){    			
        		vm.showBankDetails = true;
        		vm.userDetailsHeader = "Bank Users"
        		
    		}else if(drillDownGridType == vm.BRANCH_TYPE ){
    			
    			vm.showBranchDetails = true;
    			vm.userDetailsHeader = "Branch Users"
    			
    		}else if(drillDownGridType == vm.CUSTOMER_TYPE ){
    			vm.showCustomerDetails=true; 
    			vm.showDetailsForOrderorTradedUsers  = true;
    			vm.userDetailsHeader = "Customer Users"
    		}else if(drillDownGridType == vm.CLEARCORP){
    			vm.userDetailsHeader = "Clearcorp Users"
    		}
    		else if(drillDownGridType == vm.ORDER_TYPE || drillDownGridType == vm.TRADE_TYPE) {
    			vm.showCustomerDetails=true; 
        		vm.showBankDetails = true;
        		vm.showBranchDetails = true;
        		if(drillDownGridType == vm.ORDER_TYPE)
        			vm.userDetailsHeader = "Order placed Users of the day";
        		else if(drillDownGridType == vm.TRADE_TYPE)
        			vm.userDetailsHeader = "Traded Users of the day";
    		}
    	}
    	 /*function exportToFile(fileType){
          	  vm.baseString =   'No_Of_Logged_In_Users_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
          	  if( vm.overallSummary !=null &&  vm.overallSummary!=undefined){
          		  if(fileType == 'csv'){
          			 vm.mimeType = 'text/csv'
          			 vm.baseString = vm.baseString+ ".csv";  
          			LoggedInUsersReport.getCSVData(vm.overallSummary , processResponse )
          		  }else if(fileType == 'excel'){
          			  vm.mimeType = 'application/vnd.ms-excel';
          			  vm.baseString = vm.baseString+ ".xls";  
          			LoggedInUsersReport.getExcelData(vm.overallSummary , processResponse );
          		  }
          	  }
            }	
            function processResponse(result){
          	  var fileData = null;
      		  fileData = result.data;
      		  var link = document.createElement("a");   
      		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
      		  
      		  
      	      var blob = new Blob([bytes], {type: vm.mimeType});
      	      if(window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
      	      }else{
      	    	  link.href =  window.URL.createObjectURL(blob);      
      	    	  link.download = vm.baseString;
      	    	  document.body.appendChild(link);
      	    	  link.click();
      	    	  document.body.removeChild(link);
      	      }
      	  }*/
	}
})();