(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerRegistrationController', CustomerRegistrationController);

    CustomerRegistrationController.$inject = ['$scope', '$rootScope', '$state','$timeout', 'Auth', '$window', 'StaticLookUp', 
    'MessageBox', 'CustomerRegistration', '$sessionStorage', 'DateUtils', 'Country', 'GlobalValues', 'globalValue', 'AlertService', 
    '$filter','FileUtil', 'Branch', 'customerTypeWiseInputDetails', 'TACViewForEntitiesService', 'TacViewForEntitiesUtility'];

    function CustomerRegistrationController ($scope, $rootScope, $state, $timeout, Auth, $window, StaticLookUp, 
        MessageBox, CustomerRegistration, $sessionStorage, DateUtils, Country, GlobalValues, globalValue, AlertService,
        $filter,FileUtil, Branch, customerTypeWiseInputDetails, TACViewForEntitiesService, TacViewForEntitiesUtility) {
        var vm = this;
        vm.customerTypes = StaticLookUp.getCustomerTypes();
        //initialize customer type wise input details
        vm.customerTypeWiseInputDetails = customerTypeWiseInputDetails;

        //Indices of input details in customer type wise input details array
        $scope.firstNameIndex = 0;
        $scope.middleNameIndex = 1;
        $scope.lastNameIndex = 2;
        $scope.entityNameIndex = 3;
        $scope.panNoIndex = 4;
        $scope.panImageIndex = 5;
        $scope.legalEntityIdentifierIndex = 6;
        $scope.leiExpiryDateIndex = 7;
        $scope.cinIndex = 8;
        $scope.gstinIndex = 9;
        $scope.stateCodeIndex = 10;
        $scope.dobDoiIndex = 11;

        //window.onpopstate = function (e) { history.pushState(null, null, window.location.href); }
        vm.globalValues = GlobalValues;
        vm.customerInfo = true;
        vm.bankInfo=false;
        $sessionStorage.isAuth = true;
        vm.checkPanImage = false;
        vm.customerRegistrationRequest = {};
        vm.customerRegistrationRequest.gender = 'M';
        vm.customerRegistrationRequest.primaryMobileCountryCode = 91;
        vm.customerRegistrationRequest.alternateMobileCountryCode1 = 91;
        vm.customerRegistrationRequest.alternateMobileCountryCode2 = 91;
        vm.customerRegistrationRequest.userMobileCountryCode = 91;
        vm.customerRegistrationRequest.assignTradingBranch = false;
        vm.required = false;
        vm.otpLength = globalValue.defaultOTPLength;
        var Extensions = ["pdf","png","jpg","jpeg"];
        vm.todayDate=new Date();
        vm.leiExpiryRequired = false;
    	vm.disableLeiExpiry = true;
    	vm.leiMinDate = vm.todayDate +1;
        vm.skipOTPPrimaryContact=true;
        vm.skipOTPPrimaryEmailContact=true;
        vm.skipOTPUserContact=true;
        vm.skipOTPUserEmailContact=true;
    	vm.showSkipOTP = true;
    	if(globalValue.skipOTPVerification == 'No' || globalValue.skipOTPVerification == 'no'|| globalValue.skipOTPVerification == 'NO'){
            vm.skipOTPPrimaryContact=false;
            vm.skipOTPPrimaryEmailContact=false;
            vm.skipOTPUserContact=false;
            vm.skipOTPUserEmailContact=false;
    		vm.showSkipOTP = false;
    	}else{
            vm.skipOTPPrimaryContact=true;
            vm.skipOTPPrimaryEmailContact=true;
            vm.skipOTPUserContact=true;
            vm.skipOTPUserEmailContact=true;
    		vm.showSkipOTP = true;
    	}
    	//get rejected request by reference number
    	vm.refNo = "";
        vm.clearcorpOrgId = 1;
        vm.clearcorpForCustomerTACTypeId = 1;
        vm.bankForCustomerTACTypeId = 3;
    	vm.fetchRejectedRequest = fetchRejectedRequest;
        vm.openHelpWindow = openHelpWindow;
        vm.previewClearcorpTAC = previewClearcorpTAC;
        vm.previewBankTAC = previewBankTAC;

        $window.onresize = function() {
            changeTemplate();
        };
        changeTemplate();

        function changeTemplate() {
            var screenWidth = $window.innerWidth;
            if (screenWidth < 768) {
                vm.mobileView = true;
            } else if (screenWidth >= 768) {
                vm.mobileView = false;
            }
        }
        
        $scope.$watch('vm.customerRegistrationRequest.customerType', function(value){
        	// if(value!=null && value!=undefined){
        	// 	if(value.customerUserType.shortName=='SingleUserCustomer'){
        	// 		vm.required = true;
        	// 		vm.leiExpiryRequired = false;
        	// 		vm.disableLeiExpiry = true;
        	// 	}else if(value.customerUserType.shortName=='MultiUserCustomer'){
        	// 		vm.required = false;
        	// 		if(vm.customerRegistrationRequest.legalEntityIdentifier!=null 
        	// 				&& vm.customerRegistrationRequest.legalEntityIdentifier!=undefined 
        	// 				&& vm.customerRegistrationRequest.legalEntityIdentifier.length==20){
        	// 			vm.leiExpiryRequired = true;
        	// 			vm.disableLeiExpiry = false;
        	// 		}else{
        	// 			vm.leiExpiryRequired = false;
        	// 			vm.disableLeiExpiry = true;
        	// 		}
         //        	//vm.disableLeiExpiry = true;
         //        	//vm.customerRegistrationRequest.leiExpiryDate = null;
        	// 	}
        	// }
            if(value!=null && value!=undefined){
                vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
                    return item.customerType.id == value.id;
                });
            }else{
                vm.inputDetailsForSelectedCustType = [
                    {customerType: null, fieldName: 'FirstName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'MiddleName', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LastName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'EntityName', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'PANNo', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'PANImage', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'LegalEntityIdentifier', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LEIExpiryDate', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'CIN', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'GSTIN', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'StateCode', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'DOBDOI', display: true, isMandatory: true}
                ]
            }

        });
       
        getCountry();
        getClearcorpTACMaster();
        
        function getCountry(){
        	Country.get({id: 1}, onGetSuccess, onGetFailure);
        }
        
        function onGetSuccess(result){
            vm.customerRegistrationRequest.country=result;
            vm.customerRegistrationRequest.userCountry=result;
        }

        function onGetFailure(){
        		
        }

        function getClearcorpTACMaster(){
            TACViewForEntitiesService.getActiveTACForOrganisationAndTacType({orgId: vm.clearcorpOrgId, tacTypeId: vm.clearcorpForCustomerTACTypeId}, function(result){
                vm.clearcorpTAC = result;
            })
        }

        vm.invalidPanImageSize = false;
        vm.invalidPanImage = false;
        vm.isPrimaryMobileVerified = false;
        vm.isPrimaryEmailVerified = false;
        vm.checkPrimaryOTP = false;
        vm.checkPrimaryEmailOTP = false;
        vm.invalidPrimaryOtp = false;
        vm.invalidPrimaryEmailOtp = false;
        vm.checkUserOTP = false;
        vm.checkUserEmailOTP = false;
        vm.invalidUserOtp = false;
        vm.invalidUserEmailOtp = false;
        vm.visibleOTP = false;
        vm.visibleOTPEmail = false;
        vm.visibleUserOTP = false;
        vm.visibleUserOTPEmail = false;
        vm.isUserMobileVerified = false;
        vm.isUserEmailVerified = false;
        vm.submit = submit;
        vm.showGenerateOTPButton = false;
        vm.showGenerateOTPEmailButton = false;
        vm.disableGenerateOTP = true;
        vm.disableGenerateOTPEmail = true;
        vm.generateOTP = generateOTP;
        vm.generateOTPEmail = generateOTPEmail;
        vm.showGenerateUserOTPButton = false;
        vm.showGenerateUserOTPEmailButton = false;
        vm.disableGenerateUserOTP = true;
        vm.disableGenerateUserOTPEmail = true;
        vm.generateUserOTP = generateUserOTP;
        vm.generateUserOTPEmail = generateUserOTPEmail;
        vm.wrongPrimaryOTPCount=0;
        vm.wrongUserOTPCount=0;
      
        vm.minimumFileSizeInKB = globalValue.uploadedFileMinimumSize;
        vm.maximumFileSizeinKB = globalValue.uploadedFileMaximumSize;
        vm.invalidPanImageSixeText = "";
        vm.invalidPanImageFileNameText = "";
        vm.invalidPanImageText = "";
        vm.maxFileNameLength = (globalValue.uploadedFileNameLength != null && globalValue.uploadedFileNameLength != undefined && globalValue.uploadedFileNameLength != '') ?globalValue.uploadedFileNameLength : 150 ;
        var minFileSizeinBytes = 0;
        var maxFileSizeinBytes = 100;
        if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
        	minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
        }
        if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){
        	maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
        }

		$scope.otpInput = {
				size : globalValue.defaultOTPLength,
				type : "password",
				onDone : function(value) {
					vm.primaryMobileOtp = value;
				},
				onChange : function(value) {
					if(value!= undefined && value!=null){
		        		vm.checkPrimaryOTP = false;
		        		vm.isPrimaryMobileVerified = false;
		        		if(value.length==vm.otpLength){
		        			vm.checkPrimaryOTP = true;
		        			vm.invalidPrimaryOtp = false;
		                	if(vm.customerRegistrationRequest.primaryMobileCountryCode==null && vm.customerRegistrationRequest.primaryMobileCountryCode==undefined){
		                		vm.customerRegistrationRequest.primaryMobileCountryCode = "";
		                	}
		                	var mobileNo = vm.customerRegistrationRequest.primaryMobileCountryCode.toString() + vm.primaryMobile;
		            		CustomerRegistration.verifyOTP({mobile: mobileNo, otp: vm.primaryMobileOtp}, function(result){
		            			vm.otpVerification = result.isVerified;
		            			if(vm.otpVerification){
		            				vm.isPrimaryMobileVerified = true;
		            				vm.checkPanImage = true;
		            				vm.invalidPrimaryOtp = false;
		            			}else{
		            				vm.invalidPrimaryOtp = true;
		            				vm.isPrimaryMobileVerified = false;
		            				vm.wrongPrimaryOTPCount=vm.wrongPrimaryOTPCount+1;
		            				if(vm.wrongPrimaryOTPCount>=globalValue.maximumOTPWrongAttemptCount) // check if the wrong primary otp was entered for 3 or more times 
		            				{
		            					AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.");
		            				}
		            			}
		            		});
		        		}else{
		        			vm.checkPrimaryOTP = false;
		        		}
		        	}
				}
			};
		
		$scope.userotpInput = {
				size : globalValue.defaultOTPLength,
				type : "password",
				onDone : function(value) {
					vm.userMobileOtp = value;
				},
				onChange : function(value) {
					if(value!= undefined && value!=null){
		        		vm.checkUserOTP = false;
        				vm.isUserMobileVerified = false;
		        		if(value.length==vm.otpLength){
		        			vm.checkUserOTP = true;
		        			vm.invalidUserOtp = false;
		                	if(vm.customerRegistrationRequest.userMobileCountryCode==null && vm.customerRegistrationRequest.userMobileCountryCode==undefined){
		                		vm.customerRegistrationRequest.userMobileCountryCode = "";
		                	}
		                	var mobileNo = vm.customerRegistrationRequest.userMobileCountryCode.toString() + vm.userMobile;
		            		CustomerRegistration.verifyOTP({mobile: mobileNo, otp: vm.userMobileOtp}, function(result){
		            			vm.userOtpVerification = result.isVerified;
		            			if(vm.userOtpVerification){
		            				vm.isUserMobileVerified = true;
		            				vm.invalidUserOtp = false;
		            			}else{
		            				vm.invalidUserOtp = true;
		            				vm.isUserMobileVerified = false;
		            				vm.wrongUserOTPCount=vm.wrongUserOTPCount+1;
		            				if(vm.wrongUserOTPCount>=globalValue.maximumOTPWrongAttemptCount) // check if the wrong user otp was entered for 3 or more times 
		            				{
		            					AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.");
		            				}		            			
		            			}
		            		});
		        		}else{
		        			vm.checkUserOTP = false;
		        		}
		        	}
				}
			};
        
		$scope.otpEmailInput = {
				size : globalValue.defaultOTPLength,
				type : "password",
				onDone : function(value) {
					vm.primaryEmailOtp = value;
				},
				onChange : function(value) {
					if(value!= undefined && value!=null){
		        		vm.checkPrimaryEmailOTP = false;
		        		vm.isPrimaryEmailVerified = false;
		        		if(value.length==vm.otpLength){
		        			vm.checkPrimaryEmailOTP = true;
		        			vm.invalidPrimaryEmailOtp = false;
		            		CustomerRegistration.verifyOTPEmail({email: vm.primaryEmail, otp: vm.primaryEmailOtp}, function(result){
		            			vm.otpEmailVerification = result.isVerified;
		            			if(vm.otpEmailVerification){
		            				vm.isPrimaryEmailVerified = true;
		            				vm.checkPanImage = true;
		            				vm.invalidPrimaryEmailOtp = false;
		            			}else{
		            				vm.invalidPrimaryEmailOtp = true;
		            				vm.isPrimaryEmailVerified = false;
		            			}
		            		});
		        		}else{
		        			vm.checkPrimaryEmailOTP = false;
		        		}
		        	}
				}
			};
		
		$scope.userOtpEmailInput = {
				size : globalValue.defaultOTPLength,
				type : "password",
				onDone : function(value) {
					vm.userEmailOtp = value;
				},
				onChange : function(value) {
					if(value!= undefined && value!=null){
		        		vm.checkUserEmailOTP = false;
		        		vm.isUserEmailVerified = false;
		        		if(value.length==vm.otpLength){
		        			vm.checkUserEmailOTP = true;
		        			vm.invalidUserEmailOtp = false;
		            		CustomerRegistration.verifyOTPEmail({email: vm.userEmail, otp: vm.userEmailOtp}, function(result){
		            			vm.userOtpEmailVerification = result.isVerified;
		            			if(vm.userOtpEmailVerification){
		            				vm.isUserEmailVerified = true;
		            				vm.checkPanImage = true;
		            				vm.invalidUserEmailOtp = false;
		            			}else{
		            				vm.invalidUserEmailOtp = true;
		            				vm.isUserEmailVerified = false;
		            			}
		            		});
		        		}else{
		        			vm.checkUserEmailOTP = false;
		        		}
		        	}
				}
			};
		
        //validate pan image size
        $scope.$watch('vm.panImage', function(value){
        	
        	if(value!= undefined && value!=null){
        		if(value.name!=null && value.name!=undefined){
            		if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
            			vm.invalidPanImageSize = true;
            			vm.invalidPanFileName = false;
            			vm.invalidPanImageSixeText = "PAN image size should be in between "+ vm.minimumFileSizeInKB +"KB and " + vm.maximumFileSizeinKB +" KB";
            			vm.invalidPanImage = false;
            			vm.panImage=null;
            			angular.element("input[type='file']").val(null);
            		}else{
            			vm.inputPanImage=value.name;
            			vm.invalidPanImageSize = false;
            			vm.invalidPanImage = false;
            			if(vm.panImage.name!=null && vm.panImage.name!=undefined){
                			vm.inputPanImage=vm.panImage.name;
                			vm.customerRegistrationRequest.fileExtension = vm.inputPanImage.substring(vm.panImage.name.lastIndexOf(".") + 1);
                			var fileNameWithoutExtension  =  vm.inputPanImage.substring(0,vm.panImage.name.lastIndexOf("."));
            			}else{
            				vm.panImageBytes = vm.panImage;
            			}
            			
            			//check for extension
            			 if(Extensions.indexOf(vm.customerRegistrationRequest.fileExtension.toLowerCase()) <= -1) {
    			        	angular.element("input[type='file']").val(null);
    			        	vm.panImage=null;
    			        	vm.inputPanImage=null;
    			        	vm.invalidPanImage = true;
    			        	 vm.invalidPanFileName = false;
    			        	 vm.invalidPanImageSize = false;
    			        	 vm.invalidPanImageText = "Invalid file format";
            			 }
            			 else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
    	    				 angular.element("input[type='file']").val(null);
    	    				 vm.panImage=null;
    	    				 vm.inputPanImage=null;
    				         vm.invalidPanImage = false;
    				         vm.invalidPanImageSize = false;
    				         vm.invalidPanFileName = true;
    				         vm.invalidPanImageFileNameText = "Uploaded pan file name should not exceed " + vm.maxFileNameLength+ " charaters.";				         
    	    			 }else{
            				 var reader = new FileReader();
            				 reader.readAsArrayBuffer(vm.panImage);
            				 /*reader.onload = function(){
            					  var arrayBuffer = reader.result;
            				      var binary = '';
            				      var bytes = new Uint8Array( arrayBuffer ); 
            				      vm.invalidPanImage = false;
         					      vm.invalidPanImageSize = false;
         					      vm.invalidPanFileName = false;
                				      
         					      var data = btoa(String.fromCharCode.apply(null, bytes)); 
                				  vm.panImageBytes =  data;        					      
            				     
            				      
            				}*/
            				reader.onloadend = function(e) {
            					if (e.target.readyState === FileReader.DONE) {
            						var bytes = new Uint8Array( e.target.result ); 
            						var arr = bytes.subarray(0, 4);
    	        					if(!FileUtil.checkPanImageFileExtension(arr)){
    	      				    	  angular.element("input[type='file']").val(null);
    	      				        	vm.panImage=null;
    	      				        	vm.inputPanImage=null;
    	      				        	vm.invalidPanImage = true;
    	      					         vm.invalidPanImageSize = false;
    	      					         vm.invalidPanFileName = false;
    	      					         vm.invalidPanImageText = "Invalid file format";
    	      					         vm.panImageBytes =  null;
    	      					      
    	      				      	}else{      				      		
    	      				      		
    	      				      		vm.invalidPanImage = false;
    	      				      		vm.invalidPanImageSize = false;
    	      				      		vm.invalidPanFileName = false;
    	            				      /*for (var i = 0; i < len; i++) {
    	            				          binary += String.fromCharCode( bytes[ i ] );
    	            				      }
    	            				      var data = window.btoa( binary );*/
    	      				      		var data = btoa(String.fromCharCode.apply(null, bytes)); 
    	      				      		vm.panImageBytes =  data;  
    	      				      	}
    	        					 $scope.$digest();
            					}
            					  // Check the file signature against known types

            				};
            			 }
            			
            		}
        		}
        	}
        	else{
        		angular.element("input[type='file']").val(null);
        		
        		vm.inputPanImage=null;
        	}
        })
         $scope.$watch('vm.skipOTPPrimaryContact', function(value){
        	 if(value!=null && value!=undefined){
        		 vm.skipOTPPrimaryMobile = value;
        	 	if(value){
        		
        	 		vm.showGenerateOTPButton = false;// if skip otp then don't show the generate button
        	 		vm.disableGenerateOTP = true; // 
        	 		vm.primaryMobileOtp = null;
        	 	}else if(!value && !vm.isPrimaryMobileVerified ){
        	 		vm.showGenerateOTPButton = true; //show generate button when skip is unchecked
        	 		vm.disableGenerateOTP = true;
        	 		vm.visibleOTP = false;
        	 		if(vm.customerRegistrationRequest.primaryMobile!=null && vm.customerRegistrationRequest.primaryMobile!=undefined 
        				 && vm.customerRegistrationRequest.primaryMobile.length>=1){
        	 			vm.disableGenerateOTP = false;
        	 		}
        	 	}else if(!value && vm.isPrimaryMobileVerified){
        	 		vm.showGenerateOTPButton = false;// if skip otp then don't show the generate button
        	 		vm.disableGenerateOTP = true; // 
        	 	}
                validatePrimaryMobile(vm.customerRegistrationRequest.primaryMobile);
         	}
         });
        
        $scope.$watch('vm.skipOTPPrimaryEmailContact', function(value){
       	 if(value!=null && value!=undefined){
    		 vm.skipOTPPrimaryEmail = value;
    	 	if(value){
    		
    	 		vm.showGenerateOTPEmailButton = false;// if skip otp then don't show the generate button
    	 		vm.disableGenerateOTPEmail = true; // 
    	 		vm.primaryEmailOtp = null;
    	 	}else if(!value && !vm.isPrimaryEmailVerified ){
    	 		vm.showGenerateOTPEmailButton = true; //show generate button when skip is unchecked
    	 		vm.disableGenerateOTPEmail = true;
    	 		vm.visibleOTPEmail = false;
    	 		if(vm.customerRegistrationRequest.primaryEmail!=null && vm.customerRegistrationRequest.primaryEmail!=undefined 
    				 && vm.customerRegistrationRequest.primaryEmail.length>=1 && ((vm.customerRegistrationRequest.primaryEmail.indexOf('@')>-1) && (vm.customerRegistrationRequest.primaryEmail.indexOf('.')>-1))){
    	 			vm.disableGenerateOTPEmail = false;
    	 		}
    	 	}else if(!value && vm.isPrimaryEmailVerified){
    	 		vm.showGenerateOTPEmailButton = false;// if skip otp then don't show the generate button
    	 		vm.disableGenerateOTPEmail = true; // 
    	 	}
            validatePrimaryEmail(vm.customerRegistrationRequest.primaryEmail);
     	 }
        });

        function validatePrimaryEmail(value){
            if(value!= undefined && value!=null && value!=''){
                vm.visibleOTPEmail = false;
                vm.primaryEmail = vm.customerRegistrationRequest.primaryEmail;
                vm.primaryEmailOtp = null;
                vm.isPrimaryEmailVerified = false;
                if(value.length>=1){
                    var isSkipOTPForPrimaryEmailContact = vm.skipOTPPrimaryEmailContact;
                    vm.skipOTPPrimaryEmail = isSkipOTPForPrimaryEmailContact;
                    
                    if(!vm.skipOTPPrimaryEmail && value!=vm.verifiedPrimaryEmail){
                        vm.showGenerateOTPEmailButton = true;
                    }else{
                        vm.showGenerateOTPEmailButton = false;
                        vm.isPrimaryEmailVerified = true;
                    }
                    if((value.indexOf('@')>-1) && (value.indexOf('.')>-1)){
                        vm.disableGenerateOTPEmail = false;
                    }else{
                        vm.disableGenerateOTPEmail = true;
                    }
                    
                }else{
                    vm.disableGenerateOTPEmail = true;
                    vm.showGenerateOTPEmailButton = false;
                }
            }else{
                vm.showGenerateOTPEmailButton = false;
                vm.primaryEmailOtp = null;
            }
        }


        //send otp sms to the primary contact mobile number
        $scope.$watch('vm.customerRegistrationRequest.primaryMobile', function(value){
            validatePrimaryMobile(value);
        	// if(value!= undefined && value!=null){
        	// 	//if(vm.isPrimaryMobileVerified ){// when mobile number is changed after otp verification reset the otp fields
        	// 		vm.visibleOTP = false;
         //    		vm.primaryMobile = vm.customerRegistrationRequest.primaryMobile;
         //    		vm.primaryMobileOtp = null;
         //    		vm.isPrimaryMobileVerified = false;
        	// 	//}
        	// 	if(value.length>=1){
        	// 		var isSkipOTPForPrimaryContact = vm.skipOTPPrimaryContact;
        	// 		vm.skipOTPPrimaryMobile = isSkipOTPForPrimaryContact;
        			
         //        	if(!vm.skipOTPPrimaryMobile && (vm.customerRegistrationRequest.primaryMobileCountryCode!=vm.verifiedPrimaryMobileCountryCode || value!=vm.verifiedPrimaryMobile)){
                		
         //        		//CustomerRegistration.sendOTP({mobile: vm.customerRegistrationRequest.primaryMobile}, onPrimaryOtpSendSuccess, onPrimaryOtSendError);
         //        		vm.showGenerateOTPButton = true;
         //                vm.disableGenerateOTP = false;
         //        	}else{
         //                vm.showGenerateOTPButton = false;
         //                vm.isPrimaryMobileVerified = true;
         //            }
                	
        	// 	}else{
        	// 		vm.disableGenerateOTP = true;
         //        	vm.showGenerateOTPButton = false;
                	
        	// 	}
        		
            	
        	// }else{
        	// 	vm.showGenerateOTPButton = false;
        	// 	vm.primaryMobileOtp = null;
         // 	}
        })

        function validatePrimaryMobile(value){
            if(value!= undefined && value!=null){
                //if(vm.isPrimaryMobileVerified ){// when mobile number is changed after otp verification reset the otp fields
                    vm.visibleOTP = false;
                    vm.primaryMobile = vm.customerRegistrationRequest.primaryMobile;
                    vm.primaryMobileOtp = null;
                    vm.isPrimaryMobileVerified = false;
                //}
                if(value.length>=1){
                    var isSkipOTPForPrimaryContact = vm.skipOTPPrimaryContact;
                    vm.skipOTPPrimaryMobile = isSkipOTPForPrimaryContact;
                    
                    if(!vm.skipOTPPrimaryMobile && (vm.customerRegistrationRequest.primaryMobileCountryCode!=vm.verifiedPrimaryMobileCountryCode || value!=vm.verifiedPrimaryMobile)){
                        
                        //CustomerRegistration.sendOTP({mobile: vm.customerRegistrationRequest.primaryMobile}, onPrimaryOtpSendSuccess, onPrimaryOtSendError);
                        vm.showGenerateOTPButton = true;
                        vm.disableGenerateOTP = false;
                    }else{
                        vm.showGenerateOTPButton = false;
                        vm.isPrimaryMobileVerified = true;
                    }
                    
                }else{
                    vm.disableGenerateOTP = true;
                    vm.showGenerateOTPButton = false;
                    
                }
                
                
            }else{
                vm.showGenerateOTPButton = false;
                vm.primaryMobileOtp = null;
            }
        }
        
        function generateOTP(){
        	vm.disableGenerateOTP = true;
        	vm.showGenerateOTPButton = false;
    		/*if(vm.customerRegistrationRequest.primaryEmail!=null && vm.customerRegistrationRequest.primaryEmail!=''){
    			email = vm.customerRegistrationRequest.primaryEmail;
    		}*/
        	if(vm.customerRegistrationRequest.primaryMobileCountryCode==null && vm.customerRegistrationRequest.primaryMobileCountryCode==undefined){
        		vm.customerRegistrationRequest.primaryMobileCountryCode = "";
        	}
        	var mobileNo =  vm.customerRegistrationRequest.primaryMobile;
        	var mobileCountryCode = vm.customerRegistrationRequest.primaryMobileCountryCode.toString();
        	CustomerRegistration.sendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode}, onPrimaryOtpSendSuccess, onPrimaryOtSendError);
        }
        function onPrimaryOtpSendSuccess(result){
        	
        	AlertService.info("OTP has been sent to your mobile");
        	vm.wrongPrimaryOTPCount=0;
        	vm.visibleOTP = true;
    		vm.primaryMobile = vm.customerRegistrationRequest.primaryMobile;
    		vm.primaryMobileOtp = null;
    		vm.isPrimaryMobileVerified = false;
    		var email = 0;
        }
        
        function onPrimaryOtSendError(){
        	
        	AlertService.error("Error occurred while generating OTP");
        	vm.disableGenerateOTP = false;
        	vm.showGenerateOTPButton = true;
        }
        
        $scope.$watch('vm.customerRegistrationRequest.primaryEmail', function(value){
            validatePrimaryEmail(value);
       //  	if(value!= undefined && value!=null && value!=''){
    			// vm.visibleOTPEmail = false;
       //  		vm.primaryEmail = vm.customerRegistrationRequest.primaryEmail;
       //  		vm.primaryEmailOtp = null;
       //  		vm.isPrimaryEmailVerified = false;
       //  		if(value.length>=1){
       //  			var isSkipOTPForPrimaryEmailContact = vm.skipOTPPrimaryEmailContact;
       //  			vm.skipOTPPrimaryEmail = isSkipOTPForPrimaryEmailContact;
        			
       //          	if(!vm.skipOTPPrimaryEmail && value!=vm.verifiedPrimaryEmail){
       //          		vm.showGenerateOTPEmailButton = true;
       //          	}else{
       //                  vm.showGenerateOTPEmailButton = false;
       //                  vm.isPrimaryEmailVerified = true;
       //              }
       //          	if((value.indexOf('@')>-1) && (value.indexOf('.')>-1)){
       //                  vm.disableGenerateOTPEmail = false;
       //          	}else{
       //          		vm.disableGenerateOTPEmail = true;
       //          	}
                	
       //  		}else{
       //  			vm.disableGenerateOTPEmail = true;
       //          	vm.showGenerateOTPEmailButton = false;
       //  		}
       //  	}else{
       //  		vm.showGenerateOTPEmailButton = false;
       //  		vm.primaryEmailOtp = null;
       //   	}
        })
        
        function generateOTPEmail(){
        	vm.disableGenerateOTPEmail = true;
        	vm.showGenerateOTPEmailButton = false;
        	CustomerRegistration.sendOTPEmail({email: vm.customerRegistrationRequest.primaryEmail}, onPrimaryOtpEmailSendSuccess, onPrimaryOtpEmailSendError);
        }
        function onPrimaryOtpEmailSendSuccess(result){
        	
        	AlertService.info("OTP email has been sent to your email");
        	
        	vm.visibleOTPEmail = true;
    		vm.primaryEmail = vm.customerRegistrationRequest.primaryEmail;
    		vm.primaryEmailOtp = null;
    		vm.isPrimaryEmailVerified = false;
        }
        
        function onPrimaryOtpEmailSendError(){
        	
        	AlertService.error("Error occurred while generating OTP email");
        	vm.disableGenerateOTPEmail = false;
        	vm.showGenerateOTPEmailButton = true;
        }

        $scope.$watch('vm.skipOTPUserContact', function(value){
       	 if(value!=null && value!=undefined){
       		vm.skipOTPUserMobile = value;
       	 	if(value){
       		
       	 		vm.showGenerateUserOTPButton = false;// if skip otp then don't show the generate button
       	 		vm.disableGenerateUserOTP = true; // 
       	 		vm.userMobileOtp = null;
       	 	}else if(!value && !vm.isUserMobileVerified ){
       	 		 //show generate button when skip is unchecked
       	 		vm.showGenerateUserOTPButton = false;
       	 		vm.disableGenerateUserOTP = true;
       	 		vm.visibleUserOTP = false;
       	 		if(vm.customerRegistrationRequest.userMobile!=null && vm.customerRegistrationRequest.userMobile!=undefined 
          				 && vm.customerRegistrationRequest.userMobile.length>=1 ){
       	 			if(  (vm.customerRegistrationRequest.userMobile != vm.customerRegistrationRequest.primaryMobile) || 
       	 				(vm.skipOTPPrimaryMobile && vm.customerRegistrationRequest.userMobile == vm.customerRegistrationRequest.primaryMobile)   ){
       	 				
       	 				vm.showGenerateUserOTPButton = true;
       	 				vm.disableGenerateUserOTP = false;
       	 				
       	 			}
       	 			
       	 		}
       	 	}else if(!value && vm.isUserMobileVerified){
       	 		vm.showGenerateUserOTPButton = false;// if skip otp then don't show the generate button
       	 		vm.disableGenerateUserOTP = true; // 
       	 		//vm.visibleUserOTP = true;
       	 	}
            validateUserMobile(vm.customerRegistrationRequest.userMobile);
        }
        });

        function validateUserMobile(value){
            if(value!= undefined && value!=null){
                //if(vm.isUserMobileVerified ){// when mobile number is changed after otp verification reset the otp fields
                    vm.visibleUserOTP = false;
                    vm.userMobile = vm.customerRegistrationRequest.userMobile;
                    vm.userMobileOtp = null;
                    vm.isUserMobileVerified = false;
                //}
                var isSkipOTPForUserContact = vm.skipOTPUserContact;
                vm.skipOTPUserMobile = isSkipOTPForUserContact;
                if(value.length>=1 && !vm.skipOTPUserMobile  ){
                        vm.disableGenerateUserOTP = true;
                        vm.showGenerateUserOTPButton = false;
                        if(((value != vm.customerRegistrationRequest.primaryMobile) || 
                                (vm.skipOTPPrimaryMobile && value == vm.customerRegistrationRequest.primaryMobile)) &&
                                (vm.customerRegistrationRequest.userMobileCountryCode!=vm.verifiedUserMobileCountryCode || value!=vm.verifiedUserMobile)){
                                
                                vm.showGenerateUserOTPButton = true;
                                vm.disableGenerateUserOTP = false;
                                
                        }else if(!vm.skipOTPPrimaryMobile && value == vm.customerRegistrationRequest.primaryMobile && vm.isPrimaryMobileVerified ){
                            vm.isUserMobileVerified = true;
                            vm.showGenerateUserOTPButton = false;
                        }else if(!vm.skipOTPPrimaryMobile && vm.isPrimaryMobileVerified && value==vm.verifiedUserMobile){
                            vm.isUserMobileVerified = true;
                            vm.showGenerateUserOTPButton = false;
                        } 
                }else{
                    vm.disableGenerateUserOTP = true;
                    vm.showGenerateUserOTPButton = false;
                }
                
            }else{
                vm.showGenerateUserOTPButton = false;
                vm.userMobileOtp = null;
            }
        }

        $scope.$watch('vm.skipOTPUserEmailContact', function(value){
          	 if(value!=null && value!=undefined){
        		vm.skipOTPUserEmail = value;
        	 	if(value){
        	 		vm.showGenerateUserOTPEmailButton = false;// if skip otp then don't show the generate button
        	 		vm.disableGenerateUserOTPEmail = true; // 
        	 		vm.userEmailOtp = null;
        	 	}else if(!value && !vm.isUserEmailVerified ){
        	 		 //show generate button when skip is unchecked
        	 		vm.showGenerateUserOTPEmailButton = false;
        	 		vm.disableGenerateUserOTPEmail = true;
        	 		vm.visibleUserOTPEmail = false;
        	 		if(vm.customerRegistrationRequest.userEmail!=null && vm.customerRegistrationRequest.userEmail!=undefined 
           				 && vm.customerRegistrationRequest.userEmail.length>=1 && ((vm.customerRegistrationRequest.userEmail.indexOf('@')>-1) && (vm.customerRegistrationRequest.userEmail.indexOf('.')>-1))){
        	 			if((vm.customerRegistrationRequest.userEmail != vm.customerRegistrationRequest.primaryEmail) || 
        	 				(vm.skipOTPPrimaryEmail && vm.customerRegistrationRequest.userEmail == vm.customerRegistrationRequest.primaryEmail)){
        	 				vm.showGenerateUserOTPEmailButton = true;
        	 				vm.disableGenerateUserOTPEmail = false;
        	 			}
        	 		}
        	 	}else if(!value && vm.isUserEmailVerified){
        	 		vm.showGenerateUserOTPEmailButton = false;// if skip otp then don't show the generate button
        	 		vm.disableGenerateUserOTPEmail = true; // 
        	 	}

                validateUserEmail(vm.customerRegistrationRequest.userEmail);
             }
         });

        function validateUserEmail(value){
            if(value!= undefined && value!=null){
                vm.visibleUserOTPEmail = false;
                vm.userEmail = vm.customerRegistrationRequest.userEmail;
                vm.userEmailOtp = null;
                vm.isUserEmailVerified = false;
                var isSkipOTPForUserEmailContact = vm.skipOTPUserEmailContact;
                vm.skipOTPUserEmail = isSkipOTPForUserEmailContact;
                if(value.length>=1 && !vm.skipOTPUserEmail){
                        vm.disableGenerateUserOTPEmail = true;
                        vm.showGenerateUserOTPEmailButton = false;
                        if(((value != vm.customerRegistrationRequest.primaryEmail) || 
                                (vm.skipOTPPrimaryEmail && value == vm.customerRegistrationRequest.primaryEmail)) &&
                                (value!=vm.verifiedUserEmail)){
                                vm.showGenerateUserOTPEmailButton = true;
                                if((value.indexOf('@')>-1) && (value.indexOf('.')>-1)){
                                    vm.disableGenerateUserOTPEmail = false;
                                }else{
                                    vm.disableGenerateUserOTPEmail = true;
                                }
                                //vm.disableGenerateUserOTPEmail = false;
                                
                        }else if(!vm.skipOTPPrimaryEmail && value == vm.customerRegistrationRequest.primaryEmail && vm.isPrimaryEmailVerified ){
                            vm.isUserEmailVerified = true;
                            vm.showGenerateUserOTPEmailButton = false;
                        }else if(!vm.skipOTPPrimaryEmail && vm.isPrimaryEmailVerified && value==vm.verifiedUserEmail){
                            vm.isUserEmailVerified = true;
                            vm.showGenerateUserOTPEmailButton = false;
                        }
                }else{
                    vm.disableGenerateUserOTPEmail = true;
                    vm.showGenerateUserOTPEmailButton = false;
                }
                
            }else{
                vm.showGenerateUserOTPEmailButton = false;
                vm.userEmailOtp = null;
            }
        }
        
        //send otp sms to the user contact mobile number
        $scope.$watch('vm.customerRegistrationRequest.userMobile', function(value){
            validateUserMobile(value);
       //  	if(value!= undefined && value!=null){
       //  		//if(vm.isUserMobileVerified ){// when mobile number is changed after otp verification reset the otp fields
       //  			vm.visibleUserOTP = false;
       //      		vm.userMobile = vm.customerRegistrationRequest.userMobile;
       //      		vm.userMobileOtp = null;
       //      		vm.isUserMobileVerified = false;
       //  		//}
       //  		var isSkipOTPForUserContact = vm.skipOTPUserContact;
    			// vm.skipOTPUserMobile = isSkipOTPForUserContact;
       //  		if(value.length>=1 && !vm.skipOTPUserMobile  ){
       //  				vm.disableGenerateUserOTP = true;
       //  				vm.showGenerateUserOTPButton = false;
       //      			if(((value != vm.customerRegistrationRequest.primaryMobile) || 
       //         	 				(vm.skipOTPPrimaryMobile && value == vm.customerRegistrationRequest.primaryMobile)) &&
       //                          (vm.customerRegistrationRequest.userMobileCountryCode!=vm.verifiedUserMobileCountryCode || value!=vm.verifiedUserMobile)){
               	 				
       //         	 				vm.showGenerateUserOTPButton = true;
       //         	 				vm.disableGenerateUserOTP = false;
               	 				
       //         	 		}else if(!vm.skipOTPPrimaryMobile && value == vm.customerRegistrationRequest.primaryMobile && vm.isPrimaryMobileVerified ){
       //         	 			vm.isUserMobileVerified = true;
       //                      vm.showGenerateUserOTPButton = false;
       //         	 		}else if(!vm.skipOTPPrimaryMobile && vm.isPrimaryMobileVerified && value==vm.verifiedUserMobile){
       //                      vm.isUserMobileVerified = true;
       //                      vm.showGenerateUserOTPButton = false;
       //                  } 
       //  		}else{
       //  			vm.disableGenerateUserOTP = true;
       //          	vm.showGenerateUserOTPButton = false;
       //  		}
            	
       //  	}else{
       //  		vm.showGenerateUserOTPButton = false;
       //  		vm.userMobileOtp = null;
       //  	}
        });
        function generateUserOTP(){
        	
        	vm.disableGenerateUserOTP = true;
        	vm.showGenerateUserOTPButton = false;
    		/*if(vm.customerRegistrationRequest.primaryEmail!=null && vm.customerRegistrationRequest.primaryEmail!=''){
    			email = vm.customerRegistrationRequest.primaryEmail;
    		}*/
        	if(vm.customerRegistrationRequest.userMobileCountryCode==null && vm.customerRegistrationRequest.userMobileCountryCode==undefined){
        		vm.customerRegistrationRequest.userMobileCountryCode = "";
        	}
        	var mobileNo = vm.customerRegistrationRequest.userMobile;
        	var mobileCountryCode = vm.customerRegistrationRequest.userMobileCountryCode.toString();
    		CustomerRegistration.sendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode}, onUserOtpSendSuccess, onUserOtpSendError);
        }
        function onUserOtpSendSuccess(result){
        	AlertService.info("OTP has been sent to your mobile");
        	vm.wrongUserOTPCount=0;
        	vm.visibleUserOTP = true;
        	vm.userMobileOtp = null;
        	vm.userMobile = vm.customerRegistrationRequest.userMobile;
    		vm.isUserMobileVerified = false;
    		var email = 0;
        	
        }
        
        function onUserOtpSendError(){
        	AlertService.info("Error occurred while generating OTP");
        	vm.disableGenerateUserOTP = false;
        	vm.showGenerateUserOTPButton = true;
        }
        
        $scope.$watch('vm.customerRegistrationRequest.userEmail', function(value){
            validateUserEmail(value);
       //  	if(value!= undefined && value!=null){
    			// vm.visibleUserOTPEmail = false;
       //  		vm.userEmail = vm.customerRegistrationRequest.userEmail;
       //  		vm.userEmailOtp = null;
       //  		vm.isUserEmailVerified = false;
       //  		var isSkipOTPForUserEmailContact = vm.skipOTPUserEmailContact;
    			// vm.skipOTPUserEmail = isSkipOTPForUserEmailContact;
       //  		if(value.length>=1 && !vm.skipOTPUserEmail){
       //  				vm.disableGenerateUserOTPEmail = true;
       //  				vm.showGenerateUserOTPEmailButton = false;
       //      			if(((value != vm.customerRegistrationRequest.primaryEmail) || 
       //         	 				(vm.skipOTPPrimaryEmail && value == vm.customerRegistrationRequest.primaryEmail)) &&
       //                          (value!=vm.verifiedUserEmail)){
       //         	 				vm.showGenerateUserOTPEmailButton = true;
       //                          if((value.indexOf('@')>-1) && (value.indexOf('.')>-1)){
       //                              vm.disableGenerateUserOTPEmail = false;
       //                          }else{
       //                              vm.disableGenerateUserOTPEmail = true;
       //                          }
       //         	 				//vm.disableGenerateUserOTPEmail = false;
               	 				
       //         	 		}else if(!vm.skipOTPPrimaryEmail && value == vm.customerRegistrationRequest.primaryEmail && vm.isPrimaryEmailVerified ){
       //         	 			vm.isUserEmailVerified = true;
       //                      vm.showGenerateUserOTPEmailButton = false;
       //         	 		}else if(!vm.skipOTPPrimaryEmail && vm.isPrimaryEmailVerified && value==vm.verifiedUserEmail){
       //                      vm.isUserEmailVerified = true;
       //                      vm.showGenerateUserOTPEmailButton = false;
       //                  }
       //  		}else{
       //  			vm.disableGenerateUserOTPEmail = true;
       //          	vm.showGenerateUserOTPEmailButton = false;
       //  		}
            	
       //  	}else{
       //  		vm.showGenerateUserOTPEmailButton = false;
       //  		vm.userEmailOtp = null;
       //  	}
        });

        function generateUserOTPEmail(){
        	vm.disableGenerateUserOTPEmail = true;
        	vm.showGenerateUserOTPEmailButton = false;
    		CustomerRegistration.sendOTPEmail({email: vm.customerRegistrationRequest.userEmail}, onUserEmailOtpSendSuccess, onUserEmailOtpSendError);
        }
        
        function onUserEmailOtpSendSuccess(result){
        	AlertService.info("OTP has been sent to your email");
        	vm.visibleUserOTPEmail = true;
        	vm.userEmailOtp = null;
        	vm.userEmail = vm.customerRegistrationRequest.userEmail;
    		vm.isUserEmailVerified = false;
    		var email = 0;
        	
        }
        
        function onUserEmailOtpSendError(){
        	AlertService.info("Error occurred while generating OTP");
        	vm.disableGenerateUserOTPEmail = false;
        	vm.showGenerateUserOTPEmailButton = true;
        }

        //verify otp sms for primary mobile
        /*$scope.$watch('vm.primaryMobileOtp', function(value){
        	if(value!= undefined && value!=null){
        		vm.checkPrimaryOTP = false;
        		vm.isPrimaryMobileVerified = false;
        		if(value.length==vm.otpLength){
        			vm.checkPrimaryOTP = true;
            		CustomerRegistration.verifyOTP({mobile: vm.primaryMobile, otp: vm.primaryMobileOtp}, function(result){
            			vm.otpVerification = result.isVerified;
            			if(vm.otpVerification){
            				vm.isPrimaryMobileVerified = true;
            				vm.checkPanImage = true;
            				vm.invalidPrimaryOtp = false;
            			}else{
            				vm.invalidPrimaryOtp = true;
            				vm.isPrimaryMobileVerified = false;
            			}
            		});
        		}else{
        			vm.checkPrimaryOTP = false;
        		}
        	}
        })*/
        
        //verify otp sms for user mobile
        /*$scope.$watch('vm.userMobileOtp', function(value){
        	if(value!= undefined && value!=null){
        		vm.checkUserOTP = false;
        		if(value.length==vm.otpLength){
        			vm.checkUserOTP = true;
            		CustomerRegistration.verifyOTP({mobile: vm.userMobile, otp: vm.userMobileOtp}, function(result){
            			vm.userOtpVerification = result.isVerified;
            			if(vm.userOtpVerification){
            				vm.isUserMobileVerified = true;
            			}else{
            				vm.invalidUserOtp = true;
            				vm.isUserMobileVerified = false;
            			}
            		});
        		}else{
        			vm.checkUserOTP = false;
        		}
        	}
        })*/
        
         $scope.$watch('vm.customerRegistrationRequest.homeBranch', function(value){
        	if(value!=null&&value!=undefined){
        		vm.tradingBranchAddress = value.address;
        	}else{
        		vm.tradingBranchAddress = null;
        	}
        });
        
        function submit(){
        	if( vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display && vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
          			 && vm.customerRegistrationRequest.leiExpiryDate!= '' && moment(vm.customerRegistrationRequest.leiExpiryDate).isBefore(vm.todayDate,'day')){
           		
           		//AlertService.error($filter('translate')('gatewayApp.customerRegistration.expiryDate'));
           		$scope.$emit('errorMessage', ($filter('translate')('gatewayApp.customerRegistration.expiryDate')));
          		 
           	}else if(moment(vm.customerRegistrationRequest.dob).isAfter(vm.todayDate,'day')){
        		
       		 //AlertService.error($filter('translate')('gatewayApp.customerRegistration.futuredateOfBirth'));
        		$scope.$emit('errorMessage', ($filter('translate')('gatewayApp.customerRegistration.futuredateOfBirth')));
       	 
        	}else{
        		vm.customerRegistrationRequest.branchAddress = vm.tradingBranchAddress;
                //update clearcorp TAC and bank TAC in cust reg.request in order to update tacForCustomerRequested
                if(vm.clearcorpTAC!=null && vm.clearcorpTAC.id!=null){
                    vm.customerRegistrationRequest.clearcorpTAC = vm.clearcorpTAC;
                }
                if(vm.bankTAC!=null && vm.bankTAC.id!=null){
                    vm.customerRegistrationRequest.bankTAC = vm.bankTAC;
                }
        		//vm.customerRegistrationRequest.panImage = vm.panImageBytes;
        		$rootScope.panFile = vm.panImage;
        		
        		var fd = new FormData();
        		fd.append('pan', vm.panImage);
        		fd.append('customer', JSON.stringify(vm.customerRegistrationRequest));
        		if(vm.panImage.name!=null && vm.panImage.name!=undefined){
            		CustomerRegistration.validateCustomerOnboarding(fd, onSubmitSuccess, onSubmitFailure);
        		}else{
        			onSubmitSuccess('success');
        		}
				
		
    			
       		/*var fd = new FormData();
			vm.customerRegistrationRequest.dob = DateUtils.convertLocalDateToServer(vm.customerRegistrationRequest.dob);
			vm.customerRegistrationRequest.dobOrDoi = DateUtils.convertLocalDateToServer(vm.customerRegistrationRequest.dobOrDoi);
			if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
	       			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
				vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateToServer(vm.customerRegistrationRequest.leiExpiryDate);
			}
			vm.customerRegistrationRequest.branchAddress = vm.tradingBranchAddress;
			
			if(vm.customerRegistrationRequest.customerType.customerUserType.shortName=='MultiUserCustomer'){
				vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.companyName;
				vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.companyName;
			}
			
            fd.append('pan', vm.panImage);
			fd.append('customer', JSON.stringify(vm.customerRegistrationRequest));
        	
            CustomerRegistration.submit(fd, onSubmitSuccess, onSubmitFailure);*/
       	 }
        	
        }
        
        function onSubmitSuccess(result){
        	//$state.go('registrationRequestStatus', {requestId: result.id});
        	$state.go('customerRegistrationPreview', {customerRegistrationRequest : vm.customerRegistrationRequest, theme : 'light', image :vm.panImageBytes });
        }
        
        function onSubmitFailure(){
        	vm.customerRegistrationRequest.dob  = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dob);
        	vm.customerRegistrationRequest.dobOrDoi  = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dobOrDoi);
        	
        	if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
				vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.companyName;
				vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.companyName;
				vm.required = false;
			}
            if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display){
                if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                         && vm.customerRegistrationRequest.leiExpiryDate!= ''){
                    vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.leiExpiryDate);
                }                
            }

        }
        
        vm.resendPrimaryOTP = function resendPrimaryOTP(){
        	var email = '0';
    		if(vm.customerRegistrationRequest.primaryEmail!=null && vm.customerRegistrationRequest.primaryEmail!=''){
    			email = vm.customerRegistrationRequest.primaryEmail;
    		}
        	if(vm.customerRegistrationRequest.primaryMobileCountryCode==null && vm.customerRegistrationRequest.primaryMobileCountryCode==undefined){
        		vm.customerRegistrationRequest.primaryMobileCountryCode = "";
        	}
        	var mobileNo =  vm.customerRegistrationRequest.primaryMobile;
        	var mobileCountryCode = vm.customerRegistrationRequest.primaryMobileCountryCode.toString();
        	CustomerRegistration.sendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode}, onResendPrimaryOtpSuccess, onResendPrimaryOtpError);
        }

        function onResendPrimaryOtpSuccess(result){
        	AlertService.info("OTP has been resent to your mobile");
        	vm.wrongPrimaryOTPCount=0;
        }
        
        function onResendPrimaryOtpError(){
        	
        }
        
        vm.resendUserOTP = function resendUserOTP(){
        	var email = '0';
    		if(vm.customerRegistrationRequest.userEmail!=null && vm.customerRegistrationRequest.userEmail!=''){
    			email = vm.customerRegistrationRequest.userEmail;
    		}
        	if(vm.customerRegistrationRequest.userMobileCountryCode==null && vm.customerRegistrationRequest.userMobileCountryCode==undefined){
        		vm.customerRegistrationRequest.userMobileCountryCode = "";
        	}
        	var mobileNo = vm.customerRegistrationRequest.userMobile;
        	var mobileCountryCode = vm.customerRegistrationRequest.userMobileCountryCode.toString();
        	CustomerRegistration.sendOTP({mobile: mobileNo, mobileCountryCode : mobileCountryCode}, onResendUserOtpSuccess, onResendUserOtpError);
        }

        function onResendUserOtpSuccess(result){
        	AlertService.info("OTP has been resent to your mobile");
        	vm.wrongUserOTPCount=0;
        }
        
        function onResendUserOtpError(){
        	
        }
        
        vm.resendPrimaryOTPEmail = function resendPrimaryOTPEmail(){
        	CustomerRegistration.sendOTPEmail({email: vm.customerRegistrationRequest.primaryEmail}, onResendPrimaryOtpEmailSuccess, onResendPrimaryOtpEmailError);
        }

        function onResendPrimaryOtpEmailSuccess(result){
        	AlertService.info("OTP has been resent to your email");
        }
        
        function onResendPrimaryOtpEmailError(){
        	
        }
        
        vm.resendUserOTPEmail = function resendUserOTPEmail(){
        	CustomerRegistration.sendOTPEmail({email: vm.customerRegistrationRequest.userEmail}, onResendUserOtpEmailSuccess, onResendUserOtpEmailError);
        }

        function onResendUserOtpEmailSuccess(result){
        	AlertService.info("OTP has been resent to your email");
        }
        
        function onResendUserOtpEmailError(){
        	
        }
        
        vm.copyUserInfo = function copyUserInfo() {
        	
        	if( vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display && vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
           			 && vm.customerRegistrationRequest.leiExpiryDate!= '' && moment(vm.customerRegistrationRequest.leiExpiryDate).isBefore(vm.todayDate,'day')){
         		
           		 //AlertService.error($filter('translate')('gatewayApp.customerRegistration.expiryDate'));
        		 $scope.$emit('errorMessage', ($filter('translate')('gatewayApp.customerRegistration.expiryDate')));
           		 
           	}else if(moment(vm.customerRegistrationRequest.dobOrDoi).isAfter(vm.todayDate,'day')){
        		
           		//AlertService.error($filter('translate')('gatewayApp.customerRegistration.futuredob'));
           		$scope.$emit('errorMessage', ($filter('translate')('gatewayApp.customerRegistration.futuredob')));
       		
           	}else{
        		 CustomerRegistration.validatePan({panNumber : vm.customerRegistrationRequest.panNumber},onPanVerificationSuccess, onPanVerificationFailure);
        	}
        	
		}
        
        function onPanVerificationSuccess(result){
        	
        		vm.customerInfo=false;
            	vm.bankInfo=true;
            	if(vm.customerRegistrationRequest.customerType.customerUserType.shortName=='SingleUserCustomer'){
                    if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
                        //do not copy first/middle/last name when entity name is captured in customer info
                        //vm.customerRegistrationRequest.userFirstName=vm.customerRegistrationRequest.companyName;
                    }else{
                        vm.customerRegistrationRequest.userFirstName=vm.customerRegistrationRequest.customerFirstName;
                        vm.customerRegistrationRequest.userMiddleName=vm.customerRegistrationRequest.customerMiddleName;
                        vm.customerRegistrationRequest.userLastName=vm.customerRegistrationRequest.customerLastName;
                    }
            		vm.customerRegistrationRequest.userMobileCountryCode=vm.customerRegistrationRequest.primaryMobileCountryCode;
            		vm.customerRegistrationRequest.userMobile=vm.customerRegistrationRequest.primaryMobile;
            		vm.customerRegistrationRequest.userEmail=vm.customerRegistrationRequest.primaryEmail;
            		vm.customerRegistrationRequest.dob=vm.customerRegistrationRequest.dobOrDoi;
            		vm.customerRegistrationRequest.userAddress1=vm.customerRegistrationRequest.address1;
            		vm.customerRegistrationRequest.userAddress2=vm.customerRegistrationRequest.address2;
            		vm.customerRegistrationRequest.userCity=vm.customerRegistrationRequest.city;
            		vm.customerRegistrationRequest.userCountry=vm.customerRegistrationRequest.country;
    				$timeout(function(){
    					vm.customerRegistrationRequest.userState=vm.customerRegistrationRequest.state;
					}, 100);
            		vm.customerRegistrationRequest.userPincode=vm.customerRegistrationRequest.pincode;
            		vm.isUserEmailVerified = true;
            		vm.isUserMobileVerified = true;
            	}
            	
				  $timeout(function(){
					  if(vm.tradingBranch!=null){
						  vm.customerRegistrationRequest.tradingBranch = vm.tradingBranch;
					  }
				  }, 100);
        }
        
        function onPanVerificationFailure(){
        	
        }
        
		$scope.$watch('vm.customerRegistrationRequest.bank', function(value){
			if(value!=null && value!='' && value!=undefined){
				//var bankName = value.bankName;
				//bankName = bankName.substring(0, 5); // issue fix mantis id :20
				vm.filterText = value.ccpMembershipId;
			}
		});
		  $scope.$watch('vm.customerRegistrationRequest.legalEntityIdentifier', function(value){
	        	if(value!= undefined && value!=null){
	        		
	        		if(value.length==20){
	        			vm.leiExpiryRequired = true;
	                	vm.disableLeiExpiry = false;
	                	//vm.customerRegistrationRequest.leiExpiryDate = null;
	                	
	        		}else{
	        			vm.leiExpiryRequired = false;
	                	vm.disableLeiExpiry = true;
	                	vm.customerRegistrationRequest.leiExpiryDate = null;
	        		}        		
	            	
	        	}
	        	else{
	        		vm.leiExpiryRequired = false;
                	vm.disableLeiExpiry = true;
                	vm.customerRegistrationRequest.leiExpiryDate = null;
	        	}
	        })
	        
	      function fetchRejectedRequest(){
			  $state.go('request-reference-number', {refNo: vm.refNo});
		  }

	      var subscribeFetchRequest = $rootScope.$on('fetchRequest', function(event, result) {
	        	if($rootScope.refNo==null || $rootScope.refNo==undefined){
	        		vm.refNo = "";
	        	}else{
	        		vm.refNo = $rootScope.refNo;
                    vm.requestDetailsToResubmit = $rootScope.requestDetailsToResubmit;
                    if(vm.requestDetailsToResubmit.customerType.isRequiredForDisplay==false){
                        vm.requestDetailsToResubmit.customerType = null;
                        vm.touchedcustomertype=true;
                    }
	        		getCustomerRegistrationRequestToResubmit(vm.refNo);
	        	}
	      });
	        
	      $scope.$on('$destroy', function() {
	        	subscribeFetchRequest();	        	
	      });
	      
	      //get request by reference number to resubmit  
	      function getCustomerRegistrationRequestToResubmit(refNo){
			  if(refNo!=""){
		    	  vm.customerRegistrationRequest = {};
		          vm.customerRegistrationRequest.gender = 'M';
		          vm.customerRegistrationRequest.primaryMobileCountryCode = 91;
		          vm.customerRegistrationRequest.alternateMobileCountryCode1 = 91;
		          vm.customerRegistrationRequest.alternateMobileCountryCode2 = 91;
		          vm.customerRegistrationRequest.userMobileCountryCode = 91;
		          vm.customerRegistrationRequest.assignTradingBranch = false;
		          //getCountry();
				  vm.panImage = null;
				  vm.panImageBytes = null;
				  vm.tradingBranch = null;
				  vm.inputPanImage = null;
				  //CustomerRegistration.getCustomerRegistrationRequestToResubmit({requestReferenceNo: refNo}, onFetchSuccess, onFetchFailure)
                  onFetchSuccess(vm.requestDetailsToResubmit);
			  }
		  }
	      
	      function onFetchSuccess(result){
			  if(result.id!=null && result.id!=null){
				  vm.customerRegistrationRequest = angular.copy(result);
				  $timeout(function(){
					  vm.customerRegistrationRequest.state = angular.copy(result.state);
					  vm.customerRegistrationRequest.userState = angular.copy(result.userState);
				  }, 1000);
				  vm.panImage = vm.customerRegistrationRequest.panImage;
				  vm.panImageBytes = vm.customerRegistrationRequest.panImage;
				  vm.customerRegistrationRequest.dobOrDoi = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dobOrDoi);
				  vm.customerRegistrationRequest.dob = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dob);
                  vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
                    return item.customerType.id == vm.customerRegistrationRequest.customerType.id;
                });
				  if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
					  vm.customerRegistrationRequest.companyName = vm.customerRegistrationRequest.customerFirstName;
                      //vm.customerRegistrationRequest.customerLastName = " ";
				  }
                  if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display){
                      vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.leiExpiryDate);
                  }
				  vm.tradingBranch = angular.copy(vm.customerRegistrationRequest.tradingBranch);
				  vm.inputPanImage = "PAN Image uploaded";
                  vm.verifiedPrimaryMobileCountryCode = angular.copy(vm.customerRegistrationRequest.primaryMobileCountryCode);
                  vm.verifiedPrimaryMobile = angular.copy(vm.customerRegistrationRequest.primaryMobile);
                  vm.verifiedPrimaryEmail = angular.copy(vm.customerRegistrationRequest.primaryEmail);
                  vm.verifiedUserMobileCountryCode = angular.copy(vm.customerRegistrationRequest.userMobileCountryCode);
                  vm.verifiedUserMobile = angular.copy(vm.customerRegistrationRequest.userMobile);
                  vm.verifiedUserEmail = angular.copy(vm.customerRegistrationRequest.userEmail);
			  }else{
				  //AlertService.error("Invalid request reference number.");
			  }
	      }
	      
	      function onFetchFailure(){
	      }
	      
	      function base64ToArrayBuffer(base64) {
    	    var binary_string =  window.atob(base64);
    	    var len = binary_string.length;
    	    var bytes = new Uint8Array( len );
    	    for (var i = 0; i < len; i++)        {
    	        bytes[i] = binary_string.charCodeAt(i);
    	    }
    	    return bytes.buffer;
	      }
	      
	      vm.viewPanImage = function viewPanImage(){
	        	if(vm.customerRegistrationRequest.fileExtension=='pdf'){
	    			//var pdfWindow = window.open("");
	        		//pdfWindow.document.write("<iframe width='100%' height='100%'title='PAN'  src='data:application/pdf;base64, " + encodeURI(vm.customerRegistrationRequest.panImage)+"' ></iframe>")
	        		var contentByteArray = base64ToArrayBuffer(vm.customerRegistrationRequest.panImage);
		    		  
		    		var file = new Blob([contentByteArray], { type: 'application/pdf' });
		            var fileURL = URL.createObjectURL(file);
		              
		            if(window.navigator.msSaveOrOpenBlob) {
		                  window.navigator.msSaveOrOpenBlob(file, "PAN.pdf");
		            }
		            else {
		                   window.open(fileURL, "PAN.pdf");    
		            }
	        	}
	        	else{
	        		$state.go('view-panImage-resubmit-request', {image:vm.customerRegistrationRequest.panImage,theme:'light'});
	        	}
	        }
       
	      
	      function openHelpWindow(){
	    	  
	    	  CustomerRegistration.getHelpContent({type : 'Customer_OnBoarding'}, onGetHelpContentsSuccess, onGetHelpContentsFailure);
	    	  
	    	  
	      }
	      
	      function onGetHelpContentsSuccess(result){
	    	  if(result.contents != null && result.contents != undefined){
	    		  var contentByteArray = base64ToArrayBuffer(result.contents);
	    		  
	    		  var file = new Blob([contentByteArray], { type: 'application/pdf' });
	              var fileURL = URL.createObjectURL(file);
	              
	              if(window.navigator.msSaveOrOpenBlob) {
	                    window.navigator.msSaveOrOpenBlob(file, "CustomerOnBoarding.pdf");
	                }
	                else {
	                     window.open(fileURL, "CustomerOnBoarding.pdf");    
	                }
	              
	              
	    		  
//	    		  var pdfWindow = window.open("");
//	        		pdfWindow.document.write("<iframe width='100%' height='100%' title='PAN'  src='data:application/pdf;base64, " 
//	        				+ encodeURI(result.contents)+"' ></iframe>")
	    		  
	    	  }
	      }
	      
	      function base64ToArrayBuffer(base64) {
	    	    var binary_string =  window.atob(base64);
	    	    var len = binary_string.length;
	    	    var bytes = new Uint8Array( len );
	    	    for (var i = 0; i < len; i++)        {
	    	        bytes[i] = binary_string.charCodeAt(i);
	    	    }
	    	    return bytes.buffer;
	    	}
	      
	      function onGetHelpContentsFailure(){
	    	  
	      }
	      $scope.$watch('vm.customerRegistrationRequest.bank', function(value){
	    	  if(value!=null && value!=undefined){
	    		  Branch.getValidBranchDetailsForBank({bankId: value.id}, function(result){
	    			  if(result!=null && (result.length>1 || result.length==0)){
	    				  vm.customerRegistrationRequest.assignTradingBranch = true;
	    			  }else{
	    				  vm.customerRegistrationRequest.assignTradingBranch = false;
	    			  }
	    		  })

                  TACViewForEntitiesService.getActiveTACForOrganisationAndTacType({orgId: value.id, tacTypeId: vm.bankForCustomerTACTypeId}, function(result){
                    if(result!=null && result.tacFiles!=null){
                        vm.bankTAC = result;    
                    }else{
                        vm.bankTAC = null;
                    }
                  })
	    	  }else{
	    		  vm.customerRegistrationRequest.assignTradingBranch = false;
                  vm.bankTAC = null;
	    	  }
	      })
	      
	      $scope.$watchGroup(['vm.customerRegistrationRequest.assignTradingBranch', 'vm.customerRegistrationRequest.tradingBranch'], function(value){
	    	  if(vm.customerRegistrationRequest.assignTradingBranch!=null && vm.customerRegistrationRequest.assignTradingBranch!=undefined){
	    		  if(vm.customerRegistrationRequest.assignTradingBranch){
	    			  if(vm.customerRegistrationRequest!=null && vm.customerRegistrationRequest.tradingBranch!=null){
	    				  vm.customerRegistrationRequest.tradingBranch = null;
	    			  }
	    			  vm.disableTradingBranch = true;
	    		  }else{
	    			  vm.disableTradingBranch = false;
	    		  }
	    	  }
	      })

          function previewClearcorpTAC(){
            if(vm.clearcorpTAC!=null && vm.clearcorpTAC!=undefined){
                TacViewForEntitiesUtility.previewTAC(vm.clearcorpTAC.tacFiles.fileContent, vm.clearcorpTAC.tacFiles.fileName);    
            }
          }

          function previewBankTAC(){
            if(vm.bankTAC!=null && vm.bankTAC!=undefined){
                TacViewForEntitiesUtility.previewTAC(vm.bankTAC.tacFiles.fileContent, vm.bankTAC.tacFiles.fileName);    
            }
          }

          $scope.$watch('vm.customerRegistrationRequest.companyName', function(newVal, oldVal) {
            if(newVal!=null && newVal!=undefined)
            {
                newVal = newVal.replace(/(\r\n|\n|\r)/gm," ");
                vm.customerRegistrationRequest.companyName = newVal.replace(/(,)/gm,"");
                
            }
          });
          
          vm.openUserInfo = function openUserInfo(){
        	  $timeout(function(){
    			  //set focus to login control
    			  if(vm.customerRegistrationRequest.customerType.enableUserInfo==false 
    					  && document.getElementById("txtLogin") !=null && document.getElementById("txtLogin")!= undefined){
    	        		document.getElementById("txtLogin").focus();
    			  }
        	  }, 100)
          }
	      
    }
})();
