(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('SMSMessages', SMSMessages);

    SMSMessages.$inject = ['$resource'];

    function SMSMessages ($resource) {
        var resourceUrl =  'api/sms-messages/:id';

        return $resource(resourceUrl, {}, {
            
            
            'getSMSFailureReport':{
            	 
            	isArray:true,
 				method: 'GET',
 				params: {from:'@from',to:'@to',alertTypeId : '@alertTypeId', status : '@status'},
 				url: 'api/sms-messages/get-sms-details-for-report'
             },
        
            'getSMSFailureArchiveReport':{
            	 
             	isArray:true,
  				method: 'GET',
  				params: {from:'@from',to:'@to',alertTypeId : '@alertTypeId', status : '@status'},
  				url: 'api/sms-messages-archive/get-sms-details-for-report'
            },
         
        	'delete':{
        		method: 'POST',
        		url: 'api/delete-sms-failure-report/',
        		transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                    
                } 
            },
            
        	'deleteArchive':{
        		method: 'POST',
        		url: 'api/delete-sms-failure-report-archive/',
        		transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                    
                } 
            },
            
            'resend':{
            	method:'POST',
            	params:{smsId:'@smsId', isHistorical:'@isHistorical'},
            	url:'api/sms-messages/:smsId/:isHistorical/resend-message'
            },
            'exportData':{
  			    method: 'POST',
                  url: 'api/sms-report/export',
                  responseType: 'arraybuffer',
                  params : {
                	 alertType : '@alertType', status : '@status', from : '@from', to :'@to'
  				},
  				 transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	
                      return angular.toJson(copy);
                  },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  } 
  			}
            
             });
    }
})();
