(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.controller('SODProcessController', SODProcessController);

	SODProcessController.$inject = ['$scope', '$state', '$stateParams', '$rootScope', 'GlobalValues', 'DateUtils', 'AlertService','SODProcess','MessageBox','$filter', 'ScreenLog','entityConstants', 'StaticLookUp','$interval', '$timeout','EntityPermissions','WebSocket','Account','Auth','$uibModalStack'];

	function SODProcessController($scope, $state, $stateParams, $rootScope, GlobalValues, DateUtils, AlertService,SODProcess,MessageBox,$filter, ScreenLog,entityConstants, StaticLookUp, $interval,$timeout,EntityPermissions,WebSocket,Account,Auth,$uibModalStack) {
		
		var vm = this;
		vm.globalValues = GlobalValues;
		vm.currentBusinessDate;
		vm.nextBusinessDate;
		vm.beginSODProcess = beginSODProcess;		
		var convertedNextBusinessDate ;
		var convertedCurrentBusinessDate ;
		$scope.refresh = refresh;
		vm.convertedTodayDate = DateUtils.convertLocalDateToServer(vm.today);		
		
		vm.disbleSod = true;
		vm.sodStatus='';
		vm.isRefreshDisabled=false;
		vm.isSodProcessSuccessfull=null;
		vm.isSodCompleted = false;
		//vm.newProcessStatusMap = {};
		//vm.processStatusMap = {};
		vm.today = new Date();
		vm.errorMessage = "";
		vm.forcibleLogOffID = 0;
		vm.connectToMQID = 0;
		vm.executionCount = 0;
		vm.sodProcessList = [];
		vm.sodProcessListFromDB = [];
		vm.newProcessResultMap = {};
		vm.processResultMap = {};
		vm.processErrorMap = {};
		vm.newProcessErrorMap = {};
		vm.errorList = {};
		var orderServiceSODProcessUpdateSubscription = null;
		vm.idArray = [];
		
		/****************load config for maximumWaitingtime to get response from orderservice , define timer to check whether response is received in time *******************/
		/*		
		vm.enableTimer = false;
		vm.maximumWaitTimeForOrderServiceResponse = 0 ; 
		vm.latestOrderSeviceRequestTimestamp = 0 ; 
		vm.latestProcess = 0;
		vm.configSettings = vm.globalValues.getConfigSettings();
		
		var maximumWaitTimeForResponseString  = vm.configSettings.timerIntervalforSODEODResponseWait;
		if(maximumWaitTimeForResponseString!=null && maximumWaitTimeForResponseString!=undefined && maximumWaitTimeForResponseString.trim()!=''){
			
			vm.maximumWaitTimeForOrderServiceResponse = parseInt(maximumWaitTimeForResponseString, 10) * 1000;
			//vm.maximumWaitTimeForOrderServiceResponse = 10*1000;
		}else{
			
			vm.maximumWaitTimeForOrderServiceResponse = 60000;//default 60 sec
		}
		
		vm.responseTimeCheckInterval = vm.maximumWaitTimeForOrderServiceResponse/2;
		
		$scope.checkForResponseTimeout = function() {
			
	        console.log("$scope.callAtInterval - Interval occurred");
	        var currentTimestamp = moment().valueOf();
	        if( (vm.enableTimer == true) && ( (currentTimestamp - vm.latestOrderSeviceRequestTimestamp ) >  vm.maximumWaitTimeForOrderServiceResponse )){
	        	
	        	// update the sod Process status log = INCOMPLETE
	        	if(vm.latestProcess!=null && vm.latestProcess!=undefined && vm.latestProcess.status == vm.INPROGRESS){
	        		updateRemarksInProcessList(vm.latestProcess.id ,"Response timeout occurred",  vm.FAILED);
	        		SODProcess.insertProcessLog({"currentBusinessDate" : convertedCurrentBusinessDate,
	    				"processId" : vm.latestProcess.id,
	    				"executionCount" : vm.executionCount,
	    				"errorMessageCode" : "7001",
	    				"isSuccess" : false});
	        	}
	        	vm.sodStatus = vm.INCOMPLETE;
				vm.disbleSod = disableSodProcess();			
				
				SODProcess.processSOD( {				
					"businessDate" : convertedCurrentBusinessDate,
					"status" : vm.INCOMPLETE
				}, endSODInUISuccess, endSODInUIError);
	        	
	        }
	    }
		

	 	$interval( function(){ 
	 		$scope.checkForResponseTimeout(); 
	 		}, vm.responseTimeCheckInterval);
	 	
		function endSODInUISuccess(result){
			vm.enableTimer = false;
			//destroy the event 
			unsubscribeToSODProcessUpdate();	

			// send kafka message to order service to stop the further process.
			SODProcess.stopSODForcibly();
			
			
			
        	
		}
		function endSODInUIError(error){
			//DO NOthing			
			unsubscribeToSODProcessUpdate();		
		}*/
		/************* Constants to be used in HTML file for displaying progress icons ********************/

		vm.INPROGRESS = "INPROGRESS";
		vm.COMPLETED = "COMPLETED";
		vm.FAILED = "FAILED";
		vm.NOTSTARTED = "NOTSTARTED";
		vm.OPEN = "TO_BE_INITIATED";
		vm.INCOMPLETE = "INCOMPLETE";
		/**********************************************************************/
		 vm.permitSODProcessAddEntityAction = EntityPermissions.hasPermission('SODProcess.CREATE');
		// if(vm.permitSODProcessAddEntityAction ==false){
		
		// 	//message box 
		// 	MessageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'permitSODProcessAddEntityAction');
		// }

		// $rootScope.$on('gatewayApp.messagebox.ok.permitSODProcessAddEntityAction', function (event) {

		// 	Auth.sessionlogout();
		
		// });
		
		// $rootScope.$on('gatewayApp.messagebox.clear.permitSODProcessAddEntityAction', function (result){
		// 	Auth.sessionlogout();
			
		// })
		
		
		


		/*********** Set Initial status as NOTSTARTED for each process on page laod *****************/
		
		 function loadWorkFlowItems(){
        	 StaticLookUp.getSodWorkFlow(lookupWorkFlowSuccess, lookupWorkFlowFailure);
         }
        
		 function lookupWorkFlowSuccess(result){
			 vm.sodProcessListFromDB = result;
			 vm.idArray = [];
			 angular.forEach(vm.sodProcessListFromDB, function(item){               
	                vm.idArray.push( item.id);               
	            });
	    	 vm.sodProcessList = angular.copy(vm.sodProcessListFromDB); 
	    	// var getKeysArray = Object.keys( vm.sodProcessList);
	    	 if(vm.sodStatus == vm.OPEN || vm.sodStatus==vm.INCOMPLETE){
		    	  angular.forEach( vm.sodProcessList, function(item){	    	
		    		 if(item.processName == 'ForciblelogoffUsers' ){
		    			 vm.forcibleLogOffID =item.id;
		    			
		    		 }else if(item.processName.processName == 'ConnectToInteractiveMQ'){
		    			 vm.connectToMQID = item.id;
		    		 }
		    		
		    		item.status = vm.NOTSTARTED;
		    		item.remarks='';
		    		
		    		vm.newProcessResultMap[item.id] = null;
		    		vm.processResultMap[item.id] = null;
		    		vm.processErrorMap[item.id] = null;
		    		vm.newProcessErrorMap[item.id] = null;
		    		 
		    	 });	    	
	    	}
	    	if(vm.sodStatus==vm.INCOMPLETE || vm.sodStatus==vm.COMPLETED ||  vm.sodStatus==vm.INPROGRESS){
	    		loadworkFlowItemsWithStatus();
	    	}
		 }
		
		


		
         function reloadListAndMap(){
        	 vm.sodProcessList = angular.copy(vm.sodProcessListFromDB);
        	//var getKeysArray = Object.keys( vm.sodProcessList);
        	 vm.idArray = [];
         	 angular.forEach(vm.sodProcessList, function(item){               
                 vm.idArray.push(item.id);               
             });
        	 angular.forEach( vm.sodProcessList, function(item){
	    		 if(item.processName == 'ForciblelogoffUsers' ){
	    			 vm.forcibleLogOffID =item.id;
	    			
	    		 }else if(item.processName == 'ConnectToInteractiveMQ'){
	    			 vm.connectToMQID = item.id;
	    		 }
        	 });
        	 var indexOfForcibleLoggOff = vm.idArray.indexOf(vm.forcibleLogOffID);
        	 vm.sodProcessList[indexOfForcibleLoggOff].status = vm.NOTSTARTED;
    		 vm.sodProcessList[indexOfForcibleLoggOff].remarks='';
    		 
    		 var indexOfConnectToMQ = vm.idArray.indexOf(vm.connectToMQID);
        	 vm.sodProcessList[indexOfConnectToMQ].status = vm.NOTSTARTED;
    		 vm.sodProcessList[indexOfConnectToMQ].remarks='';
    		 
        	// vm.processStatusMap =  angular.copy(vm.newProcessStatusMap);
        	 vm.processResultMap =angular.copy(vm.newProcessResultMap);
        	 vm.processErrorMap = angular.copy(vm.newProcessErrorMap);
         }
         function lookupWorkFlowFailure(error){
        	 //do nothing
         }
		/**********************************END********************************************************/
		/******** FETCH ERROR MESSAGE AND SET TO GLOBAL VARIABLE *************************************/
         function loadErrorMEssages(){
        	 StaticLookUp.getProcessErrorMessages(loadErrorMEssagesSuccess, loadErrorMEssagesFailure);
         }
         function loadErrorMEssagesSuccess(result){
        	 vm.errorList  = result[0];
        	 //vm.globalValues.checkAndSetSelectedErrorMessages(result);        	
        	
         }
         function loadErrorMEssagesFailure(error){
        	 //do nothing
         }
         
        /******** END****************************************** *************************************/
		// if(vm.permitSODProcessAddEntityAction ==true){
			logScreenAccess();
			loadErrorMEssages();        
			loadDates();
	//    -

	
		/** set Intial State and initial status for each process and load the new businessDates *****/
		function loadDates() {
			
			
			// fetch the business date for which the SOD process is not done;
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
			
			loadWorkFlowItems();
	
		}	
		
		function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.sodStatus=vm.lastSystemSetting.sodStatus;
			vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
			convertedCurrentBusinessDate = DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
			vm.disbleSod = disableSodProcess();
			if(vm.sodStatus == vm.COMPLETED){
				AlertService.success($filter('translate')('gatewayApp.sodProcess.alreadyCompleted'));
			}else if(vm.sodStatus == vm.INPROGRESS){
				
				AlertService.error($filter('translate')('gatewayApp.sodProcess.alreadyInProgress'));
			}
		}
		function loadSystemSettingsError(){
			
		}
		
		// function to start the SOD process on start button click
		function beginSODProcess() {
			
			vm.isRefreshDisabled = false;
			vm.isSodProcessSuccessfull=null;
			vm.isSodCompleted = false;
			reloadListAndMap();
			if(vm.currentBusinessDate !=null && vm.currentBusinessDate !=undefined && vm.currentBusinessDate !=''){
				if ( vm.currentBusinessDate > vm.today ) {
					
					AlertService.error($filter('translate')('gatewayApp.sodProcess.forwardDateError'));
					
				}else if(vm.sodStatus == vm.COMPLETED){
					AlertService.success($filter('translate')('gatewayApp.sodProcess.alreadyCompleted'));
				}else if(vm.sodStatus == vm.INPROGRESS){
					
					AlertService.error($filter('translate')('gatewayApp.sodProcess.alreadyInProgress'));
				}
				else {
					//call the first process
					processSOD();
					//logOffUsersForcibly();
					
				}
			}
			

		}
		
		/*********************** Process methods before starting the sod process ********************************************/
		var processSOD = function processSOD(){
						
			SODProcess.processSOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.INPROGRESS
			}, processSODSucess, processSODError);
			
		}
		
		function processSODSucess(result){
			vm.executionCount = parseInt(result.executionCount);
			vm.executionCount = vm.executionCount+1;
			if(vm.sodStatus == vm.OPEN || vm.sodStatus == vm.INCOMPLETE){
				SODProcess.updateSODPRocessLog({"businessDate" : convertedCurrentBusinessDate},updateSODLogSuccess, updateSODLogFailure);
			}else{
				vm.sodStatus = vm.INPROGRESS;
				vm.disbleSod = true;			
				successProcessor(logOffUsersForcibly);
			}
		}
		function updateSODLogSuccess(result){
			
			vm.sodStatus = vm.INPROGRESS;
			vm.disbleSod = true;			
			successProcessor(logOffUsersForcibly);
			
		}function updateSODLogFailure(error){
			
			
		}
		
		function processSODError(error){
			var errorCode = errorProcessor(error);
			AlertService.error( errorCode );
		}
		
		/*********************** Process methods for forcible logoff users ********************************************/
		var logOffUsersForcibly = function logOffUsersForcibly(){
			
			//vm.forcibleLogOffUsersStatus = vm.INPROGRESS;	
			updateRemarksInProcessList(vm.forcibleLogOffID ,"",   vm.INPROGRESS);
			SODProcess.logOffUsersForcibly( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.forcibleLogOffID,
				"executionCount" : vm.executionCount
			}, logOffUsersForciblySucccess, logOffUsersForciblyError);
		}
		function logOffUsersForciblySucccess(result){
			//vm.forcibleLogOffUsersStatus = vm.COMPLETED;	
			updateRemarksInProcessList(vm.forcibleLogOffID ,"",  vm.COMPLETED);
			//invoke next process - auto withdrawal of pending customers
			successProcessor(startSODInOrderService);
		}
		function logOffUsersForciblyError(error){
				
			//vm.forcibleLogOffUsersStatus = vm.FAILED;			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.forcibleLogOffID ,errorCode , vm.FAILED );
			/*SODProcess.processSOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.INCOMPLETE
			}, processInCompleteSODSucess, processENDSODError)*/
		}
		/********** begin SOD in order service *************/
		var startSODInOrderService = function startSODInOrderService(){
			
			updateRemarksInProcessList(vm.connectToMQID ,"",   vm.INPROGRESS);
			//subscribe to notifications
			//subscribeToSODProcessUpdate();
			
			SODProcess.initiateSODInOrderService({
				"executionCount" : vm.executionCount,
				"businessDate" : convertedCurrentBusinessDate  },
					startSODInOrderServiceSuccess, startSODInOrderServiceFailure);
			
		}
		function startSODInOrderServiceSuccess(result){
			
			//do nothing as this is async call
		}
		function startSODInOrderServiceFailure(error){
			
			// SOD Failure
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.connectToMQID ,"",  vm.FAILED);
			SODProcess.processSOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.INCOMPLETE
			}, processInCompleteSODSucess, processENDSODError)
			AlertService.error( errorCode );
		}
		/********  begin SOD in order service *************/
		
		/*********************General method used in all process ***********************/
		function successProcessor(successProcessToInvoke){
			
			//if(vm.isSodProcessSuccessfull){				
				// next process is invoked only when previous one is is successful
				if(successProcessToInvoke!=null)
					successProcessToInvoke();
			//}
		}
		/*function updateRemarksInProcessList(processID, errorMessage, status){
			angular.forEach(vm.sodProcessList, function(item){
				if(item.id == processID  ){
					item.remarks = errorMessage;
					item.status = status ;
				}
			});
		}*/
		
		function updateRemarksInProcessList(processID, errorMessage, status){
			var indexOfProcess= vm.idArray.indexOf(processID);
			
			if(vm.sodProcessList[indexOfProcess]!=null && vm.sodProcessList[indexOfProcess]!=undefined && vm.sodProcessList[indexOfProcess] !='' ){
				vm.sodProcessList[indexOfProcess].remarks = errorMessage;
				vm.sodProcessList[indexOfProcess].status = status ;
			}
			
			
		}

		function errorProcessor(error){		
			var errorcode = "";
			if( error.message !=undefined &&  error.message !=null &&  error.message!=""){
				errorcode  = $filter('translate')( error.message ) //+  error.businessDate;				
				//vm.errorMessage = error.exceptionMessage +  errorcode ;
			}else if( !(error.data==undefined || error.data==null || error.data=='')){
				errorcode  = $filter('translate')( error.data.message );
				//vm.errorMessage = error.data.exceptionMessage +" "+  errorcode;
			}else{
				errorcode = $filter('translate')( "Service unavailable");
			}
			 		
			//AlertService.error( errorcode );
			vm.isSodProcessSuccessfull=false;
			return errorcode;
		}
		
		/********************************************/
		
		
		/***********************  END *************************************************************************************/
		/********** Refresh**********/
		  $scope.toggle = true;
	        function refresh() {
	            $scope.toggle = false;
	            $scope.refreshFlag = false;
	            refreshStatusAndProcessList();
	            if ($scope.toggle == false) {
	                $timeout(function() {
	                    $scope.toggle = true;
	                }, 2000);
	                $scope.refreshFlag = false;
	            }
	        };
	        function refreshStatusAndProcessList(){
				// fetch the business date for which the SOD process is not done;
				SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
				loadworkFlowItemsWithStatus();
			}
			function loadworkFlowItemsWithStatus(){
				SODProcess.getSodProcessStatusLog({"currentBusinessDate" : convertedCurrentBusinessDate},loadworkFlowItemsWithStatusSuccess, lookupWorkFlowFailure);
	        }
	       
			 function loadworkFlowItemsWithStatusSuccess(result){
				 var sodProcessStatusLog= result['newSodProcessLogList'];
				 vm.idArray = [];
				 angular.forEach(vm.sodProcessListFromDB, function(item){               
		                vm.idArray.push( item.id);               
		            });
				 vm.sodProcessList = angular.copy(vm.sodProcessListFromDB);
		    	// if(vm.sodStatus == vm.OPEN || vm.sodStatus==vm.INCOMPLETE){
		    		 
		    	angular.forEach( sodProcessStatusLog, function(item){
		    		var indexOfProcess = vm.idArray.indexOf(item.process.id);
		    		 if(item.isCompleted==true){
		    			 if(vm.sodProcessList[indexOfProcess]!=undefined && vm.sodProcessList[indexOfProcess]!=null){
		    			 	vm.sodProcessList[indexOfProcess].status = vm.COMPLETED;
		    			 	vm.sodProcessList[indexOfProcess].remarks='';
		    		 	}
		    		 }else{
		    			 if(vm.sodProcessList[indexOfProcess]!=undefined && vm.sodProcessList[indexOfProcess]!=null){
		    				 vm.sodProcessList[indexOfProcess].status = vm.FAILED;
		    				 if(vm.errorList[item.errorMessage]!=null && vm.errorList[item.errorMessage]!=undefined){
		    					 vm.sodProcessList[indexOfProcess].remarks=vm.errorList[item.errorMessage].rejectionReason;
		    				 }
		    			 }
		    		 }
		    		
		    		 
		    	 });
		    	
		    	
		    	//}
			 }
		/***********END *************/
	    function logScreenAccess(){
	           ScreenLog.screenLog( entityConstants.ENTITY_SHORT_NAME.SOD_PROCESS,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	        }
	    function disableSodProcess(){
	    	
	    	if(vm.currentBusinessDate ==undefined || vm.currentBusinessDate == null || vm.currentBusinessDate == ''){
	    		// no valid businessdate - disable start button
	    		return true;
	    	}else if(vm.sodStatus ==vm.OPEN ||vm.sodStatus==null || vm.sodStatus==undefined ||vm.sodStatus==''){
	    		// sodStatus is open for the business date then enable strat button
	    		vm.isRefreshDisabled=true;
	    		return false;
	    		
	    	}else if( vm.sodStatus ==vm.INCOMPLETE){
	    		vm.isRefreshDisabled=false;
	    		return false;
	    		
	    	}
	    	else if( (vm.sodStatus == vm.INPROGRESS ) && (vm.isSodProcessSuccessfull!=null && vm.isSodProcessSuccessfull==false)){
	    		// enable start button when sodStatus is in inprogress and sod process is not successfull
	    		vm.isRefreshDisabled=false;
	    		return  false;
	    	}else if( vm.sodStatus ==vm.INPROGRESS  && (vm.isSodProcessSuccessfull==null || vm.isSodProcessSuccessfull==true)){
	    		// disable start button when sodStatus is in inprogress and sod process is  successfull
	    		vm.isRefreshDisabled=false;
	    		return true;
	    	}else if( vm.COMPLETED ==vm.sodStatus ){
	    		vm.isRefreshDisabled=true;
	    		return true;
	    	}
	    	
	    }
	 // Watch for Process failure through the isSodProcessSuccessfull flag , if it is failed then enable the start button
	    $scope.$watch('vm.isSodProcessSuccessfull', function(value){
			if(value!=null && value!=undefined && value == false){
				
				//method to enable or disable start button if process is unsuccessfull
				vm.sodStatus = vm.INCOMPLETE;
				vm.disbleSod = disableSodProcess();
				/*SODProcess.processSOD( {				
					"businessDate" : convertedCurrentBusinessDate,
					"status" : vm.INCOMPLETE
				}, processInCompleteSODSucess, processENDSODError);*/
			}else if(value!=null && value!=undefined && value == true){
				vm.sodStatus = vm.INPROGRESS;
				vm.disbleSod = disableSodProcess();
			}
		
			});
	    $scope.$watch('vm.isSodCompleted', function(value){
			 if(value==true ){
				
				//method to enable or disable start button if process is unsuccessfull
					vm.sodStatus = vm.COMPLETED;
					vm.disbleSod = disableSodProcess();
					
					AlertService.success($filter('translate')('gatewayApp.sodProcess.sodProcesscompleted'));
					SODProcess.logOffUsersForcibly( {				
							"currentBusinessDate" : convertedCurrentBusinessDate,
							"processId" : 0,
							"executionCount" : vm.executionCount
					});	
				// update the status as completed when the isSODProcessSuccessful is true and inprogress
				/*SODProcess.processSOD( {				
					"businessDate" : convertedCurrentBusinessDate,
					"status" : vm.COMPLETED
				}, processENDSODSucess, processENDSODError);*/
			}
			});
	  //function subscribeToSODProcessUpdate(){
	    orderServiceSODProcessUpdateSubscription = $rootScope.$on("orderServiceSODProcessUpdate", function(event,result){
	    	
	    	if( result.success!=vm.processResultMap[result.processID]){
	    
	    		processStatusNotificationFromOrderService(result);
	    	}else if( result.success==false && result.exceptionNumber!=vm.processErrorMap[result.processID]){
	    		//update new error code
	    		processStatusNotificationFromOrderService(result);
	    	}
	    	
         });
	   //}
	   function unsubscribeToSODProcessUpdate(){
		   if(orderServiceSODProcessUpdateSubscription!=null){
			   // unscribe to order service update notification
			   orderServiceSODProcessUpdateSubscription();
		   }
	   }
	function processENDSODSucess(result){
		//unsubscribeToSODProcessUpdate();
		// need to check whether one more forcible log off is required
		//successProcessor(logOffUsersForcibly);
		if(vm.sodStatus == vm.COMPLETED){
			AlertService.success($filter('translate')('gatewayApp.sodProcess.sodProcesscompleted'));
			SODProcess.logOffUsersForcibly( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : 0,
				"executionCount" : vm.executionCount
		});	
		}
		 
		
		
		
	}
	function processInCompleteSODSucess(result){
		//unsubscribeToSODProcessUpdate();
		 /*if(vm.sodStatus == vm.INCOMPLETE){
			AlertService.error($filter('translate')('gatewayApp.sodProcess.sodProcessFailed'));
		}*/
	}
	function processENDSODError(error){
		//DO NOthing
		//unsubscribeToSODProcessUpdate();		
	}
	

	
	function processStatusNotificationFromOrderService(processStatus){
		
		//angular.forEach( vm.sodProcessList, function(item,index){
		var indexOfProcess = vm.idArray.indexOf(processStatus.processID);
		var item = vm.sodProcessList[indexOfProcess];
			//console.log("process", index);
			//console.log("process", item);
		
			if(item.id==processStatus.processID &&  processStatus.success==true ){
				
				item.status = vm.COMPLETED;
				item.remarks = "";
				
				//var keyArray = Object.keys(vm.sodProcessList);
				var keyArray = vm.idArray
				var index = keyArray.indexOf(processStatus.processID);
				var nextIndex = index+1;
				if(nextIndex < keyArray.length){
					var nextProcess = vm.sodProcessList[nextIndex];
    				if(nextProcess!=undefined && nextProcess!=null && nextProcess!='' && nextProcess.status != vm.COMPLETED && nextProcess.status !=vm.FAILED){
    					nextProcess.status = vm.INPROGRESS ;
    					
    				}
				}else if(nextIndex == keyArray.length){
					//vm.isSodProcessSuccessfull = true;
					vm.isSodCompleted  = true;
				}
				//vm.processStatusMap[processStatus.processID] =true;
				 vm.processResultMap[processStatus.processID] = processStatus.success;
				 vm.processErrorMap[processStatus.processID] = null;
				 vm.isSodProcessSuccessfull = true;
			
			}else if(item.id==processStatus.processID &&  processStatus.success==false){
			 					
				item.status = vm.FAILED;
				item.remarks = "Process Failed";
				vm.isSodProcessSuccessfull = false;	
				var errorKey = processStatus.exceptionNumber+""+processStatus.messageNumber;
				
				if( vm.errorList[errorKey]!=null && vm.errorList[errorKey]!=undefined 
						&& vm.errorList[errorKey]!='' ){
					item.remarks =  vm.errorList[errorKey].rejectionReason;
					
				}
				
				//vm.processStatusMap[processStatus.processID] =true;
				 vm.processResultMap[processStatus.processID] = processStatus.success;
				 vm.processErrorMap[processStatus.processID] = processStatus.exceptionNumber;
				
			}
		
			
		}
	
	  var webscoketDisconnect = $rootScope.$on("websocketDisconnected", function(event,result){
	    	
	    	console.log("web socket disconnected with reason" + result);
	    	angular.forEach( vm.sodProcessList, function(item){
	   		 	if(item.status == vm.INPROGRESS){
	   		 		item.status = vm.FAILED;
	   		 		item.remarks=vm.errorList["7001"].rejectionReason;
	   		 	}
	    		
	    	
	    	});
	    	
       });
		// unregister the event when destroying the controller
	  $scope.$on('$destroy', function() {
		  unsubscribeToSODProcessUpdate();
		  if(webscoketDisconnect!=null){
			  webscoketDisconnect();
		  }
      });
	 	
	}
})();
