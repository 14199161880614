(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AlertConfigurationEditController', AlertConfigurationEditController);

    AlertConfigurationEditController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AlertConfiguration', '$uibModalInstance', 'GlobalValues', 'OrganisationManipulation', 'ScreenLog'];

    function AlertConfigurationEditController($scope, $rootScope, $stateParams, entity, AlertConfiguration, $uibModalInstance, GlobalValues, OrganisationManipulation, ScreenLog) {
        var vm = this;

        if(entity!=null){
        	vm.alertConfigurationHeader=entity;
        	
        	/*vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails=vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails;
        	vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails=vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails;*/
        }
        vm.clear=clear;
        vm.save=save;
        vm.alertConfigurationDTO={};
        vm.alertConfigurationNotifyUserDetails = [];
        vm.alertConfigurationNotifyByTypeDetails = [];

        vm.eventType = AlertConfiguration.getEventTypes();
        vm.eventTypes = AlertConfiguration.getEventTypes();
        vm.reminderType = AlertConfiguration.getReminderTypes();
        vm.reminderTypes = AlertConfiguration.getReminderTypes();
        vm.notifyByTypes = AlertConfiguration.getNotifyByTypes();
        vm.notifyByTypesForAllAlerts =vm.notifyByTypes;
        vm.notificationTypes = AlertConfiguration.getNotificationTypes();
        vm.natureReminderMapping = AlertConfiguration.getNatureReminderMapping();
        vm.ownerOrganisation = GlobalValues.getOrganisation();
        
        vm.users = AlertConfiguration.getUsersForOrganisation();

        vm.roles = AlertConfiguration.getRolesForOrganisation({"orgId" : GlobalValues.getOrganisation().id});

        vm.alertConfigurationHeader = entity;
        //ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': vm.alertConfigurationHeader.id, 'action': 'EDIT'});
        var notifyByTypeDetailsArr=vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails;
        vm.notifyByTypeList=[];
        angular.forEach(notifyByTypeDetailsArr, function(value, key) 
        {
        	if (value.active==true){
            	vm.notifyByTypeList.push(value.notifyByType.id);
        	}
         });
        
        var notifyUserDetailsArr=vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails;
        vm.userList=[];
        vm.roleList=[];
        angular.forEach(notifyUserDetailsArr, function(value, key) 
        {
        	if(value.active==true){
        		if (vm.alertConfigurationHeader.configNotifyUsersBy == 'users'){
                	vm.userList.push(value.user.id);
        		}else{
                	vm.roleList.push(value.organisationRole.id);
        		}
        	}
        });
        //vm.previousState = previousState.name;
        
        vm.getEventTypes = function getEventTypes(notificationType){
        	if(notificationType!=null){
            	vm.eventType = null;
            	var as = $(vm.eventTypes)
    	        .filter(function (i,n){
    	            return (n.notificationType.id == notificationType.id);
    	        })
    			if (Object.keys(as).length > 3){
    	        	vm.eventType = as;
    			}
        	}
        }

        vm.getReminderTypes = function getReminderTypes(eventType){
        	if(eventType!=null){
            	vm.reminderTypesForEvent = null;
            	var reminderType = [];
            	var as = $(vm.natureReminderMapping)
    	        .filter(function (i,n){
    	            return (n.nature == eventType.nature);
    	        })
            	vm.reminderTypesForEvent = as;
            	if(vm.reminderTypesForEvent.length>0){
                	angular.forEach(vm.reminderTypesForEvent, function(item){
                		reminderType.push(item.reminderType);
                	})
                	vm.reminderType = reminderType;
            	}
        	}
        }

        vm.getNotifyByTypesForAlert = function getNotifyByTypesForAlert(alertType){
        	if(alertType != null){
        		vm.notifyByTypesForAlert = null;
        		        		
        		if(alertType.shortName == 'Upload Error Alert'){
                	var as = $(vm.notifyByTypesForAllAlerts)
        	        .filter(function (i,n){
        	            return (n.shortName == 'Email');
        	        })
                	vm.notifyByTypesForAlert = as;
                	
                	vm.notifyByTypes = vm.notifyByTypesForAlert;
        		}else{
        	        vm.notifyByTypes = vm.notifyByTypesForAllAlerts;
        		}
        	}
        } 
        
        vm.getNotifyByTypes = function getNotifyByTypes(selectedNotifyByList, notifyByType){
        	if (selectedNotifyByList.length==1 && notifyByType.shortName=='Email'){
            	var notifyByList = [];
            	notifyByList = selectedNotifyByList;
            	var as = $(vm.notifyByTypes)
    	        .filter(function (i,n){
    	            return (n.shortName == 'On app');
    	        })
    	        vm.OnAppNotifyByType = as;
            	if (selectedNotifyByList[0] != vm.OnAppNotifyByType[0].id){
                	notifyByList.push(vm.OnAppNotifyByType[0].id);
                	vm.notifyByList = notifyByList;
            	}
        	}
        }

        vm.flag = 0;
        vm.branchId = 0;
        vm.getUsers = function getUsers(branch){
        	if ((branch!=null && vm.flag==0) || (vm.branchId != 0 && vm.branchId != branch.id)){

        		vm.users = AlertConfiguration.getUserBranchClearances({"branchId":branch.id});
            	vm.branchId = branch.id;
            	vm.flag=1;

        	}
        }
        
       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save()
        {
        	addAlertConfigurationNotifyUserDetails();
        	
        	addAlertConfigurationNotifyByTypeDetails();
        	
        	prepareAlertConfigurationDTOToUpdate();
        	
        	AlertConfiguration.update({"orgId":GlobalValues.getUserOrganisation().id}, vm.alertConfigurationDTO, onSaveSuccess, onSaveError);   
        }
     
        function addAlertConfigurationNotifyUserDetails(){
        	if(vm.alertConfigurationHeader.configNotifyUsersBy=='users'){
            	for (var j=0; j<=vm.userList.length-1; j++){
            		if (vm.userList[j]!=null){
                		vm.filteredNotifyUser={};
                    	var as = $(vm.users)
            	        .filter(function (i,n){
            	            return (n.id == vm.userList[j]);
            	        })
            	        vm.filteredNotifyUser = as;
                		vm.alertConfigurationNotifyUserDetails.push({
                				"user":{"id":vm.filteredNotifyUser[0].id,"login":vm.filteredNotifyUser[0].login,"firstName":vm.filteredNotifyUser[0].firstName,"lastName":vm.filteredNotifyUser[0].lastName,"email":vm.filteredNotifyUser[0].email,"activated":vm.filteredNotifyUser[0].active,"langKey":vm.filteredNotifyUser[0].langKey,"resetKey":vm.filteredNotifyUser[0].resetKey,"mobileCountryCode":vm.filteredNotifyUser[0].mobileCountryCode,"mobile":vm.filteredNotifyUser[0].mobile,"userType":vm.filteredNotifyUser[0].userType,"resetDate":vm.filteredNotifyUser[0].resetDate,"active":vm.filteredNotifyUser[0].active},
                    			"organisationRole": null
                    	});
            		}
            	}
        	}
        	else{
            	for (var j=0; j<=vm.roleList.length-1; j++){
            		if (vm.roleList[j]!=null){
                		vm.filteredNotifyRole={};
                    	var as = $(vm.roles)
            	        .filter(function (i,n){
            	            return (n.id == vm.roleList[j]);
            	        })
            	        vm.filteredNotifyRole = as;
                		vm.alertConfigurationNotifyUserDetails.push({
            				"user":null,
                			"organisationRole": {"id":vm.filteredNotifyRole[0].id,"active":vm.filteredNotifyRole[0].active,"systemGenerated":vm.filteredNotifyRole[0].systemGenerated,"shortName":vm.filteredNotifyRole[0].shortName,"name":vm.filteredNotifyRole[0].name,"objectType":vm.filteredNotifyRole[0].objectType}
                		});
            		}
            	}
        	}
        }
        
        function addAlertConfigurationNotifyByTypeDetails(){
        	vm.notifyByType=[];
        	for (var j=0; j<=vm.notifyByTypeList.length-1; j++){
        		vm.filteredNotifyByType={};
            	var as = $(vm.notifyByTypes)
    	        .filter(function (i,n){
    	            return (n.id == vm.notifyByTypeList[j]);
    	        })
    	        vm.filteredNotifyByType = as;
            	vm.alertConfigurationNotifyByTypeDetails.push({
    			'notifyByType': vm.filteredNotifyByType[0]
    			});
        	}
        }
        
        function prepareAlertConfigurationDTOToUpdate(){
        	
        	if (vm.alertConfigurationHeader.numberOfDaysToNotify == null || vm.alertConfigurationHeader.numberOfDaysToNotify == ""){
        		vm.alertConfigurationHeader.numberOfDaysToNotify = 0;
        	}
        	
        	vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails=[];
        	vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails=[];

        	delete vm.alertConfigurationHeader.ownerOrganisation;
        	delete vm.alertConfigurationHeader.creatorOrganisation;
        	
        	vm.alertConfigurationDTO.alertConfigurationHeader=vm.alertConfigurationHeader;
        	var alertConfigurationNotifyUserDetails=[];
        	alertConfigurationNotifyUserDetails=Array.from(vm.alertConfigurationNotifyUserDetails);
        	var alertConfigurationNotifyByTypeDetails=[];
        	alertConfigurationNotifyByTypeDetails=Array.from(vm.alertConfigurationNotifyByTypeDetails);
       		
        	vm.alertConfigurationDTO.alertConfigurationNotifyUserDetails=alertConfigurationNotifyUserDetails;       		
        	vm.alertConfigurationDTO.alertConfigurationNotifyByTypeDetails=alertConfigurationNotifyByTypeDetails; 
        	vm.alertConfigurationDTO.organisationDTO = OrganisationManipulation.checkAndGetOrganisationDTO(vm.ownerOrganisation);
        	
        }
        
       function onSaveSuccess (result) {
    	  $scope.$emit('alertRefresh');
       	 $uibModalInstance.close(result);  
       }

       function onSaveError () {
       	
       }
        
    }
})();
