(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password', {
            parent: 'account',
            url: '/password',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.menu.account.password'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/password/password.html',
                    controller: 'PasswordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('password');
                    return $translate.refresh();
                }],
                configSettings: ['StaticLookUp', function(StaticLookUp) {
                    return StaticLookUp.getConfigSettingsPreLogin().$promise;
                }]
            }
        })
        .state('change-password', {
            parent: 'home',
            url: 'change-password/{orgType}',
            params: {login: null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.changepassword" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/changepassword/changepassword.html',
                    controller: 'ChangePasswordController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('password');
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }],
                        configSettings: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettingsPreLogin().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('home');
                }, function() {
                    $state.go('home');
                });
            }]
            
        })
        .state('change-password-expiry', {
            parent: 'login',
            url: 'change-password-expiry/{orgType}',
            params: {login: null},
            data: {
                authorities: [],
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.changepassword" | translate }}',
                parent: 'login'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/changepassword/changepassword.html',
                    controller: 'ChangePasswordController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('password');
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }],
                        configSettings: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettingsPreLogin().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('login', null, {reload:'login'});
                }, function() {
                	$state.go('login', null, {reload:'login'});
                });
            }]
            
        })  .state('change-password-user-profile', {
            parent: 'user-profile',
            url: 'change-password/user-profile',
            params: {login: null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.changepassword" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/changepassword/changepassword.html',
                    controller: 'ChangePasswordController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('password');
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }],
                        configSettings: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettingsPreLogin().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        ;
    }
})();
