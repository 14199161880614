(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('MarkupSettingsEditController', MarkupSettingsEditController);

    MarkupSettingsEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','Markup','entity','StaticLookUp', 'markupTypes','DateUtils', 'roEdApplicabilityMethods'];

     function MarkupSettingsEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,Markup,entity,StaticLookUp, markupTypes, DateUtils, roEdApplicabilityMethods) {
        var vm = this;
        vm.markup=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        vm.resubmitMarkup = resubmitMarkup;
        vm.globalValues = GlobalValues;
        vm.markupTypes = markupTypes;
        vm.entityOwnerType = vm.globalValues.getUser().entityOwnerType.shortName;
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        vm.currentBusinessDate = vm.globalValues.getSystemSettings().businessDate;
        
        vm.effectiveFromDateTime = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
        vm.todayDate = new Date();
        
        //get RO/ED applicability method list lookup
        // StaticLookUp.getRoEdApplicabilityMethodList(function(result){
        //     vm.roEdApplicabilityMethodList = result;
        // });
		vm.roEdApplicabilityMethodList = roEdApplicabilityMethods;
        
        var now = moment();
   	 	vm.momentBusinessDate = moment(vm.currentBusinessDate, 'YYYY-MM-DD');        	
   	 	vm.momentBusinessDate.set(
   			 { 	'hour':now.get('hour'),
   				'minute':now.get('minute'),
   				'second':now.get('second')
   				
   			 });
   	 	vm.effectiveFromDateTime = vm.momentBusinessDate.toDate();
   	 
        vm.disableTax=false;
        vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.decimalForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
       
       vm.todayDate = new Date();
       angular.forEach(vm.markupTypes, function(value){
   		if(value.shortName.toUpperCase()=='PAISE'){
       		vm.markupType = value; 
   		}
   	});
       
       $scope.$watch('vm.markupType', function(value){
       	if(value!=null && value!=undefined){
       		if(vm.markupType.shortName.toUpperCase()=='PERCENTAGE'){
       			vm.noOfDecimals=vm.decimalsForPercent;
    			document.getElementById("txtMarkupValue2").value = "";
    			vm.markup.markupValue = null;
       		}else{
       			vm.noOfDecimals=vm.decimalForPaise;
    			document.getElementById("txtMarkupValue1").value = "";
    			vm.markup.markupValue = null;
       		}
       	}
       });
       
       $scope.$watch('vm.markup.markupValue', function(value){
       	if(value==null || value==undefined || value==""){
       		
       		vm.disableTax=true;
       		document.getElementById("txtTaxOnMarkup").value = "";
       		vm.markup.taxOnMarkup=null;
       		
       	}else{
       		vm.disableTax=false;
       	}
       });
                
        if(workflowSharedService.viewFrom == 'markup'){
        	vm.markup=entity;
        	
        	if(vm.markup!=null && vm.markup!=undefined){
        		vm.branch=vm.markup.branch;
        		
        	}
        	vm.effectiveFromDateTime = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
        	 if(vm.entityOwnerType=='Bank'){
          	   vm.bank=vm.globalValues.getBank();
             }else if(vm.entityOwnerType=='Branch'){
          	   vm.bank=vm.globalValues.getBranch().bank;
          	   vm.branch=vm.globalValues.getBranch();
             }
        	 if(vm.markup.markupType!=null && vm.markup.markupType!=undefined){
        		 angular.forEach(vm.markupTypes, function(value){
             		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
                 		vm.markupType = value; 
             		}
             	});
        	 }
          if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
              vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
          }else{
              var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
              vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
          }

       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'markuptodolist_resubmit') {
        	vm.markup =  angular.fromJson($rootScope.payload);
        	vm.bank=vm.markup.bank;
        	vm.branch=vm.markup.branch;
        	vm.effectiveFromDateTime = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
          if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
              vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
          }else{
              var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
              vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
          }

        	vm.disableForm = false;
        	$scope.showButton=true;
        }
        else if(workflowSharedService.viewFrom == 'markup_resubmit_delete') {
        	
        	vm.markup = angular.fromJson($rootScope.payload); 
        	vm.bank=vm.markup.bank;
        	vm.branch=vm.markup.branch;
        	vm.effectiveFromDateTime = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
          if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
              vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
          }else{
              var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
              vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
          }

        	vm.disableForm = true;
        	$scope.showButton=true;
        }
		
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	vm.markup.markupType = vm.markupType.shortName;
        	vm.markup.roEdApplicabilityMethod = vm.roEdApplicabilityMethod;
        	vm.markup.bank=vm.bank;
        	vm.markup.branch=vm.branch;
        	vm.markup.effectiveFromDateTime = vm.momentBusinessDate.toDate();
        	
        	if (vm.markup.id != null) {
				Markup.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.markup, onSaveSuccess, onSaveError);
			} else {
				Markup.save({
					"orgId" : GlobalValues.getOrgId()
				}, vm.markup, onSaveSuccess, onSaveError);
			}
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitMarkup() {
        	vm.markup.markupType = vm.markupType.shortName;
        	vm.markup.roEdApplicabilityMethod = vm.roEdApplicabilityMethod;
        	vm.markup.bank=vm.bank;
        	vm.markup.branch=vm.branch;
        	vm.markup.effectiveFromDateTime = vm.momentBusinessDate.toDate();
        	
        	Markup.resubmit({"taskId" : $rootScope.taskID}, vm.markup, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {}

       
     }   
})();
