(function () {
    'use strict';
    angular
        .module('gatewayApp')
        .constant('OrganisationTypeEnum', {
        	CLEARCORP:'CLEARCORP',OTHERS:'OTHERS',ADMIN:'admin'

        })
        .run(['$rootScope', 'OrganisationTypeEnum', function($rootScope, OrganisationTypeEnum) {
            $rootScope.OrganisationTypeEnum = OrganisationTypeEnum;
        }]);
})();
