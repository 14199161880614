(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'transactionCharges'
        })
        
        .controller('TransactionChargesController', TransactionChargesController);

    TransactionChargesController.$inject = ['$scope', '$state', 'TransactionCharges', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'$timeout','DateUtils','AlertService','$filter', 'MarkupReport'];

    function TransactionChargesController($scope, $state, TransactionCharges , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		$timeout,DateUtils,AlertService,$filter, MarkupReport) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRANSACTION_CHARGES;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.clicked=false;
        vm.todayDate = new Date();
        vm.yesterdayDate = new Date();
        vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.fromDate = DateUtils.convertDateTimeFromServer(vm.yesterdayDate);
        vm.toDate = DateUtils.convertDateTimeFromServer(vm.yesterdayDate);
        vm.exportToFile = exportToFile;
        vm.transactionCharges = [];
        vm.invoiceDTO = {};
        vm.dailyTransactions=[];
        vm.valid=false;
        vm.selectedCustomerTransaction = {};
        
        
        vm.noOfMonthsForHistoricalView = vm.globalValues.getConfigSettings().noOfMOnthsForHistoricalReportsView;
        
        vm.mimeType= '';  	  
  	  	vm.baseString =   'Transaction_Charges_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
        
        MarkupReport.getCurrentBusinessDate(getBusinnessDateSuccess, getBusinessDateFailed);
        
        function getBusinnessDateSuccess(result){
        	
        	if(result != null && result != undefined){
        		vm.yesterdayDate  = DateUtils.convertLocalDateFromServer(result.date);
        		vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
        		vm.fromDate = DateUtils.convertDateTimeFromServer(vm.yesterdayDate);
                vm.toDate = DateUtils.convertDateTimeFromServer(vm.yesterdayDate);
                vm.minDate = DateUtils.getMinimumLocalDate(vm.fromDate);
        	}
        	
        	
        }
        
        function getBusinessDateFailed(){
        	
        }
        
        if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
        	 vm.loggedInCustomer = vm.globalValues.getCustomerId();
        }
        if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
        	vm.bank = vm.globalValues.getBank();
        }
        if(vm.entityOwnerType=='Branch'){
        	vm.branch = vm.globalValues.getBranch();
        }
        
       
        vm.getTransactionHeader = getTransactionHeader;
        vm.getTransctionDetails = getTransctionDetails;
        vm.ExpandOrCollapseTransactionCharges=true;
        
        workflowSharedService.viewFrom = "transactionCharges";
       
        
        logScreenAccess();
      

       function getTransactionHeader() {
    	   
    	   vm.bankId = 0;
    	   vm.branchId = 0;
    	   vm.customerId= 0;
    	   vm.selectedCustomerTransaction = {};
    	   vm.showDetails = false;
    	   
    	   vm.invoiceDTO = {};
    	   vm.transactionCharges = [];
    	   
    	   if(moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.transactionCharges.futureFromDate'));
	   			vm.valid=false;
	   		}
	   		else if(moment(vm.fromDate).isAfter(vm.toDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.transactionCharges.greaterFromDate'));
	   			vm.valid=false;
	   		}else if(moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.transactionCharges.greaterToDate'));
	   			vm.valid=false;
	   		}else if(moment(vm.fromDate).isBefore(vm.minDate, 'day')){
	   			AlertService.error('Transaction Charges Reports are available only for a period of '  + vm.noOfMonthsForHistoricalView + ' months...!');
	   			vm.valid=false;
	   		}
	   		else{
	   				vm.valid=true;
					vm.clicked=true;
		    	   if (vm.entityOwnerType == 'Bank' || vm.entityOwnerType == 'Branch'){
		    		   vm.bankId = vm.globalValues.getOrganisation().id;
		    	   }else if(vm.bank!=null && vm.bank!=undefined){
		    		   if(vm.entityOwnerType == 'Clearcorp' || vm.entityOwnerType == 'MultiUserCustomer' || vm.entityOwnerType == 'SingleUserCustomer'){
			    			  vm.bankId = vm.bank.id;
			    		  }
		    	   }
		    	   
		    	   if(vm.entityOwnerType == 'Branch'){
		    		   vm.branchId = vm.globalValues.getBranch().id;
		    	   }else  if(vm.branch!=null && vm.branch!=undefined){
		    		   if(vm.entityOwnerType=='Clearcorp' || vm.entityOwnerType=='Bank'){
		    			   vm.branchId = vm.branch.id;
		    		   }
		    	   }
		    	   
		    	   if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
		    		   vm.customerId = vm.globalValues.getCustomerId();
		    	   } else  if(vm.customer!=null && vm.customer!=undefined){
		    		   if(vm.entityOwnerType!='MultiUserCustomer' && vm.entityOwnerType!='SingleUserCustomer'){
		    			   vm.customerId = vm.customer.id;
		    		   }
		    	   }
	    	   
	    	       	   
		    	   vm.convertedFromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
		    	   vm.convertedToDate = DateUtils.convertLocalDateToServer(vm.toDate);
		    	   
		    	   TransactionCharges.getTransactionChargesHeader({"bankId": vm.bankId, "branchId":vm.branchId , "customerId" : vm.customerId ,
	    		   "fromDate": vm.convertedFromDate, "toDate" : vm.convertedToDate},function(result) {  
	    			   vm.invoiceDTO = result;
	    			   vm.transactionCharges = result.customerTransactionDTOList;
					   vm.clicked=false;
	             });        	
	   		}
    	  
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function getTransctionDetails(transactionDetails){
    	   vm.dailyTransactions=[];
    	   vm.selectedCustomerTransaction = transactionDetails; 
    	   vm.dailyTransaction=vm.selectedCustomerTransaction.dailyTransactionSummationList;
    	   vm.showDetails = true;
       }
      function exportToFile(fileType){
    	  vm.baseString =   'Transaction_Charges_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
    	  if( vm.invoiceDTO !=null &&  vm.invoiceDTO!=undefined){
    		  if(fileType == 'csv'){
    			 vm.mimeType = 'text/csv'
    			 vm.baseString = vm.baseString+ ".csv";  
    			 TransactionCharges.exportTransactionChargesCsv(vm.invoiceDTO , processResponse )
    		  }else if(fileType == 'excel'){
    			  vm.mimeType = 'application/vnd.ms-excel';
    			  vm.baseString = vm.baseString+ ".xls";  
    			  TransactionCharges.exportTransactionChargesExcel(vm.invoiceDTO , processResponse );
    		  }
    	  }
      }	
      function processResponse(result){
    	  var fileData = null;
		  fileData = result.data;
		  var link = document.createElement("a");   
		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
		  
		  
	      var blob = new Blob([bytes], {type: vm.mimeType});
	      if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	      }else{
	    	  link.href =  window.URL.createObjectURL(blob);      
	    	  link.download = vm.baseString;
	    	  document.body.appendChild(link);
	    	  link.click();
	    	  document.body.removeChild(link);
	      }
	  }

    }
})();
