(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth', 'LoginService','ChangeEmail'];

    function ResetFinishController ($stateParams, $timeout, Auth, LoginService,ChangeEmail) {
        var vm = this;

        vm.keyMissing = $stateParams.key;
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;

        changeEmail();
        
        function changeEmail() {
        	
        	console.log(vm.keyMissing);
        	ChangeEmail.update({"key" : vm.keyMissing}, onSaveSuccess, onSaveError);
        }
        
        function onSaveSuccess(result) {
        	 if (result.hasOwnProperty('IsPendingEmailChangeDiscarded') && result.IsPendingEmailChangeDiscarded != null && result.IsPendingEmailChangeDiscarded == true) {
        		 vm.IsPendingEmailChangeAlreadyDiscarded = true;
        	 }else{
        		 vm.IsPendingEmailChangeAlreadyDiscarded = false;
        	 }
        	 console.log(vm.IsPendingEmailChangeAlreadyDiscarded);
		}

		function onSaveError() {

			//$scope.clicked = false;
		}
		
        
        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
