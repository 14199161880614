(function() {
	'use strict';
	angular.module('gatewayApp').factory('CustomerOnBoardingExceptionReport',
			CustomerOnBoardingExceptionReport);

	CustomerOnBoardingExceptionReport.$inject = [ '$resource' ];

	function CustomerOnBoardingExceptionReport($resource) {
		var resourceUrl = 'api/customer-on-boarding-report/:id';

		return $resource(resourceUrl, {}, {

			'getCustomerOnBoardingReportDetails' : {
				isArray : true,
				method : 'GET',
				params : {
					from : '@from',
					to : '@to'
				},
				url : 'api/customer-on-boarding-details/:from/from/:to/to'
			},
			'exportExcelData':{
			    method: 'POST',
                url: 'api/customer-on-boarding-exception-details/excel',
                responseType: 'arraybuffer',
                params : {
					from : '@from',
					to : '@to'
				},
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
			},
			'exportCSVData':{
			    method: 'POST',
                url: 'api/customer-on-boarding-exception-details/csv',
                responseType: 'arraybuffer',
                params : {
					from : '@from',
					to : '@to'
				},
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
			}

		});
	}
})();
