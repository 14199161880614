(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('GenericCustomers', GenericCustomers);

    GenericCustomers.$inject = ['$resource'];

    function GenericCustomers ($resource) {
        var resourceUrl =  'api/customer-onboarding-import';
        
        return $resource(resourceUrl, {}, {
        	'customerImport': { method: 'GET', 
        	    url: 'api/customer-onboarding-import'
        	 },
         	
              
        });
    }
})();
