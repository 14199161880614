(function() {
	'use strict';
	angular
		.module('gatewayApp')
		.factory('TrackStatus', TrackStatus);

	TrackStatus.$inject = [ '$resource' ];

	function TrackStatus($resource) {
		var resourceUrl = 'api/public/customer-onboarding-update-activation-status/:key';

		return $resource(resourceUrl, {}, {
			'updateStatus' : {
				method : 'GET',
				params : {key : '@key'},
				url : 'api/public/customer-onboarding-update-activation-status/:key',
				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
			},
			'viewStatus' : {
				method : 'GET',
				params : {key : '@key'},
				url : 'api/public/customer-onboarding-status/:key',
				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
			}
		});
	}
})();
