(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'approvalPolicy'
        })
        
        .controller('ApprovalPolicyController', ApprovalPolicyController);

    ApprovalPolicyController.$inject = ['$scope', '$state', 'workflowSharedService', 'ApprovalPolicy', 'GlobalValues', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function ApprovalPolicyController($scope, $state, workflowSharedService, ApprovalPolicy, GlobalValues, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.entityConstants = entityConstants;
        workflowSharedService.viewFrom = "approvalPolicy";
        vm.EntityStateApprovalProcessScreen = entityConstants.ENTITY_SHORT_NAME.ENTITY_STATE_APPROVAL_PROCESS;
        vm.ApprovalProcessScreen = entityConstants.ENTITY_SHORT_NAME.APPROVAL_PROCESS;
        vm.screenLog = ScreenLog;
        vm.launch=launch;
        $scope.refresh=refresh;
        

        vm.permitEntityStateApprovalProcessMapEntityAction = EntityPermissions.hasPermission('EntityStateApprovalProcess.MAP');
        vm.permitApprovalProcessViewEntityAction = EntityPermissions.hasPermission('ApprovalProcess.VIEW');
        vm.permitApprovalProcessCreateEntityAction = EntityPermissions.hasPermission('ApprovalProcess.CREATE');
        vm.permitApprovalProcessEditEntityAction = EntityPermissions.hasPermission('ApprovalProcess.EDIT');
        vm.permitApprovalProcessDeleteEntityAction = EntityPermissions.hasPermission('ApprovalProcess.DELETE');
        
        function launch() {
        	$state.go("approval-policy.new");
        }
        
        loadAll();

        function loadAll() {
        	ApprovalPolicy.query({"orgId":GlobalValues.getOrgId()},function(result) {
                vm.approvalPolicies = result;
            });        
        	vm.screenLog.screenLog(vm.ApprovalProcessScreen,0, vm.entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }        
        
        /*vm.screenLog = function screenLog(entityRecordId,action){
        	if(action=='MAP'){
            	ScreenLog.screenLog({'entityName': 'EntityStateApprovalProcess', 'entityRecordId': entityRecordId, 'action': action});
        	}else{
            	ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': entityRecordId, 'action': action});
        	}
        }*/
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };

    }
})();
