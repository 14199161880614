(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('intraday-market-session-settings', {
            parent: 'entity',
            url: '/intraday-market-session-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.intradayMarketSessionSettings.manageIntradayMarketSessionSettings" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/intraday-market-session-settings/intraday-market-session-settings.html',
                    controller: 'intradayMarketSessionSettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('intradayMarketSessionSettings');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
        .state('intraday-market-edit', {
            parent: 'intraday-market-session-settings',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intraday-market-session-settings/intraday-market-session-settings-dialog.html',
                    controller: 'IntradayMarketSessionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['IntradayMarket', 'GlobalValues', function(IntradayMarket, GlobalValues) {
                                     return IntradayMarket.get({id : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('intraday-market-session-settings-todolist', {
            parent: 'dashboard',
            url: '/intradaymarketSessionSEttings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intraday-market-session-settings/intraday-market-session-settings-dialog.html',
                    controller: 'IntradayMarketSessionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('intraday-market-session-settings', null, { reload: 'intraday-market-session-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('intraday-market-todolist-resubmit', {
            parent: 'dashboard',
            url: '/intradaymarketSettingsEdit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intraday-market-session-settings/intraday-market-session-settings-dialog.html',
                    controller: 'IntradayMarketSessionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('intraday-market-session-settings', null, { reload: 'intraday-market-session-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
          .state('intraday-market-settings-auditLog', {
            parent: 'audit-log',
            url: '/intradayMarketSettingsAuditLog',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intraday-market-session-settings/intraday-market-session-settings-dialog.html',
                    controller: 'IntradayMarketSessionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('intraday-market-session-settings', null, { reload: 'intraday-market-session-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('intraday-market-settings-historyReport', {
            parent: 'history-report',
            url: '/intradayMarketSettingsHistory',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/intraday-market-session-settings/intraday-market-session-settings-dialog.html',
                    controller: 'IntradayMarketSessionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('intraday-market-session-settings', null, { reload: 'intraday-market-session-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         
         };
    })();
