(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'orderStatusReport'
        })
        
        .controller('OrderStatusReportController', OrderStatusReportController);

    OrderStatusReportController.$inject = ['$scope','$rootScope', '$state', 'OrderStatusDetails', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout','IntradayMarket','DateUtils', 'DefaultMarket','StaticLookUp','ExportFile'];

    function OrderStatusReportController($scope,$rootScope,$state, OrderStatusDetails , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,IntradayMarket,DateUtils, DefaultMarket,StaticLookUp,ExportFile) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDERSTATUSREPORT;
		$rootScope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
		vm.reportFileTimestampFormat = '_DD_MMM_YYYY_HH_mm';
		vm.clicked=false; 
        DefaultMarket.marketInstruments(function(result){
            vm.instrumentTypes = result;
        });
        StaticLookUp.getOrderStatus(function(result){
        	vm.orderStatusList = result;
        });
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        
        vm.todayDate = new Date();
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        
        if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
        	 vm.loggedInCustomer = vm.globalValues.getCustomerId();
        }
        if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
        	vm.bank = vm.globalValues.getBank();
        }
        if(vm.entityOwnerType=='Branch'){
        	vm.branch = vm.globalValues.getBranch();
        }
        //testing
       
        vm.getOrderStatusDetails = getOrderStatusDetails;
        vm.ExpandOrCollapseOrderStatusReport=true;
        
        workflowSharedService.viewFrom = "orderStatusReport";
		vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    vm.exportDataToPdfFile = exportDataToPdfFile;
		vm.tableName = 'orderStatusReport';
		vm.fileName = "Order_Status_Report";
        
        logScreenAccess();
       // loadCurrentBusinessDate();

       function getOrderStatusDetails() {
    	   
    	   vm.bankId = 0;
    	   vm.branchId = 0;
    	   vm.customerId= 0;
    	   vm.instrumentId = 0;
    	   vm.orderStatusId = 0;
    	   
    	   
    	   	
	    	   if (vm.entityOwnerType == 'Bank' || vm.entityOwnerType == 'Branch'){
	    		   vm.bankId = vm.globalValues.getOrganisation().id;
	    	   }else if(vm.bank!=null && vm.bank!=undefined){
	    		   if(vm.entityOwnerType == 'Clearcorp' || vm.entityOwnerType == 'MultiUserCustomer' || vm.entityOwnerType == 'SingleUserCustomer'){
		    			  vm.bankId = vm.bank.id;
		    		  }
	    	   }
	    	   
	    	   if(vm.entityOwnerType == 'Branch'){
	    		   vm.branchId = vm.globalValues.getBranch().id;
	    	   }else  if(vm.branch!=null && vm.branch!=undefined){
	    		   if(vm.entityOwnerType=='Clearcorp' || vm.entityOwnerType=='Bank'){
	    			   vm.branchId = vm.branch.id;
	    		   }
	    	   }
	    	   
	    	   if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
	    		   vm.customerId = vm.globalValues.getCustomerId();
	    	   } else  if(vm.customer!=null && vm.customer!=undefined){
	    		   if(vm.entityOwnerType!='MultiUserCustomer' && vm.entityOwnerType!='SingleUserCustomer'){
	    			   vm.customerId = vm.customer.id;
	    		   }
	    	   }
    	   
    	   
	    	   if(vm.instrument!=null && vm.instrument!=undefined){
	    		   vm.instrumentId = vm.instrument.id;
	    	   }
	    	   
	    	   if(vm.orderStatus!=null && vm.orderStatus!=undefined){
	    		   vm.orderStatusId= vm.orderStatus.id;
	    	   }
	    	   
	    	   
	    	   
    	   OrderStatusDetails.getOrderStatusDetails({"bankId": vm.bankId, "branchId":vm.branchId , "customerId" : vm.customerId ,
    		   "instrumentId" : vm.instrumentId , "orderStatusId" : vm.orderStatusId},function(result) {
    	   
               
                
                angular.forEach(result, function(value){
                	value.revisionTimeStamp=moment(value.revisionTimeStamp,'YYYYMMDDHHmmss.SSSSSSSSS').format();
                	
                	if(value.expiryTime==0){
	              		var time = moment().toDate();
		              	time.setHours(0);
		              	time.setMinutes(0);
		              	time.setSeconds(0);
		              	time.setMilliseconds(0);
		              	value.expiryTime=time;
	              	}else{
	              		value.expiryTime=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
	              	}
                	
                	if(value.buySellFlag.toUpperCase()=='BUYY'){
                		value.buySellFlag='Buy';
                	}else if(value.buySellFlag.toUpperCase()=='SELL'){
                		value.buySellFlag='Sell';
                	}
                	
                	
                	
                	if(value.timeCondition.toUpperCase()!='GTTM'){
                		value.expiryTime=null;
                	}
                	
                	if(value.timeCondition.toUpperCase()=='DAYY'){
                		value.timeCondition='DAY';
                	}else if(value.timeCondition.toUpperCase()=='GTTM'){
                		value.timeCondition='GTT';
                	}else if(value.timeCondition.toUpperCase()=='IOCC'){
                		value.timeCondition='IOC';
                	}
                	
                	if(value.priceCondition.shortName!='STLS' && value.priceCondition.shortName!='TKPR'){
                		value.stopExchangeRate=null;
                	}
                	
                	if(value.lastTradeSequenceNumber==0){
                		value.lastTradeSequenceNumber=0;
                	}
            	});
                
               
                vm.orderStatusDetails = result;
				vm.clicked=false; 
             });        	
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function loadCurrentBusinessDate(){
    	   IntradayMarket.getCurrentBusinessDate(getBusinessDateSuccess, getBusinessDateFailed);
       }
       
       function getBusinessDateSuccess(result){
    	   vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(result.businessDate);
    	   
    	   vm.convertedCurrentBusinessDate=DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
    	   
       }
       
       function getBusinessDateFailed(){
    	   
       }

	   function exportDataToExcelFile(totalRecordCount,noOfRecordsPerPage,pageNo){
		//$scope.noOfRecordsPerPage=totalRecordCount;
		ExportFile.exportFile('excel',vm.tableName, totalRecordCount,noOfRecordsPerPage,undefined,pageNo,vm.fileName,undefined,undefined,undefined);
		
	}
   
	function exportDataToCsvFile(totalRecordCount,noOfRecordsPerPage,pageNo){
		//$scope.noOfRecordsPerPage=totalRecordCount;
		ExportFile.exportFile('csv',vm.tableName, totalRecordCount,noOfRecordsPerPage,undefined,pageNo,vm.fileName,undefined,undefined,undefined);
			
		  
	}
	function exportDataToPdfFile(){
			if( vm.orderStatusDetails !=null &&  vm.orderStatusDetails!=undefined && vm.orderStatusDetails.length>0){
			   
				vm.mimeType = 'application/pdf';
				vm.extension = ".pdf";
				OrderStatusDetails.exportOrderStatusPdfFile({
					"bankId": vm.bankId, "branchId": vm.branchId, "customerId": vm.customerId,
					"instrumentId": vm.instrumentId, "orderStatusId": vm.orderStatusId
				}, processResponse);

			}
	}
	function processResponse(result){        	  
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			vm.baseString =   vm.fileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 
			
			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
	  }
	   
	   vm.getSwapPoints = function getSwapPoints(spotExchangeRate, tradeExchangeRate){
		var swapPts =0;
		   if(spotExchangeRate!=0){
			var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
			swapPts = (Math.round(parseFloat(swapPts)*100)/100);
			return swapPts;
		   }else{
			return swapPts;
		   }
		 
	   }
      
    }
})();
