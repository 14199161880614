(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'changeConfig'
	})

	.controller('ChangeConfigController', ChangeConfigController);

	ChangeConfigController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'ChangeConfig', 'GlobalValues',
			'EntityPermissions', 'ScreenLog', '$stateParams',
			'$uibModalInstance', '$rootScope', 'MessageBox', 'Workflow','entity','DateUtils', '$filter','AlertService'];

	function ChangeConfigController($scope, $state, workflowSharedService,
			ChangeConfig, GlobalValues, EntityPermissions, ScreenLog,
			$stateParams, $uibModalInstance, $rootScope, MessageBox, Workflow,entity,DateUtils, $filter,AlertService) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.saveConfig = saveConfig;
		vm.configSettings = {};
		vm.isShowDays = false;
		vm.isShowHours= false;
		vm.isShowSeconds= false;
		vm.isShowMins= false;
		vm.isShowUSD= false;
		vm.isShowPaise= false;
		vm.isShowPercentage=false;
		vm.controlMin=null;
		vm.controlMax=$filter('number')(999999999, 0);
		vm.maximumOrderValueHardCheck=vm.globalValues.getConfigSettings().maximumOrderValueHardCheck;
		vm.maximumOrderValueSoftCheck=vm.globalValues.getConfigSettings().maximumOrderValueSoftCheck;
		vm.minimumOrderValue=vm.globalValues.getConfigSettings().minimumOrderValue;
		vm.errorText = "";
		vm.numericValueError = false;
		
		$("#date_input").on("change", function () {
		     $(this).css("color", "rgba(0,0,0,0)").siblings(".datepicker_label").css({ "text-align":"center", position: "absolute",left: "10px", top:"14px",width:$(this).width()}).text($(this).val().length == 0 ? "" : ($.datepicker.formatDate($(this).attr("dateformat"), new Date($(this).val()))));
		        });
		
		vm.approveConfigSettings = approveConfigSettings;
		vm.rejectConfigSettings = rejectConfigSettings;
		vm.discardConfigSettings = discardConfigSettings;
		vm.resubmitConfigSettings = resubmitConfigSettings;
		vm.clear = clear;
		$scope.showButton = false;
		$scope.isShowSaveButton = false;
		$scope.showDiscardButton = false;
		$scope.isShowResubmit = false;
		vm.isPasswordPolicySelected = true;

		vm.clicked = false;
		vm.noOfDecimals=0;
		vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
		vm.decimalForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
		vm.decimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();


		if (workflowSharedService.viewFrom == 'applicationWideSettingstodolist_checker') {
			vm.ViewDisable = true;

			vm.configSettings = angular.fromJson($rootScope.payload);
			console.log(vm.configSettings);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			
			vm.controlType = vm.configSettings.controlType;
			vm.screenName = 'useradmin';
			vm.isDisabled = true
			$scope.showButton = true;
			$scope.showDiscardButton = false;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;

		} else if (workflowSharedService.viewFrom == 'applicationWideSettingstodolist_maker') {
			vm.ViewDisable = true;
			vm.configSettings = angular.fromJson($rootScope.payload);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			vm.controlType = vm.configSettings.controlType;
			vm.screenName = 'useradmin';
			vm.isDisabled = true
			$scope.showButton = false;
			$scope.showDiscardButton = true;
			$scope.isShowSaveButton = false;
			$scope.isShowResubmit = false;
		}

		else if (workflowSharedService.viewFrom == 'applicationWideSettings_resubmit') {
			vm.configSettings = angular.fromJson($rootScope.payload);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			
			vm.controlType = vm.configSettings.controlType;
			vm.isDisabled = false;
			vm.screenName = 'useradmin';
			$scope.isShowResubmit = true;
			$scope.isShowSaveButton = false;

		} else if (workflowSharedService.viewFrom == 'dealingAdminApplicationWideSettingstodolist_checker') {
			vm.ViewDisable = true;

			vm.configSettings = angular.fromJson($rootScope.payload);
			console.log(vm.configSettings);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			vm.controlType = vm.configSettings.controlType;
			vm.isDisabled = true
			$scope.showButton = true;
			$scope.showDiscardButton = false;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;

		} else if (workflowSharedService.viewFrom == 'dealingAdminApplicationWideSettingstodolist_maker') {
			vm.ViewDisable = true;
			vm.configSettings = angular.fromJson($rootScope.payload);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			vm.controlType = vm.configSettings.controlType;
			vm.isDisabled = true
			$scope.showButton = false;
			$scope.showDiscardButton = true;
			$scope.isShowSaveButton = false;
			$scope.isShowResubmit = false;
		}

		else if (workflowSharedService.viewFrom == 'dealingAdminApplicationWideSettingstodolist_resubmit') {
			vm.configSettings = angular.fromJson($rootScope.payload);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(angular.copy(vm.configSettings.value));
			}else{
				vm.configValue = angular.copy(vm.configSettings.value);
			}
			vm.controlType = angular.copy(vm.configSettings.controlType);
			vm.isDisabled = false;
			$scope.isShowResubmit = true;
			$scope.isShowSaveButton = false;
		} 
		else if (workflowSharedService.viewFrom == 'userAdminApplicationWideSettings_auditlog') {
			
			vm.configSettings = angular.fromJson($rootScope.payload);;
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			vm.controlType = vm.configSettings.controlType;
			vm.isDisabled = true;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;

		} 
		else if (workflowSharedService.viewFrom == 'dealingAdminApplicationWideSettings_auditlog') {
			
			vm.configSettings = angular.fromJson($rootScope.payload);
			console.log(vm.configSettings);
			vm.headerLabel = vm.configSettings.configurationCategory.name;
			vm.labelName = vm.configSettings.configDescription;
			vm.configName = vm.configSettings.configName;
			if(vm.configSettings.controlType=='amcdate'){
				vm.configValue = DateUtils.convertLocalDateFromServer(vm.configSettings.value);
			}else{
				vm.configValue = vm.configSettings.value;
			}
			vm.controlType = vm.configSettings.controlType;
			vm.isDisabled = true;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;
		} 
		else {
			vm.configSettings.isDefault = false;
			vm.configSettings.configName =  $stateParams.configName;
			vm.configSettings.value = $stateParams.configValue;
			vm.configSettings.controlType = $stateParams.controlType;
			vm.configSettings.configDescription = $stateParams.labelName;
			vm.headerLabel = $stateParams.headerLabel;
			vm.labelName = $stateParams.labelName;
			vm.configName = $stateParams.configName;
			if($stateParams.controlType=='amcdate'){
			
				
				vm.configValue = moment($stateParams.configValue,'DD/MM').toDate();
			}else{
				vm.configValue = $stateParams.configValue;
			}
			
			vm.controlType = $stateParams.controlType;
			vm.screenName = $stateParams.screenName;
			$scope.isShowSaveButton = true;
			console.log(vm.configSettings.value);
		}
		
		if(vm.configName == 'MaximumPasswordAgeInDays' || vm.configName == 'AutoWithdrawalOfCustomerRegistrationApplication' 
			|| vm.configName == 'UnUsedTimeFrame' || vm.configName == 'DefaultLimitPeriodForSpotWindow' 
			|| vm.configName == 'DefaultLimitPeriodForForwardAndAllWindow' || vm.configName == 'NoOfDaysToDisableTheAccount' 
			|| vm.configName == 'NoOfDaysBeforePasswordWarning' || vm.configName == 'NoOfDaysEmailTriggeredForPendingApprovalRecords'){
			vm.isShowDays = true;
		}else if(vm.configName == 'MinimumPasswordAgeInHours') {
			vm.isShowHours = true;
		}else if (vm.configName == 'LifeSpanOfTheOTP' || vm.configName == 'ThrottlingParameters'){
			vm.isShowSeconds = true;
		}else if (vm.configName == 'NearestRoundingOffValueForUSD') {
			vm.noOfDecimals= vm.decimalForPaise;
			vm.isShowPaise=false;
			vm.isShowUSD = true;
		}else if (vm.configName == 'MinimumOrderValue' || vm.configName == 'MaximumOrderValueHardCheck' || vm.configName == 'MaximumOrderValueSoftCheck') {
			vm.noOfDecimals= vm.decimalForPaise;
			vm.isShowPaise=false;
			vm.isShowUSD = true;
			vm.controlMin = 0;
			vm.controlMax = $filter('number')(999999999.99, vm.decimalsForAmount);
		}else if (vm.configName == 'NearestRoundingOffValueForINR') {
			vm.controlMax = $filter('number')(99, 0);
			vm.isShowPaise = true;
		}else if (vm.configName == 'AllowedOrderPriceDeviationFromMarketPriceInPaise') {
			vm.controlMax = $filter('number')(999, 0);
			vm.isShowPaise = true;
		}else if(vm.configName == 'MarketMovementInterval' || vm.configName == 'SessionTimeoutInterval' || vm.configName == 'MinimumIdleTimeToRestrictOrderActivity'
			  || vm.configName == 'PreCASHSessionCloseIntimation') {
			vm.isShowMins = true;
        }else if(vm.configName == 'TimePeriodToBlockUserLoginInMinutes'){
        	vm.isShowMins = true;
        	vm.controlMin = 1;
        	vm.controlMax = 100;
        }else if(vm.configName == 'SessionTimeoutInterval'){
        	vm.isShowMins = true;
        	vm.controlMin = 1;
        }else if(vm.configName=='ExchangeRateChange' || vm.configName=='AllowedOrderPriceDeviationFromMarketPriceInPercentage'){
			vm.noOfDecimals=vm.decimalsForPercent;
		}else if(vm.configName=='MinimumTransactionChargePerTrade' || vm.configName=='OrderLotSize'){
			vm.noOfDecimals= vm.decimalForPaise;
			vm.isShowPaise=true;
		}else if(vm.configName=='DefaultLimitPeriodForFORWARDAndALLInDays' || vm.configName=='DefaultLimitPeriodForSPOTWindowInDays'){
			vm.controlMin = 1;
        	vm.controlMax = 3000;
		}
		
		vm.onValueChange = onValueChange;
		vm.monthsCountError = false;
		vm.minMaxAlertOnValueChange = minMaxAlertOnValueChange;
		function onValueChange(value){
			if((value != null || value != undefined) && value.toString().length != 0){
				if(value > 20 || value <= 0 ){
					vm.monthsCountError = true;
				}
				else{
					vm.monthsCountError = false;
				}
			}else{
				vm.monthsCountError = false;
			}
			
			
		}
		function minMaxAlertOnValueChange(value,minValue,maxValue,errorText){
			vm.errorText = "";
			if((value != null || value != undefined) && value.toString().length != 0){
				if(value > maxValue || value < minValue ){
					vm.numericValueError = true;
					vm.errorText = $filter('translate')(errorText)
				}
				else{
					vm.numericValueError = false;
				}
			}else{
				vm.numericValueError = false;
			}
			
			
		}
		
		function saveConfig() {
			if(vm.controlType=='amcdate'){
				vm.configSettings.value = DateUtils.convertLocalDateToServer(angular.copy(vm.configValue));
			}
			if(vm.controlType == 'text' || vm.controlType == 'yesorno' || vm.controlType == 'email' ){
				vm.configSettings.value = angular.copy(vm.configValue);
			}
			 
			if(vm.configName == 'MinimumOrderValue')
				{
					if(parseFloat(vm.configSettings.value) >= parseFloat(vm.maximumOrderValueHardCheck))
						{
						AlertService.error("Minimum order value should be less than maximum order value hard check.");
						vm.clicked=false;
						return false;
						}
					if(parseFloat(vm.configSettings.value)<=parseFloat("0"))
						{
						AlertService.error("Value should be greater than zero.");
						vm.clicked=false;
						return false;
						}
				}
			 	
			else if(vm.configName == 'MaximumOrderValueHardCheck')
				{
					if(parseFloat(vm.configSettings.value) <= parseFloat(vm.minimumOrderValue))
						{
						AlertService.error("Maximum order value hard check should be greater than minimum order value.");
						vm.clicked=false;
						return false;
						}
					if(parseFloat(vm.configSettings.value) < parseFloat(vm.maximumOrderValueSoftCheck))
						{
						AlertService.error("Maximum order value hard check should be greater than or equal to maximum order value soft check.");
						vm.clicked=false;
						return false;
						}
				}
			else if(vm.configName == 'MaximumOrderValueSoftCheck')
				{
					if(parseFloat(vm.configSettings.value) > parseFloat(vm.maximumOrderValueHardCheck))
						{
						AlertService.error("Maximum order value soft check should be less than or equal to maximum order value hard check.");
						vm.clicked=false;
						return false;
						}
					if(parseFloat(vm.configSettings.value)<=parseFloat("0"))
						{
						AlertService.error("Value should be greater than zero.");
						vm.clicked=false;
						return false;
						}
				}
			
			if(vm.controlType == 'numeric'){
				if(parseFloat(vm.configSettings.value)<=parseFloat("0"))
				{
					if(vm.configName == 'OTPEmailTemplateID'){
						AlertService.error("Invalid OTP email template ID.");
					}else{
						AlertService.error("Value should be greater than zero.");
					}
					vm.clicked=false;
					return false;
				}
			}
		
            
            if(vm.configName == 'OrderLotSize' || vm.configName == 'ExchangeRateChange'
                || vm.configName == 'NearestRoundingOffValueForUSD' || vm.configName == 'AllowedOrderPriceDeviationFromMarketPriceInPercentage'){
                if(parseFloat(vm.configSettings.value)<=parseFloat("0"))
                {
                    AlertService.error("Value should be greater than zero.");
                    vm.clicked=false;
                    return false;
                }
            }

            if(vm.screenName == 'useradmin') {
            	ChangeConfig.updateConfigUserAdmin(vm.configSettings, onSaveSuccess, onSaveError)	
            }else {
            	ChangeConfig.updateConfigDealingAdmin(vm.configSettings, onSaveSuccess, onSaveError)
            }
			
		}

		function onSaveSuccess(result) {
			$scope.$emit('Refresh');
			$uibModalInstance.close();

		}

		function onSaveError() {

			vm.clicked = false;
		}

		function approveConfigSettings() {
			ChangeConfig.approve({
				"taskId" : $rootScope.taskID
			}, onApproveSuccess, onApproveError);
			
		}

		function onApproveSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.close();
		}
		function onApproveError(error) {
			vm.clicked=false;
		}

		function rejectConfigSettings() {
			Workflow.rejectTask($rootScope.taskID);
		}

		function discardConfigSettings() {
			ChangeConfig.discard({
				"taskId" : $rootScope.taskID
			}, onDiscardSuccess, onDiscardError);
			
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.close();
		}
		function onDiscardError(error) {

		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.close(result);
			$uibModalInstance.close();
		}

		function onResubmitError(error) {
			vm.clicked=false;
		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function resubmitConfigSettings() {
			if(vm.controlType=='amcdate'){
				vm.configSettings.value = DateUtils.convertLocalDateToServer(angular.copy(vm.configValue));
			}
			if(vm.controlType == 'text' || vm.controlType == 'yesorno' || vm.controlType == 'email' ){
				vm.configSettings.value = angular.copy(vm.configValue);
			}
			
			if(vm.screenName == 'useradmin') {
				ChangeConfig.resubmitForEdit({
					"taskId" : $rootScope.taskID
				}, vm.configSettings, onResubmitSuccess, onResubmitError);
			}else {
				ChangeConfig.dealerAdminResubmitForEdit({
					"taskId" : $rootScope.taskID
				}, vm.configSettings, onResubmitSuccess, onResubmitError);
			}
		

			
		}
		
		


	}
})();
