(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('swap-quotes', {
            parent: 'entity',
            url: '/swap-quotes',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.swapQuotes.manageSwapQuotes" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-date.html',
                    controller: 'SwapQuotesTenorDatesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                   
                    return SwapQuotesForTenorDates.getSystemSettings().$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
        .state('swap-quotes.edit', {
            parent: 'swap-quotes',
            url: '/edit/{businessDate}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {                 	
                            	 entity: ['SwapQuotesForTenorDates', 'GlobalValues', function(SwapQuotesForTenorDates, GlobalValues) {
                                     return SwapQuotesForTenorDates.get({orgId:GlobalValues.getBank().id,businessDate : $stateParams.businessDate}).$promise;
                                 }],
                                 systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                                     
                                     return SwapQuotesForTenorDates.getSystemSettings().$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
        .state('swap-quotes-change', {
            parent: 'home',
            url: '/swap-quotes-change/{businessDate}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                    	 entity: ['SwapQuotesForTenorDates', 'GlobalValues', function(SwapQuotesForTenorDates, GlobalValues) {
                             return SwapQuotesForTenorDates.get({orgId:GlobalValues.getBank().id,businessDate : $stateParams.businessDate}).$promise;
                         }],
                         systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                             
                             return SwapQuotesForTenorDates.getSystemSettings().$promise;
                         }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
       
        .state('swap-quotes-change-exp', {
            parent: 'swap-quotes-home-page',
            url: '/swap-quote-change/{businessDate}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                         entity: ['SwapQuotesForTenorDates', 'GlobalValues', function(SwapQuotesForTenorDates, GlobalValues) {
                             return SwapQuotesForTenorDates.get({orgId:GlobalValues.getBank().id,businessDate : $stateParams.businessDate}).$promise;
                         }],
                         systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                             
                             return SwapQuotesForTenorDates.getSystemSettings().$promise;
                         }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
       
         .state('swap-quotes-todolist', {
            parent: 'dashboard',
            url: '/swapQuotesView',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                            
                            return SwapQuotesForTenorDates.getSystemSettings().$promise;
                        }]
                        
                    }
                }).result.then(function() {
                	$state.go('swap-quotes', null, { reload: 'swap-quotes' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
            
        })
        
         .state('swap-quotes-todolist.resubmit', {
            parent: 'dashboard',
            url: '/swapQuotesResubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        },
                        systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                            
                            return SwapQuotesForTenorDates.getSystemSettings().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('swap-quotes', null, { reload: 'swap-quotes' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('swapQuotes-auditLog', {
            parent: 'audit-log',
            url: '/swapQuotesauditlog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        },
                        systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                            
                            return SwapQuotesForTenorDates.getSystemSettings().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('swap-quotes', null, { reload: 'swap-quotes' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('swapQuotes-historyReport', {
            parent: 'history-report',
            url: '/swapQuoteshistoryReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes-for-tenor-date/swap-quotes-for-tenor-dates-dialog.html',
                    controller: 'SwapQuotesForTenorDatesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        },
                        systemSettings:['SwapQuotesForTenorDates',  function (SwapQuotesForTenorDates) {
                            
                            return SwapQuotesForTenorDates.getSystemSettings().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('swap-quotes', null, { reload: 'swap-quotes' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('old-swapQuotes-auditLog', {
            parent: 'audit-log',
            url: '/oldswapQuotesauditlog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes/swap-quotes-dialog.html',
                    controller: 'SwapQuotesDialogController1',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('swap-quotes', null, { reload: 'swap-quotes' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('old-swapQuotes-historyReport', {
            parent: 'history-report',
            url: '/oldswapQuoteshistroyreport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/swap-quotes/swap-quotes-dialog.html',
                    controller: 'SwapQuotesDialogController1',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('swap-quotes', null, { reload: 'swap-quotes' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('swapQuotes');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
         };
    })();
