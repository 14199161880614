(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TrackStatusController', TrackStatusController);

    TrackStatusController.$inject = ['$stateParams', '$timeout', 'TrackStatus', '$state', 'mode', 'CustomerRegistration', '$scope', '$rootScope'];

    function TrackStatusController ($stateParams, $timeout, TrackStatus, $state, mode, CustomerRegistration, $scope, $rootScope) {
        var vm = this;
        vm.keyMissing = false;
        vm.activationKey = $stateParams.key;
        vm.requestId = $stateParams.requestId;
        vm.emailVerified = false;
        vm.approved = false;
        vm.tradingActivated = false;
        vm.withdrawn = false;
        vm.rejected = false;

        vm.openCustomerDetails = openCustomerDetails;
        vm.generateOTP = generateOTP;
        vm.clicked=false;

        //updateStatus();
        viewStatus();
        function updateStatus(){
        	if(vm.activationKey!=null && vm.activationKey!="" && mode=='trackstatus'){
            	TrackStatus.updateStatus({key: vm.activationKey}, onUpdateSuccess, onUpdateFailure);
        	}else{
        		vm.keyMissing = true;
        	}
        } 
        
        function viewStatus(){
        	if(vm.activationKey!=null && vm.activationKey!="" && mode=='customeronboardingstatus'){
            	//TrackStatus.viewStatus({key: vm.activationKey}, onUpdateSuccess, onUpdateFailure);
        		TrackStatus.viewStatus({key : $stateParams.key}, onUpdateSuccess, onUpdateFailure);
        	}else if(vm.activationKey!=null && vm.activationKey!="" && mode=='registrationstatus'){
            	//TrackStatus.viewStatus({key: vm.activationKey}, onUpdateSuccess, onUpdateFailure);
        		TrackStatus.viewStatus({key : $stateParams.key}, onUpdateSuccess, onUpdateFailure);
        	}else{
        		vm.keyMissing = true;
        	}
        }
        
        function onUpdateSuccess(result){
        	vm.customerRegistrationRequest = result;
        	vm.requestId = vm.customerRegistrationRequest.id; 
        	vm.requestRefNo = vm.customerRegistrationRequest.requestReferenceNumber; 
        	setStatus();
        }
        
        function setStatus(){
        	if(vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='PENDINGVERIFICATION'){
        		vm.emailVerified = false;
                vm.approved = false;
                vm.tradingActivated = false;
                vm.withdrawn = false;
                vm.rejected = false;
        	}else if(vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='PENDINGAPPROVAL'){
        		vm.emailVerified = true;
        	}else if(vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='PENDINGTRADINGACTIVATION'){
        		vm.emailVerified = true;
        		vm.approved = true;
        	}else if(vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='APPROVED'){
        		vm.emailVerified = true;
        		vm.approved = true;
        		vm.tradingActivated = true;
        	}else if(vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='WITHDRAWN' || vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='WITHDRAWNBYSYSTEM'){
        		vm.emailVerified = true;
        		vm.withdrawn = true;
        	}else if(vm.customerRegistrationRequest.customerBankRelationshipStatus.shortName=='REJECTED'){
        		vm.emailVerified = true;
        		vm.rejected = true;
        	}
        }
        
        function onUpdateFailure(){
        }
        
        function generateOTP(){
            CustomerRegistration.sendOTPForRequestRefNo({requestReferenceNo: vm.requestRefNo}, onValidateSuccess, onValidateFailure);
        }

        function onValidateSuccess(result){
            if(mode=='registrationstatus'){
                $state.go('otp-confirmation-fetch-reg-request', {refNo: vm.requestRefNo, theme: 'light'});
                vm.clicked=false;
            }else if(mode=='customeronboardingstatus'){
                $state.go('otp-confirmation-fetch-request', {refNo: vm.requestRefNo, theme: 'light'});
                vm.clicked=false;
            } 
        }

        function onValidateFailure(){
            vm.clicked=false;
        }

        var subscribeFetchRegisteredRequest = $rootScope.$on('fetchRegisteredRequest', function(event, result) {
            openCustomerDetails();
        });

        $scope.$on('$destroy', function() {
            $timeout(function(){
                subscribeFetchRegisteredRequest();                
            }, 500)
        });

        function openCustomerDetails(){
        	if(mode=='trackstatus'){
            	$state.go('customer-track-status-view', {id: vm.activationKey, theme: 'light'});
        	}else if(mode=='registrationstatus'){
            	$state.go('customer-registration-status-view', {id: vm.activationKey, theme: 'light'});
        	}else if(mode=='customeronboardingstatus'){
        		$state.go('customer-onboarding-status-view', {id: vm.activationKey, theme: 'light'});
        	}
        }
    }
})();
