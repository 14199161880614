(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ApprovalPolicy', ApprovalPolicy);

    ApprovalPolicy.$inject = ['$resource'];

    function ApprovalPolicy ($resource) {
        var resourceUrl =  'api/orgId/:orgId/approval-policy/:id';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId'}
        	 },
        	 'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getAllApprovalPolicy': { method: 'GET', isArray: true,
                params: {orgId:'@orgId'},
                url: 'api/orgId/:orgId/all-approval-policy'
             },
             'actionsForOrgAndPolicy': { method: 'GET', isArray: true,
                params: {orgId:'@orgId', policyId: '@policyId'},
                url: 'api/orgId/:orgId/actions-for-policy/:policyId/policyId'
             },
        	 'save': {
                 method: 'POST',
                 params: {orgId:'@orgId'},
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                    return angular.toJson(copy);
                 }                
             },
        	 'update': {
                 method: 'PUT',
                 params: {orgId:'@orgId'},
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                     return angular.toJson(copy);
                 }                
             },
             'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/approval-policy',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              }
              
        });
    }
})();
