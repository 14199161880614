(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ScheduleOfFeesController',ScheduleOfFeesController);

    ScheduleOfFeesController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues','ScreenLog', 'entityConstants','$filter', '$rootScope','$scope','$timeout'];

    function ScheduleOfFeesController($uibModalInstance,$stateParams, GlobalValues, ScreenLog, entityConstants, $filter, $rootScope, $scope,$timeout) {
    	var vm = this;
        vm.clear = clear;
        vm.theme = $stateParams.theme;
        vm.printPage = printPage;
        vm.displayPrint = true;
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
     
        function printPage() {
        	vm.displayPrint =false;
         $timeout( function(){  var printContents = document.getElementById("scheduleOfFees").innerHTML;
         var popupWin = window.open('', '_blank','fullscreen=yes');
         popupWin.opener=null;
         if (popupWin) {
           var relAttribute = document.createAttribute('rel');
           relAttribute.value = 'noopener noreferrer';
           popupWin.document.documentElement.setAttributeNode(relAttribute);
           // Write content to the new window's document
           popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
           popupWin.document.close();
        } else {
           console.error('Failed to open the window.');
        }
      	  }, 500);
         $timeout( function(){vm.displayPrint =true; }, 1000);
      
      	} 
        
        
    }
})();