(function() {
    'use strict';

    angular
        .module('gatewayApp')
        
        .controller('ForgotPIDController', ForgotPIDController);

    ForgotPIDController.$inject = ['$scope', '$state',  '$uibModalInstance'  ,'workflowSharedService','User',  'GlobalValues','EntityPermissions','$filter', 'entity','ScreenLog', '$rootScope'];

    function ForgotPIDController($scope, $state,$uibModalInstance,  workflowSharedService, User,  GlobalValues, EntityPermissions,$filter, entity,ScreenLog, $rootScope) {    
        var vm = this;
        
        
        vm.todayDate=new Date();
        vm.getPID=getPID;
        vm.getPIDFromList=getPIDFromList;
        vm.NotFound="No data found";
        vm.show=false;
        $scope.clicked=false;
        vm.clear=clear;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        
        function getPID(){
        	vm.PID=null;
        	var dob=$filter('date')(vm.dob, "yyyy-MM-dd");
        	
        	/*if(vm.aadharNumber==undefined || vm.aadharNumber==null){
        		User.getPID({"firstName":vm.firstName,"dob":dob,"aadhar":'0'},onGetPIDSuccess,onGetPIDError);
        	}
        	else{
        		User.getPID({"firstName":vm.firstName,"dob":dob,"aadhar":vm.aadharNumber},onGetPIDSuccess,onGetPIDError);
        	}*/
        	User.getPID({"firstName":vm.firstName,"lastName":vm.lastName,"dob":dob,"pincode":vm.pincode},onGetPIDSuccess,onGetPIDError);
        	
        }
        
        $scope.selectedRow = null;  // initialize our variable to null
        $scope.setClickedRow = function(index){  //function that sets the value of selectedRow to current index
           $scope.selectedRow = index;
        }
        
        function getPIDFromList(index){
        	vm.PID=vm.pidList[index].personDetails.personId;
    		$rootScope.PID=vm.PID;
    		$scope.$emit('fetchPID'); 
    		$uibModalInstance.dismiss('cancel');
        }
        
        function onGetPIDSuccess(result){
        	if(result.length==1){
        		vm.PID=result[0].personDetails.personId;
        		$rootScope.PID=vm.PID;
        		$scope.$emit('fetchPID'); 
        		$uibModalInstance.dismiss('cancel');
        	}else if(result.length>1){
        		vm.pidList = result;
        	}
        
        }
        function onGetPIDError(){
        	
        }
        
        function clear() {
			$uibModalInstance.dismiss('cancel');
		}
        
        
     }
})();