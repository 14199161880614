(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('customer-tac-upload-by-bank-view', {
			parent: 'entity',
			params:{screen:'CustomerTermsAndConditionsUpload'},
			url: '/customer-tac-upload-by-bank-view',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			ncyBreadcrumb: {
				label: '{{ "global.menu.customerTacUploadByBank.main" | translate }}',
				parent: 'home'
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-by-bank-view.html',
					controller: 'CustomerTACByBankViewController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('customerTacUploadByBank');                    
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}],
				entity: ['CustomerTACUploadByBank','GlobalValues', function(CustomerTACUploadByBank,GlobalValues) {
					return CustomerTACUploadByBank.getActiveCustomerTACUploadByBank({organisationId:GlobalValues.getOrgId()}).$promise;

				}]
			}
		})
		
		

		.state('customer-tac-upload-by-bank', {
			parent: 'customer-tac-upload-by-bank-view',
			url: '/customer-tac-upload-by-bank',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-by-bank.html',
					controller: 'CustomerTACUploadByBankController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {

						entity: function () {                        	
							return {                            		
							};
						},
						globalValue: ['StaticLookUp', function(StaticLookUp) {
							return StaticLookUp.getConfigSettings().$promise;
						}],
						systemSettings: ['IntradayMarket', function(IntradayMarket) {
							return IntradayMarket.getCurrentBusinessDate().$promise;
						}],
						configurationSetting :['GlobalValues', function(GlobalValues) {
							return GlobalValues.getConfigSettings();
						}]

					}
				}).result.then(function() {
					$state.go('^', {}, { reload: true });
				}, function() {
					$state.go('^');
				});
			}]

		})

		.state('customer-tac-upload-by-bank-todolist', {
			parent: 'dashboard',
			url: '/customer-tac-upload-by-bank-todo',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-by-bank-approval.html',
					controller: 'CustomerTACUploadByBankApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {
						entity: function () {                        	
							return {                            		
							};
						},
						systemSettings:function() {
							return {                            		
							};
						},
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('customer-tac-upload-by-bank-view', null, { reload: 'customer-tac-upload-by-bank-view' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('customerTacUploadByBank');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}

		})
		.state('customer-tac-upload-by-bank-todolist-resubmit', {
			parent: 'dashboard',
			url: '/customer-tac-upload-by-bank-todo-resubmit',
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-by-bank-approval.html',
					controller: 'CustomerTACUploadByBankApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {
						entity: function () {                       	

						},
						systemSettings: ['IntradayMarket', function(IntradayMarket) {
							return IntradayMarket.getCurrentBusinessDate().$promise;
						}],
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('customer-tac-upload-by-bank-view', null, { reload: 'customer-tac-upload-by-bank-view' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('customerTacUploadByBank');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})
		.state('customer-tac-upload-by-bank-auditLog', {
			parent: 'audit-log',
			url: '/customer-tac-upload-by-bank-auditLog',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-by-bank-approval.html',
					controller: 'CustomerTACUploadByBankApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {
						entity: function () {                        	
							return {                            		
							};
						},
						systemSettings: function() {
							return null;
						},
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('customer-tac-upload-by-bank-view', null, { reload: 'customer-tac-upload-by-bank-view' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('customerTacUploadByBank');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}

		})


		.state('customer-tac-upload-by-bank-historyReport', {
			parent: 'history-report',
			url: '/customer-tac-upload-by-bank-history',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-by-bank-approval.html',
					controller: 'CustomerTACUploadByBankApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {
						entity: function () {                        	
							return {                            		
							};
						},
						systemSettings: function() {
							return null;
						},
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('customer-tac-upload-by-bank-view', null, { reload: 'customer-tac-upload-by-bank-view' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('customerTacUploadByBank');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}

		}).state('customer-tac-upload-apporval-disclaimer', {
            parent: 'dashboard',
            url: '/customer-tc-upload-approval-disclaimer',
            params : {entityId : null, customerId : null, tradingBank : null},
            data: {
            	authorities: ['ROLE_USER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-tac-upload/customer-tac-upload-approval-disclaimer-dialog.html',
                    controller: 'CustomerTacUploadApprovalDisclaimerController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                    	
                       
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customerTacUploadByBank');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('task');
                    return $translate.refresh();
                }]
            }
        })

	};
})();
