(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('CustomerInfoChangeStatusController', CustomerInfoChangeStatusController);

    CustomerInfoChangeStatusController.$inject = ['$scope', '$timeout', '$state', '$stateParams', 'workflowSharedService', 'CustomerInfoChange', '$rootScope', 'GlobalValues', 'Workflow', '$filter', 'ScreenLog','MessageBox','entityConstants'];

    function CustomerInfoChangeStatusController($scope, $timeout, $state, $stateParams, workflowSharedService, CustomerInfoChange, $rootScope, GlobalValues, Workflow, $filter, ScreenLog, MessageBox,entityConstants) {
        var vm = this;
        vm.screenLog = ScreenLog;
        vm.tasks = [];
        vm.getTasks = [];
        vm.resubmitEntity=resubmitEntity;
        vm.viewEntity = viewEntity;
        vm.discardEntity = discardEntity;
        
        vm.globalValues=GlobalValues;
        
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER;
        
        logScreenAccess();
        loadAll();
       

        function loadAll() {
        	 //vm.rejectedCount = 0;
             //vm.enableFields = false;
            
        	CustomerInfoChange.getCustomers({ "orgId": GlobalValues.getOrgId() }, function(result) {
                vm.fetchedTasks = result;
                vm.tasks = [];
                angular.forEach(vm.fetchedTasks, function(task) {
                	task.changeType = getChangeType(task);
                    vm.tasks.push(task);
                });
               
                vm.discardProcessed = true;
            });
        }

       
        
        function discardEntity(entityList) {
            
        	$scope.rowID = entityList.id;
        	vm.discardProcessed = false;
        	vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.DISCARD);
            MessageBox.openMessageBox("Alert", "Do you want to withdraw your customer info change?", "ProceedCancel", "withdrawcustomerchange");
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'DISCARD'});
        }
        
        $rootScope.$on('gatewayApp.messagebox.proceed.withdrawcustomerchange', function (event, data) {
        	
        	if($state.current.name == 'customer-info-change-status' && $scope.rowID!=undefined && !vm.discardProcessed){
        		
        		vm.discardProcessed = true;
                CustomerInfoChange.discard({ "taskId": $scope.rowID }, onDiscardSuccess, onDiscardError).$promise.then(function (){ } );
               
        	}

        });
		
		$rootScope.$on('gatewayApp.messagebox.cancel.withdrawcustomerchange', function (event, data) {
			if($state.current.name == 'customer-info-change-status'){
				vm.discardProcessed = true;
			}
    	});
        
       

        function onDiscardSuccess(result) {
            $rootScope.removeRow($scope.rowID);
            $scope.rowID = undefined;
            vm.discardProcessed=true;
            $state.go('customer-info-change-status', null, { reload: 'customer-info-change-status' });
        }

        function onDiscardError(error) {
        	vm.discardProcessed = true;
        }
        
        function viewEntity(entity,id,payload,action,changeType, recordId){
            $rootScope.payload = payload;
            $rootScope.taskID = id;
            vm.screenLog.screenLog(entity, recordId==null?0:recordId, entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS);
            workflowSharedService.viewFrom ='customerregistrationtodolist_maker';
            var state = 'customer-info-status-view';
            $state.go(state);
        }
        
        function resubmitEntity(entity,id,payload,changeType ,reference, recordId){
            $rootScope.payload = payload;
            $rootScope.taskID = id;
            var rejectedCount = 0;
            var enableFields = false;
            //vm.screenLog.screenLog(entity, recordId==null?0:recordId, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
            workflowSharedService.viewFrom = 'customerregistrationtodolist_resubmit' ;
            var state = 'customer-info-status-view';
            // Get active and inactive tasks with same payload
            angular.forEach(vm.tasks, function(task){
            	if(task.changeType == 'Rejected' ){
            		rejectedCount =  rejectedCount+1;
            	}
            });
            CustomerInfoChange.getAllTasksForCustomersBasedOnReference({"orgId": GlobalValues.getOrgId() ,"reference" : reference},function(result){
            	vm.allTasks = result;
            	if(vm.allTasks!=null && vm.allTasks!=undefined && vm.allTasks.length == rejectedCount){
                	enableFields = true;
            	}
            	$state.params = {enableFields:enableFields}
            	$state.go(state);
            }, function(error){
            	$state.params = {enableFields:enableFields}
            	$state.go(state);
            });
            
        }
       
        
    	function getChangeType(task){
    		if(task.changeType == 'CREATE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Added';
    		}
    		
    		else if(task.taskHistory[task.taskHistory.length - 1].action == 'REJECTED'){
    			//vm.rejectedCount =  vm.rejectedCount+1;
    			return 'Rejected';
    		}
    		
    	}
    	
        $rootScope.removeRow = function(id) {
            var index = -1;
            var comArr = eval(vm.tasks);
            for (var i = 0; i < comArr.length; i++) {
                if (comArr[i].id === id) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
               // alert("Something gone wrong");
            }
            vm.tasks.splice(index, 1);
        };

        $scope.toggle = true;
        $scope.refresh = function() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };

        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
    }

})();
