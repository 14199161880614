(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('entities-tac-view', {
            parent: 'entity',
            params:{screen:'TermsAndConditionsView'},
            url: '/entities-tac-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.tacViewForEntities.title" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tac-view-for-entities/tac-view-for-entities.html',
                    controller: 'TACViewForEntitiesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       .state('entities-tac-todo-view', {
            parent: 'dashboard',
            url: '/entities-tac-view-todo',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tac-view-for-entities/tac-view-for-entities-todo-dialog.html',
                    controller: 'TACViewForEntitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('entities-tac-view', null, { reload: 'entities-tac-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
            
        })        
         .state('entities-tac-todo-view.resubmit', {
            parent: 'dashboard',
            url: '/entities-tac-view-resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/tac-view-for-entities/tac-view-for-entities-todo-dialog.html',
                    controller: 'TACViewForEntitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('entities-tac-view', null, { reload: 'entities-tac-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('entities-tac-view-auditLog', {
            parent: 'audit-log',
            url: '/entities-tac-view-audit-log-report',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/tac-view-for-entities/tac-view-for-entities-todo-dialog.html',
                    controller: 'TACViewForEntitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('entities-tac-view', null, { reload: 'entities-tac-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('entities-tac-view-historyReport', {
            parent: 'history-report',
            url: '/entities-tac-view-history-report',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/tac-view-for-entities/tac-view-for-entities-todo-dialog.html',
                    controller: 'TACViewForEntitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('entities-tac-view', null, { reload: 'entities-tac-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('tac-agree-confirmation', {
            parent: 'entities-tac-view',
            url: '/entities-tac-view-agree-confirmation/{tacTypeId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/tac-view-for-entities/tac-agree-confirmation-dialog.html',
                    controller: 'TACAgreeConfirmationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('entities-tac-view', null, { reload: 'entities-tac-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('tac-agree-confirmation-todo', {
            parent: 'dashboard',
            url: '/entities-tac-view-agree-approval-confirmation/{tacTypeId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/tac-view-for-entities/tac-agree-confirmation-dialog.html',
                    controller: 'TACAgreeConfirmationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('dashboard', null, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tacViewForEntities');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
          
        
        
        
         };
    })();
