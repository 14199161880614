(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('IntradayMarket', IntradayMarket);

    IntradayMarket.$inject = ['$resource'];

    function IntradayMarket ($resource) {
        var resourceUrl =  'api/intraday-market-session-settings/:id';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId',currentBusinessDate :'@currentBusinessDate'},
        	    url: 'api/currentBusinessDate/:currentBusinessDate/intraday-market-session-settings'
        	 },
         	
         	'getMarketSessionSettings': { method: 'GET', isArray: true,
        		url: 'api/market-session-settings/:currentBusinessDate/currentBusinessDate',
         		params: {orgId:'@orgId',currentBusinessDate :'@currentBusinessDate'}
        	 },
         	
        	 'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },           
            	
        	 'getIntradayMarketSessionSettingsForInstrument': {
                 method: 'GET',
                 url: 'api/market-session-settings/:instrument/instrument',
                 params: {instrument: '@instrument'},
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },           
            	
             'save': {
                 method: 'POST',
                 params: {orgId:'@orgId'},
                 url: 'api/intraday-market-session-settings/orgId/:orgId',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                     return angular.toJson(copy);
                 }                
             },
        	
             'update': {
             	method:'PUT',
             	params: {orgId:'@orgId'},
                url: 'api/update-intraday-market-session-settings/orgId/:orgId',
             	transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 }
             },
             'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/intraday-market-settings',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              },
              'getCurrentBusinessDate': {
                  method: 'GET',  
                  url: 'api/get-current-business-date',
                  transformResponse : function(data) {
  					if (data) {
  						data = angular.fromJson(data);
  					}
  					return data;
  				}
              },
              'getMessagesInBCMQ' : {
				method : 'GET',
        	    url: 'api/messages/getMessagesInBCMQ'
			}
        });
    }
})();
