(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrderHistory', OrderHistory);

    OrderHistory.$inject = ['$resource'];

    function OrderHistory ($resource) {
        var resourceUrl =  'api/order-history/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getOrderHistory':{
           	 
             	isArray:true,
  				method: 'GET',
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId',
  					buySell : '@buySell', orderStatus : '@orderStatus', fromDate : '@fromDate', toDate : '@toDate'},
  				
  				url: 'api/order-history/get-order-history-details'
              }
          
              
        });
    }
})();
