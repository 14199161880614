(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('SessionLog', SessionLog);

    SessionLog.$inject = ['$resource'];

    function SessionLog ($resource) {
        var resourceUrl =  'api/get-session-log/:id';

        return $resource(resourceUrl, {}, {
            
        	 'getSessionLogDetails':{
             	isArray:true,
  				method: 'GET',
  				params: {userIds:'@userIds',from:'@from',to:'@to',queryParams:'@queryParams',branchId:'@branchId',orgId:'@orgId'},
  				url: 'api/session-log-report/get-session-log-details'
              },
              
         	 'getSessionLogDetailsFromArchive':{
              	isArray:true,
   				method: 'GET',
   				params: {userIds:'@userIds',from:'@from',to:'@to',queryParams:'@queryParams',branchId:'@branchId',orgId:'@orgId'},
   				url: 'api/session-log-archive-report/get-session-log-details'
               }
             
        });
    }
})();
