(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("OrderUtil", OrderUtil);

        OrderUtil.$inject = ['$rootScope','DateUtils','$filter','MarketWatch','GlobalValues'];

    function OrderUtil($rootScope,DateUtils,$filter,MarketWatch,GlobalValues) {

    	var service = {
            validateOrderDTOForNull: validateOrderDTOForNull,
            validateSettlementDate:validateSettlementDate,
            validateOptionSettlementDate : validateOptionSettlementDate,
            validateOptionPeriods: validateOptionPeriods,
            validateSession : validateSession,
            validateSessionStatus: validateSessionStatus,
            validateAgreedTAC : validateAgreedTAC,
            validateLimitCheck: validateLimitCheck,
            validateOrderAmount: validateOrderAmount,
            validateLimitExchangeRateHardCheck:validateLimitExchangeRateHardCheck,
            validateSpreadUsed:validateSpreadUsed,
            getEventTypeForShortName : getEventTypeForShortName,
            gettickSizeForGivenInstrument : gettickSizeForGivenInstrument,
            getSettlementDate : getSettlementDate,
            getCloseTimeForInstrumentCode : getCloseTimeForInstrumentCode
    	};
    	return service;

        //validate order DTO
        function validateOrderDTOForNull(orderDTO){
            var isValidOrder = true;
            if(orderDTO.activityIndicator == null || orderDTO.activityIndicator == undefined 
                || orderDTO.bankId == null || orderDTO.bankId == undefined
                || orderDTO.bankMemberNumber == null || orderDTO.bankMemberNumber == undefined
                || orderDTO.branchCode == null || orderDTO.branchCode == undefined
                || orderDTO.branchId == null || orderDTO.branchId == undefined
                || orderDTO.buySellIndicator == null || orderDTO.buySellIndicator == undefined
                || orderDTO.customerCode == null || orderDTO.customerCode == undefined
                || orderDTO.customerId == null || orderDTO.customerId == undefined
                || orderDTO.eventType == null || orderDTO.eventType == undefined
                || orderDTO.eventTypeId == null || orderDTO.eventTypeId == undefined
                || orderDTO.expiryTime == null || orderDTO.expiryTime == undefined
                || orderDTO.instrumentCode == null || orderDTO.instrumentCode == undefined
                || orderDTO.instrumentId == null || orderDTO.instrumentId == undefined
                || orderDTO.limitExchangeRate == null || orderDTO.limitExchangeRate == undefined
                || orderDTO.orderAmount == null || orderDTO.orderAmount == undefined
                || orderDTO.retailUserCode == null || orderDTO.retailUserCode == undefined
                || orderDTO.stopExchangeRate == null || orderDTO.stopExchangeRate == undefined
                || orderDTO.timeLimitIndicator == null || orderDTO.timeLimitIndicator == undefined
                || orderDTO.typeOfOrder == null || orderDTO.typeOfOrder == undefined
                || orderDTO.userEntityOwnerId == null || orderDTO.userEntityOwnerId == undefined
                || orderDTO.userId == null || orderDTO.userId == undefined){
                isValidOrder = false;
                var orderDTOObj = angular.copy(angular.toJson(orderDTO));
                console.log(orderDTOObj);
            }
            //if given instrument is forward and then validate tradeSettlementDate, exposureType, exposureSubType
            else if(orderDTO.instrumentCode == 'Forward' && (orderDTO.tradeSettlementDate == null || orderDTO.tradeSettlementDate == undefined)){
                isValidOrder = false;
                var orderDTOObj = angular.copy(angular.toJson(orderDTO));
                console.log(orderDTOObj);
            }
                
            return isValidOrder;
        }

        function validateOptionSettlementDate(instrumentCode ,isOptionPeriod ,  settlementDate, dateFromOrTo){
            // do validateion only if forwards and option period is true else skip validation
        	if(instrumentCode != 'Forward' ||  (instrumentCode == 'Forward' && isOptionPeriod != true ) ){
         	   return {
                isValid: true,
                errorString : errorString
            }
            }
            var isValid = true;
            var errorString = "";
            var selectedDateText = "Selected Forward Option Period " + dateFromOrTo + " date ";
           // var errorText = "errorInOptionPeriod" + dateFromOrTo;
            var serverSettlemntDate = DateUtils.convertLocalDateToServer(settlementDate);
           
            var holidayList = _.filter($rootScope.holidayListAfterBusinessDate, function(s){return s.indexOf(serverSettlemntDate)!==-1;});
           
            
            if(settlementDate == null || settlementDate == undefined ){
            	isValid = false;
            	errorString = "Forward Option Period " + dateFromOrTo + " date is mandatory..!";
            	//MessageBox.openMessageBox("Alert", "Option Period " + dateFromOrTo + " date is mandatory", "Ok", errorText);
            
            }else if(holidayList!=null && holidayList!=undefined && holidayList.length>0){
             
            	isValid = false;
                errorString = selectedDateText +"is a Holiday. For Holiday List, kindly refer to Holiday View screen in General Reports menu...!";
               //MessageBox.openMessageBox("Alert", , "Ok", errorText);
               
            }else{
            	 //validate sett. date should not be greater than the maximum renor/value defined in the limits
            	if($rootScope.maxForwardTenorDate !=null && $rootScope.maxForwardTenorDate != undefined && moment(settlementDate).isAfter($rootScope.maxForwardTenorDate, 'day')){
                   
            		isValid = false;
                    errorString = selectedDateText +"is greater than the" + " maximum tenor/value date defined by the Relationship Bank. Please refer the Limit Tab for more info on maximum tenor/value date allowed...!";
                    // MessageBox.openMessageBox("Alert", , "Ok", errorText);
                }else if( moment(settlementDate).isBefore( moment($rootScope.spotNextDate), 'day') || moment(settlementDate).isAfter( moment($rootScope.maxTenorDate), 'day')){
                	 //validate sett. date should not be less than spot next date. and should not be greater than last tenor value 
                	isValid = false;
                	errorString = selectedDateText +"should be between Spot Next Date and maximum tenor date...!";
                    //MessageBox.openMessageBox("Alert", , "Ok", errorText);
                            
                }
            }
            return {
                isValid: isValid,
                errorString : errorString
            }
        }
        
        function validateOptionPeriods(instrumentCode, isOptionPeriod, from,to){
        	// do validateion only if forwards and option period is true else skip validation     
        	if(instrumentCode != 'Forward' ||  (instrumentCode == 'Forward' && isOptionPeriod != true ) ){
          	  return {
                isValid: true,
                errorString : errorString
            }
        }
        	//validate sett. date should be fall in holiday
            var isValid = true;
            var errorString = "";
            var errorShortString = "";
            //var errorText = "errorInOptionPeriodFRomAndTo";
                //validate sett. date should not be spot date. since, spot mkt watch is in separate section.
                //and sett. date should not be less than current business date.
                if( moment(from).isSame(moment(to), 'day') ){
                    isValid = false;
                    errorString = "Forward Option Period Start date and End date cannot be same...!";
                    errorShortString = "Start date and End date cannot be same";
                   // MessageBox.openMessageBox("Alert", "Option Period From and To cannot be same...!", "Ok", errorText);
                    
                }else if(moment(from).isAfter(moment(to), 'day')){
                	isValid = false;
                	errorString = "Forward Option Period Start date cannot be greater than End date...!";
                    errorShortString = "Start date cannot be greater than End date"
                	//MessageBox.openMessageBox("Alert", "Option Period From is greater than To...!", "Ok", errorText);                                   
                }
                else if(moment(to).diff(moment(from) ,'day') > $rootScope.maximumOptionPeriodDuration){
                	isValid = false;
                	errorString = "Forward Option Period date range cannot be more than "+ $rootScope.maximumOptionPeriodDuration+" days...!";
                    errorShortString = "Option Period date range cannot be more than "+ $rootScope.maximumOptionPeriodDuration+" days";
                	//MessageBox.openMessageBox("Alert", , "Ok", errorText);                                   
                }
           
            return {
                isValid:isValid,
                errorString: errorString,
                errorShortString : errorShortString
            }
        }
        function validateSettlementDate(instrumentCode ,isOptionPeriod ,  settlementDate, dateFromOrTo){
            // do validateion only if forwards and option period is true else skip validation
        	if(instrumentCode != 'Forward' ||  (instrumentCode == 'Forward' && isOptionPeriod != false ) ){
         	   return {
                isValid: true,
                errorString : errorString
            }
            }
            var isValid = true;
            var errorString = "";
            var selectedDateText = "Selected " + dateFromOrTo + " date ";
            var serverSettlemntDate = DateUtils.convertLocalDateToServer(settlementDate);
           
            var holidayList = _.filter($rootScope.holidayListAfterBusinessDate, function(s){return s.indexOf(serverSettlemntDate)!==-1;});
           
            
            if(settlementDate == null || settlementDate == undefined ){
            	isValid = false;
            	errorString =  dateFromOrTo + " date is mandatory..!";
            
            }else if(holidayList!=null && holidayList!=undefined && holidayList.length>0){
             
            	isValid = false;
                errorString = selectedDateText +"is a Holiday. For Holiday List, kindly refer to Holiday View screen in General Reports menu...!";
    
               
            }else{
            	 if( moment(settlementDate).isBefore( moment($rootScope.spotNextDate), 'day') || moment(settlementDate).isAfter( moment($rootScope.maxTenorDate), 'day')){
                	 //validate sett. date should not be less than spot next date. and should not be greater than last tenor value 
                	isValid = false;
                	errorString = selectedDateText +"should be between Spot Next Date and maximum tenor date...!";
                            
                }
            }
            return {
                isValid: isValid,
                errorString : errorString
            }
        }
        
        function validateSession(instrumentCode, priceCondtion){
        	if(!validateSessionStatus(instrumentCode)){
        		return false;
        	}

            if(!validateAgreedTAC()){
                return false;
            }
        	return true;
        }

        // validate session status
        function validateSessionStatus(instrumentCode){
        	var status = "";
        	var errorString = "";
        	if(instrumentCode == 'SPOT'){
        		status = $rootScope.retailSpotMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'CASH'){
        		status = $rootScope.cashMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'TOM'){
        		status = $rootScope.tomMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'Forward'){
                status = $rootScope.forwardMarketSession.sessionTimingsDTO.status;
            }
        	
        	if(status!='OPEN'){
        		errorString = "Session is closed for " + instrumentCode + " market...!"
        		return {
                    isValid:false,
                    errorString :  errorString
                }
        	}
        	
        	 return {
                isValid:true,
                errorString :  ""
            }
        }
        function validateAgreedTAC(tradingBank){
            var isValid = true;
            var errorString = "";
            if($rootScope.account!=null && $rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if($rootScope.agreedTACList!=null && $rootScope.agreedTACList!=undefined && $rootScope.agreedTACList.length>0){
                    //validate clearcorp TAC
                    if($rootScope.agreedTACList[0].isAgreed){
                        //validate selected trading bank TAC
                        if(tradingBank!=null && tradingBank!=undefined){
                            var agreedTACListForBank = _.filter(angular.copy($rootScope.agreedTACList), {'providerId': tradingBank.id});
                            if(agreedTACListForBank!=null && agreedTACListForBank!=undefined && agreedTACListForBank.length>0){
                                if(!agreedTACListForBank[0].isAgreed){
                                   errorString = "This activity cannot be completed. Kindly accept the revised Clearcorp/Relationship Bank Terms and Conditions by accessing from the “Settings menu >> Terms and Conditions view” menu in order to proceed with Order activity.";
                                   isValid= false; 
                                }
                            }else{
                                errorString = "This activity cannot be completed. Kindly accept the revised Clearcorp/Relationship Bank Terms and Conditions by accessing from the “Settings menu >> Terms and Conditions view” menu in order to proceed with Order activity.";
                                isValid= false;
                            }
                        }else{
                            errorString = "Trading bank should be selected to place order...!";
                            isValid= false;
                        }
                    }else{
                        errorString = "This activity cannot be completed. Kindly accept the revised Clearcorp/Relationship Bank Terms and Conditions by accessing from the “Settings menu >> Terms and Conditions view” menu in order to proceed with Order activity.";
                        isValid =  false;
                    }

                } 
            }
            return {
                isValid:isValid,
                errorString :  errorString
            }
            
        }
        
        function validateLimitCheck(oldOrderAmount, activityIndicator, orderAmount, instrumentCode, buySellFlag, priceCondition, tradeSettlementDate){
            var isValid = true;
            var errorObject = { message: "" };
        	// if($rootScope.limitNotDefined){
    		// 	errorString = "Trading limits are not defined. Order entry not possible...!";
    		// 	isValid =  false;
        	// }
            //valdiate max tenor date
            if(instrumentCode=='Forward'){
                if(moment(tradeSettlementDate).isAfter($rootScope.maxForwardTenorDate, 'day')){
                    //vm.errorString = "Settlement date is beyond the given limit maximum tenor date. Cannot place order...!";
                    errorObject.message = "Order entry not possible. Settlement date selected is greater than the" +
                    " maximum tenor/value date defined by the Relationship Bank. Please refer the Limit Tab for more info on maximum tenor/value date allowed.";

                    isValid = false;
                }
            }
        	if(buySellFlag=='SELL'){
        		if(!validateSellLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition)){
        			errorObject.message = "Sell trading limit breached. Order entry not possible...!";
        			isValid = false;
        		}
        	}else if(buySellFlag=='BUYY'){
        		if(!validateBuyLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition)){
        			errorObject.message = "Buy trading limit breached. Order entry not possible...!";
        			isValid = false;
        		}
        	}else if(buySellFlag=='SELLBUY'){
        		if(priceCondition=='MRKT'){
            		if(instrumentCode=='SPOT'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.retailSpotBuyLimitForMkt, $rootScope.retailSpotSellLimitForMkt,errorObject)){
            				isValid = false;
            			}
            		}else if(instrumentCode=='CASH'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.cashBuyLimitForMkt, $rootScope.cashSellLimitForMkt,errorObject)){
            				isValid = false;
            			}
            		}else if(instrumentCode=='TOM'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.tomBuyLimitForMkt, $rootScope.tomSellLimitForMkt,errorObject)){
            				isValid = false;
            			}
            		}else if(instrumentCode=='Forward'){
                        if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.forwardBuyLimitWithCF, $rootScope.forwardSellLimitWithCF,errorObject)){
                            isValid = false;
                        }
                    }
        		}else{
            		if(instrumentCode=='SPOT'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.retailSpotBuyLimit, $rootScope.retailSpotSellLimit,errorObject)){
            				isValid = false;
            			}
            		}else if(instrumentCode=='CASH'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.cashBuyLimit, $rootScope.cashSellLimit,errorObject)){
            				isValid = false;
            			}
            		}else if(instrumentCode=='TOM'){
            			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.tomBuyLimit, $rootScope.tomSellLimit,errorObject)){
            				isValid = false;
            			}
            		}else if(instrumentCode=='Forward'){
                        if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, $rootScope.forwardBuyLimitWithCF, $rootScope.forwardSellLimitWithCF,errorObject)){
                            isValid = false;
                        }
                    }
        		}
        	}
        	return {
                isValid: isValid,
                errorString : errorObject.message
            }
        }

        /*
		 * validate sell limit
		 */
        function validateSellLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition){
        	if(priceCondition=='MRKT'){
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}else{
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimit){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimit){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimit){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotSellLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashSellLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomSellLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardSellLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}
        	return true;
        }
        
        /*
		 * validate buy limit
		 */
        function validateBuyLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount, priceCondition){
        	if(priceCondition=='MRKT'){
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimitForMkt){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimitForMkt + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}else{
        		if(activityIndicator=='PLAC'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimit){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimit){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimit){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF){
                            return false;
                        }
                    }
            	}else if(activityIndicator=='MDFY'){
            		if(instrumentCode=='SPOT'){
                		if(orderAmount > $rootScope.retailSpotBuyLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='CASH'){
                		if(orderAmount > $rootScope.cashBuyLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='TOM'){
                		if(orderAmount > $rootScope.tomBuyLimit + oldOrderAmount){
                			return false;
                		}
            		}else if(instrumentCode=='Forward'){
                        if(orderAmount > $rootScope.forwardBuyLimitWithCF + oldOrderAmount){
                            return false;
                        }
                    }
            	}
        	}
    		
    		return true;
        }

         /*
		 * validate limits for sell and buy order
		 */
         function validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, buyLimit, sellLimit,errorObject){
            var isValid = true;
        	if($rootScope.limitType == 'GLOBALGROSS' && orderAmount > (buyLimit/2)){
				errorObject.message = "Global gross limit breached. Order entry is not possible...!";
				isValid = false;
			}
    		if(orderAmount > buyLimit){
    			errorObject.message = "Buy trading limit breached. Order entry is not possible...!";
    			isValid = false;
    		}else if(orderAmount > sellLimit){
    			errorObject.message = "Sell trading limit breached. Order entry is not possible...!";
    			isValid = false;
    		}
    		return isValid;
        }
        

        // validate order amount
        function validateOrderAmount(orderAmount){
        	// orderAmount = orderAmount.replace(/,/g, '');
            var isValid = true;
            var errorString = "";
            var errorShortString = "";
            var configSettings = $rootScope.configurationSettingsForOrder;
            var userPreference = angular.copy($rootScope.userPreferenceForOrder);
            var noOfDecimalsForAmount = angular.copy($rootScope.noOfDecimalsForAmountForOrder);
            var noOfDecimalsForPercent = angular.copy($rootScope.noOfDecimalsForPercentForOrder);
        	if(Math.round(parseFloat(orderAmount) * 100) / Math.round(parseFloat(1) * 100) ==0){
        		errorString = 'Order value should be greater than zero...! ';
                errorShortString = errorString;
        		isValid = false;
        	}else if(Number(orderAmount) < 0){
        		errorString = 'Order value should be positive...!';
                errorShortString = 'Order Quantity cannot be negative;';
        		isValid = false;
        	}
        	else if(Number(orderAmount) < Number(configSettings.minimumOrderValue)){
        		errorString = 'Order value should be greater than or equal to CCIL minimum order value (USD ' + $filter('number')(configSettings.minimumOrderValue, noOfDecimalsForAmount) + '). Order entry restricted..!'
        		errorShortString = 'Hard check should be greater than (USD ' + $filter('number')(configSettings.minimumOrderValue, noOfDecimalsForAmount) + ')';
                isValid = false;
        	}else if(Number(orderAmount) > Number(userPreference.userMaximumOrderValueHardCheck)){
        		errorString = 'Breach of user level maximum order value </br> <span class="hardcheck-value">(USD ' + $filter('number')(userPreference.userMaximumOrderValueHardCheck, noOfDecimalsForAmount) + ')</span> hard check.</br> Order entry restricted..!';
        		errorShortString = 'Hard check User Level (USD ' + $filter('number')(userPreference.userMaximumOrderValueHardCheck, noOfDecimalsForAmount) + ') Breached';
                isValid = false;
        	}else if(Number(orderAmount) > Number(configSettings.maximumOrderValue)){
        		errorString = 'Breach of CCIL maximum order value </br> <span class="hardcheck-value">(USD ' + $filter('number')(configSettings.maximumOrderValue, noOfDecimalsForAmount) + ')</span> hard check.</br> Order entry restricted..!'
        		errorShortString = 'Hard check (USD ' + $filter('number')(configSettings.minimumOrderValue, noOfDecimalsForAmount) + ') Breached';
                isValid = false;
        	}
        	else if(((Math.round(parseFloat(orderAmount) * 10000)) % (Math.round(parseFloat(configSettings.orderLotSize) * 10000)))!=0){
        		errorString = "Order value should be in multiples of lot size " + $filter('number')(configSettings.orderLotSize, noOfDecimalsForAmount) + "...!";
                errorShortString = 'Order value should be in multiples of lot size (USD ' + $filter('number')(configSettings.orderLotSize, noOfDecimalsForAmount);
        		isValid = false;
        	}
        	return {
                isValid: isValid,
                errorString : errorString,
                errorShortString : errorShortString
            }
        }
        function validateLimitExchangeRateHardCheck(buySellFlag, priceCondtion, selectedBankSpread, instrumentCode, activityIndicator, orderAmount, oldOrderAmount,limitExchangeRate,limitExchangeRateInSpot,buyLimitExchangeRate,buyLimitExchangeRateInSpot,sellLimitExchangeRate,sellLimitExchangeRateInSpot){
        	var ordertickSize = gettickSizeForGivenInstrument(instrumentCode);
        	var ltp = 0
            var configSettings = $rootScope.configurationSettingsForOrder;
            var userPreference = angular.copy($rootScope.userPreferenceForOrder);
            var errorObject = { message: "" ,shortMessage:""};
            var isValid = true;
        	if(buySellFlag!='SELLBUY'){
            	ltp = getLtpValue();
            	if(!validateLimitExchangeRate(buySellFlag, activityIndicator, priceCondtion, limitExchangeRate, limitExchangeRateInSpot, ordertickSize, ltp, configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage,errorObject)){
            		isValid = false;
            	}
        	}else if(buySellFlag=='SELLBUY'){
            	var ltp = getLtpValue();
            	if(!validateLimitExchangeRate('BUYY', activityIndicator, priceCondtion, buyLimitExchangeRate, buyLimitExchangeRateInSpot, ordertickSize, ltp, configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage,errorObject)){
            		// return false;
            	}
            	if(!validateLimitExchangeRate('SELL', activityIndicator, priceCondtion, sellLimitExchangeRate, sellLimitExchangeRateInSpot, ordertickSize, ltp, configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, userPreference.userAllowedOrderPriceDeviationFromMarketPriceInPercentage,errorObject)){
            		// return false;
            	}
            	if(errorObject.message!='' && errorObject.message!=null && errorObject.message!=undefined){
            		isValid = false;
            	}
        	}
        	return {
                isValid: isValid,
                errorString : errorObject.message,
                errorShortString : errorObject.shortMessage
            }
        }
        // get tick size
       function gettickSizeForGivenInstrument(instrumentCode){
        if(instrumentCode=='SPOT'){
            return $rootScope.tickSize.spot;
        }else if(instrumentCode=='CASH'){
            return $rootScope.tickSize.cash;
        }else if(instrumentCode=='TOM'){
            return $rootScope.tickSize.tom;
        }else if(instrumentCode=='Forward'){
            return $rootScope.tickSize.forward;
        }
    }

    // get ltp for given instrument and buy sell flag
    function getLtpValue(){
        if($rootScope.ibSpotMarketDepth!=null && $rootScope.ibSpotMarketDepth!=undefined){
            if($rootScope.ibSpotMarketDepth.ltp!=null && $rootScope.ibSpotMarketDepth.ltp!=undefined && $rootScope.ibSpotMarketDepth.ltp>0){
                return $rootScope.ibSpotMarketDepth.ltp;
            }else{
                return $rootScope.previousDayClosingPriceForIbSpot;
            }
        }else{
            return $rootScope.previousDayClosingPriceForIbSpot;
        }
    }
    function validateLimitExchangeRate(buySellFlag, activityIndicator, priceCondition, limitExchangeRate,limitExchangeRateInSpot, ordertickSize, ltp, allowedOrderPriceDeviationInPercent, allowedOrderPriceDeviationInPercentUserSettings,errorObject){
        ltp = Number(ltp);
        
        if(priceCondition!='MRKT'){
            if(Number(limitExchangeRate)<0){
                if(buySellFlag=='BUYY'){
                    errorObject.message = 'Buy limit price should be positive...!';
                    errorObject.shortMessage = 'Buy limit price cannot be negative';
                }else{
                    errorObject.message = 'Sell limit price should be positive...!';
                    errorObject.shortMessage = 'Sell limit price cannot be negative';
                }
                return false;
            }
        }
        
        if((priceCondition!='MRKT' && Number(limitExchangeRate)==0) || (priceCondition=='MRKT' && activityIndicator=='MDFY' && Number(limitExchangeRate)==0)){
            if(buySellFlag=='BUYY'){
                errorObject.message = 'Buy limit price should be greater than zero...!';
            }else{
                errorObject.message = 'Sell limit price should be greater than zero...!';
            }
            errorObject.shortMessage = errorObject.message;
            return false;
        }
        var tickSizeRemainder = (Math.round(parseFloat(limitExchangeRate)*10000)) % (Math.round(parseFloat(ordertickSize)*10000));
        if(tickSizeRemainder!=0 && priceCondition!='MRKT'){
            if(buySellFlag=='BUYY'){
                errorObject.message = 'Buy limit price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
            }else{
                errorObject.message = 'Sell limit price should be in multiples of tick size ' + Number(ordertickSize) + '...!';
            }
            errorObject.shortMessage = 'Price should be in multiples of tick size '+ Number(ordertickSize);
            return false;
        }
        
        if(!validateLimitExchangeRateRange(limitExchangeRateInSpot, allowedOrderPriceDeviationInPercentUserSettings, ltp, ordertickSize, priceCondition, buySellFlag, 'user',errorObject)){
            return false;
        }
        
        if(!validateLimitExchangeRateRange(limitExchangeRateInSpot, allowedOrderPriceDeviationInPercent, ltp, ordertickSize, priceCondition, buySellFlag, 'clearcorp',errorObject)){
            return false;
        }
        
        return true;
    }
    
    function validateLimitExchangeRateRange(limitExchangeRateInSpot, allowedOrderPriceDeviationInPercent, ltp, ordertickSize, priceCondition, buySellFlag, level,errorObject){
        var isValid = true;
        var higherCutOffPrice = $rootScope.previousDayClosingPriceForIbSpot + ($rootScope.previousDayClosingPriceForIbSpot * Number(allowedOrderPriceDeviationInPercent/100));
        var lowerCutOffPrice = $rootScope.previousDayClosingPriceForIbSpot - ($rootScope.previousDayClosingPriceForIbSpot * Number(allowedOrderPriceDeviationInPercent/100));
        var noOfDecimalsForRate = $rootScope.noOfDecimalsForRateForOrder;
        if(ltp>0 && ltp!=undefined && ltp!=null){
            higherCutOffPrice = ltp + (ltp * Number(allowedOrderPriceDeviationInPercent/100));
            lowerCutOffPrice = ltp - (ltp * Number(allowedOrderPriceDeviationInPercent/100));
        }
        higherCutOffPrice = (Math.round(parseFloat(higherCutOffPrice)*10000)/10000);
        lowerCutOffPrice = (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000)
        var higherCutOffPriceRemainder = (Math.round(parseFloat(((Math.round(parseFloat(higherCutOffPrice)*10000)) % (Math.round(parseFloat(ordertickSize)*10000))/10000))*10000)/10000);
        higherCutOffPrice = (Math.round(parseFloat(higherCutOffPrice)*10000)/10000) - (Math.round(parseFloat(higherCutOffPriceRemainder)*10000)/10000);
        
        var lowerCutOffPriceRemainder = (Math.round(parseFloat(((Math.round(parseFloat(lowerCutOffPrice)*10000)) % (Math.round(parseFloat(ordertickSize)*10000))/10000))*10000)/10000);
        if(lowerCutOffPriceRemainder!=0){
            lowerCutOffPrice = (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000) - (Math.round(parseFloat(lowerCutOffPriceRemainder)*10000)/10000) + (Math.round(parseFloat(ordertickSize)*10000)/10000);
        }else{
            lowerCutOffPrice = (Math.round(parseFloat(lowerCutOffPrice)*10000)/10000);
        }
         
        if(priceCondition!='MRKT' && (Math.round(parseFloat(limitExchangeRateInSpot)*10000) < Math.round(parseFloat(lowerCutOffPrice)*10000) ||  Math.round(parseFloat(limitExchangeRateInSpot)*10000) > Math.round(parseFloat(higherCutOffPrice)*10000))){
            if(buySellFlag=='BUYY'){
                if(level=='user'){
                    errorObject.message = 'Order entry not possible as equivalent Spot price </br> has breached User level limit price Hard check.</br> BUY Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>" +  $filter('number')(lowerCutOffPrice, noOfDecimalsForRate) +"</span> "+  ' and ' +"<span class='hardcheck-value'>" +  $filter('number')(higherCutOffPrice, noOfDecimalsForRate)+"</span> ";
                    errorObject.shortMessage = 'Equivalent Spot price User level Hard check breached. Price should be between '+$filter('number')(lowerCutOffPrice, noOfDecimalsForRate) +  ' and ' +  $filter('number')(higherCutOffPrice, noOfDecimalsForRate);
                }else{
                    errorObject.message = 'Order entry not possible as equivalent Spot price </br> has breached CCIL limit price Hard check.</br> BUY Equivalent Spot Price should be between </br> ' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, noOfDecimalsForRate)+"</span> " +  ' and ' + "<span class='hardcheck-value'>"+$filter('number')(higherCutOffPrice, noOfDecimalsForRate) +"</span> ";
                    errorObject.shortMessage = 'Equivalent Spot price CCIL Hard check breached. Price should be between '+$filter('number')(lowerCutOffPrice, noOfDecimalsForRate) +  ' and ' +  $filter('number')(higherCutOffPrice, noOfDecimalsForRate);

                }
            }else{
                if(level=='user'){
                    if(errorObject.message!=''){
                        errorObject.message = 'Order entry not possible as equivalent Spot price </br> has breached User level limit price Hard check.</br> BUY and SELL Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, noOfDecimalsForRate)+"</span> " +  ' and ' +"<span class='hardcheck-value'>"+ $filter('number')(higherCutOffPrice, noOfDecimalsForRate) +"</span> ";
                    }else{
                        errorObject.message = 'Order entry not possible as equivalent Spot price </br> has breached User level limit price Hard check.</br> SELL Equivalent Spot Price should be between </br>' + "<span class='hardcheck-value'>"+$filter('number')(lowerCutOffPrice, noOfDecimalsForRate) +"</span> "+  ' and ' +"<span class='hardcheck-value'>"+ $filter('number')(higherCutOffPrice, noOfDecimalsForRate)+"</span> ";
                        errorObject.shortMessage = 'Equivalent Spot price User level Hard check breached. Price should be between '+$filter('number')(lowerCutOffPrice, noOfDecimalsForRate) +  ' and ' +  $filter('number')(higherCutOffPrice, noOfDecimalsForRate);
                    }
                }else{
                    if(errorString!=''){
                        errorObject.message = 'Order entry not possible as equivalent Spot price </br> has breached CCIL limit price Hard check.</br> BUY and SELL Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, noOfDecimalsForRate)+"</span> " +  ' and ' +"<span class='hardcheck-value'>"+ $filter('number')(higherCutOffPrice, noOfDecimalsForRate)+"</span> ";
                    }else{
                        errorObject.message = 'Order entry not possible as equivalent Spot price </br> has breached CCIL limit price Hard check.</br> SELL Equivalent Spot Price should be between </br>' +"<span class='hardcheck-value'>"+ $filter('number')(lowerCutOffPrice, noOfDecimalsForRate)+"</span> " +  ' and ' + "<span class='hardcheck-value'>"+$filter('number')(higherCutOffPrice, noOfDecimalsForRate)+"</span> ";
                        errorObject.shortMessage = 'Equivalent Spot price CCIL Hard check breached. Price should be between '+$filter('number')(lowerCutOffPrice, noOfDecimalsForRate) +  ' and ' +  $filter('number')(higherCutOffPrice, noOfDecimalsForRate);

                    }
                }
            }
            isValid = false;
        }
        return isValid;
    }

    function validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag){
        var errorString ='';
        var isValid = true;
        if(instrumentCode!='SPOT'){
            var tickSize = gettickSizeForGivenInstrument(instrumentCode);
            var settlementDate = getSettlementDate(instrumentCode, buySellFlag);
            var spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(selectedBankSpread, instrumentCode, buySellFlag, 
                DateUtils.convertLocalDateToServer(settlementDate), $rootScope.retailSpotMarketSession.settlementDate, 
                tickSize);
            //var spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentCode);
            if((spreadUsed==null || spreadUsed==undefined || spreadUsed=='') && spreadUsed!=0){
                if(buySellFlag=='BUYY'){
                    if(instrumentCode=='CASH'){
                        errorString = 'CASH/SPOT bid spread is not given by the bank. Order entry not possible...!';
                    }else if(instrumentCode=='TOM'){
                        errorString = 'TOM/SPOT bid spread is not given by the bank. Order entry not possible...!';
                    }else if(instrumentCode=='Forward'){
                        errorString = 'Order entry not possible as FORWARD offer spread for the selected settlement date ('+  $filter('date')(settlementDate,'dd-MMM-yyyy') +') is not provided by your Relationship bank.';
                    }
                }else if(buySellFlag=='SELL'){
                    if(instrumentCode=='CASH'){
                        errorString = 'CASH/SPOT offer spread is not given by the bank. Order entry not possible...!';
                    }else if(instrumentCode=='TOM'){
                        errorString = 'TOM/SPOT offer spread is not given by the bank. Order entry not possible...!';
                    }else if(instrumentCode=='Forward'){
                        errorString = 'Order entry not possible as FORWARD bid spread for the selected settlement date ('+  $filter('date')(settlementDate,'dd-MMM-yyyy') +') is not provided by your Relationship bank.';
                    }
                }
                isValid = false;
            }
        }
        return {
            isValid:isValid,
            errorString:errorString
        }
    }

    /* get settlemnt date based on the order type, instrument and tenor name*/
    function getSettlementDate( instrumentCode, orderType){
        var settlementDate = angular.copy($rootScope.tradeSettlementDateFororder);
        if( $rootScope.tenor !=null){
            console.log('instrumentCode - ' + instrumentCode + ' orderType - ' + orderType  + ' tenorName - ' + $rootScope.tenor.tenorName);  
        }          
        if(instrumentCode == 'Forward' && $rootScope.tenor !=null && $rootScope.tenor != undefined && $rootScope.tenor.tenorName == 'Option Period'){
            if( orderType =="SELL" ){
                settlementDate = angular.copy($rootScope.tradeSettlementDateFrom);
            }else
            {
                settlementDate = angular.copy($rootScope.tradeSettlementDateFororder);
            }
        }
        return settlementDate;
    }

    function getEventTypeForShortName(eventTypeShortName){
        var eventType = {};
        angular.forEach($rootScope.eventTypes, function(value){
            if(value.shortName==eventTypeShortName){
                eventType = value;
            }
        })
        return eventType;
    }
    function getCloseTimeForInstrumentCode(instrument){
        if(instrument=='SPOT'){
            return $rootScope.retailSpotMarketSession.sessionTimingsDTO.closeTime;
        }else if(instrument=='CASH'){
            return $rootScope.cashMarketSession.sessionTimingsDTO.closeTime;
        }else if(instrument=='TOM'){
            return $rootScope.tomMarketSession.sessionTimingsDTO.closeTime;
        }else if(instrument=='Forward'){
            return $rootScope.forwardMarketSession.sessionTimingsDTO.closeTime;
        }
    }
    

    }
})();
