(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('security-policy', {
            parent: 'entity',
            url: '/security-policy',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.security.managesecuritypolicy" | translate }}',
                parent: 'home'
              },
            views: {
                'content@': {
                    templateUrl: 'app/entities/security-policy/security-policy.html',
                    controller: 'SecurityPolicyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('securityPolicy');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('security-policy.new', {
            parent: 'security-policy',
            url: '/security-policy/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$rootScope', '$uibModal', function($stateParams, $state, $rootScope, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/security-policy/security-policy-dialog.html',
                    controller: 'SecurityPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: ['SecurityPolicy', 'GlobalValues', function(SecurityPolicy, GlobalValues) {
                            return SecurityPolicy.get({orgId:GlobalValues.getOrgId(), themeID : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                	$rootScope.$emit('gatewayApp:securityPolicyRefresh');
                    $state.go('security-policy');
                }, function() {
                    $state.go('security-policy');
                });
            }]
        })
         .state('security-policy-todolist', {
            parent: 'dashboard',
            url: '/security-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/security-policy/security-policy-dialog.html',
                    controller: 'SecurityPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('security-policy', null, { reload: 'security-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
        .state('security-policy-todolist.resubmit', {
            parent: 'dashboard',
            url: '/security-policy-resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/security-policy/security-policy-dialog.html',
                    controller: 'SecurityPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                        	

                        }
                    }
                }).result.then(function() {
                    $state.go('security-policy', null, { reload: 'security-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
        })
        .state('security-policy-auditLog', {
            parent: 'audit-log',
            url: '/security-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/security-policy/security-policy-dialog.html',
                    controller: 'SecurityPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('security-policy', null, { reload: 'security-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
        .state('security-policy-historyReport', {
            parent: 'history-report',
            url: '/security-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/security-policy/security-policy-dialog.html',
                    controller: 'SecurityPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('security-policy', null, { reload: 'security-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
   };
})();