(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('MarkupSettingsViewController', MarkupSettingsViewController);

    MarkupSettingsViewController.$inject = ['$scope', '$state','$rootScope','workflowSharedService','$uibModalInstance','Markup','entity', 'Workflow', 'GlobalValues', 'ScreenLog','StaticLookUp','markupTypes','DateUtils', 'roEdApplicabilityMethods' ];

    function MarkupSettingsViewController($scope, $state, $rootScope, workflowSharedService, $uibModalInstance,Markup,entity, Workflow, GlobalValues, ScreenLog, StaticLookUp,markupTypes,DateUtils, roEdApplicabilityMethods) {
        var vm = this;
        vm.clear=clear;
        vm.approveMarkup = approveMarkup;
        vm.rejectMarkup = rejectMarkup;
        vm.discardMarkup = discardMarkup;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.globalValues = GlobalValues;
        vm.markupTypes = markupTypes;
        vm.orgId = vm.globalValues.getOrgId();
        vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.decimalForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
        
        //get RO/ED applicability method list lookup
        // StaticLookUp.getRoEdApplicabilityMethodList(function(result){
        //     vm.roEdApplicabilityMethodList = result;
        // });
        vm.roEdApplicabilityMethodList = roEdApplicabilityMethods;
        vm.hideRoEdApplicability = false;

        $scope.$watch('vm.markupType', function(value){
        	if(value!=null && value!=undefined){
        		if(vm.markupType.shortName.toUpperCase()=='PERCENTAGE'){
        			vm.noOfDecimals=vm.decimalsForPercent;
        		}else{
        			vm.noOfDecimals=vm.decimalForPaise;
        		}
        	}
        });
        
        
        if(workflowSharedService.viewFrom == 'markup'){
        	vm.markup = entity;    
        	vm.effectiveFromDateTime = DateUtils.convertDateTimeFromServer(vm.markup.effectiveFromDateTime);
        	
        	vm.bank=vm.markup.bank;
        	vm.branch=vm.markup.branch;
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
            if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'markuptodolist_checker') {
        	vm.markup =  angular.fromJson($rootScope.payload); 
        	vm.effectiveFromDateTime = DateUtils.convertDateTimeFromServer(vm.markup.effectiveFromDateTime);
        	vm.bank=vm.markup.bank;
        	vm.branch=vm.markup.branch;
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
            if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'markuptodolist_maker') {
        	vm.markup =  angular.fromJson($rootScope.payload); 
        	vm.effectiveFromDateTime = DateUtils.convertDateTimeFromServer(vm.markup.effectiveFromDateTime);
        	vm.bank=vm.markup.bank;
        	vm.branch=vm.markup.branch;
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
            if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom=='markup_import_auditlog'){
        	
        	vm.markup =  angular.fromJson($rootScope.payload);   
        	console.log(vm.markup);
        	
        	vm.effectiveFromDateTime = DateUtils.convertDateTimeFromServer(vm.markup.effectiveFromDateTime);
        	vm.bank=vm.markup.markupSettings.bank;
        	vm.branch=vm.markup.markupSettings.branch;
        	vm.markup.customer = vm.markup.markupSettings.customer;
        	vm.markup.markupValue = vm.markup.markupSettings.markupValue;
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupSettings.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
            if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
            }else{
                vm.hideRoEdApplicability = true;
                //var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                //vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }
        	$scope.showButton=false;

        }
        else if(workflowSharedService.viewFrom=='markup_auditLog'){
        	vm.markup =  angular.fromJson($rootScope.payload);   
        	vm.effectiveFromDateTime = DateUtils.convertDateTimeFromServer(vm.markup.effectiveFromDateTime);
            vm.bank=vm.markup.bank;
            vm.branch=vm.markup.branch;

        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName.toUpperCase()==vm.markup.markupType.toUpperCase()){
            		vm.markupType = value; 
        		}
        	});
            if(vm.markup.roEdApplicabilityMethod!=null && vm.markup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.markup.roEdApplicabilityMethod;
            }else{
                vm.hideRoEdApplicability = true;
                //var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                //vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }
        	$scope.showButton=false;
        }
        
        function approveMarkup() { 
        	Markup.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectMarkup() { Workflow.rejectTask($rootScope.taskID); }

        function discardMarkup() {
        	Markup.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }   
})();
