(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerRegistrationPreviewController', CustomerRegistrationPreviewController);

    CustomerRegistrationPreviewController.$inject = ['$scope', '$rootScope', '$state','$timeout', 'Auth', '$window', 'StaticLookUp', 'MessageBox', 'CustomerRegistration', '$sessionStorage', 'DateUtils', 'Country', 'GlobalValues', 'AlertService', '$filter','$stateParams','$uibModalInstance','customerTypeWiseInputDetails'];

    function CustomerRegistrationPreviewController ($scope, $rootScope, $state, $timeout, Auth, $window, StaticLookUp, MessageBox, CustomerRegistration, $sessionStorage, DateUtils, Country, GlobalValues, AlertService,$filter, $stateParams, $uibModalInstance, customerTypeWiseInputDetails) {
        var vm = this;
       
        vm.globalValues = GlobalValues;
        vm.theme = $stateParams.theme;
        vm.customerRegistrationRequest = $stateParams.customerRegistrationRequest;
        vm.panImageFile  = $stateParams.image;
        vm.viewPanImage = viewPanImage;
        vm.submit = submit;
        vm.clear = clear;
        vm.clicked = false;
        vm.dob =  $filter('date')(vm.customerRegistrationRequest.dob, "dd-MMM-yyyy");
        
         vm.dobOrDoi =  $filter('date')(vm.customerRegistrationRequest.dobOrDoi, "dd-MMM-yyyy");
         
         if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
       			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
        	 vm.leiExpiryDate =  $filter('date')(vm.customerRegistrationRequest.leiExpiryDate, "dd-MMM-yyyy");
         }
         
          
        //intialize customer type wise input details
        vm.customerTypeWiseInputDetails = customerTypeWiseInputDetails;
        
        //Indices of input details in customer type wise input details array
        $scope.firstNameIndex = 0;
        $scope.middleNameIndex = 1;
        $scope.lastNameIndex = 2;
        $scope.entityNameIndex = 3;
        $scope.panNoIndex = 4;
        $scope.panImageIndex = 5;
        $scope.legalEntityIdentifierIndex = 6;
        $scope.leiExpiryDateIndex = 7;
        $scope.cinIndex = 8;
        $scope.gstinIndex = 9;
        $scope.stateCodeIndex = 10;
        $scope.dobDoiIndex = 11;
        vm.showSingleUser=false;
		vm.showMultiUser=false;
		$scope.customerTypeSolePropId=27;
		vm.clicked=false;

        $scope.$watch('vm.customerRegistrationRequest.customerType', function(value){
            if(value!=null && value!=undefined){
                vm.inputDetailsForSelectedCustType = _.filter(vm.customerTypeWiseInputDetails, function(item) {
                    return item.customerType.id == value.id;
                });
                if(value.customerUserType.shortName=='SingleUserCustomer'){
					vm.showSingleUser=true;
					vm.showMultiUser=false;
						}else if(value.customerUserType.shortName=='MultiUserCustomer'){
							vm.showMultiUser=true;
							vm.showSingleUser=false;
					
						}
            }else{
                vm.showSingleUser=true;
				vm.showMultiUser=false;
                vm.inputDetailsForSelectedCustType = [
                    {customerType: null, fieldName: 'FirstName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'MiddleName', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LastName', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'EntityName', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'PANNo', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'PANImage', display: true, isMandatory: true},
                    {customerType: null, fieldName: 'LegalEntityIdentifier', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'LEIExpiryDate', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'CIN', display: false, isMandatory: false},
                    {customerType: null, fieldName: 'GSTIN', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'StateCode', display: true, isMandatory: false},
                    {customerType: null, fieldName: 'DOBDOI', display: true, isMandatory: true}
                ]
            }
        });

        function submit(){
        	if(moment(vm.customerRegistrationRequest.dob).isAfter(vm.todayDate,'day')){
        		
       		 AlertService.error($filter('translate')('gatewayApp.customerRegistration.futuredateOfBirth'));
       		 
       	 }else if( vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display && vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
       			 && vm.customerRegistrationRequest.leiExpiryDate!= '' && moment(vm.customerRegistrationRequest.leiExpiryDate).isBefore(vm.todayDate,'day')){
        		
       		 AlertService.error($filter('translate')('gatewayApp.customerRegistration.expiryDate'));
       		 
       	 }      	
        	else{
       		var fd = new FormData();
			vm.customerRegistrationRequest.dob = DateUtils.convertLocalDateToServer(vm.customerRegistrationRequest.dob);
			vm.customerRegistrationRequest.dobOrDoi = DateUtils.convertLocalDateToServer(vm.customerRegistrationRequest.dobOrDoi);
			if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
	       			 && vm.customerRegistrationRequest.leiExpiryDate!= ''){
				vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateToServer(vm.customerRegistrationRequest.leiExpiryDate);
			}
			
			if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
							vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.companyName;
							vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.companyName;
								
				
			}
			if(vm.customerRegistrationRequest.customerType.customerUserType.shortName=='SingleUserCustomer'){
				if(vm.customerRegistrationRequest.customerType.id != $scope.customerTypeSolePropId){
					vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.userFirstName;
					vm.customerRegistrationRequest.customerMiddleName=vm.customerRegistrationRequest.userMiddleName;
					vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.userLastName;
				}
				

			}
			vm.panFile = $rootScope.panFile;
			fd.append('customer', JSON.stringify(vm.customerRegistrationRequest));
			if($rootScope.panFile.name!=null && $rootScope.panFile.name!=undefined){
	            fd.append('pan', vm.panFile);
	            CustomerRegistration.submit(fd, onSubmitSuccess, onSubmitFailure);
			}else{
	            CustomerRegistration.reSubmit(fd, onSubmitSuccess, onSubmitFailure);
			}
        	
       	 }
        	
        }
        
        function onSubmitSuccess(result){
        	//$uibModalInstance.dismiss('cancel');
        	//$state.go('registrationRequestStatus', {requestId: result.id});
        	$uibModalInstance.close(result);
			vm.clicked = false;
        	 
        }
        
        function onSubmitFailure(){
        	vm.clicked = false;
        	vm.customerRegistrationRequest.dob  = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dob);
        	vm.customerRegistrationRequest.dobOrDoi  = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.dobOrDoi);
        	
        	if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
				vm.customerRegistrationRequest.customerFirstName=vm.customerRegistrationRequest.companyName;
				vm.customerRegistrationRequest.customerLastName=vm.customerRegistrationRequest.companyName;
				vm.required = false;
			}
            if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.leiExpiryDateIndex].display){
                if( vm.customerRegistrationRequest.leiExpiryDate !=null && vm.customerRegistrationRequest.leiExpiryDate!=undefined 
                         && vm.customerRegistrationRequest.leiExpiryDate!= ''){
                    vm.customerRegistrationRequest.leiExpiryDate = DateUtils.convertLocalDateFromServer(vm.customerRegistrationRequest.leiExpiryDate);
                }
            }
        }
        
        function base64ToArrayBuffer(base64) {
    	    var binary_string =  window.atob(base64);
    	    var len = binary_string.length;
    	    var bytes = new Uint8Array( len );
    	    for (var i = 0; i < len; i++)        {
    	        bytes[i] = binary_string.charCodeAt(i);
    	    }
    	    return bytes.buffer;
    	}

        function viewPanImage(){
        	if(vm.customerRegistrationRequest.fileExtension=='pdf'){
        		var contentByteArray = base64ToArrayBuffer(vm.panImageFile);
	    		  
	    		var file = new Blob([contentByteArray], { type: 'application/pdf' });
	            var fileURL = URL.createObjectURL(file);
	              
	            if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(file, "PAN.pdf");
	            }
	            else {
	                   window.open(fileURL, "PAN.pdf");    
	            }
    			//var pdfWindow = window.open("");
        		//pdfWindow.document.write("<iframe width='100%' height='100%'title='PAN'  src='data:application/pdf;base64, " + encodeURI(vm.panImageFile)+"' ></iframe>")
        	}
       
        	else{
        		console.log(vm.customerRegistrationRequest.panImage);
        		$state.go('view-panImage-customer-registration-preview', {image:vm.panImageFile ,theme:vm.theme});	
        	
        }
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
      
        vm.print = print;
        
		function print(){
			var customerRegDetails = angular.copy(vm.customerRegistrationRequest);
			customerRegDetails.dob = DateUtils.convertLocalDateToServer(customerRegDetails.dob);
			//customerRegDetails.dobOrDoi = DateUtils.convertLocalDateToServer(customerRegDetails.dobOrDoi);
			if( customerRegDetails.leiExpiryDate !=null && customerRegDetails.leiExpiryDate!=undefined 
	       			 && customerRegDetails.leiExpiryDate!= ''){
				customerRegDetails.leiExpiryDate = DateUtils.convertLocalDateToServer(customerRegDetails.leiExpiryDate);
			}
			
			if(vm.inputDetailsForSelectedCustType!=null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex]!= null 
                            && vm.inputDetailsForSelectedCustType[$scope.entityNameIndex].display){
				customerRegDetails.customerFirstName=customerRegDetails.companyName;
				customerRegDetails.customerLastName=customerRegDetails.companyName;
			}
       	  	
       	 	CustomerRegistration.getCustomerRegistrationPrintForNewCustomer(customerRegDetails,function(result){
	        	var file = new Blob([result.data], {type: 'application/pdf'});
	      		var fileURL =URL.createObjectURL(file);
	              vm.clicked=false;
	              if(window.navigator.msSaveOrOpenBlob) {
	                    window.navigator.msSaveOrOpenBlob(file, "CustomerRegistrationPreview.pdf");
	                }
	                else {
	                     window.open(fileURL, "CustomerRegistrationPreview.pdf");    
	                }

       	 	});
		}
    }
})();
