(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);

    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$localStorage', '$sessionStorage', 'GlobalValues'];

    function authExpiredInterceptor($rootScope, $q, $injector, $localStorage, $sessionStorage, GlobalValues) {
        var service = {
            responseError: responseError
        };

        return service;

        $rootScope.sessionExpired = false;
        function responseError(response) {
            if (response.status === 401 || response.status === 403 || response.status === 503) {
            	var messageBox = $injector.get('MessageBox');
            	if(!$rootScope.sessionExpired){
                    delete $localStorage.authenticationToken;
                    delete $sessionStorage.authenticationToken;
                    var Principal = $injector.get('Principal');
                    if (Principal.isAuthenticated()) {
                    	$rootScope.sessionExpired = true;
                    	if(response.status === 503){
                        	messageBox.openMessageBox('Alert', 'Throttling limit is execeeded. Kindly re-login after some time...!', 'Ok', 'throttlinglimitbreached');
                    	}else{
                        	messageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'sessionexpired');
                    	}
                        /*var Auth = $injector.get('Auth');
                        Auth.authorize(true);*/
                    }
            	}
            }
            $rootScope.$on('gatewayApp.messagebox.ok.sessionexpired', function (event) {
            	var Auth = $injector.get('Auth');
            	var Account = $injector.get('Account');
            	var WebSocket = $injector.get('WebSocket');
            	GlobalValues.clearPreviousValues();
            	WebSocket.disconnecteWebSocket(true);
            	Account.logout();
                Auth.authorize(true);
                $rootScope.sessionExpired = true;
                $injector.get('$uibModalStack').dismissAll();
                $injector.get('$state').go('login', null, {reload:'login'});
                window.location.reload();
            });
            
            $rootScope.$on('gatewayApp.messagebox.clear.sessionexpired', function (result){
            	var Auth = $injector.get('Auth');
            	var Account = $injector.get('Account');
            	var WebSocket = $injector.get('WebSocket');
            	GlobalValues.clearPreviousValues();
            	WebSocket.disconnecteWebSocket(true);
            	Account.logout();
                Auth.authorize(true);
                $rootScope.sessionExpired = true;
                $injector.get('$uibModalStack').dismissAll();
                $injector.get('$state').go('login', null, {reload:'login'});
                window.location.reload();
            })
            
            $rootScope.$on('gatewayApp.messagebox.ok.throttlinglimitbreached', function (event) {
            	var Auth = $injector.get('Auth');
            	var Account = $injector.get('Account');
            	var WebSocket = $injector.get('WebSocket');
            	GlobalValues.clearPreviousValues();
            	WebSocket.disconnecteWebSocket(true);
            	Account.logout();
                Auth.authorize(true);
                $rootScope.sessionExpired = true;
                $injector.get('$uibModalStack').dismissAll();
                $injector.get('$state').go('login', null, {reload:'login'});
                //setTimeout (window.close, 5000);
            });
            
            $rootScope.$on('gatewayApp.messagebox.clear.throttlinglimitbreached', function (result){
            	var Auth = $injector.get('Auth');
            	var Account = $injector.get('Account');
            	var WebSocket = $injector.get('WebSocket');
            	GlobalValues.clearPreviousValues();
            	WebSocket.disconnecteWebSocket(true);
            	Account.logout();
                Auth.authorize(true);
                $rootScope.sessionExpired = true;
                $injector.get('$uibModalStack').dismissAll();
                $injector.get('$state').go('login', null, {reload:'login'});
                //setTimeout (window.close, 5000);
            })
            
            return $q.reject(response);
        }
        
    }
})();
