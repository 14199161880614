(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account ($resource) {
        var service = $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'unlockAccount': {
            	method: 'POST',
            	url: 'api/unlock-account',
            	transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
            },
            'logout' : {
				method : 'POST',
				url : 'api/logout',
			},
			'sendOTP' : {
				method : 'POST',
				params : {login : '@login'},
				url : 'api/send-otp-login/login/:login',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'verifyOTP' : {
				method : 'POST',
				url : 'api/verify-otp-login',
			},
			'checkPasswordExpiry' : {
				method : 'POST',
				//params : {login : '@login', otp: '@otp'},
				url : 'api/check-password-expiry-warning'
			},
			'preAuthorize': {
				method : 'POST',
				params : {orgType : '@orgType'},
				url : 'api/pre-authenticate/:orgType'
			},
			'deleteUser' : {
				method : 'POST',
				url : 'api/delete-user'
			}
        });

        return service;
    }
})();
