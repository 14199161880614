(function() {
	'use strict';

	angular.module('gatewayApp').controller('BranchViewController',
			BranchViewController);

	BranchViewController.$inject = [ '$scope', '$state', '$rootScope',
			'workflowSharedService', '$uibModalInstance', 'Branch', 'entity',
			'Workflow', 'GlobalValues' ];

	function BranchViewController($scope, $state, $rootScope,
			workflowSharedService, $uibModalInstance, Branch, entity, Workflow,
			GlobalValues) {
		var vm = this;
		vm.clear = clear;
		vm.approveBranch = approveBranch;
		vm.rejectBranch = rejectBranch;
		vm.discardBranch = discardBranch;
		$scope.showButton = false;
		$scope.showDiscardButton = false;
		vm.clicked = false;
		vm.ExpandOrCollapseUser1 = false;
		vm.ExpandOrCollapseUser2 = false;
		vm.ExpandOrCollapseBankDetails = true;
		vm.globalValues = GlobalValues;
		vm.branchDTO = {};
		vm.isDefaultUsersRequired1 = false;
		vm.isDefaultUsersRequired2 = false;

		if (workflowSharedService.viewFrom == 'branch') {
			vm.branch = entity;
			vm.mobileCountryCode=vm.branch.mobileCountryCode;
			$scope.showButton = false;
		} else if (workflowSharedService.viewFrom == 'branchtodolist_checker') {
			vm.branchDTO = angular.fromJson($rootScope.payload);
			vm.branch = vm.branchDTO.branch;

			if (vm.branch == undefined) {
				vm.branch = vm.branchDTO
			}
			vm.mobileCountryCode=vm.branch.mobileCountryCode;
			vm.user1 = vm.branchDTO.user1;
			vm.user2 = vm.branchDTO.user2;

			if (vm.user1 != undefined) {
				vm.user1.dob = new Date(vm.user1.dob);
				vm.isDefaultUsersRequired1 = true;
			}

			if (vm.user2 != undefined) {
				vm.user2.dob = new Date(vm.user2.dob);
				vm.isDefaultUsersRequired2 = true;
			}

			$scope.showButton = true;
			$scope.showDiscardButton = false;
		} else if (workflowSharedService.viewFrom == 'branchtodolist_maker') {
			vm.branchDTO = angular.fromJson($rootScope.payload);
			vm.branch = vm.branchDTO.branch;
			if (vm.branch == undefined || vm.branch == null) {
				vm.branch = vm.branchDTO
			}
			vm.mobileCountryCode=vm.branch.mobileCountryCode;
			vm.user1 = vm.branchDTO.user1;
			vm.user2 = vm.branchDTO.user2;

			if (vm.user1 != undefined) {
				vm.user1.dob = new Date(vm.user1.dob);
				vm.isDefaultUsersRequired1 = true;
			}

			if (vm.user2 != undefined) {
				vm.user2.dob = new Date(vm.user2.dob);
				vm.isDefaultUsersRequired2 = true;
			}

			$scope.showButton = false;
			$scope.showDiscardButton = true;
		} else if (workflowSharedService.viewFrom == 'branch_auditLog') {
			vm.branchDTO = angular.fromJson($rootScope.payload);
			vm.branch = vm.branchDTO.branch;

			if (vm.branch == undefined) {
				vm.branch = vm.branchDTO
			}
			vm.mobileCountryCode=vm.branch.mobileCountryCode;
			vm.user1 = vm.branchDTO.user1;
			vm.user2 = vm.branchDTO.user2;
			
			if (vm.user1 != undefined) {
				vm.user1.dob = new Date(vm.user1.dob);
				vm.isDefaultUsersRequired1 = true;
			}

			if (vm.user2 != undefined) {
				vm.user2.dob = new Date(vm.user2.dob);
				vm.isDefaultUsersRequired2 = true;
			}

			$scope.showButton = false;
		}

		function approveBranch() {
			Branch.approve({
				"taskId" : $rootScope.taskID
			}, onApproveSuccess, onApproveError);
			$uibModalInstance.dismiss('cancel');
		}

		function onApproveSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
		}
		function onApproveError(error) {
			vm.clicked=false;
		}

		function rejectBranch() {
			Workflow.rejectTask($rootScope.taskID);
		}

		function discardBranch() {
			Branch.discard({
				"taskId" : $rootScope.taskID
			}, onDiscardSuccess, onDiscardError);
			$uibModalInstance.dismiss('cancel');
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);
		}
		function onDiscardError(error) {

		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
	}
})();
