(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('AuditLog', AuditLog);

    AuditLog.$inject = ['$resource'];

    function AuditLog ($resource) {
        var resourceUrl =  'api/get-audit-log/:id';

        return $resource(resourceUrl, {}, {
            
        	 'getAuditLogDetails':{
             	isArray:true,
  				method: 'GET',
  				params: {entityId:'@entityId',login:'@login',from:'@from',to:'@to',orgId:'@orgId'},
  				url: 'api/audit-log-report/:entityId/:from/:to/get-audit-log-details'
              },
              'getAuditLogDetailsFromArchive':{
               	isArray:true,
    			method: 'GET',
    			params: {entityId:'@entityId',login:'@login',from:'@from',to:'@to',orgId:'@orgId'},
    			url: 'api/audit-log-archive-report/:entityId/:from/:to/get-audit-log-details'
               }
        });
    }
})();
