(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'userRole'
        })
        .controller('ManageUserRoleController', ManageUserRoleController);

    ManageUserRoleController.$inject = ['$scope', '$state', 'workflowSharedService', 'User', 'GlobalValues', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function ManageUserRoleController ($scope, $state,workflowSharedService, User, GlobalValues, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.screenLog = ScreenLog;
        vm.entityConstants = entityConstants;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;

        vm.users = [];
        vm.permitAssignRole = EntityPermissions.hasPermission('UserRole.MAP');
        vm.permitAssignClearance = EntityPermissions.hasPermission('UserClearance.MAP');
        workflowSharedService.viewFrom = 'userRole';
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.EntityOwnerTypeId=vm.globalValues.getEntityOwnerType().id;
        vm.userBranch=vm.globalValues.getBranch();
        $scope.refresh=refresh;
        
        logScreenAccess();
        loadAll();
        
        function loadAll() {
        	if(vm.userEntityOwnerType=='Branch'){
        		User.getUsersByBranch({"branchId":vm.globalValues.getBranchId()},onGetUsersSuccess,onGetUsersError);
        		
        	}
        	else{
        		 User.query(function(result) {
                     vm.users = result;
                    
                     
                 });
        	}
           
        }
        function onGetUsersSuccess(result)
        {
        	vm.users=result;
        	
        }
        
        
        function onGetUsersError()
        {
        	
        }
        
        vm.launch=launch;
        function launch() {
        	$state.go("manage-users.new");
        }
        
        /*vm.screenLog = function screenLog(entity,entityRecordId,action){
        	ScreenLog.screenLog({'entityName': entity, 'entityRecordId': entityRecordId, 'action': action});
        }*/
        function logScreenAccess(){
        	ScreenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.USER_MANAGEMENT,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
    }
})();
