(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .constant('errorConstants', (function() {
            var problemBaseUrl = 'http://www.jhipster.tech/problem';
            return {
                EMAIL_ALREADY_USED_TYPE: problemBaseUrl + '/email-already-used',
                LOGIN_ALREADY_USED_TYPE: problemBaseUrl + '/login-already-used',
                EMAIL_NOT_FOUND_TYPE: problemBaseUrl + '/email-not-found',
                MOBILE_ALREADY_USED_TYPE: problemBaseUrl + '/mobile-already-used',
                USER_NOT_LOCKED_TYPE: problemBaseUrl + '/user-not-locked',
                USER_LOCKED_TYPE: problemBaseUrl + '/user-locked',
                EMAIL_OR_MOBILE_NOT_FOUND_TYPE: problemBaseUrl + '/email-or-mobile-not-found',
                PREVIOUS_PASSWORD_CANNOT_BE_USED_TYPE: problemBaseUrl + '/previous-password-cannot-be-used',
                PASSWORD_CANNOT_BE_CHANGED_WITH_IN_HOURS_TYPE: problemBaseUrl + '/password-cannot-be-changed-with-in-hours',
                PASSWORD_EXPIRED_TYPE: problemBaseUrl + '/password-expired',
                USER_BLOCKED_TYPE: problemBaseUrl + '/user-blocked',
                PASSWORD_SYSTEM_GENERATED_TYPE: problemBaseUrl + '/password-system-generated',
                TRADING_NOT_ACTIVATED_TYPE: problemBaseUrl + '/trading-not-activated',
                INVALID_OTP_TYPE: problemBaseUrl + '/invalid-otp',
                USER_SUSPENDED_TYPE :  problemBaseUrl + '/user-suspended',
                USER_ENTITY_SUSPENDED_TYPE :  problemBaseUrl + '/user-entity-suspended',
                NO_ACTIVE_BANK_RELATIONSHIP :  problemBaseUrl + '/no-active-bank-relationship',
//                EXCEEDS_PERMISSIBLE_USERS_COUNT_TYPE: problemBaseUrl + '/exceeds-permissible-users-count',
                PASSWORD_CONTAIN_LOGIN_TYPE: problemBaseUrl + '/password-contain-login',
                INCORRECT_PASSWORD_TYPE: problemBaseUrl + '/incorrect-password',
                USER_DORMANT_TYPE: problemBaseUrl + '/user-dormant',
                USER_DELINKED_TYPE: problemBaseUrl + '/user-entity-delinked',
                EMAIL_SEND_ATTEMPTS_BREACHED: problemBaseUrl + "/email-send-attempts-breached",
                USERNAME_NOT_FOUND : problemBaseUrl + "/user-name-not-found"
            }
        })());
})();
