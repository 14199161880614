(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Password', Password);

    Password.$inject = ['$resource'];

    function Password($resource) {
        var service = $resource('api/account/change-password', {}, {
        	'save': {
            	method: 'POST',
//            	params: {login: '@login', loginpassword:'@loginpassword', password: '@password'},
            	url: 'api/account/change-password',
            	
            	transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
            }
        });

        return service;
    }
})();
