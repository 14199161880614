(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GlobalApprovalPolicyDeleteController',GlobalApprovalPolicyDeleteController);

    GlobalApprovalPolicyDeleteController.$inject = ['$uibModalInstance', 'entity', 'GlobalApprovalPolicy', 'GlobalValues', 'ScreenLog','$scope'];

    function GlobalApprovalPolicyDeleteController($uibModalInstance, entity, GlobalApprovalPolicy, GlobalValues, ScreenLog,$scope) {
        var vm = this;

        vm.approvalPolicyDTO = entity;
    	//ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': vm.approvalPolicyDTO.id, 'action': 'DELETE'});
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.clicked=false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	GlobalApprovalPolicy.delete({"orgId":GlobalValues.getOrgId(),id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
