(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ArrayUtils', ArrayUtils);

    ArrayUtils.$inject = [];

    function ArrayUtils () {

        return {
            isEmpty: isEmpty,
            hasNull: hasNull,
            join:    join,
            hasEqualLength: hasEqualLength,
            concat: concat
        };

        /**
         * Check If Array is Blank
         * @param   array          Test array
         * @return  true or false   Boolean
         */
        function isEmpty(array) {
            return array == undefined || array == null || !angular.isArray(array) || array.length == 0;
        }

        /**
         * Check If Array has any null value
         * @param   array          Test array
         * @return  true or false   Boolean
         */
        function hasNull(array) {
            var hasNull = true;
            if(!isEmpty(array)) {
                hasNull = array.every(function(arrayElement) {
                    return (arrayElement != undefined && arrayElement != null);
                });
            }
            return !hasNull;
        }

        function join(array, objectPath, separator) {
            var elements = [];
            if(!isEmpty(array)) {
                for (var index = 0; index < array.length; index++) {
                    elements.push(_.get(array[index], objectPath, ""));
                }
            }
            return _.join(elements, separator);
        }

        function hasEqualLength(source, destination) {
            return !isEmpty(source) && !isEmpty(destination) && source.length === destination.length;
        }
        
        function concat(array, values) {
            return _.concat(array, values);
        }
    }
})();
