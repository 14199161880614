(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('order-upload', {
            parent: 'entity',
            params:{screen: 'OrderUpload'}, 
            url: '/order-upload',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.orderUpload.title" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-upload/order-upload.html',
                    controller: 'OrderUploadController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('orderUpload');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('order-upload-confirmation', {
            parent: 'order-upload',
            url: '/order-upload-confirmation',
            params: {orderDTOList:[]},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal,$rootScope) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-upload-confirmation-status/order-upload-confirmation-status.html',
                    controller: 'OrderUploadConfirmationStatusController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function(){
                        	
                        },
                        systemTime: ['Market',  function(Market ) {
         					return   Market.getCurrentDateTime().$promise;
                         }],
                         screenInfo : function(){
                            return "OrderConfirmation";
                        },
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('orderUploadConfirmationStatus');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('order-upload-status', {
            parent: 'order-upload',
            url: '/order-upload-status',
            params: {orderDTOList:[]},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal,$rootScope) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-upload-confirmation-status/order-upload-confirmation-status.html',
                    controller: 'OrderUploadConfirmationStatusController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function(){
                        	
                        },
                        systemTime: ['Market',  function(Market ) {
         					return   Market.getCurrentDateTime().$promise;
                         }],
                         screenInfo : function(){
                            return "OrderSubmission";
                        },
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('orderUploadConfirmationStatus');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
         
        
         
         };
    })();
