(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('TransactionCharges', TransactionCharges);

    TransactionCharges.$inject = ['$resource'];

    function TransactionCharges ($resource) {
        var resourceUrl =  'api/transaction-charges/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getTransactionChargesHeader':{
  				method: 'GET',
  				isArray:false,
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId',
  					 fromDate : '@fromDate', toDate : '@toDate'},  				
  				url: 'api/transaction-charges/get-transaction-charges-header',
  				 transformResponse: function(data, headers) {
  					if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                 }    
              },
             
              'exportTransactionChargesExcel': {
                  method: 'POST',
                  url: 'api/export-transaction-charges/excel',
                  responseType: 'arraybuffer',
                  transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }         
             },
              'exportTransactionChargesCsv': {
                  method: 'POST',
                  url: 'api/export-transaction-charges/csv',
                  responseType: 'arraybuffer',
                  transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }         
             }
        });
    }
})();
