(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'UserAccessView'
        })
        
        .controller('UserAccessViewController', UserAccessViewController);

    UserAccessViewController.$inject = ['$scope', '$state', 'UserAccessView', 'GlobalValues', 
    'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout','StaticLookUp'];

    function UserAccessViewController($scope, $state, UserAccessView , GlobalValues, 
    	workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,StaticLookUp) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.USERACCESSVIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.noOfRecordsPerPage1 = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page1 = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        
        vm.todayDate = new Date();
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.selectedEntityOwnerType = 'Bank';
        vm.policyType = 'SecurityPolicy';
        vm.ExpandOrCollapseFilterBy = false;
        vm.exportApprovalPolicyData = exportApprovalPolicyData;
        vm.exportSecurityPolicyData = exportSecurityPolicyData;
        vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
        vm.approvalPolicyfileName = "Approval_Policy_View_";
        vm.securityPolicyfileName = "Security_Policy_View_";
		vm.extension = "";
		vm.mimeType = "";
		vm.approvalPolicyQueryParamToExport = "";
		vm.securityPolicyQueryParamToExport = "";
		vm.selectedOrg= 0;
		vm.clicked=false;
		
		$scope.$watch('vm.selectedEntityOwnerType',function(value){
			vm.userStatusList = StaticLookUp.getUserStatusExceptDelinked({selectedEntity : value});
		});
        

        vm.ExpandOrCollapseUserAccessView=true;
        
        workflowSharedService.viewFrom = "UserAccessView";

        if(vm.entityOwnerType=='Bank'){
            vm.bank = vm.globalValues.getBank();
        }

        vm.getUserAccessView = getUserAccessView;

        //UserAccessView.getSecurityPolicyView({orgId: 2, branchId: 0, userId: 0, roleId: 0, themeId:0, actionId:0, userStatusId: 1}, onGetSecurityPolicyViewSuccess, onGetSecurityPolicyViewFailure);
        //UserAccessView.getApprovalPolicyView({orgId: 2, branchId: 0, userId: 0, roleId: 0, policyId:0, actionId:0}, onGetApprovalPolicyViewSuccess, onGetApprovalPolicyViewFailure);
        logScreenAccess();
        // loadCurrentBusinessDate();

        function logScreenAccess(){
        	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }

        function getUserAccessView(){
        	if(vm.policyType=='SecurityPolicy'){
        		getSecurityPolicyView();
        	}else if(vm.policyType=='ApprovalPolicy'){
        		getApprovalPolicyView();
        	}
        }

        function getSecurityPolicyView(){
			var orgId = 0;
			var branchId = 0;
			var userId = 0;
			var roleId = 0;
			var themeId = 0;
			var actionId = 0;
			var userStatusId=0;
			vm.securityPolicyQueryParamToExport = "";
			vm.selectedOrg= 0;
			
			if(vm.selectedEntityOwnerType=='Bank'){
				var orgId = vm.bank.id;
				if(vm.branch !=null){
					var branchId = vm.branch.id;
				}
			}else{
			    var orgId = vm.customer.id;
			}
			
			vm.selectedOrg= orgId;
			     
			
			if(vm.user !=null){
			    var userId = vm.user.id;
			}
			
			if(vm.role!=null){
			    var roleId = vm.role.id
			}
			
			if (vm.theme != null){
			    var themeId = vm.theme.id
			}
			
			if(vm.action != null){
			    var actionId = vm.action.id
			}
			
			if(vm.userStatus != null){
			    var userStatusId = vm.userStatus.id
			}
			
			var queryParam = prepareQueryParamString();
			vm.securityPolicyQueryParamToExport = angular.copy(queryParam);
			// parameters to be copied to vm variables that are used when user export the grid
			//copySecurityPolciyParameters(entityOwnerType, orgId, branchId, userId, roleId, themeId, actionId, userStatusId);
			
			UserAccessView.getSecurityPolicyView({orgId: orgId, branchId: branchId, userId: userId, roleId: roleId, themeId: themeId, actionId:actionId, userStatusId: userStatusId, queryParam:queryParam}, onGetSecurityPolicyViewSuccess, onGetSecurityPolicyViewFailure);
        }
		
		function onGetSecurityPolicyViewSuccess(result){
			vm.securityPolicies = result
			vm.clicked=false;
			console.log(vm.securityPolicies);
		}
		
		function onGetSecurityPolicyViewFailure(){
			vm.clicked=false;
		}
		
		function getApprovalPolicyView(){
			var orgId = 0;
			var branchId = 0;
			var userId = 0;
			var roleId = 0;
			var policyId = 0;
			var actionId = 0;
			vm.approvalPolicyQueryParamToExport ="";
			vm.selectedOrg= 0;
			
			if(vm.selectedEntityOwnerType=='Bank'){
				var orgId = vm.bank.id;
				if(vm.branch !=null){
				     var branchId = vm.branch.id;
				}
			}else{
				var orgId = vm.customer.id;
			}
			vm.selectedOrg= orgId;
						
			if(vm.user !=null){
			    var userId = vm.user.id;
			}
			
			if(vm.role!=null){
			    var roleId = vm.role.id
			}
			
			if (vm.policy != null){
			    var policyId = vm.policy.id
			}
			
			if(vm.action != null){
			    var actionId = vm.action.id
			}

			var queryParam = prepareQueryParamString();
			vm.approvalPolicyQueryParamToExport = angular.copy(queryParam);
			// parameters to be copied to vm variables that are used when user export the grid
			//copyApprovalPolicyParameters(entityOwnerType, orgId, branchId, userId, roleId, themeId, actionId);
			
			UserAccessView.getApprovalPolicyView({orgId: orgId, branchId: branchId, userId: userId, roleId: roleId, policyId:policyId, actionId:actionId, queryParam:queryParam}, onGetApprovalPolicyViewSuccess, onGetApprovalPolicyViewFailure);
		}
		
		function onGetApprovalPolicyViewSuccess(result){
			vm.approvalPolicies = result
			vm.clicked=false;
			console.log(vm.approvalPolicies);
		}
		
		function onGetApprovalPolicyViewFailure(){
			vm.clicked=false;
		}

		function prepareQueryParamString(){
			var queryParam = "";

			queryParam = "Entity Owner Type:";
			if(vm.selectedEntityOwnerType == 'Bank'){
				queryParam = queryParam + "BANK";
			}else{
				queryParam = queryParam + "MULTI-USER CUSTOMER";
			}
			

			if(vm.selectedEntityOwnerType=='Bank'){
				queryParam = queryParam + "; Bank:";
				queryParam = queryParam + vm.bank.bankName;
				queryParam = queryParam + "; Branch:";
				if(vm.branch!=null && vm.branch!=undefined){
					queryParam = queryParam + vm.branch.branchName;
				}else{
					queryParam = queryParam + "All";
				}
			}else{
				queryParam = queryParam + "; Customer:";
				queryParam = queryParam + vm.customer.customerName;
			}

			queryParam = queryParam + "; Policy Type:";
			if(vm.policyType == 'SecurityPolicy'){
				queryParam = queryParam + "SECURITY POLICY";
			}else{
				queryParam = queryParam + "APPROVAL POLICY";
			}
			

			queryParam = queryParam + "; User Login:";
			if(vm.user!=null && vm.user!=undefined){
				queryParam = queryParam + vm.user.login;
			}else{
				queryParam = queryParam + "All";
			}

			queryParam = queryParam + "; Role:";
			if(vm.role!=null && vm.role!=undefined){
				queryParam = queryParam + vm.role.name;
			}else{
				queryParam = queryParam + "All";
			}

			if(vm.policyType=='SecurityPolicy'){
				queryParam = queryParam + "; Theme:";
				if(vm.theme!=null && vm.theme!=undefined){
					queryParam = queryParam + vm.theme.shortName;
				}else{
					queryParam = queryParam + "All";
				}
			}else{
				queryParam = queryParam + "; Approval Policy:";
				if(vm.policy!=null && vm.policy!=undefined){
					queryParam = queryParam + vm.policy.shortName;
				}else{
					queryParam = queryParam + "All";
				}
			}

			queryParam = queryParam + "; Action:";
			if(vm.action!=null && vm.action!=undefined){
				queryParam = queryParam + vm.action.shortName;
			}else{
				queryParam = queryParam + "All";
			}

			if(vm.policyType=='SecurityPolicy'){
				queryParam = queryParam + "; User Status:";
				if(vm.userStatus!=null && vm.userStatus!=undefined){
					queryParam = queryParam + vm.userStatus.displayName;
				}else{
					queryParam = queryParam + "All";
				}
			}

			return queryParam;
		}

		function copyApprovalPolicyParameters(){

		}
		
		function exportApprovalPolicyData(fileType){

			
			if( vm.approvalPolicies !=null &&  vm.approvalPolicies != undefined && vm.approvalPolicies.length > 0){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv';
					vm.extension =  ".csv";  
					vm.baseString =   vm.approvalPolicyfileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension;
					UserAccessView.exportApprovalPolicyCsv({ orgId: vm.selectedOrg, queryParam:vm.approvalPolicyQueryParamToExport},vm.approvalPolicies, processResponse );
				}
				else if(fileType == 'xls'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.extension = ".xls";  
					vm.baseString =   vm.approvalPolicyfileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 

					UserAccessView.exportApprovalPolicyExcel({ orgId: vm.selectedOrg, queryParam:vm.approvalPolicyQueryParamToExport},vm.approvalPolicies, processResponse);
				}
				

			}
		}
		
		function exportSecurityPolicyData(fileType){

			
			if( vm.securityPolicies !=null &&  vm.securityPolicies != undefined && vm.securityPolicies.length > 0){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv';
					vm.extension =  ".csv";  
					vm.baseString =   vm.securityPolicyfileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension;
					UserAccessView.exportSecurityPolicyCsv({ orgId: vm.selectedOrg, queryParam:vm.securityPolicyQueryParamToExport},vm.securityPolicies, processResponse );
				}
				else if(fileType == 'xls'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.extension = ".xls";  
					vm.baseString =   vm.securityPolicyfileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 

					UserAccessView.exportSecurityPolicyExcel({ orgId: vm.selectedOrg, queryParam:vm.securityPolicyQueryParamToExport},vm.securityPolicies, processResponse);
				}
				

			}
		}
		
		function processResponse(result){        	  
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			
			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}

		vm.getLength = function getLength(approvalPolicy){
			var len =0;
			angular.forEach(approvalPolicy.approvalPolicyViewRoleDetailsDTOList, function(value){
				angular.forEach(value.approvalPolicyViewDetailsDTOList, function(value1){
					len = len + 1;
				})
			})
			return len;
		}

       
    }
})();
