(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('SessionLogController', SessionLogController);

    SessionLogController.$inject = ['$scope', '$state', 'workflowSharedService','$rootScope','MessageBox','GlobalValues','EntityPermissions','$filter', 'entity','DateUtils','SessionLog','ScreenLog', 'entityConstants','AlertService'];

    function SessionLogController($scope, $state, workflowSharedService, $rootScope, MessageBox, GlobalValues, EntityPermissions,$filter, entity,DateUtils,SessionLog,ScreenLog,entityConstants,AlertService) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.getSessionLogDetails=getSessionLogDetails;
        vm.todayDate=new Date();
        vm.screenLog=ScreenLog;
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.userListValue={};
        vm.ExpandOrCollapseSessionLog = true;
        workflowSharedService.viewFrom = 'userRole';
        vm.valid=false;
		vm.clicked=false;

        vm.historicalOrCurrent = 'Current';
        
		vm.reportDate  = DateUtils.convertDateTimeFromServer(vm.todayDate);
		vm.yesterdayDate = angular.copy(vm.reportDate);
		vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
		vm.fromDate = angular.copy(vm.reportDate);
		vm.fromDate.setDate(vm.fromDate.getDate() -1);
	    vm.toDate = angular.copy(vm.reportDate);
	    vm.toDate.setDate(vm.toDate.getDate() -1);

        /*
         * Get details for session log given userId, from and to date
         */
        logScreenAccess();
        
        function getQueryParamsValue(){
        	vm.queryParams = "";
        	if(vm.userEntityOwnerType == 'Clearcorp'){
        		if(vm.selectedEntityOwnerType == 'Bank'){
        			vm.queryParams = 'Entity Owner Type : BANK;';
        			vm.queryParams = vm.queryParams +'Bank : '+ vm.bankValue.shortName +';';
        		}
        		else if(vm.selectedEntityOwnerType == 'Branch'){
        			vm.queryParams = 'Entity Owner Type : BRANCH;';
        			vm.queryParams = vm.queryParams +'Bank : '+ vm.bankValue.shortName +';'+ 'Branch : ' + vm.branchValue.shortName + ';';
        		}
        		else if(vm.selectedEntityOwnerType == 'MultiUserCustomer'){
        			vm.queryParams = 'Entity Owner Type : MULTIUSERCUSTOMER;';
        			vm.queryParams = vm.queryParams +'Bank : '+ vm.bankValue.shortName +';'+ 'Branch : ' + vm.branchValue.shortName + ';'+'Customer : ' + vm.customerValue.customerCode + ';';
        		}
        		else if(vm.selectedEntityOwnerType == 'SingleUserCustomer'){
        			vm.queryParams = 'Entity Owner Type : SINGLEUSERCUSTOMER;';
        			vm.queryParams = vm.queryParams +'Bank : '+ vm.bankValue.shortName +';'+ 'Branch : ' + vm.branchValue.shortName + ';'+'Customer : ' + vm.customerValue.customerCode + ';';
        		}else{
        			vm.queryParams = 'Entity Owner Type : CLEARCORP;';
        		}

        		if(vm.userValue != null){
        			vm.queryParams = vm.queryParams + 'User :  ' + vm.userValue.login + ';'
        		}else{
        			vm.queryParams = vm.queryParams + 'User : All;'
        		}
        		vm.queryParams = vm.queryParams + 'From Date : ' + $filter('date')(vm.fromDate, "dd-MMM-yyyy") + ';';
        		vm.queryParams = vm.queryParams + 'To Date : ' + $filter('date')(vm.toDate, "dd-MMM-yyyy");
        	}
        	else{
        		if(vm.user!=undefined && vm.user!=null){
        			vm.queryParams = vm.queryParams + 'Login Name :  ' + vm.user.login + ';'
        		}else{
        			vm.queryParams = vm.queryParams + 'Login Name : All;'
        		}
        		vm.queryParams = vm.queryParams + 'From Date : ' + $filter('date')(vm.fromDate, "dd-MMM-yyyy") + ';';
        		vm.queryParams = vm.queryParams + 'To Date : ' + $filter('date')(vm.toDate, "dd-MMM-yyyy");
        	}
        }

        
        
        function getInitialRequirements(){
        	if(vm.userEntityOwnerType == 'Clearcorp'){
        		if(vm.selectedEntityOwnerType == 'Bank' || vm.selectedEntityOwnerType == 'Branch'){
        			vm.organisationId = vm.bankValue.id; 
        		}else if(vm.selectedEntityOwnerType == 'MultiUserCustomer' || vm.selectedEntityOwnerType == 'SingleUserCustomer'){
        			vm.organisationId = vm.customerValue.id;
        		}else{
        			vm.organisationId = vm.globalValues.getOrganisation().id;
        		}
        		if(vm.selectedEntityOwnerType == 'Branch'){
        			vm.branchId = vm.branchValue.id;
        		}else{
        			vm.branchId = 0;
        		}
        		if(vm.userListValue == null || vm.userListValue == undefined || vm.userListValue.length == 0){
        			vm.userListValue.push(-1);
        		}
        	}else{
        		vm.userListValue = [];
        		vm.organisationId = vm.globalValues.getOrganisation().id;
        		if(vm.globalValues.getBranch() != null){
        			vm.branchId = vm.globalValues.getBranch().id;
        		}else{
        			vm.branchId = 0;
        		}
        		if(vm.user!=undefined && vm.user!=null){
        			var userId = vm.user.id;
        			vm.userListValue.push(userId);
        			
        		}else{
        			vm.userListValue.push(-1);
        		}
        		
        	}
        	getQueryParamsValue();
        }
        function getSessionLogDetails()
        {   
        	vm.sessionLogDetails = [];
        	if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.SessionLog.futureFromDate'));
    			vm.valid=false;
    		}
    		else if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.toDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.SessionLog.greaterFromDate'));
    			vm.valid=false;
    		}else if(vm.historicalOrCurrent != 'Current' && moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.SessionLog.greaterToDate'));
    			vm.valid=false;
    		}
    		else{
				vm.clicked=true;
    			getInitialRequirements();
    			vm.valid=true;
    			var from=$filter('date')(vm.fromDate, "yyyy-MM-dd");
        		var to=$filter('date')(vm.toDate, "yyyy-MM-dd");
        		var userId = 0;
        		vm.selectedHistoryOrCurrent = angular.copy(vm.historicalOrCurrent);
        		
        		
        		if(vm.historicalOrCurrent == 'Current'){
	    			from = $filter('date')(vm.todayDate, "yyyy-MM-dd");
	        		to = $filter('date')(vm.todayDate, "yyyy-MM-dd");
        			SessionLog.getSessionLogDetails({"userIds":vm.userListValue,"from":from,"to":to,"queryParams":vm.queryParams,"branchId":vm.branchId,"orgId":vm.organisationId},onGetSessionLogSuccess,onGetSessionLogError);
        		}else{
        			SessionLog.getSessionLogDetailsFromArchive({"userIds":vm.userListValue,"from":from,"to":to,"queryParams":vm.queryParams,"branchId":vm.branchId,"orgId":vm.organisationId},onGetSessionLogSuccess,onGetSessionLogError);
        		}
    		}
        }
        //vm.fromDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        //vm.toDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        
        function onGetSessionLogSuccess(result)
        {
        	
        	vm.sessionLogDetails=result;
			vm.clicked=false;
        	
        }
        
        
        function onGetSessionLogError()
        {
        	vm.clicked=false;
        }
        
        function onGetSessionLogDetailsSuccess(result)
        {
        	
        	vm.sessionLogDetails=result;
        	
        }
        
        
        function onGetSessionLogDetailsError()
        {
        	
        }
        
        function logScreenAccess(){
           	ScreenLog.screenLog(  entityConstants.ENTITY_SHORT_NAME.SESSION_LOG_REPORT,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }

     }
})();
