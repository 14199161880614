(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        })
        
        .controller('ExecuteAlertNotificationsController', ExecuteAlertNotificationsController);

    ExecuteAlertNotificationsController.$inject = ['$scope', '$state', '$filter', 'workflowSharedService', 'ExecuteAlertNotifications', 'GlobalValues', 'EntityPermissions', 'AlertConfiguration'];

    function ExecuteAlertNotificationsController($scope, $state, $filter, workflowSharedService, ExecuteAlertNotifications, GlobalValues, EntityPermissions, AlertConfiguration) {
        var vm = this;
        vm.todayDate=new Date();
        vm.executeDate = vm.todayDate;
        vm.executeAllOrSpecific = 'all';
        vm.eventTypes = AlertConfiguration.getEventTypes();
        
        vm.corporates = ExecuteAlertNotifications.getAllBanks();

        vm.getBranchForCorporate = function getBranchForCorporate(corporate){
        	vm.branches = ExecuteAlertNotifications.getAllBranches({'bankId':corporate.id})
        }
        
        loadAll();
        
        function loadAll() {
        	
        }
        
        vm.executeAlertNotifications = function executeAlertNotifications(){
            var executeDate=$filter('date')(new Date(vm.executeDate),'dd-MMM-yyyy');
        	if(vm.executeAllOrSpecific=='specific'){
        		ExecuteAlertNotifications.executeAlertsForBUAndEvents({'executeDate':executeDate, 'corpId': vm.corporate.id, 'branchId': vm.branch.id, 'eventId': vm.eventType.id});
        	} 
        	else{
        		ExecuteAlertNotifications.execute({'executeDate':executeDate});
        	}
        }
        
        
    }
})();
