(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('TacViewForEntitiesUtility', TacViewForEntitiesUtility);

    TacViewForEntitiesUtility.$inject = ['$rootScope', 'TACViewForEntitiesService','FileUtil', 'CustomerTACUploadByBank','$filter','MessageBox'];

    function TacViewForEntitiesUtility ($rootScope, TACViewForEntitiesService,FileUtil, CustomerTACUploadByBank,$filter,MessageBox ) {
    	var seletedFileName ="";
    	var messageBoxName = "";
        var service = {
        		loadAllTacAgreedDetails: loadAllTacAgreedDetails ,
        		previewTAC : previewTAC,
        		previewTACForArray : previewTACForArray,
        		onGetCcilTacForBank : onGetCcilTacForBank,
        		downloadTAC : downloadTAC,
        		downloadTACForArray : downloadTACForArray,
        };

        return service;

        function loadAllTacAgreedDetails(loggedinEntityOwnerType,organisationId ){
            
	        if (loggedinEntityOwnerType == 'Bank'){
	        	
	        	TACViewForEntitiesService.getBankTacAgreedDetails({"bankId" : organisationId}, tacAgreedDetailsSuccess, tacAgreedDetailsError);
	       
	        }else if(loggedinEntityOwnerType == 'MultiUserCustomer' || loggedinEntityOwnerType == 'SingleUserCustomer'){
	        	
	        	TACViewForEntitiesService.getCustomerTacAgreedDetails({"customerId" : organisationId}, tacAgreedDetailsSuccess, tacAgreedDetailsError);
	        }
        }
        function tacAgreedDetailsSuccess(result){
        	$rootScope.tacList  = result;
        }
        function tacAgreedDetailsError(error){
        	console.log("error in fetching TAC agreed details")
        }
        
        function previewTACForArray(arrayBuffer, pdfFileName){
        	//var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			//var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
        	var file = new Blob([arrayBuffer], {type: 'application/pdf'});
      		var filename = pdfFileName;
            var fileURL = URL.createObjectURL(file);
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            }
            else {
            	//window.open(fileURL, filename, "width=700,height=700,"+"top="+y+", left="+x);
               window.open(fileURL, filename, "fullscreen=yes");   
            	//window.open('assets/data/forex_trading_tutorial5.pdf', 'pdf_test.pdf');
            	/*var link = document.createElement("a");   
            	link.href =  fileURL;      
  	    	    link.download =filename;
  	    	    document.body.appendChild(link);
  	    	    link.click();
  	    	    document.body.removeChild(link);*/
            }
        }
        function downloadTACForArray(arrayBuffer, pdfFileName){
        	//var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			//var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
        	var file = new Blob([arrayBuffer], {type: 'application/pdf'});
      		var filename = pdfFileName;
            var fileURL = URL.createObjectURL(file);
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            }
            else {            
            	var link = document.createElement("a");   
            	link.href =  fileURL;      
  	    	    link.download =filename;
  	    	    document.body.appendChild(link);
  	    	    link.click();
  	    	    document.body.removeChild(link);
            }
        }
        function previewTAC(fileInbase64String, pdfFileName){
        	
        	var arrayBuffer =  FileUtil.base64ToArrayBuffer(fileInbase64String);
        	previewTACForArray(arrayBuffer, pdfFileName);
        	
        }
        function downloadTAC(fileInbase64String, pdfFileName){
        	
        	var arrayBuffer =  FileUtil.base64ToArrayBuffer(fileInbase64String);
        	downloadTACForArray(arrayBuffer, pdfFileName);
        	
        }
        
      /*  function onGetCcilTacForBank(tacList,screenName){
        	var tacAgreedDetail = null; 
        	if(tacList != null  && tacList != undefined &&  tacList !=  '' && tacList.length > 0 ){
        		tacAgreedDetail = tacList[0];
        	}        	
        	checkAndOpenTheTACFile(tacAgreedDetail,screenName)
        }
        
        function checkAndOpenTheTACFile(tacAgreedDetail,screenName){
        	//TODO - check for filename 
        	seletedFileName = tacAgreedDetail.fileName;
        	// if ccil has not uploaded a tac for memebr then do nothing
        	if(tacAgreedDetail == null){
        		//do nothing
        	}// if member has not agreed to the latest tac
        	else if( tacAgreedDetail.isAgreed == 'No'){
        		
       			MessageBox.openMessageBox('Alert',$filter('translate')('error.tacViewForEntities.bank.viewAgreedTAC') , 'Ok', screenName);
             
       		}else{
       			//call the service to fetch the merged details file content  if memebr has agreed to the latest tac     			
       			ClearcorpTACUpload.getTermsAndConditionsMerged(tacAgreedDetail, onMergeSuccess, onMergeError);
       			
       		}
        }

		function onMergeSuccess (result) {
			
			previewTACForArray(result.data, seletedFileName);	
		}
	
		function onMergeError () {
	
		}*/
        
        function onGetCcilTacForBank(orgId,screenName){
        	messageBoxName = screenName;
        	CustomerTACUploadByBank.getAgreedTacForBank({orgId:orgId},onGetTacSuccess,onGetTacFailure)
        }
        
        function onGetTacSuccess(result){

			if(result != null && result != undefined && result.tacMaster != null && result.tacMaster != undefined ){
				//previewTAC(result.tacMaster.tacFiles.fileContent, result.tacMaster.tacFiles.fileName);
				previewTAC(result.tacMaster.tacFiles.fileContent, "Clearcorp's Terms and Conditions for Member.pdf");
			}else{
				MessageBox.openMessageBox('Alert',$filter('translate')('error.tacViewForEntities.bank.viewAgreedTAC') , 'Ok', messageBoxName);
	             
			}
		}

		function onGetTacFailure(error){

		}
        
    }
})();
