(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PasswordPolicyController',PasswordPolicyController);

    PasswordPolicyController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues','ScreenLog', 'entityConstants','$filter', '$rootScope','$scope'];

    function PasswordPolicyController($uibModalInstance,$stateParams, GlobalValues, ScreenLog, entityConstants, $filter, $rootScope, $scope) {
    	var vm = this;
        vm.clear = clear;
        vm.theme = $stateParams.theme;
        vm.printPage = printPage;
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function printPage() {
      	  var printContents = document.getElementById("passwordPolicy").innerHTML;
      	  var popupWin = window.open('', '_blank', 'fullscreen=yes');
          popupWin.opener=null;
      	  popupWin.document.open();
      	  popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
      	  popupWin.document.close();
      	} 
        
        
    }
})();
