(function() {
	'use strict';

	angular.module('gatewayApp')

	.controller('LimitsDialogNewController', LimitsDialogNewController);

	LimitsDialogNewController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','LimitSettings', 
		'DateUtils', 'ScreenLog','entityConstants', 'AlertService', '$filter', 'Workflow','entity','StaticLookUp','MessageBox', '$stateParams', 'Market',
		'DealingAdminApplicationWideSettings', 'DefaultMarket', '$timeout'];

	function LimitsDialogNewController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,LimitSettings,
			DateUtils,ScreenLog,entityConstants,AlertService,$filter, Workflow,entity,StaticLookUp,MessageBox,$stateParams,Market,
			DealingAdminApplicationWideSettings, DefaultMarket, $timeout) {
		var vm=this;
		var ALL_LIMIT_DETAIL_COUNT = 1;
		var INDIVIDUAL_LIMIT_DETAIL_COUNT = 3;
		var UPTOSPOTANDFORWARD_LIMIT_DETAIL_COUNT = 2;
		vm.actionType = "";
		 vm.globalValues = GlobalValues;
         vm.entityConstants = entityConstants;
         vm.screenLog = ScreenLog;
         vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
         vm.screenName = entityConstants.ENTITY_SHORT_NAME.LIMITSETTINGS;
         $scope.showOkButton = false;
         vm.limitHeader={};
         vm.minValue = 0;
		 vm.showHighLight = false;
		 vm.showHighLightText = false;
		 vm.showLimitInfo = false;
        
         vm.SETTLED_TRADES = 'SETTLEDTRADES';
         vm.DAILY = 'DAILY';
         vm.YES = 'Yes';
         vm.NO = 'No';     
         vm.MAXIMUMTENOR = 'MAXIMUMTENOR';
         vm.MAXIMUMVALUEDATE = 'MAXIMUMVALUEDATE';

		 var systemSettings = vm.globalValues.getSystemSettings();
         if(systemSettings!=null && systemSettings!=undefined){
        	 var now = moment();
        	 vm.momentBusinessDate = moment(systemSettings.businessDate, 'YYYY-MM-DD');        	
        	 vm.momentBusinessDate.set(
        			 { 	'hour':now.get('hour'),
        				'minute':now.get('minute'),
        				'second':now.get('second')
        				
        			 });
        	 vm.currentDate = vm.momentBusinessDate.toDate();
        	
        	 vm.effectiveToMinimumDate = systemSettings.businessDate;
        	// vm.tillDate = new Date(vm.currentDate);
        	// vm.tillDate.setDate(vm.currentDate.getDate() + parseInt(vm.maxLimitPeriod));
         }
         // set maximum limit period 
		 DealingAdminApplicationWideSettings.getConfigValueForConfigName({configName: 'MaximumLimitPeriod'}, function(result){
			vm.maxLimitPeriod = result.value;
			vm.tillDate = new Date(vm.currentDate);
			vm.tillDate.setDate(vm.currentDate.getDate() + parseInt(vm.maxLimitPeriod));
		})
         vm.customerLimit = {};
       
         
         vm.clear = clear;
         vm.resubmitLimitSettings= resubmitLimitSettings;
         vm.approveLimitSettings = approveLimitSettings;
         vm.rejectLimitSettings = rejectLimitSettings;
         vm.discardLimitSettings = discardLimitSettings;
         vm.save=save;
         vm.clicked = false;
         vm.showLimitUtilised = false;
         vm.showLimitUtilisedWithINR = false;
         vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
         vm.ExpandOrCollapseLimitUtilised=false;
		 vm.validateModifiedFields = validateModifiedFields;
		 vm.checkforModificationField = checkforModificationField;
		 vm.checkModifiedOfValues = checkModifiedOfValues;

         loadInstrumentType();
         loadCurrency();
         loadLimitTypes();
		 loadLimitInstrumentGroup();
		 loadLimitReinstateType();
		 loadLimitTenorType();
		 loadMaximumTenorType();
		 loadMaximumValueDate();
		 loadDefaultEffectiveToDatesFromConfig();
		 loadConfigurationSettings();
                  
         function loadInstrumentType(){
        	 StaticLookUp.getInstrumentType(istrumentLookUpSuccess, instrumentLookupFailure);
         }
         function istrumentLookUpSuccess(result){
        	 vm.instrumentTypes = result;
        	 angular.forEach( vm.instrumentTypes, function(item){
        		 if(item.shortName =='SPOT'){
        			 vm.spotType = item;
        		 }else if(item.shortName =='CASH'){
        			 vm.cashType = item;
        		 }else if (item.shortName =='TOM'){
        			 vm.tomType = item;
        		 }else if (item.shortName =='UptoSPOT'){
        			 vm.uptoSpotType = item;
        		 }else if (item.shortName =='Forward'){
        			 vm.frwdType = item;
        		 }
        	 });
        	
         }
         function instrumentLookupFailure(error){
        	 
         }        
         function loadCurrency(){
        	 StaticLookUp.getCurrency(currencySuccess,currencyFailure);
         }
         function currencySuccess(result){
        	 vm.currencies = result;
         }
         function currencyFailure(error){
        	 
         }
         function loadLimitTypes(){
        	 StaticLookUp.getLimitType(limitTypeSuccess, limitTypeFailure);
         }
         function limitTypeSuccess(result){
        	 vm.limitTypes = result;
        	 prepareLimitTypeToView();
         }
         function limitTypeFailure(error){
        	 
         }

		 function loadLimitInstrumentGroup(){
			 StaticLookUp.getLimitInstrumentGroupList(onGetLimitInstrumentGroupSuccess, onGetLimitInstrumentGroupFailure);
		 }

		 function onGetLimitInstrumentGroupSuccess(result){
			 vm.limitInstrumentGroupList = result;
			 if(vm.limitHeader.id==null || vm.limitHeader.id==undefined){
				 if(result!=null && result.length>0 && workflowSharedService.viewFrom=='limitSettings_add'){
					vm.limitInstrumentGroup = result[0];
                    vm.limitHeader.limitInstrumentGroup = result[0];
				 }
			 }
		 }

		 function onGetLimitInstrumentGroupFailure(){

		 }

		function loadLimitReinstateType(){
			StaticLookUp.getLimitReinstateTypeList(onGetLimitReinstateTypeSuccess, onGetLimitReinstateTypeFailure);
		}

		function onGetLimitReinstateTypeSuccess(result){
			vm.limitReinstateTypeList = result;
			if(vm.limitHeader.id==null || vm.limitHeader.id==undefined){
				if(result!=null && result.length>0){
				   if(workflowSharedService.viewFrom=='limitSettings_add'){
						vm.limitReinstateType = result[0];
                        vm.limitHeader.limitReinstateType = result[0];
				   }
				   vm.defaultLimitReinstateType = angular.copy(result[0]);
				}
			}
		}

		function onGetLimitReinstateTypeFailure(){

		}

		function loadLimitTenorType(){
			StaticLookUp.getLimitTenorTypeList(onGetLimitTenorTypeSuccess, onGetLimitTenorTypeFailure);
		}

		function onGetLimitTenorTypeSuccess(result){
			vm.limitTenorTypeList = result;
			if(vm.limitHeader.id==null || vm.limitHeader.id==undefined){
				if(result!=null && result.length>0){
					if(workflowSharedService.viewFrom=='limitSettings_add'){
						vm.limitTenorType = result[0];
                        vm.limitHeader.limitTenorType = result[0];
                        vm.limitHeader.maximumTenorValue = null;
                        vm.limitHeader.maximumTenorType = null;
                        vm.limitHeader.maximumValueDate = null;
					}
 				    vm.defaultLimitTenorType = angular.copy(result[0]);
				}
			}
		}

		function onGetLimitTenorTypeFailure(){

		}

		function loadMaximumTenorType(){
			vm.maximumTenorTypeList = ['Days', 'Months'];
			vm.maximumTenorType = 'Days';
		}

		function loadMaximumValueDate(){
			DefaultMarket.marketInstruments(onGetInstrumentSuccess, onGetInstrumentFailure);
		}

		function onGetInstrumentSuccess(result){
        	vm.instrumentTypes = result;
        	angular.forEach(vm.instrumentTypes, function(value){
        		if(value.instrumentCode=='SPOT'){
                    var spotNextDate = new Date(value.settlementDate);
                    spotNextDate.setDate(spotNextDate.getDate()+1);
					if(workflowSharedService.viewFrom=='limitSettings_add'){
						$timeout(function() {
							vm.maximumValueDate = angular.copy(spotNextDate);
						}, 100);
					}
                    vm.spotNextDate = angular.copy(spotNextDate);
        		}
        	})
        }
        
        function onGetInstrumentFailure(){
        	
        }

        $scope.setForm = function (form) {
            $scope.limitForm = form;
        }
		function prepareLimitInfoConent(value) {
			if(value!=null){
				vm.showLimitInfo=true;
				if(value=='GROSSBUY'){
					vm.limitTypeInfo = "Limit available to Buy only";
				}else if(value=='GROSSSELL'){
					vm.limitTypeInfo = "Limit available to Sell only";
				}else if(value=='GLOBALGROSS'){
					vm.limitTypeInfo = "TOTAL  limit available to both  Buy and Sell";
				}else{
					vm.limitTypeInfo = "NET limit available to both Buy and Sell";
				}
			}
			
		}
         
         $scope.$watch('vm.selectedLimitType' , function(value){
        	 if(vm.limitHeader!=null && vm.limitHeader!==undefined){
        		 if(value !=null && value!=undefined){
					vm.limitHeader.limitType = value.shortName;
				 }
        	 }
			 if(value!=null){
				prepareLimitInfoConent(value.shortName);
			 }
         });
		 $scope.$watch('vm.limitHeader.limitCurrency' , function(value){
			if((workflowSharedService.viewFrom == 'customer_limitSettings' || workflowSharedService.viewFrom == 'customer_limitSettings_checker'
			|| workflowSharedService.viewFrom == 'customer_limitSettings_maker'|| workflowSharedService.viewFrom == 'customer_limitSettings_resubmit' 
			|| workflowSharedService.viewFrom == 'customer_limitSettings_auditLog') && vm.limitHeader!=null && vm.limitHeader!==undefined){
				if(value.code=='USD'){
					vm.showLimitUtilisedWithINR = false;
				}else{
					vm.showLimitUtilisedWithINR = true;
				}
			}
		});

         $scope.$watch('vm.limitReinstateType' , function(value){
             if(vm.limitHeader!=null && vm.limitHeader!==undefined){
                 if(value !=null && value!=undefined){
                    vm.limitHeader.limitReinstateType = value;
                 }
             }
         });

		 vm.onChangeLimitTenorType =  function onChangeLimitTenorType(value){
			if(vm.limitHeader!=null && vm.limitHeader!==undefined){
				if(value !=null && value!=undefined){
				   vm.limitHeader.limitTenorType = value;
					if(value.shortName=='NA'){ 
						//if limit tenor type is 'NA', then clear fields related with other limit on tenor types
						vm.maximumTenorType = null;
						vm.maximumValueDate = null;
						vm.limitHeader.maximumTenorValue = null;
                        vm.limitHeader.maximumTenorType = null;
						if($scope.limitForm!=null && $scope.limitForm!=undefined){
							$scope.limitForm.$setValidity("txtMaxTenorValue" + "txtrequired", true);
							$scope.limitForm.$setValidity("dtpMaxValueDate" + "dtprequired", true);
						}
					}else if(value.shortName=='MAXIMUMTENOR'){
						//if limit tenor type is 'MAXIMUMTENOR', then clear maximum value date control
						$scope.limitForm.$setValidity("dtpMaxValueDate" + "dtprequired", true);
						vm.maximumTenorType = 'Days';
						vm.limitHeader.maximumTenorValue = null;
					}else{
						//else if limit tenor type is 'MAXIMUMVALUEDATE', then clear maximum tenor value control
						vm.maximumTenorType = null;
						vm.limitHeader.maximumTenorValue = null;
						if($scope.limitForm!=null && $scope.limitForm!=undefined){
							$scope.limitForm.$setValidity("txtMaxTenorValue" + "txtrequired", true);
						}
						vm.maximumValueDate = angular.copy(vm.spotNextDate);
					}
				}
			}
		 }

         $scope.$watch('vm.maximumTenorType' , function(value){
             if(vm.limitHeader!=null && vm.limitHeader!==undefined){
                 if(value !=null && value!=undefined){
                    vm.limitHeader.maximumTenorType = value;
                 }else{
                    vm.limitHeader.maximumTenorType = null;
                 }
             }
         });

         $scope.$watch('vm.maximumValueDate' , function(value){
             if(vm.limitHeader!=null && vm.limitHeader!==undefined){
                 if(value !=null && value!=undefined){
                    vm.limitHeader.maximumValueDate = value;
                 }else{
                    vm.limitHeader.maximumValueDate = null;
                 }
             }
         });

         $scope.$watchGroup(['vm.selectedInstrument', 'vm.limitInstrumentGroup'], function(value){
             if(vm.limitInstrumentGroup!=null && vm.limitInstrumentGroup.shortName=='SPOTWINDOW'){
                 if( vm.limitHeader!=null && vm.limitHeader!=undefined && vm.selectedInstrument == 'individual'){
                     if( document.getElementById("txtall")!=undefined &&  document.getElementById("txtall")!=null){
                        document.getElementById("txtall").value = "";
                     }
                     if( document.getElementById("txtuptospot")!=undefined &&  document.getElementById("txtuptospot")!=null){
                         document.getElementById("txtuptospot").value = "";
                     }
                     if( document.getElementById("txtforward")!=undefined &&  document.getElementById("txtforward")!=null){
                         document.getElementById("txtforward").value = "";
                     }
                     vm.allValue=null;
                     vm.uptoSpotValue = null;
                     vm.forwardValue = null;
                 }
                 if( vm.limitHeader!=null && vm.limitHeader!=undefined && vm.selectedInstrument == 'uptoSpot'){
                     if( document.getElementById("txtall")!=undefined &&  document.getElementById("txtall")!=null){
                        document.getElementById("txtall").value = "";
                     }
                     if( document.getElementById("txtspot")!=undefined &&  document.getElementById("txtspot")!=null){
                         document.getElementById("txtspot").value = "";
                     }
                     if( document.getElementById("txttom")!=undefined &&  document.getElementById("txttom")!=null){
                         document.getElementById("txttom").value = "";
                     }
                     if( document.getElementById("txtcash")!=undefined &&  document.getElementById("txtcash")!=null){
                         document.getElementById("txtcash").value = "";
                     }
                     if( document.getElementById("txtforward")!=undefined &&  document.getElementById("txtforward")!=null){
                         document.getElementById("txtforward").value = "";
                     }
                     vm.allValue=null;
                     vm.spotValue=null;
                     vm.tomValue=null;
                     vm.cashValue=null;
                     vm.forwardValue = null;
                 }
             }else if(vm.limitInstrumentGroup!=null && vm.limitInstrumentGroup.shortName=='FORWARD'){
                 if( document.getElementById("txtall")!=undefined &&  document.getElementById("txtall")!=null){
                    document.getElementById("txtall").value = "";
                 }
                 if( document.getElementById("txtspot")!=undefined &&  document.getElementById("txtspot")!=null){
                     document.getElementById("txtspot").value = "";
                 }
                 if( document.getElementById("txttom")!=undefined &&  document.getElementById("txttom")!=null){
                     document.getElementById("txttom").value = "";
                 }
                 if( document.getElementById("txtcash")!=undefined &&  document.getElementById("txtcash")!=null){
                     document.getElementById("txtcash").value = "";
                 }
                 if( document.getElementById("txtuptospot")!=undefined &&  document.getElementById("txtuptospot")!=null){
                     document.getElementById("txtuptospot").value = "";
                 }
                 vm.allValue=null;
                 vm.spotValue=null;
                 vm.tomValue=null;
                 vm.cashValue=null;
                 vm.uptoSpotValue=null;
             }else{
                 if( document.getElementById("txtspot")!=undefined &&  document.getElementById("txtspot")!=null){
                     document.getElementById("txtspot").value = "";
                 }
                 if( document.getElementById("txttom")!=undefined &&  document.getElementById("txttom")!=null){
                     document.getElementById("txttom").value = "";
                 }
                 if( document.getElementById("txtcash")!=undefined &&  document.getElementById("txtcash")!=null){
                     document.getElementById("txtcash").value = "";
                 }
                 if( document.getElementById("txtuptospot")!=undefined &&  document.getElementById("txtuptospot")!=null){
                     document.getElementById("txtuptospot").value = "";
                 }
                 if( document.getElementById("txtforward")!=undefined &&  document.getElementById("txtforward")!=null){
                     document.getElementById("txtforward").value = "";
                 }
                 vm.spotValue=null;
                 vm.tomValue=null;
                 vm.cashValue=null;
                 vm.uptoSpotValue=null;
                 vm.forwardValue=null;
             }
			 if(vm.limitInstrumentGroup!=null && workflowSharedService.viewFrom=='limitSettings_add'){
			 	prepareLimitDetailsToSaveToDB(vm.selectedInstrument, vm.limitInstrumentGroup.shortName);
			 }
         });
         
         $scope.$watch('vm.allValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.allValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.spotValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.spotValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.tomValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.tomValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.cashValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.cashValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.forwardValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.forwardValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.uptoSpotValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.uptoSpotValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         function prepareLimitTypeToView(){
        	
        	 if(vm.limitHeader!=null && vm.limitHeader!=undefined){
        		 angular.forEach(vm.limitTypes, function(item){
            		 if(item.shortName == vm.limitHeader.limitType ){
            			 vm.selectedLimitType = item;
            		 }
            	 })

				 //set limit instrument group
				 if(vm.limitHeader.limitInstrumentGroup!=null && vm.limitHeader.limitInstrumentGroup!=undefined){
					vm.limitInstrumentGroup = vm.limitHeader.limitInstrumentGroup;
                    var defaultReinstateId = "slt_inst_" + vm.limitHeader.limitInstrumentGroup.shortName;
                    if(document.getElementById(defaultReinstateId)!=null){
                        setTimeout(function(){document.getElementById(defaultReinstateId).click()});
                    }
                 }

				 //set limit reinstate type
				 if(vm.limitHeader.limitReinstateType!=null && vm.limitHeader.limitReinstateType!=undefined){
					vm.limitReinstateType = vm.limitHeader.limitReinstateType;
                    var defaultReinstateId = "slt_" + vm.limitHeader.limitReinstateType.shortName;
                    if(document.getElementById(defaultReinstateId)!=null){
                        setTimeout(function(){document.getElementById(defaultReinstateId).click()});
                    }
                 }

				 //set limit tenor type
                 if(vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType!=undefined){
					vm.limitTenorType = vm.limitHeader.limitTenorType;
                    var defaultTenorTypeId = "slt_tenor_" + vm.limitHeader.limitTenorType.shortName;
                    if(document.getElementById(defaultTenorTypeId)!=null){
                        setTimeout(function(){document.getElementById(defaultTenorTypeId).click()});
                    }
                    if(vm.limitHeader.limitTenorType.shortName!='MAXIMUMTENOR'){
                        vm.maximumTenorType = null;
                    }else{
                        vm.maximumTenorType = vm.limitHeader.maximumTenorType;
                    }
                 }

        	 }
        	
         }
         function checkInstrumentValue(instrumentValue){
        	 
        	 if(instrumentValue == "" || instrumentValue == undefined || instrumentValue == null){
        		 return 0.00;
        	 }else{
        		 return instrumentValue;
        	 }
         }
         function prepareLimitDetailsToSaveToDB(selectedInstrument, limitInstrumentGroup){
             if(limitInstrumentGroup=='SPOTWINDOW'){
                 if( vm.limitHeader!=null && vm.limitHeader!=undefined && selectedInstrument == 'individual'){
                     vm.limitHeader.limitSettingsDetailsList=[];
                     vm.limitHeader.limitSettingsDetailsList[0]={};
                     vm.limitHeader.limitSettingsDetailsList[0].instrumentType=vm.cashType;
                     vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue(vm.cashValue);
                     
                     vm.limitHeader.limitSettingsDetailsList[1]={};
                     vm.limitHeader.limitSettingsDetailsList[1].instrumentType=vm.tomType;
                     vm.limitHeader.limitSettingsDetailsList[1].value=checkInstrumentValue(vm.tomValue);
                     
                     vm.limitHeader.limitSettingsDetailsList[2]={};
                     vm.limitHeader.limitSettingsDetailsList[2].instrumentType=vm.spotType;
                     vm.limitHeader.limitSettingsDetailsList[2].value=checkInstrumentValue(vm.spotValue);
                 }else if( vm.limitHeader!=null && vm.limitHeader!=undefined && selectedInstrument == 'uptoSpot'){
                     vm.limitHeader.limitSettingsDetailsList=[];
                     vm.limitHeader.limitSettingsDetailsList[0]={};
                     vm.limitHeader.limitSettingsDetailsList[0].instrumentType=vm.uptoSpotType;
                     vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue(vm.uptoSpotValue);
                 }
             }else if(limitInstrumentGroup=='FORWARD'){
                if(vm.limitHeader!=null && vm.limitHeader!=undefined){
                    vm.limitHeader.limitSettingsDetailsList=[];
                    vm.limitHeader.limitSettingsDetailsList[0]={};
                    vm.limitHeader.limitSettingsDetailsList[0].instrumentType=vm.frwdType;
                    vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue( vm.forwardValue);
                }
             }else{
                if(vm.limitHeader!=null && vm.limitHeader!=undefined){
                    vm.limitHeader.limitSettingsDetailsList=[];
                    vm.limitHeader.limitSettingsDetailsList[0]={};
                    vm.limitHeader.limitSettingsDetailsList[0].instrumentType=null;
                    vm.limitHeader.limitSettingsDetailsList[0].value=checkInstrumentValue(vm.allValue);
                }
             }
         }
         function prepateLimitDetailsToView(){
        	 if( vm.limitHeader!=null && vm.limitHeader !=undefined && vm.limitHeader.limitSettingsDetailsList!=null 
                && vm.limitHeader.limitSettingsDetailsList!=undefined && vm.limitHeader.limitInstrumentGroup.shortName=='ALL'){
        		 vm.allValue = vm.limitHeader.limitSettingsDetailsList[0].value;
        		 if(vm.prevClose!=null && vm.prevClose!=undefined && vm.allValue !=null && vm.allValue !=undefined){
        			 vm.allValueInINR = (Math.round(parseFloat(vm.allValue)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
        	 else if(vm.limitHeader!=null && vm.limitHeader !=undefined && vm.limitHeader.limitSettingsDetailsList!=null
                && vm.limitHeader.limitSettingsDetailsList!=undefined && vm.limitHeader.limitInstrumentGroup.shortName=='SPOTWINDOW'){
                 if(vm.limitHeader.limitSettingsDetailsList.length == INDIVIDUAL_LIMIT_DETAIL_COUNT){
                     vm.selectedInstrument = "individual";
                     angular.forEach( vm.limitHeader.limitSettingsDetailsList, function(item){
                     if(item.instrumentType!=null && item.instrumentType!=undefined){
                         if(item.instrumentType.shortName =='CASH'){
                            vm.cashValue = item.value;
                             if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
                                 vm.cashValueInINR = (Math.round(parseFloat(vm.cashValue)*10000) / vm.prevClose) / 10000;
                             }
                        }else if(item.instrumentType.shortName =='TOM'){
                            vm.tomValue = item.value;
                            if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
                             vm.tomValueInINR = (Math.round(parseFloat(vm.tomValue)*10000) / vm.prevClose) / 10000;
                            }
                        }else if(item.instrumentType.shortName =='SPOT'){
                            vm.spotValue = item.value;
                            if(vm.prevClose!=null && vm.prevClose!=undefined && item.value!=null && item.value!=undefined){
                             vm.spotValueInINR = (Math.round(parseFloat(vm.spotValue)*10000) / vm.prevClose) / 10000;
                            }
                        }
                     }
                    });
                 }else{
                    vm.selectedInstrument = "uptoSpot";
                    angular.forEach( vm.limitHeader.limitSettingsDetailsList, function(item){
                         if(item.instrumentType!=null && item.instrumentType!=undefined){                       
                            if(item.instrumentType.shortName =='UptoSPOT'){
                                vm.uptoSpotValue = item.value;
                                if(vm.prevClose!=null && vm.prevClose!=undefined){
                                 vm.uptoSpotValueInINR = (Math.round(parseFloat(vm.uptoSpotValue)*10000) / vm.prevClose) / 10000;
                                }
                            }
                         }
                                         
                    });
                 }
        	 }else if(vm.limitHeader!=null && vm.limitHeader !=undefined && vm.limitHeader.limitSettingsDetailsList!=null 
                && vm.limitHeader.limitSettingsDetailsList!=undefined && vm.limitHeader.limitInstrumentGroup.shortName=='FORWARD'){
        		 angular.forEach( vm.limitHeader.limitSettingsDetailsList, function(item){
        			 if(item.instrumentType!=null && item.instrumentType!=undefined){        			 	
        			 	if(item.instrumentType.shortName =='Forward'){
        			 		vm.forwardValue = item.value;
        			 		if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 			vm.forwardValueInINR = (Math.round(parseFloat(vm.forwardValue)*10000) / vm.prevClose) / 10000;
        			 		}
        			 	}
        			 	
        			 }
        			 	  			 
        		 });
        	 }
         }
         function loadDefaultEffectiveToDatesFromConfig(){
        	 
			 //get default effective to date for spot window
			LimitSettings.getDefaultEffectiveToDate(function(result){
				vm.effecitveToDateForSpotWindow = DateUtils.convertDateTimeFromServer(result.effectiveToDate);
				if(workflowSharedService.viewFrom=='limitSettings_add' || 
                    (workflowSharedService.viewFrom=='create_customer_limitSettings_checker' 
                        && vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader.limitInstrumentGroup.shortName=='SPOTWINDOW' 
                        	&& ( vm.customerLimit.limitSettingsHeader.effectiveToDateTime ==null || vm.customerLimit.limitSettingsHeader.effectiveToDateTime ==undefined ) )){
					vm.effectiveToDate = angular.copy(vm.effecitveToDateForSpotWindow);
				}
			})
	
			//get default effective to date for forward or all window
			LimitSettings.getDefaultEffectiveToDateForForward(function(result){
				vm.effecitveToDateForForwards = DateUtils.convertDateTimeFromServer(result.effectiveToDate);
                if(workflowSharedService.viewFrom=='create_customer_limitSettings_checker' 
                        && vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader.limitInstrumentGroup.shortName!='SPOTWINDOW'
                        	&& ( vm.customerLimit.limitSettingsHeader.effectiveToDateTime ==null || vm.customerLimit.limitSettingsHeader.effectiveToDateTime ==undefined )){
                    vm.effectiveToDate = vm.effecitveToDateForForwards;
                }
			})
         }
		 
		 function loadDefaultEffectiveToDate(){
			 vm.effectiveToDate = angular.copy(vm.effecitveToDateForSpotWindow);
		 }

		 function loadConfigurationSettings(){
			 //load configuration settings to validate maximum tenor value 
			 //get confing settings - no. of active days for standard tenor
			DealingAdminApplicationWideSettings.getConfigValueForConfigName({configName: 'NoOfActiveDaysForStandardTenor'}, function(result){
				vm.noOfActiveDaysForStandardTenor = result.value;
			})

			//get confing settings - no. of active months for standard tenor
			DealingAdminApplicationWideSettings.getConfigValueForConfigName({configName: 'NoOfActiveMonthsForStandardTenor'}, function(result){
				vm.noOfActiveMonthsForStandardTenor = result.value;
			})
			
		 }

         //get max tenor date for validation
         StaticLookUp.getTenorsWithBrokenDate(onGetTenorSuccess, onGetTenorFailure);

         function onGetTenorSuccess(result){
            vm.tenors = result;
            if(vm.tenors!=null && vm.tenors!=undefined && vm.tenors.length>0){
                vm.maxTenorDate = new Date(vm.tenors[vm.tenors.length-1].settlementDate);
            }
         }

         function onGetTenorFailure(){

         }

		 function prepareDatesForView(){
        	 if(vm.limitHeader!=null && vm.limitHeader!=undefined){
        		vm.effectiveFromDate = DateUtils.convertDateTimeFromServer(vm.limitHeader.effectiveFromDateTime);
        	 	vm.effectiveToDate = DateUtils.convertDateTimeFromServer(vm.limitHeader.effectiveToDateTime);
                vm.maximumValueDate = DateUtils.convertLocalDateFromServer(vm.limitHeader.maximumValueDate);
        	 }
         }	
         function prepareDatesforServer(){
        	 vm.limitHeader.effectiveFromDateTime = vm.effectiveFromDate;        	
			 vm.limitHeader.effectiveToDateTime = new Date(vm.effectiveToDate);
        	 vm.limitHeader.effectiveToDateTime.setHours(23);
			 vm.limitHeader.effectiveToDateTime.setMinutes(59);
			 vm.limitHeader.effectiveToDateTime.setSeconds(59);
			 if(vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType.shortName=='MAXIMUMVALUEDATE'){
				vm.limitHeader.maximumValueDate = DateUtils.convertLocalDateToServer(vm.limitHeader.maximumValueDate);
			 }else{
				vm.limitHeader.maximumValueDate = null;
			 }
         }

		 if (workflowSharedService.viewFrom == 'limitSettings_add') {

			vm.limitHeader = {};
			vm.selectedInstrument = 'individual';
			vm.effectiveFromDate = vm.currentDate;
			loadDefaultEffectiveToDate();
			
	        $scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;		
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			vm.actionType="Add";
			var disableBranch=false;
			if (vm.loggedinEntityOwnerType == "Bank"){
	        	 disableBranch=false;
	        	 vm.limitHeader.bank = vm.globalValues.getBank();
	    			 
	    	 }else if(vm.loggedinEntityOwnerType == "Branch") {
	    		 disableBranch=true;
	    		
	    		 vm.limitHeader.branch = vm.globalValues.getBranch();
	    		 vm.limitHeader.bank = vm.limitHeader.branch.bank;
	    	 }
			enableControls(disableBranch,false,false,false,false);
			vm.disableReinstateField = false;
            vm.disableInstrumentGroupField = false;
            vm.disableUserRemarks = false;
            vm.showCustomerRemarks = false;
			
		} else if (workflowSharedService.viewFrom == 'limitSettings_edit') {
			
			vm.limitHeader = entity;
			
			prepateLimitDetailsToView();
			prepareDatesForView();
			vm.effectiveFromDate = vm.currentDate;
			prepareLimitTypeToView();
            LimitSettings.getLimitsAvailableForLimitInstrumentGroup({customerId : vm.limitHeader.customer.id , bankId : vm.limitHeader.bank.id, limitInstrumentGroupId : vm.limitHeader.limitInstrumentGroup.id }, function(result){
                vm.limitUtilisationDetails = result;
            })
            vm.showLimitUtilised = true;
            // if(vm.limitHeader.limitCurrency.code=='INR'){
            //      vm.showLimitUtilisedWithINR = true;
            // }
			vm.previousLimit = angular.copy(vm.limitHeader);
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			vm.actionType="Change";
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = false;
            vm.showCustomerRemarks = false;
		
        } else if (workflowSharedService.viewFrom == 'limitSettings_reset') {
            
            var limitHeader = angular.copy(entity);

            vm.limitHeader = {};
            vm.limitHeader.bank = limitHeader.bank;
            $timeout(function() {
                vm.limitHeader.branch = limitHeader.branch;
            }, 100);
            $timeout(function() {
                vm.limitHeader.customer = limitHeader.customer;
            }, 100);

            if($stateParams.resetInstrumentGroup=='SPOTWINDOW'){
                vm.selectedInstrument = 'individual';
            }
            vm.effectiveFromDate = vm.currentDate;
            loadDefaultEffectiveToDate();

            StaticLookUp.getLimitInstrumentGroupList(function(result){
                angular.forEach(result, function(value){
                    if(value.shortName==$stateParams.resetInstrumentGroup){
                        vm.limitHeader.limitInstrumentGroup = value;
                    }
                })
                //set limit instrument group
                $timeout(function() {
                    if(vm.limitHeader.limitInstrumentGroup!=null && vm.limitHeader.limitInstrumentGroup!=undefined){
                        vm.limitInstrumentGroup = vm.limitHeader.limitInstrumentGroup;
                        var defaultReinstateId = "slt_inst_" + vm.limitHeader.limitInstrumentGroup.shortName;
                        if(document.getElementById(defaultReinstateId)!=null){
                            setTimeout(function(){document.getElementById(defaultReinstateId).click()});
                        }
                    }
                }, 100);
            });

            
            $scope.showButton = true;
            $scope.showApproveRejectButton = false;
            $scope.showDiscardButton = false;       
            $scope.showResubmitButton = false;
            vm.disableForm = false;
            vm.actionType="Reset";
            var disableBranch=false;
            if (vm.loggedinEntityOwnerType == "Bank"){
                 disableBranch=true;
                 vm.limitHeader.bank = vm.globalValues.getBank();
                     
             }else if(vm.loggedinEntityOwnerType == "Branch") {
                 disableBranch=true;
                
                 vm.limitHeader.branch = vm.globalValues.getBranch();
                 vm.limitHeader.bank = vm.limitHeader.branch.bank;
             }
            enableControls(disableBranch,true,false,false,false);
            vm.disableReinstateField = false;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = false;
            vm.showCustomerRemarks = false;
        
		} else if (workflowSharedService.viewFrom == 'limitSettings_view') {
			
			vm.limitHeader = entity;
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.previousLimit = angular.copy(vm.limitHeader);
			$scope.showOkButton = true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = true;
			vm.actionType="View";
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = false;
		
		}
		else if (workflowSharedService.viewFrom == 'limitSettings_checker') {
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);			
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			vm.disableForm = true;
			vm.actionType=getActionType($rootScope.action);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = false;
		} else if (workflowSharedService.viewFrom == 'limitSettings_maker') {
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			vm.actionType=getActionType($rootScope.action);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = false;
		} else if (workflowSharedService.viewFrom == 'limitSettings_resubmit') {
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);
            //clear user remarks for resubmit
            vm.limitHeader.userRemarks = "";
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.screenLog.screenLog(vm.screenName, vm.limitHeader.id == null ? 0
					: vm.limitHeader.id,
					entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			vm.disableForm = false;
			$scope.showButton = false;
			$scope.showResubmitButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			vm.actionType=getActionType($rootScope.action);
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
            vm.disableInstrumentGroupField = false;
            if(vm.actionType=='Reset'){
                vm.disableInstrumentGroupField = true;
            }
            vm.disableUserRemarks = false;
            vm.showCustomerRemarks = false;
		} else if (workflowSharedService.viewFrom == 'limitSettings_auditLog') {
			var copyOfPayload = angular.copy($rootScope.payload);
			vm.limitHeader = angular.fromJson(copyOfPayload);
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.disableForm = true;
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			vm.actionType= getActionType($rootScope.actionName);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = false;
		}else if(workflowSharedService.viewFrom=='limit_import_auditlog'){
			var copyOfPayload = angular.copy($rootScope.payload); 
			var limitHeaderDTO = angular.fromJson(copyOfPayload);
			vm.limitHeader = limitHeaderDTO;
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.disableForm = true;
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			vm.actionType= getActionType($rootScope.actionName);
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = false;
        }
		else if (workflowSharedService.viewFrom == 'customer_limitSettings') {
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = entity;
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;		
            //clear existing customer remarks
            vm.limitHeader.customerRemarks = "";	
			//clear user remarks 
			vm.limitHeader.userRemarks = "";	
			LimitSettings.getLimitsAvailableForLimitInstrumentGroup({customerId : $stateParams.customerId , bankId :$stateParams.bankId, limitInstrumentGroupId: vm.limitHeader.limitInstrumentGroup.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			prepareDatesForView();
			vm.effectiveFromDate = vm.currentDate;
			prepareLimitTypeToView();
			ScreenLog.screenLog(vm.screenName,vm.limitHeader.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
			vm.limitHeader.effectiveFromDateTime = vm.currentDate;
			vm.previousLimit = angular.copy(vm.limitHeader);
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			vm.actionType="CustomerChange";
			enableControls(true,true,false,false,true,false);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = true;
		} 
		else if (workflowSharedService.viewFrom == 'customer_limitSettings_checker') {
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			vm.previousLimit = angular.copy(vm.limitHeader);
			vm.showHighLight =true;
			vm.showHighLightText = true;	
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : vm.limitHeader.customer.id , bankId :vm.limitHeader.bank.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			prepareDatesForView();
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			//vm.disableForm = false;
			vm.disableForm = true;
			vm.actionType="CustomerChange";
			//enableControls(true,true,true,false,true,false);
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = false;
            vm.showCustomerRemarks = true;
		} else if (workflowSharedService.viewFrom == 'customer_limitSettings_maker') {
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : vm.limitHeader.customer.id , bankId :vm.limitHeader.bank.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			prepareDatesForView();
			prepareLimitTypeToView();
			
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			vm.actionType="CustomerChange";
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = true;
			
		} else if (workflowSharedService.viewFrom == 'customer_limitSettings_resubmit') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			vm.previousLimit = angular.copy(vm.limitHeader);
			LimitSettings.getLimitsAvailableForAllInstruments({customerId : vm.limitHeader.customer.id , bankId :vm.limitHeader.bank.id }, function(result){
				vm.limitUtilisationDetails = result;
			})
			vm.showLimitUtilised = true;
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			if(vm.limitHeader.limitCurrency.code=='INR'){
				vm.showLimitUtilisedWithINR = true;
			}
	         Market.getLTPForLimitsForInstrument({"instrument": 'InterbankSPOT'}, function(result){
	        	 if(result!=null && result.ltp!=null){
	            	 vm.prevClose = (Math.round(parseFloat(result.ltp)*10000)) / 10000;
	            	 prepateLimitDetailsToView();
	        	 }else{
	        		 prepateLimitDetailsToView();
	        	 }
	         })
			//clear user remarks 
			vm.limitHeader.userRemarks = "";
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.screenLog.screenLog(vm.screenName, vm.limitHeader.id == null ? 0
					: vm.limitHeader.id,
					entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			vm.disableForm = false;
			$scope.showButton = false;
			$scope.showResubmitButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			vm.actionType="CustomerChange";
			enableControls(true,true,false,false,true,false);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = true;
		} else if (workflowSharedService.viewFrom == 'customer_limitSettings_auditLog') {
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;
			prepateLimitDetailsToView();
			prepareDatesForView();
			prepareLimitTypeToView();
			vm.disableForm = true;
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;

			if(vm.limitHeader.id!=null && vm.limitHeader.id!=undefined && !vm.limitHeader.id==""){
				vm.actionType="CustomerChange";
			}else{
				vm.actionType="CustomerCreate";
			}
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = true;
		}
		
		else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_checker') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
						
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			prepateLimitDetailsToView();
			prepareDatesForView();
			if(vm.limitHeader.effectiveFromDateTime == null || vm.limitHeader.effectiveFromDateTime == undefined
					|| vm.limitHeader.effectiveFromDateTime == ""){
				
				vm.effectiveFromDate = vm.currentDate;
				
			}
			if(vm.limitHeader.effectiveToDateTime == null || vm.limitHeader.effectiveToDateTime == undefined
					|| vm.limitHeader.effectiveToDateTime == ""){
				
				loadDefaultEffectiveToDate();
			}
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			vm.disableForm = false;
			vm.actionType="CustomerCreate";
			enableControls(true,true,false,false,false,false);
			vm.disableReinstateField = false;
            vm.disableInstrumentGroupField = false;
            vm.disableUserRemarks = false;
            vm.showCustomerRemarks = true;
			
		} 
		else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_checker_view') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
						
			vm.limitHeader = vm.customerLimit.limitSettingsHeader;			
			prepateLimitDetailsToView();
			prepareDatesForView();
			if(vm.limitHeader.effectiveFromDateTime == null || vm.limitHeader.effectiveFromDateTime == undefined
					|| vm.limitHeader.effectiveFromDateTime == ""){
				
				vm.effectiveFromDate = vm.currentDate;
				
			}
			if(vm.limitHeader.effectiveToDateTime == null || vm.limitHeader.effectiveToDateTime == undefined
					|| vm.limitHeader.effectiveToDateTime == ""){
				
				loadDefaultEffectiveToDate();
			}
			prepareLimitTypeToView();
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			vm.actionType="CustomerCreate";
			enableControls(true,true,true,true,true,true);
			vm.disableReinstateField = true;
            vm.disableInstrumentGroupField = true;
            vm.disableUserRemarks = true;
            vm.showCustomerRemarks = true;
			
		} 
		
		function enableControls(disableBranch , disableCusomer, disableOtherControls,disableEffectiveToControl, disableRadio, disableValues){
			
			vm.disableBranch = disableBranch;
			vm.disableCustomer = disableCusomer;
			vm.disableLimitType= disableOtherControls;
			vm.disableCurrency= disableOtherControls;
			vm.disableEffectiveToDate= disableEffectiveToControl;
			vm.disableRadio = disableRadio;
			vm.disableValues = disableValues;
		}
		  
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function getActionType(action){
        	if(action!=null && action!=undefined){
	        	action = action.toLowerCase();
	        	if(action.indexOf("add") !== -1 || action.indexOf("create") !== -1){
	        		return "Add";
	        	}
	        	else if(action.indexOf("edit") !== -1 || action.indexOf("modify") !== -1 || action.indexOf("modified") !== -1 || action.indexOf("change") !== -1){
	        		return "Change";
	        	}
                else if(action.indexOf("reset") !== -1){
                    return "Reset";
                }
        	}
        }
        function isCustomerLimitRequest(){
        	if(vm.actionType == 'CustomerChange' || CustomerCreate){
        		return true;
        	}
        	return false;
        }
        
        function save() {        	    		
        	 prepareLimitDetailsToSaveToDB(vm.selectedInstrument, vm.limitInstrumentGroup.shortName);
        	 prepareDatesforServer();
        	 var comparision = moment(vm.currentDate).isAfter(vm.limitHeader.effectiveToDateTime, 'day');
       		if(comparision){
       			AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
       			vm.clicked=false;
       		}else if(moment(vm.limitHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		}else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType.shortName=='MAXIMUMVALUEDATE' 
               && moment(vm.limitHeader.maximumValueDate).isBefore(vm.spotNextDate, 'day') )){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumValueDateCannotLessThanDate') + $filter('date')(vm.spotNextDate, "dd-MMM-yyyy") + '...!');
                vm.clicked=false;
            }else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType.shortName=='MAXIMUMVALUEDATE' 
			   && moment(vm.limitHeader.maximumValueDate).isAfter(vm.maxTenorDate, 'day'))){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumValueDateCannotMoreThanDate') + $filter('date')(vm.maxTenorDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		}else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType.shortName=='MAXIMUMTENOR' 
                && vm.limitHeader.maximumTenorType=='Days'
                && parseInt(vm.limitHeader.maximumTenorValue)>parseInt(vm.noOfActiveDaysForStandardTenor))){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumTenorValueCannotMoreThan') + vm.noOfActiveDaysForStandardTenor.toString() + ' days...!');
                vm.clicked=false;
            }else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType.shortName=='MAXIMUMTENOR' 
               && vm.limitHeader.maximumTenorType=='Months'
               && parseInt(vm.limitHeader.maximumTenorValue)>parseInt(vm.noOfActiveMonthsForStandardTenor))){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumTenorValueCannotMoreThan') + vm.noOfActiveMonthsForStandardTenor.toString() + ' months...!');
                vm.clicked=false;
            }
       		else if (vm.limitHeader.id !== null && vm.limitHeader.id!==undefined) {
       			if(validateChange()){
       				if(workflowSharedService.viewFrom == 'customer_limitSettings'){
						// validate for modified fields and values
						validateModifiedFields();
       					LimitSettings.updateLimitSettingsByCustomer(vm.customerLimit, onCustomerSaveSuccess, onCustomerSaveError);
       				}else{
       					LimitSettings.update(vm.limitHeader, onSaveSuccess, onSaveError);
       				}
       			}else{
       				AlertService.error($filter('translate')('gatewayApp.limitSettings.noChangesMade'));
       				vm.clicked = false;
       			}
             }else {
                 if(vm.actionType=='Add'){
                    LimitSettings.save(vm.limitHeader, onSaveSuccess, onSaveError);           
                 }else{
                    LimitSettings.reset(vm.limitHeader, onSaveSuccess, onSaveError);           
                 }
             }       	
        	
         }
     
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.clicked = false;
        }
        
        function onCustomerSaveSuccess(result){
        	 $uibModalInstance.close(result); 
        	 MessageBox.openMessageBox('Alert', 'The request has been sent for bank approval. You will be notified by email on the request status...!', 'Ok', 'homepage');
        }
        function onCustomerSaveError(error){
        	vm.clicked = false;
        }
        function resubmitLimitSettings() {
        	 prepareLimitDetailsToSaveToDB(vm.selectedInstrument, vm.limitInstrumentGroup.shortName);
        	 prepareDatesforServer();
        	 var comparision = moment(vm.currentDate).isAfter(vm.limitHeader.effectiveToDateTime, 'day');
	       	 
        	if(comparision){
        			AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
        			vm.clicked=false;
        	 }else if(moment(vm.limitHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		 }else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType.shortName=='MAXIMUMVALUEDATE' 
               && moment(vm.limitHeader.maximumValueDate).isBefore(vm.spotNextDate, 'day'))){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumValueDateCannotLessThanDate') + $filter('date')(vm.spotNextDate, "dd-MMM-yyyy") + '...!');
                vm.clicked=false;
            }else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType.shortName=='MAXIMUMVALUEDATE' 
               && moment(vm.limitHeader.maximumValueDate).isAfter(vm.maxTenorDate, 'day'))){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumValueDateCannotMoreThanDate') + $filter('date')(vm.maxTenorDate, "dd-MMM-yyyy") + '...!');
                vm.clicked=false;
            }else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType.shortName=='MAXIMUMTENOR' 
                && vm.limitHeader.maximumTenorType=='Days'
                && vm.limitHeader.maximumTenorValue>vm.noOfActiveDaysForStandardTenor)){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumTenorValueCannotMoreThan') + vm.noOfActiveDaysForStandardTenor.toString() + ' days...!');
                vm.clicked=false;
            }else if(!isCustomerLimitRequest && (vm.limitHeader.limitTenorType.shortName=='MAXIMUMTENOR' 
               && vm.limitHeader.maximumTenorType=='Months'
               && vm.limitHeader.maximumTenorValue>vm.noOfActiveMonthsForStandardTenor)){
                AlertService.error($filter('translate')('gatewayApp.limitSettings.maximumTenorValueCannotMoreThan') + vm.noOfActiveMonthsForStandardTenor.toString() + ' months...!');
                vm.clicked=false;
            }else if(workflowSharedService.viewFrom == 'customer_limitSettings_resubmit'){
				// validate for modified fields and values
				validateModifiedFields();
        		 LimitSettings.resubmitLimitChangeByCustomer({"taskId" : $rootScope.taskID}, vm.customerLimit, onResubmitSuccess, onResubmitError);
        	}else{
        		 LimitSettings.resubmit({"taskId" : $rootScope.taskID}, vm.limitHeader, onResubmitSuccess, onResubmitError);
        	}
        }
        
        function onResubmitSuccess(result) {
        	$uibModalInstance.close(result); 
        	$rootScope.removeRow($rootScope.taskID);
        }        

		function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function rejectLimitSettings() { 
        	$rootScope.action = vm.actionType;
        	Workflow.rejectTask($rootScope.taskID); 
        }

        function discardLimitSettings() {
        	LimitSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        

		function onDiscardError(error) {
        	
        }

        function approveLimitSettings() {  
    		prepareLimitDetailsToSaveToDB(vm.selectedInstrument, vm.limitInstrumentGroup.shortName);
    		prepareDatesforServer();
        	var comparision = moment(vm.currentDate).isAfter(vm.limitHeader.effectiveToDateTime, 'day');
	       	 
        	if(comparision){
       			AlertService.error($filter('translate')('gatewayApp.limitSettings.invalidaDate'));
       			vm.clicked=false;
       		}else if(moment(vm.limitHeader.effectiveToDateTime).isAfter(vm.tillDate, 'day')){
    			AlertService.error($filter('translate')('gatewayApp.limitSettings.cannotMoreThanDate') + $filter('date')(vm.tillDate, "dd-MMM-yyyy") + '...!');
    			vm.clicked=false;
       		}       	
        	else if(workflowSharedService.viewFrom == 'create_customer_limitSettings_checker' || workflowSharedService.viewFrom == 'customer_limitSettings_checker'){
				//check modified fields and push to fieldcheckarray for 2nd approval
				if(workflowSharedService.viewFrom == 'customer_limitSettings_checker'){
					var previousFieldArray = [];
					previousFieldArray = angular.copy(vm.customerLimit.fieldCheckArray);
					validateModifiedFields();
					angular.forEach(previousFieldArray, function(field) {
						if(vm.customerLimit.fieldCheckArray.indexOf(field)==-1){
							vm.customerLimit.fieldCheckArray.push(field);
						}
					});
					vm.showHighLight = false;
				}		
        		LimitSettings.approveWihPayload({"taskId" : $rootScope.taskID},vm.customerLimit,onApproveSuccess,onApproveError);         	
        	}else{
        		LimitSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError); 
        	}
        }
        
        function onApproveSuccess(result) {        	
        	$rootScope.removeRow($rootScope.taskID); 
        	$uibModalInstance.close(result);  
        }        

		function onApproveError(error) {
        	vm.clicked = false;
        }

		function validateChange(){
        	var changesMade = false;
        	var field1 = checkvalidityOfValues(vm.previousLimit.limitType , vm.limitHeader.limitType);
        	
        	var field2 = false;
        	if(vm.previousLimit.limitCurrency != null && vm.previousLimit.limitCurrency!=undefined && vm.limitHeader.limitCurrency!=null && vm.limitHeader.limitCurrency !=undefined){
        		field2 = checkvalidityOfValues(vm.previousLimit.limitCurrency.code , vm.limitHeader.limitCurrency.code);
        	}
        	var field3 = checkvalidityOfValues(DateUtils.convertLocalDateToServer(vm.previousLimit.effectiveToDateTime ),DateUtils.convertLocalDateToServer( vm.limitHeader.effectiveToDateTime));
        	var field4 =false;
        	var ChangesMAdeInDetail = false;
        	if(vm.previousLimit.limitSettingsDetailsList.length != vm.limitHeader.limitSettingsDetailsList.length){
        		field4 = true;
        	}else{
        		angular.forEach(vm.previousLimit.limitSettingsDetailsList, function(previousDetail){
        			angular.forEach(vm.limitHeader.limitSettingsDetailsList, function(currenDetail){
        				if(previousDetail.instrumentType == null && currenDetail.instrumentType == null){
        					if(checkvalidityOfValues(previousDetail.value,currenDetail.value)){
        						ChangesMAdeInDetail = true;
        					}
        				}
        				else if( ( previousDetail.instrumentType != null && currenDetail.instrumentType == null ) ||  (previousDetail.instrumentType == null && currenDetail.instrumentType != null)){
       						ChangesMAdeInDetail = true;
        				}
        				else if( previousDetail.instrumentType.id==currenDetail.instrumentType.id ){
        					if(checkvalidityOfValues(previousDetail.value,currenDetail.value)){
        						ChangesMAdeInDetail = true;
        					}
        				}
        			});
        		});
        	}
        	
        	//var field5 =false;
        	//field5 = checkvalidityOfValues(vm.previousLimit.isLimitToBeReinstated , vm.limitHeader.isLimitToBeReinstated);

            var field5 = false;
			if(vm.previousLimit.limitInstrumentGroup != null && vm.previousLimit.limitInstrumentGroup!=undefined && vm.limitHeader.limitInstrumentGroup!=null && vm.limitHeader.limitInstrumentGroup !=undefined){
        		field5 = checkvalidityOfValues(vm.previousLimit.limitInstrumentGroup.shortName , vm.limitHeader.limitInstrumentGroup.shortName);
        	}
        	
        	var field6 =false;
			if(vm.previousLimit.limitReinstateType != null && vm.previousLimit.limitReinstateType!=undefined && vm.limitHeader.limitReinstateType!=null && vm.limitHeader.limitReinstateType !=undefined){
        		field6 = checkvalidityOfValues(vm.previousLimit.limitReinstateType.shortName , vm.limitHeader.limitReinstateType.shortName);
        	}
        	
            var field7 =false;
			if(vm.previousLimit.limitTenorType != null && vm.previousLimit.limitTenorType!=undefined && vm.limitHeader.limitTenorType!=null && vm.limitHeader.limitTenorType !=undefined){
        		field7 = checkvalidityOfValues(vm.previousLimit.limitTenorType.shortName , vm.limitHeader.limitTenorType.shortName);
        	}
            
            var field8 =false;
            field8 = checkvalidityOfValues(vm.previousLimit.maximumTenorType, vm.limitHeader.maximumTenorType);
            
            var field9 =false;
            field9 = checkvalidityOfValues(vm.previousLimit.maximumTenorValue, vm.limitHeader.maximumTenorValue);
            
            var field10 =false;
            field10 = checkvalidityOfValues(vm.previousLimit.maximumValueDate, vm.limitHeader.maximumValueDate);
            
        	if(field1 || field2 || field3 || field4 || ChangesMAdeInDetail || field5 || field6 
                || field7 || field8 || field9 || field10){
        		changesMade = true;
        	}
        	return changesMade;
        }

		function checkvalidityOfValues(previousValue, currentValue){
        	var isValueChanged = (!(previousValue == null && (currentValue == "" || currentValue== null)) && previousValue!=currentValue);
        	return isValueChanged;
        }

		vm.onChangeLimitInstrumentGroup = function onChangeLimitInstrumentGroup(value){
			if(value!=null && value!=undefined){
                if(vm.limitHeader!=null && vm.limitHeader!=undefined){
                    vm.limitHeader.limitInstrumentGroup = value;
                }
				setFieldValues("txtspot", "");
				setFieldValues("txtcash", "");
				setFieldValues("txttom", "");
				setFieldValues("txtforward", "");
				setFieldValues("txtuptospot", "");
				setFieldValues("txtall", "");
				setFieldValues("txtMaxTenorValue", "");
				
                if(vm.defaultLimitReinstateType!=null && vm.defaultLimitReinstateType!=undefined){
                    var defaultReinstateId = "slt_" + vm.defaultLimitReinstateType.shortName;
                    if(document.getElementById(defaultReinstateId)!=null){
                        setTimeout(function(){document.getElementById(defaultReinstateId).click()});
                    }
                }
                if(vm.defaultLimitTenorType!=null && vm.defaultLimitTenorType!=undefined){
                    var defaultTenorTypeId = "slt_tenor_" + vm.defaultLimitTenorType.shortName;
                    if(document.getElementById(defaultTenorTypeId)!=null){
                        setTimeout(function(){document.getElementById(defaultTenorTypeId).click()});
                    }
                }
				if(vm.defaultLimitTenorType.shortName!='MAXIMUMTENOR'){
					vm.maximumTenorType = null;
				}else{
					vm.maximumTenorType = 'Days';
				}
				if(value.shortName=='SPOTWINDOW'){
					vm.selectedInstrument = 'individual';
					vm.effectiveToDate = angular.copy(vm.effecitveToDateForSpotWindow);
				}else{
					vm.effectiveToDate = angular.copy(vm.effecitveToDateForForwards);
				}

			}
		}

		function setFieldValues(fieldName, value){
        	if( document.getElementById(fieldName) !=null && document.getElementById(fieldName)!= undefined){
        		document.getElementById(fieldName).value = value;
        	}
        }
	//validate modified fields and push to field check araray
		function validateModifiedFields(){
			vm.customerLimit.fieldCheckArray=[];
			checkModifiedOfValues(vm.previousLimit.limitInstrumentGroup.shortName , vm.limitHeader.limitInstrumentGroup.shortName,"limitInstrumentGroup",vm.customerLimit);
			checkModifiedOfValues(vm.previousLimit.limitType , vm.limitHeader.limitType,"limitType",vm.customerLimit);
			checkModifiedOfValues(vm.previousLimit.limitReinstateType.shortName , vm.limitHeader.limitReinstateType.shortName,"limitReinstateType",vm.customerLimit);
			checkModifiedOfValues(vm.previousLimit.limitTenorType.shortName , vm.limitHeader.limitTenorType.shortName,"limitTenorType",vm.customerLimit);
			checkModifiedOfValues(vm.previousLimit.maximumTenorType , vm.limitHeader.maximumTenorType,"maximumTenorType",vm.customerLimit);
			checkModifiedOfValues(vm.previousLimit.maximumTenorValue , vm.limitHeader.maximumTenorValue,"maximumTenorValue",vm.customerLimit);
			checkModifiedOfValues(vm.previousLimit.maximumValueDate , vm.limitHeader.maximumValueDate,"maximumValueDate",vm.customerLimit);
			if(vm.previousLimit.limitCurrency != null && vm.previousLimit.limitCurrency!=undefined && vm.limitHeader.limitCurrency!=null && vm.limitHeader.limitCurrency !=undefined){
				checkModifiedOfValues(vm.previousLimit.limitCurrency.code , vm.limitHeader.limitCurrency.code,"limitCurrency",vm.customerLimit);
			}
			 checkModifiedOfValues(DateUtils.convertLocalDateToServer(vm.previousLimit.effectiveToDateTime ),DateUtils.convertLocalDateToServer( vm.limitHeader.effectiveToDateTime),"effectiveToDate",vm.customerLimit);
			if(vm.limitHeader.limitInstrumentGroup.shortName=='SPOTWINDOW'){
				if( vm.limitHeader!=null && vm.limitHeader!=undefined && vm.limitHeader.limitSettingsDetailsList.length == INDIVIDUAL_LIMIT_DETAIL_COUNT){
					angular.forEach(vm.previousLimit.limitSettingsDetailsList, function(previousDetail){
						angular.forEach(vm.limitHeader.limitSettingsDetailsList, function(currenDetail){
							 if( previousDetail.instrumentType.id==currenDetail.instrumentType.id ){
								if(currenDetail.instrumentType.shortName =='CASH'){
									 checkModifiedOfValues(previousDetail.value , currenDetail.value,"cashAmount",vm.customerLimit);			
								}else if(currenDetail.instrumentType.shortName =='TOM'){
									 checkModifiedOfValues(previousDetail.value , currenDetail.value,"tomAmount",vm.customerLimit);
								}else if(currenDetail.instrumentType.shortName =='SPOT'){
									 checkModifiedOfValues(previousDetail.value , currenDetail.value,"spotAmount",vm.customerLimit);  
								}
							}
						});
					});
				}else {
					 checkModifiedOfValues(vm.previousLimit.limitSettingsDetailsList[0].value , vm.limitHeader.limitSettingsDetailsList[0].value,"upToSpotAmount",vm.customerLimit);
				}
			}
		    else if(vm.limitHeader.limitInstrumentGroup.shortName=='FORWARD'){
				checkModifiedOfValues(vm.previousLimit.limitSettingsDetailsList[0].value , vm.limitHeader.limitSettingsDetailsList[0].value,"forwardAmount",vm.customerLimit);
		 }else{
			 checkModifiedOfValues(vm.previousLimit.limitSettingsDetailsList[0].value , vm.limitHeader.limitSettingsDetailsList[0].value,"allAmount",vm.customerLimit);
		 }
		}
		//check and push modified field names
		function checkModifiedOfValues(previousValue, currentValue,fieldName,customerLimit){
			
        	var isValueChanged = (!(previousValue == null && (currentValue == "" || currentValue== null)) && previousValue!=currentValue);
			 if(isValueChanged){
        	 	customerLimit.fieldCheckArray.push(fieldName);
        	 }
        	return isValueChanged;
        }
		//check modified fields in fieldCheckArray using control-modified in html
		function checkforModificationField(fieldName){
			if( workflowSharedService.viewFrom == 'customer_limitSettings_checker'  && vm.showHighLight && vm.customerLimit!=undefined && vm.customerLimit!=null) {
				var fieldCheckArray = angular.copy(vm.customerLimit.fieldCheckArray);
				if(fieldCheckArray!=undefined && fieldCheckArray!=null  && fieldCheckArray.indexOf(fieldName)>=0){
					return true;
				}else{
					return false;
				}
				 
			}
			else{
				return false;
			}
				 
			}
			
				
		}

	
})();
