(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('approval-policy', {
            parent: 'entity',
            url: '/approval-policy',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.security.manageapprovalpolicy" | translate }}',
                parent: 'home'
              },
            views: {
                'content@': {
                    templateUrl: 'app/entities/approval-policy/approval-policy.html',
                    controller: 'ApprovalPolicyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('approvalPolicy');
                    $translatePartialLoader.addPart('entityActionApprovalPolicy');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('approval-policy.new', {
            parent: 'approval-policy',
            url: '/approval-policy/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: ['ApprovalPolicy', 'GlobalValues', function(ApprovalPolicy, GlobalValues) {
                            return { id:null };
                        }],
                        mode: function(){
                       	 return "New";
                        }
                    }
                }).result.then(function() {
                    $state.go('approval-policy', {}, { reload: 'approval-policy' });
                }, function() {
                    $state.go('approval-policy');
                });
            }]
        })
        .state('approval-policy.edit', {
            parent: 'approval-policy',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                            	 entity: ['ApprovalPolicy', 'GlobalValues', function(ApprovalPolicy, GlobalValues) {
                                     return ApprovalPolicy.get({orgId:GlobalValues.getOrgId(), id:$stateParams.id}).$promise;
                                 }],
                                 mode: function(){
                                	 return "Edit";
                                 }
                    }
                }).result.then(function() {
                    $state.go('approval-policy', {}, { reload: true });
                }, function() {
                    $state.go('approval-policy');
                });
            }]
            
        })
        .state('approval-policy.view', {
            parent: 'approval-policy',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                            	 entity: ['ApprovalPolicy', 'GlobalValues', function(ApprovalPolicy, GlobalValues) {
                                     return ApprovalPolicy.get({orgId:GlobalValues.getOrgId(), id:$stateParams.id}).$promise;
                                 }],
                                 mode: function(){
                                	 return "View";
                                 }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('approval-policy.delete', {
            parent: 'approval-policy',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-delete-dialog.html',
                    controller: 'ApprovalPolicyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ApprovalPolicy', 'GlobalValues', function(ApprovalPolicy, GlobalValues) {
                            return ApprovalPolicy.get({orgId:GlobalValues.getOrgId(), id:$stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('approval-policy-todolist', {
            parent: 'dashboard',
            url: '/approval-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        },
                        mode: function(){
                       	 return "";
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('approvalPolicy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
        .state('approval-policy-todolist.resubmit', {
            parent: 'dashboard',
            url: '/approval-policy-resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                        	

                        },
                        mode: function(){
                          	 return "";
                           },
                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                           $translatePartialLoader.addPart('error');
                           $translatePartialLoader.addPart('global');
                           $translatePartialLoader.addPart('approvalPolicy');
                           return $translate.refresh();
                       }]
                    }
                }).result.then(function() {
                    $state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
        })
        .state('approval-policy.map', {
            parent: 'approval-policy',
            url: '/entity-action-approval-policy/{id}/map',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/entity-action-approval-policy.html',
                    controller: 'EntityActionApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: ['EntityActionApprovalPolicy', 'GlobalValues', function(EntityActionApprovalPolicy, GlobalValues) {
                    		return EntityActionApprovalPolicy.get({orgId:GlobalValues.getOrgId(), id:$stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('approval-policy', {}, { reload: 'approval-policy' });
                }, function() {
                    $state.go('approval-policy');
                });
            }]
        })
        .state('entity-action-approval-policy-view', {
            parent: 'entity-action-approval-policy-todolist',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                            	 entity: ['ApprovalPolicy', 'GlobalValues', function(ApprovalPolicy, GlobalValues) {
                                     return ApprovalPolicy.get({orgId:GlobalValues.getOrgId(), id:$stateParams.id}).$promise;
                                 }],
                                 mode: function(){
                                	 return "View";
                                 }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         .state('entity-action-approval-policy-todolist', {
            parent: 'dashboard',
            url: '/entity-action-approval-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/entity-action-approval-policy.html',
                    controller: 'EntityActionApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('approvalPolicy');
                            $translatePartialLoader.addPart('entityActionApprovalPolicy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
        .state('entity-action-approval-policy-todolist.resubmit', {
            parent: 'dashboard',
            url: '/entity-action-approval-policy-resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/entity-action-approval-policy.html',
                    controller: 'EntityActionApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                        	

                        },
                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                           $translatePartialLoader.addPart('error');
                           $translatePartialLoader.addPart('global');
                           $translatePartialLoader.addPart('approvalPolicy');
                           $translatePartialLoader.addPart('entityActionApprovalPolicy');
                           return $translate.refresh();
                       }]
                    }
                }).result.then(function() {
                    $state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
        })
           .state('entity-action-approval-policy-auditLog', {
            parent: 'audit-log',
            url: '/entity-action-approval-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/entity-action-approval-policy.html',
                    controller: 'EntityActionApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('approvalPolicy');
                            $translatePartialLoader.addPart('entityActionApprovalPolicy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
        .state('approval-policy-auditLog', {
            parent: 'audit-log',
            url: '/approval-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        },
                        mode: function(){
                       	 return "";
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('approvalPolicy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
         .state('entity-action-approval-policy-historyReport', {
            parent: 'history-report',
            url: '/entity-action-approval-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/entity-action-approval-policy.html',
                    controller: 'EntityActionApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('approvalPolicy');
                            $translatePartialLoader.addPart('entityActionApprovalPolicy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
        .state('approval-policy-historyReport', {
            parent: 'history-report',
            url: '/approval-policy-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/approval-policy/approval-policy-dialog.html',
                    controller: 'ApprovalPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        },
                        mode: function(){
                       	 return "";
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('approvalPolicy');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('approval-policy', null, { reload: 'approval-policy' });
                }, function() {
                	$state.go('^');
                });
            }]
            
        })
   };
})();