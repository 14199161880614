(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TradingPageController', TradingPageController);

    TradingPageController.$inject = ['$scope', '$rootScope', 'Principal', 'LoginService', '$sessionStorage', '$state', 'GlobalValues', 
    		'EntityPermissions', 'Notifications', '$sce', '$filter', 'Branch', 'StaticLookUp', 'BankRegistration','Country', 'workflowSharedService','$window',
    		'UserProfile', 'CurrencyPair', 'MarketWatch', 'Market', 'SwapQuotes', 'DefaultMarket', 'CustomerRegistration', 'CustomerBankRelationshipReport',
    		'$timeout', 'OrderStatusDetails', 'LimitSettings', 'IntradayMarket', 'DateUtils', 'MessageBox','User','ErrorMessages', 'WebSocket'];

    function TradingPageController ($scope, $rootScope, Principal, LoginService, $sessionStorage, $state, GlobalValues, 
    			EntityPermissions, Notifications, $sce, $filter, Branch, StaticLookUp, BankRegistration, Country, workflowSharedService,$window,
    			UserProfile, CurrencyPair, MarketWatch, Market, SwapQuotes, DefaultMarket, CustomerRegistration, CustomerBankRelationshipReport,
    			$timeout, OrderStatusDetails, LimitSettings, IntradayMarket, DateUtils, MessageBox,User,ErrorMessages, WebSocket) {
        var vm = this;
        
        
        vm.globalValues = GlobalValues;
        vm.account = vm.globalValues.getUser();
        subscribeWebSocket();
        DefaultMarket.marketInstruments(onGetInstrumentSuccess, onGetInstrumentFailure);
        bootUp();
        function subscribeWebSocket(){
        	WebSocket.subscribeWebSocket(vm.account.id);
        }
        
        var orderFeedEvent = $rootScope.$on("orderFeed", function(event, object){
        	//$state.reload();
        	
        	if(object.topic=='PendingOrderUpdate'){
 				var pendingOrder = object.object;
 				pendingOrdersFeed(pendingOrder);
 				//$timeout(function(){pendingOrdersFeed(pendingOrder);}, 100);
 				vm.pendingArr.push(pendingOrder);
 			}else if(object.topic=='LimitUpdate'){
 				var limit = object.object;
 				limitUpdateFeed(limit);
 				//$timeout(function(){limitUpdateFeed(limit);}, 100);
 				vm.limitArr.push(limit);
 			}else if(object.topic=='TradeNotification'){
 				var trade = object.object;
 				//$timeout(function(){tradeNotificationHandling(trade);}, 100);
 				//$timeout(function(){frameTradeNotification(trade);}, 100);
 				tradeNotificationHandling(trade);
 				frameTradeNotification(trade);
 				vm.tradeArr.push(trade);
 			}else if(object.topic=='OrderNotification'){
 				var order = object.object;
 				frameOrderNotification(order);
 				//$timeout(function(){frameOrderNotification(order);}, 100);
 				vm.orderArr.push(order);
 			}
        });
        
        var marketFeedEvent = $rootScope.$on("marketFeed", function(event, object){
        	//$state.reload();
        	if(object.topic=='MarketDepth'){
 				var marketDepthDTO = object.object;
 				//$timeout(function(){marketDepthChanges(marketDepthDTO);}, 100);
 				marketDepthChanges(marketDepthDTO);
 			}else if(object.topic=='SessionStatusChange'){
 				var marketSessionSettings = object.object;
 				//$timeout(function(){marketSettingsChanges(marketSessionSettings);}, 100);
 				marketSettingsChanges(marketSessionSettings);
 			}else if(object.topic=='SwapQuoteChange'){
 				var swapQuote = object.object;
 				//$timeout(function(){swapQuoteChanges(swapQuote);}, 100);
 				swapQuoteChanges(swapQuote);
 			}
        });
        
        
        $scope.$on('$destroy', function() {
        	orderFeedEvent();
        	marketFeedEvent();
        });
        

        vm.today = new Date();
        vm.formattedDate = $filter('date')(vm.today, 'ddMMyyyyHHmm');
        vm.downloadData = downloadData;
        
        
		 vm.allTradingBanks=CustomerBankRelationshipReport.query();
	        
	        function convertArrayOfObjectsToCSV(args) {  
	            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

	            data = args.data || null;
	            if (data == null || !data.length) {
	                return null;
	            }

	            columnDelimiter = args.columnDelimiter || ',';
	            lineDelimiter = args.lineDelimiter || '\n';

	            keys = Object.keys(data[0]);

	            result = '';
	            result += keys.join(columnDelimiter);
	            result += lineDelimiter;

	            data.forEach(function(item) {
	                ctr = 0;
	                keys.forEach(function(key) {
	                    if (ctr > 0) result += columnDelimiter;

	                    result += item[key];
	                    ctr++;
	                });
	                result += lineDelimiter;
	            });

	            return result;
	        }
	        
	        $scope.$watch('vm.content', function(value){
	           	if(value!= undefined && value!=null){
	           		
	           		var array = vm.content.split(/\n/);
	           		var tempHeader = array[0].trim();
	           		var headerErr = "Error Message";
	           		var tempHeaderErr = headerErr.trim();
	           		vm.newArray = tempHeader + "," + tempHeaderErr;
	           		
	           		for(var i=1;i<array.length;i++){
	           			
	           			if(array[i]!=""){
	           			vm.orderRequestDTO = array[i].split(',');
	           			if(vm.orderRequestDTO!=null && vm.orderRequestDTO!='' && vm.orderRequestDTO!=""){
	           				vm.orderImport={};
	           				
	           				
	           				vm.importErrorString="";
	           				vm.bankForTrading="";
	           				
	           				validateTradingBankRelationship(vm.orderRequestDTO[13],vm.orderRequestDTO[14],vm.orderRequestDTO[15]);
	           				
	           				if(vm.importErrorString==""){
	           					
	           					vm.orderImport.buySellIndicator=vm.orderRequestDTO[2];
	               				vm.orderImport.activityIndicator=vm.orderRequestDTO[0];
	               				vm.orderImport.typeOfOrder=vm.orderRequestDTO[6];
	               				vm.orderImport.instrumentCode=vm.orderRequestDTO[12];
	               				vm.orderImport.orderAmount = vm.orderRequestDTO[3];
	               				if(vm.orderImport.typeOfOrder!="MRKT"){
	               					vm.orderImport.limitExchangeRate = vm.orderRequestDTO[7];
	               				}
	               				if(vm.orderImport.typeOfOrder=="STLS" || vm.orderImport.typeOfOrder=="TKPR"){
	               					vm.orderImport.stopExchangeRate = vm.orderRequestDTO[8];
	               				}
	               				
	               				if(vm.orderImport.activityIndicator!="PLAC"){
	               					vm.orderImport.orderSequenceNumber=vm.orderRequestDTO[1];
	               					vm.orderImport.oldOrderAmount=vm.orderRequestDTO[19];
	               				}
	               				
	               				
	               				angular.forEach(vm.instrumentTypes, function(value){
	               	        		if(value.instrumentCode==vm.orderImport.instrumentCode){
	               		               	vm.orderImport.instrumentId=value.id;
	               	        		}
	               	        	});
	               				fillUploadDTO(vm.orderImport.buySellIndicator,vm.orderImport.activityIndicator,vm.orderImport.typeOfOrder,vm.orderImport.instrumentCode,
	               							vm.orderImport.orderAmount,vm.orderImport.limitExchangeRate,vm.orderImport.stopExchangeRate, vm.orderImport.oldOrderAmount);
	               				
	           				}
	               				
	               				if(vm.importErrorString!=""){
	               					
	               					
	               					
	               					var tempArr = array[i].trim();
	               					var tempErr = vm.importErrorString.trim();
                                    vm.newArray+="\n" + tempArr + "," + tempErr;

	               					
	               				}
	           				
	           			}
	           		}
	           		}
	           		if(vm.importErrorString!=""){
	           			downloadCSV({ filename: "order-import.csv" , input : vm.newArray});
	           		}
	           		
	           		angular.element("input[type='file']").val(null);
	           		vm.content=null;
	           		
	           	}
	           	else{
	           		angular.element("input[type='file']").val(null);
	           		
	           	}
	           })
	           
	           
	           function downloadCSV(args) {  
	        	
			        var data, filename, link;
			        
			        if(args.input==vm.newArray){
			        	  var csv = args.input;
			        }else{
			        	
			        	 var csv = convertArrayOfObjectsToCSV({
					            data: args.input
					        });
			        }
			       
			      
			        if (csv == null)
			        	return;

			        	filename = args.filename || 'export.csv';

			        	var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

			        	if (navigator.msSaveBlob)
			        	{ 
			        		navigator.msSaveBlob(blob, filename)
			        	}
			        	else
			        	{
				        	var link = document.createElement("a");
				        	if (link.download !== undefined)
				        	{
		
				        	
					        	var url = URL.createObjectURL(blob);
					        	link.setAttribute("href", url);
					        	link.setAttribute("download", filename);
					        	link.style = "visibility:hidden";
					        	document.body.appendChild(link);
					        	link.click();
					        	document.body.removeChild(link);
				        	}
			        	}
			        	
	        	}
	           
	           
		        function validateTradingBankRelationship(bankMemberNumber, branchCode, customerCode){
		        	
		        	 angular.forEach(vm.allTradingBanks, function(value){
		 		   		if(value.bank.bankMemberNo==bankMemberNumber && value.tradingBranch.branchId==branchCode && value.customer.customerCode==customerCode ){
		 		       		vm.orderImport.branchId=value.tradingBranch.id;
		 		       		vm.orderImport.branchName=value.tradingBranch.branchName;
		 		       		vm.orderImport.branchCode=value.tradingBranch.branchId;
		 		       		vm.orderImport.customerId=value.customer.id;
		 		       		vm.orderImport.customerName=value.customer.customerFirstName;
		 		       		vm.orderImport.customerCode=value.customer.customerCode;
		 		       		vm.orderImport.bankName=value.bank.bankName;
		 		       		vm.orderImport.bankId=value.bank.id;
		 		       		vm.orderImport.bankMemberNumber = value.bank.bankMemberNo;
		 		       		vm.bankForTrading=value.bank;
		 		   		}
		 		   		
		 		   	});
		        	 if(vm.bankForTrading==null || vm.bankForTrading==undefined || vm.bankForTrading==""){
		 		   			vm.importErrorString = vm.importErrorString = "There is no trading relationship for the customer with the bank or branch."
		 		   		}
		        }
	        
	           function fillUploadDTO(buySellFlag, activityIndicator,typeOfOrder,instrumentCode,orderAmount,limitExRate,stopExRate, oldOrderAmount){

		        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes(vm.bankComparativeQuotes, vm.bankForTrading );
		        	if(validateOrderUpload(buySellFlag, typeOfOrder, selectedBankSpread, instrumentCode,orderAmount,limitExRate,stopExRate, activityIndicator, oldOrderAmount)){
		        		
		        		
		    	       	
		    	       	
		    	       	vm.orderImport.dqAmount=vm.orderRequestDTO[4];
		    	       	vm.orderImport.mfAmount=vm.orderRequestDTO[5];
		    	       	
		    	       	vm.orderImport.timeLimitIndicator=vm.orderRequestDTO[9];
		    	       	if(vm.orderImport.timeLimitIndicator=="GTTM"){
		    	       		vm.orderImport.expiryTime=vm.orderRequestDTO[10];
		    	       	}
		    	       	
		    	       	if(vm.orderImport.activityIndicator!="PLAC"){
		    	       		vm.orderImport.revisionTimeStamp=vm.orderRequestDTO[11];
		    	       	}
		    	    	
		    	        vm.orderImport.userId=vm.orderRequestDTO[16];
		    	    	vm.orderImport.retailUserCode = vm.orderRequestDTO[17];
		    	    	vm.orderImport.userEntityOwnerId = vm.orderRequestDTO[18];
		    	    	
		            	vm.orderImport.remarks = vm.remarks;
		            	vm.orderImport.spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentCode);
		            	if(vm.orderImport.typeOfOrder=="MRKT" && vm.orderImport.activityIndicator=="MDFY"){
		            		vm.orderImport.typeOfOrder="LIMT";
		            	}
		
		            	
		            		if(activityIndicator=='PLAC'){
		                		placeOrderRequest(vm.orderImport)
		            		}else if(activityIndicator=='MDFY'){
		            			Market.modifyOrder(vm.orderImport);
		            		}else if(activityIndicator=='CNCL'){
		            			Market.cancelOrder(vm.orderImport);
		            		}
		            	
		        	}else{
		        		vm.importErrorString = vm.importErrorString + vm.errorString;
		        	}       	
	        }
	           
	        
	           
	           function validateOrderUpload(buySellFlag, priceCondtion, selectedBankSpread, instrumentCode,orderAmount,limitExRate,stopExRate,activityIndicator, oldOrderAmount){

	           
        	   if(vm.defaultInstrumentCode=='CASH'){
            		if(buySellFlag=='SELL' || buySellFlag=='MODIFYSELL'){
                    	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.cashSpotOfferSpread); 
                    		
            		}else if(buySellFlag=='BUYY' || buySellFlag=='MODIFYBUY'){
                    	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.cashSpotBidSpread); 
            		}
            	}else if(vm.defaultInstrumentCode=='TOM'){
                		if(buySellFlag=='SELL' || buySellFlag=='MODIFYSELL'){
                        	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.tomSpotOfferSpread);  
                		}else if(buySellFlag=='BUYY' || buySellFlag=='MODIFYBUY'){
                        	limitExRateInSpot = getSpotRateForGivenRateAndSpread(limitExRate, selectedBankSpread.tomSpotBidSpread); 
                		}
                }else{
                	limitExRateInSpot = limitExRate;
                }
        	   
	           	if(!validateOrderAmount(orderAmount)){
	           		return false;
	           	}
	           	
	           	if(!validateLimitCheck(oldOrderAmount, activityIndicator, orderAmount, instrumentCode, buySellFlag)){
	           		
	           		return false;
	           	}
	           	
	           	//orderticksize
	           	//ltp
	           	var orderTickSize = getTickSizeForGivenInstrument(instrumentCode);
	           	var ltp = 0
	           	
	           	if(buySellFlag!='SELLBUY'){
	               	ltp = getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, buySellFlag, selectedBankSpread);
	               	if(!validateLimitExchangeRate(buySellFlag, activityIndicator,priceCondtion, limitExRate,limitExRateInSpot, orderTickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage)){
	               		return false;
	               	}
	               	if(!validateStopExchangeRate(priceCondtion, buySellFlag, limitExRate, stopExRate, orderTickSize)){
	               		return false;
	               	}
	               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag)){
	               		return false;
	               	}
	           	}else if(buySellFlag=='SELLBUY'){
	               	var buyLtp = getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, 'BUYY', selectedBankSpread);
	               	var sellLtp = getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, 'SELL', selectedBankSpread);
	               	if(!validateLimitExchangeRate('BUYY', activityIndicator,priceCondtion, limitExRate, limitExRateInSpot, orderTickSize, buyLtp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage)){
	               		return false;
	               	}
	               	if(!validateLimitExchangeRate('SELL', activityIndicator,priceCondtion, limitExRate, limitExRateInSpot, orderTickSize, sellLtp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage)){
	               		return false;
	               	}
	               	if(!validateStopExchangeRate(priceCondtion, 'BUYY', limitExRate, stopExRate, orderTickSize)){
	               		return false;
	               	}
	               	if(!validateStopExchangeRate(priceCondtion, 'SELL', limitExRate, stopExRate, orderTickSize)){
	               		return false;
	               	}
	               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'BUYY')){
	               		return false;
	               	}
	               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'SELL')){
	               		return false;
	               	}
	           	}
	           	
	           	return true;
	           }
        
	     function downloadData(){
	    	 
	    	 if(vm.orderArr!=null || vm.orderArr!=undefined || vm.orderArr.length==0){
	    		 downloadCSV({filename : "order_activity" + vm.formattedDate +  ".csv", input : vm.orderArr});

	        		vm.orderArr=[];
	    	 }
	    	 	
	    	 if(vm.pendingArr!=null || vm.pendingArr!=undefined || vm.pendingArr.length==0){
        		downloadCSV({filename : "pending_orders" + vm.formattedDate +  ".csv", input : vm.pendingArr});
        		vm.pendingArr=[];
	    	 }
	    	 if(vm.tradeArr!=null || vm.tradeArr!=undefined || vm.tradeArr.length==0){
        		downloadCSV({filename : "trades" + vm.formattedDate +  ".csv", input : vm.tradeArr});
        		vm.tradeArr=[];
	    	 }
	    	 if(vm.limitArr!=null || vm.limitArr!=undefined || vm.limitArr.length==0){
        		downloadCSV({filename : "limits" + vm.formattedDate +  ".csv", input : vm.limitArr});
        		vm.limitArr=[];
	    	 }
        		
        		
        		
	     }
	           
	           
        /*trading home page*/
        //initializing
        vm.tickerPrice={};
        vm.showPendingOrders = true;
        vm.tickerPrice.retailSpot = 0;
        vm.tickerPrice.ibSpot = 0;
        vm.tickerPrice.retailSpotDifference = 0;
        vm.tickerPrice.ibSpotDifference = 0;
        vm.orderType='SELL';
        vm.selectedInstrument = 'SPOT';
        vm.retailSpotMarketWatch = {};
        vm.orderDTO = {};
        vm.getPendingOrdersReport = getPendingOrdersReport; 
        vm.getLimitUtilisedReport = getLimitUtilisedReport;
        vm.getTradesReport = getTradesReport;
        $rootScope.changeInstrument = changeInstrument;
        vm.changeDefaultPriceCondition = changeDefaultPriceCondition;
        vm.modifyOrder = modifyOrder;
        vm.placeOrder = placeOrder; 
        vm.placeMarketOrder = placeMarketOrder;
        vm.cancelOrder = cancelOrder;
        vm.cancelModifyOrderAction = cancelModifyOrderAction;
        vm.cancelAllOrders = cancelAllOrders;
        vm.marketDepthChanges = marketDepthChanges;
        vm.marketSettingsChanges = marketSettingsChanges;
        vm.addRemarks = addRemarks;
        vm.expandMarketDepth = expandMarketDepth;
        vm.openMarketStatistics = openMarketStatistics;
        vm.orderDTO.activityIndicator = 'PLAC';
        vm.openOrderPreference = openOrderPreference;
        vm.limitChangeRequest = limitChangeRequest;
        vm.getBuyingPower = getBuyingPower;
        vm.getSellingPower = getSellingPower;
        vm.onChangingBankInSwapQuotes = onChangingBankInSwapQuotes;
        vm.softCheckDeviationForLimitExchangeRate = softCheckDeviationForLimitExchangeRate;
        vm.softCheckDeviationForBuyLimitExchangeRate = softCheckDeviationForBuyLimitExchangeRate;
        vm.softCheckDeviationForSellLimitExchangeRate = softCheckDeviationForSellLimitExchangeRate;
        vm.pendingArr=[];
        vm.tradeArr=[];
        vm.limitArr=[];
        vm.orderArr=[];
        
        //initialiize limits
        vm.retailSpotBuyLimit = 0;
        vm.retailSpotSellLimit = 0;
        vm.cashBuyLimit = 0;
        vm.cashSellLimit = 0;
        vm.tomBuyLimit = 0;
        vm.tomSellLimit = 0;

        function onGetInstrumentSuccess(result){
        	vm.instrumentTypes = result;
        	//get tick size for each instrument
        	vm.tickSize = {};
        	angular.forEach(vm.instrumentTypes, function(value){
        		if(value.instrumentCode=='SPOT'){
        			vm.tickSize.spot = value.tickSize;
        		}else if(value.instrumentCode=='CASH'){
        			vm.tickSize.cash = value.tickSize;
        		}else if(value.instrumentCode=='TOM'){
        			vm.tickSize.tom = value.tickSize;
        		}else if(value.instrumentCode=='InterbankSPOT'){
        			vm.tickSize.ibSpot = value.tickSize;
        		}
        	})
        }
        
        function onGetInstrumentFailure(){
        	
        }
        
        //bootUp();
        
        function bootUp(){

        	if(vm.account!=null){
            	//get user specific settings for the logged in user
                //we can get default trading bank, branch and customer for the logged in user from the user preference data.
                //fill the filters of branch and customer with the default trading branch and customer we get it from user preference.
                vm.configSettings = vm.globalValues.getConfigSettings();

        		UserProfile.get({userId: vm.account.id}, onGetUserSpecSettingSuccess, onGetUserSpecSettingFailure)
                //get currency pair and make USDINR default.
                CurrencyPair.query(onGetCurrencyPairSuccess, onGetCurrencyPairFailure);
                
                Market.getLTPForLimits(onGetLtpForLimitSuccess, onGetLtpForLimitFailure);
                
                getStaticDataFromDb();
                
                $scope.noOfRecordsPerPage = 5;
                $scope.page = 1;

        	}
        }
        
        function getStaticDataFromDb(){
        	
            
            //get order status list from db and stored in array for displaying the order status in pending order.
            OrderStatusDetails.getOrderStatusList(onGetOrderStatusListSuccess, onGetOrderStatusListFailure);
            
           if( vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        	   vm.custCode= vm.globalValues.getCustomer().customerCode;
           }
           
           vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
           vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
            
        	
        }
        
        function onGetOrderStatusListSuccess(result){
        	vm.orderStatusList = result;
        }
        
        function onGetOrderStatusListFailure(){
        	
        }
        
        function onGetLtpForLimitSuccess(result){
        	vm.ltpForLimitsForAllInstrument = result;
        	angular.forEach(vm.ltpForLimitsForAllInstrument, function(value){
        		if(value.instrument.instrumentCode=='SPOT'){
        			vm.previousDayClosingPriceForSpot = value.previousDayClosingPrice;
        		}else if(value.instrument.instrumentCode=='InterbankSPOT'){
        			vm.previousDayClosingPriceForIbSpot = value.previousDayClosingPrice;
        		}
        	})
        	
        }
        
        function onGetLtpForLimitFailure(){
        	
        }
        
        function onGetUserSpecSettingSuccess(result){
        	vm.userPreference = result;
        	vm.defaultTradingBank = vm.userPreference.tradingBank;
        	vm.defaultTradingBranch = vm.userPreference.tradingBranch;
        	vm.defaultTradingCustomer = vm.userPreference.tradingCustomer;
        	//fill default values to order value, price condition, instrument to the controls.
        	if(vm.userPreference.userPriceCondition=='STLS' && vm.configSettings.isAllowStopLossOrder!='Yes'){
        		vm.userPreference.userPriceCondition = 'LIMT';
        	}
        	if(vm.userPreference.userPriceCondition=='TKPR' && vm.configSettings.isAllowTakeProfitOrder!='Yes'){
        		vm.userPreference.userPriceCondition = 'LIMT';
        	}
        	fillDefaultValuesToControls();
        	
            vm.currentDate = new Date();
            vm.currentDate = DateUtils.convertLocalDateToServer(vm.currentDate);
        	//get market session settings. to display status. market session settings will be available for SPOT, cash TOM
        	IntradayMarket.getMarketSessionSettings({"currentBusinessDate":vm.currentDate}, onGetMarketSessionSuccess, onGetMarketSessionFailure);
        	
        	getReportsToDisplay();

        }
        
        function onChangingBankInSwapQuotes(bank){
        	vm.defaultTradingBank = bank;
        	if(vm.globalValues.getOrganisation().organisationType.shortName=='CUSTOMER'){
        		CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: bank.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
					vm.defaultTradingBranch = result;
					//fillDefaultValuesToControls();
					vm.tradingBank = vm.defaultTradingBank;
					
					vm.bankFilterForPO = bank;
					vm.bankFilterForLimit = bank;
					vm.bankFilterForTrade = bank;
					vm.branchFilterForPO = result;
					vm.branchFilterForLimit = result;
					vm.branchFilterForTrade = result;
					vm.customerFilterForPO = vm.globalValues.getOrganisation().id;
					vm.customerFilterForLimit = vm.globalValues.getOrganisation().id;
					vm.customerFilterForTrade = vm.globalValues.getOrganisation().id;
					getReportsToDisplay();
				});
        	}
        }

        function onGetUserSpecSettingFailure(){
        	
        }
        
        function onGetCurrencyPairSuccess(result){
        	$rootScope.currencyPairList = result;
        	//make USDINR as default. the first record is USDINR.
        	$rootScope.currencyPair = result[0];
        	/*$rootScope.emit("currencyPair", currencyPair);
        	$rootScope.emit("currencyPairList", currencyPairList);*/
        }
        
        function onGetCurrencyPairFailure(){
        	
        }
        
        function onGetMarketSessionSuccess(result){
        	vm.marketSessionSettings = result;
        	//store session settings in separate variables for each and every instrument
        	setMarketSettings();
        	
        	//get market depth
        	Market.getMarketDepth(onGetMarketDepthSuccess, onGetMarketDepthFailure);
        	
        	//onGetMarketDepthSuccess(vm.marketDepth);
        	//Market.getMarketDepth(onGetMarketDepthSuccess, onGetMarketDepthFailure);
        }
        
        function onGetMarketSessionFailure(){
        	
        }
        
        //set market settings
        function setMarketSettings(){
        	//loop market session settings and get vm.retailSpotMarketSession, vm.cashMarketSession, vm.tomMarketSession..
        	angular.forEach(vm.marketSessionSettings, function(value){
        		if(value.instrumentCode == 'SPOT'){
        			vm.retailSpotMarketSession = value;
        		}else if(value.instrumentCode == 'TOM'){
        			vm.tomMarketSession = value;	
        		}else if(value.instrumentCode == 'CASH'){
        			vm.cashMarketSession = value;
        		}
        	})
        }
        
        //fill default values to controls and variables
        function fillDefaultValuesToControls(){
        	vm.orderDTO = {};
        	//fill order value, price condition, instrument type, order type to controls
        	if(vm.userPreference!=null && vm.userPreference!=undefined){
				vm.orderVolumeSpotMkt = vm.userPreference.userOrderValue;
				vm.orderVolumeCashMkt = vm.userPreference.userOrderValue;
				vm.orderVolumeTomMkt = vm.userPreference.userOrderValue;
				vm.defaultTimeCondition = vm.userPreference.userTimeCondition;

				setDefaultPriceCondition(vm.userPreference.userPriceCondition);

				vm.defaultInstrumentCode = vm.userPreference.userInstrumentCode;
				fillDefaultInstIdForInstCode(vm.defaultInstrumentCode);
				vm.loadBoardInstrumentCode = vm.userPreference.userInstrumentCode;
				$rootScope.loadBoardInstrumentCode = vm.userPreference.userInstrumentCode;
				vm.tradingBank = vm.defaultTradingBank;
				vm.instrumentFilterForPO = vm.userPreference.instrument;
				vm.instrumentFilterForTrade = vm.userPreference.instrument;
				vm.instrumentFilterForLimit = vm.userPreference.instrument;
				vm.selectedInstrument = vm.userPreference.instrument;

				if(vm.account.organisation.organisationType.shortName=='BANK'){
					vm.bankFilterForPO = vm.globalValues.getBank();
					vm.branchFilterForPO = vm.userPreference.tradingBranch;
					vm.customerFilterForPO = vm.userPreference.tradingCustomer;
					vm.bankFilterForTrade = vm.globalValues.getBank();
					vm.branchFilterForTrade = vm.userPreference.tradingBranch;
					vm.customerFilterForTrade = vm.userPreference.tradingCustomer;
					vm.bankFilterForLimit = vm.globalValues.getBank();
					vm.branchFilterForLimit = vm.userPreference.tradingBranch;
					vm.customerFilterForLimit = vm.userPreference.tradingCustomer;
				}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
					vm.bankFilterForPO = vm.userPreference.tradingBank;
					vm.branchFilterForPO = vm.userPreference.tradingBranch;
					vm.customerFilterForPO = vm.globalValues.getCustomer();
					vm.bankFilterForTrade = vm.userPreference.tradingBank;
					vm.branchFilterForTrade = vm.userPreference.tradingBranch;
					vm.customerFilterForTrade = vm.globalValues.getCustomer();
					vm.bankFilterForLimit = vm.userPreference.tradingBank;
					vm.branchFilterForLimit = vm.userPreference.tradingBranch;
					vm.customerFilterForLimit = vm.globalValues.getCustomer();
				}

				vm.orderDTO.orderAmount = vm.userPreference.userOrderValue;
				vm.orderDTO.timeLimitIndicator = vm.userPreference.userTimeCondition;
				vm.orderDTO.typeOfOrder = vm.userPreference.userPriceCondition;
				vm.orderDTO.instrumentCode = vm.userPreference.userInstrumentCode;
				vm.showPreOrderConfirmation = vm.userPreference.showPreOrderConfirmation;
				vm.orderDTO.activityIndicator = 'PLAC';
				vm.orderType = 'SELL';
	        	changeOrderType(vm.orderType, vm.orderDTO.activityIndicator);
        	}
        }
        
        function fillDefaultInstIdForInstCode(instCode){
        	angular.forEach(vm.instrumentTypes, function(value){
        		if(value.instrumentCode==instCode){
	               	vm.defaultInstrumentId=value.id;
        		}
        	})
        }
        
        $scope.$watch('vm.defaultInstrumentCode', function(value1){
        	angular.forEach(vm.instrumentTypes, function(value){
        		if(value.instrumentCode==value1 && value.instrumentCode==$rootScope.loadBoardInstrumentCode){
	               	vm.instrumentFilterForPO = value;
	               	vm.instrumentFilterForTrade = value;
	               	vm.instrumentFilterForLimit = value;
	               	vm.selectedInstrument = value;
	               	vm.defaultInstrumentId=value.id;
	               	//change report details according to load board instrument
	               	getReportDetailsForSelectedInstrument(value.id);
        		}
        	})
        })
        
        function changeInstrument(instrumentCode){
        	$rootScope.loadBoardInstrumentCode = instrumentCode;
        	//vm.instrumentFilterForPO = instrumentCode;
        	selectInstrumentInOrderEntry(instrumentCode);
        }
        
        function selectInstrumentInOrderEntry(instrumentCode){
        	if(instrumentCode=='SPOT'){
            	setTimeout(function(){document.getElementById('spotInstrument').click()});
        	}else if(instrumentCode=='CASH'){
            	setTimeout(function(){document.getElementById('cashInstrument').click()});
            	//document.getElementById('cashInstrument').click();
        	}else if(instrumentCode=='TOM'){
            	setTimeout(function(){document.getElementById('tomInstrument').click()});
            	//document.getElementById('tomInstrument').click();
        	}
        }
        
        function setDefaultPriceCondition(priceCondition){
        	vm.defaultPriceCondition = priceCondition;
        	if(!$scope.$$phase){
            	if(priceCondition=='MRKT' || priceCondition=='LIMT'){
                	setTimeout(function(){document.getElementById('limitTab').click()});
                	//document.getElementById('limitTab').click();
            	}else if(priceCondition=='STLS'){
                	setTimeout(function(){document.getElementById('stoplossTab').click()});
                	//document.getElementById('stoplossTab').click();
            	}else if(priceCondition=='TKPR'){
                	setTimeout(function(){document.getElementById('takeprofitTab').click()});
                	//document.getElementById('takeprofitTab').click();
            	}
        	}
        }
        
        function changeDefaultPriceCondition(priceCondition){
        	vm.defaultPriceCondition = priceCondition;
        }
        
        function onGetMarketDepthSuccess(result){
        	vm.marketDepth = result;
        	
        	prepareMarketDepthDisplay();
        	

        }
        
        function getReportsToDisplay(){
        	//get limits available for customer and bank and branch
        	//we can derive limits available for each and every instruments here and stored in separate variables to display in market watch..
            var bankId = getBankIdForLoggedInUser();
            var customerId = getCustomerIdForLoggedInUser();
        	if(bankId!=0 && customerId!=0){
        		getLimitsAvailableForAllInstruments(bankId, customerId);
        	}
            	
        	//get report details for selected instrument(to display in report section)
	        //For customer user, get limits available of the customer and default/selected bank. we can get default bank/branch from user specific settings.
	        //For bank/branch user, get limits available of the default/selected  customer for the bank. we can get default customer for bnak/branch user from user specific settings
        	getReportDetailsForSelectedInstrument(vm.selectedInstrument.id);

        }
        
        function getBankIdForLoggedInUser(){
        	var bankId = 0;
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		if(vm.defaultTradingBank!=null){
            		bankId = vm.defaultTradingBank.id;
        		}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
        		bankId = vm.globalValues.getOrganisation().id;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		if(vm.defaultTradingBank!=null){
            		bankId = vm.defaultTradingBank.id;
        		}
        	}
        	return bankId;
        }
        
        function getCustomerIdForLoggedInUser(){
        	var customerId = 0;
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP' || vm.account.organisation.organisationType.shortName=='BANK'){
        		if(vm.defaultTradingCustomer!=null){
            		customerId = vm.defaultTradingCustomer.id;
        		}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
           		customerId = vm.globalValues.getOrganisation().id;
        	}
        	return customerId;
        }
        
        function prepareMarketDepthDisplay(){
        	//get market depth for both retail spot and interbank spot
        	getMarketDepthDetailsForReport();
        	setTimeout(function(){getSwapQuotes();});
        	
        	//set previous ticker price
        	/*var previousTickerPrice = angular.copy(vm.tickerPrice);
        	vm.previousTickerPrice = previousTickerPrice;*/
        	//get ticker price
        	angular.forEach(vm.marketDepth, function(value){
        		var previousTickerPrice = angular.copy(vm.tickerPrice);
            	vm.previousTickerPrice = previousTickerPrice;
            	vm.tickerPrice = MarketWatch.getTickerPrice(value, previousTickerPrice, vm.previousDayClosingPriceForSpot, vm.previousDayClosingPriceForIbSpot);
            	$rootScope.tickerPrice = vm.tickerPrice;
        	//$rootScope.$emit('tickerPrice', vm.tickerPrice);

        	})
        }

        function onGetMarketDepthFailure(){
        	
        }
        
        function getMarketDepthDetailsForReport(){
        	
//        	setTimeout(function(){
//        		var existingSpotMarketDepth = angular.copy(vm.retailSpotMarketDepth);
//            	//get market depth for SPOT
//            	existingSpotMarketDepth = MarketWatch.getRetailSpotMarketDepth(vm.marketDepth);
            	
            	vm.retailSpotMarketDepth = MarketWatch.getRetailSpotMarketDepth(vm.marketDepth);
            	
            	if(vm.retailSpotMarketDepth!=null && vm.retailSpotMarketDepth!=undefined){
            		getRetailSpotMarketWatch();
            	}

            	var existingIbSpotMarketDepth = vm.ibSpotMarketDepth;
            	//get market depth for IB SPOT
            	existingIbSpotMarketDepth = MarketWatch.getIBSpotMarketDepth(vm.marketDepth);
            	vm.ibSpotMarketDepth = existingIbSpotMarketDepth;
            	
            	if(vm.ibSpotMarketDepth!=null && vm.ibSpotMarketDepth!=undefined){
                	//get ibSpotMarketWatch
            		var existingIbSpotMarketWatch = vm.ibSpotMarketWatch;
            		existingIbSpotMarketWatch = MarketWatch.getIBSpotMarketWatch(vm.ibSpotMarketDepth);
            		vm.ibSpotMarketWatch = existingIbSpotMarketWatch;
            	}
//            	$scope.$apply();
//        	}, 100)
        }

        function getRetailSpotMarketWatch(){
        	var existingSpotMarketWatch = vm.retailSpotMarketWatch;
        	//get retailSpot market watch
        	existingSpotMarketWatch = MarketWatch.getRetailSpotMarketWatch(vm.retailSpotMarketDepth);
        	vm.retailSpotMarketWatch = existingSpotMarketWatch;
        	if(vm.retailSpotMarketWatch!=null && vm.retailSpotMarketWatch!=undefined){
            	//get swap quotes
            	//getSwapQuotes()
        	}
        }
        
        function getSwapQuotes(){
        	
            //if the user is customer user, get spread for the banks in which the customer has relationship with.
            //if the user is bank/branch user, get spread for that particular bank.
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
            	SwapQuotes.getSwapQuotesForAllBanks(onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
            	SwapQuotes.getSwapQuotesForGivenBank({bankId: vm.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	SwapQuotes.getSwapQuotesForGivenCustomer({customerId: vm.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}
        }
        
    	function onGetSwapQuotesSuccess(result){
        	//result will be list of swap quotes for the bank(s)
        	//if result length is 1, then that is default bank for that user(customer user) and for bank/branch user, the result length will be 1. 
        	if(result.length>0){
        		//get bank swap quotes array to display in the bank comparative quotes section
        		vm.bankComparativeQuotes = result;

    			//change swap quotes in home page
        		prepareSwapQuotes();

        	}
        }
    	
    	function onGetSwapQuotesFailure(){
    		
    	}
        
    	function prepareBankComparativeQuotesInPrice(bankQuotesInSpread, retailSpotMarketWatch){
    		vm.bankQuotesInPrice = [];
    		angular.forEach(bankQuotesInSpread, function(value){
    			var bankQuotesInPriceValue = {};
    			//prepare bank quotes in price
    			bankQuotesInPriceValue = MarketWatch.getBankSwapQuotesInPrice(value, retailSpotMarketWatch);
    			vm.bankQuotesInPrice.push(bankQuotesInPriceValue);
    		})
    		
    	}
    	
    	function getBestSpreadRates(bankComparativeQuotes){
    		
    		vm.bestCashSpotBidSpread = Math.min.apply(Math,bankComparativeQuotes.map(function(item){return item.cashSpotBidSpread;}).filter(function(val){return val !== null}));
    		vm.bestCashSpotOfferSpread = Math.min.apply(Math,bankComparativeQuotes.map(function(item){return item.cashSpotOfferSpread;}).filter(function(val){return val !== null}));
    		vm.bestTomSpotBidSpread = Math.min.apply(Math,bankComparativeQuotes.map(function(item){return item.tomSpotBidSpread;}).filter(function(val){return val !== null}));
    		vm.bestTomSpotOfferSpread = Math.min.apply(Math,bankComparativeQuotes.map(function(item){return item.tomSpotOfferSpread;}).filter(function(val){return val !== null}));
    	}
    	
    	function onGetDefaultTradingBankSpreadSuccess(){
			//vm.defaultBankSpread = result;
    		
        	//get cash and tom market watch
			//getCashAndTomMarketWatch(vm.retailSpotMarketDepth, vm.defaultBankSpread);
			getCashAndTomMarketWatch(vm.retailSpotMarketWatch, vm.defaultBankSpread);
        	
        	
    	}
    	
    	function getCashAndTomMarketWatch(spotMarketWatch, bankSpread){
    		vm.cashMarketWatch = MarketWatch.getMarketWatchForGivenInstrument(spotMarketWatch, bankSpread, 'CASH');
    		vm.tomMarketWatch = MarketWatch.getMarketWatchForGivenInstrument(spotMarketWatch, bankSpread, 'TOM');
    	}
    	
    	function onGetDefaultTradingBankSpreadFailure(){
    		
    	}
    	
    	function onCashMarketWatchSuccess(result){
    		vm.cashMarketWacth = result;
    	}
    	
    	function onTomMarketWatchSuccess(result){
    		vm.tomMarketWacth = result;
    	}
    	
    	function onTomMarketWatchFailure(){
    		
    	}
    	
        function getLimitsAvailableForAllInstruments(bankId, customerId){
            //For customer user, get limits available of the customer and default/selected bank.
            //For bank/branch user, get limits available of the default/selected  customer for the bank.
            LimitSettings.getLimitsAvailableForAllInstruments({bankId: bankId, customerId: customerId}, onGetLimitsAvailableSuccess, onGetLimitsAvailableFailure);
        }
        
        function onGetLimitsAvailableSuccess(result){
        	//this is for market watch section
        	vm.limitsAvailableForAllInstrument = result;
        	if(result!=null && result!=undefined){
        		if(result.length>0){
        			if(result[0].instrumentCode=='ALL'){
        				vm.limitInstrumentCode = 'ALL';
        			}else{
        				vm.limitInstrumentCode = 'INDIVIDUAL';
        			}
        		}else{
        			fillZeroIfLimitIsNotDefined();
        		}
        	}else{
        		fillZeroIfLimitIsNotDefined();
    		}
        	//we can get limits for the instrument(spot, cash, tom or all) and limit type(gross, gross buy, gross sell, net).
        	angular.forEach(vm.limitsAvailableForAllInstrument, function(value){
        		//get limits for each instrument and assign it to separate variables to display in market watch
        		getLimitsForEachInstrument(value);
        	})	
        }
        
        function onGetLimitsAvailableFailure(){
        	
        }
        
        function fillZeroIfLimitIsNotDefined(){
        	vm.retailSpotBuyLimit = 0;
			vm.retailSpotSellLimit = 0;
			vm.cashBuyLimit = 0;
			vm.cashSellLimit = 0;
			vm.tomBuyLimit = 0;
			vm.tomSellLimit = 0;
        }
        
        function fillZeroIfLimitIsNotDefinedForInstrumentCode(instrumentCode){
        	if(instrumentCode=='SPOT'){
            	vm.retailSpotBuyLimit = 0;
    			vm.retailSpotSellLimit = 0;
        	}else if(instrumentCode=='CASH'){
    			vm.cashBuyLimit = 0;
    			vm.cashSellLimit = 0;
        	}else if(instrumentCode=='TOM'){
    			vm.tomBuyLimit = 0;
    			vm.tomSellLimit = 0;
        	}else if(instrumentCode=='ALL'){
            	vm.retailSpotBuyLimit = 0;
    			vm.retailSpotSellLimit = 0;
    			vm.cashBuyLimit = 0;
    			vm.cashSellLimit = 0;
    			vm.tomBuyLimit = 0;
    			vm.tomSellLimit = 0;
        	}
        }
        
        function getLimitsForEachInstrument(limit){
        	vm.limitType = limit.limitType;
        	if(limit.instrumentCode=='SPOT'){
    			vm.retailSpotBuyLimit = getBuyingPower(limit);
    			vm.retailSpotSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='CASH'){
    			vm.cashBuyLimit = getBuyingPower(limit);
    			vm.cashSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='TOM'){
    			vm.tomBuyLimit = getBuyingPower(limit);
    			vm.tomSellLimit = getSellingPower(limit);
    		}
        	
        	if(limit.instrumentCode=='ALL' || vm.limitInstrumentCode=='ALL'){
        		var buyingPower = getBuyingPower(limit);
        		var sellingPower =  getSellingPower(limit);
    			vm.retailSpotBuyLimit = buyingPower;
    			vm.retailSpotSellLimit = sellingPower;
    			vm.tomBuyLimit = buyingPower;
    			vm.tomSellLimit = sellingPower;
    			vm.cashBuyLimit = buyingPower;
    			vm.cashSellLimit = sellingPower;
        	}
        }
        
        function getPendingOrdersReport(){
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForPO!=null && vm.instrumentFilterForPO!=undefined){
        		instrumentTypeId = vm.instrumentFilterForPO.id;
        		vm.pendingOrderInstrumentCode = 'ALL';
        	}

        	getPendingOrderReportForInstrument(instrumentTypeId);
        }
        
        function getPendingOrderReportForInstrument(instrumentTypeId){
        	var bankId = getBankIdForReportFilters('PO');
        	var branchId = getBranchIdForReportFilters('PO');
        	var customerId = getCustomerIdForReportFilters('PO');

        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getPendingOrders(bankId, branchId, customerId,instrumentTypeId);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getPendingOrders(bankId, branchId, customerId,instrumentTypeId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getPendingOrders(bankId, branchId, customerId,instrumentTypeId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(bankId!=0 && branchId!=0 && customerId!=0){
            		getPendingOrders(bankId, branchId, customerId,instrumentTypeId);
            	}
        	}
        }
        
        function getPendingOrders(bankId, branchId, customerId, instrumentId){
        	
    		OrderStatusDetails.getPendingOrders({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetPendingOrdersSuccess, onGetPendingOrdersFailure);
        	
        }

        function onGetPendingOrdersSuccess(result){
        	vm.pendingOrders = result;
        	angular.forEach(vm.pendingOrders, function(value, index){
        		angular.forEach(vm.orderStatusList, function(orderStatus){
        			if(value.orderStatusId == orderStatus.id){
        				vm.pendingOrders[index].orderStatus = orderStatus.name;
        			}
        			value.expiryTime=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
        		})
        	})
        }
        
        function onGetPendingOrdersFailure(){
        	
        }
        
        function getTradesReport(){
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForTrade!=null && vm.instrumentFilterForTrade!=undefined){
        		instrumentTypeId = vm.instrumentFilterForTrade.id;
        		vm.tradeInstrumentCode = 'ALL';
        	}
        	
        	getTradesReportForInstrument(instrumentTypeId);
        }
        
        function getTradesReportForInstrument(instrumentTypeId){
        	var bankId = getBankIdForReportFilters('Trade');
        	var branchId = getBranchIdForReportFilters('Trade');
        	var customerId = getCustomerIdForReportFilters('Trade');

        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getTrades(bankId, branchId, customerId, instrumentTypeId);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getTrades(bankId, branchId, customerId, instrumentTypeId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getTrades(bankId, branchId, customerId, instrumentTypeId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(bankId!=0 && branchId!=0 && customerId!=0){
            		getTrades(bankId, branchId, customerId, instrumentTypeId);
            	}
        	}
        }
        
        function getTrades(bankId, branchId, customerId, instrumentId){
        	
        	OrderStatusDetails.getTradesForSelectedInstrument({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetTradesSuccess, onGetTradesFailure);
        	
        }
        
        function onGetTradesSuccess(result){
        	
        	angular.forEach(result,function(value){
        		value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        	});
        	vm.trades = result;
        	
        }
        
        function onGetTradesFailure(){
        	
        }
        
        function getLimitUtilisedReport(){
        	var instrumentTypeId = 0;
        	if(vm.instrumentFilterForLimit!=null && vm.instrumentFilterForLimit!=undefined){
        		instrumentTypeId = vm.instrumentFilterForLimit.id;
        		vm.limitInstrumentCode = 'ALL';
        	}

        	getLimitUtilisedReportForInstrument(instrumentTypeId);
        	
        }
        
        function getLimitUtilisedReportForInstrument(instrumentTypeId){
        	var bankId = getBankIdForReportFilters('Limit');
        	var branchId = getBranchIdForReportFilters('Limit');
        	var customerId = getCustomerIdForReportFilters('Limit');
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
        		getLimitsAvailable(bankId, branchId, customerId,instrumentTypeId);
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
            	if(bankId!=0){
            		getLimitsAvailable(bankId, branchId, customerId,instrumentTypeId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	if(bankId!=0 && branchId!=0){
            		getLimitsAvailable(bankId, branchId, customerId,instrumentTypeId);
            	}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	if(bankId!=0 && branchId!=0 && customerId!=0){
            		getLimitsAvailable(bankId, branchId, customerId,instrumentTypeId);
            	}
        	}
        }
        
        function getLimitsAvailable(bankId, branchId, customerId, instrumentId){
        	
        	LimitSettings.getLimitUtilsationDetailsForInstrument({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetLimitsSuccess, onGetLimitsFailure);

        }
        
        function onGetLimitsSuccess(result){
        	vm.limitsAvailableForSelectedInstrument = result;
        }
        
        function onGetLimitsFailure(){
        	
        }
        
        //place market order
        function placeMarketOrder(buySellFlag, orderVolume, instrumentType, instrumentId){
        	vm.orderDTO.typeOfOrder = 'MRKT';
        	vm.orderDTO.activityIndicator = 'PLAC';
        	vm.orderDTO.instrumentCode = instrumentType;
        	vm.orderDTO.buySellIndicator = buySellFlag;
        	vm.orderDTO.orderAmount = parseFloat(orderVolume.replace(/,/g, ''));
        	vm.orderDTO.instrumentId = instrumentId;
        	vm.orderDTO.timeLimitIndicator = 'DAYY';

        	//fill bank, branch, customer details into order request dto
        	fillBankBranchCustomerDetails()
        	
        	vm.orderDTO.customerName = vm.globalValues.getCustomer().customerFirstName;
        	vm.orderDTO.userId = vm.globalValues.getUserId();
        	vm.orderDTO.retailUserCode = vm.globalValues.getUser().login;
        	vm.orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
        	vm.orderDTO.limitExchangeRate = 0;
        	vm.orderDTO.stopExchangeRate = 0;
        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes(vm.bankComparativeQuotes, vm.tradingBank);
        	vm.orderDTO.spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentType);

        	if(validateOrder(buySellFlag,vm.orderDTO.typeOfOrder, selectedBankSpread, instrumentType, vm.orderDTO.activityIndicator)){
            	if(vm.userPreference.showPreOrderConfirmation=='yes'){
                	$rootScope.orderDTO = vm.orderDTO;
                	$state.go('pre-order-confirmation', {closeTime: '', showPreOrderConfirmation: vm.userPreference.showPreOrderConfirmation});
            	}else{
            		//send order request to server
            		Market.placeOrder(vm.orderDTO);
            	}
        	}else{
        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}
        }
        
        //place/modify/cancel order 
        function placeOrder(buySellFlag, activityIndicator){
        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes(vm.bankComparativeQuotes, vm.tradingBank);
        	if(validateOrder(buySellFlag, vm.defaultPriceCondition, selectedBankSpread, vm.defaultInstrumentCode, activityIndicator)){
            	if(activityIndicator=='MDFY' && vm.defaultPriceCondition=='MRKT'){
            		vm.defaultPriceCondition = 'LIMT';
            	}
        		if(activityIndicator=='PLAC'){
        			vm.orderDTO.activityIndicator = activityIndicator;
                	vm.orderDTO.typeOfOrder = vm.defaultPriceCondition;
                	vm.orderDTO.instrumentCode = vm.defaultInstrumentCode;
                	vm.orderDTO.instrumentId = vm.defaultInstrumentId;
                	vm.orderDTO.buySellIndicator = buySellFlag;
                	vm.orderDTO.timeLimitIndicator = vm.userPreference.userTimeCondition;
        		}

            	//fill bank, branch, customer details into order request dto
            	fillBankBranchCustomerDetails()
            	
            	vm.orderDTO.userId = vm.globalValues.getUserId();
            	vm.orderDTO.retailUserCode = vm.globalValues.getUser().login;
            	vm.orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();
            	vm.orderDTO.remarks = angular.copy(vm.remarks);
            	vm.orderDTO.spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, vm.defaultInstrumentCode);

            	if(vm.userPreference.showPreOrderConfirmation=='yes' || vm.userPreference.userTimeCondition=='GTTM'){
                	$rootScope.orderDTO = vm.orderDTO;
                	var closeTime = getCloseTimeForInstrumentCode(vm.orderDTO.instrumentCode);
                	$state.go('pre-order-confirmation', {closeTime: closeTime, showPreOrderConfirmation: vm.userPreference.showPreOrderConfirmation});
            	}else{
            		if(activityIndicator=='PLAC'){
                		placeOrderRequest(vm.orderDTO);
            		}else if(activityIndicator=='MDFY'){
            			Market.modifyOrder(vm.orderDTO);
            		}else if(activityIndicator=='CNCL'){
            			Market.cancelOrder(vm.orderDTO);
            		}
            	}
        	}else{
        		MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}       	
        }
        
        function fillBankBranchCustomerDetails(){
        	if(vm.globalValues.getEntityOwnerType().shortName=='Bank'){
            	vm.orderDTO.branchCode = vm.tradingBranch.branchId;
            	vm.orderDTO.branchId = vm.tradingBranch.id;
            	vm.orderDTO.branchName = vm.tradingBranch.branchName;
            	vm.orderDTO.bankId = vm.globalValues.getBank().id;
            	vm.orderDTO.bankName = vm.globalValues.getBank().bankName;
            	vm.orderDTO.bankMemberNumber = vm.globalValues.getBank().bankMemberNo;
            	vm.orderDTO.customerId = vm.tradingCustomer.id;
            	vm.orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	vm.orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.globalValues.getEntityOwnerType().shortName=='Branch'){
            	vm.orderDTO.branchCode = vm.globalValues.getBranch().branchId;
            	vm.orderDTO.branchId = vm.globalValues.getBranch().id;
            	vm.orderDTO.branchName = vm.globalValues.getBranch().branchName;
            	vm.orderDTO.bankId = vm.globalValues.getBank().id;
            	vm.orderDTO.bankName = vm.globalValues.getBank().bankName;
            	vm.orderDTO.bankMemberNumber = vm.globalValues.getBank().bankMemberNo;
            	vm.orderDTO.customerId = vm.tradingCustomer.id;
            	vm.orderDTO.customerName = vm.tradingCustomer.customerFirstName;
            	vm.orderDTO.customerCode = vm.tradingCustomer.customerCode;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            	vm.orderDTO.branchCode = vm.tradingBranch.branchId;
            	vm.orderDTO.branchId = vm.tradingBranch.id;
            	vm.orderDTO.branchName = vm.tradingBranch.branchName;
            	vm.orderDTO.bankId = vm.tradingBank.id;
            	vm.orderDTO.bankName = vm.tradingBank.bankName;
            	vm.orderDTO.bankMemberNumber = vm.tradingBank.bankMemberNo;
            	vm.orderDTO.customerId = vm.globalValues.getCustomer().id;
            	vm.orderDTO.customerName = vm.globalValues.getCustomer().customerFirstName;
            	vm.orderDTO.customerCode = vm.globalValues.getCustomer().customerCode;
        	}
        }
        
        function getCloseTimeForInstrumentCode(instrument){
        	if(instrument=='SPOT'){
        		return vm.retailSpotMarketSession.sessionTimingsDTO.closeTime;
        	}else if(instrument=='CASH'){
        		return vm.cashMarketSession.sessionTimingsDTO.closeTime;
        	}else if(instrument=='TOM'){
        		return vm.tomMarketSession.sessionTimingsDTO.closeTime;
        	}
        }
        
        function validateOrder(buySellFlag, priceCondtion, selectedBankSpread, instrumentCode, activityIndicator){

        	vm.errorString = "";
        	
        	if(priceCondtion=='STLS'){
            	if(!isAllowedStopLossOrders()){
            		return false;
            	}
        	}
        	
        	if(priceCondtion=='TKPR'){
            	if(!isAllowedTakeProfitOrders()){
            		return false;
            	}
        	}
        	
        	if(!validateOrderAmount(vm.orderDTO.orderAmount)){
        		return false;
        	}
        	
        	//no need to check limits while cancelling orders
        	if(activityIndicator!='CNCL'){
            	if(!validateLimitCheck(vm.orderDTO.oldOrderAmount, activityIndicator, vm.orderDTO.orderAmount, instrumentCode, buySellFlag)){
            		return false;
            	}
        	}
        	
        	//orderticksize
        	//ltp
        	var orderTickSize = getTickSizeForGivenInstrument(instrumentCode);
        	var ltp = 0
        	
        	if(buySellFlag!='SELLBUY'){
            	ltp = getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, buySellFlag, selectedBankSpread);
            	if(!validateLimitExchangeRate(buySellFlag, activityIndicator, priceCondtion, vm.orderDTO.limitExchangeRate, vm.orderDTO.limitExchangeRateInSpot, orderTickSize, ltp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise)){
            		return false;
            	}
            	if(!validateStopExchangeRate(priceCondtion, buySellFlag, vm.orderDTO.limitExchangeRate, vm.orderDTO.stopExchangeRate, orderTickSize)){
            		return false;
            	}
            	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag)){
            		return false;
            	}
        	}else if(buySellFlag=='SELLBUY'){
            	var buyLtp = getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, 'BUYY', selectedBankSpread);
            	var sellLtp = getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, 'SELL', selectedBankSpread);
            	if(!validateLimitExchangeRate('BUYY', activityIndicator, priceCondtion, vm.orderDTO.buyLimitExchangeRate, vm.orderDTO.buyLimitExchangeRateInSpot, orderTickSize, buyLtp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise)){
            		return false;
            	}
            	if(!validateLimitExchangeRate('SELL', activityIndicator, priceCondtion, vm.orderDTO.sellLimitExchangeRate, vm.orderDTO.sellLimitExchangeRateInSpot, orderTickSize, sellLtp, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage, vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise)){
            		return false;
            	}
            	if(!validateStopExchangeRate(priceCondtion, 'BUYY', vm.orderDTO.buyLimitExchangeRate, vm.orderDTO.buyStopExchangeRate, orderTickSize)){
            		return false;
            	}
            	if(!validateStopExchangeRate(priceCondtion, 'SELL', vm.orderDTO.sellLimitExchangeRate, vm.orderDTO.sellStopExchangeRate, orderTickSize)){
            		return false;
            	}
            	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'BUYY')){
               		return false;
               	}
               	if(!validateSpreadUsed(selectedBankSpread, instrumentCode, 'SELL')){
               		return false;
               	}
        	}
        	
        	if(!validateSessionStatus(instrumentCode)){
        		return false;
        	}
        	
        	return true;
        }
        
        function isAllowedStopLossOrders(){
        	if(vm.configSettings.isAllowStopLossOrder=='Yes'){
        		return true;
        	}else{
        		vm.errorString = "Stop loss orders are not allowed.";
        		return false;
        	}
        }
        
        function isAllowedTakeProfitOrders(){
        	if(vm.configSettings.isAllowTakeProfitOrder=='Yes'){
        		return true;
        	}else{
        		vm.errorString = "Take profit orders are not allowed.";
        		return false;
        	}
        }
        
        function validateTradingBankStatus(){
        	CustomerBankRelationshipReport.getTradingBanksForCustomer({customerId : vm.globalValues.getCustomer().id}, function(result){
        		if(result.length>0){
        			angular.forEach(result, function(value){
        				if(value.shortName == vm.tradingBank.shortName){
        					return true;
        				}
        			})
        		}
            	return false;
        	})
        }
        
        //validate session status
        function validateSessionStatus(instrumentCode){
        	var status = "";
        	
        	if(instrumentCode == 'SPOT'){
        		status = vm.retailSpotMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'CASH'){
        		status = vm.cashMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'TOM'){
        		status = vm.tomMarketSession.sessionTimingsDTO.status;
        	}
        	
        	if(status!='OPEN'){
        		vm.errorString = "Session is closed for " + instrumentCode + " market...!"
        		return false;
        	}
        	
        	return true;
        }
        
        //get tick size
        function getTickSizeForGivenInstrument(instrumentCode){
        	if(instrumentCode=='SPOT'){
        		return vm.tickSize.spot;
        	}else if(instrumentCode=='CASH'){
        		return vm.tickSize.cash;
        	}else if(instrumentCode=='TOM'){
        		return vm.tickSize.tom;
        	}
        }
        
        //get ltp for given instrument and buy sell flag
        function getLtpForGivenInstrumentAndBuySellFlag(instrumentCode, buySellFlag, selectedBankSpread){

        	if(instrumentCode!='SPOT'){
        		var spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentCode);
        		if(spreadUsed==null || spreadUsed==undefined || spreadUsed==''){
        			if(vm.retailSpotMarketDepth!=null && vm.retailSpotMarketDepth!=undefined){
        				if(vm.retailSpotMarketDepth.ltp!=null && vm.retailSpotMarketDepth.ltp!=undefined && vm.retailSpotMarketDepth.ltp>0){
                    		return getSpotRateForGivenRateAndSpread(vm.retailSpotMarketDepth.ltp, spreadUsed);
                		}
            		}else{
            			return getSpotRateForGivenRateAndSpread(vm.previousDayClosingPriceForSpot, spreadUsed);
            		}
        		}
        	}else{
        		if(vm.retailSpotMarketDepth!=null && vm.retailSpotMarketDepth!=undefined){
        			if(vm.retailSpotMarketDepth.ltp!=null && vm.retailSpotMarketDepth.ltp!=undefined && vm.retailSpotMarketDepth.ltp>0){
                		return vm.retailSpotMarketDepth.ltp;
            		}
        		}else{
            		return vm.previousDayClosingPriceForSpot;
        		}
        	}
        
        	/*if(instrumentCode=='SPOT'){
        		if(buySellFlag=='SELL'){
            		return vm.retailSpotMarketWatch.bidPrice;
        		}else if(buySellFlag=='BUYY'){
        			return vm.retailSpotMarketWatch.offerPrice;
        		}
        	}else if(instrumentCode=='CASH'){
        		if(buySellFlag=='SELL'){
            		return vm.cashMarketWatch.bidPrice;
        		}else if(buySellFlag=='BUYY'){
        			return vm.cashMarketWatch.offerPrice;
        		}
        	}else if(instrumentCode=='TOM'){
        		if(buySellFlag=='SELL'){
            		return vm.tomMarketWatch.bidPrice;
        		}else if(buySellFlag=='BUYY'){
        			return vm.tomMarketWatch.offerPrice;
        		}
        	}*/
        }
        
        function getLTPOrPreviousDayLTPForInstrumentAndBuySellFlag(instrumentCode, buySellFlag){
        	if(instrumentCode=='SPOT'){
        		if(vm.retailSpotMarketDepth.ltp>0){
            		return vm.retailSpotMarketDepth.ltp;
        		}
        	}else if(instrumentCode=='CASH'){
        		if(vm.cashMarketDepth.ltp>0){
            		return vm.cashMarketDepth.ltp;
        		}
        	}else if(instrumentCode=='TOM'){
        		if(vm.tomMarketDepth.ltp>0){
            		return vm.tomMarketDepth.ltp;
        		}
        	}
        	return vm.previousDayClosingPriceForSpot;
        }
        
        //validate order amount
        function validateOrderAmount(orderAmount){
        	//orderAmount = orderAmount.replace(/,/g, '');
        	if(Number(orderAmount)==0){
        		vm.errorString = 'Order value must be greater than zero...! ';
        		return false;
        	}else if(Number(orderAmount) < 0){
        		vm.errorString = 'Order value must be positive...!';
        		return false;
        	}
        	else if(Number(orderAmount) < Number(vm.configSettings.minimumOrderValue)){
        		vm.errorString = 'Order amount should not be less than minimum order value ' + $filter('number')(vm.configSettings.minimumOrderValue, 0) + '...!';
        		return false;
        	}else if(Number(orderAmount) > Number(vm.configSettings.maximumOrderValue)){
        		vm.errorString = 'Order amount should not be greater than maximum order value ' + $filter('number')(vm.configSettings.maximumOrderValue, 0) + '...!';
        		return false;
        	}
        	else if(((Math.round(parseFloat(orderAmount) * 10000)) % (Math.round(parseFloat(vm.configSettings.orderLotSize) * 10000)))!=0){
        		vm.errorString = "Order value should be in multiples of lot size " + vm.configSettings.orderLotSize + "...!";
        		return false;
        	}
        	return true;
        }
        
        function validateLimitCheck(oldOrderAmount, activityIndicator, orderAmount, instrumentCode, buySellFlag){
        	if(buySellFlag=='SELL'){
        		if(!validateSellLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount)){
        			vm.errorString = "Sell trading limit breached. Order entry not possible...!";
        			return false;
        		}
        	}else if(buySellFlag=='BUYY'){
        		if(!validateBuyLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount)){
        			vm.errorString = "Buy trading limit breached. Order entry not possible...!";
        			return false;
        		}
        	}else if(buySellFlag=='SELLBUY'){
        		if(instrumentCode=='SPOT'){
        			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, vm.retailSpotBuyLimit, vm.retailSpotSellLimit)){
        				return false;
        			}
        		}else if(instrumentCode=='CASH'){
        			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, vm.cashBuyLimit, vm.cashSellLimit)){
        				return false;
        			}
        		}else if(instrumentCode=='TOM'){
        			if(!validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, vm.tomBuyLimit, vm.tomSellLimit)){
        				return false;
        			}
        		}
        	}
        	return true;
        }
        
        /*
         * validate limits for sell and buy order
         */
        function validateLimitForeSellAndBuyOrder(instrumentCode, orderAmount, buyLimit, sellLimit){
        	if(vm.limitType == 'GLOBALGROSS' && orderAmount > (buyLimit/2)){
				vm.errorString = "Global gross limit breached. Order entry is not possible...!";
				return false;
			}
    		if(orderAmount > buyLimit){
    			vm.errorString = "Buy trading limit breached. Order entry is not possible...!";
    			return false;
    		}else if(orderAmount > sellLimit){
    			vm.errorString = "Sell trading limit breached. Order entry is not possible...!";
    			return false;
    		}
    		return true;
        }
        
        /*
         * validate sell limit
         */
        function validateSellLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount){
        	if(activityIndicator=='PLAC'){
        		if(instrumentCode=='SPOT'){
            		if(orderAmount > vm.retailSpotSellLimit){
            			return false;
            		}
        		}else if(instrumentCode=='CASH'){
            		if(orderAmount > vm.cashSellLimit){
            			return false;
            		}
        		}else if(instrumentCode=='TOM'){
            		if(orderAmount > vm.tomSellLimit){
            			return false;
            		}
        		}
        	}else if(activityIndicator=='MDFY'){
        		if(instrumentCode=='SPOT'){
            		if(orderAmount > vm.retailSpotSellLimit + oldOrderAmount){
            			return false;
            		}
        		}else if(instrumentCode=='CASH'){
            		if(orderAmount > vm.cashSellLimit + oldOrderAmount){
            			return false;
            		}
        		}else if(instrumentCode=='TOM'){
            		if(orderAmount > vm.tomSellLimit + oldOrderAmount){
            			return false;
            		}
        		}
        	}
        	return true;
        }
        
        /*
         * validate buy limit 
         */
        function validateBuyLimit(oldOrderAmount, activityIndicator, instrumentCode, orderAmount){
        	if(activityIndicator=='PLAC'){
        		if(instrumentCode=='SPOT'){
            		if(orderAmount > vm.retailSpotBuyLimit){
            			return false;
            		}
        		}else if(instrumentCode=='CASH'){
            		if(orderAmount > vm.cashBuyLimit){
            			return false;
            		}
        		}else if(instrumentCode=='TOM'){
            		if(orderAmount > vm.tomBuyLimit){
            			return false;
            		}
        		}
        	}else if(activityIndicator=='MDFY'){
        		if(instrumentCode=='SPOT'){
            		if(orderAmount > vm.retailSpotBuyLimit + oldOrderAmount){
            			return false;
            		}
        		}else if(instrumentCode=='CASH'){
            		if(orderAmount > vm.cashBuyLimit + oldOrderAmount){
            			return false;
            		}
        		}else if(instrumentCode=='TOM'){
            		if(orderAmount > vm.tomBuyLimit + oldOrderAmount){
            			return false;
            		}
        		}
        	}
    		
    		return true;
        }
        
        function validateLimitExchangeRate(buySellFlag, activityIndicator, priceCondition, limitExchangeRate,limitExchangeRateInSpot, orderTickSize, ltp, allowedOrderPriceDeviationInPercent, allowedOrderPriceDeviationFromMarketPriceInPaise){
        	ltp = Number(ltp);
        	if(priceCondition!='MRKT'){
            	if(Number(limitExchangeRate)<0){
            		if(buySellFlag=='BUYY'){
                		vm.errorString = 'Buy limit price must be positive...!';
            		}else{
                		vm.errorString = 'Sell limit price must be positive...!';
            		}
            		return false;
            	}
        	}
        	
        	if((priceCondition!='MRKT' && Number(limitExchangeRate)==0) || (priceCondition=='MRKT' && activityIndicator=='MDFY' && Number(limitExchangeRate)==0)){
        		if(buySellFlag=='BUYY'){
            		vm.errorString = 'Buy limit price must be greater than zero...!';
        		}else{
            		vm.errorString = 'Sell limit price must be greater than zero...!';
        		}
        		return false;
        	}
        	var tickSizeRemainder = (Math.round(parseFloat(limitExchangeRate)*10000)) % (Math.round(parseFloat(orderTickSize)*10000));
        	if(tickSizeRemainder!=0){
        		if(buySellFlag=='BUYY'){
            		vm.errorString = 'Buy limit price should be in multiples of tick size ' + Number(orderTickSize) + '...!';
        		}else{
            		vm.errorString = 'Sell limit price should be in multiples of tick size ' + Number(orderTickSize) + '...!';
        		}
        		return false;
        	}
        	var higherCutOffPrice = vm.previousDayClosingPriceForSpot + (vm.previousDayClosingPriceForSpot * (allowedOrderPriceDeviationInPercent/100));
        	var lowerCutOffPrice = vm.previousDayClosingPriceForSpot - (vm.previousDayClosingPriceForSpot * (allowedOrderPriceDeviationInPercent/100));
        	
        	if(ltp>0 && ltp!=undefined && ltp!=null){
            	higherCutOffPrice = ltp + (ltp * (allowedOrderPriceDeviationInPercent/100));
            	lowerCutOffPrice = ltp - (ltp * (allowedOrderPriceDeviationInPercent/100));
        	}
        	
        	 
        	if(priceCondition!='MRKT' && (Math.round(parseFloat(limitExchangeRateInSpot)*10000) < Math.round(parseFloat(lowerCutOffPrice)*10000) ||  Math.round(parseFloat(limitExchangeRateInSpot)*10000) > Math.round(parseFloat(higherCutOffPrice)*10000))){
        		if(buySellFlag=='BUYY'){
            		vm.errorString = 'Buy limit price deviated from allowed order price...!';
        		}else{
            		vm.errorString = 'Sell limit price deviated from allowed order price...!';
        		}
        		return false;
        	}
        	
        	return true;
        }
        
        function validateStopExchangeRate(priceCondition, buySellFlag, limitExchangeRate, stopExchangeRate, orderTickSize){
        	if(Number(stopExchangeRate)<0){
        		vm.errorString = 'Trigger price must be positive...!';
        		return false;
        	}
        	
        	if(priceCondition!='MRKT' && priceCondition!='LIMT' && Number(stopExchangeRate)==0){
        		vm.errorString = 'Trigger price cannot be zero...!';
        		return false;
        	}
        	
        	if(priceCondition=='STLS' || priceCondition=='TKPR'){
        		if(buySellFlag=='BUYY' && Number(stopExchangeRate) > Number(limitExchangeRate)){
        			vm.errorString = 'Trigger price should be less than or equal to the limit price...!';
        			return false;
        		}
        		if(buySellFlag=='SELL' && Number(stopExchangeRate) < Number(limitExchangeRate)){
        			vm.errorString = 'Trigger price should be greater than or equal to the limit price...!';
        			return false;
        		}
        		var tickSizeRemainder = (Math.round(parseFloat(stopExchangeRate)*10000)) % (Math.round(parseFloat(orderTickSize)*10000));
            	if(tickSizeRemainder!=0){
            		vm.errorString = 'Trigger price should be in multiples of tick size ' + Number(orderTickSize) + '...!';
            		return false;
            	}
        	}
        	return true;
        }
        
        function validateSpreadUsed(selectedBankSpread, instrumentCode, buySellFlag){
        	if(instrumentCode!='SPOT'){
        		var spreadUsed = getSpreadUsed(selectedBankSpread, buySellFlag, instrumentCode);
        		if(spreadUsed==null || spreadUsed==undefined || spreadUsed==''){
        			if(buySellFlag=='BUYY'){
        				if(instrumentCode=='CASH'){
                			vm.errorString = 'CASH/SPOT bid spread is not given by the bank. Order entry not possible...!';
        				}else if(instrumentCode=='TOM'){
                			vm.errorString = 'TOM/SPOT bid spread is not given by the bank. Order entry not possible...!';
        				}
        			}else if(buySellFlag=='SELL'){
        				if(instrumentCode=='CASH'){
                			vm.errorString = 'CASH/SPOT offer spread is not given by the bank. Order entry not possible...!';
        				}else if(instrumentCode=='TOM'){
                			vm.errorString = 'TOM/SPOT offer spread is not given by the bank. Order entry not possible...!';
        				}
        			}
        			return false;
        		}
        	}
    		return true;
        }
        
        function getSpreadUsed(selectedBankSwapQuote, buySellFlag, instrumentCode){
        	if(buySellFlag=='BUYY'){
        		if(instrumentCode=='CASH'){
        			return selectedBankSwapQuote.cashSpotBidSpread;
        		}else if(instrumentCode=='TOM'){
        			return selectedBankSwapQuote.tomSpotBidSpread;
        		}
        	}else if(buySellFlag=='SELL'){
        		if(instrumentCode=='CASH'){
        			return selectedBankSwapQuote.cashSpotOfferSpread;
        		}else if(instrumentCode=='TOM'){
        			return selectedBankSwapQuote.tomSpotOfferSpread;
        		}
        	}
        }
        
        function placeOrderRequest(orderDTO){
        	if(orderDTO.buySellIndicator!='SELLBUY'){
        		Market.placeOrder(orderDTO, onPlaceOrderSuccess, onPlaceOrderFailure);
        	}else if(orderDTO.buySellIndicator=='SELLBUY'){
        		var sellOrderDTO = getSellOrderDTO();
        		Market.placeOrder(sellOrderDTO);
        		var buyOrderDTO = getBuyOrderDTO();
        		Market.placeOrder(buyOrderDTO, onPlaceOrderSuccess, onPlaceOrderFailure);
        	}
        }
        
        function onPlaceOrderSuccess(result){
        	vm.remarks = "";
			fillDefaultValuesToControls();
        }
        
        function onPlaceOrderFailure(){
        	
        }
        
        function getBuyOrderDTO(orderDTO){
        	var buyOrderDTO = angular.copy(orderDTO);
        	buyOrderDTO.buySellIndicator = 'BUYY';
        	buyOrderDTO.limitExchangeRate = orderDTO.buyLimitExchangeRate;
        	buyOrderDTO.stopExchangeRate = orderDTO.buyStopExchangeRate;
        	return buyOrderDTO;
        }
        
        function getSellOrderDTO(orderDTO){
        	var sellOrderDTO = angular.copy(orderDTO);
        	sellOrderDTO.buySellIndicator = 'SELL';
        	sellOrderDTO.limitExchangeRate = orderDTO.sellLimitExchangeRate;
        	sellOrderDTO.stopExchangeRate = orderDTO.sellStopExchangeRate;
        	return sellOrderDTO;
        }
        
        function getReportDetailsForSelectedInstrument(instrumentTypeId){
        	
        	getPendingOrderReportForInstrument(instrumentTypeId);
        	getLimitUtilisedReportForInstrument(instrumentTypeId);
        	getTradesReportForInstrument(instrumentTypeId);
        	
    	}
        
        function getBankIdForReportFilters(reportType){
        	var bankId = 0;
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP' || vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		if(reportType=='PO'){
            		if(vm.bankFilterForPO!=null){
            			bankId = vm.bankFilterForPO.id;
            		}
        		}else if(reportType=='Limit'){
            		if(vm.bankFilterForLimit!=null){
            			bankId = vm.bankFilterForLimit.id;
            		}
        		}else if(reportType=='Trade'){
            		if(vm.bankFilterForTrade!=null){
            			bankId = vm.bankFilterForTrade.id;
            		}
        		}
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
        		bankId = vm.account.organisation.id;
        	}
        	
        	return bankId;
        }
        
        function getBranchIdForReportFilters(reportType){
        	var branchId = 0;
        	if(reportType=='PO'){
        		if(vm.branchFilterForPO!=null){
        			branchId = vm.branchFilterForPO.id;
        		}
    		}else if(reportType=='Limit'){
        		if(vm.branchFilterForLimit!=null){
        			branchId = vm.branchFilterForLimit.id;
        		}
    		}else if(reportType=='Trade'){
        		if(vm.branchFilterForTrade!=null){
        			branchId = vm.branchFilterForTrade.id;
        		}
    		}
        	return branchId;
        }
        
        function getCustomerIdForReportFilters(reportType){
        	var customerId = 0;
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP' || vm.account.organisation.organisationType.shortName=='BANK'){
        		if(reportType=='PO'){
            		if(vm.customerFilterForPO!=null){
            			customerId = vm.customerFilterForPO.id;
            		}
        		}else if(reportType=='Limit'){
            		if(vm.customerFilterForLimit!=null){
            			customerId = vm.customerFilterForLimit.id;
            		}
        		}else if(reportType=='Trade'){
            		if(vm.customerFilterForTrade!=null){
            			customerId = vm.customerFilterForTrade.id;
            		}
        		}
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		customerId = vm.globalValues.getCustomerId();
        	}
        	return customerId;
        }
        
        $scope.$watch('vm.tradingBank', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
    					vm.tradingBranch = result;
    				})
            	}
            	
            	vm.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes(vm.bankComparativeQuotes, vm.tradingBank);
            	
            	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
            		if(vm.defaultTradingCustomer!=null && vm.defaultTradingCustomer!=undefined){
                    	getLimitsAvailableForAllInstruments(vm.tradingBank.id, vm.defaultTradingCustomer.id);
            		}
            	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
            		if(vm.defaultTradingCustomer!=null && vm.defaultTradingCustomer!=undefined){
                    	getLimitsAvailableForAllInstruments(vm.globalValues.getOrganisation().id, vm.defaultTradingCustomer.id);
            		}
            	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
                	getLimitsAvailableForAllInstruments(vm.tradingBank.id, vm.globalValues.getCustomer().id);
            	}
        	}
        })
        
        $scope.$watch('vm.bankFilterForLimit', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
    					vm.branchFilterForLimit = result;
    				})
            	}
        	}
        })
        
        $scope.$watch('vm.bankFilterForPO', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
    					vm.branchFilterForPO = result;
    				})
            	}
        	}
        })
        
         $scope.$watch('vm.bankFilterForTrade', function(value){
        	if(vm.account!=null && vm.account!=undefined && value!=null && value!=undefined){
            	if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
            		CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
    					vm.branchFilterForTrade = result;
    				})
            	}
        	}
        })
        
        //modify selected order
        function modifyOrder(pendingOrder){
        	prepareOrderDTO(pendingOrder, 'MDFY');
        }
        
        //re-assing default values to controls
        function cancelModifyOrderAction(){
        	fillDefaultValuesToControls();
        }
        
        function getCustomerId(){
        	if(vm.account.organisation.organisationType.shortName=='CLEARCORP'){
            	return vm.defaultTradingCustomer.id;
        	}else if(vm.account.organisation.organisationType.shortName=='BANK'){
            	return vm.defaultTradingCustomer.id;
        	}else if(vm.account.organisation.organisationType.shortName=='CUSTOMER'){
        		vm.globalValues.getCustomer().id
        	}
        }
        
        //prepare orderDTO to modify/cancel order from pending order details
        function prepareOrderDTO(pendingOrder, activityIndicator){
        	vm.orderDTO.activityIndicator = activityIndicator;
        	vm.orderDTO.orderAmount = pendingOrder.pendingOrderAmount;
        	vm.orderDTO.typeOfOrder = pendingOrder.typeOfOrder;
        	vm.orderDTO.instrumentCode = pendingOrder.instrumentCode;
        	vm.orderDTO.buySellIndicator = pendingOrder.buySellInd;
        	vm.orderDTO.timeLimitIndicator = pendingOrder.timeLimitIndicator;
        	if(vm.orderDTO.timeLimitIndicator=='GTTM'){
            	vm.orderDTO.expiryTime = pendingOrder.expiryTime;
        	}
        	vm.orderDTO.instrumentId = pendingOrder.retailOrderInstID;
        	vm.orderDTO.branchCode = pendingOrder.branchCode;
        	vm.orderDTO.branchId = pendingOrder.branchId;
        	vm.orderDTO.bankId = pendingOrder.bankId;
        	vm.orderDTO.bankName = pendingOrder.bankName;
        	vm.orderDTO.customerId = pendingOrder.customerId;
        	vm.orderDTO.customerName = pendingOrder.customerName;
        	vm.orderDTO.limitExchangeRate = pendingOrder.limitExchangeRate;
        	vm.orderDTO.stopExchangeRate = pendingOrder.stopExchangeRate;
        	vm.orderDTO.orderSequenceNumber = pendingOrder.orderSequenceNumber;
        	vm.orderDTO.oldOrderAmount = pendingOrder.oldOrderAmount;
        	if(activityIndicator!='PLAC'){
        		vm.orderDTO.revisionTimeStamp = pendingOrder.revisiontTimestampString;
            	vm.orderDTO.orderStatusId = pendingOrder.orderStatusId;
        	}
        	if(pendingOrder.typeOfOrder=='MRKT'){
        		vm.orderDTO.typeOfOrder = 'LIMT';
        		vm.defaultPriceCondition = 'LIMT';
        		vm.orderDTO.limitExchangeRate = getDefaultLimitPriceForModifyingMarketOrder(pendingOrder.instrumentCode, pendingOrder.buySellInd);
        	}

        	setDefaultPriceCondition(pendingOrder.typeOfOrder);
        	changeInstrument(pendingOrder.instrumentCode);
        	changeOrderType(pendingOrder.buySellInd, vm.orderDTO.activityIndicator);
        	changeTradingBank(pendingOrder.bankId);
        }
        
        function getDefaultLimitPriceForModifyingMarketOrder(instrumentCode, buySellFlag){
    		if(buySellFlag=='BUYY'){
            	if(instrumentCode=='SPOT'){
            		return vm.retailSpotMarketWatch.bidPrice;
            	}else if(instrumentCode=='CASH'){
            		return vm.cashMarketWatch.bidPrice;
            	}else if(instrumentCode=='TOM'){
            		return vm.tomMarketWatch.bidPrice;
            	}
    			
    		}else if(buySellFlag=='SELL'){
    			if(instrumentCode=='SPOT'){
            		return vm.retailSpotMarketWatch.offerPrice;
            	}else if(instrumentCode=='CASH'){
            		return vm.cashMarketWatch.offerPrice;
            	}else if(instrumentCode=='TOM'){
            		return vm.tomMarketWatch.offerPrice;
            	}
    		}
        	
        }
        
        function prepareOrderDTOToCancel(pendingOrder){
        	var orderDTO = {};
        	orderDTO.activityIndicator = 'CNCL';
        	orderDTO.orderAmount = pendingOrder.pendingOrderAmount;
        	orderDTO.typeOfOrder = pendingOrder.typeOfOrder;
        	orderDTO.instrumentCode = pendingOrder.instrumentCode;
        	orderDTO.instrumentId = pendingOrder.retailOrderInstID;
        	orderDTO.buySellIndicator = pendingOrder.buySellInd;
        	orderDTO.timeLimitIndicator = pendingOrder.timeLimitIndicator;
        	if(vm.orderDTO.timeLimitIndicator=='GTTM'){
            	vm.orderDTO.expiryTime = pendingOrder.expiryTime;
        	}
        	orderDTO.branchCode = pendingOrder.branchCode;
        	orderDTO.branchId = pendingOrder.branchId;
        	orderDTO.bankId = pendingOrder.bankId;
        	orderDTO.bankMemberNumber = pendingOrder.bankMemberNumber;
        	orderDTO.bankName = pendingOrder.bankName;
        	orderDTO.customerId = pendingOrder.customerId;
        	orderDTO.customerName = pendingOrder.customerName;
        	orderDTO.customerCode = pendingOrder.customerCode;
        	orderDTO.limitExchangeRate = pendingOrder.limitExchangeRate;
        	orderDTO.stopExchangeRate = pendingOrder.stopExchangeRate;
        	orderDTO.orderSequenceNumber = pendingOrder.orderSequenceNumber;
        	orderDTO.userId = vm.globalValues.getUserId();
        	orderDTO.retailUserCode = vm.globalValues.getUser().login;
        	orderDTO.userEntityOwnerId = vm.globalValues.getEntityOwnerTypeId();

        	var selectedBankSpread = MarketWatch.getDefaultBankSwapQuotes(vm.bankComparativeQuotes, vm.tradingBank);
        	orderDTO.spreadUsed = getSpreadUsed(selectedBankSpread, pendingOrder.buySellInd, pendingOrder.instrumentCode);

        	orderDTO.revisionTimeStamp = pendingOrder.revisiontTimestampString;        	
        	return orderDTO;
        }
        
        function changeTradingBank(bankId){
        	BankRegistration.get({id: bankId}, function(result){
        		vm.tradingBank = result;
        	})
        }
        
        //to cancel order
        function cancelOrder(pendingOrder){
        	prepareOrderDTO(pendingOrder, 'CNCL');
        }
        
        $scope.$watch('vm.selectAll', function(value){
        	if(value==true){
            	angular.forEach(vm.pendingOrders, function(value, index){
            		if(value.orderStatus!="Risk Validated"){
                		vm.pendingOrders[index].select = true;
            		}
            	})
        	}else{
            	angular.forEach(vm.pendingOrders, function(value, index){
            		vm.pendingOrders[index].select = false;
            	})
        	}
        })
        
        function cancelAllOrders(){
        	var ordersSelected = false;
        	angular.forEach(vm.pendingOrders, function(value){
        		if(value.select==true){
        			ordersSelected = true;
        		}
        	});
        	if(ordersSelected==true){
            	$scope.pendingOrders = vm.pendingOrders;
            	MessageBox.openMessageBox("Alert", "Do you want to proceed to cancel selected order(s)?", "ProceedCancel", "cancelselectedorders");
        	}else{
            	MessageBox.openMessageBox("Alert", "Please select the order(s) to proceed.", "Ok", "selectedorders");
        	}
        }
        
		$rootScope.$on('gatewayApp.messagebox.proceed.cancelselectedorders', function (event, data) {
        	angular.forEach($scope.pendingOrders, function(value){
        		if(value.select){
        			var orderDTO = {};
        			orderDTO = prepareOrderDTOToCancel(value);
        			//place cancel order
        			Market.cancelOrder(orderDTO);
        		}
        	})
    	});
		
		$rootScope.$on('gatewayApp.messagebox.cancel.cancelselectedorders', function (event, data) {
			//do not proceed
		});
		

        
        function changeOrderType(buySellFlag, activityIndicator){
        	if(buySellFlag=='BUYY' && activityIndicator=='PLAC'){
            	vm.orderType = 'BUYY';
            	setTimeout(function(){document.getElementById('buyTab').click()});
        		//document.getElementById('buyTab').click();
        	}else if(buySellFlag=='SELL' && activityIndicator=='PLAC'){
        		vm.orderType = 'SELL';
            	setTimeout(function(){document.getElementById('sellTab').click()});
        		//document.getElementById('sellTab').click();
        	}else if(buySellFlag=='BUYY' && activityIndicator=='MDFY'){
            	vm.orderType = 'MODIFYBUY';
            	setTimeout(function(){document.getElementById('modifybuyTab').click()});
        		//document.getElementById('buyTab').click();
        	}else if(buySellFlag=='SELL' && activityIndicator=='MDFY'){
        		vm.orderType = 'MODIFYSELL';
            	setTimeout(function(){document.getElementById('modifysellTab').click()});
        		//document.getElementById('sellTab').click();
        	}else if(buySellFlag=='BUYY' && activityIndicator=='CNCL'){
            	vm.orderType = 'CANCELBUY';
            	setTimeout(function(){document.getElementById('modifybuyTab').click()});
        		//document.getElementById('buyTab').click();
        	}else if(buySellFlag=='SELL' && activityIndicator=='CNCL'){
        		vm.orderType = 'CANCELSELL';
            	setTimeout(function(){document.getElementById('modifysellTab').click()});
        		//document.getElementById('sellTab').click();
        	}
        }
        
        //get equivalent spot price for limit and trigger prices
        $scope.$watchGroup(['vm.orderDTO.limitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType', 'vm.tradingBank'], function(value){
        	if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
            	if(vm.defaultInstrumentCode=='CASH'){
            		if(vm.orderType=='SELL' || vm.orderType=='MODIFYSELL'){
                    	vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, vm.defaultBankSpread.cashSpotOfferSpread); 
                    		
            		}else if(vm.orderType=='BUYY' || vm.orderType=='MODIFYBUY'){
                    	vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, vm.defaultBankSpread.cashSpotBidSpread); 
            		}
            	}else if(vm.defaultInstrumentCode=='TOM'){
                		if(vm.orderType=='SELL' || vm.orderType=='MODIFYSELL'){
                        	vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, vm.defaultBankSpread.tomSpotOfferSpread);  
                		}else if(vm.orderType=='BUYY' || vm.orderType=='MODIFYBUY'){
                        	vm.orderDTO.limitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.limitExchangeRate, vm.defaultBankSpread.tomSpotBidSpread); 
                		}
                }else{
                	vm.orderDTO.limitExchangeRateInSpot = vm.orderDTO.limitExchangeRate;
                }
            	
            	
            	if(vm.defaultPriceCondition=='STLS' || vm.defaultPriceCondition=='TKPR'){
            		//limit, stop price default
            	}
        	}
        	
        })
        
        function softCheckDeviationForLimitExchangeRate(value){
        	vm.limitPriceDeviationWarning = '';
        	if(value!=null && value!=undefined && value!=''){
            	var higherCutOffPriceSoft = vm.previousDayClosingPriceForSpot + Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = vm.previousDayClosingPriceForSpot - Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpForGivenInstrumentAndBuySellFlag(vm.defaultInstrumentCode, vm.orderType, vm.defaultBankSpread);
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	if(vm.defaultPriceCondition!='MRKT' && (Math.round(parseFloat(vm.orderDTO.limitExchangeRateInSpot)*10000) < Math.round(parseFloat(lowerCutOffPriceSoft)*10000) 
            			|| Math.round(parseFloat(vm.orderDTO.limitExchangeRateInSpot)*10000) > Math.round(parseFloat(higherCutOffPriceSoft)*10000))){
            		vm.limitPriceDeviationWarning = 'Deviates by more than ' + vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise + ' paise from the LTP';
            	}
        	}
        }
        
        function softCheckDeviationForBuyLimitExchangeRate(value){
        	vm.buyLimitPriceDeviationWarning = '';
        	if(value!=null && value!=undefined && value!=''){
            	var higherCutOffPriceSoft = vm.previousDayClosingPriceForSpot + Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = vm.previousDayClosingPriceForSpot - Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpForGivenInstrumentAndBuySellFlag(vm.defaultInstrumentCode, vm.orderType, vm.defaultBankSpread);
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	if(vm.defaultPriceCondition!='MRKT' && (Math.round(parseFloat(vm.orderDTO.buyLimitExchangeRateInSpot)*10000) < Math.round(parseFloat(lowerCutOffPriceSoft)*10000) 
            			|| Math.round(parseFloat(vm.orderDTO.buyLimitExchangeRateInSpot)*10000) > Math.round(parseFloat(higherCutOffPriceSoft)*10000))){
            		vm.buyLimitPriceDeviationWarning = 'Deviates by more than ' + vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise + ' paise from the LTP';
            	}
        	}
        }
        
        function softCheckDeviationForSellLimitExchangeRate(value){
        	vm.sellLimitPriceDeviationWarning = '';
        	if(value!=null && value!=undefined && value!=''){
            	var higherCutOffPriceSoft = vm.previousDayClosingPriceForSpot + Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var lowerCutOffPriceSoft = vm.previousDayClosingPriceForSpot - Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100);
            	var ltp = getLtpForGivenInstrumentAndBuySellFlag(vm.defaultInstrumentCode, vm.orderType, vm.defaultBankSpread);
            	if(ltp>0 && ltp!=undefined && ltp!=null){
                	higherCutOffPriceSoft = ltp + (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
                	lowerCutOffPriceSoft = ltp - (Number(vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise/100));
            	}
            	
            	if(vm.defaultPriceCondition!='MRKT' && (Math.round(parseFloat(vm.orderDTO.sellLimitExchangeRateInSpot)*10000) < Math.round(parseFloat(lowerCutOffPriceSoft)*10000) 
            			|| Math.round(parseFloat(vm.orderDTO.sellLimitExchangeRateInSpot)*10000) > Math.round(parseFloat(higherCutOffPriceSoft)*10000))){
            		vm.sellLimitPriceDeviationWarning = 'Deviates by more than ' + vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise + ' paise from the LTP';
            	}
        	}
        }
        
        //stop exchange rate
        $scope.$watchGroup(['vm.orderDTO.stopExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType'], function(value){
        	if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
            	if(vm.defaultInstrumentCode=='CASH'){
            		if(vm.orderType=='SELL' || vm.orderType=='MODIFYSELL'){
                    	vm.orderDTO.stopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.stopExchangeRate, vm.defaultBankSpread.cashSpotOfferSpread); 
            		}else if(vm.orderType=='BUYY' || vm.orderType=='MODIFYBUY'){
                    	vm.orderDTO.stopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.stopExchangeRate, vm.defaultBankSpread.cashSpotBidSpread);
            		}
            	}else if(vm.defaultInstrumentCode=='TOM'){
                		if(vm.orderType=='SELL' || vm.orderType=='MODIFYSELL'){
                        	vm.orderDTO.stopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.stopExchangeRate, vm.defaultBankSpread.tomSpotOfferSpread);
                		}else if(vm.orderType=='BUYY' || vm.orderType=='MODIFYBUY'){
                        	vm.orderDTO.stopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.stopExchangeRate, vm.defaultBankSpread.tomSpotBidSpread);
                		}
                }else{
                	vm.orderDTO.stopExchangeRateInSpot = vm.orderDTO.stopExchangeRate;
                }
            	
        	}
        })
        
        //buy limit exchange rate
        $scope.$watchGroup(['vm.orderDTO.buyLimitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType'], function(value){
        	if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
            	if(vm.defaultInstrumentCode=='CASH'){
            		vm.orderDTO.buyLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyLimitExchangeRate, vm.defaultBankSpread.cashSpotBidSpread);
            	}else if(vm.defaultInstrumentCode=='TOM'){
            		vm.orderDTO.buyLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyLimitExchangeRate, vm.defaultBankSpread.tomSpotBidSpread); 
                }else{
                	vm.orderDTO.buyLimitExchangeRateInSpot = vm.orderDTO.buyLimitExchangeRate;
                }
        	}
        	
        	if(vm.userPreference!=null && vm.userPreference!=undefined){
        		if(vm.userPreference.userBidSpread!=null && vm.userPreference.userBidSpread!=undefined){
            		vm.orderDTO.sellLimitExchangeRate = Number(value[0]) + Number(vm.userPreference.userBidSpread);
            	}
        	}
        })
        
        //buy stop exchange rate
        $scope.$watchGroup(['vm.orderDTO.buyStopExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType'], function(value){
        	if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
            	if(vm.defaultInstrumentCode=='CASH'){
            		vm.orderDTO.buyStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyStopExchangeRate, vm.defaultBankSpread.cashSpotBidSpread); 
            	}else if(vm.defaultInstrumentCode=='TOM'){
            			vm.orderDTO.buyStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.buyStopExchangeRate, vm.defaultBankSpread.tomSpotBidSpread);  
                }else{
                	vm.orderDTO.buyStopExchangeRateInSpot = vm.orderDTO.buyStopExchangeRate;
                }
        	}
        })
        
        //sell limit exchange rate
        $scope.$watchGroup(['vm.orderDTO.sellLimitExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType'], function(value){
        	if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
            	if(vm.defaultInstrumentCode=='CASH'){
            		vm.orderDTO.sellLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellLimitExchangeRate, vm.defaultBankSpread.cashSpotOfferSpread); 
            	}else if(vm.defaultInstrumentCode=='TOM'){
            		vm.orderDTO.sellLimitExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellLimitExchangeRate, vm.defaultBankSpread.tomSpotOfferSpread); 
                }else{
                	vm.orderDTO.sellLimitExchangeRateInSpot = vm.orderDTO.sellLimitExchangeRate;
                }
        	}
        })
        
        //sell stop exchange rate
        $scope.$watchGroup(['vm.orderDTO.sellStopExchangeRate', 'vm.defaultInstrumentCode', 'vm.orderType'], function(value){
        	if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
            	if(vm.defaultInstrumentCode=='CASH'){
            		vm.orderDTO.sellStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellStopExchangeRate, vm.defaultBankSpread.cashSpotOfferSpread);
            	}else if(vm.defaultInstrumentCode=='TOM'){
            			vm.orderDTO.sellStopExchangeRateInSpot = getSpotRateForGivenRateAndSpread(vm.orderDTO.sellStopExchangeRate, vm.defaultBankSpread.tomSpotOfferSpread); 
                }else{
                	vm.orderDTO.sellStopExchangeRateInSpot = vm.orderDTO.sellStopExchangeRate;
                }
        	}
        })
        
        function getSpotRateForGivenRateAndSpread(rate, spread){
        	var spotRate = Number(rate) + Number(spread)/100;
        	return spotRate;
        }
        
        function marketDepthChanges(marketDepthDTO){
        	setTimeout(function(){
        	$scope.$apply(function(){
        		var marketDepth = {};
            	var existingMarketDepth = vm.marketDepth;
            	angular.forEach(existingMarketDepth, function(value, index){
            		if(value.instrumentID==marketDepthDTO.instrumentID){
            			marketDepth = MarketWatch.getMarketDepth(marketDepthDTO);
            			existingMarketDepth[index] = marketDepth;
            		}
            	});
            	
            	vm.marketDepth = existingMarketDepth;
            	
            	getMarketDepthDetailsForReport();
            	//set previous ticker price
            	var previousTickerPrice = angular.copy(vm.tickerPrice);
            	vm.previousTickerPrice = previousTickerPrice;
            	//get ticker price
            	vm.tickerPrice = MarketWatch.getTickerPrice(marketDepth, previousTickerPrice, vm.previousDayClosingPriceForSpot, vm.previousDayClosingPriceForIbSpot);
            	$rootScope.tickerPrice = vm.tickerPrice;
        	})
        	})
        }
        
        function marketSettingsChanges(marketSessionSettings){
        	vm.marketSessionSettings = marketSessionSettings;
        	setMarketSettings();
        }
        
        //update pending order report in home page
        function pendingOrdersFeed(pendingOrder){
        	//get pending orders from feed
        	setTimeout(function(){
        	$scope.$apply(function(){
            	var existingPendingOrders = [];
            	if(vm.pendingOrders!=null && vm.pendingOrders!=undefined){
            		if(vm.pendingOrders.length>0){
            			existingPendingOrders = angular.copy(vm.pendingOrders);
            		}
            	}
            	var merged = false;
            	if(pendingOrder!=null && pendingOrder!=undefined){
            		if(existingPendingOrders!=null && existingPendingOrders!= undefined){
            			if(existingPendingOrders.length>0){
                        	angular.forEach(existingPendingOrders, function(value, index){
                        		//if new order placed, order seq no will be 0 initially before the order confirmed 
                        		if(value.clientOrderID==pendingOrder.clientOrderID){
                        			existingPendingOrders[index] = pendingOrder;
                        			merged = true;
                        		}else if(value.orderSequenceNumber==pendingOrder.clientOrderID){
                        			existingPendingOrders[index] = pendingOrder;
                        			merged = true;
                        		}else if(value.orderSequenceNumber==pendingOrder.orderSequenceNumber){
                        			existingPendingOrders[index] = pendingOrder;
                        			merged = true;
                        		}
                        	});
            			}
            		}else{
            			existingPendingOrders.push(pendingOrder);
            			merged = true;
            		}
            		
                	if(!merged && pendingOrder.orderSequenceNumber==pendingOrder.clientOrderID){
            			existingPendingOrders.push(pendingOrder);
                	}
            	}
            	angular.forEach(existingPendingOrders, function(value, index){
            		angular.forEach(vm.orderStatusList, function(orderStatus){
            			if(value.orderStatusId == orderStatus.id){
            				existingPendingOrders[index].orderStatus = orderStatus.name;
            			}
            		})
            	})
            	
            	//vm.pendingOrders = existingPendingOrders;
            	setTimeout(function(){$scope.$apply(function(){vm.pendingOrders = existingPendingOrders;})});
        	})
        	})
        	
        }
        
        //update limits from the feed 
        function limitUpdateFeed(limit){
        	setTimeout(function(){
        	$scope.$apply(function(){
            	var existinglimit = [];
            	if(vm.limitsAvailableForSelectedInstrument!=null && vm.limitsAvailableForSelectedInstrument!=undefined){
            		if(vm.limitsAvailableForSelectedInstrument.length>0){
            			existinglimit = vm.limitsAvailableForSelectedInstrument;
            		}
            	}
            	if(limit!=null && limit!=undefined){
            		if(existinglimit!=null && existinglimit!= undefined){
            			if(existinglimit.length>0){
                        	angular.forEach(existinglimit, function(value, index){
                        		//if new order placed, order seq no will be 0 initially before the order confirmed 
                        		if(value.bankId==limit.bankId && value.branchId==limit.branchId && value.customerId==limit.customerId && (value.instId==limit.instId || value.instId==null)){
                        			if(value.active==true){
                        				existinglimit[index] = limit;
                            			getLimitsForEachInstrument(limit);
                        			}else if(value.active==false){
                        				existinglimit.splice(index, 1);
                        				fillZeroIfLimitIsNotDefinedForInstrumentCode(limit.instrumentCode);
                        			}
                        		}
                        	});
            			}
            		}
            	}
            	setTimeout(function(){$scope.$apply(function(){vm.limitsAvailableForSelectedInstrument = existinglimit;})});
            	//vm.limitsAvailableForSelectedInstrument = existinglimit;
        	});
        	})
        }
        
        
        //trade notificatoins handling
        function tradeNotificationHandling(trade){
        	setTimeout(function(){
        	$scope.$apply(function(){
            	//get trades from feed
        		var existingTrades = [];
            	if(vm.trades!=null && vm.trades!=undefined){
            		if(vm.trades.length>0){
            			existingTrades = vm.trades;
            		}
            	}
            	var merged = false;
            	if(trade!=null && trade!=undefined){
            		trade.tradeTime=moment(trade.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
            		if(existingTrades!=null && existingTrades!=undefined){
            			if(existingTrades.length>0){
                        	angular.forEach(existingTrades, function(value, index){
                        		if(value.orderSequenceNumber==trade.orderSequenceNumber){
                        			existingTrades[index] = trade;
                        			merged = true;
                        		}
                        	});
            			}else{
            				existingTrades.push(trade);
                			merged = true;
                		}
            		}else{
            			existingTrades.push(trade);
            			merged = true;
            		}
            	}
            	
            	setTimeout(function(){$scope.$apply(function(){vm.trades = existingTrades;})});
        	});
        	})
        }
        
        
        vm.initialString="";
        vm.messageString="";
        vm.rejectionReason="";
        vm.rejectionString="";
        vm.acceptanceMessage="";
        vm.rejectionMessage="";
       vm.errorMessageList = ErrorMessages.query();
      /*  vm.orderRequestDTO = { "userId" : 29, "errorMessage" : "Not completed" , "exceptionNumber" : 2204, "buySellIndicator" : "BUYY", "customerCode" : "IN8993000001",
        			"instrumentCode" : "SPOT", "orderAmount" : 4545324.34, "typeOfOrder" : "LIMT", "limitExchangeRate": 65.0898, "timeLimitIndicator": "Day", 
        			"messageNumber" : "8021","activityIndicator": "PLAC", "retailUserCode" : "akshayaa"};*/
       
        
        //handle order notification
        
        function frameOrderNotification(order){
        	
        	if(order!=null && order!=undefined){
        		
        		if(order.exceptionNumber!=null && order.exceptionNumber!="" && order.exceptionNumber!=undefined){
        			
        			//get rejection reason for error message
        			 angular.forEach(vm.errorMessageList, function(value){
        			   		if(value.code==order.exceptionNumber){
        			       		vm.rejectionReason = value.rejectionReason; 
        			   		}
        			   	});
            			
        		}
        		
            	if(order.buySellIndicator.toUpperCase()=='BUYY'){
            		order.buySellIndicator = 'BUY'
            	}
            	
      
            	
            	//build the initial notification string
            	vm.initialString = order.buySellIndicator + " order for client" + " " + order.customerCode + ", " + order.instrumentCode + " for " + order.orderAmount + " at " 
            		+ order.typeOfOrder + " " + order.limitExchangeRate + " " + order.timeLimitIndicator + " by " + order.retailUserCode;
            	
            	
            	//notification string if message number is 2021
            	
            	buildNotificationString(order);
            	
            	
        		
        	}
        }
        
        
        
        //build notification based on message number
        
        function buildNotificationString(order){
        	
        
        	if(order.messageNumber=='2021'){
        		
        		buildStringForMsg2021(order);
        	}
        	
        	else if(order.messageNumber=='8021'){
        		
        		buildStringForMsg8021(order);
        	}
        	
    		
        }
        
        //notification string for message number 2021
        
        function buildStringForMsg2021(order){
        	
        	
    			if(order.errorMessage!=''){
    				vm.rejectionString = vm.initialString + " rejected due to " + "&quot;" + order.errorMessage + "&quot;";
    				if(order.activityIndicator=='PLAC'){
    					vm.messageString = "PLACE ORDER rejected by RMS. " + vm.rejectionString;
    				}else if(order.activityIndicator=='MDFY'){
    					vm.messageString = "MODIFY ORDER rejected by RMS. " + vm.rejectionString;
    				}else if(order.activityIndicator=='CNCL'){
    					vm.messageString = "CANCEL ORDER rejected by RMS. " + vm.rejectionString;
    				}
    				
    				$.growl.error({ title: "Order Rejection", message: vm.messageString });
    			}else{
    				if(order.exceptionNumber==0){
        				if(order.activityIndicator=='PLAC'){
        					
        					vm.messageString = "PLACE ORDER submitted to Host. " + vm.initialString;
        				}else if(order.activityIndicator=='MDFY'){
        					vm.messageString = "MODIFY ORDER submitted to Host. " + vm.initialString;
        				}else if(order.activityIndicator=='CNCL'){
        					vm.messageString = "CANCEL ORDER submitted to Host. " + vm.initialString;
        				}
        				
        				$.growl.notice({ title: "Order Acceptance", message: vm.messageString });
        			}
        			else{
        				vm.rejectionString = vm.initialString + " rejected due to " + "&quot;" + order.errorMessage + "&quot;";
        				if(order.activityIndicator=='PLAC'){
        					vm.messageString = "PLACE ORDER rejected by RMS. " + vm.rejectionString;
        				}else if(order.activityIndicator=='MDFY'){
        					vm.messageString = "MODIFY ORDER rejected by RMS. " + vm.rejectionString;
        				}else if(order.activityIndicator=='CNCL'){
        					vm.messageString = "CANCEL ORDER rejected by RMS. " + vm.rejectionString;
        				}
        				
        				$.growl.error({ title: "Order Rejection", message: vm.messageString });
        			}
    			}
    	
    		
        }
        
      //notification string if message number is 8021
        
        function buildStringForMsg8021(order){
        	
        	
    			if(order.exceptionNumber==0){
    				vm.acceptanceMessage = vm.initialString + " accepted by Host. Order Sequence Number - " + order.orderSequenceNumber;
    				if(order.activityIndicator=='PLAC'){
    					vm.messageString = "PLACE ORDER ACCEPTANCE. " + vm.acceptanceMessage;
    				}else if(order.activityIndicator=='MDFY'){
    					vm.messageString = "ORDER MODIFICATION ACCEPTANCE. " + vm.acceptanceMessage;
    				}else if(order.activityIndicator=='CNCL'){
    					vm.messageString = "ORDER CANCELLATION ACCEPTANCE. " + vm.acceptanceMessage;
    				}
    				
    				$.growl.notice({ title: "Order Confirmation", message: vm.messageString });
    			}
    			else{
    				vm.rejectionString = vm.initialString + " rejected due to " + "&quot;" + vm.rejectionReason + "&quot;";
    				if(order.activityIndicator=='PLAC'){
    					vm.messageString = "PLACE ORDER REJECTION. " + vm.rejectionString;
    				}else if(order.activityIndicator=='MDFY'){
    					vm.messageString = "ORDER MODIFICATION REJECTION. " + vm.rejectionString;
    				}else if(order.activityIndicator=='CNCL'){
    					vm.messageString = "ORDER CANCELLATION REJECTION. " + vm.rejectionString;
    				}
    				
    				$.growl.error({ title: "Order Rejection", message: vm.messageString });
    			}
    		
        }
        
        vm.initialTradeMessage="";
        
        
        //prepare trade notification
        function frameTradeNotification(trade){
        	
        	if(trade!=null && trade!=undefined){
        		
        		if(trade.buySellIndicator.toUpperCase()=='BUYY'){
        			trade.buySellIndicator='Buy';
        		}
        		
        		vm.initialTradeMessage = trade.buySellIndicator + " trade " + trade.tradeSequenceNumber + " for " + trade.customerCode + ", " + trade.instrumentCode + 
        		  " for " + trade.tradedAmount + " at " + trade.tradedExchangeRate + " for order number " + trade.orderSequenceNumber;
        		
        		$.growl.notice({ title: "Trade Confirmation", message: vm.initialTradeMessage });
        	}
        }
        
        function swapQuoteChanges(swapQuote){
        	//var existingQuotes = vm.bankComparativeQuotes;
        	angular.forEach(vm.bankComparativeQuotes, function(value, index){
        		if(value.bank.bankName==swapQuote.bank.bankName){
        			vm.bankComparativeQuotes[index] = swapQuote;
        			
        			//change swap quotes in home page
        			prepareSwapQuotes();

        		}
        	})
        	//vm.bankComparativeQuotes = existingQuotes;
        }
        
        //add remarks for order request
        function addRemarks(){
        	$state.go('add-remarks', {remarks: vm.remarks});
        }
        
        /*$rootScope.$on('addExpiryTime', function(event, result){
        	vm.orderDTO.expiryTime = $rootScope.expiryTime;
        	if(vm.userPreference.showPreOrderConfirmation=='yes'){
            	$rootScope.orderDTO = vm.orderDTO;
            	$state.go('pre-order-confirmation');
        	}else{
        		if(activityIndicator=='PLAC'){
            		placeOrderRequest(vm.orderDTO);
        		}else if(activityIndicator=='MDFY'){
        			Market.modifyOrder(vm.orderDTO);
        		}else if(activityIndicator=='CNCL'){
        			Market.cancelOrder(vm.orderDTO);
        		}
        	}
        });*/
        
        $rootScope.$on('addRemarks', function(event, result) {
			vm.remarks = $rootScope.remarks;
		});
        
        var orderplacedsuccess = $rootScope.$on('orderplacedsuccess', function(event, result) {
			vm.remarks = "";
			fillDefaultValuesToControls();
		});
        
        //expand market depth tab
        function expandMarketDepth(instrumentCode, marketDepth){
        	$rootScope.marketDepth = marketDepth;
        	$rootScope.instrument = instrumentCode;
        	$state.go('market-depth-expand');
        }
        
      //open market statistics tab
        function openMarketStatistics(instrumentCode){
        	$rootScope.instrument = instrumentCode;
        	$state.go('market-statistics');
        }
        
        function openOrderPreference(){
        	$state.go('order-config');
        }
        
        
        function prepareSwapQuotes(){
    		//prepare bank comparativeQuotes in price
    		prepareBankComparativeQuotesInPrice(vm.bankComparativeQuotes, vm.retailSpotMarketWatch);
    		
    		//get best offer and bid spread for both Cash/spot and tom/spot.
    		getBestSpreadRates(vm.bankComparativeQuotes);
    		
        	if(vm.bankComparativeQuotes.length==1){
            	vm.defaultBankSpread = vm.bankComparativeQuotes[0];
        	}else{
        		//get default bank swap quotes for the customer user
        		vm.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes(vm.bankComparativeQuotes, vm.defaultTradingBank);
        	}
    		if(vm.defaultBankSpread!=null && vm.defaultBankSpread!=undefined){
    			onGetDefaultTradingBankSpreadSuccess();
    		}
        }
        
        function onGetswapQuotesFeedFailure(){
        	
        }
        
        function limitChangeRequest(){
         	if(vm.bankFilterForLimit!=null && vm.bankFilterForLimit!=undefined && vm.globalValues.getOrganisation().organisationType.shortName=='CUSTOMER'){
        		workflowSharedService.viewFrom = "customer_limitSettings";
        		var customerId =vm.globalValues.getOrganisation().id ;
        		var bankId =  vm.bankFilterForLimit.id;
     			var state = 'customer_limit_Settings';
     			$state.go(state,{customerId:customerId, bankId : bankId});
            	//$state.go('customer_limit_Settings',{customerId:vm.globalValues.getOrganisation().id, bankId : vm.bankFilterForLimit.id});
        	}
        }
        
        function getBuyingPower(limit){
        	var buyingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
        	var totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
        	if(limit.limitType=='GROSSSELL'){
        		buyingPower = 0;
        	}else if(limit.limitType=='GROSSBUY'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy);
        	}else if(limit.limitType=='GLOBALGROSS'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue  + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		buyingPower = limit.limitAllocatedInUSD - totalBuy;
        	}
        	return buyingPower;
        }
        
        vm.getExposure = function getExposure(limit){
        	var exposure = 0;
        	var totalBuy = 0;
        	var totalSell = 0;
        	if(limit.limitType!='NET'){
            	totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
            	totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
            	exposure = (limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue + limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue) * 100/limit.limitAllocatedInUSD;
        	}else{
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));

            	var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		exposure =  Math.max.apply(Math, xVals) * 100/limit.limitAllocatedInUSD;
        	}
        	return exposure;
        }
        
        function getSellingPower(limit){
        	var sellingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue);
        	var totalSell = limit.totalPendingSellOrderValue + (limit.totalSellTradeValue + limit.previousDaySellTradeValue);
        	if(limit.limitType=='GROSSSELL'){
        		sellingPower = limit.limitAllocatedInUSD - (totalSell);
        	}else if(limit.limitType=='GROSSBUY'){
        		sellingPower = 0;
        	}else if(limit.limitType=='GLOBALGROSS'){
        		sellingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		sellingPower = limit.limitAllocatedInUSD - totalSell;
        	}
        	return sellingPower;
        }
        
        
    }
})();
