(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('UserwiseTurnover', UserwiseTurnover);

    UserwiseTurnover.$inject = ['$resource'];

    function UserwiseTurnover ($resource) {
    	 var resourceUrl =  'api/userwiseTurnoverReport/:id';
        
        return $resource(resourceUrl, {}, {
			 'excelUserwiseTurnoverReport':{
				    method: 'POST',
	                params : {
	                	businessDate:'@businessDate'
					},
	                url: 'api/userwise-turnover-report/excel',
	                responseType: 'arraybuffer',
	                  transformRequest: function (data) {
	                     	var copy = angular.copy(data);
	                     	
	                         return angular.toJson(copy);
	                     },
	                    transformResponse: function(data, headers) {
	                  	  return {
	                            data: data,
	                            headers: headers
	                        };   
	                    }  
				},
				 'csvUserwiseTurnoverReport':{
					    method: 'POST',
		                params : {
		                	businessDate:'@businessDate'
						},
		                url: 'api/userwise-turnover-report/csv',
		                responseType: 'arraybuffer',
		                  transformRequest: function (data) {
		                     	var copy = angular.copy(data);
		                     	
		                         return angular.toJson(copy);
		                     },
		                    transformResponse: function(data, headers) {
		                  	  return {
		                            data: data,
		                            headers: headers
		                        };   
		                    }  
					}
        


              
        });
    }
})();


