(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'changeConfig'
	})

	.controller('ApplicationWideSettingsController', ApplicationWideSettingsController);

	ApplicationWideSettingsController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'ApplicationWideSettings', 'GlobalValues',
			'EntityPermissions', 'ScreenLog', 'DateUtils','$uibModalInstance','$stateParams', '$rootScope', 'entityConstants'];

	function ApplicationWideSettingsController($scope, $state, workflowSharedService,
			ApplicationWideSettings, GlobalValues, EntityPermissions, ScreenLog, DateUtils,$uibModalInstance,$stateParams,$rootScope,entityConstants) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.applicationWideSettings = {};
		vm.userId = $stateParams.userId;
		//vm.restoreDefault = restoreDefault;
		vm.launch = launch;
		vm.clear = clear;
		workflowSharedService.viewFrom = 'changeConfig';
		vm.isPasswordPolicySelected = true;
		function launch() {
			$state.go("application-wide-settings");
		}
		logScreenAccess();
		loadAll();

		function loadAll() {
			ApplicationWideSettings.get({
				"userId" : vm.userId
			},onGetConfigurationSettingsSuccess, onGetConfigurationSettingsError);
		}

		function onGetConfigurationSettingsSuccess(result) {
			vm.applicationWideSettings = result;
		}

		function onGetConfigurationSettingsError() {

		}
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
		
				
		$rootScope.$on('Refresh', function(event, result) {
			loadAll();
		});
		
		function logScreenAccess(){
	       	ScreenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.USER_ADMIN_APPLICATION_WIDE_SETTINGS ,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	    }

	}
})();
