(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FaqDeleteController',FaqDeleteController);

    FaqDeleteController.$inject = ['$uibModalInstance', '$rootScope', '$scope', '$filter', 'FAQService', 'GlobalValues'];

    function FaqDeleteController($uibModalInstance, $rootScope, $scope, $filter, FAQService, GlobalValues) {
        var vm = this;
        
        vm.faq=$rootScope.faqfilteredbyclick;
        
        vm.faqdetailsfromJSONFile = $rootScope.faqdetailsfromJSONFile;
        vm.filteredfaqcategory = vm.faqdetailsfromJSONFile;
        
        vm.indexnumber = $rootScope.indexnumber;
        getcurrentindexofFAQ();
        
        function getcurrentindexofFAQ(){
        	
        	var data = vm.faqdetailsfromJSONFile;
        	var filteredObj = data.find(function(item, i){
        		  if(item.index === vm.indexnumber){
        			  vm.currentindex = i;
        			  return i;
        		  }
              });
        	
        	//alert(vm.currentindex);
        }
        
        vm.clear=clear;
        $scope.clicked=false;
        $scope.showButton = false;
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function onSaveSuccess (result) {
        	 $rootScope.$emit('gatewayApp:reloadFAQPage');
        	 $uibModalInstance.dismiss('close');   
       }

       function onSaveError () {
       	
       }
        
       
       function saveJSON() {

       	var JSONText = angular.toJson(vm.faqdetailsfromJSONFile);
       	FAQService.uploadJSONFile({"type": "faq", "fileName": "faqdetailsjson.txt", "fileType": "txt"},JSONText,onSaveSuccess, onSaveError);
       }
       
        
       vm.deletefaq=function(){
        	faqdelete();
       }
        
    	
       function faqdelete() {

    	   vm.faqdetailsfromJSONFile.splice(vm.currentindex, 1);    

    	   saveJSON();
        
       }
}
})();
