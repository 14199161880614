(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('BankFeeAndChargesUploadService', BankFeeAndChargesUploadService);

	BankFeeAndChargesUploadService.$inject = ['$resource'];

	function BankFeeAndChargesUploadService($resource) {
		var resourceUrl =  '/api';

		return $resource(resourceUrl, {}, {
		
		'get' : {
			method : 'GET',
			params: {bankId : '@bankId'},
			url:'api/bank/:id',
			isArray : true,
			transformResponse : function(data) {
				if (data) {
					data = angular.fromJson(data);
				}
				return data;
			}
		},
			
			'getActiveFeeChargesUploadByBank':{
				method: 'GET',
				params: {bankId : '@bankId'},
				url: 'api/active-bank-fees-charges/:bankId/bankId'

			},
			'resubmit':{
				method: 'POST',
				url: 'api/tasks/resubmit/bank-fees-charges-upload-by-bank-resubmit',
				headers: {
					'Content-Type': undefined
				}


			},			
			'update':{
				method:'POST',
				url: 'api/bank-fees-charges-upload-details',
				headers: {
					'Content-Type': undefined
				}

			},
			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			}

		});
	}
})();
