(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ExposureType', ExposureType);

    ExposureType.$inject = ['$resource'];

    function ExposureType ($resource) {
        var resourceUrl =  'api/exposure-type/:id';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId'},
        	    url: 'api/exposure-type'
        	 },
         	
             'getAllExposureTypes':{
                 isArray: true,
                 url: 'api/all-exposure-types',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },

             'getEnabledExposureTypes':{
                 isArray: true,
                 url: 'api/enabled-exposure-types',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },

             'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },           
            	
             'save': {
                 method: 'POST',
                 params: {orgId:'@orgId'},
                 url: 'api/exposure-type/orgId/:orgId',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                     return angular.toJson(copy);
                 }                
             },
        	
             'update': {
             	method:'PUT',
             	params: {orgId:'@orgId'},
                url: 'api/exposure-type/orgId/:orgId',
             	transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 }
             },
 			 'validateSODAndSession' : {
				method : 'GET',
				url : 'api/validate-sod-session-status',
			 },
             'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/exposure-type',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              }
              
        });
    }
})();
