(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OrganisationViewController', OrganisationViewController);

    OrganisationViewController.$inject = ['Workflow', '$scope', '$state','$rootScope', 'workflowSharedService','$uibModalInstance','Organisation','entity'];

    function OrganisationViewController(Workflow, $scope, $state,$rootScope, workflowSharedService, $uibModalInstance, Organisation,entity) {
        var vm = this;
        vm.clear=clear;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        
        vm.approveOrganisation = approveOrganisation;
        vm.rejectOrganisation = rejectOrganisation;
        vm.discardOrganisation = discardOrganisation;
        vm.ShowRequestedByLink = false;
        $scope.clicked=false;
        
        if(workflowSharedService.viewFrom == 'organisation'){
        	vm.organisation = entity;
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'organisationtodolist_checker') {
        	vm.organisation =  angular.fromJson($rootScope.payload); 
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        	vm.taskId = $rootScope.taskID;
        	vm.ShowRequestedByLink = true;
        }
        else if(workflowSharedService.viewFrom == 'organisationtodolist_maker') {
        	vm.organisation =  angular.fromJson($rootScope.payload); 
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        
        
        if(vm.organisation.orgType=="CORPORATE" || vm.organisation.fyEndMonth != null){
     	   vm.showCorporate=true;
     	   vm.organisation.fyStartMonth= vm.organisation.fyStartMonth.toString();
            
            vm.organisation.fyEndMonth= vm.organisation.fyEndMonth.toString();
        }
        
        function approveOrganisation() { 
        	Organisation.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectOrganisation() {
        	Workflow.rejectTask($rootScope.taskID);
        }

        function discardOrganisation() {
        	Organisation.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
      
        function clear () {
        	
            $uibModalInstance.dismiss('cancel');
        }
       
        
    }
})();
