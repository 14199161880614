(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('SettlementReport', SettlementReport);

    SettlementReport.$inject = ['$resource'];

    function SettlementReport ($resource) {
        var resourceUrl =  'api/settlement-report/:id';
        
        return $resource(resourceUrl, {}, {
        	
        	'getSettlementReport':{
             	isArray:true,
  				method: 'GET',
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId: '@instrumentId', 
                    tradeTypeId: '@tradeTypeId', entityOwnerType: '@entityOwnerType', customFilter : '@customFilter', 
                    viewFor : '@viewFor', fromDate:'@fromDate', toDate : '@toDate'},
  				
  				url: 'api/settlement-report-summary/bank/:bankId/branch/:branchId/customer/:customerId/instrumentId/:instrumentId/tradeTypeId/:tradeTypeId/entityOwnerType/:entityOwnerType/customFilter/:customFilter/viewFor/:viewFor/fromDate/:fromDate/toDate/:toDate'
              },
              'exportData':{
  			    method: 'POST',
                  url: 'api/settlement-report/export',
                  responseType: 'arraybuffer',
                  params : {
                	  
                	  bankName : '@bankName',branchName :'@branchName', branchCode : '@branchCode',
                	  customerName:'@customerName', customerCode : '@customerCode',
  					customFilter : '@customFilter',instrument : '@instrument',tradeType : '@tradeType',
  					viewFor : '@viewFor',fromDate:'@fromDate',toDate:'toDate'
  				},
  				 transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	
                      return angular.toJson(copy);
                  },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  } 
  			},
  			 'exportDataCSV':{
   			    method: 'POST',
                   url: 'api/settlement-report/export-csv',
                   responseType: 'arraybuffer',
                   params : {
                 	  
                 	  bankName : '@bankName',branchName :'@branchName', branchCode : '@branchCode',
                 	  customerName:'@customerName', customerCode : '@customerCode',
   					customFilter : '@customFilter',instrument : '@instrument',tradeType : '@tradeType',
   					viewFor : '@viewFor',fromDate:'@fromDate',toDate:'toDate'
   				},
   				 transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   },
                   transformResponse: function(data, headers) {
                 	  return {
                           data: data,
                           headers: headers
                       };   
                   } 
   			}
        	
        });
    }
})();
