(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('LoggedInUsersReport', LoggedInUsersReport);

    LoggedInUsersReport.$inject = ['$resource','DateUtils'];

    function LoggedInUsersReport($resource, DateUtils) {
        var resourceUrl = 'api/loggedinusers';
        return $resource(resourceUrl, {}, {
            
            'getHeaderData': {
                method: 'GET',  
                url: 'api/loggedinusers/all-available-users-count',
               
                isArray: true
            },
            'getBankUsersDetails': {
                method: 'GET',  
                url: 'api/loggedinusers/bank-users',
                params : {
                	bankId : '@bankId'
					
				},
				isArray: true
            },
            'getBranchUsersCountForBank': {
                method: 'GET',  
                url: 'api/loggedinusers/branch-users-count-for-bank',
                params : {
                	bankId : '@bankId'
					
				},
                isArray: true
            },'getCustomerCountForBranch': {
                method: 'GET',  
                url: 'api/loggedinusers/customers-count-for-branch',
                params : {
                	bankId : '@bankId'
					
				},
                isArray: true
            },'getCustomersUsersCount': {
                method: 'GET',  
                url: 'api/loggedinusers/customer-users-count',
                params : {
                	bankId : '@bankId',
                	branchId: '@branchId'
					
				},
                isArray: true
            },'getCurrentBusinessDate': {
                method: 'GET',  
                url: 'api/eod-process',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            },
            'getExcelData': {
                method: 'POST',  
                url: 'api/loggedinusers/export/excel',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getCSVData': {
                method: 'POST',  
                url: 'api/loggedinusers/export/csv',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getOverAllSummaryData': {
                method: 'GET',  
                url: 'api/loggedinusers/overall-summary',
               
                isArray: true
            },
            'getBankwiseSummaryData': {
                method: 'GET',  
                url: 'api/loggedinusers/bank-summary',
               
                isArray: true
            },
            'getLoggedInUsersAsOFNow': {
                method: 'GET',  
                url: 'api/loggedinusers/get-users-loggedin-as-of-now',
                params : {
                	orgID : '@orgID',
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getLoggedInUsersForTheDay': {
                method: 'GET',  
                url: 'api/loggedinusers/get-users-loggedin-for-the-day',
                params : {
                	orgID : '@orgID',
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getFailedToLoginUsers': {
                method: 'GET',  
                url: 'api/loggedinusers/get-users-failed-to-login',
                params : {
                	orgID : '@orgID',
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getNotLoggedInUsers': {
                method: 'GET',  
                url: 'api/loggedinusers/get-users-not-logged-in',
                params : {
                	orgID : '@orgID',
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getUsersPlacedOrderForTheDay': {
                method: 'GET',  
                url: 'api/loggedinusers/get-users-placed-orders',
                params : {
                	orgID : '@orgID',
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getUsersPlacedTradeForTheDay': {
                method: 'GET',  
                url: 'api/loggedinusers/get-users-traded',
                params : {
                	orgID : '@orgID',
                	entityOwnerType: '@entityOwnerType'
					
				},
                isArray: true
            },
            'getCustomerLoggedInUsersForTheDay': {
                method: 'GET',  
                url: 'api/loggedinusers/get-customer-users-loggedin-for-the-day',
                params : {
                	bankId : '@bankID'
					
				},
                isArray: true
            }
        });
    }
})();