(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerRegistrationApprovalDisclaimerController', CustomerRegistrationApprovalDisclaimerController);

    CustomerRegistrationApprovalDisclaimerController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues', '$rootScope', '$scope', 'TacViewForEntitiesUtility','CustomerApproval','CustomerRegistration','systemSettings','DateUtils','MessageBox','$filter'];

    function CustomerRegistrationApprovalDisclaimerController($uibModalInstance,$stateParams,GlobalValues,   $rootScope, $scope, TacViewForEntitiesUtility,CustomerApproval,CustomerRegistration,systemSettings,DateUtils,MessageBox,$filter) {
    	
    	var vm = this;
    	
    	vm.clear = clear;
        vm.approve = approve;
        $scope.clicked = false;
        vm.entityId =  $stateParams.entityId;
        vm.onGetCcilTac = TacViewForEntitiesUtility.onGetCcilTacForBank;
        vm.organisation = GlobalValues.getOrganisation();
        vm.messageBoxName = "customerApprovalfromtodo";
        vm.isBankAgreedTac = false;
        
        
        if(systemSettings != null && systemSettings != undefined){
			vm.businessDate  = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
		}
    	
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function approve(){
        $scope.rowID = vm.entityId;
        CustomerApproval.approve({ "taskId": vm.entityId }, onApproveSuccess, onApproveError); 
      }
      
        function onApproveSuccess(result) {
        	if((result != null && result != undefined) && (result.errorMessage != null && result.errorMessage != undefined && result.errorMessage != "")){
				if(result.errorMessage  == 'tacViewForEntities.bank.cannotApproveCustomer'){
					$uibModalInstance.dismiss('cancel');
					MessageBox.openMessageBox("Alert", $filter('translate')('error.tacViewForEntities.bank.cannotApproveCustomer'), "Ok", "bankApprovalMessage");
				}
			}else{
            $rootScope.removeRow( $scope.rowID );
            $uibModalInstance.dismiss('cancel');
			}
        }

        function onApproveError(error) {
        	//$scope.clicked=false;
        	$uibModalInstance.dismiss('cancel');
        	if((error != null && error != undefined) && (result.errorMessage != null && result.errorMessage != undefined && result.errorMessage != "")){

        		if(result.errorMessage  == 'tacViewForEntities.bank.cannotApproveCustomer'){
        			MessageBox.openMessageBox("Alert", $filter('translate')('error.tacViewForEntities.bank.cannotApproveCustomer'), "Ok", "bankApprovalMessage");
        		}
        	}
        	        	
        }
        
        
    }
})();