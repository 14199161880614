(function() {
	'use strict';
	angular.module('gatewayApp').factory('ChangeSettings', ChangeSettings);

	ChangeSettings.$inject = [ '$resource' ];

	function ChangeSettings($resource) {
		var resourceUrl = 'api/change-settings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'updateCustomerSpecificSettings' : {
				method : 'POST',
				params:{customerSpecificSettings:'customerSpecificSettings'},
				url : 'api/customer-specific-settings',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
			},
			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},
			'resubmitForEdit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmit/customerSpecificSettings',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			}
		});
	}
})();
