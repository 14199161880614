(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('LimitSettings', LimitSettings);

    LimitSettings.$inject = ['$resource'];

    function LimitSettings($resource) {
        var resourceUrl =  'api/limit-settings';
        
        return $resource(resourceUrl, {}, {
        	
        	 'fetchLimits': {
                 method: 'GET',
                 params: {bank:'@bank', branch:'@branch', limitsExpiringIn: '@limitsExpiringIn', showExpired:'@showExpired'},
                 url: 'api/limit-settings',
                 isArray:true
        	 },
        	 'save':{
        		 method:'POST',
        		 url:'order/limit-settings',
        		 transformRequest: function (data) {
                   	
                   	var copy =angular.toJson(data);
                   	
                       return copy;
                   }
        	 },
           'reset':{
             method:'POST',
             url:'order/reset-limit-settings',
             transformRequest: function (data) {
                    
                    var copy =angular.toJson(data);
                    
                       return copy;
                   }
           },
           'getOutstandingQuantityForPendingOrder': {
             method:'POST',
             url:'order/getOutstandingQuantityForContractPendingOrder',
             transformRequest: function (data) {
                    
                    var copy =angular.toJson(data);
                    
                       return copy;
                   },
            transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
           },
           'checkIsExistingTradeConsumesLimit': {
             method:'POST',
             url:'order/checkIsExistingTradeConsumesLimit',
             transformRequest: function (data) {
                    
                    var copy =angular.toJson(data);
                    
                       return copy;
                   },
            transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
           },
        	 'update':{
        		 method:'PUT',
        		 url:'order/limit-settings',
        		 transformRequest: function (data) {
                   	
                   	var copy =angular.toJson(data);
                   	
                       return copy;
                   }
        	 },
        	 'updateLimitSettingsByCustomer':{
        		 method:'PUT',
        		 url:'order/customer-limit-settings',
        		 transformRequest: function (data) {
                   	
                   	var copy =angular.toJson(data);
                   	
                       return copy;
                   }
        	 },        
        	 'get':{
        		 method: 'GET',
        		 param:{id : '@id'},
        		 url: 'api/limit-settings/:id',
        		 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
        	 },
        	 'approve': {
            	method: 'GET',
            	params: {taskId:'@taskId'},
            	url: 'order/tasks/:taskId/approve'
        	 },
        	 'reject': {
        		 method: 'GET',
        		 params: {taskId:'@taskId'},
        		 url: 'api/tasks/:taskId/reject'
        	 },
        	 'discard': {
        		 method: 'GET',
        		 params: {taskId:'@taskId'},
        		 url: 'api/tasks/:taskId/discard'
        	 },
        	 'resubmit': {
        		 method: 'PUT',
        		 params: {taskId:'@taskId'},
        		 url: 'order/tasks/:taskId/resubmit/limit-settings',
        		 transformRequest: function (data) {
        			 var copy = angular.copy(data);
        			 return angular.toJson(copy);
        		 }
        	 },
        	 'resubmitLimitChangeByCustomer': {
        		 method: 'PUT',
        		 params: {taskId:'@taskId'},
        		 url: 'order/tasks/:taskId/resubmit/customer-limit-settings',
        		 transformRequest: function (data) {
        			 var copy = angular.copy(data);
        			 return angular.toJson(copy);
        		 }
        	 },
        	 'getDefaultEffectiveToDateForForward':{
				method:'GET',
				url:'api/limit-settings/default-effective-to-date-for-forwards',
			   transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
            'getDefaultEffectiveToDate':{
            	  method:'GET',
            	  url:'api/limit-settings/default-effective-to-date',
            	 transformResponse: function (data) {
                      if (data) {
                          data = angular.fromJson(data);
                      }
                      return data;
                  }
              },'approveWihPayload': {
                  method: 'POST',
                  params: {taskId:'@taskId'},
                  url: 'order/tasks/:taskId/limit-settings/approveWithModification'
              },'getLimitForCustomer' :{
            	  method: 'GET',
         		 param:{customerId : '@customerId' , bankId : '@bankId', limitInstrumentGroup: '@limitInstrumentGroup'},
         		 url: 'api/customer-limit-settings',
         		 transformResponse: function (data) {
                      if (data) {
                          data = angular.fromJson(data);
                      }
                      return data;
                  }
              },
              'getLimitUtilsationDetailsForInstrument':{
     	       	isArray:true,
     				method: 'GET',
     				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'},
     				url: 'api/limit-utilisation-details/:bankId/bank/:branchId/branch/:customerId/customer/:instrumentId/instrument'
     	      },
     		  'getLimitsAvailableForAllInstruments':{
         	       	isArray:true,
         				method: 'GET',
         				params: {bankId : '@bankId', customerId : '@customerId'},
         				url: 'api/limit-utilisation-details-for-all-instrument/:bankId/bank/:customerId/customer'
         	  },
           'getLimitsAvailableForLimitInstrumentGroup':{
                isArray:true,
                method: 'GET',
                params: {bankId : '@bankId', customerId : '@customerId', limitInstrumentGroupId : '@limitInstrumentGroupId'},
                url: 'api/limit-utilisation-details-for-limit-instrument-group/:bankId/bank/:customerId/customer/:limitInstrumentGroupId/limitInstrumentGroup'
            },
     	     'createLimitSettingsByCustomer':{
        		 method:'POST',
        		 url:'order/create-customer-limit-settings',
        		 transformRequest: function (data) {
                   	
                   	var copy =angular.toJson(data);
                   	
                       return copy;
                   }
        	 }
        	 
              
        	 
        });
    }
})();
