(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("EntityLookup", EntityLookup);

    EntityLookup.$inject = ['GlobalValues', 'User'];

    function EntityLookup(GlobalValues, User) {

		var returnValue = null;
		var userList = [];

    	var service = {
    		getUserList: getUserList,
    	};
    	return service;
    	
    	function getUserList(selectedEntityOwnerType, bankId, bracnhId, customerId){
    		var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
			if(selectedEntityOwnerType!="" && selectedEntityOwnerType!=null){
				if (selectedEntityOwnerType==userEntityOwnerType && userEntityOwnerType!="Branch"){
					returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType}, onSuccess, onFailure);
				}else if (selectedEntityOwnerType==userEntityOwnerType && userEntityOwnerType=="Branch"){
					returnValue = User.getUsersForBranch({"branchId": GlobalValues.getBranch().id}, onSuccess, onFailure);
				}else if(userEntityOwnerType=="Clearcorp" && selectedEntityOwnerType=="Bank" && (bankId!="" && bankId!=null)){
					returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": bankId, entityOwnerType: 'Bank'}, onSuccess, onFailure);
				}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank") && selectedEntityOwnerType=="Branch" && (branchId!="" && branchId!=null)){
					returnValue = User.getUsersForBranch({"branchId": branchId}, onSuccess, onFailure);
				}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank" || userEntityOwnerType=="Branch") && (selectedEntityOwnerType=="MultiUserCustomer" || selectedEntityOwnerType=="SingleUserCustomer") && (customerId!="" && customerId!=null)){
					returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": customerId, entityOwnerType: selectedEntityOwnerType}, onSuccess, onFailure);
				}
			}else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
				returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType}, onSuccess, onFailure);
			}
			
			function onSuccess(result){
				if(returnValue!=null){
					angular.forEach(returnValue, function(value){
						userList.push(value.id);
					})
					console.log(userList);
					return userList;
				}
			}
			
			function onFailure(){
				
			}
			
    	}
        
    }
})();
