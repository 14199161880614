(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dashboard', {
            parent: 'entity',
            url: '/dashboard',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.dashboard.todo" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/dashboard/1/dashboard.html',
                    controller: 'DashBoardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('bankRegistration');
                    $translatePartialLoader.addPart('branch');
                    $translatePartialLoader.addPart('smsSettings');
                    $translatePartialLoader.addPart('defaultMarkupSettings');
                    $translatePartialLoader.addPart('markupSettings');
                    $translatePartialLoader.addPart('heConnectivitySettings');
                    $translatePartialLoader.addPart('intradayMarketSessionSettings');
                    $translatePartialLoader.addPart('defaultMarketSessionSettings');
                    $translatePartialLoader.addPart('emailSettings');
                    $translatePartialLoader.addPart('amcSettings');
                    $translatePartialLoader.addPart('customerCharges');
                    $translatePartialLoader.addPart('gstSettings');
                    $translatePartialLoader.addPart('transactionChargesSettings');
                    $translatePartialLoader.addPart('uploadDownloadCertificates');
                    $translatePartialLoader.addPart('clearCorpTACUpload');
                    $translatePartialLoader.addPart('exposureType');
                    $translatePartialLoader.addPart('exposureSubType');
                    $translatePartialLoader.addPart('creditFactorSettings');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
