(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.controller('EodProcessNewController', EodProcessNewController);

	EodProcessNewController.$inject = ['$scope', '$state', '$stateParams', '$rootScope', 'GlobalValues', 'DateUtils', 'AlertService','EODProcess','MessageBox',
            '$filter','$timeout', 'ScreenLog','entityConstants','StaticLookUp','SODProcess', '$interval', '$fileLogger', 'FileUtil','EntityPermissions','Auth'];

	function EodProcessNewController($scope, $state, $stateParams, $rootScope, GlobalValues, DateUtils, AlertService,EODProcess,MessageBox,
            $filter,$timeout , ScreenLog,entityConstants,StaticLookUp, SODProcess, $interval, $fileLogger, FileUtil,EntityPermissions,Auth) {

		var vm = this;
		vm.todayDate;
		vm.nextDate;
		vm.currentBusinessDate;
		vm.nextBusinessDate;
		$fileLogger.setTimestampFormat('dd-MMM-yyyy HH:mm:ss:sss', '+0530');
		$fileLogger.info('eod screen accessed');
		vm.globalValues = GlobalValues;
		vm.beginEODProcess = beginEODProcess;		
		var convertedNextBusinessDate ;
		var convertedCurrentBusinessDate ;
		$scope.refresh = refresh;
		vm.today = new Date();
		vm.convertedTodayDate = DateUtils.convertLocalDateToServer(vm.today);
		vm.validateEod = validateEod;
		vm.validateInteractiveServerDbProcessing = validateInteractiveServerDbProcessing;
		vm.validateBroadcastServerDbProcessing = validateBroadcastServerDbProcessing;
		vm.isEODvalidated=false;
		vm.validation=vm.NOTSTARTED;
		vm.validateBroadcastDBProcessing=vm.NOTSTARTED;
		vm.validateInteractiveDBProcessing=vm.NOTSTARTED;
		vm.hideCheckList=false;
		vm.showEodProcess=false;
		vm.eodDone=false;
		vm.disbleEod = true;
		vm.eodStatus='';
		vm.isRefreshDisabled=false;
		vm.isEodProcessSuccessfull=null;
		vm.isEodCompletedForOrder=false;
		vm.isEodCompleted = false;
		vm.processStatusMap = {};
		vm.newProcessStatusMap = {};
		vm.today = new Date();
		vm.errorMessage = "";
		vm.logOffId = 0;
		vm.reconOrderReqId = 0;
		vm.reconOrderResponseId = 0;
		vm.reconTradeReqId = 0;
		vm.reconTradeResId = 0;
		vm.userLogoffReqId = 0;
		vm.userLogoffResponseId = 0;
		vm.luddlogOffReq = 0;
		vm.luddlogoffRespnse = 0;
		vm.disconnectinteractiveId = 0;
		vm.disconnectboradcastId = 0;
		vm.clearRvId = 0;
		vm.dormantId = 0;
		vm.autowithdrawalId = 0;
		vm.eodId = 0;
		vm.limitId = 0;
		vm.archiveId = 0;
		vm.shiftDateId = 0;
		vm.prepareNextdateDataId = 0;
		vm.logOffEndId = 0;
		vm.dailyTransactionId = 0;
		vm.monthEndBillingId = 0;
		vm.yearEndBillingId = 0;
		vm.holidayRequestId = 0;
		vm.holidayResponseId = 0;
		vm.updateTradingBranchId = 0;
		vm.sessionCloseMessage = "";
		vm.sessionCloseValidation = "";
		vm.responseReceivedValidation = "";
		vm.responseReceivedMessage="";
		vm.idArray = [];
		vm.autoRefreshToggle = null;
		
		vm.connectToMQID = 0;
		vm.executionCount = 0;
		vm.revokeProcessId;
		vm.eodProcessList = [];
		vm.eodProcessListFromDB = [];
		vm.newProcessResultMap = {};
		vm.processResultMap = {};
		vm.processErrorMap = {};
		vm.newProcessErrorMap = {};
		vm.errorList = {};
		var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
		vm.processId;
		vm.showResumeButton=false;
		var orderServiceEODProcessUpdateSubscription = null;
		var autoRefreshInterval = vm.globalValues.getConfigSettings().intervalBetweenFetchEODStatusLog;
		vm.orderProcessIds = [2,4,23,25,6,8,10,11,12];
		
		
		/* Constants to be used in HTML file for displaying progress icons */

		vm.INPROGRESS = "INPROGRESS";
		vm.COMPLETED = "COMPLETED";
		vm.FAILED = "FAILED";
		vm.NOTSTARTED = "NOTSTARTED";
		vm.OPEN = "TO_BE_INITIATED";
		vm.INCOMPLETE = "INCOMPLETE";
		vm.permitEODProcessAddEntityAction = EntityPermissions.hasPermission('EODProcess.CREATE');
		
		
		
		logScreenAccess();
		loadDates();
		loadErrorMEssages();
	
		/* set Intial State and initial status for each process and load the new businessDates */
		function loadDates() {
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
		}
		
		function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.eodStatus=vm.lastSystemSetting.eodStatus;
			vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
			vm.todayDate = $filter('date')(vm.currentBusinessDate,"dd/MM/yyyy");
			vm.todayDate = vm.todayDate + " | " + days[vm.currentBusinessDate.getDay()];
			convertedCurrentBusinessDate = DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
            $fileLogger.info('loadSystemSettingsSuccess - getNextValidBusinessDate');
			EODProcess.getNextValidBusinessDate( {
				"currentBusinessDate" : convertedCurrentBusinessDate
			} , getNextBusinessDateSuccess, getNextBusinessDateError);
			vm.disbleEod = disableEodProcess();
			// load all eod work flow items
			loadWorkFlowItems();
		}
		function loadSystemSettingsError(){
			
		}
		// Set Initial status as NOTSTARTED for each process on page laod /
		
		 function loadWorkFlowItems(){
       	 StaticLookUp.getEodWorkFlow(lookupWorkFlowSuccess, lookupWorkFlowFailure);
        }
        function lookupWorkFlowSuccess(result){
        	
        	vm.eodProcessListFromDB = result;
        	vm.eodProcessList = [];
        	console.log(vm.eodProcessListFromDB );
        	vm.idArray = [];
        	angular.forEach(vm.eodProcessListFromDB, function(item){               
                vm.idArray.push( item.id);               
            });
        	
   	    	vm.eodProcessList = angular.copy(vm.eodProcessListFromDB);   	    	
   	    	
   	   
          	if(vm.eodStatus == vm.OPEN || vm.eodStatus==vm.INCOMPLETE || vm.eodStatus==vm.INPROGRESS){
          		
          		 angular.forEach( vm.eodProcessList, function(item){
          		 
	          		 if(item.processName=='ForciblelogoffUsers'){
	          			 vm.logOffId = item.id;
	          		 }else if(item.processName=='ReconOrderDetailsRequest'){
	          			 vm.reconOrderReqId = item.id;
	          		 }       		 
	          		 else if(item.processName=='MakeUsersDormant'){
	        			 vm.dormantId=item.id;
	          		 }
	          		 else if(item.processName=='AutowithdrawalOfPendingApprovalCustomers'){
	        			 vm.autowithdrawalId=item.id;
	        		 }
	          		 else if(item.processName=='GenerateEODEmails'){
	        			 vm.eodId=item.id;
	        		 }
	          		 else if(item.processName=='ProcessLimitsAtEOD'){
	        			 vm.limitId=item.id;
	        		 }
	          		 else if(item.processName=='ArchiveTablesAtEOD'){
	          			 vm.archiveId=item.id;
	          		 }
	          		 else if(item.processName=='ComputeDailyTransactionCharges'){
	          			vm.dailyTransactionId=item.id;
	          		 }
	          		 else if(item.processName=='GenerateMonthEndBillingReport'){
	          			vm.monthEndBillingId=item.id;
	          		 }
	          		 else if(item.processName=='GenerateYearEndBillingReport'){
	          			vm.yearEndBillingId=item.id;
	          		 }	
	          		 else if(item.processName=='UpdateNewTradingBranchForCustomers'){
	          			vm.updateTradingBranchId=item.id;
	          		 }
	          		 else if(item.processName=='ShiftingTheSystemDate'){
	          			vm.shiftDateId=item.id;
	          		 }
	          		 else if(item.processName=='PrepareNextBusinessDateData'){
	          			vm.prepareNextdateDataId=item.id;
	          		 }	          		 
	          		 item.status = vm.NOTSTARTED;
	          		 item.remarks='';	       		
	          		 vm.newProcessResultMap[item.id] = null;
	          		 vm.processResultMap[item.id] = null;
	          		 vm.processErrorMap[item.id] = null;
	          		 vm.newProcessErrorMap[item.id] = null;
          		 });
          	}
          	if(vm.eodStatus==vm.INPROGRESS || vm.eodStatus==vm.INCOMPLETE){
                $fileLogger.info('lookupWorkFlowSuccess getEODProcessStatusLog');
          		 EODProcess.getEODProcessStatusLog({
    				"businessDate" : convertedCurrentBusinessDate
    			} ,getEODProcessStatusLogSuccess,getEODProcessStatusLogFailure);
          		vm.showResumeButton=true;
          		vm.showEodProcess=true;
          		vm.isEODvalidated=true;
          	 }
        
        }
        
	function getEODProcessStatusLogSuccess(result){
		
		var processIndex = vm.idArray.indexOf(result.processId);
   		angular.forEach(vm.eodProcessList,function(item,index){
   			if(index < processIndex)
   			item.status = vm.COMPLETED;
   		})
		
	}
	
	function getEODProcessStatusLogFailure(){}
	
        
        
        function lookupWorkFlowFailure(error){
       	 //do nothing
        }
        
        //END//
		// FETCH ERROR MESSAGE AND SET TO GLOBAL VARIABLE/
         function loadErrorMEssages(){
        	 StaticLookUp.getProcessErrorMessages(loadErrorMEssagesSuccess, loadErrorMEssagesFailure);
         }
         function loadErrorMEssagesSuccess(result){
        	        	
        	 vm.errorList  = result[0];
         }
         function loadErrorMEssagesFailure(error){
        	 //do nothing
         }

		 
         // get all eod process log
		function getAllEODProcessStatusLog(){
    				
					EODProcess.getAllEODProcessStatusLog({"businessDate" : convertedCurrentBusinessDate},getAllEODProcessStatusLogSuccess,getAllEODProcessStatusLogFailure);
		}
		//success - get all eod process log
		function getAllEODProcessStatusLogSuccess(result) {
			vm.eodStatusLogList = result;
			angular.forEach(vm.eodStatusLogList, function(value,index){
						 var indexOfRevokeProcess = vm.idArray.indexOf(value.process.id);
						var item = vm.eodProcessList[indexOfRevokeProcess];
						var nextItem = vm.eodProcessList[indexOfRevokeProcess+1];
						 if(item.status == vm.NOTSTARTED || item.status == vm.INPROGRESS){
							if(value.isCompleted==true){
								item.remarks = "";
								item.status = vm.COMPLETED;
								if(item.processName=='EODStatusUpdateProcess'){
									vm.eodDone=true;
									eodStatusLogDownload();
								 }
								 updateRemarksInProcessList(nextItem.id ,"",   vm.INPROGRESS);
							}else{
								item.remarks = value.errorMessage;
								item.status = vm.FAILED;
								stopAutoRefresh();
								eodErrorStatusLogDownload(item, value.errorMessage);
							}
						}
						
								
			});
			
		}

		function getAllEODProcessStatusLogFailure(result) {
			// nothing 
		}

      // function to start the EOD process on start/resume button click
 		function beginEODProcess(actionType) {
 			 $fileLogger.info(actionType + ' Clicked');
 			vm.isRefreshDisabled = false;
 			vm.showEodProcess=true;
 			vm.isEodProcessSuccessfull=null;
 			vm.isEodCompleted = false;
 			
 			if(vm.currentBusinessDate !=null && vm.currentBusinessDate !=undefined && vm.currentBusinessDate !=''){
 				if ( vm.currentBusinessDate > vm.today ) {
 					
 					AlertService.error($filter('translate')('gatewayApp.eodProcess.forwardDateError'));
 					
 				}else if(vm.eodStatus == vm.COMPLETED){
 					AlertService.error($filter('translate')('gatewayApp.eodProcess.alreadyCompleted'));
 				}
 				else {
 					//call the  process
					$rootScope.eodInProgress = true;
 					processeod();

 				}
 			}
 			

 		}
        
 		// Process methods before starting the eod process /
		var processeod = function processeod(){
				vm.executionCount = vm.executionCount+1;
				if(vm.eodStatus == vm.INCOMPLETE || vm.eodStatus == vm.INPROGRESS ){
					$fileLogger.info('updateEODPRocessLog');
					EODProcess.updateEODPRocessLog({"businessDate" : convertedCurrentBusinessDate},updateEODLogSuccess, updateEODLogFailure);
				}else{
                $fileLogger.info('processEOD');
				updateRemarksInProcessList(vm.logOffId ,"",   vm.INPROGRESS);
           		 $fileLogger.info('logOffUsersForcibly');
				EODProcess.initiateEODProcess( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.OPEN ,
				"nextBusinessDate": convertedNextBusinessDate,
				"processId" : vm.logOffId,
				"executionCount" : vm.executionCount
				}, initialEODProcessSucess, initialEODProcessError);
			}
			
		}
		
		function clearPreviousStatus(){
			
			var indexOfRevokeProcess = vm.idArray.indexOf(vm.revokeProcessId);
			 
			 angular.forEach(  vm.eodProcessList, function(item,index){
				 if(index >= indexOfRevokeProcess){
					 item.status = vm.NOTSTARTED;
					 item.remarks='';
					 vm.processResultMap[ item.id] = null;	
				 }else{
					item.status = vm.COMPLETED;
					item.remarks='';
				 }
			 });
		}
	
		function updateEODLogSuccess(result){
			vm.executionCount = parseInt(result.executionCount);
			vm.executionCount = vm.executionCount+1;
			vm.revokeProcessId = parseInt(result.revokeProcessId);
			$fileLogger.info('updateEODLogSuccess execution count -' + vm.executionCount + ' vm.revokeProcessId' + vm.revokeProcessId);
			clearPreviousStatus();
			if(vm.eodStatus == vm.INPROGRESS && vm.revokeProcessId != vm.dormantId && vm.revokeProcessId != vm.logOffId){
					
					AlertService.error($filter('translate')('gatewayApp.eodProcess.alreadyInProgress'));
			}else{
			
				vm.eodStatus = vm.INPROGRESS ;
				vm.disbleEod = true;	
				console.log('EOD procss resumed with process id '+ vm.revokeProcessId);
				$fileLogger.info('EOD procss resumed with process id' + vm.revokeProcessId);
				$fileLogger.info('current execution Count', vm.executionCount);
				if(vm.orderProcessIds.includes(vm.revokeProcessId)){
					successProcessor(startEODInOrderService);
				}else if(vm.revokeProcessId == vm.logOffId){
					updateRemarksInProcessList(vm.logOffId ,"",   vm.INPROGRESS);
					EODProcess.initiateEODProcess( {				
						"businessDate" : convertedCurrentBusinessDate,
						"status" : vm.INPROGRESS ,
						"nextBusinessDate": convertedNextBusinessDate,
						"processId" : vm.revokeProcessId,
						"executionCount" : vm.executionCount
						}, initialEODProcessSucess, initialEODProcessError);
				}
				else{
				updateRemarksInProcessList(vm.revokeProcessId ,"",   vm.INPROGRESS);
				EODProcess.initiateEODProcess( {				
					"businessDate" : convertedCurrentBusinessDate,
					"status" : vm.INPROGRESS ,
					"nextBusinessDate": convertedNextBusinessDate,
					"processId" : vm.revokeProcessId,
					"executionCount" : vm.executionCount
					}, processeodEndSucess, processeodEndError);
				}
			}
		}
		
		function updateEODLogFailure(){
			
		}
		

		function initialEODProcessSucess(result){
			
				updateRemarksInProcessList(vm.logOffId ,"",  vm.COMPLETED);
			  //clear revokeProcessId before starting the EOD process in OPS. 
			  vm.revokeProcessId = null;
				vm.eodStatus = vm.INPROGRESS ;
				vm.disbleEod = true;			
				successProcessor(startEODInOrderService);
			
		}
		
		
		function initialEODProcessError(error){
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.logOffId ,errorCode , vm.FAILED );
			AlertService.error( errorCode );
		}
		
		
		function updateRemarksInProcessList(processID, errorMessage, status){
			var indexOfRevokeProcess = vm.idArray.indexOf(processID);
			var item = vm.eodProcessList[indexOfRevokeProcess];
			item.remarks = errorMessage;
			item.status = status ;
			//download log file whenever any process is failed.
			if(status==vm.FAILED){
				eodErrorStatusLogDownload(item, errorMessage);
			}
		}
		
		
		
		function eodErrorStatusLogDownload(item, errorMessage) {
			$fileLogger.info('EOD stopped in ' + item.processName);
			vm.isEodProcessSuccessfull=false;
			vm.showResumeButton=true;
			$fileLogger.info(errorMessage);
			$timeout(function () {
				$rootScope.eodInProgress = false;
				FileUtil.downloadLogFile("FXRetail_EOD_Process");
			}, 1000);
		}

		 function disableEodProcess(){
		    	
		    	if(vm.currentBusinessDate ==undefined || vm.currentBusinessDate == null || vm.currentBusinessDate == ''){
		    		// no valid businessdate - disable start button
		    		return true;
		    	}else if(vm.eodStatus ==vm.OPEN || vm.eodStatus==null || vm.eodStatus==undefined ||vm.eodStatus==''){
		    		// eodStatus is open for the business date then enable start button
		    		vm.isRefreshDisabled=true;
		    		return false;
		    	}else if( vm.eodStatus ==vm.INCOMPLETE){
		    		
		    		vm.isRefreshDisabled=false;
		    		return false;
		    		
		    	}
		    	else if( (vm.eodStatus ==vm.INPROGRESS ) && !(vm.isEodProcessSuccessfull==null || vm.isEodProcessSuccessfull==false)){
		    		// enable start button when eodStatus is in in progress and eod process is not successful
		    		vm.isRefreshDisabled=false;
		    		return  false;
		    	}else if( vm.eodStatus ==vm.INPROGRESS  && (vm.isEodProcessSuccessfull==null || vm.isEodProcessSuccessfull==true)){
		    		// disable start button when sodStatus is in inprogress and sod process is  successfull
		    		vm.isRefreshDisabled=false;
		    		return true;
		    	}else if( vm.COMPLETED ==vm.eodStatus ){
		    		vm.isRefreshDisabled=true;
		    		return true;
		    	}
		    	
		    }
		 
		 
		
		
		function getNextBusinessDateSuccess(result) {
			vm.nextBusinessDate = DateUtils.convertLocalDateFromServer(result.date);
			vm.nextDate = $filter('date')(vm.nextBusinessDate,"dd/MM/yyyy");
			vm.nextDate = vm.nextDate + " | " + days[vm.nextBusinessDate.getDay()];
			convertedNextBusinessDate = DateUtils.convertLocalDateToServer(vm.nextBusinessDate);
		}
		function getNextBusinessDateError(error) {
		}
		
		

		
		
		
		
		
		/* begin EOD in order service */
		var startEODInOrderService = function startEODInOrderService(){
			var nextProcesss = vm.eodProcessList[1];
			if(vm.revokeProcessId==null){
	
				vm.processId = nextProcesss.id;
			}else{
				vm.processId = vm.revokeProcessId;
				
				nextProcesss = vm.eodProcessList[vm.idArray.indexOf(vm.revokeProcessId)];
			}
			console.log('next order server EOD process - ' + nextProcesss.id);
		
				updateRemarksInProcessList(vm.processId ,"",   vm.INPROGRESS);
				
				var eodRequest = {};
				eodRequest.executionCount = vm.executionCount;
				eodRequest.businessDate = convertedCurrentBusinessDate;
				eodRequest.startingProcessID =  vm.processId;

                $fileLogger.info('initiateEODInOrderService');
				EODProcess.initiateEODInOrderService(eodRequest,
					startEODInOrderServiceSuccess, startEODInOrderServiceFailure);
		}

		function startEODInOrderServiceSuccess(result){
			//do nothing as this is async call
            
			$fileLogger.info('initiateEODInOrderService success');
			
		}
		function startEODInOrderServiceFailure(error){
			
			// EOD Failure
			var errorCode = errorProcessor(error);
			$fileLogger.info('initiateEODInOrderService failed ' + errorCode);
			updateRemarksInProcessList(vm.processId ,"",  vm.FAILED);
            $fileLogger.info('processEOD called again in startEODInOrderServiceFailure');
			EODProcess.processEOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.INCOMPLETE,
				"nextBusinessDate" : convertedNextBusinessDate
			},  processENDEODSucess, processENDEODError);
			AlertService.error( errorCode );
		}
		/*  END */
		
		
		
		
		
		
		/*General method used in all process */
		function successProcessor(successProcessToInvoke){
			
			if(vm.isEodProcessSuccessfull == null || vm.isEodProcessSuccessfull == true){
				// next process is invoked only when previous one is is successful
				if(successProcessToInvoke!=null)
					successProcessToInvoke();
			}
		}
		

		function errorProcessor(error){		
			var errorcode = "";
			if( error.message !=undefined &&  error.message !=null &&  error.message!=""){
				errorcode  = $filter('translate')( error.message ) //+  error.businessDate;				
				//vm.errorMessage = error.exceptionMessage +  errorcode ;
			}else if( !(error.data==undefined || error.data==null || error.data=='')){
				errorcode  = $filter('translate')( error.data.message );
				//vm.errorMessage = error.data.exceptionMessage +" "+  errorcode;
			}else{
				errorcode = $filter('translate')( "Service unavailable");
			}
			 		
			//AlertService.error( errorcode );
			vm.isEodProcessSuccessfull=false;
			return errorcode;
		}
		
		

		// Watch for Process failure through the isEodProcessSuccessfull flag , if it is failed log the status, if it true do nothing
		 $scope.$watch('vm.isEodProcessSuccessfull', function(value){
				if(value!=null && value!=undefined && value == false){
	               
					//method to enable or disable start button if process is unsuccessfull
					vm.eodStatus = vm.INCOMPLETE;
					vm.disbleEod = disableEodProcess();
					
				}
			
				});
	
	
		 orderServiceEODProcessUpdateSubscription = $rootScope.$on("orderServiceEODProcessUpdate", function(event,result){
			 $fileLogger.info(" websocket message orderServiceEODProcessUpdate recevied for process id " + result.processID + "status " +result.success );
		    	if( result.success!=vm.processResultMap[result.processID]){
		    
		    		processStatusNotificationFromOrderService(result);
		    	}else if( result.success==false && result.exceptionNumber!=vm.processErrorMap[result.processID]){
		    		//update new error code
		    		processStatusNotificationFromOrderService(result);
		    	}
		    	
	         });
	  
      function unsubscribeToEODProcessUpdate(){
          if(orderServiceEODProcessUpdateSubscription!=null){
              // unscribe to order service update notification
              orderServiceEODProcessUpdateSubscription();
          }
      }
      var webscoketDisconnect = $rootScope.$on("websocketDisconnected", function(event,result){
	    	
	    	console.log("web socket disconnected with reason " + result);
	    	 $fileLogger.info("web socket disconnected with reason " + result);
	    	var logOffIndex = vm.idArray.indexOf(vm.logOffId);
	    	var dormantIndex = vm.idArray.indexOf(vm.dormantId);
	    	angular.forEach( vm.eodProcessList, function(item,index){
	    		// set the status as failed only for order service process when websocket disconnection occurs
	    		
	   		 	if(item.status == vm.INPROGRESS && index > logOffIndex && index < dormantIndex){
	   		 		item.status = vm.FAILED;
	   		 		item.remarks=vm.errorList["7001"].rejectionReason;
	   		 	}
	    		
	    	
	    	});
	    	
     });
	    $scope.$on('$destroy', function() {
	        	unsubscribeToEODProcessUpdate();	        	
	        	if(webscoketDisconnect!=null){
	  			  webscoketDisconnect();
	  		  }	
	    });
		
		function processENDEODSucess(result){			
		    	
				
		}
		function processENDEODError(error){
			//DO NOthing
		}
		   
		    
		function eodStatusLogDownload() {
			stopAutoRefresh();
			AlertService.success($filter('translate')('gatewayApp.eodProcess.eodProcesscompleted') + $filter('date')(vm.currentBusinessDate, "dd-MMM-yyyy"));
			$timeout(function () {
				$rootScope.eodInProgress = false;
				FileUtil.downloadLogFile("FXRetail_EOD_Process");
			}, 1000);
		}

		    
			
		
		
		function stopAutoRefresh() {
			if (angular.isDefined(vm.autoRefreshToggle)) {
				$interval.cancel(vm.autoRefreshToggle);
				vm.autoRefreshToggle = undefined;
			}
		}

			function processStatusNotificationFromOrderService(processStatus){
				

					var indexOfProcess =  vm.idArray.indexOf(processStatus.processID);
					var item = vm.eodProcessList[indexOfProcess];
					console.log("notification received from order service for process "+ processStatus.processID);
							if(item.id==processStatus.processID &&  processStatus.success==true ){
							item.status = vm.COMPLETED;
							item.remarks = "";
							var keyArray = vm.idArray
							var index = keyArray.indexOf(processStatus.processID);
							var nextIndex = index+1;
							if(nextIndex < keyArray.length){
								var nextProcess = vm.eodProcessList[nextIndex];
								if(nextProcess!=undefined && nextProcess!=null && nextProcess!='' && nextProcess.status!= vm.COMPLETED && nextProcess.status!= vm.FAILED){
									nextProcess.status = vm.INPROGRESS ;
									
								}
								if(item.processName == 'DisconnectBroadcastMQChannel' && !vm.isEodCompletedForOrder){
									console.log('received DisconnectBroadcastMQChannel completion message');
									vm.isEodCompletedForOrder  = true;
									
									 $fileLogger.info('calling to start from make user dormant method - from processStatusNotificationFromOrderService');
									// call rest of the eod process in QRS
									EODProcess.initiateEODProcess( {				
										"businessDate" : convertedCurrentBusinessDate,
										"status" : vm.INPROGRESS ,
										"nextBusinessDate": convertedNextBusinessDate,
										"processId" : vm.dormantId,
										"executionCount" : vm.executionCount
										}, processeodEndSucess, processeodEndError);
								}
								
							}
				
						vm.processResultMap[processStatus.processID] = processStatus.success;
						vm.processErrorMap[processStatus.processID] = null;
					   
				   
				   }else if(item.id==processStatus.processID &&  processStatus.success==false){
							
					   item.status = vm.FAILED;
					   item.remarks = "Process Failed";
					   vm.isEodProcessSuccessfull = false;			
					   var errorKey = processStatus.exceptionNumber+""+processStatus.messageNumber;
				   
					   if( vm.errorList[errorKey]!=null && vm.errorList[errorKey]!=undefined 
							   && vm.errorList[errorKey]!='' ){
						   item.remarks =  vm.errorList[errorKey].rejectionReason;
						   
					   }

						vm.processResultMap[processStatus.processID] = processStatus.success;
						vm.processErrorMap[processStatus.processID] = processStatus.exceptionNumber;
					   
				   }
				
					 
			}
			function processeodEndSucess(result){
				
				vm.autoRefreshToggle = $interval(function() {
					getAllEODProcessStatusLog();
						
					}, autoRefreshInterval);
			}
			function processeodEndError(result){

			}		
		
		/* Refresh*/
		  $scope.toggle = true;
	        function refresh() {
				$fileLogger.info('eod screen refreshed');
	            $scope.toggle = false;
	            $scope.refreshFlag = false;
	            loadDates();
	            if ($scope.toggle == false) {
	                $timeout(function() {
	                    $scope.toggle = true;
	                }, 2000);
	                $scope.refreshFlag = false;
	            }
	        };
		
		
	    function logScreenAccess(){
	           ScreenLog.screenLog( entityConstants.ENTITY_SHORT_NAME.EOD_PROCESS,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	        }


	    /**Validate EOD process*/
	    function validateEod(){
	    	EODProcess.validateEOD(validateSuccess,validateFailure);
	    	vm.validation = vm.INPROGRESS;
	    }
	    
	    function validateSuccess(result){
	    	
	    	if(result.isValidated==true){
	    		vm.validation = vm.COMPLETED;
	    		vm.remarks="";
	    		validateSessionClose();
	    	}else{
	    		vm.validation = vm.FAILED;
	    		vm.remarks="Not processed";
	    	}
	    	
	    }
	    
	    function validateFailure(error){
	    	var errorCode = '';
	    	if(error.message!=null && error.message!=undefined && error.message!=''){
	    		errorCode = $filter('translate')(error.message);
	    	}
	    	vm.remarks=errorCode;
	    	vm.validation = vm.FAILED;
	    }
	    
	    function validateInteractiveServerDbProcessing(){
            $fileLogger.info('Validate Clicked');
	    	EODProcess.validateDBProcessing({"serverName" : "Interactive_Server"},validateInteractiveServerDbProcessingSuccess,validateInteractiveServerDbProcessingFailure);
	    	vm.validateInteractiveDBProcessing = vm.INPROGRESS;
	    }
	    
	    function validateInteractiveServerDbProcessingSuccess(result){
	    	vm.validateInteractiveDBProcessing = vm.COMPLETED;
    		vm.remarksForInteractiveServerDBProcessing="";
    		validateBroadcastServerDbProcessing();
	    	
	    }
	    
	    function validateInteractiveServerDbProcessingFailure(error){
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.remarksForInteractiveServerDBProcessing=errorCode;
	    	vm.validateInteractiveDBProcessing = vm.FAILED;
	    }
	    
	    function validateBroadcastServerDbProcessing(){
	    	EODProcess.validateDBProcessing({"serverName" : "Broadcast_Server"},validateBroadcastServerDbProcessingSuccess,validateBroadcastServerDbProcessingFailure);
	    	vm.validateBroadcastDBProcessing = vm.INPROGRESS;
	    }
	    
	    function validateBroadcastServerDbProcessingSuccess(result){
	    	vm.validateBroadcastDBProcessing = vm.COMPLETED;
    		vm.remarksForBroadcastServerDBProcessing="";
    		validateResponseReceived();
	    }
	    
	    function validateBroadcastServerDbProcessingFailure(error){
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.remarksForBroadcastServerDBProcessing=errorCode;
	    	vm.validateBroadcastDBProcessing = vm.FAILED;
	    }
	    
	    function validateResponseReceived(){
	    	vm.responseReceivedValidation = vm.INPROGRESS;
	    	vm.responseReceivedMessage="";
	    	EODProcess.validateResponseReceived(validateResponseReceivedSuccess,validateResponseReceivedFailure);
	    }
	    
	    function validateResponseReceivedSuccess(result){    	
	    	
	    	if( result.responseReceived==true){
	    		vm.responseReceivedValidation = vm.COMPLETED;
	    		vm.responseReceivedMessage="";
	    		validateEod();
	    		
	    	}else{
	    		vm.responseReceivedValidation = vm.FAILED;
	    		vm.responseReceivedMessage= "Not processed";
	    	}
	    	
    		
	    }
	    
	    function validateResponseReceivedFailure(error){
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.responseReceivedMessage = errorCode;
	    	vm.responseReceivedValidation = vm.FAILED;
	    }
	    
	    
	    function validateSessionClose(){
	    	vm.sessionCloseValidation = vm.INPROGRESS;
	    	EODProcess.validateSessionClose({"currentBusinessDate" : convertedCurrentBusinessDate},validateSessionCloseSuccess,validateSessionCloseFailure);
	    	
	    }
	    
	    function validateSessionCloseSuccess(result){    	
	    	vm.isEODvalidated = result.isSessionClosed;
	    	if( result.isSessionClosed==true){
	    		vm.sessionCloseValidation = vm.COMPLETED;
	    		vm.sessionCloseMessage="";
	    		
	    	}else{
	    		vm.sessionCloseValidation = vm.FAILED;
	    		vm.sessionCloseMessage= $filter('translate')('gatewayApp.eodProcess.sessionCloseMessage');
	    	}
	    	
	    	
	    }
	    
	    function validateSessionCloseFailure(error){
	    	
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.sessionCloseMessage=errorCode;
	    	vm.sessionCloseValidation = vm.FAILED;
	    }
	  
	    
		
	
	}
})();
