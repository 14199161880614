(function() {
	'use strict';

	angular.module('gatewayApp').controller('UsersDialogController',
			UsersDialogController);

	UsersDialogController.$inject = [ '$scope', '$state', '$rootScope',
			'GlobalValues', 'workflowSharedService', '$uibModalInstance',
			'User', 'entity', 'DateUtils', 'ScreenLog', 'StaticLookUp' ,'AlertService','$filter'];

	function UsersDialogController($scope, $state, $rootScope, GlobalValues,
			workflowSharedService, $uibModalInstance, User, entity, DateUtils,
			ScreenLog, StaticLookUp,AlertService,$filter) {
		var vm = this;
		vm.screenName = 'UserManagement';
		vm.screenLog = ScreenLog;
		vm.users = entity;
		vm.clear = clear;
		vm.save = save;
		vm.clicked = false;
		$scope.showButton = false;
		vm.resubmitUsers = resubmitUsers;
		vm.globalValues = GlobalValues;
		vm.mobileCountryCode = 91;
		vm.gender = "M";
		$scope.showButton = false;
		vm.userType = vm.globalValues.getOrganisation().organisationType.shortName;
		vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
		vm.disablePID = false;
		vm.openForgotPID=openForgotPID;
		vm.country=vm.globalValues.getCountry();
		console.log(vm.country);
		vm.bank=vm.globalValues.getBank();
		vm.todayDate = new Date();
		$scope.formChangedForEmailOrMobile = false;
		vm.otpVerifiedId='';
		vm.userDetails=angular.copy(vm.users);
		// User.query(function(result) {
		// 	vm.allusers = result;
			
		// });
		

		if (workflowSharedService.viewFrom == 'userRole') {
			vm.users = entity;
			/*if(vm.users.id!==null){
			    ScreenLog.screenLog({'entityName': 'UserManagement', 'entityRecordId': vm.users.id, 'action': 'EDIT'});
			}else{
			    ScreenLog.screenLog({'entityName': 'UserManagement', 'entityRecordId': 0, 'action': 'ADD'});
			}*/
			if (vm.users.id != null) {
				vm.mobileCountryCode = vm.users.mobileCountryCode;
				vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
				vm.gender = vm.users.gender;
				vm.email = vm.users.email;
				vm.mobile = vm.users.mobile;
				vm.country=vm.users.country;
				if (vm.users.entityOwnerType.shortName == 'Branch') {
					vm.branch = vm.users.branch;
				}
			}
			$scope.showButton = false;
		} else if (workflowSharedService.viewFrom == 'usermanagementtodolist_resubmit') {
			vm.users = angular.fromJson($rootScope.payload);
			vm.mobileCountryCode = vm.users.mobileCountryCode;
			vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
			vm.gender = vm.users.gender;
			vm.email = vm.users.email;
			vm.mobile = vm.users.mobile;
			vm.country=vm.users.country;
			if (vm.users.entityOwnerTypeShortName == 'Branch') {
				vm.branch = vm.users.branch;
			}

			var entityRecordId = 0;
			if (vm.users.id != null) {
				entityRecordId = vm.users.id;
			}
			vm.screenLog.screenLog(vm.screenName, entityRecordId, 'RESUBMIT');
			vm.disableForm = false;
			$scope.showButton = true;
		} else if (workflowSharedService.viewFrom == 'usermanagementtodolist_resubmit_delink') {
			vm.users = angular.fromJson($rootScope.payload);
			vm.mobileCountryCode = vm.users.mobileCountryCode;
			vm.country=vm.users.country
			vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
			vm.gender = vm.users.gender;
			vm.users.mobileCountryCode = vm.mobileCountryCode;
			vm.users.country=vm.country;
			vm.email = vm.users.email;
			vm.mobile = vm.users.mobile;
			
			if (vm.users.entityOwnerType.shortName == 'Branch') {
				vm.branch = vm.users.branch;
			}
			vm.disableForm = true;
			$scope.showButton = true;
		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			
			if(moment(vm.dob).isAfter(vm.todayDate,'day')){
        		
        		 AlertService.error($filter('translate')('userManagement.futuredob'));
        		 vm.clicked=false;
        	 }else{
        		 vm.users.login = vm.users.login.toLowerCase();
     			vm.users.langKey = "en";
     			vm.users.organisation = null;
     			vm.users.mobileCountryCode = vm.mobileCountryCode;
     			vm.users.userType = vm.globalValues.getOrganisation().organisationType.shortName;
     			vm.users.gender = null;
     			vm.users.country=null;
				vm.users.address1='';
				vm.users.address2='';
				vm.users.city='';
				vm.users.state=null;
				vm.users.pincode='';
     			vm.users.dob = DateUtils.convertLocalDateToServer(vm.dob);
     			if (vm.users.userType == 'CLEARCORP') {
     				vm.users.entityOwnerTypeShortName = 'Clearcorp';
     			} else if (vm.users.userType == 'CUSTOMER') {
     				vm.users.entityOwnerTypeShortName = 'MultiUserCustomer';
     			} else if (vm.users.userType = 'BANK'
     					&& vm.userEntityOwnerType == 'Branch') {
     				vm.users.entityOwnerTypeShortName = 'Branch';
     				vm.users.entityOwnerType = vm.globalValues.getUser().entityOwnerType;
     				vm.users.branch = vm.globalValues.getBranch();
     				vm.users.branchId=vm.globalValues.getBranch().id;
     			} else {
     				vm.users.entityOwnerTypeShortName = vm.users.entityOwnerType.shortName;
     			}
     			if (vm.branch != null) {
     				vm.users.branchId = vm.branch.id;
     				vm.users.branch = vm.branch;
     			}
     			if (vm.users.id != null) {
     				User.update({
     					"orgId" : vm.globalValues.getOrgId()
     				}, vm.users, onSaveSuccess, onSaveError);
     			} else {
     				User.save({
     					"orgId" : vm.globalValues.getOrgId()
     				}, vm.users, onSaveSuccess, onSaveError);
     			}
        	 }
			

		}

		function onSaveSuccess(result) {
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
		//if(repo==false){
			// 	AlertService.error($filter('translate')('userManagement.isAuthorisedPerson'));
		//}
			vm.clicked = false;
		}

		$rootScope.$on('changeEmailRefresh', function(event, result) {
			vm.users.email = $rootScope.email;
			vm.otpVerifiedId=$rootScope.otpVerifiedId;
			vm.users.otpVerifiedId =vm.otpVerifiedId;
			$scope.formChangedForEmailOrMobile = true;
		});

		$rootScope.$on('changeMobileRefresh', function(event, result) {
			vm.users.mobile = $rootScope.mobile;
			vm.mobileCountryCode = $rootScope.mobileCountryCode;
			$scope.formChangedForEmailOrMobile = true;
		});

		function resubmitUsers() {
			if(moment(vm.dob).isAfter(vm.todayDate,'day')){
				vm.clicked=false;
        		 AlertService.error($filter('translate')('userManagement.futuredob'));
        		 
        	 }else{
        		 vm.users.login = vm.users.login.toLowerCase();
     			vm.users.langKey = "en";
     			vm.users.organisation = null;
     			vm.users.mobileCountryCode = vm.mobileCountryCode;
     			vm.users.userType = vm.globalValues.getOrganisation().organisationType.shortName;
     			vm.users.gender = null;
     			vm.users.country=null;
			    vm.users.address1='';
				vm.users.address2='';
				vm.users.city='';
				vm.users.state=null;
				vm.users.pincode='';

     			vm.users.dob = DateUtils.convertLocalDateToServer(vm.dob);
     			if (vm.users.userType == 'CLEARCORP') {
     				vm.users.entityOwnerTypeShortName = 'Clearcorp';
     			} else if (vm.users.userType == 'CUSTOMER') {
     				vm.users.entityOwnerTypeShortName = 'MultiUserCustomer';
     			} else if (vm.users.userType = 'BANK'
     					&& vm.userEntityOwnerType == 'Branch') {
     				vm.users.entityOwnerTypeShortName = 'Branch';
     				vm.users.entityOwnerType = vm.globalValues.getUser().entityOwnerType;
     				vm.users.branch = vm.globalValues.getBranch();
     				vm.users.branchId=vm.globalValues.getBranch().id;
     			} else {
     				vm.users.entityOwnerTypeShortName = vm.users.entityOwnerType.shortName;
     			}
     			if (vm.branch != null) {
     				vm.users.branchId = vm.branch.id;
     				vm.users.branch = vm.branch;
     			}
     			User.resubmitUserManagement({
     				"taskId" : $rootScope.taskID
     			}, vm.users, onResubmitSuccess, onResubmitError);
     			$uibModalInstance.dismiss('cancel');
        	 }
			
		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
		}
		function onResubmitError(error) {

			vm.dob = DateUtils.convertLocalDateFromServer(vm.users.dob);
			//if(repo==false){
			// 	AlertService.error($filter('translate')('userManagement.isAuthorisedPerson'));
		//}
		}

		vm.fetchUserByPersonalId = function fetchUserByPersonalId() {
			User
					.fetchUserByPersonalId(
							{
								'personalId' : vm.personalId
							},
							function(result) {
								if (result != null) {
									vm.users = result;
									vm.users.login = null;
									vm.users.mobile = null;
									vm.users.email = null;
									vm.users.id = null;
									vm.mobileCountryCode = vm.users.mobileCountryCode;
									vm.country=vm.users.country;
									vm.users.entityOwnerType = null;
									vm.dob = DateUtils
											.convertLocalDateFromServer(vm.users.dob);
									vm.gender = vm.users.gender;
									/*if (vm.users.entityOwnerType.shortName == 'Branch') {
										vm.branch = vm.users.branch;
									}*/
									vm.disablePID = true;
									vm.users.personId = vm.personalId;
								} else {
									MessageBox.openMessageBox("Alert", "Invalid personal ID", "Ok", "personid");
								}

							});
		}
		
		$rootScope.$on('gatewayApp.messagebox.ok.personid', function (event, data) {
			//do nothing
		});

		function openForgotPID(){
			if(vm.users==null || vm.users.id==null){
				$state.go('forgot-pid');
				$rootScope.$on('fetchPID', function(event, result) {
					vm.disablePID = false;
					vm.users = null;
					vm.dob = null;
					vm.gender = "M";
		        	vm.personalId= $rootScope.PID;
		        });
				
			}
			
			
		}
	}
})();
