(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('RelationshipBankFeesChargesViewController', RelationshipBankFeesChargesViewController)

	RelationshipBankFeesChargesViewController.$inject = ['ScreenLog','$scope', '$state', '$rootScope', 'GlobalValues','RelationshipBankFeeAndChargesViewService', 'workflowSharedService', 'entityConstants','BankFeeViewForEntitiesUtility','$timeout'];

	function RelationshipBankFeesChargesViewController(ScreenLog,$scope, $state, $rootScope, GlobalValues,RelationshipBankFeeAndChargesViewService,workflowSharedService,entityConstants,BankFeeViewForEntitiesUtility,$timeout) {
		var vm = this;
		vm.bankFeeChargeDetails = [];
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.BANK_FEES_CHARGES_VIEW;
		vm.globalValues=GlobalValues;
		$scope.refresh=refresh;
		vm.orgId = vm.globalValues.getCustomer();  
		
		console.log(vm.orgId);
		vm.screenLog = ScreenLog;
		logScreenAccess();
		vm.loadAll = loadAll;
		vm.bankRegistration ={};
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.openBankFeesChargesPreview = openBankFeesChargesPreview;
		function openBankFeesChargesPreview(bankFeesChargesFile){
			BankFeeViewForEntitiesUtility.previewBankFee(bankFeesChargesFile,'file');	
		}

		vm.loadAll();
	
		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

	
		function loadAll() {
			RelationshipBankFeeAndChargesViewService.getRelationshipBankViewDto({customerId:vm.orgId.id},onRetreiveSuccess,onRetreiveFailure);
				
				function onRetreiveSuccess(result) {
					console.log(result);
					
					vm.bankFeeChargeDetails = result;
					console.log(vm.bankFeeChargeDetails);

				}
				function onRetreiveFailure(error){
					console.log(error);
				}

			
		}
		
		$scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };

		

	}
})();
