(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'instrumentView'
        })
        
        .controller('InstrumentViewController', InstrumentViewController);

    InstrumentViewController.$inject = ['$scope', '$state', 'InstrumentView', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function InstrumentViewController($scope, $state, InstrumentView , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.INSTRUMENT_VIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        $scope.refresh=refresh;
        workflowSharedService.viewFrom = "instrumentView";
       
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   InstrumentView.query({"orgId": GlobalValues.getOrgId()},function(result) {
                vm.instruments = result;
             });        	
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
