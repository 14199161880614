(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'heConnectivitySettings'
        })
        
        .controller('HeConnectivitySettingsController', HeConnectivitySettingsController);

    HeConnectivitySettingsController.$inject = ['$scope', '$state','$rootScope','HeConnectivity', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout','MessageBox','Auth'];

    function HeConnectivitySettingsController($scope, $state,$rootScope, HeConnectivity , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,MessageBox,Auth) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.HE_CONNECTIVITY_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "heConnectivitySettings";
        vm.entityOwnerType=vm.globalValues.getEntityOwnerType().shortName;
        $scope.refresh=refresh;
        
        vm.permitHeConnectivitySettingsEditAction = EntityPermissions.hasPermission('HEConnectivitySettings.EDIT');
      /* if(vm.permitHeConnectivitySettingsEditAction ==false){
			//message box 
			MessageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'permitHeConnectivitySettingsEditAction');
		}

		$rootScope.$on('gatewayApp.messagebox.ok.permitHeConnectivitySettingsEditAction', function (event) {
			Auth.sessionlogout();
		});
		
		$rootScope.$on('gatewayApp.messagebox.clear.permitHeConnectivitySettingsEditAction', function (result){
			Auth.sessionlogout();
		})*/
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   HeConnectivity.query({"orgId": GlobalValues.getOrgId()},function(result) {
                vm.connectivitySettings = result;
             });        	
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
