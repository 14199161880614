(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Notifications', Notifications);

    Notifications.$inject = ['$resource'];

    function Notifications ($resource) {
        var resourceUrl = 'api/';

        return $resource(resourceUrl, {}, {

            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },

            'getPopupNotificationsByUserId': {
          	  method: 'GET',
          	  isArray: false,
          	  url: 'api/popup-notifications',
              transformResponse: function (data) {
                      if (data) {
                          data = angular.fromJson(data);
                      }
                      return data;
                  }
           },
           
           'getPopupNotificationsCount': {
           	  method: 'GET',
           	  isArray: false,
           	  url: 'api/popup-notifications-count',
               transformResponse: function (data) {
                       if (data) {
                           //data = parseInt(data, 10);
                       }
                       return data;
                   }
            },
            
          'updatePopupNotificationStatus': {
                method: 'POST',
                params: {notificationId:'@notificationId'},
                url: 'api/popup-notifications-status/:notificationId',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
            'insertNotifications':{
            	method:'POST',
            	
                url: 'api/insert-notifications/message',
                params: {message:'@message'},
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'broadcastMessage':{
            	method:'POST',
                url: 'order/broadcast-message/message',
                transformRequest: function (data) {
                	var copy = angular.toJson(data);
                    return copy;
                }
            }
            
            
        });
    }
})();
