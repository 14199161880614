(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RoleController', RoleController);

    RoleController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', 'Role', 'GlobalValues', 'entity', 'workflowSharedService', 'ScreenLog','entityConstants'];

    function RoleController($scope, $rootScope, $state, $uibModalInstance, Role, GlobalValues, entity, workflowSharedService, ScreenLog,entityConstants) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORGANISATION_ROLE;
        vm.screenLog = ScreenLog;
        vm.clear=clear;
        vm.save=save;
        $scope.showButton = false;
        vm.resubmitRole =resubmitRole;
        vm.clicked=false;
        $scope.showButton = false;
        
        
        if(workflowSharedService.viewFrom == 'role'){
        	vm.role=entity;
        	if(vm.role.id !== null){
                //ScreenLog.screenLog({'entityName': 'OrganisationRole', 'entityRecordId': vm.role.id, 'action': 'EDIT'});
        	}else{
                //ScreenLog.screenLog({'entityName': 'OrganisationRole', 'entityRecordId': 0, 'action': 'ADD'});
        	}
        	vm.role.organisation = null;
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'role_resubmit') {
        	vm.role = angular.fromJson($rootScope.payload); 
            var entityRecordId = 0;
            if(vm.role.id!=null){
  	      		entityRecordId = vm.role.id;
  	      	}
            vm.screenLog.screenLog(vm.screenName, entityRecordId, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);

        	$scope.showButton=true;
        }
        vm.role.organisation={};
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {	
        	if(vm.role.name!=""){
        	 if (vm.role.id !== null) {
                 Role.update({orgId: GlobalValues.getCorpId()}, vm.role, onSaveSuccess, onSaveError);
             } else {
            	 Role.save({orgId: GlobalValues.getCorpId()}, vm.role, onSaveSuccess, onSaveError);
             	
             }
        	}
         }
        
        
        	
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }

        function onSaveError (error) {
        	vm.clicked=false;
        }
        
        function resubmitRole() {
          vm.role.organisation.id= GlobalValues.getCorpId();
           	Role.resubmit({"taskId" : $rootScope.taskID}, vm.role, onResubmitSuccess, onResubmitError);
           	$uibModalInstance.dismiss('cancel');
        }
           
        function onResubmitSuccess(result) {
          
           	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked=false;
        }
     }   
})();
