(function() {
	'use strict';
	angular.module('gatewayApp').factory('ChangeMobile', ChangeMobile);

	ChangeMobile.$inject = [ '$resource' ];

	function ChangeMobile($resource) {
		var resourceUrl = 'api/change-mobile/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'SendOTPSMS' : {
				method : 'POST',
				url : 'api/user-profile/send-otp-mobile'
			},
			'SendOTPSMSForCustomer' : {
				method : 'POST',
				url : 'api/user-profile/send-otp-for-customer'
			},
			'resendOTP' : {
				method : 'POST',
				url : 'api/resend-otp-mobile',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'update' : {
				method : 'PUT',
				url : 'api/user-profile/update-new-mobile'
			},
			'updateForCustomer' : {
				method : 'PUT',
				url : 'api/user-profile/update-new-mobile-for-customer'
			},
			'activatePendingEmailChangeRequest' : {
				method : 'POST',
				params : {
					userId : '@userId'
				},
				url : 'api/user-profile/activate-email-change/userId/:userId'
			},
			'checkPassword' : {
				method : 'POST',
				/*params : {
					userId : '@userId',
					password : '@password'
				},*/
				url : 'api/user-profile/check-password'
			},
			'checkPasswordForCustomer' : {
				method : 'POST',
				/*params : {
					userId : '@userId',
					password : '@password',
					customerId : '@customerId'
				},*/
				url : 'api/user-profile/check-password-customer'
			}

		});
	}
})();
