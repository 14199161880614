(function() {
	'use strict';
	angular
		.module('gatewayApp')
		.factory('CustomerRegistration', CustomerRegistration);

	CustomerRegistration.$inject = [ '$resource' ];

	function CustomerRegistration($resource) {
		var resourceUrl = 'api/public/customer-registration/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method : 'GET',
				url: 'api/customers',
				isArray : true
			},
			'get': {
                method: 'POST',
                url:'api/public/customer-onboarding-view',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
			'getCustomer': {
                method: 'GET',
                url:'api/customer/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
			'sendOTP' : {
				method : 'POST',
				url : 'api/send-otp-mobile',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'sendOTPEmail' : {
				method : 'POST',
				url : 'api/send-otp-email',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'resendOTP' : {
				method : 'POST',
				params : {mobile : '@mobile'},
				url : 'api/resend-otp/mobile/:mobile',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'verifyOTP' : {
				method : 'POST',
				url : 'api/verify-otp-mobile',
			},
			'verifyOTPEmail' : {
				method : 'POST',
				url : 'api/verify-otp-email',
			},
            'getCustomersForBank': {
                method: 'GET',
                url: 'api/customer-for-bank/:bankId/customer',
                params: {bankId: '@bankId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomersForBankNonProp': {
                method: 'GET',
                url: 'api/customer-for-bank-non-prop/:bankId/customer',
                params: {bankId: '@bankId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomersForBranch': {
                method: 'GET',
                url: 'api/customer-for-branch/bank/:bankId/branch/:branchId/customerType/:customerType/customer',
                params: {bankId: '@bankId', branchId: '@branchId',customerType : '@customerType'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllNonPropCustomersForBank': {
                method: 'GET',
                url: 'api/all-non-prop-customers-for-bank/:bankId/bank/:customerUserTypeId/customerUserType',
                params: {bankId: '@bankId', customerUserTypeId : '@customerUserTypeId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSingleAndMultiCustomersForBranch': {
                method: 'GET',
                url: 'api/single-and-multi-customer-for-branch/bank/:bankId/branch/:branchId/customer',
                params: {bankId: '@bankId', branchId: '@branchId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomersForBranchForLimits': {
                method: 'GET',
                url: 'api/customer-for-branch-with-valid-status/bank/:bankId/branch/:branchId/customer/:customerId',
                params: {bankId: '@bankId', branchId: '@branchId',customerId:'@customerId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
           
			'submit' : {
				method : 'POST',
				url : 'api/public/customer-onboarding',
				headers: {
                    'Content-Type': undefined
                }
			},
			'reSubmit' : {
				method : 'POST',
				url : 'api/public/resubmit-customer-onboarding',
				headers: {
                    'Content-Type': undefined
                }
			},
			'discard' : {
				method : 'GET',
				params : {
					requestId : '@requestId'
				},
				url : 'api/public/customer-onboarding-withdraw/:requestId'
			},
			'validatePan':{
				method : 'POST',
				params:{
					panNumber :'@panNumber'
				},
				url : 'api/public/customer-onboarding/verifyPan/:panNumber'
			},
			'getPANImage':{
				method : 'GET',
                isArray: false,
                params: {customerCode: '@customerCode'},
				url : 'api/customer-pan-image/customer/:customerCode'
			},
            'getAllStatusSingleAndMultiCustomersForBranch': {
                method: 'GET',
                url: 'api/all-single-and-multi-customer-for-branch/bank/:bankId/branch/:branchId/customer',
                params: {bankId: '@bankId', branchId: '@branchId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
			'getAllCustomersForBranch':{
                method: 'GET',
                url: 'api/all-customers-for-branch/:branchId/branch',
                params: {bankId: '@bankId', branchId: '@branchId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'customerByEntityOwnerTypeShortName': {
                method: 'GET',
                url: 'api/customer-for-branch-by-entity-owner-type-shortname/bank/:bankId/branch/:branchId/customerType/:customerType/customer',
                params: {bankId: '@bankId', branchId: '@branchId',customerType : '@customerType'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getBankDetails': {
            	method: 'GET',
            	url: 'api/public/bank',
            	isArray: true
            },
            'getTradingBranchDetails': {
            	method: 'GET',
            	url: 'api/public/trading-branch/{bankId}/bank',
            	isArray: true
            },
            'validateRejectedRequestAndSendOTP': {
                method: 'GET',
                url:'api/public/validate-rejected-request-and-send-otp',
                params: {requestReferenceNo: '@requestReferenceNo'}
            },
            'sendOTPForRequestRefNo': {
                method: 'GET',
                url:'api/public/send-otp-for-request-ref-no',
                params: {requestReferenceNo: '@requestReferenceNo'}
            },
			'getCustomerRegistrationRequestToResubmit': {
                method: 'GET',
                url:'api/public/rejected-request',
                params: {requestReferenceNo: '@requestReferenceNo'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'verifyOTPAndGetCustomerRegistrationRequest': {
                method: 'GET',
                url:'api/public/verify-otp-for-request-ref-no',
                params: {requestReferenceNo: '@requestReferenceNo', otp: '@otp'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'sendOTPToMobileAndEmail' : {
                method : 'POST',
                url : 'api/public/send-otp-mobile-email',
                transformRequest : function(data) {
                    var copy = angular.copy(data);

                    return angular.toJson(copy);
                }
            },
            'nonPropCustomersForBranch': {
                method: 'GET',
                url: 'api/non-prop-customers/bank/:bankId/branch/:branchId/customer',
                params: {bankId: '@bankId', branchId: '@branchId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomerRegistrationPrintForNewCustomer' :{
            	method: 'POST',
                url: 'api/public/generate-new-customer-registration-pdf',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };
                }  
            },
            'getCustomerRegistrationPrintForBankApproval' :{
            	method: 'POST',
                url: 'api/generate-customer-registration-pdf-view-bank-approval',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };
                }  
            },
            'validateCustomerOnboarding':{
            	method : 'POST',
				url : 'api/public/validate-customer-onboarding',
				headers: {
                    'Content-Type': undefined
                }
            },
            'getHelpContent':{
            	method : 'GET',
                isArray: false,
                params: {type: '@type'},
				url : 'api/public/help-contents/:type'
            },
            'getSingleAndMultiCustomersForBranchNonProp': {
                method: 'GET',
                url: 'api/all-single-and-multi-customer-for-branch-non-prop/bank/:bankId/branch/:branchId/customer',
                params: {bankId: '@bankId', branchId: '@branchId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllNonPropAllCustomer':{
                method: 'GET',
                url: 'api/all-customers-non-prop',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllNonPropCustomersForLookup':{
                method: 'GET',
                params: {customerUserTypeId:'@customerUserTypeId'},
                url: 'api/all-non-prop-customers-lookup/:customerUserTypeId/customerUserType',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
			'editTradingBranch':{
        		method: 'PUT',
  				params: {orgId:'@orgId', oldBranchId:'@oldBranchId'},
  				url: 'api/trading-branch-edit',
  				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}      					
           },
           'getMarkupDetails':{
            method: 'POST',
            url: 'api/customer-onboarding/fillMarkup',
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        }


		});
	}
})();
