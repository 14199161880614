(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('UploadDownloadParticipantCertificates', UploadDownloadParticipantCertificates);

    UploadDownloadParticipantCertificates.$inject = ['$resource'];

    function UploadDownloadParticipantCertificates ($resource) {
        var resourceUrl =  'api/upload-download-participant-certificate/:id';
        
        return $resource(resourceUrl, {}, {
        	
              
              'uploadCertificate':{
                	 
               
    				method: 'POST',
    				url: 'api/upload-participant-certificate',
    				headers: {
                        'Content-Type': undefined
                    }
                },
                'approve': {
                    method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/approve'
                },
                'reject': {
                    method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/reject'
                },
                'discard': {
                  	 method: 'GET',
                       params: {taskId:'@taskId'},
                       url: 'api/tasks/:taskId/discard'
                 },
                 'downloadCertificate':{
                	 
                     
     				method: 'GET',
     				url: 'api/download-participant-certificate',
     				 responseType: 'arraybuffer',
     				  transformResponse: function(data, headers) {
     	              	  return {
     	                        data: data,
     	                        headers: headers
     	                    };   
     	                } 
                 }
          
              
        });
    }
})();
