(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UsersDelinkController',UsersDelinkController);

    UsersDelinkController.$inject = ['$uibModalInstance', 'entity', 'User', 'GlobalValues', 'ScreenLog','$scope'];

    function UsersDelinkController($uibModalInstance, entity, User, GlobalValues, ScreenLog,$scope) {
        var vm = this;

        vm.users = entity;
        console.log(vm.users);
        //ScreenLog.screenLog({'entityName': 'OrganisationUsers', 'entityRecordId': vm.users.id, 'action': 'DELETE'});
        vm.clear = clear;
        vm.confirmDelink = confirmDelink;
        $scope.clicked=false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelink (id) {
            User.delink({"id": id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
