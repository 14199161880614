(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('SwapQuotesForTenorDatesDialogController', SwapQuotesForTenorDatesDialogController);

	SwapQuotesForTenorDatesDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','SwapQuotes','entity', 'DateUtils', 'ScreenLog','entityConstants', 'AlertService', '$filter', 'Workflow','SwapQuotesForTenorDates','systemSettings'];

	function SwapQuotesForTenorDatesDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,SwapQuotes,entity,DateUtils,ScreenLog,entityConstants,AlertService,$filter, Workflow,SwapQuotesForTenorDates,systemSettings) {
		var vm = this;
		vm.swapQuotesForTenorDatesList=entity;  
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.SWAP_QUOTES;
		vm.stringBusinessDate = $state.params.businessDate;
		vm.businessDate = DateUtils.convertLocalDateFromServer(vm.stringBusinessDate);
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		vm.clear=clear;
		vm.save=save;
		vm.clicked=false;
		$scope.showButton = false;
		vm.globalValues = GlobalValues;
		vm.currentDate = systemSettings.businessDate;
		vm.bank = vm.globalValues.getBank();
		vm.instrumentList = [];
		vm.bidErrorList = [];
		vm.offerErrorList = [];
		vm.swapQuotesForTenorDatesDTO ={};
		vm.checkWhetherBidValueIsModified = checkWhetherBidValueIsModified;
		vm.checkWhetherOfferValueIsModified = checkWhetherOfferValueIsModified;
		vm.isModified = isModified;
		
		
		$scope.showButton = false;
		$scope.showResubmitButton = false;
		$scope.showApproveRejectButton = false;
		$scope.showDiscardButton = false;
		vm.approveSwapQuotes = approveSwapQuotes;
		vm.rejectSwapQuotes = rejectSwapQuotes;
		vm.discardSwapQuotes = discardSwapQuotes;
		vm.resubmitSwapQuotes =resubmitSwapQuotes;
		vm.onSpreadValueChange = onSpreadValueChange;

		vm.removeErrorFromList = removeErrorFromList;
		vm.pushErrorToList = pushErrorToList;
		vm.isNotNewEdit = true;
		vm.checkForModification = false;
		//vm.spreadValueError = spreadValueError;
		//vm.tenorNameBidValueMap = {};
		//vm.tenorNameOfferValueMap = {};
		
		if(workflowSharedService.viewFrom == 'swapQuotes'){
			vm.swapQuotes=entity;
			SwapQuotesForTenorDates.getSettlementDateForCashTomFrwd(onInstrumentSuccess, onInstrumentFailure);
			vm.previousQuotes = angular.copy(vm.swapQuotes);
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			vm.disableSpread=false;
			$scope.showResubmitButton = false;
			vm.isNotNewEdit = false;
			vm.checkForModification = true;
		}else if(workflowSharedService.viewFrom == 'swapQuotes_checker') {
			vm.swapQuotesForTenorDatesDTO =  angular.fromJson($rootScope.payload);
			vm.businessDate = DateUtils.convertLocalDateFromServer(vm.swapQuotesForTenorDatesDTO.businessDate);
			vm.swapQuotesForTenorDatesList = angular.copy(vm.swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList);
			SwapQuotesForTenorDates.getSettlementDateForCashTomFrwd(onInstrumentSuccess, onInstrumentFailure);
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton=true;
			$scope.showDiscardButton = false;
			vm.disableSpread=true;
			vm.checkForModification = false;
		}
		else if(workflowSharedService.viewFrom == 'swapQuotes_maker') {
			vm.swapQuotesForTenorDatesDTO =  angular.fromJson($rootScope.payload);
			vm.businessDate = DateUtils.convertLocalDateFromServer(vm.swapQuotesForTenorDatesDTO.businessDate);
			vm.swapQuotesForTenorDatesList = angular.copy(vm.swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList);
			SwapQuotesForTenorDates.getSettlementDateForCashTomFrwd(onInstrumentSuccess, onInstrumentFailure);
			$scope.showButton = false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton=false;
			$scope.showDiscardButton = true;
			vm.disableSpread=true;
			vm.checkForModification = false;
		}
		else if(workflowSharedService.viewFrom == 'swapQuotes_resubmit') {
			vm.swapQuotesForTenorDatesDTO =  angular.fromJson($rootScope.payload);
			vm.stringBusinessDate = vm.swapQuotesForTenorDatesDTO.businessDate;
			vm.businessDate = DateUtils.convertLocalDateFromServer(vm.swapQuotesForTenorDatesDTO.businessDate);
			vm.swapQuotesForTenorDatesList = vm.swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList;
			SwapQuotesForTenorDates.getSettlementDateForCashTomFrwd(onInstrumentSuccess, onInstrumentFailure);
			vm.screenLog.screenLog(vm.screenName, vm.swapQuotesForTenorDatesDTO.id==null?0:vm.swapQuotesForTenorDatesDTO.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			vm.disableForm = false;
			$scope.showButton=false;
			$scope.showResubmitButton = true;
			$scope.showApproveRejectButton=false;
			$scope.showDiscardButton = false;
			vm.disableSpread=false;
			vm.checkForModification = true;
			vm.isNotNewEdit = false;
		}
		else if(workflowSharedService.viewFrom == 'swapQuotes_auditLog') {
			vm.swapQuotesForTenorDatesDTO =  angular.fromJson($rootScope.payload);
			vm.swapQuotesForTenorDatesList = vm.swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList;
			vm.bank = vm.swapQuotesForTenorDatesDTO.bank;
			vm.stringBusinessDate = vm.swapQuotesForTenorDatesDTO.businessDate;
			vm.businessDate = DateUtils.convertLocalDateFromServer(vm.swapQuotesForTenorDatesDTO.businessDate);
			SwapQuotesForTenorDates.getSettlementDateForCashTomFrwd(onInstrumentSuccess, onInstrumentFailure);
			vm.disableForm = true;
			$scope.showButton=false;
			$scope.showResubmitButton = false;
			$scope.showApproveRejectButton=false;
			$scope.showDiscardButton = false;
			vm.disableSpread=true;
			vm.isNotNewEdit = false;
		}
		//vm.businessDate = DateUtils.convertLocalDateFromServer(vm.swapQuotes.businessDate)
		if(( workflowSharedService.viewFrom == 'swapQuotes_resubmit' || workflowSharedService.viewFrom == 'swapQuotes') && vm.stringBusinessDate != DateUtils.convertLocalDateToServer(vm.currentDate) ){
			vm.clicked=true;

			AlertService.error($filter('translate')('gatewayApp.swapQuotes.pastDateEditError'));
			vm.disableSpread=true;
			

		}
		
		angular.forEach(vm.swapQuotesForTenorDatesList , function(item){
			item.bidError = false;
			item.offerError = false;
			/*if( workflowSharedService.viewFrom == 'swapQuotes'){
				//vm.tenorNameBidValueMap[item.tenorName] = item.bidSpread;
				//vm.tenorNameOfferValueMap[item.tenorName] = item.offerSpread; 
				item.initialBidSpread = item.bidSpread;
				item.initialOfferSpread = item.offerSpread;
			}*/
		});

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function save () {        	    		

			if(vm.stringBusinessDate != DateUtils.convertLocalDateToServer(vm.currentDate) ){
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.pastDateEditError'));
				vm.clicked=false;
			}
			vm.swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList = vm.swapQuotesForTenorDatesList;
			vm.swapQuotesForTenorDatesDTO.bank = vm.bank;
			vm.swapQuotesForTenorDatesDTO.businessDate = DateUtils.convertLocalDateToServer(vm.businessDate);
				
			SwapQuotesForTenorDates.update({ "orgId": GlobalValues.getOrgId()},vm.swapQuotesForTenorDatesDTO, onSaveSuccess, onSaveError);

		}

		function onSaveSuccess (result) {
			//$uibModalInstance.dismiss('cancel');
			angular.element("#BidSpread_0").focus();
		}

		function onSaveError () {

			vm.clicked = false;
			angular.element("#BidSpread_0").focus();
		}
		function resubmitSwapQuotes() {
			vm.swapQuotesForTenorDatesDTO.businessDate = DateUtils.convertLocalDateToServer(vm.businessDate);
			
			SwapQuotes.resubmit({"taskId" : $rootScope.taskID}, vm.swapQuotesForTenorDatesDTO, onResubmitSuccess, onResubmitError);
			$uibModalInstance.dismiss('cancel');
		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
		}        
		function onResubmitError(error) {
			vm.clicked = false;
			angular.element("#BidSpread_0").focus();
		}

		function rejectSwapQuotes() { Workflow.rejectTask($rootScope.taskID); }

		function discardSwapQuotes() {
			SwapQuotesForTenorDates.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
			$uibModalInstance.dismiss('cancel');	
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onDiscardError(error) {

		}

		function approveSwapQuotes() {  
			SwapQuotesForTenorDates.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
			$uibModalInstance.dismiss('cancel');     	
		}

		function onApproveSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onApproveError(error) {

		}


		function onInstrumentSuccess(result){
			vm.instrumentList = result;
			angular.forEach(  vm.instrumentList, function(item){

				if(item.instrumentCode =='CASH'){
					vm.cashTickSize = item.tickSize * 100; // convert to paise
					/*if(item.isSettlementDateHoliday==true){
       		 			vm.disableCashSpread= true;   		 			
       		 		}else{
       		 			vm.disableCashSpread= false;   		 		
       		 		}  */  
				}

				if (item.instrumentCode =='TOM' ){
					vm.tomTickSize = item.tickSize * 100;
					/*if(item.isSettlementDateHoliday==true){
       		 			vm.disableTOMSpread= true;       		 			
       		 		}else{
       		 			vm.disableTOMSpread= false;       		 	   
       		 		} */      		 		
				}
				if (item.instrumentCode =='Forward' ){
					vm.forwardTickSize = item.tickSize * 100;
					/*if(item.isSettlementDateHoliday==true){
    		 			vm.disableTOMSpread= true;       		 			
    		 		}else{
    		 			vm.disableTOMSpread= false;       		 	   
    		 		} */      		 		
				}

			});
		}
		function onInstrumentFailure(error){

		}

		function isValidSpreadValue(rowCount,spreadValue,tickSize,bidOrOffer){
			if(spreadValue !=null && spreadValue!=undefined && spreadValue!='' && tickSize!=undefined && tickSize!=null){

				// convert spread to float
				var intSpreadValue = Math.round(parseFloat(spreadValue) * 100);

				var tickSizeInPaise = Math.round(parseFloat(tickSize)*100);// convert tick size to fixed decimal
				

				var remainder = intSpreadValue % tickSizeInPaise;
				
				// fix remainder to 2 decimal
				if(remainder>0 || remainder<0){
					//set the error to the list based on the index
					if(bidOrOffer=='Bid'){
						vm.swapQuotesForTenorDatesList[rowCount].bidError = true;
					}else{
						vm.swapQuotesForTenorDatesList[rowCount].offerError = true;
					}
					pushErrorToList(bidOrOffer,rowCount);
				}else{
					//remove the error if present
					if(bidOrOffer=='Bid'){
						vm.swapQuotesForTenorDatesList[rowCount].bidError = false;
					}
					else{
						vm.swapQuotesForTenorDatesList[rowCount].offerError = false;
					}
					
					removeErrorFromList(bidOrOffer,rowCount);

				}

			}
			else if(( spreadValue==null || spreadValue==undefined || spreadValue=='' ) && tickSize!=undefined && tickSize!=null){
				if(bidOrOffer=='Bid'){
					vm.swapQuotesForTenorDatesList[rowCount].bidError = false;
				}
				else{
					vm.swapQuotesForTenorDatesList[rowCount].offerError = false;
				}
				
				removeErrorFromList(bidOrOffer,rowCount);
			}
			
			return true;
		}
		
		function removeErrorFromList(bidOrOffer,rowCount){
			if(bidOrOffer=='Bid'){
				angular.forEach(vm.bidErrorList, function(value){
					if(value==rowCount){
						if(vm.bidErrorList.length == 1){
							vm.bidErrorList = [];
						}
						else{
							vm.bidErrorList.splice(value,1);
						}
						
					}
				});
				
			}else{
				angular.forEach(vm.offerErrorList, function(value){
					if(value==rowCount){
						if(vm.offerErrorList.length == 1){
							vm.offerErrorList = [];
						}else{
							vm.offerErrorList.splice(value,1);
						}
						
					}
				});
			}
		}

		function pushErrorToList(bidOrOffer,rowCount){
			var errorPresent = 0;
			if(bidOrOffer=='Bid'){				
				angular.forEach(vm.bidErrorList, function(value){
					if(value==rowCount){
						errorPresent = 1;
						return true;
					}
				});
				if(errorPresent !=1){
					vm.bidErrorList.push(rowCount);
				}				
			}
			else{
				angular.forEach(vm.offerErrorList, function(value){
					if(value==rowCount){
						errorPresent = 1;
						return true;
					}
				});
				if(errorPresent != 1){
					vm.offerErrorList.push(rowCount);
				}
				
			}
			
		}
		
		/*function findErrorPresent(){
			angular.forEach(vm.errorList, function(item){
				if(item == "Bid" || item == "Offer"){
					return true;
				}
			});
						
				return false;
			
		}*/
		
		function onSpreadValueChange(rowCount, spreadValue,bidOrOffer,swapQuotes){
			if(swapQuotes.tenorName == "CASH"){
				isValidSpreadValue(rowCount,spreadValue,vm.cashTickSize,bidOrOffer)
			}
			else if(swapQuotes.tenorName == "TOM"){
				isValidSpreadValue(rowCount,spreadValue,vm.tomTickSize,bidOrOffer)

			}
			else{
				isValidSpreadValue(rowCount,spreadValue,vm.forwardTickSize,bidOrOffer)
			}
			
		}
		function checkWhetherBidValueIsModified(index, swapQuotes){
			//console.log("bid" + index);
			if(vm.checkForModification){
				if(workflowSharedService.viewFrom == 'swapQuotes_auditLog'){
					swapQuotes.isBidSpreadModified = false;
				}else{
				swapQuotes.isBidSpreadModified = checkForModification(swapQuotes.initialBidSpread,swapQuotes.bidSpread );
				}
			}
			//console.log("bid mod" + swapQuotes.isBidSpreadModified);
		}
		function checkWhetherOfferValueIsModified(index, swapQuotes){
			//console.log("offer" + index);
			if(vm.checkForModification){
				if(workflowSharedService.viewFrom == 'swapQuotes_auditLog'){
					swapQuotes.isOfferSpreadModified = false;
				}else{			
					swapQuotes.isOfferSpreadModified = checkForModification(swapQuotes.initialOfferSpread,swapQuotes.offerSpread);
				}
			}
			//console.log("offer mod" + swapQuotes.isOfferSpreadModified);
		}
		function checkForModification(intialSpread, givenSpread){
			console.log( intialSpread);
			console.log(givenSpread);
			if( ( (intialSpread===null || intialSpread === undefined ||  intialSpread ==="")
					&& ( givenSpread===null || givenSpread === undefined ||  givenSpread ==="") )	 ){
			//both null
					//console.log("first");
				return false;
			}else if( intialSpread === givenSpread){
				//console.log("third");
				return false;
			}else{
				//console.log("fourth");
					return  true;
			}
		}
		
		function isModified(isSpreadModified){
			return (vm.isNotNewEdit && isSpreadModified);
			
		}
	

	}   //
})();
