(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('SystemAmendedTrades', SystemAmendedTrades);

	SystemAmendedTrades.$inject = ['$resource'];

	function SystemAmendedTrades ($resource) {
		var resourceUrl =  'api/system-amended-trades/:orgId';

		return $resource(resourceUrl, {}, {  
			
			'getAllSystemAmendedTrades':{
				isArray:true,
  				method: 'GET',
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId',fromDate : '@fromDate', toDate : '@toDate'},				
  				url: 'api/system-amended-trades'
			}

		});
	}
})();

