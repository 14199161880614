 (function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customer-specific-settings', {
            parent: 'entity',
            params:{screen: 'CustomerSpecificSettings'},
            url: '/customer-specific-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.customerSettings.settings" | translate }}',
                parent: 'home'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-specific-settings/customer-specific-settings.html',
                    controller: 'CustomerSpecificSettingsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'smd',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('home', null, {reload:true});
                }, function() {
                    $state.go('home', null, {reload:true});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('customerSpecificSettings');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
         };
    })();
