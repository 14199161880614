(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('exposure-sub-type', {
            parent: 'entity',
            params:{screen:'ExposureSubType'},
            url: '/exposure-sub-type',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.exposureSubType.title" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type.html',
                    controller: 'ExposureSubTypeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                validateSODAndSession: ['ExposureType', function(ExposureType, GlobalValues) {
                    return ExposureType.validateSODAndSession().$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('exposureSubType');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
       
        .state('exposure-sub-type-new', {
            parent: 'exposure-sub-type',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	                           	
                             
                            };
                        },
                        mode: function(){
                        	return ""
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('exposure-sub-type-edit', {
            parent: 'exposure-sub-type',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	 entity: ['ExposureSubType', 'GlobalValues', function(ExposureSubType, GlobalValues) {
                             return ExposureSubType.get({id : $stateParams.id}).$promise;
                         }],
                         mode: function(){
                         	return ""
                         }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('exposure-sub-type-view', {
            parent: 'exposure-sub-type',
            url: '/view/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	 entity: ['ExposureSubType', 'GlobalValues', function(ExposureSubType, GlobalValues) {
                             return ExposureSubType.get({id : $stateParams.id}).$promise;
                         }],
                         mode: function(){
                         	return "VIEW"
                         }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('exposure-sub-type-todolist', {
            parent: 'dashboard',
            url: '/exposureSubType',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        mode: function(){
                        	return ""
                        }
                    }
                }).result.then(function() {
                	$state.go('exposure-sub-type', null, { reload: 'exposure-sub-type' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('exposure-sub-type-todolist-resubmit', {
            parent: 'dashboard',
            url: '/exposureSubTypeEdit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
	                      entity: function () {                       	
	                          
	                      },
	                      mode: function(){
	                      	return ""
	                      }
                    }
                }).result.then(function() {
                	$state.go('exposure-sub-type', null, { reload: 'exposure-sub-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
          .state('exposure-sub-type-auditLog', {
            parent: 'audit-log',
            url: '/exposureSubTypeAuditLog',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        mode: function(){
                        	return ""
                        }
                    }
                }).result.then(function() {
                	$state.go('exposure-sub-type', null, { reload: 'exposure-sub-type' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('exposure-sub-type-historyReport', {
            parent: 'history-report',
            url: '/exposureSubTypeHistory',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/exposure-sub-type/exposure-sub-type-dialog.html',
                    controller: 'ExposureSubTypeEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        mode: function(){
                        	return ""
                        }
                    }
                }).result.then(function() {
                	$state.go('exposure-sub-type', null, { reload: 'exposure-sub-type' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         
         };
    })();
