(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .constant('entityConstants', (function() {           
            return {
            	ENTITY_SHORT_NAME:{
            		BANK_REGISTRATION : 'BankRegistration',
            		USER_MANAGEMENT : 'UserManagement',
            		ORGANISATION_ROLE : 'OrganisationRole',
            		BRANCH_REGISTRATION: 'BranchRegistration',
            		CUSTOMER_VIEW_REPORT:'CustomerViewReport',
            		TRADING_BANK_LIST : 'TradingBankList',
            		SECURITY_POLICY : 'SecurityPolicy',
            		APPROVAL_PROCESS : 'ApprovalProcess',
            		ENTITY_STATE_APPROVAL_PROCESS : 'EntityStateApprovalProcess',
            		TODO:'ToDo',
            		CUSTOMER_APPROVAL: 'CustomerApproval',
            		SESSION_LOG_REPORT : 'SessionLogReport',
            		AUDIT_LOG_REPORT : 'AuditLogReport',
            		CUSTOMER_ONBOARDING_EXCEPTION_REPORT : 'CustomerOnboardingExceptionReport',
            		NO_OF_AVAILABLE_USERS : 'NumberOfAvailableUsers',
            		NO_OF_LOGGED_IN_USERS : 'NumberOfLoggedinUsers',
            		EMAIL_SETTINGS : 'EmailSettings',
            		USER_ROLE : 'UserRole',
            		SMS_FAILURE_REPORT : 'SMSFailureReport',
            		SMS_SETTINGS : 'SMSSettings',
            		HISTORY_REPORT : 'HistoryReport',
            		GLOBAL_SECURITY_POLICY :'GlobalSecurityPolicy',
            		GLOBAL_APPROVAL_PROCESS : 'GlobalApprovalProcess',
            		MANAGE_GLOBAL_ENTITY_STATE_APPROVAL_PROCESS : 'ManageGlobalEntityStateApprovalProcess',
            		CUSTOMER_REGISTRATION : 'CustomerRegistration',
            		CUSTOMER : 'Customer',
            		USER_ADMIN_APPLICATION_WIDE_SETTINGS : 'UserAdminApplicationWideSettings',
            		DELAING_ADMIN_APPLICATION_WIDE_SEETINGS : 'DealingAdminApplicationWideSettings',
            		EOD_PROCESS : 'EODProcess',
            		USER_PROFILE : 'UserProfile',
            		DEFAULT_MARKUP_SETTINGS : 'DefaultMarkupSettings',
            		MARKUP_SETTINGS : 'MarkupSettings',
            		SPECIAL_HOLIDAY : 'SpecialHoliday',
            		REGULAR_HOLIDAY : 'RegularHolidays',
            		SWAP_QUOTES: 'SwapQuotes',
            		HE_CONNECTIVITY_SETTINGS: 'HEConnectivitySettings',
            		INSTRUMENT_VIEW : 'InstrumentView',
            		INTRADAY_MARKET_SESSION_SETTINGS: 'IntradayMarketSessionSettings',
            		DEFAULT_MARKET_SESSION_SETTINGS:'DefaultMarketSessionSettings',
            		USER_RESTRICTION : 'UserRestriction',
            		LIMITSETTINGS : 'LimitSettings',
            		CUSTOMERLIMITSETTINGS :'CustomerLimitChangeRequest',
            		MARKUPREPORT : 'MarkupReport',
            		ORDERSTATUSREPORT : 'OrderStatusReport',
            		ORDERACTIVITYREPORT: 'OrderActivityReport',
            		MARKETMOVEMENT: 'MarketMovement',
            		USERWISETURNOVERREPORT: 'UserwiseTurnoverReport',
            		TRADESTOBESETTLEDREPORT: 'TradesToBeSettledReport',
            		SOD_PROCESS: 'SODProcess',
            		ORDER_HISTORY : 'OrderHistory',
            		TRADE_HISTORY : 'TradeHistory',
            		NO_OF_AVAILABLE_USER_REPORT : 'NoOfAvailableUsers',
            		NO_OF_LOGGEDIN_USER_REPORT : 'NoOfLoggedInUsers',
            		AMC_SETTINGS : 'AMCSettings',
            		CUSTOMER_CHARGES : 'CustomerCharges',
            		GST_SETTINGS : 'GSTSettings',
            		INVOICE_VIEW:'InvoiceView',
            		TRANSACTION_CHARGES:'TransactionChargesReport', 
            		TRADEORDERMANAGEMENTDASHBOARD: 'TradeOrderManagementDashboard',
            		TRADES_DOWNLOAD: 'TradesDownload',
            		HE_STATUS_UPDATE:'HEStatusUpdate',
            		ORDER_SERVER_MANUAL_LOGIN : 'OrderServerManualLogin',
            		SERVER_COMPONENT_STATUS :'ServerComponentStatus',
            		UPLOAD_DOWNLOAD_PARTICIPANT_CERTIFICATE : 'UploadDownloadParticipantCertificate',
            		HOLIDAY_REPORT:'HolidayReport',
            		HOME_BRANCH_IFSC_UPLOAD:'HomeBranchIFSCUpload',
            		MODIFY_CUSTOMER_DETAILS:'ModifyCustomerDetails',
                    PENDING_ORDER_REPORT : 'PendingOrderReport',
                    TRADES_FOR_THE_DAY : 'TradesForTheDay',
                    SWAP_QUOTES_VIEW : 'SwapQuotesView',
                    CUSTOMER_MARKUP_VIEW : 'CustomerMarkupView',
                    LIMIT_UTILISATION_REPORT : 'LimitUtilisationReport',
                    STANDARD_TENOR_VIEW:'StandardTenorView',
                    SYSTEM_AMENDED_TRADES:'SystemAmendedTradesReport',
                    TERMS_AND_CONDITIONS_VIEW: 'TermsAndConditionsView',
                    CLEARCORP_TAC_UPLOAD:'TermsAndConditionsUpload',
                    CUSTOMER_TERMS_AND_CONDITIONS_UPLOAD:'CustomerTermsAndConditionsUpload',
                    OTR_SUMMARY_VIEW:'OTRSummary',
                    TRANSACTION_CHARGES_SUMMARY_VIEW:'TransactionChargesSummary',
                    AMC_SUMMARY_VIEW:"AMCSummary",
                    NO_OF_LOGGEDIN_USER_REPORT_FOR_BANK:"NoOfLoggedInUsersForBank",
                    USERACCESSVIEW:"UserAccessView",
                    EXPOSURE_TYPE:"ExposureType",
                    EXPOSURE_SUB_TYPE:"ExposureSubType",
                    CREDIT_FACTOR_SETTINGS:"CreditFactorSettings",
					LIMIT_UTILISATION_SPLITUP:"LimitUtilisationSplitupReport",
					OUTSTANDING_TRADES_REPORT:"OutstandingTradesReport",
					PENDING_LIMIT_REQUESTS_VIEW:"PendingLimitRequestsView",
					CREDIT_FACTOR_SETTINGS_VIEW: "CreditFactorSettingsView",
					BANK_FEES_CHARGES_UPLOAD: "BankFeesChargesUpload",
					BANK_FEES_CHARGES_VIEW: "BankFeesAndChargesView",
					CLEARCORP_FEES_AND_CHARGES_VIEW:"ClearcorpFeesAndChargesView",
					CANCELLEDORDERSREPORT:"CancelledOrdersReport",
					EINVOICE_UPLOAD:"eInvoiceUpload",
					OTR_SUMMARY_FOR_BANK:"OTRSummaryForBank",
					ORDER_ACTIVITY_LOG:"OrderActivityLog",
					ORDER_UPLOAD :"OrderUpload",
					ORDER_CONFIRMATION:"OrderConfirmation"
            	},
            	ENTITY_ACTION_TYPE:{
            		ACCESS : 'ACCESS',
            		VIEW_DETAILS : 'VIEW DETAILS',
            		APPROVE : 'APPROVE',
            		DISCARD : 'DISCARD',
            		REJECT : 'REJECT',
            		VIEW : 'VIEW',
            		EDIT :'EDIT',
                    RESET : 'RESET',
            		MAP :'MAP',
            		DELINK :'DELINK',
            		CREATE :'CREATE',
            		ADD :'ADD',
            		DELETE :'DELETE',
            		RESUBMIT:'RESUBMIT',
            		CLEARDATA:'CLEARDATA',
            		RESEND :'RESEND',
            		REMOVE:'REMOVE',
            		WITHDRAW:'WITHDRAW',
            		IMPORT:'IMPORT',
            		LOGIN : 'LOGIN',
            		UPLOAD : 'UPLOAD',
            		DOWNLOAD : 'DOWNLOAD',
            		AGREE : 'AGREE',
            		VIEW_AGREED_BANKS:'VIEW AGREED BANKS',
            		EXPORT_AGREED_BANKS:'EXPORT AGREED BANKS',
            		VIEW_BANKS_TAC_FOR_CUSTOMERS:'VIEW BANK\'S TERMS AND CONDITIONS FOR CUSTOMERS',
            		VIEW_HISTORY:'VIEW HISTORY'

            	}
            }
        })());
})();
