(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'amcSummaryView'
	})

	.controller('AMCSummaryViewController', AMCSummaryViewController);

	AMCSummaryViewController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
		'DateUtils','AlertService','$filter',   'AMCSummaryView','financialYears','configSetting'];

	function AMCSummaryViewController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
			DateUtils,AlertService,$filter,  AMCSummaryView,financialYears,configSetting) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.AMC_SUMMARY_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.financialYears = financialYears;
		vm.loadFinancialYearDisplay = loadFinancialYearDisplay;
		loadFinancialYearDisplay();
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.downloadEInvoice = downloadEInvoice;
		vm.configSettings = configSetting;
		vm.freshOrPrintOption = 'PrintGeneratedInvoice';
		vm.getAmcSummary = getAmcSummary;
		vm.exportDataToFile = exportDataToFile;
		vm.setDefaultValue = setDefaultValue;
		vm.clearAllValues = clearAllValues;
		vm.filterData = {};
		vm.amcSummaryfileName = "AMC_Summary_Report_";
		vm.eInvoiceChargesfileName = "e-Invoice_";
		vm.fileName = vm.amcSummaryfileName;
		vm.extension = "";
		vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
		vm.amcSummaryDetails = null;
		vm.showDownloadEInvoice = false;
		vm.clicked=false;
		
		logScreenAccess();
		vm.valid = false;
		vm.issuePresent = false;

		function loadFinancialYearDisplay(){
			var yearCount = 0;
			if(vm.financialYears != null && vm.financialYears != undefined){
				for(yearCount = 0;yearCount < vm.financialYears.length; yearCount++){
					var fromYear = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYears[yearCount].financialYearStart))).year();
					var toYear = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYears[yearCount].financialYearEnd))).year();
					var displayYear = fromYear+"-"+ toYear;
					vm.financialYears[yearCount].displayYear = displayYear;
				}
			}
		}

		function getAmcSummary(){
			if(vm.freshOrPrintOption == 'PrintGeneratedInvoice'){
				vm.showDownloadEInvoice = true;
			}else{
				vm.showDownloadEInvoice = false;
			}
			vm.eInvoiceDisable = false;
			vm.amcSummaryDetails = [];
			
			if(moment(vm.fromDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.amcSummary.fromDateGreaterThanToDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			
			else{
				vm.valid = true;
				vm.issuePresent = false;
				vm.clicked=true;
				vm.selectedPrintorRefresh = angular.copy(vm.freshOrPrintOption);
				clearAllValues();
				AMCSummaryView.getAMCSummaryReport({ from : DateUtils.convertLocalDateToServer(vm.fromDate), to: DateUtils.convertLocalDateToServer(vm.toDate),printorGenerateAfreshOption : vm.selectedPrintorRefresh}, 
						onGetAMCSummarySuccess, onGetAMCSummaryFailure);  		
			}

		}
		function onGetAMCSummarySuccess(result){
			vm.amcSummaryDetails = result;
			vm.clicked=false;
		}
		function onGetAMCSummaryFailure(error){
			vm.clicked=false;
		}

		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		//this method is used to set default value to both the from and to date and financial year
		function setDefaultValue(value){

			if(value == 'PrintGeneratedInvoice'){
				vm.allFinancialYears = [];
				if(vm.configSettings.billingEndDate!=null && vm.configSettings.billingEndDate !=""){
					vm.allFinancialYears.push(angular.copy(vm.financialYears[0]));	//temporary
				}
				vm.allFinancialYears.push(angular.copy(vm.financialYears[1]));
				vm.financialYear =  angular.copy(vm.financialYears[1]);
	

			}else if(value == 'GenerateAfresh'){
				vm.allFinancialYears = angular.copy(vm.financialYears);	
				vm.financialYear = angular.copy(vm.allFinancialYears[0]);
				
			}
			vm.fromDate = DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYear.financialYearStart));
			vm.toDate  = DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYear.financialYearEnd));
			
		}


		vm.onFinacialYearSelection = onFinacialYearSelection;
		function onFinacialYearSelection(value){
			vm.fromDate = DateUtils.convertLocalDateFromServer(angular.copy(value.financialYearStart));
			vm.toDate  = DateUtils.convertLocalDateFromServer(angular.copy(value.financialYearEnd));

		}

		function downloadEInvoice(){
			vm.mimeType = 'application/vnd.ms-excel';
			vm.extension = ".xls"; 
			vm.fileName = vm.eInvoiceChargesfileName;
			AMCSummaryView.downloadEInvoiceExcel({ from : DateUtils.convertLocalDateToServer(vm.fromDate), to: DateUtils.convertLocalDateToServer(vm.toDate),financialYearId :vm.financialYear.id}, processResponse );


         }

		function exportDataToFile(fileType){

			vm.fileName = vm.amcSummaryfileName;
			if( vm.amcSummaryDetails !=null &&  vm.amcSummaryDetails != undefined && vm.amcSummaryDetails.headerList.length > 0){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv';
					vm.extension =  ".csv";  
					AMCSummaryView.exportAMCSummaryCsv({freshOrPrintOption:vm.selectedPrintorRefresh },vm.amcSummaryDetails, processResponse );
				}
				else if(fileType == 'xls'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.extension = ".xls";  
					AMCSummaryView.exportAMCSummaryExcel({freshOrPrintOption:vm.selectedPrintorRefresh },vm.amcSummaryDetails, processResponse );
				}
				else if(fileType == 'pdf'){
					vm.mimeType = 'application/pdf';
					vm.extension = ".pdf";  
					AMCSummaryView.exportAMCSummaryPdf({freshOrPrintOption:vm.selectedPrintorRefresh },vm.amcSummaryDetails , processResponse );
				}

			}
		}

		function processResponse(result){        	  
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			vm.baseString =   vm.fileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 

			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}


		$scope.$watch('vm.freshOrPrintOption',function(value){
			if(value!= undefined && value!=null ){
				setDefaultValue(value);
				
			}
		
		});
		
		$scope.$watch('vm.financialYear',function(value){
			if(value!= undefined && value!=null ){
				onFinacialYearSelection(value);
			}
			vm.eInvoiceDisable = true;
		
		});
		
		function clearAllValues(){
			vm.amcSummaryDetails = null;
			$scope.filterText = null;
			$scope.filterTextSummary = null;
		}
		

	}
})();