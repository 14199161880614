(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manage-role', {
            parent: 'entity',
            params:{screen: 'OrganisationRole'},
            url: '/manage-role',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.security.manageroles" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/role-definition/manage-role.html',
                    controller: 'ManageRoleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('role');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('manage-role.new', {
            parent: 'manage-role',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-dialog.html',
                    controller: 'RoleController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	 id:null,  
                            	name:null,
                            	organisation:null 
                            	
                             
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('manage-role', null, { reload: 'manage-role' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-role.edit', {
            parent: 'manage-role',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-dialog.html',
                    controller: 'RoleController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	
                            	 entity: ['Role', 'GlobalValues', function(Role, GlobalValues) {
                                     return Role.get({orgId: GlobalValues.getCorpId(),id : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-role.view', {
            parent: 'manage-role',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-view-dialog.html',
                    controller: 'RoleViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	
                            	 entity: ['Role', 'GlobalValues', function(Role, GlobalValues) {
                                     return Role.get({orgId: GlobalValues.getCorpId(),id : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-role.delete', {
            parent: 'manage-role',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-delete-dialog.html',
                    controller: 'RoleDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: ['Role', 'GlobalValues', function(Role, GlobalValues) {
                        	return Role.get({orgId:GlobalValues.getCorpId(),id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('manage-role-todolist', {
            parent: 'dashboard',
            url: '/roleview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-view-dialog.html',
                    controller: 'RoleViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-role', null, { reload: 'manage-role' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-role-todolist.resubmit', {
            parent: 'dashboard',
            url: '/roleedit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-dialog.html',
                    controller: 'RoleController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                        	
                           /* return {
                                shortName: null,                            	
                                name: null,
                                effectiveFrom: null,
                                validTill: null,
                                permittedTradingPosition: null,
                                permittedOpenExposure: null,
                                isDefault: null,
                                id: null	                                                  
                            };*/
                        }
                    }
                }).result.then(function() {
                    $state.go('manage-role', null, { reload: 'manage-role' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('manage-role-auditLog', {
            parent: 'audit-log',
            url: '/roleview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-view-dialog.html',
                    controller: 'RoleViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-role', null, { reload: 'manage-role' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-role-historyReport', {
            parent: 'history-report',
            url: '/roleview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/role-definition/role-view-dialog.html',
                    controller: 'RoleViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('manage-role', null, { reload: 'manage-role' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
   };
})();