(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'branch'
        })
        
        .controller('BranchController', BranchController);

    BranchController.$inject = ['$scope', '$state', 'workflowSharedService', 'Branch', 'GlobalValues', 'EntityPermissions','ScreenLog','$rootScope','entityConstants','$timeout'];

    function BranchController($scope, $state, workflowSharedService, Branch, GlobalValues, EntityPermissions,ScreenLog,$rootScope,entityConstants,$timeout) {
        
        var vm = this;
        
        vm.globalValues = GlobalValues;
        vm.screenLog = ScreenLog;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.BRANCH_REGISTRATION;
        $scope.refresh=refresh;
        workflowSharedService.viewFrom = "branch";
        vm.entityOwnerType = vm.globalValues.getUser().entityOwnerType.shortName;
        vm.launch=launch;
        vm.clicked=false;
        function launch() {
        	$state.go("branch.new");
        }
        
        vm.permitBranchEditEntityAction = EntityPermissions.hasPermission('BranchRegistration.EDIT')
        vm.permitBranchCreateEntityAction = EntityPermissions.hasPermission('BranchRegistration.CREATE')
		vm.permitBranchViewEntityAction = EntityPermissions.hasPermission('BranchRegistration.VIEW')
		vm.permitBranchDeleteEntityAction = EntityPermissions.hasPermission('BranchRegistration.DELETE')
		vm.permitBranchImportEntityAction = EntityPermissions.hasPermission('BranchRegistration.IMPORT')
 
		console.log(vm.permitBranchCreateEntityAction);
		if(vm.permitBranchCreateEntityAction){
			loadAll();	
		}
        
		function loadAll(){
			Branch.getAllBranchesForBank({bankId: vm.globalValues.getOrganisation().id}, function(result) {
                vm.branch = result;
                vm.clicked=false;                    
            });	
		}
		logScreenAccess();
        vm.loadAll = function loadAll(bank) {
        	vm.bankId = 0;
        		if(bank!=null && bank!=undefined){
            		vm.bankId = bank.id;
        		}
        		Branch.getAllBranchesForBank({bankId: vm.bankId}, function(result) {
                    vm.branch = result;
                    vm.clicked=false;            
                });

        	if(bank != undefined){vm.bankId = bank.id};
        	console.log(vm.bankId);
        	
        	
            
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
      
    }
})();
