(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FaqCategoryController', FaqCategoryController);

    FaqCategoryController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','FAQService'];

     function FaqCategoryController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,FAQService) {
        var vm = this;
        
        vm.name='';
        
        vm.faqcategorylistfromJSONFile = $rootScope.faqcategorylistfromJSONFile;
        vm.clear=clear;

        $scope.clicked=false;
        $scope.showButton = false;
       
        
        if(workflowSharedService.viewFrom == 'faq'){
       	 	$scope.showButton=false;
        }

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
     
        function onSaveSuccess (result) {
        	 $uibModalInstance.dismiss('close');   
        }

        function onSaveError () {
        	
        }

        function saveJSON() {
        	var JSONText = angular.toJson(vm.faqcategorylistfromJSONFile);
        	FAQService.uploadJSONFile({"type": "faq", "fileName": "faqcategorylist.txt", "fileType": "txt"},JSONText,function(result) {
                
        	});
        }
        
        vm.save=function(){
        	faqsave(onSaveSuccess(true), onSaveError);
        }
      
        function faqsave() {
        	
        	
        	vm.faqcategorylistfromJSONFile.push({ 'name':vm.name });
            
            saveJSON();
            
            }
        
     }   
})();
        