(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'holidayReport'
        })
        
        .controller('HolidayReportController', HolidayReportController);

    HolidayReportController.$inject = ['$scope', '$state', 'InvoiceView', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    				'$timeout','DateUtils','StaticLookUp','MessageBox','$rootScope','HolidayReport'];

    function HolidayReportController($scope, $state, InvoiceView , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    				$timeout,DateUtils,StaticLookUp,MessageBox,$rootScope,HolidayReport) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.HOLIDAY_REPORT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.isRegenerated=false;
        vm.selectedFilter='group'; 
        vm.loadHolidayYearsAndCurrency=loadHolidayYearsAndCurrency;
		vm.clicked=false;
        vm.getHolidayData=getHolidayData;
        vm.changedYear=changedYear;
        vm.onObtainHolidayReportSuccess=onObtainHolidayReportSuccess;
        vm.onObtainHolidayReportFailure=onObtainHolidayReportFailure;
        vm.exportToFile=exportToFile;
        vm.ExpandOrCollapseHolidayReport=true;
        vm.enableShowFuture=true;
        vm.holidayData=[];
        vm.allHolidayType = [];
        workflowSharedService.viewFrom = "holidayReport";
        vm.mimeType= '';  	  
 	  	vm.baseString =   'Holiday_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm'); 
 	  	vm.selectedCurrentYear=true;
 	  	vm.selectedCurrency="ALL";
 	  	vm.logScreenAccess=logScreenAccess;
 	  	
        loadHolidayYearsAndCurrency();
        logScreenAccess();
        vm.selectedHolidayType = "All";
       // loadCurrentBusinessDate();
        
        /*function getAllHolidayType(){
        	vm.allHolidayType = StaticLookUp.getAllHolidayType();
        	vm.allHolidayType.push("ALL");
        }
        */
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        
        function changedYear()
        {
        	var currentYear=new Date().getFullYear();
        
        	if(vm.selectedYear==currentYear)
        		{
        			vm.enableShowFuture=true;
        			vm.selectedCurrentYear=true;
        		}
        	else
        		{
        		vm.enableShowFuture=false;
        		vm.selectedCurrentYear=false;
        		}
        }
        
        function loadHolidayYearsAndCurrency(){
        	HolidayReport.getAllTheYears(function(result){
        		vm.yearsList = result;
        	});
        	
        	vm.selectedYear=new Date().getFullYear();

        }
        
        
        function getHolidayData()
        {
        				var currency='';
        	
			        	if(vm.selectedCurrency!="ALL")
			        	{
			        		currency=vm.selectedCurrency;
			        	}
			        	if(vm.selectedCurrentYear==true)
			        	{
			        		HolidayReport.getCurrentYearData({currencyCode:currency,holidayType:vm.selectedHolidayType},onObtainHolidayReportSuccess,onObtainHolidayReportFailure)
			        	}
			        	else
			        	{
			        		HolidayReport.getSpecificYearData({givenYear : vm.selectedYear,currencyCode:currency,holidayType:vm.selectedHolidayType},onObtainHolidayReportSuccess,onObtainHolidayReportFailure)
			
			        	}
          }
        function onObtainHolidayReportSuccess(result){
    		vm.holidayData=result;
			vm.clicked=false;
    		console.log(result);
    	}
        
        function onObtainHolidayReportFailure()
        {
        	vm.clicked=false;
        }
      	
        
        
        function exportToFile(fileType){
        	vm.baseString =   'Holiday_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm'); 
        	
        	 vm.convertedTodayDate = DateUtils.convertLocalDateToServer(vm.todayDate);
    	
        	  if( vm.holidayData !=null &&  vm.holidayData!=undefined){
        		  if(fileType == 'csv'){
        			  vm.mimeType = 'text/csv'
        			  vm.baseString =vm.baseString+ ".csv";  
        			  HolidayReport.csvHolidayReport(vm.holidayData, onGenerateFileSuccess);
        		  }else if(fileType == 'excel'){
        			  vm.mimeType = 'application/vnd.ms-excel';
        			  vm.baseString = vm.baseString+ ".xls";
        			  HolidayReport.excelHolidayReport(vm.holidayData, onGenerateFileSuccess);
        		  }
        		 

        		  function onGenerateFileSuccess(result)
        		  {
        	    	  var fileData = null;
        			  fileData = result.data;
        			  var link = document.createElement("a");   
        			  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
        			  
        			  
        		      var blob = new Blob([bytes], {type: vm.mimeType});
        		      if(window.navigator.msSaveOrOpenBlob) {
        	                window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
        		      }else{
        		    	  link.href =  window.URL.createObjectURL(blob);      
        		    	  link.download = vm.baseString;
        		    	  document.body.appendChild(link);
        		    	  link.click();
        		    	  document.body.removeChild(link);
        		      }
        		  }
        		  
    
        		  
        	  }
          }	
		
    }
})();
