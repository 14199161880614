(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerViewPanImageController',CustomerViewPanImageController);

    CustomerViewPanImageController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues'];

    function CustomerViewPanImageController($uibModalInstance,$stateParams, GlobalValues) {
    	var vm = this;
        vm.clear = clear;
        vm.image = $stateParams.image;
        vm.theme = $stateParams.theme;
        
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        
    }
})();
