(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'changeSettings'
	})

	.controller('CustomerSpecificSettingsController', CustomerSpecificSettingsController);

	CustomerSpecificSettingsController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'CustomerSpecificSettings', 'GlobalValues',
			'EntityPermissions', 'ScreenLog', 'DateUtils','$uibModalInstance','$stateParams','$rootScope','entityConstants' ];

	function CustomerSpecificSettingsController($scope, $state, workflowSharedService,
			CustomerSpecificSettings, GlobalValues, EntityPermissions, ScreenLog, DateUtils,$uibModalInstance,$stateParams,$rootScope,entityConstants) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.customerSpecificSettings = {};
		vm.save = save;
		vm.userId = $stateParams.userId;
		vm.restoreDefault = restoreDefault;
		vm.launch = launch;
		vm.clear = clear;
		workflowSharedService.viewFrom = 'changeSettings';
		vm.isShowRegistrationSettings = true;
		 vm.customerId = vm.globalValues.getCustomerId();
		 vm.isShowOrderSettings=true;
		function launch() {
			$state.go("customer-specific-settings");
		}
		logScreenAccess();
		loadAll();

		function loadAll() {
			CustomerSpecificSettings.get({
				"customerId" : vm.customerId
			},onGetCustomerSpecificSettingsSuccess, onGetCustomerSpecificSettingsError);
		}

		function onGetCustomerSpecificSettingsSuccess(result) {
			vm.customerSpecificSettings = result;
			if(vm.customerSpecificSettings.IsTradeSMSToBeSent==null || vm.customerSpecificSettings.IsTradeSMSToBeSent == undefined)
				{
				vm.customerSpecificSettings.IsTradeSMSToBeSent='Yes';
				}
	
		}

		function onGetCustomerSpecificSettingsError() {

		}
		
		function restoreDefault() {
			loadAll();
		}
		
	
		function save() {
			var fd = new FormData();
			
			vm.user.dob = DateUtils.convertLocalDateToServer(vm.user.dob);
			fd.append('user', JSON.stringify(vm.user));
            
			console.log(fd);

			CustomerSpecificSettings.update( fd, onSaveSuccess, onSaveError);
			
		}
		
		$rootScope.$on('Refresh', function(event, result) {
			loadAll();
		});
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function onSaveSuccess(result) {
			$uibModalInstance.close(result);
		}

		function onSaveError() {

			$scope.clicked = false;
		}
		function logScreenAccess(){
	       	ScreenLog.screenLog(entityConstants.ENTITY_SHORT_NAME.CUSTOMER_SPECIFIC_SETTINGS ,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	    }

	}
})();
