(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ClearCorpMarkupReportService', ClearCorpMarkupReportService);

    ClearCorpMarkupReportService.$inject = ['$resource'];

    function ClearCorpMarkupReportService($resource) {
        var resourceUrl =  'api/markup/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getMarkupForReport':{
          		isArray:true,
 				method: 'GET',
 				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'
 				},
 				url: 'api/markupsettings-for-customer'
           },
           'exportMarkupExcel':{
           	 method: 'GET',
                url: 'api/clearcorp-markup-report/excel-download',
                responseType: 'arraybuffer',
                params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'
                },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }      
           },
           'exportMarkupCsv':{
            	 method: 'GET',
                 url: 'api/clearcorp-markup-report/csv-download',
                 responseType: 'arraybuffer',
                 params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'
                 },
                 transformResponse: function(data, headers) {
               	  return {
                         data: data,
                         headers: headers
                     };   
                 }      
            },
            'exportMarkupPdf':{
             	 method: 'GET',
                  url: 'api/clearcorp-markup-report/pdf-download',
                  responseType: 'arraybuffer',
                  params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'
                  },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }      
             }
        });
    }
   })();
