(function() {
	'use strict';
	angular.module('gatewayApp').factory('UserProfile', UserProfile);

	UserProfile.$inject = [ '$resource', 'DateUtils' ];

	function UserProfile($resource, DateUtils) {
		var resourceUrl = 'api/bank/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				params : {
					userId : '@userId'
				},
				url : 'api/user-profile/:userId',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			'save' : {
				method : 'POST',
				url : 'api/user-profile/update-user',
				headers: {
                    'Content-Type': undefined
                },
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'updateWithImage' : {
				method : 'POST',
				url : 'api/user-profile/update-user-with-image',
				headers: {
                    'Content-Type': undefined
                }
			},
			'update' : {
				method : 'POST',
				url : 'api/user-profile/update-user',
				headers: {
                    'Content-Type': undefined
                }
			},
			'updatePreferences' : {
				method : 'PUT',
				url : 'api/user-profile/update-preferences',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'updateGivenPreference' : {
				method : 'PUT',
				params : {
					userId : '@userId', settingsName: '@settingsName', value: '@value'
				},
				url : 'api/user-profile/update-given-preference',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'updateCustomerInfo' : {
				method : 'POST',
				url : 'api/user-profile/update-customer-info',
				headers: {
                    'Content-Type': undefined
                }
			},
			'restoreDefault' : {
				method : 'PUT',
				url : 'api/user-profile/restore-default',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'getCustomer' : {
				method : 'GET',
				params : {
					customerId : '@customerId'
				},
				url : 'api/user-profile/get-customer-info/:customerId',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'updateUserProfileRemoveImage' : {
				method : 'POST',
				url : 'api/user-profile/update-user-remove-image',
				headers: {
                    'Content-Type': undefined
                }
			},
			
            'getPendingTaskCount':{
            	 method: 'GET',
            	 url: 'api/pending-tasks-for-user',
                 transformResponse: function (data) {
                	 if(data){
                     data = angular.fromJson(data);
                	 }
                     return data;
                 }
            },

            'getUserMarketWatchSettingsList':{
            	 method: 'GET',
            	 isArray : true,
            	 url: 'api/user-market-watch-list-for-user',
                 transformResponse: function (data) {
                	 if(data){
                     data = angular.fromJson(data);
                	 }
                     return data;
                 }
            },

            'saveUserMarketWatchList' : {
				method : 'POST',
				url : 'api/save-user-market-watch-list',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					angular.forEach(copy, function(value){
						value.settlementDate = DateUtils.convertLocalDateToServer(value.settlementDate);
					})
					return angular.toJson(copy);
				}
			},

            'resetUserMarketWatchList' : {
				method : 'POST',
				isArray : true,
				url : 'api/reset-user-market-watch-list',
                transformResponse: function (data) {
               	 if(data){
                    data = angular.fromJson(data);
               	 }
                    return data;
                }
			}

		});
	}
})();
