(function() {
	'use strict';

	angular.module('gatewayApp').controller('BranchDialogController',
			BranchDialogController);

	BranchDialogController.$inject = [ '$scope', '$state', '$rootScope',
			'GlobalValues', 'workflowSharedService', '$uibModalInstance',
			'Branch', 'entity', 'DateUtils' ,'AlertService','$filter'];

	function BranchDialogController($scope, $state, $rootScope, GlobalValues,
			workflowSharedService, $uibModalInstance, Branch, entity, DateUtils,AlertService,$filter) {
		var vm = this;
		vm.branch = entity;
		vm.branchDTO = {};
		vm.user1 = {};
		vm.user2 = {};
		vm.ExpandOrCollapseUser1 = false;
		vm.ExpandOrCollapseUser2 = false;
		vm.ExpandOrCollapseBankDetails = true;
		vm.user1.mobileCountryCode = 91;
		vm.user2.mobileCountryCode = 91;
		vm.mobileCountryCode=91;
		vm.user1.gender = "M";
		vm.user2.gender = "M";
		vm.ifscOrSwift = 'IFSC';
		vm.numberOfDigits = 11;
		vm.isDefaultUsersRequired1 = false;
		vm.isDefaultUsersRequired2 = false;
		vm.openSearchBranch = openSearchBranch;
		vm.clear = clear;
		vm.save = save;
		vm.clicked = false;
		$scope.showButton = false;
		vm.resubmitBranch = resubmitBranch;
		vm.globalValues = GlobalValues;
		//vm.branch.country=vm.globalValues.getCountry();
		vm.user1.country=vm.globalValues.getCountry();
		vm.user2.country=vm.globalValues.getCountry();
		$scope.showButton = false;
        vm.todayDate=new Date();
        vm.valid=true;

		if (workflowSharedService.viewFrom == 'branch') {
			vm.branch = entity;
			if(vm.branch!=null && vm.branch.id!=null){
				//do nothing
			}else{
				vm.branch.country=vm.globalValues.getCountry();
			}
			vm.branch.bank = vm.globalValues.getBank();
			if(vm.branch.ifscOrSwift!=null && vm.branch.ifscOrSwift!=undefined){
				vm.ifscOrSwift = vm.branch.ifscOrSwift;	
			}
			if(vm.ifscOrSwift == 'SWIFT'){
				vm.swiftCode = vm.branch.ifscCode;
			}
			if(vm.branch.mobileCountryCode!=null && vm.branch.mobileCountryCode!=undefined){
				vm.mobileCountryCode=vm.branch.mobileCountryCode;
			}
			$scope.showButton = false;
		} else if (workflowSharedService.viewFrom == 'branchtodolist_resubmit') {
			vm.branchDTO = angular.fromJson($rootScope.payload);
			vm.branch = vm.branchDTO.branch;
			
		
			if (vm.branch == undefined) {
				vm.branch = vm.branchDTO
			} else {

				vm.user1 = vm.branchDTO.user1;
				vm.user2 = vm.branchDTO.user2;

				if (vm.user1 != null) {
					vm.isDefaultUsersRequired1 = true;
					vm.user1.dob = new Date(vm.branchDTO.user1.dob);
				}
				if (vm.user2 != null) {
					vm.isDefaultUsersRequired2 = true;
					vm.user2.dob = new Date(vm.branchDTO.user2.dob);
				}
				
				if(vm.user1==null){
					vm.user1={};
					vm.user1.mobileCountryCode=91;
					vm.user1.gender="M";
					vm.user1.country=vm.globalValues.getCountry();
				}
				if(vm.user2==null){
					vm.user2={};
					vm.user2.mobileCountryCode=91;
					vm.user2.gender="M";
					vm.user2.country=vm.globalValues.getCountry();
				}
			}
			
			
			vm.ifscOrSwift = vm.branch.ifscOrSwift;
			
			if(vm.ifscOrSwift == 'SWIFT'){
				vm.swiftCode = vm.branch.ifscCode;
			}
			vm.mobileCountryCode=vm.branch.mobileCountryCode;
			
			vm.disableForm = false;
			$scope.showButton = true;
		} else if (workflowSharedService.viewFrom == 'branch_resubmit_delete') {
			vm.branch = angular.fromJson($rootScope.payload);
			vm.disableForm = true;
			$scope.showButton = true;
		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		/* $scope.$watch('vm.ifscOrSwift', function(value){
	        	if(vm.ifscOrSwift == 'IFSC'){
	        		vm.numberOfDigits = 11;
	        	}else if(vm.ifscOrSwift == 'SWIFT'){
	        		vm.numberOfDigits = 8;
	        	}
	        });*/
		
		function save() {
			
			

			vm.branch.aliasName = vm.branch.branchName;
			vm.branch.shortName = vm.branch.branchName;
			vm.branch.name = vm.branch.branchName;
			vm.branch.mobileCountryCode=vm.mobileCountryCode;
			if(vm.ifscOrSwift == 'SWIFT'){
				vm.branch.ifscCode = vm.swiftCode;
				vm.branch.ifscOrSwift = 'SWIFT'
			}else {
				vm.branch.ifscOrSwift = 'IFSC'
			}
			if (vm.branch.id == null) {
				vm.branchDTO.branch = vm.branch;

				if (vm.isDefaultUsersRequired1 == true) {
					if(moment(vm.user1.dob).isAfter(vm.todayDate,'day')){
		    			AlertService.error($filter('translate')('gatewayApp.branch.futuredobUser1'));
		    			vm.clicked = false;
		    			vm.valid=false;
		    		}else{
		    			vm.valid=true;
		    			vm.branchDTO.user1 = vm.user1;
						vm.branchDTO.user1.entityOwnerTypeShortName = 'Branch'
						vm.branchDTO.user1.dob = DateUtils
								.convertLocalDateToServer(vm.user1.dob);
		    		}
					
				} else {
					vm.branchDTO.user1 = null;
				}
				
				if (vm.isDefaultUsersRequired2 == true) {
					if(moment(vm.user2.dob).isAfter(vm.todayDate,'day')){
		    			AlertService.error($filter('translate')('gatewayApp.branch.futuredobUser2'));
		    			vm.clicked = false;
		    			vm.valid=false;
		    		}else{
		    			vm.valid=true;
		    			vm.branchDTO.user2 = vm.user2;
						vm.branchDTO.user2.entityOwnerTypeShortName = 'Branch'
						vm.branchDTO.user2.dob = DateUtils
								.convertLocalDateToServer(vm.user2.dob);
		    		}
					
				} else {
					vm.branchDTO.user2 = null;
				}

			}

			if (vm.branch.id !== null) {
				Branch.update({
					"corpId" : GlobalValues.getCorpId()
				}, vm.branch, onSaveSuccess, onSaveError);
			} else if(vm.valid==true) {
				Branch.save({
					"corpId" : GlobalValues.getCorpId()
				}, vm.branchDTO, onSaveSuccess, onSaveError);
			}

		}

		function onSaveSuccess(result) {
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			if (vm.isDefaultUsersRequired1 == true) {
				vm.user1.dob = DateUtils
						.convertDateTimeFromServer(vm.branchDTO.user1.dob);
				}
			if (vm.isDefaultUsersRequired2 == true) {
				vm.user2.dob = DateUtils
						.convertDateTimeFromServer(vm.branchDTO.user2.dob);
				}
			vm.clicked = false;
		}

		function resubmitBranch() {
			vm.branch.aliasName = vm.branch.branchName;
			vm.branch.shortName = vm.branch.branchName;
			vm.branch.name = vm.branch.branchName;
			vm.branch.mobileCountryCode=vm.mobileCountryCode;
			if(vm.ifscOrSwift == 'SWIFT'){
				vm.branch.ifscCode = vm.swiftCode;
				vm.branch.ifscOrSwift = 'SWIFT'
			}else {
				vm.branch.ifscOrSwift = 'IFSC'
			}
			if (vm.branch.id == null) {
				vm.branchDTO.branch = vm.branch;

				if (vm.isDefaultUsersRequired1 == true) {
					vm.branchDTO.user1 = vm.user1;
					vm.user1.dob = DateUtils
							.convertLocalDateToServer(vm.user1.dob);
					
					vm.branchDTO.user1.entityOwnerTypeShortName = 'Branch'
					
					vm.branchDTO.user1.dob = DateUtils
							.convertLocalDateToServer(vm.user1.dob);
					
				}else{
					vm.branchDTO.user1 = null;
				}
				
				if (vm.isDefaultUsersRequired2==true) {
					vm.branchDTO.user2 = vm.user2;
					vm.user2.dob = DateUtils
							.convertLocalDateToServer(vm.user2.dob);
					
					vm.branchDTO.user2.entityOwnerTypeShortName = 'Branch'
					
					vm.branchDTO.user2.dob = DateUtils
							.convertLocalDateToServer(vm.user2.dob);
					
				}else{
					vm.branchDTO.user2=null;
				}
			}

			if (vm.branch.id == null) {
				Branch.resubmitForCreate({
					"taskId" : $rootScope.taskID
				}, vm.branchDTO, onResubmitSuccess, onResubmitError);
			} else {
				Branch.resubmitForEdit({
					"taskId" : $rootScope.taskID
				}, vm.branch, onResubmitSuccess, onResubmitError);
			}

		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}
		function onResubmitError(error) {
			if(vm.branchDTO.user2!=null){
				vm.branchDTO.user2.dob = DateUtils.convertLocalDateFromServer(vm.branchDTO.user2.dob);
			}
			if(vm.branchDTO.user1!=null){
				vm.branchDTO.user1.dob = DateUtils.convertLocalDateFromServer(vm.branchDTO.user1.dob);
			}
        	vm.clicked = false;
		}
		
		$scope.$watch('vm.homeBranch', function(value){
			if(value!=null && value!='' && value!=undefined){
				vm.branch.branchName = value.branchName;
				vm.ifscOrSwift = "IFSC";
				vm.branch.ifscOrSwift = "IFSC";
				vm.branch.ifscCode = value.ifscCode;
			}
		})
		
		$scope.$watch('vm.branch.bank', function(value){
			if(value!=null && value!='' && value!=undefined){
				//var bankName = value.bankName;
				//bankName = bankName.substring(0, 5);		// mantis issue fix:20		
				vm.filterText = value.ccpMembershipId;
			}
		})
		
		function openSearchBranch(){
			if(workflowSharedService.viewFrom == 'branch'){
				$state.go('home-branch-filter', {filterText: vm.filterText});
			}else if(workflowSharedService.viewFrom == 'branchtodolist_resubmit'){
				$state.go('home-branch-filter-resubmit', {filterText: vm.filterText});
			}
			
			$rootScope.$on('searchBranch', function(event, result) {
	        	vm.homeBranch= $rootScope.branch;
	        });
		}

	}
})();
