(function() {
	'use strict';
	angular.module('gatewayApp').factory('ApplicationWideSettings', ApplicationWideSettings);

	ApplicationWideSettings.$inject = [ '$resource' ];

	function ApplicationWideSettings($resource) {
		var resourceUrl = 'api/applicationWideSettings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				url : 'api/configuration-settings',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}

		});
	}
})();
