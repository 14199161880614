(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('BranchDeleteController',BranchDeleteController);

    BranchDeleteController.$inject = ['$uibModalInstance', 'entity', 'Branch', 'GlobalValues','$scope'];

    function BranchDeleteController($uibModalInstance, entity, Branch, GlobalValues,$scope) {
        var vm = this;

        vm.branch = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.clicked=false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        function confirmDelete (id) {
        	Branch.delete({"corpId": GlobalValues.getCorpId(),"id":id},
                    function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
