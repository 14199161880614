(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SwapQuotesDialogController1', SwapQuotesDialogController1);

    SwapQuotesDialogController1.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','SwapQuotes','entity', 'DateUtils', 'ScreenLog','entityConstants', 'AlertService', '$filter', 'Workflow'];

     function SwapQuotesDialogController1($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,SwapQuotes,entity,DateUtils,ScreenLog,entityConstants,AlertService,$filter, Workflow) {
        var vm = this;
        vm.swapQuotes=entity;  
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SWAP_QUOTES;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        vm.resubmitSwapQuotes =resubmitSwapQuotes;
        //vm.currentDate = new Date();
        vm.globalValues = GlobalValues;
        vm.currentDate = vm.globalValues.getSystemSettings().businessDate;
        vm.disableSpread= false;
        $scope.showButton = false;
        $scope.showResubmitButton = false;
        $scope.showApproveRejectButton = false;
        $scope.showDiscardButton = false;
        vm.approveSwapQuotes = approveSwapQuotes;
        vm.rejectSwapQuotes = rejectSwapQuotes;
        vm.discardSwapQuotes = discardSwapQuotes;
        vm.previousQuotes = {};
        vm.disableCashSpread= false;
        vm.disableTOMSpread= false;
        vm.instrumentList = [];
        vm.showcashBidError=false;
        vm.showcashOfferError=false;
        vm.showtomBidError=false;
        vm.showtomOfferError=false;
                
        if(workflowSharedService.viewFrom == 'swapQuotes'){
        	vm.swapQuotes=entity;
        	 SwapQuotes.getSettlementDateForCashAndTOM(onInstrumentSuccess, onInstrumentFailure);
        	 vm.previousQuotes = angular.copy(vm.swapQuotes);
        	 $scope.showButton = true;
             $scope.showApproveRejectButton = false;
             $scope.showDiscardButton = false;
             vm.disableSpread=false;
             $scope.showResubmitButton = false;
        }else if(workflowSharedService.viewFrom == 'swapQuotes_checker') {
        	vm.swapQuotes =  angular.fromJson($rootScope.payload); 
        	 SwapQuotes.getSettlementDateForCashAndTOM(onInstrumentSuccess, onInstrumentFailure);
        	 $scope.showButton = false;
        	 $scope.showResubmitButton = false;
        	$scope.showApproveRejectButton=true;
        	$scope.showDiscardButton = false;
        	 vm.disableSpread=true;
        }
        else if(workflowSharedService.viewFrom == 'swapQuotes_maker') {
        	vm.swapQuotes =  angular.fromJson($rootScope.payload); 
        	 SwapQuotes.getSettlementDateForCashAndTOM(onInstrumentSuccess, onInstrumentFailure);
        	 $scope.showButton = false;
        	 $scope.showResubmitButton = false;
        	$scope.showApproveRejectButton=false;
        	$scope.showDiscardButton = true;
        	 vm.disableSpread=true;
        }
        else if(workflowSharedService.viewFrom == 'swapQuotes_resubmit') {
        	vm.swapQuotes = angular.fromJson($rootScope.payload); 
        	 SwapQuotes.getSettlementDateForCashAndTOM(onInstrumentSuccess, onInstrumentFailure);
        	vm.screenLog.screenLog(vm.screenName, vm.swapQuotes.id==null?0:vm.swapQuotes.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	vm.disableForm = false;
        	$scope.showButton=false;
        	 $scope.showResubmitButton = true;
        	$scope.showApproveRejectButton=false;
        	$scope.showDiscardButton = false;
        	 vm.disableSpread=false;
        }
       else if(workflowSharedService.viewFrom == 'swapQuotes_auditLog') {
        	vm.swapQuotes = angular.fromJson($rootScope.payload); 
        	 SwapQuotes.getSettlementDateForCashAndTOM(onInstrumentSuccess, onInstrumentFailure);
        	vm.disableForm = true;
        	$scope.showButton=false;
        	$scope.showResubmitButton = false;
        	$scope.showApproveRejectButton=false;
        	$scope.showDiscardButton = false;
        	vm.disableSpread=true;
        }
		vm.businessDate = DateUtils.convertLocalDateFromServer(vm.swapQuotes.businessDate)
		if(( workflowSharedService.viewFrom == 'swapQuotes_resubmit' || workflowSharedService.viewFrom == 'swapQuotes') && vm.swapQuotes.businessDate != DateUtils.convertLocalDateToServer(vm.currentDate) ){
				vm.clicked=true;
				
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.pastDateEditError'));
				vm.disableSpread=true;
				//$scope.clicked=false;
			
		
		}
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {        	    		
        	
       		if(vm.swapQuotes.businessDate != DateUtils.convertLocalDateToServer(vm.currentDate) ){
       			AlertService.error($filter('translate')('gatewayApp.swapQuotes.pastDateEditError'));
       			vm.clicked=false;
       			
       		}
       		
       		else if (vm.swapQuotes.id !== null) {
       			if(validateChange()){
       			 
       				SwapQuotes.update({ "orgId": GlobalValues.getOrgId() },vm.swapQuotes, onSaveSuccess, onSaveError);
       			}else{
       				AlertService.error($filter('translate')('gatewayApp.swapQuotes.noChangesMade'));
       				vm.clicked=false;
       			}
        		
             }else {
            	 SwapQuotes.save({ "orgId": GlobalValues.getOrgId() },vm.swapQuotes, onSaveSuccess, onSaveError);           
             }       	
       	
         }
     
        function onSaveSuccess (result) {
        	//$scope.clicked=true;
        //	$uibModalInstance.close(result);  
        }

        function onSaveError () {
        	//vm. = DateUtils.convertDateTimeFromServer(vm.swapQuotes.holidayDate);

        	vm.clicked = false;
        }
        function resubmitSwapQuotes() {
        	SwapQuotes.resubmit({"taskId" : $rootScope.taskID}, vm.swapQuotes, onResubmitSuccess, onResubmitError);
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function rejectSwapQuotes() { Workflow.rejectTask($rootScope.taskID); }

        function discardSwapQuotes() {
        	SwapQuotes.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

        function approveSwapQuotes() {  
        	SwapQuotes.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
        function validateChange(){
        	var changesMade = false;
        	var field1 = checkvalidityOfValues(vm.previousQuotes.cashSpotBidSpread , vm.swapQuotes.cashSpotBidSpread);
        	var field2 = checkvalidityOfValues(vm.previousQuotes.cashSpotOfferSpread , vm.swapQuotes.cashSpotOfferSpread);
        	var field3 = checkvalidityOfValues(vm.previousQuotes.tomSpotBidSpread , vm.swapQuotes.tomSpotBidSpread);
        	var field4 = checkvalidityOfValues(vm.previousQuotes.tomSpotOfferSpread , vm.swapQuotes.tomSpotOfferSpread);
        	
        	if(field1 || field2 || field3 || field4 ){
        		changesMade = true;
        	}
        	return changesMade;
        }
        function checkvalidityOfValues(previousValue, currentValue){
        	//var isValueChanged = (!(previousValue == null && (currentValue == "" || currentValue== null)) && previousValue!=currentValue);
        	
        	
        	if(!(previousValue == null && (currentValue == "" || currentValue== null))){
        		if(previousValue==0 && currentValue==""){
        			return true;
        		}
        		if(previousValue!=currentValue){
        			return true;
        		}
        	}
        	return false;
        }
       function onInstrumentSuccess(result){
    	    vm.instrumentList = result;
    	    angular.forEach(  vm.instrumentList, function(item){
       		
       		 	if(item.instrumentCode =='CASH'){
       		 		vm.cashSettlementDate = item.settlementDate;
       		 		vm.cashTickSize = item.tickSize * 100; // convert to paise
       		 		if(item.isSettlementDateHoliday==true){
       		 			vm.disableCashSpread= true;   		 			
       		 		}else{
       		 			vm.disableCashSpread= false;   		 		
       		 		}    
       		 	}
       		 		  		 
       		 	if (item.instrumentCode =='TOM' ){
       		 		vm.tomSettlementDate = item.settlementDate;
       		 		vm.tomTickSize = item.tickSize * 100;
       		 		if(item.isSettlementDateHoliday==true){
       		 			vm.disableTOMSpread= true;       		 			
       		 		}else{
       		 			vm.disableTOMSpread= false;       		 	   
       		 		}       		 		
       		 	}
       		 		
    	   });
       }
       function onInstrumentFailure(error){
    	   
       }
       
       function isValidSpreadValue(tickSize, spreadValue){
    	  if(spreadValue !=null && spreadValue!=undefined && spreadValue!='' && tickSize!=undefined && tickSize!=null){
    		 
    		// var floatValue = parseFloat(spreadValue);// convert spread to float
    		 var intSpreadValue = Math.round(parseFloat(spreadValue) * 100);
    		 
    		 var tickSizeInPaise = Math.round(parseFloat(tickSize)*100);// convert tick size to fixed decimal
    		// var intTickSize = parseInt (tickSizeInPaise * 100);
    		 
    		 var remainder = intSpreadValue % tickSizeInPaise;
    		 // fix remainder to 2 decimal
    		 if(remainder>0 || remainder<0){
    			 return false;
    	  	}else{
    	  		return true;
    	  	}
    		 
    	  }
    	  return true;
       }
      $scope.$watch('vm.swapQuotes.cashSpotBidSpread' , function(value){
    	  
    	vm.showcashBidError=!isValidSpreadValue(vm.cashTickSize,value );
    	var element = angular.element('#cashBidSpread');
    	
    	  
      });
      $scope.$watch('vm.swapQuotes.cashSpotOfferSpread' , function(value){
    	
    	vm.showcashOfferError=!isValidSpreadValue(vm.cashTickSize,value );
    	  
      });
      $scope.$watch('vm.swapQuotes.tomSpotBidSpread' , function(value){
    	
    	  vm.showtomBidError=!isValidSpreadValue(vm.tomTickSize,value )
    	 
      });
      $scope.$watch('vm.swapQuotes.tomSpotOfferSpread' , function(value){
    	  
    	vm.showtomOfferError=!isValidSpreadValue(vm.tomTickSize,value );
    	  
      });
    	  
      
    	   
      		
     }   
})();
