(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('TradesDownload', TradesDownload);

    TradesDownload.$inject = ['$resource'];

    function TradesDownload($resource) {
    	 var resourceUrl =  'api/limit-settings';
         
         return $resource(resourceUrl, {}, {
        	 
        	 'getTradesOutstandingCSV':{
        		 method: 'POST',
 				url: 'api/trades-outstanding/export-csv',
 				responseType: 'arraybuffer',
 				transformRequest: function (data) {
 					var copy = angular.copy(data);
 					return angular.toJson(copy);
 				},
 				transformResponse: function(data, headers) {
 					return {
 						data: data,
 						headers: headers
 					};   
 				} 
        	 },
        	 'getTradesOutstandingExcel':{
        		 method: 'POST',
  				url: 'api/trades-outstanding/export-excel',
 				responseType: 'arraybuffer',
  				transformRequest: function (data) {
 					var copy = angular.copy(data);
 					return angular.toJson(copy);
 				},
 				transformResponse: function(data, headers) {
 					return {
 						data: data,
 						headers: headers
 					};   
 				} 
        	 },
        	 'getBytesForDownload':{
        	  
        	  params: {from: '@from', to : '@to'},  	
        	  method: 'POST',
              url: 'api/trades/csv-download',
              responseType: 'arraybuffer',
              transformResponse: function(data, headers) {
            	  return {
                      data: data,
                      headers: headers
                  };   
              } 
        	},
        	'getFixBytesForDownload':{
          	  
          	  params: {from: '@from', to : '@to'},  	
          	  method: 'POST',
                url: 'api/trades/fix-download',
                responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
          	},
          	'getCSVForTrades':{
          	   	
          	  method: 'POST',
                url: 'api/trades/home-csv-download',
                responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
          	},
        	'getExcelForTrades':{
          	   	
            	 method: 'POST',
          		 url: 'api/trades/home-excel-download',
          		 responseType: 'arraybuffer',
                 transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                } 
          	}
         });
    }
})();
