
(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('BankFeeViewForEntitiesUtility', BankFeeViewForEntitiesUtility);

    BankFeeViewForEntitiesUtility.$inject = ['$rootScope','FileUtil','$filter','MessageBox'];

    function BankFeeViewForEntitiesUtility ($rootScope,FileUtil,$filter,MessageBox ) {
    	var seletedFileName ="";
    	var messageBoxName = "";
        var service = {
        		previewBankFee : previewBankFee,
        		previewBankFeeForArray : previewBankFeeForArray,
        		downloadBankFee : downloadBankFee,
        		downloadBankFeeForArray : downloadBankFeeForArray,
        };

        return service;
        
        function previewBankFeeForArray(arrayBuffer, pdfFileName){
        	//var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			//var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
        	var file = new Blob([arrayBuffer], {type: 'application/pdf'});
      		var filename = pdfFileName;
            var fileURL = URL.createObjectURL(file);
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            }
            else {
            	//window.open(fileURL, filename, "width=700,height=700,"+"top="+y+", left="+x);
               window.open(fileURL, filename, "fullscreen=yes");   
            	//window.open('assets/data/forex_trading_tutorial5.pdf', 'pdf_test.pdf');
            	/*var link = document.createElement("a");   
            	link.href =  fileURL;      
  	    	    link.download =filename;
  	    	    document.body.appendChild(link);
  	    	    link.click();
  	    	    document.body.removeChild(link);*/
            }
        }
        function downloadBankFeeForArray(arrayBuffer, pdfFileName){
        	//var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			//var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
        	var file = new Blob([arrayBuffer], {type: 'application/pdf'});
      		var filename = pdfFileName;
            var fileURL = URL.createObjectURL(file);
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            }
            else {            
            	var link = document.createElement("a");   
            	link.href =  fileURL;      
  	    	    link.download =filename;
  	    	    document.body.appendChild(link);
  	    	    link.click();
  	    	    document.body.removeChild(link);
            }
        }
        function previewBankFee(fileInbase64String, pdfFileName){
        	
        	var arrayBuffer =  FileUtil.base64ToArrayBuffer(fileInbase64String);
        	previewBankFeeForArray(arrayBuffer, pdfFileName);
        	
        }
        function downloadBankFee(fileInbase64String, pdfFileName){
        	
        	var arrayBuffer =  FileUtil.base64ToArrayBuffer(fileInbase64String);
        	downloadBankFeeForArray(arrayBuffer, pdfFileName);
        	
        }
        

        
               
    }
})();
