(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'faq'
	})

	.controller('FaqDetailsController', FaqDetailsController);

	FaqDetailsController.$inject = [ '$rootScope', '$scope', '$state',
			'workflowSharedService', 'FAQService', 'GlobalValues',
			'EntityPermissions' ];

	function FaqDetailsController($rootScope, $scope, $state, workflowSharedService,
			FAQService, GlobalValues, EntityPermissions) {
		var vm = this;
		workflowSharedService.viewFrom = "faq";
		vm.launch = launch;
		vm.loadincomplete = true;
		function launch() {
			$rootScope.faqfilteredbyclick = null;
			$state.go("manage-faq.new");
		}
		
		vm.addCategory = addCategory;
		function addCategory(){
			$state.go("manage-faq-category");
		}
		
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
		
		$rootScope.$on('gatewayApp:reloadFAQPage', function(){
			vm.faqdetailsfromJSONFile = null;
			vm.faqdetailsfiltered = null;
			$rootScope.faqdetailsfromJSONFile = null;
			vm.loadincomplete = true;
			loadfaqdetails();
		});
		
		vm.faqCategoryList = [];

		vm.permitFaqEditEntityAction = EntityPermissions.hasPermission('FAQ.EDIT')
		vm.permitFaqCreateEntityAction = EntityPermissions.hasPermission('FAQ.CREATE')
		vm.permitFaqViewEntityAction = EntityPermissions.hasPermission('FAQ.VIEW')
		vm.permitFaqDeleteEntityAction = EntityPermissions.hasPermission('FAQ.DELETE')

		vm.faqcategorynameselected = "";

		vm.Myshow = false;

		vm.faqdetailsfiltered = [];
		
		loadAll();

		function loadAll() {

			//load faq details from json
			loadfaqdetails();
			
			//load faq category list from json file
			loadfaqcategory();
			
		}

		function loadfaqdetails(){
			FAQService.downloadJSONFile({
				"type" : "faq",
				"fileName" : "faqdetailsjson.txt",
				"fileType" : "txt"
			}, function(result) {
				vm.faqdetailsfromJSONFile = result;
				$rootScope.faqdetailsfromJSONFile = vm.faqdetailsfromJSONFile;
				vm.filterfaqcategory();
				vm.loadincomplete = false;
			});
		}
		
		function loadfaqcategory(){
			FAQService.downloadJSONFile({
				"type" : "faq",
				"fileName" : "faqcategorylist.txt",
				"fileType" : "txt"
			}, function(result) {
				vm.faqcategorylistfromJSONFile = result;
				$rootScope.faqcategorylistfromJSONFile = vm.faqcategorylistfromJSONFile;
				vm.loadincomplete = false;
			});
		}
		
		vm.downloadvideo = function() {
			//alert("downloadvideo");
			FAQService.downloadVideo({
				"type" : "faq",
				"fileName" : "sample.mp4",
				"fileType" : "mp4"
			}, fd, onSaveSuccess, onSaveError);
		}

		function onSaveSuccess(result) {
			$state.go($state.current, {}, {
				reload : true
			});
		}

		function onSaveError(result) {
			$state.go($state.current, {}, {
				reload : true
			});
		}


		//filter faq list by selected category
		//vm.faqcategorynameselected = "Approval";
		vm.filterfaqcategory = function filterfaqcategory() {
			
			vm.faqdetailsfiltered = null;
			var as = $(vm.faqdetailsfromJSONFile)
			.filter(function (i,n){
				return (n.categorylist.indexOf(vm.faqcategorynameselected) != -1);
			});

			if (Object.keys(as).length > 3){
				vm.faqdetailsfiltered = as;
			}
		}
		
		//filter current selected faq 
		vm.filterfaq = function filterfaq(secfaqdetails) {
			$rootScope.faqfilteredbyclick = secfaqdetails;
			$rootScope.indexnumber = secfaqdetails.index;
		}

		vm.Myfun = function() {
			vm.Myshow = !vm.Myshow;
		}

	}
})();
