(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AlertConfigurationDeleteController',AlertConfigurationDeleteController);

    AlertConfigurationDeleteController.$inject = ['$uibModalInstance', 'entity', 'AlertConfiguration', 'GlobalValues', 'OrganisationManipulation', '$scope', 'ScreenLog'];

    function AlertConfigurationDeleteController($uibModalInstance, entity, AlertConfiguration, GlobalValues, OrganisationManipulation, $scope, ScreenLog) {
        var vm = this;

        vm.alertConfigurationHeader = entity;
        //ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': vm.alertConfigurationHeader.id, 'action': 'DELETE'});
        var notifyByTypeDetailsArr=vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails;
        vm.notifyByTypeList=[];
        var alertConfigurationNotifyByTypeDetails = [];
        angular.forEach(notifyByTypeDetailsArr, function(value, key) 
        {
        	if (value.active==true){
            	//vm.notifyByTypeList.push(value.notifyByType.id);
            	alertConfigurationNotifyByTypeDetails.push({
        			'notifyByType': value.notifyByType
        			});
        	}
         });
        
        var notifyUserDetailsArr=vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails;
        vm.userList=[];
        vm.roleList=[];
        var alertConfigurationNotifyUserDetails = []
        angular.forEach(notifyUserDetailsArr, function(value, key) 
        {
        	if(value.active==true){
        		if (vm.alertConfigurationHeader.configNotifyUsersBy == 'users'){
                	//vm.userList.push(value.user.id);
        			alertConfigurationNotifyUserDetails.push({
        				"user":{"id":value.user.id,"login":value.user.login,"firstName":value.user.firstName,"lastName":value.user.lastName,"email":value.user.email,"activated":value.user.active,"langKey":value.user.langKey,"resetKey":value.user.resetKey,"mobileCountryCode":value.user.mobileCountryCode,"mobile":value.user.mobile,"userType":value.user.userType,"resetDate":value.user.resetDate,"active":value.user.active},
            			"organisationRole": null
            	});
        		}else{
                	//vm.roleList.push(value.organisationRole.id);
        			alertConfigurationNotifyUserDetails.push({
        				"user":null,
            			"organisationRole": {"id":value.organisationRole.id,"active":value.organisationRole.active,"systemGenerated":value.organisationRole.systemGenerated,"shortName":value.organisationRole.shortName,"name":value.organisationRole.name,"objectType":value.organisationRole.objectType}
            		});
        		}
        	}
        });

        vm.clear = clear;
        vm.alertConfigurationDTO = {};
        vm.ownerOrganisation = GlobalValues.getOrganisation();
        //vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.confirmDelete = function confirmDelete() {
        	prepareAlertConfigurationDTOToUpdate();
        	//alert(vm.Member.id);
        	AlertConfiguration.delete({"orgId":GlobalValues.getUserOrganisation().id}, vm.alertConfigurationDTO,
                function () {
        		 	$scope.$emit('alertRefresh');
                    $uibModalInstance.close(true);
                });
        }
        
        function prepareAlertConfigurationDTOToUpdate(){
        	
        	vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails=[];
        	vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails=[];

        	delete vm.alertConfigurationHeader.ownerOrganisation;
        	delete vm.alertConfigurationHeader.creatorOrganisation;
        	vm.alertConfigurationDTO.alertConfigurationHeader=vm.alertConfigurationHeader;
        	vm.alertConfigurationDTO.alertConfigurationNotifyUserDetails=alertConfigurationNotifyUserDetails;       		
        	vm.alertConfigurationDTO.alertConfigurationNotifyByTypeDetails=alertConfigurationNotifyByTypeDetails; 
        	vm.alertConfigurationDTO.organisationDTO = OrganisationManipulation.checkAndGetOrganisationDTO(vm.ownerOrganisation);
        	
        }
        
        
    }
})();
