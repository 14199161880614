(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'tacViewForEntities'
        })
        .controller('TACViewForEntitiesController',TACViewForEntitiesController);

    TACViewForEntitiesController.$inject = [   'GlobalValues','$scope','ScreenLog','workflowSharedService','entityConstants','EntityPermissions','$state','TACViewForEntitiesService','$rootScope','$timeout','TacViewForEntitiesUtility','ClearcorpTACUpload','ScreenLogService'];

    function TACViewForEntitiesController(  GlobalValues,$scope,ScreenLog,workflowSharedService,entityConstants,EntityPermissions,$state,TACViewForEntitiesService,$rootScope, $timeout,TacViewForEntitiesUtility,ClearcorpTACUpload,ScreenLogService) {
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TERMS_AND_CONDITIONS_VIEW ; 
        vm.entityConstants = entityConstants;
        //vm.permitTACAgreeEntityAction = EntityPermissions.hasPermission('TermsAndConditionsView.AGREE')
        //vm.permitTACViewEntityAction = EntityPermissions.hasPermission('TermsAndConditionsView.VIEW')
        // retain overflow visiblity in page refresh - Mantis 1579 10-Aug 2020
        vm.permitTACAgreeEntityAction = true;
        vm.permitTACViewEntityAction = true;
        vm.isCustomerEntity = false;
        vm.loggedInUsersORganisationName = "";       
        
        vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.organisation = vm.globalValues.getOrganisation();
        vm.loggedInUsersOrganisationName  =  vm.organisation.name;
        vm.previewTAC = previewTAC;
        vm.confirmAgreeTAC = confirmAgreeTAC;
        $scope.refresh=refresh;
        vm.exportToFile = exportToFile;
        workflowSharedService.viewFrom= "tacViewForEntities";
        logScreenAccess();
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        
        loadAll();
        function loadAll(){
        
	        if (vm.loggedinEntityOwnerType == 'Bank'){
	        	vm.isCustomerEntity = false; 
	        	//TACViewForEntitiesService.getBankTacAgreedDetails({"bankId" : vm.organisation.id}, tacAgreedDetailsSuccess, tacAgreedDetailsError);
	       
	        }else if(vm.loggedinEntityOwnerType == 'MultiUserCustomer' || vm.loggedinEntityOwnerType == 'SingleUserCustomer'){
	        	vm.isCustomerEntity = true;     
	        	//TACViewForEntitiesService.getCustomerTacAgreedDetails({"customerId" : vm.organisation.id}, tacAgreedDetailsSuccess, tacAgreedDetailsError);
	        }
	        TacViewForEntitiesUtility.loadAllTacAgreedDetails(vm.loggedinEntityOwnerType, vm.organisation.id);
        }
       /* function tacAgreedDetailsSuccess(result){ 
        	vm.tacList  = result;
        }
        function tacAgreedDetailsError(error){
        	console.log("error in fetching TAC agreed details")
        }*/
        
        function confirmAgreeTAC(tacAgreedDetail){
        	console.log("create and set selected tac to rootscope")
        	var tacAgreedEntityDTO = {};
        	tacAgreedEntityDTO.agreedEntityName =  vm.loggedInUsersOrganisationName;
        	tacAgreedEntityDTO.tacProviderName = tacAgreedDetail.tacProviderName;
        	tacAgreedEntityDTO.tacAgreedEntities={};
        	tacAgreedEntityDTO.tacAgreedEntities.organisationId = vm.organisation.id;
        	tacAgreedEntityDTO.tacAgreedEntities.tacType = {};
        	tacAgreedEntityDTO.tacAgreedEntities.tacType.id= tacAgreedDetail.tacTypeId;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster = {};
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.id = tacAgreedDetail.tacMasterId;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.organisationId= tacAgreedDetail.tacProviderId;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.tacType = {}
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.tacType.id= tacAgreedDetail.tacTypeId;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.enforcingDate = tacAgreedDetail.enforcingDate;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.createdDate = tacAgreedDetail.latestRevisionTime;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.tacFiles = {};
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.tacFiles.id = tacAgreedDetail.tac_file_id;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.tacFiles.fileName = tacAgreedDetail.fileName;
        	tacAgreedEntityDTO.tacAgreedEntities.tacMaster.tacFiles.fileContent = tacAgreedDetail.fileContent;
        	$rootScope.selectedTacAgreedEntityDTO = tacAgreedEntityDTO;
        	var state = 'tac-agree-confirmation';
   			$state.go(state,{tacTypeId : tacAgreedEntityDTO.tacAgreedEntities.tacType.id} );
        	
        }
        
        function previewTAC(tacAgreedDetail){
        	//TODO - check for filename 
        	vm.seletedFileName = findFileNameForAgreedTac(tacAgreedDetail.tacTypeId);
        	//only on successful screen log , open the tac view
        	ScreenLogService.screenLog({entityName: vm.screenName, entityRecordId:tacAgreedDetail.tacMasterId, action:vm.entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS},function(){
        		if(tacAgreedDetail.isAgreed == 'No'){
        			TacViewForEntitiesUtility.previewTAC(tacAgreedDetail.fileContent, tacAgreedDetail.fileName);
        		}else{
        			//call the service to fetch the merged details file content
        			if(vm.isCustomerEntity==false){
        				ClearcorpTACUpload.getTermsAndConditionsMerged(tacAgreedDetail, onMergeSuccess, onMergeError);
        			}else{
        				ClearcorpTACUpload.getTermsAndConditionsMergedCustomerView(tacAgreedDetail, onMergeSuccess, onMergeError);
        			}
        		}
        	});
        }
		function onMergeSuccess (result) {
			
			TacViewForEntitiesUtility.previewTACForArray(result.data, vm.seletedFileName);	
		}
	
		function onMergeError () {
	
		}
		
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
    	function exportToFile(fileType){
			vm.baseString =   'Terms_And_Conditions_View_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			if( $rootScope.tacList !=null &&  $rootScope.tacList !=undefined){
				if(vm.isCustomerEntity){			
					if(fileType == 'csv' ){
						vm.mimeType = 'text/csv'
						vm.baseString = vm.baseString+ ".csv";  
						TACViewForEntitiesService.getCustomercsv($rootScope.tacList , processResponse );
					}else if(fileType == 'excel' ){
						vm.mimeType = 'application/vnd.ms-excel';
						vm.baseString = vm.baseString+ ".xls";  
						TACViewForEntitiesService.getCustomerExcel($rootScope.tacList , processResponse );
					}
				}
				else{			
					if(fileType == 'csv' ){
						vm.mimeType = 'text/csv'
						vm.baseString = vm.baseString+ ".csv";  
						TACViewForEntitiesService.getBankCsv($rootScope.tacList , processResponse );
					}else if(fileType == 'excel' ){
						vm.mimeType = 'application/vnd.ms-excel';
						vm.baseString = vm.baseString+ ".xls";  
						TACViewForEntitiesService.getBankExcel($rootScope.tacList , processResponse );
					}
				}
			}
		}
    	function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			
		}
    	
    	function findFileNameForAgreedTac(tacTypeId){
    		if(tacTypeId == 1){
    	         
    	          return "Clearcorp's Terms and Conditions for Customer.pdf";
    	    }else if( tacTypeId == 2){
    	          
    	    	return "Clearcorp's Terms and Conditions for Member.pdf";
    	     }else if(tacTypeId == 3){
    	         
    	    	 return "Relationship Bank's Terms and Conditions for Customer.pdf";
    	     }else{
    	    	 return "Default";
    	     }
    		
    	}
    }
})();
