(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customer-bank-relationship-report', {
            parent: 'entity',
            params:{screen:'TradingBankList'},
            url: '/customer-bank-relationship-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.customer.customerBankRelationshipReport" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/customer-bank-relationship-report/customer-bank-relationship-report.html',
                    controller: 'CustomerBankRelationshipReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('customerBankRelationshipReport');
                    $translatePartialLoader.addPart('customer-registration');
                    return $translate.refresh();
                }],
                entity: function(){
                	
                },
                agreedTACList: ['TACViewForEntitiesService', 'GlobalValues', function(TACViewForEntitiesService, GlobalValues) {
                    return TACViewForEntitiesService.getTacAgreedDetailsForGivenCustomer({"customerId" : GlobalValues.getOrganisation().id}).$promise;
                }]
            }
        })
        .state('trading-bank-create', {
            parent: 'customer-bank-relationship-report',
            url: '/trading-bank',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/customer-bank-relationship-report/customer-trading-bank-dialog.html',
                    controller: 'CustomerTradingBankDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	    
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('customer-bank-relationship-report', null, { reload: 'customer-bank-relationship-report' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
       
       };
    })();