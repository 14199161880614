(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'transactionChargesSummaryView'
	})

	.controller('TransactionChargesSummaryViewController', TransactionChargesSummaryViewController);

	TransactionChargesSummaryViewController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
		'DateUtils','AlertService','$filter',   'TransactionChargesSummaryView','systemSettings','configSetting','previousSystemSettings','financialYears'];

	function TransactionChargesSummaryViewController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
			DateUtils,AlertService,$filter,  TransactionChargesSummaryView,systemSettings,configSetting,previousSystemSettings,financialYears) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRANSACTION_CHARGES_SUMMARY_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;

		$scope.noOfRecordsPerPageSummary = angular.copy($scope.noOfRecordsPerPage);
		$scope.pageSummary = 1;

		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.configSettings = configSetting;
		vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		vm.freshOrPrintOption = 'PrintGeneratedInvoice';
		vm.getTransactionChargesSummary = getTransactionChargesSummary;
		vm.exportDataToFile = exportDataToFile;
		vm.setDefaultValue = setDefaultValue;
		vm.getTransactionChargesSummaryDetails = getTransactionChargesSummaryDetails;
		vm.loadFinancialMonthOption = loadFinancialMonthOption;
		vm.clearAllValues = clearAllValues;
		vm.downloadEInvoice = downloadEInvoice;
		vm.filterData = {};
		vm.TransactionChargesfileName = "Transaction_Charges_Summary_Report_";
		vm.eInvoiceChargesfileName = "e-Invoice_";
		vm.fileName = vm.TransactionChargesfileName;
		vm.extension = "";
		vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
		vm.firstTimeLoad = null;
		vm.financialMonth = {};
		vm.previousFiancialMonthOptions = [];
		vm.transactionChargesSummarySelected = [];
		vm.transactionChargesSummaryDetails = null;
		vm.selectedBankName = "";
		vm.showDownloadEInvoice = false;
		vm.financialYear = vm.globalValues.getFinancialYear();
		vm.financialYearList=financialYears;
		logScreenAccess();
		vm.valid = false;
		vm.issuePresent = false;
		vm.clicked=false;

		if(vm.configSettings != null && vm.configSettings != undefined && vm.configSettings.noOfMOnthsForHistoricalReportsView != null && vm.configSettings.noOfMOnthsForHistoricalReportsView != undefined && !isNaN(vm.configSettings.noOfMOnthsForHistoricalReportsView)){
			vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		}
		else{
			vm.noOFMonthsToDisplayForReports = 10;
		}

		if(systemSettings != null && systemSettings != undefined && previousSystemSettings != null && previousSystemSettings != undefined){
			vm.currentBusinessDate = systemSettings.businessDate;
			vm.previousBusinessDate = previousSystemSettings.businessDate;
			vm.currentBusinessDateMoment = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.currentBusinessDate)));
			vm.previousBusinessDateMoment = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.previousBusinessDate)));
			vm.minDate =  angular.copy(vm.currentBusinessDateMoment).subtract(vm.noOFMonthsToDisplayForReports,'months').startOf('month').toDate();		
			loadFinancialMonthOption();
			setDefaultValue(vm.freshOrPrintOption);
		}


		function getTransactionChargesSummary(){
			vm.transactionChargesSummaryDetails = [];
			vm.transactionChargesSummarySelected = [];
			vm.valid = true;
			vm.eInvoiceDisable = false;
			vm.bankId = 0;
			if(vm.freshOrPrintOption == 'PrintGeneratedInvoice'&& (vm.bank==null || vm.bank== undefined|| vm.bank=='')){
				vm.showDownloadEInvoice = true;
			}else{
				vm.showDownloadEInvoice = false;
			}

			if(vm.bank!=null && vm.bank !=undefined){
				vm.bankId = angular.copy(vm.bank.id);
			}

			if(moment(vm.minDate).isAfter(vm.fromDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.transactionChargesSummary.fromDateLesserThanMinDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}else if(moment(vm.maxDate).isBefore(vm.fromDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.transactionChargesSummary.fromDateGreaterMaxDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(moment(vm.toDate).isAfter(vm.maxDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.transactionChargesSummary.toDateGreaterMaxDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(moment(vm.minDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.transactionChargesSummary.toDateLesserThanMinDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(moment(vm.fromDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.transactionChargesSummary.fromDateGreaterThanToDate'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else if(vm.fromDate.getMonth() != vm.toDate.getMonth()){
				AlertService.error($filter('translate')('gatewayApp.transactionChargesSummary.fromToNotSameMonth'));
				vm.valid = false;
				vm.issuePresent = true;
			}
			else{
				vm.issuePresent = false;
				vm.valid = true;
				vm.clicked=true;
				if(vm.bank != null && vm.bank != undefined){
					vm.selectedBankName = vm.bank.bankName;
				}else{
					vm.selectedBankName = '--All--';
				}
				vm.selectedPrintorRefresh = angular.copy(vm.freshOrPrintOption);
				clearAllValues();
				TransactionChargesSummaryView.getTransactionChargesSummaryReport({ bankId : vm.bankId,from : DateUtils.convertLocalDateToServer(vm.fromDate), to: DateUtils.convertLocalDateToServer(vm.toDate),printorGenerateAfreshOption : vm.selectedPrintorRefresh}, 
						onGetTransactionChargesSummarySuccess, onGetTransactionChargesSummaryFailure);  		
			}
			

		}
		function onGetTransactionChargesSummarySuccess(result){
			vm.transactionChargesSummaryDetails = result;
			vm.clicked=false;
		}
		function onGetTransactionChargesSummaryFailure(error){
			vm.clicked=false;
		}

		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		//this method is used to set default value to both the from and to date or financial month
		function setDefaultValue(value){

			if(value == 'PrintGeneratedInvoice'){
				vm.maxDate =  angular.copy(vm.previousBusinessDateMoment).endOf('month').toDate();//previous bdate
				vm.financialMonth = angular.copy(vm.previousFiancialMonthOptions[0]);
				vm.fromDate = angular.copy( vm.financialMonth.date).toDate();
				vm.toDate =angular.copy( vm.financialMonth.date).endOf('month').toDate();
				
				//vm.minToDate = angular.copy(vm.financialMonth.date).toDate();
				//vm.maxToDate =  angular.copy(vm.toDate);		

			}else if(value == 'GenerateAfresh'){
				vm.maxDate =  angular.copy(vm.previousBusinessDateMoment).toDate();//previous bdate
				vm.fromDate = angular.copy(vm.previousBusinessDateMoment).startOf('month').toDate();//prev
				vm.toDate =  angular.copy(vm.previousBusinessDateMoment).toDate();//prev
				
				//vm.minToDate = angular.copy(vm.fromDate);
				//vm.maxToDate =  angular.copy(vm.previousBusinessDateMoment).toDate();//prev
				
				

			}
			
		}

		function loadFinancialMonthOption(){
			vm.previousFiancialMonthOptions = [];
			if(vm.configSettings.billingEndDate!=null && vm.configSettings.billingEndDate !=""){
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment)));	//temporary
			}	
			var monthCount =1;
			for(monthCount =1; monthCount <= vm.noOFMonthsToDisplayForReports ;monthCount++) {
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment).subtract(monthCount,'month')))
			}

		}

		vm.createMonthOptions = createMonthOptions;
		function createMonthOptions(dateMoment){
			var currentMonthOption = {}
			currentMonthOption.date =  angular.copy(dateMoment).startOf('month');
			currentMonthOption.monthDisplay = dateMoment.format('MMM - YYYY');
			return currentMonthOption;

		}
		vm.onFinacialMonthSelection = onFinacialMonthSelection;
		function onFinacialMonthSelection(value){
			var monthSelected = value.date; 
			vm.fromDate = angular.copy(monthSelected).toDate();
			vm.toDate  = angular.copy(monthSelected).endOf('month').toDate();
			loadFinancialYearDisplay(vm.fromDate)
			//vm.minToDate = angular.copy(vm.financialMonth.date).toDate();
			//vm.maxToDate =  angular.copy(vm.toDate);

		}
       
 		function downloadEInvoice(){
			vm.mimeType = 'application/vnd.ms-excel';
			vm.extension = ".xls"; 
			vm.fileName = vm.eInvoiceChargesfileName;
		 TransactionChargesSummaryView.downloadEInvoiceExcel({ from : DateUtils.convertLocalDateToServer(vm.fromDate), to: DateUtils.convertLocalDateToServer(vm.toDate),financialYearId :vm.financialYear.id}, processResponse );


         }

		 function loadFinancialYearDisplay(value){
			var yearCount = 0;
			if(vm.financialYearList != null && vm.financialYearList != undefined){
				for(yearCount = 0;yearCount < vm.financialYearList.length; yearCount++){
					var fromYear =  moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYearList[yearCount].financialYearStart)));
					var toYear = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.financialYearList[yearCount].financialYearEnd))); 
					var financialMonth= moment(angular.copy(value)); 
					if( fromYear.isBefore(financialMonth)&&toYear.isAfter(financialMonth)){
						vm.financialYear=vm.financialYearList[yearCount];
						
					}else{
						vm.financialYear=vm.globalValues.getFinancialYear();
					}
				
			}
		}
	}

		//dowmload button
		//show condition when banck field is empty and option is print generate invoice
		// api call - get excel 
		// byte array convert to excel file
	

		function exportDataToFile(fileType){
			vm.fileName = vm.TransactionChargesfileName;
			if( vm.transactionChargesSummaryDetails !=null &&  vm.transactionChargesSummaryDetails != undefined && vm.transactionChargesSummaryDetails.headerList.length > 0){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv';
					vm.extension =  ".csv";  
					TransactionChargesSummaryView.exportTransactionChargesSummaryCsv({freshOrPrintOption:vm.selectedPrintorRefresh,bank:vm.selectedBankName },vm.transactionChargesSummaryDetails, processResponse );
				}
				else if(fileType == 'xls'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.extension = ".xls";  
					TransactionChargesSummaryView.exportTransactionChargesSummaryExcel({freshOrPrintOption:vm.selectedPrintorRefresh,bank:vm.selectedBankName },vm.transactionChargesSummaryDetails, processResponse );
				}
				else if(fileType == 'pdf'){
					vm.mimeType = 'application/pdf';
					vm.extension = ".pdf";  
					TransactionChargesSummaryView.exportTransactionChargesSummaryPdf({freshOrPrintOption:vm.selectedPrintorRefresh,bank:vm.selectedBankName },vm.transactionChargesSummaryDetails , processResponse );
				}

			}
		}

		function processResponse(result){        	  
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			vm.baseString =   vm.fileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 

			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}

		

		$scope.$watch('vm.fromDate',function(value){
			if(value!= undefined && value!=null && (vm.firstTimeLoad != null && vm.firstTimeLoad != undefined)){
				
				if(vm.freshOrPrintOption == 'PrintGeneratedInvoice'){					
					//vm.maxToDate =   moment(angular.copy(value)).endOf('month').toDate();
					//vm.minToDate = angular.copy(value);
					vm.toDate = moment(angular.copy(value)).endOf('month').toDate();
				}else{
					
					if(!moment(value).isAfter(vm.maxDate) && !moment(value).isBefore(vm.minDate)){
						if(vm.previousBusinessDateMoment.isAfter(moment(angular.copy(value)).endOf('month'))){
							
							//vm.maxToDate =  moment(angular.copy(value)).endOf('month').toDate();
							//vm.minToDate = angular.copy(value);
							vm.toDate = new Date (moment(angular.copy(value)).endOf('month').toDate());
							
						}else{
							
							//vm.maxToDate = angular.copy(vm.previousBusinessDateMoment).toDate();
							//vm.minToDate = angular.copy(vm.previousBusinessDateMoment).startOf('month').toDate();
							vm.toDate = new Date(angular.copy(vm.previousBusinessDateMoment).toDate());
							
						}
					}
				}

			}
			vm.eInvoiceDisable = true;
			vm.firstTimeLoad = 'No';
		});

		$scope.$watch('vm.freshOrPrintOption',function(value){
			if(value!= undefined && value!=null ){
				setDefaultValue(value);
				
			}
		
		});
		
		$scope.$watch('vm.financialMonth',function(value){
			if(value!= undefined && value!=null ){
				onFinacialMonthSelection(value);
			}
		
		});
		
		function clearAllValues(){
			vm.transactionChargesSummaryDetails = null;
			vm.transactionChargesSummarySelected = [];
			$scope.filterText = null;
			$scope.filterTextSummary = null;
		}
		


		function getTransactionChargesSummaryDetails(transactionChargesDetails){
			vm.transactionChargesSummarySelected = vm.transactionChargesSummaryDetails.bankWiseCustomerDetails[transactionChargesDetails.bankChargesDTO.bankName];
			vm.transactionChargesSummaryCharges = vm.transactionChargesSummaryDetails.detailsListGrandTotalCharges[transactionChargesDetails.bankChargesDTO.bankName];

		}
	}
})();