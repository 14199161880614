(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('upload-download-participant-certificate', {
            parent: 'entity',
            url: '/upload-download-participant-certificate',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.uploadDownloadCertificates.title" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/upload-download-participant-certificate/upload-download-participant-certificate.html',
                    controller: 'ParticipantCertificateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('uploadDownloadCertificates');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
          .state('upload-participant-certificate', {
            parent: 'entity',
            url: '/upload-participant-certificate',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.uploadDownloadCertificates.ccTitle" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/upload-download-participant-certificate/upload-download-participant-certificate.html',
                    controller: 'ParticipantCertificateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('uploadDownloadCertificates');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
        
         .state('participant-certificate-todolist', {
            parent: 'dashboard',
            url: '/participant-certificate-todo',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/upload-download-participant-certificate/participant-certificate-dialog.html',
                    controller: 'ParticipantCertificateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('upload-download-participant-certificate', null, { reload: 'upload-download-participant-certificate' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         
         };
    })();
