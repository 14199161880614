 (function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('change-settings', {
            parent: 'customer-specific-settings',
            url: '/change-settings/{headerLabel}/{labelName}/{settingsName}/{settingsValue}/{settingsType}/{screenName}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-settings/change-settings.html',
                    controller: 'ChangeSettingsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('customerSpecificSettings');
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
         .state('change-settings-auditlog', {
            parent: 'audit-log',
            url: '/change-settings-auditlog',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-settings/change-settings.html',
                    controller: 'ChangeSettingsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('customerSpecificSettings');
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('change-settings-historyreport', {
            parent: 'history-report',
            url: '/change-settings-historyreport',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-settings/change-settings.html',
                    controller: 'ChangeSettingsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('customerSpecificSettings');
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
        .state('customer-specific-settings-todolist', {
            parent: 'dashboard',
            url: '/customer-specific-settings-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/change-settings/change-settings.html',
                    controller: 'ChangeSettingsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                	$state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('customerSpecificSettings');
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer-specific-settings-todoList-resubmit', {
            parent: 'dashboard',
            url: '/customer-specific-settings-resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-settings/change-settings.html',
                    controller: 'ChangeSettingsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                        	

                        }
                    }
                }).result.then(function() {
                	$state.go('^', null, { reload: true });
                }, function() {
                	$state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('customerSpecificSettings');
                	 $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })

        
         };
    })();
