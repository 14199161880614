(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('CustomerBankRelationshipReport', CustomerBankRelationshipReport);

    CustomerBankRelationshipReport.$inject = ['$resource'];

    function CustomerBankRelationshipReport ($resource) {
        var resourceUrl =  'api/customer-bank-relationship-report/:id';

        return $resource(resourceUrl, {}, {
            
        	'query': { method: 'GET', isArray: true,
        	    url: 'api/all-trading-banks'
        	 },
            
            'getCustomerTradingBanks':{
            	 
            	isArray:true,
 				method: 'GET',
 				url: 'api/trading-bank-for-customer/:customerId/customer'
             },
             
             'getPendingAndRejected':{
            	 
             	isArray:true,
  				method: 'GET',
  				url: 'api/pending-rejected-request-for-customer/:customerId/customer'
              },
              'save' : {
  				method : 'POST',
  				params : {
  					customerId : '@customerId'
  				},
  				url : 'api/add-trading-bank/:customerId/customerId',
  				transformRequest : function(data) {
  					var copy = angular.copy(data);
  					return angular.toJson(copy);
  				}
  			},
			'delete' : {
				method : 'POST',
				params : {
					customerTradingBankDetailsId : '@customerTradingBankDetailsId',
					customerId:'@customerId'
				},
				url : 'api/delete-trading-bank/:customerTradingBankDetailsId/customer-trading-bank/:customerId/customerId'
			},
			'discard' : {
				method : 'GET',
				params : {
					requestId : '@requestId'
				},
				url : 'api/public/customer-onboarding-withdraw/:requestId'
			},
            'getTradingBanksForCustomer':{
           	 
            	isArray:true,
 				method: 'GET',
 				url: 'api/trading-bank-list-for-customer/:customerId/customer'
             },
             'getTradingBranchForCustomerAndBank':{
               	 
             	isArray:false,
  				method: 'GET',
  				url: 'api/trading-branch-for-customer-bank/:customerId/customer/:bankId/bank',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
              },
              'getValidTradingBranchForCustomerAndBank':{
                	 
               	isArray:false,
    				method: 'GET',
    				url: 'api/valid-trading-branch-for-customer-bank/:customerId/customer/:bankId/bank',
                  transformResponse: function (data) {
                      if (data) {
                          data = angular.fromJson(data);
                      }
                      return data;
                  }
                },
			'close' : {
				method : 'GET',
				params : {
					id : '@id'
				},
				url : 'api/customer-rejection-close/:id'
			},
			'getTradingBanksAllStatusForCustomer':{
	           	 
            	isArray:true,
 				method: 'GET',
 				url: 'api/trading-bank-list-all-status-for-customer/:customerId/customer'
             },
             
			 'excelCustomerBankRelationshipReport':{
				    method: 'POST',
	  				params : {
	  					customerId : '@customerId'
	  				},
	                url: 'api/customer-tradingbank-details/excel/:customerId/customer',
	                responseType: 'arraybuffer',
	                transformResponse: function(data, headers) {
	              	  return {
	                        data: data,
	                        headers: headers
	                    };   
	                } 
				},
				
				'csvCustomerBankRelationshipReport':{
					    method: 'POST',
		  				params : {
		  					customerId : '@customerId'
		  				},
		                url: 'api/customer-tradingbank-details/csv/:customerId/customer',
		                responseType: 'arraybuffer',
		                transformResponse: function(data, headers) {
		              	  return {
		                        data: data,
		                        headers: headers
		                    };   
		                } 
				},
				'getAllNonTerminatedCustomers':{
			           	 
			            	isArray:true,
			 				method: 'GET',
			 				params : {
			  					bankId : '@bankId',
			  					branchId : '@branchId'
			  				},
			 				url: 'api/all-customers-non-terminated'
			     },
			     'getAllNonPropNonTerminatedCustomers':{
			               	 
			             	isArray:true,
			  				method: 'GET',
			  				params : {
			  					bankId : '@bankId',
			  					branchId : '@branchId'
			  				},
			  				url: 'api/customers-non-prop-non-terminated'
			      },       
			      'getAllNonTerminatedNonSuspendedCustomers':{
              	 
			            	isArray:true,
			            	method: 'GET',
			            	params : {
				  					bankId : '@bankId',
				  					branchId : '@branchId'
			            	},
			            	url: 'api/all-customers-non-terminated-non-suspended'
			       }
			
              
            
             });
    }
})();
