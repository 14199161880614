(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {
            this.viewFrom = 'refreshLimit'
        })

        .controller('RefreshLimitController', RefreshLimitController);

        RefreshLimitController.$inject = ['$scope', '$state',  '$filter', 'GlobalValues','RefreshLimit','AlertService','IntradayMarket'];

    function RefreshLimitController($scope, $state, $filter,GlobalValues,RefreshLimit,AlertService,IntradayMarket) {
        
           var vm = this;
           vm.globalValues = GlobalValues;
           vm.bankId=0;
           vm.customerId=0;
           //vm.customerTypeList=['SingleCustomer','BankWiseCustomer','All'];
           vm.onRadioValueChange=onRadioValueChange;
           vm.systemSettings = vm.globalValues.getSystemSettings();
            vm.validateEodCompleted=validateEodCompleted;
            vm.status='TO_BE_INITIATED'
            vm.selectedType='customer'; //default customer type
            vm.submitRefreshLimit=submitRefreshLimit;
            vm.clicked=false;
            vm.cleaFilterValues=cleaFilterValues;
            setDisableAndRequiredControl(false,false, vm.emptyString,"");
            vm.allCustomer="--All--";
            vm.emptyString="";
            vm.isEODCompletedAndSessionIsOpen=false;
           vm.marketSessionStatus =true;
           vm.IsSessionMessageReceived;
        IntradayMarket.getMarketSessionSettings({ "currentBusinessDate": vm.systemSettings.businessDate }, onGetMarketSessionSuccess, onGetMarketSessionFailure);
        function onGetMarketSessionSuccess(result) {
            vm.marketSession = result;
            SplitInstrumetValue();
        }
        function onGetMarketSessionFailure() {
       
        }
        IntradayMarket.getMessagesInBCMQ(onGetMarketSessionMessageSuccess, onGetMarketSessionMessageFailure);
        function onGetMarketSessionMessageSuccess(result){
            vm.IsSessionMessageReceived=result;
        }
        function onGetMarketSessionMessageFailure(){

        }
        function SplitInstrumetValue() {
            angular.forEach(vm.marketSession, function (value) {
                if (value.sessionTimingsDTO.status == 'OPEN') {
                    vm.marketSessionStatus = false;
                }
            })
        }

            $scope.$watch('vm.selectedType', function(value){
                onRadioValueChange(value);
            });  


            //  user types changes when click radio button
            function onRadioValueChange(selectedType){
               
                 if(selectedType==='customer'){
                   setDisableAndRequiredControl(false,false, vm.emptyString,"");
                 cleaFilterValues(vm.emptyString,vm.emptyString);
                 vm.clicked=false;
                 vm.isEODCompletedAndSessionIsOpen=false;
                   
                }

                else if(selectedType=='bank'){
                    
        	        setDisableAndRequiredControl(false,true,vm.emptyString,vm.allCustomer);
                    validateEodCompleted();
                    cleaFilterValues(vm.emptyString,vm.emptyString);
                }

                else if(selectedType==='all'){
                    setDisableAndRequiredControl(true,true,vm.allCustomer, vm.allCustomer);
                    validateEodCompleted();
                    cleaFilterValues(vm.emptyString,vm.emptyString);                 

                }
            }

            
            //validate the eod is completed or not
            
           

            function validateEodCompleted(){
                if( vm.systemSettings.eodStatus===vm.status && vm.systemSettings.sodStatus===vm.status || vm.systemSettings.eodStatus===vm.status && vm.systemSettings.sodStatus==='COMPLETED'&& vm.marketSessionStatus && vm.IsSessionMessageReceived.IsSessionMessageReceived=="NOTRECEIVED"){
                    vm.clicked=false;   

                }
                else{
                    AlertService.error($filter('translate')('gatewayApp.refreshLimit.eodAndSessionOpenCompletionError'));
                    vm.clicked=true;
                    vm.isEODCompletedAndSessionIsOpen=true;
                }
            }


            
            //submitTheRefreshLimit when the submit button is click
            

            function submitRefreshLimit(){
                
                 
                 if(vm.selectedType==='customer'){
                    vm.bankId=vm.bank.id;
                    vm.customerId=vm.customer.id;
                  
                    RefreshLimit.ProcessRefreshLimits({"bankId":vm.bankId,"customerId":vm.customerId}, onRefreshLimitSucess, onRefreshLimitFailure);

                 }
                 else if(vm.selectedType==='bank')
                 {
                    vm.bankId=vm.bank.id;
                    RefreshLimit.ProcessRefreshLimtisForBankWiseCustomers({"bankId":vm.bankId}, onRefreshLimitSucess, onRefreshLimitFailure);
                 }

                 else if(vm.selectedType==='all'){
                    RefreshLimit.ProcessRefreshLimtisForAllCustomers(onRefreshLimitSucess, onRefreshLimitFailure);
                 }

                 else {
                    AlertService.error($filter('translate')('gatewayApp.refreshLimit.selectValidCustomerType'));
                 }
            }


            /**
             * 
             * @param {boolean} bankControl 
             * @param {boolean}} customerControl 
             *
             * @param {string} bankHolder 
             * @param {string} customerHolder 
             */

            function setDisableAndRequiredControl(bankControl,customerControl,bankHolder,customerHolder){
                vm.disableBankControl= bankControl;
                vm.disableCustomerControl= customerControl;
                vm.requiredBankControl=!bankControl;
                vm.requiredCustomerControl=!customerControl;
                vm.bankHolder=bankHolder;
                vm.customerHolder=customerHolder;
            }


            function onRefreshLimitSucess (result) {
               // AlertService.success(result);
                cleaFilterValues(vm.emptyString,vm.emptyString);
                vm.clicked = false;
           }
           
         
           function onRefreshLimitFailure (result) {
            // AlertService.error(result);
             vm.clicked = false;
           }


           
           //clear the filter values

           function cleaFilterValues(bankValue,customerValue){
            vm.bank=bankValue;             
            vm.customer=customerValue;
           }
}


})();