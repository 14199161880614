(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FaqEditController', FaqEditController);

    FaqEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','FAQService'];

     function FaqEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,FAQService) {
        var vm = this;
        
        vm.faq=$rootScope.faqfilteredbyclick;
        
        vm.editquestion=vm.faq.question;
        vm.editanswer=vm.faq.answer;
        vm.editvideofilename=vm.faq.videofilename;
        vm.edittemplatefilename=vm.faq.templatefilename;
        vm.editcategorylist=vm.faq.categorylist;
        
        vm.faqcategorylistfromJSONFile = $rootScope.faqcategorylistfromJSONFile;

        vm.faqdetailsfromJSONFile = $rootScope.faqdetailsfromJSONFile;
        
        vm.hidden_videofilename ="";        
        vm.originalvideofilename = vm.faq.videofilename;

        vm.indexnumber = $rootScope.indexnumber;
        getcurrentindexofFAQ();
        
        vm.VideoFileNametodelete = "";
        vm.TemplateFileNametodelete = "";
        
        function getcurrentindexofFAQ(){
        	
        	var data = vm.faqdetailsfromJSONFile;
        	var filteredObj = data.find(function(item, i){
        		  if(item.index === vm.indexnumber){
        			  vm.currentindex = i;
        			  return i;
        		  }
              });
        	
        }

        //vm.clear=clear;
        $scope.clicked=false;
        $scope.showButton = false;
        
        $scope.showButton = false;
        
        
        if(workflowSharedService.viewFrom == 'faq'){
        	
       	 	$scope.showButton=false;
        }

        
        vm.clear = function clear () {
       	 $rootScope.$emit('gatewayApp:reloadFAQPage');
            $uibModalInstance.dismiss('cancel');
        }
        
     
        function onSaveSuccess (result) {
        	 $rootScope.$emit('gatewayApp:reloadFAQPage');
        	 $uibModalInstance.dismiss('close');  
        }

        function onSaveError () {
        	
        }

        function saveJSON() {
        	//if (vm.VideoFileNametodelete != "") { vm.deleteFilesFromS3bucket("videos", vm.VideoFileNametodelete , "mp4");}
        	//if (vm.TemplateFileNametodelete != "") {  vm.deleteFilesFromS3bucket("templates", vm.TemplateFileNametodelete , "xlsx"); }
     	       	
        	//alert(vm.faqdetailsfromJSONFile[vm.currentindex].answer);
//        	if (document.getElementById("field_VideoFileName").value != "") { vm.uploadvideo(); }
//        	if (document.getElementById("field_TemplateFileName").value != "") { vm.uploadtemplate(); }
        	
        	if (vm.editvideofilename != null && vm.editvideofilename != "") { vm.uploadvideo(); }
        	if (vm.edittemplatefilename != null && vm.edittemplatefilename != "") { vm.uploadtemplate(); }
        	
        	var JSONText = angular.toJson(vm.faqdetailsfromJSONFile);
        	FAQService.uploadJSONFile({"type": "faq", "fileName": "faqdetailsjson.txt", "fileType": "txt"},JSONText,onSaveSuccess, onSaveError);
        }
        
        vm.uploadvideo= function() {
        	//alert("uploadvideo");
        	var fd = new FormData();
            fd.append("file", vm.videofileforupload);
            FAQService.uploadCommonFiles({"type" : "faq", "subfolder" : "videos"}, fd);
        }
        
        vm.uploadtemplate= function() {
        	//alert("uploadtemp");
        	var fd = new FormData();
            fd.append("file", vm.templatefileforupload);
            FAQService.uploadCommonFiles({"type" : "faq", "subfolder" : "templates"}, fd);
            //alert("uploadtemp end ");
        }
        
        function ResetFileNames(){
        	
        	if (vm.editis_video_available == false) {
        		vm.editvideofilename = ' ';}
        	else{
        		vm.editvideofilename = document.getElementById("field_VideoFileName").files[0].name;
        	}
        	
        	if (vm.editis_template_available == false) {
        		vm.edittemplatefilename = ' ';}
        	else{
        		vm.edittemplatefilename = document.getElementById("field_TemplateFileName").files[0].name;
        	}
        	
        }                
             
            $scope.uploadVideoFile = function () {                
                if (document.getElementById("field_VideoFileName").value != "") {
                	vm.hidden_videofilename =document.getElementById("field_VideoFileName").files[0].name;
                }
                else
                	{
                	vm.hidden_videofilename ="";
                	}
                vm.editvideofilename = vm.hidden_videofilename;                
            }
            
            $scope.uploadTemplateFile = function () {                
                if (document.getElementById("field_TemplateFileName").value != "") {
                	vm.edittemplatefilename =document.getElementById("field_TemplateFileName").files[0].name;
                }
                else
                	{
                	vm.edittemplatefilename ="";
                	}
            }
        
        	
        	vm.edit = function(){
        		faqfinishEdit();
        	}
        	
        	function faqfinishEdit() {
            	
        		//alert(vm.editquestion);        		        		       		
        		vm.faqdetailsfromJSONFile[vm.currentindex].question = vm.editquestion;
        		vm.faqdetailsfromJSONFile[vm.currentindex].answer = vm.editanswer;
            	            	
        		vm.faqdetailsfromJSONFile[vm.currentindex].videofilename = vm.editvideofilename;
            	            	
        		vm.faqdetailsfromJSONFile[vm.currentindex].templatefilename = vm.edittemplatefilename;
        		vm.faqdetailsfromJSONFile[vm.currentindex].categorylist = vm.editcategorylist;            	
            	
                saveJSON();
                //alert("end edit");
                }
        	
        	vm.deleteFilesFromS3bucket = function deleteFilesFromS3bucket(subfolder, filename, filetype){	       
	        	 //alert(filename);	                 
	             FAQService.deleteFiles({"type": "faq", "subfolder":subfolder, "fileName": filename, "fileType": filetype});
	             //alert("delete completed");
	         }
       	
       	
        	vm.RemoveVideo= function RemoveVideo() {
       		 	vm.VideoFileNametodelete =  vm.editvideofilename;        		
            	if (vm.VideoFileNametodelete != "") { vm.deleteFilesFromS3bucket("videos", vm.VideoFileNametodelete , "mp4");}
       		 	//alert("RemoveVideo");
             	document.getElementById("field_VideoFileName").value = "";
             	vm.editvideofilename = "";
             	//alert("RemoveVideo end");
             }

       	  vm.RemoveTemplate= function RemoveTemplate() {
       		  
       		  vm.TemplateFileNametodelete =  vm.edittemplatefilename;  
          	if (vm.TemplateFileNametodelete != "") {  vm.deleteFilesFromS3bucket("templates", vm.TemplateFileNametodelete , "xlsx"); }
               	//alert("RemoveTemplate");
               	document.getElementById("field_TemplateFileName").value = "";
               	vm.edittemplatefilename = "";
               	//alert("RemoveTemplate end");
               }        	  
       	      	  
        	  
     }   
})();
