
    (function() {
        'use strict';
    
        angular
            .module('gatewayApp')
            .config(stateConfig);
    
        stateConfig.$inject = ['$stateProvider'];
    
        function stateConfig($stateProvider) {
            $stateProvider
            .state('refresh-limit', {
                parent: 'entity',
                params:{screen: 'RefreshLimit'}, 
                url: '/refresh-limit',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'global.title'
                },
                ncyBreadcrumb: {
                    label: '{{ "global.menu.refreshLimit.main" | translate }}',
                    parent: 'home'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/refresh-limit/refresh-limit.html',
                        controller: 'RefreshLimitController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('refreshLimit');                    
                        $translatePartialLoader.addPart('error');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                   
                }
            })
            
             
             };
        })();