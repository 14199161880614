

(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manage-organisation', {
            parent: 'entity',
            url: '/manage-organisation',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.organisation.manageorganisations" | translate }}',
                parent: 'home'
              },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manage-organisation/manage-organisation.html',
                    controller: 'ManageOrganisationController',
                    controllerAs: 'vm',
                    resolve: {
                         	entity:['Organisation', 'GlobalValues','$sessionStorage', function(Organisation, GlobalValues, $sessionStorage) {
                                 return $sessionStorage.userorganisation;
                             }]
                             }
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('organisation');
                    $translatePartialLoader.addPart('corporate');
                    $translatePartialLoader.addPart('consultant');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('manage-organisation.edit', {
            parent: 'manage-organisation',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-organisation/organisation-edit-dialog.html',
                    controller: 'OrganisationEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity:['Organisation','$sessionStorage', function(Organisation,$sessionStorage) {
                            
                    		if($sessionStorage.userorganisation.organisationType.shortName=="CORPORATE"){
                    			return Organisation.getCorporate({id:$stateParams.id}).$promise;
                    		}
                    		else if($sessionStorage.userorganisation.organisationType.shortName=="CONSULTANT"){
                    			return Organisation.getConsultant({id:$stateParams.id}).$promise;
                    		}
                    		else 
                    			return null;
                    		
                        }]
                        }
                    
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-organisation-todolist.resubmit', {
            parent: 'dashboard',
            url: '/organisationresubmit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-organisation/organisation-edit-dialog.html',
                    controller: 'OrganisationEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity:{
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
          .state('manage-organisation-todolist', {
            parent: 'dashboard',
            url: '/organisationview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-organisation/organisation-view-dialog.html',
                    controller: 'OrganisationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity:{
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('manage-organisation.view', {
            parent: 'manage-organisation',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-organisation/organisation-view-dialog.html',
                    controller: 'OrganisationViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                    	entity:['Organisation','$sessionStorage', function(Organisation,$sessionStorage) {
                            
                    		if($sessionStorage.userorganisation.organisationType.shortName=="CORPORATE"){
                    			return Organisation.getCorporate({id:$stateParams.id}).$promise;
                    		}
                    		else if($sessionStorage.userorganisation.organisationType.shortName=="CONSULTANT"){
                    			return Organisation.getConsultant({id:$stateParams.id}).$promise;
                    		}
                    		else 
                    			return null;
                    		
                        }]
                        }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]            
        })
       

        };
    })();
