(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ClearCorpLimitUtilisedReportService', ClearCorpLimitUtilisedReportService);

    ClearCorpLimitUtilisedReportService.$inject = ['$resource'];

    function ClearCorpLimitUtilisedReportService($resource) {
        var resourceUrl =  'api/clearcorp-limits-reports/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getLimitsForReport':{
          		isArray:true,
 				method: 'GET',
 				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'
 				},
 				url: 'api/clearcorp-limit-utilisation-report-details'
           },
           'exportLimitsExcel':{
           	 method: 'POST',
                url: 'api/clearcorp-limit-utilisation-report-details/excel-download',
                responseType: 'arraybuffer',
                params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'					
                },
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }      
           },
           'exportLimitsCsv':{
            	 method: 'POST',
                 url: 'api/clearcorp-limit-utilisation-report-details/csv-download',
                 responseType: 'arraybuffer',
                 params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'						
                 },
                 transformRequest: function (data) {
                    	var copy = angular.copy(data);
                    	
                        return angular.toJson(copy);
                 },
                 transformResponse: function(data, headers) {
               	  return {
                         data: data,
                         headers: headers
                     };   
                 }      
            },
            'exportLimitsPdf':{
             	 method: 'POST',
                  url: 'api/clearcorp-limit-utilisation-report-details/pdf-download',
                  responseType: 'arraybuffer',
                  params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'
				  },
				  transformRequest: function (data) {
	                   	var copy = angular.copy(data);
	                   	
	                       return angular.toJson(copy);
	              },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }      
             }
        });
    }
   })();
