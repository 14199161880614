(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('EmailCategory', EmailCategory);

    EmailCategory.$inject = ['$resource'];

    function EmailCategory ($resource) {
        var resourceUrl =  'api/email-category/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getEmailCategoryForEntity': {
                method: 'GET',
                params: {entityOwnerTypeId:'@entityOwnerTypeId'},
                isArray: true,
                url: 'api/email-category-for-entity/entityOwnerTypeId/:entityOwnerTypeId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
