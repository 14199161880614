(function() {
	'use strict';
	angular.module('gatewayApp').factory('CustomerSpecificSettings', CustomerSpecificSettings);

	CustomerSpecificSettings.$inject = [ '$resource' ];

	function CustomerSpecificSettings($resource) {
		var resourceUrl = 'api/customerSpecificSettings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				 params: {customerId: '@customerId'},
				url : 'api/get-customer-specific-settings',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}
		});
	}
})();
