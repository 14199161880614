(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var resourceUrl =  'api/users-for-organisation';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        	    url: 'api/users-for-organisation'
        	 },
        	 'get': {
                 method: 'GET',
                 params: {userId:'@userId'},
                 url: 'api/user/:userId',
                 isArray:false,
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
        	 'getUsersByOrganisationAndEntityOwnerType': {
                 method: 'GET',
                 params: {orgId:'@orgId', entityOwnerType:'@entityOwnerType'},
                 url: 'api/users-by-organisation/:orgId/entity-owner-type/:entityOwnerType',
                 isArray:true,
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
        	 'getUsersByBranch': {
                 method: 'GET',
                 params: {branchId:'@branchId'},
                 url: 'api/users-by-branch/:branchId',
                 isArray:true,
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'saveClearance': {
                 method: 'POST',
                 params: {orgId:'@orgId'},
                 url: 'api/orgId/:orgId/users-clearance',
                 transformRequest: function (data) {
                 	
                 	var copy =angular.toJson(data);
                 	
                     return copy;
                 }                
             },
             'updateSessionLog':{
                 method: 'POST',
                 url: 'api/update-session-log',
             },
             'insertSessionLog':{
                 method: 'POST',
                 url: 'api/insert-session-log',
             },
             'getClearance': {
  	            method: 'GET',
  	            params: {userId:'@userId'},
  	            url: 'api/user-clearance/:userId',
  	            transformResponse: function (data) {
  	                if (data) {
  	                    data = angular.fromJson(data);
  	                }
  	                return data;
  	            }
          	},
             'mapRole': {
                 method: 'POST',
                 url: 'api/users-role',
                 transformRequest: function (data) {
                 
                 	var copy =angular.toJson(data);
                 	
                     return copy;
                 }                
             },
             
         	'getRolesForUser': {
 	            method: 'GET',
 	            params: {userId:'@userId'},
 	            url: 'api/users-role/:userId',
 	            transformResponse: function (data) {
 	                if (data) {
 	                    data = angular.fromJson(data);
 	                }
 	                return data;
 	            }
         	},
         	
         	'getUserClearance': {
 	            method: 'GET',
 	            params: {userId:'@userId'},
 	            url: 'api/users-clearance/:userId',
 	            transformResponse: function (data) {
 	                if (data) {
 	                    data = angular.fromJson(data);
 	                   
 	                }
 	                return data;
 	            }
         	},
         	'fetchUserByPersonalId': {
 	            method: 'GET',
 	            params: {personalId:'@personalId'},
 	            url: 'api/fetch-user-for-personal-id/:personalId',
 	            transformResponse: function (data) {
 	                if (data) {
 	                    data = angular.fromJson(data);
 	                   
 	                }
 	                return data;
 	            }
         	},
         	'resubmitClearance': {
                method: 'PUT',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/resubmit/user-clearance',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                	return angular.toJson(copy);
                }
            },
         	 'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'resubmit': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmit/user-roles',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             },

             'discard': {
            	 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/discard'
            },
            'mapOrganisation':{
            	method:'PUT',
            	url: 'api/users/map-organisation'
            },
            'getOrganisationByAlias': {
            	method: 'GET',
            	params: {aliasName:'@aliasName'},
            	url: 'api/get-org-by-alias/:aliasName'
            },
            'save': {
                method: 'POST',
                params: {orgId:'@orgId'},
                url: 'api/users/:orgId',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
            'update': {
            	method:'PUT',
            	params: {orgId:'@orgId'},
                url: 'api/users/:orgId',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
            'delink': {
                method: 'PUT',
                params: {id:'@id'},
                url: 'api/delink-user/:id',              
                    transformRequest: function (data) {
                       	var copy = angular.copy(data);
                           return angular.toJson(copy);
                       }
            },
            'resubmitUserManagement': {
                method: 'PUT',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/resubmit/usermanagement',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                	return angular.toJson(copy);
                }
            },
            'getPID': {
            	method: 'GET',
            	isArray: true,
            	params: {firstName:'@firstName',dob:'@dob',aadhar:'@aadhar'},
            	url: 'api/get-pid/:firstName/:lastName/:dob/:pincode'
            },
            'getUsersForOrganisation': {
                method: 'GET',
                params: {orgId:'@orgId'},
                url: 'api/users-by-organisation/:orgId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUsersExceptDelinkedForOrganisation': {
                method: 'GET',
                params: {orgId:'@orgId'},
                url: 'api/users-by-organisation-except-delinked/:orgId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUsersExceptDelinkedForBranch': {
                method: 'GET',
                params: {branchId:'@branchId'},
                url: 'api/all-users-except-delinked-for-branch/:branchId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getNonDelinkedUsersForBranch':{
            	method: 'GET',
                params: {bankId:'@bankId',branchId:'@branchId'},
                url: 'api/all-non-delinked-users-for-branch/:bankId/:branchId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUsersForBranch': {
                method: 'GET',
                params: {branchId:'@branchId'},
                url: 'api/users-for-branch/:branchId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUsersByOrganisationAndEntityOwnerTypeForRestriciton': {
                method: 'GET',
                params: {orgId:'@orgId', entityOwnerType:'@entityOwnerType'},
                url: 'api/users-by-organisation-for-restriction/:orgId/entity-owner-type/:entityOwnerType',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUsersForOrganisationForRestriction': {
                method: 'GET',
                params: {orgId:'@orgId'},
                url: 'api/users-by-organisation-for-restriction/:orgId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },'getUsersForBranchRestriction': {
                method: 'GET',
                params: {branchId:'@branchId'},
                url: 'api/users-for-branch-restriciton/:branchId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
			'checkPassword' : {
				method : 'POST',
				url : 'api/user-profile/check-password',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'getUsersByOrganisationAndEntityOwnerTypeTurnOverReport': {
                method: 'GET',
                params: {orgId:'@orgId', entityOwnerType:'@entityOwnerType'},
                url: 'api/users-by-org-entityowner-turnover-report/:orgId/entity-owner-type/:entityOwnerType',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUsersForOrganisationTurnOverReport': {
                method: 'GET',
                params: {orgId:'@orgId'},
                url: 'api/users-by-organisation-turnover-report/:orgId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },'getUsersForBranchTurnOverReport': {
                method: 'GET',
                params: {branchId:'@branchId',bankId:'@bankId'},
                url: 'api/users-for-branch-turnover-report/:bankId/:branchId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllUsersByOrganisationAndEntityOwnerType': {
                method: 'GET',
                params: {orgId:'@orgId', entityOwnerType:'@entityOwnerType'},
                url: 'api/all-users-by-org-entityowner/:orgId/entity-owner-type/:entityOwnerType',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllUsersForOrganisation': {
                method: 'GET',
                params: {orgId:'@orgId'},
                url: 'api/all-users-by-organisation/:orgId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },'getAllUsersForBranch': {
                method: 'GET',
                params: {branchId:'@branchId',bankId:'bankId'},
                url: 'api/all-users-for-branch/:bankId/:branchId',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
