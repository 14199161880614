(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.service("workflowSharedService", function () {
			this.viewFrom = 'otrSummaryView'
		})

		.controller('OTRSummaryForBankViewController', OTRSummaryForBankViewController);

	OTRSummaryForBankViewController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog', 'entityConstants',
		'DateUtils', 'AlertService', '$filter', 'systemSettings', 'configSetting', 'OTRSummaryForBankView'];

	function OTRSummaryForBankViewController($scope, $state, GlobalValues, workflowSharedService, EntityPermissions, ScreenLog, entityConstants,
		DateUtils, AlertService, $filter, systemSettings, configSetting, OTRSummaryForBankView) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.OTR_SUMMARY_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;

		$scope.noOfRecordsPerPageSummary = angular.copy($scope.noOfRecordsPerPage);
		$scope.pageSummary = 1;
		vm.clicked=false;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.configSettings = configSetting;
		vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		vm.exportDataToFile = exportDataToFile;
		vm.setDefaultValue = setDefaultValue;
		vm.loadFinancialMonthOption = loadFinancialMonthOption;
		vm.clearAllValues = clearAllValues;
		vm.filterData = {};
		vm.otrSummaryfileName = "OTR_Summary_Report_";
		vm.fileName = vm.otrSummaryfileName;
		vm.extension = "";
		vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
		vm.firstTimeLoad = null;
		vm.financialMonth = {};
		vm.previousFiancialMonthOptions = [];
		vm.otrSummarySelected = [];
		vm.showNoRecordsFound = false;
		vm.financialYear = vm.globalValues.getFinancialYear();
		vm.otrSummaryDetails = [];
		vm.getOTRSummaryForBank = getOTRSummaryForBank;
		vm.OtrSummaryForBank = vm.globalValues.getBank();
		vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
		vm.otrSummarygrossCharges = 0;
		vm.isCurrenMonth = true;
		vm.OtrSummaryDate=true;
		logScreenAccess();
		vm.valid = false;
		if (vm.configSettings != null && vm.configSettings != undefined && vm.configSettings.noOfMOnthsForHistoricalReportsView != null && vm.configSettings.noOfMOnthsForHistoricalReportsView != undefined && !isNaN(vm.configSettings.noOfMOnthsForHistoricalReportsView)) {
			vm.noOFMonthsToDisplayForReports = vm.configSettings.noOfMOnthsForHistoricalReportsView;
		}
		else {
			vm.noOFMonthsToDisplayForReports = 2;
		}

		if (systemSettings != null && systemSettings != undefined) {
			vm.currentBusinessDate = systemSettings.businessDate;
			vm.currentBusinessDateMoment = moment(DateUtils.convertLocalDateFromServer(angular.copy(vm.currentBusinessDate)));
			//vm.maxDate =  angular.copy(vm.currentBusinessDateMoment).toDate();
			vm.minDate = angular.copy(vm.currentBusinessDateMoment).subtract(vm.noOFMonthsToDisplayForReports, 'months').startOf('month').toDate();
			loadFinancialMonthOption();
			setDefaultValue();
		}


		function getOTRSummaryForBank() {
			vm.bankId = vm.OtrSummaryForBank.id
			vm.otrSummarySelected = [];
			vm.valid = true;
			vm.branchId = 0;

			if (vm.bank != null && vm.bank != undefined) {
				vm.bankId = angular.copy(vm.bank.id);
			}
			if (vm.userEntityOwnerType == 'Branch') {
				vm.branchId = vm.globalValues.getBranchId();
			}
			if (moment(vm.minDate).isAfter(vm.fromDate, 'day')) {
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromDateLesserThanMinDate'));
				vm.valid = false;
			} else if (moment(vm.maxDate).isBefore(vm.fromDate, 'day')) {
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromDateGreaterMaxDate'));
				vm.valid = false;
			}
			else if (moment(vm.toDate).isAfter(vm.maxDate, 'day')) {
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.toDateGreaterMaxDate'));
				vm.valid = false;
			}
			else if (moment(vm.minDate).isAfter(vm.toDate, 'day')) {
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.toDateLesserThanMinDate'));
				vm.valid = false;
			}
			else if (moment(vm.fromDate).isAfter(vm.toDate, 'day')) {
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromDateGreaterThanToDate'));
				vm.valid = false;
			}
			else if (vm.fromDate.getMonth() != vm.toDate.getMonth()) {
				AlertService.error($filter('translate')('gatewayApp.otrSummaryReport.fromToNotSameMonth'));
				vm.valid = false;
			}
			clearAllValues();
			vm.clicked=true;
			OTRSummaryForBankView.getOTRSummaryForBankReport({ bankId: vm.bankId, branchId: vm.branchId, fromDate: DateUtils.convertLocalDateToServer(vm.fromDate), toDate: DateUtils.convertLocalDateToServer(vm.toDate) },
				onGetOTRSummaryForBankSuccess, onGetOTRSummaryForBankFailure);


		}
		function onGetOTRSummaryForBankSuccess(result) {
			vm.otrSummaryDetails = result;
			totalGrossCharges();
			vm.clicked=false;
		}
		function onGetOTRSummaryForBankFailure(error) {
			vm.clicked=false;
		}

		function totalGrossCharges() {
			if (vm.otrSummaryDetails != null && vm.otrSummaryDetails != undefined) {
				var otrSummaryDetailsCount;
				vm.otrSummaryCharges = 0;
				for (otrSummaryDetailsCount = 0; otrSummaryDetailsCount <= vm.otrSummaryDetails.length -1; otrSummaryDetailsCount++) {
					vm.otrSummaryCharges = vm.otrSummaryCharges + vm.otrSummaryDetails[otrSummaryDetailsCount].chargeDetails.grossCharges;
				}
			}
		}

		function logScreenAccess() {
			ScreenLog.screenLog(vm.screenName, 0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		//this method is used to set default value to both the from and to date or financial month
		function setDefaultValue() {


			/*vm.selectedFinancialMonth  = vm.previousFiancialMonthOptions[0];*/
			vm.financialMonth = angular.copy(vm.previousFiancialMonthOptions[0]);
			vm.fromDate = angular.copy(vm.financialMonth.date).toDate();
			vm.toDate = angular.copy(vm.financialMonth.date).endOf('month').toDate();
			vm.maxDate = angular.copy(vm.currentBusinessDateMoment).endOf('month').toDate();//previous bdate	//temporary
			//vm.minToDate = angular.copy(vm.financialMonth.date).toDate();
			//vm.maxToDate =  angular.copy(vm.toDate);

		}
		function loadFinancialMonthOption() {
			vm.previousFiancialMonthOptions = [];
			vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment))); //allways dispaly in current month
			var monthCount = 1;
			for (monthCount = 1; monthCount <= vm.noOFMonthsToDisplayForReports; monthCount++) {
				vm.previousFiancialMonthOptions.push(createMonthOptions(angular.copy(vm.currentBusinessDateMoment).subtract(monthCount, 'month')))
			}

		}
		vm.createMonthOptions = createMonthOptions;
		function createMonthOptions(dateMoment) {
			var currentMonthOption = {};
			currentMonthOption.date = angular.copy(dateMoment).startOf('month');
			currentMonthOption.monthDisplay = dateMoment.format('MMM - YYYY');
			return currentMonthOption;

		}
		vm.onFinacialMonthSelection = onFinacialMonthSelection;
		function onFinacialMonthSelection(value) {
			var monthSelected = value.date; 
			vm.fromDate = angular.copy(monthSelected).toDate();	
			var financialdate = angular.copy(monthSelected).toDate();
			var currentdate = angular.copy(vm.currentBusinessDateMoment).toDate();
			if (financialdate.getMonth() == currentdate.getMonth()) {
				vm.isCurrenMonth = false;
				vm.toDate  = angular.copy(vm.currentBusinessDateMoment).toDate();
			
			} else {
				vm.isCurrenMonth = true;
				vm.toDate  = angular.copy(monthSelected).endOf('month').toDate();
			}
		}
		function exportDataToFile(fileType) {
			if (vm.otrSummaryDetails != null && vm.otrSummaryDetails != undefined && vm.otrSummaryDetails.length > 0) {
				if (fileType == 'csv') {
					vm.mimeType = 'text/csv';
					vm.extension = ".csv";
					OTRSummaryForBankView.exportOTRSummaryForBankCsv({ bankId: vm.bankId, branchId: vm.branchId, fromDate: DateUtils.convertLocalDateToServer(vm.fromDate), toDate: DateUtils.convertLocalDateToServer(vm.toDate) }, processResponse);
				}
				else if (fileType == 'xls') {
					vm.mimeType = 'application/vnd.ms-excel';
					vm.extension = ".xls";
					OTRSummaryForBankView.exportOTRSummaryForBankExcel({ bankId: vm.bankId, branchId: vm.branchId, fromDate: DateUtils.convertLocalDateToServer(vm.fromDate), toDate: DateUtils.convertLocalDateToServer(vm.toDate) }, processResponse);
				}
			}
		}
		function processResponse(result) {
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");
			var bytes = new Uint8Array(fileData);   // convert uint8 to binary array from arraybuffer
			vm.baseString = vm.fileName + moment().format(vm.reportFileTimestampFormat) + vm.extension;

			var blob = new Blob([bytes], { type: vm.mimeType });
			if (window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString);
			} else {
				link.href = window.URL.createObjectURL(blob);
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		$scope.$watch('vm.financialMonth', function (value) {
			if (value != undefined && value != null) {
				onFinacialMonthSelection(value);
			}

		});


		function clearAllValues() {
			vm.otrSummaryDetails = null;
			vm.otrSummarySelected = [];
			$scope.filterText = null;
			$scope.filterTextSummary = null;
		}
	}
})();