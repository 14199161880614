(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SpecialHolidaysDeleteController',SpecialHolidaysDeleteController);

    SpecialHolidaysDeleteController.$inject = ['$uibModalInstance', 'entity', 'SpecialHolidays', 'GlobalValues'];

    function SpecialHolidaysDeleteController($uibModalInstance, entity, SpecialHolidays, GlobalValues) {
        var vm = this;

        vm.specialHolidays = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        function confirmDelete (id) {
        	SpecialHolidays.delete({"orgId": GlobalValues.getOrgId(),"id":id},
                    function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
