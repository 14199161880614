(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('StringUtils', StringUtils);

    StringUtils.$inject = ['ArrayUtils'];

    function StringUtils(ArrayUtils) {

        return {
            isBlank: isBlank,
            truncate: truncate,
            getMaximumLength: getMaximumLength,
            getValid: getValid,
            trim: trim,
            includes : includes,
            toUpperCase: toUpperCase,
            toLowerCase: toLowerCase,
            isString: isString,
            parseBoolean: parseBoolean,
            isEqual: isEqual
        };

        /**
         * Check If String is Blank
         * <code>
         *     isBlank(undefined) // true
         *     isBlank(null) // true
         *     isBlank(1) // true
         *     isBlank("") // true
         *     isBlank(" ") // true
         *     isBlank("Test") // false
         *     isBlank(" Test ") // false
         * </code>
         * @param   string          Test String
         * @return  true or false   Boolean
         */
        function isBlank(string) {
            return (string == undefined || string == null || !angular.isString(string) || string.trim() == "");
        }

        function getValid(string, defaultString) {
            return !isBlank(string) ? string : defaultString;
        }

        /**
         * Truncate the input string upto given maximum length
         * <code>
         *     truncatedString = truncate("Test", 2); // "Te"
         * </code>
         * @param   string          Input String
         * @param   maxLength       Maximum length
         * @return  returnString    Truncated String
         */
        function truncate(string, maxLength) {
            var returnString = string;
            if (!isBlank(string)) {
                if (string.length > maxLength) {
                    returnString = string.substr(0, maxLength) + '...';
                }
            }
            return returnString;
        }

        /**
         * Get Maximum length of a string property in an array of objects
         * <code>
         *     maxLength = getMaximumLength([{ 'businessUnit': { 'shortName': 'PLANO' }}, 
                                             { 'businessUnit': { 'shortName': 'KOHINOOR' }}], 'businessUnit.shortName'); // 8
         * </code>
         * @param   objectArray     Array of objects
         * @param   property        Property - Object path
         * @return  maxLength       Maximum length of given property
         */
        function getMaximumLength(objectArray, property) {
            var maxLength = 0;
            if (!ArrayUtils.isEmpty(objectArray)) {
                angular.forEach(objectArray, function(object, index) {
                    var evaluatedString = isBlank(_.get(object.model, property)) ? "" : _.get(object.model, property);
                    if (index == 0) {
                        maxLength = evaluatedString.length;
                    } else {
                        if (evaluatedString.length > maxLength) {
                            maxLength = evaluatedString.length;
                        }
                    }
                });
            }
            return maxLength;
        }

        function trim(string) {
            var trimmedString = ""
            if(!isBlank(string)) {
                trimmedString = string.replace(/^\s+|\s+$/gm, '');
            }
            return trimmedString;
        }
        
        /**
         * Check whether given value present in String
         */
        function includes(collection, value) {        	
        	return _.includes(collection, value);
        }

        function toUpperCase(value) {
            if (isBlank(value)) return "";
            return value.toUpperCase();
        }
        
        function toLowerCase(value) {
            if (isBlank(value)) return "";
            return value.toLowerCase();
        }

        function isString(value) {
            return angular.isString(value);
        }

        function parseBoolean(booleanString) {
            return !isBlank(booleanString) && trim(toLowerCase(booleanString)) === "true";
        }
        
        function isEqual(string1, string2){
        	return (string1 == string2);
        }
    }
})();
