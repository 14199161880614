(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('BankFeeAndChargesUploadController', BankFeeAndChargesUploadController)

	BankFeeAndChargesUploadController.$inject = ['ScreenLog','$scope', '$state', '$rootScope', 'GlobalValues','BankFeeAndChargesUploadService', 'workflowSharedService', 'entityConstants','TacViewForEntitiesUtility','CustomerTACUploadByBank','$timeout'];

	function BankFeeAndChargesUploadController(ScreenLog,$scope, $state, $rootScope, GlobalValues,BankFeeAndChargesUploadService,workflowSharedService,entityConstants,TacViewForEntitiesUtility,CustomerTACUploadByBank,$timeout) {
		var vm = this;
		vm.bankFeeChargeDetails = {};
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.BANK_FEES_CHARGES_UPLOAD;
		vm.globalValues=GlobalValues;
		$scope.refresh=refresh;
		vm.orgId = vm.globalValues.getOrgId();
		console.log(vm.orgId);
		workflowSharedService.viewFrom = "BankFeesAndChargesUploadByBankView";
		vm.screenLog = ScreenLog;		
		vm.loadAll = loadAll;
		vm.bankRegistration ={};
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.uploadBankFeesCharges = uploadBankFeesCharges;
		vm.openBankFeesChargesPreview = openBankFeesChargesPreview;
		function openBankFeesChargesPreview(bankFeesChargesFile){
			TacViewForEntitiesUtility.previewTAC(bankFeesChargesFile.fileContent,bankFeesChargesFile.fileName);	
		}

		vm.loadAll();
	
		logScreenAccess();
		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

	
		function loadAll() {
				BankFeeAndChargesUploadService.getActiveFeeChargesUploadByBank({bankId:vm.orgId},onRetreiveSuccess,onRetreiveFailure);
				
				function onRetreiveSuccess(result) {
					console.log(result);
					
					vm.bankFeeChargeDetails = result;
					console.log(vm.bankFeeChargeDetails);
					if (vm.bankFeeChargeDetails.lastModifiedDate == '' || vm.bankFeeChargeDetails.lastModifiedDate == null ){
						
						vm.bankFeeChargeDetails.lastModifiedDate = '-';
						console.log(vm.bankFeeChargeDetails);
					}

				}
				function onRetreiveFailure(error){
					console.log(error);
				}

			
		}
		function uploadBankFeesCharges(bankFeesCharges){
		
			$state.go('feeUpload');

		}
		
		$scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };

		

	}
})();
