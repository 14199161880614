(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'systemAmendedTrades'
	})

	.controller('SystemAmendedTradesController', SystemAmendedTradesController);

	SystemAmendedTradesController.$inject = ['$scope', '$state', 'workflowSharedService', 'GlobalValues', 'ScreenLog', 'entityConstants','DateUtils', 'AlertService', '$filter','SystemAmendedTrades','systemSettings'];
	function SystemAmendedTradesController($scope, $state, workflowSharedService, GlobalValues, ScreenLog , entityConstants, DateUtils, AlertService,$filter,SystemAmendedTrades,systemSettings) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.SYSTEM_AMENDED_TRADES;
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		vm.clicked=false;		
		vm.loadAll = loadData;
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();		
		vm.ExpandOrCollapseSystemAmendedTrades = true;
		vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
		vm.noOfMonthsForHistoricalView = vm.globalValues.getConfigSettings().noOfMOnthsForHistoricalReportsView;
		vm.yesterdayDate = new Date();
		vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		workflowSharedService.viewFrom = "systemAmendedTrades";

		if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
        	 vm.loggedInCustomer = vm.globalValues.getCustomerId();
        }
        if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
        	vm.bank = vm.globalValues.getBank();
        }
        if(vm.entityOwnerType=='Branch'){
        	vm.branch = vm.globalValues.getBranch();
        }
        
        if(systemSettings != null && systemSettings != undefined){
        	vm.currentDate = systemSettings.businessDate;
        	vm.fromDate = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
        	vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
    		vm.toDate =DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
    		vm.minDate = DateUtils.getMinimumLocalDate(vm.fromDate);
	    	
	    }
        
        
		function loadData(){
			vm.bankId = 0;
			vm.branchId = 0;
			vm.customerId = 0;
			vm.systemAmendedtradesDetails = [];
			if (vm.entityOwnerType == 'Bank' || vm.entityOwnerType == 'Branch'){
				vm.bankId = vm.globalValues.getOrganisation().id;
			}else if(vm.bank!=null && vm.bank!=undefined){
				if(vm.entityOwnerType == 'Clearcorp' || vm.entityOwnerType == 'MultiUserCustomer' || vm.entityOwnerType == 'SingleUserCustomer'){
					vm.bankId = vm.bank.id;
				}
			}

			if(vm.entityOwnerType == 'Branch'){
				vm.branchId = vm.globalValues.getBranch().id;
			}else  if(vm.branch!=null && vm.branch!=undefined){
				if(vm.entityOwnerType=='Clearcorp' || vm.entityOwnerType=='Bank'){
					vm.branchId = vm.branch.id;
				}
			}

			if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
				vm.customerId = vm.globalValues.getCustomerId();
			} else  if(vm.customer!=null && vm.customer!=undefined){
				if(vm.entityOwnerType!='MultiUserCustomer' && vm.entityOwnerType!='SingleUserCustomer'){
					vm.customerId = vm.customer.id;
				}
			}

			var fromServerDate = DateUtils.convertLocalDateToServer(vm.fromDate);
			var toServerDate = DateUtils.convertLocalDateToServer(vm.toDate);
			
		    if(moment(vm.fromDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.systemAmendedTrades.fromDateError'));

			}
			else{
				vm.clicked=true;
				SystemAmendedTrades.getAllSystemAmendedTrades({ bankId: vm.bankId, branchId:vm.branchId , customerId : vm.customerId  , fromDate : fromServerDate, toDate : toServerDate} , function(result) {
					vm.systemAmendedtradesDetails = result;    
					vm.clicked=false;

					angular.forEach(vm.systemAmendedtradesDetails,function(value){
						value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
					});

				});


			}
		}

		logScreenAccess();

		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}


	}
})();