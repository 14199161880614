
(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('MemberBanksFeesChargesViewController', MemberBanksFeesChargesViewController)

	MemberBanksFeesChargesViewController.$inject = ['ScreenLog','$scope', '$state', '$rootScope', 'GlobalValues','MemberBanksFeeAndChargesViewService', 'workflowSharedService', 'entityConstants','BankFeeViewForEntitiesUtility','$timeout'];

	function MemberBanksFeesChargesViewController( ScreenLog,$scope, $state, $rootScope, GlobalValues,MemberBanksFeeAndChargesViewService,workflowSharedService,entityConstants,BankFeeViewForEntitiesUtility,$timeout) {
		var vm = this;
		vm.banksFeeChargeDTO = [];
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.BANK_FEES_CHARGES_VIEW;
		vm.globalValues=GlobalValues;
		$scope.refresh=refresh;
		workflowSharedService.viewFrom = "bankFeesAndChargesView";
		vm.screenLog = ScreenLog;
		logScreenAccess();
		
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.openBankFeesChargesPreview = openBankFeesChargesPreview;
		function openBankFeesChargesPreview(memberBankFeesChargesFile){
			BankFeeViewForEntitiesUtility.previewBankFee(memberBankFeesChargesFile,'Bank Fees And Charges.pdf');	
		}
		
		$scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };


		loadAll();
	
		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}
		
		function loadAll() {
			MemberBanksFeeAndChargesViewService.getMemberBankViewDto(function (result){
				vm.banksFeeChargeDTO = result;
			});
		};

	
	/*	function loadAll() {
			MemberBanksFeeAndChargesViewService.getMemberBankViewDto().$promise.then(function(result) {
				console.log(result);				
				vm.banksFeeChargeDTO = result;
				console.log(vm.banksFeeChargeDTO);
	  	        });	
		};*/
		/*function onRetreiveSuccess(result) {
			console.log(result);
			
			vm.banksFeeChargeDTO = result;
			console.log(vm.banksFeeChargeDTO);

		}
		function onRetreiveFailure(error){
			console.log(error);
		}*/
		
		
		
		

	}
})();