(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'clearcorpTradesOutstandingReport'
        })
        
        .controller('ClearcorpTradesOutstandingReportController', ClearcorpTradesOutstandingReportController);

    ClearcorpTradesOutstandingReportController.$inject = ['$scope', 'GlobalValues', 'ScreenLog','entityConstants', 
    	'DateUtils','AlertService', 'ClearcorpTradesOutstandingReportService', 'OrderStatusDetails', 'systemSetting', 'DefaultMarket'];

    function ClearcorpTradesOutstandingReportController($scope, GlobalValues, ScreenLog,entityConstants, 
    		DateUtils,AlertService, ClearcorpTradesOutstandingReportService, OrderStatusDetails, systemSetting, DefaultMarket) {
       
    	var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.OUTSTANDING_TRADES_REPORT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
       
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
       
	    vm.getTradesOutstanding = getTradesOutstanding;
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    
	    vm.filterData = {};
	    vm.fileName = "Clearcorp_trades_outstanding_report_";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
	    
		DefaultMarket.marketInstruments(function(result){
            vm.instrumentTypes = result;
        });

	    logScreenAccess();
	    vm.valid = false;
		vm.clicked=false;
        vm.queryfromDate =  null;
        vm.querytoDate = null;

        if(systemSetting != null && systemSetting != undefined){
        	vm.currentDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);
        	vm.fromSetDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);
        	vm.toSetDate = DateUtils.convertDateTimeFromServer(systemSetting.businessDate);
        }
        
        function getTradesOutstanding(){
			var bankId = 0;
        	var branchId = 0;
        	var customerId = 0;
        	var instrumentId = 0;

        	vm.clearcorpTradesOutstandingReportDetails = [];

        	if(moment(vm.fromSetDate).isAfter(vm.toSetDate,'day')){
        		AlertService.error($filter('translate')('gatewayApp.settlementReport.greaterFromDate'));
        		vm.valid=false;
        	}
        	else{
				vm.valid = true;
				vm.clicked=true;
        		if(vm.bank!=null){
        			bankId = vm.bank.id;
        		}
        		if(vm.branch!=null){
        			branchId = vm.branch.id;
        		}
        		if(vm.customer!=null){
        			customerId = vm.customer.id;
        		}
        		if(vm.instrument!=null){
        			instrumentId = vm.instrument.id;
        		}

				copyFilterParams(bankId, branchId, customerId, instrumentId, angular.copy(vm.fromSetDate), angular.copy(vm.toSetDate));

        		OrderStatusDetails.getTradesOutstandingList({bankId: bankId, branchId: branchId, customerId: customerId, instrumentId: instrumentId, 
        			fromSetDate: DateUtils.convertLocalDateToServer(vm.fromSetDate),toSetDate : DateUtils.convertLocalDateToServer(vm.toSetDate)}, onGetTradeOSSuccess, onGetTradeOSFailure)

        	}
        }

        function onGetTradeOSSuccess(result){
        	angular.forEach(result,function(value){
        		value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        	});
        	vm.clearcorpTradesOutstandingReportDetails = result;
			vm.clicked=false;
        }

		function onGetTradeOSFailure(error){
			vm.clicked=false;
        }

		function copyFilterParams(bankId, branchId, customerId, instrumentId, fromSetDate, toSetDate){
			vm.filterData = {};
			
			vm.filterData.bankId = bankId;
			vm.filterData.branchId = branchId;
			vm.filterData.customerId = customerId;
			vm.filterData.instrumentId = instrumentId;
			vm.filterData.fromSetDate = fromSetDate;
			vm.filterData.toSetDate = toSetDate;

		}
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }

		function exportDataToExcelFile(){
      	 
      	  if(vm.clearcorpTradesOutstandingReportDetails  !=null &&   vm.clearcorpTradesOutstandingReportDetails !=undefined &&  vm.clearcorpTradesOutstandingReportDetails .length>0){
      		 
      			vm.mimeType = 'application/vnd.ms-excel';
      			vm.extension = ".xls";  
				var selectedTrades = angular.copy(vm.clearcorpTradesOutstandingReportDetails);
				angular.forEach(selectedTrades,function(value,index){        		
					value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');    	        		 	        		
				});
				ClearcorpTradesOutstandingReportService.getTradesOutstandingExcel({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, customerId: vm.filterData.customerId, instrumentId: vm.filterData.instrumentId, 
        			fromSetDate: DateUtils.convertLocalDateToServer(vm.filterData.fromSetDate),toSetDate : DateUtils.convertLocalDateToServer(vm.filterData.toSetDate)},selectedTrades, processResponse);
      		 
      	  }
        }       
        function exportDataToCsvFile(){        	
        		
        	  if( vm.clearcorpTradesOutstandingReportDetails !=null &&  vm.clearcorpTradesOutstandingReportDetails!=undefined && vm.clearcorpTradesOutstandingReportDetails.length>0){
        		 
        			vm.mimeType = 'text/csv';
        			vm.extension = ".csv";  
					var selectedTrades = angular.copy(vm.clearcorpTradesOutstandingReportDetails);
					angular.forEach(selectedTrades,function(value,index){        		
						value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');    	        		 	        		
					});

					ClearcorpTradesOutstandingReportService.getTradesOutstandingCsv({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, customerId: vm.filterData.customerId, instrumentId: vm.filterData.instrumentId, 
						fromSetDate: DateUtils.convertLocalDateToServer(vm.filterData.fromSetDate),toSetDate : DateUtils.convertLocalDateToServer(vm.filterData.toSetDate)},selectedTrades, processResponse);
					 
        	  }
        }
	    
	    function processResponse(result){
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString =     vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension;   	
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}

    }
})();