(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Branch', Branch);

    Branch.$inject = ['$resource'];

    function Branch ($resource) {
        var resourceUrl =  'api/branch/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },           
           	
			'getBranchLookup' : {
				method : 'GET',
				isArray: true,
				url : 'api/branch/lookup'
			},

			'save': {
                method: 'POST',
                params: {corpId:'@corpId'},
                url: 'api/branch/:corpId',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
            'update': {
            	method:'PUT',
            	params: {corpId:'@corpId'},
                url: 'api/branch/:corpId',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
            'delete':{
                method: 'DELETE',
                params: {corpId:'@corpId', id:'@id'},
                url: 'api/branch/:corpId/:id'    
                },
         
            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
             'resubmitForCreate': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmitForCreate/branch',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             },
             'resubmitForEdit': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmitForEdit/branch',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             },
             'getAllClearanceBranches': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId'},
                 url: 'api/bank/:bankId/cleared-branches',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getAllBranchesForBank': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId'},
                 url: 'api/branches-for-bank/:bankId',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getAllBranchesLookupForBank': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId'},
                 url: 'api/branch/lookup/:bankId/bank',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getAllBranchesLookupWithStatusForBank': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId'},
                 url: 'api/branch-with-status/lookup/:bankId/bank',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getAllActiveBranchesLookupForBank': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId'},
                 url: 'api/active-branch/lookup/:bankId/bank',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getBranchForUser': {
                 method: 'GET',
                 params: {userId:'@userId'},
                 url: 'api/branch-for-user/:userId',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
             'getValidBranchesForBank': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId',customerId:'@customerId'},
                 url: 'api/valid-status-branches-for-bank/:bankId/:customerId',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },           
             'getValidBranchDetailsForBank': {
                 method: 'GET',
                 isArray: true,
                 params: {bankId:'@bankId'},
                 url: 'api/public/trading-branch/:bankId/bank',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             }           
            
              });
    }
})();

            
