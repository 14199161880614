(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'orderHistory'
        })
        
        .controller('OrderHistoryController', OrderHistoryController);

    OrderHistoryController.$inject = ['$scope', '$state', 'OrderHistory', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'$timeout','DateUtils','AlertService','$filter'];

    function OrderHistoryController($scope, $state, OrderHistory , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,DateUtils,
    		AlertService,$filter) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDER_HISTORY;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        
        vm.todayDate = new Date();
        vm.yesterdayDate = new Date();
        vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.fromDate = DateUtils.convertDateTimeFromServer(vm.yesterdayDate);
        vm.toDate = DateUtils.convertDateTimeFromServer(vm.yesterdayDate);
        vm.buySellIndicators=["BUY","SELL"];
        vm.valid=false;
		vm.clicked=false;
        
        vm.minDate = DateUtils.getSubtractMonth(new Date(),3);
        vm.noOfMonthsForHistoricalView = vm.globalValues.getConfigSettings().noOfMOnthsForHistoricalReportsView;
        
        if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
        	 vm.loggedInCustomer = vm.globalValues.getCustomerId();
        }
        if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
        	vm.bank = vm.globalValues.getBank();
        }
        if(vm.entityOwnerType=='Branch'){
        	vm.branch = vm.globalValues.getBranch();
        }
        
       
        vm.getOrderHistory = getOrderHistory;
        vm.ExpandOrCollapseOrderHistory=true;
        
        workflowSharedService.viewFrom = "orderHistory";
       
        
        logScreenAccess();
       // loadCurrentBusinessDate();

       function getOrderHistory() {
    	   
    	   vm.bankId = null;
    	   vm.branchId = null;
    	   vm.customerId= null;
    	   vm.instrumentId = null;
    	   vm.buySellFlag=null;
    	   vm.orderStatusName = null;
    	   
    	   if(moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.orderHistory.futureFromDate'));
	   			vm.valid=false;
	   		}
	   		else if(moment(vm.fromDate).isAfter(vm.toDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.orderHistory.greaterFromDate'));
	   			vm.valid=false;
	   		}else if(moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.orderHistory.greaterToDate'));
	   			vm.valid=false;
	   		}
	   		else if(moment(vm.fromDate).isBefore(vm.minDate, 'day')){
	   			AlertService.error('Historical Reports are available only for a period of '  + vm.noOfMonthsForHistoricalView + ' months...!');
	   			vm.valid=false;
	   		}else if(!(moment(vm.fromDate).isSame(vm.toDate,'month'))){
				AlertService.error($filter('translate')('gatewayApp.orderHistory.helpText'));
				vm.valid=false;
			}
	   		else{
				vm.clicked=true;
	   			vm.valid=true;
	   		 if (vm.entityOwnerType == 'Bank' || vm.entityOwnerType == 'Branch'){
	    		   vm.bankId = vm.globalValues.getOrganisation().id;
	    	   }else if(vm.bank!=null && vm.bank!=undefined){
	    		   if(vm.entityOwnerType == 'Clearcorp' || vm.entityOwnerType == 'MultiUserCustomer' || vm.entityOwnerType == 'SingleUserCustomer'){
		    			  vm.bankId = vm.bank.id;
		    		  }
	    	   }
	    	   
	    	   if(vm.entityOwnerType == 'Branch'){
	    		   vm.branchId = vm.globalValues.getBranch().id;
	    	   }else  if(vm.branch!=null && vm.branch!=undefined){
	    		   if(vm.entityOwnerType=='Clearcorp' || vm.entityOwnerType=='Bank'){
	    			   vm.branchId = vm.branch.id;
	    		   }
	    	   }
	    	   
	    	   if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
	    		   vm.customerId = vm.globalValues.getCustomerId();
	    	   } else  if(vm.customer!=null && vm.customer!=undefined){
	    		   if(vm.entityOwnerType!='MultiUserCustomer' && vm.entityOwnerType!='SingleUserCustomer'){
	    			   vm.customerId = vm.customer.id;
	    		   }
	    	   }
  	   
  	   
	    	   if(vm.instrument!=null && vm.instrument!=undefined){
	    		   vm.instrumentId = vm.instrument.id;
	    	   }
	    	   
	    	   if(vm.orderStatus!=null && vm.orderStatus!=undefined && vm.orderStatus !=""){
	    		   vm.orderStatusName= vm.orderStatus.name;
	    		   if(vm.orderStatusName=='Filled'){
	    			   vm.orderStatusName='TRAD';
	    		   }else if(vm.orderStatusName=='Cancelled'){
	    			   vm.orderStatusName='CNCL';
	    		   }
	    	   }
	    	   if(vm.buySell!=null && vm.buySell!=undefined && vm.buySell!=""){
	    		   if(vm.buySell=='BUY'){
	    			   vm.buySellFlag = 'BUYY';
	    		   }else if(vm.buySell=='SELL'){
	    			   vm.buySellFlag='SELL';
	    		   }else{
	    			   vm.buySellFlag=null;
	    		   }
	    	   }
	    	   
	    	   vm.convertedFromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
	    	   vm.convertedToDate = DateUtils.convertLocalDateToServer(vm.toDate);
	    	   
  	   OrderHistory.getOrderHistory({bankId: vm.bankId, branchId:vm.branchId , customerId : vm.customerId ,
  		   instrumentId : vm.instrumentId , buySell:vm.buySellFlag,orderStatus : vm.orderStatusName,
  		   fromDate: vm.convertedFromDate, toDate : vm.convertedToDate},function(result) {
  	   
              
              angular.forEach(result, function(value){
              	
              	if(value.buySellIndicator.toUpperCase()=='BUYY'){
              		value.buySellIndicator='Buy';
              	}else if(value.buySellIndicator.toUpperCase()=='SELL'){
              		value.buySellIndicator='Sell';
              	}
              	
              	if(value.orderStatus=='TRAD'){
              		value.orderStatus='Filled';
              	}else if(value.orderStatus=='CNCL'){
              		value.orderStatus='Cancelled';
              	}
              	//alert("SD" + value.settlementStartDate ); 	
          	});
             
             
              vm.orderHistory = result;
			  vm.clicked=false;
           });        
	   		}
    	   	
	    	  	
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
  	    vm.getSwapPoints = function getSwapPoints(spotExchangeRate, tradeExchangeRate){
			var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
			swapPts = (Math.round(parseFloat(swapPts)*100)/100);
			return swapPts;
		}
  
    }
})();
