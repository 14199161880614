(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.controller('MarkupReportController', MarkupReportController);

	MarkupReportController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',  'AlertService','MarkupReport','$filter', 'ScreenLog','entityConstants','DateUtils','$window'];

	function MarkupReportController( $scope, $state, $rootScope, GlobalValues,AlertService , MarkupReport , $filter , ScreenLog, entityConstants,DateUtils, $window){
		var vm = this;
		vm.globalValues = GlobalValues;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.MARKUPREPORT;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        vm.bank= null;
        vm.branch = null;
        vm.customer = null;
        vm.selectedHeaderBankId = null;
        vm.selectedHeaderBranchId = null;
        vm.selectedHeaderCustomerID = null;
        vm.headerList =[];
        vm.detailsList=[];
		vm.clicked=false;
        vm.loadHeader = getMarkupReportHeader;
        vm.getDetails = getDetails;
        //vm.reportDate = new Date();
        vm.showDetails= false;
        vm.bankLookUpName = "bank";
 		vm.bankFilter = '';
        vm.loggedInUser = vm.globalValues.getUser();
        vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.ExpandOrCollapse = true;
        vm.valid=false;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.todayDate = new Date();
 		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
 	    $scope.page = 1; 
 	   vm.expandBuyTrades = true;
       vm.expandSellTrades = true;
       vm.exportToFile = exportToFile;
       vm.historicalTrade = false;
       vm.valid = false;
       var index = 0;
       vm.markupTypePaise = 'Paise';
       vm.markupTypePercentage = 'Percentage';
       
      
       vm.historicalOrCurrent = 'Current';
 	    
 	   MarkupReport.getCurrentBusinessDate(getBusinnessDateSuccess, getBusinessDateFailed);
 	   
 	   function getBusinnessDateSuccess(result) {
 		  if (result.date) {
 			 vm.reportDate  = DateUtils.convertLocalDateFromServer(result.date);
 			vm.yesterdayDate = angular.copy(vm.reportDate);
 	       vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
 			 vm.fromDate = angular.copy(vm.reportDate);
 			 vm.fromDate.setDate(vm.fromDate.getDate() -1);
 		       vm.toDate = angular.copy(vm.reportDate);
 		      vm.toDate.setDate(vm.toDate.getDate() -1);
          }
 		  else {vm.reportDate =  null;}
 		  //vm.reportDate  = DateUtils.convertLocalDateFromServer(result.date);		console.log(  vm.reportDate );	
			
		}

		function getBusinessDateFailed(error) {
			//Do Nothing
		}
 	    
		if (vm.loggedinEntityOwnerType == 'Clearcorp'){
			 vm.disableBank = false;
			 vm.disableBranch = false;
			 vm.disableCustomer = false;
			 vm.bankLookUpName = "nonTerminatedBanks";
	    }
 	    else if (vm.loggedinEntityOwnerType == 'Bank'){
	  		
	  		 vm.bank = vm.globalValues.getBank();
	  		 vm.disableBank = true;
	  		 vm.disableBranch = false;
	  		 vm.disableCustomer = false;
 	    }
 	    else if (vm.loggedinEntityOwnerType == 'Branch'){
 	    	
	  		 vm.bank = vm.globalValues.getBank();
	  		 vm.branch = vm.globalValues.getBranch();
	  		 vm.disableBank = true;
	  		 vm.disableBranch = true;
	  		 vm.disableCustomer = false;
	  		 vm.bankLookUpName = "bank";
	  		 vm.bankFilter = '';
 	    }
 	    else if (vm.loggedinEntityOwnerType == 'MultiUserCustomer' || vm.loggedinEntityOwnerType == 'SingleUserCustomer'){
	  		
	  		 vm.customer = vm.globalValues.getOrganisation();
	  		 vm.disableBank = false;
	  		 vm.disableBranch = true;
	  		 vm.disableCustomer = true;
	  		 vm.bankLookUpName = "tradingBank";
	  		 vm.bankFilter = vm.customer.id;
 	    }else{
 	    	
 	    }
 	    
 	   logScreenAccess();
 	   function getMarkupReportHeader(){
 		   vm.valid=true;
 		   var bankid = null;
 		   var branchId= null
 		   var customerid= null;
 		   if(vm.bank != undefined && vm.bank != null & vm.bank != ''){
 			  bankid = vm.bank.id;
 		   }
 		  if(vm.branch != undefined && vm.branch != null && vm.branch != ''){
 			 branchId = vm.branch.id;
 		   }
 		 if(vm.customer != undefined && vm.customer != null && vm.customer != ''){
 			customerid = vm.customer.id;
		   }
 		
 		 
 		if(vm.historicalOrCurrent == 'Historical' && moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
   			AlertService.error($filter('translate')('gatewayApp.markupReport.futureFromDate'));
   			vm.valid=false;
   		}
   		else if(vm.historicalOrCurrent == 'Historical' && moment(vm.fromDate).isAfter(vm.toDate,'day')){
   			AlertService.error($filter('translate')('gatewayApp.markupReport.greaterFromDate'));
   			vm.valid=false;
   		}else if(vm.historicalOrCurrent == 'Historical' && moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
   			AlertService.error($filter('translate')('gatewayApp.markupReport.greaterToDate'));
   			vm.valid=false;
   		}
   		else{
			vm.clicked=true;
   			vm.valid = true;
   		 if(vm.historicalOrCurrent == 'Historical'){
  			vm.historicalTrade = true;
  			 vm.convertedFromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
  			 vm.convertedToDate = DateUtils.convertLocalDateToServer(vm.toDate);
  			MarkupReport.getHistoricalMarkupReportHeaderList({bankId: bankid, branchId : branchId, custId: customerid, fromDate : vm.convertedFromDate,
  				toDate : vm.convertedToDate} , markupHeaderSuccess, markupHeaderFailure );
  			
  		 }else{
  			vm.historicalTrade = false;
  			MarkupReport.getMarkupReportHeaderList({bankId: bankid, branchId : branchId, custId: customerid} , markupHeaderSuccess, markupHeaderFailure );
  		 }
   		 
   		}
 		 
 		
 		 
 	   }
 	   function markupHeaderSuccess(result){
 		  vm.headerList = result;
 		  vm.showDetails= false;
		  vm.clicked=false;
 		  
 	   }
 	   function markupHeaderFailure(error){
		 vm.clicked=false;
 	   }
 	  
 	   function getMarkupReportDetailsListExport(){
 		  var bankid = null;
		  var branchId= null
		  var customerid= null;
		  if(vm.bank != undefined && vm.bank != null & vm.bank != ''){
			  bankid = vm.bank.id;
		  }
		  if(vm.branch != undefined && vm.branch != null && vm.branch != ''){
			 branchId = vm.branch.id;
		   }
		  if(vm.customer != undefined && vm.customer != null && vm.customer != ''){
			customerid = vm.customer.id;
		   }
		  MarkupReport.getMarkupReportDetailsList({bankId: bankid, branchId : branchId, custId: customerid} , markupDetailsListExportSuccess, markupDetailsListExportFailure );
 	   }
 	  function markupDetailsListExportSuccess(result){
 		 vm.detailsListForExport = result;
 		
 	   }
 	  function markupDetailsListExportFailure(error){
 		  
	   }
 	  
 	 function getMarkupReportDetailsList(){
		 
 		 if(!vm.historicalTrade){
 			 MarkupReport.getMarkupReportDetailsList({bankId: vm.selectedHeaderBankId, branchId : vm.selectedHeaderBranchId, custId: vm.selectedHeaderCustomerID, buyOrSell : 'BUYY'}
 			  , markupDetailsBuyListSuccess, markupDetailsBuyListFailure );
 		 }
 		 else if(vm.businessDate != null &&vm.businessDate != undefined){
 			 vm.convertedBusinessDate = DateUtils.convertLocalDateToServer(vm.businessDate);
 			 MarkupReport.getHistoricalMarkupReportDetailsList({bankId: vm.selectedHeaderBankId, branchId : vm.selectedHeaderBranchId, 
 				 custId: vm.selectedHeaderCustomerID, buyOrSell : 'BUYY', businessDate :  vm.convertedBusinessDate}
			  , markupDetailsBuyListSuccess, markupDetailsBuyListFailure );
 		 }
 		 
		 
	   }
	  function markupDetailsBuyListSuccess(result){
		   index = 1;
		 vm.buyDetailsList = result;
		 
		 angular.forEach(vm.buyDetailsList, function(val){
			 val.index = index++;
		 })
		 
		 if(!vm.historicalTrade){
			 MarkupReport.getMarkupReportDetailsList({bankId: vm.selectedHeaderBankId, branchId : vm.selectedHeaderBranchId, custId: vm.selectedHeaderCustomerID, buyOrSell : 'SELL'}
			  , markupDetailsSellListSuccess, markupDetailsSellListFailure );
		 }
		 else if(vm.businessDate !=null && vm.businessDate !=undefined){
			 vm.convertedBusinessDate = DateUtils.convertLocalDateToServer(vm.businessDate);
			 MarkupReport.getHistoricalMarkupReportDetailsList({bankId: vm.selectedHeaderBankId, branchId : vm.selectedHeaderBranchId, custId: vm.selectedHeaderCustomerID,
				 buyOrSell : 'SELL', businessDate : vm.convertedBusinessDate}
			  , markupDetailsSellListSuccess, markupDetailsSellListFailure );
		 }
		 
		 
		 
		
	   }
	  function markupDetailsBuyListFailure(error){
		  
	   }
	  function getDetails(header){
		  vm.selectedHeaderBankId = header.bankId;
		  vm.selectedHeaderBranchId = header.branchId;
		  vm.selectedHeaderCustomerID=header.customerId;
		  vm.selectedHeaderCustomerName = header.customerName;
		  vm.businessDate = header.businessDate;
		  vm.showDetails= true;
		  vm.valid=true;
		  getMarkupReportDetailsList();
	  }
	   function logScreenAccess(){
			  vm.screenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	    }
	   
	   function markupDetailsSellListSuccess(result){
		   
		   
		   vm.sellDetailsList = result;
		   
		   angular.forEach(vm.sellDetailsList, function(val){
				 val.index = index++;
			 })
		   vm.totalBuyMarkupValue = 0;
		   vm.totalSellMarkupValue = 0;
		   vm.totalBuyTax = 0;
		   vm.totalSellTax =0;
		   vm.totalBuyMarkupPostTax = 0;
		   vm.totalSellMarkupPostTax = 0;
		   if(vm.buyDetailsList.length>0){
			   vm.totalBuyMarkupValue = vm.buyDetailsList.reduce(function (a,b) { return a + b.markupValue; }, 0);
			   vm.totalBuyTax = vm.buyDetailsList.reduce(function (a,b) { return a + b.taxOnMarkup; }, 0);
			   vm.totalBuyMarkupPostTax = vm.buyDetailsList.reduce(function (a,b) { return a + b.markupValuePostTax; }, 0);
		   }
		   if(vm.sellDetailsList.length>0){
			   vm.totalSellMarkupValue = vm.sellDetailsList.reduce(function (a,b) { return a + b.markupValue; }, 0);
			   vm.totalSellTax = vm.sellDetailsList.reduce(function (a,b) { return a + b.taxOnMarkup; }, 0);
			   vm.totalSellMarkupPostTax = vm.sellDetailsList.reduce(function (a,b) { return a + b.markupValuePostTax; }, 0);
		   }
		   vm.totalMarkupValue = vm.totalBuyMarkupValue + vm.totalSellMarkupValue;
		   vm.totalTax = vm.totalBuyTax + vm.totalSellTax;
		   vm.totalMarkupPostTax = vm.totalBuyMarkupPostTax + vm.totalSellMarkupPostTax;
		   
		  
	   }
	   
	   function markupDetailsSellListFailure(error){
		   
	   }
	   
	   
	   function exportToFile(fileType){
	      	  
	      	  
	      	 vm.valid=true;
	 		   var bankid = null;
	 		   var branchId= null
	 		   var customerid= null;
	 		   if(vm.bank != undefined && vm.bank != null & vm.bank != ''){
	 			  bankid = vm.bank.id;
	 		   }
	 		  if(vm.branch != undefined && vm.branch != null && vm.branch != ''){
	 			 branchId = vm.branch.id;
	 		   }
	 		 if(vm.customer != undefined && vm.customer != null && vm.customer != ''){
	 			customerid = vm.customer.id;
			   }
	 		 vm.fileType = fileType;
	      	  if( vm.headerList !=null &&  vm.headerList!=undefined){
	      		 
	      		  
	      		vm.convertedFromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
	 			 vm.convertedToDate = DateUtils.convertLocalDateToServer(vm.toDate);
	 			 
		 			 if(!vm.historicalTrade){
		 				 
		 				 if(vm.fileType == 'excel')
		 					 MarkupReport.exportData({bankId: bankid, branchId : branchId, custId: customerid, fromDate : vm.convertedFromDate, toDate : vm.convertedToDate}, onExportSuccess);
		 				 else
		 					MarkupReport.exportCSVData({bankId: bankid, branchId : branchId, custId: customerid, fromDate : vm.convertedFromDate, toDate : vm.convertedToDate}, onExportSuccess);
		 			 }
		 			 else{
		 				 
		 				if(vm.fileType == 'excel')
		 					
		 					MarkupReport.exportHistoricalData({bankId: bankid, branchId : branchId, custId: customerid, fromDate : vm.convertedFromDate, toDate : vm.convertedToDate}, onExportSuccess);
		 				else
		 					MarkupReport.exportHistoricalCSVData({bankId: bankid, branchId : branchId, custId: customerid, fromDate : vm.convertedFromDate, toDate : vm.convertedToDate}, onExportSuccess);
		 			 }
	 			 
	 			 }
	      		
	      		  
	      		  
	      	  }
	        
	   function onExportSuccess(result){
		   var mimeType= '';
	      	
	      	  var baseString =   'Markup_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   
	      	  
		   if(vm.fileType == 'csv'){
   			  mimeType = 'text/csv'
   			  baseString = baseString+ ".csv";  
   		  }else if(vm.fileType == 'excel'){
   			  mimeType = 'application/vnd.ms-excel';
   			  baseString = baseString+ ".xls";  
   		  }
		   
		   var fileData = result.data;
			  
 			var IEFile = new Blob([result.data], {type: mimeType});
     		
             
             if(window.navigator.msSaveOrOpenBlob) {
                   window.navigator.msSaveOrOpenBlob(IEFile, baseString );
               }
             else{
           	  
           	  var link = document.createElement("a");   
     			  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
     			      			  
 			      var blob = new Blob([bytes], {type: mimeType});
 			      link.href =  window.URL.createObjectURL(blob);
 			      
     		   	  link.download = baseString;
     		   	  document.body.appendChild(link);
     		   	  link.click();
     			  document.body.removeChild(link);
             }
             
	   }
	
	}
})();