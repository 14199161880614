(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrderServerManualLogin', OrderServerManualLogin);

    OrderServerManualLogin.$inject = ['$resource'];

    function OrderServerManualLogin ($resource) {
        var resourceUrl =  'api/order-server-manual-login';
        
        return $resource(resourceUrl, {}, {
        	
              'manualLogin': {
                  method: 'POST',
                  url: 'order/manual-login'
              }
              
        });
    }
})();
