(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'customerregistration'
        })
        .controller('CustomerOnBoardingExceptionReportController', CustomerOnBoardingExceptionReportController);

    CustomerOnBoardingExceptionReportController.$inject = ['$scope', '$state', 'workflowSharedService','CustomerOnBoardingExceptionReport',  
    	'GlobalValues','EntityPermissions','$filter', 'entity','ScreenLog','entityConstants','$timeout', 'DateUtils', 'AlertService','$window'];

    function CustomerOnBoardingExceptionReportController($scope, $state,  workflowSharedService, CustomerOnBoardingExceptionReport,  
    		GlobalValues, EntityPermissions,$filter, entity,ScreenLog,entityConstants,$timeout, DateUtils, AlertService, $window) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.showDetails=false;
        vm.todayDate=new Date();
        vm.customerOnBoarding=customerOnBoarding;
        vm.GetNumberOfDaysInPipeline=GetNumberOfDaysInPipeline;
        vm.screenLog=ScreenLog;
        $scope.refresh=refresh;
        vm.prepareTotalRow = prepareTotalRow;
        vm.prepareDetails=prepareDetails;
        vm.prepareElements=prepareElements;
        vm.todayDate=new Date();
        vm.fromDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        vm.toDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        vm.mimeType= '';  	  
  	  	vm.baseString =  'Customer_OnBoarding_Exception_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm'); 
        workflowSharedService.viewFrom = "customerregistration";
		vm.clicked=false; 
        
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_ONBOARDING_EXCEPTION_REPORT;
        logScreenAccess();
        
        //display all the records of customer onboarding
        
        vm.loadAll = loadAll;
        vm.exportToFile = exportToFile
        
        function loadAll() {
        	vm.totalPendingTasksCount = 0;
        	vm.totalRejectedTasksCount = 0;
        	vm.totalAutoWithdrawnTasksCount = 0;
        	vm.totalWithdrawnTasksCount = 0
        	vm.totalPendingTasks = [];
        	vm.totalRejectedTasks = [];
        	vm.totalAutoWithdrawnTasks = [];
        	vm.totalWithdrawnTasks = [];
        	vm.customerRegistrationDetails = [];
        	if(moment(vm.fromDate).isAfter(vm.todayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.AuditLog.futureFromDate'));
    		}
    		else if(moment(vm.fromDate).isAfter(vm.toDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.AuditLog.greaterFromDate'));
    		}else if(moment(vm.toDate).isAfter(vm.todayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.AuditLog.greaterToDate'));
    		}
    		else{
        		var from=DateUtils.convertLocalDateToServer(vm.fromDate);
        		var to=DateUtils.convertLocalDateToServer(vm.toDate);
        		
                CustomerOnBoardingExceptionReport.getCustomerOnBoardingReportDetails({"from":from,"to":to}, function(result) {
                    vm.customerOnBoardingDetails = result; 
					vm.clicked=false; 
                    /*if(vm.userEntityOwnerType=='Branch'){
                    	if(vm.customerOnBoardingDetails.length>0){
                    		vm.showDetails=true;
                        	vm.customerRegistrationDetails = vm.customerOnBoardingDetails[0].customerRegistrationRequestList;
                    	}else{
                    		vm.customerRegistrationDetails = [];
                    	}
                    }*/
                });
    		}
        }
        
        function customerOnBoarding(customerRegistrationList, status){
        	vm.customerRegistrationDetails = customerRegistrationList;
        	if(status=='pending'){
        		vm.showDateOfRejectionWithdrawn = false;
        	}else{
        		vm.showDateOfRejectionWithdrawn = true;
        	}
        	
        }
        
        //get the number of days in pipeline given created date
        function GetNumberOfDaysInPipeline(createdDate){
        		$scope.formattedCreatedDate =   $filter('date')(createdDate, "yyyy-MM-dd");
        		var startDate = Date.parse($scope.formattedCreatedDate);
        		$scope.formattedTodayDate =   $filter('date')(vm.todayDate, "yyyy-MM-dd");
        		var endDate = Date.parse($scope.formattedTodayDate);
        		var timeDiff = endDate - startDate;
        		$scope.daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        		return $scope.daysDiff;
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
        
        $scope.$watch('vm.filterText', function(value){
        	vm.totalPendingTasksCount = 0;
        	vm.totalRejectedTasksCount = 0;
        	vm.totalAutoWithdrawnTasksCount = 0;
        	vm.totalWithdrawnTasksCount = 0;
        	vm.totalPendingTasks = [];
        	vm.totalRejectedTasks = [];
        	vm.totalAutoWithdrawnTasks = [];
        	vm.totalWithdrawnTasks = [];
        	vm.customerRegistrationDetails = [];
        	angular.forEach(vm.filterData, function(value){
            	prepareTotalRow(value);
        	})
        	
        })
        
        function prepareElements()
        {
        	 //if(vm.userEntityOwnerType!='Branch'){
	        	vm.totalPendingTasksCount = 0;
	        	vm.totalRejectedTasksCount = 0;
	        	vm.totalAutoWithdrawnTasksCount = 0;
	        	vm.totalWithdrawnTasksCount = 0;
	        	vm.totalPendingTasks = [];
	        	vm.totalRejectedTasks = [];
	        	vm.totalAutoWithdrawnTasks = [];
	        	vm.totalWithdrawnTasks = [];
	        	vm.customerRegistrationDetails = [];
        	 //}
        }
        
      function prepareDetails(){
        	prepareElements();
        	angular.forEach(vm.filterData, function(value){
            	prepareTotalRow(value);
        	})
        }
        
        function prepareTotalRow(customerOnBoardingDetail){
        	vm.totalPendingTasksCount = vm.totalPendingTasksCount + customerOnBoardingDetail.numberOfPendingTasks; 
        	vm.totalRejectedTasksCount = vm.totalRejectedTasksCount + customerOnBoardingDetail.numberOfRejectedTasks; 
			vm.totalAutoWithdrawnTasksCount = vm.totalAutoWithdrawnTasksCount + customerOnBoardingDetail.numberOfAutoWithdrawnTasks; 
			vm.totalWithdrawnTasksCount = vm.totalWithdrawnTasksCount + customerOnBoardingDetail.numberOfWithdrawnTasks; 

			if(customerOnBoardingDetail.customerRegistrationRequestList!=null && customerOnBoardingDetail.customerRegistrationRequestList!=undefined){
				vm.totalPendingTasks = vm.totalPendingTasks.concat(customerOnBoardingDetail.customerRegistrationRequestList); 
			}
			if(customerOnBoardingDetail.rejectedRequestList!=null && customerOnBoardingDetail.rejectedRequestList!=undefined){
				vm.totalRejectedTasks = vm.totalRejectedTasks.concat(customerOnBoardingDetail.rejectedRequestList); 
			}
			if(customerOnBoardingDetail.autoWithdrawnRequestList!=null && customerOnBoardingDetail.autoWithdrawnRequestList!=undefined){
				vm.totalAutoWithdrawnTasks = vm.totalAutoWithdrawnTasks.concat(customerOnBoardingDetail.autoWithdrawnRequestList); 
			}
			if(customerOnBoardingDetail.withdrawnRequestList!=null && customerOnBoardingDetail.withdrawnRequestList!=undefined){
				vm.totalWithdrawnTasks = vm.totalWithdrawnTasks.concat(customerOnBoardingDetail.withdrawnRequestList);
			}
        }
        function exportToFile(fileType){

    		var from=DateUtils.convertLocalDateToServer(vm.fromDate);
    		var to=DateUtils.convertLocalDateToServer(vm.toDate);
    		vm.baseString =  'Customer_OnBoarding_Exception_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');
      	  if( vm.customerOnBoardingDetails !=null &&  vm.customerOnBoardingDetails!=undefined){
      		  if(fileType == 'csv'){
      			  vm.mimeType = 'text/csv'
      			  vm.baseString = vm.baseString+ ".csv";
           		CustomerOnBoardingExceptionReport.exportCSVData({"from":from,"to":to},vm.customerOnBoardingDetails ,onGenerateSuccess);
      		  }else if(fileType == 'excel'){
      			  vm.mimeType = 'application/vnd.ms-excel';
      			  vm.baseString = vm.baseString+ ".xls"; 
      			CustomerOnBoardingExceptionReport.exportExcelData({"from":from,"to":to},vm.customerOnBoardingDetails ,onGenerateSuccess);
      		  }
      	  }
        }
      	  
        function onGenerateSuccess(result){
        	
			  var fileData = result.data;
 			  
 			  var IEfile= new Blob([result.data], {type: vm.mimeType});
			      if(window.navigator.msSaveOrOpenBlob) {
			    	 window.navigator.msSaveOrOpenBlob(IEfile, vm.baseString );
			      }
			      else
			    	  {
	     			  var link = document.createElement("a");   
	     			  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	     			  
	 			      //var data = btoa(String.fromCharCode.apply(null, bytes)); // get data string from binary array			     
	     		   	  //link.href = 'data:'+mimeType+';base64,'+ encodeURI(data);
	     			  
	 			      var blob = new Blob([bytes], {type: vm.mimeType});
	 			      link.href =  window.URL.createObjectURL(blob);
	 			      
	 			   
	 			      
	     		   	  link.download = vm.baseString;
	     		   	  document.body.appendChild(link);
	     		   	  link.click();
	     			  document.body.removeChild(link);
			    	  }
      	}
        
        vm.getAddress = function getAddress(address1, address2){
        	var address = '-';
        	if(address1!=null && address1!=undefined && address1!=''){
        		address = address1;
        	}
        	if(address2!=null && address2!=undefined && address2!=''){
        		address = address + ', ' + address2;
        	}
        	return address;
        }
        
        vm.getMobile = function getMobile(mobile, mobileCountryCode){
        	var mobileNo = '-';
        	if(mobile!=null && mobile!=undefined && mobile!=''){
        		mobileNo = mobile;
        		if(mobileCountryCode!=null && mobileCountryCode!=undefined && mobileCountryCode!=''){
        			mobileNo = mobileCountryCode + '-' + mobile;
        		}
        	}
        	return mobileNo;
        }

    }
})();
