(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'globalSecurityPolicy'
        })
        .controller('GlobalSecurityPolicyController', GlobalSecurityPolicyController);

    GlobalSecurityPolicyController.$inject = ['$scope', '$rootScope', '$state', 'workflowSharedService', 'GlobalSecurityPolicy', 'GlobalValues', 'EntityPermissions','ScreenLog','entityConstants'];

    function GlobalSecurityPolicyController ($scope, $rootScope, $state, workflowSharedService, GlobalSecurityPolicy, GlobalValues, EntityPermissions, ScreenLog,entityConstants) {
        var vm = this;
        vm.screenLog=ScreenLog;
        vm.securityPolicies = [];        
       
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.GLOBAL_SECURITY_POLICY;
        
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.clicked=false;
        //vm.permitSecurityPolicyMapEntityAction = EntityPermissions.hasPermission('GlobalSecurityPolicy.MAP');
        vm.permitSecurityPolicyMapEntityAction = true;
                
        workflowSharedService.viewFrom = 'globalSecurityPolicy';
        
        $rootScope.$on('gatewayApp:globalSecurityPolicyRefresh', function(event, result) {
        	//loadAll();
        });
        logScreenAccess();
        //loadAll();
        
        vm.loadAll = function loadAll(organisationType, customerUserType) {
        	vm.custUserTypeID = 0;
        	$rootScope.organisationType = organisationType;
        	
        	$rootScope.customerUserType = customerUserType;
        	if(customerUserType != undefined){vm.custUserTypeID = customerUserType.id};
        	GlobalSecurityPolicy.getGlobalSecurityPolicies({"orgTypeId":organisationType.id, "customerUserTypeId": vm.custUserTypeID},function(result) {
        		vm.securityPolicies = result;
                vm.clicked=false;
        		console.log(result);
        		
        	});
        }
        
        $rootScope.$on('globalSecurityPolicyRefresh', function(event, result) {
        	vm.organisationtype = $rootScope.organisationType;
        	vm.customerusertype = $rootScope.customerUserType;
        	if($rootScope.organisationType != null)
        		vm.loadAll(vm.organisationtype, vm.customerusertype); 
        });
        function logScreenAccess(){
            
       	 ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
    }
})();
