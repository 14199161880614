(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('no-of-loggedin-users-for-bank', {
			parent: 'entity',
			params:{screen: 'NoOfLoggedInUsersForBank'},
			url: '/no-of-loggedin-users-for-bank',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			ncyBreadcrumb: {
				label: '{{ "global.menu.security.noOfLoggedInUsers" | translate }}',
				parent: 'home'
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/logged-in-users-for-bank/bank-loggedin-users.html',
					controller: 'BankLoggedInUsersController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('loggedInUsersForBank');                    
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]

			}
		})

	};
})();