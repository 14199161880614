(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('UserRestriciton', UserRestriciton);

    UserRestriciton.$inject = ['$resource'];

    function UserRestriciton ($resource) {
        var resourceUrl =  'api/user-status';
        
        return $resource(resourceUrl, {}, {
        	
        	 'get': {
                 method: 'GET',
                 params: {userId:'@userId'},
                 url: 'api/user-status',                 
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
        	 },
        	 'updateUserStatus' : {
        		 method: 'POST',
                 url: 'api/user-status-change/:orgId',
                 params: {orgId:'@orgId'},
                 isArray:false,
                 transformRequest: function (data) {
                  	
                  	var copy =angular.toJson(data);
                  	
                      return copy;
                  }
        	 },'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/user-restriction',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              }
             
        })
    }
}) ();