(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AlertConfigurationDialogController', AlertConfigurationDialogController);

    AlertConfigurationDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','AlertConfiguration','entity', 'OrganisationManipulation', 'ScreenLog'];

     function AlertConfigurationDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,AlertConfiguration,entity, OrganisationManipulation, ScreenLog) {
        var vm = this;
        //vm.alertConfigurationHeader=entity;
        vm.configNotifyUsersBy = 'users';
        vm.screenName = 'AlertConfiguration';
        vm.screenLog = ScreenLog;
        vm.resubmit=resubmit;
        //ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': 0, 'action': 'ADD'});

        vm.alertConfigurationDTO={};
        //vm.alertConfigurationHeader={};
        vm.alertConfigurationNotifyUserDetails = [];
        vm.alertConfigurationNotifyByTypeDetails = [];
        vm.eventTypes = AlertConfiguration.getEventTypes();
        vm.reminderTypes = AlertConfiguration.getReminderTypes();
        vm.notifyByTypes = AlertConfiguration.getNotifyByTypes();
        vm.notifyByTypesForAllAlerts =vm.notifyByTypes;
        vm.notificationTypes = AlertConfiguration.getNotificationTypes();
        vm.natureReminderMapping = AlertConfiguration.getNatureReminderMapping();
        vm.creatorOrganisation = GlobalValues.getUserOrganisation()
        vm.ownerOrganisation = GlobalValues.getOrganisation();
        
        vm.users = AlertConfiguration.getUsersForOrganisation();

        vm.roles = AlertConfiguration.getRolesForOrganisation({"orgId" : GlobalValues.getOrganisation().id});
        vm.clear=clear;
        vm.save=save;
        $scope.clicked=false;
        $scope.showButton = false;

        if(entity!=null){
        	vm.alertConfigurationHeader=entity;
        	vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails=[];
        	vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails=[];
        	
        	vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails=vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails;
        	vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails=vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails;

        }
        else {
        	vm.alertConfigurationHeaderDTO =  angular.fromJson($rootScope.payload); 
        	vm.alertConfigurationHeader=vm.alertConfigurationHeaderDTO.alertConfigurationHeader;
        	vm.configNotifyUsersBy=vm.alertConfigurationHeader.configNotifyUsersBy;
        	// console.log(vm.alertConfigurationHeaderDTO);
            var notifyByTypeDetailsArr=vm.alertConfigurationHeaderDTO.alertConfigurationNotifyByTypeDetails;
            vm.notifyByTypeList=[];
            angular.forEach(notifyByTypeDetailsArr, function(value, key) 
            {
            	if (value.active==true){
                	vm.notifyByTypeList.push(value.notifyByType.id);
            	}
             });
            
            var notifyUserDetailsArr=vm.alertConfigurationHeaderDTO.alertConfigurationNotifyUserDetails;
            vm.userList=[];
            vm.roleList=[];
            angular.forEach(notifyUserDetailsArr, function(value, key) 
            {
            	if(value.active==true){
            		if (vm.alertConfigurationHeader.configNotifyUsersBy == 'users'){
                    	vm.userList.push(value.user.id);
            		}else{
                    	vm.roleList.push(value.organisationRole.id);
            		}
            	}
            });
            $scope.showButton=false;
        }
        
        if(workflowSharedService.viewFrom == 'alert_configuration_resubmit') {
        	var entityRecordId = 0;
        	if(vm.alertConfigurationHeader.id!=null){
        		entityRecordId = vm.alertConfigurationHeader.id;
        	}
        	vm.screenLog.screenLog(vm.screenName, entityRecordId, 'RESUBMIT');

            $scope.disableControl = true;
            vm.disableForm = false;
        	$scope.showButton=true;
        }else if(workflowSharedService.viewFrom == 'alert_configuration_resubmit_delete'){
            vm.disableForm = true;
        	$scope.showButton=true;
        }
        
        if(vm.alertConfigurationHeaderDTO==null){
        	$scope.disableControl = false;
        }
        
        
        vm.getEventTypes = function getEventTypes(notificationType){
        	if(notificationType!=null){
            	vm.eventTypesForNotificationType = null;
            	var as = $(vm.eventTypes)
    	        .filter(function (i,n){
    	            return (n.notificationType.id == notificationType.id);
    	        })
    			if (Object.keys(as).length > 3){
    	        	vm.eventTypesForNotificationType = as;
    			}
        	}
        }

        vm.getReminderTypes = function getReminderTypes(eventType){
        	if(eventType!=null){
            	vm.reminderTypesForEvent = null;
            	var reminderTypesForEventType = [];
            	var as = $(vm.natureReminderMapping)
    	        .filter(function (i,n){
    	            return (n.nature == eventType.nature);
    	        })
            	vm.reminderTypeForEvent = as;
            	if(vm.reminderTypeForEvent.length>0){
                	angular.forEach(vm.reminderTypeForEvent, function(item){
                		reminderTypesForEventType.push(item.reminderType);
                	})
                	vm.reminderTypesForEventType = reminderTypesForEventType;
            	}
        	}
        }

        vm.getNotifyByTypesForAlert = function getNotifyByTypesForAlert(alertType){
        	if(alertType != null){
        		vm.notifyByTypesForAlert = null;
        		        		
        		if(alertType.shortName == 'Upload Error Alert'){
                	var as = $(vm.notifyByTypesForAllAlerts)
        	        .filter(function (i,n){
        	            return (n.shortName == 'Email');
        	        })
                	vm.notifyByTypesForAlert = as;
                	
                	vm.notifyByTypes = vm.notifyByTypesForAlert;
        		}else{
        	        vm.notifyByTypes = vm.notifyByTypesForAllAlerts;
        		}
        	}
        } 
        
        vm.getNotifyByTypes = function getNotifyByTypes(selectedNotifyByList, notifyByType){
        	if (selectedNotifyByList.length==1 && notifyByType.shortName=='Email'){
            	var notifyByList = [];
            	notifyByList = selectedNotifyByList;
            	var as = $(vm.notifyByTypes)
    	        .filter(function (i,n){
    	            return (n.shortName == 'On app');
    	        })
    	        vm.OnAppNotifyByType = as;
            	if (vm.OnAppNotifyByType!=null){
                	if (selectedNotifyByList[0] != vm.OnAppNotifyByType[0].id){
                    	notifyByList.push(vm.OnAppNotifyByType[0].id);
                    	vm.notifyByList = notifyByList;
                	}
            	}
        	}
        }
        
        vm.clearNotifyByTypes = function clearNotifyByTypes(){
        	vm.notifyByTypeList = '';
        } 
        
        vm.flag = 0;
        vm.branchId = 0;
        vm.getUsers = function getUsers(branch){
        	if ((branch!=null && vm.flag==0) || (vm.branchId != 0 && vm.branchId != branch.id)){

        		vm.users = AlertConfiguration.getUserBranchClearances({"branchId":branch.id});
            	vm.branchId = branch.id;
            	vm.flag=1;

        	}
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        

        function save()
        {
        	vm.alertConfigurationHeader.configNotifyUsersBy = vm.configNotifyUsersBy;
        	addAlertConfigurationNotifyUserDetails();
        	
        	addAlertConfigurationNotifyByTypeDetails();
        	
        	prepareAlertConfigurationDTOToSave();
        	// console.log(vm.alertConfigurationHeader.id);
        	if(vm.alertConfigurationHeader.id!=null)
        		AlertConfiguration.update({"orgId":GlobalValues.getUserOrganisation().id}, vm.alertConfigurationDTO, onSaveSuccess, onSaveError);   
        	else
        		AlertConfiguration.save({"orgId":GlobalValues.getUserOrganisation().id}, vm.alertConfigurationDTO, onSaveSuccess, onSaveError);      		
        }
     
        function addAlertConfigurationNotifyUserDetails(){
        	if(vm.alertConfigurationHeader.configNotifyUsersBy=='users'){
            	for (var j=0; j<=vm.userList.length-1; j++){
            		if (vm.userList[j]!=null){
                		vm.filteredNotifyUser={};
                    	var as = $(vm.users)
            	        .filter(function (i,n){
            	            return (n.id == vm.userList[j]);
            	        })
            	        vm.filteredNotifyUser = as;
                		vm.alertConfigurationNotifyUserDetails.push({
                				"user":{"id":vm.filteredNotifyUser[0].id,"login":vm.filteredNotifyUser[0].login,"firstName":vm.filteredNotifyUser[0].firstName,"lastName":vm.filteredNotifyUser[0].lastName,"email":vm.filteredNotifyUser[0].email,"activated":vm.filteredNotifyUser[0].active,"langKey":vm.filteredNotifyUser[0].langKey,"resetKey":vm.filteredNotifyUser[0].resetKey,"mobileCountryCode":vm.filteredNotifyUser[0].mobileCountryCode,"mobile":vm.filteredNotifyUser[0].mobile,"userType":vm.filteredNotifyUser[0].userType,"resetDate":vm.filteredNotifyUser[0].resetDate,"active":vm.filteredNotifyUser[0].active},
                    			"organisationRole": null
                    	});
            		}
            	}
        	}
        	else{
            	for (var j=0; j<=vm.roleList.length-1; j++){
            		if (vm.roleList[j]!=null){
                		vm.filteredNotifyRole={};
                    	var as = $(vm.roles)
            	        .filter(function (i,n){
            	            return (n.id == vm.roleList[j]);
            	        })
            	        vm.filteredNotifyRole = as;
                		vm.alertConfigurationNotifyUserDetails.push({
            				"user":null,
                			"organisationRole": {"id":vm.filteredNotifyRole[0].id,"active":vm.filteredNotifyRole[0].active,"systemGenerated":vm.filteredNotifyRole[0].systemGenerated,"shortName":vm.filteredNotifyRole[0].shortName,"name":vm.filteredNotifyRole[0].name,"objectType":vm.filteredNotifyRole[0].objectType}
                		});
            		}
            	}
        	}
        }
        
        function addAlertConfigurationNotifyByTypeDetails(){
        	vm.notifyByType=[];
        	for (var j=0; j<=vm.notifyByTypeList.length-1; j++){
        		vm.filteredNotifyByType={};
            	var as = $(vm.notifyByTypes)
    	        .filter(function (i,n){
    	            return (n.id == vm.notifyByTypeList[j]);
    	        })
    	        vm.filteredNotifyByType = as;
            	vm.alertConfigurationNotifyByTypeDetails.push({
    			'notifyByType': vm.filteredNotifyByType[0]
    			});
        	}
        }
        
        function prepareAlertConfigurationDTOToSave(){
        	if (vm.alertConfigurationHeader.numberOfDaysToNotify == null || vm.alertConfigurationHeader.numberOfDaysToNotify == ""){
        		vm.alertConfigurationHeader.numberOfDaysToNotify = 0;
        	}
        	
        	vm.alertConfigurationDTO.alertConfigurationHeader=vm.alertConfigurationHeader;
        	var alertConfigurationNotifyUserDetails=[];
        	alertConfigurationNotifyUserDetails=Array.from(vm.alertConfigurationNotifyUserDetails);
        	var alertConfigurationNotifyByTypeDetails=[];
        	alertConfigurationNotifyByTypeDetails=Array.from(vm.alertConfigurationNotifyByTypeDetails);
       		
        	vm.alertConfigurationDTO.alertConfigurationNotifyUserDetails=alertConfigurationNotifyUserDetails;       		
        	vm.alertConfigurationDTO.alertConfigurationNotifyByTypeDetails=alertConfigurationNotifyByTypeDetails; 
        	vm.alertConfigurationDTO.organisationDTO = OrganisationManipulation.checkAndGetOrganisationDTO(vm.ownerOrganisation);
        }
        
        function onSaveSuccess (result) {
        	 $scope.$emit('alertRefresh');
        	 $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	
        }

        function resubmit() {
        	vm.alertConfigurationHeader.configNotifyUsersBy = vm.configNotifyUsersBy;
        	addAlertConfigurationNotifyUserDetails();
        	
        	addAlertConfigurationNotifyByTypeDetails();
        	
        	prepareAlertConfigurationDTOToSave();
        	AlertConfiguration.resubmit({"taskId" : $rootScope.taskID}, vm.alertConfigurationDTO, onResubmitSuccess, onResubmitError);
			$uibModalInstance.dismiss('cancel');
		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
		}
		function onResubmitError(error) {
		}
     }   
})();
