(function () {
	'use strict';
	angular
		.module('gatewayApp')
		.factory('OTRSummaryForBankView', OTRSummaryForBankView);

	OTRSummaryForBankView.$inject = ['$resource'];

	function OTRSummaryForBankView($resource) {
		var resourceUrl = 'api/otr-summary-for-bank/:id';

		return $resource(resourceUrl, {}, {

			'getOTRSummaryForBankReport': {
				method: 'GET',
				isArray: true,
				params: { bankId: '@bankId', branchId: '@branchId', fromDate: '@fromDate', toDate: '@toDate' },
				url: 'api/otr-summary/get-otr-summary-details-for-bank'
			},
			'exportOTRSummaryForBankExcel': {
				method: 'POST',
				url: 'api/otr-summary-for-bank/excel-download',
				responseType: 'arraybuffer',
				params: { bankId: '@bankId', branchId: '@branchId', fromDate: '@fromDate', toDate: '@toDate' },
				transformRequest: function (data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				},
				transformResponse: function (data, headers) {
					return {
						data: data,
						headers: headers
					};
				}
			},
			'exportOTRSummaryForBankCsv': {
				method: 'POST',
				url: 'api/otr-summary-for-bank/csv-download',
				responseType: 'arraybuffer',
				params: { bankId: '@bankId', branchId: '@branchId', fromDate: '@fromDate', toDate: '@toDate' },
				transformRequest: function (data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				},
				transformResponse: function (data, headers) {
					return {
						data: data,
						headers: headers
					};
				}
			},


		});
	}
})();