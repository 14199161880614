(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('RefreshLimit', RefreshLimit);

        RefreshLimit.$inject = ['$resource'];

    function RefreshLimit($resource) {
    	 var resourceUrl =  '';
        

         return $resource(resourceUrl, {}, {
         'ProcessRefreshLimits': {
            method: 'POST',
            params: {bankId:'@bankId',customerId:'@customerId'},
            url: 'order/refresh-limits-utilisation-details/bank/:bankId/customer/:customerId',
            transformRequest: function (data) {
                var copy = angular.toJson(data);
                return copy;
            }/* ,
            transformResponse: function(data, headers) {
                return {
                    data: angular.fromJson(data),
                    headers: headers
                };   
            }      */         
        },
        
        'ProcessRefreshLimtisForAllCustomers': {
            method: 'POST',
            url: 'order/refresh-limits-utilisation-details',
            transformRequest: function (data) {
                var copy = angular.toJson(data);
                return copy;
            }/* ,
            transformResponse: function(data, headers) {
                return {
                    data: angular.fromJson(data),
                    headers: headers
                };   
            }   */             
        },



        'ProcessRefreshLimtisForBankWiseCustomers': {
            method: 'POST',
            params: {bankId:'@bankId'},
            url: 'order/refresh-limits-utilisation-details/bank/:bankId',
            transformRequest: function (data) {
                var copy = angular.toJson(data);
                return copy;
            }/* ,
            transformResponse: function(data, headers) {
                return {
                    data: angular.fromJson(data),
                    headers: headers
                };   
            }     */            
        }

    });
    
  }
        
})();