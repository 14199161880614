(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manage-faq', {
            parent: 'entity',
            url: '/manage-faq',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.managefaq" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/manage-faq/faq-details.html',
                    controller: 'FaqDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('faq');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })

.state('manage-faq.new', {
    parent: 'manage-faq',
    url: '/new',
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'global.title'
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-dialog.html',
            controller: 'FaqController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
            	entity: function () {                        	
                    return {
                    	 id:null,  
                    	name:null
                    	 
                    	
                     
                    };
                }
            }
/*        }).result.then(function() {
            $state.go('manage-faq', null, { reload: 'manage-faq' });
        }, function() {
            $state.go('^');
        });*/
        }).result.then(function() {
            $state.go('manage-faq');
        }, function() {
            $state.go('manage-faq');
        });
    }]
    
})

.state('manage-faq-category', {
    parent: 'manage-faq',
    url: '/category-new',
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'global.title'
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-category-dialog.html',
            controller: 'FaqCategoryController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'sm',
            resolve: {
            	entity: function () {                        	
                    return {
                    	 id:null,  
                    	name:null
                    };
                }
            }
        }).result.then(['$rootScope', function($rootScope) {
        	$state.go('manage-faq');
        }], function() {
        	$state.go('manage-faq');
        });
    }]
    
})

.state('manage-faq.edit', {
    parent: 'manage-faq',
    url: '/{id}/edit',
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'global.title'
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-edit-dialog.html',
            controller: 'FaqEditController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
//            resolve: {
//            	
//                    	 entity: ['Faq', 'GlobalValues', function(Faq, GlobalValues) {
//                             return Faq.get({id : $stateParams.id}).$promise;
//                         }]
//               
//            }
        }).result.then(function() {
            $state.go('manage-faq');
        }, function() {
            $state.go('manage-faq');
        });
    }]
    
})
.state('manage-faq.view', {
    parent: 'manage-faq',
    url: '/view',
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'global.title'
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-view-dialog.html',
            controller: 'FaqViewController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
//            resolve: {
//            	
//                    	 entity: ['Faq', 'GlobalValues', function(Faq, GlobalValues) {
//                             return Faq.get({id : $stateParams.id}).$promise;
//                    	 }]
//               
//            }
        }).result.then(function() {
            $state.go('manage-faq');
        }, function() {
            $state.go('manage-faq');
        });
    }]
    
})
.state('manage-faq.delete', {
    parent: 'manage-faq',
    url: '/{id}/delete',
    data: {
        authorities: ['ROLE_USER']
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-delete-dialog.html',
            controller: 'FaqDeleteController',
            controllerAs: 'vm',
            size: 'md',
//            resolve: {
//                entity: ['Faq', 'GlobalValues', function(Faq, GlobalValues) {
//                    return Faq.get({id : $stateParams.id}).$promise;
//                }]
//            }
//        }).result.then(function() {
//            $state.go('^', null, { reload: true });
//            $rootScope.$emit('gatewayApp:reloadFAQPage');
//        }, function() {
//            $state.go('^');
//        });
        }).result.then(function() {
            $state.go('manage-faq');
        }, function() {
            $state.go('manage-faq');
        });
}]
})
/*.state('manage-faq-todolist', {
    parent: 'dashboard',
    url: '/faqview',
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'global.title'
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-view-dialog.html',
            controller: 'FaqViewController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
            	entity: function () {                        	
                    return {
                    		
                    };
                }
            }
        }).result.then(function() {
        	$state.go('manage-faq', null, { reload: 'manage-faq' });
        }, function() {
            $state.go('^');
        });
    }]
    
})*/

        .state('manage-faq-openvideo', {
            parent: 'manage-faq',
            url: '/manage-faq/{videofilename}',
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.managefaq" | translate }} ',
                parent: 'manage-faq'
              },
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/search-faq/faq-video.html',
                    controller: 'FaqVideoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	return {videoName : $stateParams.videofilename};
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]

            })

.state('manage-faq-todolist.resubmit', {
    parent: 'dashboard',
    url: '/faqedit',
    data: {
        authorities: ['ROLE_USER']
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/components/manage-faq/faq-dialog.html',
            controller: 'FaqController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
              entity: function () {                        	
                   /* return {
                        shortName: null,                            	
                        name: null,
                        effectiveFrom: null,
                        validTill: null,
                        permittedTradingPosition: null,
                        permittedOpenExposure: null,
                        isDefault: null,
                        id: null	                                                  
                    };*/
                }
            }
        }).result.then(function() {
            $state.go('manage-faq', null, { reload: 'manage-faq' });
        }, function() {
            $state.go('^');
        });
    }]
})
};
})();