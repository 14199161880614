(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
    	 $stateProvider
         .state('view-relationship-fees-charges', {
             parent: 'entity',
             url: '/relationship-bank-fees-charges',
             data: {
            	 authorities: ['ROLE_USER'],
                 pageTitle: 'global.title'
             },
             ncyBreadcrumb: {
                 label: '{{ "global.menu.relationshipBankFeesAndCharges.main" | translate }}',
                 parent: 'home'
             },
             views: {
                 'content@': {
                     templateUrl: 'app/entities/relationship-bank-fees-charge-view/relationship-bank-fees-charge-view.html',
                     controller: 'RelationshipBankFeesChargesViewController',
                     controllerAs: 'vm'
                 }
             },
             resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                 	$translatePartialLoader.addPart('error');
                     $translatePartialLoader.addPart('global');       
                 	$translatePartialLoader.addPart('relationshipBankFeesChargesView');
                
                     return $translate.refresh();
                 }],
                 entity: function(){
                 	
                 }
             }
         })
     
         
    };
    })();
