(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('IntradayMarketSessionDialogController', IntradayMarketSessionDialogController);

    IntradayMarketSessionDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','Workflow','$uibModalInstance',
    				'IntradayMarket','entity','StaticLookUp','DateUtils','AlertService','$filter'];

     function IntradayMarketSessionDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService,Workflow, $uibModalInstance,
    		 			IntradayMarket,entity,StaticLookUp,DateUtils,AlertService,$filter) {
        var vm = this;
        vm.intradayMarket=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitIntradayMarket = resubmitIntradayMarket;
        vm.globalValues = GlobalValues;
        vm.discardIntradayMarket = discardIntradayMarket;
        vm.rejectIntradayMarket= rejectIntradayMarket;
        vm.approveIntradayMarket= approveIntradayMarket;
       vm.viewMode=false;
      //vm.controlMinDate =new Date();
      vm.controlMinDate =vm.globalValues.getSystemSettings().businessDate;
      vm.validateSessionTime = validateSessionTime;
                
        if(workflowSharedService.viewFrom == 'intradayMarketSessionSettings'){
        	vm.intradayMarket=entity;
        	
        	vm.intradayMarket.businessDate = new Date(vm.intradayMarket.businessDate);
        	vm.openTime = moment(vm.intradayMarket.openTime, 'HH:mm');
        	vm.closeTime = moment(vm.intradayMarket.closeTime,'HH:mm');
        	
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'intradayMarketSessionSettings_todolist_resubmit') {
        	vm.intradayMarket =  angular.fromJson($rootScope.payload);
        	vm.intradayMarket.businessDate = new Date(vm.intradayMarket.businessDate);
        	vm.openTime = moment(vm.intradayMarket.openTime, 'HH:mm');
        	vm.closeTime = moment(vm.intradayMarket.closeTime,'HH:mm');
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'intradayMarketSessionSettings_todolist_checker') {
        	vm.intradayMarket =  angular.fromJson($rootScope.payload); 
        	vm.intradayMarket.businessDate = new Date(vm.intradayMarket.businessDate);
        	vm.openTime = moment(vm.intradayMarket.openTime, 'HH:mm');
        	vm.closeTime = moment(vm.intradayMarket.closeTime,'HH:mm');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'intradayMarketSessionSettings_todolist_maker') {
        	vm.intradayMarket =  angular.fromJson($rootScope.payload); 
        	vm.intradayMarket.businessDate = new Date(vm.intradayMarket.businessDate);
        	vm.openTime = moment(vm.intradayMarket.openTime, 'HH:mm');
        	vm.closeTime = moment(vm.intradayMarket.closeTime,'HH:mm');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='intradayMarketSessionSettings_auditlog'){
        	vm.intradayMarket =  angular.fromJson($rootScope.payload);   
        	vm.intradayMarket.businessDate = new Date(vm.intradayMarket.businessDate);
        	vm.openTime = moment(vm.intradayMarket.openTime, 'HH:mm');
        	vm.closeTime = moment(vm.intradayMarket.closeTime,'HH:mm');
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	if(vm.intradayMarket.sessionStatus != 'OPEN' && !vm.validateSessionTime(vm.openTime)){
        		 AlertService.error($filter('translate')('gatewayApp.intradayMarket.pastOpenTime'));
        		 vm.clicked = false;
        	}
        		
        	else if(!vm.validateSessionTime(vm.closeTime)){
        		AlertService.error($filter('translate')('gatewayApp.intradayMarket.pastCloseTime'));
        		vm.clicked = false;
        	}
        		 
        	else{
        		
        		vm.intradayMarket.openTime = vm.openTime.format( 'HH:mm');
            	vm.intradayMarket.closeTime = vm.closeTime.format( 'HH:mm');
            	
            	
                    	IntradayMarket.update({
            					"orgId" : GlobalValues.getOrgId()
            				}, vm.intradayMarket, onSaveSuccess, onSaveError);
        	}
        	
        			
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitIntradayMarket() {
        	
        	if(!vm.validateSessionTime(vm.openTime)){
        		 AlertService.error($filter('translate')('gatewayApp.intradayMarket.pastOpenTime'));
        		 vm.clicked = false;
        	}
       		
       	else if(!vm.validateSessionTime(vm.closeTime)){
       	 AlertService.error($filter('translate')('gatewayApp.intradayMarket.pastCloseTime'));
       	 vm.clicked = false;
       	}
       		
       	else{
       		
       		vm.intradayMarket.openTime = vm.openTime.format( 'HH:mm');
        	vm.intradayMarket.closeTime = vm.closeTime.format( 'HH:mm');
        	
        			IntradayMarket.resubmit({"taskId" : $rootScope.taskID}, vm.intradayMarket, onResubmitSuccess, onResubmitError);
                	
               		
                	$uibModalInstance.dismiss('cancel');
       	}
        	
        	
        	
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {}

        function approveIntradayMarket() { 
        	IntradayMarket.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectIntradayMarket() { Workflow.rejectTask($rootScope.taskID); }

        function discardIntradayMarket() {
        	IntradayMarket.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
        
        
        function validateSessionTime(inputTime){
        	
        	var isValidOpenTime = true;
        	 vm.currentTimeMoment = moment(new Date(), 'HH:mm A').format('HH:mm A');
      	   var currentTime = moment(vm.currentTimeMoment, 'HH:mm A');
      	   
      	  if(currentTime.isAfter(moment(inputTime, 'HH:mm A'))){
      		  	isValidOpenTime = false;
			}
      	  
      	  return isValidOpenTime;
        }
        	
        

     }   
})();
