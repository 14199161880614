(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('GlobalOrganisationTypeRole', GlobalOrganisationTypeRole);

    GlobalOrganisationTypeRole.$inject = ['$resource'];

    function GlobalOrganisationTypeRole ($resource) {
        var resourceUrl =  'api/orgId/:orgId/global-organisation-type-role/:id';

        return $resource(resourceUrl, {}, {
        	'queryAll': { method: 'GET', isArray: true,
        		params: {orgTypeId:'@orgTypeId',customerUserTypeId:'@customerUserTypeId' },
        	    url: 'api/global-organisation-type-role/orgTypeId/:orgTypeId/customerUserTypeId/:customerUserTypeId'
        	 },
        	 'rolesAllForOrganisationType': { method: 'GET', isArray: true,
          		params: {orgTypeId:'@orgTypeId',custUserTypeID: '@custUserTypeId'},
          	    url: 'api/orgTypeId/:orgTypeId/custUserTypeId/:custUserTypeId/rolesAllForOrganisationType'
          	 }
        });
    }
})();
