(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'heStatusUpdate'
        })
        
        .controller('HEStatusUpdateController', HEStatusUpdateController);

    HEStatusUpdateController.$inject = ['$scope', '$state',  'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout',
    		'HeConnectivity','MessageBox','$rootScope','AlertService','$filter'];

    function HEStatusUpdateController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,HeConnectivity,
    			MessageBox,$rootScope,AlertService,$filter) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.HE_STATUS_UPDATE;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        workflowSharedService.viewFrom = "heStatusUpdate";
        vm.screenLog = ScreenLog;
        var yesMessageEvent = null;
        var statusUpdateEvent=null;
        logScreenAccess();
        loadAll();
        vm.sendHEStatusUpdate = sendHEStatusUpdate;
        
        vm.todayDate = new Date();
      
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function loadAll(){
    	   HeConnectivity.query({"orgId": GlobalValues.getOrgId()},function(result) {
               vm.currentStatus = result[0].interactiveStatus;
               
               if(vm.currentStatus=='Online'){
        		   vm.changedStatus='Offline';
        	   }else{
        		   vm.changedStatus='Online';
        	   }
               
            }); 
    	   
    	   
       }
       
       function sendHEStatusUpdate(){
    	   $scope.status = vm.changedStatus;
    	   MessageBox.openMessageBox("Alert", "Do you want to change the HE Interactive Status?", "YesNo", "changeHEStatus");
       }
       
       $scope.$on('$destroy', function() {
 		  if (yesMessageEvent!=null )
 			  	yesMessageEvent();
	        });
   
 		
    	 yesMessageEvent = $rootScope.$on('gatewayApp.messagebox.yes.changeHEStatus', function (event, data) {
 			if($scope.status!=null && $scope.status!=undefined){
 				vm.heStatusUpdate = "PROGRESS";
 				HeConnectivity.changeStatus({status : $scope.status},changeStatusSuccess,changeStatusFailure);
 				
 			}
 		});
 	
 
		$rootScope.$on('gatewayApp.messagebox.no.changeHEStatus', function (event, data) {
			//do nothing
		});
		
		function changeStatusSuccess(){
			
		}
		
		function changeStatusFailure(error){
			vm.heStatusUpdate = "";
			if(error.status==503){
				
				AlertService.error($filter('translate')( "error.order.serviceUnavailable"));
			}else if(error.status==403){
				//since session expiry  popup is diplayed do not show any error for 403 status
				//AlertService.error($filter('translate')( "Service Unavailable"));
			}
			else{
				AlertService.error($filter('translate')( error.data));
			}
		}
		
		 statusUpdateEvent = $rootScope.$on('heStatusUpdate', function (event, data) {
	 			if(data!=null){
	 				if(data.message=="success"){
	 					//vm.heStatusUpdate = "SUCCESS";
	 					vm.heStatusUpdate = "";
	 					console.log("He connection successful " + data.message);
	 					AlertService.success($filter('translate')('gatewayApp.heStatusUpdate.success'));
	 					 loadAll();
	 				}else{
	 					//vm.heStatusUpdate = "FAILURE";
	 					vm.heStatusUpdate = "";
	 					console.log("He connection failed " + data.message);
	 					AlertService.error(data.message);
	 					//AlertService.error($filter('translate')('gatewayApp.heStatusUpdate.error'));
	 				}
	 			}
	 			
	 		});
	 	
		 $scope.$on('$destroy', function() {
	 		  if (statusUpdateEvent!=null )
	 			 statusUpdateEvent();
		        });
     
      
    }
})();
