(function () {
	'use strict';

	angular
		.module('gatewayApp')
		.controller('EODProcessController', EodProcessController);

	EodProcessController.$inject = ['$scope', '$state', '$stateParams', '$rootScope', 'GlobalValues', 'DateUtils', 'AlertService','EODProcess','MessageBox',
            '$filter','$timeout', 'ScreenLog','entityConstants','StaticLookUp','SODProcess', '$interval', '$fileLogger', 'FileUtil','EntityPermissions','Auth'];

	function EodProcessController($scope, $state, $stateParams, $rootScope, GlobalValues, DateUtils, AlertService,EODProcess,MessageBox,
            $filter,$timeout , ScreenLog,entityConstants,StaticLookUp, SODProcess, $interval, $fileLogger, FileUtil,EntityPermissions,Auth) {

		var vm = this;
		vm.todayDate;
		vm.nextDate;
		vm.currentBusinessDate;
		vm.nextBusinessDate;
		$fileLogger.setTimestampFormat('dd-MMM-yyyy HH:mm:ss:sss', '+0530');
		$fileLogger.info('eod screen accessed');
		vm.globalValues = GlobalValues;
		vm.beginEODProcess = beginEODProcess;		
		var convertedNextBusinessDate ;
		var convertedCurrentBusinessDate ;
		$scope.refresh = refresh;
		vm.today = new Date();
		vm.convertedTodayDate = DateUtils.convertLocalDateToServer(vm.today);
		vm.validateEod = validateEod;
		vm.validateInteractiveServerDbProcessing = validateInteractiveServerDbProcessing;
		vm.validateBroadcastServerDbProcessing = validateBroadcastServerDbProcessing;
		vm.isEODvalidated=false;
		vm.validation=vm.NOTSTARTED;
		vm.validateBroadcastDBProcessing=vm.NOTSTARTED;
		vm.validateInteractiveDBProcessing=vm.NOTSTARTED;
		vm.hideCheckList=false;
		vm.showEodProcess=false;
		vm.eodDone=false;
		vm.disbleEod = true;
		vm.eodStatus='';
		vm.isRefreshDisabled=false;
		vm.isEodProcessSuccessfull=null;
		vm.isEodCompletedForOrder=false;
		vm.isEodCompleted = false;
		vm.processStatusMap = {};
		vm.newProcessStatusMap = {};
		vm.today = new Date();
		vm.errorMessage = "";
		vm.logOffId = 0;
		vm.reconOrderReqId = 0;
		vm.reconOrderResponseId = 0;
		vm.reconTradeReqId = 0;
		vm.reconTradeResId = 0;
		vm.userLogoffReqId = 0;
		vm.userLogoffResponseId = 0;
		vm.luddlogOffReq = 0;
		vm.luddlogoffRespnse = 0;
		vm.disconnectinteractiveId = 0;
		vm.disconnectboradcastId = 0;
		vm.clearRvId = 0;
		vm.dormantId = 0;
		vm.autowithdrawalId = 0;
		vm.eodId = 0;
		vm.limitId = 0;
		vm.archiveId = 0;
		vm.shiftDateId = 0;
		vm.prepareNextdateDataId = 0;
		vm.logOffEndId = 0;
		vm.dailyTransactionId = 0;
		vm.monthEndBillingId = 0;
		vm.yearEndBillingId = 0;
		vm.holidayRequestId = 0;
		vm.holidayResponseId = 0;
		vm.updateTradingBranchId = 0;
		vm.sessionCloseMessage = "";
		vm.sessionCloseValidation = "";
		vm.responseReceivedValidation = "";
		vm.responseReceivedMessage="";
		vm.idArray = [];

		
		vm.connectToMQID = 0;
		vm.executionCount = 0;
		vm.revokeProcessId;
		vm.eodProcessList = [];
		vm.eodProcessListFromDB = [];
		vm.newProcessResultMap = {};
		vm.processResultMap = {};
		vm.processErrorMap = {};
		vm.newProcessErrorMap = {};
		vm.errorList = {};
		var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
		vm.processId;
		vm.showResumeButton=false;
		var orderServiceEODProcessUpdateSubscription = null;
		
		
		/************* Constants to be used in HTML file for displaying progress icons ********************/

		vm.INPROGRESS = "INPROGRESS";
		vm.COMPLETED = "COMPLETED";
		vm.FAILED = "FAILED";
		vm.NOTSTARTED = "NOTSTARTED";
		vm.OPEN = "TO_BE_INITIATED";
		vm.INCOMPLETE = "INCOMPLETE";
		 vm.permitEODProcessAddEntityAction = EntityPermissions.hasPermission('EODProcess.CREATE');
		// if(vm.permitEODProcessAddEntityAction ==false){
		// 	//message box 
		// 	MessageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'permitEODProcessAddEntityAction');
		// }

		// $rootScope.$on('gatewayApp.messagebox.ok.permitEODProcessAddEntityAction', function (event) {
		// 	Auth.sessionlogout();
		// });
		
		// $rootScope.$on('gatewayApp.messagebox.clear.permitEODProcessAddEntityAction', function (result){
		// 	Auth.sessionlogout();
		// })
		
		
		logScreenAccess();
		loadDates();
		loadErrorMEssages();
	
		/** set Intial State and initial status for each process and load the new businessDates *****/
		function loadDates() {
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
		}
		
		function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.eodStatus=vm.lastSystemSetting.eodStatus;
			vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
			vm.todayDate = $filter('date')(vm.currentBusinessDate,"dd/MM/yyyy");
			vm.todayDate = vm.todayDate + " | " + days[vm.currentBusinessDate.getDay()];
			convertedCurrentBusinessDate = DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
            $fileLogger.info('loadSystemSettingsSuccess - getNextValidBusinessDate');
			EODProcess.getNextValidBusinessDate( {
				"currentBusinessDate" : convertedCurrentBusinessDate
			} , getNextBusinessDateSuccess, getNextBusinessDateError);
			vm.disbleEod = disableEodProcess();
			
			/*if(vm.eodStatus == vm.COMPLETED){
					AlertService.error($filter('translate')('gatewayApp.eodProcess.alreadyCompleted'));
			}else if(vm.eodStatus == vm.INPROGRESS){
					
					AlertService.error($filter('translate')('gatewayApp.eodProcess.alreadyInProgress'));
			}*/
			loadWorkFlowItems();
		}
		function loadSystemSettingsError(){
			
		}
		/*********** Set Initial status as NOTSTARTED for each process on page laod *****************/
		
		 function loadWorkFlowItems(){
       	 StaticLookUp.getEodWorkFlow(lookupWorkFlowSuccess, lookupWorkFlowFailure);
        }
        function lookupWorkFlowSuccess(result){
        	
        	vm.eodProcessListFromDB = result;
        	vm.eodProcessList = [];
        	console.log(vm.eodProcessListFromDB );
        	vm.idArray = [];
        	angular.forEach(vm.eodProcessListFromDB, function(item){               
                vm.idArray.push( item.id);               
            });
        	
   	    	vm.eodProcessList = angular.copy(vm.eodProcessListFromDB);   	    	
   	    	
   	   
          	if(vm.eodStatus == vm.OPEN || vm.eodStatus==vm.INCOMPLETE || vm.eodStatus==vm.INPROGRESS){
          		
          		 angular.forEach( vm.eodProcessList, function(item){
          		 
	          		 if(item.processName=='ForciblelogoffUsers'){
	          			 vm.logOffId = item.id;
	          		 }else if(item.processName=='ReconOrderDetailsRequest'){
	          			 vm.reconOrderReqId = item.id;
	          		 }       		 
	          		 else if(item.processName=='MakeUsersDormant'){
	        			 vm.dormantId=item.id;
	          		 }
	          		 else if(item.processName=='AutowithdrawalOfPendingApprovalCustomers'){
	        			 vm.autowithdrawalId=item.id;
	        		 }
	          		 else if(item.processName=='GenerateEODEmails'){
	        			 vm.eodId=item.id;
	        		 }
	          		 else if(item.processName=='ProcessLimitsAtEOD'){
	        			 vm.limitId=item.id;
	        		 }
	          		 else if(item.processName=='ArchiveTablesAtEOD'){
	          			 vm.archiveId=item.id;
	          		 }
	          		 else if(item.processName=='ComputeDailyTransactionCharges'){
	          			vm.dailyTransactionId=item.id;
	          		 }
	          		 else if(item.processName=='GenerateMonthEndBillingReport'){
	          			vm.monthEndBillingId=item.id;
	          		 }
	          		 else if(item.processName=='GenerateYearEndBillingReport'){
	          			vm.yearEndBillingId=item.id;
	          		 }	
	          		 else if(item.processName=='UpdateNewTradingBranchForCustomers'){
	          			vm.updateTradingBranchId=item.id;
	          		 }
	          		 else if(item.processName=='ShiftingTheSystemDate'){
	          			vm.shiftDateId=item.id;
	          		 }
	          		 else if(item.processName=='PrepareNextBusinessDateData'){
	          			vm.prepareNextdateDataId=item.id;
	          		 }	          		 
	          		 item.status = vm.NOTSTARTED;
	          		 item.remarks='';	       		
	          		 vm.newProcessResultMap[item.id] = null;
	          		 vm.processResultMap[item.id] = null;
	          		 vm.processErrorMap[item.id] = null;
	          		 vm.newProcessErrorMap[item.id] = null;
          		 });
          	}
          	if(vm.eodStatus==vm.INPROGRESS || vm.eodStatus==vm.INCOMPLETE){
                $fileLogger.info('lookupWorkFlowSuccess getEODProcessStatusLog');
          		 EODProcess.getEODProcessStatusLog({
    				"businessDate" : convertedCurrentBusinessDate
    			} ,getEODProcessStatusLogSuccess,getEODProcessStatusLogFailure);
          		vm.showResumeButton=true;
          		vm.showEodProcess=true;
          		vm.isEODvalidated=true;
          	 }
        
        }
        
	function getEODProcessStatusLogSuccess(result){
		
		var processIndex = vm.idArray.indexOf(result.processId);
   		angular.forEach(vm.eodProcessList,function(item,index){
   			if(index < processIndex)
   			item.status = vm.COMPLETED;
   		})
		
	}
	
	function getEODProcessStatusLogFailure(){}
	
        function reloadListAndMap(){
        	
        	vm.eodProcessList = angular.copy(vm.eodProcessListFromDB);
        	vm.idArray = [];
        	angular.forEach(vm.eodProcessListFromDB, function(item){               
                vm.idArray.push( item.id);               
            });
        	
       	 	angular.forEach( vm.eodProcessList, function(item){
	       	 	 if(item.processName=='ForciblelogoffUsers'){      			
	       			 vm.logOffId = item.id;
	      		 }else if(item.processName=='ReconOrderDetailsRequest'){       			
	      			 vm.reconOrderReqId = item.id;;
	       		 }
	      		 else if(item.processName=='MakeUsersDormant'){
	    			 vm.dormantId=item.id;;
	    		 }
	      		 else if(item.processName=='AutowithdrawalOfPendingApprovalCustomers'){
	    			 vm.autowithdrawalId=item.id;;
	    		 }
	      		 else if(item.processName=='GenerateEODEmails'){
	    			 vm.eodId=item.id;;
	    		 }
	      		 else if(item.processName=='ProcessLimitsAtEOD'){
	    			 vm.limitId=item.id;
	    		 }
	      		 else if(item.processName=='ArchiveTablesAtEOD'){
	      			 vm.archiveId=item.id;
	      		
	      		 }else if(item.processName=='ComputeDailyTransactionCharges'){
	      			 vm.dailyTransactionId=item.id;
	      		 }
	      		 else if(item.processName=='GenerateMonthEndBillingReport'){
	      			 vm.monthEndBillingId=item.id;
	      		 }
	      		 else if(item.processName=='GenerateYearEndBillingReport'){
	      			 vm.yearEndBillingId=item.id;
	      		 }
	      		 else if(item.processName=='UpdateNewTradingBranchForCustomers'){
	      			 vm.updateTradingBranchId=item.id;
	      		 }
	      		 else if(item.processName=='ShiftingTheSystemDate'){
	      			 vm.shiftDateId=item.id;
	      		 }
	      		 else if(item.processName=='PrepareNextBusinessDateData'){
	      			 	vm.prepareNextdateDataId=item.id;
	      		 }
       	 	});
       	 	// vm.processStatusMap =  angular.copy(vm.newProcessStatusMap);
       	 	vm.processResultMap = angular.copy(vm.newProcessResultMap);
       	 	vm.processErrorMap = angular.copy(vm.newProcessErrorMap);
       	 
        }
        
        function lookupWorkFlowFailure(error){
       	 //do nothing
        }
        
        /**********************************END********************************************************/
		/******** FETCH ERROR MESSAGE AND SET TO GLOBAL VARIABLE *************************************/
         function loadErrorMEssages(){
        	 StaticLookUp.getProcessErrorMessages(loadErrorMEssagesSuccess, loadErrorMEssagesFailure);
         }
         function loadErrorMEssagesSuccess(result){
        	// vm.globalValues.checkAndSetSelectedErrorMessages(result);        	
        	 vm.errorList  = result[0];
         }
         function loadErrorMEssagesFailure(error){
        	 //do nothing
         }
         /****************load config for maximumWaitingtime to get response from orderservice , define timer to check whether response is received in time *******************/
 				
 		/*vm.enableTimer = false;
 		vm.maximumWaitTimeForOrderServiceResponse = 0 ; 
 		vm.latestOrderSeviceRequestTimestamp = 0 ; 
 		vm.latestProcess = 0;
 		vm.configSettings = vm.globalValues.getConfigSettings();
 		
 		var maximumWaitTimeForResponseString  = vm.configSettings.timerIntervalforSODEODResponseWait;
 		if(maximumWaitTimeForResponseString!=null && maximumWaitTimeForResponseString!=undefined && maximumWaitTimeForResponseString.trim()!=''){
 			
 			vm.maximumWaitTimeForOrderServiceResponse = parseInt(maximumWaitTimeForResponseString, 10) * 1000;
 			//vm.maximumWaitTimeForOrderServiceResponse = 60*1000;
 		}else{
 			
 			vm.maximumWaitTimeForOrderServiceResponse = 60000;//default 60 secs
 		}
 		
 		vm.responseTimeCheckInterval = vm.maximumWaitTimeForOrderServiceResponse/2;
 		
 		$scope.checkForResponseTimeout = function() {
 			
 	        console.log("$scope.callAtInterval - Interval occurred");
 	        var currentTimestamp = moment().valueOf();
 	        if( (vm.enableTimer == true) && ( (currentTimestamp - vm.latestOrderSeviceRequestTimestamp ) >  vm.maximumWaitTimeForOrderServiceResponse )){
 	        	
 	        	// update the eod Process status log = INCOMPLETE
 	        	if(vm.latestProcess!=null && vm.latestProcess!=undefined && vm.latestProcess.status == vm.INPROGRESS){
 	        		updateRemarksInProcessList(vm.latestProcess.id ,"Response timeout occurred",  vm.FAILED);
 	        		EODProcess.insertProcessLog({"currentBusinessDate" : convertedCurrentBusinessDate,
 	    				"processId" : vm.latestProcess.id,
 	    				"executionCount" : vm.executionCount,
 	    				"errorMessageCode" : "7001",
 	    				"isSuccess" : false});
 	        	}
 	        	vm.eodStatus = vm.INCOMPLETE;
 				vm.disbleEod = disableEodProcess();			
 				
 				EODProcess.processEOD( {				
 					"businessDate" : convertedCurrentBusinessDate,
 					"status" : vm.INCOMPLETE,
 					"nextBusinessDate" : convertedNextBusinessDate
 				}, endEODInUISuccess, endEODInUIError);
 	        	
 	        }
 	    }
 		

 	 	$interval( function(){ 
 	 		$scope.checkForResponseTimeout(); 
 	 		}, vm.responseTimeCheckInterval);
 	 	
 		function endEODInUISuccess(result){
 			
 			vm.enableTimer = false;
 			//destroy the event 
 			unsubscribeToEODProcessUpdate();	

 			// send kafka message to order service to stop the further process.
 			EODProcess.stopEODForcibly();
 			
 			
 			
         	
 		}
 	
 		function endEODInUIError(error){
 			//DO NOthing			
 			unsubscribeToEODProcessUpdate();		
 		}*/
 		/***************************************************************************/
      // function to start the EOD process on start button click
 		function beginEODProcess(actionType) {
 			 $fileLogger.info(actionType + ' Clicked');
 			vm.isRefreshDisabled = false;
 			vm.showEodProcess=true;
 			vm.isEodProcessSuccessfull=null;
 			vm.isEodCompleted = false;
 			//reloadListAndMap();
 			if(vm.currentBusinessDate !=null && vm.currentBusinessDate !=undefined && vm.currentBusinessDate !=''){
 				if ( vm.currentBusinessDate > vm.today ) {
 					
 					AlertService.error($filter('translate')('gatewayApp.eodProcess.forwardDateError'));
 					
 				}else if(vm.eodStatus == vm.COMPLETED){
 					AlertService.error($filter('translate')('gatewayApp.eodProcess.alreadyCompleted'));
 				}
 				else {
 					//call the first process
					$rootScope.eodInProgress = true;
 					processeod();
 					//logOffUsersForcibly();
 					
 				}
 			}
 			

 		}
        
 		/*********************** Process methods before starting the eod process ********************************************/
		var processeod = function processeod(){
					
                $fileLogger.info('processEOD');
				EODProcess.processEOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.INPROGRESS ,
				"nextBusinessDate": convertedNextBusinessDate
				}, processeodSucess, processeodError);
		
			
		}
		
		function clearPreviousStatus(){
			
			var indexOfRevokeProcess = vm.idArray.indexOf(vm.revokeProcessId);
			 
			 angular.forEach(  vm.eodProcessList, function(item,index){
				 if(index >= indexOfRevokeProcess){
					 item.status = vm.NOTSTARTED;
					 item.remarks='';
					 vm.processResultMap[ item.id] = null;	
				 }else{
					item.status = vm.COMPLETED;
					item.remarks='';
				 }
			 });
		}
	
		function updateEODLogSuccess(result){
			vm.executionCount = parseInt(result.executionCount);
			vm.executionCount = vm.executionCount+1;
			vm.revokeProcessId = parseInt(result.revokeProcessId);
			$fileLogger.info('updateEODLogSuccess execution count -' + vm.executionCount + ' vm.revokeProcessId' + vm.revokeProcessId);
			clearPreviousStatus();
			if(vm.eodStatus == vm.INPROGRESS && vm.revokeProcessId != vm.dormantId && vm.revokeProcessId != vm.logOffId){
					
					AlertService.error($filter('translate')('gatewayApp.eodProcess.alreadyInProgress'));
			}else{
			
				vm.eodStatus = vm.INPROGRESS ;
				vm.disbleEod = true;	
				console.log('EOD procss resumed with process id '+ vm.revokeProcessId);
				$fileLogger.info('EOD procss resumed with process id' + vm.revokeProcessId);
			if(vm.revokeProcessId == vm.logOffId || vm.revokeProcessId == 0){
				successProcessor(logOffUsersForcibly);
			}if(vm.revokeProcessId == vm.reconOrderResponseId ){
				successProcessor(startEODInOrderService);
			}else if(vm.revokeProcessId == vm.dormantId){
				console.log('calling make user dormant method - from Resume EOD');
				successProcessor(MakeUsersDormant);
			}else if(vm.revokeProcessId == vm.autowithdrawalId){
				successProcessor(autoWithrdrawPendingCutomerRegistration);
			}else if(vm.revokeProcessId == vm.eodId ){
				successProcessor(generateEODEmails);
			}else if(vm.revokeProcessId == vm.limitId ){
				successProcessor(processLimitsAtEOD);
			}else if(vm.revokeProcessId == vm.archiveId){
				successProcessor(archiveTables);
			}else if(vm.revokeProcessId == vm.dailyTransactionId){
				successProcessor(computeDailyTransactions);
			}else if(vm.revokeProcessId == vm.monthEndBillingId){
				successProcessor(monthEndBilling);
			}else if(vm.revokeProcessId == vm.yearEndBillingId){
				successProcessor(yearEndBilling);
			}else if(vm.revokeProcessId == vm.updateTradingBranchId){
				successProcessor(updateNewTradingBranchForCustomers);
			}
			else if(vm.revokeProcessId ==	vm.shiftDateId){
				successProcessor(shiftDate);
			}
			else if(vm.revokeProcessId == vm.prepareNextdateDataId ){
				successProcessor(prepareNextBusinessDatedata);
			}else{
				successProcessor(startEODInOrderService);
			}
			
			}
		}
		
		function updateEODLogFailure(){
			
		}
		

		function processeodSucess(result){
			
			if(vm.eodStatus == vm.INCOMPLETE || vm.eodStatus == vm.INPROGRESS ){
                $fileLogger.info('updateEODPRocessLog');
				EODProcess.updateEODPRocessLog({"businessDate" : convertedCurrentBusinessDate},updateEODLogSuccess, updateEODLogFailure);
			}else{			
				vm.executionCount = vm.executionCount+1;
				$fileLogger.info('current execution Count', vm.executionCount);
				//		vm.revokeProcessId = parseInt(result.revokeProcessId);
				vm.eodStatus = vm.INPROGRESS ;
				vm.disbleEod = true;			
				successProcessor(logOffUsersForcibly);
			}
		}
		
		
		function processeodError(error){
			var errorCode = errorProcessor(error);
			AlertService.error( errorCode );
		}
		
		
		function updateRemarksInProcessList(processID, errorMessage, status){
			var indexOfRevokeProcess = vm.idArray.indexOf(processID);
			var item = vm.eodProcessList[indexOfRevokeProcess];
			item.remarks = errorMessage;
			item.status = status ;
			//download log file whenever any process is failed.
			if(status==vm.FAILED){
				$fileLogger.info('EOD stopped in ' + item.processName);
				$fileLogger.info(errorMessage);
				$timeout(function(){
					$rootScope.eodInProgress = false;
					FileUtil.downloadLogFile("FXRetail_EOD_Process");
				}, 1000);
			}
		}
		
		
		
		 function disableEodProcess(){
		    	
		    	if(vm.currentBusinessDate ==undefined || vm.currentBusinessDate == null || vm.currentBusinessDate == ''){
		    		// no valid businessdate - disable start button
		    		return true;
		    	}else if(vm.eodStatus ==vm.OPEN || vm.eodStatus==null || vm.eodStatus==undefined ||vm.eodStatus==''){
		    		// eodStatus is open for the business date then enable start button
		    		vm.isRefreshDisabled=true;
		    		return false;
		    	}else if( vm.eodStatus ==vm.INCOMPLETE){
		    		
		    		vm.isRefreshDisabled=false;
		    		return false;
		    		
		    	}
		    	else if( (vm.eodStatus ==vm.INPROGRESS ) && !(vm.isEodProcessSuccessfull==null || vm.isEodProcessSuccessfull==false)){
		    		// enable start button when eodStatus is in in progress and eod process is not successful
		    		vm.isRefreshDisabled=false;
		    		return  false;
		    	}else if( vm.eodStatus ==vm.INPROGRESS  && (vm.isEodProcessSuccessfull==null || vm.isEodProcessSuccessfull==true)){
		    		// disable start button when sodStatus is in inprogress and sod process is  successfull
		    		vm.isRefreshDisabled=false;
		    		return true;
		    	}else if( vm.COMPLETED ==vm.eodStatus ){
		    		vm.isRefreshDisabled=true;
		    		return true;
		    	}
		    	
		    }
		 
		 
		/*function getBusinnessDateSuccess(result) {
					
			vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(result.date);
			convertedCurrentBusinessDate = DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
			EODProcess.getNextValidBusinessDate( {
					"currentBusinessDate" : convertedCurrentBusinessDate
				} , getNextBusinessDateSuccess, getNextBusinessDateError);
				

			//vm.nextBusinessDate =  DateUtils.convertLocalDateFromServer(result.date);
			//vm.nextBusinessDate.setDate(vm.nextBusinessDate.getDate() + 1);
			//convertedNextBusinessDate = DateUtils.convertLocalDateToServer(vm.nextBusinessDate);
			
		}

		function getBusinessDateFailed(error) {
			//Do Nothing
		}*/
		
		function getNextBusinessDateSuccess(result) {
			vm.nextBusinessDate = DateUtils.convertLocalDateFromServer(result.date);
			vm.nextDate = $filter('date')(vm.nextBusinessDate,"dd/MM/yyyy");
			vm.nextDate = vm.nextDate + " | " + days[vm.nextBusinessDate.getDay()];
			convertedNextBusinessDate = DateUtils.convertLocalDateToServer(vm.nextBusinessDate);
		}
		function getNextBusinessDateError(error) {
		}
		/*function getNextTomDateSuccess(result) {
			vm.tomDate = DateUtils.convertLocalDateFromServer(result.date);
		}
		function getNextTomDateError(error) {
		}*/
		

		
		/*********************** Process methods for forcible logoff users ********************************************/
		var logOffUsersForcibly = function logOffUsersForcibly(){
			
			updateRemarksInProcessList(vm.logOffId ,"",   vm.INPROGRESS);
            $fileLogger.info('logOffUsersForcibly');
			EODProcess.logOffUsersForcibly( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.logOffId,
				"executionCount" : vm.executionCount
			}, logOffUsersForciblySucccess, logOffUsersForciblyError);
		}
		function logOffUsersForciblySucccess(result){
			updateRemarksInProcessList(vm.logOffId ,"",  vm.COMPLETED);
			//clear revokeProcessId before starting the EOD process in OPS. 
			//if it retained as 1. then it will make issue on OPS. issue fixed on 21-Jan-2021.
			vm.revokeProcessId = null;
			//invoke next process - auto withdrawal of pending customers
			successProcessor(startEODInOrderService);
		}
		function logOffUsersForciblyError(error){
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.logOffId ,errorCode , vm.FAILED );
		
		}
		
		/************************* END *****************************************************************************/
		
		/********** begin EOD in order service *************/
		var startEODInOrderService = function startEODInOrderService(){
			//var getKeysArray = Object.keys(vm.eodProcessList);
			var nextProcesss = vm.eodProcessList[1];
			if(vm.revokeProcessId==null){
				//vm.processId = vm.reconOrderReqId;
				vm.processId = nextProcesss.id;
			}else{
				vm.processId = vm.revokeProcessId;
				
				nextProcesss = vm.eodProcessList[vm.idArray.indexOf(vm.revokeProcessId)];
			}
			console.log('next order server EOD process - ' + nextProcesss.id);
			/*if(nextProcesss.processName=='ReconOrderDetailsRequest' || nextProcesss.processName=='ReconOrderDetailsResponse' || nextProcesss.processName=='ReconTradeDetailsRequest'
				|| nextProcesss.processName=='ReconTradeDetailsResponse' || nextProcesss.processName=='UserLogOffRequest' || nextProcesss.processName=='UserLogOffResponse'
				|| nextProcesss.processName=='LUDDLogOffRequest' || nextProcesss.processName=='LUDDLogOffResponse' || nextProcesss.processName=='DisconnectInteractiveMQChannel' || nextProcesss.processName=='DisconnectBroadcastMQChannel'){*/
				updateRemarksInProcessList(vm.processId ,"",   vm.INPROGRESS);
				
				var eodRequest = {};
				eodRequest.executionCount = vm.executionCount;
				eodRequest.businessDate = convertedCurrentBusinessDate;
				eodRequest.startingProcessID =  vm.processId;
				 
				/*EODProcess.initiateEODInOrderService({
					"executionCount" : vm.executionCount,
					"businessDate": convertedCurrentBusinessDate,
					"revokeProcessId": vm.processId},
						startEODInOrderServiceSuccess, startEODInOrderServiceFailure);*/
                $fileLogger.info('initiateEODInOrderService');
				EODProcess.initiateEODInOrderService(eodRequest,
						startEODInOrderServiceSuccess, startEODInOrderServiceFailure);
			/*}else{
				successProcessor(nextProcesss.processName);
			}*/
			
		}
		function startEODInOrderServiceSuccess(result){
			//do nothing as this is async call
            
			$fileLogger.info('initiateEODInOrderService success');
			
		}
		function startEODInOrderServiceFailure(error){
			
			// EOD Failure
			var errorCode = errorProcessor(error);
			$fileLogger.info('initiateEODInOrderService failed ' + errorCode);
			updateRemarksInProcessList(vm.processId ,"",  vm.FAILED);
            $fileLogger.info('processEOD called again in startEODInOrderServiceFailure');
			EODProcess.processEOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.INCOMPLETE,
				"nextBusinessDate" : convertedNextBusinessDate
			},  processENDEODSucess, processENDEODError);
			AlertService.error( errorCode );
		}
		/********  END *************/
		
		
		
		/*********************** Process methods for make users dormant ********************************************/
		var MakeUsersDormant = function MakeUsersDormant(){
			
			updateRemarksInProcessList(vm.dormantId ,"",   vm.INPROGRESS);		
            $fileLogger.info('makeUsersDormant');
			EODProcess.makeUsersDormant( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.dormantId,
				"executionCount" : vm.executionCount
			}, makeUsersDormantSucccess, makeUsersDormantError);
		}
		function makeUsersDormantSucccess(result){
			updateRemarksInProcessList(vm.dormantId ,"",  vm.COMPLETED);
			//invoke next process - auto withdrawal of pending customers
			successProcessor(autoWithrdrawPendingCutomerRegistration);
		}
		function makeUsersDormantError(error){
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.dormantId ,errorCode , vm.FAILED );
		}
		
		
		/************************* END *****************************************************************************/

		/*********************** Process methods for auto withdrawal of pening customer registration ********************************************/
		
		var autoWithrdrawPendingCutomerRegistration = function autoWithrdrawPendingCutomerRegistration(){
			
			
			updateRemarksInProcessList(vm.autowithdrawalId ,"",   vm.INPROGRESS);			
            $fileLogger.info('autoWithdrwaPendingCustomers');            
			EODProcess.autoWithdrwaPendingCustomers( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.autowithdrawalId,
				"executionCount" : vm.executionCount
			}, autoWithrdrawPendingCutomerRegistrationSucccess, autoWithrdrawPendingCutomerRegistrationError);
		}
		function autoWithrdrawPendingCutomerRegistrationSucccess(result){
			
			updateRemarksInProcessList(vm.autowithdrawalId ,"",  vm.COMPLETED);
			//invoke next process - generate EOD Emails
			successProcessor(generateEODEmails);
		}
		function autoWithrdrawPendingCutomerRegistrationError(error){
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.autowithdrawalId ,errorCode , vm.FAILED );
		}
		
		
		/************************* END *****************************************************************************/
		
		/*********************** Process methods for GEnerate EOD Emails ********************************************/
		var generateEODEmails = function generateEODEmails(){			
			
			updateRemarksInProcessList(vm.eodId ,"",   vm.INPROGRESS);			
            $fileLogger.info('generateEODEmails');            
			EODProcess.generateEODEmails( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.eodId,
				"executionCount" : vm.executionCount
			}, generateEODEmailsSuccess, generateEODEmailsError);
		}
		function generateEODEmailsSuccess(result) {
			updateRemarksInProcessList(vm.eodId ,"",  vm.COMPLETED);
			//invoke next process - shift date 
			successProcessor(processLimitsAtEOD);
			
		}
		function generateEODEmailsError(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.eodId ,errorCode , vm.FAILED );
			
		}
		

		/*********************** Process Methods for limit expire emails ********************************************/
		

		var processLimitsAtEOD  = function processLimitsAtEOD(){
			
			updateRemarksInProcessList(vm.limitId ,"",   vm.INPROGRESS);
			
            $fileLogger.info('processLimits');        
			EODProcess.processLimits( {
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.limitId,
				"executionCount" : vm.executionCount
				
			}, processLimitsSuccess, processLimitsError);
		}
		function processLimitsSuccess(result) {
						
			
			updateRemarksInProcessList(vm.limitId ,"",  vm.COMPLETED);
		
			successProcessor(archiveTables);
			
			
		}
		function processLimitsError(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.limitId ,errorCode , vm.FAILED );
			
		}
		

		
		/*********************** Process Methods for archivivng tables********************************************/
		

		var archiveTables  = function archiveTables(){
			
			updateRemarksInProcessList(vm.archiveId ,"",   vm.INPROGRESS);
			
            $fileLogger.info('archiveTables');    
			EODProcess.archiveTables( {
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.archiveId,
				"executionCount" : vm.executionCount
				
			}, archiveTablesSuccess, archiveTablesFailure);
		}
		function archiveTablesSuccess(result) {
						
			
			updateRemarksInProcessList(vm.archiveId ,"",  vm.COMPLETED);
		
			successProcessor(computeDailyTransactions);
			
			
		}
		function archiveTablesFailure(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.archiveId ,errorCode , vm.FAILED );
			
		}
		
	/*********************** Process Methods for computeDailyTransaction tables********************************************/
		

		var computeDailyTransactions  = function computeDailyTransactions(){
			
			updateRemarksInProcessList(vm.dailyTransactionId ,"",   vm.INPROGRESS);
			
            $fileLogger.info('computeDailyTransactions');
			EODProcess.computeDailyTransactions( {
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.dailyTransactionId,
				"executionCount" : vm.executionCount
				
			}, computeDailyTransactionsSuccess, computeDailyTransactionssFailure);
		}
		function computeDailyTransactionsSuccess(result) {
						
			
			updateRemarksInProcessList(vm.dailyTransactionId ,"",  vm.COMPLETED);
		
			successProcessor(monthEndBilling);
			
			
		}
		function computeDailyTransactionssFailure(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.dailyTransactionId ,errorCode , vm.FAILED );
			
		}
		/*********************** Process Methods for monthEndBilling tables********************************************/
		

		var monthEndBilling  = function monthEndBilling(){
			
			updateRemarksInProcessList(vm.monthEndBillingId ,"",   vm.INPROGRESS);
			
            $fileLogger.info('generateMonthEndBillingReport');
			EODProcess.generateMonthEndBillingReport( {
				
				"processId" : vm.monthEndBillingId,
				"executionCount" : vm.executionCount
				
			}, monthEndBillingSuccess, monthEndBillingFailure);
		}
		function monthEndBillingSuccess(result) {
						
			
			updateRemarksInProcessList(vm.monthEndBillingId ,"",  vm.COMPLETED);
		
			successProcessor(yearEndBilling);
			
			
		}
		function monthEndBillingFailure(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.monthEndBillingId ,errorCode , vm.FAILED );
			
		}
		
/*********************** Process Methods for yearEndBilling tables********************************************/
		

		var yearEndBilling  = function yearEndBilling(){
			
			updateRemarksInProcessList(vm.yearEndBillingId ,"",   vm.INPROGRESS);
			
            $fileLogger.info('generateYearEndBillingReport');
			EODProcess.generateYearEndBillingReport( {
				"processId" : vm.yearEndBillingId,
				"executionCount" : vm.executionCount
				
			}, yearEndBillingSuccess, yearEndBillingFailure);
		}
		function yearEndBillingSuccess(result) {
						
			
			updateRemarksInProcessList(vm.yearEndBillingId ,"",  vm.COMPLETED);
		
			successProcessor(updateNewTradingBranchForCustomers);
			
			
		}
		function yearEndBillingFailure(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.yearEndBillingId ,errorCode , vm.FAILED );
			
		}
		/*********************** Process Methods for shifting Date ********************************************/
		

		var shiftDate  = function shiftDate(){
			
			updateRemarksInProcessList(vm.shiftDateId ,"",   vm.INPROGRESS);
            $fileLogger.info('shiftDateInSystem');
			EODProcess.shiftDateInSystem( {
				"currentBusinessDate" : convertedCurrentBusinessDate,		
				"processId" : vm.shiftDateId,
				"executionCount" : vm.executionCount
				
			}, shiftDateSuccess, shiftDateError);
		}
		function shiftDateSuccess(result) {
						
			updateRemarksInProcessList(vm.shiftDateId ,"",  vm.COMPLETED);
			
			successProcessor(prepareNextBusinessDatedata);
		}
		function shiftDateError(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.shiftDateId ,errorCode , vm.FAILED );
			
		}
		
		/*********************** Prepare ********************************************/
		

		var prepareNextBusinessDatedata  = function prepareNextBusinessDatedata(){
			
			updateRemarksInProcessList(vm.prepareNextdateDataId ,"",   vm.INPROGRESS);
            $fileLogger.info('prepareNextBusinessDateData');
			EODProcess.prepareNextBusinessDateData( { "currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.prepareNextdateDataId,
				"executionCount" : vm.executionCount}, prepareSuccess, prepareError);
		}
		function prepareSuccess(result) {
						
			updateRemarksInProcessList(vm.prepareNextdateDataId ,"",  vm.COMPLETED);
            $fileLogger.info('processEOD - Complete');
			EODProcess.processEOD( {				
				"businessDate" : convertedCurrentBusinessDate,
				"status" : vm.COMPLETED,
				"nextBusinessDate" : convertedNextBusinessDate
			}, processEODcompleteSucess, processEODcompleteError);
			vm.eodDone=true;
		
			
		}
		function prepareError(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.prepareNextdateDataId ,errorCode , vm.FAILED );
			
		}
		
		/*********************** Process methods for forcible logoff users ********************************************//*
		var logOffUsersForciblyEnd = function logOffUsersForciblyEnd(){
			
			updateRemarksInProcessList(vm.logOffEndId ,"",   vm.INPROGRESS);
			EODProcess.logOffUsersForcibly( {				
				"currentBusinessDate" : convertedCurrentBusinessDate,
				"processId" : vm.logOffEndId,
				"executionCount" : vm.executionCount
			}, logOffUsersForciblyEndSucccess, logOffUsersForciblyEndError);
		}
		function logOffUsersForciblyEndSucccess(result){
			updateRemarksInProcessList(vm.logOffEndId ,"",  vm.COMPLETED);
			loadDates();
			//invoke next process - auto withdrawal of pending customers
		}
		function logOffUsersForciblyEndError(error){
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.logOffEndId ,errorCode , vm.FAILED );
		
		}*/
		
		/*********************General method used in all process ***********************/
		function successProcessor(successProcessToInvoke){
			
			if(vm.isEodProcessSuccessfull == null || vm.isEodProcessSuccessfull == true){
				// next process is invoked only when previous one is is successful
				if(successProcessToInvoke!=null)
					successProcessToInvoke();
			}
		}
		

		function errorProcessor(error){		
			var errorcode = "";
			if( error.message !=undefined &&  error.message !=null &&  error.message!=""){
				errorcode  = $filter('translate')( error.message ) //+  error.businessDate;				
				//vm.errorMessage = error.exceptionMessage +  errorcode ;
			}else if( !(error.data==undefined || error.data==null || error.data=='')){
				errorcode  = $filter('translate')( error.data.message );
				//vm.errorMessage = error.data.exceptionMessage +" "+  errorcode;
			}else{
				errorcode = $filter('translate')( "Service unavailable");
			}
			 		
			//AlertService.error( errorcode );
			vm.isEodProcessSuccessfull=false;
			return errorcode;
		}
		
		/********************************************/
		
		
		/*********************** END ********************************************/
		// Watch for Process failure through the isEodProcessSuccessfull flag , if it is failed log the status, if it true do nothing
		 $scope.$watch('vm.isEodProcessSuccessfull', function(value){
				if(value!=null && value!=undefined && value == false){
	               
					//method to enable or disable start button if process is unsuccessfull
					vm.eodStatus = vm.INCOMPLETE;
					vm.disbleEod = disableEodProcess();
					/*EODProcess.processEOD( {				
						"businessDate" : convertedCurrentBusinessDate,
						"status" : vm.INCOMPLETE,
						"nextBusinessDate" : convertedNextBusinessDate
					}, processENDEODSucess, processENDEODError);*/
				}
			
				});
		   /* $scope.$watch('vm.isEodCompleted', function(value){
				 if(value==true ){
					// update the status as completed when the isEODProcessSuccessful is true and inprogress
					EODProcess.processEOD( {				
						"businessDate" : convertedCurrentBusinessDate,
						"status" : 'COMPLETED'
					}, processENDEODSucess, processENDEODError);
				}
				});*/
	
		 orderServiceEODProcessUpdateSubscription = $rootScope.$on("orderServiceEODProcessUpdate", function(event,result){
			 $fileLogger.info(" websocket message orderServiceEODProcessUpdate recevied for process id " + result.processID + "status " +result.success );
		    	if( result.success!=vm.processResultMap[result.processID]){
		    
		    		processStatusNotificationFromOrderService(result);
		    	}else if( result.success==false && result.exceptionNumber!=vm.processErrorMap[result.processID]){
		    		//update new error code
		    		processStatusNotificationFromOrderService(result);
		    	}
		    	
	         });
	  
      function unsubscribeToEODProcessUpdate(){
          if(orderServiceEODProcessUpdateSubscription!=null){
              // unscribe to order service update notification
              orderServiceEODProcessUpdateSubscription();
          }
      }
      var webscoketDisconnect = $rootScope.$on("websocketDisconnected", function(event,result){
	    	
	    	console.log("web socket disconnected with reason " + result);
	    	 $fileLogger.info("web socket disconnected with reason " + result);
	    	var logOffIndex = vm.idArray.indexOf(vm.logOffId);
	    	var dormantIndex = vm.idArray.indexOf(vm.dormantId);
	    	angular.forEach( vm.eodProcessList, function(item,index){
	    		// set the status as failed only for order service process when websocket disconnection occurs
	    		
	   		 	if(item.status == vm.INPROGRESS && index > logOffIndex && index < dormantIndex){
	   		 		item.status = vm.FAILED;
	   		 		item.remarks=vm.errorList["7001"].rejectionReason;
	   		 	}
	    		
	    	
	    	});
	    	
     });
	    $scope.$on('$destroy', function() {
	        	unsubscribeToEODProcessUpdate();	        	
	        	if(webscoketDisconnect!=null){
	  			  webscoketDisconnect();
	  		  }	
	    });
		
		function processENDEODSucess(result){			
		    	
				
		}
		    
		    function processEODcompleteSucess(result){
		    	AlertService.success($filter('translate')('gatewayApp.eodProcess.eodProcesscompleted') + $filter('date')(vm.currentBusinessDate,"dd-MMM-yyyy"));
                $timeout(function(){
					$rootScope.eodInProgress = false;
                    FileUtil.downloadLogFile("FXRetail_EOD_Process");
                }, 1000);
				EODProcess.logOffUsersForcibly( {				
					"currentBusinessDate" : convertedCurrentBusinessDate,
					"processId" : 0,
					"executionCount" : vm.executionCount
				});
		    }
		    
		    function processEODcompleteError(error){}
		    
		    
			
			function processENDEODError(error){
				//DO NOthing
				

			}
		
		
			function processStatusNotificationFromOrderService(processStatus){
				
				//angular.forEach( vm.sodProcessList, function(item,index){
				var indexOfProcess =  vm.idArray.indexOf(processStatus.processID);
				var item = vm.eodProcessList[indexOfProcess];
					//console.log("process", index);
					console.log("notification received from order service for process "+ processStatus.processID);
				
					if(item.id==processStatus.processID &&  processStatus.success==true ){
						
						item.status = vm.COMPLETED;
						item.remarks = "";
						
						//var keyArray = Object.keys(vm.eodProcessList);
						var keyArray = vm.idArray
						var index = keyArray.indexOf(processStatus.processID);
						var nextIndex = index+1;
						if(nextIndex < keyArray.length){
							var nextProcess = vm.eodProcessList[nextIndex];
		    				if(nextProcess!=undefined && nextProcess!=null && nextProcess!='' && nextProcess.status!= vm.COMPLETED && nextProcess.status!= vm.FAILED){
		    					nextProcess.status = vm.INPROGRESS ;
		    					
		    				}
		    				// $fileLogger.info('success notification received from order server for process id- ' + processStatus.processID);
		    				if(item.processName == 'DisconnectBroadcastMQChannel' && !vm.isEodCompletedForOrder){
		    					console.log('received DisconnectBroadcastMQChannel completion message');
		    					vm.isEodCompletedForOrder  = true;
		    					
		    					 $fileLogger.info('calling make user dormant method - from processStatusNotificationFromOrderService');
		    					successProcessor(MakeUsersDormant);
		    				}
		    				
						}
							
							
						
						//vm.processStatusMap[processStatus.processID] =true;
						 vm.processResultMap[processStatus.processID] = processStatus.success;
						 vm.processErrorMap[processStatus.processID] = null;
						
					
					}else if(item.id==processStatus.processID &&  processStatus.success==false){
						
						//$fileLogger.info('failure notification received from order server for process id- ' + processStatus.processID)				
						item.status = vm.FAILED;
						item.remarks = "Process Failed";
						vm.isEodProcessSuccessfull = false;			
						var errorKey = processStatus.exceptionNumber+""+processStatus.messageNumber;
					
						if( vm.errorList[errorKey]!=null && vm.errorList[errorKey]!=undefined 
								&& vm.errorList[errorKey]!='' ){
							item.remarks =  vm.errorList[errorKey].rejectionReason;
							
						}
						
						//vm.processStatusMap[processStatus.processID] =true;
						 vm.processResultMap[processStatus.processID] = processStatus.success;
						 vm.processErrorMap[processStatus.processID] = processStatus.exceptionNumber;
						
					}
					 
			}
		
		
		/***********************  END *************************************************************************************/
		/********** Refresh**********/
		  $scope.toggle = true;
	        function refresh() {
				$fileLogger.info('eod screen refreshed');
	            $scope.toggle = false;
	            $scope.refreshFlag = false;
	            loadDates();
	            if ($scope.toggle == false) {
	                $timeout(function() {
	                    $scope.toggle = true;
	                }, 2000);
	                $scope.refreshFlag = false;
	            }
	        };
		
		/***********END *************/
	    function logScreenAccess(){
	           ScreenLog.screenLog( entityConstants.ENTITY_SHORT_NAME.EOD_PROCESS,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
	        }
	    
	    function validateEod(){
            //$fileLogger.info('validateEOD');
	    	EODProcess.validateEOD(validateSuccess,validateFailure);
	    	vm.validation = vm.INPROGRESS;
	    }
	    
	    function validateSuccess(result){
	    	
	    	if(result.isValidated==true){
	    		vm.validation = vm.COMPLETED;
	    		vm.remarks="";
	    		validateSessionClose();
	    	}else{
	    		vm.validation = vm.FAILED;
	    		vm.remarks="Not processed";
	    	}
	    	
	    }
	    
	    function validateFailure(error){
	    	var errorCode = '';
	    	if(error.message!=null && error.message!=undefined && error.message!=''){
	    		errorCode = $filter('translate')(error.message);
	    	}
	    	vm.remarks=errorCode;
	    	vm.validation = vm.FAILED;
	    }
	    
	    function validateInteractiveServerDbProcessing(){
            $fileLogger.info('Validate Clicked');
	    	EODProcess.validateDBProcessing({"serverName" : "Interactive_Server"},validateInteractiveServerDbProcessingSuccess,validateInteractiveServerDbProcessingFailure);
	    	vm.validateInteractiveDBProcessing = vm.INPROGRESS;
	    }
	    
	    function validateInteractiveServerDbProcessingSuccess(result){
	    	vm.validateInteractiveDBProcessing = vm.COMPLETED;
    		vm.remarksForInteractiveServerDBProcessing="";
    		validateBroadcastServerDbProcessing();
	    	
	    }
	    
	    function validateInteractiveServerDbProcessingFailure(error){
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.remarksForInteractiveServerDBProcessing=errorCode;
	    	vm.validateInteractiveDBProcessing = vm.FAILED;
	    }
	    
	    function validateBroadcastServerDbProcessing(){
            //$fileLogger.info('validateBroadcastDBProcessing');
	    	EODProcess.validateDBProcessing({"serverName" : "Broadcast_Server"},validateBroadcastServerDbProcessingSuccess,validateBroadcastServerDbProcessingFailure);
	    	vm.validateBroadcastDBProcessing = vm.INPROGRESS;
	    }
	    
	    function validateBroadcastServerDbProcessingSuccess(result){
	    	vm.validateBroadcastDBProcessing = vm.COMPLETED;
    		vm.remarksForBroadcastServerDBProcessing="";
    		validateResponseReceived();
	    }
	    
	    function validateBroadcastServerDbProcessingFailure(error){
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.remarksForBroadcastServerDBProcessing=errorCode;
	    	vm.validateBroadcastDBProcessing = vm.FAILED;
	    }
	    
	    function validateResponseReceived(){
	    	vm.responseReceivedValidation = vm.INPROGRESS;
	    	vm.responseReceivedMessage="";
            //$fileLogger.info('validateResponseReceived');
	    	EODProcess.validateResponseReceived(validateResponseReceivedSuccess,validateResponseReceivedFailure);
	    }
	    
	    function validateResponseReceivedSuccess(result){    	
	    	//vm.isEODvalidated = result.responseReceived;
	    	
	    	if( result.responseReceived==true){
	    		vm.responseReceivedValidation = vm.COMPLETED;
	    		vm.responseReceivedMessage="";
	    		validateEod();
	    		
	    	}else{
	    		vm.responseReceivedValidation = vm.FAILED;
	    		//vm.responseReceivedMessage= $filter('translate')('gatewayApp.eodProcess.responseReceivedMessage');
	    		vm.responseReceivedMessage= "Not processed";
	    	}
	    	
    		
	    }
	    
	    function validateResponseReceivedFailure(error){
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.responseReceivedMessage = errorCode;
	    	vm.responseReceivedValidation = vm.FAILED;
	    }
	    
	    
	    function validateSessionClose(){
	    	vm.sessionCloseValidation = vm.INPROGRESS;
	    	//vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
	    	//convertedCurrentBusinessDate = DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
            //$fileLogger.info('validateSessionClose');
	    	EODProcess.validateSessionClose({"currentBusinessDate" : convertedCurrentBusinessDate},validateSessionCloseSuccess,validateSessionCloseFailure);
	    	
	    }
	    
	    function validateSessionCloseSuccess(result){    	
	    	vm.isEODvalidated = result.isSessionClosed;
	    	if( result.isSessionClosed==true){
	    		vm.sessionCloseValidation = vm.COMPLETED;
	    		vm.sessionCloseMessage="";
	    		
	    	}else{
	    		vm.sessionCloseValidation = vm.FAILED;
	    		vm.sessionCloseMessage= $filter('translate')('gatewayApp.eodProcess.sessionCloseMessage');
	    	}
	    	
	    	
	    }
	    
	    function validateSessionCloseFailure(error){
	    	
	    	var errorCode = '';
	    	if(error.data.validationMsg!=null && error.data.validationMsg!=undefined && error.data.validationMsg!=''){
	    		errorCode = $filter('translate')(error.data.validationMsg);
	    	}
	    	vm.sessionCloseMessage=errorCode;
	    	vm.sessionCloseValidation = vm.FAILED;
	    }
	    var replaceAt=function(index, replacement, stringtoBemodified) {
	        return  replacement + this.substr(index+1, stringtoBemodified.length-1) + replacement;
	    }
	    /*********************** Process Methods for UpdateNewTradingBranchForCustomers ********************************************/
		

		var updateNewTradingBranchForCustomers  = function updateNewTradingBranchForCustomers(){
			
			updateRemarksInProcessList(vm.updateTradingBranchId ,"",   vm.INPROGRESS);
            $fileLogger.info('updateNewTradingBranchForCustomers');
			EODProcess.updateNewTradingBranchForCustomers( {
				"currentBusinessDate" : convertedCurrentBusinessDate,		
				"processId" : vm.updateTradingBranchId,
				"executionCount" : vm.executionCount
				
			}, updateTradingBranchSuccess, updateTradingBranchError);
		}
		function updateTradingBranchSuccess(result) {
			console.log(result)	;		
			console.log(result.data)	;
			console.log(result.message)	;
			var message = "";
			if(result != null && result!=undefined && result.message!=null && result.message!="" && result.message!=undefined){
				message = result.message
			}
			updateRemarksInProcessList(vm.updateTradingBranchId ,message,  vm.COMPLETED);
			
			successProcessor(shiftDate);
		}
		function updateTradingBranchError(error) {
			
			var errorCode = errorProcessor(error);
			updateRemarksInProcessList(vm.updateTradingBranchId ,errorCode , vm.FAILED );
			
		}
		
		/*********************** Prepare ********************************************/
	
	}
})();
