(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'swapQuotes'
	})

	.controller('SwapQuotesTenorDatesController', SwapQuotesTenorDatesController);

	SwapQuotesTenorDatesController.$inject = ['$scope', '$state', 'workflowSharedService', 'SwapQuotes', 'GlobalValues', 'EntityPermissions', 'ScreenLog', 'entityConstants','DateUtils', 'AlertService', '$filter','SwapQuotesForTenorDates','ImportFile', 'systemSettings'];
	function SwapQuotesTenorDatesController($scope, $state, workflowSharedService, SwapQuotes, GlobalValues, EntityPermissions, ScreenLog , entityConstants, DateUtils, AlertService,$filter,SwapQuotesForTenorDates,ImportFile, systemSettings) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.SWAP_QUOTES;
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		vm.loadAll = loadData;
		vm.clicked=false;
		vm.editSwapQuotes = editSwapQuotes;		
		vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		/*vm.exportDataToExcelFile = exportDataToExcelFile;
		vm.exportDataToCsvFile = exportDataToCsvFile;
		vm.exportDataToPdfFile = exportDataToPdfFile;*/
		vm.noOfMonthsForHistoricalView = vm.globalValues.getConfigSettings().noOfMOnthsForHistoricalReportsView;
		vm.generateSwapQuotesTemplate = generateSwapQuotesTemplate;
		vm.uploadedData =null;
		vm.checkSODCompleted = checkSODCompleted;
		//vm.importFile = importFile;
		var systemSettings = systemSettings;
		vm.systemSettings = systemSettings; 
		if(systemSettings!=null && systemSettings!=undefined){
			vm.currentDate = systemSettings.businessDate;
			vm.currentBusinessDate = systemSettings.businessDate;
			vm.fromDate = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
			vm.toDate = DateUtils.convertLocalDateFromServer(vm.currentBusinessDate);
			vm.minDate = DateUtils.getMinimumLocalDate(DateUtils.convertLocalDateFromServer(vm.currentBusinessDate));
		}
		

		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		workflowSharedService.viewFrom = "swapQuotes";

		vm.permitSwapQuotesEditEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);

		function loadData(){

			var fromServerDate = DateUtils.convertLocalDateToServer(vm.fromDate);
			var toServerDate = DateUtils.convertLocalDateToServer(vm.toDate);
			if(moment(vm.fromDate).isAfter(vm.currentDate,'day') || moment(vm.toDate).isAfter(vm.currentDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.forwardDateViewError'));

			}else if(moment(vm.fromDate).isAfter(vm.toDate,'day') ){
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.fromDateError'));

			}/*else if(moment(vm.fromDate).isBefore(vm.minDate, 'day')){
	   			AlertService.error('Historical Reports are available only for a period of '  + vm.noOfMonthsForHistoricalView + ' months...!');
	   			vm.valid=false;
	   		}*/
			else{
				vm.clicked=true;
				SwapQuotesForTenorDates.getAllSwapQuotes({ orgId : vm.globalValues.getOrganisation().id , fromDate : fromServerDate, toDate : toServerDate} , function(result) {
					vm.swapQuotesForTenorDates = result; 
					vm.clicked=false;                   
				});


			}
		}

		logScreenAccess();

		//SwapQuotesForTenorDates.getSystemSettings(onObtainSuccess);
		
		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		function editSwapQuotes(swapQuotes,$event){			
		
			
			if(swapQuotes.businessDate != DateUtils.convertLocalDateToServer(vm.currentDate) ){
				AlertService.error($filter('translate')('gatewayApp.swapQuotes.pastDateEditError'));

			}
			else if(vm.systemSettings != null && vm.systemSettings !=undefined  && (vm.systemSettings.eodStatus == 'INCOMPLETE' || vm.systemSettings.eodStatus == 'INPROGRESS')){
				
					AlertService.error($filter('translate')('gatewayApp.swapQuotes.cannotEdit'));

			}
			else{
				ScreenLog.screenLog(vm.screenName,swapQuotes.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
				//$state.params = {id:swapQuotes.id}
				var state = '.edit';
				$state.go(state,{businessDate:swapQuotes.businessDate});
			}
		}

		/*function onObtainSuccess(result){
			vm.systemSettings = result 
		}*/
		
		 $scope.$watch('vm.uploadedData', function(value){
         	if(value!=null && value!=undefined){
         		if(checkSODCompleted() == true){
         			ImportFile.importFile("SwapQuotesImport", value);
             		angular.element("input[type='file']").val(null);
         		}
         		else{
         			AlertService.error($filter('translate')('gatewayApp.swapQuotes.sodIncomplete'));
         		}
         	}
         })
         
        function checkSODCompleted(){
			 if(systemSettings.sodStatus != 'COMPLETED'){
				 return false;
			 }else{
				 return true;
			 }
		 }
		
		function importFile(uploadType,fileContent){
			ImportFile.importFile(uploadType,fileContent);
		}
		
		function generateSwapQuotesTemplate(){
				vm.mimeType = 'text/csv';
				vm.extension = ".csv";  
				SwapQuotesForTenorDates.generateTemplateCSV({orgId: vm.globalValues.getOrganisation().id,businessDate:DateUtils.convertLocalDateToServer(vm.currentBusinessDate)}, onImportSuccess);
		}
		/*function exportDataToExcelFile(){
			if(vm.swapQuotesForTenorDates !=null &&  vm.swapQuotesForTenorDates!=undefined && vm.swapQuotesForTenorDates.length>0){
				vm.mimeType = 'application/vnd.ms-excel';
				vm.extension = ".xls";  
				SwapQuotesForTenorDates.exportData({orgId:  vm.globalValues.getOrganisation().id, from:  DateUtils.convertLocalDateToServer(vm.fromDate), to:  DateUtils.convertLocalDateToServer(vm.toDate)}, vm.swapQuotesForTenorDates, onExportSuccess);
			}
		}

		

		function exportDataToPdfFile(){
			if(vm.swapQuotesForTenorDates !=null &&  vm.swapQuotesForTenorDates!=undefined && vm.swapQuotesForTenorDates.length>0){
				vm.mimeType = 'application/pdf';
				vm.extension = ".pdf";  
				SwapQuotesForTenorDates.exportDataPdf({orgId:  vm.globalValues.getOrganisation().id, from:  DateUtils.convertLocalDateToServer(vm.fromDate), to:  DateUtils.convertLocalDateToServer(vm.toDate)}, vm.swapQuotesForTenorDates, onExportSuccess);
			}
		}
		*/
		 function onImportSuccess(result){        	  
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString = "SwapQuotesImport"+  vm.extension; 
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob){
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString);
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}


	}
})();