(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("WebSocket", WebSocket);

    WebSocket.$inject = ['$rootScope', 'MarketWatch', '$filter','Notifications', 'GlobalValues', 'MessageBox', 'entityConstants', 'DateUtils', '$interval'];

    function WebSocket($rootScope, MarketWatch, $filter,Notifications, GlobalValues,MessageBox, entityConstants, DateUtils, $interval) {

    	var isSubscribed = false;
    	var isConnected = false;
        var isConnectInprogress = false;
        var isDisconnected = false;
    	var isForciblyDisconnected = false;
    	var user = 0;
    	var globalValues = GlobalValues;
    	var stompClient;
    	var service = {
    		subscribeWebSocket: subscribeWebSocket,
    		reSubscribeWebSocket: reSubscribeWebSocket,
    		disconnecteWebSocket: disconnecteWebSocket,
    		subscribe: function(scope, callback) {
                var handler = $rootScope.$on('orderFeed', callback);
                scope.$on('$destroy', handler);
    		}
    	};
    	return service;
    	
    	function disconnecteWebSocket(isSessionExpired){
    		if(isConnected){
    			isSubscribed = false;
	     		isConnected = false;
	     		isForciblyDisconnected = true;
	     		if(!isSessionExpired){
	     			if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
		     			$.growl.alert({ title: "Session Message", message: "Session expired." });	     		
	     			}
	     		}
                console.log('websocket disconnect from disconnect msg received from WBS');
	     		stompClient.disconnect();
    		}
    	}
    	
    	function subscribeWebSocket(userId){
        	user = userId;
        	
        	if(isConnected==false){
                isConnectInprogress = true;
                console.log("Attempting to connect");
            	var headers = {'session' : userId};
            	stompClient = webstomp.over(new SockJS("/websocket/tracker"));
            	stompClient.connect(headers, function(frame){
                    console.log('Connect success');
                    isConnected = true;
            		if(isSubscribed == false){
                	    isSubscribed = true;
                        console.log('Attempting to subscribe');
                	    isForciblyDisconnected = false;
                	    stompClient.subscribe("/topic/cspl", function(d) {
                	     	console.log(d.body);
                	    });
                	    
                	    stompClient.subscribe("/user/topic/disconnect", function(d) {
        	    	     	disconnecteWebSocket(false);
                	    });
                	    
                	    stompClient.subscribe("/user/topic/order", function(d) {
        	    	     	console.log('User - ' + d.body);
        	    	     	if(d.body!=null && d.body!=undefined && d.body!=''){
        	    	     		var object = angular.fromJson(d.body);
        	    	     		if(object!=null && object!=undefined){
        	    	     			orderFeed(object);
        	    	     		}
        	    	     	}
                	    });
                	    
                	    stompClient.subscribe("/user/topic/market", function(d) {
        		    		console.log('User - ' + d.body);
        	     	     	if(d.body!=null && d.body!=undefined && d.body!=''){
        	    	     		var object = angular.fromJson(d.body);
        	    	     		if(object!=null && object!=undefined){
        	    	     			marketFeed(object);
        	    	     		}
        	    	     	}
                	    });
                	    
                	    stompClient.subscribe("/topic/allusers", function(d) {
        		    		console.log('User - ' + d.body);
        	     	     	if(d.body!=null && d.body!=undefined && d.body!=''){
        	    	     		var object = angular.fromJson(d.body);
        	    	     		if(object!=null && object!=undefined){
        	    	     			intradayFeed(object);
        	    	     		}
        	    	     	}
                	    });
                	    
                	    stompClient.subscribe("/topic/server/status", function(d) {
        		    		console.log('User - ' + d.body);
        	     	     	if(d.body!=null && d.body!=undefined && d.body!=''){
        	    	     		var object = angular.fromJson(d.body);
        	    	     		if(object!=null && object!=undefined){
        	    	     			updateConnectionStatus(object);
        	    	     		}
        	    	     	}
                	    });
                	    
                	    stompClient.subscribe("/user/topic/process", function(d) {
    		    		console.log('User - ' + d.body);
    	     	     		if(d.body!=null && d.body!=undefined && d.body!=''){
    	    	     			var object = angular.fromJson(d.body);
    	    	     			console.log("process - " + object);
    	    	     			if(object!=null && object!=undefined){
    	    	     			
    	    	     				processFeed(object);
    	    	     			}
    	    	     		}
            	    	});
                	    
                	    stompClient.subscribe("/user/topic/others", function(d) {
        	    	     	console.log('User - ' + d.body);
        	    	     	if(d.body!=null && d.body!=undefined && d.body!=''){
        	    	     		var object = angular.fromJson(d.body);
        	    	     		if(object!=null && object!=undefined){
        	    	     			othersFeed(object);
        	    	     		}
        	    	     	}
                	    });

                        isConnectInprogress = false;

                        // $interval(function(){
                        //     $rootScope.pendingOrders = $rootScope.pendingOrdersMemory;
                        //     $rootScope.trades = $rootScope.tradesMemory;
                        //     $rootScope.outstandingTrades = $rootScope.outstandingTradesMemory;
                        //     $rootScope.limitsAvailableForSelectedInstrument = $rootScope.limitsAvailableForSelectedInstrumentMemory;
                        //     $rootScope.bankComparativeDTOInPrice = $rootScope.bankComparativeDTOInPriceMemory;
                        //     $rootScope.retailSpotMarketDepth = $rootScope.retailSpotMarketDepthMemory;
                        //     $rootScope.retailSpotMarketWatch =$rootScope.retailSpotMarketWatchMemory;
                        //     $rootScope.ibSpotMarketDepth = $rootScope.ibSpotMarketDepthMemory;
                        //     $rootScope.ibSpotMarketWatch = $rootScope.ibSpotMarketWatchMemory;
                        //     $rootScope.userMarketWatchList = $rootScope.userMarketWatchListMemory;
                        //     $rootScope.outrightRatesList = $rootScope.outrightRatesListMemory;
                        // },500);

                        //to refresh home page reports from database after websocket reconnection
                        //timeout is to sync db writer process
                        if(isDisconnected){
                            setTimeout(function() {
                                $rootScope.refreshreportscount = 0;
                                $rootScope.$emit('refreshreports');
                                isDisconnected = false;
                            }, 1500);
                        }
            		}	
            	}, 
            	function(message) {
                    console.log('Error in connection');
            		handlingFailure(message);
            	});	
        	}
        }
    	
    	function handlingFailure(message){
    	    console.log('Retrying due to ' + message.code + ' - ' + message.reason + 
                '. Connect progress status - ' + isConnectInprogress + 
                ". Connect status - " + isConnected);

            if(!isConnectInprogress || !isConnected){
                isConnectInprogress = true;
                if(stompClient != null){
                    console.log('Forcibly disconnected due to error started');
                    stompClient.disconnect();
                    console.log('Forcibly disconnected due to error complete');
                }
                $rootScope.$emit('websocketDisconnected', message.reason);
                isSubscribed = false;
                isConnected = false;
                isDisconnected = true;
                reSubscribeWebSocket();
            }
    	}
    	
    	function reSubscribeWebSocket(){
    		if(!isForciblyDisconnected){
                console.log('subscribe websocket from resubscribeWebsocket');
    			subscribeWebSocket(user);	
    		}
    		
    	}
    	
    	function orderFeed(object){
        	if(object.topic=='PendingOrderUpdate'){
				var pendingOrder = object.object;
				pendingOrdersFeed(object.object);
			}else if(object.topic=='LimitUpdate'){
				var limit = object.object;
				limitUpdateFeed(object.object);
			}else if(object.topic=='TradeNotification'){
				var trade = object.object;
				frameTradeNotification(object.object);
				tradeNotificationHandling(object.object);
			}else if(object.topic=='OrderNotification'){
				var order = object.object;
				frameOrderNotification(object.object);
			}else if(object.topic=='MarkUpUpdate'){
				markupChange(object.object);
			}else if(object.topic=='TradeOutStandingNotification'){
                var limit = object.object;
                tradeOutstandingUpdate(object.object);
            }if(object.topic == 'TacAgreeUpdate'){
        		tacAgreedChange(object.object);
        	}
        	$rootScope.$digest();
    	}
    	
    	function marketFeed(object){

        	if(object.topic=='MarketDepthRetailSpot' || object.topic=='MarketDepthInterbank'){
				marketDepthChanges(object.object);
			}else if(object.topic=='SessionStatusChange' || object.topic=='InstrumentMasterUpdate' ){
				marketSettingsChanges(object.object);
			}else if(object.topic=='SwapQuoteChange'){
				swapQuoteChanges(object.object);
			}else if(object.topic=='IntraDayMemberBreachUpdate'){
				frameMemberBreachedNotification(object.object, object.notificationId);
			}else if(object.topic=='PreCashSessionCloseIntimation'){
				framePreCashSessionCloseIntimationNotification(object.object, object.notificationId);
			}
        	$rootScope.$digest();
    	}
    	
    	/*
    	 * Intraday Feed 
    	 */
    	function intradayFeed(object){
    		if(object.topic=='SessionStatusChange' || object.topic=='InstrumentMasterUpdate' ){
				marketSettingsChanges(object.object);
			}else if(object.topic=='SwapQuoteChange'){
				swapQuoteChanges(object.object);
			}else if(object.topic=='IntraDayMemberBreachUpdate'){
				frameMemberBreachedNotification(object.object, object.notificationId);
			}else if(object.topic=='BroadcastMessage'){
				frameBroadcastMessage(object);
			}else if(object.topic=='PreCashSessionCloseIntimation'){
				framePreCashSessionCloseIntimationNotification(object.object, object.notificationId);
			}else if(object.topic=='OperationStatusIntimation'){
				serverComponentStatusChange(object.object);
			}
    		$rootScope.$digest();
    	}
    	
    	function othersFeed(object){
        	if(object.topic=='TacNotification'){				
				tacNotificationFeed(object);				
        	}
        	if(object.topic == 'TacAgreeUpdate'){
        		tacAgreedChange(object.object);
        	}
        	$rootScope.$digest();
    	}
    	
    	function marketDepthChanges(marketDepthDTO){
        	//setTimeout(function(){
        	//$scope.$apply(function(){
        		var marketDepth = {};
            	var existingMarketDepth = angular.copy($rootScope.marketDepth);
    			//console.log('Before processing' + angular.toJson(existingMarketDepth));
    		
    			if(existingMarketDepth == null || existingMarketDepth == undefined || existingMarketDepth.length == 0){
    				existingMarketDepth = [];
    				existingMarketDepth[0]= MarketWatch.getMarketDepth(marketDepthDTO);
    			}else if( existingMarketDepth.length == 1 && existingMarketDepth[0].instrumentID!=marketDepthDTO.instrumentID){
    				existingMarketDepth[1] = MarketWatch.getMarketDepth(marketDepthDTO);
    			}
    			else{
    				angular.forEach(existingMarketDepth, function(value, index){
    					if(value.instrumentID==marketDepthDTO.instrumentID){
    					//	console.log('processing');
    						marketDepth = MarketWatch.getMarketDepth(marketDepthDTO);
    						existingMarketDepth[index] = marketDepth;
    					}
    				});
    			}
    			//console.log('After processing' + angular.toJson(existingMarketDepth));
            	$rootScope.marketDepth = existingMarketDepth;
            	
            	getMarketDepthDetailsForReport();
            	
            	//get cash and tom market watch
            	//getCashAndTomMarketWatch($rootScope.retailSpotMarketWatchMemory, $rootScope.defaultBankSpread);
            	if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined
                    && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null 
                    && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                    prepareBankComparativeQuotesInPrice($rootScope.bankComparativeDTO, $rootScope.retailSpotMarketWatch);
                
                    //update user market watch list online
                    prepareUserMarketWatchOnline();

                    //update outright comparison when market depth changes received
                    prepareOutrightComparision($rootScope.outrightRateSettlementDate, $rootScope.outrightRateInstrument);
                }
            	
            	//set previous ticker price
            	var previousTickerPrice = angular.copy($rootScope.tickerPrice);
            	$rootScope.previousTickerPrice = previousTickerPrice;
            	//get ticker price
            	$rootScope.tickerPrice = MarketWatch.getTickerPrice(marketDepthDTO, previousTickerPrice, $rootScope.previousDayClosingPriceForSpot, $rootScope.previousDayClosingPriceForIbSpot);
        	//})
        	//}, 500)
        }
        
        function marketSettingsChanges(marketSessionSettings){
        	//$rootScope.marketSessionSettingsList = marketSessionSettings;
        	setMarketSettings(marketSessionSettings);
        }
        
        function tacNotificationFeed(object){
        	if(object!=null && object!=undefined && Number(object.notificationId.substring(1)) > $rootScope.queryServerNotificationLastId){
        		var notification = object.object;
        		var dateFromServer  =  DateUtils.convertLocalDateToServer(notification.enforcingDate) ;
        		var date = $filter('date')(dateFromServer, "dd-MMM-yyyy");
        		$rootScope.messageString = "";
        		if(notification.tacTypeId == 1){
        			$rootScope.messageString = "Clearcorp has revised the terms & conditions for the customers. Kindly agree it before the enforcing date " + date + " in order to avoid any restrictions in order related activity on the platform."+"<br>"+"Direction: Menus -> Settings -> Terms & Conditions View";
        		}else if(notification.tacTypeId == 2){
                    $rootScope.messageString = "Clearcorp has revised the terms & conditions for the member banks. Kindly agree it before the enforcing date " + date +" to avoid any restrictions in approvals of new customers/add bank requests."+"<br>"+"Direction: Menus -> Settings -> Clearcorp's Terms & Conditions View";
        		}else{
        			$rootScope.messageString = "Your relationship bank " + notification.orgName + " has revised the terms & conditions. Kindly agree it before the enforcing date " + date + " in order to avoid any restrictions in order related activity on the platform with "+ notification.orgName +"."+"<br>"+"Direction: Menus -> Settings -> Terms & Conditions View";
        		}
        		if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
        			$.growl.alert({ title: "Terms & Conditions", message: $rootScope.messageString });
        		}
        		var msgString = "Terms & Conditions~~" + $rootScope.messageString;
        		insertPopupNotifications(msgString, object.notificationId);
        	}

        }
    	
    	function pendingOrdersFeed(pendingOrder){

        	//get pending orders from feed
        	//setTimeout(function(){
        	//$scope.$apply(function(){
            	var existingPendingOrders = [];
            	var pendingOrderIndex = -1;
            	if($rootScope.pendingOrders!=null && $rootScope.pendingOrders!=undefined){
            		if($rootScope.pendingOrders.length>0){
            			existingPendingOrders = angular.copy($rootScope.pendingOrders);
            		}
            	}
            	var merged = false;
            	if(pendingOrder!=null && pendingOrder!=undefined){
            		pendingOrder.expiryTimeString=moment(pendingOrder.expiryTime,'YYYYMMDDHHmmss').format();
            		if(existingPendingOrders!=null && existingPendingOrders!= undefined){
            			if(existingPendingOrders.length>0){
                        	angular.forEach(existingPendingOrders, function(value, index){
                        		//if new order placed, order seq no will be 0 initially before the order confirmed 
                        		if(value.clientOrderID==pendingOrder.clientOrderID){
                        			existingPendingOrders[index] = pendingOrder;
                        			pendingOrderIndex = index;
                        			merged = true;
                        		}else if(value.orderSequenceNumber==pendingOrder.clientOrderID){
                        			existingPendingOrders[index] = pendingOrder;
                        			pendingOrderIndex = index;
                        			merged = true;
                        		}else if(value.orderSequenceNumber==pendingOrder.orderSequenceNumber){
                        			existingPendingOrders[index] = pendingOrder;
                        			pendingOrderIndex = index;
                        			merged = true;
                        		}
                        	});
            			}else{
            				if($rootScope.bankFilterForPO!=null && pendingOrder.bankId==$rootScope.bankFilterForPO.id && $rootScope.branchFilterForPO!=null && pendingOrder.branchId==$rootScope.branchFilterForPO.id 
                    				&& $rootScope.customerFilterForPO!=null && pendingOrder.customerId==$rootScope.customerFilterForPO.id){
            					if($rootScope.instrumentFilterForPO==null){
                        			existingPendingOrders.push(pendingOrder);
                        			pendingOrderIndex = existingPendingOrders.length - 1;
                        			merged = true;
            					}else if(pendingOrder.retailOrderInstID==$rootScope.instrumentFilterForPO.id){
                        			existingPendingOrders.push(pendingOrder);
                        			pendingOrderIndex = existingPendingOrders.length - 1;
                        			merged = true;
            					}
            				}
                		}
            		}
            		
                	if(!merged){
        				if($rootScope.bankFilterForPO!=null && pendingOrder.bankId==$rootScope.bankFilterForPO.id && $rootScope.branchFilterForPO!=null && pendingOrder.branchId==$rootScope.branchFilterForPO.id 
                				&& $rootScope.customerFilterForPO!=null && pendingOrder.customerId==$rootScope.customerFilterForPO.id){
        					if($rootScope.instrumentFilterForPO==null){
                    			existingPendingOrders.push(pendingOrder);
                    			pendingOrderIndex = existingPendingOrders.length - 1;
                    			merged = true;
        					}else if(pendingOrder.retailOrderInstID==$rootScope.instrumentFilterForPO.id){
                    			existingPendingOrders.push(pendingOrder);
                    			pendingOrderIndex = existingPendingOrders.length - 1;
                    			merged = true;
        					}
        				}else if($rootScope.bankFilterForPO==null && $rootScope.branchFilterForPO!=null && pendingOrder.branchId==$rootScope.branchFilterForPO.id 
                                && $rootScope.customerFilterForPO!=null && pendingOrder.customerId==$rootScope.customerFilterForPO.id){
                            if($rootScope.instrumentFilterForPO==null){
                                existingPendingOrders.push(pendingOrder);
                                pendingOrderIndex = existingPendingOrders.length - 1;
                                merged = true;
                            }else if(pendingOrder.retailOrderInstID==$rootScope.instrumentFilterForPO.id){
                                existingPendingOrders.push(pendingOrder);
                                pendingOrderIndex = existingPendingOrders.length - 1;
                                merged = true;
                            }
                        }else if($rootScope.bankFilterForPO==null && $rootScope.branchFilterForPO==null && $rootScope.customerFilterForPO!=null && pendingOrder.customerId==$rootScope.customerFilterForPO.id){
                            if($rootScope.instrumentFilterForPO==null){
                                existingPendingOrders.push(pendingOrder);
                                pendingOrderIndex = existingPendingOrders.length - 1;
                                merged = true;
                            }else if(pendingOrder.retailOrderInstID==$rootScope.instrumentFilterForPO.id){
                                existingPendingOrders.push(pendingOrder);
                                pendingOrderIndex = existingPendingOrders.length - 1;
                                merged = true;
                            }
                        }else if($rootScope.bankFilterForPO==null && $rootScope.branchFilterForPO==null && $rootScope.customerFilterForPO==null){
                            if($rootScope.instrumentFilterForPO==null){
                                existingPendingOrders.push(pendingOrder);
                                pendingOrderIndex = existingPendingOrders.length - 1;
                                merged = true;
                            }else if(pendingOrder.retailOrderInstID==$rootScope.instrumentFilterForPO.id){
                                existingPendingOrders.push(pendingOrder);
                                pendingOrderIndex = existingPendingOrders.length - 1;
                                merged = true;
                            }
                        }
                	}
            	}
            	
            	if(merged){
            		if(existingPendingOrders!=null && existingPendingOrders!=undefined && existingPendingOrders.length>0){
                		var orderStatus = $filter('filter')($rootScope.orderStatusList, {id: existingPendingOrders[pendingOrderIndex].orderStatusId});
                        var eventType = $filter('filter')($rootScope.eventTypes, {id: existingPendingOrders[pendingOrderIndex].eventTypeId});
                		existingPendingOrders[pendingOrderIndex].orderStatus = orderStatus[0].name;
                        existingPendingOrders[pendingOrderIndex].eventTypeName = eventType[0].name;
                        if (orderStatus[0].name=='Cancelled' || orderStatus[0].name=='Traded' || orderStatus[0].name=='Filled' 
                            || orderStatus[0].name=='Rejected' || orderStatus[0].name=='Rejected By RMS' || orderStatus[0].name=='Cancelled By RMS' ){
                        	existingPendingOrders.splice(pendingOrderIndex, 1);
                		}
            		}
            	}
            	
            	$rootScope.pendingOrders = existingPendingOrders;
//            	setTimeout(function(){$scope.$apply(function(){$rootScope.pendingOrdersMemory = existingPendingOrders;})});
        	//})
        	//}, 500)
        	
        
    	}
    	
    	 //update limits from the feed 
        function limitUpdateFeed(limit){
        	//setTimeout(function(){
        	//$scope.$apply(function(){
            	var existinglimit = [];
                var merged = false;
            	if($rootScope.limitsAvailableForSelectedInstrument!=null && $rootScope.limitsAvailableForSelectedInstrument!=undefined){
            		if($rootScope.limitsAvailableForSelectedInstrument.length>0){
                        if(limit.instrumentName!='ALL'){
                            angular.forEach($rootScope.limitsAvailableForSelectedInstrument, function(limitvalue, limitindex){
                                if(limitvalue.instrumentName=='ALL'){
                                    $rootScope.limitsAvailableForSelectedInstrument.splice(limitindex, 1);
                                }
                            })
                            if(limit.instrumentName=='SPOT' || limit.instrumentName=='CASH' || limit.instrumentName=='TOM'){
                                angular.forEach($rootScope.limitsAvailableForSelectedInstrument, function(limitvalue1, limitindex1){
                                    if(limitvalue1.instrumentName=='Upto SPOT'){
                                        $rootScope.limitsAvailableForSelectedInstrument.splice(limitindex1, 1);
                                    }
                                })
                            }
                            if(limit.instrumentName=='Upto SPOT'){
                                angular.forEach($rootScope.limitsAvailableForSelectedInstrument, function(limitvalue2, limitindex2){
                                    if(limitvalue2.instrumentName=='SPOT'){
                                        $rootScope.limitsAvailableForSelectedInstrument.splice(limitindex2, 1);
                                    }
                                })
                                angular.forEach($rootScope.limitsAvailableForSelectedInstrument, function(limitvalue3, limitindex3){
                                    if(limitvalue3.instrumentName=='TOM'){
                                        $rootScope.limitsAvailableForSelectedInstrument.splice(limitindex3, 1);
                                    }
                                })
                                angular.forEach($rootScope.limitsAvailableForSelectedInstrument, function(limitvalue4, limitindex4){
                                    if(limitvalue4.instrumentName=='CASH'){
                                        $rootScope.limitsAvailableForSelectedInstrument.splice(limitindex4, 1);
                                    }
                                })
                            }

                            existinglimit = $rootScope.limitsAvailableForSelectedInstrument;
                        }
            			
            		}
            	}
            	if(limit!=null && limit!=undefined){
            		if(existinglimit!=null && existinglimit!= undefined && existinglimit.length>0){
            			//if(existinglimit.length>0){
                        	angular.forEach(existinglimit, function(value, index){
                        		//if new order placed, order seq no will be 0 initially before the order confirmed 
                        		if(value.bankId==limit.bankId && value.branchId==limit.branchId && value.customerId==limit.customerId && (value.instId==limit.instId || value.instId==null)){
                        			if(limit.active==true){
                        				existinglimit[index] = limit;
                                        merged = true;
                            			//getLimitsForEachInstrument(limit);
                        			}else if(limit.active==false){
                        				existinglimit.splice(index, 1);
                                        merged = true;
                        				fillZeroIfLimitIsNotDefinedForInstrumentCode(limit.instrumentCode);
                        			}
                        		}
                        	});
            			//}
            		}else{
            			if($rootScope.instrumentFilterForLimit==null || $rootScope.instrumentFilterForLimit==undefined){
            				existinglimit.push(limit);
                            merged = true;
            			}else if($rootScope.instrumentFilterForLimit.id==limit.instId || limit.instId==0){
                            merged = true;
            				existinglimit.push(limit);
            			}
            		}
                    if(!merged){
                        if($rootScope.instrumentFilterForLimit==null || $rootScope.instrumentFilterForLimit==undefined){
                            existinglimit.push(limit);
                            merged = true;
                        }else if($rootScope.instrumentFilterForLimit.id==limit.instId || limit.instId==0){
                            merged = true;
                            existinglimit.push(limit);
                        }
                    }
                    if($rootScope.defaultTradingBank!=null && $rootScope.defaultTradingBank.id==limit.bankId && limit.active==true){
                        //if reset action type is spot window, then clear existing limits on forward
                        if(limit.resetActionType=='SPOTWINDOW'){ 
                            fillZeroIfLimitIsNotDefinedForInstrumentCode('Forward');
                        }else if(limit.resetActionType=='FORWARD'){
                            //if reset action type is forward, then clear existing limits on SPOT window
                            fillZeroIfLimitIsNotDefinedForInstrumentCode('SPOT');
                            fillZeroIfLimitIsNotDefinedForInstrumentCode('TOM');
                            fillZeroIfLimitIsNotDefinedForInstrumentCode('CASH');
                        }
            			getLimitsForEachInstrument(limit);
                    }else if($rootScope.defaultTradingBank!=null && $rootScope.defaultTradingBank.id==limit.bankId && limit.active==false){
            			fillZeroIfLimitIsNotDefinedForInstrumentCode(limit.instrumentCode);
            		}
            		
            		if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER' 
            			&& $rootScope.account.organisation.id == limit.customerId
            			&& $rootScope.bankFilterForLimit.id ==limit.bankId && limit.active==true  ){
            			$rootScope.limitAvailableForSlectBankFilterInReport = true;
            		}else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER' 
            			&& $rootScope.account.organisation.id == limit.customerId
            			&& $rootScope.bankFilterForLimit.id ==limit.bankId && limit.active==false  ){
            			$rootScope.limitAvailableForSlectBankFilterInReport = false;
            		}
            	}

                angular.forEach(existinglimit, function(limit, index){
                    if(limit.active==false){
                        existinglimit.splice(index, 1);
                        fillZeroIfLimitIsNotDefinedForInstrumentCode(limit.instrumentCode);
                    }
                })

//            	setTimeout(function(){$scope.$apply(function(){$rootScope.limitsAvailableForSelectedInstrumentMemory = existinglimit;})});
            	$rootScope.limitsAvailableForSelectedInstrument = existinglimit;


                //apply credit factor
                var creditFactor = MarketWatch.getCreditFactorValue($rootScope.defaultInstrumentCode, angular.copy($rootScope.tradeSettlementDate), $rootScope.selectedCreditFactor, GlobalValues.getConfigSettings().defaultCreditFactorSettingsInPercentage);
                $rootScope.forwardBuyLimitWithCF = MarketWatch.computeLimitAmountApplyingCreditFactor(angular.copy($rootScope.forwardBuyLimit), creditFactor);
                $rootScope.forwardSellLimitWithCF = MarketWatch.computeLimitAmountApplyingCreditFactor(angular.copy($rootScope.forwardSellLimit), creditFactor);

        	//});
        	//}, 500)
        }
        
        function fillZeroIfLimitIsNotDefinedForInstrumentCode(instrumentCode){
        	if(instrumentCode=='SPOT'){
            	$rootScope.retailSpotBuyLimit = 0;
    			$rootScope.retailSpotSellLimit = 0;
        	}else if(instrumentCode=='CASH'){
    			$rootScope.cashBuyLimit = 0;
    			$rootScope.cashSellLimit = 0;
        	}else if(instrumentCode=='TOM'){
    			$rootScope.tomBuyLimit = 0;
    			$rootScope.tomSellLimit = 0;
        	}else if(instrumentCode=='UptoSPOT'){
                $rootScope.retailSpotBuyLimit = 0;
                $rootScope.retailSpotSellLimit = 0;
                $rootScope.cashBuyLimit = 0;
                $rootScope.cashSellLimit = 0;
                $rootScope.tomBuyLimit = 0;
                $rootScope.tomSellLimit = 0;
            }else if(instrumentCode=='Forward'){
                $rootScope.forwardBuyLimit = 0;
                $rootScope.forwardSellLimit = 0;
                $rootScope.forwardBuyLimitWithCF = 0;
                $rootScope.forwardSellLimitWithCF = 0;
                $rootScope.maxForwardTenorDate = null;
            }else if(instrumentCode=='ALL' || instrumentCode==null ||  instrumentCode==undefined){
            	$rootScope.retailSpotBuyLimit = 0;
    			$rootScope.retailSpotSellLimit = 0;
    			$rootScope.cashBuyLimit = 0;
    			$rootScope.cashSellLimit = 0;
    			$rootScope.tomBuyLimit = 0;
    			$rootScope.tomSellLimit = 0;
                $rootScope.forwardBuyLimit = 0;
                $rootScope.forwardSellLimit = 0;
                $rootScope.forwardBuyLimitWithCF = 0;
                $rootScope.forwardSellLimitWithCF = 0;
                $rootScope.maxForwardTenorDate = null;
        	}
        }
        
        
        //trade notificatoins handling
        function tradeNotificationHandling(trade){
        	//setTimeout(function(){
        	//$scope.$apply(function(){
            	//get trades from feed
        		var existingTrades = [];
            	if($rootScope.trades!=null && $rootScope.trades!=undefined){
            		if($rootScope.trades.length>0){
            			existingTrades = $rootScope.trades;
            		}
            	}
            	var merged = false;
            	if(trade!=null && trade!=undefined){
                    var eventType = $filter('filter')($rootScope.eventTypes, {id: trade.eventTypeId});
                    trade.eventTypeName = eventType[0].name;
            		trade.tradeTime=moment(trade.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
            		if($rootScope.bankFilterForTrade!=null && trade.bankId==$rootScope.bankFilterForTrade.id && $rootScope.branchFilterForTrade!=null && trade.branchId==$rootScope.branchFilterForTrade.id 
            				&& $rootScope.customerFilterForTrade!=null && trade.customerId==$rootScope.customerFilterForTrade.id){
            			if($rootScope.instrumentFilterForTrade==null){
                    		existingTrades.push(trade);
    					}else if(trade.retailOrderInstId==$rootScope.instrumentFilterForTrade.id){
                    		existingTrades.push(trade);
    					}
    				}else if($rootScope.bankFilterForTrade==null && $rootScope.branchFilterForTrade!=null && trade.branchId==$rootScope.branchFilterForTrade.id 
                            && $rootScope.customerFilterForTrade!=null && trade.customerId==$rootScope.customerFilterForTrade.id){
                        if($rootScope.instrumentFilterForTrade==null){
                            existingTrades.push(trade);
                        }else if(trade.retailOrderInstId==$rootScope.instrumentFilterForTrade.id){
                            existingTrades.push(trade);
                        }
                    }else if($rootScope.bankFilterForTrade==null && $rootScope.branchFilterForTrade==null && $rootScope.customerFilterForTrade!=null && trade.customerId==$rootScope.customerFilterForTrade.id){
                        if($rootScope.instrumentFilterForTrade==null){
                            existingTrades.push(trade);
                        }else if(trade.retailOrderInstId==$rootScope.instrumentFilterForTrade.id){
                            existingTrades.push(trade);
                        }
                    }else if($rootScope.bankFilterForTrade==null && $rootScope.branchFilterForTrade==null && $rootScope.customerFilterForTrade==null){
                        if($rootScope.instrumentFilterForTrade==null){
                            existingTrades.push(trade);
                        }else if(trade.retailOrderInstId==$rootScope.instrumentFilterForTrade.id){
                            existingTrades.push(trade);
                        }
                    }
            	}
            	
            	var summaryBuyTrades = existingTrades.filter(function(val){
                    return val.buySellIndicator == "BUYY";
                })
                
                var summarySellTrades = existingTrades.filter(function(val){
                    return val.buySellIndicator == "SELL";
                })
                
                var totalUSDBought = summaryBuyTrades.reduce(function (a,b) { return a + b.tradedAmount; }, 0);
             var totalUSDSold = summarySellTrades.reduce(function (a,b) { return a + b.tradedAmount; }, 0);
             var totalINRSold = summaryBuyTrades.reduce(function (a,b) { return a + b.tradedValue; }, 0);
             var totalINRBought = summarySellTrades.reduce(function (a,b) { return a + b.tradedValue; }, 0);
                
                $rootScope.trades = existingTrades;
//                setTimeout(function(){$scope.$apply(function(){$rootScope.tradesMemory = existingTrades;})});
            //});
            //}, 500)
                
                if($rootScope.instrumentFilterForTrade!=null){
                	$rootScope.totalUSDBought = totalUSDBought;
                    $rootScope.totalUSDSold = totalUSDSold;
                    $rootScope.totalINRSold = totalINRSold;
                    $rootScope.totalINRBought = totalINRBought;
                    $rootScope.weightedAverageBuy = Math.abs(totalINRSold/totalUSDBought);
                    $rootScope.weightedAverageSell = Math.abs(totalINRBought/totalUSDSold);
                    $rootScope.usdNet = totalUSDBought - totalUSDSold;
                    $rootScope.inrNet = totalINRBought - totalINRSold;
                    $rootScope.netWeightedAveragePrice = Math.abs((totalINRBought - totalINRSold) / (totalUSDBought - totalUSDSold));
                    
                	if(isNaN(Math.abs(totalINRSold/totalUSDBought)) || Math.abs(totalINRSold/totalUSDBought) == Infinity)
                		$rootScope.weightedAverageBuy = null;
                	if(isNaN(Math.abs((totalINRBought - totalINRSold) / (totalUSDBought - totalUSDSold))) ||
                			(Math.abs((totalINRBought - totalINRSold) / (totalUSDBought - totalUSDSold))) == Infinity)
                		$rootScope.netWeightedAveragePrice = null;
                	
                	if(isNaN(Math.abs(totalINRBought/totalUSDSold)) || Math.abs(totalINRBought/totalUSDSold) == Infinity)
                		 $rootScope.weightedAverageSell = null;
                }else{
                	$rootScope.totalUSDBought = null;
                    $rootScope.totalUSDSold = null;
                    $rootScope.totalINRSold = null;
                    $rootScope.totalINRBought = null;
                    $rootScope.weightedAverageBuy = null;
                    $rootScope.weightedAverageSell = null;
                    $rootScope.usdNet = null;
                    $rootScope.inrNet = null;
                    $rootScope.netWeightedAveragePrice = null;
                }
            	
        }
        
        function tacAgreedChange(tacAgreed){
        	console.log("tacAgreedChange");
            angular.forEach($rootScope.agreedTACList, function(value){
                if(tacAgreed.tacMasterOrgId==value.providerId){
                    value.isAgreed = true;
                }
            })
        }
        
        function swapQuoteChanges(swapQuote){
        	//var existingQuotes = $rootScope.bankComparativeQuotes;
        	angular.forEach($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, function(value, index){
        		if(value.bank.bankName==swapQuote.bank.bankName){
                    var swapQuotesTenorDateDTO = angular.copy($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList[index]);

                    angular.forEach(swapQuote.swapQuotesForTenorDatesList, function(quote){
                        angular.forEach(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, function(existingQuote, existingQuoteIndex){
                            if(quote.tenorName == existingQuote.tenorName){
                                swapQuotesTenorDateDTO.swapQuotesForTenorDatesList[existingQuoteIndex] = quote;
                            }
                        })
                    })
                    $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList[index] = swapQuotesTenorDateDTO;
        			//change swap quotes in home page
        			prepareSwapQuotes();

        		}
        	})
        	//$rootScope.bankComparativeQuotes = existingQuotes;
        }
        
        function markupChange(markup){
        	var merged = false;
        	var existingMarkup = [];
        	if($rootScope.markupSettings!=null && $rootScope.markupSettings!=undefined){
        		if($rootScope.markupSettings.length>0){
        			existingMarkup = angular.copy($rootScope.markupSettings);
        		}
        	}
        	if(existingMarkup!=null && existingMarkup!= undefined){
    			if(existingMarkup.length>0){
    				angular.forEach(existingMarkup, function(value, index){
    	        		if(value.bankId==markup.bankId && value.customerId==markup.customerId){
    	        			existingMarkup[index] = markup;
    	        			merged = true;
    	        		}
    	        	});
    			}
    		}
        	
        	if(!merged && $rootScope.account.organisation.organisationType.shortName=='CUSTOMER' && markup.customerId == $rootScope.account.organisation.id){
        		existingMarkup.push(markup);
        		merged = true;
        	}
        	
        	$rootScope.markupSettings = existingMarkup;
        }

        function tradeOutstandingUpdate(outstandingTrade){

                var existingOutstandingTrades = [];
                var tradeOutstanidngIndex = -1;
                var fromSettDate = null;
                var toSettDate = null;

                //if the from date is null then provide the minimum possible date , else send the selected date 
                if($rootScope.fromSettlementDate == null && $rootScope.fromSettlementDate == undefined){
                    fromSettDate = new Date(1900,0,1);
                }else{
                    fromSettDate = $rootScope.fromSettlementDate;
                }

                // if to date is null then provide the maximum possible date , else send the selected date 
                if($rootScope.toSettlementDate == null && $rootScope.toSettlementDate == undefined){
                    toSettDate = new Date(9999,11,31); 
                }else{
                    toSettDate = $rootScope.toSettlementDate; 
                }
                fromSettDate = DateUtils.convertLocalDateToServer(fromSettDate);
                toSettDate = DateUtils.convertLocalDateToServer(toSettDate);

                if($rootScope.outstandingTrades!=null && $rootScope.outstandingTrades!=undefined){
                    if($rootScope.outstandingTrades.length>0){
                        existingOutstandingTrades = angular.copy($rootScope.outstandingTrades);
                    }
                }
                var merged = false;
                if(outstandingTrade!=null && outstandingTrade!=undefined){
                    outstandingTrade.tradeTime=moment(outstandingTrade.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
                    if(existingOutstandingTrades!=null && existingOutstandingTrades!= undefined){
                        if(existingOutstandingTrades.length>0){
                            angular.forEach(existingOutstandingTrades, function(value, index){
                                if(outstandingTrade.orderSequenceNumber!=null && outstandingTrade.tradeSequenceNumber!=undefined){
                                    if(value.orderSequenceNumber==outstandingTrade.orderSequenceNumber &&
                                        value.tradeSequenceNumber==outstandingTrade.tradeSequenceNumber){
                                        existingOutstandingTrades[index] = outstandingTrade;
                                        tradeOutstanidngIndex = index;
                                        merged = true;
                                    }
                                }
                            });
                        }else{
                            if($rootScope.bankFilterForOT!=null && outstandingTrade.bankId==$rootScope.bankFilterForOT.id && $rootScope.branchFilterForOT!=null && outstandingTrade.branchId==$rootScope.branchFilterForOT.id 
                                    && $rootScope.customerFilterForOT!=null && outstandingTrade.customerId==$rootScope.customerFilterForOT.id
                                    && outstandingTrade.tradeSettlementDate>=fromSettDate && outstandingTrade.tradeSettlementDate<=toSettDate){
                                if($rootScope.instrumentFilterForOT==null){
                                    existingOutstandingTrades.push(outstandingTrade);
                                    tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                    merged = true;
                                }else if(outstandingTrade.retailOrderInstID==$rootScope.instrumentFilterForOT.id){
                                    existingOutstandingTrades.push(outstandingTrade);
                                    tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                    merged = true;
                                }
                            }
                        }
                    }
                    
                    if(!merged){
                        if($rootScope.bankFilterForOT!=null && outstandingTrade.bankId==$rootScope.bankFilterForOT.id && $rootScope.branchFilterForOT!=null && outstandingTrade.branchId==$rootScope.branchFilterForOT.id 
                                && $rootScope.customerFilterForOT!=null && outstandingTrade.customerId==$rootScope.customerFilterForOT.id
                                && outstandingTrade.tradeSettlementDate>=fromSettDate && outstandingTrade.tradeSettlementDate<=toSettDate){
                            if($rootScope.instrumentFilterForOT==null){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }else if(outstandingTrade.retailOrderInstId==$rootScope.instrumentFilterForOT.id){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }
                        }else if($rootScope.bankFilterForOT==null && $rootScope.branchFilterForOT!=null && outstandingTrade.branchId==$rootScope.branchFilterForOT.id 
                                && $rootScope.customerFilterForOT!=null && outstandingTrade.customerId==$rootScope.customerFilterForOT.id
                                && outstandingTrade.tradeSettlementDate>=fromSettDate && outstandingTrade.tradeSettlementDate<=toSettDate){
                            if($rootScope.instrumentFilterForOT==null){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }else if(outstandingTrade.retailOrderInstId==$rootScope.instrumentFilterForOT.id){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }
                        }else if($rootScope.bankFilterForOT==null && $rootScope.branchFilterForOT==null && $rootScope.customerFilterForOT!=null && outstandingTrade.customerId==$rootScope.customerFilterForOT.id
                            && outstandingTrade.tradeSettlementDate>=fromSettDate && outstandingTrade.tradeSettlementDate<=toSettDate){
                            if($rootScope.instrumentFilterForOT==null){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }else if(outstandingTrade.retailOrderInstId==$rootScope.instrumentFilterForOT.id){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }
                        }else if($rootScope.bankFilterForOT==null && $rootScope.branchFilterForOT==null && $rootScope.customerFilterForOT==null
                            && outstandingTrade.tradeSettlementDate>=fromSettDate && outstandingTrade.tradeSettlementDate<=toSettDate){
                            if($rootScope.instrumentFilterForOT==null){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }else if(outstandingTrade.retailOrderInstId==$rootScope.instrumentFilterForOT.id){
                                existingOutstandingTrades.push(outstandingTrade);
                                tradeOutstanidngIndex = existingOutstandingTrades.length - 1;
                                merged = true;
                            }
                        }
                    }
                }
                
                if(merged){
                    if(existingOutstandingTrades!=null && existingOutstandingTrades!=undefined && existingOutstandingTrades.length>0){
                        var orderStatus = $filter('filter')($rootScope.orderStatusList, {id: existingOutstandingTrades[tradeOutstanidngIndex].orderStatusId});
                        var eventType = $filter('filter')($rootScope.eventTypes, {id: existingOutstandingTrades[tradeOutstanidngIndex].eventTypeId});
                        var tradeStatus = $filter('filter')($rootScope.tradeStatusList, {id: existingOutstandingTrades[tradeOutstanidngIndex].tradeStatusId});
                        existingOutstandingTrades[tradeOutstanidngIndex].eventTypeName = eventType[0].name;
                        existingOutstandingTrades[tradeOutstanidngIndex].orderStatus = orderStatus[0].name;
                        existingOutstandingTrades[tradeOutstanidngIndex].tradeStatusName = tradeStatus[0].name;
                        if (existingOutstandingTrades[tradeOutstanidngIndex].outStandingAmount<=0){
                            existingOutstandingTrades.splice(tradeOutstanidngIndex, 1);
                        }
                    }
                }
                
                $rootScope.outstandingTrades = existingOutstandingTrades;
        
        }
        
        function getLimitsForEachInstrument(limit){
        	$rootScope.limitType = limit.limitType;
        	$rootScope.limitNotDefined = false;
        	if(limit.instrumentCode=='SPOT'){
    			$rootScope.retailSpotBuyLimit = getBuyingPower(limit);
    			$rootScope.retailSpotSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='CASH'){
    			$rootScope.cashBuyLimit = getBuyingPower(limit);
    			$rootScope.cashSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='TOM'){
    			$rootScope.tomBuyLimit = getBuyingPower(limit);
    			$rootScope.tomSellLimit = getSellingPower(limit);
    		}else if(limit.instrumentCode=='Forward'){
                $rootScope.forwardBuyLimit = getBuyingPower(limit);
                $rootScope.forwardSellLimit = getSellingPower(limit);
                $rootScope.maxForwardTenorDate = MarketWatch.getMaxForwardTenorDate(limit, new Date($rootScope.retailSpotMarketSession.settlementDate));
            }
        	
        	if(limit.instrumentCode=='ALL' || $rootScope.limitInstrumentCode=='ALL' || limit.instrumentCode==null){
        		var buyingPower = getBuyingPower(limit);
        		var sellingPower =  getSellingPower(limit);
    			$rootScope.retailSpotBuyLimit = buyingPower;
    			$rootScope.retailSpotSellLimit = sellingPower;
    			$rootScope.tomBuyLimit = buyingPower;
    			$rootScope.tomSellLimit = sellingPower;
    			$rootScope.cashBuyLimit = buyingPower;
    			$rootScope.cashSellLimit = sellingPower;
                $rootScope.forwardBuyLimit = buyingPower;
                $rootScope.forwardSellLimit = sellingPower;
                $rootScope.maxForwardTenorDate = MarketWatch.getMaxForwardTenorDate(limit, new Date($rootScope.retailSpotMarketSession.settlementDate));
        	}
            if(limit.instrumentCode=='UptoSPOT'){
                var buyingPower = getBuyingPower(limit);
                var sellingPower =  getSellingPower(limit);
                $rootScope.retailSpotBuyLimit = buyingPower;
                $rootScope.retailSpotSellLimit = sellingPower;
                $rootScope.tomBuyLimit = buyingPower;
                $rootScope.tomSellLimit = sellingPower;
                $rootScope.cashBuyLimit = buyingPower;
                $rootScope.cashSellLimit = sellingPower;
            }
        	if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
        		fillLimitsInMarketWatch();
        	}
        }
        
        function getBuyingPower(limit){
        	var buyingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
        	var totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
        	if(limit.limitType=='GROSSSELL'){
        		buyingPower = 0;
        	}else if(limit.limitType=='GROSSBUY'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy);
        	}else if(limit.limitType=='GLOBALGROSS'){
        		buyingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue  + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		buyingPower = limit.limitAllocatedInUSD - totalBuy;
        	}
            buyingPower = (Math.round(parseFloat(buyingPower)*100)/100);
        	return buyingPower;
        }
        
        function getSellingPower(limit){
        	var sellingPower = 0;
        	var totalBuy = limit.totalPendingBuyOrderValue + (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue);
        	var totalSell = limit.totalPendingSellOrderValue + (limit.totalSellTradeValue + limit.previousDaySellTradeValue);
        	if(limit.limitType=='GROSSSELL'){
        		sellingPower = limit.limitAllocatedInUSD - (totalSell);
        	}else if(limit.limitType=='GROSSBUY'){
        		sellingPower = 0;
        	}else if(limit.limitType=='GLOBALGROSS'){
        		sellingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
        	}else if(limit.limitType=='NET'){
        		totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
        		totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));
        		var xVals = [];
        		xVals.push(totalBuy);
        		xVals.push(totalSell);
        		sellingPower = limit.limitAllocatedInUSD - totalSell;
        	}
            sellingPower = (Math.round(parseFloat(sellingPower)*100)/100);
        	return sellingPower;
        }
        
        function fillLimitsInMarketWatch(){
        	$rootScope.retailSpotBuyLimitForMkt = $rootScope.retailSpotBuyLimit;
			$rootScope.retailSpotSellLimitForMkt = $rootScope.retailSpotSellLimit;
			$rootScope.tomBuyLimitForMkt = $rootScope.tomBuyLimit;
			$rootScope.tomSellLimitForMkt = $rootScope.tomSellLimit;
			$rootScope.cashBuyLimitForMkt = $rootScope.cashBuyLimit;
			$rootScope.cashSellLimitForMkt = $rootScope.cashSellLimit;
            $rootScope.forwardBuyLimitForMkt = $rootScope.forwardBuyLimit;
            $rootScope.forwardSellLimitForMkt = $rootScope.forwardSellLimit;
        }
        
        function prepareSwapQuotes(){
            //get best offer and bid spread for Cash/spot and tom/spot, frwd/spot.
            getBestSpreadRates($rootScope.bankComparativeDTO);

            //if($rootScope.defaultTab=='comparitive_tab2' || $rootScope.defaultTab=='comparitive_tab4'){
                //prepare bank comparativeQuotes in price
            prepareBankComparativeQuotesInPrice($rootScope.bankComparativeDTO, $rootScope.retailSpotMarketWatch);
            //}
    		
    		
        	if($rootScope.bankComparativeDTO!=null && $rootScope.bankComparativeDTO!=undefined 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList!=null 
                && $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList.length==1){
                $rootScope.defaultBankSpread = $rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList[0].swapQuotesForTenorDatesList;
            }else{
                // get default bank swap quotes for the customer user
                console.log('prepare swap quotes in home controller');
                console.log($rootScope.defaultTradingBank);
                if($rootScope.defaultTradingBank!=null && $rootScope.defaultTradingBank!=undefined){
                    $rootScope.defaultBankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, $rootScope.defaultTradingBank.id);
                }
            }

            //update user market watch online
            prepareUserMarketWatchOnline();

            //update outright comparison when swap quote changes received
            prepareOutrightComparision($rootScope.outrightRateSettlementDate, $rootScope.outrightRateInstrument);
        }
        
        function prepareBankComparativeQuotesInPrice(bankComparativeDTO, retailSpotMarketWatch){
            $rootScope.bankComparativeDTOInPrice = {};
            $rootScope.bankComparativeDTOInPrice.header = bankComparativeDTO.header;
            $rootScope.bankComparativeDTOInPrice.swapQuotesTenorDateDTOList = [];
            angular.forEach(bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                var swapQuotesTenorDateDTOInPrice = {};
                swapQuotesTenorDateDTOInPrice.bank = swapQuotesTenorDateDTO.bank;
                swapQuotesTenorDateDTOInPrice.businessDate = swapQuotesTenorDateDTO.businessDate;
                swapQuotesTenorDateDTOInPrice.swapQuotesForTenorDatesList = [];
                angular.forEach(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, function(swapQuotesForTenorDates){
                    var swapQuotesForTenorDatesInPrice = {};
                    swapQuotesForTenorDatesInPrice = angular.copy(swapQuotesForTenorDates);
                    // prepare bank quotes in price
                    swapQuotesForTenorDatesInPrice = MarketWatch.getBankSwapQuotesInPrice(swapQuotesForTenorDatesInPrice, retailSpotMarketWatch);
                    swapQuotesTenorDateDTOInPrice.swapQuotesForTenorDatesList.push(swapQuotesForTenorDatesInPrice)
                })
                $rootScope.bankComparativeDTOInPrice.swapQuotesTenorDateDTOList.push(swapQuotesTenorDateDTOInPrice);
            })
            
        }
    	
        function getBestSpreadRates(bankComparativeDTO){
            
            if(bankComparativeDTO!=null && bankComparativeDTO!=undefined && bankComparativeDTO.swapQuotesTenorDateDTOList != null 
                && bankComparativeDTO.swapQuotesTenorDateDTOList.length>0){
                var swapQuotesForTenorDatesList = [];
                angular.forEach(bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                    swapQuotesForTenorDatesList = swapQuotesForTenorDatesList.concat(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList);
                })

                $rootScope.bestBankQuoteList = [];
                angular.forEach(bankComparativeDTO.header, function(header){
                    var bestBankQuote = {};
                    bestBankQuote.tenorName = header;
                    var bankQuotesForTenorForBid = _.filter(angular.copy(swapQuotesForTenorDatesList), { 'tenorName': header });
                    var bankQuotesForTenorForOffer = _.filter(angular.copy(swapQuotesForTenorDatesList), { 'tenorName': header });
                    bestBankQuote.bestBidSpread = Math.max.apply(Math,bankQuotesForTenorForBid.map(function(item){return item.bidSpread;}).filter(function(val){return val !== null}));
                    bestBankQuote.bestOfferSpread = Math.min.apply(Math,bankQuotesForTenorForOffer.map(function(item){return item.offerSpread;}).filter(function(val){return val !== null}));
                    $rootScope.bestBankQuoteList.push(bestBankQuote);
                })
            }

            angular.forEach($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                angular.forEach(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, function(swapQuotesForTenorDates){
                    swapQuotesForTenorDates.isBestBid = false;
                    swapQuotesForTenorDates.isBestOffer = false;
                    angular.forEach($rootScope.bestBankQuoteList, function(bestBankQuote){
                        if(swapQuotesForTenorDates.tenorName==bestBankQuote.tenorName){
                            if(swapQuotesForTenorDates.bidSpread==bestBankQuote.bestBidSpread){
                                swapQuotesForTenorDates.isBestBid = true;
                            }
                            if(swapQuotesForTenorDates.offerSpread==bestBankQuote.bestOfferSpread){
                                swapQuotesForTenorDates.isBestOffer = true;
                            }
                        }
                    })    
                })
            })
        }
        
        
        function getCashAndTomMarketWatch(spotMarketWatch, bankSpread){
    		$rootScope.cashMarketWatch = MarketWatch.getMarketWatchForGivenInstrument(spotMarketWatch, bankSpread, 'CASH');
    		$rootScope.tomMarketWatch = MarketWatch.getMarketWatchForGivenInstrument(spotMarketWatch, bankSpread, 'TOM');
    	}
        function setMarketSettings(value){
        	//loop market session settings and get $rootScope.retailSpotMarketSession, $rootScope.cashMarketSession, $rootScope.tomMarketSession..
        	//angular.forEach($rootScope.marketSessionSettings, function(value){
        		if(value.instrumentCode == 'SPOT'){
        			$rootScope.retailSpotMarketSession = value;
        		}else if(value.instrumentCode == 'TOM'){
        			$rootScope.tomMarketSession = value;	
        		}else if(value.instrumentCode == 'CASH'){
        			$rootScope.cashMarketSession = value;
        		}else if(value.instrumentCode == 'Forward'){
                    $rootScope.forwardMarketSession = value;
                }
        	//})
            //prepare user market watch online
            prepareUserMarketWatchOnline();
        }
        
        function getMarketDepthDetailsForReport(){
        	
//        	setTimeout(function(){
//        		var existingSpotMarketDepth = angular.copy($rootScope.retailSpotMarketDepthMemory);
//            	//get market depth for SPOT
//            	existingSpotMarketDepth = MarketWatch.getRetailSpotMarketDepth($rootScope.marketDepth);
            	
            	$rootScope.retailSpotMarketDepth = MarketWatch.getRetailSpotMarketDepth($rootScope.marketDepth);
            	
            	if($rootScope.retailSpotMarketDepth!=null && $rootScope.retailSpotMarketDepth!=undefined){
            		getRetailSpotMarketWatch();
            	}

            	var existingIbSpotMarketDepth = $rootScope.ibSpotMarketDepth;
            	//get market depth for IB SPOT
            	existingIbSpotMarketDepth = MarketWatch.getIBSpotMarketDepth($rootScope.marketDepth);
            	$rootScope.ibSpotMarketDepth = existingIbSpotMarketDepth;
            	
            	if($rootScope.ibSpotMarketDepth!=null && $rootScope.ibSpotMarketDepth!=undefined){
                	//get ibSpotMarketWatch
            		var existingIbSpotMarketWatch = $rootScope.ibSpotMarketWatch;
            		existingIbSpotMarketWatch = MarketWatch.getIBSpotMarketWatch($rootScope.ibSpotMarketDepth);
            		$rootScope.ibSpotMarketWatch = existingIbSpotMarketWatch;
            	}
//            	$scope.$apply();
//        	}, 100)
        }        
        
        function getRetailSpotMarketWatch(){
        	var existingSpotMarketWatch = $rootScope.retailSpotMarketWatch;
        	//get retailSpot market watch
        	existingSpotMarketWatch = MarketWatch.getRetailSpotMarketWatch($rootScope.retailSpotMarketDepth);
        	$rootScope.retailSpotMarketWatch = existingSpotMarketWatch;
        	if($rootScope.retailSpotMarketWatch!=null && $rootScope.retailSpotMarketWatch!=undefined){
            	//get swap quotes
            	//getSwapQuotes()
        	}
        }
        
        function getSwapQuotes(){
            //if the user is customer user, get spread for the banks in which the customer has relationship with.
            //if the user is bank/branch user, get spread for that particular bank.
        	if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP'){
            	SwapQuotes.getSwapQuotesForAllBanks(onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}else if($rootScope.account.organisation.organisationType.shortName=='BANK'){
            	SwapQuotes.getSwapQuotesForGivenBank({bankId: $rootScope.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
            	SwapQuotes.getSwapQuotesForGivenCustomer({customerId: $rootScope.account.organisation.id}, onGetSwapQuotesSuccess, onGetSwapQuotesFailure);
        	}
        }
        
        function onGetSwapQuotesSuccess(result){
        	//result will be list of swap quotes for the bank(s)
        	//if result length is 1, then that is default bank for that user(customer user) and for bank/branch user, the result length will be 1. 
        	if(result.length>0){
        		//get bank swap quotes array to display in the bank comparative quotes section
        		$rootScope.bankComparativeDTO = result;
        		
        		//fill defatult bank for clearcorp users
        		//fillDefaultBankForClearcorpUsers(result);

    			//change swap quotes in home page
        		prepareSwapQuotes();

        	}
        }
    	
    	function onGetSwapQuotesFailure(){
    		
    	}

        function frameOrderNotification(order){
        	
        	if(order!=null && order!=undefined && Number(order.notificationId.substring(1)) > $rootScope.orderServerNotificationLastId){
        		
        		if(order.exceptionNumber!=null && order.exceptionNumber!="" && order.exceptionNumber!=undefined){
        			
        			//get rejection reason for error message
        			 angular.forEach($rootScope.errorMessageList, function(value){
        			   		if(value.messageNumber==8021 && value.code==order.exceptionNumber){
        			       		$rootScope.rejectionReason = value.rejectionReason; 
        			       		order.errorMessage = value.rejectionReason;
        			   		}
        			   	});
            			
        		}
        		
            	if(order.buySellIndicator.toUpperCase()=='BUYY'){
            		order.buySellIndicator = 'BUY'
            	}
            	
            	var orderAmount = 0;
            	if(order.messageNumber=='8021' && (order.activityIndicator=='CNCL' || order.activityIndicatorRequested=='CNCL')){
            		orderAmount = order.remainingAmount;
            	}else{
            		orderAmount = order.orderAmount;
            	}
            	
            	//build the initial notification string
            	if(order.typeOfOrder=='MRKT'){
            		$rootScope.initialString = order.buySellIndicator + " " + order.instrumentCode + " USD " + $filter('number')(orderAmount, 2) + " @ Market " +
            		"for customer " + order.customerCode + " by " + order.retailUserCode;
            	}else{
            		$rootScope.initialString = order.buySellIndicator + " " + order.instrumentCode + " USD " + $filter('number')(orderAmount, 2) + " @ " + $filter('number')(order.limitExchangeRate, 4) +
            		" for customer " + order.customerCode + " by " + order.retailUserCode;
            	}
            	
            
            	
            	
            	
            /*	$rootScope.initialString = order.buySellIndicator + " order for client" + " " + order.customerCode + ", " + order.instrumentCode + " for " + order.orderAmount + " at " 
            		+ order.typeOfOrder + " " + order.limitExchangeRate + " " + order.timeLimitIndicator + " by " + order.retailUserCode;*/
            	
            	
            	//notification string if message number is 2021
            	
            	buildNotificationString(order);
            	
            	
        		
        	}
        }
        
        
        
        //build notification based on message number
        
        function buildNotificationString(order){
        	
        
        	if(order.messageNumber=='2021'){
        		
        		buildStringForMsg2021(order);
        	}
        	
        	else if(order.messageNumber=='8021'){
        		
        		buildStringForMsg8021(order);
        	}else if (order.messageNumber=='8026'){
        		buildStringForMsg8026(order);
        	}
        	
    		
        }
        
        //notification string for message number 2021
        
        function buildStringForMsg2021(order){
    		var isBankBreachedMsg = "";
    		if(order.isBankBreached){
    			isBankBreachedMsg = "and accepted for restricted matching";
    		}
    	
    			if(order.errorMessage!=''){
    			//	$rootScope.rejectionString = $rootScope.initialString + " rejected due to " + "&quot;" + order.errorMessage + "&quot;";
    				if(order.activityIndicator=='PLAC'){
    					$rootScope.messageString = "NEW order request is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
    				}else if(order.activityIndicator=='MDFY'){
    					$rootScope.messageString = "MODIFY request for order No. " + order.orderSequenceNumber + " is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
    				}else if(order.activityIndicator=='CNCL'){
    					$rootScope.messageString = "CANCEL request for order No. " + order.orderSequenceNumber +  " is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
    				}
    				if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
        				$.growl.error({ title: "Order Rejection", message: $rootScope.messageString });
    				}
    				var RejectionString = "Order Rejection~~" + $rootScope.messageString;
    				insertPopupNotifications(RejectionString, order.notificationId);
    			}else{
    				if(order.exceptionNumber==0){
        				if(order.activityIndicator=='PLAC'){
        					if(order.eventTypeId==2 || order.eventTypeId==3){ //if event type is rollover or early delivery
								$rootScope.messageString = "NEW order request submitted for execution with order No. " + order.orderSequenceNumber +"; " + $rootScope.initialString;
							}else{
								$rootScope.messageString = "NEW order request is risk validated " + isBankBreachedMsg + "; " + $rootScope.initialString;
							}
        				}else if(order.activityIndicator=='MDFY'){
        					$rootScope.messageString = "MODIFICATION request for order No. " + order.orderSequenceNumber + " is risk validated " + isBankBreachedMsg + "; " + $rootScope.initialString;
        				}else if(order.activityIndicator=='CNCL'){
        					$rootScope.messageString = "CANCELLATION request for order No. " + order.orderSequenceNumber + " is risk validated; " + $rootScope.initialString;
        				}
        				if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
            				$.growl.notice({ title: "Order Acceptance", message: $rootScope.messageString });
        				}
        				var AcceptanceString = "Order Acceptance~~" + $rootScope.messageString;
        				insertPopupNotifications(AcceptanceString, order.notificationId);
        			}
    				else if(order.exceptionNumber!=11){
        				if(order.activityIndicator=='PLAC'){
        					$rootScope.messageString = "NEW order request is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
        				}else if(order.activityIndicator=='MDFY'){
        					$rootScope.messageString = "MODIFY request for order No. " + order.orderSequenceNumber + " is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
        				}else if(order.activityIndicator=='CNCL'){
        					$rootScope.messageString = "CANCEL request for order No. " + order.orderSequenceNumber +  " is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
        				}
        				if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
            				$.growl.error({ title: "Order Rejection", message: $rootScope.messageString });
        				}
        				var RejectionString = "Order Rejection~~" + $rootScope.messageString;
        				insertPopupNotifications(RejectionString, order.notificationId);
        			}
    			}
    			
    		
        }
        
      //notification string if message number is 8021
        
        function buildStringForMsg8021(order){
        	
        		//check exception numbers which are treated as successful response
        		if(order.exceptionNumber!=0){
        			if($rootScope.negligibleExceptionNumberList!=undefined){
            			if($rootScope.negligibleExceptionNumberList.indexOf(order.exceptionNumber.toString())!==-1){
            				order.exceptionNumber=0;
            			}
        			}
        		}
    			if(order.exceptionNumber==0){
    				//$rootScope.acceptanceMessage = $rootScope.initialString + " accepted by Host. Order Sequence Number - " + order.orderSequenceNumber;
    				if(order.activityIndicator=='PLAC'){
    					$rootScope.messageString = "NEW order request submitted for execution with order No. " + order.orderSequenceNumber +"; " + $rootScope.initialString;
    				}else if(order.activityIndicator=='MDFY'){
    					$rootScope.messageString = "MODIFY request with order No. " + order.orderSequenceNumber +" is submitted for execution; " + $rootScope.initialString;
    				}else if(order.activityIndicator=='CNCL'){
    					$rootScope.messageString =  "CANCEL request for order No. " + order.orderSequenceNumber +" is submitted for execution; " + $rootScope.initialString;
    				}
    				if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
        				$.growl.notice({ title: "Order Confirmation", message: $rootScope.messageString });
    				}
    				var ConfirmationString = "Order Confirmation~~" + $rootScope.messageString;
    				insertPopupNotifications(ConfirmationString, order.notificationId);
    			}
    			else{
    				//$rootScope.rejectionString = $rootScope.initialString + " rejected due to " + "&quot;" + $rootScope.rejectionReason + "&quot;";
    				if(order.exceptionNumber==1801){
    					order.errorMessage = $rootScope.rejectionReason;
    				}
    				if(order.activityIndicatorRequested=='PLAC'){
    					$rootScope.messageString = "NEW order request is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
    				}else if(order.activityIndicatorRequested=='MDFY'){
    					$rootScope.messageString = "MODIFY request for order No. " + order.orderSequenceNumber + " is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
    				}else if(order.activityIndicatorRequested=='CNCL'){
    					$rootScope.messageString = "CANCEL request for order No. " + order.orderSequenceNumber +  " is rejected with error: "  + order.errorMessage  + "; " + $rootScope.initialString;
    				}
    				
    				if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
        				$.growl.error({ title: "Order Rejection", message: $rootScope.messageString });
    				}
    				var RejectionString = "Order Rejection~~" + $rootScope.messageString;
    				insertPopupNotifications(RejectionString, order.notificationId);
    				
    			}
    			
    			
        }
        
        function buildStringForMsg8026(order){
        	
        	if(order.typeOfOrder=='MRKT'){
            		$rootScope.message = order.buySellIndicator + " " + order.instrumentCode + " USD " + $filter('number')(order.orderAmount, 2) + " @ Market " +
            		"for customer " + order.customerCode;
            	}else{
            		$rootScope.message = order.buySellIndicator + " " + order.instrumentCode + " USD " + $filter('number')(order.orderAmount, 2) + " @ " + $filter('number')(order.limitExchangeRate, 4) +
            		" for customer " + order.customerCode;
            	}
        	$rootScope.messageString = "Order No. " + order.orderSequenceNumber + " is cancelled by Admin/Session close; " + $rootScope.message;
        	if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
            	$.growl.notice({ title: "Order Cancellation-by Admin/Session close", message: $rootScope.messageString });
        	}
			var ConfirmationString = "Order Cancellation-by Admin/Session close~~" + $rootScope.messageString;
			insertPopupNotifications(ConfirmationString, order.notificationId);
        }
        
        $rootScope.initialTradeMessage="";
        
        
        //prepare trade notification
        function frameTradeNotification(trade){
        	var tradeMessage = angular.copy(trade);
        	if(Number(trade.notificationId.substring(1)) > $rootScope.orderServerNotificationLastId){
	        	if(tradeMessage!=null && tradeMessage!=undefined){
	        		
	        		if(tradeMessage.buySellIndicator.toUpperCase()=='BUYY'){
	        			tradeMessage.buySellIndicator='BUY';
	        		}
	        		
	        	/*	$rootScope.initialTradeMessage = trade.buySellIndicator + " trade " + trade.tradeSequenceNumber + " for " + trade.customerCode + ", " + trade.instrumentCode + 
	        		  " for " + trade.tradedAmount + " at " + trade.tradedExchangeRate + " for order number " + trade.orderSequenceNumber;*/
	        		  
	        		  
	        	$rootScope.initialTradeMessage = "TRADE No. " + tradeMessage.tradeSequenceNumber + " confirmed for order No. " + tradeMessage.orderSequenceNumber + "; "
	        		 + tradeMessage.buySellIndicator + " " + tradeMessage.instrumentCode + " USD " + $filter('number')(tradeMessage.tradedAmount, 2) + " traded @ " + $filter('number')(tradeMessage.tradedExchangeRate, 4) + " for customer "
	        		 	+ tradeMessage.customerCode;
		        	if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
		        		$.growl.warning({ title: "Trade Confirmation", message: $rootScope.initialTradeMessage });
		        	}
	        	}
	        	var tradeConfirmation = "Trade Confirmation~~" + $rootScope.initialTradeMessage;
				insertPopupNotifications(tradeConfirmation, trade.notificationId);
        	}
        }
        
        
        function insertPopupNotifications(msg, notificationId){
        	//update last notification id
        	if(notificationId.includes("O")){
        		$rootScope.orderServerNotificationLastId = angular.copy(Number(notificationId.substring(1)));
        	}else if(notificationId.includes("P")){
        		$rootScope.pushNotificationLastId = angular.copy(Number(notificationId.substring(1)));
        	}
        	else if(notificationId.includes("Q")){
        		$rootScope.queryServerNotificationLastId =  angular.copy(Number(notificationId.substring(1)));
        	}
        	
        	var notificaiton = preparePopupNotificationObj(msg, notificationId);
        	//Notifications.insertNotifications({message :msg},onInsertNotificationSuccess,onInsertNotificationFailure);
        	onInsertNotificationSuccess(notificaiton);
        }
        
        function preparePopupNotificationObj(msg, notificationId){
        	var notification = {};
        	notification.entity = $rootScope.dashboardEntity;
        	notification.message = msg;
        	notification.status = 'PENDING';
        	notification.notificationId = notificationId;
        	notification.createdDateTime = new Date();
        	return notification;
        }
        
        function onInsertNotificationSuccess(result){
        	 //$rootScope.$emit("getNotifications", {});
        	$rootScope.notificationsDTO.popupNotifications.splice(0, 0, result);
        	$rootScope.notificationsDTO.popupNotificationsCount+=1;
        	console.log($rootScope.notificationsDTO);
        	//getNotifications();
        }
        
       
        
        function onInsertNotificationFailure(){
        	
        }
        
        function processFeed(object){
        	if( object.object != undefined && object.object !=null && object.object.operation =='SOD'){
				
				//updateProcessList(object.object, $rootScope.sodProcessList);
        		console.log("socket " + object.object.processID);
        		$rootScope.$emit('orderServiceSODProcessUpdate',object.object);
        	}else if(object.object != undefined && object.object !=null && object.object.operation =='EOD'){
        		$rootScope.$emit('orderServiceEODProcessUpdate',object.object);
        		//updateProcessList(object.object, $rootScope.eodProcessList);
        	}else if(object.object != undefined && object.object !=null && object.object.operationName=='HEStatusChange'){
        		$rootScope.$emit('heStatusUpdate',object.object);
        		frameHeNotification(object.object);
        		
        	}else if(object.object != undefined && object.object !=null &&object.object.operationName=='OrderServerManualLogin'){
        		$rootScope.$emit('orderServerManualLogin',object.object);
        		frameOrderServerMAnualLoginNotification(object.object);
        		
        	}
        }
      
        function frameMemberBreachedNotification(breachedMemberDetails, notificationId){
        	//var breachedMemberDetailsCopy = angular.copy(breachedMemberDetails);
        	
        	if(breachedMemberDetails!=null && breachedMemberDetails!=undefined && breachedMemberDetails.id!=null && breachedMemberDetails.id!=undefined && breachedMemberDetails.id !=''
        		&& Number(notificationId.substring(1)) > $rootScope.orderServerNotificationLastId){       		        		
        	    //console.log("breachedMemberDetails " + breachedMemberDetails.id)    	
        		
        		var messageTobeDisplayed ='';
        		var popupMessage ='';
        		if(breachedMemberDetails.marginFlag == 'Y' ||breachedMemberDetails.exposureLimitBuyFlag == 'Y' || breachedMemberDetails.exposureLimitSellFlag =='Y'){
        			 messageTobeDisplayed = "Restricted matching is <span style='color:#f04a4a;'>enabled</span> for " + breachedMemberDetails.bankName ;
        			 popupMessage = "Breached Member Details~~" + messageTobeDisplayed;
        		}else{
        			 messageTobeDisplayed =   "Restricted matching is <span style='color:#00ff00'>disabled</span> for " + breachedMemberDetails.bankName ;
        			 popupMessage = "Breached Member Details~~" + messageTobeDisplayed;
        		}
        		if(globalValues.getEntityOwnerType().shortName == "Clearcorp"){
        			
        			showBreachedMemberNotification(messageTobeDisplayed ,popupMessage, notificationId );
        		} else if(globalValues.getEntityOwnerType().shortName == "Bank" || globalValues.getEntityOwnerType().shortName == "Branch"){
        			
        			if( globalValues.getBank()!=null && globalValues.getBank()!=undefined && globalValues.getBank()!='' && globalValues.getBank().id == breachedMemberDetails.id ){
        				//console.log("breachedMemberDetails bank id:" + globalValues.getBank().id);
        				showBreachedMemberNotification(messageTobeDisplayed ,popupMessage, notificationId );
        			}
        		}else if(globalValues.getEntityOwnerType().shortName == "SingleUserCustomer"  || globalValues.getEntityOwnerType().shortName == "MultiUserCustomer"){
        			
        			angular.forEach($rootScope.tradingRelationsForCustomer, function(item){
        				//console.log("breachedMemberDetails customer bank id:" + item.id);
        				if(item.bank.id == breachedMemberDetails.id){
        					showBreachedMemberNotification(messageTobeDisplayed ,popupMessage, notificationId );
        				}
        			});
						
        				
        		}
    			
        	}
        	
        	
        }
        
        function frameBroadcastMessage(object){
        	if(object!=null && object!=undefined && Number(object.notificationId.substring(1)) > $rootScope.orderServerNotificationLastId){
        		if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
            		$.growl.alert({ title: "Clearcorp Broadcast", message: object.object });
        		}
            	var msgString = "Clearcorp Broadcast~~" + object.object;
            	insertPopupNotifications(msgString, object.notificationId);
        	}
        }
        
        function framePreCashSessionCloseIntimationNotification(object, notificationId){
        	if(Number(notificationId.substring(1)) > $rootScope.pushNotificationLastId){
            	var sessioncloseIntimation = "CASH Session is about to close in another " + object + " minutes...!";
            	if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
                	$.growl.alert({ title: "CASH Session Close Intimation", message: sessioncloseIntimation });
            	}
            	var intimationMsg = "CASH Session Close Intimation~~" + sessioncloseIntimation;
            	insertPopupNotifications(intimationMsg, notificationId);
        	}
        }
        
        function serverComponentStatusChange(object){
        	if(object.operationName=='BroadcastRecoveryStart'){
        		$rootScope.broadcastServerDown = true;
        	}else if(object.operationName=='BroadcastRecoveryCompleted'){
        		$rootScope.broadcastServerDown = false;
        	}
        }
        
        function updateConnectionStatus(object){
    		if(object.participant=='OPS'  ){
    			
    			if(object.isConnected==true){
					$rootScope.orderServerDown = false;
				}else{
					$rootScope.orderServerDown = true;
				}
    			$rootScope.$digest();
			}
    		else if(object.participant=='PNS'  ){
    			
    			if(object.isConnected==true){
					$rootScope.broadcastServerDown = false;
				}else{
					$rootScope.broadcastServerDown = true;
				}
    			$rootScope.$digest();
			}
    		
    	}        
        function showBreachedMemberNotification(messageTobeDisplayed , popupMessage, notificationId ){
        	if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
            	$.growl.alert({ title: "Breached Member Details", message: messageTobeDisplayed });
        	}
			insertPopupNotifications(popupMessage, notificationId);
        }
        function frameHeNotification(data){
        	var messageTobeDisplayed="";
        	var title = "";
        	var isError = false;
        	if(data.message=="success"){
        		messageTobeDisplayed = "HE status update completed successfully" ;
        		isError = false;
        		title = "HE Status Update Completed";
        	}else{
        		messageTobeDisplayed = data.message ;
        		isError = true;
        		title = "HE Status Update Failed";
        	}
        	
    		var popupMessage = title+"~~" + messageTobeDisplayed;
    		showStatusNotification(messageTobeDisplayed , popupMessage ,title , isError);
        }
        function frameOrderServerMAnualLoginNotification(data){
        	
        	var messageTobeDisplayed="";
        	var title = "Order Server Manual Login Intimation";
        	var isError = false;
        	var title ="";
        	if(data.message=="success"){
        		messageTobeDisplayed = "Order Server manual login completed succesfully" ;
        		isError = false;
        		title = "Order Server Manual Login Completed";
        	}else{
        		messageTobeDisplayed = data.message ;
        		isError = true;
        		title = "Order Server Manual Login Failed";
        	}
        	var popupMessage = title+"~~" + messageTobeDisplayed;
        	showStatusNotification(messageTobeDisplayed , popupMessage,title , isError);
        	
        }
        function showStatusNotification(messageTobeDisplayed , popupMessage , title , isError){
        	if($rootScope.userPreference.showInteractiveMsgPopup == null || $rootScope.userPreference.showInteractiveMsgPopup.toLowerCase() == 'yes'){
            	if(isError){
            		$.growl.error({ title: title, message: messageTobeDisplayed });
            	}else{
            		$.growl.notice({ title: title, message: messageTobeDisplayed });
            	}
        	}
        	insertPopupNotifications(popupMessage);
        	
			
        }

        // get tick size
        function gettickSizeForGivenInstrument(instrumentCode){
            if(instrumentCode=='SPOT'){
                return $rootScope.tickSize.spot;
            }else if(instrumentCode=='CASH'){
                return $rootScope.tickSize.cash;
            }else if(instrumentCode=='TOM'){
                return $rootScope.tickSize.tom;
            }else if(instrumentCode=='Forward'){
                return $rootScope.tickSize.forward;
            }
        }

        //get session status for instrument code
        function getSessionStatusForInstrument(instrumentCode){
            var status = 'CLOSED';
            if(instrumentCode == 'SPOT'){
                status = $rootScope.retailSpotMarketSession.sessionTimingsDTO.status;
            }else if(instrumentCode == 'CASH'){
                status = $rootScope.cashMarketSession.sessionTimingsDTO.status;
            }else if(instrumentCode == 'TOM'){
                status = $rootScope.tomMarketSession.sessionTimingsDTO.status;
            }else if(instrumentCode == 'Forward'){
                status = $rootScope.forwardMarketSession.sessionTimingsDTO.status;
            }
            return status;
        }

        //prepare user market watch online
        function prepareUserMarketWatchOnline(){
            angular.forEach($rootScope.userMarketWatchList, function(userMarketWatch){
                var tickSize = gettickSizeForGivenInstrument(userMarketWatch.instrument);
                userMarketWatch.sessionStatus = getSessionStatusForInstrument(userMarketWatch.instrument);
                userMarketWatch = MarketWatch.fillBidOfferSpreadInUserMarketWatch(userMarketWatch, $rootScope.bankComparativeDTO, $rootScope.defaultBankSpread, $rootScope.retailSpotMarketSession.settlementDate, tickSize);
                userMarketWatch = MarketWatch.fillBidOfferPriceInUserMarketWatch(userMarketWatch, $rootScope.retailSpotMarketWatch);
            })
        }

        function prepareOutrightComparision(settlementDate, instrument){
            $rootScope.outrightRatesList = [];
            $rootScope.outrightRateSettlementDate = settlementDate;
            $rootScope.outrightRateInstrument = instrument;
            angular.forEach($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                var outrightRates = {};
                outrightRates.bank = swapQuotesTenorDateDTO.bank;
                outrightRates.instrument = instrument;
                outrightRates.settlementDate = settlementDate;
                var tickSize = gettickSizeForGivenInstrument(outrightRates.instrument);
                outrightRates.sessionStatus = getSessionStatusForInstrument(outrightRates.instrument);
                outrightRates = MarketWatch.fillBidOfferSpreadInUserMarketWatch(outrightRates, $rootScope.bankComparativeDTO, swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, $rootScope.retailSpotMarketSession.settlementDate, tickSize);
                outrightRates = MarketWatch.fillBidOfferPriceInUserMarketWatch(outrightRates, $rootScope.retailSpotMarketWatch);
                $rootScope.outrightRatesList.push(outrightRates);
            });
            console.log('outright');
            console.log($rootScope.outrightRatesList);
            $rootScope.outrightRatesList = MarketWatch.sortOutrightRatesList($rootScope.outrightRatesList);
        }
    }
})();
