(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('AlertConfiguration', AlertConfiguration);

    AlertConfiguration.$inject = ['$resource'];

    function AlertConfiguration ($resource) {
        var resourceUrl =  'api/alertConfiguration/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                params: {orgId:'@orgId'},
                url: 'api/alertConfiguration/:orgId',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
            
            'getEventTypes': {
          	  method: 'GET',
          	  isArray: true,
          	  url: 'api/event-type'
             },

            'getUsersForOrganisation': {
             	  method: 'GET',
             	  isArray: true,
             	  url: 'api/users-for-organisation'
             },

             'getUserBranchClearances': {
            	  method: 'GET',
            	  isArray: true,
            	  params: {branchId:'@branchId'},
            	  url: 'api/branchId/:branchId/user-branch-clearances'
             },
             
            'getRolesForOrganisation': {
            	  method: 'GET',
            	  isArray: true,
            	  params: {orgId:'@orgId'},
            	  url: 'api/orgId/:orgId/rolesAllForOrganisation'
            },
             

            'getReminderTypes': {
             	  method: 'GET',
             	  isArray: true,
             	  url: 'api/reminder-type'
                },

            'getNotifyByTypes': {
               	  method: 'GET',
               	  isArray: true,
               	  url: 'api/notify-by-type'
              },
                

             'getNotificationTypes': {
            	  method: 'GET',
               	  isArray: true,
               	  url: 'api/notification-type'
              },
                  
              'getNatureReminderMapping': {
            	  method: 'GET',
               	  isArray: true,
               	  url: 'api/nature-reminder-mapping'
              },
              
           	'getAlertConfiguration':{
          	  method: 'GET',
        	  url: 'api/alertConfigurations',
          	  isArray:true,
              transformResponse: function (data) {
               if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
           	},
              
           	'getAlertConfigurationsByBranch':{
            	  method: 'GET',
            	  params: {branchId:'@branchId', orgId:'@orgId'},
            	  url: 'api/alert-configurations/branch/:branchId/org/:orgId',
            	  isArray:true,
            	  transformResponse: function (data) {
                 if (data) {
                          data = angular.fromJson(data);
                      }
                      return data;
                  }
             	},
                
            'update': {
            	method:'PUT',
            	params: {orgId:'@orgId'},
                url: 'api/alert-configuration-edit/:orgId',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
            'delete': {
                method: 'PUT',
                params: {orgId:'@orgId'},
                url: 'api/alert-configuration-delete/:orgId',              
                    transformRequest: function (data) {
                       	var copy = angular.copy(data);
                       	
                           return angular.toJson(copy);
                       }
            },
            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
             'resubmit': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmit/alert-configuration',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             }
             
       });
    }
})();
