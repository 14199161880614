(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserProfilePanImageController',UserProfilePanImageController);

    UserProfilePanImageController.$inject = ['$scope','$uibModalInstance','$stateParams', 'GlobalValues'];

    function UserProfilePanImageController($scope,$uibModalInstance,$stateParams, GlobalValues) {
    	var vm = this;
        vm.clear = clear;
        vm.image = $stateParams.image;
       
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

     
        
    }
})();
