(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('WorkflowRejectionReasonController', WorkflowRejectionReasonController);

    WorkflowRejectionReasonController.$inject = ['$scope','$rootScope', '$state', '$window', '$uibModalInstance', 'Tasks', '$uibModalStack'];

    function WorkflowRejectionReasonController ($scope,$rootScope, $state, $window, $uibModalInstance, Tasks, $uibModalStack) {
        var vm = this;
        
        vm.taskId = $rootScope.taskIdForRejection;
        vm.clear=clear;
        vm.confirm=confirm;
        //vm.reasonValidation=reasonValidation;
        
        function clear(){
        	$uibModalInstance.dismiss('cancel');
        }
        
        function confirm(){
        	 Tasks.reject({"taskId": vm.taskId}, vm.reason, onSuccess, onError);
        	 $uibModalInstance.close('');
        	 $uibModalStack.dismissAll();
        }
        
        function onSuccess(){
        	$rootScope.removeRow(vm.taskId);
        }
        
        function onError(){
        	
        }
       // this validation comen for all input validation 
        $scope.$watch('vm.reason', function(value){
            if (value != null && value != undefined && value !='') {
             value = value.toString().replace(/^[^a-zA-Z0-9]|[{}<>]/g,'');
                value = value.toString().replace(/^[^a-zA-Z0-9]+|[{}<>]/g, '');
                vm.reason= value;
        }
        });
        
    }
})();
