(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'searchfaq'
        })
        
        .controller('SearchFaqController', SearchFaqController);

    SearchFaqController.$inject = ['$rootScope', '$scope', '$state', '$filter', 'workflowSharedService', 'FAQService', 'GlobalValues', 'EntityPermissions', '$sce'];

    function SearchFaqController($rootScope, $scope, $state, $filter, workflowSharedService, FAQService, GlobalValues, EntityPermissions, $sce) {
        var vm = this;
        workflowSharedService.viewFrom = "searchfaq";
       
		vm.permitSearchFaqViewEntityAction = EntityPermissions.hasPermission('SearchFAQ.VIEW')

		vm.loadincomplete = true;
       
        vm.Myshow=false;
        
        vm.faqdetailsfiltered=[];
        
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        
        loadAll();

		function loadAll() {

			//load faq category list from json file
			loadfaqcategory();

			//load faq details from json
			loadfaqdetails();
			
		}
        
		function loadfaqdetails(){
			FAQService.downloadJSONFile({
				"type" : "faq",
				"fileName" : "faqdetailsjson.txt",
				"fileType" : "txt"
			}, function(result) {
				vm.faqdetailsfromJSONFile = result;
				vm.loadincomplete = false;
				vm.filterfaqcategory('Approval');
			});
		}
		
		function loadfaqcategory(){
			FAQService.downloadJSONFile({
				"type" : "faq",
				"fileName" : "faqcategorylist.txt",
				"fileType" : "txt"
			}, function(result) {
				vm.faqcategorylistfromJSONFile = result;
				vm.loadincomplete = false;
			});
		}
		
		  vm.downloadTemplate = function downloadTemplate(templatefilename){
	        	 //alert("downloadTemplate");
	        	 //alert(templatefilename);
	        	 
	             var downloadLink = document.createElement("a");
	             downloadLink.href = "api/file/faq/templates/downloadtemplate/" + templatefilename + "/xlsx";            
	             downloadLink.download = templatefilename;
	             //alert("downloadTemplate1");

	             document.body.appendChild(downloadLink);
	             downloadLink.click();
	             document.body.removeChild(downloadLink);
	               //FAQService.downloadTemplate({fileName:'Template',fileType:'xls'}, onSuccess, onFailure);
	         }
		vm.searchFaqByEnter = function($event) {
			if ($event.keyCode === 13){
				vm.searchFaq(vm.questionHint);
			}
		}
		
        vm.filterfaqcategory = filterfaqcategory; 
        	function filterfaqcategory(category){
        		vm.faqdetailsfiltered = null;
        		vm.faqcategory = ''
    			var as = $(vm.faqdetailsfromJSONFile)
    			        .filter(function (i,n){
    			            return (n.categorylist.indexOf(category) != -1);
    			        }).css( "background-color", "red" );
//    			   alert(as);
//    			   alert(Object.keys(as).length);
                   if (Object.keys(as).length > 3){
                  	vm.faqdetailsfiltered = as;
                  	vm.faqcategory = category;
                   }
                   else{
                     vm.faqcategory = category + ' - No FAQ found'
                   }
    			   
        }
        
            
         vm.searchFaq = function searchFaq(faqStr){

        	 if (faqStr != null && faqStr != ""){

        		 vm.faqdetailsfiltered = null;
            	
        		 faqStr = faqStr.toLowerCase();
        		 
        		 var as = $(vm.faqdetailsfromJSONFile)
        		 .filter(function (i,n){
        			 return ((n.question.toLowerCase().indexOf(faqStr) != -1) || (n.answer.toLowerCase().indexOf(faqStr) != -1));
        		 });
		   
//        		 alert(as);
//        		 alert(Object.keys(as).length);
        		 vm.faqdetailsfiltered = as;

                if (Object.keys(as).length > 3){
                	 vm.faqdetailsfiltered = as;
                     vm.faqcategory = 'Related questions'
                 }
                 else{
                     vm.faqcategory = 'No such FAQ available'
                 }
        	 }
        }
         
        vm.highlight = function highlight(text, search) {
        	 //alert(text);
        	    if (!search) {
        	        return $sce.trustAsHtml(text);
        	    }
        	    return $sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<span class="highlighted">$&</span>'));
        }
        
        $scope.isExpanded = function(faqDTO){
            return (faqDTO.expanded == false);
        };
         
    }
})();
         