(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrderActivityLog', OrderActivityLog);

        OrderActivityLog.$inject = ['$resource'];

    function OrderActivityLog($resource) {
    	 var resourceUrl =  '';
         
         return $resource(resourceUrl, {}, {
           'exportOrderActivityLogCSV':{
				method: 'POST',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId',reportDate:'@reportDate'},
				url: 'api/export-order-activity-log-csv',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
				   return {
					   data: data,
					   headers: headers
				   };   
			   } 

			 },
			 'exportOrderActivityLogExcel':{
				method: 'POST',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId',reportDate:'@reportDate'},
				url: 'api/export-order-activity-log-excel',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
				   return {
					   data: data,
					   headers: headers
				   };   
			   } 

			 }
         });
        }
    })();