(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CustomerTradingBankDialogController', CustomerTradingBankDialogController);

    CustomerTradingBankDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance',
    'CustomerBankRelationshipReport','entity','Branch', 'TACViewForEntitiesService', 'TacViewForEntitiesUtility'];

     function CustomerTradingBankDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,
     	CustomerBankRelationshipReport,entity,Branch, TACViewForEntitiesService, TacViewForEntitiesUtility) {
        var vm = this;
        vm.tradingBank=entity; 
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        vm.globalValues = GlobalValues;
        vm.clearcorpOrgId = 1;
        vm.clearcorpForCustomerTACTypeId = 1;
        vm.bankForCustomerTACTypeId = 3;
        vm.previewClearcorpTAC = previewClearcorpTAC;
        vm.previewBankTAC = previewBankTAC;
        getClearcorpTACMaster();
        vm.tradingBank.assignTradingBranch = true;
        $scope.showButton = false;
       
        $scope.$watch('vm.tradingBank.homeBranch', function(value){
        	if(value!=null&&value!=undefined){
        		vm.tradingBranchAddress = value.address;
        	}
        });
                
        if(workflowSharedService.viewFrom == 'tradingBank'){
        	vm.tradingBank=entity;
       	 	$scope.showButton=false;
        }
        
		
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	vm.tradingBank.branchAddress = vm.tradingBranchAddress;

            //update clearcorp TAC and bank TAC in cust reg.request in order to update tacForCustomerRequested
            if(vm.clearcorpTAC!=null && vm.clearcorpTAC.id!=null){
                vm.tradingBank.clearcorpTAC = vm.clearcorpTAC;
            }
            if(vm.bankTAC!=null && vm.bankTAC.id!=null){
                vm.tradingBank.bankTAC = vm.bankTAC;
            }
        	
        	CustomerBankRelationshipReport.save({ "customerId": GlobalValues.getOrgId() },vm.tradingBank, onSaveSuccess, onSaveError);           
         	
         }
      
       
     
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	vm.clicked = false;
        }
        
        function getClearcorpTACMaster(){
            TACViewForEntitiesService.getActiveTACForOrganisationAndTacType({orgId: vm.clearcorpOrgId, tacTypeId: vm.clearcorpForCustomerTACTypeId}, function(result){
                vm.clearcorpTAC = result;
            })
        }

		$scope.$watch('vm.tradingBank.bank', function(value){
			if(value!=null && value!='' && value!=undefined){
				//var bankName = value.bankName;
				//bankName = bankName.substring(0, 5); // mantis issue fix: 20
				vm.filterText = value.ccpMembershipId;
			}
		})
		
		$scope.$watch('vm.tradingBank.assignTradingBranch', function(value){
	    	  if(value!=null && value!=undefined){
	    		  if(value){
	    			  if(vm.tradingBank.tradingBranch!=null){
	    				  vm.tradingBank.tradingBranch = null;
	    			  }
	    			  vm.disableTradingBranch = true;
	    			  //vm.tradingBranchRequired = false;
	    		  }else{
	    			  vm.disableTradingBranch = false;
	    			 // vm.tradingBranchRequired = true;
	    		  }
	    	  }
	    })
	      
	    $scope.$watch('vm.tradingBank.bank', function(value){
	    	if(value!=null && value!=undefined){
	    		// Branch.getValidBranchDetailsForBank({bankId: value.id}, function(result){
	    		// 	if(result!=null && (result.length>1 || result.length==0)){
	    		// 		vm.tradingBank.assignTradingBranch = true;
	    		// 	}
				// 	// else{
	    		// 	// 	//vm.tradingBank.assignTradingBranch = false;
	    		// 	// 	//$scope.tradingBankForm.$setValidity('txttradingBranchachrequired', true);
	    		// 	// }
	    		// })

				TACViewForEntitiesService.getActiveTACForOrganisationAndTacType({orgId: value.id, tacTypeId: vm.bankForCustomerTACTypeId}, function(result){
					if(result!=null && result.tacFiles!=null){
						vm.bankTAC = result;    
					}else{
						vm.bankTAC = null;
					}
				})
	    	}else{
	    		 // vm.tradingBank.assignTradingBranch = false;
	    		  vm.bankTAC = null;
	    	}
	    })

		function previewClearcorpTAC(){
			if(vm.clearcorpTAC!=null && vm.clearcorpTAC!=undefined){
			    TacViewForEntitiesUtility.previewTAC(vm.clearcorpTAC.tacFiles.fileContent, vm.clearcorpTAC.tacFiles.fileName);    
			}
		}

		function previewBankTAC(){
			if(vm.bankTAC!=null && vm.bankTAC!=undefined){
			    TacViewForEntitiesUtility.previewTAC(vm.bankTAC.tacFiles.fileContent, vm.bankTAC.tacFiles.fileName);    
			}
		}
	  
       
     }   
})();
