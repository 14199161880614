(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SMSSettingsEditDialogController', SMSSettingsEditDialogController);

    SMSSettingsEditDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','SMSSettings','entity'];

     function SMSSettingsEditDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,SMSSettings,entity) {
        var vm = this;
        vm.smsSettings=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        vm.resubmitSMSSettings =resubmitSMSSettings;
        vm.globalValues = GlobalValues;
        
        $scope.showButton = false;
       
                
        if(workflowSharedService.viewFrom == 'smssettings'){
        	vm.smsSettings=entity;
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'smssettingstodolist_resubmit') {
        	vm.smsSettings =  angular.fromJson($rootScope.payload);
        	
        	vm.disableForm = false;
        	$scope.showButton=true;
        }
        else if(workflowSharedService.viewFrom == 'smssettings_resubmit_delete') {
        	vm.smsSettings = angular.fromJson($rootScope.payload); 
        	vm.disableForm = true;
        	$scope.showButton=true;
        }
		
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
                 SMSSettings.update({ "orgId": vm.globalValues.getOrgId() },vm.smsSettings, onSaveSuccess, onSaveError);
               	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitSMSSettings() {
        	
        		 SMSSettings.resubmit({"taskId" : $rootScope.taskID}, vm.smsSettings, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked=false;
        }

       
     }   
})();
