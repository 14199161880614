(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TACAgreeConfirmationController',TACAgreeConfirmationController);

    TACAgreeConfirmationController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues','ScreenLog','$filter', '$rootScope', '$scope','TACViewForEntitiesService','workflowSharedService' ,'entityConstants','TacViewForEntitiesUtility'];

    function TACAgreeConfirmationController($uibModalInstance,$stateParams,GlobalValues, ScreenLog, $filter, $rootScope, $scope,TACViewForEntitiesService,workflowSharedService, entityConstants,TacViewForEntitiesUtility) {
    	
    	var vm = this;
    	vm.screenLog = ScreenLog;
    	vm.entityConstants = entityConstants;
    	vm.screenName = entityConstants.ENTITY_SHORT_NAME.TERMS_AND_CONDITIONS_VIEW ;
        vm.clear = clear;
        vm.approve = approve;
        vm.tacTypeId =  $stateParams.tacTypeId;
        $scope.clicked = false;
    	vm.isTacDeclared = false;
    	vm.agreeTAC = agreeTAC;
    	 vm.loggedinEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
         vm.organisation = GlobalValues.getOrganisation();
         
    	checkForTacType();
        if( workflowSharedService.viewFrom == 'tacViewForEntities') {
        	vm.isViewFromToDo = false;
        	
        }else{
        	vm.isViewFromToDo = true;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function approve(){
        	$scope.rowID = $rootScope.taskID;
        	TACViewForEntitiesService.approve({ "taskId":  $rootScope.taskID }, onApproveSuccess, onApproveError);
            
        }
        function agreeTAC(){
        	vm.screenLog.screenLog(vm.screenName,$rootScope.selectedTacAgreedEntityDTO.tacAgreedEntities.tacMaster.id,vm.entityConstants.ENTITY_ACTION_TYPE.AGREE);
        	console.log(angular.toJson($rootScope.selectedTacAgreedEntityDTO));
        	TACViewForEntitiesService.agreeTAC($rootScope.selectedTacAgreedEntityDTO,onAgreeSuccess, onAgreeFailure );
        }
        function onApproveSuccess(result) {
           
            $rootScope.removeRow( $rootScope.taskID );
            $uibModalInstance.dismiss('cancel');
        }

        function onApproveError(error) {
        	//$scope.clicked=false;
        	 $uibModalInstance.dismiss('cancel');        	
        }

        function onAgreeSuccess(result) {
        	
            $uibModalInstance.dismiss('cancel');
            TacViewForEntitiesUtility.loadAllTacAgreedDetails(vm.loggedinEntityOwnerType, vm.organisation.id);
        }

        function onAgreeFailure(error) {
        	//$scope.clicked=false;
        	 $uibModalInstance.dismiss('cancel');
        }
        function checkForTacType(){
        	
        	if( vm.tacTypeId  == 1  ){        
        		vm.isTACForCCIL = true;
        		vm.showClearcorpTACHelpText = true;
        	}else if( vm.tacTypeId == 2){
        		vm.isTACForCCIL = true;
        		vm.showClearcorpTACHelpText = false;
        	}else if(vm.tacType == 3){
        		vm.isTACForCCIL = false;
        		vm.showClearcorpTACHelpText = false;
        	}	
        }
        
        
    }
})();
