(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'swap-quotes'
	})

	.controller('StandardTenorViewController', StandardTenorViewController);

	StandardTenorViewController.$inject = ['$scope', '$rootScope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
		'DateUtils',  'StandardTenorView','IntradayMarket','systemSettings'];

	function StandardTenorViewController($scope, $rootScope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
			DateUtils,  StandardTenorView,IntradayMarket,systemSettings) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.STANDARD_TENOR_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.loadAll = loadAll; 
		vm.exportToFile = exportToFile;
		//loadCurrentBusinessDate();
		if(systemSettings != null && systemSettings != undefined){
			vm.businessDate  = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
		}
		
		vm.ExpandOrCollapseStandardTenorView = true;
		vm.exportDataToExcelFile = exportDataToExcelFile;
		vm.exportDataToCsvFile = exportDataToCsvFile;
		vm.filterData = {};
		vm.fileName = "Standard_Tenor_View_";
		vm.extension = "";
		vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm_ss';
		vm.valid=false;
		logScreenAccess();
		loadAll();
		
		
		function logScreenAccess(){
			ScreenLog.screenLog(vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		function loadAll(){
			vm.standardTenorList = []; //to re-initialize the array
			StandardTenorView.get({businessDate:  DateUtils.convertLocalDateToServer(vm.businessDate)},onGetStandardTenorSuccess,onGetStandardTenorFailure)
		}

		function onGetStandardTenorSuccess(result){
			vm.standardTenorList = result;
		}

		function onGetStandardTenorFailure(){

		}


		function exportToFile(fileType){
			if(fileType=='csv'){
				exportDataToCsvFile();
			}else if(fileType=='excel'){
				exportDataToExcelFile();
			}
			$rootScope.fileType = fileType;
		}

		function exportDataToExcelFile(){
			if(vm.standardTenorList !=null &&  vm.standardTenorList!=undefined && vm.standardTenorList.length>0){
				vm.mimeType = 'application/vnd.ms-excel';
				vm.extension = ".xls";  
				StandardTenorView.exportData({businessDate:  DateUtils.convertLocalDateToServer(vm.businessDate)}, onExportSuccess);
			}
		}

		function exportDataToCsvFile(){
			if(vm.standardTenorList !=null &&  vm.standardTenorList!=undefined && vm.standardTenorList.length>0){
				vm.mimeType = 'text/csv';
				vm.extension = ".csv";  
				StandardTenorView.exportDataCSV({businessDate:  DateUtils.convertLocalDateToServer(vm.businessDate)}, onExportSuccess);
			}
		}


		function onExportSuccess(result){        	  
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			vm.baseString = vm.fileName + moment().format(vm.reportFileTimestampFormat) +  vm.extension; 

			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob){
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString);
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
})();