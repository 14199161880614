(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('csplDocumentUpload', ['StringUtils', 'ArrayUtils', 'CollectionUtils',
            'FileUtil', 'GlobalValues', 'OperationEnum', 'FloatUtils' , '$timeout', 
            function(StringUtils, ArrayUtils, CollectionUtils,
                FileUtil, GlobalValues, OperationEnum, FloatUtils, $timeout) {

                function linkFunction(scope, iElement, iAttrs, controllers) {

                    scope.formController = controllers[0];
                    scope.disableControl=false;
                    scope.invalidPan=false;
                    scope.invalidPanText='';
                    scope.file=null;
                    // set the maximim allowed file size 
                    scope.showRemove=false;
                    

                    document.getElementById("uploadPath").addEventListener("change", uploadPathChangeEventListener);
                 

                    scope.viewFile = {};

                    if(scope.file!=null && scope.file!=undefined){
                        // var uploadFile ={};
                        // uploadFile.fileName = scope.viewFile.name;
                        // uploadFile.file = scope.viewFile;
                        scope.viewFile=scope.file;
                      // scope.showRemove=true;
                            
                       
                    }
                    
                    function uploadPathChangeEventListener(event) {

                        try{
                                if(event.target.files.length>0){
                                    uploadInputFiles(event.target.files[0]);
                                    event.target.value = '';
                                }
                            
                        }
                        catch(error) {
                            scope.invalidPan=true;
                            scope.invalidPanText=error;
                           
                            //ModalService.openCsplAlertModal(error);
                        }

                        $timeout(function (){
                            setFormDirty();
                        },200);
                       
                    }
                  
                    function setFormDirty(){
                        if(_.get(scope, 'formController', false)){
                            scope.formController.$setDirty();
                        }
                    }

                    function uploadInputFiles(file){
                        scope.invalidPan=false;
                    
                        //Get all uploaded file
                        var uploadedFile = file;

                        //Validate all uploaded files
                        validateUploadedFiles(scope.viewFile, uploadedFile);

                        //Prepare File object for each uploaded file and add to list
                        
                            scope.file = file;
                            var uploadFile ={};
                            uploadFile.fileName = uploadedFile.name;
                            uploadFile.file = uploadedFile;
                            
                            scope.viewFile=uploadFile;
                            
                            //scope.multipartFileList=uploadedFile;

                        
                        scope.disableControl=true;
                        scope.showRemove=true;
                        scope.$digest();
                    }

                    /**
                     * Validate list of uploaded files
                     * Validate file duplication, invalid extension, invalid file size
                     */
                    function validateUploadedFiles(filesList, uploadedFile){

                        //Validate file duplication
                        //validateFileDuplication(filesList, uploadedFile);
                        
                        //Validate file extension
                        validateFileExtension(uploadedFile);

                        //Validate size of uploaded files
                        validateUploadedFilesSize(uploadedFile);

                    }

                    /**
                     * Validate if uploaded file is already present
                     */
                    function validateFileDuplication(filesList, uploadedFile){

                        var filesWithError = [];

                        angular.forEach(uploadedFile, function(file){
                            //Validate if file already exists
                            var filteredList = CollectionUtils.filter(filesList, 'fileName', file.name);

                            //Add all invalid files
                            if(!ArrayUtils.isEmpty(filteredList)){
                                filesWithError.push(file.name);
                            }
                        })

                        if(!ArrayUtils.isEmpty(filesWithError)){
                            throw "File(s) " + filesWithError.join(", ") + " already exists.";
                        }
                    }

                    /**
                     * Validate if uploaded files have valid allowed extensions
                     */
                    function validateFileExtension(uploadedFile){
                        
                        var filesWithError = [];

                       
                            
                            //Validate extension
                            var fileExtension = uploadedFile.name.substr(uploadedFile.name.lastIndexOf('.'));
                            if (!CollectionUtils.includes(getFileExtensions(), StringUtils.toLowerCase(fileExtension))){
                                filesWithError.push(uploadedFile.name);
                            }
                            

            

                        if(!ArrayUtils.isEmpty(filesWithError)){
                            throw "" + filesWithError.join(", ") + " are not allowed. The accepted file formats are pdf, jpg, png.";
                        }
                    }

                    /**
                     * Validate minimum and maximum size of uploaded files
                     */
                    function validateUploadedFilesSize(uploadedFile){

                        //Get min and max file size allowed from config settings
                        var maxSizeConfig = 200;
                        //GlobalValues.getConfigSettings().documentMaximumSize;

                        //Get default 100kb size
                        var maxSizeAllowed = FloatUtils.getFloat(200);
                        
                        //Get maximum value from config
                        if(!StringUtils.isBlank(maxSizeConfig)){
                            //Convert MB into bytes
                            maxSizeAllowed = FloatUtils.getFloat(maxSizeConfig);
                        }
                        // min size 1kb
                        var minSizeAllowed = 1;
                        

                        //Validate file size
                        validateFileSize(uploadedFile, minSizeAllowed, maxSizeAllowed);
                    }

                    /**
                     * Validate size of uploaded files
                     */
                    function validateFileSize(uploadedFile, minSizeAllowed, maxSizeAllowed){

                        var filesWithError = [];

                        

                            //Convert Kb into bytes
                            var minSizeAllowedBytes = FloatUtils.multiply(FloatUtils.getFloat(minSizeAllowed), 
                                FloatUtils.getFloat(1024));
                            var maxSizeAllowedBytes = FloatUtils.multiply(FloatUtils.getFloat(maxSizeAllowed), 
                                    FloatUtils.getFloat(1024));
  
                            //Validate size
                            //Validate minimum and maximum file size using config settings
                            if(!FileUtil.isFileSizeValid(uploadedFile.size ,minSizeAllowedBytes, maxSizeAllowedBytes  )){
                                filesWithError.push(uploadedFile.name);
                            }
                        

                        if(!ArrayUtils.isEmpty(filesWithError)){
                            throw "" + filesWithError.join(", ") + " is of invalid size. Minimum size  is "+minSizeAllowed +" Kb and Maximum  size is " + maxSizeAllowed + " kb.";
                        }

                    }
                    
                    //Get allowed file extensions
                    function getFileExtensions(){
                        return ['.jpg', '.png','.pdf'];
                    }

                    //Remove selected file
                    scope.removeFile = function(){

                        setFormDirty();  
                        scope.viewFile=null;
                        scope.file = null;
                        scope.disableControl=false;
                        scope.showRemove=false;
                        scope.invalidPan=true;
                        scope.invalidPanText="PAN image should be uploaded.";
                    }

                    //View selected file
                    scope.fileView = function(){

                        //If byte array is empty, file is just uploaded, so read from multipart file
                        if(StringUtils.isBlank(scope.viewFile.fileContentByte)){

                            if(scope.viewFile.file != undefined && scope.viewFile.file != null){
                                //open uploaded file
                                FileUtil.viewFile(scope.viewFile.file, scope.viewFile.name);
                            }
                        }else{
                            FileUtil.viewFileFromBase64(scope.viewFile.fileContentByte, scope.viewFile.name);
                        }
                    }

                    //Download selected file either from byte array or uploaded file content
                    scope.downloadFile = function(fileToDownload){

                        //If byte array is empty, file is just uploaded, so download from multipart file
                        if(StringUtils.isBlank(fileToDownload.fileContentByte)){

                            if(fileToDownload.file != undefined && fileToDownload.file != null){
                                //download uploaded file
                                FileUtil.downloadFile(fileToDownload.file);
                            }
                        }else{
                            //Download file from Base64 string
                            FileUtil.downloadFileFromBase64(fileToDownload.fileContentByte, fileToDownload.name);
                        }
                    }
                    scope.$watch('controlRequired', function(value) {
                        if (value != null && value != "" && (value == "true" || value == true)) {
                                if (scope.form != null) {
                                    scope.form.$setValidity(iAttrs.controlId + "fileRequired", false);
                                }
                            }
                            });
                    scope.$watch('file', function(value) {
                         if (value != null && value != "") {
                            scope.form.$setValidity(iAttrs.controlId + "fileRequired", true);
                                      
                            }else{
                                scope.form.$setValidity(iAttrs.controlId + "fileRequired", false);
                            
                            }
                     });
                    



                     angular.element(document).ready(function() {
                        var dropbox = document.getElementById("dropContainer");

                        if(dropbox != undefined && dropbox != null){
                            
                            dropbox.addEventListener("dragenter", dragenter, false);
                            dropbox.addEventListener("dragover", dragover, false);
                            dropbox.addEventListener("drop", drop, false);
                            dropbox.addEventListener("dragleave", dragleave, false);
                        }

                        
                    });
                    
                    
                    
                    function dragenter(evt) {
                        $('#dropContainer').addClass("active"); 
                        evt.stopPropagation();
                        evt.preventDefault();
                      };

                    function dragover(evt) {
                        $('#dropContainer').addClass("active"); 
                        evt.stopPropagation();
                        evt.preventDefault();
                      };

                    function dragleave(evt){
                        $('#dropContainer').removeClass("active"); 
                    }
                      
                    function drop(evt) {
                        $('#dropContainer').removeClass("active"); 
                        evt.stopPropagation();
                        evt.preventDefault();
                        try{
                            if(!scope.disableControl){
                                uploadInputFiles(evt.dataTransfer.files[0]);
                            }
                           
                           
                        }
                        catch(error) {
                            scope.invalidPan=true;
                            scope.invalidPanText=error;
                          
                          //  ModalService.openCsplAlertModal(error);
                        }
                        $timeout(function (){
                            setFormDirty();
                        },200);
                            
                      };
                }

                return {
                    templateUrl: 'app/components/cspl-document-upload/cspl-document-upload.html',
                    restrict: 'E',
                    scope: {
                        file : '=',
                        controlRequired: '=',
                        form:'=',
                        controlId: '='
                    },
                    require: ['^form'],
                    link: linkFunction
                };
            }]);
})();
