(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrganisationType', OrganisationType);

    OrganisationType.$inject = ['$resource'];

    function OrganisationType ($resource) {
        var resourceUrl =  'api/organisation-type/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'organisationTypesForGlobalApprovalPolicy':{
            	method:'GET',
            	isArray:true,
            	params: {orgId:'@orgId'},
            	url: 'api/get-orgType-for-global-approval-policy/:orgId'
            }
        });
    }
})();
