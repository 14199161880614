(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'alertConfiguration'
        })
        
        .controller('AlertConfigurationController', AlertConfigurationController);

    AlertConfigurationController.$inject = ['$scope', '$state', 'workflowSharedService', 'AlertConfiguration', 'GlobalValues', 'EntityPermissions', '$rootScope', 'ScreenLog'];

    function AlertConfigurationController($scope, $state, workflowSharedService, AlertConfiguration, GlobalValues, EntityPermissions, $rootScope, ScreenLog) {
        var vm = this;
        vm.screenName = 'AlertConfiguration';
        vm.screenLog = ScreenLog;
        //workflowSharedService.viewFrom = "alertConfiguration";
        vm.launch=launch;
        function launch() {
        	$state.go("alert-configuration.new");
        }

        //ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': 0, 'action': 'ACCESS'});

        vm.permitAlertConfigurationEditEntityAction = EntityPermissions.hasPermission('AlertConfiguraion.EDIT')
        vm.permitAlertConfigurationCreateEntityAction = EntityPermissions.hasPermission('AlertConfiguraion.CREATE')
		vm.permitAlertConfigurationViewEntityAction = EntityPermissions.hasPermission('AlertConfiguraion.VIEW')
		vm.permitAlertConfigurationDeleteEntityAction = EntityPermissions.hasPermission('AlertConfiguraion.DELETE')


        loadAll();
        
        function loadAll() {
        	/*AlertConfiguration.query(function(result) {
                vm.alertConfigurationHeader = result;
               console.log(vm.alertConfigurationHeader);
            }); */   
        	
            /*AlertConfiguration.getAlertConfiguration(function(result){
                vm.alertConfigurationHeader = result;
            });*/
       }
        
        vm.getAlertConfigurationsByBranch = function getAlertConfigurations(branch){
        	if (branch!=null){
        		$rootScope.branch = branch;
                AlertConfiguration.getAlertConfigurationsByBranch({'branchId': branch.id, 'orgId':GlobalValues.getUserOrganisation().id}, function(result){
                    vm.alertConfigurationHeader = result;
                });
        	}
        }
        
        vm.getNotifyByTypes = function(alertDetails){
        	var notifyByTypeNames = '';
        	var alertNotifyByTypeDetailsArr = alertDetails.alertConfigurationNotifyByTypeDetails;
        	angular.forEach(alertNotifyByTypeDetailsArr, function(value,key)
        			{
        			if (notifyByTypeNames==''){
        				notifyByTypeNames = value.notifyByType.longName;
        			}
        			else
        			{
        				notifyByTypeNames = notifyByTypeNames + ", " + value.notifyByType.longName;
        			}
        		})
        	return (notifyByTypeNames);
        }
        
        $rootScope.$on('alertRefresh', function(event, result) {
        	vm.branch = $rootScope.branch;
        	
        	if($rootScope.branch != null)
        		vm.getAlertConfigurationsByBranch(vm.branch); 
        });
        
        /*vm.screenLog = function screenLog(entityRecordId,action){
        	ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': entityRecordId, 'action': action});
        }*/
    }
})();
