(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('login', {
            url: '/login/{orgType}',
            data: {
                authorities: [],
                pageTitle: 'login.tab'
            },
            views: {
                'logincontent@': {
                    templateUrl: 'app/components/login/login.html',
                    controller: 'LoginController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	globalValue: ['StaticLookUp', function(StaticLookUp) {
                    return StaticLookUp.getConfigSettingsPreLogin().$promise;
                }],
            	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('login');
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
