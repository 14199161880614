(function() {
	'use strict';
	angular.module('gatewayApp').factory('ManageEntityRestrictions',
			ManageEntityRestrictions);

	ManageEntityRestrictions.$inject = [ '$resource' ];

	function ManageEntityRestrictions($resource) {
		var resourceUrl = 'api/';

		return $resource(
				resourceUrl,
				{},
				{
					'fetchStatusDetailsForBank' : {
						method : 'GET',
						params : {
							bankId : '@bankId'
						},
						url : 'api/fetch-entity-restriction-bank/:bankId',
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'fetchStatusDetailsForBranch' : {
						method : 'GET',
						params : {
							bankId : '@bankId',
							branchId : '@branchId'
						},
						url : 'api/fetch-entity-restriction-branch/:bankId/:branchId',
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'fetchDetailsForCustomer' : {
						method : 'GET',
						params : {
							customerId : '@customerId'
						},
						url : 'api/fetch-entity-restriction-customer/:customerId',
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'fetchDetailsForCustomerRelationship' : {
						method : 'GET',
						params : {
							bankId : '@bankId',
							branchId : '@branchId',
							customerId : '@customerId'
						},
						url : 'api/fetch-entity-restriction-cust-rel/:bankId/:branchId/:customerId',
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},

					'manageEntityRestrictionsForBank' : {
						method : 'POST',
						url : 'api/manage-entity-restriction-bank',
						/*params : {
							bankId : '@bankId',
							oldStatus : '@oldStatus',
							newStatus : '@newStatus'
						},*/
						transformRequest : function(data) {
							var copy = angular.copy(data);
							return angular.toJson(copy);
						}
					},

					'manageEntityRestrictionsForBranch' : {
						method : 'POST',
						url : 'api/manage-entity-restriction-branch',
						/*params : {
							bankId : '@bankId',
							branchId : '@branchId',
							oldStatus : '@oldStatus',
							newStatus : '@newStatus'
						},*/
						transformRequest : function(data) {
							var copy = angular.copy(data);
							return angular.toJson(copy);
						}
					},

					'manageEntityRestrictionsForCustomer' : {
						method : 'POST',
						url : 'api/manage-entity-restriction-customer',
						transformRequest : function(data) {
							var copy = angular.copy(data);
							return angular.toJson(copy);
						}
					},
					'approve' : {
						method : 'GET',
						params : {
							taskId : '@taskId'
						},
						url : 'api/tasks/:taskId/approve'
					},
					'reject' : {
						method : 'GET',
						params : {
							taskId : '@taskId'
						},
						url : 'api/tasks/:taskId/reject'
					},
					'discard' : {
						method : 'GET',
						params : {
							taskId : '@taskId'
						},
						url : 'api/tasks/:taskId/discard'
					},

					'ResubmitForEdit' : {
						method : 'PUT',
						params : {
							taskId : '@taskId'
						},
						url : 'api/tasks/:taskId/RestrictionResubmitForEdit',
						transformRequest : function(data) {
							var copy = angular.copy(data);
							return angular.toJson(copy);
						}
					},
					'manageEntityRestrictionsForCustomerRelationship' : {
						method : 'POST',
						url : 'api/manage-entity-restriction-customer-relationship',
						transformRequest : function(data) {
							var copy = angular.copy(data);
							return angular.toJson(copy);
						}
					},

				});
	}
})();
