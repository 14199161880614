(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'pendingOrderReport'
        })
        
        .controller('PendingOrderReportController', PendingOrderReportController);

    PendingOrderReportController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    	'DateUtils','AlertService','$filter',   'PendingOrderService', 'SODProcess', 'DealingAdminApplicationWideSettings'];

    function PendingOrderReportController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    		DateUtils,AlertService,$filter,  PendingOrderService, SODProcess, DealingAdminApplicationWideSettings) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.PENDING_ORDER_REPORT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
       
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.configSettings = vm.globalValues.getConfigSettings();
		
        vm.toTime = moment();    
        
        vm.defaultDuration = "01:00:00";
	    vm.maxDate = moment().subtract( vm.duration);
	    vm.todayDate = new Date();
	    vm.getPendingOrders = getPendingOrders;
	    vm.exportDataToExcelFile = exportDataToExcelFile;
	    vm.exportDataToCsvFile = exportDataToCsvFile;
	    vm.exportDataToPdfFile = exportDataToPdfFile;
	    vm.filterData = {};
	    vm.fileName = "Pending_Order_Report_";
	    vm.extension = "";
	    vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm';
	    
	    logScreenAccess();
	    vm.valid = false;
		vm.clicked=false;
	   
        function loadDates() {
        	
			SODProcess.getCurrentBusinessDate(loadSystemSettingsSuccess, loadSystemSettingsError);
		}
    	function loadSystemSettingsSuccess(result){
			vm.lastSystemSetting = result;
			vm.todayDate = DateUtils.convertLocalDateFromServer(vm.lastSystemSetting.businessDate);
    	}
    	
    	function loadSystemSettingsError(){
			
		}
        loadDates();
        
       var subtractDurationFromGivenValue = function(toValue) {
        	 if(toValue !=null && toValue !=undefined && toValue != ""){
        		 var duration = vm.duration;
        		 var hours = duration.hours();
        		 var minutes = duration.minutes();
        		 if(duration==null || duration ==undefined ||  duration=="" || ( hours == 0 && minutes == 0)){
        			 duration = moment.duration( vm.defaultDuration);
        		 }
        		 return angular.copy(toValue).subtract(duration);
        	 }else{
        		 
        		 return null;
        	 }
        }
       DealingAdminApplicationWideSettings.getConfigValueForConfigName(
        		{"configName": "TimeDifferenceForTimePickerControl"}, function(result){
        			if(result !=null && result !=undefined && result.value!=null && result.value !=undefined && result.value != ""){
        				vm.duration = moment.duration( parseInt( result.value), 'hours');
        			}else{
        				vm.duration = moment.duration( vm.defaultDuration);
        			}
        			 vm.fromTime = subtractDurationFromGivenValue( vm.toTime);
       });
      
     
       
        function setReportPeriod(){
        	vm.toDateTime = moment(angular.copy(vm.todayDate ) , 'YYYY-MM-DD');        	
        	vm.toDateTime.set(
       			 { 	'hour':vm.toTime.get('hour'),
       				'minute':vm.toTime.get('minute'),
       				'second':vm.toTime.get('second')
       				
       			 });
        	vm.fromDateTime = moment(angular.copy(vm.todayDate ) , 'YYYY-MM-DD');        	
        	vm.fromDateTime.set(
       			 { 	'hour':vm.fromTime.get('hour'),
       				'minute':vm.fromTime.get('minute'),
       				'second':vm.fromTime.get('second')
       				
       			 });
       	 
        	vm.fromTimeDateObject = new Date(vm.fromDateTime);
        	vm.toTimeDateObject = new Date(vm.toDateTime);
        }
        
        function validatePeriod(){
        	
        	
        }
        
        function copyFilterData(){
        	
        	 if(vm.bank!=null && vm.bank !=undefined){
        		 
        		 vm.filterData.bankId = angular.copy(vm.bank.id);
        	 }else{
        		
        		 vm.filterData.bankId = 0;
        	 }
        	 if(vm.branch!=null && vm.branch!=undefined){
        		
        		 vm.filterData.branchId = angular.copy(vm.branch.id);
        	}else{
        		 
        		vm.filterData.branchId = 0;
        	}if(vm.customer!=null && vm.customer!=undefined){
        		 
        		vm.filterData.customerId = angular.copy(vm.customer.id);
        	}else{
        		
        		vm.filterData.customerId = 0;
        	}
        	if(vm.instrument!=null && vm.instrument!=undefined){
        		 vm.filterData.instrumentId = angular.copy(vm.instrument.id);
        	}else{
        		vm.filterData.instrumentId = 0;
        	}
        	 vm.filterData.fromTimeDateObject = angular.copy(vm.fromTimeDateObject );
        	 vm.filterData.toTimeDateObject = angular.copy(vm.toTimeDateObject);
        }
        
        function getPendingOrders(){
        	vm.valid = true;
        	if(vm.toTime.isAfter(moment())){
        		
        		AlertService.error("To Time should be less than or equal to current time");
        	}else if(vm.fromTime.isAfter( vm.toTime)){
        		
        		AlertService.error("From Time should be less than or equal to To Time");
        	}else{
				vm.clicked=true;
        		setReportPeriod();
            	copyFilterData();
        		PendingOrderService.getPendingOrdersForReport({bankId: vm.filterData.bankId, branchId: vm.filterData.branchId, 
        			customerId:vm.filterData.customerId,  instrumentId: vm.filterData.instrumentId, fromTime:vm.fromTimeDateObject , toTime:vm.toTimeDateObject  }, 
        			onGetPendingOrdersSuccess, onGetPendingOrdersFailure);  		
        	}
        	
        	
        }
        function onGetPendingOrdersSuccess(result){
        	 angular.forEach(result, function(value){
             	value.revisionTimeStamp=moment(value.revisionTimeStamp,'YYYYMMDDHHmmss.SSSSSSSSS').format();
             	
             	if(value.expiryTime==0){
	              		var time = moment().toDate();
		              	time.setHours(0);
		              	time.setMinutes(0);
		              	time.setSeconds(0);
		              	time.setMilliseconds(0);
		              	value.expiryTime=time;
	              	}else{
	              		value.expiryTime=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
	              	}
             	
             	if(value.buySellFlag.toUpperCase()=='BUYY'){
             		value.buySellFlag='Buy';
             	}else if(value.buySellFlag.toUpperCase()=='SELL'){
             		value.buySellFlag='Sell';
             	}
             	
             	
             	
             	if(value.timeCondition.toUpperCase()!='GTTM'){
             		value.expiryTime=null;
             	}
             	
             	if(value.timeCondition.toUpperCase()=='DAYY'){
             		value.timeCondition='DAY';
             	}else if(value.timeCondition.toUpperCase()=='GTTM'){
             		value.timeCondition='GTT';
             	}else if(value.timeCondition.toUpperCase()=='IOCC'){
             		value.timeCondition='IOC';
             	}
             	
             	if(value.priceCondition.shortName!='STLS' && value.priceCondition.shortName!='TKPR'){
             		value.stopExchangeRate=null;
             	}
             	
             	if(value.lastTradeSequenceNumber==0){
             		value.lastTradeSequenceNumber=0;
             	}
         	});
        	vm.pendingOrderDetails = result;
			vm.clicked=false;
        }
        function onGetPendingOrdersFailure(error){
        	vm.clicked=false;
        }
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        function exportDataToExcelFile(){
      	  	
      	  if( vm.pendingOrderDetails !=null &&  vm.pendingOrderDetails!=undefined && vm.pendingOrderDetails.length>0){
      		 
      			  vm.mimeType = 'application/vnd.ms-excel';
      			  vm.extension = ".xls";  
      			  PendingOrderService.exportPendingOrderExcel({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId, fromTime: vm.filterData.fromTimeDateObject , toTime: vm.filterData.toTimeDateObject  } 
      			, processResponse );
      		 
      	  }
        }
       
        function exportDataToCsvFile(){
        	
        		
        	  if( vm.pendingOrderDetails !=null &&  vm.pendingOrderDetails!=undefined && vm.pendingOrderDetails.length>0){
        		 
        			vm.mimeType = 'text/csv';
        			vm.extension =  ".csv";  
        			PendingOrderService.exportPendingOrderCsv({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
          			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId, fromTime: vm.filterData.fromTimeDateObject , toTime: vm.filterData.toTimeDateObject  } , processResponse );
        		 
        	  }
        }
	    function exportDataToPdfFile(){
	    	
	    		
	      	  if( vm.pendingOrderDetails !=null &&  vm.pendingOrderDetails!=undefined && vm.pendingOrderDetails.length>0){
	      		 
	      			  vm.mimeType = 'application/pdf';
	      			  vm.extension = ".pdf";  
	      			  PendingOrderService.exportPendingOrderPdf({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
	        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId, fromTime: vm.filterData.fromTimeDateObject , toTime: vm.filterData.toTimeDateObject  } , processResponse );
	      		 
	      	  }
	    }
	    function processResponse(result){        	  
	      	  var fileData = null;
	  		  fileData = result.data;
	  		  var link = document.createElement("a");   
	  		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
	  		  vm.baseString =   vm.fileName + moment().format( vm.reportFileTimestampFormat ) +  vm.extension; 
	  		  
	  	      var blob = new Blob([bytes], {type: vm.mimeType});
	  	      if(window.navigator.msSaveOrOpenBlob) {
	                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	  	      }else{
	  	    	  link.href =  window.URL.createObjectURL(blob);      
	  	    	  link.download = vm.baseString;
	  	    	  document.body.appendChild(link);
	  	    	  link.click();
	  	    	  document.body.removeChild(link);
	  	      }
	  	}

		vm.getSwapPoints = function getSwapPoints(spotExchangeRate, tradeExchangeRate){
			var swapPts =0;
			if(spotExchangeRate!=0){
			 var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
			 swapPts = (Math.round(parseFloat(swapPts)*100)/100);
			 return swapPts;
			}else{
			 return swapPts;
			}
		}
    }
})();