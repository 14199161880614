(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('GenerateDealTicket', GenerateDealTicket);

    GenerateDealTicket.$inject = ['$resource'];

    function GenerateDealTicket ($resource) {
        var resourceUrl =  'api/generate-deal-ticket';
        
        return $resource(resourceUrl, {}, {
        	          
            	
             'generate': {
                 method: 'POST',
                 url: 'api/generate-deal-ticket',
                 responseType: 'arraybuffer',
                 transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	
                      return angular.toJson(copy);
                  },
                 transformResponse: function(data, headers) {
                     return {
                         data: data,
                         headers: headers
                     };
                 }              
             },
             'generateOutstandingTrades': {
                 method: 'POST',
                 url: 'api/generate-deal-ticket-outstanding-trades',
                 responseType: 'arraybuffer',
                 transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	
                      return angular.toJson(copy);
                  },
                 transformResponse: function(data, headers) {
                     return {
                         data: data,
                         headers: headers
                     };
                 }              
             }
              
        });
    }
})();
