(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'globalApprovalPolicy'
        })
        
        .controller('GlobalApprovalPolicyController', GlobalApprovalPolicyController);

    GlobalApprovalPolicyController.$inject = ['$scope', '$state','$rootScope', 'workflowSharedService', 'GlobalApprovalPolicy', 'GlobalValues', 'EntityPermissions', 'ScreenLog','entityConstants'];

    function GlobalApprovalPolicyController($scope, $state,$rootScope, workflowSharedService, GlobalApprovalPolicy, GlobalValues, EntityPermissions, ScreenLog,entityConstants) {
        var vm = this;
        workflowSharedService.viewFrom = "globalApprovalPolicy";
        /*vm.EntityStateApprovalProcessScreen = 'EntityStateApprovalProcess';
        vm.ApprovalProcessScreen = 'ApprovalProcess';*/
        vm.entityConstants = entityConstants;
        vm.EntityStateApprovalProcessScreen = entityConstants.ENTITY_SHORT_NAME.MANAGE_GLOBAL_ENTITY_STATE_APPROVAL_PROCESS;
        vm.ApprovalProcessScreen = entityConstants.ENTITY_SHORT_NAME.GLOBAL_APPROVAL_PROCESS;
        vm.screenLog = ScreenLog;
        vm.launch=launch;
        logScreenAccess();
        vm.clicked=false;
        /*
        vm.permitEntityStateApprovalProcessMapEntityAction = EntityPermissions.hasPermission('EntityStateApprovalProcess.MAP');
        vm.permitApprovalProcessViewEntityAction = EntityPermissions.hasPermission('ApprovalProcess.VIEW');
        vm.permitApprovalProcessCreateEntityAction = EntityPermissions.hasPermission('ApprovalProcess.CREATE');
        vm.permitApprovalProcessEditEntityAction = EntityPermissions.hasPermission('ApprovalProcess.EDIT');
        vm.permitApprovalProcessDeleteEntityAction = EntityPermissions.hasPermission('ApprovalProcess.DELETE');
         */      
        vm.permitEntityStateApprovalProcessMapEntityAction = true;
        vm.permitApprovalProcessViewEntityAction = true;
        vm.permitApprovalProcessCreateEntityAction = true;
        vm.permitApprovalProcessEditEntityAction = true;
        vm.permitApprovalProcessDeleteEntityAction = true;
       
        
        function launch() {
        	$state.go("global-approval-policy.new");
        }
        
        
        vm.loadAll = function loadAll(organisationType, customerUserType) {
        	
        	if(organisationType.shortName=="CUSTOMER"){
        		vm.custUserTypeID = customerUserType.id
        	}
        	else{
        		vm.custUserTypeID = 0;
        		customerUserType =null;
        		vm.customerusertype=null;
        	}
        	
        	$rootScope.organisationType = organisationType;        	
        	$rootScope.customerUserType = customerUserType;
        	
        	
        	//if(customerUserType != undefined){vm.custUserTypeID = customerUserType.id};
        	GlobalApprovalPolicy.getGlobalApprovalPolicies({"orgTypeId":organisationType.id, "customerUserTypeId": vm.custUserTypeID},function(result) {
        		vm.globalApprovalPolicies = result;
                vm.clicked=false;
        		console.log(result);
        		
        	});
        }
        function logScreenAccess(){
            
       	 ScreenLog.screenLog( vm.ApprovalProcessScreen,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
    }
})();
