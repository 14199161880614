(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'otpConfirmation'
	})

	.controller('OtpConfirmController', OtpConfirmController);

	OtpConfirmController.$inject = [ '$scope', '$state', 'GlobalValues', 'mode', '$stateParams', '$uibModalInstance', '$rootScope', 'CustomerRegistration', 'globalValue', 'AlertService' ];

	function OtpConfirmController($scope, $state,  GlobalValues, mode, $stateParams, $uibModalInstance, $rootScope, CustomerRegistration, globalValue, AlertService) {
		var vm = this;
		vm.mobile = $stateParams.mobile;
		vm.requestId = $stateParams.requestId;
		vm.mobileCountryCode = $stateParams.mobileCountryCode;
        vm.email = $stateParams.email;
        vm.refNo = $stateParams.refNo;
        vm.theme = $stateParams.theme;
		vm.clear = clear;
		vm.verify = verify;
        vm.wrongOTPAttemptCount=0;
		AlertService.info("OTP has been sent to your email id and mobile number");
		
        $scope.otpInput = {
    			size : globalValue.defaultOTPLength,
    			type : "password",
    			onDone : function(value) {
    				vm.otp = value;
    			},
    			onChange : function(value) {
                    if((value==null || value==undefined || value=='') || (value!=null && value!=undefined && value.length!=parseInt(globalValue.defaultOTPLength))){
                        vm.disableFetch = true;
                    }else{
                        vm.disableFetch = false;
                    }
    				//console.log(value);
    			}
    		};
        
        function verify(){
            if(vm.wrongOTPAttemptCount>=globalValue.maximumOTPWrongAttemptCount){ // check if the wrong otp was entered for 3 or more times 
                AlertService.error("Maximum wrong OTP attempt count is exceeded!<br>Please request for resend OTP.");
            }else if(mode=='customeronboardingwithdrawrequest' || mode=='customeronboardingwithdrawregrequest'){

                var mobileNo = vm.mobileCountryCode + vm.mobile;
                vm.clicked=true;
                CustomerRegistration.verifyOTP({mobile:mobileNo, otp: vm.otp}, onMobileVerifySuccess,onMobileVerifyFailure);
              /*       if(result.isVerified){
                        if(mode=='customeronboardingwithdrawrequest' || mode=='customeronboardingwithdrawregrequest'){
                            $rootScope.requestId = vm.requestId;
                            $scope.$emit('withdrawOTPConfirm');
                        }
                        $uibModalInstance.dismiss('cancel');
                    }else{
                        vm.wrongOTPAttemptCount=vm.wrongOTPAttemptCount+1;
                        AlertService.error("The entered OTP is wrong");
                    } */
                
            }else if(mode=='customeronboardingfetchrequest' || mode=='customeronboardingfetchregrequest'){
                vm.clicked=true;
                CustomerRegistration.verifyOTPAndGetCustomerRegistrationRequest({requestReferenceNo: vm.refNo, otp: vm.otp}, onVerifySuccess, onVerifyFailure);
            }
        }
        function onMobileVerifySuccess(result) {
            if (result.isVerified == 'VERIFIED') {
                vm.clicked=false;
                if (mode == 'customeronboardingwithdrawrequest' || mode == 'customeronboardingwithdrawregrequest') {
                    $rootScope.requestId = vm.requestId;
                    $scope.$emit('withdrawOTPConfirm');
                }
                $uibModalInstance.dismiss('cancel');
            
            }
        }
        function onMobileVerifyFailure() {
            vm.wrongOTPAttemptCount = vm.wrongOTPAttemptCount + 1;
            AlertService.error("The entered OTP is wrong");
            vm.clicked=false;
        }
        
        function onVerifySuccess(result){
            $rootScope.requestDetailsToDisplay = result;
            $uibModalInstance.close(result);
            vm.clicked=false;
        }

        function onVerifyFailure(){
            vm.disableFetch = false;
            vm.wrongOTPAttemptCount=vm.wrongOTPAttemptCount+1;
            vm.clicked=false;
        }
        vm.resendOTP = function resendOTP(){
            if(mode=='customeronboardingwithdrawrequest' || mode=='customeronboardingwithdrawregrequest'){
                CustomerRegistration.sendOTPToMobileAndEmail({mobile: vm.mobile, mobileCountryCode : vm.mobileCountryCode, email: vm.email}, onResendOTPSuccess, onResendOTPError);
            }else if(mode=='customeronboardingfetchrequest' || mode=='customeronboardingfetchregrequest'){
                CustomerRegistration.sendOTPForRequestRefNo({requestReferenceNo: vm.refNo}, onResendOTPSuccess, onResendOTPError);
            }
        }
        
        function onResendOTPSuccess(){
            vm.wrongOTPAttemptCount=0;
        	AlertService.info("OTP has been resent to your email id and mobile number");
        }
        
        function onResendOTPError(){
        	
        }
        
        function clear(){
			$uibModalInstance.dismiss('cancel');
        }

		
	}
})();