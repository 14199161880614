(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('search-faq', {
            parent: 'entity',
            url: '/gatewayDesk',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/search-faq/search-faq.html',
                    controller: 'SearchFaqController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('searchfaq');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })

/*     .state('search-faq.view', {
        parent: 'entity',
        url: '/search-faq/{videofilename}',
        ncyBreadcrumb: {
            label: '{{ "global.menu.faq.searchfaq" | translate }} ',
            parent: 'search-faq'
          },
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'global.title'
        },
        views: {
            'content@': {
                templateUrl: 'app/components/search-faq/faq-video.html',
                controller: 'FaqVideoController',
                controllerAs: 'vm'
            }
        },
        resolve: {

            entity: ['$stateParams', function($stateParams){
            	return $stateParams.videofilename;
            }]
        }
        });*/

        .state('search-faq.view', {
            parent: 'search-faq',
            url: '/search-faq/{videofilename}',
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.searchfaq" | translate }} ',
                parent: 'search-faq'
              },
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/search-faq/faq-video.html',
                    controller: 'FaqVideoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: function(){
                        	return {videoName : $stateParams.videofilename};
                        }
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]

            });

}
})();        