(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .service('CollectionUtils', CollectionUtils);

    CollectionUtils.$inject = ['ArrayUtils', 'ObjectUtils', 'StringUtils'];

    function CollectionUtils(ArrayUtils, ObjectUtils, StringUtils) {

        this.filter = function (array, objectPath, filterValue) {
            var filteredValue = [];

            if (!ArrayUtils.isEmpty(array) && !StringUtils.isBlank(filterValue)) {
                filteredValue = _.filter(array, function (element) {
                    return ObjectUtils.valueOf(element, objectPath).toLowerCase() === filterValue.toLowerCase();
                });
            }
            return filteredValue;
        };

        this.firstFilterValue = function (array, objectPath, filterValue) {
            var filteredValueArray = this.filter(array, objectPath, filterValue);
            var filteredValue;

            if (filteredValueArray.length > 0) {
                filteredValue = filteredValueArray[0];
            }
            return filteredValue;
        }
        this.includes = function (collection, element) {
            return _.includes(collection, element);
        };

        /** sort the collection by give element list
         * ex :var users = [ { 'user': 'fred',   'age': 48 },  { 'user': 'barney', 'age': 36 },
                             { 'user': 'fred',   'age': 40 },  { 'user': 'barney', 'age': 34 }];
                            
                    sort(users, ['user', 'age']);
                     => objects for [['barney', 34], ['barney', 36], ['fred', 40], ['fred', 48]]
         */
        this.sort = function (collection, elementList) {
            return _.sortBy(collection, elementList);
        }

        this.map = function(collection, iteratee) {
            return _.map(collection, iteratee);
        }
    }
})();
