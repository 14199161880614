(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
    	 $stateProvider.state('no-of-available-users', {
           	 parent: 'entity',
             params:{screen: 'NoOfAvailableUsers'},
             url: '/available-users-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.networkmonitor.availableUsers" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    //templateUrl: 'app/entities/available-users-report/available-users-report.html',
                	templateUrl: 'app/entities/available-users-report/cc-available-users-report.html',
                    controller: 'CCAvailableUsersReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('availableUsersReport');                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
    };
})();