(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AlertConfigurationDetailsController', AlertConfigurationDetailsController);

    AlertConfigurationDetailsController.$inject = ['Workflow', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AlertConfiguration', '$uibModalInstance', 'GlobalValues', 'workflowSharedService', 'ScreenLog'];

    function AlertConfigurationDetailsController(Workflow, $scope, $rootScope, $stateParams, previousState, entity, AlertConfiguration, $uibModalInstance, GlobalValues, workflowSharedService, ScreenLog) {
        var vm = this;

        vm.eventType = AlertConfiguration.getEventTypes();
        vm.reminderType = AlertConfiguration.getReminderTypes();
        vm.notifyByTypes = AlertConfiguration.getNotifyByTypes();
        vm.notificationTypes = AlertConfiguration.getNotificationTypes();
        vm.natureReminderMapping = AlertConfiguration.getNatureReminderMapping();
        vm.discardAlertConfiguration=discardAlertConfiguration;
        vm.rejectAlertConfiguration=rejectAlertConfiguration;
        vm.approveAlertConfiguration=approveAlertConfiguration;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        
        vm.users = AlertConfiguration.getUsersForOrganisation();

        vm.roles = AlertConfiguration.getRolesForOrganisation({"orgId" : GlobalValues.getOrganisation().id});

        if (entity!=null){
            vm.alertConfigurationHeader = entity;
            var notifyByTypeDetailsArr=vm.alertConfigurationHeader.alertConfigurationNotifyByTypeDetails;
            vm.notifyByTypeList=[];
            angular.forEach(notifyByTypeDetailsArr, function(value, key) 
            {
            	if (value.active==true){
                	vm.notifyByTypeList.push(value.notifyByType.id);
            	}
             });
            
            var notifyUserDetailsArr=vm.alertConfigurationHeader.alertConfigurationNotifyUserDetails;
            vm.userList=[];
            vm.roleList=[];
            angular.forEach(notifyUserDetailsArr, function(value, key) 
            {
            	if(value.active==true){
            		if (vm.alertConfigurationHeader.configNotifyUsersBy == 'users'){
                    	vm.userList.push(value.user.id);
            		}else{
                    	vm.roleList.push(value.organisationRole.id);
            		}
            	}
            });
        }
        if(workflowSharedService.viewFrom == 'alertConfiguration'){
        	
        	vm.alertConfigurationHeader=entity;
            //ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': vm.alertConfigurationHeader.id, 'action': 'VIEW'});
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'alertconfigurationtodolist_checker') {
        	vm.alertConfigurationHeaderDTO =  angular.fromJson($rootScope.payload); 
        	vm.alertConfigurationHeader=vm.alertConfigurationHeaderDTO.alertConfigurationHeader;
            var notifyByTypeDetailsArr=vm.alertConfigurationHeaderDTO.alertConfigurationNotifyByTypeDetails;
            vm.notifyByTypeList=[];
            angular.forEach(notifyByTypeDetailsArr, function(value, key) 
            {
            	if (value.active==true){
                	vm.notifyByTypeList.push(value.notifyByType.id);
            	}
             });
            var notifyUserDetailsArr=vm.alertConfigurationHeaderDTO.alertConfigurationNotifyUserDetails;
            vm.userList=[];
            vm.roleList=[];
            angular.forEach(notifyUserDetailsArr, function(value, key) 
            {
            	if(value.active==true){
            		if (vm.alertConfigurationHeader.configNotifyUsersBy == 'users'){
                    	vm.userList.push(value.user.id);
            		}else{
                    	vm.roleList.push(value.organisationRole.id);
            		}
            	}
            });

        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'alertconfigurationtodolist_maker') {
        	vm.alertConfigurationHeaderDTO =  angular.fromJson($rootScope.payload); 
        	vm.alertConfigurationHeader=vm.alertConfigurationHeaderDTO.alertConfigurationHeader;

            var notifyByTypeDetailsArr=vm.alertConfigurationHeaderDTO.alertConfigurationNotifyByTypeDetails;
            vm.notifyByTypeList=[];
            angular.forEach(notifyByTypeDetailsArr, function(value, key) 
            {
            	if (value.active==true){
                	vm.notifyByTypeList.push(value.notifyByType.id);
            	}
             });
            
            var notifyUserDetailsArr=vm.alertConfigurationHeaderDTO.alertConfigurationNotifyUserDetails;
            vm.userList=[];
            vm.roleList=[];
            angular.forEach(notifyUserDetailsArr, function(value, key) 
            {
            	if(value.active==true){
            		if (vm.alertConfigurationHeader.configNotifyUsersBy == 'users'){
                    	vm.userList.push(value.user.id);
            		}else{
                    	vm.roleList.push(value.organisationRole.id);
            		}
            	}
            });

            $scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        if(workflowSharedService.viewFrom == 'alertConfiguration_auditLog'){
        	
        	vm.alertConfigurationHeaderDTO =  angular.fromJson($rootScope.payload); 
        	vm.alertConfigurationHeader=vm.alertConfigurationHeaderDTO.alertConfigurationHeader;
            //ScreenLog.screenLog({'entityName': 'AlertConfiguration', 'entityRecordId': vm.alertConfigurationHeader.id, 'action': 'VIEW'});
       	 	$scope.showButton=false;
       	 	$scope.showDiscardButton = false;
        }
        
        
        vm.flag = 0;
        vm.branchId = 0;
        vm.getUsers = function getUsers(branch){
        	if ((branch!=null && vm.flag==0) || (vm.branchId != 0 && vm.branchId != branch.id)){

        		vm.users = AlertConfiguration.getUserBranchClearances({"branchId":branch.id});
            	vm.branchId = branch.id;
            	vm.flag=1;

        	}
        }

        vm.previousState = previousState.name;
        
        vm.clear=clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function approveAlertConfiguration() { 
        	AlertConfiguration.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectAlertConfiguration() { Workflow.rejectTask($rootScope.taskID); }

        function discardAlertConfiguration() {
        	AlertConfiguration.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
        
    }
})();
