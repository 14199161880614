(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('ClearcorpTACUpload', ClearcorpTACUpload);

	ClearcorpTACUpload.$inject = ['$resource'];

	function ClearcorpTACUpload($resource) {
		var resourceUrl =  'api/terms-conditions-upload-details/terms-conditions/:id';

		return $resource(resourceUrl, {}, {


			'getTermsConditionDetails':{
				isArray:true,
				method: 'GET',
				params: {organisationId : '@organisationId'
				},
				url: 'api/terms-conditions-upload-details/:organisationId'
			},
			'getBankTermsAndConditions':{
				isArray:true,
				method: 'GET',
				url: 'api/terms-conditions-upload-details/get-all-bank-tac'
			},
			'getAgreedBankDetails':{
				isArray:true,
				method: 'GET',
				url: 'api/tac-agreed-view/get-all-bank-ccil-tac'
			},
			'getTermConditionDTO':{
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'resubmit':{
				 method: 'POST',
                 url: 'api/tasks/resubmit/tac-resubmit',
                 headers: {
 					'Content-Type': undefined
 				}

				
			},
			'resubmitWithoutMultipart':{
				 method: 'PUT',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/resubmit/tac-resubmit-without-multipart',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                	return angular.toJson(copy);
                }
				
			},
			'update':{
				method:'POST',
				url: 'api/terms-conditions-upload-details',

				headers: {
					'Content-Type': undefined
				}

			},
			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},
			'getCSVForAgreedBanks':{

				method: 'POST',
				url: 'api/terms-conditions-upload-details/agreed-banks/csv',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'getExcelForAgreedBanks':{

				method: 'POST',
				url: 'api/terms-conditions-upload-details/agreed-banks/excel',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'getTermsAndConditionsMerged':{
				method:'POST',
				url: 'api/terms-conditions-upload/export-merged-pdf',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
            'getTermsAndConditionsMergedCustomerView':{
				method:'POST',
				url: 'api/terms-conditions-upload/customer/export-merged-pdf',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'getCSVForBanksTermsAndConditionsView':{

				method: 'POST',
				url: 'api/terms-conditions-upload-details/banks-tac-view-clearcorp/csv',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'getExcelForBanksTermsAndConditions':{

				method: 'POST',
				url: 'api/terms-conditions-upload-details/banks-tac-view-clearcorp/excel',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},

		});
	}
})();
