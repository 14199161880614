(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TermsAndConditionsApprovalController',TermsAndConditionsApprovalController);

    TermsAndConditionsApprovalController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues','ScreenLog', 'entityConstants','$filter', '$rootScope','$scope'
    	,'CustomerApproval'];

    function TermsAndConditionsApprovalController($uibModalInstance,$stateParams, GlobalValues, ScreenLog, entityConstants, $filter, $rootScope, $scope,
    		CustomerApproval) {
    	var vm = this;
    	vm.screenLog = ScreenLog;
    	vm.entityConstants = entityConstants;
    	vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_APPROVAL;
        vm.clear = clear;
       vm.approve = approve;
       vm.entityId =  $stateParams.entityId;
        $scope.clicked = false;
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function approve(){
        	$scope.rowID = vm.entityId;
            CustomerApproval.approve({ "taskId": vm.entityId }, onApproveSuccess, onApproveError);
            
        }
        
        function onApproveSuccess(result) {
            var flag = $filter('filter')(vm.tasks, { id: $scope.rowID });
            $rootScope.removeRow($scope.rowID);
            $uibModalInstance.dismiss('cancel');
        }
        
        

        function onApproveError(error) {
        	$scope.clicked=false;
        }

        
        
    }
})();
