//view-relationship-bank-fees-charges
//relationshipBankFeesChargesView

(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
    	 $stateProvider
         .state('view-member-fees-charges', {
             parent: 'entity',
             url: '/view-member-fees-charges',
             data: {
            	 authorities: ['ROLE_USER'],
                 pageTitle: 'global.title'
             },
             ncyBreadcrumb: {
                 label: '{{ "global.menu.memberFeesAndCharges.main" | translate }}',
                 parent: 'home'
             },
             views: {
                 'content@': {
                     templateUrl: 'app/entities/clearcorp-member-bank-fee-charges-view/clearcorp-member-bank-fee-charges-view.html',
                     controller: 'MemberBanksFeesChargesViewController',
                     controllerAs: 'vm'
                 }
             },
             resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                 	$translatePartialLoader.addPart('error');
                     $translatePartialLoader.addPart('global');       
                 	$translatePartialLoader.addPart('memberBanksFeesAndChargesView');
                
                     return $translate.refresh();
                 }]
             }
         })
         
         .state('clearcorp-member-bank-history-view', {
             parent: 'view-member-fees-charges',
             url: '/member-bank-history-view',
             data: {
                 authorities: ['ROLE_USER'],
                 pageTitle: 'global.title'
             },
             ncyBreadcrumb: {
                 label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                 parent: 'view-member-fees-charges'
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                 $uibModal.open({
                     templateUrl: 'app/entities/clearcorp-member-bank-fee-charges-view/clearcorp-member-bank-history.html',
                     controller: 'ClearcorpMemberBankHistoryController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     size: 'md',
                     resolve: {
                     	globalValue: ['StaticLookUp', function(StaticLookUp) {
                             return StaticLookUp.getConfigSettings().$promise;
                         }]
                     }
                 }).result.then(function() {
                     $state.go('^', {}, { reload: false });
                 }, function() {
                     $state.go('^');
                 });
             }],
             resolve: {                
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                 	$translatePartialLoader.addPart('clearCorpTACUpload');
                     $translatePartialLoader.addPart('error');
                     $translatePartialLoader.addPart('global');
                     return $translate.refresh();
                 }]
                
             }
         })
         
         
     
         
    };
    })();
