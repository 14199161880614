(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Tasks', Tasks);

    Tasks.$inject = ['$resource','DateUtils'];

    function Tasks ($resource, DateUtils) {
        var resourceUrl = 'api/tasks/';
        return $resource(resourceUrl, {}, {
            'query': { 
            	method: 'GET', 
            	isArray: true, 
            	url: 'api/tasks/orgId/:orgId',
            	transformResponse: function (data) {
	                if (data) {
	                    data = angular.fromJson(data);   
	                    angular.forEach(data,function(value,index){                	
	                    	value.payload = angular.fromJson(value.payload);
	                    	// var getDate = value.createdDate;
	                        // getDate = getDate.substring(0, getDate.indexOf('T'));
	                        // value.createdDate = getDate;
	                    })
	                }
                return data;
            }},
            'get': {
                method: 'GET',               
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);                                              
                    }
                    return data;
                }
            },
            'approve': {
                method: 'GET',
                isArray: true, 
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'POST',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'view':{
            	method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId',
                transformResponse: function (data) {                	
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'discard': {
            	 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/discard'
            },
            'orderApprove': {
                method: 'GET', 
                params: {taskId:'@taskId'},
                url: 'order/tasks/:taskId/approve'
            },
            'orderApproveImport': {
                method: 'GET', 
                isArray: 'true',
                params: {taskId:'@taskId'},
                url: 'order/tasks/:taskId/approve'
            }
        });
    }
})();
