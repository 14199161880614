(function () {
    'use strict';
    angular
        .module('gatewayApp')
        .constant('OperationEnum', {
        	ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete', NONE: 'None'
        })
        .run(['$rootScope', 'OperationEnum', function($rootScope, OperationEnum) {
            $rootScope.OperationEnum = OperationEnum;
        }]);
})();
