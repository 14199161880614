(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'homeBranchIFSCUpload'
        })
        
        .controller('HomeBranchIfscUploadController', HomeBranchIfscUploadController);

    HomeBranchIfscUploadController.$inject = ['$scope', '$state', 'workflowSharedService', 'HomeBranchIfscUpload', 'EntityPermissions', 'ScreenLog', 'entityConstants','ImportFile', 'AlertService', '$filter','ImportFileService', 'MessageBox','FileUtil','$rootScope'];
    function HomeBranchIfscUploadController($scope, $state, workflowSharedService, HomeBranchIfscUpload, EntityPermissions, ScreenLog , entityConstants,ImportFile, AlertService,$filter ,ImportFileService, MessageBox,FileUtil,$rootScope) {
    	
    	var vm = this;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.HOME_BRANCH_IFSC_UPLOAD;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        vm.generateTemplate = generateTemplate;
        vm.uploadFile = uploadFile;
		
        vm.invalidFileSize = false;
		vm.invalidFileName = false;
		vm.invalidFileNameText = "Invalid File Name";	
		vm.invalidFileSizeText = "Uploaded File size should be minimum of 1 Byte and maximum of 3MB";
		vm.invalidFileText = "Invalid file format";
		
		vm.invalidFile = false;
		vm.uploadedIfscData=null;
		 var Extensions = ["xls","xlsx"];
		
		workflowSharedService.viewFrom = "homeBranchIFSCUpload";
		vm.mimeType = 'application/vnd.ms-excel';
		vm.baseString = "homebranchupload"+ ".xls";  
		$rootScope.homebranchUploadInProgress = false;
				
		/* vm.permitUpload = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.IMPORT);
		
		if(vm.permitUpload == false){
			  $state.go('home');
		} */
		 var minFileSizeinBytes = 1;
	     var maxFileSizeinBytes = 3*1024*1024;
	     vm.fileExtension = "";
	     vm.inputFileName = "";
	     vm.fileNameWithoutExtension = "";
       
        logScreenAccess();
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        function uploadFile(){        	
        	$rootScope.homebranchUploadInProgress = false;
        	if(vm.invalidFile == true){
        		vm.uploadedIfscData=null;
		        vm.inputFileName=null;
		        angular.element("input[type='file']").val(null);
				AlertService.error(vm.invalidFileText);
			}else if(vm.invalidFileSize == true){
				vm.uploadedIfscData=null;
		        vm.inputFileName=null;
		        angular.element("input[type='file']").val(null);
				AlertService.error(vm.invalidFileSizeText);
			}else if(vm.invalidFileName == true){
				vm.uploadedIfscData=null;
		        vm.inputFileName=null;
		        angular.element("input[type='file']").val(null);
				AlertService.error(vm.invalidFileNameText);
			}else {
				ImportFile.importFile('HomeBranchImport',vm.uploadedIfscData);
				 vm.inputFileName=null;
			       angular.element("input[type='file']").val(null);
		      	
			}
		}        
        function generateTemplate(){
        	//ImportFile.generateTemplate( 'HomeBranchIFSCUpload');
        	HomeBranchIfscUpload.generateTemplateFile(generateSuccess, generateFailure)
        	
        }
        function generateSuccess(result){
        	/*var base64data = "base64," + $.base64.encode(result.data);
            var blob = new Blob([csvstring], { type: "application/vnd.ms-excel" + ';' + base64data })
            window.saveAs(blob, "homebranchupload" + "."  + "xls");*/
        	
        	var fileData = null;
   		  	fileData = result.data;
   		  	var link = document.createElement("a");   
   		  	var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer   		  
   		  
   		  	var blob = new Blob([bytes], {type: vm.mimeType});
   		  	if(window.navigator.msSaveOrOpenBlob) {
                   window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
   		  	}else{
   	    	  link.href =  window.URL.createObjectURL(blob);      
   	    	  link.download = vm.baseString;
   	    	  document.body.appendChild(link);
   	    	  link.click();
   	    	  document.body.removeChild(link);
   		  	}
        }
        function generateFailure(error){
        	//AlertService.error($filter('translate')('gatewayApp.homeBranchIfscUpload.templateError'));
        }
       /* function onImportSuccess(result) {
			
			MessageBox.showMessageBox("Alert", " File has been uploaded successfully. ", "Ok");
			$state.go($state.current, {}, {});
		
		
        }
        function onImportFailure(result) {
			console.log(result);
			if(result.data!="") {
				ImportFileService.exportException(result.data, true, 'HomeBranchImport' + '_error');
	    	}else{
	            MessageBox.showMessageBox("Alert",'HomeBranchImport' + " - Invalid File", "Ok");    
	    	}
			$state.go($state.current, {}, {});
        }*/
        
       $scope.$watch('vm.uploadedIfscData', function(value){
        	if(value!=null && value!=undefined){
        		vm.inputFileName = value.name;
        		console.log(minFileSizeinBytes);
        		console.log(maxFileSizeinBytes)
        		console.log(value.size)
        		console.log(FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  ))
        		if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
        			vm.invalidFileSize = true;
        			vm.invalidFileName = false;
        			vm.invalidFile = false;
        			
        		}else{
        			vm.fileNameWithoutExtension ="";        	
        			vm.invalidFileSize = false;
        			vm.invalidFile = false;
        			if(value.name!=null && value.name!=undefined){
            			vm.fileExtension = vm.inputFileName.substring(value.name.lastIndexOf(".") + 1);
            			vm.fileNameWithoutExtension =  vm.inputFileName.substring(0,value.name.lastIndexOf("."));
        			}else{
        				
    			         vm.invalidFile = false;
    			         vm.invalidFileSize = false;
    			         vm.invalidFileName = true;
    			        
    			         //AlertService.error(vm.invalidFileNameText );
        			}    			
        			
        			if(vm.fileNameWithoutExtension != 'homebranchupload' && vm.fileNameWithoutExtension != 'homebranchupload' ){
        				
    			         vm.invalidFile = false;
    			         vm.invalidFileSize = false;
    			         vm.invalidFileName = true;
        			 }
        			 else if(Extensions.indexOf(vm.fileExtension.toLowerCase()) <= -1) {
    		        	
        				//check for extension
    		        	vm.invalidFile = true;
    		        	vm.invalidFileName = false;
    		        	vm.invalidFileSize = false;
        			 }
        			 else{
        				var reader = new FileReader();
        				 reader.readAsArrayBuffer(value);
        				
        				reader.onloadend = function(e) {
        					if (e.target.readyState === FileReader.DONE) {
        						var bytes = new Uint8Array( e.target.result ); 
        						var arr = bytes.subarray(0, 4);
            					if(!FileUtil.checkExcelFileExtension(arr)){          				        	
          				        	
            						vm.invalidFile = true;
          					        vm.invalidFileSize = false;
          					        vm.invalidFileName = false;          					             					      
          				      	}else{    				      		
          				      		
          				      		vm.invalidFile = false;
          				      		vm.invalidFileSize = false;
          				      		vm.invalidFileName = false;
          						 
          				      	}
        					}
        					
        				};
        				
        			 }  
        		}
        	}
        });
       var setFoucs = function(){
    	   
    	   angular.element("#downloadTemplate").focus();
    	}     
       angular.element(document).ready(setFoucs);
   	

    }
})();