(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('order-status-report', {
            parent: 'entity',
            url: '/order-status-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.orderStatusReport.main" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-status-report/order-status-report.html',
                    controller: 'OrderStatusReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('orderStatusReport');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderActivity');
                    return $translate.refresh();
                }]
               
            }
        })
        
        .state('order-activity', {
            parent: 'order-status-report',
            url: '/order-activity-status/{orderNumber}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                            	 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.orderNumber}).$promise;
                                 }],
                                 businessDate: null
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('order-activity-pending-orders', {
            parent: 'home',
            url: '/order-activity-pending-order/{orderNumber}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                            	 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.orderNumber}).$promise;
                                 }],
                                 businessDate: null
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         .state('order-activity-reports', {
            parent: 'home-page-reports',
            url: '/order-activity-reports',
            params: {ordNo : null},
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        
                                 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.ordNo}).$promise;
                                 }],
                                 businessDate: null
                       
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('order-activity-trade-history', {
            parent: 'trade-history',
            url: '/order-activity-trade-history/{orderNumber}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                            	 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.orderNumber}).$promise;
                                 }],
                                 businessDate: null
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('order-activity-order-history', {
            parent: 'order-history',
            url: '/order-activity-order-history/{orderNumber}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-activity/order-activity.html',
                    controller: 'OrderActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        
                                 entity: ['OrderActivity', 'GlobalValues', function(OrderActivity, GlobalValues) {
                                     return OrderActivity.get({orderNumber : $stateParams.orderNumber}).$promise;
                                 }],
                                 businessDate: null
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         
         };
    })();
