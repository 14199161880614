(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('TradeHistory', TradeHistory);

    TradeHistory.$inject = ['$resource'];

    function TradeHistory ($resource) {
        var resourceUrl =  'api/trade-history/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getTradeHistory':{
           	 
             	isArray:true,
  				method: 'GET',
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId',
  					buySell : '@buySell',  fromDate : '@fromDate', toDate : '@toDate'},
  				
  				url: 'api/trade-history/get-trade-history-details'
              }
          
              
        });
    }
})();
