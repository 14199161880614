(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("EntityPermissions", EntityPermissions);
       
    EntityPermissions.$inject = ['SecurityPolicy', 'GlobalValues', '$rootScope', '$q'];

    function EntityPermissions (SecurityPolicy, GlobalValues, $rootScope, $q) {
    	
    	var entityPermission = null;
		var entityPermissionFetchInProgress = true;
		var modalDefer;

    	var service = {
    			hasPermission: hasPermission,
    			getEntityPermission: getEntityPermission,
    			clearEntityPermission: clearEntityPermission,
                hasScreenActionPermission : hasScreenActionPermission,
				hasPermissionPromise : hasPermissionPromise,
				hasScreenActionPermissionPromise : hasScreenActionPermissionPromise
    	};
    	return service;
    	
    	function clearEntityPermission(){
    		entityPermission = null;
    	}
    	
    	function hasPermission(entity){            
    		if(entityPermission == null){
    			getEntityPermission();
    		}
    		
    		var entityDetails = entity.split('.');    		
    		if (entityDetails.length == 2){
    			return hasEntityActionPermission(entityDetails[0], entityDetails[1]);
    		}
    		else{
    			return hasEntityPermission(entityDetails[0]);
    		}
    	}
    	
    	function getEntityPermission(){
			entityPermissionFetchInProgress = true;
    		entityPermission = SecurityPolicy.getEntityPermissions({orgId : GlobalValues.getOrgId()}, entityPermissionFetchSuccess, entityPermissionFetchFailure);
    	}
    	
    	function entityPermissionFetchSuccess(data){
			if(modalDefer != undefined) {
				modalDefer.resolve(data);
			}
			entityPermissionFetchInProgress = false;
    		entityPermission = data;
    		$rootScope.$emit("gatewayApp.entityPermissionFetched");
    	}
    	
    	function entityPermissionFetchFailure(){
    		entityPermissionFetchInProgress = false;
    	}
    	
    	function hasEntityPermission(entity){
    		if ( getIndexOfEntity(entity) > -1){
    			return true;
    		}
    		else{
    			return false;
    		}
    	}
    	
    	function hasEntityActionPermission(entity,action){
    		if(hasEntityPermission(entity)){
    			if (entityPermission[getIndexOfEntity(entity)].entityActions.indexOf(action) > -1){
    				return true
    			}
    			else{
    				return false;
    			}
    		}
    		else{
    			return false;
    		}
    	}
    	
    	function getIndexOfEntity(entity){
    		for (var i = 0; i <= entityPermission.length-1; i++){
    			if (entityPermission[i].entityName==entity){
    				return i;
    			}
    		}
    		return -1;
    	}

        function hasScreenActionPermission(screen, action) {
            return hasEntityActionPermission(screen, action);
        }

		function hasPermissionPromise(screen) {

			if(entityPermissionFetchInProgress){
				modalDefer = $q.defer();
				SecurityPolicy.getEntityPermissions({orgId : GlobalValues.getOrgId()}, entityPermissionFetchSuccess, entityPermissionFetchFailure);
				return modalDefer.promise;
			}
			else{
				return hasPermission(screen);
			}
            
        }

		function hasScreenActionPermissionPromise(screen, action){
			if(entityPermissionFetchInProgress){
				modalDefer = $q.defer();
				SecurityPolicy.getEntityPermissions({orgId : GlobalValues.getOrgId()}, entityPermissionFetchSuccess, entityPermissionFetchFailure);
				return modalDefer.promise;
			}
			else{
				return hasScreenActionPermission(screen, action);
			}
		}
    	
    }
})();