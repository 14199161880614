(function () {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('LoggedInUsersForBankController', LoggedInUsersForBankController);

	LoggedInUsersForBankController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',  'AlertService','LoggedInUsersForBank','$filter', 'ScreenLog','entityConstants','DateUtils', '$timeout'];

	function LoggedInUsersForBankController( $scope, $state, $rootScope, GlobalValues,AlertService , LoggedInUsersForBank , $filter , ScreenLog, entityConstants,DateUtils,$timeout){
		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.NO_OF_LOGGEDIN_USER_REPORT_FOR_BANK;
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		$scope.refresh = refresh;

		vm.headerList =[];
		vm.selectedEntityUserList=[];

		var recordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.noOfRecordsPerPage = recordsPerPage;
		$scope.page = 1;
		$scope.noOfUserRecordsPerPage = recordsPerPage;
		$scope.userPage = 1;
		$scope.noOfBranchRecordsPerPage = recordsPerPage;
		$scope.branchPage = 1;


		vm.getBankUsersDetails = getBankUsersDetails;
		vm.getBranchWiseUsersCount = getBranchWiseUsersCount;
		vm.getBranchUsersDetails = getBranchUsersDetails;


		vm.selectedBankName = '';
		vm.selectedBankId = '';	
		vm.selectedEntityName = '';
		vm.selectedEntityType = '';
		vm.selectedBranchName = '';
		vm.selectedBranchId = '';

		vm.showBranchDrillDown = false;		
		vm.showSelectedBankUsersDetails = false;
		vm.showSelectedBranchUsersDetails = false;


		//initialise list		
		vm.branchWiseUserCountList= [];
		vm.selectedEntityUserList = [];		

		vm.exportToFile = exportToFile;

		vm.todayDate = new Date();
		vm.reportDate = DateUtils.convertDateTimeFromServer(vm.todayDate);

		logScreenAccess();
		loadHeader();

		function loadHeader(){
			vm.headerList =[];
			vm.totalBankUsersCount = 0;
			vm.totalBranchUsersCount = 0;	
			vm.totalBranchUserCountForBank = 0;
			vm.filterText="";

			LoggedInUsersForBank.getHeaderData(reportHeaderSuccess, reportHeaderFailure );
		}
		function reportHeaderSuccess(result){
			vm.headerList = result;
			calculateHeaderTotal(vm.headerList);
			showGridsForHeaderSelection();
		}
		function reportHeaderFailure(error){

		}

		function getBankUsersDetails(selectedBankId, selectedBankName){

			vm.selectedBankName = selectedBankName;
			vm.selectedBankId = selectedBankId;
			vm.selectedEntityName = selectedBankName;
			vm.selectedEntityType = 'Bank';
			vm.userFilterText="";
			//reset the paggination
			$scope.noOfUserRecordsPerPage = recordsPerPage;
			$scope.userPage = 1; 	
			vm.showSelectedBankUsersDetails = true;
			vm.showSelectedBranchUsersDetails = false;
			vm.showBranchDrillDown = false;
			LoggedInUsersForBank.getBankUsersDetails({bankId:selectedBankId},bankUserDetailsSuccess, bankUserDetailsFailure );

		}
		function bankUserDetailsSuccess(result){
			vm.selectedEntityUserList = result;
		}
		function bankUserDetailsFailure(error){

		}


		function getBranchWiseUsersCount(selectedBankId, selectedBankName){
			vm.branchWiseUserCountList = [];
			vm.totalBranchUserCountForBank = 0; 					  
			vm.selectedBankName = selectedBankName;
			vm.selectedBankId = selectedBankId;
			vm.branchWiseFilterText="";
			//reset the paggination
			showGridsForBranchUserSelection();
			LoggedInUsersForBank.getBranchUsersCountForBank({bankId:selectedBankId}, branchWiseUsersCountSuccess, branchWiseUsersCountFailure );
		}
		function branchWiseUsersCountSuccess(result){

			vm.branchWiseUserCountList = result;
			calculateBranchUserTotal( vm.branchWiseUserCountList);
		}
		function branchWiseUsersCountFailure(error){
		}


		function getBranchUsersDetails(branchName , branchId, userList){
			vm.selectedEntityName = branchName;
			vm.selectedEntityType = 'Branch';
			vm.userFilterText="";
			vm.selectedEntityUserList = userList;
			//reset the paggination
			$scope.noOfUserRecordsPerPage = recordsPerPage;
			$scope.userPage = 1; 
			vm.showSelectedBankUsersDetails = false;
			vm.showSelectedBranchUsersDetails = true;
			vm.showBranchDrillDown = true;
		}

		function logScreenAccess(){
			vm.screenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

	

		/********** Refresh**********/
		$scope.toggle = true;
		function refresh() {
			$scope.toggle = false;
			$scope.refreshFlag = false;
			loadHeader();
			if ($scope.toggle == false) {
				$timeout(function() {
					$scope.toggle = true;
				}, 2000);
				$scope.refreshFlag = false;
			}
		};

		/***********END *************/
		function calculateHeaderTotal(list){
			vm.totalBankUsersCount = 0;
			vm.totalBranchUsersCount = 0;

			angular.forEach(list, function(overallCountForbank) {

				vm.totalBankUsersCount = vm.totalBankUsersCount + overallCountForbank.bankUsersCount;  
				vm.totalBranchUsersCount = vm.totalBranchUsersCount + overallCountForbank.branchUsersCount; 

			});

		}
		function calculateBranchUserTotal(list){
			vm.totalBranchUserCountForBank = 0;
			angular.forEach(list , function(branch) {		
				vm.totalBranchUserCountForBank = vm.totalBranchUserCountForBank + branch.branchUsersCount;
			});

		}

		$scope.$watch('vm.filterText', function(value){
			calculateHeaderTotal(vm.filterData);
			showGridsForHeaderSelection();
		}); 
		$scope.$watch('vm.branchWiseFilterText', function(value){
			calculateBranchUserTotal(vm.branchWiseUserFilterData);
			showGridsForBranchUserSelection();
		}); 



		function showGridsForBranchUserSelection(){

			$scope.noOfBranchRecordsPerPage = recordsPerPage;
			$scope.branchPage = 1;
			vm.showBranchDrillDown = true;
			vm.showSelectedBankUsersDetails = false;
			vm.showSelectedBranchUsersDetails = false;
			vm.userFilterText="";
		}
		function showGridsForHeaderSelection(){

			vm.showBranchDrillDown = false;
			vm.showSelectedBankUsersDetails = false;
			vm.showSelectedBranchUsersDetails = false;
			vm.userFilterText="";
			vm.branchWiseFilterText="";
		}
		function exportToFile(fileType){
			vm.baseString =   'No_Of_Logged_In_Users_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			if( vm.headerList !=null &&  vm.headerList!=undefined){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv'
						vm.baseString = vm.baseString+ ".csv";  
					LoggedInUsersForBank.getCSVData(vm.headerList , processResponse )
				}else if(fileType == 'excel'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls";  
					LoggedInUsersForBank.getExcelData(vm.headerList , processResponse );
				}
			}
		}	
		function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer


			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
})();