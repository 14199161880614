(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SMSSettingsViewDialogController', SMSSettingsViewDialogController);

    SMSSettingsViewDialogController.$inject = ['$scope', '$state','$rootScope','workflowSharedService','$uibModalInstance','SMSSettings','entity', 'Workflow', 'GlobalValues', 'ScreenLog'];

    function SMSSettingsViewDialogController($scope, $state, $rootScope, workflowSharedService, $uibModalInstance,SMSSettings,entity, Workflow, GlobalValues, ScreenLog) {
        var vm = this;
        vm.clear=clear;
        vm.approveSMSSettings = approveSMSSettings;
        vm.rejectSMSSettings = rejectSMSSettings;
        vm.discardSMSSettings = discardSMSSettings;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.globalValues = GlobalValues;
        vm.disable=true;
        

        
        if(workflowSharedService.viewFrom == 'smssettings'){
        	vm.smsSettings = entity;        
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'smssettingtodolist_checker') {
        	vm.smsSettings =  angular.fromJson($rootScope.payload); 
        	
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'smssettingstodolist_maker') {
        	vm.smsSettings =  angular.fromJson($rootScope.payload); 
        	
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom=='smssettings_auditlog'){
        	vm.smsSettings =  angular.fromJson($rootScope.payload);        	 	
        	$scope.showButton=false;

        }
        
        function approveSMSSettings() { 
        	SMSSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	$scope.clicked=false;
        }
       
        function rejectSMSSettings() { Workflow.rejectTask($rootScope.taskID); }

        function discardSMSSettings() {
        	SMSSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }   
})();
