(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('PasswordResetInit', PasswordResetInit);

    PasswordResetInit.$inject = ['$resource'];

    function PasswordResetInit($resource) {
        var service = $resource('api/account/reset-password-request', {}, {
        	
        	'save': {
            	method: 'POST',
            	url: 'api/account/reset-password-request',
            	transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
            }
        
        });

        return service;
    }
})();
