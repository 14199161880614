(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Role', Role);

    Role.$inject = ['$resource'];

    function Role ($resource) {
        var resourceUrl =  'api/orgId/:orgId/roles/:id';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId'},
        	    url: 'api/orgId/:orgId/roles'
        	 },
         	'queryAll': { method: 'GET', isArray: true,
        		params: {orgId:'@orgId'},
        	    url: 'api/orgId/:orgId/rolesAll'
        	 },
        	 'rolesAllForOrganisation': { method: 'GET', isArray: true,
         		params: {orgId:'@orgId'},
         	    url: 'api/orgId/:orgId/rolesAllForOrganisation'
         	 },
        	 'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
        	 'save': {
                 method: 'POST',
                 params: {orgId:'@orgId'},
                 url: 'api/orgId/:orgId/roles',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 }                
             },
             'update': {
             	method:'PUT',
             	params: {orgId:'@orgId'},
                url: 'api/orgId/:orgId/roles',
             	transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 }
             },
             'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/role',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              }
              
        });
    }
})();
