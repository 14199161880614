(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'regularHolidays'
        })
        
        .controller('RegularHolidayController', RegularHolidayController);

    RegularHolidayController.$inject = ['$scope', '$state', 'workflowSharedService', 'RegularHoliday', 'GlobalValues', 'EntityPermissions', '$rootScope', 'StaticLookUp', 'ScreenLog', 'entityConstants'];

    function RegularHolidayController($scope, $state, workflowSharedService, RegularHoliday, GlobalValues, EntityPermissions, $rootScope, StaticLookUp, ScreenLog , entityConstants) {
        var vm = this;

        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.REGULAR_HOLIDAY;
        vm.screenLog = ScreenLog;
        vm.launch=launch;
        vm.dayList=getDayList();
        workflowSharedService.viewFrom = "regularHolidays";
        function launch() {
        	$state.go("regular-holiday.new");
        }
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
        
       vm.permitRegularHolidayEditEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
       vm.permitRegularHolidayCreateEntityAction = EntityPermissions.hasPermission(vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.ADD);
       vm.permitRegularHolidayViewEntityAction = EntityPermissions.hasPermission(vm.screenName+'.' + vm.entityConstants.ENTITY_ACTION_TYPE.VIEW);
      
		
		loadAll();
		
        function loadAll(){
    		RegularHoliday.query(function(result){
                vm.regularHolidaysHeader = result;
            });
        }
        
        function getDayList(){
        	return StaticLookUp.getDayNumberList();
        }
        
        $rootScope.$on('regularHolidayRefresh', function(event, result) {
        });
        logScreenAccess();
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
        }
        
    }
})();
