(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SecurityPolicyDialogController', SecurityPolicyDialogController);

    SecurityPolicyDialogController.$inject = ['Workflow', '$scope', '$state', '$stateParams', '$rootScope','workflowSharedService', 'GlobalValues', '$uibModalInstance', 'Role', 'SecurityPolicy','entity','ScreenLog','entityConstants'];

     function SecurityPolicyDialogController(Workflow, $scope, $state, $stateParams, $rootScope, workflowSharedService, GlobalValues, $uibModalInstance, Role, SecurityPolicy, entity,ScreenLog,entityConstants) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SECURITY_POLICY;
        vm.screenLog = ScreenLog;
        vm.save = save;
        vm.clear = clear;
        vm.securityPolicyDTO ={};
        vm.clicked=false;
        
        vm.approveSecurityPolicy = approveSecurityPolicy;
        vm.rejectSecurityPolicy = rejectSecurityPolicy;
        vm.discardSecurityPolicy = discardSecurityPolicy;
        vm.resubmitSecurityPolicy = resubmitSecurityPolicy;
         Role.queryAll({orgId:GlobalValues.getOrgId()}, function(result){
        	 vm.roles = result;
        	if(workflowSharedService.viewFrom == 'securityPolicy'){
        		
    	        showButtonsViewScreen();    	       
    	        vm.selected = {orgRoleID: []};
    	        vm.securityPolicyDTO = entity;
    	        vm.screenLog.screenLog(vm.screenName, vm.securityPolicyDTO.themeID, entityConstants.ENTITY_ACTION_TYPE.MAP);
    	    	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
            }
            else if(workflowSharedService.viewFrom == 'securityPolicytodolist_checker') {
            	vm.ViewDisable = true;
            	//vm.roles =  Role.queryAll({orgId:GlobalValues.getOrgId()});
    	        vm.selected = {orgRoleID: []};
            	vm.securityPolicyDTO =  angular.fromJson($rootScope.payload); 
            	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
            	showButtonsViewCheckerScreen();
            }
            else if(workflowSharedService.viewFrom == 'securityPolicytodolist_maker') {
            	vm.ViewDisable = true;
            	//vm.roles =  Role.queryAll({orgId:GlobalValues.getOrgId()});
    	        vm.selected = {orgRoleID: []};
            	vm.securityPolicyDTO =  angular.fromJson($rootScope.payload); 
            	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
            	showButtonsViewMakerScreen();
            }
            
            else if(workflowSharedService.viewFrom == 'securityPolicy_resubmit') {
            	vm.securityPolicyDTO = angular.fromJson($rootScope.payload); 
    	        vm.screenLog.screenLog(vm.screenName, vm.securityPolicyDTO.themeID, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
    	        //vm.roles =  Role.queryAll({orgId:GlobalValues.getOrgId()});
    	        vm.selected = {orgRoleID: []};
            	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
            	showButtonsResubmitScreen();
            }
            else if(workflowSharedService.viewFrom == 'securityPolicy_auditLog') {
            	vm.ViewDisable = true;
            	//vm.roles =  Role.queryAll({orgId:GlobalValues.getOrgId()});
    	        vm.selected = {orgRoleID: []};
            	vm.securityPolicyDTO =  angular.fromJson($rootScope.payload); 
            	vm.selected.orgRoleID = vm.securityPolicyDTO.organisationRoleIDs;
            	vm.btnClose=true;
            	vm.btnApproveReject=false;
            	
            }
        });
        
        function showButtonsViewScreen(){
        	vm.ViewDisable = false;
            vm.btnClose = false;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = true;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = false;
        }
        function showButtonsViewCheckerScreen(){
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        	vm.btnClose = true;
        	$scope.showDiscardButton = false;
        	vm.btnApproveReject = true;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        function showButtonsResubmitScreen(){
        	vm.btnClose = false;
        	$scope.showButton=true;
        	vm.btnApproveReject = false;
        	$scope.showDiscardButton = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = true;
        }
        function showButtonsViewMakerScreen(){
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        	vm.btnClose = true;
        	vm.btnApproveReject = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }
		
		function save () {	
			
			vm.securityPolicyDTO.organisationRoleIDs = vm.selected.orgRoleID;
        	SecurityPolicy.save({"orgId":GlobalValues.getOrgId()},vm.securityPolicyDTO, onSaveSuccess, onSaveError);	
        }
     
        function onSaveSuccess (result) {
        	
            $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.clicked=false;
        }
        function approveSecurityPolicy() { 
        	SecurityPolicy.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	vm.clicked=false;
        }
       
        function rejectSecurityPolicy() { Workflow.rejectTask($rootScope.taskID); }

        function onRejectSuccess(result) {
        
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onRejectError(error) {}
        
        function discardSecurityPolicy() {
        	SecurityPolicy.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {

        }
        function resubmitSecurityPolicy() {
        	vm.securityPolicyDTO.organisationRoleIDs = vm.selected.orgRoleID;
         	SecurityPolicy.resubmit({"taskId" : $rootScope.taskID}, vm.securityPolicyDTO, onResubmitSuccess, onResubmitError);
          	$uibModalInstance.dismiss('cancel');
          }
          
          function onResubmitSuccess(result) {
         
          	$rootScope.removeRow($rootScope.taskID);
          }        
          function onResubmitError(error) {
        	  vm.clicked=false;
          }

     } 
})();