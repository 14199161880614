(function() {
	'use strict';
	angular
	.module('gatewayApp')
	.factory('StandardTenorView', StandardTenorView);

	StandardTenorView.$inject = ['$resource'];

	function StandardTenorView ($resource) {
		var resourceUrl =  'api/standard-tenor-view';

		return $resource(resourceUrl, {}, {
			'get':{
				method: 'GET',
				isArray:true,
				transformResponse: function (data) {			
				if (data) {
					data = angular.fromJson(data);
				}
				return data;
			}},


			'exportData':{
				method: 'POST',
				url: 'api/standard-tenor-view/export-excel/:businessDate',
				responseType: 'arraybuffer',
				params : { businessDate : '@businessDate'},
				transformRequest: function (data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			},
			'exportDataCSV':{
				method: 'POST',
				url: 'api/standard-tenor-view/export-csv/:businessDate',
				responseType: 'arraybuffer',
				params : { businessDate : '@businessDate'},
				transformRequest: function (data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				},
				transformResponse: function(data, headers) {
					return {
						data: data,
						headers: headers
					};   
				} 
			}
		});
	}
})();


