(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TermsAndConditionsController',TermsAndConditionsController);

    TermsAndConditionsController.$inject = ['$uibModalInstance','$stateParams', 'GlobalValues','mode','$state'];

    function TermsAndConditionsController($uibModalInstance,$stateParams, GlobalValues, mode, $state) {
    	var vm = this;
        vm.clear = clear;
       
        vm.theme = $stateParams.theme;
        vm.printPage = printPage;
        vm.mode = mode;
        vm.viewPasswordPolicy = viewPasswordPolicy;
        vm.viewScheduleOfFees = viewScheduleOfFees;
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function printPage() {
        	  var printContents = document.getElementById("printPage").innerHTML;
        	  var popupWin = window.open('', '_blank');
        	  popupWin.document.open();
			  popupWin.opener=null;
        	  popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="styles.css" /></head><body style="-webkit-print-color-adjust:exact;border:none !important" onload="window.print()">' + printContents + '</body></html>');
        	  popupWin.document.close();
        	} 
        
        function viewPasswordPolicy(){
        	
        	if(vm.mode == 'customerOnBoarding'){
        		$state.go('password-policy-customer-onBoarding', {theme : vm.theme});
        	}
        	else if(vm.mode == 'customerViewApproval'){
        		$state.go('password-policy-customer-view-approval', {theme : vm.theme});
        	}
        	else if(vm.mode == 'customerToDo'){
        		$state.go('password-policy-customer-approval', {theme : vm.theme});
        	}
        	else if(vm.mode == 'tradingBankRelationship'){
        		$state.go('password-policy-bank-registration', {theme : vm.theme});
        	}
        }
        
        function viewScheduleOfFees(){
        	
        	if(vm.mode == 'customerOnBoarding'){
        		$state.go('schedule-of-fees-customer-onBoarding', {theme : vm.theme});
        	}
        	else if(vm.mode == 'customerViewApproval'){
        		$state.go('schedule-of-fees-customer-view-approval', {theme : vm.theme});
        	}
        	else if(vm.mode == 'customerToDo'){
        		$state.go('schedule-of-fees-customer-approval', {theme : vm.theme});
        	}
        	else if(vm.mode == 'tradingBankRelationship'){
        		$state.go('schedule-of-fees-bank-registration', {theme : vm.theme});
        	}
        }
    }
})();
