(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ImportFileService', ImportFileService);

    ImportFileService.$inject = ['$resource'];

    function ImportFileService ($resource) {
        var resourceUrl =  '/api/screen-log';

        return $resource(resourceUrl, {}, {
		 'importFile': {
         	headers: {'Content-Type': undefined},	
         	method: 'POST', 
         	isArray: true,
             params: {uploadType:"@uploadType"},
             url: 'api/files/:uploadType/import',
             transformResponse: function (data) {
                 if (data) {
                     data = angular.fromJson(data);
                 }
                 return data;
             }
		 },
		 'orderImport': {
	         	headers: {'Content-Type': undefined},	
	         	method: 'POST', 
	         	isArray: true,
	             params: {uploadType:"@uploadType"},
	             url: 'order/files/:uploadType/import',
	             transformResponse: function (data) {
	                 if (data) {
	                     data = angular.fromJson(data);
	                 }
	                 return data;
	             }
			 }
        });
    }
})();
