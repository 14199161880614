(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TransactionChargesSettingsDialogController', TransactionChargesSettingsDialogController);

    TransactionChargesSettingsDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',
    	'workflowSharedService','$uibModalInstance','TransactionChargesSettings','entity','DateUtils','Workflow', 'AlertService', '$filter'];

     function TransactionChargesSettingsDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, 
    		 $uibModalInstance,TransactionChargesSettings,entity,DateUtils,Workflow, AlertService, $filter) {
        var vm = this;
        vm.transactionCharges=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitCharges = resubmitCharges;
        vm.globalValues = GlobalValues;
        vm.discardCharges = discardCharges;
        vm.rejectCharges= rejectCharges;
        vm.approveCharges= approveCharges;
        vm.viewMode=false;
        vm.showEffectiveFrom=false;
        vm.addCharges = addCharges;
        vm.editCharges = editCharges;
        vm.removeCharges = removeCharges;
        vm.resetCharges = resetCharges;
        vm.noOfDecimals = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForPercent = 4;
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        vm.transactionChargesDetailsList = [];
        vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.newTransactionCharges = { minimumTradedValue : 0.01, maximumTradedValue : null, charges : null, editable : true, valid: false}
        
        if(workflowSharedService.viewFrom == 'transactionChargesSettings'){
    		vm.transactionChargesDetailsList.push(angular.copy(vm.newTransactionCharges));
			if (entity != null && entity != undefined && entity.transactionChargesDetailsList != null && entity.transactionChargesDetailsList != undefined
					&& entity.transactionChargesDetailsList.length > 0) {
				vm.transactionCharges = entity;
				setValidForExistingChargesSlabs();
				prepareNewTransactionCharge(vm.transactionCharges.transactionChargesDetailsList);
				vm.transactionCharges.transactionChargesDetailsList.push(angular.copy(vm.newTransactionCharges));
				vm.transactionChargesDetailsList = angular.copy(vm.transactionCharges.transactionChargesDetailsList);
			}
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        	$scope.DisabledControl = false;
        }
        else if(workflowSharedService.viewFrom == 'transactionChargesSettingstodolist_resubmit') {
        	vm.transactionCharges = angular.copy( angular.fromJson($rootScope.payload));
        	setValidForExistingChargesSlabs();
        	prepareNewTransactionCharge(vm.transactionCharges.transactionChargesDetailsList);
        	vm.transactionCharges.transactionChargesDetailsList.push(angular.copy(vm.newTransactionCharges));
        	vm.transactionChargesDetailsList = angular.copy(vm.transactionCharges.transactionChargesDetailsList);
        	
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        	$scope.DisabledControl = false;
        }
        else if(workflowSharedService.viewFrom == 'transactionChargesSettingstodolist_resubmit_delete') {
        	vm.transactionCharges = angular.copy( angular.fromJson($rootScope.payload));
        	
        	vm.transactionChargesDetailsList = angular.copy(vm.transactionCharges.transactionChargesDetailsList);
        	
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=true;
        	$scope.DisabledControl = true;
        }
        else if(workflowSharedService.viewFrom == 'transactionChargesSettings_todolist_checker') {
        	vm.transactionCharges =  angular.fromJson($rootScope.payload); 
        	vm.transactionChargesDetailsList = angular.copy(vm.transactionCharges.transactionChargesDetailsList);
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	$scope.DisabledControl = true;
        }
        else if(workflowSharedService.viewFrom == 'transactionChargesSettings_todolist_maker') {
        	vm.transactionCharges =  angular.fromJson($rootScope.payload); 
        	vm.transactionChargesDetailsList = angular.copy(vm.transactionCharges.transactionChargesDetailsList);
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        	$scope.DisabledControl = true;
        }
        else if(workflowSharedService.viewFrom=='transactionChargesSettings_auditlog'){
        	vm.transactionCharges =  angular.fromJson($rootScope.payload); 
        	vm.transactionChargesDetailsList = angular.copy(vm.transactionCharges.transactionChargesDetailsList);
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	$scope.DisabledControl = true;

        }
        
        //prepare new charge slab to enter. getting maximum transaction charges from the last slab and assigned it to minimum transaction charges of new slab
		function prepareNewTransactionCharge(transactionChargesDetailsList){
			if(transactionChargesDetailsList.length>0){
				var lastSlab = transactionChargesDetailsList[transactionChargesDetailsList.length-1];
				if(lastSlab!=null && lastSlab!=undefined){
					vm.newTransactionCharges.minimumTradedValue = Number(lastSlab.maximumTradedValue) + Number(0.01);
				}
			}
		}
		
		function setValidForExistingChargesSlabs(){
			angular.forEach(vm.transactionCharges.transactionChargesDetailsList, function(value,index){
				value.valid =true;
			})
		}
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	vm.transactionChargesDetailsList = getValidObject(vm.transactionChargesDetailsList);
        	if($scope.errorMsg!=''){
   				AlertService.error($filter('translate')($scope.errorMsg));
        	}else{
	        	vm.transactionCharges.transactionChargesDetailsList = vm.transactionChargesDetailsList;
	        	vm.transactionCharges.transactionChargesDetailsList = $filter('orderBy')(vm.transactionCharges.transactionChargesDetailsList, 'minimumTradedValue');
	        	if (vm.transactionCharges.id != null) {
	        		TransactionChargesSettings.update({
						"orgId" : GlobalValues.getOrgId()
					}, vm.transactionCharges, onSaveSuccess, onSaveError);
				} else {
					TransactionChargesSettings.save({
						"orgId" : GlobalValues.getOrgId()
					}, vm.transactionCharges, onSaveSuccess, onSaveError);
				}
        	}
         }
      
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
      
        function onSaveError () {
        	prepareNewTransactionCharge(vm.transactionChargesDetailsList);
			vm.transactionChargesDetailsList.push(angular.copy(vm.newTransactionCharges));
        	vm.clicked = false;
        }

        function resubmitCharges() {
        	vm.transactionChargesDetailsList = getValidObject(vm.transactionChargesDetailsList);
        	vm.transactionCharges.transactionChargesDetailsList = vm.transactionChargesDetailsList;
        
        	TransactionChargesSettings.resubmit({"taskId" : $rootScope.taskID}, vm.transactionCharges, onResubmitSuccess, onResubmitError);
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        

        function onResubmitError(error) {
        	prepareNewTransactionCharge(vm.transactionChargesDetailsList);
			vm.transactionChargesDetailsList.push(angular.copy(vm.newTransactionCharges));
        	vm.clicked = false;
        }
        
        function approveCharges() { 
        	TransactionChargesSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        

        function onApproveError(error) {
        	
        }
       
        function rejectCharges() { Workflow.rejectTask($rootScope.taskID); }

        function discardCharges() {
        	TransactionChargesSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        

        function onDiscardError(error) {
        	
        }
        
        function addCharges(index){
        	$scope.errorMessage = '';
        	
        	$scope.errorMessage = validateList(index);
    		if($scope.errorMessage == ''){
        		vm.transactionChargesDetailsList[index].editable = false;
        		vm.transactionChargesDetailsList[index].valid = true;
        		vm.transactionChargesDetailsList[index].maximumTradedValue = (Math.round(parseFloat(vm.transactionChargesDetailsList[index].maximumTradedValue) * 10000)/10000)
        		vm.transactionChargesDetailsList[index].minimumTradedValue = (Math.round(parseFloat(vm.transactionChargesDetailsList[index].minimumTradedValue) * 10000)/10000)
            	if(vm.transactionChargesDetailsList[index+ 1] != null && vm.transactionChargesDetailsList[index+ 1] != undefined){
            		vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = Number(vm.transactionChargesDetailsList[index].maximumTradedValue) + Number(0.01);
    			}
        		prepareNewTransactionCharge(vm.transactionChargesDetailsList);
				vm.transactionChargesDetailsList.push(angular.copy(vm.newTransactionCharges));
    		}
   			else{
   				vm.transactionChargesDetailsList[index].valid = false;
   				//AlertService.error($filter('translate')(errorMessage));
   				$scope.errorMessage = $filter('translate')($scope.errorMessage);
   			}
        	
        }
        
        function editCharges(editIndex){
        	
        	angular.forEach(vm.transactionChargesDetailsList, function(value, index){
        		
        		if(angular.equals(value, vm.newTransactionCharges) && vm.transactionChargesDetailsList.length>1){
        			vm.transactionChargesDetailsList.splice(index, 1);
        		}
        		
        		if(!value.valid && vm.transactionChargesDetailsList.length>1){
        			vm.transactionChargesDetailsList.splice(index, 1);
        		}
        		
        		value.editable = false;
        		
        		if(index>0 && index == vm.transactionChargesDetailsList.length -1){
        			
        				vm.transactionChargesDetailsList.splice(index,1);
        			
        		}
        		
        	})
        	
        	if(vm.transactionChargesDetailsList[editIndex] != null && vm.transactionChargesDetailsList[editIndex] != undefined)
        		vm.transactionChargesDetailsList[editIndex].editable = true;
        }
        
        function removeCharges(index){
        	if(vm.transactionChargesDetailsList[index+ 1] != null && vm.transactionChargesDetailsList[index+ 1] != undefined){
        		if(index==0){
        			vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = Number('0.01');
        		}else{
        			vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = Number(vm.transactionChargesDetailsList[index-1].maximumTradedValue) + Number(0.01);
        		}
			}
        	vm.transactionChargesDetailsList.splice(index, 1);
        }
        
        function resetCharges(index){
        	var maxValueCtrl = "txtMaxValue_" + index;
        	var chargesCtrl = "txtCharges_" + index;
        	document.getElementById(maxValueCtrl).value = "";
        	vm.transactionChargesDetailsList[index].maximumTradedValue = null;
        	document.getElementById(chargesCtrl).value = "";
        	vm.transactionChargesDetailsList[index].charges = null;
        	vm.transactionChargesDetailsList[index].valid = false;
        }
        
        
        function getValidObject(transactionChargesDetails) {
        	$scope.errorMsg = '';
        	angular.forEach(transactionChargesDetails, function(value,index) {
				if (angular.equals(value, vm.newTransactionCharges)) {
					transactionChargesDetails.splice(index, 1);

				} else if (value.editable == true) {
					if(vm.transactionChargesDetailsList[index].maximumTradedValue!=null && vm.transactionChargesDetailsList[index].charges!=null){
						$scope.errorMsg = 'gatewayApp.transactionChargesSettings.incompleteSlab';
						vm.clicked = false;
					}else{
						if(vm.transactionChargesDetailsList[index+ 1] != null && vm.transactionChargesDetailsList[index+ 1] != undefined){
	                		if(index==0){
	                			vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = Number('0.01');
	                		}else{
	                			vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = Number(vm.transactionChargesDetailsList[index-1].maximumTradedValue) + Number(0.01);
	                		}
	        			}
						transactionChargesDetails.splice(index, 1);
					}
				}
				
			});

			vm.transactionChargesDetailsList = transactionChargesDetails;

			return vm.transactionChargesDetailsList;
		}

        
        function validateList(index){
        	
        	if(Number(vm.transactionChargesDetailsList[index].minimumTradedValue) > Number(vm.transactionChargesDetailsList[index].maximumTradedValue) 
        			|| vm.transactionChargesDetailsList[index].maximumTradedValue=='' || vm.transactionChargesDetailsList[index].maximumTradedValue==undefined){
				
    			//AlertService.error($filter('translate')('gatewayApp.transactionChargesSettings.maxLessThanMin'));
    			return 'gatewayApp.transactionChargesSettings.maxLessThanMin';
			}
			
			else if(vm.transactionChargesDetailsList[index-1] != null && vm.transactionChargesDetailsList[index-1] != undefined
					&& Number(vm.transactionChargesDetailsList[index].minimumTradedValue)
					<= Number(vm.transactionChargesDetailsList[index-1].maximumTradedValue)){
				
				//AlertService.error($filter('translate')('gatewayApp.transactionChargesSettings.greaterMin'));
				return 'gatewayApp.transactionChargesSettings.greaterMin';
			}
        	
			else if(vm.transactionChargesDetailsList[index+ 1] != null && vm.transactionChargesDetailsList[index+ 1] != undefined
					&& Number(vm.transactionChargesDetailsList[index].maximumTradedValue)
					>= Number(vm.transactionChargesDetailsList[index+ 1].maximumTradedValue)){
			//	AlertService.error($filter('translate')('gatewayApp.transactionChargesSettings.greaterMax'));
				return 'gatewayApp.transactionChargesSettings.greaterMax';
			}
			else if(vm.transactionChargesDetailsList[index+ 1] != null && vm.transactionChargesDetailsList[index+ 1] != undefined
					&& Number(vm.transactionChargesDetailsList[index].maximumTradedValue)
					> Number(vm.transactionChargesDetailsList[index+ 1].minimumTradedValue)){
			//	AlertService.error($filter('translate')('gatewayApp.transactionChargesSettings.greaterMax'));
				//return 'gatewayApp.transactionChargesSettings.greaterMax';
				vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = vm.transactionChargesDetailsList[index].maximumTradedValue;
			}
			else if(vm.transactionChargesDetailsList[index+ 1] != null && vm.transactionChargesDetailsList[index+ 1] != undefined){
				vm.transactionChargesDetailsList[index+ 1].minimumTradedValue = vm.transactionChargesDetailsList[index].maximumTradedValue;
			}
        	return '';
        }
       
     }   
})();
