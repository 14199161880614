(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CSPLControlController', CSPLControlController);

    CSPLControlController.$inject = ['$rootScope', '$scope', 'CSPLControlService', '$timeout', 'User', '$window', 'CSPLDataModel'];

    function CSPLControlController($rootScope, $scope, CSPLControlService, $timeout, User, $window, CSPLDataModel) {
        var vm = this;
        vm.captcha = null;
        vm.captchaNotSet = false;
        vm.submited = false;
        vm.submit = submit;
        vm.hideWidget = hideWidget;
        vm.hideRow = hideRow; 
        vm.widget1 = true;
        vm.widget2 = true;
        vm.widget3 = true;
        vm.widget4 = true;
        vm.widget5 = true;
        vm.widget1Data = [];
        vm.widget3Data = [];
        vm.widget1DataCount = 0;
        vm.widget3DataCount = 0;
        vm.allItems = [{"news": "Sec 1", "src" : "downarrow.jpg"}, {"news": "Sec 2", "src" : "uparrow.jpg"}, {"news": "Sec 3", "src" : "downarrow.jpg"}, {"news": "Sec 4", "src" : "downarrow.jpg"}];
        
        
        $scope.dashboardOptions = { 
        		'widgetDefinitions' : [{
                	name : 'Widget1',
                	template : '<input type="text" class="form-control" ng-model="cspmmodel" /> <br /> {{value}} <br /> <button class="btn btn-primary" ng-click=updateValue("a")>Test</button>',
                	dataAttrName: 'value',  
                    dataModelType: CSPLDataModel
                },{
                	name : 'Widget2',
                	template : '<input type="text" class="form-control" ng-model="cspmmodel" /> <br /> {{value}} <br /> <button class="btn btn-close">Clear</button>'
                },{
                	name : 'Widget3',
                	template : '<input type="text" class="form-control" ng-model="cspmmodel" /> <br /> {{value}} <br /> <button class="btn btn-close">Clear</button>'
                },{
                	name : 'Widget4',
                	template : '<input type="text" class="form-control" ng-model="cspmmodel" /> <br /> {{value}} <br /> <button class="btn btn-close">Clear</button>'
                },{
                	name : 'Widget5',
                	template : '<input type="text" class="form-control" ng-model="cspmmodel" /> <br /> {{value}} <br /> <button class="btn btn-close">Clear</button>'
                }],
                hideWidgetSettings: true,
                hideWidgetName: true,
                hideToolbar: true,
                hideWidgetClose: true,
                'defaultWidgets' :  [{ name : 'Widget1'}, { name : 'Widget2'}, { name : 'Widget3'}, { name : 'Widget4'}, { name : 'Widget5'}],
                'settingsModalOptions' : {},
                'widgetButtons' : false
        };

        
//        refreshWidget1();
//        refreshWidget3();
//        refreshTicker();
        
        function refreshTicker(){
        	User.query(function(data) {
        		vm.widget1DataCount += 1;
        		vm.allItems[3].news = "CSPL " + vm.widget1DataCount;
        		
        		if (vm.allItems[3].src == 'downarrow.jpg'){
        			vm.allItems[3].src = 'uparrow.jpg';
        		}
        		else {
        			vm.allItems[3].src = 'downarrow.jpg';
        		}
        		
        		$timeout(refreshTicker, 2000);
        	});
        }
        
//        angular.element('#mqMain').mouseover(function() {
//        	angular.element('#mqMain').stop();
//        }).mouseout(function() {
//        	angular.element('#mqMain').start();
//        });
        
        function refreshWidget1(){
        	User.query(function(data) {
        		vm.widget1Data = data;
        		vm.widget1DataCount += 1;
        		vm.widget1Data[0].login = vm.widget1Data[0].login + vm.widget1DataCount; 
        		$timeout(refreshWidget1, 2000);
        	});
        }
        
        function refreshWidget3(){
        	User.query(function(data) {
        		vm.widget3Data = data;
        		vm.widget3DataCount += 1;
        		vm.widget3Data[0].login = vm.widget3Data[0].login + vm.widget3DataCount; 
        		$timeout(refreshWidget3, 3000);
        	});
        }
        
        function hideRow(){
        	vm.widget3 = !vm.widget3;
        }
        
        function hideWidget(){
        	vm.widget2 = !vm.widget2;
        	if (vm.widget2){
        		angular.element('#wg1').css('width', '50%');
        	}
        	else {
        		angular.element('#wg1').css('width', '100%');        		
        	}
//        	var myEl = angular.element( document.querySelector( '#wg1' ) );
//        	myEl.addClass('col-lg-6');
//        	myEl.addClass('col-lg-12');
        }
        
//        var recaptcha = recaptchaFactory.create('.csCaptcha', {
//            sitekey: '6LdPbU4UAAAAAJxfAdVJrDhjs4RevSqC59LSPCig'
//        });
//        var callbacks = {
//    	    created: function (widgetId) {
//
//    	    },
//    	    success: function (response) {
//    	    	vm.captcha = response;
//    	    },
//    	    expired: function (widgetId) {
//    	        recaptcha.reload();
//    	    }
//    	}
//        recaptcha.setConfig(callbacks);
//        recaptcha.render();
        
        function submit(){
        	vm.captchaNotSet = false;
        	if (vm.captcha == null){
        		vm.captchaNotSet = true;
        	}
        	else {
        		CSPLControlService.verify(vm.captcha, function(data){
        			vm.submited = true;
        			vm.message = data.result;
        		});
    		
        	}
        }
        

    }
})();
