(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("Workflow", Workflow);

    Workflow.$inject = ['$rootScope', '$state'];

    function Workflow($rootScope,$state) {

    	var service = {
    			approveTask: approveTask,
    			rejectTask: rejectTask,
    			discardTask: discardTask,
    			rejectCustomerApproval: rejectCustomerApproval
    	};
    	return service;
    	
    	function approveTask(taskId){
    		
    	}
    	
    	function discardTask(taskId){
    		
    	}
    	
    	function rejectTask(taskId){
    		$rootScope.taskIdForRejection = taskId;
    		$state.go('workflow-rejection');
    	}
    	function rejectCustomerApproval(taskId){
    		$rootScope.taskIdForRejection = taskId;
    		$state.go('workflow-customer-rejection');
    	}

       
    }
})();
