(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'translationHandler', '$window',
        'Auth', 'Principal', 'VERSION', 'EntityPermissions', 'ObjectUtils', 'StringUtils','MessageBox','Account'];

    function stateHandler($rootScope, $state, $sessionStorage, $translate, JhiLanguageService, translationHandler, $window,
        Auth, Principal, VERSION, EntityPermissions, ObjectUtils, StringUtils,MessageBox,Account) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // validate screen parameter if available
                if (toStateParams) { 
                    var hasAccess =  getStateHasAccess(toStateParams);
                    
                    // no access permissions for the screen then move to login screen
                    if(!hasAccess){
                        event.preventDefault(); 
                       // $state.go('accessdenied');
                        MessageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'unAthuAccess');
                        return; 
                    }
                }
                $rootScope.$on('gatewayApp.messagebox.ok.unAthuAccess', function (event) {
                    Auth.sessionlogout();
                });

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
                var titleKey = 'global.title' ;

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);
            });

            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });
        }

        /**
         * get state has Access
         * @param {object} stateParams 
         * @returns 
         */
         function getStateHasAccess(stateParams) {
            var hasAccess= true;

            //get Screen name and action from the parameter
            var screen = getScreenParam(stateParams);
            var action = getActionParam(stateParams);

            //default screen access is true, if the screen required access then check the access and assign it
            if (!StringUtils.isBlank(action) && !StringUtils.isBlank(screen)) {
                
                var returnValue = EntityPermissions.hasScreenActionPermissionPromise(screen, action);
                if(!angular.isUndefined(returnValue) && !angular.isUndefined(returnValue.then)){
                    returnValue.then(function(result){
                        var access=EntityPermissions.hasScreenActionPermission(screen, action);
                        if(!access){
                            $state.go('accessdenied');
                        };
                    })
                }
                else{
                    hasAccess = returnValue;
                }
            } else if (!StringUtils.isBlank(screen)) {
    
                var returnValue = EntityPermissions.hasPermissionPromise(screen);
                if(!angular.isUndefined(returnValue) && !angular.isUndefined(returnValue.then)){
                    returnValue.then(function(result){
                        var access=EntityPermissions.hasPermission(screen);
                        if(!access){
                            $state.go('accessdenied');
                        };
                    })
                }
                else{
                    hasAccess = returnValue;
                }
            }
            return hasAccess;  
        }

        /**
         * Get screen name parameter
         */
        function getScreenParam(stateParams){
           var screenParam = screenParam = ObjectUtils.valueOf(stateParams, 'screen');
           return screenParam;
        }

        /**
         * Get action name parameter
         */
        function getActionParam(stateParams){
            var actionParam = ObjectUtils.valueOf(stateParams, 'action');
            return actionParam; 
        }

    }
})();
