'use strict';

angular.module('gatewayApp')
  .factory('CSPLDataModel',['WidgetDataModel', '$timeout', function (WidgetDataModel, $timeout) {
      function CSPLDataModel() {
      }
      var value = 'CSPL';
      var widgetScope = null;
      var refreshWidget = null;
      var count = 0;
      CSPLDataModel.prototype = Object.create(WidgetDataModel.prototype);
      CSPLDataModel.prototype.constructor = WidgetDataModel;

      angular.extend(CSPLDataModel.prototype, {
          init: function () {
        	  widgetScope = this.widgetScope;
        	  refreshWidget = this.refreshWidget;
              this.widgetScope.updateValue = this.updateValue;
              this.refreshWidget();
              this.updateScope(value);      
          },
          refreshWidget: function (){
        	  count += 1;
        	  widgetScope.cspmmodel = value + count;   
        	  //$timeout(refreshWidget, 2000);
          },
          updateScope: function(data){
        	  widgetScope.cspmmodel = data;
          },
          updateValue : function(data){
        	  widgetScope.value = 'Button Clicked';
          },
          updateLimit: function (limit) {
              this.dataModelOptions = this.dataModelOptions ? this.dataModelOptions : {};
              this.dataModelOptions.limit = limit;
              this.limit = limit;
          },

          destroy: function () {
              WidgetDataModel.prototype.destroy.call(this);
          }
      });

      return CSPLDataModel;
  }]);