(function() {
	'use strict';
	angular.module('gatewayApp').factory('TransactionChargesSettings', TransactionChargesSettings);

	TransactionChargesSettings.$inject = [ '$resource' ];

	function TransactionChargesSettings($resource) {
		var resourceUrl = 'api/transaction-charges-settings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				url: 'api/all-transaction-charges-settings',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			

			'save' : {
				method : 'POST',
				params : {
					orgId : '@orgId'
				},
				url : 'api/transaction-charges-settings/orgId/:orgId',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'update' : {
				method : 'PUT',
				params : {
					orgId : '@orgId'
				},
				url : 'api/transaction-charges-settings/orgId/:orgId',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'delete' : {
				method : 'DELETE',
				params : {
					orgId : '@orgId',
					id : '@id'
				},
				url : 'api/transaction-charges/:orgId/:id'
			},

			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},
			
			'resubmit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmitForCreate/transaction-charges',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'resubmitForEdit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmitForEdit/transaction-charges',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			}
		

		});
	}
})();
