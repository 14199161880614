(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('InvoiceView', InvoiceView);

    InvoiceView.$inject = ['$resource'];

    function InvoiceView ($resource) {
        var resourceUrl =  'api/invoice-view/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getInvoiceDetailsByInvoiceNumber':{
           	 
        		isArray : true,
  				method: 'GET',
  				params: {invoiceNo : '@invoiceNo',orgId : '@orgId'},

  				url: 'api/invoice-view/get-invoice-detais-by-number',
  				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
              },
              
              'getInvoiceDetails':{
                	 
               	isArray:true,
    				method: 'GET',
    				params: {bankId : '@bankId',chargesTypeId:'@chargesTypeId',financialYearId:'@financialYearId',
    							invoiceStartDate:'@invoiceStartDate',invoiceEndDate:'@invoiceEndDate'},
    				url: 'api/invoice-view/get-all-invoice-details'
                },
            'getReportPeriodList':{
            	isArray:true,
				method: 'GET',
				params:{financialYearFromDate:'@financialYearFromDate',financialYearToDate:'@financialYearToDate'},
				url: 'api/invoice-view/get-all-report-periods'
            },
            'getAnnexureDetails':{
            	isArray:false,
				method: 'GET',
				params: {
					invoiceId: '@invoiceId'
				},
				url: 'api/invoice-view/get-annexure-details'
            },
            'getInvoiceNumbers':{
            	isArray:true,
				method: 'GET',
				url: 'api/invoice-view/get-all-invoice-numbers'
            },
            'regenerateInvoice':{
              	 
        		isArray : true,
  				method: 'GET',
  				params: {invoiceNumber : '@invoiceNumber'},
  				url: 'api/invoice-view/regenerate-invoice-by-number',
  				transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
              }
          
              
        });
    }
})();
