 (function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('change-email', {
            parent: 'user-profile',
            url: '/change-email/{userId}/{email}/{mode}/{customerId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-email/change-email.html',
                    controller: 'ChangeEmailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
         .state('change-email-user', {
            parent: 'manage-users',
            url: '/change-email/{userId}/{email}/{mode}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'manage-users'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-email/change-email.html',
                    controller: 'ChangeEmailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('change-email-customer-info-resubmit', {
            parent: 'customer-info-status-view',
            url: '/change-email-customer-info/{userId}/{email}/{mode}/{customerId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'user-profile'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/change-email/change-email.html',
                    controller: 'ChangeEmailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
         };
    })();
