
(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= ''
	})

	.controller('ClearcorpMemberBankHistoryController', ClearcorpMemberBankHistoryController);

	ClearcorpMemberBankHistoryController.$inject = ['$scope','$rootScope','$state',  'GlobalValues', 'workflowSharedService', '$uibModalInstance','EntityPermissions', 'ScreenLog','entityConstants','$timeout','DateUtils','MemberBanksFeeAndChargesViewService','BankFeeViewForEntitiesUtility','AlertService','$filter'];

	function ClearcorpMemberBankHistoryController($scope,$rootScope,$state, GlobalValues, workflowSharedService, $uibModalInstance, EntityPermissions, ScreenLog,entityConstants,$timeout,DateUtils,MemberBanksFeeAndChargesViewService,BankFeeViewForEntitiesUtility,AlertService,$filter) {
		var vm = this;
		vm.clear=clear;
		vm.go = go;
		vm.banksFeeChargeDTO = [];
		
		vm.globalValues = GlobalValues;
		vm.valid=false;
		
        vm.todayDate = new Date();
        vm.fromDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        vm.toDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        
      //  vm.minDate = DateUtils.getMinimumLocalDate(new Date());
        vm.noOfMonthsForHistoricalView = vm.globalValues.getConfigSettings().noOfMOnthsForHistoricalReportsView;
        
        vm.clicked=false;
		vm.goClick = false;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.BANK_FEES_CHARGES_VIEW;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
	
		vm.permitBankViewEntityAction = true;
		vm.openBankFeesChargesPreview = openBankFeesChargesPreview;
		function openBankFeesChargesPreview(memberBankFeesChargesFile){
			BankFeeViewForEntitiesUtility.previewBankFee(memberBankFeesChargesFile,'Bank Fees And Charges.pdf');	
		}

		
		
		function go() {
			
			if(moment(vm.fromDate).isAfter(vm.todayDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.memberBanksFeesAndChargesView.futureFromDate'));
	   			vm.valid=false;
	   		}
	   		else if(moment(vm.fromDate).isAfter(vm.toDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.memberBanksFeesAndChargesView.greaterFromDate'));
	   			vm.valid=false;
	   		}else if(moment(vm.toDate).isAfter(vm.todayDate,'day')){
	   			AlertService.error($filter('translate')('gatewayApp.memberBanksFeesAndChargesView.greaterToDate'));
	   			vm.valid=false;
	   		}
	   	/*	else if(moment(vm.fromDate).isBefore(vm.minDate, 'day')){
	   			AlertService.error('Historical Reports are available only for a period of '  + vm.noOfMonthsForHistoricalView + ' months...!');
	   			vm.valid=false;
	   		}*/
	   		else{
	   			vm.valid=true;
				vm.clicked=true;
	   			vm.convertedFromDate = DateUtils.convertLocalDateToServer(vm.fromDate);
		    	vm.convertedToDate = DateUtils.convertLocalDateToServer(vm.toDate);
		    	
		    	/*var from=$filter('date')(vm.fromDate, "yyyy-MM-dd");
	        	var to=$filter('date')(vm.toDate, "yyyy-MM-dd");*/
			
			MemberBanksFeeAndChargesViewService.getMemberBankHistoryViewDto({'fromDate':vm.convertedFromDate,'toDate':vm.convertedToDate},onRetreiveSuccess,onRetreiveFailure);
			vm.goClick = false;
				function onRetreiveSuccess(result) {
					console.log(result);
					vm.clicked=false;
					vm.banksFeeChargeDTO = result;
					vm.goClick = true;
					console.log(vm.banksFeeChargeDTO);

				}
				function onRetreiveFailure(error){
					console.log(error);
					vm.clicked=false;
				}
	   		}
			
		}
		
		
		$scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
	



		function clear () {
			$uibModalInstance.dismiss('cancel');
		}
		

	
	}
})();