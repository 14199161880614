(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CreditFactorSettingsDialogController', CreditFactorSettingsDialogController);

    CreditFactorSettingsDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',
    	'workflowSharedService','$uibModalInstance','CreditFactorSettings','entity','DateUtils',
        'Workflow', 'AlertService', '$filter', 'DealingAdminApplicationWideSettings'];

     function CreditFactorSettingsDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, 
    		 $uibModalInstance,CreditFactorSettings,entity,DateUtils,
             Workflow, AlertService, $filter, DealingAdminApplicationWideSettings) {
        var vm = this;
        vm.creditFactorSettingsHeader=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitCreditFactorSettings = resubmitCreditFactorSettings;
        vm.globalValues = GlobalValues;
        vm.discardCreditFactorSettings = discardCreditFactorSettings;
        vm.rejectCreditFactorSettings= rejectCreditFactorSettings;
        vm.approveCreditFactorSettings= approveCreditFactorSettings;
        vm.viewMode=false;
        vm.showEffectiveFrom=false;
        vm.addCreditFactorSettings = addCreditFactorSettings;
        vm.editCreditFactorSettings = editCreditFactorSettings;
        vm.removeCreditFactorSettings = removeCreditFactorSettings;
        vm.resetCreditFactorSettings = resetCreditFactorSettings;
        vm.noOfDecimals = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForPercent = 2;
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        vm.creditFactorSettingsDetailsList = [];
        vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.newCreditFactor = { tenorFrom : 1, tenorTo : null, creditFactor : null, editable : true, valid: false}

        loadTenorType();
        loadConfigurationSettings();
        
        function loadTenorType(){
            vm.tenorTypeList = ['Days', 'Months'];
        }

        function loadConfigurationSettings(){
             //load configuration settings to validate maximum tenor value 
             //get confing settings - no. of active days for standard tenor
            DealingAdminApplicationWideSettings.getConfigValueForConfigName({configName: 'NoOfActiveDaysForStandardTenor'}, function(result){
                vm.noOfActiveDaysForStandardTenor = result.value;
            })

            //get confing settings - no. of active months for standard tenor
            DealingAdminApplicationWideSettings.getConfigValueForConfigName({configName: 'NoOfActiveMonthsForStandardTenor'}, function(result){
                vm.noOfActiveMonthsForStandardTenor = result.value;
            })
            
        }

        if(workflowSharedService.viewFrom == 'creditFactorSettings'){
    		vm.creditFactorSettingsDetailsList.push(angular.copy(vm.newCreditFactor));
			if (entity != null && entity != undefined && entity.creditFactorSettingsDetailsList != null && entity.creditFactorSettingsDetailsList != undefined
					&& entity.creditFactorSettingsDetailsList.length > 0) {
				vm.creditFactorSettingsHeader = entity;
				setValidForExistingCreditFactorSlabs();
				prepareNewCreditFactorSettings(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
				vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList.push(angular.copy(vm.newCreditFactor));
				vm.creditFactorSettingsDetailsList = angular.copy(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
			}
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        	$scope.DisabledControl = false;
        }
        else if(workflowSharedService.viewFrom == 'creditFactorSettingstodolist_resubmit') {
        	vm.creditFactorSettingsHeader = angular.copy( angular.fromJson($rootScope.payload));
        	setValidForExistingCreditFactorSlabs();
        	prepareNewCreditFactorSettings(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
        	vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList.push(angular.copy(vm.newCreditFactor));
        	vm.creditFactorSettingsDetailsList = angular.copy(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
        	
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        	$scope.DisabledControl = false;
        }
        else if(workflowSharedService.viewFrom == 'creditFactorSettings_todolist_checker') {
        	vm.creditFactorSettingsHeader =  angular.fromJson($rootScope.payload); 
        	vm.creditFactorSettingsDetailsList = angular.copy(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	$scope.DisabledControl = true;
        }
        else if(workflowSharedService.viewFrom == 'creditFactorSettings_todolist_maker') {
        	vm.creditFactorSettingsHeader =  angular.fromJson($rootScope.payload); 
        	vm.creditFactorSettingsDetailsList = angular.copy(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        	$scope.DisabledControl = true;
        }
        else if(workflowSharedService.viewFrom=='creditFactorSettings_auditlog'){
        	vm.creditFactorSettingsHeader =  angular.fromJson($rootScope.payload); 
        	vm.creditFactorSettingsDetailsList = angular.copy(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList);
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	$scope.DisabledControl = true;

        }
        
        //prepare new credit factor slab to enter. getting 'tenor to' from the last slab and assigned it to 'tenor from' of new slab
		function prepareNewCreditFactorSettings(creditFactorSettingsDetailsList){
			if(creditFactorSettingsDetailsList.length>0){
				var lastSlab = creditFactorSettingsDetailsList[creditFactorSettingsDetailsList.length-1];
				if(lastSlab!=null && lastSlab!=undefined){
					vm.newCreditFactor.tenorFrom = Number(lastSlab.tenorTo) + Number(1);
				}
			}
		}
		
		function setValidForExistingCreditFactorSlabs(){
			angular.forEach(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList, function(value,index){
				value.valid =true;
			})
		}
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	vm.creditFactorSettingsDetailsList = getValidObject(vm.creditFactorSettingsDetailsList);
            var errorMsgForMaxTenorValidation = validateMaximumTenorValue(vm.creditFactorSettingsDetailsList)
        	if($scope.errorMsg!=''){
   				AlertService.error($scope.errorMsg);
        	}else if(errorMsgForMaxTenorValidation!=''){
                AlertService.error(errorMsgForMaxTenorValidation);
            }else{
	        	vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList = vm.creditFactorSettingsDetailsList;
	        	vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList = $filter('orderBy')(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList, 'minimumTradedValue');
	        	if (vm.creditFactorSettingsHeader.id != null) {
	        		CreditFactorSettings.update({
						"orgId" : GlobalValues.getOrgId()
					}, vm.creditFactorSettingsHeader, onSaveSuccess, onSaveError);
				} else {
					CreditFactorSettings.save({
						"orgId" : GlobalValues.getOrgId()
					}, vm.creditFactorSettingsHeader, onSaveSuccess, onSaveError);
				}
        	}
         }
      
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
      
        function onSaveError () {
        	prepareNewCreditFactorSettings(vm.creditFactorSettingsDetailsList);
			vm.creditFactorSettingsDetailsList.push(angular.copy(vm.newCreditFactor));
        	vm.clicked = false;
        }

        function resubmitCreditFactorSettings() {
        	vm.creditFactorSettingsDetailsList = getValidObject(vm.creditFactorSettingsDetailsList);
        	vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList = vm.creditFactorSettingsDetailsList;
            var errorMsgForMaxTenorValidation = validateMaximumTenorValue(vm.creditFactorSettingsDetailsList)
            if($scope.errorMsg!=''){
                AlertService.error($scope.errorMsg);
            }else if(errorMsgForMaxTenorValidation!=''){
                AlertService.error(errorMsgForMaxTenorValidation);
            }else{
                CreditFactorSettings.resubmit({"taskId" : $rootScope.taskID}, vm.creditFactorSettingsHeader, onResubmitSuccess, onResubmitError);
                $uibModalInstance.dismiss('cancel');
            }
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        

        function onResubmitError(error) {
        	prepareNewCreditFactorSettings(vm.creditFactorSettingsDetailsList);
			vm.creditFactorSettingsDetailsList.push(angular.copy(vm.newCreditFactor));
        	vm.clicked = false;
        }
        
        function approveCreditFactorSettings() { 
        	CreditFactorSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        

        function onApproveError(error) {
        	
        }
       
        function rejectCreditFactorSettings() { Workflow.rejectTask($rootScope.taskID); }

        function discardCreditFactorSettings() {
        	CreditFactorSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        

        function onDiscardError(error) {
        	
        }
        
        function addCreditFactorSettings(index){
        	$scope.errorMessage = '';
        	
        	$scope.errorMessage = validateList(index);
            if($scope.errorMessage == ''){
                vm.creditFactorSettingsDetailsList[index].editable = false;
                vm.creditFactorSettingsDetailsList[index].valid = true;
                vm.creditFactorSettingsDetailsList[index].tenorTo = (Math.round(parseFloat(vm.creditFactorSettingsDetailsList[index].tenorTo) * 10000)/10000)
                vm.creditFactorSettingsDetailsList[index].tenorFrom = (Math.round(parseFloat(vm.creditFactorSettingsDetailsList[index].tenorFrom) * 10000)/10000)
                if(vm.creditFactorSettingsDetailsList[index+ 1] != null && vm.creditFactorSettingsDetailsList[index+ 1] != undefined){
                    vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = Number(vm.creditFactorSettingsDetailsList[index].tenorTo) + Number(1);
                }
                prepareNewCreditFactorSettings(vm.creditFactorSettingsDetailsList);
                vm.creditFactorSettingsDetailsList.push(angular.copy(vm.newCreditFactor));
            }
            else{
                vm.creditFactorSettingsDetailsList[index].valid = false;
               // $scope.errorMessage = $filter('translate')($scope.errorMessage);
            }
        	
        }
        
        function editCreditFactorSettings(editIndex){

            //clear error message in current row before editing the selected row
            $scope.errorMessage = '';
        	
        	angular.forEach(vm.creditFactorSettingsDetailsList, function(value, index){
        		
        		if(angular.equals(value, vm.newCreditFactor) && vm.creditFactorSettingsDetailsList.length>1){
        			vm.creditFactorSettingsDetailsList.splice(index, 1);
        		}
        		
        		if(!value.valid && vm.creditFactorSettingsDetailsList.length>1){
        			vm.creditFactorSettingsDetailsList.splice(index, 1);
        		}
        		
        		value.editable = false;
        		
        		//if(index>0 && index == vm.creditFactorSettingsDetailsList.length -1){
        			
        			//vm.creditFactorSettingsDetailsList.splice(index,1);
        			
        		//}
        		
        	})
        	
        	if(vm.creditFactorSettingsDetailsList[editIndex] != null && vm.creditFactorSettingsDetailsList[editIndex] != undefined)
        		vm.creditFactorSettingsDetailsList[editIndex].editable = true;
        }
        
        function removeCreditFactorSettings(index){
        	if(vm.creditFactorSettingsDetailsList[index+ 1] != null && vm.creditFactorSettingsDetailsList[index+ 1] != undefined){
        		if(index==0){
        			vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = Number('1');
        		}else{
        			vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = Number(vm.creditFactorSettingsDetailsList[index-1].tenorTo) + Number(1);
        		}
			}
        	vm.creditFactorSettingsDetailsList.splice(index, 1);
        }
        
        function resetCreditFactorSettings(index){
        	var maxValueCtrl = "txtTenorTo_" + index;
        	var chargesCtrl = "txtCreditFactor_" + index;
        	document.getElementById(maxValueCtrl).value = "";
        	vm.creditFactorSettingsDetailsList[index].tenorTo = null;
        	document.getElementById(chargesCtrl).value = "";
        	vm.creditFactorSettingsDetailsList[index].creditFactor = null;
        	vm.creditFactorSettingsDetailsList[index].valid = false;

            //reset error message
            $scope.errorMessage = '';
        }
        
        
        function getValidObject(creditFactorSettingsDetailsList) {
        	$scope.errorMsg = '';
        	angular.forEach(creditFactorSettingsDetailsList, function(value,index) {
				if (angular.equals(value, vm.newCreditFactor)) {
					creditFactorSettingsDetailsList.splice(index, 1);

				} else if (value.editable == true) {
					if(vm.creditFactorSettingsDetailsList[index].tenorTo!=null && vm.creditFactorSettingsDetailsList[index].creditFactor!=null){
						$scope.errorMsg = $filter('translate')('gatewayApp.creditFactorSettings.incompleteSlab');
						vm.clicked = false;
					}else{
						if(vm.creditFactorSettingsDetailsList[index+ 1] != null && vm.creditFactorSettingsDetailsList[index+ 1] != undefined){
	                		if(index==0){
	                			vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = Number('1');
	                		}else{
	                			vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = Number(vm.creditFactorSettingsDetailsList[index-1].tenorTo) + Number(1);
	                		}
	        			}
						creditFactorSettingsDetailsList.splice(index, 1);
					}
				}

			});

			vm.creditFactorSettingsDetailsList = creditFactorSettingsDetailsList;

			return vm.creditFactorSettingsDetailsList;
		}

        function validateMaximumTenorValue(creditFactorSettingsDetailsList){
            var errorMessage = '';
            angular.forEach(creditFactorSettingsDetailsList, function(value, index){
                if(value!=null && value!=undefined && value.tenorTo!=null && value!=undefined){
                    if(vm.creditFactorSettingsHeader.tenorType=='Days'){
                        if(parseInt(value.tenorTo)>parseInt(vm.noOfActiveDaysForStandardTenor)){
                            errorMessage = 'Row no. ' + (index + 1) + ' - ' + $filter('translate')('gatewayApp.creditFactorSettings.tenorToGreaterThanMaxTenor') + vm.noOfActiveDaysForStandardTenor.toString() + ' days';
                            vm.clicked = false;
                        }
                    }else{
                        if(parseInt(value.tenorTo)>parseInt(vm.noOfActiveMonthsForStandardTenor)){
                            errorMessage = 'Row no. ' + (index + 1) + ' - ' + $filter('translate')('gatewayApp.creditFactorSettings.tenorToGreaterThanMaxTenor') + vm.noOfActiveMonthsForStandardTenor.toString() + ' months';
                            vm.clicked = false;
                        }
                    }
                    if(errorMessage==''){
                        if(parseInt(value.tenorFrom)==parseInt(value.tenorTo)){
                            errorMessage = 'Row no. ' + (index + 1) + ' - ' + $filter('translate')('gatewayApp.creditFactorSettings.maxLessThanMin');
                            vm.clicked = false;
                        }
                    }
                }
            })
            return errorMessage;
        }

        
        function validateList(index){
            
            if(Number(vm.creditFactorSettingsDetailsList[index].tenorFrom) >= Number(vm.creditFactorSettingsDetailsList[index].tenorTo) 
                    || vm.creditFactorSettingsDetailsList[index].tenorTo=='' || vm.creditFactorSettingsDetailsList[index].tenorTo==undefined){
                
                return $filter('translate')('gatewayApp.creditFactorSettings.maxLessThanMin');
            }
            
            else if(vm.creditFactorSettingsDetailsList[index-1] != null && vm.creditFactorSettingsDetailsList[index-1] != undefined
                    && Number(vm.creditFactorSettingsDetailsList[index].tenorFrom)
                    <= Number(vm.creditFactorSettingsDetailsList[index-1].tenorTo)){
                
                return $filter('translate')('gatewayApp.creditFactorSettings.greaterMin');
            }
            
            else if(vm.creditFactorSettingsDetailsList[index+ 1] != null && vm.creditFactorSettingsDetailsList[index+ 1] != undefined
                    && Number(vm.creditFactorSettingsDetailsList[index].tenorTo)
                    >= Number(vm.creditFactorSettingsDetailsList[index+ 1].tenorTo)){

                return $filter('translate')('gatewayApp.creditFactorSettings.greaterMax');
            }
            else if(vm.creditFactorSettingsDetailsList[index+ 1] != null && vm.creditFactorSettingsDetailsList[index+ 1] != undefined
                    && Number(vm.creditFactorSettingsDetailsList[index].tenorTo)
                    >= Number(vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom)){

                vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = vm.creditFactorSettingsDetailsList[index].tenorTo;
            }
            else if(vm.creditFactorSettingsDetailsList[index+ 1] != null && vm.creditFactorSettingsDetailsList[index+ 1] != undefined){
                vm.creditFactorSettingsDetailsList[index+ 1].tenorFrom = vm.creditFactorSettingsDetailsList[index].tenorTo;
            }

            if(vm.creditFactorSettingsHeader.tenorType=='Days'){
                if(parseInt(vm.creditFactorSettingsDetailsList[index].tenorTo)>parseInt(vm.noOfActiveDaysForStandardTenor)){
                    return $filter('translate')('gatewayApp.creditFactorSettings.tenorToGreaterThanMaxTenor') + vm.noOfActiveDaysForStandardTenor.toString() + ' days';
                }
            }else{
                if(parseInt(vm.creditFactorSettingsDetailsList[index].tenorTo)>parseInt(vm.noOfActiveMonthsForStandardTenor)){
                    return $filter('translate')('gatewayApp.creditFactorSettings.tenorToGreaterThanMaxTenor') + vm.noOfActiveMonthsForStandardTenor.toString() + ' months';
                }
            }

            return '';
        }
       
     }   
})();
