(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('manage-limits', {
            parent: 'entity',
            url: '/limits',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.riskManagement.limitSettings" | translate }}',
                parent: 'home'
              },
            views: {
                'content@': {
                    templateUrl: 'app/entities/manage-limit/manage-limit.html',
                    controller: 'LimitsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('manage-limits.edit', {
            parent: 'manage-limits',
            url: '/limits/edit/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: ['LimitSettings', 'GlobalValues', function(LimitSettings, GlobalValues) {
                   		 	
                            return LimitSettings.get({id : $stateParams.id}).$promise;
                        }]
              
           }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('manage-limits.reset', {
            parent: 'manage-limits',
            url: '/limits/reset/{id}',
            params: {resetInstrumentGroup: null},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: ['LimitSettings', 'GlobalValues', function(LimitSettings, GlobalValues) {
                            
                            return LimitSettings.get({id : $stateParams.id}).$promise;
                     }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('manage-limits.new', {
            parent: 'manage-limits',
            url: '/limits/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {                       	
                            
                          }
                      }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('manage-limits.view', {
            parent: 'manage-limits',
            url: '/limits/view/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                     	
                      	 entity: ['LimitSettings', 'GlobalValues', function(LimitSettings, GlobalValues) {
                               return LimitSettings.get({id : $stateParams.id}).$promise;
                           }]
                      }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('limit-settings-todolist', {
            parent: 'dashboard',
            url: '/limit-settings-todolist',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {                       	
                            
                          }
                      }
                }).result.then(function() {
                    $state.go('dashboard', {}, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('limit-settings-todolist.resubmit', {
            parent: 'dashboard',
            url: '/limit-settings-todolist/resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {                       	
                            
                          }
                      }
                }).result.then(function() {
                    $state.go('dashboard', {}, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer_limit_Settings', {
            parent: 'home',
            url: '/customer-limit-change-request',
            params : {customerId: null, bankId: null, limitInstrumentGroup: null},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                   	 entity: ['LimitSettings', function(LimitSettings) {
                        return LimitSettings.getLimitForCustomer({customerId : $stateParams.customerId , bankId :$stateParams.bankId, limitInstrumentGroup: $stateParams.limitInstrumentGroup}).$promise;
                     }]
                    }
                }).result.then(function() {
                    $state.go('home', {}, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer-limit-settings-todolist', {
            parent: 'dashboard',
            url: '/customer-limits-todoList',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('dashboard', {}, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer-limit-settings-todolist.resubmit', {
            parent: 'dashboard',
            url: '/customer-limit-settings-todolist/resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {                       	
                            
                          }
                      }
                }).result.then(function() {
                    $state.go('dashboard', {}, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('old-limit-settings-auditLog', {
            parent: 'audit-log',
            url: '/old-limit-settings-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog.html',
                    controller: 'LimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('limit-settings-auditLog', {
            parent: 'audit-log',
            url: '/limit-settings-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('old-customer-limit-settings-auditLog', {
            parent: 'audit-log',
            url: '/old-customer-limit-settings-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog.html',
                    controller: 'LimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('home', {}, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer-limit-settings-auditLog', {
            parent: 'audit-log',
            url: '/customer-limit-settings-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('home', {}, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('old-limit-settings-historyReport', {
            parent: 'history-report',
            url: '/old-limit-settings-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog.html',
                    controller: 'LimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('limit-settings-historyReport', {
            parent: 'history-report',
            url: '/limit-settings-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-limits', {}, { reload: 'manage-limits' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('old-customer-limit-settings-historyReport', {
            parent: 'history-report',
            url: '/old-customer-limit-settings-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog.html',
                    controller: 'LimitsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('home', {}, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('customer-limit-settings-historyReport', {
            parent: 'history-report',
            url: '/customer-limit-settings-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('home', {}, { reload: 'home' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('create-customer-limit-settings', {
            parent: 'home',
            params:{screen:'CustomerLimitChangeRequest'},
            url: 'create-customer-limits',
            data: {
                authorities: ['ROLE_USER']
            },
           
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/customer-new-limit.html',
                    controller: 'CustomerNewLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                    	 entity: function() {
                         }
              
           }
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                	$state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('create-customer-limit-settings-todolist', {
            parent: 'dashboard',
            url: '/create-customer-limits-todoList',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/manage-limit/customer-new-limit.html',
                    controller: 'CustomerNewLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: function() {
                        }
              
                    }
                }).result.then(function() {
                    $state.go('dashboard', null, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('create-customer-limit-settings-todolist.resubmit', {
            parent: 'dashboard',
            url: '/create-customer-limit-settings-todolist/resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/customer-new-limit.html',
                    controller: 'CustomerNewLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {                       	
                            
                          }
                      }
                }).result.then(function() {
                    $state.go('dashboard', null, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('create-customer-limit-settings-todolist-checker', {
            parent: 'dashboard',
            url: '/create-customer-limit-settings-checker',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/limit-dialog-new.html',
                    controller: 'LimitsDialogNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('dashboard', {}, { reload: 'dashboard' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('create-customer-limit-settings-limit-report', {
            parent: 'home',
            url: '/create-customer-limits-new/bankId/{bankId}/instrument/{instrumentCode}',
            data: {
                authorities: ['ROLE_USER']
            },
           
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/customer-new-limit.html',
                    controller: 'CustomerNewLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                    	 entity: function() {
                         }
              
           }
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                	$state.go('home', null, {reload:false});
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('old-create-customer-limit-settings-auditLog', {
            parent: 'audit-log',
            url: '/old-create-customer-limit-settings-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/manage-limit/customer-new-limit-dialog.html',
                	controller: 'CustomerCreateLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('audit-log', {}, { reload: 'audit-log' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
         .state('create-customer-limit-settings-auditLog', {
            parent: 'audit-log',
            url: '/create-customer-limit-settings-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/customer-new-limit.html',
                    controller: 'CustomerNewLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('audit-log', {}, { reload: 'audit-log' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('old-create-customer-limit-settings-historyReport', {
            parent: 'history-report',
            url: '/old-create-limit-settings-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/manage-limit/customer-new-limit-dialog.html',
                    controller: 'CustomerCreateLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('history-report', {}, { reload: 'history-report' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('create-customer-limit-settings-historyReport', {
            parent: 'history-report',
            url: '/create-limit-settings-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-limit/customer-new-limit.html',
                    controller: 'CustomerNewLimitController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        
                     entity: [function() {
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('history-report', {}, { reload: 'history-report' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
    }
})();