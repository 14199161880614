(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
         .state('manage-Entity-Restrictions', {
            parent: 'home',
            params:{screen: 'EntityRestriction'},
            url: 'Entity_Restrictions/Change_Restrictions',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	 templateUrl: 'app/entities/manage-entity-restrictions/manage-entity-restrictions.html',
                     controller: 'manageEntityRestrictionsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        restrictionReasons: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getAllRestrictionReaons().$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('entityRestrictions');
                            return $translate.refresh();
                        }]
                       
                    }
                }).result.then(function() {
                    $state.go('home', {}, { reload: 'manage-Entity' });
                }, function() {
                    $state.go('home');
                });
            }]
        })
        
        .state('manage-Entity', {
            parent: 'entity',
            url: '/Entity_Restrictions',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.security.manageglobalapprovalpolicy" | translate }}',
                parent: 'home'
              },
            views: {
                'content@': {
                	templateUrl: 'app/entities/manage-entity-restrictions/manage-entity-restrictions.html',
                    controller: 'manageEntityRestrictionsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                restrictionReasons: ['StaticLookUp', function(StaticLookUp) {
                    return StaticLookUp.getAllRestrictionReaons().$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('entityRestrictions');
                    return $translate.refresh();
                }]
               
            }
        })
        
         .state('manage-Entity-Restrictions-todolist', {
            parent: 'dashboard',
            url: '/Entity_Restrictions/Change_Restrictions',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/manage-entity-restrictions/manage-entity-restrictions.html',
                    controller: 'manageEntityRestrictionsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        restrictionReasons: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getAllRestrictionReaons().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('manage-Entity-Restrictions', null, { reload: 'manage-Entity-Restrictions' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('manage-Entity-Restrictions-todolist.resubmit', {
            parent: 'dashboard',
            url: '/Entity_Restrictions',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/manage-entity-restrictions/manage-entity-restrictions.html',
                    controller: 'manageEntityRestrictionsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        },
                        restrictionReasons: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getAllRestrictionReaons().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('manage-Entity-Restrictions', null, { reload: 'manage-Entity-Restrictions' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('manage-Entity-Restrictions-auditLog', {
            parent: 'audit-log',
            url: '/manage-Entity-Restrictions-auditLog',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-entity-restrictions/manage-entity-restrictions.html',
                    controller: 'manageEntityRestrictionsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }],
                        restrictionReasons: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getAllRestrictionReaons().$promise;
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-Entity', {}, { reload: 'manage-Entity' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('manage-Entity-Restrictions-historyReport', {
            parent: 'history-report',
            url: '/manage-Entity-Restrictions-historyReport',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/manage-entity-restrictions/manage-entity-restrictions.html',
                    controller: 'manageEntityRestrictionsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                   	 entity: [function() {
                        }],
                        restrictionReasons: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getAllRestrictionReaons().$promise;
                        }]
              
                    }
                }).result.then(function() {
                    $state.go('manage-Entity', {}, { reload: 'manage-Entity' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('limitSettings');
                    return $translate.refresh();
                }]
               
            }
        })
    }
    
    
})();