(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HomeBranchFilterController', HomeBranchFilterController);

    HomeBranchFilterController.$inject = ['$scope', '$state',  '$uibModalInstance', '$rootScope', '$stateParams'];

    function HomeBranchFilterController($scope, $state,$uibModalInstance, $rootScope, $stateParams) {    
        var vm = this;
        vm.filterText = $stateParams.filterText;
        vm.clear=clear;
     
        function clear() {
			$uibModalInstance.dismiss('cancel');
		}
        
        $scope.$watch('vm.branch', function(value){
        	if(value!=null && value!='' && value!= undefined){
        		$rootScope.branch=value;
        		$scope.$emit('searchBranch'); 
        		$uibModalInstance.dismiss('cancel');
        	}
        })
     }
})();