(function() {
	'use strict';
	angular.module('gatewayApp').factory('HolidayReport',
			HolidayReport);

	HolidayReport.$inject = [ '$resource' ];

	function HolidayReport($resource) {
		var resourceUrl = 'api/holiday-report/:id';

		return $resource(resourceUrl, {}, {

			'getAllTheYears' : {
				method : 'GET',
				url : 'api/get-all-years',
				 isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
			},
			'getHolidayListAfterGivenDate' : {
				method : 'GET',
				url : 'api/holiday-list-after-given-date',
				 isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
			},
			'getSpecificYearData':{
				isArray: true,
			    method: 'GET',
                url: 'api/get-specific-year-data',
                params : {
                	givenYear : '@givenYear',
					currencyCode : '@currencyCode',
					holidayType :'holidayType'
				},
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 }
			},
			'getCurrentYearData':{
			    method: 'GET',
			    isArray: true,
                url: 'api/get-current-year-data',
                params : {
					currencyCode : '@currencyCode',
					holidayType :'@holidayType'
				},
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 }
			},
			 'excelHolidayReport':{
				    method: 'POST',
	                url: 'api/history-report/excel',
	                responseType: 'arraybuffer',
	                  transformRequest: function (data) {
	                     	var copy = angular.copy(data);
	                     	
	                         return angular.toJson(copy);
	                     },
	                    transformResponse: function(data, headers) {
	                  	  return {
	                            data: data,
	                            headers: headers
	                        };   
	                    }  
				},
				 'csvHolidayReport':{
					    method: 'POST',
		                url: 'api/history-report/csv',
		                responseType: 'arraybuffer',
		                  transformRequest: function (data) {
		                     	var copy = angular.copy(data);
		                     	
		                         return angular.toJson(copy);
		                     },
		                    transformResponse: function(data, headers) {
		                  	  return {
		                            data: data,
		                            headers: headers
		                        };   
		                    }  
					}

		});
	}
})();
