(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('MarketDepthExpandController', MarketDepthExpandController);

    MarketDepthExpandController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', '$stateParams', 'GlobalValues'];

    function MarketDepthExpandController($scope, $rootScope, $state, $uibModalInstance, $stateParams, GlobalValues) {
        var vm = this;
        
        vm.clear=clear;
        vm.globalValues = GlobalValues;

        vm.instrument=$stateParams.instrument;
        vm.marketDepth=$stateParams.marketDepth;
        vm.ibMarketDepth=$stateParams.ibMarketDepth;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
    }   
})();
