(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TACViewForEntitiesDialogController',TACViewForEntitiesDialogController);

    TACViewForEntitiesDialogController.$inject = [ '$uibModalInstance',  'GlobalValues','$scope', 'Workflow','entity','entityConstants','$state','$rootScope','ScreenLog','workflowSharedService','TACViewForEntitiesService','DateUtils','TacViewForEntitiesUtility'];

    function TACViewForEntitiesDialogController( $uibModalInstance, GlobalValues,$scope, Workflow,entity, entityConstants, $state, $rootScope,ScreenLog,workflowSharedService,TACViewForEntitiesService,DateUtils,TacViewForEntitiesUtility) {
        
        var vm = this;
        vm.entityConstants = entityConstants;
        //TODO -change shortname
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TERMS_AND_CONDITIONS_VIEW ;      
        vm.screenLog = ScreenLog;
        
        vm.clear=clear;
        vm.clicked=false;
              
        //vm.currentDate = new Date();
        vm.globalValues = GlobalValues;
        //vm.currentDate = vm.globalValues.getSystemSettings().businessDate;       
       
        $scope.showResubmitButton = false;
        $scope.showApproveRejectButton = false;
        $scope.showDiscardButton = false;
        $scope.showOk = false;
        
        vm.resubmit = resubmit;
        vm.approve = approve;
        vm.reject = reject;
        vm.discard = discard;
        vm.preview = preview;
        console.log($rootScope.payload);
        loadContentFromPayload();
        
        if(workflowSharedService.viewFrom == 'entited_tac_view_checker') {
        	
        	$scope.showResubmitButton = false;
        	$scope.showApproveRejectButton=true;
        	$scope.showDiscardButton = false;
        	
        }
        else if(workflowSharedService.viewFrom == 'entited_tac_view_maker') {
        	        	
        	$scope.showResubmitButton = false;
        	$scope.showApproveRejectButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom == 'entited_tac_view_resubmit') {
        	
        	
        	vm.screenLog.screenLog(vm.screenName, 0, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	        	    	
        	$scope.showResubmitButton = true;
        	$scope.showApproveRejectButton=false;
        	$scope.showDiscardButton = false;
        }
       else if(workflowSharedService.viewFrom == 'entited_tac_view_auditLog') {    		   	 
        	
        	$scope.showResubmitButton = false;
        	$scope.showApproveRejectButton=false;
        	$scope.showDiscardButton = false;
        	$scope.showOk = true;
        }
        
       function loadContentFromPayload(){
        	
        	vm.tacAgreedEntityDetails = angular.fromJson(angular.copy($rootScope.payload));        	                                      
        	vm.latestRevisionDateTime = DateUtils.convertLocalDateFromServerTo12HrsString(
        			angular.copy(vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.createdDate));
        	vm.enforcingDate =  DateUtils.convertLocalDateFromServer(angular.copy(vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.enforcingDate));
        	vm.tacType = vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.tacType.id;
        	vm.fileContent = vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.tacFiles.fileContent;
        	if(vm.tacType == 1){
        		 vm.isTACForCCIL= true;
        		 vm.showClearcorpTACHelpText= true;
        	}else if( vm.tacType == 2){
        	 	vm.isTACForCCIL= true;
        		vm.showClearcorpTACHelpText= false;
        	}else if(vm.tacType == 3){
        		vm.isTACForCCIL= false;
        		vm.showClearcorpTACHelpText= false;
        	}
        }
        
  
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
       
		
        function resubmit() {
        	//this resubmit has nothing to do, they can have a preview
        	
        	vm.screenLog.screenLog(vm.screenName, vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			
        	TACViewForEntitiesService.resubmit({"taskId" : $rootScope.taskID}, vm.tacAgreedEntityDetails, onResubmitSuccess, onResubmitError);
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function reject() { Workflow.rejectTask($rootScope.taskID); }

        function discard() {
        	TACViewForEntitiesService.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

        function approve() {  
        	TACViewForEntitiesService.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
        

        function preview(){
        	//TODO - check for filename 
        	//vm.screenLog.screenLog(vm.screenName,vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.id,vm.entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS)
       		var file = vm.tacAgreedEntityDetails.tacAgreedEntities.tacMaster.tacFiles;
       		TacViewForEntitiesUtility.previewTAC(file.fileContent, file.fileName);
       		
        }
        
    }
})();
