(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PreOrderConfirmationController', PreOrderConfirmationController);

    PreOrderConfirmationController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'Market', '$stateParams', 
		'MessageBox', 'User', 'GlobalValues', 'Idle', '$timeout','systemTime','OrderUtil','$state','workflowSharedService'];

    function PreOrderConfirmationController($scope, $rootScope, $uibModalInstance, Market, $stateParams, 
		MessageBox, User, GlobalValues, Idle, $timeout,systemTime,OrderUtil,$state,workflowSharedService) {
        var vm = this;
        vm.password=null;
        vm.allowedPasswordCharacters=/^[!@#^&*()\-_.:;a-zA-Z0-9]/;
        vm.clear=clear;
        vm.submit=submit;
        vm.add = add;
        vm.checkPassword = checkPassword;
        $scope.clicked=false;
        vm.orderDTO = $rootScope.orderDTO;
        vm.showRollOverOrEarlyDelivery = false;
        if(vm.orderDTO.activityIndicator == 'ROLL' || vm.orderDTO.activityIndicator == 'EARL' ){
        	vm.showRollOverOrEarlyDelivery = true;
        }

        vm.eventTypeName = getEventTypeForShortName(vm.orderDTO.eventType);
        if(vm.eventTypeName==null || vm.eventTypeName==undefined || vm.eventTypeName==''){
            vm.eventTypeName = vm.orderDTO.eventType;
        }
        /*if(vm.orderDTO.typeOfOrder=='MRKT' && vm.orderDTO.activityIndicator=='PLAC'){
			vm.orderDTO.sellLimitExchangeRate=0;
        	vm.orderDTO.buyLimitExchangeRate=0;
		}*/
        vm.showGTT = false;
        vm.showPreOrderConfirmation = true;
        vm.errorMessage = '';
        vm.errorString = '';
        vm.globalValues = GlobalValues;
        vm.submitAction = submitAction;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.serverDateUTCOffset;
        vm.serverDateTimeZone = systemTime.timeZone;
		vm.showOrderConfirmationForSplitOrder=$stateParams.showOrderConfirmationForSplitOrder;
		vm.noOfSplits=$stateParams.noOfSplit;
		vm.orderDTOList=[];
        
        function clear () {
            $rootScope.orderPlacedSuccessCount = 0;
            $scope.$emit('orderplacedsuccess');
            $uibModalInstance.close();
        }
        
        vm.showPasswordWindow = $stateParams.sessionTimedOut;
        vm.cancelSelected = $stateParams.cancelSelected;
        vm.selectedOrders = $rootScope.selectedPendingOrders;
        if(!vm.cancelSelected){
        	if(vm.orderDTO.typeOfOrder=='MRKT' && vm.orderDTO.activityIndicator=='PLAC'){
    			vm.orderDTO.sellLimitExchangeRate=0;
            	vm.orderDTO.buyLimitExchangeRate=0;
    		}
        }
        
        if(!vm.cancelSelected){
            if(vm.orderDTO.timeLimitIndicator=='GTTM' && vm.orderDTO.activityIndicator!='CNCL'){
            	vm.showGTT = true;            	
            	vm.showPreOrderConfirmation = false;
            }else if(vm.orderDTO.timeLimitIndicator=='GTTM' && vm.orderDTO.activityIndicator=='CNCL'){
            	//fillDefaultTime();
            	Market.getCurrentDateTime(getTimeForOrderCancellation, getTimeFailure);
            	
            }
        }else{
        	//MessageBox.openMessageBox("Alert", "Do you want to proceed to cancel selected order(s)?", "ProceedCancel", "cancelselectedorders");
        }
        
        if(vm.showPasswordWindow){
        	vm.showGTT = false;
        	vm.showPreOrderConfirmation = false;
        	$timeout(function(){document.getElementById("verifyPasswordBtn").focus();}, 100);
        }
        
        vm.closeTime = $stateParams.closeTime;
        vm.showPreOrderConfirmationDisplay = $stateParams.showPreOrderConfirmation;
        if(vm.showGTT){
        	//fetch the server current time with time zone
        	Market.getCurrentDateTime(getTimeSuccess, getTimeFailure);
            //fillDefaultTime();
        }
        
        function fillDefaultTime(){
        	if(vm.orderDTO.activityIndicator=='MDFY' || vm.orderDTO.activityIndicator=='CNCL'){
        		vm.currentDate = moment(angular.copy(vm.orderDTO.expiryTimeString));
        		vm.serverDateUTCOffset =  vm.currentDate.utcOffset();
            	vm.expiryDateTime = addMinutes(vm.currentDate, 0);
        	}else{
        		//vm.currentDate = new Date();
        		vm.expiryDateTime = addMinutes(vm.currentDate, 1);
            	
        	}
        }
        
        function addMinutes(date, minutes) {
            //return new Date(date.getTime() + minutes*60000);
            return  moment(date).add(minutes , 'minutes');
        }
        
        $scope.$watch('vm.expiryDateTime', function(value){
        	if(value!=null && value!=undefined && value!=''){
            	vm.errorString = '';
            	return Market.getCurrentDateTime(getCurrentServerTimeAndValidate, getTimeFailure);
            }
        })
        
        function validateExpirtyTime(){
        	return Market.getCurrentDateTime(getCurrentServerTimeAndValidate, getTimeFailure);
        }
        
        function formatExpiryTime(){
        	var expiryTime;// = moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
        	if(vm.serverDateUTCOffset == moment().utcOffset()){
        		expiryTime = moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
         	}else{
         		expiryTime = moment(vm.expiryDateTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
         	}
        	var expiryTimeInString = "";
        	if(expiryTime.split(':').length>1){
            	expiryTimeInString = expiryTime.split(':')[0] + expiryTime.split(':')[1] + '00';
            	//var todayDateString = $filter('date')(new Date(),'yyyy-MM-dd');
            	var todayDateString = moment(vm.currentDate).utcOffset(vm.serverDateUTCOffset).format( 'YYYYMMDD');
            	//todayDateString = todayDateString.replace(/-/g, '');
            	expiryTimeInString = todayDateString + expiryTimeInString;
        	}
        	vm.expiryTime = expiryTimeInString;
        }
        
        function add(){
        	//validateExpiryTime();
        	if(validateExpirtyTime()){
        		if(vm.serverDateUTCOffset == moment().utcOffset()){
    				vm.expiryTimeString=moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
             	}else{
             		vm.expiryTimeString= moment(vm.expiryDateTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
             	}
            	formatExpiryTime();
            	vm.orderDTO.expiryTime = vm.expiryTime;
            	vm.orderDTO.expiryTimeString = vm.expiryTimeString;
            	if(vm.showPreOrderConfirmationDisplay=='yes'){
                	vm.showPreOrderConfirmation = true;
                	vm.showGTT = false;
                    $timeout(function(){$scope.clicked = false;}, 100);
                	$timeout(function(){document.getElementById("submitBtn").focus();}, 100);
                	
            	}else{
            		submit();
            	}
            	//$uibModalInstance.close();
            	//$scope.$emit('addExpiryTime');
        	}else{
        		//show error
        		//MessageBox.openMessageBox("Alert", vm.errorString, "Ok", "homepage");
        	}
        }
        
        function submitAction(preOrderConfirmation, addGTT, verifyUser){
        	var action = "";
        	if(preOrderConfirmation){
        		action='preOrderConfirmation';
        	}else if(addGTT){
        		action='addGTT';
        	}else if(verifyUser){
        		action='verifyUser';
        	}
        	
        	if(action=='preOrderConfirmation'){
        		submit();
        	}else if(action=='addGTT'){
        		add();
        	}else if(action=='verifyUser'){
        		checkPassword();
        	}
        }
        
        function submit(){
        	if(vm.cancelSelected!=true){
            	vm.errorMessage = '';
            	console.log(vm.orderDTO);
            	if(vm.orderDTO.buySellIndicator!='SELLBUY'){
		      if(OrderUtil.validateOrderDTOForNull(vm.orderDTO)){
                	if(vm.orderDTO.activityIndicator=='PLAC' || vm.orderDTO.activityIndicator=='TRAD' || vm.orderDTO.activityIndicator=='ROLL' || vm.orderDTO.activityIndicator=='EARL'){
                        vm.orderDTO.activityIndicator='PLAC';

						//check if split order then split the order amount 
				 		if(vm.orderDTO.isSplitOrder==true){
							splitTheOrder(vm.orderDTO,vm.noOfSplits);
							//if chech show split order is on in user profile 
							if(vm.showOrderConfirmationForSplitOrder=='yes'){
								if(vm.orderDTOList != null && vm.orderDTOList != undefined){
								
								$uibModalInstance.dismiss('cancel');
								workflowSharedService.viewFrom = 'SplitOrder';
									$state.go('split-order-confirmation',{orderDTOList:vm.orderDTOList}) .catch(function (error) {
										// if error occurs go to create state
										$state.go('split-order-confirmation',{orderDTOList:vm.orderDTOList});
									})
								}
							
						}else{
							//showOrderConfirmationForSplitOrder shoud not show
								if (vm.orderDTOList != null && vm.orderDTOList != undefined) {
				
									$uibModalInstance.dismiss('cancel');
									workflowSharedService.viewFrom = 'SplitOrder';
									$state.go('split-order-status',{orderDTOList:vm.orderDTOList}) .catch(function (error) {
										// if error occurs go to create state
										$state.go('split-order-status',{orderDTOList:vm.orderDTOList});
									})
				   
							   }
							
						}
					} else{
						//regular order
						Market.placeOrder(vm.orderDTO, onOrderSucess, onOrderFailure);
					}
                	}else if(vm.orderDTO.activityIndicator=='MDFY'){
                        Market.modifyOrder(vm.orderDTO, onOrderSucess, onOrderFailure);
            		}else if(vm.orderDTO.activityIndicator=='CNCL'){
                        Market.cancelOrder(vm.orderDTO, onOrderSucess, onOrderFailure);
            		}
		  }else{
			MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");
		  }
            	}else if(vm.orderDTO.buySellIndicator=='SELLBUY'){
            		if(vm.orderDTO.typeOfOrder=='MRKT'){
						vm.orderDTO.sellLimitExchangeRate=0;
						vm.orderDTO.buyLimitExchangeRate=0;
					}
					var sellOrderDTO = getSellOrderDTO();
					var buyOrderDTO = getBuyOrderDTO();
					if(OrderUtil.validateOrderDTOForNull(sellOrderDTO) && OrderUtil.validateOrderDTOForNull(buyOrderDTO)){
						Market.placeOrder(sellOrderDTO);
						Market.placeOrder(buyOrderDTO, onOrderSucess, onOrderFailure);
					}else{
						MessageBox.openMessageBox("Alert", "The order request is not processed, please submit again.", "Ok", "orderrequestfailure");
					}            	
		}
            	//onPlaceOrderSuccess();
        	}
        }

		$rootScope.$on('gatewayApp.messagebox.ok.orderrequestfailure', function (event, data) {
			$uibModalInstance.close();
		});

		$rootScope.$on('gatewayApp.messagebox.clear.orderrequestfailure', function (event, data) {
			$uibModalInstance.close();
		});

		function onOrderSucess(result){
            onPlaceOrderSuccess();
        }

        function onOrderFailure(){
            onPlaceOrderSuccess();
        }

        function onPlaceBuySuccess(result){
    		var sellOrderDTO = getSellOrderDTO();
    		Market.placeOrder(sellOrderDTO,  onPlaceOrderSuccess, onPlaceOrderFailure);
        }
        
        function onPlaceBuyFailure(){
        	
        }
        
        function getBuyOrderDTO(){
        	var orderDTO = angular.copy(vm.orderDTO);
        	orderDTO.buySellIndicator = 'BUYY';
        	orderDTO.limitExchangeRate = vm.orderDTO.buyLimitExchangeRate;
        	orderDTO.stopExchangeRate = vm.orderDTO.buyStopExchangeRate;
        	return orderDTO;
        }
        
        function getSellOrderDTO(){
        	var orderDTO = angular.copy(vm.orderDTO);
        	orderDTO.buySellIndicator = 'SELL';
        	orderDTO.limitExchangeRate = vm.orderDTO.sellLimitExchangeRate;
        	orderDTO.stopExchangeRate = vm.orderDTO.sellStopExchangeRate;
        	return orderDTO;
        }
        
        function onPlaceOrderSuccess(){
            $rootScope.orderPlacedSuccessCount = 0;
        	$scope.$emit('orderplacedsuccess');
        	//$uibModalInstance.close(result);
        	$uibModalInstance.close();
        }
        
        function onPlaceOrderFailure(result){
        	$scope.clicked = false;
        	if(result !=null && result != undefined){
        		if(result.data!=null && result.data!=undefined){
        			if(result.data=='Access Denied'){
        				//AlertService.error("Trading session is expired...! Re-login to continue trading.")
        				vm.errorMessage = 'Trading session is expired...! Re-login to continue trading.';
        			}
        		}
        	}
        }

        function checkPassword(){
     	   var userCredentials = {};
 			userCredentials.userId = vm.globalValues.getUserId();
 			userCredentials.password = vm.password;
 			User.checkPassword(userCredentials, onCheckPasswordSuccess, onCheckPasswordFailure);
        }
        
        function onCheckPasswordSuccess(result){
        	vm.globalValues.setSessionTimedOut(false);
        	Idle.watch();
        	if(vm.cancelSelected){
            	MessageBox.openMessageBox("Alert", "Do you want to proceed to cancel selected order(s)?", "ProceedCancel", "cancelselectedordersfrompreorder");
            }else if(vm.orderDTO.timeLimitIndicator=='GTTM' && vm.orderDTO.activityIndicator!='CNCL'){
            	vm.showGTT = true;
            	//fillDefaultTime();
            	Market.getCurrentDateTime(getTimeSuccess, getTimeFailure);
            	vm.showPreOrderConfirmation = false;
            	vm.showPasswordWindow = false;
                $scope.clicked = false;
            	$timeout(function(){document.getElementById("addGTTBtn").focus();}, 100);
            }else{
            	if(vm.showPreOrderConfirmationDisplay=='yes'){
                	vm.showPreOrderConfirmation = true;
                	vm.showGTT = false;
                	vm.showPasswordWindow = false;
                    $scope.clicked = false;
                	$timeout(function(){document.getElementById("submitBtn").focus();}, 100);
            	}else{
            		submit();
            	}
            }        	
        }
        
        function onCheckPasswordFailure(){
        	vm.showPasswordWindow = true;
        	vm.showGTT = false;
        	vm.showPreOrderConfirmation = false;
            $scope.clicked = false;
        	$timeout(function(){document.getElementById("verifyPasswordBtn").focus();}, 100);
        }
        
        var cancelselectedordersfrompreorder = $rootScope.$on('gatewayApp.messagebox.proceed.cancelselectedordersfrompreorder', function (event, data) {
        	// angular.forEach($rootScope.selectedPendingOrders, function(value, index){
        	// 	if(value.select){
        	// 		var orderDTO = {};
        	// 		orderDTO = MarketWatch.prepareOrderDTOToCancel(value, vm.globalValues.getUserId(), 
         //                vm.globalValues.getUser().login, $rootScope.bankComparativeDTO, 
         //                $rootScope.retailSpotMarketSession);
        	// 		//place cancel order
        	// 		Market.cancelOrder(orderDTO);
        	// 		//$rootScope.selectedPendingOrders.splice(index,1);
        	// 	}
        	// })
        	// $rootScope.selectedPendingOrders = [];
        	// $rootScope.selectAll = false;
            $rootScope.cancelSelectedOrderCount = 0;
        	$scope.$emit('cancelselectedfrompreorderconfirmation');
        	$uibModalInstance.dismiss('cancel');
    	});
		
        $scope.$on('$destroy', function() {
            cancelselectedordersfrompreorder(); 
        });

		$rootScope.$on('gatewayApp.messagebox.cancel.cancelselectedordersfrompreorder', function (event, data) {
			//do not proceed
			$uibModalInstance.dismiss('cancel');
		});
		
		
		/*
         * allow only 30 characters in password and only some special characters
         */
		 $scope.$watch('vm.password', function(newVal, oldVal) {
			 if(newVal!=null||newVal!=undefined)
	        	{
			       	  if(newVal.length > 30) {       
			       		  vm.password = oldVal;
			       	  }
			       	  if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
			   		  {
			       		  vm.password = oldVal;
			   		  }
	        	}
	       	});
		 
		 function getTimeSuccess( result){
			 
			 // vm.currentDate = DateUtils.convertDateTimeFromServer(result.currentDateTime);
			 vm.currentDate = moment.parseZone(result.currentDateTime);			 
			 vm.serverDateUTCOffset =  vm.currentDate.utcOffset();
			 vm.serverDateTimeZone = result.timeZone;
			 //console.log("time zone " +vm.currentDate.format('Z'));
			 //console.log("utc offset " + vm.serverDateUTCOffset );
			 fillDefaultTime();
		 }
		 function getTimeFailure(error){
			 
		 }
		 function getCurrentServerTimeAndValidate(result){
			 
			//vm.currentDate = new Date();
			//vm.currentDate   =  DateUtils.convertDateTimeFromServer(result.currentDateTime);
			vm.currentDate = moment.parseZone(result.currentDateTime);
			vm.serverDateUTCOffset =  vm.currentDate.utcOffset();
         	vm.currentTime = moment.parseZone(vm.currentDate, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');  
         	if(vm.serverDateUTCOffset == moment().utcOffset()){
         		vm.expiryTime = moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
         	}else{
         		vm.expiryTime = moment(vm.expiryDateTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
         	}
         	
         	
         	if(vm.expiryTime==null || vm.expiryTime==undefined || vm.expiryTime==''){
         		vm.errorSting = 'Invalid expiry time...!';
         		return false;
         	}
         	
         	//validate close time
         	if(vm.expiryTime.split(':')[0] > vm.closeTime.split(':')[0]){
         		vm.errorString = 'Expiry time cannot be greater than ' + vm.orderDTO.instrumentCode +  ' session close time '+ vm.closeTime + '...!';
         		return false;
         	}else if((vm.expiryTime.split(':')[0] == vm.closeTime.split(':')[0]) && (vm.expiryTime.split(':')[1] >= vm.closeTime.split(':')[1])){
         		vm.errorString = 'Expiry time cannot be greater than ' + vm.orderDTO.instrumentCode +  ' session close time '+ vm.closeTime + '...!';
         		return false;
         	}

         	//validate open time
         	if(vm.expiryTime.split(':')[0] < vm.currentTime.split(':')[0]){
         		vm.errorString = 'Expiry time must be greater than current time...!';
         		return false;
         	}else if((vm.expiryTime.split(':')[0] == vm.currentTime.split(':')[0]) && (vm.expiryTime.split(':')[1] <= vm.currentTime.split(':')[1])){
         		vm.errorString = 'Expiry time must be greater than current time...!';
         		return false;
         	}
         	
         	return true;
		 }
		function getTimeForOrderCancellation(result){
			//vm.currentDate   =  DateUtils.convertDateTimeFromServer(result.currentDateTime);
			vm.currentDate = moment.parseZone(result.currentDateTime);
			vm.serverDateUTCOffset =  vm.currentDate.utcOffset();			
			fillDefaultTime();
			formatExpiryTime();
			if(vm.serverDateUTCOffset == moment().utcOffset()){
				vm.expiryTimeString=moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
         	}else{
         		vm.expiryTimeString= moment(vm.expiryDateTime, 'HH:mm').utcOffset(vm.serverDateUTCOffset).format( 'HH:mm');
         	}
        	
        	//vm.orderDTO.expiryTime = vm.expiryTime;
		}

        function getEventTypeForShortName(eventTypeShortName){
            var eventType = '';
            angular.forEach($rootScope.eventTypes, function(value){
                if(value.shortName==eventTypeShortName){
                    eventType = value.name;
                }
            })
            return eventType;
        }
		//divide arder amount then split the order based on no of split and store to list  ;  
	  function splitTheOrder(orderDTO,noOfsplits){
		if (orderDTO.orderAmount != null && orderDTO.orderAmount != undefined && orderDTO.orderAmount != '') {
			if (noOfsplits != null && noOfsplits != undefined && noOfsplits != "") {
			  vm.splitedOrderAmount = orderDTO.orderAmount / noOfsplits;
			   for(var splitOrder=0;splitOrder<=noOfsplits-1;splitOrder++){
				vm.orderDTO.orderAmount=vm.splitedOrderAmount;
				vm.orderDTO.SystemUniqueSNo=angular.copy(splitOrder+1);
				orderDTO.uploadStatus='SUCCESS';
				vm.orderDTOList.push(angular.copy(vm.orderDTO));
			 } 
			}
		}
	}	
	
		
    }   
})();
