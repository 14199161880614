(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'userwiseTurnoverReport'
        })
        
        .controller('UserwiseTurnoverReportController', UserwiseTurnoverReportController);

    UserwiseTurnoverReportController.$inject = ['$scope', '$state', 'OrderStatusDetails', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 
    	'ScreenLog','entityConstants','$timeout','DateUtils','userRoleList','UserwiseTurnover'];

    function UserwiseTurnoverReportController($scope, $state, OrderStatusDetails , GlobalValues, workflowSharedService, EntityPermissions, 
    		ScreenLog,entityConstants,$timeout,DateUtils,userRoleList,UserwiseTurnover) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.USERWISETURNOVERREPORT;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.noOfRecordsPerPage1 = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page1 = 1;
        vm.globalValues=GlobalValues;
        //vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.loggedInUser = vm.globalValues.getUser();
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.userLookup = 'userForTurnOverReport';
		vm.bankId =  0;
    	vm.branchId = 0;
        vm.customerId = 0;
        vm.userwiseTurnoverDetails=[];
       // vm.todayDate = new Date();
        var currentBusinessDate =  vm.globalValues.getSystemSettings().businessDate;
        vm.todayDate = DateUtils.convertLocalDateFromServer(currentBusinessDate);
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
		vm.clicked=false;
        vm.getUserWiseTurnover = getUserWiseTurnover;
        vm.getUserwiseTurnoverDetails = getUserwiseTurnoverDetails;
        vm.exportToFile=exportToFile;
        vm.showCCIL= false;
        
        vm.ExpandOrCollapseUserwiseTurnoverReport=true;
        vm.isShowDetails=false;
        vm.hideUser=false;
        workflowSharedService.viewFrom = "userwiseTurnoverReport";
       if(userRoleList!=null && userRoleList!=undefined && userRoleList.length==0){
    	   vm.hideUser = true;
       }
       vm.mimeType= '';  	  
 	  	vm.baseString =   'Userwise_Turnover_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   
        logScreenAccess();
        
        if (vm.userEntityOwnerType =='SingleUserCustomer'){
        	vm.userListValue = [];
        	vm.userListValue.push(vm.loggedInUser.id);
        	getUserWiseTurnover();
        }/*else if(){
        	vm.userEntityOwnerType ==
        }*/

       function getUserWiseTurnover() {
			vm.bankId =  0;
    	   	vm.branchId = 0;
		  //prepare data to server for customers
		  prepareDataForCustomer();
		  //prepare data to server for bank,branch and clearcorp
		  prepareDataForOthers();
		  
    	   OrderStatusDetails.getUserWiseTurnoverHeader({ "bankId":vm.bankId ,"branchId":vm.branchId, "login":vm.userListValue,"customerId":vm.customerId},onGetUserWiseTurnoverHeaderSuccess,onGetUserwiseTurnoverHeaderFailure);

        }

		function prepareDataForCustomer() {
			if(vm.userEntityOwnerType == 'SingleUserCustomer' || vm.userEntityOwnerType == 'MultiUserCustomer')
				vm.customerId = vm.globalValues.getOrgId();
		}
		function prepareDataForOthers() {
			// if  bank user
			if (vm.userEntityOwnerType == 'Bank') {
				vm.bankId =  vm.globalValues.getOrgId();
			}
			// if branch user
			if (vm.userEntityOwnerType == 'Branch') {
				vm.bankId =  vm.globalValues.getOrgId();
				vm.branchId = vm.globalValues.getBranchId();
			}
			//if user manualy select bank and branch
			if(vm.bankValue!=null && vm.bankValue!=undefined){
    		   vm.bankId = vm.bankValue.id;
    	   	  }
    	   if(vm.branchValue!=null && vm.branchValue!=undefined){
    		   vm.branchId = vm.branchValue.id;
    	     }
		}
       
       function onGetUserWiseTurnoverHeaderSuccess(result){
    	   vm.userwiseTurnoverHeader=result;
    	   vm.isShowDetails=false;
    	   vm.userwiseTurnoverDetails=[];
    	   console.log(vm.userwiseTurnoverDetails.length);
		   vm.clicked=false;
       }
       function onGetUserwiseTurnoverHeaderFailure(){
		vm.clicked=false;
       }
       
       function getUserwiseTurnoverDetails(header){
    	  var bankId = header.bankId;
    	  var branchId = header.branchId;
    	  var userId = header.retailUserId;
    	  OrderStatusDetails.getUserWiseTurnoverDetails({"bankId":bankId,"branchId":branchId,"userId":userId,"customerId":vm.customerId},onGetUserwiseTurnoverDetailsSuccess,
    			   																													onGetUserwiseTurnoverDetailsFailure)
    	   
       }
       
       function onGetUserwiseTurnoverDetailsSuccess(result){
    	   vm.isShowDetails=true;
    	   angular.forEach(result,function(value){
    		   if(value.buySellFlag=='BUYY'){
    			   value.buySellFlag='Buy';
    		   }else{
    			   value.buySellFlag='Sell';
    		   }
    	   })
    	   vm.userwiseTurnoverDetails=result;
       }
       
       function onGetUserwiseTurnoverDetailsFailure(){
    	   
       }
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       $scope.$watch('vm.selectedEntityOwnerType', function(value){
    	   //clear table on change of the entity 
    	   vm.userwiseTurnoverHeader = null;
    	   vm.userwiseTurnoverDetails = null;
       });
       
       function exportToFile(fileType){

    	 	vm.baseString =   'Userwise_Turnover_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   
     	 vm.convertedTodayDate = DateUtils.convertLocalDateToServer(vm.todayDate);
 	
     	  if( vm.userwiseTurnoverHeader !=null &&  vm.userwiseTurnoverHeader!=undefined){
     		  if(fileType == 'csv'){
     			  vm.mimeType = 'text/csv'
     			  vm.baseString =vm.baseString+ ".csv";  
     			 UserwiseTurnover.csvUserwiseTurnoverReport({"businessDate":vm.convertedTodayDate}, vm.userwiseTurnoverHeader, onGenerateFileSuccess);
     		  }else if(fileType == 'excel'){
     			  vm.mimeType = 'application/vnd.ms-excel';
     			  vm.baseString = vm.baseString+ ".xls";
     			 UserwiseTurnover.excelUserwiseTurnoverReport({"businessDate":vm.convertedTodayDate}, vm.userwiseTurnoverHeader, onGenerateFileSuccess);
     		  }
     		 

     		  function onGenerateFileSuccess(result)
     		  {
     	    	  var fileData = null;
     			  fileData = result.data;
     			  var link = document.createElement("a");   
     			  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
     			  
     			  
     		      var blob = new Blob([bytes], {type: vm.mimeType});
     		      if(window.navigator.msSaveOrOpenBlob) {
     	                window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
     		      }else{
     		    	  link.href =  window.URL.createObjectURL(blob);      
     		    	  link.download = vm.baseString;
     		    	  document.body.appendChild(link);
     		    	  link.click();
     		    	  document.body.removeChild(link);
     		      }
     		  }
     		  
 
     		  
     	  }
       }	
       
    }
})();
