(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'changeSettings'
	})

	.controller('ChangeSettingsController', ChangeSettingsController);

	ChangeSettingsController.$inject = [ '$scope', '$state',
			'workflowSharedService', 'ChangeSettings', 'GlobalValues',
			'EntityPermissions', 'ScreenLog', '$stateParams',
			'$uibModalInstance', '$rootScope', 'MessageBox', 'Workflow','entity','DateUtils', '$filter'];

	function ChangeSettingsController($scope, $state, workflowSharedService,
			ChangeSettings, GlobalValues, EntityPermissions, ScreenLog,
			$stateParams, $uibModalInstance, $rootScope, MessageBox, Workflow,entity,DateUtils, $filter) {

		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenLog = ScreenLog;
		vm.saveCustomerSpecificSettings = saveCustomerSpecificSettings;
		vm.customerSpecificSettings = {};
		vm.controlMin=null;
		vm.controlMax=null;
		vm.customerId = vm.globalValues.getCustomerId();
		vm.customerSpecificSettings.customerId=vm.customerId ;
/*		$("#date_input").on("change", function () {
		     $(this).css("color", "rgba(0,0,0,0)").siblings(".datepicker_label").css({ "text-align":"center", position: "absolute",left: "10px", top:"14px",width:$(this).width()}).text($(this).val().length == 0 ? "" : ($.datepicker.formatDate($(this).attr("dateformat"), new Date($(this).val()))));
		        });*/
		
		vm.approveCustomerSpecificSettings = approveCustomerSpecificSettings;
		vm.rejectCustomerSpecificSettings = rejectCustomerSpecificSettings;
		vm.discardCustomerSpecificSettings = discardCustomerSpecificSettings;
		vm.resubmitCustomerSpecificSettings = resubmitCustomerSpecificSettings;
		vm.clear = clear;
		$scope.showButton = false;
		$scope.isShowSaveButton = false;
		$scope.showDiscardButton = false;
		$scope.isShowResubmit = false;
		$scope.isShowOkButton = false;
		vm.isPasswordPolicySelected = true;

		vm.clicked = false;
		vm.noOfDecimals=0;



		if (workflowSharedService.viewFrom == 'customerSpecificSettingstodolist_checker') {
			vm.ViewDisable = true;

			vm.customerSpecificSettings = angular.fromJson($rootScope.payload);
			console.log(vm.customerSpecificSettings);
			vm.headerLabel = "Order Settings";
			vm.labelName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsDescription;
			vm.settingsName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsName;
			if(vm.customerSpecificSettings.settingsType=='amcdate'){
				vm.settingsValue = DateUtils.convertLocalDateFromServer(vm.customerSpecificSettings.value);
			}else{
				vm.settingsValue = vm.customerSpecificSettings.value;
			}
			
			vm.settingsType = vm.customerSpecificSettings.settingsType;
			vm.screenName = 'dealingAdmin';
			vm.isDisabled = true
			$scope.showButton = true;
			$scope.showDiscardButton = false;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;

		} else if (workflowSharedService.viewFrom == 'customerSpecificSettingstodolist_maker') {
			vm.ViewDisable = true;
			vm.customerSpecificSettings = angular.fromJson($rootScope.payload);
			vm.headerLabel =  "Order Settings";
			vm.labelName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsDescription;
			vm.settingsName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsName;
			if(vm.customerSpecificSettings.settingsType=='amcdate'){
				vm.settingsValue = DateUtils.convertLocalDateFromServer(vm.customerSpecificSettings.value);
			}else{
				vm.settingsValue = vm.customerSpecificSettings.value;
			}
			vm.settingsType = vm.customerSpecificSettings.settingsType;
			vm.screenName = 'dealingAdmin';
			vm.isDisabled = true
			$scope.showButton = false;
			$scope.showDiscardButton = true;
			$scope.isShowSaveButton = false;
			$scope.isShowResubmit = false;
		}

		else if (workflowSharedService.viewFrom == 'customerSpecificSettings_resubmit') {
			vm.customerSpecificSettings = angular.fromJson($rootScope.payload);
			vm.headerLabel =  "Order Settings";
			vm.labelName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsDescription;
			vm.settingsName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsName;
			if(vm.customerSpecificSettings.settingsType=='amcdate'){
				vm.settingsValue = DateUtils.convertLocalDateFromServer(vm.customerSpecificSettings.value);
			}else{
				vm.settingsValue = vm.customerSpecificSettings.value;
			}
			
			vm.settingsType = vm.customerSpecificSettings.settingsType;
			vm.isDisabled = false;
			vm.screenName = 'dealingAdmin';
			$scope.isShowResubmit = true;
			$scope.isShowSaveButton = false;

		} 		else if (workflowSharedService.viewFrom == 'customerSpecificSettings_auditlog') {
			
			vm.customerSpecificSettings = angular.fromJson($rootScope.payload);;
			vm.headerLabel = "Order Settings";
			vm.labelName = vm.customerSpecificSettings.customerSpecificSettingsList.settingsDescription;
			vm.settingsName = vm.customerSpecificSettings.settingsName;
			if(vm.customerSpecificSettings.settingsType=='amcdate'){
				vm.settingsValue = DateUtils.convertLocalDateFromServer(vm.customerSpecificSettings.value);
			}else{
				vm.settingsValue = vm.customerSpecificSettings.value;
			}
			vm.settingsType = vm.customerSpecificSettings.settingsType;
			vm.isDisabled = true;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;
			$scope.isShowOkButton = true;

		} 
		else {
			vm.customerSpecificSettings.isDefault = false;
			vm.customerSpecificSettings.settingsName =  $stateParams.settingsName;
			vm.customerSpecificSettings.value = $stateParams.settingsValue;
			vm.customerSpecificSettings.settingsType = $stateParams.settingsType;
			
			vm.headerLabel = $stateParams.headerLabel;
			vm.labelName = $stateParams.labelName;
			vm.settingsName = $stateParams.settingsName;
			if($stateParams.settingsType=='amcdate'){
			
				
				vm.settingsValue = moment($stateParams.settingsValue,'DD/MM').toDate();
			}else{
				vm.settingsValue = $stateParams.settingsValue;
			}
			
			vm.settingsType = $stateParams.settingsType;
			vm.screenName = $stateParams.screenName;
			$scope.isShowSaveButton = true;
			console.log(vm.customerSpecificSettings.value);
		}
		
		if(vm.settingsName == 'MaximumPasswordAgeInDays' || vm.settingsName == 'AutoWithdrawalOfCustomerRegistrationApplication' || vm.settingsName == 'UnUsedTimeFrame' 
			|| vm.settingsName == 'DefaultLimitPeriodForSpotWindow' || vm.settingsName == 'DefaultLimitPeriodForForwardWindow' || vm.settingsName == 'NoOfDaysToDisableTheAccount' || vm.settingsName == 'NoOfDaysBeforePasswordWarning' || vm.settingsName == 'NoOfDaysEmailTriggeredForPendingApprovalRecords'){
			vm.isShowDays = true;
		}else if(vm.settingsName == 'MinimumPasswordAgeInHours') {
			vm.isShowHours = true;
		}else if (vm.settingsName == 'LifeSpanOfTheOTP' || vm.settingsName == 'ThrottlingParameters'){
			vm.isShowSeconds = true;
		}else if (vm.settingsName == 'NearestRoundingOffValueForUSD') {
			vm.noOfDecimals= vm.decimalForPaise;
			vm.isShowPaise=true;
			vm.isShowUSD = true;
		}else if (vm.settingsName == 'MinimumOrderValue' || vm.settingsName == 'MaximumOrderValue') {
			vm.noOfDecimals= vm.decimalForPaise;
			vm.isShowPaise=false;
			vm.isShowUSD = true;
			vm.controlMin = 0;
			vm.controlMax = $filter('number')(999999999.99, vm.decimalsForAmount);
		}else if (vm.settingsName == 'NearestRoundingOffValueForINR' || vm.settingsName == 'AllowedOrderPriceDeviationFromMarketPriceInPaise') {
			vm.isShowPaise = true;
		}else if(vm.settingsName == 'MarketMovementInterval' || vm.settingsName == 'SessionTimeoutInterval' || vm.settingsName == 'MinimumIdleTimeToRestrictOrderActivity'
			  || vm.settingsName == 'PreCASHSessionCloseIntimation') {
			vm.isShowMins = true;
		}else if(vm.settingsName=='ExchangeRateChange'){
			vm.noOfDecimals=vm.decimalsForPercent;
		}else if(vm.settingsName=='MinimumTransactionChargePerTrade' || vm.settingsName=='OrderLotSize'){
			vm.noOfDecimals= vm.decimalForPaise;
			vm.isShowPaise=true;
		}
		

		function saveCustomerSpecificSettings() {

			if(vm.settingsType == 'yesorno' ){
				vm.customerSpecificSettings.value = vm.settingsValue;
			}
			
			ChangeSettings.updateCustomerSpecificSettings(vm.customerSpecificSettings, onSaveSuccess, onSaveError)	

			
		}

		function onSaveSuccess(result) {
			$scope.$emit('Refresh');
			$uibModalInstance.close();

		}

		function onSaveError() {

			vm.clicked = false;
		}

		function approveCustomerSpecificSettings() {
			ChangeSettings.approve({
				"taskId" : $rootScope.taskID
			}, onApproveSuccess, onApproveError);
			
		}

		function onApproveSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.close();
		}
		function onApproveError(error) {
			vm.clicked=false;
		}

		function rejectCustomerSpecificSettings() {
			Workflow.rejectTask($rootScope.taskID);
		}

		function discardCustomerSpecificSettings() {
			ChangeSettings.discard({
				"taskId" : $rootScope.taskID
			}, onDiscardSuccess, onDiscardError);
			
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.close();
		}
		function onDiscardError(error) {

		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.close(result);
			$uibModalInstance.close();
		}

		function onResubmitError(error) {
			vm.clicked=false;
		}

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function resubmitCustomerSpecificSettings() {
			if(vm.settingsType == 'yesorno' ){
				vm.customerSpecificSettings.value = vm.settingsValue;
			}
			
				ChangeSettings.resubmitForEdit({
					"taskId" : $rootScope.taskID
				}, vm.customerSpecificSettings, onResubmitSuccess, onResubmitError);
		

			
		}
		
		


	}
})();
