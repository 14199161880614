(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'serverComponentStatus'
	})

	.controller('ServerComponentStatusController',
			ServerComponentStatusController);

	ServerComponentStatusController.$inject = [ '$scope', '$state',
			'GlobalValues', 'workflowSharedService', 'EntityPermissions',
			'ScreenLog', 'entityConstants', '$timeout',
			'ServerComponentStatus', 'MessageBox', '$rootScope',
			'AlertService', '$filter', '$interval','Auth' ];

	function ServerComponentStatusController($scope, $state, GlobalValues,
			workflowSharedService, EntityPermissions, ScreenLog,
			entityConstants, $timeout, ServerComponentStatus, MessageBox,
			$rootScope, AlertService, $filter, $interval,Auth) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.SERVER_COMPONENT_STATUS;
		$scope.noOfRecordsPerPage = GlobalValues
				.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues = GlobalValues;
		workflowSharedService.viewFrom = "serverComponentStatus";
		vm.screenLog = ScreenLog;
		var autoRefreshInterval = 3;
		vm.isRefreshCompelete = true;
		vm.autoRefreshCount = 1;
		var yesMessageEvent = null;
		var startRecoveryEvent = null;
		var yesHeMessageEvent = null;
		var statusUpdateEvent = null;
		logScreenAccess();

		vm.orderServerManualLogin = orderServerManualLogin;
		vm.sendHEStatusUpdate = sendHEStatusUpdate;

		vm.todayDate = new Date();
		vm.serverComponentStatus = {
			"Zero" : "Zero"
		};
		vm.permitServerComponentStatusViewAction = EntityPermissions.hasPermission('ServerComponentStatus.VIEW');
      /* if(vm.permitServerComponentStatusViewAction ==false){
			//message box 
			MessageBox.openMessageBox('Alert', 'Session expired. You will be redirected to login page...!', 'Ok', 'permitServerComponentStatusViewAction');
		}

		$rootScope.$on('gatewayApp.messagebox.ok.permitServerComponentStatusViewAction', function (event) {
			Auth.sessionlogout();
		});
		
		$rootScope.$on('gatewayApp.messagebox.clear.permitServerComponentStatusViewAction', function (result){
			Auth.sessionlogout();
		})*/

		loadAll();
		fetchAndSetServerComponentStatus();

		vm.autoRefreshCountDown = autoRefreshInterval;

		$interval(function() {
			if (vm.isRefreshCompelete) {
				if (vm.autoRefreshCountDown - 1 == 0) {
					vm.autoRefreshCountDown = autoRefreshInterval;
					vm.isRefreshCompelete = false;
				} else {
					vm.autoRefreshCountDown = vm.autoRefreshCountDown - 1;
				}
			}

		}, 1000);

		$interval(function() {
			fetchAndSetServerComponentStatus();
			vm.autoRefreshCount = vm.autoRefreshCount + 1;
		}, autoRefreshInterval * 1000);

		function logScreenAccess() {
			ScreenLog.screenLog(vm.screenName, 0,
					entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		function fetchAndSetServerComponentStatus() {
			ServerComponentStatus.getServerComponentStatus(function(result) {
				angular.forEach(result, function(value) {					
					vm.serverComponentStatus[value.processCode] = value;			
					if(value.processCode == 'WBS'){
						vm.connectedSite = value.activeNode;
					}
				});
			});	
			vm.isRefreshCompelete = true;
		}

		function loadAll() {
			ServerComponentStatus.query({
				"orgId" : GlobalValues.getOrgId()
			}, function(result) {
				vm.currentStatus = result[0].interactiveStatus;

				if (vm.currentStatus == 'Online') {
					vm.changedStatus = 'Offline';
				} else {
					vm.changedStatus = 'Online';
				}

			});

		}
		function sendHEStatusUpdate() {
			$scope.status = vm.changedStatus;
			MessageBox.openMessageBox("Alert",
					"Do you want to change the status from " + vm.currentStatus
							+ " to " + vm.changedStatus + "?", "YesNo",
					"changeHEStatus");
		}

		function orderServerManualLogin() {

			MessageBox.openMessageBox("Alert",
					"Do you want to manually login to order server?", "YesNo",
					"orderServerManualLogin");
		}

		yesMessageEvent = $rootScope.$on(
				'gatewayApp.messagebox.yes.orderServerManualLogin', function(
						event, data) {
					vm.orderServerManualLoginStatus = "PROGRESS";
					ServerComponentStatus.manualLogin(
							orderServerManualLoginSuccess,
							orderServerManualLoginFailure);

				});

		$rootScope.$on('gatewayApp.messagebox.no.orderServerManualLogin',
				function(event, data) {
					// do nothing
				});

		function orderServerManualLoginSuccess(error) {

		}

		function orderServerManualLoginFailure(error) {
			// alert(error.message);
			vm.orderServerManualLoginStatus = "";
			var errorString = "";
			if (error.status == 503) {

				// AlertService.error($filter('translate')(
				// "error.order.serviceUnavailable"));
				errorString = $filter('translate')(
						"error.order.serviceUnavailable");
				MessageBox.openMessageBox("Alert", errorString
						+ " Kindly contact support team.", "Ok", "alertError");
			} else if (error.status == 403) {
				// since session expiry popup is diplayed do not show any error
				// for 403 status

			} else {
				// AlertService.error($filter('translate')( error.data));
				errorString = error.data;
				MessageBox.openMessageBox("Alert", errorString
						+ " Kindly contact support team.", "Ok", "alertError");
			}

		}

		startRecoveryEvent = $rootScope.$on('orderServerManualLogin', function(
				event, data) {
			if (data != null) {

				if (data.message == "success") {
					// vm.orderServerManualLoginStatus = "SUCCESS";
					vm.orderServerManualLoginStatus = "";
					console.log("Order server manual login successful "
							+ data.message);
					// AlertService.success($filter('translate')('global.menu.orderServerManualLogin.success'));
					MessageBox.openMessageBox("Alert", $filter('translate')(
							'global.menu.orderServerManualLogin.success'),
							"Ok", "alertError");

				} else {
					// vm.orderServerManualLoginStatus = "FAILURE";
					vm.orderServerManualLoginStatus = "";
					console.log("Order server manual login failed "
							+ data.message);
					// AlertService.error(data.message);
					MessageBox.openMessageBox("Alert", data.message, "Ok",
							"alertError");
				}
			}

		});

		$scope.$on('$destroy', function() {

			if (startRecoveryEvent != null)
				startRecoveryEvent();

			if (yesHeMessageEvent != null)
				yesHeMessageEvent();

			if (yesMessageEvent != null)
				yesMessageEvent();

			if (statusUpdateEvent != null)
				statusUpdateEvent();

		});

		yesHeMessageEvent = $rootScope.$on(
				'gatewayApp.messagebox.yes.changeHEStatus', function(event,
						data) {
					if ($scope.status != null && $scope.status != undefined) {
						vm.heStatusUpdate = "PROGRESS";
						ServerComponentStatus.changeStatus({
							status : $scope.status
						}, changeStatusSuccess, changeStatusFailure);

					}

				});

		$rootScope.$on('gatewayApp.messagebox.no.changeHEStatus', function(
				event, data) {
			// do nothing
		});

		function changeStatusSuccess() {

		}

		function changeStatusFailure(error) {
			vm.heStatusUpdate = "";
			var errorString = "";
			if (error.status == 503) {

				// AlertService.error($filter('translate')(
				// "error.order.serviceUnavailable"));
				errorString = $filter('translate')(
						"error.order.serviceUnavailable");
				MessageBox.openMessageBox("Alert", errorString
						+ " Kindly contact support team.", "Ok", "alertError");
			} else if (error.status == 403) {
				// since session expiry popup is diplayed do not show any error
				// for 403 status

			} else {
				// AlertService.error($filter('translate')( error.data));
				errorString = error.data;
				MessageBox.openMessageBox("Alert", errorString
						+ " Kindly contact support team.", "Ok", "alertError");
			}

		}
		statusUpdateEvent = $rootScope.$on('heStatusUpdate', function(event,
				data) {
			if (data != null) {
				if (data.message == "success") {
					// vm.heStatusUpdate = "SUCCESS";
					vm.heStatusUpdate = "";
					console.log("He connection successful " + data.message);
					// AlertService.success($filter('translate')('gatewayApp.servercomponent.heSuccess'));
					loadAll();
					MessageBox.openMessageBox("Alert", $filter('translate')(
							'gatewayApp.servercomponent.heSuccess'), "Ok",
							"alertError");
				} else {
					// vm.heStatusUpdate = "FAILURE";
					vm.heStatusUpdate = "";
					console.log("He connection failed " + data.message);
					// AlertService.error(data.message);
					// AlertService.error($filter('translate')('gatewayApp.heStatusUpdate.error'));
					MessageBox.openMessageBox("Alert", $filter('translate')(
							'gatewayApp.heStatusUpdate.error'), "Ok",
							"alertError");
				}
			}

		});

		$rootScope.$on('gatewayApp.messagebox.Ok.alertError', function(event,
				data) {
			// do nothing
		});

	}
})();
