(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('audit-log', {
            parent: 'entity',
            params:{screen: 'AuditLogReport'},
            url: '/audit-log',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.AuditLog.manageAuditLog" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/audit-log/audit-log.html',
                    controller: 'AuditLogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('auditLog');
                    $translatePartialLoader.addPart('smsSettings');
                    $translatePartialLoader.addPart('bankRegistration');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('role');
                    $translatePartialLoader.addPart('branch');
                    $translatePartialLoader.addPart('securityPolicy');
                    $translatePartialLoader.addPart('entityActionApprovalPolicy');
                    $translatePartialLoader.addPart('approvalPolicy');
                    $translatePartialLoader.addPart('alertconfiguration');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('markupSettings');
                    $translatePartialLoader.addPart('defaultMarkupSettings');
                    $translatePartialLoader.addPart('heConnectivitySettings');
                    $translatePartialLoader.addPart('intradayMarketSessionSettings');
                    $translatePartialLoader.addPart('defaultMarketSessionSettings');
                    $translatePartialLoader.addPart('emailSettings');
                    $translatePartialLoader.addPart('amcSettings');
                    $translatePartialLoader.addPart('customerCharges');
                    $translatePartialLoader.addPart('gstSettings');
                    $translatePartialLoader.addPart('transactionChargesSettings');
                    $translatePartialLoader.addPart('creditFactorSettings');
                    return $translate.refresh();
                }],
                entity: function(){
                	
                }
            }
        })
        .state('audit-log-param-view', {
            parent: 'audit-log',
            url: '/audit-log/param-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/audit-log/audit-log-params-view-dialog.html',
                    controller: 'AuditLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: $state.params.paramMap
                       
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
       
       };
    })();