(function() {
	'use strict';
	angular.module('gatewayApp').factory('RegularHoliday', RegularHoliday);

	RegularHoliday.$inject = [ '$resource', 'DateUtils' ];

	function RegularHoliday($resource, DateUtils) {
		var resourceUrl = 'api/regular-holiday/:id';

		return $resource(
				resourceUrl,
				{},
				{
					'query' : {
						method : 'GET',
						isArray : true
					},
					'get' : {
						method : 'GET',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},

					'save' : {
						method : 'POST',
						url : 'api/regular-holiday/:orgId',
						params: {orgId:'@orgId'},
						transformRequest : function(data) {
							var copy = angular.copy(data);
	                    	copy.effectiveFromDate = DateUtils.convertLocalDateToServer(copy.effectiveFromDate);
							return angular.toJson(copy);
						}
					},
					'update' : {
						method : 'PUT',
						url : 'api/regular-holiday/:orgId',
						transformRequest : function(data) {
							var copy = angular.copy(data);
							copy.effectiveFromDate = DateUtils.convertLocalDateToServer(copy.effectiveFromDate);
							return angular.toJson(copy);
						}
					},
					'delete' : {
						method : 'DELETE',
						params : {
							id : '@id'
						},
						url : 'api/regular-holiday/:id'
					},
					'approve': {
		                method: 'GET',
		                params: {taskId:'@taskId'},
		                url: 'api/tasks/:taskId/approve'
		            },
		            'reject': {
		                method: 'GET',
		                params: {taskId:'@taskId'},
		                url: 'api/tasks/:taskId/reject'
		            },
		            'discard': {
		              	 method: 'GET',
		                   params: {taskId:'@taskId'},
		                   url: 'api/tasks/:taskId/discard'
		             },
		             'resubmit': {
		                 method: 'PUT',
		                 params: {taskId:'@taskId'},
		                 url: 'api/tasks/:taskId/resubmit/regular-holiday',
		                 transformRequest: function (data) {
		                 	var copy = angular.copy(data);
		                 	return angular.toJson(copy);
		                 }
		            }
				});
	}
})();
