(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'markup'
        })
        
        .controller('MarkupSettingsController', MarkupSettingsController);

    MarkupSettingsController.$inject = ['$scope', '$state', 'Markup', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants'];

    function MarkupSettingsController($scope, $state, Markup , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.MARKUP_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "markup";
        vm.bank = GlobalValues.getBank();
        vm.entityOwnerType = vm.globalValues.getUser().entityOwnerType.shortName;
        vm.launch=launch;
        vm.clicked=false;
        function launch() {
        	$state.go("markup-new");
        }
        vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.decimalForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
        
        
        
        vm.permitMarkupSettingsEditEntityAction = EntityPermissions.hasPermission('MarkupSettings.EDIT');
        vm.permitMarkupSettingsViewEntityAction = EntityPermissions.hasPermission('MarkupSettings.VIEW');
        vm.permitMarkupSettingsAddEntityAction = EntityPermissions.hasPermission('MarkupSettings.CREATE');
        vm.permitMarkupSettingsImportEntityAction = EntityPermissions.hasPermission('MarkupSettings.IMPORT');
        
        logScreenAccess();
        
        if(vm.entityOwnerType=='Branch'){
            loadAll();

        }

       function loadAll() {
    	   
    	   vm.bankId = vm.globalValues.getBank().id;
    	   vm.branchLogin = vm.globalValues.getBranch().id;
    		   Markup.getAllMarkupForBranch({"branchId": vm.branchLogin,"bankId":vm.bankId},function(result) {
                   vm.markup = result;
                });  
    	 
    	        	
        }      
       
       vm.loadCustomers = function loadCustomers(branch) {
    	   vm.branchId = 0;
    	   vm.bankId = vm.globalValues.getBank().id;
   		if(branch!=null && branch!=undefined){
       		vm.branchId = branch.id;
   		}
   		Markup.getAllMarkupForBranch({"branchId": vm.branchId,"bankId": vm.bankId}, function(result) {
            vm.markup = result;
            vm.clicked=false;  
        });

       	
       	
           
       }
       
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
    }
})();
