(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('MarketMovement', MarketMovement);

    MarketMovement.$inject = ['$resource'];

    function MarketMovement ($resource) {
        var resourceUrl =  'api/market-movement-details';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true,
        		params: {instrument:'@instrument', businessDate : '@businessDate'},
        	    url: 'api/get-market-movement-details/instrument/:instrument/businessDate/:businessDate/market-movement'
        	 },
        
	        'getLTPForLimitsForInstrument': {
	            method: 'GET',  
	           
	            params : {
	            	instrument : '@instrument'
				},
				 url: 'api/instrument/:instrument/ltp-for-limits'
	        },
	        'getDailyClosingPrice': {
	            method: 'GET',  
	           
	            params : {
	            	instrument : '@instrument'
				},
				 url: 'api/instrument/:instrument/daily-closing-price'
	        },
	        'getDailyClosingPriceList': {
	            method: 'GET',  
	           isArray: true,
	            params : {
	            	instrument : '@instrument'
				},
				 url: 'api/instrument/:instrument/daily-closing-price-list'
	        }
              
        });
    }
})();
