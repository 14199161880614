(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('history-report', {
            parent: 'entity',
            params:{screen: 'HistoryReport'},
            url: '/history-report',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.HistoryReport.manageHistoryReport" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/history-report/history-report.html',
                    controller: 'HistoryReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('historyReport');
                    $translatePartialLoader.addPart('smsSettings');
                    $translatePartialLoader.addPart('bankRegistration');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('role');
                    $translatePartialLoader.addPart('branch');
                    $translatePartialLoader.addPart('securityPolicy');
                    $translatePartialLoader.addPart('entityActionApprovalPolicy');
                    $translatePartialLoader.addPart('approvalPolicy');
                    $translatePartialLoader.addPart('alertconfiguration');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('markupSettings');
                    $translatePartialLoader.addPart('defaultMarkupSettings');
                    $translatePartialLoader.addPart('heConnectivitySettings');
                    $translatePartialLoader.addPart('intradayMarketSessionSettings');
                    $translatePartialLoader.addPart('defaultMarketSessionSettings');
                    $translatePartialLoader.addPart('emailSettings');
                    $translatePartialLoader.addPart('amcSettings');
                    $translatePartialLoader.addPart('customerCharges');
                    $translatePartialLoader.addPart('gstSettings');
                    $translatePartialLoader.addPart('transactionChargesSettings');
                    $translatePartialLoader.addPart('creditFactorSettings');
                    return $translate.refresh();
                }],
                entity: function(){
                	
                }
            }
        })
       
       };
    })();