(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('AuditLogDialogController', AuditLogDialogController);

    AuditLogDialogController.$inject = ['$scope', 'entity','$stateParams','$uibModalInstance'];

    function AuditLogDialogController($scope, entity, $stateParams, $uibModalInstance) {    
        var vm = this;
        vm.clear = clear;
        vm.paramMap = entity;
        //console.log("vm.paramMap " + vm.paramMap);   
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
       
    }   
          
        
       
})();
