(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('LimitUtilisationSplitup', LimitUtilisationSplitup);

        LimitUtilisationSplitup.$inject = ['$resource'];

    function LimitUtilisationSplitup ($resource) {
        var resourceUrl =  'api/limit-utilisation-splitup/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getLimitUtilisationSplitupHeader':{
  				method: 'GET',
  				isArray:true,
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId',
  					 instrumentId : '@instrumentId'},  				
  				url: 'api/limit-utilisation-splitup-report',
  				 transformResponse: function(data, headers) {
  					if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                 }    
              },
             
              'exportLimitUtilisationSplitupExcel': {
                  method: 'POST',
                  url: 'api/limit-utilisation-splitup-report/excel-download',
                  responseType: 'arraybuffer',
                  params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'},
                  transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }         
             },
              'exportLimitUtilisationSplitupCsv': {
                  method: 'POST',
                  url: 'api/limit-utilisation-splitup-report/csv-download',
                  responseType: 'arraybuffer',
                  transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }         
             }
        });
    }
})();
