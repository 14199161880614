(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'defaultMarkup'
        })
        
        .controller('DefaultMarkupSettingsController', DefaultMarkupSettingsController);

    DefaultMarkupSettingsController.$inject = ['$scope', '$state', 'DefaultMarkup', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function DefaultMarkupSettingsController($scope, $state, DefaultMarkup , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.DEFAULT_MARKUP_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "defaultMarkup";
        vm.entityOwnerType=vm.globalValues.getEntityOwnerType().shortName;
        $scope.refresh=refresh;
        
        vm.permitDefaultMarkupSettingsEditEntityAction = EntityPermissions.hasPermission('DefaultMarkupSettings.EDIT');
        vm.permitDefaultMarkupSettingsViewEntityAction = EntityPermissions.hasPermission('DefaultMarkupSettings.VIEW');
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   DefaultMarkup.query({"orgId": GlobalValues.getOrgId()},function(result) {
                vm.defaultMarkup = result;
             });        	
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
