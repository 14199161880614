(function () {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('BankLoggedInUsersController', BankLoggedInUsersController);

	BankLoggedInUsersController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',  'AlertService','LoggedInUsersForBank','$filter', 'ScreenLog','entityConstants','DateUtils', '$timeout'];

	function BankLoggedInUsersController( $scope, $state, $rootScope, GlobalValues,AlertService , LoggedInUsersForBank , $filter , ScreenLog, entityConstants,DateUtils,$timeout){
		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.NO_OF_LOGGEDIN_USER_REPORT_FOR_BANK;
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		$scope.refresh = refresh;

		vm.currentDateTime =moment().format( 'DD_MMM_YYYY_HH_mm');
        
        vm.selectedEntityType = '';        
        vm.showUserDetailsDrillDown = false;
        vm.showBankDetails = false;
  		vm.showBranchDetails = false;
		//initiate list
        vm.bankSummary = {};
        vm.userDetailsList =[];

		var recordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.noOfRecordsPerPage = recordsPerPage;
		$scope.page = 1;
		$scope.noOfUserRecordsPerPage = recordsPerPage;
		$scope.userPage = 1;
		$scope.noOfBranchRecordsPerPage = recordsPerPage;
		$scope.branchPage = 1;

		vm.getUserDetails = getUserDetails;
		//vm.getUsersLoggedInForTheMoment = getUsersLoggedInForTheMoment;
	   // vm.getUsersLoggedInForTheDay = getUsersLoggedInForTheDay;
		vm.YES = true;
		vm.NO = false;
		vm.isAsOfNow = true;
		vm.BANK_TYPE= "Bank";
	    vm.BRANCH_TYPE= "Branch";
	
		vm.exportToFile = exportToFile;

		vm.todayDate = new Date();
		vm.reportDate = DateUtils.convertDateTimeFromServer(vm.todayDate);

		logScreenAccess();
		loadHeader();

		function loadHeader(){
			vm.bankSummary =[];
			vm.filterText="";
			resetGridsForHeaderSelection();

			LoggedInUsersForBank.getSummaryData({'isAsOfNow' : vm.isAsOfNow}, reportHeaderSuccess, reportHeaderFailure );
		}
		function reportHeaderSuccess(result){
			vm.bankSummary = result;
		}
		function reportHeaderFailure(error){

		}
		function resetGridsForHeaderSelection(){
   		
   	     vm.selectedEntityType = '';
   	     vm.userSummaryFilterText = '';  	
   	        
   	     vm.showUserDetailsDrillDown = false;
   		 vm.showBankDetails = false;
   		 vm.showBranchDetails = false;
   		
   	   }
		function userDetailsSuccess(result){
    		vm.userDetailsList = result;
    		angular.element("#userSearch").focus();
    	}
    	function userDetailsFailure(error){
    		
    	}
    	
    	function getUserDetails(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);
    		if(vm.isAsOfNow)
    			LoggedInUsersForBank.getLoggedInUsersAsOFNow({ 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
        		
    		else
    			LoggedInUsersForBank.getLoggedInUsersForTheDay({ 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
        		
    	}
    	
    	/*function getUsersLoggedInForTheMoment( entityType){
    		
    		
    		LoggedInUsersForBank.getLoggedInUsersAsOFNow({ 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}
    	function getUsersLoggedInForTheDay(bankId, bankName, entityType, drillDownGridType){
    		
    		LoggedInUsersForBank.getLoggedInUsersForTheDay({ 'entityOwnerType' : entityType } ,userDetailsSuccess, userDetailsFailure)
    		
    	}*/
    	
    	function setSelectionDetails(bankId, bankName, entityType,drillDownGridType){
   		 
    	    vm.selectedEntityType = entityType;
    	    vm.userSummaryFilterText = '';
    	    setColumDisplayForUserDetails(bankId, bankName, drillDownGridType);
    	}
    	function setColumDisplayForUserDetails(bankId, bankName, drillDownGridType){
    		vm.showUserDetailsDrillDown = true;
    		vm.showBankDetails = false;
    		vm.showBranchDetails = false;
    		
    		if(drillDownGridType ==  vm.BANK_TYPE ){    			
        		vm.showBankDetails = true;
        		vm.userDetailsHeader = "Bank Users"
        		
    		}else if(drillDownGridType == vm.BRANCH_TYPE ){
    			
    			vm.showBranchDetails = true;
    			vm.userDetailsHeader = "Branch Users"
    			
    		}
    	}



		function logScreenAccess(){
			vm.screenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

	

		/********** Refresh**********/
		$scope.toggle = true;
		function refresh() {
			$scope.toggle = false;
			$scope.refreshFlag = false;
			loadHeader();
			if ($scope.toggle == false) {
				$timeout(function() {
					$scope.toggle = true;
				}, 2000);
				$scope.refreshFlag = false;
			}
		};

		/***********END *************/
	

		$scope.$watch('vm.filterText', function(value){
			resetGridsForHeaderSelection();
		}); 
		$scope.$watch('vm.isAsOfNow', function(newValue, oldValue){
			if(newValue!=null && newValue != undefined && newValue!=oldValue){
				loadHeader();
			}
		}); 



		function exportToFile(fileType){
			vm.baseString =   'No_Of_Logged_In_Users_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv'
					vm.baseString = vm.baseString+ ".csv";  
					LoggedInUsersForBank.getCSVData({'isAsOfNow' : vm.isAsOfNow} , processResponse )
				}else if(fileType == 'excel'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls";  
					LoggedInUsersForBank.getExcelData({'isAsOfNow' : vm.isAsOfNow} , processResponse );
				}
			
		}	
		function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer


			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
})();