(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('UploadService', UploadService);

    UploadService.$inject = ['$resource'];

    function UploadService($resource) {
        var resourceUrl =  'api/upload/:id';

        return $resource(resourceUrl, {}, {
            'approve': {
                method: 'GET',
                isArray: true, 
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
             'orderApprove': {
                 method: 'GET',
                 isArray: false, 
                 params: {taskId:'@taskId'},
                 url: 'order/tasks/:taskId/approve'
             },
             'orderApproveImport': {
                method: 'GET', 
                isArray: 'true',
                params: {taskId:'@taskId'},
                url: 'order/tasks/:taskId/approve'
            }
        });
    }
})();

            
