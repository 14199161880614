(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
    	$stateProvider.state('trackStatus', {
            parent: 'account',
            url: '/activate?key',
            data: {
                authorities: []
            },
            views: {
                'logincontent@': {
                    templateUrl: 'app/account/track-status/track-status.html',
                    controller: 'TrackStatusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	mode: function(){ return "trackstatus";},
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trackStatus');
                    $translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        })
        .state('registrationRequestStatus', {
            parent: 'account',
            url: '/view-customer-onboarding-status?key',
            data: {
                authorities: []
            },
            views: {
                'logincontent@': {
                    templateUrl: 'app/account/track-status/track-status.html',
                    controller: 'TrackStatusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	mode: function(){ return "registrationstatus";},
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trackStatus');
                    $translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('otp-confirm');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        })
        .state('customerOnboardingStatus', {
            parent: 'account',
            url: '/customer-onboarding-status/{key}/key',
            data: {
                authorities: []
            },
            views: {
                'logincontent@': {
                    templateUrl: 'app/account/track-status/track-status.html',
                    controller: 'TrackStatusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	mode: function(){ return "customeronboardingstatus";},
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trackStatus');
                    $translatePartialLoader.addPart('userProfile');
                    $translatePartialLoader.addPart('customer-registration');
                    $translatePartialLoader.addPart('otp-confirm');
                    $translatePartialLoader.addPart('error');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
