(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('alert-configuration', {
            parent: 'entity',
            url: '/alert-configuration',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.alertConfiguration.alertConfiguration" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/alert-configuration/alert-configuration.html',
                    controller: 'AlertConfigurationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('alertconfiguration');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('alert-configuration.new', {
            parent: 'alert-configuration',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-dialog.html',
                    controller: 'AlertConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	 id:null,  
                            	name:null
                            	 
                            	
                             
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('alert-configuration', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('alert-configuration.edit', {
            parent: 'alert-configuration',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-edit-dialog.html',
                    controller: 'AlertConfigurationEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['AlertConfiguration', 'GlobalValues', function(AlertConfiguration, GlobalValues) {
                                     return AlertConfiguration.get({id : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('alert-configuration.view', {
            parent: 'alert-configuration',
            url: '/alertconfiguration/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-view-dialog.html',
                    controller: 'AlertConfigurationDetailsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    		translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('alertconfiguration');
                            return $translate.refresh();
                    		}],
                    		entity: ['AlertConfiguration', function(AlertConfiguration) {
                            return AlertConfiguration.get({id : $stateParams.id}).$promise;
                    		}],
                    		previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'AlertConfiguration',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                    		}]
                    	}
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('alert-configuration.delete', {
            parent: 'alert-configuration',
            url: '/delete/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-delete-dialog.html',
                    controller: 'AlertConfigurationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AlertConfiguration', 'GlobalValues', function(AlertConfiguration, GlobalValues) {
                            return AlertConfiguration.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('alert-configuration-todolist', {
            parent: 'dashboard',
            url: '/alertconfigurationview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['GlobalValues','$stateParams', '$state', '$uibModal', function(GlobalValues,$stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-view-dialog.html',
                    controller: 'AlertConfigurationDetailsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                      	 entity: ['AlertConfiguration', function(AlertConfiguration) {
                             //return AlertConfiguration.get({id : $stateParams.id}).$promise;
                 		}],
                		previousState: ["$state", function ($state) {
                			var currentStateData = {
                			name: $state.current.name || 'AlertConfiguration',
                			params: $state.params,
                			url: $state.href($state.current.name, $state.params)
                			};
                			return currentStateData;
                		}]
                               	 
                   
                       }
                   }).result.then(function() {
                       $state.go('^', {}, { reload: true });
                   }, function() {
                       $state.go('^');
                   });
               }]
               
           })
           .state('alert-configuration-todolist.resubmit', {
            parent: 'dashboard',
            url: '/alertconfigurationresubmit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['GlobalValues','$stateParams', '$state', '$uibModal', function(GlobalValues,$stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-dialog.html',
                    controller: 'AlertConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                      	 entity: function() {
                      		//return AutoUpload.get({corpID: GlobalValues.getCorpId(),id : $stateParams.id}).$promise;
                           }
                               	 
                   
                       }
                   }).result.then(function() {
                       $state.go('alert-configuration', null, { reload: 'alert-configuration' });
                   }, function() {
                       $state.go('^');
                   });
               }]
               
           })
           .state('alert-configuration-auditLog', {
            parent: 'audit-log',
            url: '/alertconfigurationview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['GlobalValues','$stateParams', '$state', '$uibModal', function(GlobalValues,$stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-view-dialog.html',
                    controller: 'AlertConfigurationDetailsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                      	 entity: ['AlertConfiguration', function(AlertConfiguration) {
                             //return AlertConfiguration.get({id : $stateParams.id}).$promise;
                 		}],
                		previousState: ["$state", function ($state) {
                			var currentStateData = {
                			name: $state.current.name || 'AlertConfiguration',
                			params: $state.params,
                			url: $state.href($state.current.name, $state.params)
                			};
                			return currentStateData;
                		}]
                               	 
                   
                       }
                   }).result.then(function() {
                       $state.go('^', {}, { reload: true });
                   }, function() {
                       $state.go('^');
                   });
               }]
               
           })
            .state('alert-configuration-historyReport', {
            parent: 'history-report',
            url: '/alertconfigurationview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['GlobalValues','$stateParams', '$state', '$uibModal', function(GlobalValues,$stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/alert-configuration/alert-configuration-view-dialog.html',
                    controller: 'AlertConfigurationDetailsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                      	 entity: ['AlertConfiguration', function(AlertConfiguration) {
                             //return AlertConfiguration.get({id : $stateParams.id}).$promise;
                 		}],
                		previousState: ["$state", function ($state) {
                			var currentStateData = {
                			name: $state.current.name || 'AlertConfiguration',
                			params: $state.params,
                			url: $state.href($state.current.name, $state.params)
                			};
                			return currentStateData;
                		}]
                               	 
                   
                       }
                   }).result.then(function() {
                       $state.go('^', {}, { reload: true });
                   }, function() {
                       $state.go('^');
                   });
               }]
               
           })
        };
    })();