(function() {
	'use strict';
	angular.module('gatewayApp').factory('BankRegistration', BankRegistration);

	BankRegistration.$inject = [ '$resource' ];

	function BankRegistration($resource) {
		var resourceUrl = 'api/bank/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			'getBankLookup' : {
				method : 'GET',
				isArray: true,
				url : 'api/bank/lookup'
			},

			'getBankLookupWithStatus' : {
				method : 'GET',
				isArray: true,
				url : 'api/bank-with-status/lookup'
			},

			'save' : {
				method : 'POST',
				params : {
					corpId : '@corpId'
				},
				url : 'api/bank/:corpId',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'update' : {
				method : 'PUT',
				params : {
					corpId : '@corpId'
				},
				url : 'api/bank/:corpId',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'delete' : {
				method : 'DELETE',
				params : {
					corpId : '@corpId',
					id : '@id'
				},
				url : 'api/bank/:corpId/:id'
			},

			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},
			 'getUserNo': {
           	  method: 'GET',
           	  params: {MemberNo:'@MemberNo'},
           	  url: 'api/MemberNo/:MemberNo/bank'
            },
			'resubmitForCreate' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmitForCreate/bank',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'resubmitForEdit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmitForEdit/bank',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'getBankForShortName':{
				method : 'GET',
				params : {
					bankName : '@bankName'
				},
				url : 'api/trading-bank/bankName/:bankName',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'getValidBankWithValidStatus':{
				method : 'GET',
				url : 'api/valid-bank-for-registration',
				isArray : true
			},
			'getNonTerminatedBankList':{
				method : 'GET',
				url : 'api/non-terminated-bank',
				isArray : true
			}

		});
	}
})();
