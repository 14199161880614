(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('default-markup-settings', {
            parent: 'entity',
            url: '/default-markup-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.defaultMarkupSettings.main" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings.html',
                    controller: 'DefaultMarkupSettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('defaultMarkupSettings');
                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
        .state('default-markup-view', {
            parent: 'default-markup-settings',
            url: '/default-markup-settings/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings-view-dialog.html',
                    controller: 'DefaultMarkupSettingsViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	
                            	 entity: ['DefaultMarkup', 'GlobalValues', function(DefaultMarkup, GlobalValues) {
                                     return DefaultMarkup.get({id : $stateParams.id}).$promise;
                                 }], 
                                 markupTypes: ['StaticLookUp', function(StaticLookUp){
                                	 return StaticLookUp.getMarkupTypes().$promise;
                                 }],
                                 roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp){
                                    return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                                }]
                               
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('default-markup-edit', {
            parent: 'default-markup-settings',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings-edit-dialog.html',
                    controller: 'DefaultMarkupSettingsEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	
                            	 entity: ['DefaultMarkup', 'GlobalValues', function(DefaultMarkup, GlobalValues) {
                                     return DefaultMarkup.get({id : $stateParams.id}).$promise;
                                 }], 
                                 markupTypes: ['StaticLookUp', function(StaticLookUp){
                                	 return StaticLookUp.getMarkupTypes().$promise;
                                 }],
                                 roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp){
                                    return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                                }]
                               
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('default-markup-todolist', {
            parent: 'dashboard',
            url: '/defaultMarkupSettings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings-view-dialog.html',
                    controller: 'DefaultMarkupSettingsViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }, 
                        markupTypes: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('default-markup-settings', null, { reload: 'default-markup-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('default-markup-todolist.resubmit', {
            parent: 'dashboard',
            url: '/defaultMarkupSettingsEdit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings-edit-dialog.html',
                    controller: 'DefaultMarkupSettingsEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                      entity: function () {                       	
                          
                        }, 
                        markupTypes: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('default-markup-settings', null, { reload: 'default-markup-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
          .state('default-markup-auditLog', {
            parent: 'audit-log',
            url: '/defaultMarkupSettingsAuditLog',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings-view-dialog.html',
                    controller: 'DefaultMarkupSettingsViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }, 
                        markupTypes: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('default-markup-settings', null, { reload: 'default-markup-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('default-markup-historyReport', {
            parent: 'history-report',
            url: '/defaultMarkupettingsHistory',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/default-markup-settings/default-markup-settings-view-dialog.html',
                    controller: 'DefaultMarkupSettingsViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }, 
                        markupTypes: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getMarkupTypes().$promise;
                        }],
                        roEdApplicabilityMethods: ['StaticLookUp', function(StaticLookUp){
                       	 return StaticLookUp.getRoEdApplicabilityMethodList().$promise;
                        }]
                    }
                }).result.then(function() {
                	$state.go('default-markup-settings', null, { reload: 'default-markup-settings' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
         
         };
    })();
