(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('branch', {
            parent: 'entity',
            params:{screen: 'BranchRegistration'},
            url: '/branch',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.branch.manageBranchRegistration" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/branch/branch.html',
                    controller: 'BranchController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('branch');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        .state('branch.new', {
            parent: 'branch',
            url: '/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-dialog.html',
                    controller: 'BranchDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {
                            	 id:null,  
                            	name:null                            	
                             
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('branch', null, { reload: 'branch' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('branch.view', {
            parent: 'branch',
            url: '/branch/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-detail.html',
                    controller: 'BranchViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['Branch', 'GlobalValues', function(Branch, GlobalValues) {
                                     return Branch.get({id : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('branch.edit', {
            parent: 'branch',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-dialog.html',
                    controller: 'BranchDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity: ['Branch', 'GlobalValues', function(Branch, GlobalValues) {
                                     return Branch.get({id : $stateParams.id}).$promise;
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('branch.delete', {
            parent: 'branch',
            url: '/delete/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-delete-dialog.html',
                    controller: 'BranchDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Branch', 'GlobalValues', function(Branch, GlobalValues) {
                            return Branch.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    //$state.go('^', null, { reload: true });
                	$state.go('branch', null, { reload: 'branch' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
         .state('branch-todolist', {
            parent: 'dashboard',
            url: '/branchview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-detail.html',
                    controller: 'BranchViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('branch', null, { reload: 'branch' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('branch-todolist.resubmit', {
            parent: 'dashboard',
            url: '/branchedit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-dialog.html',
                    controller: 'BranchDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        }
                    }
                }).result.then(function() {
                	$state.go('branch', null, { reload: 'branch' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('branch-auditLog', {
            parent: 'audit-log',
            url: '/branchview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-detail.html',
                    controller: 'BranchViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('branch', null, { reload: 'branch' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        .state('branch-historyReport', {
            parent: 'history-report',
            url: '/branchview',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/branch/branch-detail.html',
                    controller: 'BranchViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('branch', null, { reload: 'branch' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })

        .state('home-branch-filter', {
            parent: 'branch.new',
            url: '/fetch-branch/{filterText}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/home-branch-filter/home-branch-filter.html',
                    controller: 'HomeBranchFilterController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('branch.new', null, { reload: 'branch.new' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })

        .state('home-branch-filter-resubmit', {
            parent: 'branch-todolist.resubmit',
            url: '/fetch-branch-resubmit/{filterText}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/home-branch-filter/home-branch-filter.html',
                    controller: 'HomeBranchFilterController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        }
                    }
                }).result.then(function() {
                	$state.go('branch.new', null, { reload: 'branch.new' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         };
    })();
