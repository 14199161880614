(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {
            this.viewFrom = 'orderActivityLog'
        })

        .controller('OrderActivityLogController', OrderActivityLogController);

        OrderActivityLogController.$inject = ['$scope', '$state', 'OrderActivityLog', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog', 'entityConstants',
        '$timeout', 'DateUtils', 'AlertService', '$filter'];

    function OrderActivityLogController($scope, $state, OrderActivityLog, GlobalValues, workflowSharedService, EntityPermissions, ScreenLog, entityConstants, $timeout, DateUtils,
        AlertService, $filter) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORDER_ACTIVITY_LOG;
        vm.globalValues = GlobalValues;
        vm.screenLog = ScreenLog;

        vm.todayDate = new Date();
        vm.reportDate = angular.copy(vm.todayDate);
        vm.minDate = DateUtils.getSubtractMonth(vm.todayDate,1);
        vm.maxDate = angular.copy(vm.todayDate);

       
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.downloadFileType ="CSV" ;
        vm.downloadFileTypeIndicators = ["CSV","XLS"];
        vm.exportOrderActivityLogToFiles = exportOrderActivityLogToFiles;
        vm.clicked=false;
        logScreenAccess();
        setUsers();
        function setUsers() {
            if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
                vm.loggedInCustomer = vm.globalValues.getCustomerId();
           }
           if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
               vm.bank = vm.globalValues.getBank();
           }
           if(vm.entityOwnerType=='Branch'){
               vm.branch = vm.globalValues.getBranch();
           }
            
        }
  

		function prepareDataForCustomer() {
			if(vm.entityOwnerType == 'SingleUserCustomer' || vm.entityOwnerType == 'MultiUserCustomer')
				vm.customerId = vm.globalValues.getOrgId();
		}
		function prepareDataForOthers() {
			// if  bank user
			if (vm.entityOwnerType == 'Bank') {
				vm.bankId =  vm.globalValues.getOrgId();
			}
			// if branch user
			if (vm.entityOwnerType == 'Branch') {
				vm.bankId =  vm.globalValues.getOrgId();
				vm.branchId = vm.globalValues.getBranchId();
			}
			//if user manualy select bank and branch
			if(vm.bank!=null && vm.bank!=undefined){
    		   vm.bankId = vm.bank.id;
    	   	  }
    	   if(vm.branch!=null && vm.branch!=undefined){
    		   vm.branchId = vm.branch.id;
    	     }
            if (vm.customer != null && vm.customer != undefined) {
                vm.customerId = vm.customer.id;
		}

    }

        // download order activity log report 
        function exportOrderActivityLogToFiles() {
            vm.bankId = 0;
            vm.branchId = 0;
            vm.customerId = 0;
            vm.instrumentId = 0;
            if (moment(vm.reportDate).isBefore(vm.minDate, 'day')) {
				AlertService.error($filter('translate')('gatewayApp.orderActivityLog.isBeforDate'));
				vm.valid = false;
			}else {
                vm.clicked=true;
                //prepare data to server for customers
                prepareDataForCustomer();
                //prepare data to server for bank,branch and clearcorp
                prepareDataForOthers();
                } 
                if (vm.instrument != null && vm.instrument != undefined) {
                    vm.instrumentId = vm.instrument.id;
                }
                var reportDate = DateUtils.convertLocalDateToServer(vm.reportDate);
              
	    
                if (vm.downloadFileType != null && vm.downloadFileType != undefined && vm.downloadFileType != "") {

                    vm.baseString = 'OrderActivityLog_' +moment(vm.reportDate).format('DD_MMM_YYYY');


                    if (vm.downloadFileType == 'CSV') {
                        vm.mimeType = 'text/csv'
                        vm.baseString = vm.baseString + ".csv";
                        OrderActivityLog.exportOrderActivityLogCSV ({"bankId":vm.bankId, "branchId": vm.branchId, "customerId": vm.customerId,"instrumentId": vm.instrumentId,"reportDate":reportDate}, processResponse,processfailde );
                    } else if (vm.downloadFileType == 'XLS') {
                        vm.mimeType = 'application/vnd.ms-excel';
                        vm.baseString = vm.baseString + ".xls";
                        OrderActivityLog.exportOrderActivityLogExcel({ "bankId":vm.bankId, "branchId": vm.branchId, "customerId": vm.customerId,"instrumentId": vm.instrumentId,"reportDate":reportDate}, processResponse,processfailde);
                    }
                }
            }

        function processResponse(result) {
            vm.clicked=false;
            var fileData = null;
            fileData = result.data;
            var link = document.createElement("a");
            var bytes = new Uint8Array(fileData);   // convert uint8 to binary
            if(fileData  !=null && fileData !=undefined && fileData.byteLength > 0){
            var blob = new Blob([bytes], { type: vm.mimeType });
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, vm.baseString);
            } else {
                link.href = window.URL.createObjectURL(blob);
                link.download = vm.baseString;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }else{
            AlertService.error("No Records Found");
        }
    }
    function processfailde(){
        vm.clicked=false;
    }
    function logScreenAccess(){
        ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
    }

  
}
})();