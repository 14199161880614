(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OrderConfigController', OrderConfigController);

    OrderConfigController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', 'UserProfile', 'GlobalValues', '$filter', 'ExposureType', 'ExposureSubType'];

    function OrderConfigController($scope, $rootScope, $state, $uibModalInstance, UserProfile, GlobalValues, $filter, ExposureType, ExposureSubType) {
        var vm = this;
        vm.clear=clear;
        vm.globalValues = GlobalValues;
        vm.save=save;
        $scope.clicked=false;
        loadAll();
        vm.configSettings = vm.globalValues.getConfigSettings();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
		vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
		vm.noOfdecimalsForPaise = vm.globalValues.getNumberOfDecimalsForRateInReports();
        vm.minimumOrderValue = vm.configSettings.minimumOrderValue;
        vm.maximumOrderValueHardCheck = vm.configSettings.maximumOrderValueHardCheck;
        vm.allowedOrderPriceDeviationFromMarketPriceInPercentage = vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPercentage;
        vm.allowedOrderPriceDeviationFromMarketPriceInPaise = vm.configSettings.allowedOrderPriceDeviationFromMarketPriceInPaise;
        vm.isAllowStopLoss = vm.configSettings.isAllowStopLossOrder;
        vm.isAllowTakeProfit = vm.configSettings.isAllowTakeProfitOrder;
        vm.isAllowMarketOrder = vm.configSettings.isAllowMarketOrder;
        vm.errorText1 = '';
        vm.errorText2 = '';
        vm.errorText3 = '';
        vm.errorText4 = '';
        vm.errorText5 = '';
        vm.exposureType = null;
        vm.exposureSubType = null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function loadAll(){
        	UserProfile.get({
				"userId" : vm.globalValues.getUserId()
			}, onGetUserProfileSuccess, onGetUserProfileError);
        }
        
        function onGetUserProfileSuccess(result){
        	vm.userprofile = result;
        	if(vm.userprofile.userPriceCondition=='STLS' && vm.isAllowStopLoss!='Yes'){
        		vm.userprofile.userPriceCondition = 'LIMT';
        	}
        	if(vm.userprofile.userPriceCondition=='TKPR' && vm.isAllowTakeProfit!='Yes'){
        		vm.userprofile.userPriceCondition = 'LIMT';
        	}
            //load lookup and values for exposure type and exposure sub type
            loadLookupAndValues(vm.userprofile.userExposureType, vm.userprofile.userExposureSubType);
        }
        
        //load lookup and values for exposure type and exposure sub type
        function loadLookupAndValues(userExposureType, userExposureSubType){
            loadExposureTypeLookup(userExposureType);
            loadExposureSubTypeLookup(userExposureSubType);
        }
        
        //load lookup and value for exposure type 
        function loadExposureTypeLookup(userExposureType){
            ExposureType.getEnabledExposureTypes(function(result){
                vm.exposureTypes = result;
                angular.forEach(vm.exposureTypes, function(value){
                    if(value.name==userExposureType){
                        vm.exposureType = value;
                    }
                })
                if(vm.exposureType.id==null || vm.exposureType.id==undefined){
                    vm.exposureType = null;
                }
            })
        }
            
        //load lookup and value for exposure sub  type 
        function loadExposureSubTypeLookup(userExposureSubType){
            ExposureSubType.getEnabledExposureSubTypes(function(result){
                vm.exposureSubTypes = result;
                angular.forEach(vm.exposureSubTypes, function(value){
                    if(value.name==userExposureSubType){
                        vm.exposureSubType = value;
                    }
                })
                if(vm.exposureSubType.id==null || vm.exposureSubType.id==undefined){
                    vm.exposureSubType = null;
                }
            })
        }

        vm.validateOrderValue = function validateOrderValue(value){
        	vm.errorText1 = '';
        	if(vm.userprofile!=undefined && vm.userprofile.userMaximumOrderValueHardCheck!=undefined && Number(vm.userprofile.userMaximumOrderValueHardCheck) > Number(vm.minimumOrderValue)){
	        	if(Number(value) < Number(vm.minimumOrderValue) || Number(value) > Number(vm.userprofile.userMaximumOrderValueHardCheck)){
	        		vm.errorText1 = 'Value should be in between ' + $filter('number')(vm.minimumOrderValue,vm.noOfDecimalsForAmount) + ' and ' + $filter('number')(vm.userprofile.userMaximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
	        	}
        	}
        }
        
        vm.validateAllowedOrderPriceDeviationFromMarketPriceInPercentage = function validateAllowedOrderPriceDeviationFromMarketPriceInPercentage(value){
        	vm.errorText2 = '';
        	if(Number(value) > Number(vm.allowedOrderPriceDeviationFromMarketPriceInPercentage)){
        		vm.errorText2 = "Value should be less than or equal to Clearcorp's hard check value " + $filter('number')(vm.allowedOrderPriceDeviationFromMarketPriceInPercentage) + '.';
        	}else if(Number(value) <= 0){
        		vm.errorText2 = "Value should be greater than 0.";
        	}
        }
        
        vm.validateMaximumOrderValueHardCheck = function validateMaximumOrderValueHardCheck(value){
        	vm.errorText3 = '';
        	if(Number(value) > Number(vm.maximumOrderValueHardCheck)){
        		vm.errorText3 = "Value should be less than or equal to Clearcorp's maximum order value hard check " + $filter('number')(vm.maximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
        	}else if(Number(value) <= Number(vm.minimumOrderValue)){
        		vm.errorText3 = "Value should be greater than Clearcorp's minimum order value " + $filter('number')(vm.minimumOrderValue,vm.noOfDecimalsForAmount) + '.';
        	}else if(Number(value) <= 0){
        		vm.errorText3 = "Value should be greater than 0.";
        	}
        }
        
        vm.validateMaximumOrderValueSoftCheck = function validateMaximumOrderValueSoftCheck(value){
        	vm.errorText4 = '';
        	if(vm.userprofile!=null && vm.userprofile!=undefined){
	        	if(Number(value) > Number(vm.maximumOrderValueHardCheck)){
                    vm.errorText4 = "Value should be less than or equal to Clearcorp's maximum order value hard check " + $filter('number')(vm.maximumOrderValueHardCheck,vm.noOfDecimalsForAmount) + '.';
                }else if(Number(value) > Number(vm.userprofile.userMaximumOrderValueHardCheck)){
	        		vm.errorText4 = "Value should be less than or equal to user level maximum order value hard check " + $filter('number')(vm.userprofile.userMaximumOrderValueHardCheck) + '.';
	        	}else if(Number(value) <= 0){
	        		vm.errorText4 = "Value should be greater than 0.";
	        	}
        	}
        }
        
        vm.validateAllowedOrderPriceDeviationFromMarketPriceInPaise = function validateAllowedOrderPriceDeviationFromMarketPriceInPaise(value){
        	vm.errorText5 = '';
        	if(Number(value) > Number(vm.allowedOrderPriceDeviationFromMarketPriceInPaise)){
        		vm.errorText5 = "Value should be less than or equal to Clearcorp's soft check value " + $filter('number')(vm.allowedOrderPriceDeviationFromMarketPriceInPaise) + '.';
        	}else if(Number(value) <= 0){
        		vm.errorText5 = "Value should be greater than 0.";
        	}
        }
        
        function onGetUserProfileError(){
        	
        }
        
        function save(){
            if(vm.exposureType!=null||vm.exposureType!=undefined ){
                vm.userprofile.userExposureType = vm.exposureType.name;
            }

            if(vm.exposureSubType!=null||vm.exposureSubType!=undefined ){
                vm.userprofile.userExposureSubType = vm.exposureSubType.name;
            }

			UserProfile.updatePreferences(vm.userprofile, onSaveSuccess, onSaveError);
        }
        
        function onSaveSuccess(result){
            loadAll();
        	$uibModalInstance.close(result);
        }
        
        function onSaveError(){
        	 $scope.clicked=true;
        }
    }   
})();
