(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'clearcorpBankTACView'
	})

	.controller('ClearcorpBankTACViewController', ClearcorpBankTACViewController);

	ClearcorpBankTACViewController.$inject = ['$scope','$rootScope','$state',  'GlobalValues', 'workflowSharedService', '$uibModalInstance','EntityPermissions', 'ScreenLog','entityConstants','$timeout','entity','DateUtils','ClearcorpTACUpload','TacViewForEntitiesUtility'];

	function ClearcorpBankTACViewController($scope,$rootScope,$state, GlobalValues, workflowSharedService, $uibModalInstance, EntityPermissions, ScreenLog,entityConstants,$timeout,entity,DateUtils,ClearcorpTACUpload,TacViewForEntitiesUtility) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.TERMS_AND_CONDITIONS_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.clear=clear;
		vm.bankTermsAndConditionsList=entity;
		vm.openTACPreview = openTACPreview;
		vm.exportToFile = exportToFile;
		 vm.permitTACViewEntityAction = EntityPermissions.hasPermission('TermsAndConditionsView.VIEW');

		function exportToFile(fileType){
			vm.baseString =   'Bank\'s_Terms_And_Conditions_For_Customers_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			if( vm.bankTermsAndConditionsList !=null &&  vm.bankTermsAndConditionsList !=undefined){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv'
						vm.baseString = vm.baseString+ ".csv";  
					ClearcorpTACUpload.getCSVForBanksTermsAndConditionsView(vm.bankTermsAndConditionsList , processResponse );
				}else if(fileType == 'excel'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls";  
					ClearcorpTACUpload.getExcelForBanksTermsAndConditions(vm.bankTermsAndConditionsList , processResponse );
				}
			}
		}	
		function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}

		function openTACPreview(bankTACClearcorp){
			var fd1 = new FormData();
			var contentByteArray = base64ToArrayBuffer(bankTACClearcorp.tacMaster.tacFiles.fileContent);
			TacViewForEntitiesUtility.previewTACForArray(contentByteArray, "Banks's Terms and Conditions for Customer.pdf");	
		}
		
		function base64ToArrayBuffer(base64) {
    	    var binary_string =  window.atob(base64);
    	    var len = binary_string.length;
    	    var bytes = new Uint8Array( len );
    	    for (var i = 0; i < len; i++)        {
    	        bytes[i] = binary_string.charCodeAt(i);
    	    }
    	    return bytes.buffer;
    	}
        

		logScreenAccess(); // screen log required for view agreed banks
		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.VIEW_BANKS_TAC_FOR_CUSTOMERS);
		}


		function clear () {
			$uibModalInstance.dismiss('cancel');
		}
		

	
	}
})();
