(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$sessionStorage', 'EntityPermissions', 'GlobalValues', 'User', 
    	'Account','OrderStatusDetails','MessageBox','$rootScope','$scope', '$localStorage', 'WebSocket', 'Idle', '$fileLogger'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, $sessionStorage, EntityPermissions, GlobalValues, User, 
    		Account, OrderStatusDetails, MessageBox, $rootScope,$scope, $localStorage, WebSocket, Idle, $fileLogger) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.globalValues = GlobalValues;
        var yesMessageEvent = null;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            if($rootScope.eodInProgress!=null && $rootScope.eodInProgress!=undefined && $rootScope.eodInProgress==true){
                $fileLogger.info('user logged out');
            }
        	OrderStatusDetails.getPendingOrdersForUser(onGettingPendingOrderSuccess, onGettingPendingOrderFailure);
        	
        }
        
        function logoutAccount(){
        	//update session log
        	User.updateSessionLog(onSessionUpdateSuccess, onSessionUpdateFailure);
        }
        
        function onSessionUpdateSuccess(){
            Account.logout(function(result){
                var orgTypeParam = GlobalValues.getLoginUrlParam();
                GlobalValues.clearPreviousValues();
                EntityPermissions.clearEntityPermission();
                $sessionStorage.isAuth = false;
                collapseNavbar();
                Auth.logout();
                //$localStorage.authenticationToken = null;
                $sessionStorage.authenticationToken = null;
                WebSocket.disconnecteWebSocket(true);
                Idle.unwatch();
                $state.go('login', {orgType : orgTypeParam}, {reload:'login'});
                //window.location.reload();
            });
        }
        
        function onSessionUpdateFailure(){
        	
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
        
        function onGettingPendingOrderSuccess(result){
        	if(result.length > 0){
        		MessageBox.openMessageBox("Alert", "There are pending orders...! Are you sure to logout?", "YesNo", "pendingOrderSignoutAlert");
        	}else{
        		logoutAccount();
        	}
        }
        
        function onGettingPendingOrderFailure(){
        	
        }
        
        yesMessageEvent = $rootScope.$on('gatewayApp.messagebox.yes.pendingOrderSignoutAlert', function (event, data) {
        	logoutAccount();
        });
        
        $scope.$on('$destroy', function() {
    		  if (yesMessageEvent!=null )
    			  	yesMessageEvent();
  	        });
          
  		
  		$rootScope.$on('gatewayApp.messagebox.no.pendingOrderSignoutAlert', function (event, data) {
  			//do nothing
      	});
    }
})();
