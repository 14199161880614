(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ExecuteAlertNotifications', ExecuteAlertNotifications);

    ExecuteAlertNotifications.$inject = ['$resource'];

    function ExecuteAlertNotifications ($resource) {
        var resourceUrl =  'api/executeAlertNotifications';

        return $resource(resourceUrl, {}, {
            'execute': {
                method: 'POST',
            	params: {executeDate:'@executeDate'},
                url: 'api/execute-alert-notifications/:executeDate',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
            'executeAlertsForBUAndEvents': {
                method: 'POST',
            	params: {executeDate:'@executeDate', corpId: '@corpId', branchId: '@branchId', eventId: '@eventId'},
                url: 'api/execute-notifications/:executeDate/:corpId/:branchId/:eventId',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
        	'getAllCorporates': {
	            method: 'GET',
	            isArray: true,
	            url: 'api/corporate-organisations',
	            transformResponse: function (data) {
	                if (data) {
	                    data = angular.fromJson(data);
	                }
	                return data;
	            }
        	},
        	'getAllBanks': {
	            method: 'GET',
	            isArray: true,
	            url: 'api/bank-organisations',
	            transformResponse: function (data) {
	                if (data) {
	                    data = angular.fromJson(data);
	                }
	                return data;
	            }
        	},
        	'getAllBranches': {
	            method: 'GET',
	            isArray: true,
	            params: {bankId:'@bankId'},
	            url: 'api/bank/:bankId/cleared-branches',
	            transformResponse: function (data) {
	                if (data) {
	                    data = angular.fromJson(data);
	                }
	                return data;
	            }
        	}
       });
    }
})();
            