(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HomePageReportsController', HomePageReportsController);

    HomePageReportsController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', '$stateParams', 
    'GlobalValues', 'OrderStatusDetails', 'MessageBox', '$filter', 'DateUtils', 'DefaultMarket', 'LimitSettings',
    'TradesDownload', 'GenerateDealTicket','CustomerBankRelationshipReport', 'EntityPermissions', '$controller',
    'MarketWatch'];

    function HomePageReportsController($scope, $rootScope, $state, $uibModalInstance, $stateParams, 
        GlobalValues, OrderStatusDetails, MessageBox, $filter, DateUtils, DefaultMarket, LimitSettings,
        TradesDownload, GenerateDealTicket,CustomerBankRelationshipReport, EntityPermissions, $controller,
        MarketWatch) {
        var vm = this;
        
        vm.clear=clear;
        vm.hasPermission = EntityPermissions.hasPermission;

        vm.globalValues = GlobalValues;
        vm.noOfRecordsPerPageOP = 10;
        vm.pageOP = 1;
        vm.noOfRecordsPerPageTrade = 10;
        vm.pageTrade = 1;
        vm.noOfRecordsPerPageOT = 10;
        vm.pageOT = 1;
        vm.noOfRecordsPerPageLimit = 10;
        vm.pageLimit = 1;

        //initialize variables
        vm.customerFilterForPO=$rootScope.customerFilterForPO;
        vm.customerFilterForLimit=$rootScope.customerFilterForLimit;
        vm.customerFilterForTrade=$rootScope.customerFilterForTrade;
        vm.customerFilterForOT=$rootScope.customerFilterForOT;

        vm.bankFilterForPO=$rootScope.bankFilterForPO;
        vm.bankFilterForLimit=$rootScope.bankFilterForLimit;
        vm.bankFilterForTrade=$rootScope.bankFilterForTrade;
        vm.bankFilterForOT=$rootScope.bankFilterForOT;

        vm.branchFilterForPO=$rootScope.branchFilterForPO;
        vm.branchFilterForLimit=$rootScope.branchFilterForLimit;
        vm.branchFilterForTrade=$rootScope.branchFilterForTrade;
        vm.branchFilterForOT=$rootScope.branchFilterForOT;

        vm.instrumentFilterForPO=$rootScope.instrumentFilterForPO;
        vm.instrumentFilterForLimit=$rootScope.instrumentFilterForLimit;
        vm.instrumentFilterForTrade=$rootScope.instrumentFilterForTrade;
        vm.instrumentFilterForOT=$rootScope.instrumentFilterForOT;

        vm.fromSettlementDate = $rootScope.fromSettlementDate;
        vm.toSettlementDate = $rootScope.toSettlementDate;
        vm.selectAll = $rootScope.selectAll;

        
        vm.orderStatusList = $rootScope.orderStatusList;

        // angular.extend(this, $controller('HomeController', {
        //     $scope : $scope, $uibModalInstance: $uibModalInstance, reload:false
        // }));

        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.poDetails = true;
        vm.tradeDetails = false;
        vm.otDetails = false;
        vm.limitDetails = false;
        vm.reportTab = $rootScope.reportTab;

        vm.permitModifyOrderEntry = vm.hasPermission('OrderEntry.EDIT');
        vm.permitCancelOrderEntry = vm.hasPermission('OrderEntry.DELETE');
        vm.permitCustomerLimitSettings = vm.hasPermission('CustomerLimitChangeRequest');

        //actions
        vm.modifyOrder = modifyOrder;
        vm.cancelOrder = cancelOrder;
        vm.cancelPendingOrdersSelected = cancelPendingOrdersSelected;
        vm.cancelAllOrders = cancelAllOrders;
        vm.cancelTrade = cancelTrade;
        vm.rolloverTrade = rolloverTrade;
        vm.earlyDeliveryTrade = earlyDeliveryTrade;
        vm.checkOrUncheckPendingOrderItem = checkOrUncheckPendingOrderItem;
        vm.checkOrUncheckPendingOrders = checkOrUncheckPendingOrders;
        vm.checkOrUncheckOutstandingTradesItem= checkOrUncheckOutstandingTradesItem;
        vm.changeRequest = changeRequest;
        vm.newLimitRequest = newLimitRequest;

        vm.getBuyingPower = getBuyingPower;
        vm.getSellingPower = getSellingPower;
        vm.getExposure = getExposure;
        vm.printDealTicket = printDealTicket;
        vm.printDealTicketOutstandingTrade = printDealTicketOutstandingTrade;

        if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
            vm.custCode= vm.globalValues.getCustomer().customerCode;
        }

        DefaultMarket.marketInstruments(onGetInstrumentSuccess, onGetInstrumentFailure);

        function onGetInstrumentSuccess(result){
            vm.instrumentTypes = result;
        }

        function onGetInstrumentFailure(){
            
        }

        //initialize methods
        vm.getPendingOrdersReport = getPendingOrdersReport;
        vm.getTradesReport = getTradesReport;
        vm.getOutstandingTradesReport = getOutstandingTradesReport; 
        vm.getLimitUtilisedReport = getLimitUtilisedReport;

        //implementation of methods
        //get pending orders report
        function getPendingOrdersReport(){
            vm.clickedReport=true;
            var instrumentTypeId = 0;
            if(vm.instrumentFilterForPO!=null && vm.instrumentFilterForPO!=undefined){
                instrumentTypeId = vm.instrumentFilterForPO.id;
            }

            getPendingOrderReportForInstrument(instrumentTypeId);
            syncInputsToHomePage();
        }
        

        // get pending orders reports for given instrument
        function getPendingOrderReportForInstrument(){
            var bankId = getBankIdForReportFilters('PO');
            var branchId = getBranchIdForReportFilters('PO');
            var customerId = getCustomerIdForReportFilters('PO');
            var instrumentId = getInstrumentIdForReportFilters('PO');

            if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP'){
                getPendingOrders(bankId, branchId, customerId,instrumentId);
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
                if(bankId!=0){
                    getPendingOrders(bankId, branchId, customerId,instrumentId);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
                if(bankId!=0 && branchId!=0){
                    getPendingOrders(bankId, branchId, customerId,instrumentId);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if(customerId!=0){
                    getPendingOrders(bankId, branchId, customerId,instrumentId);
                }
            }
        }

        function getPendingOrders(bankId, branchId, customerId, instrumentId){
            
            OrderStatusDetails.getPendingOrders({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetPendingOrdersSuccess, onGetPendingOrdersFailure);
            
        }
        
        function onGetPendingOrdersSuccess(result){
            $rootScope.pendingOrders = result;
            vm.clickedReport=false;
            angular.forEach($rootScope.pendingOrders, function(value, index){
                angular.forEach($rootScope.orderStatusList, function(orderStatus){
                    if(value.orderStatusId == orderStatus.id){
                        $rootScope.pendingOrders[index].orderStatus = orderStatus.name;
                    }
                    value.expiryTimeString=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
                })
            })
        }
        
        function onGetPendingOrdersFailure(){
            vm.clickedReport=false;  
        }

        vm.getMomentFromOrderRevisionTimeStamp =  function(timestampString){
            return moment(timestampString,'YYYYMMDDHHmmss.SSSSSSSSS').format();
        }

        //get outstanding trades report
        function getOutstandingTradesReport(){
            vm.clickedReport=true;
            var instrumentTypeId = 0;
            if(vm.instrumentFilterForOT!=null && vm.instrumentFilterForOT!=undefined){
                instrumentTypeId = vm.instrumentFilterForOT.id;
            }

            if(validateTradesOutstandingDates(vm.fromSettlementDate, vm.toSettlementDate)){
                getOutstandingTradeReportForInstrument(instrumentTypeId);
            }else{
                MessageBox.openMessageBox("Alert","From date cannot be greater than the To date.", "Ok", "homepage");
            }
            syncInputsToHomePage();
        }

        function getOutstandingTradeReportForInstrument(){
            var bankId = getBankIdForReportFilters('OT');
            var branchId = getBranchIdForReportFilters('OT');
            var customerId = getCustomerIdForReportFilters('OT');
            var instrumentId = getInstrumentIdForReportFilters('OT');
            
            
            if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP'){
                getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
                if(bankId!=0){
                    getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
                if(bankId!=0 && branchId!=0){
                    getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if(customerId!=0){
                    getOutstandingTrades(bankId, branchId, customerId,instrumentId,vm.fromSettlementDate,vm.toSettlementDate);
                }
            }
        }

        function getOutstandingTrades(bankId, branchId, customerId, instrumentId, fromDate, toDate){

            var fromSettDate = null
            var toSettDate = null;
            
            //if the from date is null then provide the minimum possible date , else send the selected date 
            if(fromDate == null && fromDate == undefined){
                fromSettDate = new Date(1900,0,1);
            }else{
                fromSettDate = fromDate;
            }

            // if to date is null then provide the maximum possible date , else send the selected date 
            if(toDate == null && toDate == undefined){
                toSettDate = new Date(9999,11,31); 
            }else{
                toSettDate = toDate; 
            }

            OrderStatusDetails.getTradesOutstandingList({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId, fromSetDate : DateUtils.convertLocalDateToServer(fromSettDate),toSetDate :DateUtils.convertLocalDateToServer(toSettDate) }, onOutstandingTradesSuccess, onGetOutstandingTradesFailure);

            // if(moment(fromSettDate).isAfter(toSettDate,'day')){
            //  MessageBox.openMessageBox("Alert","From date cannot be greater than the To date.", "Ok", "homepage");
            // }

            // else{
            //  OrderStatusDetails.getTradesOutstandingList({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId, fromSetDate : DateUtils.convertLocalDateToServer(fromSettDate),toSetDate :DateUtils.convertLocalDateToServer(toSettDate) }, onOutstandingTradesSuccess, onGetOutstandingTradesFailure);
            // }
        }
               
        function validateTradesOutstandingDates(fromDate, toDate){
            var validFilterDates = true;
            var fromSettDate = null
            var toSettDate = null;
            
            //if the from date is null then provide the minimum possible date , else send the selected date 
            if(fromDate == null && fromDate == undefined){
                fromSettDate = new Date(1900,0,1);
            }else{
                fromSettDate = fromDate;
            }

            // if to date is null then provide the maximum possible date , else send the selected date 
            if(toDate == null && toDate == undefined){
                toSettDate = new Date(9999,11,31); 
            }else{
                toSettDate = toDate; 
            }

            if(moment(fromSettDate).isAfter(toSettDate,'day')){
                validFilterDates = false;
            }

            return validFilterDates;
        }
        
        function onOutstandingTradesSuccess(result){
            
            angular.forEach(result,function(value){
                value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
                value.select = false;
            });
            
            $rootScope.outstandingTrades = result;
            vm.clickedReport=false;
        }
        
        function onGetOutstandingTradesFailure(){
            vm.clickedReport=false;
        }

        function getTradesReport(){
            vm.clickedReport=true;
            var instrumentTypeId = 0;
            if(vm.instrumentFilterForTrade!=null && vm.instrumentFilterForTrade!=undefined){
                instrumentTypeId = vm.instrumentFilterForTrade.id;
            }
            
            getTradesReportForInstrument(instrumentTypeId);
            syncInputsToHomePage();
        }

        // get trades report for given instrument
        function getTradesReportForInstrument(){
            var bankId = getBankIdForReportFilters('Trade');
            var branchId = getBranchIdForReportFilters('Trade');
            var customerId = getCustomerIdForReportFilters('Trade');
            var instrumentId = getInstrumentIdForReportFilters('Trade');

            if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP'){
                getTrades(bankId, branchId, customerId, instrumentId);
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
                if(bankId!=0){
                    getTrades(bankId, branchId, customerId, instrumentId);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
                if(bankId!=0 && branchId!=0){
                    getTrades(bankId, branchId, customerId, instrumentId);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if(customerId!=0){
                    getTrades(bankId, branchId, customerId, instrumentId);
                }
            }
        }
        
        function getTrades(bankId, branchId, customerId, instrumentId){
            
            OrderStatusDetails.getTradesForSelectedInstrument({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetTradesSuccess, onGetTradesFailure);
            
        }
        
        function onGetTradesSuccess(result){
            vm.clickedReport=false;
            angular.forEach(result,function(value){
                value.tradeTime=moment(value.tradeTime,'YYYYMMDDHHmmss.SSSSSSSSS').format();
            });
            
             
            vm.summaryBuyTrades = result.filter(function(val){
                return val.buySellIndicator == "BUYY";
            })
            
            vm.summarySellTrades = result.filter(function(val){
                return val.buySellIndicator == "SELL";
            })
            
             vm.totalUSDBought = vm.summaryBuyTrades.reduce(function (a,b) { return a + b.tradedAmount; }, 0);
             vm.totalUSDSold = vm.summarySellTrades.reduce(function (a,b) { return a + b.tradedAmount; }, 0);
             vm.totalINRSold = vm.summaryBuyTrades.reduce(function (a,b) { return a + b.tradedValue; }, 0);
             vm.totalINRBought = vm.summarySellTrades.reduce(function (a,b) { return a + b.tradedValue; }, 0);
             
             
            $rootScope.trades = result;
            vm.expandTradePosition = false;
            if(vm.instrumentFilterForTrade!=null){
                $rootScope.totalUSDBought = vm.totalUSDBought;
                $rootScope.totalUSDSold = vm.totalUSDSold;
                $rootScope.totalINRSold = vm.totalINRSold;
                $rootScope.totalINRBought = vm.totalINRBought;
                $rootScope.weightedAverageBuy = Math.abs(vm.totalINRSold/vm.totalUSDBought);
                $rootScope.weightedAverageSell = Math.abs(vm.totalINRBought/vm.totalUSDSold);
                $rootScope.usdNet = vm.totalUSDBought - vm.totalUSDSold;
                $rootScope.inrNet = vm.totalINRBought - vm.totalINRSold;
                $rootScope.netWeightedAveragePrice = Math.abs((vm.totalINRBought - vm.totalINRSold) / (vm.totalUSDBought - vm.totalUSDSold));
                
                if(isNaN(Math.abs(vm.totalINRSold/vm.totalUSDBought)) || Math.abs(vm.totalINRSold/vm.totalUSDBought) == Infinity)
                    $rootScope.weightedAverageBuy = null;
                if(isNaN(Math.abs((vm.totalINRBought - vm.totalINRSold) / (vm.totalUSDBought - vm.totalUSDSold))) ||
                        (Math.abs((vm.totalINRBought - vm.totalINRSold) / (vm.totalUSDBought - vm.totalUSDSold))) == Infinity)
                    $rootScope.netWeightedAveragePrice = null;
                
                if(isNaN(Math.abs(vm.totalINRBought/vm.totalUSDSold)) || Math.abs(vm.totalINRBought/vm.totalUSDSold) == Infinity)
                     $rootScope.weightedAverageSell = null;
            }else{
                $rootScope.totalUSDBought = null;
                $rootScope.totalUSDSold = null;
                $rootScope.totalINRSold = null;
                $rootScope.totalINRBought = null;
                $rootScope.weightedAverageBuy = null;
                $rootScope.weightedAverageSell = null;
                $rootScope.usdNet = null;
                $rootScope.inrNet = null;
                $rootScope.netWeightedAveragePrice = null;
            }
            
        }
        
        function onGetTradesFailure(){
            vm.clickedReport=false; 
        }

        //get limits for instrument
        function getLimitUtilisedReport(){
            vm.clickedReport=true;
            var instrumentTypeId = 0;
            if(vm.instrumentFilterForLimit!=null && vm.instrumentFilterForLimit!=undefined){
                instrumentTypeId = vm.instrumentFilterForLimit.id;
                vm.limitInstrumentCode = 'ALL';
            }

            getLimitUtilisedReportForInstrument(instrumentTypeId);
            syncInputsToHomePage();
            
        }

        // get limit utilisation reports for given instrument
        function getLimitUtilisedReportForInstrument(){
            var bankId = getBankIdForReportFilters('Limit');
            var branchId = getBranchIdForReportFilters('Limit');
            var customerId = getCustomerIdForReportFilters('Limit');
            var instrumentId = getInstrumentIdForReportFilters('Limit');
            if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP'){
                getLimitsAvailable(bankId, branchId, customerId,instrumentId);
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName!='Branch'){
                if(bankId!=0){
                    getLimitsAvailable(bankId, branchId, customerId,instrumentId);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK' && vm.globalValues.getEntityOwnerType().shortName=='Branch'){
                if(bankId!=0 && branchId!=0){
                    getLimitsAvailable(bankId, branchId, customerId,instrumentId);
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if(customerId!=0){
                    getLimitsAvailable(bankId, branchId, customerId,instrumentId);
                }
            }
        }
        
        function getLimitsAvailable(bankId, branchId, customerId, instrumentId){
            
            LimitSettings.getLimitUtilsationDetailsForInstrument({bankId: bankId, branchId: branchId, customerId: customerId,  instrumentId: instrumentId}, onGetLimitsSuccess, onGetLimitsFailure);

        }
        
        function onGetLimitsSuccess(result){
            $rootScope.limitsAvailableForSelectedInstrument = result;
            vm.clickedReport=false;
        }
        
        function onGetLimitsFailure(){
            vm.clickedReport=false;
        }
        
        function getBuyingPower(limit){
            var buyingPower = 0;
            var totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
            var totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
            if(limit.limitType=='GROSSSELL'){
                buyingPower = 0;
            }else if(limit.limitType=='GROSSBUY'){
                buyingPower = limit.limitAllocatedInUSD - (totalBuy);
            }else if(limit.limitType=='GLOBALGROSS'){
                buyingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
            }else if(limit.limitType=='NET'){
                totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
                totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue  + limit.previousDayBuyTradeValue));
                var xVals = [];
                xVals.push(totalBuy);
                xVals.push(totalSell);
                buyingPower = limit.limitAllocatedInUSD - totalBuy;
            }
            if(buyingPower<0){
                buyingPower=0;
            }
            return buyingPower;
        }
        
        function getExposure(limit){
            var exposure = 0;
            var totalBuy = 0;
            var totalSell = 0;
            if(limit.limitType!='NET'){
                totalBuy = limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue;
                totalSell = limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue;
                exposure = (limit.totalPendingBuyOrderValue + limit.totalBuyTradeValue + limit.previousDayBuyTradeValue + limit.totalPendingSellOrderValue + limit.totalSellTradeValue + limit.previousDaySellTradeValue) * 100/limit.limitAllocatedInUSD;
            }else{
                totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
                totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));

                var xVals = [];
                xVals.push(totalBuy);
                xVals.push(totalSell);
                exposure =  Math.max.apply(Math, xVals) * 100/limit.limitAllocatedInUSD;
            }
            return exposure;
        }
        
        function getSellingPower(limit){
            var sellingPower = 0;
            var totalBuy = limit.totalPendingBuyOrderValue + (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue);
            var totalSell = limit.totalPendingSellOrderValue + (limit.totalSellTradeValue + limit.previousDaySellTradeValue);
            if(limit.limitType=='GROSSSELL'){
                sellingPower = limit.limitAllocatedInUSD - (totalSell);
            }else if(limit.limitType=='GROSSBUY'){
                sellingPower = 0;
            }else if(limit.limitType=='GLOBALGROSS'){
                sellingPower = limit.limitAllocatedInUSD - (totalBuy + totalSell);
            }else if(limit.limitType=='NET'){
                totalBuy = limit.totalPendingBuyOrderValue+((limit.totalBuyTradeValue + limit.previousDayBuyTradeValue) - (limit.totalSellTradeValue + limit.previousDaySellTradeValue));
                totalSell = limit.totalPendingSellOrderValue+((limit.totalSellTradeValue + limit.previousDaySellTradeValue) - (limit.totalBuyTradeValue + limit.previousDayBuyTradeValue));
                var xVals = [];
                xVals.push(totalBuy);
                xVals.push(totalSell);
                sellingPower = limit.limitAllocatedInUSD - totalSell;
            }
            if(sellingPower<0){
                sellingPower=0; 
            }
            return sellingPower;
        }
        
        function printDealTicket(trade){
            vm.tradeResponse = angular.copy(trade);
            console.log(typeof vm.tradeResponse.tradeTime);
            vm.tradeResponse.tradeTime = moment(vm.tradeResponse.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
            GenerateDealTicket.generate(vm.tradeResponse,function(result){
                var file = new Blob([result.data], {type: 'application/pdf'});
                // var fileURL = URL.createObjectURL(file);
                // window.open(fileURL, "_blank", "fullscreen=yes");
                var fileURL = URL.createObjectURL(file);
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "DealTicket.pdf");
                }
                else {
                     window.open(fileURL, "_blank", "fullscreen=yes");    
                }
            });
        }
        
        function printDealTicketOutstandingTrade(outstandingTrade){
            vm.tradeResponse = angular.copy(outstandingTrade);
            console.log(typeof vm.tradeResponse.tradeTime);
            vm.tradeResponse.tradeTime = moment(vm.tradeResponse.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
            GenerateDealTicket.generateOutstandingTrades(vm.tradeResponse,function(result){
                var file = new Blob([result.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, "DealTicket.pdf");
                }
                else {
                     window.open(fileURL, "_blank", "fullscreen=yes");    
                }
            });
        }

        // get bank, branch and customer id from report filters
        function getBankIdForReportFilters(reportType){
            var bankId = 0;
            if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP' || $rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                if(reportType=='PO'){
                    if(vm.bankFilterForPO!=null){
                        bankId = vm.bankFilterForPO.id;
                    }
                }else if(reportType=='Limit'){
                    if(vm.bankFilterForLimit!=null){
                        bankId = vm.bankFilterForLimit.id;
                    }
                }else if(reportType=='Trade'){
                    if(vm.bankFilterForTrade!=null){
                        bankId = vm.bankFilterForTrade.id;
                    }
                }
                else if(reportType=='OT'){
                    if(vm.bankFilterForOT!=null){
                        bankId = vm.bankFilterForOT.id;
                    }
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='BANK'){
                bankId = $rootScope.account.organisation.id;
            }
            
            return bankId;
        }
        
        function getBranchIdForReportFilters(reportType){
            var branchId = 0;
            if(reportType=='PO'){
                if(vm.branchFilterForPO!=null){
                    branchId = vm.branchFilterForPO.id;
                }
            }else if(reportType=='Limit'){
                if(vm.branchFilterForLimit!=null){
                    branchId = vm.branchFilterForLimit.id;
                }
            }else if(reportType=='Trade'){
                if(vm.branchFilterForTrade!=null){
                    branchId = vm.branchFilterForTrade.id;
                }
            }
            else if(reportType=='OT'){
                if(vm.branchFilterForOT!=null){
                    branchId = vm.branchFilterForOT.id;
                }
            }
            return branchId;
        }
        
        function getInstrumentIdForReportFilters(reportType){
            var instrumentId = 0;
            if(reportType=='PO'){
                if(vm.instrumentFilterForPO!=null){
                    instrumentId = vm.instrumentFilterForPO.id;
                }
            }else if(reportType=='Limit'){
                if(vm.instrumentFilterForLimit!=null){
                    instrumentId = vm.instrumentFilterForLimit.id;
                }
            }else if(reportType=='Trade'){
                if(vm.instrumentFilterForTrade!=null){
                    instrumentId = vm.instrumentFilterForTrade.id;
                }
            }
            else if(reportType=='OT'){
                if(vm.instrumentFilterForOT!=null){
                    instrumentId = vm.instrumentFilterForOT.id;
                }
            }
            return instrumentId;
        }
        
        function getCustomerIdForReportFilters(reportType){
            var customerId = 0;
            if($rootScope.account.organisation.organisationType.shortName=='CLEARCORP' || $rootScope.account.organisation.organisationType.shortName=='BANK'){
                if(reportType=='PO'){
                    if(vm.customerFilterForPO!=null){
                        customerId = vm.customerFilterForPO.id;
                    }
                }else if(reportType=='Limit'){
                    if(vm.customerFilterForLimit!=null){
                        customerId = vm.customerFilterForLimit.id;
                    }
                }else if(reportType=='Trade'){
                    if(vm.customerFilterForTrade!=null){
                        customerId = vm.customerFilterForTrade.id;
                    }
                }
                else if(reportType=='OT'){
                    if(vm.customerFilterForOT!=null){
                        customerId = vm.customerFilterForOT.id;
                    }
                }
            }else if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                customerId = vm.globalValues.getCustomerId();
            }
            return customerId;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
            syncInputsToHomePage();
        }
        
        vm.exportOTToExcelFile = exportOTToExcelFile;
        vm.exportOTToCsvFile = exportOTToCsvFile;
        vm.exportTradesToCsvFile = exportTradesToCsvFile;
        vm.exportTradesToExcelFile = exportTradesToExcelFile;

        vm.reportFileTimestampFormat = 'DD_MMM_YYYY_HH_mm_ss';
        function exportOTToCsvFile(){
        	
        	if($rootScope.outstandingTrades!=null && $rootScope.outstandingTrades.length >0){
        		vm.mimeType = 'text/csv';

        		var baseString = 'OUTSTANDING_TRADES_'+ moment().format(vm.reportFileTimestampFormat) ;  
        		vm.baseString = baseString+ ".csv";
        		var selectedTrades = angular.copy($rootScope.outstandingTrades);
        		angular.forEach(selectedTrades,function(value,index){        		
        			value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');    	        		 	        		
        		});

        		TradesDownload.getTradesOutstandingCSV(selectedTrades, processResponse);

        	}
        	          
        }
        
        function exportOTToExcelFile(){
        	if($rootScope.outstandingTrades!=null && $rootScope.outstandingTrades.length >0){           	
        		vm.mimeType = 'application/vnd.ms-excel';
        		var baseString = 'OUTSTANDING_TRADES_'+ moment().format(vm.reportFileTimestampFormat);  
        		vm.baseString = baseString+ ".xls";  
        		var selectedTrades = angular.copy($rootScope.outstandingTrades);
        		angular.forEach(selectedTrades,function(value,index){        		
        			value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');    	        		 	        		
        		});

        		TradesDownload.getTradesOutstandingCSV(selectedTrades, processResponse);


        	}
        }
        
        function exportTradesToCsvFile(){
            if($rootScope.trades!=null && $rootScope.trades.length >0){

                vm.mimeType = 'text/csv';
                 var baseString =    moment(vm.currentDate).format( 'YYYYMMDD')+'_FXRETAIL_TRADES';  
                 vm.baseString = baseString+ ".csv";  
                var selectedTrades = angular.copy($rootScope.trades);
                angular.forEach(selectedTrades,function(value,index){               
                    value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
                    console.log("tradetime " +  value.tradeTime);
                    
                });
                TradesDownload.getCSVForTrades(selectedTrades , processResponse );
            }
        }
        
        function exportTradesToExcelFile(){
            if($rootScope.trades!=null && $rootScope.trades.length >0){

                  vm.mimeType = 'application/vnd.ms-excel';
                  var baseString =    moment(vm.currentDate).format( 'YYYYMMDD')+'_FXRETAIL_TRADES';  
                  vm.baseString = baseString+ ".xls";  
                  var selectedTrades = angular.copy($rootScope.trades);
                    angular.forEach(selectedTrades,function(value,index){               
                        value.tradeTime = moment(value.tradeTime).format('YYYYMMDDHHmmss.SSSSSSSSS');
                        console.log("tradetime " +  value.tradeTime);
                        
                    });
                  TradesDownload.getExcelForTrades(selectedTrades , processResponse );
            }
        }

        function processResponse(result){
          var fileData = null;
          fileData = result.data;
          var link = document.createElement("a");   
          var bytes = new Uint8Array( fileData );   // convert uint8 to binary
                                                    // array from arraybuffer
          var blob = new Blob([bytes], {type: vm.mimeType});
          if(window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
          }else{
              link.href =  window.URL.createObjectURL(blob);      
              link.download = vm.baseString;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
        }

        vm.openOrderActivity = function openOrderActivity(ordNo){
            $state.go('order-activity-reports', {ordNo: ordNo});
        }

        vm.changeTab = function changeTab(tabId){
            setTimeout(function(){document.getElementById(tabId).click();},100);
        }

        //events emitted to the homepage
        //modify order from pending orders
        function modifyOrder(pendingOrder){
            $rootScope.pendingOrderToModify = pendingOrder;
            $rootScope.modifyCount = 0;
            $scope.$emit('modifypendingorder');
            $uibModalInstance.close();
            syncInputsToHomePage();
        }

        //cancel order from pending orders
        function cancelOrder(pendingOrder){
            $rootScope.pendingOrderToCancel = pendingOrder;
            $rootScope.cancelCount = 0;
            $scope.$emit('cancelpendingorder');
            //$uibModalInstance.close();
            //syncInputsToHomePage();
        }

        //canel contraction from outstanding trades
        // function cancelTrade(){
        //     $rootScope.cancelTradeCount = 0;
        //     $scope.$emit('cancelpendingtrade');
        //     //$uibModalInstance.close();
        //     //syncInputsToHomePage();
        // }

        function cancelTrade(){
            //vm.remarks = "";
            var outstandingTrades = angular.copy($rootScope.outstandingTrades);
            var outstandingTrade = _.filter(outstandingTrades, {'select': true});
            if(validateOutstandingTrade(outstandingTrade)){
                $rootScope.outstandingTradeToCancel = outstandingTrade[0];
                $rootScope.cancelTradeCount = 0;
                $scope.$emit('cancelpendingtrade');
                $uibModalInstance.close();
                syncInputsToHomePage();
            }
        }

        function validateOutstandingTrade(outstandingTrade) {
            var isValid = true;
            if(outstandingTrade.length>0){
                //validate selected contract is forward contract. Need not validate based on event type
                if(outstandingTrade[0].instrumentCode!='Forward' ){	//&& outstandingTrade[0].eventTypeName!='Early Delivery'
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "The SPOT/TOM/CASH contract cannot be cancelled.", "Ok", "selectedorders");
                }
                else{
                    //validate outstanding amount
                	var cancellationSettlementDate = $rootScope.getSettlementDateForContractCancellation(outstandingTrade[0]);                  	 							                 
                    var instrumentCode = MarketWatch.getInstrumentCodeForSettlement(cancellationSettlementDate, $rootScope.tenors, $rootScope.retailSpotMarketSession, $rootScope.cashMarketSession, $rootScope.tomMarketSession);
                    if((outstandingTrade[0].outStandingAmount - outstandingTrade[0].blockedAmount)<=0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Cancellation order(s) is already pending for execution, the operation is not permitted...!", "Ok", "selectedorders");   
                    }
                    //validate spread 
                    if(instrumentCode!='SPOT'){
                        var tickSize = MarketWatch.gettickSizeForGivenInstrument(instrumentCode, $rootScope.tickSize);
                        var buySellIndicator = 'BUYY';
                        if(outstandingTrade[0].buySellIndicator=='BUYY'){
                            buySellIndicator = 'SELL';  
                        }
                        var bankSpread = MarketWatch.getDefaultBankSwapQuotes($rootScope.bankComparativeDTO.swapQuotesTenorDateDTOList, outstandingTrade[0].bankId);
                        var spreadUsed = MarketWatch.getSpreadForTenorAndSettlementDate(bankSpread, instrumentCode, buySellIndicator, 
                            DateUtils.convertLocalDateToServer(angular.copy(cancellationSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                            tickSize);
                        if(spreadUsed==null || spreadUsed==undefined){
                            isValid = false;
                            if(buySellIndicator == 'BUYY'){
                               if(instrumentCode=='Forward'){
                                  MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD offer spread for the selected settlement date is not provided by your Relationship bank.", "Ok", "selectedorders"); 
                               }else{
                                  MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT bid spread is not given by the bank. Order entry not possible...!", "Ok", "selectedorders");   
                               }
                            }else{
                               if(instrumentCode=='Forward'){
                                  MessageBox.openMessageBox("Alert", "Order entry not possible as FORWARD bid spread for the selected settlement date is not provided by your Relationship bank.", "Ok", "selectedorders"); 
                               }else{
                                  MessageBox.openMessageBox("Alert", instrumentCode + "/SPOT offer spread is not given by the bank. Order entry not possible...!", "Ok", "selectedorders");   
                               }
                            }
                        }
                    }
                }
            }else{
                isValid = false;
                MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "selectedorders");
            }
            return isValid;
        }

        var closereportexp = $rootScope.$on('closereportexp', function(event, result){
            $uibModalInstance.close();
            syncInputsToHomePage();
        })

        $scope.$on('$destroy', function() {
            closereportexp(); 
        });

        //cancel selected orders from pending orders
        function cancelPendingOrdersSelected(){
            $rootScope.cancelSelectedCount = 0;
            $scope.$emit('cancelselectedpendingorders');
            //$uibModalInstance.close();
            //syncInputsToHomePage();
        }
        //cancel selected orders from pending orders
        function cancelAllOrders(){
            vm.selectAll = true;
          //  pendingOrder.select =true;
            $rootScope.cancelSelectedCount = 0;
            $scope.$emit('cancelallpendingorders');
            //$uibModalInstance.close();
            //syncInputsToHomePage();
        }

        //limit change request from limits
        function changeRequest(){
            var limits = angular.copy($rootScope.limitsAvailableForSelectedInstrument);
            var selectedLimits = _.filter(limits, {'select': true});
            if(selectedLimits.length<=0){
                MessageBox.openMessageBox("Alert", "Please select a limit to proceed.", "Ok", "limitchangerequestexp");
            }else{
                $rootScope.selectedLimit = selectedLimits[0];
                $rootScope.limitChangeRequestCount = 0;
                $scope.$emit('limitchangerequestfromexp');
                //$uibModalInstance.close();
                syncInputsToHomePage();
            }
        }

        //new limit request from limits
        function newLimitRequest(){
            $rootScope.newLimitRequestCount = 0;
            $rootScope.bankFilterForLimit = vm.bankFilterForLimit;
            $scope.$emit('newlimitrequestfromexp');
            //$uibModalInstance.close();
            syncInputsToHomePage();
        }

        //sync local variables changes in home page
        $scope.$watch('vm.bankFilterForLimit', function(value){
            if($rootScope.account!=null && $rootScope.account!=undefined && value!=null && value!=undefined){
                $rootScope.bankFilterForLimit = value;
                if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                    CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        vm.branchFilterForLimit = result;
                        $rootScope.branchFilterForLimit = result;
                    });
                    // get the limits when the bakFilterForLimit changes
                    LimitSettings.getLimitsAvailableForAllInstruments({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, checkLimitOnBankFilterChangeSuccess, onGetLimitsAvailableFailure);
                }
            }else{
                vm.branchFilterForLimit = null;
                $rootScope.bankFilterForLimit = null;
                $rootScope.branchFilterForLimit = null;
            }
        })
        
        function checkLimitOnBankFilterChangeSuccess(result){
            if(result!=null && result !=undefined && result.length > 0){
                $rootScope.limitAvailableForSlectBankFilterInReport = true;
            }else{
                $rootScope.limitAvailableForSlectBankFilterInReport = false;
            }
        }
        
        function onGetLimitsAvailableFailure(){
            
        }

        $scope.$watch('vm.bankFilterForOT', function(value){
            if($rootScope.account!=null && $rootScope.account!=undefined && value!=null && value!=undefined){
                $rootScope.bankFilterForOT = value;
                if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                    CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        vm.branchFilterForOT = result;
                        $rootScope.branchFilterForOT = result;
                    })
                }
            }else{
                vm.branchFilterForOT = null;
                $rootScope.bankFilterForOT = null;
                $rootScope.branchFilterForOT = null;
            }
        })
        
        $scope.$watch('vm.bankFilterForPO', function(value){
            if($rootScope.account!=null && $rootScope.account!=undefined && value!=null && value!=undefined){
                $rootScope.bankFilterForPO = value;
                if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                    CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        vm.branchFilterForPO = result;
                        $rootScope.branchFilterForPO = result;
                    })
                }
            }else{
                vm.branchFilterForPO = null;
                $rootScope.bankFilterForPO = null;
                $rootScope.branchFilterForPO = null;
            }
        })
        
         $scope.$watch('vm.bankFilterForTrade', function(value){
            if($rootScope.account!=null && $rootScope.account!=undefined && value!=null && value!=undefined){
                $rootScope.bankFilterForTrade = value;
                if($rootScope.account.organisation.organisationType.shortName=='CUSTOMER'){
                    CustomerBankRelationshipReport.getTradingBranchForCustomerAndBank({bankId: value.id, customerId: vm.globalValues.getOrganisation().id}, function(result){
                        vm.branchFilterForTrade = result;
                        $rootScope.branchFilterForTrade = result;
                    })
                }
            }else{
                vm.branchFilterForTrade = null;
                $rootScope.bankFilterForTrade = null;
                $rootScope.branchFilterForTrade = null;
            }
        })

        $scope.$watch('vm.instrumentFilterForPO', function(value){
            $rootScope.instrumentFilterForPO = value;
        })

        $scope.$watch('vm.instrumentFilterForLimit', function(value){
            $rootScope.instrumentFilterForLimit = value;            
        })

        $scope.$watch('vm.instrumentFilterForTrade', function(value){
            $rootScope.instrumentFilterForTrade = value;
        })

        $scope.$watch('vm.instrumentFilterForOT', function(value){
            $rootScope.instrumentFilterForOT = value;
        })
        
        $scope.$watch('vm.branchFilterForOT', function(value){
            $rootScope.branchFilterForOT = value;
        })

        $scope.$watch('vm.branchFilterForPO', function(value){
            $rootScope.branchFilterForPO = value;
        })

        $scope.$watch('vm.branchFilterForLimit', function(value){
            $rootScope.branchFilterForLimit = value;
        })

        $scope.$watch('vm.branchFilterForTrade', function(value){
            $rootScope.branchFilterForTrade = value;
        })
        
        $scope.$watch('vm.customerFilterForPO', function(value){
            $rootScope.customerFilterForPO = value;
        })
        
        $scope.$watch('vm.customerFilterForOT', function(value){
            $rootScope.customerFilterForOT = value;
        })

        $scope.$watch('vm.customerFilterForLimit', function(value){
            $rootScope.customerFilterForLimit = value;
        })

        $scope.$watch('vm.customerFilterForTrade', function(value){
            $rootScope.customerFilterForTrade = value;
        })
        
        $scope.$watch('vm.fromSettlementDate', function(value){
            $rootScope.fromSettlementDate = value;
        })

        $scope.$watch('vm.toSettlementDate', function(value){
            $rootScope.toSettlementDate = value;
        })

        $scope.$watch('vm.selectAll', function(value){
            $rootScope.selectAll = value;
        })

        $scope.$watch('vm.reportTab', function(value){
            $rootScope.reportTab = vm.reportTab;
            syncInputsToHomePage();
        })

        //actions from the report
        function checkOrUncheckPendingOrderItem(){
            var allSelected = true;
        
            angular.forEach($rootScope.pendingOrders, function(value, index){
                if (!$rootScope.pendingOrders[index].select){
                    allSelected = false
                }
            });
            
            if(allSelected){
                vm.selectAll= true;
                // if(document.getElementById("selectAll")!=null ||
                // document.getElementById("selectAll")!=undefined)
                document.getElementById("selectAll").checked = true;
            }else{
                vm.selectAll= false;
                // if(document.getElementById("selectAll")!=null ||
                // document.getElementById("selectAll")!=undefined)
                document.getElementById("selectAll").checked = false;
            }
        }

        function checkOrUncheckPendingOrders(event){
            
            changePendingOrderItemSelection(event.target.checked);
               
        }

        function changePendingOrderItemSelection(value){
            if(value){
                angular.forEach($rootScope.pendingOrders, function(value, index){
                    if(value.orderStatus!="Risk Validated"){
                        $rootScope.pendingOrders[index].select = true;
                    }
                });
            }else{
                angular.forEach($rootScope.pendingOrders, function(value, index){
                    $rootScope.pendingOrders[index].select = false;
                });
            }
        }

        function checkOrUncheckOutstandingTradesItem(outstandingValue, event){
            var selectedValue = event.target.checked;
            angular.forEach($rootScope.outstandingTrades, function(value, index){                
                    value.select = false;                
            })
            outstandingValue.select = selectedValue;
        }

        $rootScope.$on('$destroy', function () {
            if(emitSyncInputs!=null){
                emitSyncInputs();
            }
        });

        function syncInputsToHomePage(){
            $rootScope.customerFilterForPO=vm.customerFilterForPO;
            $rootScope.customerFilterForLimit=vm.customerFilterForLimit;
            $rootScope.customerFilterForTrade=vm.customerFilterForTrade;
            $rootScope.customerFilterForOT=vm.customerFilterForOT;

            $rootScope.bankFilterForPO=vm.bankFilterForPO;
            $rootScope.bankFilterForLimit=vm.bankFilterForLimit;
            $rootScope.bankFilterForTrade=vm.bankFilterForTrade;
            $rootScope.bankFilterForOT=vm.bankFilterForOT;

            $rootScope.branchFilterForPO=vm.branchFilterForPO;
            $rootScope.branchFilterForLimit=vm.branchFilterForLimit;
            $rootScope.branchFilterForTrade=vm.branchFilterForTrade;
            $rootScope.branchFilterForOT=vm.branchFilterForOT;

            $rootScope.instrumentFilterForPO=vm.instrumentFilterForPO;
            $rootScope.instrumentFilterForLimit=vm.instrumentFilterForLimit;
            $rootScope.instrumentFilterForTrade=vm.instrumentFilterForTrade;
            $rootScope.instrumentFilterForOT=vm.instrumentFilterForOT;

            $rootScope.fromSettlementDate = vm.fromSettlementDate;
            $rootScope.toSettlementDate = vm.toSettlementDate;
            $rootScope.selectAll = document.getElementById("selectAll").checked;
            $rootScope.reportTab = vm.reportTab;

            var emitSyncInputs = $scope.$emit('syncinputs');

        }

        vm.getSwapPointsForTradesReport = function getSwapPointsForTradesReport(spotExchangeRate, tradeExchangeRate){
            var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
            swapPts = (Math.round(parseFloat(swapPts)*100)/100);
            return swapPts;
        }

        function rolloverTrade(){
            var outstandingTrades = angular.copy($rootScope.outstandingTrades);
            var outstandingTrade = _.filter(outstandingTrades, {'select': true});
            //validate spread is available for cancellation leg
            if(validateOutstandingTradeForRollover(outstandingTrade)){ 
                $rootScope.outstandingTradeToRollover = outstandingTrade[0];
                $rootScope.rolloverTradeCount = 0;
                $scope.$emit('rolloverpendingtrade');
                $uibModalInstance.close();
                syncInputsToHomePage();
            }
        }

        function validateOutstandingTradeForRollover(outstandingTrade) {
            var isValid = true;
            if(outstandingTrade.length>0){
                //validate selected contract is forward contract
                if(outstandingTrade[0].instrumentCode!='Forward' ){ //&& outstandingTrade[0].eventTypeName=='Booking'
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "The SPOT/TOM/CASH contract cannot be rolled over...!", "Ok", "traderollover");
                }
                else if(outstandingTrade[0].instrumentCode == 'Forward' && outstandingTrade[0].isOptionPeriod == true ){
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "The option period forward contract cannot be rolled over...!", "Ok", "traderollover");
                }            	
                else{
                    //validate outstanding amount
                	//intruent code for cancellation leg to validate the session status of the cancellation leg
                    var instrumentCode = getInstrumentCodeForSettlement(outstandingTrade[0].tradeSettlementDate);     
                    var cancellationLegSpread = findCancellationLegSpread(instrumentCode, outstandingTrade[0].tradeSettlementDate, $rootScope.findBuyySellIndicatorForCancellationLeg(outstandingTrade[0].buySellIndicator) );
                    
                    /*if(outstandingTrade[0].instrumentCode!='Forward' && outstandingTrade[0].eventTypeName=='Early Delivery'){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "The Early Delivery SPOT/TOM/CASH contract cannot be rolled over...!", "Ok", "traderollover");
                    }else*/ 
                    if((outstandingTrade[0].outStandingAmount - outstandingTrade[0].blockedAmount)<=0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Order(s) is already pending for execution, the operation is not permitted...!", "Ok", "traderollover");   
                    }else if(!validateCancellationLegSessionStatus('ROLL', instrumentCode)){
                    	 isValid = false;
                        MessageBox.openMessageBox("Alert", "Session is closed for Cancellation Leg instrument " + instrumentCode + "...!", "Ok", "traderollover");  
                    }else if(cancellationLegSpread == null || cancellationLegSpread == undefined){
                    	isValid = false;
                   	 MessageBox.openMessageBox("Alert", "Contract cannot be rolled over. Cancellation Leg Swap Point for "+ $filter('date')(outstandingTrade[0].tradeSettlementDate,'dd-MMM-yyyy') +" is not provided by Relationship bank...!", "Ok", "traderollover");
                        
                   }
                }
            }else{
                isValid = false;
                MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "traderollover");
            }
            return isValid;
        }

        function earlyDeliveryTrade(){
            var outstandingTrades = angular.copy($rootScope.outstandingTrades);
            var outstandingTrade = _.filter(outstandingTrades, {'select': true});
            if(validateOutstandingTradeForEarlyDelivery(outstandingTrade)){ 
                $rootScope.outstandingTradeToEarlyDelivery = outstandingTrade[0];
                $rootScope.earlyDeliveryTradeCount = 0;
                $scope.$emit('earlydeliverypendingtrade');
                $uibModalInstance.close();
                syncInputsToHomePage();
            }
        }

        function validateOutstandingTradeForEarlyDelivery(outstandingTrade) {
            var isValid = true;
            if(outstandingTrade.length>0){
                //validate selected contract is forward contract
                if(outstandingTrade[0].instrumentCode!='Forward' ){ //&& outstandingTrade[0].eventTypeName=='Booking'
                    isValid = false;
                    MessageBox.openMessageBox("Alert", "The SPOT/TOM/CASH contract cannot be early delivered...!", "Ok", "tradeearlydelivery");
                }
                else if(outstandingTrade[0].instrumentCode == 'Forward' && outstandingTrade[0].isOptionPeriod == true && !$rootScope.isEventAppliedBeforeOptionPeriod(outstandingTrade[0]) ){
               	 isValid = false;
               	 MessageBox.openMessageBox("Alert", "The option period forward contract cannot be early delivered during the option period...!", "Ok", "tradeearlydelivery");
                } 
                else{
                    //validate outstanding amount
                    //var instrumentCode = getInstrumentCodeForSettlement(outstandingTrade[0].tradeSettlementDate);
                    
                   /* if(outstandingTrade[0].instrumentCode!='Forward' && outstandingTrade[0].eventTypeName=='Early Delivery'){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "The Early Delivery SPOT/TOM/CASH contract cannot be early delivered...!", "Ok", "tradeearlydelivery");
                    }else*/ 
                	if((outstandingTrade[0].outStandingAmount - outstandingTrade[0].blockedAmount)<=0){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Order(s) is already pending for execution, the operation is not permitted...!", "Ok", "tradeearlydelivery");   
                    }
                                     
                    var tradeSettlementDateForCancellation = $rootScope.getSettlementDateForContractCancellation(outstandingTrade[0]);
                    var cancellationLegInstrumentCode = getInstrumentCodeForSettlement(tradeSettlementDateForCancellation);
                    var cancellationLegSpread = findCancellationLegSpread(cancellationLegInstrumentCode, tradeSettlementDateForCancellation, $rootScope.findBuyySellIndicatorForCancellationLeg(outstandingTrade[0].buySellIndicator) );
                    
                    var instrumentCode = getInstrumentCodeForSettlement(outstandingTrade[0].tradeSettlementDate);
                    
                    if(instrumentCode=='CASH'){
                        isValid = false;
                        MessageBox.openMessageBox("Alert", "Contract cannot be early delivered as the contract entered into CASH instrument...!", "Ok", "tradeearlydelivery");
                    }else if(!validateCancellationLegSessionStatus('EARL', cancellationLegInstrumentCode)){
                    	 isValid = false;
                    	 MessageBox.openMessageBox("Alert", "Session is closed for Cancellation Leg instrument " + instrumentCode + "...!", "Ok", "tradeearlydelivery");  
                    }else if(cancellationLegSpread == null || cancellationLegSpread == undefined){
                    	isValid = false;
                   	 	MessageBox.openMessageBox("Alert", "Contract cannot be early delivered. Cancellation Leg Swap Point for "+ $filter('date')(outstandingTrade[0].tradeSettlementDate,'dd-MMM-yyyy') +" is not provided by Relationship bank...!", "Ok", "tradeearlydelivery");
                     }
                }
            }else{
                isValid = false;
                MessageBox.openMessageBox("Alert", "Please select a trade to proceed.", "Ok", "tradeearlydelivery");
            }
            return isValid;
        }

        
        function getInstrumentCodeForSettlement(tradeSettlementDate){
            var tenor = null;
            angular.forEach($rootScope.tenors, function(value){
                if(value.settlementDate==tradeSettlementDate){
                    tenor = value;
                }
            })
            if(tenor==null || tenor == undefined){
                if(tradeSettlementDate==$rootScope.retailSpotMarketSession.settlementDate){
                    return 'SPOT';
                }else if(tradeSettlementDate==$rootScope.cashMarketSession.settlementDate){
                    return 'CASH';
                }else if(tradeSettlementDate==$rootScope.tomMarketSession.settlementDate){
                    return 'TOM';
                }else {
                    tenor = $rootScope.tenors[0];
                    return 'Forward';
                }
            }else{
                return 'Forward';
            }
        }
        // validate session status
        function validateSessionStatus(instrumentCode){
        	var status = "";
        	
        	if(instrumentCode == 'SPOT'){
        		status = $rootScope.retailSpotMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'CASH'){
        		status = $rootScope.cashMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'TOM'){
        		status = $rootScope.tomMarketSession.sessionTimingsDTO.status;
        	}else if(instrumentCode == 'Forward'){
                status = $rootScope.forwardMarketSession.sessionTimingsDTO.status;
            }
        	
        	if(status!='OPEN'){        		
        		return false;
        	}
        	
        	return true;
        }
        /**
         * Valdate cancellation leg session status before placing ROLL or EARLY delivery
         */
        function validateCancellationLegSessionStatus(activityIndicator, cancellationLegInstrument){
        	if(activityIndicator=='ROLL' || activityIndicator=='EARL'){
        		var isValidSessionStatus = validateSessionStatus(cancellationLegInstrument);
        		
        		return isValidSessionStatus ;
        	}
        	return true;
        }
        
        // this functionality is invoked during validation and also during the trade date settlement change
        function findCancellationLegSpread(instCode, tradeCancellationSettlementDate, orderTypeOfCancellationLeg){
        	
        	 //get tick size for instcode and stored in variable
            var tickSize = $rootScope.gettickSizeForGivenInstrument(instCode);

        	 if(instCode=='SPOT'){ //if given instrument is SPOT, then set cancellation leg spread as 0
                 return  0;
             }else{
                 //get spread for given settlement date,  order type of cancellation leg
                 return MarketWatch.getSpreadForTenorAndSettlementDate($rootScope.defaultBankSpread, instCode, orderTypeOfCancellationLeg, 
                     DateUtils.convertLocalDateToServer(angular.copy(tradeCancellationSettlementDate)), $rootScope.retailSpotMarketSession.settlementDate, 
                     tickSize);
             }
        }
    }   
})();
