(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('EntityActionApprovalPolicy', EntityActionApprovalPolicy);

    EntityActionApprovalPolicy.$inject = ['$resource'];

    function EntityActionApprovalPolicy ($resource) {
        var resourceUrl =  'api/orgId/:orgId/entity-action-approval-policy/:id';
        
        return $resource(resourceUrl, {}, {
        	'getEntityActions': {
        		method: 'GET', 
        		isArray: true,
        		url: 'api/orgId/:orgId/entity-actions/:id'
        	 },
        	 'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
             },
        	 'map': {
                 method: 'PUT',
                 params: {orgId:'@orgId'},
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                     return angular.toJson(copy);
                 }                
             },
             'approve': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/approve'
             },
             'reject': {
                 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/reject'
             },
             'discard': {
               	 method: 'GET',
                    params: {taskId:'@taskId'},
                    url: 'api/tasks/:taskId/discard'
              },
              'resubmit': {
                  method: 'PUT',
                  params: {taskId:'@taskId'},
                  url: 'api/tasks/:taskId/resubmit/entity-action-approval-policy',
                  transformRequest: function (data) {
                  	var copy = angular.copy(data);
                  	return angular.toJson(copy);
                  }
              }
              
        });
    }
})();
