(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ServerComponentStatus', ServerComponentStatus);

    ServerComponentStatus.$inject = ['$resource'];

    function ServerComponentStatus ($resource) {
        var resourceUrl =  'api/order-server-manual-login';
        
        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', 
        		isArray: true,
    			params: {orgId:'@orgId'},
    			url: 'api/orgId/:orgId/he-connectivity-settings'
    	 		},
        	
              'manualLogin': {
                  method: 'POST',
                  url: 'order/manual-login'
              },
              'changeStatus': {
                  method: 'POST',
                  params: {status:'@status'},
                  url: 'order/he-interactive-status-update'
              },
              'getServerComponentStatus': {
                  method: 'GET',
                  isArray: true,
                  url: 'api/server-component-status',
                  transformResponse: function (data) {
                      if (data) {
                          data = angular.fromJson(data);
                      }
                      return data;
                  }
              }
        });
    }
})();
