(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'creditFactorSettings'
        })
        
        .controller('CreditFactorSettingsController', CreditFactorSettingsController);

    CreditFactorSettingsController.$inject = ['$scope', '$state', 'CreditFactorSettings', 'GlobalValues', 'workflowSharedService', 
    'EntityPermissions', 'ScreenLog','entityConstants','$timeout', 'validateSODAndSession', 'IntradayMarket', 'MessageBox'];

    function CreditFactorSettingsController($scope, $state, CreditFactorSettings , GlobalValues, workflowSharedService, 
      EntityPermissions, ScreenLog,entityConstants,$timeout, validateSODAndSession, IntradayMarket, MessageBox) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CREDIT_FACTOR_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "creditFactorSettings";
        vm.bank = GlobalValues.getBank();
        $scope.refresh=refresh;
        vm.exportToFile = exportToFile;
        vm.editCreditFactor = editCreditFactor;
        vm.validateSODAndSession = validateSODAndSession;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForPercent = 4;
        
        vm.permitCreditFactorSettingsEditEntityAction = EntityPermissions.hasPermission('CreditFactorSettings.EDIT');
        
        //get market session settings.
        IntradayMarket.getIntradayMarketSessionSettingsForInstrument({"instrument":'InterbankSPOT'}, onGetMarketSessionSuccess, onGetMarketSessionFailure);

        function onGetMarketSessionSuccess(result){
          vm.marketSessionSettings = result;
        }

        function onGetMarketSessionFailure(){

        }

        logScreenAccess();
        loadAll();

        function loadAll() {
    	    CreditFactorSettings.query({orgId: vm.globalValues.getOrganisation().id}, getCreditFactorSettingsSuccess, getCreditFactorSettingsFailure);
        }      
       

        function editCreditFactor(creditFactorSettingsHeaderId){
          if(vm.marketSessionSettings.sessionStatus=='OPEN'){
            MessageBox.openMessageBox("Alert", "Modification is permitted only after the IB.SPOT market hours...!", "Ok", "creditfactorsettings");
          }else if(!vm.validateSODAndSession.allowToAddOrEdit){
            MessageBox.openMessageBox("Alert", "SOD is completed for the business day, modifications is not permitted.", "Ok", "creditfactorsettings");
          }else{
            vm.screenLog.screenLog(vm.screenName, creditFactorSettingsHeaderId, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
            $state.go("credit-factor-settings-edit", {id:creditFactorSettingsHeaderId});
          }
        }

       function getCreditFactorSettingsSuccess(result){
    	   vm.creditFactorSettingsHeader = result;
    	   vm.creditFactorSettingsDetailsList=[];
    	   
         if(vm.creditFactorSettingsHeader!=null && vm.creditFactorSettingsHeader!=undefined){
             angular.forEach(vm.creditFactorSettingsHeader.creditFactorSettingsDetailsList, function(detail){
               vm.details = angular.copy(detail);
               vm.details.creditFactorSettingsHeader = vm.creditFactorSettingsHeader;
               vm.creditFactorSettingsDetailsList.push(vm.details);
             })
         }
    		  
       }
       
       function getCreditFactorSettingsFailure(){}
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };

	   	function exportToFile(fileType){
			vm.baseString =   'Credit_Factor_Settings_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			if(vm.creditFactorSettingsDetailsList !=null &&  vm.creditFactorSettingsDetailsList !=undefined){
				if(fileType == 'csv' ){
					vm.mimeType = 'text/csv'
					vm.baseString = vm.baseString+ ".csv";  
					CreditFactorSettings.getCreditFactorCSV(vm.creditFactorSettingsDetailsList, processResponse);
				}else if(fileType == 'excel' ){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls";  
					CreditFactorSettings.getCreditFactorExcel(vm.creditFactorSettingsDetailsList, processResponse);
				}
			}
		}
	   	
		function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			
		}
       
       
    }
})();
