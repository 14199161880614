(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('TACViewForEntitiesService', TACViewForEntitiesService);

    TACViewForEntitiesService.$inject = ['$resource'];

    function TACViewForEntitiesService ($resource) {
        var resourceUrl =  'api/tac-agreed-view/:id';

        return $resource(resourceUrl, {}, {
            
            
            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/discard'
             },
             'resubmit': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/resubmit-tac-agreed-entities',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             },
            'getBankTacAgreedDetails':{
	           	 method: 'GET',
	             params: {bankId:'@bankId'},
	             url: 'api/tac-agreed-view/get-bank-ccil-tac',
	             isArray: true            	
            },
            'getCustomerTacAgreedDetails':{
	           	 method: 'GET',
	             params: {customerId:'@customerId'},
	             url: 'api/tac-agreed-view/get-customer-all-tac',
	             isArray: true            	
            },
            'getTacAgreedDetailsForGivenCustomer':{
	           	 method: 'GET',
	             params: {customerId:'@customerId'},
	             url: 'api/tac-agreed-view/get-all-agreed-tac-for-customer',
	             isArray: true            	
            },
            'agreeTAC' :{
            	 method: 'POST',
            	 url: 'api/tac-agreed-view/agree-tac',
	             transformRequest: function (data) {
	                 	var copy = angular.copy(data);
	                 	return angular.toJson(copy);
	                 }
            },
            'getAnnexure2':{
                method:'POST',
                url: 'api/terms-conditions-upload/annexure2',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getAnnexure1':{
                method:'POST',
                url: 'api/public/terms-conditions-upload/annexure1',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getBankExcel':{
                method:'POST',
                url: 'api/tac-agreed-view/excel',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getCustomerExcel':{
                method:'POST',
                url: 'api/tac-agreed-view/customer/excel',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getBankCsv':{
                method:'POST',
                url: 'api/tac-agreed-view/csv',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getCustomercsv':{
                method:'POST',
                url: 'api/tac-agreed-view/customer/csv',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getActiveTACForOrganisationAndTacType':{
                 method: 'GET',
                 params: {orgId:'@orgId'},
                 url: 'api/public/active-tac-master/:orgId/org/:tacTypeId/tac-type'
            },
            'getCustomerAgreedTACList':{
                isArray:true,
                method: 'GET',
                url: 'api/customer-agreed-tac-list/:customerId/customer'
             }
            
        });
    }
})();

            
