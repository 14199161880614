(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('BankRegistrationDeleteController',BankRegistrationDeleteController);

    BankRegistrationDeleteController.$inject = ['$uibModalInstance', 'entity', 'BankRegistration', 'GlobalValues','$scope'];

    function BankRegistrationDeleteController($uibModalInstance, entity, BankRegistration, GlobalValues,$scope) {
        var vm = this;

        vm.bankRegistration = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.clicked=false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        function confirmDelete (id) {
        	BankRegistration.delete({"corpId": GlobalValues.getCorpId(),"id":id},
                    function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
