(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('HistoryReport', HistoryReport);

    HistoryReport.$inject = ['$resource'];

    function HistoryReport ($resource) {
        var resourceUrl =  'api/get-history-report/:id';

        return $resource(resourceUrl, {}, {
            
        	 'getHistoryReportDetails':{
             	isArray:true,
  				method: 'GET',
  				params: {entityId:'@entityId',login:'@login',from:'@from',to:'@to',orgId:'@orgId'},
  				url: 'api/history-report/:entityId/:from/:to/get-history-report-details'
              },
             'getHistoryReportDetailsFromArchive':{
               	isArray:true,
    			method: 'GET',
    			params: {entityId:'@entityId',login:'@login',from:'@from',to:'@to',orgId:'@orgId'},
    			url: 'api/history-archive-report/:entityId/:from/:to/get-history-report-details'
              }
        });
    }
})();
