(function() {
    'use strict';

    var app = angular.module('gatewayApp');
    app.service("MessageBox", MessageBox)
    app.controller('ModalInstanceCtrl',['MessageBox', '$uibModalInstance','$sce', function(MessageBox, $uibModalInstance,$sce) {

    	var vm = this;
    	vm.messageDetails = {};
    	vm.messageDetails.messageTitle = MessageBox.getMessageTitle();
    	vm.messageDetails.messageContent = MessageBox.getMessageContent();
		vm.messageDetails.msgType = MessageBox.getMsgType();
		vm.messageDetails.alertType = MessageBox.getAlertType();
		vm.message = $sce.trustAsHtml(vm.messageDetails.messageContent);
    	vm.mode = MessageBox.getMode();

    	vm.yesButton = false;
    	vm.noButton = false;
    	vm.okButton = false;
    	vm.proceedButton = false;
    	vm.cancelButton = false;
    	vm.proceedBroadcastButton = false;

    	if(vm.mode == "OkCancel") { vm.okButton = true; vm.cancelButton = true;}
    	else if(vm.mode == "YesNo") { vm.yesButton = true; vm.noButton = true;}
    	else if(vm.mode == "YesNoCancel") { vm.yesButton = true; vm.noButton = true; vm.cancelButton = true;}
    	else if(vm.mode == "Ok") { vm.okButton = true;}
    	else if(vm.mode == "ProceedCancel") { vm.proceedButton = true; vm.cancelButton = true;}
    	else if(vm.mode == "ProceedCancelBroadcast") { vm.proceedBroadcastButton = true; vm.cancelButton = true;}
    	vm.ok = function() {
    	    $uibModalInstance.close("ok");
    	};
    	vm.cancel = function() {
    	    $uibModalInstance.dismiss("cancel");
    	};
    	vm.yes = function() {
    	    $uibModalInstance.close("yes");
    	};
    	vm.no = function() {
    	    $uibModalInstance.dismiss("no");
    	};
    	vm.proceed = function() {
    	    $uibModalInstance.close("proceed");
    	};
    	vm.proceedBroadcast = function() {
    	    $uibModalInstance.close("proceedBroadcast");
    	};
    }]);

    MessageBox.$inject = ['$uibModal', '$rootScope'];

    function MessageBox($uibModal, $rootScope) {
    	var messageTitle = "";
    	var messageContent = "";
    	var mode = "";
    	var moduleInstance = null;
		var msgType="";
		var alertType="";
		

    	var service = {
    		showMessageBox: showMessageBox,
    		getMessageContent: getMessageContent,
			getMsgType:getMsgType,
			getAlertType:getAlertType,
    		getMessageTitle: getMessageTitle,
    		getMode: getMode,
            openMessageBox: openMessageBox,
			openAlertMessageBox:openAlertMessageBox,
            closeMessageBox: closeMessageBox
    	}
    	return service;

    	function getMessageTitle(){
    		return messageTitle;
    	}

    	function getMessageContent(){
    		return messageContent;
    	}
		function getMsgType(){
    		return msgType;
    	}
		function getAlertType(){
			return alertType;
		}

    	function getMode() {
    		return mode;
    	}

    	function showMessageBox(title, content, msgMode) {
    		messageTitle = title;
    		messageContent = content;
    		mode = msgMode;
            openUibModal(null);
    	}

        function openMessageBox(title, content, msgMode, screenName) {
            messageTitle = title;
            messageContent = content;
            mode = msgMode;
            openUibModal(screenName);
        }
		// open alert message box for Hard check and soft check order validation
		function openAlertMessageBox(title, content, msgMode, screenName,msgtype,alerttype) {
            messageTitle = title;
            messageContent = content;
            mode = msgMode;
			msgType= msgtype;
			alertType=alerttype;
            openAlertUibModal(screenName);
        }
        function openUibModal(screenName) {
            moduleInstance = $uibModal.open({
                templateUrl: 'app/controllers/messagebox/messagebox.html',
                controller: 'ModalInstanceCtrl',
                controllerAs: 'vm',
            });
            moduleInstance.result
                .then(function(successOption) {
                    if(successOption == 'ok') { 
                        if (true) {}
                        if(screenName != undefined && screenName != null && screenName != "" && screenName != " ") {
                        	$rootScope.$emit(getEmitMessage('gatewayApp.messagebox.ok', screenName)); 
                        }else{
                        	$rootScope.$emit('gatewayApp.messagebox.ok');
                        }
                    }
                    else if(successOption == 'yes') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.yes', screenName)); }
                    else if(successOption == 'proceed') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.proceed', screenName)); }
                    else if(successOption == 'proceedBroadcast') { $rootScope.$broadcast(getEmitMessage('gatewayApp.messagebox.proceed', screenName)); }
            }, function(failureOption) {
                if(failureOption == 'cancel') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.cancel', screenName)); }
                else if(failureOption == 'no') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.no', screenName)); }
                else { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.clear', screenName)); }
            });
        }
		//for soft check and hard check order alert
		function openAlertUibModal(screenName) {
            moduleInstance = $uibModal.open({
                templateUrl: 'app/controllers/messagebox/alertMessageBox.html',
                controller: 'ModalInstanceCtrl',
                controllerAs: 'vm',
            });
            moduleInstance.result
                .then(function(successOption) {
                    if(successOption == 'ok') { 
                        if (true) {}
                        if(screenName != undefined && screenName != null && screenName != "" && screenName != " ") {
                        	$rootScope.$emit(getEmitMessage('gatewayApp.messagebox.ok', screenName)); 
                        }else{
                        	$rootScope.$emit('gatewayApp.messagebox.ok');
                        }
                    }
                    else if(successOption == 'yes') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.yes', screenName)); }
                    else if(successOption == 'proceed') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.proceed', screenName)); }
                    else if(successOption == 'proceedBroadcast') { $rootScope.$broadcast(getEmitMessage('gatewayApp.messagebox.proceed', screenName)); }
            }, function(failureOption) {
                if(failureOption == 'cancel') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.cancel', screenName)); }
                else if(failureOption == 'no') { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.no', screenName)); }
                else { $rootScope.$emit(getEmitMessage('gatewayApp.messagebox.clear', screenName)); }
            });
        }

        function getEmitMessage(message, screeenName) {
            if(screeenName != undefined && screeenName != null && screeenName != "" && screeenName != " ") {
                return message + "." + screeenName;
            } else {
                return message;
            }
        }
        function closeMessageBox(msgMode) {
            if ( msgMode == mode && moduleInstance!= null && moduleInstance !=undefined){
            	moduleInstance.close();
            }
            
        }

     }

})();