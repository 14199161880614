(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SpecialHolidaysDialogController', SpecialHolidaysDialogController);

    SpecialHolidaysDialogController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','SpecialHolidays','entity', 'DateUtils', 'ScreenLog','entityConstants'];

     function SpecialHolidaysDialogController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,SpecialHolidays,entity,DateUtils,ScreenLog,entityConstants) {
        var vm = this;
        vm.specialHolidays=entity;  
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SPECIAL_HOLIDAY;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        vm.clear=clear;
        vm.save=save;
        $scope.clicked=false;
        $scope.showButton = false;
        vm.resubmitSpecialHolidays =resubmitSpecialHolidays;
        vm.globalValues = GlobalValues;
        
        $scope.showButton = false;
        
        
                
        if(workflowSharedService.viewFrom == 'specialholidays'){
        	vm.specialHolidays=entity;
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'specialholidays_resubmit') {
        	vm.specialHolidays = angular.fromJson($rootScope.payload); 
        	vm.screenLog.screenLog(vm.screenName, vm.specialHolidays.id==null?0:vm.specialHolidays.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	vm.disableForm = false;
        	$scope.showButton=true;
        }
        else if(workflowSharedService.viewFrom == 'specialholidays_resubmit_delete') {
        	vm.specialHolidays = angular.fromJson($rootScope.payload); 
        	vm.disableForm = true;
        	$scope.showButton=true;
        }
		vm.specialHolidays.holidayDate = new Date(vm.specialHolidays.holidayDate);

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {        	    		
        	 vm.specialHolidays.holidayDate = DateUtils.convertLocalDateToServer(vm.specialHolidays.holidayDate);
       		
        	 if (vm.specialHolidays.id !== null) {
                 SpecialHolidays.update({ "orgId": GlobalValues.getOrgId() },vm.specialHolidays, onSaveSuccess, onSaveError);
             } else {
            	 SpecialHolidays.save({ "orgId": GlobalValues.getOrgId() },vm.specialHolidays, onSaveSuccess, onSaveError);           
             }       	
        	
         }
     
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.specialHolidays.holidayDate = DateUtils.convertDateTimeFromServer(vm.specialHolidays.holidayDate);

        	$scope.clicked = false;
        }
        function resubmitSpecialHolidays() {
       		SpecialHolidays.resubmit({"taskId" : $rootScope.taskID}, vm.specialHolidays, onResubmitSuccess, onResubmitError);
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {}

       
     }   
})();
