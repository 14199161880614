(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'customerTacUploadByBankView'
	})

	.controller('CustomerTACByBankViewController', CustomerTACByBankViewController);

	CustomerTACByBankViewController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
		'AlertService','$rootScope','CustomerTACUploadByBank','$timeout','TacViewForEntitiesUtility','entity'];

	function CustomerTACByBankViewController($scope, $state,  GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
			AlertService,$rootScope,CustomerTACUploadByBank,$timeout,TacViewForEntitiesUtility,entity) {

		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_TERMS_AND_CONDITIONS_UPLOAD;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.filterData = {};	    
		vm.extension = "";
		workflowSharedService.viewFrom = "customerTacUploadByBankView";
		logScreenAccess();
		vm.loadAll = loadAll;
		loadAll();
		vm.valid = false;
		vm.openTACPreview = openTACPreview;
		vm.uploadTAC = uploadTAC;
		$scope.refresh=refresh;
		vm.bankTACDetails = entity;


		function base64ToArrayBuffer(base64) {
			var binary_string =  window.atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array( len );
			for (var i = 0; i < len; i++)        {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		}

		function openTACPreview(tacFile){
			TacViewForEntitiesUtility.previewTAC(tacFile.fileContent,tacFile.fileName);	
		}

		function uploadTAC(tacDetails){
			$state.go('customer-tac-upload-by-bank',{id:tacDetails.id});

		}

		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		
		function loadAll() {
			vm.bankTACDetails = [];
			CustomerTACUploadByBank.getActiveCustomerTACUploadByBank({organisationId:GlobalValues.getOrgId()},function(result) {
				vm.bankTACDetails = result;

			});
		}

		$scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };

	}
})();