(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ManageUserClearanceController', ManageUserClearanceController);

    ManageUserClearanceController.$inject = ['Workflow', '$scope', '$state', '$stateParams', 'workflowSharedService', '$rootScope', '$uibModalInstance', 'entity', 'User', 'GlobalValues', 'ScreenLog'];

     function ManageUserClearanceController(Workflow, $scope, $state, $stateParams, workflowSharedService, $rootScope, $uibModalInstance, entity, User, GlobalValues, ScreenLog) {
        var vm = this;
        vm.screenName = 'UserClearance';
        vm.screenLog = ScreenLog;
        vm.ViewDisable = false;
        vm.selected = {
        		branch:[]
        		
        };
        vm.select = {
        		organisation:[]
        };

        vm.discardUserClearance=discardUserClearance;
        vm.approveUserClearance=approveUserClearance;
        vm.rejectUserClearance=rejectUserClearance;
        vm.resubmit = resubmit;
        $scope.clicked=false;
        if(workflowSharedService.viewFrom == 'userRole') {
        	
         	$scope.showButton=false;
         	$scope.showDiscardButton=false;
        	$scope.showSave=true;
        	$scope.showResubmit=false;
         	$scope.disableElements=false;
        vm.getUserClearanceDTO = entity;
        User.getClearance({"userId" :vm.getUserClearanceDTO.userID}, onGetSuccess, onGetError);
		//ScreenLog.screenLog({'entityName': 'UserClearance', 'entityRecordId': vm.getUserClearanceDTO.userID, 'action': 'MAP'});
        }
        else if(workflowSharedService.viewFrom == 'userclearancetodolist_checker'){
        	vm.ViewDisable = true;
          	$scope.showButton=true;
         	$scope.showDiscardButton=false;
        	$scope.showSave=false;
        	$scope.showResubmit=false;
        	$scope.disableElements=true;
        	vm.setClearanceDTO =  angular.fromJson($rootScope.payload); 
        	User.getUserClearance({"userId" : vm.setClearanceDTO.userID}, onGetSuccessChecker, onGetSuccessError);
        	
        	assignDataForChecklist();
        }
        else if(workflowSharedService.viewFrom == 'userclearancetodolist_maker'){
        	vm.ViewDisable = true;
         	$scope.showButton=false;
         	$scope.showResubmit=false;
         	$scope.showSave=false;
         	$scope.showDiscardButton=true;
         	$scope.disableElements=true;
        	vm.setClearanceDTO =  angular.fromJson($rootScope.payload);
        	User.getUserClearance({"userId" : vm.setClearanceDTO.userID}, onGetSuccessChecker, onGetSuccessError);
        	
        	assignDataForChecklist();
        }
        else if(workflowSharedService.viewFrom == 'userclearancetodolist_resubmit'){
        	$scope.showButton=false;
        	$scope.showResubmit=true;
         	$scope.showDiscardButton=false;
        	$scope.showSave=false;
        	$scope.disableElements=false;
        	vm.setClearanceDTO =  angular.fromJson($rootScope.payload);
        	vm.screenLog.screenLog(vm.screenName, vm.setClearanceDTO.userID, 'RESUBMIT');
        	User.getUserClearance({"userId" : vm.setClearanceDTO.userID}, onGetSuccessChecker, onGetSuccessError);
        	assignDataForChecklist();
        }
        function onGetSuccess(result){
        	vm.setClearanceDTO = result;
        	
        	vm.setClearanceDTO.userShortName = vm.getUserClearanceDTO.userShortName;
        	assignDataForChecklist();
        }
        function onGetError(error){
        	
        }
        function onGetSuccessChecker(result){
        	vm.getUserClearanceDTO =result;
        	vm.setClearanceDTO.userShortName = vm.getUserClearanceDTO.userShortName;
        	assignDataForChecklist();
        }
        function onGetSuccessError(error){
        	
        }
        
        vm.save = save;
        vm.clear = clear;
        $scope.corporate = {id:{}};
        
        
        
        function assignDataForChecklist(){
        	vm.selected.branch = vm.setClearanceDTO.buIDList;
        	vm.select.organisation =  vm.setClearanceDTO.organisationIDList;
        }
       
        
       
        function resubmit () {	
        	vm.setClearanceDTO.organisationIDList = vm.select.organisation;
        	vm.setClearanceDTO.buIDList = vm.selected.branch;
        	vm.setClearanceDTO.userID = vm.getUserClearanceDTO.userID;
        	
            User.resubmitClearance({"taskId" : $rootScope.taskID},vm.setClearanceDTO, onSaveSuccess, onSaveError);	
        }
     
        
        function save () {	
        	vm.setClearanceDTO.organisationIDList = vm.select.organisation;
        	vm.setClearanceDTO.buIDList = vm.selected.branch;
        	vm.setClearanceDTO.userID = vm.getUserClearanceDTO.userID;
		
            User.saveClearance({orgId:GlobalValues.getOrgId()}, vm.setClearanceDTO, onSaveSuccess, onSaveError);	
        }
        function onSaveSuccess (result) {
        	
            $uibModalInstance.close(result);  
        }

        function onSaveError (error) {
        	
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function approveUserClearance() { 
        	User.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);
        	
        }        
        function onApproveError(error) {
        	
        }
        
        function rejectUserClearance() { 
        	Workflow.rejectTask($rootScope.taskID);
        }

        
        function discardUserClearance() {
        	User.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
     } 
})();