(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TransactionChargesSettingsDeleteController',TransactionChargesSettingsDeleteController);

    TransactionChargesSettingsDeleteController.$inject = ['$uibModalInstance', 'entity', 'TransactionChargesSettings', 'GlobalValues','$scope'];

    function TransactionChargesSettingsDeleteController($uibModalInstance, entity, TransactionChargesSettings, GlobalValues,$scope) {
        var vm = this;

        vm.transactionCharges = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.clicked=false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        
        function confirmDelete (id) {
        	TransactionChargesSettings.delete({"orgId": GlobalValues.getOrgId(),"id":id},
                    function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
