(function() {
	'use strict';
	angular.module('gatewayApp').factory('CustomerInfoChange', CustomerInfoChange);

	CustomerInfoChange.$inject = [ '$resource' ];

	function CustomerInfoChange($resource) {
		var resourceUrl = 'api/customer-info/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'getCustomers' : {
				method : 'GET',
				isArray : true,
				params : {
					orgId : '@orgId'
				},
				url : 'api/customer-info-status/orgId/:orgId',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/multitask/discard'
			},
			 
			'resubmitCustomer' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmitForCreate/bank',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'getAllTasksForCustomersBasedOnReference' : {
				method : 'GET',
				isArray : true,
				params : {
					orgId : '@orgId',
					reference: '@reference'
				},
				url : 'api/allTasks/customer-info-status/orgId/:orgId/reference/:reference',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			

		});
	}
})();
