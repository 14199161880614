(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("MarketWatch", MarketWatch);

    MarketWatch.$inject = ['$sessionStorage', '$filter'];

    function MarketWatch($sessionStorage, $filter) {

        var NA_LimitTenorTypeId = 1;
        var MAXIMUMTENORVALUE_LimitTenorTypeId = 2;
        var MAXIMUMVALUEDATE_LimitTenorTypeId = 3;

    	var service = {
    		getTickerPrice: getTickerPrice,
    		getDefaultBankSwapQuotes: getDefaultBankSwapQuotes,
    		getRetailSpotMarketDepth: getRetailSpotMarketDepth,
    		getIBSpotMarketDepth: getIBSpotMarketDepth,
    		getRetailSpotMarketWatch: getRetailSpotMarketWatch,
    		getIBSpotMarketWatch: getIBSpotMarketWatch,
    		getMarketWatchForGivenInstrument: getMarketWatchForGivenInstrument,
    		getBankSwapQuotesInPrice: getBankSwapQuotesInPrice,
    		getMarketDepth: getMarketDepth,
            getSpreadForTenorAndSettlementDate: getSpreadForTenorAndSettlementDate,
            getBestBidOfferForUserMarketWatch: getBestBidOfferForUserMarketWatch,
            getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice: getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice,
            fillTenorNameAndSettlementDate: fillTenorNameAndSettlementDate,
            getTenorNameForSettlementDateInUserMarketWatch: getTenorNameForSettlementDateInUserMarketWatch,
            fillBidOfferSpreadInUserMarketWatch: fillBidOfferSpreadInUserMarketWatch,
            fillBidOfferPriceInUserMarketWatch: fillBidOfferPriceInUserMarketWatch,
            getAndFillBidOfferSpreadForDateInUserMarketWatch: getAndFillBidOfferSpreadForDateInUserMarketWatch,
            sortOutrightRatesList: sortOutrightRatesList,
            gettickSizeForGivenInstrument: gettickSizeForGivenInstrument,
            getInstrumentCodeForSettlement: getInstrumentCodeForSettlement,
            computeTenorDatesForGivenCreditFactorSettingsHeaderList: computeTenorDatesForGivenCreditFactorSettingsHeaderList,
            computeTenorDatesForGivenCreditFactorSettingsHeader: computeTenorDatesForGivenCreditFactorSettingsHeader,
            getCreditFactorValue: getCreditFactorValue,
            getCreditFactorSettingsForGivenBank: getCreditFactorSettingsForGivenBank,
            computeLimitAmountApplyingCreditFactor: computeLimitAmountApplyingCreditFactor,
            getMaxForwardTenorDate: getMaxForwardTenorDate,
            addDaysToGivenDate: addDaysToGivenDate

    	};
    	return service;
    	
    	/*function getTickerPrice(marketDepth, currentTickerPrice, previousDayClosingPriceForSpot, previousDayClosingPriceForIbSpot){
    		var tickerPrice = {};
    		tickerPrice = angular.copy(currentTickerPrice);
    		//angular.forEach(marketDepth, function(value){
    			if(marketDepth!=null && marketDepth!=undefined){
    				if(marketDepth.instrumentID==3){
    					tickerPrice.retailSpot = marketDepth.ltp;
    					if(marketDepth.ltp > 0){
    						if(currentTickerPrice.retailSpot>0 && marketDepth.ltt!=tickerPrice.lttSpot){
        						tickerPrice.retailSpotDifference = marketDepth.ltp - currentTickerPrice.retailSpot;	
    						}else if(currentTickerPrice.retailSpot<=0){
        						tickerPrice.retailSpotDifference = marketDepth.ltp - previousDayClosingPriceForSpot;	
    						}
    					}else{
    						tickerPrice.retailSpot = previousDayClosingPriceForSpot;
    						tickerPrice.retailSpotDifference = 0;
    					}
    					if(marketDepth.ltt!=null && marketDepth.ltt!=undefined && marketDepth.ltt!=''){
    						tickerPrice.lttSpot = marketDepth.ltt;
    					}
					}else if(marketDepth.instrumentID==4){
    					tickerPrice.ibSpot = marketDepth.ltp;
    					if(marketDepth.ltp > 0){
    						if(currentTickerPrice.ibSpot > 0 && marketDepth.ltt!=tickerPrice.lttIbSpot){
        						tickerPrice.ibSpotDifference = marketDepth.ltp - currentTickerPrice.ibSpot;	
    						}if(currentTickerPrice.ibSpot<=0){
    							if(previousDayClosingPriceForIbSpot!=null && previousDayClosingPriceForIbSpot!=undefined){
    								tickerPrice.ibSpotDifference = marketDepth.ltp - previousDayClosingPriceForIbSpot;
    							}
    						}
    					}else{
    						if(previousDayClosingPriceForIbSpot!=null && previousDayClosingPriceForIbSpot!=undefined){
        						tickerPrice.ibSpot = previousDayClosingPriceForIbSpot;
    						}
    						tickerPrice.ibSpotDifference = 0;
    					}
    					if(marketDepth.ltt!=null && marketDepth.ltt!=undefined && marketDepth.ltt!=''){
    						tickerPrice.lttIbSpot = marketDepth.ltt;
    					}
					}
    			}
    		//});
    		return tickerPrice;
    	}*/
    	
    	function getTickerPrice(marketDepth, currentTickerPrice, previousDayClosingPriceForSpot, previousDayClosingPriceForIbSpot){
    		var tickerPrice = {};
    		tickerPrice = angular.copy(currentTickerPrice);
    		//angular.forEach(marketDepth, function(value){
    			if(marketDepth!=null && marketDepth!=undefined){
    				if(marketDepth.instrumentID==3){
    					tickerPrice.retailSpot = marketDepth.ltp;
    					if(marketDepth.ltp > 0){
    						if(tickerPrice.retailSpot>0){
    							tickerPrice.retailSpotDifference = marketDepth.ltp - previousDayClosingPriceForSpot;
    						}
    					}else{
    						if(previousDayClosingPriceForSpot!=null && previousDayClosingPriceForSpot!=undefined){
        						tickerPrice.retailSpot = previousDayClosingPriceForSpot;
    						}
    						tickerPrice.retailSpotDifference = 0;
    					}
					}else if(marketDepth.instrumentID==4){
    					tickerPrice.ibSpot = marketDepth.ltp;
    					if(marketDepth.ltp > 0){
    						if(tickerPrice.ibSpot > 0){
    							if(previousDayClosingPriceForIbSpot!=null && previousDayClosingPriceForIbSpot!=undefined){
    								tickerPrice.ibSpotDifference = marketDepth.ltp - previousDayClosingPriceForIbSpot;
    							}else{
    								tickerPrice.ibSpotDifference = 0;
    							}
    						}
    					}else{
    						if(previousDayClosingPriceForIbSpot!=null && previousDayClosingPriceForIbSpot!=undefined){
        						tickerPrice.ibSpot = previousDayClosingPriceForIbSpot;
    						}
    						tickerPrice.ibSpotDifference = 0;
    					}
					}
    			}
    		//});
    		return tickerPrice;
    	}
    	
    	//get default or selected trading bank
    	function getDefaultBankSwapQuotes(swapQuotesList, defaultTradingBankId){
    		//loop swap quotes list
    		//match the bank of the swap quotes with default bank
    		//get that bank swap quotes and return
    		var defaultBankSwapQuotes = [];
    		angular.forEach(swapQuotesList, function(value){
    			if(value.bank.id==defaultTradingBankId){
    				defaultBankSwapQuotes = value.swapQuotesForTenorDatesList;
    			}
    		});
    		return defaultBankSwapQuotes;
    	}
    	
    	function getRetailSpotMarketDepth(marketDepth){
    		//loop market depth
    		//get retailSpot market depth and return
			var spotMarketDepth = {};
    		angular.forEach(marketDepth, function(value){
    			if(value.instrumentID == 3){
    				var details = getMarketDepthDetails(value);
    				spotMarketDepth = value;
    				spotMarketDepth.marketDepthDetails = details;
    			}
    		})
    		return spotMarketDepth;
    		/*var spotMaketDepth = {};
    		if(marketDepth!=null && marketDepth!=undefined && marketDepth.length > 1){
        		spotMaketDepth = marketDepth[1];
    		}
    		return spotMaketDepth;*/
    	}
    	
    	function getIBSpotMarketDepth(marketDepth){
    		//loop market depth
    		//get IB spot market depth and return
    		var ibSpotMarketDepth = [];
    		angular.forEach(marketDepth, function(value){
    			if(value.instrumentID == 4){
    				var details = getMarketDepthDetails(value);
    				ibSpotMarketDepth = value;
    				ibSpotMarketDepth.marketDepthDetails = details;
    			}
    		})
    		return ibSpotMarketDepth;
    	}
    	
    	//get market depth from market depth dto coming from push notification server
    	function getMarketDepth(marketDepthDTO){
    		var marketDepth = {};
			var details = getMarketDepthDetails(marketDepthDTO);
			marketDepth = marketDepthDTO;
			marketDepth.marketDepthDetails = details;
			return marketDepth;
    	}
    	
    	function getMarketDepthDetails(marketDepth){
    		var spotMarketDepth = [];
    		angular.forEach(marketDepth.bidDetails, function(value, index){
    			if(spotMarketDepth.length>index){
					spotMarketDepth[index].bidPrice = value.price;
					spotMarketDepth[index].bidVolume = value.volume;
    			}else{
        			var spotMarketDepthDetail = {};
        			//spotMarketDepthDetail.levelNo = value.levelNo;
    				spotMarketDepthDetail.bidPrice = value.price;
    				spotMarketDepthDetail.bidVolume = value.volume;
        			spotMarketDepth.push(spotMarketDepthDetail);
    			}
    		})
    		
    		angular.forEach(marketDepth.offerDetails, function(value, index){
    			if(spotMarketDepth.length>index){
					spotMarketDepth[index].offerPrice = value.price;
					spotMarketDepth[index].offerVolume = value.volume;
    			}else{
        			var spotMarketDepthDetail = {};
        			//spotMarketDepthDetail.levelNo = value.levelNo;
    				spotMarketDepthDetail.offerPrice = value.price;
    				spotMarketDepthDetail.offerVolume = value.volume;
        			spotMarketDepth.push(spotMarketDepthDetail);
    			}
    		})
    		
    		return spotMarketDepth;
    		
    	}
    	
    	function getRetailSpotMarketWatch(retailSpotMarketDepth){
    		//loop market depth
    		//get first record of the market depth 
    		//fill best bid price, offer price, last traded bid qty, last traded offer qty to variable and return it.
    		var retailSpotMarketWatch = {};
    		if(retailSpotMarketDepth!=null && retailSpotMarketDepth!=undefined){
    			if(retailSpotMarketDepth.marketDepthDetails!=null && retailSpotMarketDepth.marketDepthDetails!=undefined && retailSpotMarketDepth.marketDepthDetails.length>0){
    				if(retailSpotMarketDepth.marketDepthDetails[0].bidPrice>0){
        				retailSpotMarketWatch.bidPrice = retailSpotMarketDepth.marketDepthDetails[0].bidPrice.toString();
    				}
    				if(retailSpotMarketDepth.marketDepthDetails[0].offerPrice>0){
        				retailSpotMarketWatch.offerPrice = retailSpotMarketDepth.marketDepthDetails[0].offerPrice.toString();
    				}
    				retailSpotMarketWatch.bidVolume = retailSpotMarketDepth.marketDepthDetails[0].bidVolume;
    				retailSpotMarketWatch.offerVolume = retailSpotMarketDepth.marketDepthDetails[0].offerVolume;
    			}
    		}
    		return retailSpotMarketWatch;
    	}
    	
    	function getIBSpotMarketWatch(ibSpotMarketDepth){
    		//loop market depth
    		//get first record of the market depth 
    		//fill best bid price, offer price, last traded bid qty, last traded offer qty to variable and return it. 
    		var ibSpotMarketWatch = {};
    		if(ibSpotMarketDepth!=null && ibSpotMarketDepth!=undefined){
    			if(ibSpotMarketDepth.marketDepthDetails!=null && ibSpotMarketDepth.marketDepthDetails!=undefined && ibSpotMarketDepth.marketDepthDetails.length>0){
    				if(ibSpotMarketDepth.marketDepthDetails[0].bidPrice>0){
        				ibSpotMarketWatch.bidPrice = ibSpotMarketDepth.marketDepthDetails[0].bidPrice.toString();
    				}
    				if(ibSpotMarketDepth.marketDepthDetails[0].offerPrice>0){
        				ibSpotMarketWatch.offerPrice = ibSpotMarketDepth.marketDepthDetails[0].offerPrice.toString();
    				}
    				ibSpotMarketWatch.bidVolume = ibSpotMarketDepth.marketDepthDetails[0].bidVolume;
    				ibSpotMarketWatch.offerVolume = ibSpotMarketDepth.marketDepthDetails[0].offerVolume;
    			}
    		}
    		return ibSpotMarketWatch;
    	}
    	
    	/*function getMarketWatchForGivenInstrument(retailSpotMarketDepth, defaultBankSpread, instrument){
    		//compute cash/tom market watch by using retailSpot market watch and bank spread
    		//bid value for cash/tom = bid value of retailSpot mkt - offer spread cash/tom
    		//offer value for cash/tom = offer value of retailSpot mkt - bid spread cash/tom
    		var marketWatch = {};
			if(retailSpotMarketDepth.ltp!=null && retailSpotMarketDepth.ltp!=undefined){
	    		if(instrument=='CASH'){
	        		marketWatch.bidPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketDepth.ltp, defaultBankSpread.cashSpotOfferSpread);
	        		marketWatch.offerPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketDepth.ltp, defaultBankSpread.cashSpotBidSpread);
	    		}else if(instrument=='TOM'){
	        		marketWatch.bidPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketDepth.ltp, defaultBankSpread.tomSpotOfferSpread);
	        		marketWatch.offerPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketDepth.ltp, defaultBankSpread.tomSpotBidSpread);
	    		}
	    		marketWatch.bidVolume = retailSpotMarketDepth.ltq;
	    		marketWatch.offerVolume = retailSpotMarketDepth.ltq; 
			}
    		return marketWatch;
    	}*/
    	
    	function getMarketWatchForGivenInstrument(retailSpotMarketWatch, defaultBankSpread, instrument){
    		//compute cash/tom market watch by using retailSpot market watch and bank spread
    		//bid value for cash/tom = bid value of retailSpot mkt - offer spread cash/tom
    		//offer value for cash/tom = offer value of retailSpot mkt - bid spread cash/tom
    		var marketWatch = {};
			if(retailSpotMarketWatch.bidVolume!=null && retailSpotMarketWatch.offerVolume!=undefined){
	    		if(instrument=='CASH'){
	        		marketWatch.bidPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.bidPrice, defaultBankSpread.cashSpotOfferSpread);
	        		marketWatch.offerPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.offerPrice, defaultBankSpread.cashSpotBidSpread);
	    		}else if(instrument=='TOM'){
	        		marketWatch.bidPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.bidPrice, defaultBankSpread.tomSpotOfferSpread);
	        		marketWatch.offerPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.offerPrice, defaultBankSpread.tomSpotBidSpread);
	    		}
	    		marketWatch.bidVolume = retailSpotMarketWatch.bidVolume;
	    		marketWatch.offerVolume = retailSpotMarketWatch.offerVolume; 
			}
    		return marketWatch;
    	}
    	
    	function getBankSwapQuotesInPrice(swapQuotesForTenorDatesInPrice, spotMarketWatch){
            var swapQuotesInPrice = angular.copy(swapQuotesForTenorDatesInPrice);
            if(swapQuotesInPrice.tenorName=='CASH' || swapQuotesInPrice.tenorName=='TOM'){
                var isBestBid = angular.copy(swapQuotesInPrice.isBestBid);
                var isBestOffer = angular.copy(swapQuotesInPrice.isBestOffer);
                if(isBestBid){
                    swapQuotesInPrice.isBestOffer = true;
                    if(!isBestOffer){
                        swapQuotesInPrice.isBestBid = false;
                    }
                }
                if(isBestOffer){
                    swapQuotesInPrice.isBestBid = true;
                    if(!isBestBid){
                        swapQuotesInPrice.isBestOffer = false;
                    }
                }
                var bidSpread = swapQuotesInPrice.bidSpread;
                var offerSpread = swapQuotesInPrice.offerSpread;
                swapQuotesInPrice.bidSpread = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(spotMarketWatch.bidPrice,offerSpread);
                swapQuotesInPrice.offerSpread = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(spotMarketWatch.offerPrice,bidSpread);
            }else{
                var isBestBid = angular.copy(swapQuotesInPrice.isBestBid);
                var isBestOffer = angular.copy(swapQuotesInPrice.isBestOffer);
                var bidSpread = angular.copy(swapQuotesInPrice.bidSpread);
                var offerSpread = angular.copy(swapQuotesInPrice.offerSpread);

                //for forward, if bid spread is best, then offer price will be the best, 
                //so, alter spread is taken as best here
                /*if(isBestBid){
                    swapQuotesInPrice.isBestOffer = true;
                    swapQuotesInPrice.isBestBid = false;
                }
                if(isBestOffer){
                    swapQuotesInPrice.isBestBid = true;
                    swapQuotesInPrice.isBestOffer = false;
                }*/
                if(offerSpread!=null && offerSpread!=undefined){
                    var forwardOfferSpread = offerSpread * -1;
                    swapQuotesInPrice.offerSpread = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(spotMarketWatch.offerPrice,forwardOfferSpread);
                }else{
                    swapQuotesInPrice.offerSpread = null;
                }

                if(bidSpread!=null && bidSpread!=undefined){
                    var forwardBidSpread = bidSpread * -1;
                    swapQuotesInPrice.bidSpread = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(spotMarketWatch.bidPrice,forwardBidSpread);
                }else{
                    swapQuotesInPrice.bidSpread = null;
                }
            }

            return swapQuotesInPrice;
    	}
    	
    	function getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(price, spread){
    		if(spread!=null && spread!=undefined && price!=null && price!= undefined && price!=''){
        		var eqvPrice = (Math.round(parseFloat(price)*10000)/10000) -  ((Math.round(parseFloat(spread)*100)/100)/100);
        		if(eqvPrice>0){
        			return (Math.round(parseFloat(eqvPrice)*10000)/10000);
        		}else{
        			return null;
        		}
    		}else{
    			return null;
    		}
    		
    	}

        //get best bid/offer for user market watch having broken date tenor
        function getBestBidOfferForUserMarketWatch(userMarketWatch, bankComparativeDTO, spotDate, tickSize){
            var bankQuoteList = [];
            userMarketWatch.isBestBid = false;
            userMarketWatch.isBestOffer = false;
            if(userMarketWatch.bidSpread!=null || userMarketWatch.offerSpread!=null){
                //get best bid/offer only when customer has more than one trading bank
                //hence check swapQuotesTenorDateDTOList lenght is more than one
                if(bankComparativeDTO!=null && bankComparativeDTO!=undefined 
                    && bankComparativeDTO.swapQuotesTenorDateDTOList!=null 
                     && bankComparativeDTO.swapQuotesTenorDateDTOList.length>1){
                    angular.forEach(bankComparativeDTO.swapQuotesTenorDateDTOList, function(swapQuotesTenorDateDTO){
                        var bankQuote = {};

                        var bidBuySellFlag = 'BUYY';
                        var offerBuySellFlag = 'SELL';
                        if(userMarketWatch.instrument=='Forward'){
                            bidBuySellFlag = 'SELL';
                            offerBuySellFlag = 'BUYY';
                        }
                        //since spread computation is for forward, buySellFlag is SELL for bidSpread & BUYY for offerSpread
                        bankQuote.bidSpread = getSpreadForTenorAndSettlementDate(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, 
                            userMarketWatch.instrument, bidBuySellFlag, userMarketWatch.settlementDate, spotDate, tickSize);
                        bankQuote.offerSpread = getSpreadForTenorAndSettlementDate(swapQuotesTenorDateDTO.swapQuotesForTenorDatesList, 
                            userMarketWatch.instrument, offerBuySellFlag, userMarketWatch.settlementDate, spotDate, tickSize);
                        bankQuoteList.push(bankQuote);
                    })
                    //get best bid and offer spread
                    var bestBidSpread = null;
                    var bestOfferSpread = null;
                    bestBidSpread = Math.max.apply(Math,bankQuoteList.map(function(item){return item.bidSpread;}).filter(function(val){return val !== null}));
                    bestOfferSpread = Math.min.apply(Math,bankQuoteList.map(function(item){return item.offerSpread;}).filter(function(val){return val !== null}));

                    //check best Bid/Offer should not be null and equal to bid/Offer spread of user market watch
                    if(bestBidSpread!=null && bestBidSpread!=undefined && userMarketWatch.bidSpread==bestBidSpread){
                        if(userMarketWatch.instrument=='Forward'){
                            userMarketWatch.isBestOffer = true;    
                        }else{
                            userMarketWatch.isBestBid = true;
                        }
                        
                    }
                    if(bestOfferSpread!=null && bestOfferSpread!=undefined && userMarketWatch.offerSpread==bestOfferSpread){
                        if(userMarketWatch.instrument=='Forward'){
                            userMarketWatch.isBestBid = true;    
                        }else{
                            userMarketWatch.isBestOffer = true;
                        }
                        
                    }
                }
            }
            return userMarketWatch;
        }
    	
        function getSpreadForTenorAndSettlementDate(defaultBankSpread, tenor, buyOrSell, settlementDate, spotDate, tickSize){
            var spread = null;
            if(tenor!='Forward'){
                var spreadForTenor = _.filter(angular.copy(defaultBankSpread), { 'tenorName': tenor });
                if(spreadForTenor!=null && spreadForTenor!=undefined && spreadForTenor.length>0){
                    if(buyOrSell=='BUYY' || buyOrSell=='MODIFYBUY'){
                        spread = spreadForTenor[0].bidSpread;
                    }else{
                        spread = spreadForTenor[0].offerSpread;
                    }
                }
                return spread;
            }else{
                if(settlementDate > spotDate){
                    var spreadForTenor = _.filter(angular.copy(defaultBankSpread), { 'settlementDate': settlementDate });
                    if(spreadForTenor!=null && spreadForTenor!=undefined && spreadForTenor.length>0){
                        if(buyOrSell=='BUYY' || buyOrSell=='MODIFYBUY'){
                            spread = spreadForTenor[0].offerSpread;
                        }else{
                            spread = spreadForTenor[0].bidSpread;
                        }
                    }
                    if(spread!=null && spread!=undefined){
                        return spread;
                    }else{
                        spread = getSpreadUsingInterpolation(defaultBankSpread, buyOrSell, settlementDate, spotDate, tickSize);
                        return spread;
                    }
                }else{
                    return null;
                }
            }
        }

        function getSpreadUsingInterpolation(defaultBankSpread, buyOrSell, settlementDate, spotDate, tickSize){
            var bankSpreadForComputation = angular.copy(defaultBankSpread);
            var swapQuoteDefined = getSwapQuoteListDefined(bankSpreadForComputation, buyOrSell);

            if(swapQuoteDefined.length>0){
                var lastTenorDate = swapQuoteDefined[swapQuoteDefined.length-1].settlementDate;
                if(settlementDate>lastTenorDate){
                    return null;
                }else{
                    var nextTenor = getNextTenor(swapQuoteDefined, buyOrSell, settlementDate);
                    var previousTenor =  getPreviousTenor(swapQuoteDefined, buyOrSell, settlementDate, spotDate);
                    var spread = getTenorRate(previousTenor, nextTenor, buyOrSell, settlementDate, tickSize);
                    return spread;
                }
            }else{
                return null;
            }
        }

        function getSwapQuoteListDefined(bankSpreadList, buyOrSell){
            var swapQuoteDefined = [];
            if(buyOrSell=='BUYY' || buyOrSell=='MODIFYBUY'){
                swapQuoteDefined = _.filter(bankSpreadList, function(bankSpread) {
                                            return (bankSpread.offerSpread!=null && bankSpread.offerSpread!=undefined);
                                        });
            }else{
                swapQuoteDefined = _.filter(bankSpreadList, function(bankSpread) {
                                            return (bankSpread.bidSpread!=null && bankSpread.bidSpread!=undefined);
                                        });
            }
            return swapQuoteDefined;
        }

        function getNextTenor(swapQuoteDefined, buyOrSell, settlementDate){
            var nextTenorList = _.filter(swapQuoteDefined, function(swapQuote){
                return (swapQuote.settlementDate>settlementDate);
            });
            var nextTenor = nextTenorList[0];
            return nextTenor;
        }

        function getPreviousTenor(swapQuoteDefined, buyOrSell, settlementDate, spotDate){
            var previousTenorList = _.filter(swapQuoteDefined, function(swapQuote){
                return (swapQuote.settlementDate<settlementDate);
            });
            
            var previousTenor = null;
            
            if(previousTenorList.length==0 || previousTenorList[previousTenorList.length-1].tenorName=='TOM' || previousTenorList[previousTenorList.length-1].tenorName=='CASH'){
                var tenor = {};
                tenor.settlementDate = spotDate;
                tenor.offerSpread = 0;
                tenor.bidSpread = 0;
                tenor.tenorName = 'SPOT';
                previousTenor = tenor;
            }else{
                previousTenor = previousTenorList[previousTenorList.length-1];
            }

            return previousTenor;
        }

        function getTenorRate(previousTenor, nextTenor, buyOrSell, settlementDate, tickSize){
            var nextTenorSpread = null;
            var previousTenorSpread = null;
            if(buyOrSell=='BUYY' || buyOrSell=='MODIFYBUY'){
                nextTenorSpread = nextTenor.offerSpread;
                previousTenorSpread = previousTenor.offerSpread;
            }else{
                nextTenorSpread = nextTenor.bidSpread;
                previousTenorSpread = previousTenor.bidSpread;
            }

            var diffBetweenSettDateAndPreviousTenor = getDifferenceBetweenDatesInDays(settlementDate, previousTenor.settlementDate);
            var diffBetweenNextTenorAndPreviousTenor = getDifferenceBetweenDatesInDays(nextTenor.settlementDate, previousTenor.settlementDate);

            var spreadUsed = previousTenorSpread + diffBetweenSettDateAndPreviousTenor * ((nextTenorSpread - previousTenorSpread)/(diffBetweenNextTenorAndPreviousTenor))
            spreadUsed = (Math.round(parseFloat(spreadUsed)*100)/100);
    
            var spreadUsedRemainder = (Math.round(parseFloat(((Math.round(parseFloat(spreadUsed)*100)) % (Math.round(parseFloat(tickSize)*10000))/100))*100)/100);
            if(spreadUsedRemainder!=0){
                var halfTickSize = (Math.round(parseFloat(tickSize)*10000)/100) / 2;
                if(Math.abs(angular.copy(spreadUsedRemainder))>=halfTickSize){
                    if(spreadUsedRemainder<0){
                        spreadUsed = (Math.round(parseFloat(spreadUsed)*100)/100) - (Math.round(parseFloat(spreadUsedRemainder)*10000)/10000) + (Math.round(parseFloat(tickSize * -1)*10000)/100);    
                    }else{
                        spreadUsed = (Math.round(parseFloat(spreadUsed)*100)/100) - (Math.round(parseFloat(spreadUsedRemainder)*10000)/10000) + (Math.round(parseFloat(tickSize)*10000)/100);    
                    }
                }else{
                    spreadUsed = (Math.round(parseFloat(spreadUsed)*100)/100) - (Math.round(parseFloat(spreadUsedRemainder)*10000)/10000);
                }
            }else{
                spreadUsed = (Math.round(parseFloat(spreadUsed)*10000)/10000);
            }            
            return spreadUsed;
        }

        function getDifferenceBetweenDatesInDays(fromDate, toDate){
            var start = moment(fromDate, "YYYY-MM-DD");
            var end = moment(toDate, "YYYY-MM-DD");

            //Difference in number of days
            var differenceInDays = moment.duration(start.diff(end)).asDays();
            return differenceInDays;
        }

        //fill tenor name and settlement date in user market watch list
        function fillTenorNameAndSettlementDate(userMarketWatch, userMarketWatchFromDB, tenors, cashMarketSession, tomMarketSession){
            if(userMarketWatchFromDB.tenorSettlementDateDetails!=null &&
                userMarketWatchFromDB.tenorSettlementDateDetails!=undefined){
                //if tenorSettlementDateDetails is not null, set tenor name and settlement date from the obj
                userMarketWatch.tenorName = userMarketWatchFromDB.tenorSettlementDateDetails.tenorName;
                userMarketWatch.settlementDate = userMarketWatchFromDB.tenorSettlementDateDetails.settlementDate;
                if(userMarketWatch.tenorName=='CASH' || userMarketWatch.tenorName=='TOM'){
                    userMarketWatch.instrument = userMarketWatch.tenorName;
                }else{
                    userMarketWatch.instrument = 'Forward';
                }
            }else{
                //if tenorSettlementDateDetails is null, then its a broken date tenor, 
                //to get tenor name for broken date tenor
                userMarketWatch.tenorName = getTenorNameForSettlementDateInUserMarketWatch(userMarketWatchFromDB.settlementDate, tenors, cashMarketSession, tomMarketSession)
                userMarketWatch.instrument = 'Forward';
            }
            return userMarketWatch;
        }

        //get tenor name for settlement date to display in user market watch list
        function getTenorNameForSettlementDateInUserMarketWatch(settlementDate, tenors, cashMarketSession, tomMarketSession){
            var tenorName = '';
            angular.forEach(tenors, function(value){
                if(value.settlementDate==settlementDate){
                    tenorName = value.tenorName;
                }
            })
            if(tenorName==null || tenorName == undefined || tenorName == '' || tenorName == 'Broken Date'){
                if(settlementDate==tomMarketSession.settlementDate){
                    tenorName = 'TOM';
                }else if(settlementDate==cashMarketSession.settlementDate){
                    tenorName = 'CASH';
                }else{
                    tenorName = $filter('date')(new Date(settlementDate),'ddMMMyy')
                }
                
            }
            return tenorName;
        }

        //fill bid offer spread in user market watch
        function fillBidOfferSpreadInUserMarketWatch(userMarketWatch, bankComparativeDTO, defaultBankSpread, spotDate, tickSize){
            //filter default bank quotes with tenor name, if it is found, then bid/offer spread and best bid/offer flag is taken from the obj filtered.
            if(userMarketWatch.tenorSettlementDateDetails!=null && userMarketWatch.tenorSettlementDateDetails!=undefined){
                var spreadForTenor = _.filter(angular.copy(defaultBankSpread), { 'tenorName': userMarketWatch.tenorSettlementDateDetails.tenorName });
                if(spreadForTenor!=null && spreadForTenor!=undefined && spreadForTenor.length>0){
                    userMarketWatch.bidSpread = spreadForTenor[0].bidSpread;
                    userMarketWatch.offerSpread = spreadForTenor[0].offerSpread;
                    userMarketWatch.isBestBid = false;
                    userMarketWatch.isBestOffer = false;
                    if(userMarketWatch.instrument=='Forward'){ 
                    //for forward, if bid spread is best, then offer price will be the best, 
                    //so, alter spread is taken as best here
                        /*if(spreadForTenor[0].isBestBid){
                            userMarketWatch.isBestOffer = true;
                            userMarketWatch.isBestBid = false;
                        }
                        if(spreadForTenor[0].isBestOffer){
                            userMarketWatch.isBestBid = true;
                            userMarketWatch.isBestOffer = false;
                        }*/
                        //get spread if it is not defined.
                        var bidSpread = angular.copy(userMarketWatch.bidSpread);
                        var offerSpread = angular.copy(userMarketWatch.offerSpread);
                        if(userMarketWatch.bidSpread==null || userMarketWatch.bidSpread==undefined){
                            userMarketWatch.bidSpread = getSpreadForTenorAndSettlementDate(defaultBankSpread, userMarketWatch.instrument, 'SELL', 
                            userMarketWatch.settlementDate, spotDate, tickSize);
                        }
                        if(userMarketWatch.offerSpread==null || userMarketWatch.offerSpread==undefined){
                            userMarketWatch.offerSpread = getSpreadForTenorAndSettlementDate(defaultBankSpread, userMarketWatch.instrument, 'BUYY', 
                            userMarketWatch.settlementDate, spotDate, tickSize);
                        }
                        //if(bidSpread==null || bidSpread==undefined || offerSpread==null || offerSpread==undefined){
                        userMarketWatch = getBestBidOfferForUserMarketWatch(userMarketWatch, bankComparativeDTO, spotDate, tickSize);    
                        //}
                    }else{
                        // userMarketWatch.isBestBid = spreadForTenor[0].isBestBid;
                        // userMarketWatch.isBestOffer = spreadForTenor[0].isBestOffer;
                        userMarketWatch = getBestBidOfferForUserMarketWatch(userMarketWatch, bankComparativeDTO, spotDate, tickSize);
                    }
                }
            }else{ //if not found, get bid offer spread using interpolation method
                 userMarketWatch = getAndFillBidOfferSpreadForDateInUserMarketWatch(userMarketWatch, bankComparativeDTO, defaultBankSpread, spotDate, tickSize);
            }
            return userMarketWatch;
        }

        //get and fill bid offer spread for given date in user market watch
        function getAndFillBidOfferSpreadForDateInUserMarketWatch(userMarketWatch, bankComparativeDTO, defaultBankSpread, spotDate, tickSize){
            var bidBuySellFlag = 'BUYY';
            var offerBuySellFlag = 'SELL';
            if(userMarketWatch.instrument=='Forward'){ //for forward bid spread is computed for offer, offer spread is computed for bid
                bidBuySellFlag = 'SELL';
                offerBuySellFlag = 'BUYY';
            }

            //get bid and offer spread using interpolation method
            userMarketWatch.bidSpread = getSpreadForTenorAndSettlementDate(defaultBankSpread, userMarketWatch.instrument, bidBuySellFlag, 
            userMarketWatch.settlementDate, spotDate, tickSize);
            userMarketWatch.offerSpread = getSpreadForTenorAndSettlementDate(defaultBankSpread, userMarketWatch.instrument, offerBuySellFlag, 
            userMarketWatch.settlementDate, spotDate, tickSize);

            //get best bid/offer for user market watch (broken date tenor)
            userMarketWatch = getBestBidOfferForUserMarketWatch(userMarketWatch, bankComparativeDTO, spotDate, tickSize);
            return userMarketWatch;
        }

        //fill bid offer price in user market watch
        function fillBidOfferPriceInUserMarketWatch(userMarketWatch, retailSpotMarketWatch){
            userMarketWatch.bidPrice = null;
            userMarketWatch.offerPrice = null;
            if(userMarketWatch.offerSpread!=null && userMarketWatch.offerSpread!=undefined){
                if(userMarketWatch.instrument!='Forward'){
                    userMarketWatch.bidPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.bidPrice, userMarketWatch.offerSpread);
                }else{ //for forward instrument, bid spread is used to compute offer price
                    userMarketWatch.offerPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.offerPrice, (userMarketWatch.offerSpread * -1));
                }
            }

            if(userMarketWatch.bidSpread!=null && userMarketWatch.bidSpread!=undefined){
                if(userMarketWatch.instrument!='Forward'){
                    userMarketWatch.offerPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.offerPrice, userMarketWatch.bidSpread);
                }else{ //for forward instrument, offer spread is used to compute bid price
                    userMarketWatch.bidPrice = getEquivalentInstrumentPriceForGivenSpreadAndSpotPrice(retailSpotMarketWatch.bidPrice, (userMarketWatch.bidSpread * -1));
                }
            }
            return userMarketWatch;
        }

        function sortOutrightRatesList(outrightsList){
            var outrightRatesList = angular.copy(outrightsList);
            var sortedOutrightRatesList = [];
            var bestBidOfferBankArr = [];
            var bestBidBankArr = [];
            var bestOfferBankArr = [];
            var nonBestBankArr = [];
            var bestBidBank = null;
            var bestOfferBank = null;

            angular.forEach(outrightRatesList, function(value, index){
                if(value.isBestBid==true && value.isBestOffer==true){
                    bestBidOfferBankArr.push(value);
                }else if(value.isBestBid==true){
                    bestBidBankArr.push(value);
                }else if(value.isBestOffer==true){
                    bestOfferBankArr.push(value);
                }else{
                    nonBestBankArr.push(value);
                }

                // if(value.isBestBid==true){
                //     bestBidBank = value.bank;
                //     sortedOutrightRatesList.push(value); //push best bid bank
                // }
                // if(value.isBestOffer==true){
                //     bestOfferBank = value.bank;
                //     if(bestBidBank!=bestOfferBank){
                //         sortedOutrightRatesList.push(value); //push best offer bank
                //     }
                // }
            });

            if(bestBidOfferBankArr.length>0){
                sortedOutrightRatesList = sortedOutrightRatesList.concat(bestBidOfferBankArr);
            }

            if(bestBidBankArr.length>0){
                sortedOutrightRatesList = sortedOutrightRatesList.concat(bestBidBankArr);
            }

            if(bestOfferBankArr.length>0){
                sortedOutrightRatesList = sortedOutrightRatesList.concat(bestOfferBankArr);
            }

            if(nonBestBankArr.length>0){
                sortedOutrightRatesList = sortedOutrightRatesList.concat(nonBestBankArr);
            }

            // angular.forEach(outrightRatesList, function(outright1, index1){ //remove best bid bank from org list
            //     if(outright1.bank==bestBidBank){
            //         outrightRatesList.splice(index1,1);
            //     }
            // })

            // angular.forEach(outrightRatesList, function(outright2, index2){ //remove best bid bank from org list
            //     if(outright2.bank==bestOfferBank){
            //         outrightRatesList.splice(index2,1);
            //     }
            // })

            // angular.forEach(outrightRatesList, function(outright3){ //add other banks
            //     sortedOutrightRatesList.push(outright3);
            // })

            // outrightsList = sortedOutrightRatesList;

            return sortedOutrightRatesList;
        }

        // get tick size
        function gettickSizeForGivenInstrument(instrumentCode, tickSize){
            if(instrumentCode=='SPOT'){
                return tickSize.spot;
            }else if(instrumentCode=='CASH'){
                return tickSize.cash;
            }else if(instrumentCode=='TOM'){
                return tickSize.tom;
            }else if(instrumentCode=='Forward'){
                return tickSize.forward;
            }
        }

        //get instrument code for settlement date
        function getInstrumentCodeForSettlement(tradeSettlementDate, tenors, retailSpotMarketSession, cashMarketSession, tomMarketSession){
            var tenor = null;
            angular.forEach(tenors, function(value){
                if(value.settlementDate==tradeSettlementDate){
                    tenor = value;
                }
            })
            if(tenor==null || tenor == undefined){
                if(tradeSettlementDate==retailSpotMarketSession.settlementDate){
                    return 'SPOT';
                }else if(tradeSettlementDate==cashMarketSession.settlementDate){
                    return 'CASH';
                }else if(tradeSettlementDate==tomMarketSession.settlementDate){
                    return 'TOM';
                }else {
                    tenor = tenors[0];
                    return 'Forward';
                }
            }else{
                return 'Forward';
            }
        }

        function computeTenorDatesForGivenCreditFactorSettingsHeaderList(creditFactorSettingsHeaderList, spotDate, cashDate){
            var computedCreditFactorSettingsHeaderList = angular.copy(creditFactorSettingsHeaderList);
            angular.forEach(computedCreditFactorSettingsHeaderList, function(header){

                header = computeTenorDatesForGivenCreditFactorSettingsHeader(header, spotDate, cashDate);

            });

            return computedCreditFactorSettingsHeaderList;

        }

        function computeTenorDatesForGivenCreditFactorSettingsHeader(creditFactorSettingsHeader, spotDate, cashDate){
            angular.forEach(creditFactorSettingsHeader.creditFactorSettingsDetailsList, function(detail, index){
                if(creditFactorSettingsHeader.tenorType=='Days'){
                    detail.tenorFromDate = addDaysToGivenDate(spotDate, detail.tenorFrom);
                    detail.tenorToDate = addDaysToGivenDate(spotDate, detail.tenorTo);
                }else{
                    detail.tenorFromDate = addDaysToGivenDate(spotDate, 1);
                    detail.tenorFromDate = addMonthsToGivenDate(detail.tenorFromDate, Number(detail.tenorFrom)-1);
                    detail.tenorToDate = addMonthsToGivenDate(spotDate, detail.tenorTo);
                }

                //set cash date as starting date for the first tenor. bcos CASH/TOM/SPOT may also comes in FRWD utilisation.
                if(index==0){
                    detail.tenorFromDate = cashDate;
                }
            });
            return creditFactorSettingsHeader;
        }

        function addDaysToGivenDate(tenorDate, tenorDays){
             var date = moment(tenorDate, 'YYYY-MM-DD');
             date.add(tenorDays, 'days');
             var resultdate = new Date(date.toDate());
             return resultdate;
        }

        function addMonthsToGivenDate(tenorDate, tenorMonths){
             var date = moment(tenorDate, 'YYYY-MM-DD');
             date.add(tenorMonths, 'months');
             var resultdate = new Date(date.toDate());
             return resultdate;
        }

        function getCreditFactorValueForGivenSettlementDate(settlementDate, creditFactorSettingsHeader, defaultCreditFactor){
            var creditFactor = defaultCreditFactor;
            if(creditFactorSettingsHeader!=null && creditFactorSettingsHeader!=undefined
                && creditFactorSettingsHeader.creditFactorSettingsDetailsList!=null 
                    && creditFactorSettingsHeader.creditFactorSettingsDetailsList!=undefined 
                    && creditFactorSettingsHeader.creditFactorSettingsDetailsList.length>0){

                angular.forEach(creditFactorSettingsHeader.creditFactorSettingsDetailsList, function(detail){
                    if((moment(detail.tenorFromDate).isSame(settlementDate, 'day') || moment(detail.tenorFromDate).isBefore(settlementDate, 'day'))
                        && (moment(detail.tenorToDate).isSame(settlementDate, 'day') || moment(detail.tenorToDate).isAfter(settlementDate, 'day'))){

                        creditFactor = detail.creditFactor;

                    }
                });
            }
            // return default credit factor from config ,if no tenors matches
            return creditFactor;
        }

        //get credit factor settings from given bank
        function getCreditFactorSettingsForGivenBank(creditFactorSettingsHeaderList, defaultTradingBankId){
            var creditFactorSettingsHeader = {};
            angular.forEach(creditFactorSettingsHeaderList, function(header){
                if(header.bank.id == defaultTradingBankId){
                    creditFactorSettingsHeader = header;
                }
            })
            return creditFactorSettingsHeader;
        }

        function getCreditFactorValue(instrumentCode, settlementDate, creditFactorSettingsHeader, defaultCreditFactor){
            var creditFactor = defaultCreditFactor;
            if(instrumentCode!='Forward'){
                //no need to compute
            }else{
                creditFactor = getCreditFactorValueForGivenSettlementDate(settlementDate, creditFactorSettingsHeader, defaultCreditFactor);
            }
            return creditFactor;
        }

        function computeLimitAmountApplyingCreditFactor(incomingLimitAmount, creditFactor){
            var cfAppliedLimit = incomingLimitAmount * (100/parseFloat(creditFactor));
            cfAppliedLimit = (Math.round(parseFloat(cfAppliedLimit)*100)/100); //to round off to 2 decimals
            return cfAppliedLimit;
        }

        function getMaxForwardTenorDate(limitDTO, spotDate){
            var maxTenorDate = null;
            if(limitDTO.limitTenorTypeId==NA_LimitTenorTypeId){
                //do nothing
            }else if(limitDTO.limitTenorTypeId==MAXIMUMTENORVALUE_LimitTenorTypeId){
                if(limitDTO.maximumTenorValue!=null && limitDTO.maximumTenorValue!=undefined){
                    if(limitDTO.maximumTenorType=='Days'){
                        maxTenorDate = addDaysToGivenDate(spotDate, limitDTO.maximumTenorValue);               
                    }else{
                        maxTenorDate = addMonthsToGivenDate(spotDate, limitDTO.maximumTenorValue);
                    }             
                }

            }else if(limitDTO.limitTenorTypeId==MAXIMUMVALUEDATE_LimitTenorTypeId){
                if(limitDTO.maximumValueDate!=null && limitDTO.maximumValueDate!=undefined){
                    maxTenorDate = new Date(limitDTO.maximumValueDate);    
                }
            }
            return maxTenorDate;
        }

    }
})();
