(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'transactionChargesSettings'
        })
        
        .controller('TransactionChargesSettingsController', TransactionChargesSettingsController);

    TransactionChargesSettingsController.$inject = ['$scope', '$state', 'TransactionChargesSettings', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function TransactionChargesSettingsController($scope, $state, TransactionChargesSettings , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TRANSACTION_CHARGES;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "transactionChargesSettings";
        vm.bank = GlobalValues.getBank();
        $scope.refresh=refresh;
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.noOfDecimalsForPercent = 4;
        
        vm.launch=launch;
        function launch() {
        	$state.go("transaction-charges-settings-new");
        }
        
        
        vm.permitTransactionChargesAddEntityAction = EntityPermissions.hasPermission('TransactionChargesSettings.CREATE');
        vm.permitTransactionChargesDeleteEntityAction = EntityPermissions.hasPermission('TransactionChargesSettings.DELETE');
        vm.permitTransactionChargesEditEntityAction = EntityPermissions.hasPermission('TransactionChargesSettings.EDIT');
        vm.permitTransactionChargesImportEntityAction = EntityPermissions.hasPermission('TransactionChargesSettings.IMPORT');
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   
    	   TransactionChargesSettings.query(getTransactionChargesSuccess, getTransactionChargesFailure);
    	 
        }      
       
       function getTransactionChargesSuccess(result){
    	   vm.transactionChargesHeaderList = result;
    	   vm.transactionCharges=[];
    	   
    	   angular.forEach(vm.transactionChargesHeaderList, function(value){
    		   angular.forEach(value.transactionChargesDetailsList, function(detail){
    			   vm.details = angular.copy(detail);
    			   vm.details.transactionChargesSlabHeader = value;
    			   vm.transactionCharges.push(vm.details);
    		   })
    		  
    	   })
       }
       
       function getTransactionChargesFailure(){}
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
