(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('OrderStatusDetails', OrderStatusDetails);

    OrderStatusDetails.$inject = ['$resource'];

    function OrderStatusDetails ($resource) {
        var resourceUrl =  'api/order-status-details/:id';
        
        return $resource(resourceUrl, {}, {
        	
        	'getTradesOutstandingList':{
        		isArray:true,
				method: 'GET',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId',fromSetDate :'@fromSetDate',toSetDate:'@toSetDate'},
				url: 'api/trades-outstanding/:bankId/:branchId/:customerId/:instrumentId/:fromSetDate/:toSetDate'
        	},
        	'getOrderStatusDetails':{
           	 
             	isArray:true,
  				method: 'GET',
  				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId', orderStatusId : '@orderStatusId'},
  				url: 'api/order-status-report/bank/:bankId/branch/:branchId/customer/:customerId/instrument/:instrumentId/orderStatus/:orderStatusId/order-status-details'
              },

         'getPendingOrders':{
         	isArray:true,
				method: 'GET',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'},
				url: 'api/pending-order/bank/:bankId/branch/:branchId/customer/:customerId/instrument/:instrumentId/order-status-details'
          },
          'getOrderStatusList':{
           		isArray:true,
  				method: 'GET',
  				url: 'api/order-status-list'
            },
	     'getTradesForSelectedInstrument':{
	       	isArray:true,
				method: 'GET',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'},
				url: 'api/trades/:bankId/bank/:branchId/branch/:customerId/customer/:instrumentId/instrument'
	      },
	      'getUserWiseTurnoverHeader':{
		       	isArray:true,
					method: 'GET',
					params: {bankId: '@bankId', branchId: '@branchId', login : '@login',customerId: '@customerId'},
					url: 'api/trades/get-userwise-turnover-report-header'
		      },
		      
		  'getUserWiseTurnoverDetails':{
			       	isArray:true,
						method: 'GET',
						params: {bankId :'@bankId',branchId:'@branchId',userId:'@userId',customerId: '@customerId'},
						url: 'api/trades/bank/:bankId/branch/:branchId/user/:userId/customer/:customerId/get-userwise-turnover-report-details'
	      },'getUserRoleListFromUser': {
                method: 'GET',
                url: 'api/get-user-role-for-user',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPendingOrdersForUser':{
             	isArray:true,
    				method: 'GET',
    				url: 'api/pending-order-for-user'
              },
		'exportOrderStatusPdfFile':{
          	method: 'POST',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId', orderStatusId : '@orderStatusId'},
				url: 'api/export-order-status-report-pdf/bank/:bankId/branch/:branchId/customer/:customerId/instrument/:instrumentId/orderStatus/:orderStatusId/',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
				   return {
					   data: data,
					   headers: headers
				   };   
			   } 

			 }
              
        });
    }
})();
