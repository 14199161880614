(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth', 'errorConstants', 'Account', '$stateParams','$scope','$state','StringUtils','OrganisationTypeEnum','globalValue'];

    function RequestResetController ($timeout, Auth, errorConstants, Account, $stateParams,$scope, $state,StringUtils,OrganisationTypeEnum,globalValue) {
        var vm = this;

        vm.error = null;
        vm.errorEmailOrMobileNotExists = null;
        vm.errorUserNotLocked = null;
        vm.requestReset = requestReset;
        vm.requestUnlock = requestUnlock;
        vm.resetAccount = {};
        vm.action = $stateParams.action;
        vm.success = null;
        vm.timeToLoadLoginOnSucess = 3000; // in seconds
        vm.clicked = false;
        vm.configSettings = globalValue;
        setEnvironmentName();

        $timeout(function (){angular.element('#email').focus();});
        
        	$scope.$watch('vm.resetAccount.mobile', function(value) {
                if(vm.resetAccount.mobile !=null && vm.resetAccount.mobile !=undefined &&  vm.resetAccount.mobile !=''){
        	
        	vm.resetAccount.mobile = vm.resetAccount.mobile.toString().replace(/[^0-9]/g, '');
        	vm.resetAccount.mobile = vm.resetAccount.mobile.toString().replace(/(?!-)[^0-9]/g,'');
                }
        	});

            if(!StringUtils.isBlank($stateParams.orgType) && $stateParams.orgType == OrganisationTypeEnum.ADMIN){
                vm.orgType = OrganisationTypeEnum.CLEARCORP;
            }else{
                vm.orgType = OrganisationTypeEnum.OTHERS;
            }

        function requestReset () {

            vm.error = null;
            vm.errorEmailOrMobileNotExists = null;
            vm.errorEmailSendAttemptsBreached = null;
            vm.errorUserNotLocked = null;
            Auth.resetPasswordInit(vm.resetAccount.email, vm.resetAccount.mobile,vm.orgType).then(function () {
                vm.success = 'OK';
                $timeout(reloadLoginAtTimeOut, vm.timeToLoadLoginOnSucess );
            }).catch(function (response) {
                vm.success = null;
                vm.clicked = false;
                if (response.status === 400 && angular.fromJson(response.data).type === errorConstants.EMAIL_OR_MOBILE_NOT_FOUND_TYPE) {
                    vm.errorEmailOrMobileNotExists = 'ERROR';
                }else if(response.status === 400 && angular.fromJson(response.data).type === errorConstants.EMAIL_SEND_ATTEMPTS_BREACHED) {
                    vm.errorEmailSendAttemptsBreached = 'ERROR';
                }
                else {
                    vm.error = 'ERROR';
                }
            });
        }
        
        function requestUnlock(){
            vm.error = null;
            vm.errorEmailOrMobileNotExists = null;
            vm.errorUserNotLocked = null;

            Account.unlockAccount({email: vm.resetAccount.email, mobile: vm.resetAccount.mobile ,orgType : vm.orgType}, onUnlockSuccess, onUnlockFailure);
        }
        
        function onUnlockSuccess(){
        	vm.success = 'OK';
        	$timeout(reloadLoginAtTimeOut, vm.timeToLoadLoginOnSucess);
        }
        
        function onUnlockFailure(response){
        	vm.success = null;
            vm.clicked = false;
            if (response.status === 400 && angular.fromJson(response.data).type === errorConstants.USER_NOT_LOCKED_TYPE) {
                vm.errorUserNotLocked = 'ERROR';
            } else if (response.status === 400 && angular.fromJson(response.data).type === errorConstants.EMAIL_OR_MOBILE_NOT_FOUND_TYPE) {
                vm.errorEmailOrMobileNotExists = 'ERROR';
            } else if(response.status === 400 && angular.fromJson(response.data).type === errorConstants.EMAIL_SEND_ATTEMPTS_BREACHED) {
                vm.errorEmailSendAttemptsBreached = 'ERROR';
            } else {
                vm.error = 'ERROR';
            }
        }
        function reloadLoginAtTimeOut() {
            //console.log("Timeout occurred");
            $state.go('login', {"orgType" : $stateParams.orgType}, {reload:'login'});
        }
        function setEnvironmentName() {
			if( vm.configSettings.environmentName !='' &&  vm.configSettings.environmentName!=undefined && vm.configSettings.environmentName!=null){
				vm.environmentName = vm.configSettings.environmentName.toUpperCase();
			}
		}
    }
})();
