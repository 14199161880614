(function() {
	'use strict';

	angular.module('gatewayApp').service("workflowSharedService", function() {
		this.viewFrom = 'entityRestrictions'
	})

	.controller('manageEntityRestrictionsController',
			manageEntityRestrictionsController);

	manageEntityRestrictionsController.$inject = [ '$scope', '$state',
			'$rootScope', '$uibModalInstance', 'ManageEntityRestrictions',
			'GlobalValues', 'workflowSharedService', 'Workflow' ,'restrictionReasons'];

	function manageEntityRestrictionsController($scope, $state, $rootScope,
			$uibModalInstance, ManageEntityRestrictions, GlobalValues,
			workflowSharedService, Workflow, restrictionReasons) {
		var vm = this;

		vm.isShowBankEntity = true;
		vm.isShowBranchEntity = true;
		vm.FetchStatusDetailsForBank = FetchStatusDetailsForBank;
		vm.clear = clear;
		vm.isShowRestrictionDetails = false;
		vm.globalValues = GlobalValues;
		vm.save = save;
		vm.approve = approve;
		vm.reject = reject;
		vm.discard = discard;
		vm.resubmit = resubmit;
		vm.prepareEntityRestrictionDTO = prepareEntityRestrictionDTO;
		$scope.showButton = false;
		$scope.isShowSaveButton = false;
		$scope.showDiscardButton = false;
		$scope.isShowResubmit = false;
		vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
		vm.mulitCustomerEntityOwnerTypeId = 4;
		$scope.clicked=false;
		vm.restrictionReasonsList = restrictionReasons;
		vm.entityRestrictionsDTO = {};		
		
		 if($state.current.name=="manage-Entity-Restrictions"){
        	 workflowSharedService.viewFrom="";
         }
		 
		if (workflowSharedService.viewFrom == 'entityRestrictionstodolist_checker') {
			vm.ViewDisable = true;

			vm.entityrestriction = angular.fromJson($rootScope.payload);
			setValuesFromTask(vm.entityrestriction);
			vm.isDisabled = true
			$scope.showButton = true;
			$scope.showDiscardButton = false;
			$scope.isShowResubmit = false;
			$scope.isShowSaveButton = false;

		} else if (workflowSharedService.viewFrom == 'entityRestrictionstodolist_maker') {
			vm.ViewDisable = true;
			vm.entityrestriction = angular.fromJson($rootScope.payload);
			setValuesFromTask(vm.entityrestriction);
			vm.isDisabled = true
			$scope.showButton = false;
			$scope.showDiscardButton = true;
			$scope.isShowSaveButton = false;
			$scope.isShowResubmit = false;
		}
		else if (workflowSharedService.viewFrom == 'entityRestriction_auditLog') {
			vm.ViewDisable = true;
			vm.entityrestriction = angular.fromJson($rootScope.payload);
			setValuesFromTask(vm.entityrestriction);
			vm.isDisabled = true
			$scope.showButton = false;
			$scope.showDiscardButton = false;
			$scope.isShowSaveButton = false;
			$scope.isShowResubmit = false;
		}
		

		else if (workflowSharedService.viewFrom == 'entityRestrictionstodolist_resubmit') {
			vm.entityrestriction = angular.fromJson($rootScope.payload);
			setValuesFromTask(vm.entityrestriction);
			vm.isDisabled = false;
			$scope.isShowResubmit = true;
			$scope.isShowSaveButton = false;
		} else {
			$scope.isShowSaveButton = true;
			defaultValuesBasedOnEntity();
			
		}

		
		function defaultValuesBasedOnEntity() {
			
			vm.isShowRestrictionDetails = false;
			if (vm.entityOwnerType == 'Clearcorp') {
				vm.isShowBankEntity = true;
				vm.isShowBranchEntity = true;
				vm.bank = null;
				vm.isShowCustomerEntity = true;
				vm.isShowCustomerRelationshipEntity = true;
				vm.entity = "BANK";
			}

			if (vm.entityOwnerType == 'Bank') {

				vm.isShowBankEntity = false;
				vm.isShowCustomerEntity = false;
				vm.isShowCustomerRelationshipEntity = true;
				vm.entity = "BRANCH";
				vm.bank = vm.globalValues.getBank();
				vm.branch = null;
				vm.isBankDisabled = true;
			}

			if (vm.entityOwnerType == 'Branch') {

				vm.isShowBankEntity = false;
				vm.isShowBranchEntity = false;
				vm.isShowCustomerEntity = false;
				vm.isShowCustomerRelationshipEntity = true;
				vm.entity = "CUSTREL";
				vm.bank = vm.globalValues.getBank();
				vm.branch = vm.globalValues.getBranch();
				vm.isBankDisabled = true;
				vm.isBranchDisabled = true;
			}

			if (vm.entityOwnerType == 'MultiUserCustomer') {

				vm.isShowBankEntity = false;
				vm.isShowBranchEntity = false;
				vm.isShowCustomerEntity = false;
				vm.isShowCustomerRelationshipEntity = true;
				vm.entity = "CUSTREL";
			}       
			
		}
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		$scope.$watch('vm.bank', function(value) {
			if (value != null && value != undefined && vm.entity == "BANK") {
				FetchStatusDetailsForBank();
			}
		});

		$scope.$watch('vm.branch', function(value) {
			if (value != null && value != undefined && vm.entity == "BRANCH") {
				FetchStatusDetailsForBranch();
			}
		});

		function prepareEntityRestrictionDTO(bank,branch,customer,newStatus,oldStatus,entityType,reason,remarks) {
			vm.entityRestrictionsDTO.bank = bank;
			vm.entityRestrictionsDTO.branch = branch;
			vm.entityRestrictionsDTO.customer = customer;
			vm.entityRestrictionsDTO.entityType = entityType;
			vm.entityRestrictionsDTO.oldStatus = oldStatus;
			vm.entityRestrictionsDTO.newStatus = newStatus;
			vm.entityRestrictionsDTO.reason = reason;
			vm.entityRestrictionsDTO.remarks = remarks;
		}

		function save() {

			if (vm.entity == "BANK") {
				prepareEntityRestrictionDTO(vm.bank,null,null,vm.newStatus,vm.currentStatus,"BANK",vm.restrictionReason,vm.remarks);
				ManageEntityRestrictions.manageEntityRestrictionsForBank(vm.entityRestrictionsDTO, onSaveSuccess, onSaveError);
			} else if (vm.entity == "BRANCH") {
				prepareEntityRestrictionDTO(vm.bank,vm.branch,null,vm.newStatus,vm.currentStatus,"BRANCH",vm.restrictionReason,vm.remarks);
				ManageEntityRestrictions.manageEntityRestrictionsForBranch(vm.entityRestrictionsDTO, onSaveSuccess, onSaveError);
			} else if (vm.entity == "CUSTOMER") {
				prepareEntityRestrictionDTO(null,null,vm.customer,vm.newStatus,vm.currentStatus,"CUSTOMER",vm.restrictionReason,vm.remarks);
				ManageEntityRestrictions.manageEntityRestrictionsForCustomer(vm.entityRestrictionsDTO, onSaveSuccess, onSaveError);
			} else {
				prepareEntityRestrictionDTO(vm.bank,vm.branch,vm.customer,vm.newStatus,vm.currentStatus,"CUSTREL",vm.restrictionReason,vm.remarks);
				ManageEntityRestrictions.manageEntityRestrictionsForCustomerRelationship(vm.entityRestrictionsDTO, onSaveSuccess, onSaveError);
			}

		}

		function setValuesFromTask(task) {

			if (task.entityType == 'BANK') {
				vm.entity = "BANK";
				vm.isShowBankEntity = true;
				vm.isShowBranchEntity = false;
				vm.isShowCustomerEntity = false;
				vm.isShowCustomerRelationshipEntity = false
				vm.bank = task.bank;
				//FetchStatusDetailsForBank();
				vm.currentStatus = task.oldStatus;
				vm.newStatus = task.newStatus;
			}

			if (task.entityType == 'BRANCH') {
				vm.entity = "BRANCH";
				vm.isShowBankEntity = false;
				vm.isShowBranchEntity = true;
				vm.isShowCustomerEntity = false;
				vm.isShowCustomerRelationshipEntity = false

				vm.bank = task.bank;
				vm.branch = task.branch;
				//FetchStatusDetailsForBranch();
				vm.currentStatus = task.oldStatus;
				vm.newStatus = task.newStatus;
				
			}

			if (task.entityType == 'CUSTOMER') {
				vm.entity = "CUSTOMER";
				vm.isShowBankEntity = false;
				vm.isShowBranchEntity = false;
				vm.isShowCustomerEntity = true;
				vm.isShowCustomerRelationshipEntity = false

				vm.customer = task.customer;

				//fetchDetailsForCustomer();
				vm.currentStatus = task.oldStatus;
				vm.newStatus = task.newStatus;
			}

			if (task.entityType == 'CUSTREL') {
				vm.entity = "CUSTREL";
				vm.isShowBankEntity = false;
				vm.isShowBranchEntity = false;
				vm.isShowCustomerEntity = false;
				vm.isShowCustomerRelationshipEntity = true

				vm.bank = task.bank;
				vm.branch = task.branch;
				vm.customer = task.customer;
				//fetchDetailsForCustomerRelationship();
				vm.currentStatus = task.oldStatus;
				vm.newStatus = task.newStatus;
			
			}
			vm.restrictionReason = task.reason;
			vm.remarks = task.remarks;


		}

		$scope.$watch('vm.customer', function(value) {
			if (value != null && value != undefined
					&& (vm.entity == "CUSTOMER" || vm.entity == "CUSTREL")) {

				if (vm.entity == "CUSTOMER") {
					fetchDetailsForCustomer();
				} else if (vm.entity == "CUSTREL") {
					fetchDetailsForCustomerRelationship()
				}

			}
		});

		$scope.$watch('vm.entity', function(value) {
			if (value != null && value != undefined) {
				if( !(workflowSharedService.viewFrom == 'entityRestrictionstodolist_resubmit' 
					|| workflowSharedService.viewFrom == 'entityRestrictionstodolist_maker'
						|| workflowSharedService.viewFrom == 'entityRestrictionstodolist_checker'
							|| workflowSharedService.viewFrom =='entityRestriction_auditLog')){
		             
		             vm.newStatus = null;
		             vm.isShowRestrictionDetails = false;
					 vm.restrictionReason = null;
					 vm.remarks = null;
		             if (vm.entityOwnerType == 'Clearcorp') {
		 				
		 				vm.bank =  null;
		 				vm.branch = null;
		 				vm.customer = null;
		 		
		 			}

		 			if (vm.entityOwnerType == 'Bank') {
		 				vm.bank = vm.globalValues.getBank();
		 				vm.branch = null;
		 				vm.customer = null;
		 			}

		 			if (vm.entityOwnerType == 'Branch') {

		 				vm.bank = vm.globalValues.getBank();
		 				vm.branch = vm.globalValues.getBranch();
		 		
		 			}

		 			if (vm.entityOwnerType == 'MultiUserCustomer') {

		 				vm.bank = null;
		 				vm.branch = null;
		 				vm.customer = null;

		 			}    
	        	 }
				//clearValues();
				/*
				 * vm.bank = null; vm.branch = null; vm.customer = null;
				 */
			}
		});
		
				 
		
		function approve() {
			ManageEntityRestrictions.approve({
				"taskId" : $rootScope.taskID
			}, onApproveSuccess, onApproveError);

		}

		function onApproveSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}
		function onApproveError(error) {

		}

		function reject() {
			Workflow.rejectTask($rootScope.taskID);
		}

		function discard() {
			ManageEntityRestrictions.discard({
				"taskId" : $rootScope.taskID
			}, onDiscardSuccess, onDiscardError);

		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}
		function onDiscardError(error) {

		}

		function resubmit() {
			vm.entityrestriction.bank = vm.bank;
			vm.entityrestriction.branch = vm.branch;
			vm.entityrestriction.customer = vm.customer;
			vm.entityrestriction.oldStatus = vm.currentStatus;
			vm.entityrestriction.newStatus = vm.newStatus;
			vm.entityrestriction.reason = vm.restrictionReason;
			vm.entityrestriction.remarks = vm.remarks;
			ManageEntityRestrictions.ResubmitForEdit({
				"taskId" : $rootScope.taskID
			}, vm.entityrestriction, onResubmitSuccess, onResubmitError);
			
			
		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}

		function onResubmitError(error) {
			$scope.clicked=false;
		}

		function FetchStatusDetailsForBank() {			
				ManageEntityRestrictions.fetchStatusDetailsForBank({
					"bankId" : vm.bank.id
				}, onFetchSuccess, onFetchError);
			
		}

		function FetchStatusDetailsForBranch() {
				ManageEntityRestrictions.fetchStatusDetailsForBranch({
				"bankId" : vm.bank.id,
				"branchId" : vm.branch.id
				}, onFetchSuccess, onFetchError);
		
		}

		function fetchDetailsForCustomer() {
		
				ManageEntityRestrictions.fetchDetailsForCustomer({
				"customerId" : vm.customer.id
				}, onFetchSuccess, onFetchError);
			
		}

		function fetchDetailsForCustomerRelationship() {
			
				ManageEntityRestrictions.fetchDetailsForCustomerRelationship({
					"bankId" : vm.bank.id,
					"branchId" : vm.branch.id,
					"customerId" : vm.customer.id
				}, onFetchSuccess, onFetchError);
			
		}

        function onFetchSuccess(result) {
            if(isStatusCheckRequired()){
            	vm.currentStatus = result.CurrentStatus;
                vm.availableStatusList = result.AvailableStatus.split(";");
                vm.isShowRestrictionDetails = true;
                if( result.AvailableStatus==undefined || result.AvailableStatus==null || result.AvailableStatus.trim()=="" ){
                	vm.showChangeStatus = false;
                }else{
                	vm.showChangeStatus = true;
                }
            }     	
            
            else{
            	vm.showChangeStatus = true;
    			vm.isShowRestrictionDetails = true;
    			vm.availableStatusList = [];
    			vm.availableStatusList.push(vm.newStatus);
            }
			
        }


		function onFetchError(error) {

		}

		function onSaveSuccess(result) {
			defaultValuesBasedOnEntity();
		}

		function onSaveError(error) {

			$scope.clicked=false;
		}
		
		function isStatusCheckRequired(){
			
			if(!(
				 workflowSharedService.viewFrom == 'entityRestrictionstodolist_maker' 
					|| workflowSharedService.viewFrom == 'entityRestrictionstodolist_checker' || workflowSharedService.viewFrom =='entityRestriction_auditLog')){
				return true;
			}else{
				return false;
			}
		}

	}

})();
