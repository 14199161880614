(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GlobalEntityActionApprovalPolicyDialogController', GlobalEntityActionApprovalPolicyDialogController);

    GlobalEntityActionApprovalPolicyDialogController.$inject = ['Workflow', '$scope', '$state', 'entity', '$rootScope', '$uibModalInstance', 'workflowSharedService', 'GlobalEntityActionApprovalPolicy', 'GlobalValues', 'CSPLPagination', 'ScreenLog','entityConstants'];

    function GlobalEntityActionApprovalPolicyDialogController(Workflow, $scope, $state, entity,  $rootScope,  $uibModalInstance, workflowSharedService, GlobalEntityActionApprovalPolicy, GlobalValues, CSPLPagination, ScreenLog,entityConstants) {
        var vm = this;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.MANAGE_GLOBAL_ENTITY_STATE_APPROVAL_PROCESS;
        vm.screenLog = ScreenLog;
        vm.save = save;
        vm.entityActionApprovalPolicyDTO = {};
        vm.selected = {entityActions: []};

        vm.clear = clear;
        vm.approveEntityActionApprovalPolicy = approveEntityActionApprovalPolicy;
        vm.rejectEntityActionApprovalPolicy = rejectEntityActionApprovalPolicy;
        vm.discardEntityActionApprovalPolicy = discardEntityActionApprovalPolicy;
        vm.resubmitEntityActionApprovalPolicy = resubmitEntityActionApprovalPolicy;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.clicked=false;
        vm.selectAll=false;
    	vm.checkOrUncheckHeaderAction = checkOrUncheckHeaderAction;
    	vm.checkOrUncheckAction = checkOrUncheckAction;
        
        if(workflowSharedService.viewFrom == 'globalApprovalPolicy'){
	        vm.entityActionApprovalPolicyDTO = entity;
	        console.log(vm.entityActionApprovalPolicyDTO);
			//ScreenLog.screenLog({'entityName': 'EntityStateApprovalProcess', 'entityRecordId': vm.entityActionApprovalPolicyDTO.id, 'action': 'MAP'});
	        vm.ShowApprovalPolicyLink = false;
	        showButtonsManipulateScreen();
        }
        else if(workflowSharedService.viewFrom == 'globalEntityActionApprovalPolicytodolist_checker') {
        	vm.ShowApprovalPolicyLink = true;
        	vm.entityActionApprovalPolicyDTO =  angular.fromJson($rootScope.payload); 
        	showButtonsViewCheckerScreen();
        }
        else if(workflowSharedService.viewFrom == 'globalEntityActionApprovalPolicytodolist_maker') {
        	vm.ShowApprovalPolicyLink = false;
        	vm.entityActionApprovalPolicyDTO =  angular.fromJson($rootScope.payload); 
        	showButtonsViewMakerScreen();
        }
        
        else if(workflowSharedService.viewFrom == 'globalEntityActionApprovalPolicy_resubmit') {
        	vm.ShowApprovalPolicyLink = false;
        	vm.entityActionApprovalPolicyDTO = angular.fromJson($rootScope.payload); 
			vm.screenLog.screenLog(vm.screenName, vm.entityActionApprovalPolicyDTO.id, entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	showButtonsResubmitScreen();
        }

        GlobalEntityActionApprovalPolicy.getEntityActions({orgId:GlobalValues.getOrgId(), id:vm.entityActionApprovalPolicyDTO.approvalPolicyId},function(result) {            
        					vm.entityActionApprovalPolicies = result;
        					console.log(vm.entityActionApprovalPolicies);
						    		
						     vm.selected.entityActions =angular.copy(vm.entityActionApprovalPolicyDTO.entityActions);
						     console.log( "approval policy length" + vm.entityActionApprovalPolicies.length);
						     console.log( "exiisting" + vm.selected.entityActions.length);
						     if( vm.entityActionApprovalPolicies.length == vm.selected.entityActions.length){
						    	 vm.selectAll = true;
						    	 document.getElementById("selectAll").checked = true;
						     }
						    });
        
        function showButtonsManipulateScreen(){
        	vm.ViewDisable = false;
            vm.btnClose = false;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = true;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = false;
        }
        function showButtonsViewCheckerScreen(){
        	$scope.showButton=true;
        	vm.ViewDisable = true;
        	$scope.showDiscardButton = false;
        	vm.btnClose = true;
        	$scope.showDiscardButton = false;
        	vm.btnApproveReject = true;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        function showButtonsResubmitScreen(){
        	vm.ViewDisable = false;
        	vm.btnClose = false;
        	$scope.showButton=true;
        	vm.btnApproveReject = false;
        	$scope.showDiscardButton = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = true;
        }
        function showButtonsViewMakerScreen(){
        	vm.ViewDisable = true;
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        	vm.btnClose = true;
        	vm.btnApproveReject = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }
		
		function save () {
			vm.entityActionApprovalPolicyDTO.entityActions = vm.selected.entityActions;
			GlobalEntityActionApprovalPolicy.map({"orgId":GlobalValues.getOrgId()}, vm.entityActionApprovalPolicyDTO, onSaveSuccess, onSaveError);
        }
     
        function onSaveSuccess (result) {
            $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	$scope.clicked=false;
        }
        function approveEntityActionApprovalPolicy() { 
        	GlobalEntityActionApprovalPolicy.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	$scope.clicked=false;
        }
       
        function rejectEntityActionApprovalPolicy() { Workflow.rejectTask($rootScope.taskID);}

        function discardEntityActionApprovalPolicy() {
        	GlobalEntityActionApprovalPolicy.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {

        }
        function resubmitEntityActionApprovalPolicy() {
        	vm.entityActionApprovalPolicyDTO.entityActions = vm.selected.entityActions;
        	GlobalEntityActionApprovalPolicy.resubmit({"taskId" : $rootScope.taskID}, vm.entityActionApprovalPolicyDTO, onResubmitSuccess, onResubmitError);
          	$uibModalInstance.dismiss('cancel');
          }
          
          function onResubmitSuccess(result) {
          	$rootScope.removeRow($rootScope.taskID);
          }        
          function onResubmitError(error) { 
        	  $scope.clicked=false;
          }
          function checkOrUncheckHeaderAction(event){
          	
        	  changeHeaderActionSelection(event.target.checked);
                 
          }
         function changeHeaderActionSelection(value){
      	 	if(value){
          		angular.forEach(vm.entityActionApprovalPolicies, function(item, index){
          		
          			//item.entityActions = true;
          			vm.selected.entityActions.push(item.entityActions);
          			
          		});
      		}else{
      			angular.forEach(vm.entityActionApprovalPolicies, function(item, index){
      				//item.entityActions = false;
      				vm.selected.entityActions = [];
      			});
      		}
          
          }
          function checkOrUncheckAction(){
          	          	
          	if(vm.selected.entityActions.length == vm.entityActionApprovalPolicies.length ){
          		vm.selectAll= true;
          		document.getElementById("selectAll").checked = true;
          	}else{
          		vm.selectAll= false;
          		document.getElementById("selectAll").checked = false;
          	}
          }
          
          /*function setupPagination(result){
      		vm.CSPLPagination = CSPLPagination;
      	    vm.CSPLPagination.initialisePagination('globalEntityActionApprovalPolicy', vm.entityActionApprovalPolicies);
      		vm.totalRecordLength = vm.CSPLPagination.getTotalRecordCount('globalEntityActionApprovalPolicy');
      		vm.itemsPerPage = vm.CSPLPagination.getItemsPerPage('globalEntityActionApprovalPolicy');
      		vm.pageCount = 1;
              $scope.$watch('vm.pageCount', function(){
              	vm.CSPLPagination.setPage('globalEntityActionApprovalPolicy', vm.pageCount);
              	vm.pagedValues = vm.CSPLPagination.getPagedValues('globalEntityActionApprovalPolicy');
              });
     }*/
    }
})();
