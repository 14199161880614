(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'smssettings'
        })
        
        .controller('SMSSettingsController', SMSSettingsController);

    SMSSettingsController.$inject = ['$scope', '$state', 'SMSSettings', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function SMSSettingsController($scope, $state, SMSSettings, GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.SMS_SETTINGS;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "smssettings";
        $scope.refresh=refresh;
        
        vm.permitSMSSettingsEditEntityAction = EntityPermissions.hasPermission('SMSSettings.EDIT');
        vm.permitSMSSettingsViewEntityAction = EntityPermissions.hasPermission('SMSSettings.VIEW');
        
        logScreenAccess();
        loadAll();

       function loadAll() {
    	   SMSSettings.query(function(result) {
               vm.SMSSettings = result;                
           });       	
        }      
       function logScreenAccess(){
          	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
          }
        
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
