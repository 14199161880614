(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("ScreenLog", ScreenLog);

    ScreenLog.$inject = ['ScreenLogService'];

    function ScreenLog(ScreenLogService) {

        var corp = null;

    	var service = {
    		screenLog: screenLog,
    	};
    	return service;
    	
    	function screenLog(entityName, entityRecordId, action){
    		ScreenLogService.screenLog({entityName: entityName, entityRecordId:entityRecordId, action:action});
    	}
        
    }
})();
