(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ChangePasswordController', ChangePasswordController);

    ChangePasswordController.$inject = ['$scope','Auth', 'Principal', '$sessionStorage', '$uibModalInstance', 'GlobalValues', 'errorConstants', '$stateParams', 'StaticLookUp', 'EntityPermissions', '$state', 'MessageBox', '$rootScope','configSettings','$injector','$timeout'];

    function ChangePasswordController ($scope, Auth, Principal, $sessionStorage, $uibModalInstance, GlobalValues, errorConstants, $stateParams, StaticLookUp, EntityPermissions, $state, MessageBox, $rootScope,configSettings,$injector,$timeout) {
        var vm = this;
        vm.login = $stateParams.login;
        vm.action = $stateParams.action;
        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.clear = clear;
        vm.error = null;
        vm.previousPasswordError = null;
        vm.minimumPasswordAgeError = null;
        vm.incorrectPasswordError = null;
        vm.passwordContainLogin = null;
        vm.userLockedError = null;
        vm.success = null;
        vm.currentPasswordDoNotMatch = null;
        vm.allowedPasswordCharacters=/^[!@#^&*()\-_.:;a-zA-Z0-9]/;
        vm.globalValues = GlobalValues;
       // StaticLookUp.getConfigSettings(onGetSuccess, onGetFailure);
        vm.showPassword=false;
        vm.showConfirmPassword=false;
        vm.showCurrentPassword=false;
        vm.showPwd=showPwd;
        vm.showCurrentPwd=showCurrentPwd;
        vm.showConfirmPwd=showConfirmPwd;
        vm.boColor=null;
        vm.onFocusCurrentPwd=onFocusCurrentPwd;
        vm.onBlurCurrentPwd=onBlurCurrentPwd;
        vm.onFocusPwd=onFocusPwd;
        vm.onBlurPwd=onBlurPwd;
        vm.onFocusConfirmPwd=onFocusConfirmPwd;
        vm.onBlurConfirmPwd=onBlurConfirmPwd;
       
        vm.controlType = "password";
        vm.controlType1 = "password";
        vm.controlType2 = "password";
        vm.configSetting=configSettings;
        vm.globalValues.checkAndSetSelectedConfigurationSettings(configSettings);
        vm.timeToLoadLoginOnUnlock = 3000; // in seconds
        vm.loginpassword=null;
        vm.password=null;
        vm.confirmPassword=null;
        vm.clicked = false;
        
      /*  
        function onGetSuccess(result){
            vm.minimumPasswordLength = result.minimumPasswordLength;
            vm.minimumNumberOfPreviousPasswordThatCannotBeRepeated = result.minimumNumberOfPreviousPasswordThatCannotBeRepeated;
            vm.minimumPasswordAgeInHours = result.minimumPasswordAgeInHours;
            vm.globalValues.checkAndSetSelectedConfigurationSettings(result);      	
        }
        
        function onGetFailure(){
        	
        }*/
        Principal.identity().then(function(account) {
            vm.account = account;
        });
        
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function changePassword () {
        	vm.doNotMatch = '';
        	vm.currentPasswordDoNotMatch = '';
        	vm.error = '';
        	vm.previousPasswordError = '';
        	vm.minimumPasswordAgeError = '';
        	vm.incorrectPasswordError = '';
        	vm.passwordContainLogin = '';
        	vm.userLockedError = '';
        	
        	if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.previousPasswordError = null;
                vm.minimumPasswordAgeError = null;
                vm.incorrectPasswordError = null;
                vm.passwordContainLogin = null;
                vm.success = null;
            	vm.userLockedError = null;
                vm.doNotMatch = 'ERROR';
                vm.clicked = false;
            }
//        	else if ($sessionStorage.pwd !== vm.currentPassword){
//                vm.error = null;
//                vm.success = null;
//                vm.currentPasswordDoNotMatch = 'ERROR';
//        	}
        	else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.login,vm.loginpassword,vm.password).then(function () {
                    vm.error = null;
                    vm.previousPasswordError = null;
                    vm.minimumPasswordAgeError = null;
                    vm.incorrectPasswordError = null;
                    vm.passwordContainLogin = null;
                	vm.userLockedError = null;
                    vm.success = 'OK';
                    if(vm.action!='Expiry'){
                    	MessageBox.openMessageBox('Alert', 'Password has been successfully changed, Please re-login with new credentials...!', 'Ok', 'changepassword');
                    	//signOut();
                    }else{
                        $uibModalInstance.dismiss('cancel');  
                    }
                }).catch(function (response) {
                	vm.clicked = false;
                	if(angular.fromJson(response.data).type === errorConstants.PREVIOUS_PASSWORD_CANNOT_BE_USED_TYPE){
                		vm.previousPasswordError = 'ERROR'
                		vm.incorrectPasswordError = null;
                		vm.minimumPasswordAgeError = null;
                		vm.passwordContainLogin = null;
                        vm.error = null;
                       	vm.success = null;
                       	vm.userLockedError = null;
                	}else if(angular.fromJson(response.data).type === errorConstants.PASSWORD_CANNOT_BE_CHANGED_WITH_IN_HOURS_TYPE){
                		vm.minimumPasswordAgeError = "ERROR";
                		vm.incorrectPasswordError = null;
                		vm.previousPasswordError = null;
                		vm.passwordContainLogin = null;
                        vm.error = null;
                       	vm.success = null;
                       	vm.userLockedError = null;
                	}else if(angular.fromJson(response.data).type === errorConstants.PASSWORD_CONTAIN_LOGIN_TYPE){
                		vm.passwordContainLogin = "ERROR";
                		vm.incorrectPasswordError = null;
                		vm.minimumPasswordAgeError = null;
                		vm.previousPasswordError = null;
                        vm.error = null;
                       	vm.success = null;
                       	vm.userLockedError = null;
                	}else if(angular.fromJson(response.data).type === errorConstants.INCORRECT_PASSWORD_TYPE){
                		vm.passwordContainLogin = null;
                		vm.minimumPasswordAgeError = null;
                		vm.previousPasswordError = null;
                		vm.userLockedError = null;
                		vm.incorrectPasswordError = "ERROR";
                        vm.error = null;
                       	vm.success = null;   
                       	vm.userLockedError = null;
                    }else if(angular.fromJson(response.data).type === errorConstants.USER_LOCKED_TYPE){
                    	 vm.userLockedError = "ERROR";
                    	 vm.passwordContainLogin = null;
                 		 vm.minimumPasswordAgeError = null;
                 		 vm.previousPasswordError = null;                 		
                 		 vm.incorrectPasswordError = null;
                         vm.error = null;
                         vm.success = null;  
                     	$timeout(signOut, vm.timeToLoadLoginOnUnlock);
                    
                	}else{
                    	vm.success = null;
                        vm.error = 'ERROR';
                	}
                });
            }
        }
        
        function showPwd(controlType){
        	//vm.showPassword=!vm.showPassword;
        	if(controlType=="password"){
        		vm.controlType1 = "text";		
        	}
        	if(controlType=="text"){
        		vm.controlType1 = "password";		
        	}
        	

        }
        
        function showCurrentPwd(controlType){
        	//vm.showCurrentPassword=!vm.showCurrentPassword;
        	if(controlType=="password"){
        		vm.controlType = "text";		
        	}
        	if(controlType=="text"){
        		vm.controlType = "password";		
        	}
        }
        
        function showConfirmPwd(controlType){
        	//vm.showConfirmPassword=!vm.showConfirmPassword;
        	if(controlType=="password"){
        		vm.controlType2 = "text";		
        	}
        	if(controlType=="text"){
        		vm.controlType2 = "password";		
        	}
        }
        
        function onFocusCurrentPwd(){
        	vm.boColor = "#FFC000";
        }
        function onBlurCurrentPwd(){
        	vm.boColor = "#DADAE7";
        }
        
        function onFocusPwd(){
        	vm.boColor1 = "#FFC000";
        	
        }
        function onBlurPwd(){
        	vm.boColor1 = "#DADAE7";
        	
        }
        
        function onFocusConfirmPwd(){
        	vm.boColor2 = "#FFC000";
        }
        function onBlurConfirmPwd(){
        	vm.boColor2 = "#DADAE7";
        }
        
        $rootScope.$on('gatewayApp.messagebox.ok.changepassword', function (event, data) {
        	//console.log($state.current.name);
        	if($state.current.name == 'change-password' || $state.current.name == 'change-password-expiry'||$state.current.name == 'change-password-user-profile'){
            	signOut();
                //$uibModalInstance.dismiss('cancel');  
            	
        	}else{
                $uibModalInstance.dismiss('cancel');  
        	}
        });

        /*
         * allow only 30 characters in password and only some special characters
         */
        $scope.$watch('vm.loginpassword', function(newVal, oldVal) {
        	if(newVal!=null||newVal!=undefined)
        		{
	        		if(newVal.length > 30) {       
	        			vm.loginpassword = oldVal;
	        		}
	        		if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
	        		{
	        			vm.loginpassword = oldVal;
	        		}
	        		if(vm.password==null||vm.confirmPassword==null||vm.loginpassword==null||vm.password==undefined||vm.confirmPassword==undefined||vm.loginpassword==undefined||vm.loginpassword.length<4||vm.confirmPassword.length<vm.configSetting.minimumPasswordLength||vm.password.length<vm.configSetting.minimumPasswordLength)
	        			$scope.form.$setValidity("valid", false);
	        		else
	        			$scope.form.$setValidity("valid", true);

        		}
      	        	});
        
        $scope.$watch('vm.confirmPassword', function(newVal, oldVal) {
        	if(newVal!=null||newVal!=undefined)
    		{
	        	  if(newVal.length > 30) {       
	        		  vm.confirmPassword = oldVal;
	        	  }
	        	  if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
	    		  {
	        		  vm.confirmPassword = oldVal;
	    		  }
	        	  if(vm.password==null||vm.confirmPassword==null||vm.loginpassword==null||vm.password==undefined||vm.confirmPassword==undefined||vm.loginpassword==undefined||vm.loginpassword.length<4||vm.confirmPassword.length<vm.configSetting.minimumPasswordLength||vm.password.length<vm.configSetting.minimumPasswordLength)
	        		  $scope.form.$setValidity("valid", false);
	        	  else
	        		  $scope.form.$setValidity("valid", true);
    		}
        	});
        
        
        $scope.$watch('vm.password', function(newVal, oldVal) {
        	if(newVal!=null||newVal!=undefined)
    		{
        	  if(newVal.length > 30) {       
        		  vm.password = oldVal;
        	  }
        	  if(!newVal.charAt((newVal.length)-1).match(vm.allowedPasswordCharacters))
    		  {
        		  vm.password = oldVal;
    		  }
        	  if(vm.password==null||vm.confirmPassword==null||vm.loginpassword==null||vm.password==undefined||vm.confirmPassword==undefined||vm.loginpassword==undefined||vm.loginpassword.length<4||vm.confirmPassword.length<vm.configSetting.minimumPasswordLength||vm.password.length<vm.configSetting.minimumPasswordLength)
        		  $scope.form.$setValidity("valid", false);
        	  else
        		  $scope.form.$setValidity("valid", true);
    		}
        	});
      

        var signOut = function(){
        	GlobalValues.clearPreviousValues();
            EntityPermissions.clearEntityPermission();
            $sessionStorage.isAuth = false;
            Auth.logout();
            $injector.get('$uibModalStack').dismissAll();
            $state.go('login', {orgType : $stateParams.orgType}, {reload:'login'});
        }
        
    }
})();
