(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'bankregistration'
        })
        
        .controller('BankRegistrationController', BankRegistrationController);

    BankRegistrationController.$inject = ['$scope', '$state', 'workflowSharedService', 'BankRegistration', 'GlobalValues', 'EntityPermissions','ScreenLog','entityConstants','$timeout'];

    function BankRegistrationController($scope, $state, workflowSharedService, BankRegistration, GlobalValues, EntityPermissions,ScreenLog,entityConstants,$timeout) {
        
        var vm = this;
        vm.globalValues = GlobalValues;
        vm.entityConstants = entityConstants;
        vm.screenLog = ScreenLog;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.BANK_REGISTRATION ; 
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.refresh=refresh;
        
        workflowSharedService.viewFrom = "bankregistration";
        
        vm.launch=launch;
        function launch() {
        	$state.go("bank-registration.new");
        }
        
        vm.permitBankRegistrationEditEntityAction = EntityPermissions.hasPermission('BankRegistration.EDIT')
        vm.permitBankRegistrationCreateEntityAction = EntityPermissions.hasPermission('BankRegistration.CREATE')
		vm.permitBankRegistrationViewEntityAction = EntityPermissions.hasPermission('BankRegistration.VIEW')
		vm.permitBankRegistrationDeleteEntityAction = EntityPermissions.hasPermission('BankRegistration.DELETE')
		vm.permitBankRegistrationImportEntityAction = EntityPermissions.hasPermission('BankRegistration.IMPORT')

		logScreenAccess();
        loadAll();
        
        function loadAll() {
            BankRegistration.query(function(result) {
                vm.bankRegistration = result;                
            });
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        
        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
      
    }
})();
