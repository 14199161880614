(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AddRemarksController', AddRemarksController);

    AddRemarksController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', '$stateParams'];

    function AddRemarksController($scope, $rootScope, $state, $uibModalInstance, $stateParams) {
        var vm = this;
        vm.clear=clear;
        vm.add=add;
        $scope.clicked=false;
        vm.remarkList = [''];
        vm.addAdditionalRemark=addAdditionalRemark;
        vm.nonAllowedCharacters=/^[\n,]/;
		vm.removeAdditionalRemark=removeAdditionalRemark;
        vm.remarksValidation=remarksValidation;
        vm.remarks = $stateParams.remarks;

        checkAndFillRemark()
        vm.remarkDTO={};
        defaultRemarkvalue();
        vm.maxRemark=4;
        function defaultRemarkvalue(){
            vm.remarkDTO.remarks1="";
            vm.remarkDTO.remarks2=null;
            vm.remarkDTO.remarks3=null;
            vm.remarkDTO.remarks4=null;
        }
        function checkAndFillRemark() {
            if (vm.remarks != undefined && vm.remarks != null && vm.remarks != '') {
                vm.remarkList = [];
                if (vm.remarks.remarks1 !== null) {
                    vm.remarkList.push(vm.remarks.remarks1);
                }else{
                    vm.remarkList = [''];
                }
                
                if (vm.remarks.remarks2 !== null) {
                   vm.remarkList.push(vm.remarks.remarks2);
                }
                
                if (vm.remarks.remarks3 !== null) {
                   vm.remarkList.push(vm.remarks.remarks3);
                }
                
                if (vm.remarks.remarks4 !== null) {
                   vm.remarkList.push(vm.remarks.remarks4);
                }
            } 
        }

        function addAdditionalRemark(){
        if (vm.remarkList.length <= vm.maxRemark-1) { 
           vm.remarkList.push('');
        }

        }

        function removeAdditionalRemark (index){
           vm.remarkList.splice(index, 1 );
        };

        function remarksValidation(value, index) {
            if (value != null && value != undefined && value !='') {
                value = value.replace(/[^a-zA-Z0-9-\s.]/g,"");
               vm.remarkList[index]= value;
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function add() {
            setRemarksvalues()
            $rootScope.remarks =  vm.remarkDTO;
            $scope.$emit('addRemarks');
            $uibModalInstance.close();
        }

        function setRemarksvalues(){
            angular.forEach( vm.remarkList, function(value, index){
                    if (index >= 0 && index <= 3) {
                       vm.remarkDTO['remarks' + (index + 1)] = value;
                    }
                });

        }
    }   
})();
