(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ExposureTypeEditController', ExposureTypeEditController);

    ExposureTypeEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService', '$uibModalInstance', 'ExposureType', 'entity', 'mode', 'Workflow'];

     function ExposureTypeEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance, ExposureType, entity, mode, Workflow) {
        var vm = this;
        vm.exposureType=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitExposureType = resubmitExposureType;
        vm.globalValues = GlobalValues;
        vm.discardExposureType = discardExposureType;
        vm.rejectExposureType = rejectExposureType;
        vm.approveExposureType = approveExposureType;
        vm.viewMode=false;
        vm.disabled=false;
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        vm.mode = mode;
        
        if(workflowSharedService.viewFrom == 'exposureType'){
        	vm.exposureType=entity;
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'exposureTypetodolist_resubmit') {
        	vm.exposureType =  angular.fromJson($rootScope.payload);
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'exposureType_todolist_checker') {
        	vm.exposureType =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'exposureType_todolist_maker') {
        	vm.exposureType =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='exposureType_auditlog'){
        	vm.exposureType =  angular.fromJson($rootScope.payload); 
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        else if(workflowSharedService.viewFrom=='exposure_type_import_auditlog'){
        	
        	vm.exposureType =  angular.fromJson($rootScope.payload);   
        	
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
		
        if(vm.exposureType!=null && vm.exposureType.isEnabled!=null && vm.exposureType.isEnabled!=undefined){
        	vm.disabled = !vm.exposureType.isEnabled;
        }
        
        if(mode=='VIEW'){
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	if(vm.disabled){
        		vm.exposureType.isEnabled = false;
        	}else{
        		vm.exposureType.isEnabled = true;
        	}
        	
        	if (vm.exposureType.id != null) {
				ExposureType.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.exposureType, onSaveSuccess, onSaveError);
			} else {
				ExposureType.save({
					"orgId" : GlobalValues.getOrgId()
				}, vm.exposureType, onSaveSuccess, onSaveError);
			}
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitExposureType() {
        	
        	if(vm.disabled){
        		vm.exposureType.isEnabled = false;
        	}else{
        		vm.exposureType.isEnabled = true;
        	}
        	
        	ExposureType.resubmit({"taskId" : $rootScope.taskID}, vm.exposureType, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function approveExposureType() { 
        	ExposureType.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectExposureType() { Workflow.rejectTask($rootScope.taskID); }

        function discardExposureType() {
        	ExposureType.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        

        function onDiscardError(error) {
        	
        }

        $scope.$watch('vm.exposureType.name', function(newVal, oldVal) {
            if(newVal!=null||newVal!=undefined)
            {
                newVal = newVal.replace(/(\r\n|\n|\r)/gm," ");
                newVal = newVal.replace(/(,)/gm,"");
                newVal = newVal.replace("=","");
                newVal = newVal.replace(/[^\x20-\x7E]/g, '');
                vm.exposureType.name = newVal;
                
            }
        });
        
        $scope.$watch('vm.exposureType.remarks', function(newVal, oldVal) {
            if(newVal!=null && newVal!=undefined && newVal!='')
            {
                newVal = newVal.replace(/[^\x20-\x7E]/g, '');
                vm.exposureType.remarks = newVal;
                
            }
        });

     }   
})();
