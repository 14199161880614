(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('UserAccessView', UserAccessView);

    UserAccessView.$inject = ['$resource'];

    function UserAccessView ($resource) {
        var resourceUrl =  'api/security-policy-view';
        
        return $resource(resourceUrl, {}, {
        	
        	'getSecurityPolicyView':{
        		isArray:true,
				method: 'GET',
				params: {orgId : '@orgId', branchId : '@branchId', userId : '@userId', roleId :'@roleId',themeId:'@themeId',actionId:'@actionId',userStatusId:'@userStatusId', queryParam:'@queryParam'},
				url: 'api/orgId/:orgId/security-policy-view/:branchId/branch/:userId/user/:roleId/role/:themeId/theme/:actionId/action/:userStatusId/userStatus'
        	},
           'getApprovalPolicyView':{
        	  isArray:true,
        	  method: 'GET',
        	  params: {orgId : '@orgId', branchId : '@branchId', userId : '@userId', roleId :'@roleId', policyId:'@policyId', actionId:'@actionId', queryParam:'@queryParam'},
        	  url: 'api/orgId/:orgId/approval-policy-view/:branchId/branch/:userId/user/:roleId/role/:policyId/policy/:actionId/action'
           },
           'exportApprovalPolicyExcel':{
        	  method: 'POST',
              params: {orgId : '@orgId', queryParam:'@queryParam'},
              responseType: 'arraybuffer',
              url: 'api/orgId/:orgId/approval-policy-view/export/excel',
              transformRequest: function (data) {
                 	var copy = angular.copy(data);                 	
                     return angular.toJson(copy);
              },
              transformResponse: function(data, headers) {
				return {
					data: data,
					headers: headers
				};   
			}         
             
           },
           'exportApprovalPolicyCsv' : {
        	  method: 'POST',
        	  responseType: 'arraybuffer',
              params: {queryParam:'@queryParam'},
              url: 'api/orgId/:orgId/approval-policy-view/export/csv',
              transformRequest: function (data) {
               	var copy = angular.copy(data);                 	
                   return angular.toJson(copy);
              },
              transformResponse: function(data, headers) {
            	return {
					data: data,
					headers: headers
				};  
              }
           },'exportSecurityPolicyExcel':{
        	  method: 'POST',
              params: {orgId : '@orgId', queryParam:'@queryParam'},
              responseType: 'arraybuffer',
              url: 'api/orgId/:orgId/security-policy-view/export/excel',
              transformRequest: function (data) {
                 	var copy = angular.copy(data);                 	
                     return angular.toJson(copy);
              },
              transformResponse: function(data, headers) {
				return {
					data: data,
					headers: headers
				};   
			}         
             
           },'exportSecurityPolicyCsv':{
        	   method: 'POST',
         	  responseType: 'arraybuffer',
               params: {queryParam:'@queryParam'},
               url: 'api/orgId/:orgId/security-policy-view/export/csv',
               transformRequest: function (data) {
                	var copy = angular.copy(data);                 	
                    return angular.toJson(copy);
               },
               transformResponse: function(data, headers) {
             	return {
 					data: data,
 					headers: headers
 				};  
               }
           }
        });
    }
})();
