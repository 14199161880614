(function() {
	'use strict';

	angular.module('gatewayApp')

	.controller('CustomerCreateLimitController', CustomerCreateLimitController);

	CustomerCreateLimitController.$inject = [ '$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','LimitSettings', 
		 'ScreenLog','entityConstants', 'AlertService', '$filter', 'Workflow','entity','StaticLookUp','MessageBox', '$stateParams',  'CustomerTradingBankDetails' ];

	function CustomerCreateLimitController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,LimitSettings,
			ScreenLog,entityConstants,AlertService,$filter, Workflow,entity,StaticLookUp,MessageBox,$stateParams,CustomerTradingBankDetails) {
		
		 var vm=this;
		 vm.globalValues = GlobalValues;
         vm.entityConstants = entityConstants;
         vm.screenLog = ScreenLog;
         vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
         vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
         $scope.showOkButton = false;
         
         if($state.current.name=="create-customer-limit-settings" || $state.current.name=="create-customer-limit-settings-limit-report"){
        	 workflowSharedService.viewFrom="";
         }
         
         
         vm.clear = clear;
         vm.resubmitLimitSettings= resubmitLimitSettings;
        
         vm.discardLimitSettings = discardLimitSettings;
         vm.save=save;
         vm.clicked = false;
        
         vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
         vm.defaultLimitType = 'GLOBALGROSS';
         vm.selectedInstrument = 'all';
         vm.defaultCurrencyCode = 'USD'
         vm.defaultLimitCurrency = null;
         vm.disableSave = false;

        // loadInstrumentType();
         loadCurrency();
         loadLimitTypes();
         loadTradingBankDetails();
         vm.tradingBankDetails = [];
         function loadTradingBankDetails(){
        	 //get trading bank details whos status is not in (suspended, terminated and pendingtradingactivation) 
        	CustomerTradingBankDetails.getValidTradingBankDetails( {'customerId': vm.globalValues.getOrganisation().id }, getTradingBankDetailsSuccess, getTradingBankDetailsFailure)
        	
         }    
         function getTradingBankDetailsSuccess(result){
        	
        	 vm.tradingBankDetails = result;
        	//load default trading bank and branch when new request from menu
 	        if($state.current.name=="create-customer-limit-settings" && $rootScope.defaultTradingBank!=null && $rootScope.defaultTradingBank!=undefined){
 	        	checkForBankAndBranch($rootScope.defaultTradingBank.id) ;
 			}else if($state.current.name=="create-customer-limit-settings-limit-report"){
 				checkForBankAndBranch($stateParams.bankId);
 	        }
         }
         function getTradingBankDetailsFailure(error){
        	 
         }
         function loadCurrency(){
        	 StaticLookUp.getCurrency(currencySuccess,currencyFailure);
         }
         function currencySuccess(result){
        	vm.currencies = result;
        	angular.forEach(vm.currencies, function(item){
        		if(item.code==vm.defaultCurrencyCode){
           		 	vm.defaultLimitCurrency =item;
           		 	if(	vm.customerLimit.customerLimitRequest.limitCurrency ==null 
           		 			|| 	vm.customerLimit.customerLimitRequest.limitCurrency == undefined
           		 			||  vm.customerLimit.customerLimitRequest.limitCurrency == ''){
           		 		vm.customerLimit.customerLimitRequest.limitCurrency = vm.defaultLimitCurrency;
           		 	}
           		}
           	});
        	
         }
         function currencyFailure(error){
        	 
         }
         function loadLimitTypes(){
        	 StaticLookUp.getLimitType(limitTypeSuccess, limitTypeFailure);
         }
         function limitTypeSuccess(result){
        	 vm.limitTypes = result;
        	 prepareLimitTypeToView();
         }
         function limitTypeFailure(error){
        	 
         }
         
         $scope.$watch('vm.customerLimit.limitSettingsHeader.bank' , function(value){
        	
        	 angular.forEach(vm.tradingBankDetails, function(item){
        		 if(value !=null && value!=undefined && value !=""){
	        		 if(item.bank.id == value.id ){
	        			 vm.customerLimit.limitSettingsHeader.branch = item.tradingBranch;
	        			 
	        		 }
        	 	}
        	 });
         });
         
       /*  $scope.$watch('vm.selectedLimitType' , function(value){
        	 if(vm.limitSettingsHeader!=null && vm.limitSettingsHeader!==undefined){
        		 if(value !=null && value!=undefined)
        			 vm.limitSettingsHeader.limitType = value.shortName;
        		// else if(vm.limitSettingsHeader.limitType=null || vm.limitSettingsHeader.limitType == undefined )
        			// vm.limitSettingsHeader.limitType="";
        	 }
         });
         $scope.$watch('vm.isLimitToBeReinstated' , function(value){
        	 //if(vm.limitSettingsHeader!=null && vm.limitSettingsHeader!==undefined){
        		//alert(value + " " + typeof value);
        		 if(value !=null && value!=undefined && value== vm.NO){
        			 vm.showLimiReinstateType = false;
        			 vm.limitSettingsHeader.isLimitToBeReinstated =false;
        			 vm.limitSettingsHeader.limitReinstateMethod = null;
        		 }else if(value !=null && value!=undefined && value==vm.YES){
        			 vm.showLimiReinstateType = true;
        			 vm.limitSettingsHeader.isLimitToBeReinstated =true;
        			 document.getElementById("reinstateMethodRadio").focus();
        		 }
        	 //}
         });
         $scope.$watch('vm.selectedInstrument', function(value){
        	 if( vm.limitSettingsHeader!=null && vm.limitSettingsHeader!=undefined && value == 'all'){
        		 if( document.getElementById("txtspot")!=undefined &&  document.getElementById("txtspot")!=null){
        			 document.getElementById("txtspot").value = "";
        		 }
        		 if( document.getElementById("txttom")!=undefined &&  document.getElementById("txttom")!=null){
        			 document.getElementById("txttom").value = "";
        		 }
        		 if( document.getElementById("txtcash")!=undefined &&  document.getElementById("txtcash")!=null){
        			 document.getElementById("txtcash").value = "";
        		 }
        		 vm.spotValue=null;
        		 vm.tomValue=null;
        		 vm.cashValue=null;
        	 }else if( vm.limitSettingsHeader!=null && vm.limitSettingsHeader!=undefined && value == 'individual'){
        		 if( document.getElementById("txtall")!=undefined &&  document.getElementById("txtall")!=null){
        		 	document.getElementById("txtall").value = "";
        	 	}
        		 vm.allValue=null;
        	 }
        	 prepareLimitDetailsToSaveToDB(value);
         });
         
         $scope.$watch('vm.allValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.allValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.spotValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.spotValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.tomValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.tomValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         
         $scope.$watch('vm.cashValue', function(value){
        	 if(value!=null && value!=undefined){
        		 if(vm.prevClose!=null && vm.prevClose!=undefined){
        			 vm.cashValueInINR = (Math.round(parseFloat(value)*10000) / vm.prevClose) / 10000;
        		 }
        	 }
         });
         */
         function prepareLimitTypeToView(){
        	
        	
        	angular.forEach(vm.limitTypes, function(item){
            		 if(item.shortName == vm.defaultLimitType ){
            			 vm.selectedLimitType = item;
            		 }
            })
        	
         }
         function checkInstrumentValue(instrumentValue){
        	 
        	 if(instrumentValue == "" || instrumentValue == undefined || instrumentValue == null){
        		 return 0.00;
        	 }else{
        		 return instrumentValue;
        	 }
         }
         
         function checkDefaultTradingBranch(giveBankId){
         	var matchFound = false;
        	 angular.forEach(vm.tradingBankDetails, function(item){
        		 if(item.bank.id == giveBankId ){
        			 vm.customerLimit.limitSettingsHeader.branch = item.tradingBranch;
        			 matchFound = true;
        			 
        		 }
        	 });
         }
         
         function checkForBankAndBranch(giveBankId){
          	var matchFound = false;
         	 angular.forEach(vm.tradingBankDetails, function(item){
         		 if(item.bank.id == giveBankId ){
         			 vm.customerLimit.limitSettingsHeader.bank = item.bank;
         			 vm.customerLimit.limitSettingsHeader.branch = item.tradingBranch;
         			 matchFound = true;
         		 }
         	 });
         	 if(!matchFound){ // if no match found from the given trading bank then disable the save button
         		 vm.disableSave = true;
         	 }
          }
      
         function prepareLimitDetailsToSaveToDB(){
        	 if( vm.customerLimit.limitSettingsHeader!=null && vm.customerLimit.limitSettingsHeader!=undefined ){
        		 
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList=[];
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList[0]={};
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList[0].instrumentType=null;
        		 vm.customerLimit.limitSettingsHeader.limitSettingsDetailsList[0].value=checkInstrumentValue( vm.customerLimit.customerLimitRequest.limitAmount);
        		 vm.customerLimit.limitSettingsHeader.limitReinstateMethod = null;
        		 vm.customerLimit.limitSettingsHeader.isLimitToBeReinstated = false;
        		 vm.customerLimit.limitSettingsHeader.userRemarks= vm.customerLimit.customerLimitRequest.userRemarks;
        		 vm.customerLimit.limitSettingsHeader.limitCurrency= vm.customerLimit.customerLimitRequest.limitCurrency;
        		 vm.customerLimit.limitSettingsHeader.limitType = vm.selectedLimitType.shortName;
        		 vm.customerLimit.limitSettingsHeader.effectiveFromDateTime = null;
        		 vm.customerLimit.limitSettingsHeader.effectiveToDateTime = null;
        	 }
         }
         function prepateLimitDetailsToView(){
        	 
        	
        	
         }
         function loadBranch(){
        	 
         }
     
		
		if (workflowSharedService.viewFrom == 'create_customer_limitSettings_maker') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			
	   
			prepareLimitTypeToView();
			
			$scope.showButton = false;
			$scope.showResubmitButton = false;		
			$scope.showDiscardButton = true;
			vm.disableForm = true;
			
			enableControls(true,true);
			
			
		} else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_resubmit') {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			
			
			prepareLimitTypeToView();
			
			vm.screenLog.screenLog(vm.screenName, vm.customerLimit.limitSettingsHeader.id == null ? 0
					: vm.customerLimit.limitSettingsHeader.id,
					entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
			
			vm.disableForm = false;
			$scope.showButton = false;
			$scope.showResubmitButton = true;			
			$scope.showDiscardButton = false;			
			//disable bank
			enableControls(true,false);
			
		} else if (workflowSharedService.viewFrom == 'create_customer_limitSettings_auditLog') {
			
			vm.customerLimit  = angular.fromJson(angular.copy($rootScope.payload));
			
			   
			prepareLimitTypeToView();
			
			$scope.showButton = false;
			$scope.showResubmitButton = false;		
			$scope.showDiscardButton = false;
			$scope.showOkButton = true;
			vm.disableForm = true;			
			enableControls(true,true);
			
		}		
		else {
			
			vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMERLIMITSETTINGS;
			
			vm.customerLimit={}; 
	        vm.customerLimit.limitSettingsHeader = {};
	        vm.customerLimit.customerLimitRequest = {};;	
	        	        	
	        vm.customerLimit.limitSettingsHeader.customer = vm.globalValues.getCustomer();
			prepareLimitTypeToView();
			ScreenLog.screenLog(vm.screenName,0, vm.entityConstants.ENTITY_ACTION_TYPE.CREATE);
						
			$scope.showButton = true;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton = false;
			vm.disableForm = false;
			
			
			if($state.current.name=="create-customer-limit-settings"){
				enableControls(false,false);
			}else{
				//disable bank when request is from limit report
				enableControls(true,false);
			}
			
		} 
	
	
		function enableControls(disableBank , disableValues){
			vm.disableBank = disableBank
			vm.disableBranch = true;
			vm.disableCustomer = true;
			vm.disableLimitType= true;			
			vm.diableFields = disableValues;
		}
		  
		 function clear() {
        	 workflowSharedService.viewFrom="";
             $uibModalInstance.dismiss('cancel');
         }
       
        
        function save () {        	    		
        	
        	prepareLimitDetailsToSaveToDB();     
       		
       		LimitSettings.createLimitSettingsByCustomer(vm.customerLimit, onCustomerSaveSuccess, onCustomerSaveError);
       				
        	
         }
     
              
        function onCustomerSaveSuccess(result){
        	//$scope.clicked = true;
        	 $uibModalInstance.close(result); 
        	 MessageBox.openMessageBox('Alert', 'The request has been sent for bank approval. You will be notified by email on the request status...!', 'Ok', 'homepage');
        }
        function onCustomerSaveError(error){
        	vm.clicked = false;
        }
        function resubmitLimitSettings() {        	
        	prepareLimitDetailsToSaveToDB();     
        	 LimitSettings.resubmitLimitChangeByCustomer({"taskId" : $rootScope.taskID}, vm.customerLimit, onResubmitSuccess, onResubmitError);
        	
        }
        
        function onResubmitSuccess(result) {
        	$uibModalInstance.close(result); 
        	$rootScope.removeRow($rootScope.taskID);
        	
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
      

        function discardLimitSettings() {
        	LimitSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

        
       
       
        
        
	}
})();
