(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ClearCorpTradesReportService', ClearCorpTradesReportService);

    ClearCorpTradesReportService.$inject = ['$resource'];

    function ClearCorpTradesReportService($resource) {
        var resourceUrl =  'api/trades/:id';
        
        return $resource(resourceUrl, {}, {
        	
        
        	'getTradesForReport':{
          		isArray:true,
 				method: 'GET',
 				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'
 						, fromTime: '@fromTime' , toTime : '@toTime'
 				},
 				url: 'api/clearcorp-trades-report'
           },
           'exportTradeExcel':{
           	 method: 'GET',
                url: 'api/clearcorp-trades-report/excel-download',
                responseType: 'arraybuffer',
                params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'
						, fromTime: '@fromTime' , toTime : '@toTime'
                },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }      
           },
           'exportTradesCsv':{
            	 method: 'GET',
                 url: 'api/clearcorp-trades-report/csv-download',
                 responseType: 'arraybuffer',
                 params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'
						, fromTime: '@fromTime' , toTime : '@toTime'
                 },
                 transformResponse: function(data, headers) {
               	  return {
                         data: data,
                         headers: headers
                     };   
                 }      
            },
            'exportTradesPdf':{
             	 method: 'GET',
                  url: 'api/clearcorp-trades-report/pdf-download',
                  responseType: 'arraybuffer',
                  params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId', instrumentId : '@instrumentId'
						, fromTime: '@fromTime' , toTime : '@toTime'
                  },
                  transformResponse: function(data, headers) {
                	  return {
                          data: data,
                          headers: headers
                      };   
                  }      
             }
        });
    }
   })();
