(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FaqViewController', FaqViewController);

    FaqViewController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance', '$http'];

     function FaqViewController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance, $http) {
        var vm = this;
        
		
       vm.faq=$rootScope.faqfilteredbyclick;
       vm.faqcategory = $rootScope.faqcategory;
       vm.faqcategorylistfromJSONFile = $rootScope.faqcategorylistfromJSONFile;
       vm.faqdetailsfilteredforselectedcategory = $rootScope.faqdetailsfilteredforselectedcategory;
       
        
        vm.faqdetailsfromJSONFile = $rootScope.faqdetailsfromJSONFile;
        vm.clear=clear;
        $scope.clicked=false;
        $scope.showButton = false;
       
        
        if(workflowSharedService.viewFrom == 'faq'){
        	//vm.faq=entity;
       	 	$scope.showButton=false;
        }

        vm.downloadTemplate = function downloadTemplate(templatefilename){
          	 //alert("downloadTemplate");
          	 //alert(templatefilename);
          	// vm.videofilename = "sample.mp4"
          	 //       vm.videolink="api/file/faq/videos/download/" + vm.videofilename  + "/mp4";
          	 
               var downloadLink = document.createElement("a");
               downloadLink.href = "api/file/faq/templates/downloadtemplate/" + templatefilename + "/xlsx";            
               downloadLink.download = templatefilename;
               //alert("downloadTemplate1");
               document.body.appendChild(downloadLink);
               downloadLink.click();
               document.body.removeChild(downloadLink);
                 //FAQService.downloadTemplate({fileName:'Template',fileType:'xls'}, onSuccess, onFailure);
           }
   		  
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

     }   
})();
        