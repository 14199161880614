(function() {
    'use strict';

    angular
        .module('gatewayApp')
        
        .controller('GenericCustomersController', GenericCustomersController);

    GenericCustomersController.$inject = ['$scope', '$state', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','GenericCustomers','AlertService'];

    function GenericCustomersController($scope, $state , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,GenericCustomers, AlertService) {
        var vm = this;
        vm.importCustomers = importCustomers
        
       function importCustomers() {
        	GenericCustomers.query(onGetSuccess, onGetFailure);
    	        	
        }      
        
        function onGetSuccess(){
        	AlertService.info("File uploaded successfully...!");
        }
        
        function onGetFailure(){
        	
        }
    }
})();
