(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('FeeUploadApprovalController', FeeUploadApprovalController);

	FeeUploadApprovalController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','BankFeeAndChargesUploadService','entity','DateUtils','Workflow','FileUtil','AlertService','$filter','$uibModalInstance','systemSettings','BankFeeViewForEntitiesUtility','globalValue','configurationSetting'];

	function FeeUploadApprovalController($scope, $state, $rootScope, GlobalValues, workflowSharedService,BankFeeAndChargesUploadService,entity,DateUtils,Workflow,FileUtil,AlertService,$filter,$uibModalInstance,systemSettings,BankFeeViewForEntitiesUtility,globalValue,configurationSetting) {
		var vm = this;
		vm.bankFeeDTO=entity;   

		vm.clear=clear;
		vm.clicked=false;
		$scope.showButton = false;
		$scope.showApproveRejectButton=false;
		$scope.showDiscardButton = false;
		$scope.showResubmitButton=false;
		vm.globalValues = GlobalValues;
		vm.discardBankFee = discardBankFee;
		vm.rejectBankFee = rejectBankFee;
		vm.approveBankFee = approveBankFee;

        vm.checkbankFeeFile = false;
		vm.resubmitBankFee = resubmitBankFee;
		vm.viewMode=false;
		vm.configSettings = globalValue;
		vm.maxFileNameLength = (vm.configSettings.uploadedFileNameLength != null && vm.configSettings.uploadedFileNameLength != undefined && vm.configSettings.uploadedFileNameLength != '') ?vm.configSettings.uploadedFileNameLength : 150 ;
		var minFileSizeinBytes = 0;
		var maxFileSizeinBytes = 500000;
		vm.maximumFileSizeinKB = (configurationSetting != null && configurationSetting != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != null && configurationSetting.maximumFileSizeTermsAndConditionsUpload != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != '') ? parseInt(configurationSetting.maximumFileSizeTermsAndConditionsUpload) : 500000 ;
		vm.minimumFileSizeInKB = 0;
		if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
			minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
		}
		if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){

			maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
			if(vm.maximumFileSizeinKB == 1024){
				vm.showInKB = false;
				vm.bankFeeFileUploadSize = 1;
			}else{
				vm.showInKB = true;
				vm.bankFeeFileUploadSize = vm.maximumFileSizeinKB;
			}
		}
		
		var Extensions = "pdf";
	

		$scope.showButton = false;
		vm.orgId = vm.globalValues.getOrgId();
	
	

		if(workflowSharedService.viewFrom == 'bankFeesAndChargesUploadtodolist_resubmit') {
			vm.bankFeeDTO =  angular.copy(angular.fromJson($rootScope.payload));
			console.log(vm.bankFeeDTO)
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=true;
			vm.viewMode=false;
		}

		else if(workflowSharedService.viewFrom == 'bankFeesAndChargesUpload_todolist_checker') {
			vm.bankFeeDTO =  angular.fromJson($rootScope.payload); 
			console.log(vm.bankFeeDTO)
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;
		}
		else if(workflowSharedService.viewFrom == 'bankFeesAndChargesUpload_todolist_maker') {
			vm.bankFeeDTO =  angular.fromJson($rootScope.payload); 
			console.log(vm.bankFeeDTO)
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			$scope.showResubmitButton=false;
		}
		else if(workflowSharedService.viewFrom=='bankFeesAndChargesUpload_auditlog'){
			vm.bankFeeDTO =  angular.fromJson($rootScope.payload); 
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;

		}else{
			vm.bankFeeDTO=entity;
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;
			vm.viewMode=false;
		}
		

		vm.openBankFeePreview = openBankFeePreview;
		function openBankFeePreview(pdfContent){
			BankFeeViewForEntitiesUtility.previewBankFee(pdfContent.fileContent, pdfContent.fileName);
		}


		function clear() {
			$uibModalInstance.dismiss('cancel');
		}


		function approveBankFee() { 
			BankFeeAndChargesUploadService.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
			     	
		}

		function onApproveSuccess(result) {
			$uibModalInstance.dismiss('cancel');
			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onApproveError(error) {
			vm.clicked = false;
		}

		function rejectBankFee() { Workflow.rejectTask($rootScope.taskID); }

		function discardBankFee() {
			BankFeeAndChargesUploadService.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
			$uibModalInstance.dismiss('cancel');	
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onDiscardError(error) {

		}
		
		function resubmitBankFee() {
		
				if(vm.bankFee != null){
					var fd1 = new FormData();
									fd1.append('id', JSON.stringify($rootScope.taskID));
					fd1.append('file', vm.bankFee);
					BankFeeAndChargesUploadService.resubmit( fd1, onResubmitSuccess, onResubmitError);


				}
	
			
		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}        
		function onResubmitError(error) {
			vm.clicked = false;
		}

		//validate terms and condition 
		$scope.$watch('vm.bankFee', function(value){
		
			
			if(value!= undefined && value!=null){
				
				if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
					vm.invalidBankFeeSize = true;
					if(vm.showInKB == true){
						vm.invalidBankFeeSixeText = "Maximum of " + vm.bankFeeFileUploadSize +" KB file size is allowed";
					}else{
						vm.invalidBankFeeSixeText = "Maximum of " + vm.bankFeeFileUploadSize +" MB file size is allowed";
					}
					vm.invalidBankFee = false;
					vm.invalidBankFeeName = false;
					vm.bankFee=null;
					angular.element("input[type='file']").val(null);

				}else{
					vm.inputBankFee=vm.bankFee.name;
					vm.invalidBankFeeSize = false;
					vm.invalidBankFee = false;
					vm.invalidBankFeeName = false;
					vm.fileExtension = vm.inputBankFee.substring(vm.bankFee.name.lastIndexOf(".") + 1);
					var fileNameWithoutExtension  =  vm.inputBankFee.substring(0,vm.bankFee.name.lastIndexOf("."));
					//check for extension
					if(!(Extensions == (vm.fileExtension.toLowerCase()))) {
						angular.element("input[type='file']").val(null);
						vm.bankFee=null;
						vm.inputBankFee=null;
						vm.invalidBankFee = true;
						vm.invalidBankFeeSize = false;
						vm.invalidBankFeeName = false;
						vm.invalidBankFeeText = "Accepted file format .pdf";
					}
					else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
	    				 angular.element("input[type='file']").val(null);
	    				 vm.bankFee=null;
				         vm.inputBankFee=null;
				         vm.invalidBankFee = false;
				         vm.invalidBankFeeSize = false;
				         vm.invalidBankFeeName = true;
				         vm.invalidBankFeeNameText = "Uploaded pdf file name should not exceed " + vm.maxFileNameLength+ " charaters.";
				         
	    			 }

					else{ // check for malicious file with correct extension
						var reader = new FileReader();
						reader.readAsArrayBuffer(value.slice(0,4));
						reader.onloadend = function(e){
							if (e.target.readyState === FileReader.DONE) {
								var arrayBuffer = e.target.result;
								var bytes = new Uint8Array( arrayBuffer );
								if(!FileUtil.checkTermsConditionExtension(bytes)){
									angular.element("input[type='file']").val(null);
									vm.bankFee=null;
									vm.inputBankFee=null;
									vm.invalidBankFee = true;
									vm.invalidBankFeeSize = false;
									vm.invalidBankFeeName = false;
									vm.invalidBankFeeText = "Accepted file format .pdf";
								}
								else{
									vm.invalidBankFeeSize = false;
									vm.invalidBankFee =false;
									vm.invalidBankFeeName = false;


								}
								$scope.$digest();
							}
						}; 
					}
				}
			}
			else{
				angular.element("input[type='file']").val(null);

				vm.inputBankFee=null;
			}
		});
		
	}   
})();
