(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('EInvoiceUpload', EInvoiceUpload);

        EInvoiceUpload.$inject = ['$resource'];

    function EInvoiceUpload($resource) {
        var resourceUrl =  'api/e-invoice-upload';

        return $resource(resourceUrl, {}, {
        	   'uploadFiles': {
                   method: 'POST',
                   url: 'api/upload-eInvoice-jsonMappingfiles',
                //   params: {chargeTypeId : '@chargeTypeId', financialYearId : '@financialYearId'},
                   headers: {'Content-Type': undefined},
                   responseType: 'arraybuffer',
                   transformResponse: function(data, headers) {
                 	  return {
                           data: data,
                           headers: headers
                       };   
                   }         
              }
            
            
              });
    }
})();

            
