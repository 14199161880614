(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('CustomerApprovalController', CustomerApprovalController);

    CustomerApprovalController.$inject = ['$scope', '$timeout', '$state', '$stateParams', 'workflowSharedService', 'CustomerApproval', '$rootScope', 'GlobalValues', 'Workflow', '$filter', 'ScreenLog', 'entityConstants','DefaultMarkup','AlertService','MessageBox'];

    function CustomerApprovalController($scope, $timeout, $state, $stateParams, workflowSharedService, CustomerApproval, $rootScope, GlobalValues, Workflow, $filter, ScreenLog, entityConstants,DefaultMarkup,AlertService,MessageBox) {
        var vm = this;
        vm.screenLog = ScreenLog;
        vm.tasks = [];
        vm.getTasks = [];
        vm.approveEntity = approveEntity; 
        vm.rejectEntity = rejectEntity;
        vm.viewEntity = viewEntity;
        vm.discardEntity = discardEntity;
        
        vm.globalValues=GlobalValues;
        vm.userType = vm.globalValues.getOrganisation().organisationType.shortName;
        vm.userLogin=vm.globalValues.getUser().login;
        
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_APPROVAL;
        logScreenAccess();
        
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
 	    $scope.page = 1;
        
        loadAll();

        function loadAll() {
            CustomerApproval.query({ "orgId": GlobalValues.getOrgId() }, function(result) {
                vm.fetchedTasks = result;
                vm.tasks = [];
                
                angular.forEach(vm.fetchedTasks, function(task) {
                	task.changeType = getChangeType(task);
                	console.log(angular.toJson(task));
                	if(task.entity.shortName == 'Customer'){
                		
                		CustomerApproval.getBankDetails({ "customerId":  task.entityRecordID, "bankId" : GlobalValues.getOrgId() }, 
                			function(bankDetails){ 
                				task.bankAccNumber = bankDetails.bankAccountNumber;
                			}).$promise.then(function(){
                				
                            });
                	}                		
                    vm.tasks.push(task);
                });
            });
        }

        function approveEntity(entityList) {
        	vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
        	// Terms and conditions agree not requeired on approval  - 22 june 2020 (TAC CR)
        	//instead disclaimer required for customer approval,no check box , just information
        	if(entityList.changeType != 'DELETE' && entityList.entity.shortName != 'Customer' ){
                if(entityList.payload.markupSettings==null){
                    DefaultMarkup.getByBank({"bankId":entityList.payload.bank.id}, onSaveSuccess, onSaveError);
                    function onSaveSuccess (result) {
                        if(result!=null){
                            if(result.markupType==null||result.markupType==undefined||result.markupType==''){
                                AlertService.error('Please assign the markup type');
                            }else if(result.markupValue==null||result.markupValue==undefined||result.markupValue==''){
                                AlertService.error('Please assign the markup value');
                            }else if(result.roEdApplicabilityMethod==null||result.roEdApplicabilityMethod==undefined){
                                AlertService.error('Please assign the RO/ED applicability method');
                            }else{
                                MessageBox.openMessageBox("Alert", "Please note the system will assign the default markup set by the bank for this customer. Do you want to proceed?", "ProceedCancel", "setDefaultMarkup");
                            }  
                        }
                    } 
                }else{
                    $state.go('customer-registration-apporval-disclaimer', {entityId : entityList.id,customerId:null,tradingBank : null});
                }
                  
                $rootScope.$on('gatewayApp.messagebox.proceed.setDefaultMarkup', function (event, data) {
                    $state.go('customer-registration-apporval-disclaimer', {entityId : entityList.id,customerId:null,tradingBank : null});
                });
                
                $rootScope.$on('gatewayApp.messagebox.cancel.setDefaultMarkup', function (event, data) {
                    //do not proceed
                }); 
                function onSaveError () {
                    
                }
        		
        	}else{
        		 $scope.rowID = entityList.id;
        		 CustomerApproval.approve({ "taskId": entityList.id }, onApproveSuccess, onApproveError);
        	}
        	
           /* $scope.rowID = entityList.id;
            
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'APPROVE'});
            CustomerApproval.approve({ "taskId": entityList.id }, onApproveSuccess, onApproveError);*/
        }

        function onApproveSuccess(result) {
            var flag = $filter('filter')(vm.tasks, { id: $scope.rowID });
            $rootScope.removeRow($scope.rowID);
        }
        
        

        function onApproveError(error) {}

        vm.checkTaskWithCurrentLogin = function checkTaskWithCurrentLogin(taskHistory){
        	var isValidTask = true;
        	var versionNo = getMaxVersionOfTask(taskHistory);
        	if(taskHistory!=null && taskHistory!=undefined){
        		angular.forEach(taskHistory, function(value){
        			if(value!=null && value!=undefined){
        				if(value.action=="APPROVED" && value.version == versionNo){
        					if(value.createdBy==vm.userLogin){
        						isValidTask = false;
        					}
        				}
        			}
        		});
        		return isValidTask;
        	}
        }
        
        function getMaxVersionOfTask(taskHistory){
        	var versionNo = 0;
        	if(taskHistory!=null && taskHistory!=undefined){
        		if(taskHistory.length>0){
        			versionNo = taskHistory[taskHistory.length-1].version;
        		}
        	}
        	return versionNo;
        }

        function rejectEntity(entityList) {
            $scope.rowID = entityList.id;
            vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.REJECT);
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'REJECT'});
            Workflow.rejectCustomerApproval(entityList.id);
        }
        
        function discardEntity(entityList) {
            $scope.rowID = entityList.id;
            vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.DISCARD);
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'DISCARD'});
            CustomerApproval.discard({ "taskId": entityList.id }, onDiscardSuccess, onDiscardError);
        }

        function onDiscardSuccess(result) {
            $rootScope.removeRow($scope.rowID);
        }

        function onDiscardError(error) {
        	
        }
        
        function viewEntity(entity,id,payload,action,changeType, recordId){
            $rootScope.payload = payload;
            $rootScope.taskID = id;
            var param;
            var state;
            vm.screenLog.screenLog(entity,recordId==null?0:recordId, entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS);
            workflowSharedService.viewFrom ='customerregistrationtodolist_checker';
            if(entity == 'Customer'){
            	state = 'customer-update-approval-todo-view';
            }else{
            	state = 'customer-approval-todo-view';
            }
           
            
            $state.go(state,{ 'mode' :param  });
        }
        
       
        
        
        
        
    	function getChangeType(task){
    		if(task.changeType == 'CREATE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'ADDED';
    		}else
    			 return task.changeType;
    		
    	}
    	
        $rootScope.removeRow = function(id) {
            var index = -1;
            var comArr = eval(vm.tasks);
            for (var i = 0; i < comArr.length; i++) {
                if (comArr[i].id === id) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                //alert("Something gone wrong");
            }
            vm.tasks.splice(index, 1);
        };

        $scope.toggle = true;
        $scope.refresh = function() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }

    }

})();
