(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('ScreenLogService', ScreenLogService);

    ScreenLogService.$inject = ['$resource'];

    function ScreenLogService ($resource) {
        var resourceUrl =  '/api/screen-log';

        return $resource(resourceUrl, {}, {
            'screenLog': {
                method: 'GET',
                params: {entityName:'@entityName', entityRecordId:'@entityRecordId', action:'@action'},
                url: 'api/screen-log/:entityName/:entityRecordId/:action'
            },
            'screenActionLog': {
                method: 'GET',
                params: {screenLogId:'@screenLogId', action:'@action'},
                url: 'api/screen-action-log/:screenLogId/:action'
            }
        });
    }
})();
