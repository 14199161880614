(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'role'
        })
        
        .controller('ManageRoleController', ManageRoleController);

    ManageRoleController.$inject = ['$scope', '$state', 'Role', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout'];

    function ManageRoleController($scope, $state, Role, GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.ORGANISATION_ROLE;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "role";
        vm.launch=launch;
        function launch() {
        	$state.go("manage-role.new");
        }
        $scope.refresh=refresh;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;

        
        vm.permitOrganisationRoleEditEntityAction = EntityPermissions.hasPermission('OrganisationRole.EDIT');
        vm.permitOrganisationRoleViewEntityAction = EntityPermissions.hasPermission('OrganisationRole.VIEW');
        vm.permitOrganisationRoleDeleteEntityAction = EntityPermissions.hasPermission('OrganisationRole.DELETE');
        vm.permitOrganisationRoleCreateEntityAction = EntityPermissions.hasPermission('OrganisationRole.CREATE');
        logScreenAccess();
        loadAll();

       function loadAll() {
            Role.query({"orgId": GlobalValues.getCorpId()},function(result) {
                vm.roles = result;
             });        	
        }      
       /*vm.screenLog = function screenLog(entityRecordId,action){
          	ScreenLog.screenLog({'entityName': 'OrganisationRole', 'entityRecordId': entityRecordId, 'action': action});
       }*/
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadAll();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
    }
})();
