(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'searchfaq'
        })
        
        .controller('FaqVideoController', FaqVideoController);

    FaqVideoController.$inject = ['$rootScope', '$scope', '$state', '$uibModalInstance', 'workflowSharedService', 'FAQService', 'entity', 'GlobalValues', '$timeout'];

    function FaqVideoController($rootScope,  $scope, $state, $uibModalInstance, workflowSharedService, FAQService, entity, GlobalValues,$timeout) {
        var vm = this;
        workflowSharedService.viewFrom = "searchfaq";
       
        vm.videofilename = entity.videoName;

        getsourefilepath();
        
        function getsourefilepath(){
        	
        	$timeout(function () {
    			vm.videolink="api/file/faq/videos/download/" + vm.videofilename  + "/mp4";
            	var video = document.querySelector('video');
            	var source = document.querySelector('source');
//            	alert(vm.videolink);
//            	alert(source.src);
            	source.src = vm.videolink;
            	var isPlaying = video.currentTime > 0 && !video.paused && !video.ended 
                && video.readyState > 2;
                console.log(isPlaying);
                if (!isPlaying) {
                	video.load();
                	video.play();
                }
//            	video.load();
//            	video.play();
//            	alert("changesource end");

            }, 1000);
        	

        	
        }
        
        angular.element(document).ready(function () {
			   $('.videoElement').bind('contextmenu',function() { return false; });
         });
        
        vm.clear=clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();

