(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'intradayMarketSessionSettings'
        })
        
        .controller('intradayMarketSessionSettingsController', intradayMarketSessionSettingsController);

    intradayMarketSessionSettingsController.$inject = ['$scope', '$state', 'IntradayMarket', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','DateUtils','$filter','$timeout','AlertService'];

    function intradayMarketSessionSettingsController($scope, $state, IntradayMarket , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,DateUtils,$filter,$timeout,AlertService) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.INTRADAY_MARKET_SESSION_SETTINGS;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        workflowSharedService.viewFrom = "intradayMarketSessionSettings";
        vm.currentBusinessDate;
        vm.convertedCurrentBusinessDate;
        vm.permitIntradayMarketEditAction = EntityPermissions.hasPermission('IntradayMarketSessionSettings.EDIT');
        $scope.refresh=refresh;
        vm.editIntradayMarket = editIntradayMarket;
        vm.todayDate = new Date();
        
        logScreenAccess();
      loadCurrentBusinessDate();
    //    loadAll();
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function loadCurrentBusinessDate(){
    	   IntradayMarket.getCurrentBusinessDate(getBusinessDateSuccess, getBusinessDateFailed);
       }
       
       function getBusinessDateSuccess(result){
    	   vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(result.businessDate);
    	   vm.convertedCurrentBusinessDate=DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
    	   IntradayMarket.query({"currentBusinessDate":vm.convertedCurrentBusinessDate},function(result) {
    		   	
    		   angular.forEach(result, function(value){
			   		
	    			  var closeTime = value.closeTime.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [value.closeTime];
	    			   
	    			  closeTime = closeTime.slice (1);  
	    			  closeTime[5] = ' ';
	    			  closeTime[6] = +closeTime[0] < 12 ? 'AM' : 'PM'; 
	    			  closeTime[0] = +closeTime[0] % 12 || 12;
	    			  if(closeTime[0]<=9){
	    				  closeTime[0]=0+""+closeTime[0];
	    			  }
				    value.closeTime = closeTime.join (''); 
				    
				    
				    var openTime = value.openTime.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [value.openTime];
	    			   
				    openTime = openTime.slice (1);  
				    openTime[5] = ' ';
				    openTime[6] = +openTime[0] < 12 ? 'AM' : 'PM'; 
				    openTime[0] = +openTime[0] % 12 || 12;
				    if(openTime[0]<=9){
				    	openTime[0]=0+""+openTime[0];
				    }
				    value.openTime = openTime.join (''); 
				  });
				  
				  
				  vm.intradayMarket = result;
				
		 
             });
       }
       function getBusinessDateFailed(){
    	   
       }
       
       function editIntradayMarket(intradayMarket,$event){
    	   vm.currentTimeMoment = moment(new Date(), 'HH:mm A').format('HH:mm A');
    	   var currentTime = moment(vm.currentTimeMoment, 'HH:mm A');
    	   console.log(intradayMarket.openTime);
    	   console.log(currentTime.isBefore(moment(intradayMarket.openTime, 'HH:mm A')));
    	   if(intradayMarket.sessionStatus == 'CLOSED' && currentTime.isAfter(moment(intradayMarket.openTime, 'HH:mm A'))){
    		   AlertService.error($filter('translate')('gatewayApp.intradayMarket.closedSession'));
    	   }else{
    		   $state.params = {id:intradayMarket.id};
      			var state = 'intraday-market-edit';
      			$state.go(state,{id:intradayMarket.id});
    	   }
       }
       
      /* function loadAll(){
    	   
    	   vm.currentDate=DateUtils.convertLocalDateToServer(vm.todayDate);
    	   IntradayMarket.query({"currentBusinessDate":vm.currentDate},function(result) {
    		   	
    		   angular.forEach(result, function(value){
			   		
	    			  var closeTime = value.closeTime.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [value.closeTime];
	    			   
	    			  closeTime = closeTime.slice (1);  
	    			  closeTime[5] = ' ';
	    			  closeTime[6] = +closeTime[0] < 12 ? 'AM' : 'PM'; 
	    			  closeTime[0] = +closeTime[0] % 12 || 12;
	    			  if(closeTime[0]<=9){
	    				  closeTime[0]=0+""+closeTime[0];
	    			  }
				    value.closeTime = closeTime.join (''); 
				    
				    
				    var openTime = value.openTime.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [value.openTime];
	    			   
				    openTime = openTime.slice (1);  
				    openTime[5] = ' ';
				    openTime[6] = +openTime[0] < 12 ? 'AM' : 'PM'; 
				    openTime[0] = +openTime[0] % 12 || 12;
				    if(openTime[0]<=9){
				    	openTime[0]=0+""+openTime[0];
				    }
				    value.openTime = openTime.join (''); 
				  });
				  
				  
				  vm.intradayMarket = result;
				
		 
             });
       }*/
       $scope.toggle = true;
       function refresh() {
           $scope.toggle = false;
           $scope.refreshFlag = false;
           loadCurrentBusinessDate();
           if ($scope.toggle == false) {
               $timeout(function() {
                   $scope.toggle = true;
               }, 2000);
               $scope.refreshFlag = false;
           }
       };
      
    }
})();
