(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('BankRegistrationViewController', BankRegistrationViewController);

    BankRegistrationViewController.$inject = ['$scope', '$state','$rootScope','workflowSharedService','$uibModalInstance','BankRegistration','entity', 'Workflow', 'GlobalValues'];

    function BankRegistrationViewController($scope, $state, $rootScope, workflowSharedService, $uibModalInstance,BankRegistration,entity, Workflow, GlobalValues) {
        var vm = this;
        vm.clear=clear;
        vm.approveBankRegistration = approveBankRegistration;
        vm.rejectBankRegistration = rejectBankRegistration;
        vm.discardBankRegistration = discardBankRegistration;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.globalValues = GlobalValues;
        vm.ExpandOrCollapseUser1 = false;
        vm.ExpandOrCollapseUser2 = false;
        vm.ExpandOrCollapseBankDetails = true;
        vm.hidePanForAudit = false;
        vm.bankDTO = {};
        
        
        
        if(workflowSharedService.viewFrom == 'bankregistration'){
        	vm.bankRegistration = entity; 
        	vm.mobileCountryCode=vm.bankRegistration.mobileCountryCode;
        	vm.tradeName = vm.bankRegistration.tradeName;
        	vm.legalName = vm.bankRegistration.legalName;
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'bank-registrationtodolist_checker') {
        	vm.bankDTO =  angular.fromJson($rootScope.payload);
        	vm.bankRegistration = vm.bankDTO.bank;
        	
        	if(vm.bankRegistration == undefined){
        		vm.bankRegistration = vm.bankDTO;
        		
        	}
        	        	
        	vm.mobileCountryCode=vm.bankRegistration.mobileCountryCode;
        	vm.tradeName = vm.bankRegistration.tradeName;
        	vm.legalName = vm.bankRegistration.legalName;
        	vm.user1=  vm.bankDTO.user1;
        	vm.user2=  vm.bankDTO.user2;
        	
        	if(vm.user1 != undefined){
        		vm.user1.dob = new Date(vm.user1.dob);
            	vm.user2.dob = new Date(vm.user2.dob);
        	}
        	       	
        	
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'bank-registrationtodolist_maker') {
        	vm.bankDTO =  angular.fromJson($rootScope.payload); 
        	vm.bankRegistration = vm.bankDTO.bank;
        	
        	if(vm.bankRegistration == undefined){
        		vm.bankRegistration = vm.bankDTO;
        		
        	}
        	
        	vm.mobileCountryCode=vm.bankRegistration.mobileCountryCode;
        	vm.tradeName = vm.bankRegistration.tradeName;
        	vm.legalName = vm.bankRegistration.legalName;
        	vm.user1=  vm.bankDTO.user1;
        	vm.user2=  vm.bankDTO.user2;
        	
        	if(vm.user1 != undefined){
        		vm.user1.dob = new Date(vm.user1.dob);
            	vm.user2.dob = new Date(vm.user2.dob);
        	}
        	
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        if(workflowSharedService.viewFrom == 'bankregistration_auditlog'){
        	vm.bankDTO =  angular.fromJson($rootScope.payload); 
        	vm.bankRegistration = vm.bankDTO.bank;
        
        	if(vm.bankRegistration == undefined){
        		vm.bankRegistration = vm.bankDTO;
        		
        	}
        	if(vm.bankRegistration.bankPan == undefined || vm.bankRegistration.bankPan == ''){
        	vm.hidePanForAudit = true;
        	}
        	vm.mobileCountryCode=vm.bankRegistration.mobileCountryCode;
        	vm.tradeName = vm.bankRegistration.tradeName;
        	vm.legalName = vm.bankRegistration.legalName;
        	vm.user1=  vm.bankDTO.user1;
        	vm.user2=  vm.bankDTO.user2;
        	if(vm.user1 != undefined){
        		vm.user1.dob = new Date(vm.user1.dob);
            	vm.user2.dob = new Date(vm.user2.dob);
        	}
       	 	$scope.showButton=false;
        }
        vm.bankRegistration.dateOfJoining = new Date(vm.bankRegistration.dateOfJoining);

        function approveBankRegistration() { 
        	BankRegistration.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectBankRegistration() { Workflow.rejectTask($rootScope.taskID); }

        function discardBankRegistration() {
        	BankRegistration.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }   
})();
