(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('otr-summary', {
			parent: 'entity',
			params:{screen: 'OTRSummary'},
			url: '/otr-summary',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			ncyBreadcrumb: {
				label: '{{ "global.menu.otrSummary.main" | translate }}',
				parent: 'home'
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/otr-summary/otr-summary.html',
					controller: 'OTRSummaryViewController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('otrSummaryReport');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}],
				configSetting :['GlobalValues', function(GlobalValues) {
                    return GlobalValues.getConfigSettings();
                }],
                systemSettings: ['IntradayMarket', function(IntradayMarket) {
                    return IntradayMarket.getCurrentBusinessDate().$promise;
                }],
				financialYears: ['StaticLookUp', function(StaticLookUp){
					return StaticLookUp.getPastAndPresentFinancialYears().$promise;
				}]

			}
		})   

	};
})();
