(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'invoiceView'
        })
        
        .controller('InvoiceViewController', InvoiceViewController);

    InvoiceViewController.$inject = ['$scope', '$state', 'InvoiceView', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants',
    				'$timeout','DateUtils','StaticLookUp','financialYears','chargeTypes','MessageBox','$rootScope'];

    function InvoiceViewController($scope, $state, InvoiceView , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,
    				$timeout,DateUtils,StaticLookUp,financialYears,chargeTypes,MessageBox,$rootScope) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.INVOICE_VIEW;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.financialYear = vm.globalValues.getFinancialYear();
        vm.chargeTypes = chargeTypes;
        vm.financialYears = financialYears;
        vm.financialYear = vm.financialYears[0];
        vm.selectedFilter='group';
        vm.generatePDF = generatePDF;
        vm.regenerateInvoice = regenerateInvoice;
        vm.checkForRegeneration = checkForRegeneration;
        vm.isRegenerated=false;
        vm.invoiceRegeneratedNumber ="";
        
        vm.todayDate = new Date();
       
        vm.orgId = vm.globalValues.getOrgId();
        
        var yesMessageEvent = null;
        vm.clicked=false;
        vm.currentBusinessDate = DateUtils.convertDateTimeFromServer(vm.globalValues.getSystemSettings().businessDate);
       // console.log(moment(vm.currentBusinessDate).subtract(1, 'months').calendar());
        vm.previousDate = moment(vm.currentBusinessDate).subtract(1, 'months').calendar();
        
     //   vm.currentMonth = moment().subtract(1,'months').month();
        
        
      
        
        vm.loadReportPeriods=loadReportPeriods;
        vm.getInvoiceView = getInvoiceView;
        vm.ExpandOrCollapseOrderStatusReport=true;
        
        workflowSharedService.viewFrom = "invoiceView";
       
        loadReportPeriods();
        logScreenAccess();
       // loadCurrentBusinessDate();
        
        
        function loadReportPeriods(){
        	InvoiceView.getReportPeriodList({financialYearFromDate : vm.financialYear.financialYearStart,financialYearToDate:vm.financialYear.financialYearEnd},function(result){
        		vm.reportPeriods = result;
        	});
        	
        	InvoiceView.getInvoiceNumbers(function(result){
        		vm.invoiceNumbers = result;
        	})
        }
        
        $scope.$watch('vm.selectedFilter',function(value){
        	console.log(vm.selectedFilter);
        	if(value!=null && value!=undefined){
        		if(value=='individual'){
        			document.getElementById("txtbank").value = "";
        			vm.bank=null;
        			document.getElementById("chargesType").value = "";
        			document.getElementById("reportPeriod").value = "";
        		}
        		if(value=='group' && document.getElementById("invoiceNumber")!=null && document.getElementById("invoiceNumber")!=undefined){
        			document.getElementById("invoiceNumber").value = "";
        			//vm.invoiceNumber='';
        		}
        	}
        })

       function getInvoiceView() {
    	   
    	   vm.chargesTypeId = 0;
    	   vm.convertedStartDate = null;
    	   vm.convertedEndDate = null;
    	   
    	   if(vm.chargesType!=null && vm.chargesType!=undefined && vm.chargesType!=""){
    		   vm.chargesTypeId = vm.chargesType.id;
    	   }
    	   
    	   if(vm.reportPeriod!=null && vm.reportPeriod!=undefined && vm.reportPeriod!=""){
    		   var reportDates = vm.reportPeriod.split(" to ");
    		   vm.reportStartDate = parseDate(reportDates[0]);
    		   vm.reportEndDate = parseDate(reportDates[1]);
    		   vm.convertedStartDate = DateUtils.convertLocalDateToServer(vm.reportStartDate);
    		   vm.convertedEndDate = DateUtils.convertLocalDateToServer(vm.reportEndDate);
    	   }
    	  if(vm.invoiceNumber!=null && vm.invoiceNumber!=undefined && vm.selectedFilter=='individual'){
    		  InvoiceView.getInvoiceDetailsByInvoiceNumber({"invoiceNo":vm.invoiceNumber, "orgId":vm.orgId}, function(result){
    			  
    			  angular.forEach(result,function(value){
					  value.invoiceEndDate = DateUtils.convertDateTimeFromServer(value.invoiceEndDate);
					  vm.initialString = buildFileNames(value);
					  value.originalFileName = vm.initialString + '.pdf';
					  value.duplicateFileName = vm.initialString + '_dup.pdf';
					  value.annexureFileName = vm.initialString + '.xls';
					  
				  })
				  
    			  vm.invoiceView = result;
				  vm.clicked=false;
    			  
    		  })
    	  }else{
    		  InvoiceView.getInvoiceDetails({"bankId":vm.bank.id,"chargesTypeId":vm.chargesTypeId,"financialYearId":vm.financialYear.id,
    			  "invoiceStartDate":vm.convertedStartDate,"invoiceEndDate":vm.convertedEndDate},function(result){
    				
    				  angular.forEach(result,function(value){
    					  value.invoiceEndDate = DateUtils.convertDateTimeFromServer(value.invoiceEndDate);
    					  vm.initialString = buildFileNames(value);
						  value.originalFileName = vm.initialString + '.pdf';
						  value.duplicateFileName = vm.initialString + '_dup.pdf';
						  value.annexureFileName = vm.initialString + '.xls';
    				  })
    				 
    				 
    	      
		  
    			  vm.invoiceView = result;
				  vm.clicked=false; 
    		  })
    	  }
    	  
    	  
       }
        
        function checkForRegeneration(invoice){
        	
        	if(moment(invoice.invoiceEndDate).month()==moment(vm.previousDate).month() && moment(invoice.invoiceEndDate).year()==moment(vm.previousDate).year() && invoice.active){
        		return true;
        	}else{
        		return false;
        	}
        }
        
        function parseDate(s) {
        	  var months = {jan:0,feb:1,mar:2,apr:3,may:4,jun:5,
        	                jul:6,aug:7,sep:8,oct:9,nov:10,dec:11};
        	  var p = s.split('-');
        	  return new Date(p[2], months[p[1].toLowerCase()], p[0]);
        	}

        	
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function generatePDF(invoice, fileType){
       		
    	   InvoiceView.getAnnexureDetails({invoiceId: invoice.id}, function(result){
    		   var link = document.createElement("a");
    		   var fileData = null;
    		   vm.baseString = buildFileNames(invoice);
    		   if(fileType=='original'){
        		   fileData = result.originalInvoice;
        		   link.download = vm.baseString;
    		   }else if(fileType=='duplicate'){
        		   fileData = result.duplicateInvoice;
        		   link.download = vm.baseString + '_dup';
    		   }else if(fileType=='annexure'){
        		   fileData = result.annexures;
        		   link.download = vm.baseString;
    		   }
    		  
 			
    			if(fileType=='annexure'){
        			link.href = 'data:application/vnd.ms-excel;base64,'+ encodeURI(fileData);
    			}else{
        			link.href = 'data:application/pdf;base64,'+ encodeURI(fileData);
    	       		
     		   	}
	      		
    			  document.body.appendChild(link);
    			  link.title="title";
    			  link.setAttribute('title', 'signature');
    			  link.click();
    			  document.body.removeChild(link);
    	   })
    	   

       }
       
       /*function regenerateInvoice(invoiceNumber){
    	   
    	if(invoiceNumber!=null && invoiceNumber!=undefined){
    		$scope.invoiceNumber = invoiceNumber;
    		MessageBox.openMessageBox("Alert", "Do you want to regenerate Invoice?", "YesNo", "regenerateInvoice");
    		
    	}
    	}*/
    	function regenerateInvoice(invoiceId){
 	   
    		if(invoiceId!=null && invoiceId!=undefined){
    			$scope.invoiceNumber = invoiceId;
    			MessageBox.openMessageBox("Alert", "Do you want to regenerate the selected Invoice?", "YesNo", "regenerateInvoice");
    		
    		}
    	}
    	
    	  $scope.$on('$destroy', function() {
    		  if (yesMessageEvent!=null )
    			  	yesMessageEvent();
	        });
      
    		
       	 yesMessageEvent = $rootScope.$on('gatewayApp.messagebox.yes.regenerateInvoice', function (event, data) {
    			if($scope.invoiceNumber!=null && $scope.invoiceNumber!=undefined){
    				InvoiceView.regenerateInvoice({invoiceNo : $scope.invoiceNumber}, regenerateInvoiceSuccess,regenerateInvoiceFailure);
    			}
    		});
    	
    
		$rootScope.$on('gatewayApp.messagebox.no.regenerateInvoice', function (event, data) {
			//do nothing
		});
		
		function regenerateInvoiceSuccess(result){
			getInvoiceView();
			vm.isRegenerated = true;
			vm.invoiceRegeneratedNumber = vm.filterData[0].invoiceNumber;
			console.log(vm.isRegenerated);
		}
		
		function regenerateInvoiceFailure(){
			
		}
		
		function buildFileNames(invoice){
			var convertedCurrentBusinessDate = DateUtils.convertLocalDateToServer(invoice.invoiceEndDate);
			
			var generatedDate = DateUtils.convertDateTimeFromServer(invoice.invoiceGeneratedDate);
			var initialString = 'tmp_' + invoice.bank.ccpMembershipId + '_FX';
			if(invoice.chargesType.shortName=='AMC'){
				initialString = initialString + '0002_A';
			}else if(invoice.chargesType.shortName=='OneTimeRegistration'){
				initialString = initialString + '0003_M';
			}else if(invoice.chargesType.shortName=='Transaction'){
				initialString = initialString + '0001_M';
			}
			
			initialString = initialString + '_F_F_' + moment(invoice.invoiceEndDate).format("YYYYMMDD") + '_' +  moment(generatedDate).format("YYYYMMDD");
			return initialString;
			
		}
		$scope.$watch('vm.filterText', function(value){
			if( vm.isRegenerated==true && vm.filterData.length >0 &&  vm.invoiceRegeneratedNumber != vm.filterData[0].invoiceNumber){
				vm.isRegenerated=false;
			}
        });
    	
		
    }
})();
