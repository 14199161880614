(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'limitSettings'
        })
        
        .controller('LimitsController', LimitsController);

    LimitsController.$inject = ['$scope', '$rootScope', '$state', 'workflowSharedService', 'GlobalValues', 'EntityPermissions', 'ScreenLog', 'entityConstants' ,'StaticLookUp','LimitSettings','DateUtils','$filter','MessageBox'];

    function LimitsController($scope, $rootScope, $state, workflowSharedService, GlobalValues, EntityPermissions, ScreenLog , entityConstants, StaticLookUp,LimitSettings,DateUtils,$filter,MessageBox) {
    
    	 var vm = this;
         vm.globalValues = GlobalValues;
         vm.screenName = entityConstants.ENTITY_SHORT_NAME.LIMITSETTINGS;
         vm.entityConstants = entityConstants;
         vm.screenLog = ScreenLog;
		 vm.clicked=false;
         vm.loadAll = loadAll;
         vm.loadDefaultLimitPeriod = loadDefaultLimitPeriod;
         vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
         
  		 $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
  	     $scope.page = 1;
         
         vm.selectedBranch=null;
         vm.selectedBank=null;
         vm.limitsExpiringIn="";
         vm.showExpired=false;
         vm.disableExpiringIn=false;
         vm.disableBranch=false;
         vm.limitRows=[];
         loadDefaultLimitPeriod();
         //defaultSetup(); -- load it after getting the config limitexpiryperiod irrespective of success or failre
         vm.edit = editLimit;
         vm.view = viewLimit;
         vm.reset = resetLimit;
         var loadDefaultLimitPeriod;
         //vm.currentDate = new Date();
         var systemSettings = vm.globalValues.getSystemSettings();
         if(systemSettings!=null && systemSettings!=undefined){
        	 
        	 var now = moment();
        	 vm.momentBusinessDate = moment(systemSettings.businessDate, 'YYYY-MM-DD');        	
        	 vm.momentBusinessDate.set(
        			 { 	'hour':now.get('hour'),
        				'minute':now.get('minute'),
        				'second':now.get('second')
        				
        			 });
        	 vm.currentDate = vm.momentBusinessDate.toDate();
         }
         //loadAll();
         
         function loadDefaultLimitPeriod(){
        	 StaticLookUp.getConfigSettings(getConfigSuccess, getConfgiFailure);
         }
         function getConfigSuccess(result){
        	 var configData = result;
        	 loadDefaultLimitPeriod = configData.defaultLimitPeriodForSpotWindow;
        	 vm.limitsExpiringIn =loadDefaultLimitPeriod;
        	 defaultSetup();
         }
         function getConfgiFailure(error){
        	 //TODO
        	 defaultSetup();
         }
         /*$scope.$watch('vm.limitsExpiringIn', function(value){
        	 if(value==undefined || value ==null || value ==""){
        		 vm.disableExpired=false;
        	 }else{
        		 vm.disableExpired=true;
        	 }
         });*/
         $scope.$watch('vm.showExpired', function(value){
        	 if(value){
        		vm.disableExpiringIn =true;
        		vm.limitsExpiringIn="";
        	 }else{
        		 vm.disableExpiringIn =false;
        		 vm.limitsExpiringIn= loadDefaultLimitPeriod;
        	 }
         })
         
         function defaultSetup(){
        	 if (vm.loggedinEntityOwnerType == "Bank"){
        		 vm.disableBranch=false;
        			 
				 vm.selectedBank = vm.globalValues.getBank();
        	 }else if(vm.loggedinEntityOwnerType == "Branch") {
        		 vm.disableBranch=true;
        		 vm.selectedBranch = vm.globalValues.getBranch();
        		 vm.selectedBank = vm.globalValues.getBank();
        		 loadAll();
        	 }
        	 
         }
       

 		workflowSharedService.viewFrom = "limitSettings";
 		
         vm.launch=launch;
         function launch() {
        	 workflowSharedService.viewFrom = "limitSettings_add";
         	$state.go("manage-limits.new");
         	//$state.go("special-holidays.view");
         }
         
        vm.permitLimitEditEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
        vm.permitLimitResetEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.RESET);
        vm.permitLimitCreateEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.CREATE);
 		vm.permitLimitViewEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.VIEW);
 		vm.permitLimitImportEntityAction = EntityPermissions.hasPermission( vm.screenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.IMPORT);
 		
         function loadAll() {
        	 var selectedBranchId = null;
        	 if(vm.selectedBranch != null && vm.selectedBranch !=undefined){
        		 selectedBranchId = vm.selectedBranch.id
        	 }
        	return LimitSettings.fetchLimits({bank:vm.selectedBank.id , branch:selectedBranchId, limitsExpiringIn:vm.limitsExpiringIn, showExpired:vm.showExpired } , fetchSuccess,fetchFailure);
         }
         function fetchSuccess(result){
			 vm.clicked=false;
        	 var fetchedLimits = result;
             vm.limitRows = [];
             angular.forEach(fetchedLimits, function(row) {
            	 var orderedLimitDetails = [];
            	 orderedLimitDetails = $filter('orderBy')(row.limitSettingsDetailsList, 'instrumentType.id');
            	angular.forEach(orderedLimitDetails, function(details){
            		var newLimit = angular.copy(row);
            		newLimit.instrumentType =  details.instrumentType;
            		if(DateUtils.convertDateTimeFromServer(row.effectiveToDateTime) < vm.currentDate || row.active==false){
            			newLimit.showEdit=false;
                        newLimit.showResetToSpot = false;
                        newLimit.showResetToForward = false;
                        newLimit.showResetToAll = false;
            		}else{
            			newLimit.showEdit=true;
            		}
            		
                    if(DateUtils.convertDateTimeFromServer(row.effectiveToDateTime) < vm.currentDate || row.active==false){
                        //dont enable 
                    }
                    else{	
	                    //show reset to spot/forward/all depend on limit instrument group
	                    if(row.limitInstrumentGroup.shortName=='SPOTWINDOW'){
	                        newLimit.showResetToForward = false;
	                        newLimit.showResetToAll = true;
	                        newLimit.showResetToSpot = false;
	                    }else if(row.limitInstrumentGroup.shortName=='FORWARD'){
	                        newLimit.showResetToForward = false;
	                        newLimit.showResetToAll = true;
	                        newLimit.showResetToSpot = false;
	                    }else if(row.limitInstrumentGroup.shortName=='ALL'){
	                        newLimit.showResetToForward = true;
	                        newLimit.showResetToAll = false;
	                        newLimit.showResetToSpot = true;
	                    }
                    }
            		if(newLimit.instrumentType ==null || newLimit.instrumentType==undefined || newLimit.instrumentType ==""){
            			newLimit.instrumentType={};
            			newLimit.instrumentType.name="ALL";
            		}           		            		
            		if(newLimit.limitType=="GROSSBUY"){
            			newLimit.limitType="Gross Buy";
            		}else if(newLimit.limitType=="GROSSSELL"){
            			newLimit.limitType="Gross Sell";
            		}else if(newLimit.limitType=="GLOBALGROSS"){
            			newLimit.limitType="Global Gross";
            		}else if(newLimit.limitType=="NET"){
            			newLimit.limitType="Net";
            		}
            		if(newLimit.limitReinstateMethod=="SettledTrades"){
            			newLimit.limitReinstateMethod="Settled Trades";
            		}
            		newLimit.value = details.value;
            		if(row.userRemarks == null || row.userRemarks == undefined ){
            			newLimit.userRemarks = "";
            		}else{
            			newLimit.userRemarks = row.userRemarks ; 
            		}
                 	vm.limitRows.push(newLimit);
            	});
             	
             });
         }
         function fetchFailure(error){
			vm.clicked=false;
         }
         
         logScreenAccess();
         function logScreenAccess(){
            	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
         }
         function viewLimit(limit){
        	
        	workflowSharedService.viewFrom = "limitSettings_view";
     	 	ScreenLog.screenLog(vm.screenName,limit.id, vm.entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS);
 			$state.params = {id:limit.id}
 			var state = '.view';
 			$state.go(state,{id:limit.id});
         }
         
         function editLimit(limit){
        	 workflowSharedService.viewFrom = "limitSettings_edit";
        	 	ScreenLog.screenLog(vm.screenName,limit.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
    			$state.params = {id:limit.id}
    			var state = '.edit';
    			$state.go(state,{id:limit.id});
         }

         function resetLimit(limit, resetInstrumentGroup){
            $rootScope.selectedLimit = angular.copy(limit);
            $rootScope.selectedResetInstrumentGroup = angular.copy(resetInstrumentGroup);
            MessageBox.openMessageBox("Alert", 'Already active limits exist. Do you want to proceed?', "YesNo", "resetlimits");
         }

         $rootScope.$on('gatewayApp.messagebox.yes.resetlimits', function (event, data) {
            workflowSharedService.viewFrom = "limitSettings_reset";
                ScreenLog.screenLog(vm.screenName,$rootScope.selectedLimit.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
                $state.params = {id:$rootScope.selectedLimit.id, resetInstrumentGroup: $rootScope.selectedResetInstrumentGroup};
                var state = '.reset';
                $state.go(state,{id:$rootScope.selectedLimit.id, resetInstrumentGroup: $rootScope.selectedResetInstrumentGroup});

         });

         $rootScope.$on('gatewayApp.messagebox.no.resetlimits', function (event, data) {
            //do nothing
         });

         $rootScope.$on('gatewayApp.messagebox.clear.resetlimits', function (event, data) {
            //do nothing
         });

         $scope.instrumentIdComparator = function(v1, v2) {
           // If we don't get strings, just compare by index
           if ( v1 != null && v1!=undefined 
        		   && v2!=null && v2!=undefined ) {
        	   return (v1.id < v2.id) ? 1 : -1;
           }

           return -1;
         };
         
    }
})();
  
