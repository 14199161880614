(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('amc-summary', {
			parent: 'entity',
			params:{screen: 'AMCSummary'},
			url: '/amc-summary',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			ncyBreadcrumb: {
				label: '{{ "global.menu.amcSummary.main" | translate }}',
				parent: 'home'
			},
			views: {
				'content@': {
					templateUrl: 'app/entities/amc-summary/amc-summary.html',
					controller: 'AMCSummaryViewController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('amcSummary');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}],
				configSetting :['GlobalValues', function(GlobalValues) {
                    return GlobalValues.getConfigSettings();
                }],
				systemSettings: ['IntradayMarket', function(IntradayMarket) {
					return IntradayMarket.getCurrentBusinessDate().$promise;
				}],
				financialYears: ['StaticLookUp', function(StaticLookUp){
					return StaticLookUp.getPastAndPresentFinancialYears().$promise;
				}]

			}
		})   

	};
})();
