(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.service("workflowSharedService", function () {        	
		this.viewFrom= 'agreedBankList'
	})

	.controller('AgreedBanksController', AgreedBanksController);

	AgreedBanksController.$inject = ['$scope','$rootScope','$state',  'GlobalValues', 'workflowSharedService', '$uibModalInstance','EntityPermissions', 'ScreenLog','entityConstants','$timeout','entity','DateUtils','ClearcorpTACUpload','TacViewForEntitiesUtility'];

	function AgreedBanksController($scope,$rootScope,$state, GlobalValues, workflowSharedService, $uibModalInstance, EntityPermissions, ScreenLog,entityConstants,$timeout,entity,DateUtils,ClearcorpTACUpload,TacViewForEntitiesUtility) {
		var vm = this;
		vm.entityConstants = entityConstants;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.TERMS_AND_CONDITIONS_VIEW;
		$scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.page = 1;
		vm.globalValues=GlobalValues;
		vm.screenLog = ScreenLog;
		vm.clear=clear;
		vm.agreedBankList=entity;
		vm.openTACPreview = openTACPreview;
		vm.exportToFile = exportToFile;
		 vm.permitTACViewEntityAction = EntityPermissions.hasPermission('TermsAndConditionsView.VIEW');

		function exportToFile(fileType){
			vm.baseString =   'Agreed_Banks_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			if( vm.agreedBankList !=null &&  vm.agreedBankList !=undefined){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv'
						vm.baseString = vm.baseString+ ".csv";  
					ClearcorpTACUpload.getCSVForAgreedBanks(vm.agreedBankList , processResponse );
				}else if(fileType == 'excel'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls";  
					ClearcorpTACUpload.getExcelForAgreedBanks(vm.agreedBankList , processResponse );
				}
			}
		}	
		function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			//ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.EXPORT_AGREED_BANKS); - action not required for export
		}

		function openTACPreview(agreedBank){
			vm.seletedFileName = agreedBank.fileName;
			var fd1 = new FormData();			
			ClearcorpTACUpload.getTermsAndConditionsMerged(agreedBank, onMergeSuccess, onMergeError);
		}

		function onMergeSuccess (result) {
			/*var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			  var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
			var file = new Blob([result.data], {type: 'application/pdf'});
			var fileURL = URL.createObjectURL(file);
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(file, "TermsAndCondition.pdf");
			}
			else {
				window.open(fileURL, "_blank",  "width=700,height=700,"+"top="+y+", left="+x);    
			}*/
			TacViewForEntitiesUtility.previewTACForArray(result.data, "Clearcorp's Terms and Conditions for Member.pdf");	

		}


		function onMergeError () {

		}
		logScreenAccess(); // screen log required for view agreed banks
		function logScreenAccess(){
			ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.VIEW_AGREED_BANKS);
		}


		function clear () {
			$uibModalInstance.dismiss('cancel');
		}
		

	
	}
})();
