(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
    	 $stateProvider
         .state('view-bank-fees-upload', {
             parent: 'entity',
			 params:{screen: 'BankFeesChargesUpload'},
             url: '/bank-fees-upload',
             data: {
            	 authorities: ['ROLE_USER'],
                 pageTitle: 'global.title'
             },
             ncyBreadcrumb: {
                 label: '{{ "global.menu.bankFeesAndChargesUpload.main" | translate }}',
                 parent: 'home'
             },
             views: {
                 'content@': {
                     templateUrl: 'app/entities/bank-fees-and-charges-upload/bank-fees-and-charges.html',
                     controller: 'BankFeeAndChargesUploadController',
                     controllerAs: 'vm'
                 }
             },
             resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                 	$translatePartialLoader.addPart('error');
                     $translatePartialLoader.addPart('global');       
                 	$translatePartialLoader.addPart('bankFeesAndChargesUpload');
                
                     return $translate.refresh();
                 }],
                 entity: function(){
                 	
                 }
             }
         })
         
         .state('feeUpload', {
 			parent: 'view-bank-fees-upload',
 			url: '/feeUpload',
 			data: {
 				authorities: ['ROLE_USER'],
 				pageTitle: 'global.title'
 			},
 			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
 				$uibModal.open({
 					templateUrl: 'app/entities/bank-fees-and-charges-upload/fee-charge-upload.html',
 					controller: 'FeeUploadController',
 					controllerAs: 'vm',
 					backdrop: 'static',
 					size: 'md',
 					resolve: {
 						
 		                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
 		                 	$translatePartialLoader.addPart('error');
 		                     $translatePartialLoader.addPart('global');       
 		                 	$translatePartialLoader.addPart('bankFeesAndChargesUpload');
 		                
 		                     return $translate.refresh();
 		                 }],


 						entity: function () {                        	
 							return {                            		
 							};
 						},
 						globalValue: ['StaticLookUp', function(StaticLookUp) {
 							return StaticLookUp.getConfigSettings().$promise;
 						}],
 						systemSettings: ['IntradayMarket', function(IntradayMarket) {
 							return IntradayMarket.getCurrentBusinessDate().$promise;
 						}],
 						configurationSetting :['GlobalValues', function(GlobalValues) {
 							return GlobalValues.getConfigSettings();
 						}]

 					}
 				}).result.then(function() {
 					$state.go('^', {}, { reload: true });
 				}, function() {
 					$state.go('^');
 				});
 			}]

 		})
 		
 		.state('bankFeesAndChargesUpload-todolist', {
			parent: 'dashboard',
			url: '/bankFeesAndChargesUpload-todo',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/bank-fees-and-charges-upload/fee-upload-approval.html',
					controller: 'FeeUploadApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {

						entity: function () {                        	
							return {                            		
							};
						},
						systemSettings:function() {
							return {                            		
							};
						},
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('view-bank-fees-upload', null, { reload: 'view-bank-fees-upload' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					$translatePartialLoader.addPart('bankFeesAndChargesUpload');
					return $translate.refresh();
				}]
			}

		})
		.state('bankFeesAndChargesUpload-todolist-resubmit', {
			parent: 'dashboard',
			url: '/bankFeesAndChargesUpload-todo-resubmit',
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/bank-fees-and-charges-upload/fee-upload-approval.html',
					controller: 'FeeUploadApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {

						entity: function () {                       	

						},
						systemSettings: ['IntradayMarket', function(IntradayMarket) {
							return IntradayMarket.getCurrentBusinessDate().$promise;
						}],
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('view-bank-fees-upload', null, { reload: 'view-bank-fees-upload' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('bankFeesAndChargesUpload');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})  //
        
		.state('bank-fees-charges-upload-auditLog', {
			parent: 'audit-log',
			url: '/bank-fees-charges-upload-auditLog',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/bank-fees-and-charges-upload/fee-upload-approval.html',
					controller: 'FeeUploadApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {
						entity: function () {                        	
							return {                            		
							};
						},
						systemSettings: function() {
							return null;
						},
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('view-bank-fees-upload', null, { reload: 'view-bank-fees-upload' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('bankFeesAndChargesUpload');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}

		})
		
		.state('bank-fees-charges-upload-historyReport', {
			parent: 'history-report',
			url: '/bank-fees-charges-upload-history',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'global.title'
			},
			onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/entities/bank-fees-and-charges-upload/fee-upload-approval.html',
					controller: 'FeeUploadApprovalController',
					controllerAs: 'vm',
					backdrop: 'static',
					size: 'md',
					resolve: {
						entity: function () {                        	
							return {                            		
							};
						},
						systemSettings: function() {
							return null;
						},
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
					}
				}).result.then(function() {
					$state.go('view-bank-fees-upload', null, { reload: 'view-bank-fees-upload' });
				}, function() {
					$state.go('^');
				});
			}],
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('bankFeesAndChargesUpload');
					$translatePartialLoader.addPart('error');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}

		})


         
    };
    })();
