(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('SODProcess', SODProcess);

    SODProcess.$inject = ['$resource','DateUtils'];

    function SODProcess ($resource, DateUtils) {
        var resourceUrl = 'api/sod-Process';
        return $resource(resourceUrl, {}, {
            
           'getCurrentBusinessDate': {
                method: 'GET',  
                url: 'api/sod-process/system-settings',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            },
           
			/*'getNextValidBusinessDate' : {
				method : 'GET',
				params : {
					
					currentBusinessDate : '@currentBusinessDate'
					
				},
				url : 'api/sod-Process/get-next-system-date',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},*/			
			'processSOD' : {
				method : 'POST',
				params : {
					
					businessDate : '@businessDate',
					status : '@status'
					
				},
				url : 'api/sod-Process/updateProcessStatus',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'logOffUsersForcibly' : {
				method : 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount'
				},
				url : 'api/sod-Process/forcibleLogOff',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'initiateSODInOrderService' :{
				
				method : 'POST',				
				url : 'order/process/sod',
				/*params : {
					
					executionCount : '@executionCount',
					businessDate : '@businessDate',
				},*/
				transformRequest: function (data) {
                   	
                   	var copy =angular.toJson(data);
                   	
                       return copy;
                },
				transformResponse : function(data) {
					if(data!=null && data!=undefined && data !=''){
						if(data.substring(0,1)=='<'){
				
						}else {
							data = angular.fromJson(data);
							return data;
						}
					
					}
				}
				
			},
			'updateSODPRocessLog' : {
				method : 'POST',
				params : {
					
					businessDate : '@businessDate',
				},
				url : 'api/sod-Process/updateProcessStatusLog',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			
			/*'stopSODForcibly' :{
				method : 'POST',
				url : 'api/sod-process/stop-sod-forcibly',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},*/
			'insertProcessLog' :{
				method: 'POST',
				params : {
					
					currentBusinessDate : '@currentBusinessDate',
					processId : '@processId',
					executionCount : '@executionCount',
					errorMessageCode : '@errorMessageCode',
					isSuccess : '@isSuccess'
				},
				url : 'api/sod-process/insert-process-log',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			/*'getSodProcessStatus' :{
				method : 'GET',
				url : 'api/sod-process/get-sod-process-status',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},*/
			'getSodProcessStatusLog' :{
				
				method : 'GET',
				params : {
					
					currentBusinessDate : '@currentBusinessDate'
				},
				url : 'api/sod-process/get-sod-process-status-log',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}
			
            
        });
    }
})();
