(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GSTSettingsEditController', GSTSettingsEditController);

    GSTSettingsEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','GSTSettings','entity','DateUtils','Workflow'];

     function GSTSettingsEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,GSTSettings,entity,DateUtils,Workflow) {
        var vm = this;
        vm.gstSetting=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitGSTSettings = resubmitGSTSettings;
        vm.globalValues = GlobalValues;
        vm.discardGSTSettings = discardGSTSettings;
        vm.rejectGSTSettings= rejectGSTSettings;
        vm.approveGSTSettings= approveGSTSettings;
        vm.viewMode=false;
        vm.decimalsForPercent = vm.globalValues.getNumberOfDecimalsForPercent();
        vm.country = vm.globalValues.getCountry();
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        var systemSettings = vm.globalValues.getSystemSettings();
        
        $scope.$watch('vm.gstSetting.state', function(value){
           	if(value!=null && value!=undefined){
           		vm.stateCode = vm.gstSetting.state.tinNumber;
           	}
           });
      
           
        if(workflowSharedService.viewFrom == 'gstSettings'){
        	vm.gstSetting=entity;
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'gstSettingstodolist_resubmit') {
        	vm.gstSetting =  angular.fromJson($rootScope.payload);
        	
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'gstSettings_todolist_checker') {
        	vm.gstSetting =  angular.fromJson($rootScope.payload); 
        
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'gstSettings_todolist_maker') {
        	vm.gstSetting =  angular.fromJson($rootScope.payload); 
        	
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='gstSettings_auditlog'){
        	vm.gstSetting =  angular.fromJson($rootScope.payload); 
        
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        else if(workflowSharedService.viewFrom=='gst_settings_import_auditlog'){
        	
        	vm.gstSetting =  angular.fromJson($rootScope.payload);   
        	vm.gstSetting = vm.gstSetting.gstSettings;
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	vm.gstSetting.effectiveFrom = DateUtils.convertLocalDateToServer(systemSettings.businessDate);
        	
        	if(vm.gstSetting.cgst==null || vm.gstSetting.cgst==undefined || vm.gstSetting.cgst==""){
        		vm.gstSetting.cgst=0;
        	}
        	if(vm.gstSetting.sgst==null || vm.gstSetting.sgst==undefined || vm.gstSetting.sgst==""){
        		vm.gstSetting.sgst=0;
        	}
        	if(vm.gstSetting.igst == null || vm.gstSetting.igst==undefined || vm.gstSetting.igst==""){
        		vm.gstSetting.igst=0;
        	}
        	if(vm.gstSetting.utgst==null || vm.gstSetting.utgst==undefined || vm.gstSetting.utgst==""){
        		vm.gstSetting.utgst=0;
        	}
        	
        	if (vm.gstSetting.id != null) {
				GSTSettings.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.gstSetting, onSaveSuccess, onSaveError);
			} else {
				GSTSettings.save({
					"orgId" : GlobalValues.getOrgId()
				}, vm.gstSetting, onSaveSuccess, onSaveError);
			}
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitGSTSettings() {
        	
        	vm.gstSetting.effectiveFrom = DateUtils.convertLocalDateToServer(systemSettings.businessDate);
        	
        	if(vm.gstSetting.cgst==null || vm.gstSetting.cgst==undefined || vm.gstSetting.cgst==""){
        		vm.gstSetting.cgst=0;
        	}
        	if(vm.gstSetting.sgst==null || vm.gstSetting.sgst==undefined || vm.gstSetting.sgst==""){
        		vm.gstSetting.sgst=0;
        	}
        	if(vm.gstSetting.igst == null || vm.gstSetting.igst==undefined || vm.gstSetting.igst==""){
        		vm.gstSetting.igst=0;
        	}
        	if(vm.gstSetting.utgst==null || vm.gstSetting.utgst==undefined || vm.gstSetting.utgst==""){
        		vm.gstSetting.utgst=0;
        	}
        	
        	
        	GSTSettings.resubmit({"taskId" : $rootScope.taskID}, vm.gstSetting, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function approveGSTSettings() { 
        	GSTSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectGSTSettings() { Workflow.rejectTask($rootScope.taskID); }

        function discardGSTSettings() {
        	GSTSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
        
        

       
     }   
})();
