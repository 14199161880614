(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('AvailableUsersReport', AvailableUsersReport);

    AvailableUsersReport.$inject = ['$resource','DateUtils'];

    function AvailableUsersReport ($resource, DateUtils) {
        var resourceUrl = 'api/availableusers';
        return $resource(resourceUrl, {}, {
            
            'getHeaderData': {
                method: 'GET',  
                url: 'api/availableusers/all-available-users-count',
               
                isArray: true
            },
            'getBankUsersDetails': {
                method: 'GET',  
                url: 'api/availableusers/bank-users',
                params : {
                	bankId : '@bankId'
					
				},
				isArray: true
            },
            'getBranchUsersCountForBank': {
                method: 'GET',  
                url: 'api/availableusers/branch-users-count-for-bank',
                params : {
                	bankId : '@bankId'
					
				},
                isArray: true
            },'getCustomerCountForBranch': {
                method: 'GET',  
                url: 'api/availableusers/customers-count-for-branch',
                params : {
                	bankId : '@bankId'
					
				},
                isArray: true
            },'getCustomersUsersCount': {
                method: 'GET',  
                url: 'api/availableusers/customer-users-count',
                params : {
                	bankId : '@bankId',
                	branchId: '@branchId'
					
				},
                isArray: true
            },'getCurrentBusinessDate': {
                method: 'GET',  
                url: 'api/eod-process',
                transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            },
            'getExcelData': {
                method: 'POST',  
                url: 'api/availableusers/export/excel',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getCSVData': {
                method: 'POST',  
                url: 'api/availableusers/export/csv',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	
                     return angular.toJson(copy);
                 },
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            }
            ,'getOverAllSummaryData': {
                method: 'GET',  
                url: 'api/availableUsers/overall-summary',
              
                
            },'getBankSummary': {
                method: 'GET',  
                url: 'api/availableUsers/bank-summary',                
				isArray: true
                
            },'getUserDetails': {
                method: 'GET',  
                url: 'api/availableUsers/get-user-details',   
                params : {
                	orgID : '@orgID',
                	entityOwnerType : '@entityOwnerType'
					
				},
				isArray: true
                
            },'getAvailalbeUsersExcelData': {
                method: 'POST',  
                url: 'api/cc-availableusers/export/excel',
                responseType: 'arraybuffer',
               
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            },
            'getAvailalbeUsersCSVData': {
                method: 'POST',  
                url: 'api/cc-availableusers/export/csv',
                responseType: 'arraybuffer',
               
                transformResponse: function(data, headers) {
              	  return {
                        data: data,
                        headers: headers
                    };   
                }         
            }
        });
    }
})();