(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter','GlobalValues'];

    function DateUtils($filter,GlobalValues) {

        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            getMinimumLocalDate : getMinimumLocalDate,
            dateformat: dateformat,
            convertLocalDateFromServerTo12HrsString:convertLocalDateFromServerTo12HrsString,
            getSubtractMonth:getSubtractMonth,
            convertLocalDateTimeToServer:convertLocalDateTimeToServer
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }
        function convertLocalDateTimeToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-ddTHH:mm:ss');
            } else {
                return null;
            }
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }
        function convertLocalDateFromServerUsingMoment(date){
        	if (date) {
                //var dateString = date.split('-');
                return  moment(date, 'YYYY-DD-MM');
            }
        	return null;
        }
        function convertLocalDateFromServerTo12HrsString(date){
        	if (date) {
                //var dateString = date.split('-');
                return  moment(date).format('DD-MMM-YYYY hh:mm:ss A');
            }
        	return null;
        }
        
        function getMinimumLocalDate(date){
        	
        	//get first day of previous month
        	return new Date(date.getFullYear(), date.getMonth() - 1, 1);
        }

        function getSubtractMonth(date,noOfMonths){
            return new Date(date.getFullYear(), date.getMonth() - noOfMonths, 1);
        }
        
        
    }

})();
