(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('CSPLControlService', CSPLControlService);

    CSPLControlService.$inject = ['$resource'];

    function CSPLControlService ($resource) {
        var service = $resource('api/verify-captcha', {}, {
            'verify': { method:'POST'}
        });

        return service;
    }
})();
