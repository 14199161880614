(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'cancelledOrdersReport'
        })
        
        .controller('CancelledOrdersReportController', CancelledOrdersReportController);

        CancelledOrdersReportController.$inject = ['$scope', '$state', 'CancelledOrdersReportService', 'GlobalValues', 'workflowSharedService', 'EntityPermissions', 'ScreenLog','entityConstants','$timeout','IntradayMarket','DateUtils', 'DefaultMarket','StaticLookUp'];

    function CancelledOrdersReportController($scope, $state, CancelledOrdersReportService , GlobalValues, workflowSharedService, EntityPermissions, ScreenLog,entityConstants,$timeout,IntradayMarket,DateUtils, DefaultMarket,StaticLookUp) {

            var vm = this;
            vm.entityConstants = entityConstants;
            vm.screenName = entityConstants.ENTITY_SHORT_NAME.CANCELLEDORDERSREPORT;
            $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
            $scope.page = 1;
            vm.globalValues=GlobalValues;
            DefaultMarket.marketInstruments(function(result){
                vm.instrumentTypes = result;
            });
           
            vm.screenLog = ScreenLog;
            vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
            vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();

            vm.todayDate = new Date();
            vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
            vm.orgId = vm.globalValues.getOrgId();

           
            vm.clicked=false;
            vm.getCancelledOrdersReport = getCancelledOrdersReport;
            vm.ExpandOrCollapsecanCelledOrdersReport=true;

            workflowSharedService.viewFrom = "cancelledOrdersReport";


            logScreenAccess();
            function getCancelledOrdersReport() {
               
            
   vm.bankId = 0;
   vm.branchId = 0;
   vm.customerId= 0;
   vm.instrumentId = 0;
                        if(vm.bank!=null && vm.bank!=undefined){
                                vm.bankId = vm.bank.id;      
                        }


                        if(vm.branch!=null && vm.branch!=undefined){
                                vm.branchId = vm.branch.id;
                            }

                        if(vm.customer!=null && vm.customer!=undefined){
                                vm.customerId = vm.customer.id;
                            }


                        if(vm.instrument!=null && vm.instrument!=undefined){
                            vm.instrumentId = vm.instrument.id;
                        }

                        if(vm.orderStatus!=null && vm.orderStatus!=undefined){
                            vm.orderStatusId= vm.orderStatus.id;
                        }

 CancelledOrdersReportService.getCancelledOrdersReport({"bankId": vm.bankId, "branchId":vm.branchId , "customerId" : vm.customerId ,
       "instrumentId" : vm.instrumentId},function(result) {
   
       	   
   
     
        
        angular.forEach(result, function(value){
            value.revisionTimeStamp=moment(value.revisionTimeStamp,'YYYYMMDDHHmmss.SSSSSSSSS').format();
            
            if(value.expiryTime==0){
                  var time = moment().toDate();
                  time.setHours(0);
                  time.setMinutes(0);
                  time.setSeconds(0);
                  time.setMilliseconds(0);
                  value.expiryTime=time;
              }else{
                  value.expiryTime=moment(value.expiryTime,'YYYYMMDDHHmmss').format();
              }
            
            if(value.buySellFlag.toUpperCase()=='BUYY'){
                value.buySellFlag='Buy';
            }else if(value.buySellFlag.toUpperCase()=='SELL'){
                value.buySellFlag='Sell';
            }
            
            
            
            if(value.timeCondition.toUpperCase()!='GTTM'){
                value.expiryTime=null;
            }
            
            if(value.timeCondition.toUpperCase()=='DAYY'){
                value.timeCondition='DAY';
            }else if(value.timeCondition.toUpperCase()=='GTTM'){
                value.timeCondition='GTT';
            }else if(value.timeCondition.toUpperCase()=='IOCC'){
                value.timeCondition='IOC';
            }
            
            if(value.priceCondition.shortName!='STLS' && value.priceCondition.shortName!='TKPR'){
                value.stopExchangeRate=null;
            }
            
            if(value.lastTradeSequenceNumber==0){
                value.lastTradeSequenceNumber=0;
            }
         });
        
       
        vm.cancelledOrdersReport = result;
        vm.clicked=false;
     });        	
} 
function logScreenAccess(){
    ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
}

function loadCurrentBusinessDate(){
    IntradayMarket.getCurrentBusinessDate(getBusinessDateSuccess, getBusinessDateFailed);
}

function getBusinessDateSuccess(result){
    vm.currentBusinessDate = DateUtils.convertLocalDateFromServer(result.businessDate);
    
    vm.convertedCurrentBusinessDate=DateUtils.convertLocalDateToServer(vm.currentBusinessDate);
    
}

function getBusinessDateFailed(){
    
} 
vm.getSwapPoints = function getSwapPoints(spotExchangeRate, tradeExchangeRate){
    var swapPts =0;
		   if(spotExchangeRate!=0){
			var swapPts = Math.abs(spotExchangeRate - tradeExchangeRate) * 100
			swapPts = (Math.round(parseFloat(swapPts)*100)/100);
			return swapPts;
		   }else{
			return swapPts;
		   }
 }
    





}
})();

