(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GlobalApprovalPolicyDialogController', GlobalApprovalPolicyDialogController);

    GlobalApprovalPolicyDialogController.$inject = ['Workflow', '$scope', '$state', 'entity', 'mode', '$rootScope', '$uibModalInstance', 'workflowSharedService', 'GlobalApprovalPolicy', 'GlobalValues', 'OrganisationManipulation', 'ScreenLog','entityConstants','AlertService'];

    function GlobalApprovalPolicyDialogController(Workflow, $scope, $state, entity, mode, $rootScope,  $uibModalInstance, workflowSharedService, GlobalApprovalPolicy, GlobalValues, OrganisationManipulation, ScreenLog,entityConstants,AlertService) {
        var vm = this;
        vm.save = save;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.GLOBAL_APPROVAL_PROCESS;
        vm.screenLog = ScreenLog;
        vm.approvalPolicyDTO = {};
        vm.ownerOrganisation = {};
        vm.approvalPolicyRolesList = [];
        vm.approvalPolicyRoleIds = [];
        vm.selectedPolicyOrganisationType = null;
        vm.selectedPolicyOrganisationRole = null;
        vm.error = null;
        vm.clicked=false;
                       
        vm.clear = clear;
        vm.approveApprovalPolicy = approveApprovalPolicy;
        vm.rejectApprovalPolicy = rejectApprovalPolicy;
        vm.discardApprovalPolicy = discardApprovalPolicy;
        vm.resubmitApprovalPolicy = resubmitApprovalPolicy;
        vm.addProcessRole = addProcessRole;
        vm.removePolicy = removePolicy;
        vm.validateNoOfApprovals=validateNoOfApprovals;
        
        if(workflowSharedService.viewFrom == 'globalApprovalPolicy'){
	        vm.approvalPolicyDTO = entity;
	        console.log(vm.approvalPolicyDTO);
	        vm.organisationtype = vm.approvalPolicyDTO.organisationType;
	   		vm.customerUserType = vm.approvalPolicyDTO.customerUserType;
	        if (mode == "View"){
	        	//ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': vm.approvalPolicyDTO.id, 'action': 'VIEW'});
	        	showButtonsViewScreen();
	        	vm.ViewDisable = true;
	        }
	        else if (mode == "Edit"){
                //ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': vm.approvalPolicyDTO.id, 'action': 'EDIT'});
	        	showButtonsManipulateScreen();
	        	vm.EditDisable = true;
	        }
	        else{
				//ScreenLog.screenLog({'entityName': 'ApprovalProcess', 'entityRecordId': 0, 'action': 'ADD'});
		        showButtonsManipulateScreen();
	        }
	        
        }
        else if (workflowSharedService.viewFrom == "globalEntityActionApprovalPolicytodolist_checker"){
        	 vm.ViewDisable = true;
	   		 vm.approvalPolicyDTO = entity;
	   		vm.organisationtype = vm.approvalPolicyDTO.organisationType;
	   		vm.customerUserType = vm.approvalPolicyDTO.customerUserType;
			 showButtonsViewScreen();
        }
        else if(workflowSharedService.viewFrom == 'globalApprovalPolicytodolist_checker') {
        	vm.ViewDisable = true;

    		vm.approvalPolicyDTO =  angular.fromJson($rootScope.payload); 
    		vm.organisationtype = vm.approvalPolicyDTO.organisationType;
	   		vm.customerUserType = vm.approvalPolicyDTO.customerUserType;
    		showButtonsViewCheckerScreen();
        }
        else if(workflowSharedService.viewFrom == 'globalApprovalPolicytodolist_maker') {
        	vm.ViewDisable = true;
        	vm.approvalPolicyDTO =  angular.fromJson($rootScope.payload); 
        	vm.organisationtype = vm.approvalPolicyDTO.organisationType;
	   		vm.customerUserType = vm.approvalPolicyDTO.customerUserType;
        	showButtonsViewMakerScreen();
        }
        
        else if(workflowSharedService.viewFrom == 'globalApprovalPolicy_resubmit') {
        	vm.EditDisable = true;
        	vm.approvalPolicyDTO = angular.fromJson($rootScope.payload); 
        	vm.organisationtype = vm.approvalPolicyDTO.organisationType;
	   		vm.customerUserType = vm.approvalPolicyDTO.customerUserType;
            var entityRecordId = 0;
            if(vm.approvalPolicyDTO.id!=null){
  	      		entityRecordId = vm.approvalPolicyDTO.id;
  	      	}          
            vm.screenLog.screenLog(vm.screenName, entityRecordId, vm.entityConstants.ENTITY_ACTION_TYPE.RESUBMIT);
        	showButtonsResubmitScreen();
        }

        vm.ownerOrganisation = OrganisationManipulation.checkAndGetOrganisation(vm.approvalPolicyDTO.organisationType);
        
        if (vm.approvalPolicyDTO.globalApprovalPolicyRoles != null){
//            vm.approvalPolicyRolesList = OrganisationManipulation.checkAndGetGivenObjectListWithOrganisation(vm.approvalPolicyDTO.approvalPolicyRoles);
            vm.approvalPolicyRolesList = vm.approvalPolicyDTO.globalApprovalPolicyRoles;
        	fetchExistingRoleIDs();
        }
        
        function fetchExistingRoleIDs(){
        	vm.approvalPolicyDTO.globalApprovalPolicyRoles.forEach(function(item) {
        		  vm.approvalPolicyRoleIds.push(item.globalOrganisationTypeRole.id);
        	});
        }
        
        function addProcessRole(){
        	var approvalPolicyRoleDTO = {}
        	
        	if (vm.selectedPolicyOrganisationRole!=null && validateDuplicationOfRole() && vm.validateNoOfApprovals()){
	        	vm.approvalPolicyRoleIds.push(vm.selectedPolicyOrganisationRole.id);
	        	approvalPolicyRoleDTO.organisationType =  vm.selectedPolicyOrganisationType;
	        	approvalPolicyRoleDTO.globalOrganisationTypeRole = vm.selectedPolicyOrganisationRole;
	        	approvalPolicyRoleDTO.noOfApprovals=vm.noOfApprovals;
	        	vm.approvalPolicyRolesList.push(approvalPolicyRoleDTO);
	            vm.selectedPolicyOrganisationType = null;
	            vm.selectedPolicyOrganisationRole = null;
	            vm.noOfApprovals="";
        	}
        }
        
        function validateDuplicationOfRole(){
        	vm.error = "";
        	
        	if (vm.approvalPolicyRoleIds.indexOf(vm.selectedPolicyOrganisationRole.id) > -1){
        		AlertService.error( "Role already exists in the process.");
        		return false;
        	}
        	return true;
        }
        
        function validateExistanceOfLevels(){
        	vm.error = "";
        	
        	if (vm.approvalPolicyRoleIds.length == 0){
        		AlertService.error( "Workflow levels should not be empty.");
      
        		return false;
        	}
        	return true;
        }
        
        function removePolicy(rowId){
        	vm.approvalPolicyRoleIds.splice(rowId,1);
        	vm.approvalPolicyRolesList.splice(rowId,1);
        	$scope.approvalPolicyForm.$setDirty();
        }
        
        function showButtonsViewScreen(){
            vm.btnClose = true;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        
        function showButtonsManipulateScreen(){
        	vm.ViewDisable = false;
            vm.btnClose = false;
            vm.btnApproveReject = false;
            $scope.showDiscardButton = false;
            $scope.showSaveButton = true;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = false;
        }
        function showButtonsViewCheckerScreen(){
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        	vm.btnClose = true;
        	$scope.showDiscardButton = false;
        	vm.btnApproveReject = true;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
        function showButtonsResubmitScreen(){
        	vm.btnClose = false;
        	$scope.showButton=true;
        	vm.btnApproveReject = false;
        	$scope.showDiscardButton = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = true;
            $scope.showResubmitButton = true;
        }
        function showButtonsViewMakerScreen(){
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        	vm.btnClose = true;
        	vm.btnApproveReject = false;
        	$scope.showSaveButton = false;
            $scope.showCancelButton = false;
            $scope.showResubmitButton = false;
        }
		function clear () {
            $uibModalInstance.dismiss('cancel');
        }
		
		function save () {	
			if (validateExistanceOfLevels()){
				vm.approvalPolicyDTO.globalApprovalPolicyRoles = vm.approvalPolicyRolesList;
				vm.approvalPolicyDTO.organisationType = vm.organisationtype;
				vm.approvalPolicyDTO.customerUserType = vm.customerUserType;
				var customerUserTypeId = 0;
				if(vm.customerUserType!=null && vm.customerUserType!=undefined){
					customerUserTypeId = vm.customerUserType.id;
				}
				if (mode == "Edit"){
					GlobalApprovalPolicy.update({"orgTypeId":vm.organisationtype.id,"custUserTypeId":customerUserTypeId}, vm.approvalPolicyDTO, onSaveSuccess, onSaveError);
				}
				else {
					GlobalApprovalPolicy.save({"orgTypeId":vm.organisationtype.id,"custUserTypeId":customerUserTypeId},vm.approvalPolicyDTO, onSaveSuccess, onSaveError);
				}
			}
        }
     
        function onSaveSuccess (result) {
            $uibModalInstance.close(result);  
        }

        function onSaveError () {
        	vm.clicked=false;
        }
        function approveApprovalPolicy() { 
        	GlobalApprovalPolicy.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	vm.clicked=false;
        }
       
        function rejectApprovalPolicy() { Workflow.rejectTask($rootScope.taskID);}

        function discardApprovalPolicy() {
        	GlobalApprovalPolicy.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {

        }
        function resubmitApprovalPolicy() {
			if (validateExistanceOfLevels()){
				
				GlobalApprovalPolicy.resubmit({"taskId" : $rootScope.taskID}, getActualApprovalPolicyDTO(), onResubmitSuccess, onResubmitError);
	          	$uibModalInstance.dismiss('cancel');
			}
          }
          
          function onResubmitSuccess(result) {
          	$rootScope.removeRow($rootScope.taskID);
          }        
          function onResubmitError(error) { 
        	  vm.clicked=false;
          }
          
          function getActualApprovalPolicyDTO(){
        	  
			vm.approvalPolicyDTO.organisationType = vm.organisationtype;
			vm.approvalPolicyDTO.customerUserType = vm.customerUserType;
			vm.approvalPolicyDTO.globalApprovalPolicyRoles = vm.approvalPolicyRolesList;	
			/* Since no OrganisationDTO in the actual DTO class for GlobalApprovalPolicy.java ignoring hte two lines*/
        	//vm.approvalPolicyDTO.organisationType = OrganisationManipulation.checkAndGetOrganisationDTO(vm.ownerOrganisation);
        	//vm.approvalPolicyDTO.globalApprovalPolicyRoles = OrganisationManipulation.checkAndGetGivenObjectListWithOrganisationDTO(vm.approvalPolicyRolesList);
        	  
        	  return vm.approvalPolicyDTO;
          }
          function validateNoOfApprovals(){
          	vm.error = "";
          	
          	if (vm.noOfApprovals =="" || vm.noOfApprovals ==null|| vm.noOfApprovals >5 || vm.noOfApprovals < 1){
          		AlertService.error(  "No of Approvals should be 1 to 5.");
          		//vm.error = "No of Approvals should be 1 to 5."
          		return false;
          	}
          	return true;
          }
        
    }
    
})();
