(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('PendingLimitRequestsViewService', PendingLimitRequestsViewService);

    PendingLimitRequestsViewService.$inject = ['$resource'];

    function PendingLimitRequestsViewService($resource) {
        var resourceUrl =  'api/pending-limit-request-view/:id';
        
        return $resource(resourceUrl, {}, {
        	
        	'getPendingLimitRequestsView':{
        		isArray:true,
				method: 'GET',
				params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'},
				url: 'api/pending-limit-requests-view'
        	},
            'exportPendingLimitRequestsExcel':{
                method: 'POST',
                url: 'api/pending-limit-requests-view/export-excel',
                params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'},
                responseType: 'arraybuffer',
                 transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'exportPendingLimitRequestsCsv':{
                method: 'POST',
                url: 'api/pending-limit-requests-view/export-csv',
                params: {bankId : '@bankId', branchId : '@branchId', customerId : '@customerId'},
                responseType: 'arraybuffer',
                 transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            }
        });
    }
   })();
