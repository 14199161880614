(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('DefaultMarkupSettingsViewController', DefaultMarkupSettingsViewController);

    DefaultMarkupSettingsViewController.$inject = ['$scope', '$state','$rootScope','workflowSharedService','$uibModalInstance','DefaultMarkup','entity', 'Workflow', 'GlobalValues', 'ScreenLog','StaticLookUp','markupTypes', 'roEdApplicabilityMethods' ];

    function DefaultMarkupSettingsViewController($scope, $state, $rootScope, workflowSharedService, $uibModalInstance,DefaultMarkup,entity, Workflow, GlobalValues, ScreenLog, StaticLookUp,markupTypes,roEdApplicabilityMethods) {
        var vm = this;
        vm.clear=clear;
        vm.approveDefaultMarkup = approveDefaultMarkup;
        vm.rejectDefaultMarkup = rejectDefaultMarkup;
        vm.discardDefaultMarkup = discardDefaultMarkup;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        $scope.clicked=false;
        vm.globalValues = GlobalValues;
        vm.markupTypes = markupTypes;
        
        //get RO/ED applicability method list lookup
        // StaticLookUp.getRoEdApplicabilityMethodList(function(result){
        //     vm.roEdApplicabilityMethodList = result;
        // });
        vm.roEdApplicabilityMethodList = roEdApplicabilityMethods;

        vm.hideRoEdApplicability = false;
        
        $scope.$watch('vm.markupType', function(value){
        	if(value!=null && value!=undefined){
        		if(vm.markupType.shortName=='Percentage'){
        			vm.noOfDecimals="3";
        		}else{
        			vm.noOfDecimals="2";
        		}
        	}
        });
        
        if(workflowSharedService.viewFrom == 'defaultMarkup'){
        	vm.defaultMarkup = entity;    
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarkuptodolist_checker') {
        	vm.defaultMarkup =  angular.fromJson($rootScope.payload); 
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarkuptodolist_maker') {
        	vm.defaultMarkup =  angular.fromJson($rootScope.payload); 
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        else if(workflowSharedService.viewFrom=='defaultMarkup_auditlog'){
        	vm.defaultMarkup =  angular.fromJson($rootScope.payload);   
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                vm.hideRoEdApplicability = true;
                //var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                //vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

        	$scope.showButton=false;

        }
        
        function approveDefaultMarkup() { 
        	DefaultMarkup.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectDefaultMarkup() { Workflow.rejectTask($rootScope.taskID); }

        function discardDefaultMarkup() {
        	DefaultMarkup.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }

       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }   
})();
