(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('ClearcorpTACEditController', ClearcorpTACEditController);

	ClearcorpTACEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','ClearcorpTACUpload','entity','DateUtils','Workflow','FileUtil','globalValue','TacViewForEntitiesUtility','systemSettings','AlertService','$filter','configurationSetting'];

	function ClearcorpTACEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,ClearcorpTACUpload,entity,DateUtils,Workflow,FileUtil,globalValue,TacViewForEntitiesUtility,systemSettings,AlertService,$filter,configurationSetting) {
		var vm = this;
		vm.clearCorpTermsAndCondtionsUploadDTO=entity;   

		vm.clear=clear;
		vm.save=save;
		vm.clicked=false;
		$scope.showButton = false;
		$scope.showApproveRejectButton=false;
		$scope.showDiscardButton = false;
		$scope.showResubmitButton=false;
		vm.resubmitTAC = resubmitTAC;
		vm.globalValues = GlobalValues;
		vm.discardTAC = discardTAC;
		vm.rejectTAC = rejectTAC;
		vm.approveTAC = approveTAC;
		vm.viewMode=false;
		vm.checkTACFile=false;
		vm.configSettings = globalValue;
		vm.maxFileNameLength = (vm.configSettings.uploadedFileNameLength != null && vm.configSettings.uploadedFileNameLength != undefined && vm.configSettings.uploadedFileNameLength != '') ?vm.configSettings.uploadedFileNameLength : 150 ;
		var minFileSizeinBytes = 0;
		var maxFileSizeinBytes = 500;
		vm.maximumFileSizeinKB = (configurationSetting != null && configurationSetting != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != null && configurationSetting.maximumFileSizeTermsAndConditionsUpload != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != '') ? parseInt(configurationSetting.maximumFileSizeTermsAndConditionsUpload) : 500 ;
		vm.minimumFileSizeInKB = 0;
		if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
			minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
		}
		if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){

			maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
			if(vm.maximumFileSizeinKB == 1024){
				vm.showInKB = false;
				vm.tacFileUploadSize = 1;
			}else{
				vm.showInKB = true;
				vm.tacFileUploadSize = vm.maximumFileSizeinKB;
			}
		}
		var Extensions = "pdf";
		vm.minDate = new Date();
		vm.minDate.setDate(new Date().getDate() + 1);

		$scope.showButton = false;
		vm.orgId = vm.globalValues.getOrgId();
		vm.configurationSettings = vm.globalValues.getConfigSettings();
		if(systemSettings != null && systemSettings != undefined){
			vm.businessDate  = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
			//vm.minDate = vm.minDate.vm.businessDate + 1;
			var daysAdded = 1;
			if(vm.configurationSettings.intervalNoOfDaysToConfigureTACEnforcementDate != null && vm.configurationSettings.intervalNoOfDaysToConfigureTACEnforcementDate != undefined){
				daysAdded = parseInt(vm.configurationSettings.intervalNoOfDaysToConfigureTACEnforcementDate);
			}
			vm.businessDatePlusOne =  moment(vm.businessDate).add(daysAdded,'days').toDate();
			vm.minDate = angular.copy(vm.businessDatePlusOne);
		}


		if(workflowSharedService.viewFrom == 'tacUpload'){
			vm.clearCorpTermsAndCondtionsUploadDTO=entity;
			vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate = vm.minDate;
			$scope.showButton = true;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;
			vm.viewMode=false;
		}
		else if(workflowSharedService.viewFrom == 'termsConditionUploadtodolist_resubmit') {
			vm.clearCorpTermsAndCondtionsUploadDTO =  angular.copy(angular.fromJson($rootScope.payload));
			vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate = DateUtils.convertLocalDateFromServer(vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.enforcingDate);

			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=true;
			vm.viewMode=false;
		}
		else if(workflowSharedService.viewFrom == 'termsConditionUpload_todolist_checker') {
			vm.clearCorpTermsAndCondtionsUploadDTO =  angular.fromJson($rootScope.payload); 
			vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate =DateUtils.convertLocalDateFromServer( vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.enforcingDate);
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = true;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;
		}
		else if(workflowSharedService.viewFrom == 'termsConditionUpload_todolist_maker') {
			vm.clearCorpTermsAndCondtionsUploadDTO =  angular.fromJson($rootScope.payload); 
			vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate =DateUtils.convertLocalDateFromServer( vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.enforcingDate);
			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = true;
			$scope.showResubmitButton=false;
		}
		else if(workflowSharedService.viewFrom=='termsConditionUpload_auditlog'){
			vm.clearCorpTermsAndCondtionsUploadDTO =  angular.fromJson($rootScope.payload); 
			vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate =DateUtils.convertLocalDateFromServer( vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.enforcingDate);			vm.viewMode=true;
			$scope.showButton = false;
			$scope.showApproveRejectButton = false;
			$scope.showDiscardButton = false;
			$scope.showResubmitButton=false;

		}
		function base64ToArrayBuffer(base64) {
			var binary_string =  window.atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array( len );
			for (var i = 0; i < len; i++)        {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		}

		vm.openTACPreview = openTACPreview;
		function openTACPreview(tacAgreedDetail){
			 /*var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			  var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
			var contentByteArray = base64ToArrayBuffer(pdfContent);
			var file = new Blob([contentByteArray], {type: 'application/pdf'});
			$scope.fileURL = URL.createObjectURL(file);
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(file, "TermsAndConditions.pdf");
			}
			else {
				var win =  window.open($scope.fileURL, "_blank", "width=700,height=700,"+"top="+y+", left="+x);  
				
			}*/
			TacViewForEntitiesUtility.previewTAC(tacAgreedDetail.fileContent, tacAgreedDetail.fileName);
		}



		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function save () {   
			//check if the terms and condition is not null before saving
			if(moment(vm.minDate).isAfter(DateUtils.convertLocalDateToServer(vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate))){
				vm.clicked = false;
				AlertService.error($filter('translate')('gatewayApp.clearCorpTACUpload.enforcingDateGreater'));
				
			}
			else{
				if(vm.termsCondition != null){
					var fd1 = new FormData();
					vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.tacFiles.fileName = vm.termsCondition.name;
					vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.enforcingDate = DateUtils.convertLocalDateToServer(vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate);
					fd1.append('dto', JSON.stringify(vm.clearCorpTermsAndCondtionsUploadDTO));
					fd1.append('file', vm.termsCondition);
					ClearcorpTACUpload.update(fd1, onSaveSuccess, onSaveError);
				}
			}
		}


		function onSaveSuccess (result) {
			$uibModalInstance.close(result);  
		}


		function onSaveError () {

			vm.clicked = false;
		}
		function resubmitTAC() {
			if(moment(vm.minDate).isAfter(DateUtils.convertLocalDateToServer(vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate))){
				vm.clicked = false;
				AlertService.error($filter('translate')('gatewayApp.clearCorpTACUpload.enforcingDateGreater'));
				
			}
			else{

				if(vm.termsCondition != null){
					var fd1 = new FormData();
					vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.tacFiles.fileName = vm.termsCondition.name;
					vm.clearCorpTermsAndCondtionsUploadDTO.tacMaster.enforcingDate = DateUtils.convertLocalDateToServer(vm.clearCorpTermsAndCondtionsUploadDTO.enforcingDate);
					fd1.append('id', JSON.stringify($rootScope.taskID));
					fd1.append('dto', JSON.stringify(vm.clearCorpTermsAndCondtionsUploadDTO));
					fd1.append('file', vm.termsCondition);
					ClearcorpTACUpload.resubmit( fd1, onResubmitSuccess, onResubmitError);


				}
			}

		}

		function onResubmitSuccess(result) {
			$rootScope.removeRow($rootScope.taskID);
			$uibModalInstance.dismiss('cancel');
		}        
		function onResubmitError(error) {
			vm.clicked = false;
		}

		function approveTAC() { 
			ClearcorpTACUpload.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
			   	
		}

		function onApproveSuccess(result) {
			$uibModalInstance.dismiss('cancel');  
			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onApproveError(error) {
			vm.clicked = false;
		}

		function rejectTAC() { Workflow.rejectTask($rootScope.taskID); }

		function discardTAC() {
			ClearcorpTACUpload.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
			$uibModalInstance.dismiss('cancel');	
		}

		function onDiscardSuccess(result) {

			$rootScope.removeRow($rootScope.taskID);        	
		}        
		function onDiscardError(error) {

		}



		//validate terms and condition 
		$scope.$watch('vm.termsCondition', function(value){
			if(value!= undefined && value!=null){
				if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
					vm.invalidTermsConditionSize = true;
					if(vm.showInKB == true){
						vm.invalidTermsConditionSixeText = "Maximum of " + vm.tacFileUploadSize +" KB file size is allowed";
					}else{
						vm.invalidTermsConditionSixeText = "Maximum of " + vm.tacFileUploadSize +" MB file size is allowed";
					}
					vm.invalidTermsCondition = false;
					vm.invalidTermsConditionName = false;
					vm.termsCondition=null;
					angular.element("input[type='file']").val(null);

				}else{
					vm.inputTermsCondition=vm.termsCondition.name;
					vm.invalidTermsConditionSize = false;
					vm.invalidTermsCondition = false;
					vm.invalidTermsConditionName = false;
					vm.fileExtension = vm.inputTermsCondition.substring(vm.termsCondition.name.lastIndexOf(".") + 1);
					var fileNameWithoutExtension  =  vm.inputTermsCondition.substring(0,vm.termsCondition.name.lastIndexOf("."));

					//check for extension
					if(!(Extensions == (vm.fileExtension.toLowerCase()))) {
						angular.element("input[type='file']").val(null);
						vm.termsCondition=null;
						vm.inputTermsCondition=null;
						vm.invalidTermsCondition = true;
						vm.invalidTermsConditionSize = false;
						vm.invalidTermsConditionName = false;
						vm.invalidTermsConditionText = "Accepted file format .pdf";
					}
					else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
	    				 angular.element("input[type='file']").val(null);
	    				 vm.termsCondition=null;
				         vm.inputTermsCondition=null;
				         vm.invalidTermsCondition = false;
				         vm.invalidTermsConditionSize = false;
				         vm.invalidTermsConditionName = true;
				         vm.invalidTermsConditionNameText = "Uploaded pdf file name should not exceed " + vm.maxFileNameLength+ " charaters.";
				         
	    			 }

					else{ // check for malicious file with correct extension
						var reader = new FileReader();
						reader.readAsArrayBuffer(value.slice(0,4));
						reader.onloadend = function(e){
							if (e.target.readyState === FileReader.DONE) {
								var arrayBuffer = e.target.result;
								var bytes = new Uint8Array( arrayBuffer );
								if(!FileUtil.checkTermsConditionExtension(bytes)){
									angular.element("input[type='file']").val(null);
									vm.termsCondition=null;
									vm.inputTermsCondition=null;
									vm.invalidTermsCondition = true;
									vm.invalidTermsConditionSize = false;
									vm.invalidTermsConditionName = false;
									vm.invalidTermsConditionText = "Accepted file format .pdf";
								}
								else{
									vm.invalidTermsConditionSize = false;
									vm.invalidTermsCondition =false;
									vm.invalidTermsConditionName = false;


								}
								$scope.$digest();
							}
						}; 
					}
				}
			}
			else{
				angular.element("input[type='file']").val(null);

				vm.inputTermsCondition=null;
			}
		});


	}   
})();
