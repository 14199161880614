(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('SpecialHolidays', SpecialHolidays);

    SpecialHolidays.$inject = ['$resource'];

    function SpecialHolidays ($resource) {
        var resourceUrl =  'api/special-holidays/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },           
           	
            'save': {
                method: 'POST',
                params: {orgId:'@orgId'},
                url: 'api/special-holidays/:orgId',
                transformRequest: function (data) {
                	var copy = angular.copy(data);
                    return angular.toJson(copy);
                }                
            },
            'update': {
            	method:'PUT',
            	params: {orgId:'@orgId'},
                url: 'api/special-holidays/:orgId',
                transformRequest: function (data) {
                   	var copy = angular.copy(data);
                   	
                       return angular.toJson(copy);
                   }
            },
            'delete':{
                method: 'DELETE',
                params: {orgId:'@orgId', id:'@id'},
                url: 'api/special-holidays/:orgId/:id'    
                },
         
            'approve': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/approve'
            },
            'reject': {
                method: 'GET',
                params: {taskId:'@taskId'},
                url: 'api/tasks/:taskId/reject'
            },
            'discard': {
              	 method: 'GET',
                   params: {taskId:'@taskId'},
                   url: 'api/tasks/:taskId/discard'
             },
             'resubmit': {
                 method: 'PUT',
                 params: {taskId:'@taskId'},
                 url: 'api/tasks/:taskId/resubmit/special-holidays',
                 transformRequest: function (data) {
                 	var copy = angular.copy(data);
                 	return angular.toJson(copy);
                 }
             }
            
            
              });
    }
})();

            
