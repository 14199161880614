(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('FAQService', FAQService);

    FAQService.$inject = ['$resource'];

    function FAQService ($resource) {
    	 var resourceUrl =  'api/file/:buId/:type/download/:fileName';
         
         return $resource(resourceUrl, {}, {
        	 'downloadVideo': {
               	 method: 'GET',
                 params: {type:'@type',subfolder:'@subfolder',fileName:'@fileName',fileType:'@fileType'},
                 url: 'api/file/:type/:subfolder/download/:fileName/:fileType'
              },
         	 'downloadTemplate': {
               	 method: 'GET',
                 params: {type:'@type',subfolder:'@subfolder',fileName:'@fileName',fileType:'@fileType'},
                 url: 'api/file/:type/:subfolder/downloadtemplate/:fileName/:fileType'
              },
              'downloadJSONFile': {
               	 method: 'GET', isArray: true,
                  params: {type:'@type',fileName:'@fileName',fileType:'@fileType'},
                  url: 'api/file/:type/downloadJSONFile/:fileName/:fileType',
                  transformResponse: function (data) {
                          if (data) {
                              data = angular.fromJson(data);
                          }
                          return data;
                      }
               },
               	'uploadJSONFile': {
                	 method: 'POST',
                   params: {type:'@type',fileName:'@fileName',fileType:'@fileType'},
                   url: 'api/file/:type/uploadJSONFile/:fileName/:fileType'
                },
                'uploadCommonFiles': {
                	headers: {'Content-Type': undefined},	
                	method: 'POST', 
                	isArray: true,
                    params: {type:'@type',subfolder:'@subfolder'},
                    url: 'api/files/:type/:subfolder/uploadgivensubfolder'	
                },
                'deleteFiles': {
                    method: 'POST',
                    params: {type:'@type',subfolder:'@subfolder',fileName:'@fileName',fileType:'@fileType'},
                    url: 'api/file/:type/:subfolder/deleteFiles/:fileName/:fileType'
                 
                 }
            
        });
    }
})();
