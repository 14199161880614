(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Market', Market);

    Market.$inject = ['$resource'];

    function Market ($resource) {
        var resourceUrl =  'api/market/:id';

        return $resource(resourceUrl, {}, {
            'getMarketDepth': {
                method: 'GET',
                isArray: true,
                url: 'api/market-depth-summary',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLTPForLimits': {
                method: 'GET',
                isArray: true,
                url: 'api/ltp-for-limits',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLTPForLimitsForInstrument': {
                method: 'GET',
                url: 'api/instrument/:instrument/ltp-for-limits',
                params: {instrument: '@instrument'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getDailyClosingPrice': {
                method: 'GET',
                isArray: true,
                url: 'api/daily-closing-price',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
			'placeOrder' : {
				method : 'POST',
				url : 'order/placeorderrequest',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
           	
			'modifyOrder' : {
				method : 'POST',
				url : 'order/modifyorderrequest',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
           	
			'cancelOrder' : {
				method : 'POST',
				url : 'order/cancelorderrequest',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'cancelOrderList' : {
				method : 'POST',
				url : 'order/cancelorderrequestlist',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'cancelOrderListWithSingleObject' : {
				method : 'POST',
				url : 'order/cancelorderrequestlistwithlistobject',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
            'cancelOrderListWithSingleObjectForClearCorp' : {
				method : 'POST',
				url : 'order/cancelorderrequestlistwithlistobjectforclearcorp',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
            'cancelAllPendingOrdersForClearCorp' : {
				method : 'POST',
				url : 'order/cancelAllPendingOrdersForClearCorp',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
            'getMarketSessionSettings': {
                method: 'GET',
                url: 'api/market-session-settings',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },'getCurrentDateTime': {

				method : 'GET',				
				url : 'api/current-date-time',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
            }
        });
    }
})();
