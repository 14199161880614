(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ObjectUtils', ObjectUtils);

    ObjectUtils.$inject = ['StringUtils', 'ArrayUtils'];

    function ObjectUtils(StringUtils, ArrayUtils) {

        return {
            isInvalid: isInvalid,
            isEqual: isEqual,
            removeProperty: removeProperty,
            getFieldDescription: getFieldDescription,
            getObjectFromDescription: getObjectFromDescription,
            isObject: isObject,
            valueOf: valueOf,
            isNotNull: isNotNull,
            isEmpty: isEmpty,
            getKeys: getKeys,
            getValue: getValue,
            hasKeys: hasKeys
        };

        /**
         * Check If object is Invalid
         * @param   object          Test object
         * @return  true or false   Boolean
         */
        function isInvalid(object) {
            return object == undefined || object == null || !angular.isObject(object);
        }

        function removeProperty(object, property) {
            if (!isInvalid(object) && !StringUtils.isBlank(property) && object.hasOwnProperty(property)) {
                delete object[property];
            }
        }

        /**
         * Check If Two objects are equal by value
         * @param  baseObject       Base Object to compare with
         * @param  destinyObject    Destiny Object for comparison
         * @return {Boolean}        Returns Equality
         */
        function isEqual(baseObject, destinyObject) {
            // Create arrays of property names     
            var baseObjectProperties = _.keys(baseObject);
            var destinyObjectProperties = _.keys(destinyObject);

            // If count of properties is different, objects are not equivalent     
            if (baseObjectProperties.length != destinyObjectProperties.length) {
                return false;
            }

            for (var index = 0; index < baseObjectProperties.length; index++) {
                var property = baseObjectProperties[index];
                // If values of same property are not equal, objects are not equivalent         
                if (!_.isEqual(baseObject[property], destinyObject[property])) {
                    return false;
                }
            }
            // If we made it this far, objects are considered equivalent     
            return true;
        }


        function getFieldDescription(object, rowSeparator, columnSeparator) {
            var description = "";
            var keyValuePairs = [];
            var keys = _.keys(object);
            for (var index = 0; index < keys.length; index++) {
                if (!StringUtils.isBlank(object[keys[index]])) {
                    keyValuePairs.push(keys[index] + columnSeparator + object[keys[index]]);
                }
            }
            if (!ArrayUtils.isEmpty(keyValuePairs)) {
                description = keyValuePairs.join(rowSeparator);
            }
            return description;
        }


        function getObjectFromDescription(description, rowSeparator, columnSeparator) {
            var object = {};

            if (!StringUtils.isBlank(description)) {
                var rows = description.split(rowSeparator);

                if (!ArrayUtils.isEmpty(rows)) {
                    for (var index = 0; index < rows.length; index++) {
                        var keyValuePair = rows[index].split(columnSeparator);
                        object[keyValuePair[0]] = keyValuePair[1];
                    }
                }
            }

            return object;
        }

        function isObject(object) {
            return angular.isObject(object);
        }

        function valueOf(object, objectPath) {
            var returnValue = '';
            if (isObject(object) && !isInvalid(object) && !StringUtils.isBlank(objectPath)) {
                returnValue = _.get(object, objectPath, '');
            } else if (StringUtils.isString(object)) {
                returnValue = object;
            }
            return returnValue;
        }

        function isNotNull(object) {
            return (object != undefined && object != null);
        }

        function isEmpty(object) {
            if (isNotNull(object)) {
                for (var key in object) {
                    if (object.hasOwnProperty(key)) {
                        return false;
                    }
                }
            }
            return true;
        }

        function getKeys(object) {
            return _.keys(object);
        }

        function getValue(object, path, defaultValue) {
            var returnValue = angular.copy(defaultValue);
            if(!isInvalid(object) && !StringUtils.isBlank(path)) {
                returnValue = _.get(object, path, defaultValue);
            }

            if(returnValue == undefined || returnValue == null) {
                returnValue = angular.copy(defaultValue);
            }
            return returnValue;
        }

        function hasKeys(object) {
            return !ArrayUtils.isEmpty(getKeys(object));
        }
    }
})();
