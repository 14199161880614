(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('DefaultMarkupSettingsEditController', DefaultMarkupSettingsEditController);

    DefaultMarkupSettingsEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','DefaultMarkup','entity','StaticLookUp', 'markupTypes', 'roEdApplicabilityMethods'];

     function DefaultMarkupSettingsEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,DefaultMarkup,entity,StaticLookUp, markupTypes, roEdApplicabilityMethods) {
        var vm = this;
        vm.defaultMarkup=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        vm.resubmitDefaultMarkup = resubmitDefaultMarkup;
        vm.globalValues = GlobalValues;
        vm.markupTypes = markupTypes;
        
        $scope.showButton = false;
        vm.disableTax=false;

        //get RO/ED applicability method list lookup
        // StaticLookUp.getRoEdApplicabilityMethodList(function(result){
        //     vm.roEdApplicabilityMethodList = result;
        // });
        vm.roEdApplicabilityMethodList = roEdApplicabilityMethods;
        
        $scope.$watch('vm.markupType', function(value){
        	if(value!=null && value!=undefined){
        		if(vm.markupType.shortName=='Percentage'){
        			vm.noOfDecimals="3";
        			document.getElementById("txtMarkupValue2").value = "";
        			vm.defaultMarkup.markupValue = null;
        		}else{
        			vm.noOfDecimals="2";
        			document.getElementById("txtMarkupValue1").value = "";
        			vm.defaultMarkup.markupValue = null;
        		}
        	}
        });
        
        $scope.$watch('vm.defaultMarkup.markupValue', function(value){
        	if(value==null || value==undefined || value==""){
        		
        		vm.disableTax=true;
        		document.getElementById("txtTaxOnMarkup").value = "";
        		vm.defaultMarkup.taxOnMarkup=null;
        	}else{
        		vm.disableTax=false;
        	}
        });
       
                
        if(workflowSharedService.viewFrom == 'defaultMarkup'){
        	vm.defaultMarkup=entity;

        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
        	
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarkuptodolist_resubmit') {
        	vm.defaultMarkup =  angular.fromJson($rootScope.payload);
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

        	vm.disableForm = false;
        	$scope.showButton=true;
        }
        else if(workflowSharedService.viewFrom == 'defaultMarkup_resubmit_delete') {
        	vm.defaultMarkup = angular.fromJson($rootScope.payload); 
        	angular.forEach(vm.markupTypes, function(value){
        		if(value.shortName==vm.defaultMarkup.markupType){
            		vm.markupType = value; 
        		}
        	});
            if(vm.defaultMarkup.roEdApplicabilityMethod!=null && vm.defaultMarkup.roEdApplicabilityMethod!=undefined){
                vm.roEdApplicabilityMethod = vm.defaultMarkup.roEdApplicabilityMethod;
            }else{
                var defaultROEDApplicabilityMethod = _.filter(angular.copy(vm.roEdApplicabilityMethodList), { 'shortName': 'BOTHLEGS' });
                vm.roEdApplicabilityMethod = defaultROEDApplicabilityMethod[0];
            }

        	vm.disableForm = true;
        	$scope.showButton=true;
        }
		
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	vm.defaultMarkup.markupType = vm.markupType.shortName;
            vm.defaultMarkup.roEdApplicabilityMethod = vm.roEdApplicabilityMethod;
        	DefaultMarkup.update({ "orgId": vm.globalValues.getOrgId() },vm.defaultMarkup, onSaveSuccess, onSaveError);
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitDefaultMarkup() {
        	vm.defaultMarkup.roEdApplicabilityMethod = vm.roEdApplicabilityMethod;
        	DefaultMarkup.resubmit({"taskId" : $rootScope.taskID}, vm.defaultMarkup, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }

       
     }   
})();
