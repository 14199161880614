(function() {
	'use strict';
	angular.module('gatewayApp').factory('CreditFactorSettings', CreditFactorSettings);

	CreditFactorSettings.$inject = [ '$resource' ];

	function CreditFactorSettings($resource) {
		var resourceUrl = 'api/credit-factor-settings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				params : {
					orgId : '@orgId'
				},
				url: 'api/credit-factor-settings-for-bank/:orgId/orgId',
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			'getCreditFactorsForCustomer' : {
				method : 'GET',
				params : {
					orgId : '@orgId'
				},
				url: 'api/credit-factor-settings-for-customer/:customerId/customer',
				isArray: true
			},
			'update' : {
				method : 'PUT',
				params : {
					orgId : '@orgId'
				},
				url : 'api/credit-factor-settings/orgId/:orgId',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
            'getCreditFactorExcel':{
                method:'POST',
                url: 'api/credit-factor-settings/excel',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
            'getCreditFactorCSV':{
                method:'POST',
                url: 'api/credit-factor-settings/csv',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function(data, headers) {
                    return {
                        data: data,
                        headers: headers
                    };   
                } 
            },
			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},
			
			'resubmit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmit-credit-factor-settings',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			},
			'getActiveCreditSettings' : {
				method:'GET',
				isArray:true,
	            url: 'api/credit-factor-settings-view'    
			}
		

		});
	}
})();
