(function() {
	'use strict';
	angular.module('gatewayApp').factory('SMSSettings', SMSSettings);

	SMSSettings.$inject = [ '$resource' ];

	function SMSSettings($resource) {
		var resourceUrl = 'api/sms-settings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
				
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},

			'update' : {
				method : 'PUT',
				params : {
					orgId : '@orgId'
				},
				url : 'api/sms-settings/:orgId',
				transformRequest : function(data) {
					var copy = angular.copy(data);

					return angular.toJson(copy);
				}
			},
			'approve' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/approve'
			},
			'reject' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/reject'
			},
			'discard' : {
				method : 'GET',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/discard'
			},
			'resubmit' : {
				method : 'PUT',
				params : {
					taskId : '@taskId'
				},
				url : 'api/tasks/:taskId/resubmit/smsSetting',
				transformRequest : function(data) {
					var copy = angular.copy(data);
					return angular.toJson(copy);
				}
			}
			
			

		});
	}
})();
