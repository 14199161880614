(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ExpiryTimeController', ExpiryTimeController);

    ExpiryTimeController.$inject = ['$scope', '$rootScope', '$state',  '$uibModalInstance', '$filter', '$stateParams'];

    function ExpiryTimeController($scope, $rootScope, $state, $uibModalInstance, $filter, $stateParams) {
        var vm = this;
        vm.clear=clear;
        vm.add=add;
        $scope.clicked=false;
        vm.closeTime = $stateParams.closeTime;
        fillDefaultTime();
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        
        function add(){
        	//validateExpiryTime();
        	if(!validateExpirtyTime()){
        		
        	}else{
            	formatExpiryTime();
            	$rootScope.expiryTime = vm.expiryTime;
            	$uibModalInstance.close();
            	$scope.$emit('addExpiryTime');
        	}
        }
        
        function fillDefaultTime(){
        	vm.currentDate = new Date();
        	vm.expiryDateTime = addMinutes(vm.currentDate, 1);
        }
        
        function addMinutes(date, minutes) {
            return new Date(date.getTime() + minutes*60000);
        }
        
        function validateExpirtyTime(){
        	vm.currentDate = new Date();
        	vm.currentTime = moment(vm.currentDate, 'HH:mm').format( 'HH:mm');
        	
        	vm.expiryTime = moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
        	
        	if(vm.expiryTime==null || vm.expiryTime==undefined || vm.expiryTime==''){
        		vm.errorSting = 'Invalid expiry time';
        		return false;
        	}
        	
        	if(vm.expiryTime.split(':')[0] < vm.currentTime.split(':')[0]){
        		vm.errorString = 'Expiry time should be greater than current time.';
        		return false;
        	}else if(vm.expiryTime.split(':')[1] < vm.currentTime.split(':')[1]){
        		vm.errorString = 'Expiry time should be greater than current time.';
        		return false;
        	}
        	
        	if(vm.expiryTime.split(':')[0] < vm.currentTime.split(':')[0]){
        		vm.errorString = 'Expiry time should be greater than current time.';
        		return false;
        	}else if(vm.expiryTime.split(':')[1] < vm.currentTime.split(':')[1]){
        		vm.errorString = 'Expiry time should be greater than current time.';
        		return false;
        	}
        	return true;
        }
        
        function formatExpiryTime(){
        	vm.expiryTime = moment(vm.expiryDateTime, 'HH:mm').format( 'HH:mm');
        	var expiryTimeInString = "";
        	if(vm.expiryTime.split(':').length>1){
            	expiryTimeInString = vm.expiryTime.split(':')[0] + vm.expiryTime.split(':')[1] + '00';
            	var todayDateString = $filter('date')(new Date(),'yyyy-mm-dd');
            	todayDateString = todayDateString.replace('-', '');
            	expiryTimeInString = todayDateString + expiryTimeInString;
        	}
        	vm.expiryTime = expiryTimeInString;
        }
    }   
})();
