(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('AuditLogController', AuditLogController);

    AuditLogController.$inject = ['$scope', '$state', 'workflowSharedService','$rootScope','MessageBox','AuditLog', 'GlobalValues','EntityPermissions','$filter', 'entity','DateUtils','ScreenLog','entityConstants','AlertService'];

    function AuditLogController($scope, $state, workflowSharedService, $rootScope, MessageBox, AuditLog, GlobalValues, EntityPermissions,$filter, entity,DateUtils,ScreenLog,entityConstants,AlertService) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.getAuditLogDetails=getAuditLogDetails;
        vm.todayDate=new Date();
        vm.Values=Values;
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.userListValue={};
        vm.ExpandOrCollapseAuditLog = true;
        vm.entityConstants = entityConstants;
        vm.screenLog=ScreenLog;
        vm.showReportParams = showReportParams;
        vm.valid=false;
		vm.clicked=false;
        vm.historicalOrCurrent = 'Current';
        
		vm.reportDate  = DateUtils.convertDateTimeFromServer(vm.todayDate);
		vm.yesterdayDate = angular.copy(vm.reportDate);
		vm.yesterdayDate.setDate(vm.yesterdayDate.getDate() - 1);
		vm.fromDate = angular.copy(vm.reportDate);
		vm.fromDate.setDate(vm.fromDate.getDate() -1);
	    vm.toDate = angular.copy(vm.reportDate);
	    vm.toDate.setDate(vm.toDate.getDate() -1);
	    vm.orgId = null;

        /* $scope.$watch('vm.fromDate', function(newValue, oldValue){
        	if(newValue!=null && newValue!=undefined){
        		if(!moment(newValue).isAfter(oldValue,'day') && !moment(newValue).isBefore(oldValue,'day')){
            		if(moment(newValue).isAfter(vm.todayDate,'day')){
            			AlertService.error($filter('translate')('gatewayApp.AuditLog.futureFromDate'));
            			vm.valid=false;
            		}
        		}
        	}
        })*/
        
        /*$scope.$watch('vm.fromDate',function(value){
        	if(value!=null && value!=undefined){
        		if(moment(value).isAfter(vm.todayDate,'day')){
        			AlertService.error($filter('translate')('gatewayApp.AuditLog.futureFromDate'));
        			vm.valid=false;
        		}
        }
        })*/
        
        /*
         * Get details for audit log given login name, screen, from and to date
         */
        logScreenAccess();
        function getAuditLogDetails()
        {   
        	
        	console.log(vm.selectedEntityOwnerType);
        	setOrganisationIdForSlectedEntity();
        	vm.auditLogDetails = [];
    		if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.yesterdayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.AuditLog.futureFromDate'));
    			vm.valid=false;
    		}
    		else if(vm.historicalOrCurrent != 'Current' && moment(vm.fromDate).isAfter(vm.toDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.AuditLog.greaterFromDate'));
    			vm.valid=false;
    		}else if(vm.historicalOrCurrent != 'Current' && moment(vm.toDate).isAfter(vm.yesterdayDate,'day')){
    			AlertService.error($filter('translate')('gatewayApp.AuditLog.greaterToDate'));
    			vm.valid=false;
    		}
    		else{
    			vm.valid=true;
				vm.clicked=true;
    			var from=$filter('date')(vm.fromDate, "yyyy-MM-dd");
	        	var to=$filter('date')(vm.toDate, "yyyy-MM-dd");
	        	var entityId = 0;

            	if(vm.screenName!=undefined && vm.screenName!=null && vm.screenName!=''){
            		entityId = vm.screenName.id;
            	}
            	
            	if(vm.historicalOrCurrent == 'Current'){
	    			from=$filter('date')(vm.todayDate, "yyyy-MM-dd");
	        		to=$filter('date')(vm.todayDate, "yyyy-MM-dd");
                	AuditLog.getAuditLogDetails({"entityId":entityId,"login":vm.userListValue,"from":from,"to":to,"orgId":vm.orgId},onGetAuditLogDetailsSuccess,onGetAuditLogDetailsError);
            	}else{
                	AuditLog.getAuditLogDetailsFromArchive({"entityId":entityId,"login":vm.userListValue,"from":from,"to":to,"orgId":vm.orgId},onGetAuditLogDetailsSuccess,onGetAuditLogDetailsError);
            	}
            	
    		}
        }
        
        function setOrganisationIdForSlectedEntity(){
        	if(vm.userEntityOwnerType == vm.selectedEntityOwnerType){
        		vm.orgId = vm.globalValues.getOrgId(); 
        		console.log("logged in user org id "  + vm.orgId);
        	}else{
	        	if(vm.userEntityOwnerType == 'Clearcorp'){
	        	
		        	 if( vm.selectedEntityOwnerType =='Bank' || vm.selectedEntityOwnerType == 'Branch'){
		        		vm.orgId = vm.bankValue.id; 
		        		console.log("selected bank or branch org id "  + vm.orgId);
		        	}else if( vm.selectedEntityOwnerType =='SingleUserCustomer' || vm.selectedEntityOwnerType == 'MultiUserCustomer'){
		        		vm.orgId = vm.customerValue.id; 
		        		console.log("selected customer id "  + vm.orgId);
		        	}
	        	}else if(vm.userEntityOwnerType == 'Bank'){
	        		
	        		if(vm.selectedEntityOwnerType == 'Branch'){
		        		vm.orgId = vm.globalValues.getOrgId(); 
		        		console.log("logged in user org id "  + vm.orgId);
	        		}else if( vm.selectedEntityOwnerType =='SingleUserCustomer' || vm.selectedEntityOwnerType == 'MultiUserCustomer'){
		        		vm.orgId = vm.customerValue.id; 
		        		console.log("selected customer id "  + vm.orgId);
		        	}
	        	}else if(vm.userEntityOwnerType == 'Branch'){
	        		
	        		if( vm.selectedEntityOwnerType =='SingleUserCustomer' || vm.selectedEntityOwnerType == 'MultiUserCustomer'){
		        		vm.orgId = vm.customerValue.id; 
		        		console.log("selected customer id "  + vm.orgId);
		        	}
	        	}
	        	
        	}
        }
        //vm.fromDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        //vm.toDate = DateUtils.convertDateTimeFromServer(vm.todayDate);
        
        function onGetAuditLogDetailsSuccess(result)
        {
        	
        	vm.auditLogDetails=result;
        	vm.clicked=false;
        }
        
        
        function onGetAuditLogDetailsError()
        {
        	vm.clicked=false;
        }
        
        /*
         * Load the screen by passing entity and payload
         */
        
        function Values(entity,payload,actionName){
            $rootScope.payload = payload;
            $rootScope.actionName = actionName;
            workflowSharedService.viewFrom = getViewFrom(entity,actionName);
            var state = getState(entity,actionName);
            $state.go(state);
            
            
        	
        }     
        
        function getViewFrom(entity,actionName){
        	if (entity == 'SecurityPolicy'){
        		return 'securityPolicy_auditLog';
        	}
        	
        	else if (entity == 'GlobalSecurityPolicy'){
        		return 'globalSecurityPolicy_auditLog';
        	}
        	
        	else if (entity == 'EntityStateApprovalProcess'){
        		return 'entityActionApprovalPolicy_auditLog';
        	}
        	
        	
        	else if (entity == 'ApprovalProcess'){
        		return 'approvalPolicy_auditLog';
        	}
        	
        	
        	else if (entity == 'OrganisationRole'){
        		return 'role_auditLog';
        	}
        	
        	
        	else if (entity == 'UserRole' ){
        		return 'userroletodolist_auditLog';
        	}
        	

        	else if (entity == 'UserManagement') {
        		return 'userRole_auditLog';
        	}
        	
          	else if (entity == 'BankRegistration'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else{
        			return 'bankregistration_auditlog';
        		}
        	}
        	
          	else if (entity == 'UserAdminApplicationWideSettings'){
        		return 'userAdminApplicationWideSettings_auditlog';
        	}

			else if (entity == 'PendingOrderReport'){
        		return 'messageuploadtodolist_auditLog';
        	}
        	
          	else if (entity == 'DealingAdminApplicationWideSettings'){
        		return 'dealingAdminApplicationWideSettings_auditlog';
        	}
        	
        	else if (entity == 'BranchRegistration'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else{
        			return 'branch_auditLog';
        		}
        	}
        	
        	else if (entity == 'AlertConfiguration'){
        		return 'alertConfiguration_auditLog';
        	}

        	
        	
        	else if (entity == 'SMSSettings'){
        		return 'smssettings_auditlog';
        	}
        	else if(entity=='CustomerRegistration'){
        		return 'customerregistrationtodolist_auditlog'
        	}
        	else if(entity=='Customer'){
        		return 'customerregistrationtodolist_auditlog'
        	}
        	else if (entity == 'DefaultMarkupSettings'){
        		return 'defaultMarkup_auditlog';
        	}
        	else if (entity == 'MarkupSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'markup_import_auditlog';
        		}else{
        			return 'markup_auditLog';
        		}
        		
        	}
        	else if (entity == 'HEConnectivitySettings'){
        		return 'heConnectivitySettings_auditlog';
        	}
        	else if (entity=='IntradayMarketSessionSettings'){
        		return 'intradayMarketSessionSettings_auditlog' ;
        	}
        	else if (entity=='DefaultMarketSessionSettings'){
        		return 'defaultMarketSessionSettings_auditlog';
        	}
        	else if (entity=='EmailSettings'){
        		return 'emailSettings_auditlog';
        	}
        	else if (entity=='UserRestriction'){
        		return 'userRestriction_auditLog';
        	}
        	else if (entity=='SwapQuotes'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}        		
        		return 'swapQuotes_auditLog';
        	}
          	else if (entity=='SpecialHoliday'){
        		return 'specialHoliday_auditLog';
        	}
          	else if (entity=='RegularHolidays'){
        		return 'regularHoliday_auditLog';
        	}
          	else if (entity=='LimitSettings'){
        		
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'limit_import_auditlog';
        		}else{
        			return 'limitSettings_auditLog';
        		}
          		
        	}
          	else if (entity=='CustomerLimitChangeRequest'){
          	
          		if(actionName.indexOf('CREATE') >= 0 && (vm.userEntityOwnerType == 'SingleUserCustomer' || vm.userEntityOwnerType =='MultiUserCustomer') )
          			return 'create_customer_limitSettings_auditLog';
          		else
          			return 'customer_limitSettings_auditLog';
        	}
          	else if (entity=='EntityRestriction'){
        		return 'entityRestriction_auditLog';
        	}
          	else if (entity == 'AMCSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'amc_import_auditlog';
        		}else{
        			return 'amcSettings_auditlog';
        		}
        		
        	}
          	else if (entity == 'CustomerCharges'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'customer_charges_import_auditlog';
        		}else{
        			return 'customerCharges_auditlog';
        		}
        		
        	}
          	else if (entity == 'GSTSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'messageuploadtodolist_auditLog';
        		}else if(actionName=='ADD CONFIRM-IMPORT'){
        			return 'gst_settings_import_auditlog';
        		}else{
        			return 'gstSettings_auditlog';
        		}
        		
        	}
          	else if(entity == 'CustomerSpecificSettings'){
          		return 'customerSpecificSettings_auditlog';
          	}

          	else if (entity=='TransactionChargesSettings'){
				if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT'){
					return 'messageuploadtodolist_auditLog';
				}else{
	        		return 'transactionChargesSettings_auditlog';
				}	
        	}

            else if (entity=='CreditFactorSettings'){
                return 'creditFactorSettings_auditlog';
            }

          	else if (entity=='HomeBranchIFSCUpload'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT'|| actionName == 'ADD CONFIRM - IMPORT'){
          			return 'messageuploadtodolist_auditLog';
          		}
          	}
          	else if (entity=='ModifyCustomerDetails'){
        		//modifycustomerdetails_auditlog
        		return 'modifycustomerdetails_auditlog';
        	}
          	else if (entity=='TermsAndConditionsUpload'){
        		return 'termsConditionUpload_auditlog';
        	}
          	else if (entity=='TermsAndConditionsView'){
        		
        		return 'entited_tac_view_auditLog';
        	}
          	else if (entity=='CustomerTermsAndConditionsUpload'){
        		return 'customerTermsConditionUploadByBank_auditlog';
        	}
          	else if (entity=='ExposureType'){
        		return 'exposureType_auditlog';
        	}
          	else if (entity=='ExposureSubType'){
        		return 'exposureSubType_auditlog';
        	}
          	else if (entity=='BankFeesChargesUpload'){
        		return 'bankFeesAndChargesUpload_auditlog';
        	}
        }
        
        function getState(entity,actionName){
        	if (entity == 'SecurityPolicy'){
        		return 'security-policy-auditLog';
        	}
        	else if (entity == 'GlobalSecurityPolicy'){
        		return 'global-security-policy-auditLog';
        	}
        	
        	else if (entity == 'EntityStateApprovalProcess'){
        		return 'entity-action-approval-policy-auditLog';
        	}
        	
        	
        	else if (entity == 'ApprovalProcess' ){
        		return 'approval-policy-auditLog';
        	}
        	
        	
        	else if (entity == 'OrganisationRole'){
        		return 'manage-role-auditLog';
        	}
        	
        	
        	else if (entity == 'UserRole' ){
        		return 'manage-users-role-auditLog';
        	}
        	
        	else if(entity=='CustomerSpecificSettings')
        		{
        			return 'change-settings-auditlog'
        		}
        	
        	else if (entity == 'UserManagement'){
        		return 'usermanagement-auditLog';
        	}
        	
         	else if (entity == 'BankRegistration' ){
         		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}else{
        			return 'bank-registration-auditLog';
        		}
        	}
        	
        	
         	else if (entity == 'DealingAdminApplicationWideSettings' ){
        		return 'dealing-admin-change-config-auditlog';
        	}
        	
         	else if (entity == 'UserAdminApplicationWideSettings' ){
        		return 'change-config-auditlog';
        	}
        	
        	else if (entity == 'BranchRegistration' ){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}else{
        			return 'branch-auditLog';
        		}
        	}
        	
        	else if (entity == 'AlertConfiguration'){
        		return 'alert-configuration-auditLog';
        	}
        	else if (entity == 'SMSSettings' ){
        		return 'sms-settings-auditLog';
        	}
        	else if(entity=='CustomerRegistration'){
				var customerRegistration =  angular.fromJson($rootScope.payload);
                    if( customerRegistration.primaryContactName != "" ){
                        return 'old-customer-registration-auditLog';
                    }else{
						return 'customer-registration-auditLog';
					}
        		
        	}
        	else if(entity=='Customer'){
        		return 'customer-info-status-view-auditLog';
        	}
        	else if (entity=='DefaultMarkupSettings'){
        		return 'default-markup-auditLog';
        	}
        	else if (entity=='MarkupSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}else{
        			return 'markup-auditLog';
        		}
        		
        	}
        	else if(entity=='HEConnectivitySettings'){
        		return 'he-connectiviy-settings-auditLog';
        	}
        	else if(entity=='IntradayMarketSessionSettings'){
        		return 'intraday-market-settings-auditLog';
        	}
        	else if (entity=='DefaultMarketSessionSettings'){
        		return 'default-market-settings-auditLog';
        	}
        	else if (entity=='EmailSettings'){
        		return 'email-auditLog';
        	}
        	else if (entity=='UserRestriction'){
        		return 'userRestriction-auditLog';
        	}
        	else if (entity=='SwapQuotes'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName=='REJECT - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}
        		var swapQuotesForTenorDatesDTO =  angular.fromJson($rootScope.payload);
        		if(swapQuotesForTenorDatesDTO.swapQuotesForTenorDatesList == undefined ){
        			return 'old-swapQuotes-auditLog';
        		}
        		return 'swapQuotes-auditLog';
        	}
          	else if (entity=='SpecialHoliday'){
        		return 'specialHoliday-auditLog';
        	}
          	else if (entity=='RegularHolidays'){
        		return 'regularHoliday-auditLog';
        	}
          	else if (entity=='LimitSettings'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT' || actionName=='REJECT - IMPORT'){
          			return 'upload-todolist-auditLog';
          		}else{
                    var limitSettingsHeaderDTO =  angular.fromJson($rootScope.payload);
                    if(limitSettingsHeaderDTO.limitInstrumentGroup == undefined ){
                        return 'old-limit-settings-auditLog';
                    }
          			return 'limit-settings-auditLog';
          		}
        		
        	}
          	else if (entity=='CustomerLimitChangeRequest'){
          		          			
          		if(actionName.indexOf('CREATE') >= 0 && (vm.userEntityOwnerType == 'SingleUserCustomer' || vm.userEntityOwnerType =='MultiUserCustomer') )
          			return 'create-customer-limit-settings-auditLog';
          		else
                    var customerLimitSettingsDTO =  angular.fromJson($rootScope.payload);
                    if(customerLimitSettingsDTO.limitSettingsHeader.limitInstrumentGroup == undefined ){
                        return 'old-customer-limit-settings-auditLog';
                    }
          			return 'customer-limit-settings-auditLog';
        	}
          	else if (entity=='EntityRestriction'){
        		return 'manage-Entity-Restrictions-auditLog';
        	}
          	else if (entity=='AMCSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}else{
        			return 'amc-auditLog';
        		}
        		
        	}
          	else if (entity=='CustomerCharges'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}else{
        			return 'customer-charges-auditLog';
        		}
        		
        	}
        	else if (entity=='GSTSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}else{
        			return 'gst-settings-auditLog';
        		}
        		
        	}
        	
        	else if (entity=='TransactionChargesSettings'){
        		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT'){
        			return 'upload-todolist-auditLog';
        		}
        		else{
        			return 'transaction-charges-settings-auditLog';
        		}
        	}

            else if (entity=='CreditFactorSettings'){
                return 'credit-factor-settings-auditLog';
            }

        	else if (entity=='HomeBranchIFSCUpload'){
          		if(actionName=='DISCARD - IMPORT' || actionName=='PENDING APPROVAL - IMPORT'|| actionName == 'REJECT - IMPORT' || actionName == 'ADD CONFIRM - IMPORT'){
          			return 'upload-todolist-auditLog';
          		}
          	}
        	else if (entity=='ModifyCustomerDetails'){
        		//modifycustomerdetails_auditlog
        		return 'modify-customer-details-auditLog';
        	}
        	else if (entity=='TermsAndConditionsUpload'){
        		return 'terms-condition-upload-auditLog';
        	}
        	else if (entity=='TermsAndConditionsView'){
        		
        		return 'entities-tac-view-auditLog';
        	}
        	else if (entity=='CustomerTermsAndConditionsUpload'){
        		return 'customer-tac-upload-by-bank-auditLog';
        	}
        	else if (entity=='ExposureType'){
        		return 'exposure-type-auditLog';
        	}
        	else if (entity=='ExposureSubType'){
        		return 'exposure-sub-type-auditLog';
        	}
        	else if (entity=='BankFeesChargesUpload'){
        		return 'bank-fees-charges-upload-auditLog';
        	}
			else if (entity == 'PendingOrderReport'){
        		return 'upload-todolist-auditLog';
        	}
        	
        }
        function showReportParams(queryString){
        	
    		vm.paramList = {};   		
    	
    		queryString = queryString.replace("{" , "");
    		queryString = queryString.replace("}" , "");
    		queryString = queryString.replace(/'/g , "");
    		
    		var splittedArray = queryString.split(';');
    			
    		angular.forEach(splittedArray, function(item) {
    			var simpleArray = item.split(':');
    			if(simpleArray!=undefined && simpleArray!=null && simpleArray.length==2
    					&& simpleArray[0]!=undefined && simpleArray[0]!=null && simpleArray[1]!=undefined && simpleArray[1]!=null){
    				var key="";
    				var value="";
    				key = simpleArray[0].trim();
    				value= simpleArray[1].trim();
    				vm.paramList[key] = value;
    			}
    		});
    		$state.params = {paramMap:vm.paramList};
    		$state.go('audit-log-param-view');
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( entityConstants.ENTITY_SHORT_NAME.AUDIT_LOG_REPORT,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
     }
})();
