(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('workflow-rejection', {
            parent: 'dashboard',
            url: '/workflow-rejection',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$rootScope', '$uibModal', function($stateParams, $state, $rootScope, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/components/workflow/rejection_reason.html',
                    controller: 'WorkflowRejectionReasonController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
 
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('workflow-customer-rejection', {
            parent: 'customer-todo',
            url: '/workflow-rejection',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$rootScope', '$uibModal', function($stateParams, $state, $rootScope, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/components/workflow/rejection_reason.html',
                    controller: 'WorkflowRejectionReasonController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
 
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();
