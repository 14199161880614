(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AMCSettingsEditController', AMCSettingsEditController);

    AMCSettingsEditController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','$uibModalInstance','AMCSettings','entity','DateUtils','Workflow'];

     function AMCSettingsEditController($scope, $state, $rootScope, GlobalValues, workflowSharedService, $uibModalInstance,AMCSettings,entity,DateUtils,Workflow) {
        var vm = this;
        vm.amcSetting=entity;   
        
        vm.clear=clear;
        vm.save=save;
        vm.clicked=false;
        $scope.showButton = false;
        $scope.showApproveRejectButton=false;
        $scope.showDiscardButton = false;
    	$scope.showResubmitButton=false;
        vm.resubmitAMC = resubmitAMC;
        vm.globalValues = GlobalValues;
        vm.discardAMC = discardAMC;
        vm.rejectAMC= rejectAMC;
        vm.approveAMC= approveAMC;
        vm.viewMode=false;
        vm.showEffectiveFrom=false;
        vm.financialYear = vm.globalValues.getFinancialYear().description;
        vm.noOfDecimals = vm.globalValues.getNumberOfDecimalsForAmount();
        
        $scope.showButton = false;
        vm.orgId = vm.globalValues.getOrgId();
        
        $scope.$watch('vm.bank', function(value){
           	if(value!=null && value!=undefined){
           		vm.effectiveFrom = new Date(vm.bank.dateOfJoining);
           		vm.showEffectiveFrom = true;
           	}
           });
       
           
        if(workflowSharedService.viewFrom == 'amcSettings'){
        	vm.amcSetting=entity;
        	vm.bank = vm.amcSetting.bank;
        	$scope.showButton = true;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'amcSettingtodolist_resubmit') {
        	vm.amcSetting =  angular.fromJson($rootScope.payload);
        	vm.bank = vm.amcSetting.bank;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=true;
        	vm.viewMode=false;
        }
        else if(workflowSharedService.viewFrom == 'amcSettings_todolist_checker') {
        	vm.amcSetting =  angular.fromJson($rootScope.payload); 
        	vm.bank = vm.amcSetting.bank;
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = true;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom == 'amcSettings_todolist_maker') {
        	vm.amcSetting =  angular.fromJson($rootScope.payload); 
        	vm.bank = vm.amcSetting.bank;
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = true;
        	$scope.showResubmitButton=false;
        }
        else if(workflowSharedService.viewFrom=='amcSettings_auditlog'){
        	vm.amcSetting =  angular.fromJson($rootScope.payload); 
        	vm.bank = vm.amcSetting.bank;
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
        else if(workflowSharedService.viewFrom=='amc_import_auditlog'){
        	
        	vm.amcSetting =  angular.fromJson($rootScope.payload);   
        	
        	vm.effectiveFrom = new Date(vm.amcSetting.amcSettings.bank.dateOfJoining);
        	vm.bank=vm.amcSetting.amcSettings.bank;
        	
        	vm.amcSetting.charges = vm.amcSetting.amcSettings.charges;
        	vm.viewMode=true;
        	$scope.showButton = false;
        	$scope.showApproveRejectButton = false;
        	$scope.showDiscardButton = false;
        	$scope.showResubmitButton=false;

        }
		

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {   
        	
        	vm.amcSetting.effectiveFrom = DateUtils.convertLocalDateToServer(vm.effectiveFrom);
        	vm.amcSetting.financialYear = vm.globalValues.getFinancialYear();
        	vm.amcSetting.bank = vm.bank;
        	
        	if (vm.amcSetting.id != null) {
				AMCSettings.update({
					"orgId" : GlobalValues.getOrgId()
				}, vm.amcSetting, onSaveSuccess, onSaveError);
			} else {
				AMCSettings.save({
					"orgId" : GlobalValues.getOrgId()
				}, vm.amcSetting, onSaveSuccess, onSaveError);
			}
        	
         }
      
       
        function onSaveSuccess (result) {
        	 $uibModalInstance.close(result);  
        }
        
      
        function onSaveError () {
        	
        	vm.clicked = false;
        }
        function resubmitAMC() {
        	
        	vm.amcSetting.effectiveFrom = DateUtils.convertLocalDateToServer(vm.effectiveFrom);
        	vm.amcSetting.financialYear = vm.globalValues.getFinancialYear();
        	vm.amcSetting.bank = vm.bank;
        	
        	AMCSettings.resubmit({"taskId" : $rootScope.taskID}, vm.amcSetting, onResubmitSuccess, onResubmitError);
        	
       		
        	$uibModalInstance.dismiss('cancel');
        }
        
        function onResubmitSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);
        }        
        function onResubmitError(error) {
        	vm.clicked = false;
        }
        
        function approveAMC() { 
        	AMCSettings.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	
        }
       
        function rejectAMC() { Workflow.rejectTask($rootScope.taskID); }

        function discardAMC() {
        	AMCSettings.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
        
        

       
     }   
})();
