(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
    	 $stateProvider.state('otp-confirmation-withdraw-request', {
             parent: 'customer-onboarding-status-view',
             url: '/otp-confirmation-withdraw-request/{mobile}/{mobileCountryCode}/{requestId}/{email}/{theme}',
             data: {
                 authorities: []
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                 $uibModal.open({
                     templateUrl: 'app/entities/otp-confirm/otp-confirm.html',
                     controller: 'OtpConfirmController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     size: 'sm',
                     resolve: {
                    	 globalValue: ['StaticLookUp', function(StaticLookUp) {
                             return StaticLookUp.getConfigSettingsPreLogin().$promise;
                         }],
                    	 mode: function(){ return "customeronboardingwithdrawrequest"; }
                     }
                 }).result.then(function() {
                     $state.go('^', {}, { reload: true });
                 }, function() {
                     $state.go('^');
                 });
             }]
             
         })
         .state('otp-confirmation-withdraw-reg-request', {
             parent: 'registrationRequestStatus',
             url: '/otp-confirmation-withdraw-reg-regrequest/{mobile}/{mobileCountryCode}/{requestId}/{email}/{theme}',
             data: {
                 authorities: []
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                 $uibModal.open({
                     templateUrl: 'app/entities/otp-confirm/otp-confirm.html',
                     controller: 'OtpConfirmController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     size: 'sm',
                     resolve: {
                    	 globalValue: ['StaticLookUp', function(StaticLookUp) {
                             return StaticLookUp.getConfigSettingsPreLogin().$promise;
                         }],
                    	 mode: function(){ return "customeronboardingwithdrawregrequest"; }
                     }
                 }).result.then(function() {
                     $state.go('^', {}, { reload: true });
                 }, function() {
                     $state.go('^');
                 });
             }]
             
         })
         $stateProvider.state('otp-confirmation-fetch-reg-request', {
             parent: 'registrationRequestStatus',
             url: '/otp-confirmation-fetch-reg-request/{theme}',
             params: {refNo: null},
             data: {
                 authorities: []
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                 $uibModal.open({
                     templateUrl: 'app/entities/otp-confirm/otp-confirm.html',
                     controller: 'OtpConfirmController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     size: 'sm',
                     resolve: {
                         globalValue: ['StaticLookUp', function(StaticLookUp) {
                             return StaticLookUp.getConfigSettingsPreLogin().$promise;
                         }],
                         mode: function(){ return "customeronboardingfetchregrequest"; }
                     }
                 }).result.then(function(result) {
                     $state.go('customer-registration-status-view', {id: result.activationKey, theme: 'light'});
                 }, function() {
                     $state.go('^');
                 });
             }]
         })
         $stateProvider.state('otp-confirmation-fetch-request', {
             parent: 'customerOnboardingStatus',
             url: '/otp-confirmation-fetch-request/{theme}',
             params: {refNo: null},
             data: {
                 authorities: []
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                 $uibModal.open({
                     templateUrl: 'app/entities/otp-confirm/otp-confirm.html',
                     controller: 'OtpConfirmController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     size: 'sm',
                     resolve: {
                         globalValue: ['StaticLookUp', function(StaticLookUp) {
                             return StaticLookUp.getConfigSettingsPreLogin().$promise;
                         }],
                         mode: function(){ return "customeronboardingfetchrequest"; }
                     }
                 }).result.then(function(result) {
                     $state.go('customer-onboarding-status-view', {id: result.activationKey, theme: 'light'});
                 }, function() {
                     $state.go('^');
                 });
             }]
         })
    }
})();