(function() {
    'use strict';

    var jhiAlert = {
        template: '<div class="alerts" style="word-wrap:break-word;" ng-cloak="" role="alert">' +
                        '<div ng-repeat="alert in $ctrl.alerts" style="word-wrap:break-word;" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                            '<uib-alert ng-cloak="" type="{{alert.type}}" class="alert-directive"  ><span style="word-wrap:break-word" ng-bind-html="alert.msg"></span></uib-alert>' +
                        '</div>' +
                  '</div>',
        controller: jhiAlertController
    };

    angular
        .module('gatewayApp')
        .component('nmbAlert', jhiAlert);

    jhiAlertController.$inject = ['$scope', 'AlertService'];

    function jhiAlertController($scope, AlertService) {
        var vm = this;

        vm.alerts = AlertService.get();
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });
    }
})();
