(function () {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('CCAvailableUsersReportController', CCAvailableUsersReportController);

	CCAvailableUsersReportController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues',  'AlertService','AvailableUsersReport','$filter', 'ScreenLog','entityConstants','DateUtils','$timeout'];

	function CCAvailableUsersReportController(  $scope, $state, $rootScope, GlobalValues,AlertService , AvailableUsersReport , $filter , ScreenLog, entityConstants,DateUtils,$timeout){
		
		var vm = this;
		vm.globalValues = GlobalValues;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.NO_OF_AVAILABLE_USER_REPORT;
		vm.entityConstants = entityConstants;
		vm.screenLog = ScreenLog;
		$scope.refresh = refresh;

		vm.currentDateTime =moment().format( 'DD_MMM_YYYY_HH_mm');
        
        vm.selectedEntityType = '';        
        vm.showUserDetailsDrillDown = false;
        vm.showBankDetails = false;
  		vm.showBranchDetails = false;
  		vm.showBankSummary = false;
  		vm.showCustomerDetails = false;
  		vm.userSummaryFilterText = '';
  		vm.bankSummaryfilterText = '';
  		
		//initiate list
  		vm.overAllSummary = {};
        vm.userDetailsList =[];
        vm.bankSummaryList  =[];

		var recordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
		$scope.noOfRecordsPerPage = recordsPerPage;
		$scope.page = 1;
		$scope.noOfRecordsPerUSerPage = recordsPerPage;
		$scope.userPage = 1;
		

		vm.getUserDetails = getUserDetails;
		vm.getBankSummary = getBankSummary;
		
		//vm.getUsersLoggedInForTheMoment = getUsersLoggedInForTheMoment;
	   // vm.getUsersLoggedInForTheDay = getUsersLoggedInForTheDay;
		vm.YES = true;
		vm.NO = false;
		vm.isAsOfNow = true;
		vm.BANK_TYPE= "Bank";
	    vm.BRANCH_TYPE= "Branch";
	    vm.CUSTOMER_TYPE= "Customer";
        vm.CLEARCORP = "Clearcorp";
	
		vm.exportToFile = exportToFile;

		vm.todayDate = new Date();
		vm.reportDate = DateUtils.convertDateTimeFromServer(vm.todayDate);

		logScreenAccess();
		loadHeader();

		function loadHeader(){
			
			resetGridsForHeaderSelection();

			AvailableUsersReport.getOverAllSummaryData( reportHeaderSuccess, reportHeaderFailure );
		}
		function reportHeaderSuccess(result){
			vm.overAllSummary = result;
		}
		function reportHeaderFailure(error){

		}
		function resetGridsForHeaderSelection(){   		
   	   
			vm.bankSummaryList  = [];
			vm.userDetailsList  = [];
			vm.showUserDetailsDrillDown = false;
		    vm.showBankDetails = false;
		    vm.showBranchDetails = false;
		    vm.showBankSummary = false;
		  	vm.showCustomerDetails = false;
		  	vm.userSummaryFilterText = '';
		  	vm.bankSummaryfilterText = '';   		
   	   }
		function resetGridsBankSelection(){  
			
			vm.userDetailsList  = [];
			vm.showUserDetailsDrillDown = false;
		    vm.showBankDetails = false;
		    vm.showBranchDetails = false;
		  	vm.showCustomerDetails = false;
		  	vm.userSummaryFilterText = '';
   		
   	   }
		function userDetailsSuccess(result){
    		vm.userDetailsList = result;
    		angular.element("#userSearch").focus();
    	}
    	function userDetailsFailure(error){
    		
    	}
    	
    	function getBankSummary(){
    		//resetGridsBankSelection();
    		AvailableUsersReport.getBankSummary( getBankSummarySuccess, getBankSummaryFailure );
    	}
    	function getBankSummarySuccess(result){

    		vm.filterData = result;
    		vm.bankSummaryList = result;
    		vm.bankSummaryfilterText = '';
    		vm.showBankSummary = true;
    		vm.showUserDetailsDrillDown = false;
    		calculateBankHeaderTotal(result);
    	}
    	function getBankSummaryFailure(){
    		
    	}
    	
    	function calculateBankHeaderTotal(list){
     		
    		vm.totalBankUsers = 0;     		
     		angular.forEach(list, function(overallCountForbank) {
     			
     			vm.totalBankUsers = vm.totalBankUsers + overallCountForbank.bankUsers;
         		
     		});
     		
     	}    	
    	function getUserDetails(bankId, bankName, entityType, drillDownGridType){
    		setSelectionDetails(bankId, bankName, entityType,drillDownGridType);    		
    		AvailableUsersReport.getUserDetails({ 'entityOwnerType' : entityType , 'orgID' : bankId} ,userDetailsSuccess, userDetailsFailure)
        	    		
    	}
    
    	function setSelectionDetails(bankId, bankName, entityType,drillDownGridType){
   		 
    	    vm.selectedEntityType = entityType;
    	    vm.userSummaryFilterText = '';
    	    setColumDisplayForUserDetails(bankId, bankName, drillDownGridType);
    	}
    	function setColumDisplayForUserDetails(bankId, bankName, drillDownGridType){
    		vm.showUserDetailsDrillDown = true;
    		vm.showBankDetails = false;
    		vm.showBranchDetails = false;
    		vm.showCustomerDetails = false;
    		
    		
    		if(drillDownGridType ==  vm.BANK_TYPE ){    	
    			
        		vm.showBankDetails = true;
        		vm.userDetailsHeader = "Bank Users"
        		
    		}else if(drillDownGridType == vm.BRANCH_TYPE ){
    			vm.showBankSummary = false;
    			vm.showBranchDetails = true;
    			vm.userDetailsHeader = "Branch Users"
    			
    		}else if(drillDownGridType == vm.CUSTOMER_TYPE ){
    			vm.showBankSummary = false;
    			vm.showCustomerDetails = true;
    			vm.userDetailsHeader = "Customer Users"
    			
    		}else if(drillDownGridType == vm.CLEARCORP ){
    			vm.showBankSummary = false;
    			vm.userDetailsHeader = "Clearcorp Users"
    			
    		}
    	}
		function logScreenAccess(){
			vm.screenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
		}

		/********** Refresh**********/
		$scope.toggle = true;
		function refresh() {
			$scope.toggle = false;
			$scope.refreshFlag = false;
			loadHeader();
			if ($scope.toggle == false) {
				$timeout(function() {
					$scope.toggle = true;
				}, 2000);
				$scope.refreshFlag = false;
			}
		};

		/***********END *************/
	
	 
    	$scope.$watch('vm.bankSummaryfilterText', function(value){
    		calculateBankHeaderTotal(vm.filterData);
    		resetGridsBankSelection();
        }); 

		function exportToFile(fileType){
			vm.baseString =   'No_Of_Available_Users_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
			
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv'
					vm.baseString = vm.baseString+ ".csv";  
					AvailableUsersReport.getAvailalbeUsersCSVData( processResponse )
				}else if(fileType == 'excel'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls";  
					AvailableUsersReport.getAvailalbeUsersExcelData(processResponse );
				}
			
		}	
		function processResponse(result){
			var fileData = null;
			fileData = result.data;
			var link = document.createElement("a");   
			var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer


			var blob = new Blob([bytes], {type: vm.mimeType});
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
			}else{
				link.href =  window.URL.createObjectURL(blob);      
				link.download = vm.baseString;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
})();