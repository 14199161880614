(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RoleViewController', RoleViewController);

    RoleViewController.$inject = ['Workflow', '$scope', '$rootScope', '$state',  '$uibModalInstance', 'workflowSharedService', 'Role', 'entity', 'ScreenLog'];

    function RoleViewController(Workflow, $scope, $rootScope, $state, $uibModalInstance, workflowSharedService, Role, entity, ScreenLog) {
        var vm = this;
        vm.role=entity;
        vm.clear=clear;
        vm.approveRole = approveRole;
        vm.rejectRole = rejectRole;
        vm.discardRole = discardRole;
        $scope.showButton = false;
        $scope.showDiscardButton = false;
        vm.clicked=false;
        if(workflowSharedService.viewFrom == 'role'){
        	vm.role = entity;
            //ScreenLog.screenLog({'entityName': 'OrganisationRole', 'entityRecordId': vm.role.id, 'action': 'VIEW'});
       	 	$scope.showButton=false;
        }
        else if(workflowSharedService.viewFrom == 'roletodolist_checker') {
        	vm.role =  angular.fromJson($rootScope.payload); 
        	
        	$scope.showButton=true;
        	$scope.showDiscardButton = false;
        }
        else if(workflowSharedService.viewFrom == 'roletodolist_maker') {
        	vm.role =  angular.fromJson($rootScope.payload); 
        
        	$scope.showButton=false;
        	$scope.showDiscardButton = true;
        }
        if(workflowSharedService.viewFrom == 'role_auditLog'){
        	vm.role =  angular.fromJson($rootScope.payload); 
            //ScreenLog.screenLog({'entityName': 'OrganisationRole', 'entityRecordId': vm.role.id, 'action': 'VIEW'});
       	 	$scope.showButton=false;
        }
       
        function approveRole() { 
        	Role.approve({"taskId" : $rootScope.taskID},onApproveSuccess,onApproveError);         	
        	$uibModalInstance.dismiss('cancel');     	
        }
        
        function onApproveSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onApproveError(error) {
        	vm.clicked=false;
        }
       
        function rejectRole() {Workflow.rejectTask($rootScope.taskID);}

        function discardRole() {
        	Role.discard({"taskId" : $rootScope.taskID},onDiscardSuccess,onDiscardError);         	
        	$uibModalInstance.dismiss('cancel');	
        }
        
        function onDiscardSuccess(result) {
        	$rootScope.removeRow($rootScope.taskID);        	
        }        
        function onDiscardError(error) {
        	
        }
              
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        

        
       
     }   
})();
