(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function() {
            this.viewFrom = ''
        })
        .controller('DashBoardController', DashBoardController);

    DashBoardController.$inject = ['$scope', '$timeout', '$state', '$stateParams', 'workflowSharedService', 'Tasks', '$rootScope', 'GlobalValues', 
    	'Workflow', '$filter', 'ScreenLog','entityConstants','MessageBox', 'AlertService'];

    function DashBoardController($scope, $timeout, $state, $stateParams, workflowSharedService, Tasks, $rootScope, GlobalValues, 
    		Workflow, $filter, ScreenLog,entityConstants,MessageBox , AlertService) {
        var vm = this;
        vm.screenLog = ScreenLog;
        vm.tasks = [];
        vm.getTasks = [];
        vm.approveEntity = approveEntity; 
        vm.rejectEntity = rejectEntity;
        vm.viewEntity = viewEntity;
        vm.discardEntity = discardEntity;
        vm.resubmitEntity = resubmitEntity;
        vm.globalValues=GlobalValues;
        vm.userLogin=vm.globalValues.getUser().login;
        $scope.refresh = refresh;
        vm.loggedinEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;;
        $scope.noOfRecordsPerPage1 = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page1 = 1;;
        
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.TODO;
        vm.entityConstants = entityConstants;
        
        loadAll();
        logScreenAccess();

        function loadAll() {
            Tasks.query({ "orgId": GlobalValues.getOrgId() }, function(result) {
                vm.fetchedTasks = result;
                vm.tasks = [];
                angular.forEach(vm.fetchedTasks, function(task) {
                	task.taskAction = task.changeType;
                	task.changeType = getChangeType(task);                	
                    vm.tasks.push(task);
                });
                
            });
        }
        
        vm.checkTaskWithCurrentLogin = checkTaskWithCurrentLogin;
        function checkTaskWithCurrentLogin(taskHistory){
        	var isValidTask = true;
        	var versionNo = getMaxVersionOfTask(taskHistory);
        	if(taskHistory!=null && taskHistory!=undefined){
        		angular.forEach(taskHistory, function(value){
        			if(value!=null && value!=undefined){
        				if((value.action=="APPROVED" || value.action=="CREATED" || value.action=="RESUBMITTED") && value.version == versionNo && taskHistory[taskHistory.length-1].action!="REJECTED"){
        					if(value.createdBy==vm.userLogin){
        						isValidTask = false;
        					}
        				}
        			}
        		});
        		return isValidTask;
        	}
        }
        
        $scope.customFilter1 = function customFilter1(taskList){
        	return (taskList.lastModifiedBy==vm.userLogin && taskList.changeType!='Rejected' ) || ((!checkTaskWithCurrentLogin(taskList.taskHistory) && taskList.changeType!='Rejected'));
        }
        
        $scope.customFilter = function customFilter(taskList){
        	return (taskList.lastModifiedBy!=vm.userLogin || taskList.changeType=='Rejected') && (checkTaskWithCurrentLogin(taskList.taskHistory) || taskList.changeType=='Rejected');
        }
        
        function getMaxVersionOfTask(taskHistory){
        	var versionNo = 0;
        	if(taskHistory!=null && taskHistory!=undefined){
        		if(taskHistory.length>0){
        			versionNo = taskHistory[taskHistory.length-1].version;
        		}
        	}
        	return versionNo;
        }

        function approveEntity(entityList) {
            $scope.rowID = entityList.id;
            vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.APPROVE);
            vm.taskChangeType = entityList.changeType;
            vm.taskEntityType = entityList.entity.shortName;
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'APPROVE'});
           if(entityList.entity.shortName == 'CustomerLimitChangeRequest'|| entityList.entity.shortName == 'PendingOrderReport' || ( entityList.entity.shortName =='LimitSettings'  && vm.taskChangeType!='Imported' )){
        	   
        	   Tasks.orderApprove({ "taskId": entityList.id }, onApproveSuccess, onApproveError);
           }else if( entityList.entity.shortName =='LimitSettings' && vm.taskChangeType =='Imported'  ){
        	   
        	   Tasks.orderApproveImport({ "taskId": entityList.id }, onApproveSuccess, onApproveError);
           }else if(entityList.entity.shortName == 'TermsAndConditionsView' && vm.taskChangeType =='Agreed' ){
        	 
               $rootScope.taskID =  entityList.id;  
               var tacAgreedDTOPayload  = angular.fromJson(angular.copy(  entityList.payload));        	    
        	   $state.go('tac-agree-confirmation-todo', {tacTypeId :tacAgreedDTOPayload.tacAgreedEntities.tacType.id} );
       	   }else if(entityList.entity.shortName == 'CustomerTermsAndConditionsUpload' && vm.taskChangeType =='Imported' ){
       		
       		   $state.go('customer-tac-upload-apporval-disclaimer', {entityId : entityList.id});
       	   }else{
       		   
        	   if( vm.taskEntityType== 'HomeBranchIFSCUpload'){
        		   MessageBox
					.showMessageBox(
							"Alert",
							"Home Branch Import approval is still in progress... Please wait",
							"autoclose");
        	   }
        	   
        	   Tasks.approve({ "taskId": entityList.id }, onApproveSuccess, onApproveError);
           }
           
        }

        function onApproveSuccess(result) {
        	console.log("import result type " + typeof result);
        	if(vm.taskChangeType=='Imported'){
        		if(  vm.taskEntityType == 'HomeBranchIFSCUpload'){        			
        			 MessageBox.closeMessageBox("autoclose");
        		}
	        	if(result.length!=undefined && result.length >0) {
	        		exportException(result, true, 'Import' + '_error');
	        	}else if(  vm.taskEntityType != 'CustomerTermsAndConditionsUpload' &&  vm.taskEntityType != 'TermsAndConditionsUpload' && vm.taskEntityType!='BankFeesChargesUpload'){
	        		//MessageBox.showMessageBox("Alert", " File has been uploaded successfully.  ", "Ok");
	        		AlertService.success($filter('translate')('error.upload.success'));
	        	}
        	}
            var flag = $filter('filter')(vm.tasks, { id: $scope.rowID });
            $rootScope.removeRow($scope.rowID);
            refresh();
        }

        function onApproveError(result) {
        	if(  vm.taskEntityType == 'HomeBranchIFSCUpload'){        		
        		 MessageBox.closeMessageBox("autoclose");
    		}
        }

    	function exportException(JSONData, showHeader, fileName) {

			if (JSONData != "") {
				var arrData = typeof (JSONData) != 'object' ? JSON
						.parse(JSONData) : JSONData;
						var out = arrData[arrData.length-1];
					if (out == "failure") {
							MessageBox.showMessageBox("Alert",
									"File upload failed! Please check the error log. ",
									"Ok");
							//generateFile(arrData,fileName);
					} 
					else if(out=="invalid"){
								MessageBox.showMessageBox("Alert",
										"Invalid file",
										"Ok");
					} else {
					MessageBox
							.showMessageBox(
									"Alert",
									"Partial success! Please check the error log for the failed records.  ",
									"Ok");
				}
						
				var csv = "";
				
				
				
				console.log(out);
				
				

				for (var i = 0; i <= arrData.length-2; i++) {

					var row = "";

					row += arrData[i];
					
					/*for ( var index in arrData[i]) {
						
					}
*/
					row = row.slice(0, row.length - 1);

					csv += row + '\r\n';

				}
				
				console.log(csv);

				if (csv != '') {
					//csv = 'test, test'
					/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csv);

					var link = document.createElement("a");
					link.href = uri;

					link.style = 'hidden';
					link.download = fileName + '.csv';

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);*/
					var blob = new Blob([csv], { type: "application/" + "csv" })
		            window.saveAs(blob, fileName + "."  + "csv");

				}
			}

		}

	

        function rejectEntity(entityList) {
            $scope.rowID = entityList.id;
            $rootScope.action = entityList.taskAction;
            vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.REJECT);
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'REJECT'});
            Workflow.rejectTask(entityList.id, onRejectSuccess, onRejectError);
        }
        
        function onRejectSuccess(){
        	refresh();
        }
        
        function onRejectError(){
        		
        }
        
        function discardEntity(entityList) {
            $scope.rowID = entityList.id;
            vm.screenLog.screenLog(entityList.entity.shortName, entityList.entityRecordID==null?0:entityList.entityRecordID, entityConstants.ENTITY_ACTION_TYPE.DISCARD);
            //ScreenLog.screenLog({'entityName': entityList.entity.shortName, 'entityRecordId': entityList.entityRecordID==null?0:entityList.entityRecordID, 'action': 'DISCARD'});
            Tasks.discard({ "taskId": entityList.id }, onDiscardSuccess, onDiscardError);
        }

        function onDiscardSuccess(result) {
            $rootScope.removeRow($scope.rowID);
            refresh();
        }

        function onDiscardError(error) {
        	
        }
        
        function viewEntity(entity,id,payload,action,changeType, recordId,entityShortName, taskAction){
        	$rootScope.payload = payload;
            $rootScope.taskID = id;          
            $rootScope.action = taskAction;          
            
            vm.screenLog.screenLog(entity, recordId==null?0:recordId, entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS);
            workflowSharedService.viewFrom = getViewFrom(entity,action,changeType,entityShortName);
            workflowSharedService.importType = entity;
            var state = getState(entity, action,changeType,entityShortName);
            $state.go(state);
        }
        
        function resubmitEntity(entity,id,payload,changeType,recordId,taskAction){
            $rootScope.payload = payload;
            $rootScope.taskID = id;
            $rootScope.action = taskAction;
                        
            //vm.screenLog.screenLog(entity, recordId==null?0:recordId, entityConstants.ENTITY_ACTION_TYPE.VIEW_DETAILS);
            workflowSharedService.viewFrom = getViewFrom(entity,'resubmit',changeType,'', taskAction);
            var state = getState(entity, 'resubmit',changeType,'');
            $state.go(state);
        }
        
       
        function getViewFrom(entity,action,changeType,entityShortName, taskAction){
        	
        	
        	
        	if (entity == 'SecurityPolicy' && action == "checker"){
        		return 'securityPolicytodolist_checker';
        	}
        	else if (entity == 'SecurityPolicy' && action == "maker"){
        		return 'securityPolicytodolist_maker';
        	}
        	else if (entity == 'SecurityPolicy' && action == "resubmit"){
        		return 'securityPolicy_resubmit';
        	}
        	else if (entity == 'GlobalSecurityPolicy' && action == "checker"){
        		return 'globalSecurityPolicytodolist_checker';
        	}
        	else if (entity == 'GlobalSecurityPolicy' && action == "maker"){
        		return 'globalSecurityPolicytodolist_maker';
        	}
        	else if (entity == 'GlobalSecurityPolicy' && action == "resubmit"){
        		return 'globalSecurityPolicy_resubmit';
        	}
        	else if (entity == 'UserAdminApplicationWideSettings' && action == "checker"){
        		return 'applicationWideSettingstodolist_checker';
        	}
        	else if (entity == 'UserAdminApplicationWideSettings' && action == "maker"){
        		return 'applicationWideSettingstodolist_maker';
        	}
        	else if (entity == 'UserAdminApplicationWideSettings' && action == "resubmit"){
        		return 'applicationWideSettings_resubmit';
        	}
        	else if (entity == 'DealingAdminApplicationWideSettings' && action == "checker"){
        		return 'dealingAdminApplicationWideSettingstodolist_checker';
        	}
        	else if (entity == 'DealingAdminApplicationWideSettings' && action == "maker"){
        		return 'dealingAdminApplicationWideSettingstodolist_maker';
        	}
        	else if (entity == 'DealingAdminApplicationWideSettings' && action == "resubmit"){
        		return 'dealingAdminApplicationWideSettingstodolist_resubmit';
        	}
        	else if (entity == 'EntityStateApprovalProcess' && action == "checker"){
        		return 'entityActionApprovalPolicytodolist_checker';
        	}
        	else if (entity == 'EntityStateApprovalProcess' && action == "maker"){
        		return 'entityActionApprovalPolicytodolist_maker';
        	}
        	else if (entity == 'EntityStateApprovalProcess' && action == "resubmit"){
        		return 'entityActionApprovalPolicy_resubmit';
        	}
        	
            else if (entity == 'EntityRestriction' && action == "checker"){
                return 'entityRestrictionstodolist_checker';
            }
            else if (entity == 'EntityRestriction' && action == "maker"){
                return 'entityRestrictionstodolist_maker';
            }
            else if (entity == 'EntityRestriction' && action == "resubmit"){
                return 'entityRestrictionstodolist_resubmit';
            }

        	
        	else if (entity == 'ManageGlobalEntityStateApprovalProcess' && action == "checker"){
        		return 'globalEntityActionApprovalPolicytodolist_checker';
        	}
        	else if (entity == 'ManageGlobalEntityStateApprovalProcess' && action == "maker"){
        		return 'globalEntityActionApprovalPolicytodolist_maker';
        	}
        	else if (entity == 'ManageGlobalEntityStateApprovalProcess' && action == "resubmit"){
        		return 'globalEntityActionApprovalPolicy_resubmit';
        	}
        	
        	else if (entity == 'ApprovalProcess' && action == "checker"){
        		return 'approvalPolicytodolist_checker';
        	}
        	else if (entity == 'ApprovalProcess' && action == "maker"){
        		return 'approvalPolicytodolist_maker';
        	}
        	else if (entity == 'ApprovalProcess' && action == "resubmit"){
        		return 'approvalPolicy_resubmit';
        	}
        	
        	else if (entity == 'GlobalApprovalProcess' && action == "checker"){
        		return 'globalApprovalPolicytodolist_checker';
        	}
        	else if (entity == 'GlobalApprovalProcess' && action == "maker"){
        		return 'globalApprovalPolicytodolist_maker';
        	}
        	else if (entity == 'GlobalApprovalProcess' && action == "resubmit"){
        		return 'globalApprovalPolicy_resubmit';
        	}
        	
        	else if (entity == 'UserClearance' && action == "checker"){
        		return 'userclearancetodolist_checker';
        	}
        	else if (entity == 'UserClearance' && action == "maker"){
        		return 'userclearancetodolist_maker';
        	}
        	else if (entity == 'UserClearance' && action == "resubmit"){
        		return 'userclearancetodolist_resubmit';
        	}
        	
        	else if (entity == 'OrganisationRole' && action == "checker"){
        		return 'roletodolist_checker';
        	}
        	else if (entity == 'OrganisationRole' && action == "maker"){
        		return 'roletodolist_maker';
        	}
        	else if (entity == 'OrganisationRole' && action == "resubmit"){
        		return 'role_resubmit';
        	}
        	
        	else if (entity == 'UserRole' && action == "checker"){
        		return 'userroletodolist_checker';
        	}
        	else if (entity == 'UserRole' && action == "maker"){
        		return 'userroletodolist_maker';
        	}
        	else if (entity == 'UserRole' && action == "resubmit"){
        		return 'userroletodolist_resubmit';
        	}

        	else if (entity == 'UserManagement' && action == "checker"){
        		return 'usermanagementtodolist_checker';
        	}
        	else if (entity == 'UserManagement' && action == "maker"){
        		return 'usermanagementtodolist_maker';
        	}
        	else if (entity == 'UserManagement' && action == "resubmit"){
        		return 'usermanagementtodolist_resubmit';
        	}
          	else if (entity == 'BankRegistration' && action == "checker"){
          		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'bank-registrationtodolist_checker';
          		}
        		
        	}
        	else if (entity == 'BankRegistration' && action == "maker"){
        		if(entityShortName == 'BankImport'){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'bank-registrationtodolist_maker';
          		}
           	}
        	else if (entity == 'BankRegistration' && action == "resubmit"){
        		return 'bank-registrationtodolist_resubmit';
        	}
        	else if (entity == 'BranchRegistration' && action == "checker"){
        		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'branchtodolist_checker';
          		}
           	}
        	else if (entity == 'BranchRegistration' && action == "maker"){
        		if(entityShortName == 'BranchImport'){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'branchtodolist_maker';
          		}
        	}
        	else if (entity == 'BranchRegistration' && action == "resubmit"){
        		return 'branchtodolist_resubmit';
        	}

        	else if (entity == 'AlertConfiguration' && action == "checker"){
        		return 'alertconfigurationtodolist_checker';
        	}
        	else if (entity == 'AlertConfiguration' && action == "maker"){
        		return 'alertconfigurationtodolist_maker';
        	}
        	else if (entity == 'AlertConfiguration' && action == "resubmit"){
        		return 'alert_configuration_resubmit';
        	}
        	else if (entity == 'SMSSettings' && action == "checker"){
        		return 'smssettingtodolist_checker';
        	}
        	else if (entity == 'SMSSettings' && action == "maker"){
        		return 'smssettingstodolist_maker';
        	}
        	else if (entity == 'SMSSettings' && action == "resubmit"){
        		return 'smssettingstodolist_resubmit';
        	}
        	else if (entity == 'SpecialHoliday' && action == "checker"){
        		return 'specialholidaystodolist_checker';
        	}
        	else if (entity == 'SpecialHoliday' && action == "maker"){
        		return 'specialholidaystodolist_maker';
        	}
        	else if (entity == 'SpecialHoliday' && action == "resubmit"){
        		return 'specialholidays_resubmit';
        	}

        	else if (entity == 'RegularHolidays' && action == "checker"){
        		return 'regularholidaystodolist_checker';
        	}
        	else if (entity == 'RegularHolidays' && action == "maker"){
        		return 'regularholidaystodolist_maker';
        	}
        	else if (entity == 'RegularHolidays' && action == "resubmit"){
        		return 'regularholidays_resubmit';
        	}
        	else if (entity == 'SwapQuotes' && action == "checker"){
        		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}
        		return 'swapQuotes_checker';
        	}
        	else if (entity == 'SwapQuotes' && action == "maker"){
        		if(entityShortName == 'SwapQuotesImport'){
          			return 'messageuploadtodolist_maker';	
          		}
        		return 'swapQuotes_maker';
        	}
        	else if (entity == 'SwapQuotes' && action == "resubmit"){
        		return 'swapQuotes_resubmit';
        	}
		else if (entity=='DefaultMarkupSettings' && action == "checker"){
        		return 'defaultMarkuptodolist_checker';
        	}
        	else if (entity=='DefaultMarkupSettings' && action == "maker"){
        		return 'defaultMarkuptodolist_maker';
        	}
        	else if (entity=='DefaultMarkupSettings' && action == "resubmit"){
        		return 'defaultMarkuptodolist_resubmit';
        	}
        	else if (entity=='MarkupSettings' && action == "checker"){
        		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'markuptodolist_checker';
          		}
        		
        	}
        	else if (entity=='MarkupSettings' && action == "maker"){
        		if(entityShortName == 'MarkupImport'){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'markuptodolist_maker';
          		}
        		
        	}
        	else if (entity=='MarkupSettings' && action == "resubmit"){
        		return 'markuptodolist_resubmit';
        	}
        	else if (entity=='HEConnectivitySettings' && action == "checker"){
        		return 'heConnectivitySettings_todolist_checker';
        	}
        	else if (entity=='HEConnectivitySettings' && action == "maker"){
        		return 'heConnectivitySetting_todolist_maker';
        	}
        	else if (entity=='HEConnectivitySettings' && action == "resubmit"){
        		return 'heConnectivitySettings_todolist_resubmit';
        	}
        	else if (entity=='IntradayMarketSessionSettings' && action == "checker"){
        		return 'intradayMarketSessionSettings_todolist_checker';
        	}
        	else if (entity=='IntradayMarketSessionSettings' && action == "maker"){
        		return 'intradayMarketSessionSettings_todolist_maker';
        	}
        	else if (entity=='IntradayMarketSessionSettings' && action == "resubmit"){
        		return 'intradayMarketSessionSettings_todolist_resubmit';
        	}
        	else if (entity=='DefaultMarketSessionSettings' && action == "checker"){
        		return 'defaultMarketSessionSettings_todolist_checker';
        	}
        	else if (entity=='DefaultMarketSessionSettings' && action == "maker"){
        		return 'defaultMarketSessionSettings_todolist_maker';
        	}
        	else if (entity=='DefaultMarketSessionSettings' && action == "resubmit"){
        		return 'defaultMarketSessionSettings_todolist_resubmit';
        	}
        	else if (entity=='EmailSettings' && action == "checker"){
        		return 'emailSettings_todolist_checker';
        	}
        	else if (entity=='EmailSettings' && action == "maker"){
        		return 'emailSettings_todolist_maker';
        	}
        	else if (entity=='EmailSettings' && action == "resubmit"){
        		return 'emailSettings_todolist_resubmit';
        	}else if (entity=='UserRestriction' && action == "checker"){
        		return 'userRestriction_checker';
        	}
        	else if (entity=='UserRestriction' && action == "maker"){
        		return 'userRestriction_maker';
        	}
        	else if (entity=='UserRestriction' && action == "resubmit"){
        		return 'userRestriction_resubmit';
        	}
        	else if (entity=='LimitSettings' && action == "checker"){
        		if(entityShortName == 'LimitSettingsImport' && ( changeType == 'Imported' || changeType == 'Rejected')){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'limitSettings_checker';
          		}
        		
        	}
        	else if (entity=='LimitSettings' && action == "maker"){
        		if(entityShortName == 'LimitSettingsImport' && ( changeType == 'Imported' || changeType == 'Rejected')){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'limitSettings_maker';
          		}
        		
        	}
        	else if (entity=='LimitSettings' && action == "resubmit"){
        		return 'limitSettings_resubmit';
        	}
        	else if (entity=='CustomerLimitChangeRequest' && action == "checker"){
        		
        		if( $rootScope.action=='CREATE'){
        			
        			return 'create_customer_limitSettings_checker'
        		}        	
        		return 'customer_limitSettings_checker';
        	}
        	else if (entity=='CustomerLimitChangeRequest' && action == "maker"){
        		
        		if(  $rootScope.action=='CREATE' && !(vm.loggedinEntityOwnerType == "Bank" || vm.loggedinEntityOwnerType == "Branch" ) ){
        			
        			return 'create_customer_limitSettings_maker'
        		} 
        		else if( $rootScope.action=='CREATE' && (vm.loggedinEntityOwnerType == "Bank" || vm.loggedinEntityOwnerType == "Branch" ) ){
        			
        			return 'create_customer_limitSettings_checker_view'
        		} 
        		return 'customer_limitSettings_maker';
        	}
        	else if (entity=='CustomerLimitChangeRequest' && action == "resubmit"){
        		if( $rootScope.action=='CREATE'){
        			
        			return 'create_customer_limitSettings_resubmit'
        		} 
        		
        		return 'customer_limitSettings_resubmit';
        	}
			else if (entity=='PendingOrderReport' && action == "checker"){
          			return 'messageuploadtodolist_checker';	   		      		
        	}
        	else if (entity=='PendingOrderReport' && action == "maker"){
          			return 'messageuploadtodolist_maker';	     	
        	}
        	else if (entity=='AMCSettings' && action == "checker"){
        		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'amcSettings_todolist_checker';
          		}
        		
        	}
        	else if (entity=='AMCSettings' && action == "maker"){
        		if(entityShortName == 'AMCImport'){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'amcSettings_todolist_maker';
          		}
        		
        	}
        	else if (entity=='AMCSettings' && action == "resubmit"){
        		return 'amcSettingtodolist_resubmit';
        	}
        	
        	else if (entity=='CustomerCharges' && action == "checker"){
        		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'customerCharges_todolist_checker';
          		}
        		
        	}
        	else if (entity=='CustomerCharges' && action == "maker"){
        		if(entityShortName == 'OneTimeRegistrationChargesImport'){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'customerCharges_todolist_maker';
          		}
        		
        	}
        	else if (entity=='CustomerCharges' && action == "resubmit"){
        		return 'customerChargestodolist_resubmit';
        	}
        	
        	else if (entity=='GSTSettings' && action == "checker"){
        		if(changeType == 'Imported'){
          			return 'messageuploadtodolist_checker';	
          		}else {
          			return 'gstSettings_todolist_checker';
          		}
        		
        	}
        	else if (entity=='GSTSettings' && action == "maker"){
        		if(entityShortName == 'GSTSettingsImport'){
          			return 'messageuploadtodolist_maker';	
          		}else {
          			return 'gstSettings_todolist_maker';
          		}
        		
        	}
        	else if (entity=='GSTSettings' && action == "resubmit"){
        		return 'gstSettingstodolist_resubmit';
        	}
        	else if (entity=='TermsAndConditionsUpload' && action == "resubmit"){
        		return 'termsConditionUploadtodolist_resubmit';
        	}
        	else if (entity=='TermsAndConditionsUpload' && action == "checker"){
        		
          			return 'termsConditionUpload_todolist_checker';

        	}
        	else if (entity=='TermsAndConditionsUpload' && action == "maker"){
        		
          			return 'termsConditionUpload_todolist_maker';
        		
        	}
        	else if (entity=='CustomerTermsAndConditionsUpload' && action == "resubmit"){
        		return 'customerTermsConditionUploadByBanktodolist_resubmit';
        	}
        	else if (entity=='CustomerTermsAndConditionsUpload' && action == "checker"){
        		
          			return 'customerTermsConditionUploadByBank_todolist_checker';

        	}
        	else if (entity=='CustomerTermsAndConditionsUpload' && action == "maker"){
        		
          			return 'customerTermsConditionUploadByBank_todolist_maker';
        		
        	}
        	else if(entity=='CustomerSpecificSettings' &&  action == "checker"){
        		return 'customerSpecificSettingstodolist_checker';
        	}
        	else if (entity=='CustomerSpecificSettings' && action == "maker"){    
          			return 'customerSpecificSettingstodolist_maker';       		
        	}
        	else if(entity=='CustomerSpecificSettings' && action == "resubmit"){
        			return 'customerSpecificSettings_resubmit';
        	}
        	
        	else if (entity=='TransactionChargesSettings' && action == "checker"){
        		if(entityShortName == 'TransactionChargesImport'){
        			return 'messageuploadtodolist_checker';	
        		}else{
        			return 'transactionChargesSettings_todolist_checker';
        		}
        		
        		
        	}
        	else if (entity=='TransactionChargesSettings' && action == "maker"){
        		if(entityShortName == 'TransactionChargesImport'){
        			return 'messageuploadtodolist_maker';
        		}else{
        			return 'transactionChargesSettings_todolist_maker';
        		}
        		
        	}
        	else if (entity=='TransactionChargesSettings' && action == "resubmit" && taskAction != "DELETE"){
        		return 'transactionChargesSettingstodolist_resubmit';
        	}
        	else if (entity=='TransactionChargesSettings' && action == "resubmit" && taskAction == "DELETE"){
        		return 'transactionChargesSettingstodolist_resubmit_delete';
        	}
        	
            else if (entity=='CreditFactorSettings' && action == "checker"){
                return 'creditFactorSettings_todolist_checker';
            }
            else if (entity=='CreditFactorSettings' && action == "maker"){
                return 'creditFactorSettings_todolist_maker';
            }
            else if (entity=='CreditFactorSettings' && action == "resubmit"){
                return 'creditFactorSettingstodolist_resubmit';
            }

        	else if (entity=='UploadDownloadParticipantCertificate' && action == "checker"){
        		return 'participantCertificate_todolist_checker';
        	}

        	else if (entity=='UploadDownloadParticipantCertificate' && action == "maker"){
        		return 'participantCertificate_todolist_maker';
        	}
        	else if (entity=='HomeBranchIFSCUpload' && action == "maker"){
        		return 'messageuploadtodolist_maker';
        	}
        	else if (entity=='HomeBranchIFSCUpload' && action == "checker"){
        		return 'messageuploadtodolist_checker';
        	}
        	else if(entity=='ModifyCustomerDetails' &&  action == "checker"){
        		return 'modifycustomerdetails_checker';
        	}
        	else if (entity=='ModifyCustomerDetails' && action == "maker"){    
          			return 'modifycustomerdetails_maker';       		
        	}
        	else if(entity=='ModifyCustomerDetails' && action == "resubmit"){
        			return 'modifycustomerdetails_resubmit';
        	}
        	else if(entity=='TermsAndConditionsView' &&  action == "checker"){
        		return 'entited_tac_view_checker';
        	}
        	else if (entity=='TermsAndConditionsView' && action == "maker"){    
          			return 'entited_tac_view_maker';       		
        	}
        	else if(entity=='TermsAndConditionsView' && action == "resubmit"){
        			return 'entited_tac_view_resubmit';
        	}
        	else if (entity=='ExposureType' && action == "checker"){
        		return 'exposureType_todolist_checker';
        		
        	}
        	else if (entity=='ExposureType' && action == "maker"){
        		return 'exposureType_todolist_maker';
        		
        	}
        	else if (entity=='ExposureType' && action == "resubmit"){
        		return 'exposureTypetodolist_resubmit';
        	}
        	else if (entity=='ExposureSubType' && action == "checker"){
        		return 'exposureSubType_todolist_checker';
        		
        	}
        	else if (entity=='ExposureSubType' && action == "maker"){
        		return 'exposureSubType_todolist_maker';
        		
        	}
        	else if (entity=='ExposureSubType' && action == "resubmit"){
        		return 'exposureSubTypetodolist_resubmit';
        	}
        	else if (entity=='BankFeesChargesUpload' && action == "checker"){
        		return 'bankFeesAndChargesUpload_todolist_checker';
        		
        	}
        	else if (entity=='BankFeesChargesUpload' && action == "maker"){
        		return 'bankFeesAndChargesUpload_todolist_maker';
        		
        	}
        	else if (entity=='BankFeesChargesUpload' && action == "resubmit"){
        		return 'bankFeesAndChargesUploadtodolist_resubmit';
        	}
        }
        
        function getState(entity,action,changeType,entityShortName){
        	
        	if(entity == 'CustomerSpecificSettings' && action !='resubmit'){
        		return 'customer-specific-settings-todolist';
        		
        	}
        	else if(entity == 'CustomerSpecificSettings' && action =='resubmit' ){
        		return 'customer-specific-settings-todoList-resubmit'
        	}
        	else if (entity == 'SecurityPolicy' && action != 'resubmit'){
        		return 'security-policy-todolist';
        	}
        	else if (entity == 'SecurityPolicy' && action == 'resubmit'){
        		return 'security-policy-todolist.resubmit';
        	}
        	else if (entity == 'GlobalSecurityPolicy' && action != 'resubmit'){
        		return 'global-security-policy-todolist';
        	}
        	else if (entity == 'GlobalSecurityPolicy' && action == 'resubmit'){
        		return 'global-security-policy-todolist.resubmit';
        	}
        	else if (entity == 'UserAdminApplicationWideSettings' && action != 'resubmit'){
        		return 'application-wide-settings-todolist';
        	}
        	else if (entity == 'UserAdminApplicationWideSettings' && action == 'resubmit'){
        		return 'application-wide-settings-todolist.resubmit';
        	}
        	else if (entity == 'DealingAdminApplicationWideSettings' && action != 'resubmit'){
        		return 'dealing-admin-application-wide-settings-todolist';
        	}
        	else if (entity == 'DealingAdminApplicationWideSettings' && action == 'resubmit'){
        		return 'dealing-admin-application-wide-settings-todolist.resubmit';
        	}
        	else if (entity == 'EntityStateApprovalProcess' && action != 'resubmit'){
        		return 'entity-action-approval-policy-todolist';
        	}
        	else if (entity == 'EntityStateApprovalProcess' && action == 'resubmit'){
        		return 'entity-action-approval-policy-todolist.resubmit';
        	}
        	
            else if (entity == 'EntityRestriction' && action != 'resubmit'){
                return 'manage-Entity-Restrictions-todolist';
            }
            else if (entity == 'EntityRestriction' && action == 'resubmit'){
                return 'manage-Entity-Restrictions-todolist.resubmit';
            }

        	
        	else if (entity == 'ManageGlobalEntityStateApprovalProcess' && action != 'resubmit'){
        		return 'global-entity-action-approval-policy-todolist';
        	}
        	else if (entity == 'ManageGlobalEntityStateApprovalProcess' && action == 'resubmit'){
        		return 'global-entity-action-approval-policy-todolist.resubmit';
        	}
        	
        	else if (entity == 'ApprovalProcess' && action != 'resubmit'){
        		return 'approval-policy-todolist';
        	}
        	else if (entity == 'ApprovalProcess' && action == 'resubmit'){
        		return 'approval-policy-todolist.resubmit';
        	}
        	
        	else if (entity == 'GlobalApprovalProcess' && action != 'resubmit'){
        		return 'global-approval-policy-todolist';
        	}
        	else if (entity == 'GlobalApprovalProcess' && action == 'resubmit'){
        		return 'global-approval-policy-todolist.resubmit';
        	}
        	
        	else if (entity == 'UserClearance' && action != 'resubmit'){
        		return 'manage-user-todolist';
        	}
        	else if (entity == 'UserClearance' && action == 'resubmit'){
        		return 'manage-user-todolist-resubmit';
        	}
        	
        	else if (entity == 'OrganisationRole' && action != 'resubmit'){
        		return 'manage-role-todolist';
        	}
        	else if (entity == 'OrganisationRole' && action == 'resubmit'){
        		return 'manage-role-todolist.resubmit';
        	}
        	
        	else if (entity == 'UserRole' && action != 'resubmit'){
        		return 'manage-users-roletodolist-assignrole';
        	}
        	else if (entity == 'UserRole' && action == 'resubmit'){
        		return 'manage-users-roletodolist-assignrole.resubmit';
        	}
        	
        	else if (entity == 'UserManagement' && action != 'resubmit'){
        		return 'usermanagement-todolist';
        	}
        	else if (entity == 'UserManagement' && action == 'resubmit'){
        		return 'usermanagement-todolist.resubmit';
        	}
         	else if (entity == 'BankRegistration' && action != 'resubmit'){
         		if(entityShortName == 'BankImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}else {
          			return 'bank-registration-todolist';
          		}
        		
        	}
        	else if (entity == 'BankRegistration' && action == 'resubmit'){
        		return 'bank-registration-todolist.resubmit';
        	}
        	else if (entity == 'BranchRegistration' && action != 'resubmit'){
        		if(entityShortName == 'BranchImport'  || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}else {
          			return 'branch-todolist';
          		}
        		
        	}
        	else if (entity == 'BranchRegistration' && action == 'resubmit'){
        		return 'branch-todolist.resubmit';
        	}
        	else if (entity == 'AlertConfiguration' && action != 'resubmit'){
        		return 'alert-configuration-todolist';
        	}
        	else if (entity == 'AlertConfiguration' && action == 'resubmit'){
        		return 'alert-configuration-todolist.resubmit';
        	}
        	else if (entity == 'SMSSettings' && action != 'resubmit'){
        		return 'sms-settings-todolist';
        	}
        	else if (entity == 'SMSSettings' && action == 'resubmit'){
        		return 'sms-settings-todolist.resubmit';
        	}else if (entity == 'RegularHolidays' && action != 'resubmit'){
        		return 'regular-holidays-todolist';
        	}
        	else if (entity == 'RegularHolidays' && action == 'resubmit'){
        		return 'regular-holidays-todolist.resubmit';
        	}
        	else if (entity == 'SpecialHoliday' && action != 'resubmit'){
        		return 'special-holidays-todolist';
        	}
        	else if (entity == 'SpecialHoliday' && action == 'resubmit'){
        		return 'special-holidays-todolist.resubmit';
        	}
        	else if (entity == 'SwapQuotes' && action != 'resubmit'){
        		if(entityShortName == 'SwapQuotesImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}
        		return 'swap-quotes-todolist';
        	}
        	else if (entity == 'SwapQuotes' && action == 'resubmit'){
        		return 'swap-quotes-todolist.resubmit';
        	}
        	else if (entity == 'DefaultMarkupSettings' && action != 'resubmit'){
        		return 'default-markup-todolist';
        	}
        	else if (entity == 'DefaultMarkupSettings' && action == 'resubmit'){
        		return 'default-markup-todolist.resubmit';
        	}
        	else if (entity == 'MarkupSettings' && action != 'resubmit'){
        		if(entityShortName == 'MarkupImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}else {
          			return 'markup-todolist';
          		}
        	}
        	else if (entity == 'MarkupSettings' && action == 'resubmit'){
        		return 'markup-todolist.resubmit';
        	}
        	else if (entity == 'DefaultMarkupSettings' && action != 'resubmit'){
        		return 'default-markup-todolist';
        	}
        	else if (entity == 'DefaultMarkupSettings' && action == 'resubmit'){
        		return 'default-markup-todolist.resubmit';
        	}
        	else if (entity == 'MarkupSettings' && action != 'resubmit'){
        		return 'markup-todolist';
        	}
        	else if (entity == 'MarkupSettings' && action == 'resubmit'){
        		return 'markup-todolist.resubmit';
        	}
        	else if (entity == 'HEConnectivitySettings' && action != 'resubmit'){
        		return 'he-connectivity-settings-todolist';
        	}
        	else if (entity == 'HEConnectivitySettings' && action == 'resubmit'){
        		return 'he-connectivity-settings-todolist-resubmit';
        	}
        	else if (entity == 'IntradayMarketSessionSettings' && action != 'resubmit'){
        		return 'intraday-market-session-settings-todolist';
        	}
        	else if (entity == 'IntradayMarketSessionSettings' && action == 'resubmit'){
        		return 'intraday-market-todolist-resubmit';
        	}
        	else if (entity == 'DefaultMarketSessionSettings' && action != 'resubmit'){
        		return 'default-market-session-settings-todolist';
        	}
        	else if (entity == 'DefaultMarketSessionSettings' && action == 'resubmit'){
        		return 'default-market-todolist-resubmit';
        	}
        	else if (entity == 'EmailSettings' && action != 'resubmit'){
        		return 'email-todolist';
        	}
        	else if (entity == 'EmailSettings' && action == 'resubmit'){
        		return 'email-todolist.resubmit';
        	}else if (entity == 'UserRestriction' && action != 'resubmit'){
        		return 'user-restrictions-todolist';
        	}
        	else if (entity == 'UserRestriction' && action == 'resubmit'){
        		return 'user-restrictions-todolist.resubmit';
        	}else if (entity == 'LimitSettings' && action != 'resubmit'){
        		
        		if(entityShortName == 'LimitSettingsImport' && ( changeType == 'Imported' || changeType == 'Rejected')){
          			return 'upload-todolist';	
        		}else {
        			return 'limit-settings-todolist'; 
        			}
        	}
        	else if (entity == 'LimitSettings' && action == 'resubmit'){
        		return 'limit-settings-todolist.resubmit';
        	}else if (entity == 'CustomerLimitChangeRequest' && action != 'resubmit'){
        		
        		if($rootScope.action =='CREATE' && action=='maker'){
        			if( vm.loggedinEntityOwnerType == "Bank" || vm.loggedinEntityOwnerType == "Branch"  ){
            			
            			return 'create-customer-limit-settings-todolist-checker'
            		} 
        			return 'create-customer-limit-settings-todolist';
        		}
        		
        		if($rootScope.action =='CREATE' && action=='checker'){
        			return 'create-customer-limit-settings-todolist-checker';
        		}
        		return 'customer-limit-settings-todolist';
        	}
        	else if (entity == 'CustomerLimitChangeRequest' && action == 'resubmit'){
        		
        		if($rootScope.action =='CREATE'){
        			return 'create-customer-limit-settings-todolist.resubmit';
        		}
        		return 'customer-limit-settings-todolist.resubmit';
        	}else if (entity == 'PendingOrderReport' && action != 'resubmit'){
        		return 'upload-todolist';	
        
        	}
        	else if (entity == 'AMCSettings' && action != 'resubmit'){
        	
        		if(entityShortName == 'AMCImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}else {
          			return 'amc-settings-todolist';
          		}
        	}
        	else if (entity == 'AMCSettings' && action == 'resubmit'){
        		return 'amc-todolist-resubmit';
        	}
        	else if (entity == 'CustomerCharges' && action != 'resubmit'){
            	
        		if(entityShortName == 'OneTimeRegistrationChargesImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}else {
          			return 'customer-charges-todolist';
          		}
        	}
        	else if (entity == 'CustomerCharges' && action == 'resubmit'){
        		return 'customer-charges-todolist-resubmit';
        	}
        	else if (entity == 'TermsAndConditionsUpload' && action != 'resubmit'){

        		return 'terms-condition-upload-todolist';

        	}
        	else if (entity == 'TermsAndConditionsUpload' && action == 'resubmit'){
        		return 'terms-condition-upload-todolist-resubmit';
        	}
        	else if (entity == 'CustomerTermsAndConditionsUpload' && action != 'resubmit'){

        		return 'customer-tac-upload-by-bank-todolist';

        	}
        	else if (entity == 'CustomerTermsAndConditionsUpload' && action == 'resubmit'){
        		return 'customer-tac-upload-by-bank-todolist-resubmit';
        	}

        	else if (entity == 'GSTSettings' && action != 'resubmit'){
            	
        		if(entityShortName == 'GSTSettingsImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}else {
          			return 'gst-settings-todolist';
          		}
        	}
        	else if (entity == 'GSTSettings' && action == 'resubmit'){
        		return 'gst-settings-todolist-resubmit';
        	}
        	
        	if (entity == 'TransactionChargesSettings' && action != 'resubmit'){
        		if(entityShortName=='TransactionChargesImport' || changeType == 'Imported'){
        			return 'upload-todolist';
        		}else{
        			return 'transaction-charges-settings-todolist';
        		}
        		
        		
        	}
        	else if (entity == 'TransactionChargesSettings' && action == 'resubmit'){
        		return 'transaction-charges-settings-todolist-resubmit';
        	}

            if (entity == 'CreditFactorSettings' && action != 'resubmit'){
                return 'credit-factor-settings-todolist';
            }
            else if (entity == 'CreditFactorSettings' && action == 'resubmit'){
                return 'credit-factor-settings-todolist-resubmit';
            }
        	
        	else if (entity == 'UploadDownloadParticipantCertificate'){
        		return 'participant-certificate-todolist';
        	}
        	else if (entity == 'HomeBranchIFSCUpload' && action != 'resubmit'){
            	
        		if(entityShortName == 'HomeBranchImport' || changeType == 'Imported'){
          			return 'upload-todolist';	
          		}
        	}
        	else if (entity == 'ModifyCustomerDetails' && action == 'resubmit'){
        		return 'modify-customer-details-todoList';
        	}
        	else if (entity == 'ModifyCustomerDetails' && action != 'resubmit'){
        		return 'modify-customer-details-todoList';
        	}
        	else if (entity == 'TermsAndConditionsView' && action == 'resubmit'){
        		return 'entities-tac-todo-view.resubmit';
        	}
        	else if (entity == 'TermsAndConditionsView' && action != 'resubmit'){
        		return 'entities-tac-todo-view';
        	}
        	else if (entity == 'ExposureType' && action != 'resubmit'){
        		return 'exposure-type-todolist';
        	}
        	else if (entity == 'ExposureType' && action == 'resubmit'){
        		return 'exposure-type-todolist-resubmit';
        	}
        	else if (entity == 'ExposureSubType' && action != 'resubmit'){
        		return 'exposure-sub-type-todolist';
        	}
        	else if (entity == 'ExposureSubType' && action == 'resubmit'){
        		return 'exposure-sub-type-todolist-resubmit';
        	}
        	else if (entity == 'BankFeesChargesUpload' && action != 'resubmit'){
        		return 'bankFeesAndChargesUpload-todolist';
        	}
        	else if (entity == 'BankFeesChargesUpload' && action == 'resubmit'){
        		return 'bankFeesAndChargesUpload-todolist-resubmit';
        	}
        	
        }
        
    	function getChangeType(task){
    		console.log(task.changeType);
    		if(task.changeType == 'CREATE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Added';
    		}
    		else if(task.changeType == 'EDIT' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Modified';
    		}
            else if(task.changeType == 'RESET' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Reset';
            }
    		else if(task.changeType == 'DELETE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Deleted';
    		}
    		else if(task.changeType == 'REMOVE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Removed';
    		}
    		else if(task.changeType == 'CANCEL' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Cancelled';
    		}
    		else if(task.changeType == 'ROLLOVER' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Rolled over';
    		}
    		else if(task.changeType == 'MAP' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Assign';
    		}
    		else if(task.changeType == 'COMMITTED' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Commit';
    		}
    		else if(task.changeType == 'DELIVERY' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Delivered';
    		}
    		else if(task.changeType == 'UNDODELIVERY' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Undo Delivery';
    		}
    		else if(task.changeType == 'UNDOCANCEL' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Undo Cancellation';
    		}
    		else if(task.changeType == 'ADDPRICE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Added Price';
    		}
    		else if(task.changeType == 'UNDOSETTLE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Undo Settlement';
    		}
            else if(task.changeType == 'EDITPRICE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Edited Price';
    		}
            else if(task.changeType == 'CLOSEOUTCASH' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Close Out Cash';
            }
            else if(task.changeType == 'CLOSEOUTDELIVERY' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Close Out Delivery';
            }
            else if(task.changeType == 'SQUAREOFF' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Square Off';
            }
            else if(task.changeType == 'UNWIND' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Unwind';
    		}
            else if(task.changeType == 'UNDOFXO' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Undo Fx Options';
            }
            else if(task.changeType == 'DELINK' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Delinked';
            }
    		else if(task.taskHistory[task.taskHistory.length - 1].action == 'REJECTED'){
    			return 'Rejected';
    		}
            else if(task.changeType == 'IMPORT'  && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
                return 'Imported';
            }
    		if(task.changeType == 'AGREE' && task.taskHistory[task.taskHistory.length - 1].action != 'REJECTED'){
    			return 'Agreed';
    		}
    	}
    	
        $rootScope.removeRow = function(id) {
            var index = -1;
            var comArr = eval(vm.tasks);
            for (var i = 0; i < comArr.length; i++) {
                if (comArr[i].id === id) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                //alert("Something gone wrong");
            }
            vm.tasks.splice(index, 1);
        };

        $scope.toggle = true;
        function refresh() {
            $scope.toggle = false;
            $scope.refreshFlag = false;
            loadAll();
            if ($scope.toggle == false) {
                $timeout(function() {
                    $scope.toggle = true;
                }, 2000);
                $scope.refreshFlag = false;
            }
        };
        
        
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        
        $rootScope.$on('gatewayApp.messagebox.clear.autoclose',function (event, data){
            //do nothing
        });
        
    }

})();