 (function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('clearcorp-tac-upload-view', {
            parent: 'entity',
            params:{screen:'TermsAndConditionsUpload'},
            url: '/clearcorp-tac-upload-view',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.clearCorpTACUpload.main" | translate }}',
                parent: 'home'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-view.html',
                    controller: 'ClearcorpTACViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clearCorpTACUpload');                    
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
                /*,entity: ['ClearcorpTACUpload','GlobalValues', function(ClearcorpTACUpload,GlobalValues) {
                    return ClearcorpTACUpload.getTermsConditionDetails({organisationId:GlobalValues.getOrgId()}).$promise;
                }],*/
               
            }
        })
        
         .state('agreed-bank-details', {
            parent: 'clearcorp-tac-upload-view',
            url: '/agreed-bank-details',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'clearcorp-tac-upload-view'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-bank-agreed-view.html',
                    controller: 'AgreedBanksController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        entity: ['ClearcorpTACUpload','GlobalValues', function(ClearcorpTACUpload,GlobalValues) {
                            return ClearcorpTACUpload.getAgreedBankDetails().$promise;
                        }],
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
            	
                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('clearCorpTACUpload');
                    $translatePartialLoader.addPart('agreedBanks');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
        .state('clearcorp-bank-tac-view', {
            parent: 'clearcorp-tac-upload-view',
            url: '/banks-terms-and-condition',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            ncyBreadcrumb: {
                label: '{{ "global.menu.faq.gatewayDesk" | translate }}',
                parent: 'clearcorp-tac-upload-view'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-bank-tac-view.html',
                    controller: 'ClearcorpBankTACViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        entity: ['ClearcorpTACUpload','GlobalValues', function(ClearcorpTACUpload,GlobalValues) {
                            return ClearcorpTACUpload.getBankTermsAndConditions().$promise;
                        }],
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {                
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('clearCorpTACUpload');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
               
            }
        })
        
       
        
        
        .state('terms-condition-upload', {
            parent: 'clearcorp-tac-upload-view',
            url: '/terms-condition-upload/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-edit.html',
                    controller: 'ClearcorpTACEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	
                            	 entity:['ClearcorpTACUpload', function(ClearcorpTACUpload) {
                            		 return ClearcorpTACUpload.getTermConditionDTO({id : $stateParams.id}).$promise;
                                 }],
                                 globalValue: ['StaticLookUp', function(StaticLookUp) {
                                     return StaticLookUp.getConfigSettings().$promise;
                                 }],
                                 systemSettings: ['IntradayMarket', function(IntradayMarket) {
                                     return IntradayMarket.getCurrentBusinessDate().$promise;
                                 }],
                                 configurationSetting :['GlobalValues', function(GlobalValues) {
                                     return GlobalValues.getConfigSettings();
                                 }]
                       
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         .state('terms-condition-upload-todolist', {
            parent: 'dashboard',
            url: '/terms-condition-upload-to-do',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-approval.html',
                    controller: 'ClearcorpTACEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        systemSettings: ['IntradayMarket', function(IntradayMarket) {
                            return IntradayMarket.getCurrentBusinessDate().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
                    }
                }).result.then(function() {
                	$state.go('clearcorp-tac-upload-view', null, { reload: 'clearcorp-tac-upload-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clearCorpTACUpload');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
            
        })
        .state('terms-condition-upload-todolist-resubmit', {
            parent: 'dashboard',
            url: '/clearcorp-tac-resubmit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-edit.html',
                    controller: 'ClearcorpTACEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                      entity: function () {                       	
                          
                        },
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        systemSettings: ['IntradayMarket', function(IntradayMarket) {
                            return IntradayMarket.getCurrentBusinessDate().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
                    }
                }).result.then(function() {
                	$state.go('clearcorp-tac-upload-view', null, { reload: 'clearcorp-tac-upload-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clearCorpTACUpload');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
          .state('terms-condition-upload-auditLog', {
            parent: 'audit-log',
            url: '/terms-condition-upload-audit-log',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-approval.html',
                    controller: 'ClearcorpTACEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        systemSettings: ['IntradayMarket', function(IntradayMarket) {
                            return IntradayMarket.getCurrentBusinessDate().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
                    }
                }).result.then(function() {
                	$state.go('clearcorp-tac-upload-view', null, { reload: 'clearcorp-tac-upload-view' });
                }, function() {
                    $state.go('^');
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('clearCorpTACUpload');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
            
        })
        
        
        .state('terms-condition-upload-historyReport', {
            parent: 'history-report',
            url: '/TACHistory',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal',  function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/clearcorp-terms-condition-upload/clearcorp-tac-approval.html',
                    controller: 'ClearcorpTACEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    	entity: function () {                        	
                            return {                            		
                            };
                        },
                        globalValue: ['StaticLookUp', function(StaticLookUp) {
                            return StaticLookUp.getConfigSettings().$promise;
                        }],
                        systemSettings: ['IntradayMarket', function(IntradayMarket) {
                            return IntradayMarket.getCurrentBusinessDate().$promise;
                        }],
                        configurationSetting :['GlobalValues', function(GlobalValues) {
                            return GlobalValues.getConfigSettings();
                        }]
                    }
                }).result.then(function() {
                	$state.go('clearcorp-tac-upload-view', null, { reload: 'clearcorp-tac-upload-view' });
                }, function() {
                    $state.go('^');
                });
            }]
            
        })
        
         };
    })();
