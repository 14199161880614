(function() {
	'use strict';

	angular.module('gatewayApp').service("ExportFile", ExportFile);

	ExportFile.$inject = ['$rootScope','GlobalValues','$timeout', '$filter'];

	function ExportFile($rootScope,GlobalValues, $timeout, $filter) {

        var tableName = "";
        var totalRecordCount = 0;
        var filterText = "";
        var pageNo = 0;
        var fileName = "";
        var ignoreColumn = "";
        var sheetName = "";
        var footer = "";
        var noOfRecordsPerPage = 0;
        var isExportMode = false;
        var exportOption = '';
        var pageIndex = 1;
        var noOfRecordsPerPageCopy=0;
	
		var service = {
			exportFile : exportFile
		};
		return service;

        //export file public call
        function exportFile(OPTION,TABLENAME, TOTALRECORDS,NOOFRECORDSPERPAGE,FILTERTEXT,PAGENO,FILENAME,IGNORECOLUMN,SHEETNAME,FOOTER) {
            tableName = TABLENAME;
            totalRecordCount=TOTALRECORDS;
            //noOfRecordsPerPage = NOOFRECORDSPERPAGE;
            filterText = FILTERTEXT;
            fileName = FILENAME;
            ignoreColumn = IGNORECOLUMN;
            sheetName = SHEETNAME;
            footer = FOOTER;

            if (TOTALRECORDS != null && TOTALRECORDS > 0) {
                exportOption = OPTION;
                pageIndex = PAGENO;
                noOfRecordsPerPage = NOOFRECORDSPERPAGE;
                noOfRecordsPerPageCopy = noOfRecordsPerPage;
                checkAndProcessExport();
            }


        }

        
        function checkAndProcessExport() {
            if ((filterText == null || filterText == "") && (totalRecordCount > noOfRecordsPerPage)) {
                $rootScope.noOfRecordsPerPage = totalRecordCount;
                //noOfRecordsPerPage = noOfRecordsPerPage;
                isExportMode = true; 
                $timeout(function() {
                    processExport();
                });
           } else {
                processExport();
            }
        }
        
        function processExport() {
            sheetName =  fileName;
            var fileFullName = fileName + "_"+ $filter('date')(new Date(), "dd")  + "_"+ $filter('date')(new Date(), "MMM") + "_"+
                                $filter('date')(new Date(), "yyyy") + "_"+ $filter('date')(new Date(), "HH") + "_"+ $filter('date')(new Date(), "mm");
            
            if (exportOption == 'csv') {
                $('#' + tableName).tableExport({ type: 'csv', escape: 'false' ,fileName : fileFullName,ignoreColumn : ignoreColumn, sheetName : sheetName, footer : footer,enviromentName:GlobalValues.getEnvironmentName()});
            } else if (exportOption == 'excel') {
                $('#' + tableName).tableExport({ type: 'excel', escape: 'false' ,fileName : fileFullName,ignoreColumn : ignoreColumn, sheetName : sheetName, footer : footer,enviromentName:GlobalValues.getEnvironmentName()});
            } else if (exportOption == 'pdf') {
                $('#' + tableName).tableExport({ type: 'pdf', pdfFontSize: '7', escape: 'false' ,fileName : fileFullName,ignoreColumn : ignoreColumn});
            } else if (exportOption == 'png') {
                $('#' + tableName).tableExport({ type: 'png', escape: 'false',fileName : fileFullName,ignoreColumn : ignoreColumn });
            } else if (exportOption == 'doc') {
                $('#' + tableName).tableExport({ type: 'doc', escape: 'false' ,fileName : fileFullName,ignoreColumn : ignoreColumn});
            } else if (exportOption == 'txt') {
                $('#' + tableName).tableExport({ type: 'txt', escape: 'false' ,fileName : fileFullName,ignoreColumn : ignoreColumn});
            } else if (exportOption == 'powerpoint') {
                $('#' + tableName).tableExport({ type: 'powerpoint', escape: 'false' ,fileName : fileFullName,ignoreColumn : ignoreColumn});
            }
            pageNo = pageIndex;
            $rootScope.noOfRecordsPerPage = noOfRecordsPerPageCopy;
        }

    }
})();